import Decimal from 'decimal.js';
import dayjs from 'dayjs';

import * as ratesData from './rates/json/data.js';

// Sort XLM/USD rates in descending order by time.
const Data = ratesData.getData();
Data["XLM/USD"].sort((a, b) => b[0] - a[0]);

function getXlmUsdRate(date) {
    const d = dayjs(date);
    const record = Data["XLM/USD"].find(r => d.unix() > r[0]);
    const rate = record[1];
    return new Decimal(rate);
}

export function getUsdRate(asset, date) {
    const d = dayjs(date);
    const xlmUsd = getXlmUsdRate(date);
    if (asset == "XLM") {
        return xlmUsd;
    } else {
        const assetData = Data[`${asset}/XLM`];
        if (!assetData) {
            return;
        }
        const history = assetData;
        history.sort((a, b) => b["ts"] - a["ts"]);
        const record = history.find(h => d.unix() > h["ts"])
        const highXlm = new Decimal(record["price"][1]);
        const lowXlm = new Decimal(record["price"][2]);
        const midXlm = (highXlm.add(lowXlm)).dividedBy(new Decimal(2));
        const midUsd = midXlm.mul(xlmUsd);
        return midUsd;
    }
}

export function getUsdAmount(asset, date, amount) {
    const usdRate = getUsdRate(asset, date);
    if (usdRate) {
        return new Decimal(amount).mul(usdRate).toFixed(7);
    }
}

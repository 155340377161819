export function getNetworkAccountName(accounts, account) {
    return `Stellar ${getAccountName(accounts, account)}`;
}

export function getAccountName(accounts, account) {
    return `${getShortAddress(account)} (${(accounts[account] || {}).name || "?"})`;
}

export function getLongAssetID(assetType, assetCode = undefined, assetIssuer = undefined) {
    if (assetType == "native") {
        return "XLM";
    }
    return `${assetCode}-${assetIssuer}`
}

export function getShortAssetID(assetType, assetCode = undefined, assetIssuer = undefined) {
    if (assetType == "native") {
        return "XLM";
    }
    return `${assetCode}-${getShortAddress(assetIssuer)}`
}

export function getShortAssetIDFromLong(asset) {
    const parts = asset.split('-');
    if (parts.length == 1) {
        return parts[0];
    }
    return `${parts[0]}-${getShortAddress(parts[1])}`;
}

export function getRecordID(transaction, operation, effect) {
    return `${transaction.id} ${operation.id} ${effect.id}`;
}

export function getShortAddress(address) {
    return `${address.substring(0, 4)}`
}

export function getMemoSuffix(transaction) {
    if (!transaction.memo) {
        return "";
    }
    return ` Memo: ${transaction.memo || ""}`;
}

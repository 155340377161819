[
  {
    "ts": 1533340800,
    "supply": 186000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 2
    },
    "payments": 3,
    "payments_amount": 196000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1533427200,
    "supply": 186000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 229174941,
    "price": [
      4.276208366079605,
      4.276208366079605,
      4.276208366079605,
      4.276208366079605
    ],
    "volume": 979999800
  },
  {
    "ts": 1533513600,
    "supply": 186000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.276208366079605,
      4.276208366079605,
      4.276208366079605,
      4.276208366079605
    ],
    "volume": 0
  },
  {
    "ts": 1533600000,
    "supply": 186000000000,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 15
    },
    "payments": 10,
    "payments_amount": 1000000000,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      4.276208366079605,
      4.3080953,
      4.276208366079605,
      4.3080953
    ],
    "volume": 86161906
  },
  {
    "ts": 1533686400,
    "supply": 186000000000,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      4.3080953,
      5.0717109,
      4.3080953,
      5.0717109
    ],
    "volume": 93798062
  },
  {
    "ts": 1533772800,
    "supply": 186000000000,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.0717109,
      5.0717109,
      5.0717109,
      5.0717109
    ],
    "volume": 0
  },
  {
    "ts": 1533859200,
    "supply": 186000000000,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 114,
    "traded_amount": 134529942628,
    "price": [
      5.0717109,
      5.0717109,
      4.241242381194135,
      4.462308000020826
    ],
    "volume": 582045103966
  },
  {
    "ts": 1533945600,
    "supply": 186000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 48,
    "traded_amount": 63436325222,
    "price": [
      4.462308000020826,
      4.784688995215311,
      4.462308000020826,
      4.484573261755756
    ],
    "volume": 292856460063
  },
  {
    "ts": 1534032000,
    "supply": 186000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 2015905,
    "price": [
      4.484573261755756,
      4.484573261755756,
      4.478979079125814,
      4.478982375204353
    ],
    "volume": 9029200
  },
  {
    "ts": 1534118400,
    "supply": 186000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.478982375204353,
      4.478982375204353,
      4.478982375204353,
      4.478982375204353
    ],
    "volume": 0
  },
  {
    "ts": 1534204800,
    "supply": 186000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 48,
    "traded_amount": 37579419806,
    "price": [
      4.478982375204353,
      4.974656575603019,
      4.478982375204353,
      4.709965529232118
    ],
    "volume": 183155890754
  },
  {
    "ts": 1534291200,
    "supply": 186000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 18
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 2231265,
    "price": [
      4.709965529232118,
      4.709965529232118,
      4.458853193061775,
      4.458853193061775
    ],
    "volume": 10205134
  },
  {
    "ts": 1534377600,
    "supply": 1686000000000,
    "trustlines": {
      "total": 23,
      "authorized": 23,
      "funded": 20
    },
    "payments": 3,
    "payments_amount": 2000000000000,
    "trades": 6,
    "traded_amount": 1213617472,
    "price": [
      4.458853193061775,
      4.7042435,
      4.45884472070531,
      4.7042435
    ],
    "volume": 3243870326
  },
  {
    "ts": 1534464000,
    "supply": 1686000000000,
    "trustlines": {
      "total": 23,
      "authorized": 23,
      "funded": 20
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 17380746768,
    "price": [
      4.7042435,
      4.7042435,
      4.229543600075514,
      4.2295436127017325
    ],
    "volume": 37894817884
  },
  {
    "ts": 1534550400,
    "supply": 1686000000000,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 20,
    "traded_amount": 18187176385,
    "price": [
      4.2295436127017325,
      4.456397180142571,
      4.229543600565562,
      4.456397180034718
    ],
    "volume": 41454391999
  },
  {
    "ts": 1534723200,
    "supply": 1686000000000,
    "trustlines": {
      "total": 25,
      "authorized": 25,
      "funded": 22
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 24831370810,
    "price": [
      4.456397180034718,
      4.6105738,
      4.45639718003046,
      4.6105738
    ],
    "volume": 55405987968
  },
  {
    "ts": 1534896000,
    "supply": 1686000000000,
    "trustlines": {
      "total": 29,
      "authorized": 29,
      "funded": 24
    },
    "payments": 4,
    "payments_amount": 73668058597,
    "trades": 12,
    "traded_amount": 71635118728,
    "price": [
      4.6105738,
      4.77817014,
      4.20179111000127,
      4.77817014
    ],
    "volume": 217491939633
  },
  {
    "ts": 1534982400,
    "supply": 1686000000000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 23
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 609207578,
    "price": [
      4.77817014,
      4.77817014,
      4.512791592002164,
      4.512791592002164
    ],
    "volume": 1920677522
  },
  {
    "ts": 1535068800,
    "supply": 1686000000000,
    "trustlines": {
      "total": 32,
      "authorized": 32,
      "funded": 24
    },
    "payments": 1,
    "payments_amount": 21316,
    "trades": 7,
    "traded_amount": 200000043169,
    "price": [
      4.512791592002164,
      4.612448099991764,
      4.498194800012241,
      4.520641771439294
    ],
    "volume": 911064390139
  },
  {
    "ts": 1535155200,
    "supply": 1686000000000,
    "trustlines": {
      "total": 37,
      "authorized": 37,
      "funded": 24
    },
    "payments": 4,
    "payments_amount": 255130551,
    "trades": 70,
    "traded_amount": 1483295975507,
    "price": [
      4.520641771439294,
      4.664437563050946,
      4.461523750347715,
      4.596019398256343
    ],
    "volume": 6766735456161
  },
  {
    "ts": 1535241600,
    "supply": 1686000000000,
    "trustlines": {
      "total": 44,
      "authorized": 44,
      "funded": 24
    },
    "payments": 3,
    "payments_amount": 11000000000,
    "trades": 572,
    "traded_amount": 19190486216274,
    "price": [
      4.596019398256343,
      4.761904761904762,
      4.47263313001094,
      4.680650234012779
    ],
    "volume": 88981918747882
  },
  {
    "ts": 1535328000,
    "supply": 1686000000000,
    "trustlines": {
      "total": 48,
      "authorized": 48,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 67,
    "traded_amount": 1077745891581,
    "price": [
      4.680650234012779,
      4.761904761904762,
      4.499999842500006,
      4.499999842500006
    ],
    "volume": 4947102762257
  },
  {
    "ts": 1535414400,
    "supply": 1686000000000,
    "trustlines": {
      "total": 49,
      "authorized": 49,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 70,
    "traded_amount": 1437080355092,
    "price": [
      4.499999842500006,
      4.499999842500006,
      4.233417620029471,
      4.282344001455894
    ],
    "volume": 6237145594604
  },
  {
    "ts": 1535500800,
    "supply": 1686000000000,
    "trustlines": {
      "total": 51,
      "authorized": 51,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 194,
    "traded_amount": 3789782594134,
    "price": [
      4.282344001455894,
      4.631578947368421,
      4.221877745138029,
      4.439527618275283
    ],
    "volume": 16443343762226
  },
  {
    "ts": 1535587200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 51,
      "authorized": 51,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 270,
    "traded_amount": 5776068622546,
    "price": [
      4.439527618275283,
      4.682552663531972,
      4.368448300012392,
      4.6063260979061615
    ],
    "volume": 26238320234598
  },
  {
    "ts": 1535673600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 53,
      "authorized": 53,
      "funded": 29
    },
    "payments": 3,
    "payments_amount": 37275145846,
    "trades": 566,
    "traded_amount": 5034241968446,
    "price": [
      4.6063260979061615,
      4.8,
      4.467880920006684,
      4.52009518
    ],
    "volume": 22921750444686
  },
  {
    "ts": 1535760000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 58,
      "authorized": 58,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 124420991,
    "trades": 72,
    "traded_amount": 205687871412,
    "price": [
      4.52009518,
      4.52009518,
      4.265986822752603,
      4.39805565570142
    ],
    "volume": 919840769038
  },
  {
    "ts": 1535846400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 58,
      "authorized": 58,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 24799173533,
    "trades": 60,
    "traded_amount": 422303739065,
    "price": [
      4.39805565570142,
      4.4733694428728725,
      4.269795180001629,
      4.407803388578246
    ],
    "volume": 1705842401938
  },
  {
    "ts": 1535932800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 61,
      "authorized": 61,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 8588653531,
    "trades": 55,
    "traded_amount": 441312584227,
    "price": [
      4.407803388578246,
      4.54545456640003,
      4.349339580079734,
      4.427651227990171
    ],
    "volume": 1890081933543
  },
  {
    "ts": 1536019200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 63,
      "authorized": 63,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 27970922197,
    "price": [
      4.427651227990171,
      4.588045560691286,
      4.362084580068589,
      4.362084580068589
    ],
    "volume": 90988420774
  },
  {
    "ts": 1536105600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 45,
    "traded_amount": 147678230564,
    "price": [
      4.362084580068589,
      4.7,
      4.362084580068589,
      4.7
    ],
    "volume": 568613746119
  },
  {
    "ts": 1536192000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 66,
      "authorized": 66,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 45,
    "traded_amount": 335043985213,
    "price": [
      4.7,
      5.108634161592973,
      4.7,
      4.934147096556403
    ],
    "volume": 1634079949566
  },
  {
    "ts": 1536278400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 66,
      "authorized": 66,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 45633147,
    "price": [
      4.934147096556403,
      4.934147096556403,
      4.740676364327486,
      4.749643337843055
    ],
    "volume": 217536136
  },
  {
    "ts": 1536364800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 66,
      "authorized": 66,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 39,
    "traded_amount": 1108155482807,
    "price": [
      4.749643337843055,
      5.1255380085021525,
      4.749643337843055,
      5.1255380085021525
    ],
    "volume": 5375252500793
  },
  {
    "ts": 1536451200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 68,
      "authorized": 68,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 157,
    "traded_amount": 1861831041560,
    "price": [
      5.1255380085021525,
      5.216763690339301,
      4.978998310106865,
      5.0244843
    ],
    "volume": 9313886432033
  },
  {
    "ts": 1536537600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 71,
      "authorized": 71,
      "funded": 35
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 13,
    "traded_amount": 93654553,
    "price": [
      5.0244843,
      5.340628489433139,
      5.0244843,
      5.338179945186948
    ],
    "volume": 443302705
  },
  {
    "ts": 1536624000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 74,
      "authorized": 74,
      "funded": 35
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 26,
    "traded_amount": 2100478839,
    "price": [
      5.338179945186948,
      5.338179945186948,
      4.76987,
      4.76987
    ],
    "volume": 11073218311
  },
  {
    "ts": 1536710400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 73,
      "authorized": 73,
      "funded": 35
    },
    "payments": 1,
    "payments_amount": 2300000000,
    "trades": 35,
    "traded_amount": 135836998124,
    "price": [
      4.76987,
      5.233566220678987,
      4.76987,
      5.1222544
    ],
    "volume": 645468940628
  },
  {
    "ts": 1536796800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 72,
      "authorized": 72,
      "funded": 34
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 12689361038,
    "price": [
      5.1222544,
      5.1222544,
      4.792179541595925,
      4.884763000053663
    ],
    "volume": 62000093983
  },
  {
    "ts": 1536883200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 73,
      "authorized": 73,
      "funded": 35
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 18,
    "traded_amount": 1655509638,
    "price": [
      4.884763000053663,
      4.924992191135484,
      4.884763000053663,
      4.9
    ],
    "volume": 4076686169
  },
  {
    "ts": 1536969600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 73,
      "authorized": 73,
      "funded": 33
    },
    "payments": 1,
    "payments_amount": 3990050,
    "trades": 5,
    "traded_amount": 849795614,
    "price": [
      4.9,
      5.0000001,
      4.835729243307488,
      4.859907338721701
    ],
    "volume": 4112373730
  },
  {
    "ts": 1537056000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 74,
      "authorized": 74,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 12341145620,
    "price": [
      4.859907338721701,
      4.923179200784781,
      4.733965124388152,
      4.733965124388152
    ],
    "volume": 59771344454
  },
  {
    "ts": 1537142400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 107197720412,
    "price": [
      4.733965124388152,
      5.1580438,
      4.714330429347538,
      5.1580438
    ],
    "volume": 505678382251
  },
  {
    "ts": 1537228800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 77,
      "authorized": 77,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 104950000,
    "price": [
      5.1580438,
      5.1580438,
      4.999524545021439,
      4.999524545021439
    ],
    "volume": 524700101
  },
  {
    "ts": 1537315200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.999524545021439,
      4.999524545021439,
      4.999524545021439,
      4.999524545021439
    ],
    "volume": 0
  },
  {
    "ts": 1537401600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.999524545021439,
      4.999524545021439,
      4.999524545021439,
      4.999524545021439
    ],
    "volume": 0
  },
  {
    "ts": 1537488000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 37
    },
    "payments": 1,
    "payments_amount": 31856866025,
    "trades": 35,
    "traded_amount": 413776157444,
    "price": [
      4.999524545021439,
      4.999524545021439,
      3.703703703683241,
      3.9840637451257703
    ],
    "volume": 1494494365857
  },
  {
    "ts": 1537574400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 37
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.9840637451257703,
      3.9840637451257703,
      3.9840637451257703,
      3.9840637451257703
    ],
    "volume": 0
  },
  {
    "ts": 1537660800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 75,
      "authorized": 75,
      "funded": 37
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 24,
    "traded_amount": 1937336,
    "price": [
      3.9840637451257703,
      3.9840637451257703,
      3.4022462108821285,
      3.4022462108821285
    ],
    "volume": 3381966
  },
  {
    "ts": 1537747200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 37
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.4022462108821285,
      3.4022462108821285,
      3.4022462108821285,
      3.4022462108821285
    ],
    "volume": 0
  },
  {
    "ts": 1537833600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 79,
      "authorized": 79,
      "funded": 39
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 42,
    "traded_amount": 122058437986,
    "price": [
      3.4022462108821285,
      4.266571250920192,
      3.4022462108821285,
      4.049412690442643
    ],
    "volume": 262553482577
  },
  {
    "ts": 1537920000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 48
    },
    "payments": 9,
    "payments_amount": 22019645301,
    "trades": 334,
    "traded_amount": 114000541450,
    "price": [
      4.049412690442643,
      4.19451681008752,
      3.8725191158651233,
      4.13065916021581
    ],
    "volume": 314112629317
  },
  {
    "ts": 1538006400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 131,
      "authorized": 131,
      "funded": 48
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 93,
    "traded_amount": 51642264240,
    "price": [
      4.13065916021581,
      4.13065916021581,
      3.8709814442269455,
      3.905075441333079
    ],
    "volume": 119984043422
  },
  {
    "ts": 1538092800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 216,
      "authorized": 216,
      "funded": 52
    },
    "payments": 1,
    "payments_amount": 101559,
    "trades": 66,
    "traded_amount": 41217123670,
    "price": [
      3.905075441333079,
      4.050437957658989,
      3.819999999904146,
      3.9878962
    ],
    "volume": 143197703411
  },
  {
    "ts": 1538179200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 261,
      "authorized": 261,
      "funded": 55
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 268,
    "traded_amount": 81149249519,
    "price": [
      3.9878962,
      4.101304240833402,
      3.850073517,
      3.986475801115201
    ],
    "volume": 175857724446
  },
  {
    "ts": 1538265600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 294,
      "authorized": 294,
      "funded": 55
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 35,
    "traded_amount": 15339288408,
    "price": [
      3.986475801115201,
      3.9913693,
      3.81947261663286,
      3.9285276626419177
    ],
    "volume": 33069689408
  },
  {
    "ts": 1538352000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 362,
      "authorized": 362,
      "funded": 63
    },
    "payments": 4,
    "payments_amount": 767019354000,
    "trades": 33,
    "traded_amount": 292230551251,
    "price": [
      3.9285276626419177,
      3.941760000041075,
      3.7860216295346505,
      3.83
    ],
    "volume": 1050818976038
  },
  {
    "ts": 1538438400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 407,
      "authorized": 407,
      "funded": 65
    },
    "payments": 6,
    "payments_amount": 100000000000,
    "trades": 23,
    "traded_amount": 22450362123,
    "price": [
      3.83,
      4.0529236,
      2,
      3.9540054
    ],
    "volume": 88637250992
  },
  {
    "ts": 1538524800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 436,
      "authorized": 436,
      "funded": 65
    },
    "payments": 1,
    "payments_amount": 11883100000,
    "trades": 15,
    "traded_amount": 59662285245,
    "price": [
      3.9540054,
      4.250329971625629,
      3.9540054,
      4.250329971625629
    ],
    "volume": 194970420507
  },
  {
    "ts": 1538611200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 459,
      "authorized": 459,
      "funded": 73
    },
    "payments": 7,
    "payments_amount": 100000000000,
    "trades": 7,
    "traded_amount": 20113810422,
    "price": [
      4.250329971625629,
      4.250329971625629,
      4.0365778538,
      4.099999844774454
    ],
    "volume": 81237870294
  },
  {
    "ts": 1538697600,
    "supply": 1696000000000,
    "trustlines": {
      "total": 476,
      "authorized": 476,
      "funded": 79
    },
    "payments": 14,
    "payments_amount": 409000000000,
    "trades": 74,
    "traded_amount": 297975821129,
    "price": [
      4.099999844774454,
      4.191778307946413,
      4.099999844774454,
      4.142485200695899
    ],
    "volume": 1153621965207
  },
  {
    "ts": 1538784000,
    "supply": 1696000000000,
    "trustlines": {
      "total": 488,
      "authorized": 488,
      "funded": 91
    },
    "payments": 11,
    "payments_amount": 285000000000,
    "trades": 29,
    "traded_amount": 100624496297,
    "price": [
      4.142485200695899,
      4.261071400041764,
      4.0699999999307925,
      4.200000000769574
    ],
    "volume": 403473822261
  },
  {
    "ts": 1538870400,
    "supply": 1696000000000,
    "trustlines": {
      "total": 498,
      "authorized": 498,
      "funded": 88
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 91,
    "traded_amount": 88793695178,
    "price": [
      4.200000000769574,
      4.256917031081415,
      4.11999999920896,
      4.12
    ],
    "volume": 317114040680
  },
  {
    "ts": 1538956800,
    "supply": 1696000000000,
    "trustlines": {
      "total": 518,
      "authorized": 518,
      "funded": 81
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 47,
    "traded_amount": 163710375569,
    "price": [
      4.12,
      4.141012868410129,
      4.03,
      4.03
    ],
    "volume": 667169973442
  },
  {
    "ts": 1539043200,
    "supply": 1696000000000,
    "trustlines": {
      "total": 538,
      "authorized": 538,
      "funded": 84
    },
    "payments": 7,
    "payments_amount": 105000000000,
    "trades": 86,
    "traded_amount": 110190721225,
    "price": [
      4.03,
      4.141007015067868,
      4.0262592629,
      4.079061764173157
    ],
    "volume": 417093735120
  },
  {
    "ts": 1539129600,
    "supply": 1816000000000,
    "trustlines": {
      "total": 555,
      "authorized": 555,
      "funded": 84
    },
    "payments": 10,
    "payments_amount": 332443008000,
    "trades": 84,
    "traded_amount": 118487847808,
    "price": [
      4.079061764173157,
      4.222235200018279,
      4.06,
      4.139999968776356
    ],
    "volume": 446805948772
  },
  {
    "ts": 1539216000,
    "supply": 1816000000000,
    "trustlines": {
      "total": 571,
      "authorized": 571,
      "funded": 88
    },
    "payments": 6,
    "payments_amount": 96900000000,
    "trades": 74,
    "traded_amount": 1214489929558,
    "price": [
      4.139999968776356,
      4.903663200006266,
      4.139999968776356,
      4.903663200006266
    ],
    "volume": 5587057895671
  },
  {
    "ts": 1539302400,
    "supply": 2029500000000,
    "trustlines": {
      "total": 598,
      "authorized": 598,
      "funded": 92
    },
    "payments": 11,
    "payments_amount": 213500000000,
    "trades": 30,
    "traded_amount": 295004797368,
    "price": [
      4.903663200006266,
      4.903663200006266,
      4.626994813113113,
      4.6932514328
    ],
    "volume": 1373277377384
  },
  {
    "ts": 1539388800,
    "supply": 7039500000000,
    "trustlines": {
      "total": 612,
      "authorized": 612,
      "funded": 89
    },
    "payments": 3,
    "payments_amount": 5010100000000,
    "trades": 57,
    "traded_amount": 359846115012,
    "price": [
      4.6932514328,
      4.709528081707317,
      4.657444765358837,
      4.7014574517728525
    ],
    "volume": 1686205371211
  },
  {
    "ts": 1539475200,
    "supply": 7039500000000,
    "trustlines": {
      "total": 639,
      "authorized": 639,
      "funded": 91
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 52,
    "traded_amount": 83702348301,
    "price": [
      4.7014574517728525,
      4.888673500254011,
      4.689082300681179,
      4.888673500254011
    ],
    "volume": 365051686878
  },
  {
    "ts": 1539561600,
    "supply": 7039500000000,
    "trustlines": {
      "total": 654,
      "authorized": 654,
      "funded": 89
    },
    "payments": 3,
    "payments_amount": 5030000000000,
    "trades": 26,
    "traded_amount": 303665872678,
    "price": [
      4.888673500254011,
      4.898181706882818,
      4.6085688963800235,
      4.663636760603571
    ],
    "volume": 1413057477417
  },
  {
    "ts": 1539648000,
    "supply": 7227800000000,
    "trustlines": {
      "total": 684,
      "authorized": 684,
      "funded": 95
    },
    "payments": 14,
    "payments_amount": 308300000000,
    "trades": 74,
    "traded_amount": 427761562048,
    "price": [
      4.663636760603571,
      4.700000107785296,
      4.469999938921665,
      4.469999938921665
    ],
    "volume": 1871332566058
  },
  {
    "ts": 1539734400,
    "supply": 7397350000000,
    "trustlines": {
      "total": 692,
      "authorized": 692,
      "funded": 97
    },
    "payments": 8,
    "payments_amount": 189550000000,
    "trades": 58,
    "traded_amount": 300080521118,
    "price": [
      4.469999938921665,
      4.65,
      4.164509699908092,
      4.190458042204813
    ],
    "volume": 1292682170150
  },
  {
    "ts": 1539820800,
    "supply": 7897350000000,
    "trustlines": {
      "total": 707,
      "authorized": 707,
      "funded": 95
    },
    "payments": 1,
    "payments_amount": 500000000000,
    "trades": 138,
    "traded_amount": 137633094529,
    "price": [
      4.190458042204813,
      4.629629632592593,
      4.149999976846907,
      4.250639827167255
    ],
    "volume": 581197599609
  },
  {
    "ts": 1539907200,
    "supply": 7962350000000,
    "trustlines": {
      "total": 714,
      "authorized": 714,
      "funded": 95
    },
    "payments": 3,
    "payments_amount": 565000000000,
    "trades": 27,
    "traded_amount": 172032901819,
    "price": [
      4.250639827167255,
      4.6,
      4.154,
      4.6
    ],
    "volume": 720980800127
  },
  {
    "ts": 1539993600,
    "supply": 8064550000000,
    "trustlines": {
      "total": 726,
      "authorized": 726,
      "funded": 102
    },
    "payments": 6,
    "payments_amount": 102200000000,
    "trades": 22,
    "traded_amount": 10525274825,
    "price": [
      4.6,
      4.6,
      4.153999911925993,
      4.6
    ],
    "volume": 44618451614
  },
  {
    "ts": 1540080000,
    "supply": 8064550000000,
    "trustlines": {
      "total": 735,
      "authorized": 735,
      "funded": 102
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 27555549986,
    "price": [
      4.6,
      4.6,
      4.153999913386636,
      4.176599919201597
    ],
    "volume": 114640600887
  },
  {
    "ts": 1540166400,
    "supply": 8064550000000,
    "trustlines": {
      "total": 751,
      "authorized": 751,
      "funded": 103
    },
    "payments": 4,
    "payments_amount": 121200000000,
    "trades": 48,
    "traded_amount": 140589765506,
    "price": [
      4.176599919201597,
      4.3037667,
      4.157985139804724,
      4.192016111457712
    ],
    "volume": 503733102212
  },
  {
    "ts": 1540252800,
    "supply": 8064550000000,
    "trustlines": {
      "total": 766,
      "authorized": 766,
      "funded": 100
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 88623300711,
    "price": [
      4.192016111457712,
      4.311463505174672,
      4.192016111457712,
      4.2259442764364925
    ],
    "volume": 375169941183
  },
  {
    "ts": 1540339200,
    "supply": 8279550000000,
    "trustlines": {
      "total": 780,
      "authorized": 780,
      "funded": 101
    },
    "payments": 4,
    "payments_amount": 218500000000,
    "trades": 11,
    "traded_amount": 109653967151,
    "price": [
      4.2259442764364925,
      4.238884485152491,
      4.190738963636363,
      4.234706041523316
    ],
    "volume": 464200941855
  },
  {
    "ts": 1540425600,
    "supply": 8449550000000,
    "trustlines": {
      "total": 794,
      "authorized": 794,
      "funded": 103
    },
    "payments": 3,
    "payments_amount": 170000000000,
    "trades": 14,
    "traded_amount": 69885059883,
    "price": [
      4.234706041523316,
      4.294065500220478,
      4.234706041523316,
      4.294065500220478
    ],
    "volume": 280175967535
  },
  {
    "ts": 1540512000,
    "supply": 8662550000000,
    "trustlines": {
      "total": 811,
      "authorized": 811,
      "funded": 106
    },
    "payments": 5,
    "payments_amount": 213000000000,
    "trades": 27,
    "traded_amount": 234351980417,
    "price": [
      4.294065500220478,
      4.340001319361984,
      4.154,
      4.34
    ],
    "volume": 996734807552
  },
  {
    "ts": 1540598400,
    "supply": 8712550000000,
    "trustlines": {
      "total": 822,
      "authorized": 822,
      "funded": 107
    },
    "payments": 3,
    "payments_amount": 120988000000,
    "trades": 25,
    "traded_amount": 157850109360,
    "price": [
      4.34,
      4.445122438154305,
      4.153999999974424,
      4.377428500379603
    ],
    "volume": 680082186309
  },
  {
    "ts": 1540684800,
    "supply": 8712550000000,
    "trustlines": {
      "total": 833,
      "authorized": 833,
      "funded": 106
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 32,
    "traded_amount": 31477709339,
    "price": [
      4.377428500379603,
      4.390663932201021,
      4.262009597011502,
      4.318270342694256
    ],
    "volume": 124637524955
  },
  {
    "ts": 1540771200,
    "supply": 8757300000000,
    "trustlines": {
      "total": 839,
      "authorized": 839,
      "funded": 106
    },
    "payments": 5,
    "payments_amount": 44970000000,
    "trades": 18,
    "traded_amount": 82126480391,
    "price": [
      4.318270342694256,
      4.491719102011107,
      4.254343365425182,
      4.491719102011107
    ],
    "volume": 356927689634
  },
  {
    "ts": 1540857600,
    "supply": 10267300000000,
    "trustlines": {
      "total": 847,
      "authorized": 847,
      "funded": 106
    },
    "payments": 4,
    "payments_amount": 1510360325054,
    "trades": 25,
    "traded_amount": 132087373405,
    "price": [
      4.491719102011107,
      4.5015772870662465,
      4.153999999971633,
      4.475904999524998
    ],
    "volume": 553751966673
  },
  {
    "ts": 1540944000,
    "supply": 10317300000000,
    "trustlines": {
      "total": 855,
      "authorized": 855,
      "funded": 105
    },
    "payments": 1,
    "payments_amount": 50000000000,
    "trades": 21,
    "traded_amount": 116761727519,
    "price": [
      4.475904999524998,
      4.53,
      4.475904999524998,
      4.509999999884907
    ],
    "volume": 526586875249
  },
  {
    "ts": 1541030400,
    "supply": 10367525600000,
    "trustlines": {
      "total": 867,
      "authorized": 867,
      "funded": 104
    },
    "payments": 3,
    "payments_amount": 50225600000,
    "trades": 41,
    "traded_amount": 310390216829,
    "price": [
      4.509999999884907,
      4.522764619610804,
      4.501708099823044,
      4.501708100048521
    ],
    "volume": 1399736356595
  },
  {
    "ts": 1541116800,
    "supply": 10477525600000,
    "trustlines": {
      "total": 874,
      "authorized": 874,
      "funded": 107
    },
    "payments": 7,
    "payments_amount": 120180200000,
    "trades": 34,
    "traded_amount": 165762874911,
    "price": [
      4.501708100048521,
      4.501708100048521,
      4.306572542954589,
      4.327248839098559
    ],
    "volume": 729095741499
  },
  {
    "ts": 1541203200,
    "supply": 10476537600000,
    "trustlines": {
      "total": 886,
      "authorized": 886,
      "funded": 108
    },
    "payments": 4,
    "payments_amount": 62136000000,
    "trades": 28,
    "traded_amount": 89449669938,
    "price": [
      4.327248839098559,
      4.327248839098559,
      4.223884459785165,
      4.223884459785165
    ],
    "volume": 380475537556
  },
  {
    "ts": 1541289600,
    "supply": 10476537600000,
    "trustlines": {
      "total": 904,
      "authorized": 904,
      "funded": 111
    },
    "payments": 1,
    "payments_amount": 4000000000,
    "trades": 10,
    "traded_amount": 32906948471,
    "price": [
      4.223884459785165,
      4.2935802,
      4.184999999738062,
      4.184999999738062
    ],
    "volume": 138802348945
  },
  {
    "ts": 1541376000,
    "supply": 10476537600000,
    "trustlines": {
      "total": 916,
      "authorized": 916,
      "funded": 110
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 14,
    "traded_amount": 72931095260,
    "price": [
      4.184999999738062,
      4.2935802,
      4.145443721846795,
      4.145443721981903
    ],
    "volume": 303776502894
  },
  {
    "ts": 1541462400,
    "supply": 10597537600000,
    "trustlines": {
      "total": 943,
      "authorized": 943,
      "funded": 118
    },
    "payments": 10,
    "payments_amount": 883758900000,
    "trades": 149,
    "traded_amount": 823532209343,
    "price": [
      4.145443721981903,
      4.145443722165942,
      3.7941578,
      3.7941578
    ],
    "volume": 3202538496886
  },
  {
    "ts": 1541548800,
    "supply": 10757437600000,
    "trustlines": {
      "total": 956,
      "authorized": 956,
      "funded": 123
    },
    "payments": 8,
    "payments_amount": 161437804695,
    "trades": 103,
    "traded_amount": 185466612398,
    "price": [
      3.7941578,
      3.9166666666666665,
      3.78,
      3.907431387423535
    ],
    "volume": 697635677991
  },
  {
    "ts": 1541635200,
    "supply": 10837437600000,
    "trustlines": {
      "total": 967,
      "authorized": 967,
      "funded": 119
    },
    "payments": 2,
    "payments_amount": 80010000000,
    "trades": 81,
    "traded_amount": 423307608569,
    "price": [
      3.907431387423535,
      4.035402262633998,
      3.8537938037728474,
      3.8874762232072837
    ],
    "volume": 1650004391010
  },
  {
    "ts": 1541721600,
    "supply": 11177437600000,
    "trustlines": {
      "total": 975,
      "authorized": 975,
      "funded": 123
    },
    "payments": 19,
    "payments_amount": 442267408073,
    "trades": 689,
    "traded_amount": 175970057291,
    "price": [
      3.8874762232072837,
      3.9232114467408583,
      3.8499998977004073,
      3.923140000158639
    ],
    "volume": 601082264878
  },
  {
    "ts": 1541808000,
    "supply": 11177437600000,
    "trustlines": {
      "total": 989,
      "authorized": 989,
      "funded": 126
    },
    "payments": 7,
    "payments_amount": 5615428045,
    "trades": 91,
    "traded_amount": 259686902850,
    "price": [
      3.923140000158639,
      3.923140001200976,
      3.8499999999103287,
      3.8499999999807826
    ],
    "volume": 971185015436
  },
  {
    "ts": 1541894400,
    "supply": 11284437600000,
    "trustlines": {
      "total": 1009,
      "authorized": 1009,
      "funded": 123
    },
    "payments": 5,
    "payments_amount": 587056400000,
    "trades": 65,
    "traded_amount": 703268183915,
    "price": [
      3.8499999999807826,
      3.8900001322600044,
      3.7048233835,
      3.754831675894931
    ],
    "volume": 2651052738704
  },
  {
    "ts": 1541980800,
    "supply": 11384437600000,
    "trustlines": {
      "total": 1020,
      "authorized": 1020,
      "funded": 127
    },
    "payments": 7,
    "payments_amount": 250559950044,
    "trades": 54,
    "traded_amount": 242568850620,
    "price": [
      3.754831675894931,
      3.772727272727273,
      3.5647942334096108,
      3.7701982003718633
    ],
    "volume": 905619819085
  },
  {
    "ts": 1542067200,
    "supply": 11438437600000,
    "trustlines": {
      "total": 1032,
      "authorized": 1032,
      "funded": 127
    },
    "payments": 4,
    "payments_amount": 55067119968,
    "trades": 169,
    "traded_amount": 249864754910,
    "price": [
      3.7701982003718633,
      4,
      3.7479999999016824,
      3.936220701329943
    ],
    "volume": 825183842338
  },
  {
    "ts": 1542153600,
    "supply": 11438437600000,
    "trustlines": {
      "total": 1040,
      "authorized": 1040,
      "funded": 118
    },
    "payments": 3,
    "payments_amount": 71803040,
    "trades": 67,
    "traded_amount": 310572505315,
    "price": [
      3.936220701329943,
      4.739358901082201,
      3.911399999867541,
      4.411580779586215
    ],
    "volume": 1358198793648
  },
  {
    "ts": 1542240000,
    "supply": 12499087600000,
    "trustlines": {
      "total": 1045,
      "authorized": 1045,
      "funded": 122
    },
    "payments": 13,
    "payments_amount": 1063078268603,
    "trades": 81,
    "traded_amount": 395901607689,
    "price": [
      4.411580779586215,
      4.768633400083377,
      3.949999999974692,
      4.459604022918793
    ],
    "volume": 1702414848508
  },
  {
    "ts": 1542326400,
    "supply": 12554087600000,
    "trustlines": {
      "total": 1052,
      "authorized": 1052,
      "funded": 120
    },
    "payments": 3,
    "payments_amount": 55000000000,
    "trades": 77,
    "traded_amount": 48783742397,
    "price": [
      4.459604022918793,
      4.459604022918793,
      4.11661840022682,
      4.28
    ],
    "volume": 188423649564
  },
  {
    "ts": 1542412800,
    "supply": 12738087600000,
    "trustlines": {
      "total": 1055,
      "authorized": 1055,
      "funded": 124
    },
    "payments": 13,
    "payments_amount": 204020349200,
    "trades": 214,
    "traded_amount": 126669738113,
    "price": [
      4.28,
      4.35,
      3.9499999999331417,
      4.1166613
    ],
    "volume": 506265489015
  },
  {
    "ts": 1542499200,
    "supply": 12738087600000,
    "trustlines": {
      "total": 1063,
      "authorized": 1063,
      "funded": 123
    },
    "payments": 1,
    "payments_amount": 400000000000,
    "trades": 43,
    "traded_amount": 224237179742,
    "price": [
      4.1166613,
      4.1166613,
      3.96616071675,
      4.066624319552086
    ],
    "volume": 905139792405
  },
  {
    "ts": 1542585600,
    "supply": 12803087600000,
    "trustlines": {
      "total": 1072,
      "authorized": 1072,
      "funded": 120
    },
    "payments": 17,
    "payments_amount": 108205995563,
    "trades": 67,
    "traded_amount": 309960754058,
    "price": [
      4.066624319552086,
      4.503903983973308,
      4.066624319552086,
      4.456268320117249
    ],
    "volume": 1339514441913
  },
  {
    "ts": 1542672000,
    "supply": 12818087600000,
    "trustlines": {
      "total": 1081,
      "authorized": 1081,
      "funded": 117
    },
    "payments": 5,
    "payments_amount": 617000001362,
    "trades": 75,
    "traded_amount": 352662822837,
    "price": [
      4.456268320117249,
      5.65345080763583,
      4.43568739978138,
      5.479599178279307
    ],
    "volume": 1781445887840
  },
  {
    "ts": 1542758400,
    "supply": 12873087600000,
    "trustlines": {
      "total": 1087,
      "authorized": 1087,
      "funded": 120
    },
    "payments": 5,
    "payments_amount": 55033507818,
    "trades": 43,
    "traded_amount": 136073113081,
    "price": [
      5.479599178279307,
      5.479599178279307,
      4.97529019991683,
      5.116366642887107
    ],
    "volume": 696764672648
  },
  {
    "ts": 1542844800,
    "supply": 13046087600000,
    "trustlines": {
      "total": 1094,
      "authorized": 1094,
      "funded": 122
    },
    "payments": 7,
    "payments_amount": 173000399936,
    "trades": 241,
    "traded_amount": 267929644940,
    "price": [
      5.116366642887107,
      5.280235234220545,
      5.084793001645974,
      5.280235234220545
    ],
    "volume": 1249636951772
  },
  {
    "ts": 1542931200,
    "supply": 13046087600000,
    "trustlines": {
      "total": 1104,
      "authorized": 1104,
      "funded": 123
    },
    "payments": 1,
    "payments_amount": 1264,
    "trades": 31,
    "traded_amount": 51826819839,
    "price": [
      5.280235234220545,
      5.799110501341847,
      5.280235234220545,
      5.7451123
    ],
    "volume": 291091333227
  },
  {
    "ts": 1543017600,
    "supply": 13046087600000,
    "trustlines": {
      "total": 1108,
      "authorized": 1108,
      "funded": 123
    },
    "payments": 1,
    "payments_amount": 1523,
    "trades": 33,
    "traded_amount": 68248657244,
    "price": [
      5.7451123,
      6.57596655200373,
      5.650770997497726,
      6.57596655200373
    ],
    "volume": 411766580043
  },
  {
    "ts": 1543104000,
    "supply": 13046087600000,
    "trustlines": {
      "total": 1121,
      "authorized": 1121,
      "funded": 121
    },
    "payments": 1,
    "payments_amount": 1265,
    "trades": 93,
    "traded_amount": 483770449639,
    "price": [
      6.57596655200373,
      7.472767900086763,
      6.412648221343874,
      6.414734387553306
    ],
    "volume": 3337072480728
  },
  {
    "ts": 1543190400,
    "supply": 13066087600000,
    "trustlines": {
      "total": 1125,
      "authorized": 1125,
      "funded": 124
    },
    "payments": 3,
    "payments_amount": 320000000000,
    "trades": 21,
    "traded_amount": 13414280421,
    "price": [
      6.414734387553306,
      7.5,
      6.322444678609062,
      7.2232814
    ],
    "volume": 93341509512
  },
  {
    "ts": 1543276800,
    "supply": 13106087600000,
    "trustlines": {
      "total": 1134,
      "authorized": 1134,
      "funded": 127
    },
    "payments": 5,
    "payments_amount": 59800000000,
    "trades": 11,
    "traded_amount": 21518742011,
    "price": [
      7.2232814,
      7.287270100099901,
      6.896551724137931,
      6.896551724137931
    ],
    "volume": 152932871074
  },
  {
    "ts": 1543363200,
    "supply": 13146087600000,
    "trustlines": {
      "total": 1140,
      "authorized": 1140,
      "funded": 123
    },
    "payments": 4,
    "payments_amount": 40000000000,
    "trades": 55,
    "traded_amount": 184065170919,
    "price": [
      6.896551724137931,
      7.308002334151986,
      6.183999999617142,
      7.308002328571429
    ],
    "volume": 1143769173988
  },
  {
    "ts": 1543449600,
    "supply": 13411601100000,
    "trustlines": {
      "total": 1152,
      "authorized": 1152,
      "funded": 129
    },
    "payments": 31,
    "payments_amount": 265793099365,
    "trades": 78,
    "traded_amount": 140259564200,
    "price": [
      7.308002328571429,
      7.308003265782313,
      6.111111111111111,
      6.183999999840671
    ],
    "volume": 867556209793
  },
  {
    "ts": 1543536000,
    "supply": 13513651100000,
    "trustlines": {
      "total": 1162,
      "authorized": 1162,
      "funded": 133
    },
    "payments": 16,
    "payments_amount": 201283518000,
    "trades": 53,
    "traded_amount": 98896784100,
    "price": [
      6.183999999840671,
      7.30800233588198,
      5.842999999211626,
      6.307811088478261
    ],
    "volume": 629080098614
  },
  {
    "ts": 1543622400,
    "supply": 13533651100000,
    "trustlines": {
      "total": 1173,
      "authorized": 1173,
      "funded": 132
    },
    "payments": 2,
    "payments_amount": 20000000000,
    "trades": 24,
    "traded_amount": 61660298578,
    "price": [
      6.307811088478261,
      6.400128040973112,
      6.042292421492387,
      6.1475159
    ],
    "volume": 375859078215
  },
  {
    "ts": 1543708800,
    "supply": 13533651100000,
    "trustlines": {
      "total": 1179,
      "authorized": 1179,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 16283501052,
    "price": [
      6.1475159,
      6.292387092552205,
      6.089922578180817,
      6.282326399920579
    ],
    "volume": 101453072671
  },
  {
    "ts": 1543795200,
    "supply": 13543651100000,
    "trustlines": {
      "total": 1183,
      "authorized": 1183,
      "funded": 130
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 9,
    "traded_amount": 6774013302,
    "price": [
      6.282326399920579,
      6.800555406776076,
      6.282326399920579,
      6.750002197500725
    ],
    "volume": 43525940980
  },
  {
    "ts": 1543881600,
    "supply": 13566401100000,
    "trustlines": {
      "total": 1182,
      "authorized": 1182,
      "funded": 131
    },
    "payments": 4,
    "payments_amount": 22750000000,
    "trades": 6,
    "traded_amount": 14320515455,
    "price": [
      6.750002197500725,
      6.978134299947846,
      6.606852625,
      6.978037299813502
    ],
    "volume": 99660641325
  },
  {
    "ts": 1543968000,
    "supply": 13611401100000,
    "trustlines": {
      "total": 1188,
      "authorized": 1188,
      "funded": 130
    },
    "payments": 8,
    "payments_amount": 50099102112,
    "trades": 55,
    "traded_amount": 98893487182,
    "price": [
      6.978037299813502,
      7.688125500060669,
      6.933750097651607,
      7.6708561001242135
    ],
    "volume": 714903858014
  },
  {
    "ts": 1544054400,
    "supply": 13684401100000,
    "trustlines": {
      "total": 1193,
      "authorized": 1193,
      "funded": 132
    },
    "payments": 7,
    "payments_amount": 73000000000,
    "trades": 28,
    "traded_amount": 38968831333,
    "price": [
      7.6708561001242135,
      8,
      7.353991435047854,
      8
    ],
    "volume": 300492745059
  },
  {
    "ts": 1544140800,
    "supply": 13709001100000,
    "trustlines": {
      "total": 1202,
      "authorized": 1202,
      "funded": 133
    },
    "payments": 8,
    "payments_amount": 44600000000,
    "trades": 185,
    "traded_amount": 404651611014,
    "price": [
      8,
      9.476383400005947,
      8,
      8.720494207666666
    ],
    "volume": 3558507729650
  },
  {
    "ts": 1544227200,
    "supply": 13919001100000,
    "trustlines": {
      "total": 1207,
      "authorized": 1207,
      "funded": 136
    },
    "payments": 6,
    "payments_amount": 211210753830,
    "trades": 177,
    "traded_amount": 202539379401,
    "price": [
      8.720494207666666,
      8.922025601055456,
      8.07989760408602,
      8.66839920074521
    ],
    "volume": 1449228748355
  },
  {
    "ts": 1544313600,
    "supply": 13919001100000,
    "trustlines": {
      "total": 1212,
      "authorized": 1212,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 37,
    "traded_amount": 53022083669,
    "price": [
      8.66839920074521,
      8.66839920074521,
      8.133077976373084,
      8.160684848781603
    ],
    "volume": 423300361870
  },
  {
    "ts": 1544400000,
    "supply": 13997001100000,
    "trustlines": {
      "total": 1216,
      "authorized": 1216,
      "funded": 136
    },
    "payments": 8,
    "payments_amount": 78000000000,
    "trades": 38,
    "traded_amount": 60207025417,
    "price": [
      8.160684848781603,
      8.736474300975118,
      8.160684848781603,
      8.649950348960633
    ],
    "volume": 500028487539
  },
  {
    "ts": 1544486400,
    "supply": 14022701100000,
    "trustlines": {
      "total": 1222,
      "authorized": 1222,
      "funded": 138
    },
    "payments": 14,
    "payments_amount": 53700422568,
    "trades": 45,
    "traded_amount": 102193982056,
    "price": [
      8.649950348960633,
      9.141192300118501,
      8.649950348960633,
      9.05520136743935
    ],
    "volume": 916323422220
  },
  {
    "ts": 1544572800,
    "supply": 14187891100000,
    "trustlines": {
      "total": 1230,
      "authorized": 1230,
      "funded": 142
    },
    "payments": 25,
    "payments_amount": 171256055897,
    "trades": 88,
    "traded_amount": 135309918813,
    "price": [
      9.05520136743935,
      9.181779454519637,
      7,
      8.795074758135444
    ],
    "volume": 1132671493949
  },
  {
    "ts": 1544659200,
    "supply": 14239891100000,
    "trustlines": {
      "total": 1236,
      "authorized": 1236,
      "funded": 145
    },
    "payments": 4,
    "payments_amount": 52713475817,
    "trades": 48,
    "traded_amount": 262022803920,
    "price": [
      8.795074758135444,
      9.67572248518649,
      8.795074758135444,
      9.67572248518649
    ],
    "volume": 2354288185627
  },
  {
    "ts": 1544745600,
    "supply": 14282141100000,
    "trustlines": {
      "total": 1246,
      "authorized": 1246,
      "funded": 147
    },
    "payments": 12,
    "payments_amount": 44898628359,
    "trades": 123,
    "traded_amount": 120014811360,
    "price": [
      9.67572248518649,
      10.120000971520287,
      8.8,
      10.12
    ],
    "volume": 931203446870
  },
  {
    "ts": 1544832000,
    "supply": 14282141100000,
    "trustlines": {
      "total": 1251,
      "authorized": 1251,
      "funded": 145
    },
    "payments": 19,
    "payments_amount": 9528067395,
    "trades": 275,
    "traded_amount": 275537631914,
    "price": [
      10.12,
      10.200000612000098,
      8.79999999995312,
      10.2
    ],
    "volume": 1998100457433
  },
  {
    "ts": 1544918400,
    "supply": 14282141100000,
    "trustlines": {
      "total": 1257,
      "authorized": 1257,
      "funded": 144
    },
    "payments": 60,
    "payments_amount": 1477430476,
    "trades": 92,
    "traded_amount": 94055710887,
    "price": [
      10.2,
      10.5,
      10.134978041849651,
      10.5
    ],
    "volume": 956667902736
  },
  {
    "ts": 1545004800,
    "supply": 14282141100000,
    "trustlines": {
      "total": 1265,
      "authorized": 1265,
      "funded": 145
    },
    "payments": 12,
    "payments_amount": 2126231916,
    "trades": 113,
    "traded_amount": 267899896305,
    "price": [
      10.5,
      10.601456650340308,
      8.933596576,
      9.261779
    ],
    "volume": 2708512751815
  },
  {
    "ts": 1545091200,
    "supply": 14388291100000,
    "trustlines": {
      "total": 1271,
      "authorized": 1271,
      "funded": 145
    },
    "payments": 74,
    "payments_amount": 175823069639,
    "trades": 187,
    "traded_amount": 463248091536,
    "price": [
      9.261779,
      9.574945,
      8.799999999428493,
      9.340962167211583
    ],
    "volume": 4170383141765
  },
  {
    "ts": 1545177600,
    "supply": 14522541100000,
    "trustlines": {
      "total": 1273,
      "authorized": 1273,
      "funded": 151
    },
    "payments": 14,
    "payments_amount": 10154377245631,
    "trades": 203,
    "traded_amount": 550767554985,
    "price": [
      9.340962167211583,
      9.340962167211583,
      8.109999973417759,
      8.847023201111918
    ],
    "volume": 4695188814224
  },
  {
    "ts": 1545264000,
    "supply": 14561271000000,
    "trustlines": {
      "total": 1277,
      "authorized": 1277,
      "funded": 151
    },
    "payments": 7,
    "payments_amount": 388729900000,
    "trades": 140,
    "traded_amount": 456333900604,
    "price": [
      8.847023201111918,
      8.87890550233493,
      7.95799998368883,
      8.1022301
    ],
    "volume": 3787455559094
  },
  {
    "ts": 1545350400,
    "supply": 14598221000000,
    "trustlines": {
      "total": 1277,
      "authorized": 1277,
      "funded": 153
    },
    "payments": 10,
    "payments_amount": 487344472211,
    "trades": 160,
    "traded_amount": 525532993465,
    "price": [
      8.1022301,
      8.709683000169457,
      7.541359203813663,
      8.4866825
    ],
    "volume": 4185759790716
  },
  {
    "ts": 1545436800,
    "supply": 14604471000000,
    "trustlines": {
      "total": 1278,
      "authorized": 1278,
      "funded": 152
    },
    "payments": 2,
    "payments_amount": 6250000000,
    "trades": 27,
    "traded_amount": 57179496407,
    "price": [
      8.4866825,
      8.647698804109732,
      8.339817541,
      8.498826736
    ],
    "volume": 489945848063
  },
  {
    "ts": 1545523200,
    "supply": 14604471000000,
    "trustlines": {
      "total": 1280,
      "authorized": 1280,
      "funded": 151
    },
    "payments": 2,
    "payments_amount": 200000000000,
    "trades": 114,
    "traded_amount": 349910996725,
    "price": [
      8.498826736,
      8.498826736,
      7.889867841409692,
      8.05999999975355
    ],
    "volume": 2808749937551
  },
  {
    "ts": 1545609600,
    "supply": 14610971000000,
    "trustlines": {
      "total": 1281,
      "authorized": 1281,
      "funded": 147
    },
    "payments": 5,
    "payments_amount": 271895526331,
    "trades": 78,
    "traded_amount": 363186655196,
    "price": [
      8.05999999975355,
      8.060000000034329,
      7.139999999842814,
      7.464601360349224
    ],
    "volume": 2862034521301
  },
  {
    "ts": 1545696000,
    "supply": 14611471000000,
    "trustlines": {
      "total": 1284,
      "authorized": 1284,
      "funded": 148
    },
    "payments": 1,
    "payments_amount": 500000000,
    "trades": 14,
    "traded_amount": 8569082010,
    "price": [
      7.464601360349224,
      8.560672994845662,
      7.464601360349224,
      8.559213301133916
    ],
    "volume": 70694421427
  },
  {
    "ts": 1545782400,
    "supply": 15061471000000,
    "trustlines": {
      "total": 1286,
      "authorized": 1286,
      "funded": 151
    },
    "payments": 9,
    "payments_amount": 451927365661,
    "trades": 26,
    "traded_amount": 185634192647,
    "price": [
      8.559213301133916,
      8.670282174333364,
      8.009999999813942,
      8.350305325
    ],
    "volume": 1543065397991
  },
  {
    "ts": 1545868800,
    "supply": 15074471000000,
    "trustlines": {
      "total": 1288,
      "authorized": 1288,
      "funded": 152
    },
    "payments": 2,
    "payments_amount": 13050747785,
    "trades": 68,
    "traded_amount": 218968742734,
    "price": [
      8.350305325,
      9.20090058342864,
      8.350305325,
      9.20090058342864
    ],
    "volume": 1934686603260
  },
  {
    "ts": 1545955200,
    "supply": 15196011000000,
    "trustlines": {
      "total": 1294,
      "authorized": 1294,
      "funded": 157
    },
    "payments": 7,
    "payments_amount": 141570000000,
    "trades": 33,
    "traded_amount": 51852800449,
    "price": [
      9.20090058342864,
      9.20090058342864,
      8.2718734,
      8.320867200277688
    ],
    "volume": 468214232174
  },
  {
    "ts": 1546041600,
    "supply": 15211011000000,
    "trustlines": {
      "total": 1295,
      "authorized": 1295,
      "funded": 158
    },
    "payments": 2,
    "payments_amount": 15013384506,
    "trades": 32,
    "traded_amount": 91202440259,
    "price": [
      8.320867200277688,
      8.5346864,
      8.274481086017165,
      8.5346864
    ],
    "volume": 766285660034
  },
  {
    "ts": 1546128000,
    "supply": 15211011000000,
    "trustlines": {
      "total": 1296,
      "authorized": 1296,
      "funded": 159
    },
    "payments": 1,
    "payments_amount": 250000000000,
    "trades": 7,
    "traded_amount": 3368564759,
    "price": [
      8.5346864,
      8.75195387,
      8.531683605525743,
      8.61207
    ],
    "volume": 29156070368
  },
  {
    "ts": 1546214400,
    "supply": 15216011000000,
    "trustlines": {
      "total": 1302,
      "authorized": 1302,
      "funded": 156
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 16,
    "traded_amount": 17348591341,
    "price": [
      8.61207,
      9.1283170088271,
      8.590600336,
      9.098734
    ],
    "volume": 153542989266
  },
  {
    "ts": 1546300800,
    "supply": 15216011000000,
    "trustlines": {
      "total": 1308,
      "authorized": 1308,
      "funded": 155
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 33,
    "traded_amount": 40902475314,
    "price": [
      9.098734,
      9.314571000009343,
      8.936631397002458,
      8.942663308519824
    ],
    "volume": 379174424905
  },
  {
    "ts": 1546387200,
    "supply": 15216011000000,
    "trustlines": {
      "total": 1307,
      "authorized": 1307,
      "funded": 155
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 55,
    "traded_amount": 155152699786,
    "price": [
      8.942663308519824,
      8.942663308519824,
      8.608108108108109,
      8.61
    ],
    "volume": 1336147792474
  },
  {
    "ts": 1546473600,
    "supply": 15249971000000,
    "trustlines": {
      "total": 1310,
      "authorized": 1310,
      "funded": 157
    },
    "payments": 12,
    "payments_amount": 114348197043,
    "trades": 16,
    "traded_amount": 131943633202,
    "price": [
      8.61,
      9.157577515743741,
      8.609999999622177,
      8.917321270588236
    ],
    "volume": 1139988476586
  },
  {
    "ts": 1546560000,
    "supply": 15249971000000,
    "trustlines": {
      "total": 1313,
      "authorized": 1313,
      "funded": 156
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 14,
    "traded_amount": 22962715903,
    "price": [
      8.917321270588236,
      9.003353800112816,
      8.791649336666667,
      8.7934099
    ],
    "volume": 205014566802
  },
  {
    "ts": 1546646400,
    "supply": 15297671000000,
    "trustlines": {
      "total": 1319,
      "authorized": 1319,
      "funded": 155
    },
    "payments": 3,
    "payments_amount": 47700000000,
    "trades": 19,
    "traded_amount": 41384063516,
    "price": [
      8.7934099,
      8.954891100323739,
      8.713359200022518,
      8.954891100323739
    ],
    "volume": 361923607863
  },
  {
    "ts": 1546732800,
    "supply": 15312671000000,
    "trustlines": {
      "total": 1318,
      "authorized": 1318,
      "funded": 154
    },
    "payments": 3,
    "payments_amount": 15538717280,
    "trades": 34,
    "traded_amount": 95121124547,
    "price": [
      8.954891100323739,
      9,
      8.505376344086022,
      8.51
    ],
    "volume": 816020782839
  },
  {
    "ts": 1546819200,
    "supply": 15312671000000,
    "trustlines": {
      "total": 1318,
      "authorized": 1318,
      "funded": 155
    },
    "payments": 4,
    "payments_amount": 282037260000,
    "trades": 73,
    "traded_amount": 252976143237,
    "price": [
      8.51,
      8.51000000054518,
      8.119999999570492,
      8.51000000054518
    ],
    "volume": 2099820824238
  },
  {
    "ts": 1546905600,
    "supply": 15312671000000,
    "trustlines": {
      "total": 1326,
      "authorized": 1326,
      "funded": 160
    },
    "payments": 2,
    "payments_amount": 300020200,
    "trades": 22,
    "traded_amount": 7651063040,
    "price": [
      8.51000000054518,
      8.51000000054518,
      8.12,
      8.367347
    ],
    "volume": 62242821953
  },
  {
    "ts": 1546992000,
    "supply": 15342671000000,
    "trustlines": {
      "total": 1332,
      "authorized": 1332,
      "funded": 160
    },
    "payments": 1,
    "payments_amount": 30000000000,
    "trades": 20,
    "traded_amount": 10651342939,
    "price": [
      8.367347,
      8.51,
      8.11,
      8.168319599068749
    ],
    "volume": 87002296270
  },
  {
    "ts": 1547078400,
    "supply": 15342671000000,
    "trustlines": {
      "total": 1338,
      "authorized": 1338,
      "funded": 154
    },
    "payments": 5,
    "payments_amount": 702261979,
    "trades": 58,
    "traded_amount": 70072673267,
    "price": [
      8.168319599068749,
      9.644943100021692,
      7.76442124780461,
      9.320715569612782
    ],
    "volume": 624529967410
  },
  {
    "ts": 1547164800,
    "supply": 15360671000000,
    "trustlines": {
      "total": 1341,
      "authorized": 1341,
      "funded": 152
    },
    "payments": 5,
    "payments_amount": 19300233516,
    "trades": 35,
    "traded_amount": 102005680751,
    "price": [
      9.320715569612782,
      9.689876900090196,
      9.137293314407186,
      9.454038717633045
    ],
    "volume": 955752716094
  },
  {
    "ts": 1547251200,
    "supply": 15360671000000,
    "trustlines": {
      "total": 1348,
      "authorized": 1348,
      "funded": 151
    },
    "payments": 14,
    "payments_amount": 519005881,
    "trades": 30,
    "traded_amount": 14349233829,
    "price": [
      9.454038717633045,
      9.605550049554013,
      9.454038717633045,
      9.4673391
    ],
    "volume": 136575899465
  },
  {
    "ts": 1547337600,
    "supply": 15360671000000,
    "trustlines": {
      "total": 1350,
      "authorized": 1350,
      "funded": 148
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 46,
    "traded_amount": 81940401646,
    "price": [
      9.4673391,
      9.997402600008602,
      8.961769985213067,
      9.80499819
    ],
    "volume": 762924336637
  },
  {
    "ts": 1547424000,
    "supply": 15440671000000,
    "trustlines": {
      "total": 1350,
      "authorized": 1350,
      "funded": 149
    },
    "payments": 1,
    "payments_amount": 80000000000,
    "trades": 26,
    "traded_amount": 52459224703,
    "price": [
      9.80499819,
      9.80499819,
      9.13235271102666,
      9.23422225423544
    ],
    "volume": 483497032146
  },
  {
    "ts": 1547510400,
    "supply": 15512371000000,
    "trustlines": {
      "total": 1353,
      "authorized": 1353,
      "funded": 150
    },
    "payments": 12,
    "payments_amount": 155900000000,
    "trades": 54,
    "traded_amount": 195253337942,
    "price": [
      9.23422225423544,
      9.6800008,
      9.2,
      9.543607599757108
    ],
    "volume": 1823490952062
  },
  {
    "ts": 1547596800,
    "supply": 15722371000000,
    "trustlines": {
      "total": 1357,
      "authorized": 1357,
      "funded": 154
    },
    "payments": 5,
    "payments_amount": 210064844181,
    "trades": 27,
    "traded_amount": 43357964377,
    "price": [
      9.543607599757108,
      9.5889364766507,
      9.283577073373516,
      9.4856133
    ],
    "volume": 407599443518
  },
  {
    "ts": 1547683200,
    "supply": 15722371000000,
    "trustlines": {
      "total": 1362,
      "authorized": 1362,
      "funded": 150
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 54,
    "traded_amount": 134098669713,
    "price": [
      9.4856133,
      9.570244641007251,
      9.202476570461538,
      9.251188039512325
    ],
    "volume": 1260141512657
  },
  {
    "ts": 1547769600,
    "supply": 15722371000000,
    "trustlines": {
      "total": 1364,
      "authorized": 1364,
      "funded": 149
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 914256184,
    "price": [
      9.251188039512325,
      9.410312196,
      9.251188039512325,
      9.410312196
    ],
    "volume": 8575128966
  },
  {
    "ts": 1547856000,
    "supply": 15753371000000,
    "trustlines": {
      "total": 1373,
      "authorized": 1373,
      "funded": 151
    },
    "payments": 4,
    "payments_amount": 41000000000,
    "trades": 14,
    "traded_amount": 18770288395,
    "price": [
      9.410312196,
      9.4662952211074,
      9.13242,
      9.385661515981734
    ],
    "volume": 175347565796
  },
  {
    "ts": 1547942400,
    "supply": 15753371000000,
    "trustlines": {
      "total": 1374,
      "authorized": 1374,
      "funded": 148
    },
    "payments": 1,
    "payments_amount": 20000000000,
    "trades": 40,
    "traded_amount": 203783701281,
    "price": [
      9.385661515981734,
      9.79,
      9.253039160484107,
      9.79
    ],
    "volume": 1958885478389
  },
  {
    "ts": 1548028800,
    "supply": 15873371000000,
    "trustlines": {
      "total": 1375,
      "authorized": 1375,
      "funded": 149
    },
    "payments": 7,
    "payments_amount": 124947881829,
    "trades": 20,
    "traded_amount": 175905136875,
    "price": [
      9.79,
      9.8499453,
      9.477900379662294,
      9.8
    ],
    "volume": 1695594717581
  },
  {
    "ts": 1548115200,
    "supply": 15873371000000,
    "trustlines": {
      "total": 1389,
      "authorized": 1389,
      "funded": 153
    },
    "payments": 3,
    "payments_amount": 400159957986,
    "trades": 18,
    "traded_amount": 72010284667,
    "price": [
      9.8,
      10.050311860433302,
      9.59274021417976,
      9.737515531248176
    ],
    "volume": 703010022629
  },
  {
    "ts": 1548201600,
    "supply": 15923371000000,
    "trustlines": {
      "total": 1400,
      "authorized": 1400,
      "funded": 155
    },
    "payments": 7,
    "payments_amount": 50475997472,
    "trades": 19,
    "traded_amount": 114594118794,
    "price": [
      9.737515531248176,
      10.05000000002087,
      9.70913,
      9.727749475359616
    ],
    "volume": 1130420336533
  },
  {
    "ts": 1548288000,
    "supply": 15923371000000,
    "trustlines": {
      "total": 1409,
      "authorized": 1409,
      "funded": 157
    },
    "payments": 6,
    "payments_amount": 288291576,
    "trades": 14,
    "traded_amount": 26952988716,
    "price": [
      9.727749475359616,
      10.050001133504114,
      9.727749475359616,
      9.925313001839157
    ],
    "volume": 268142967149
  },
  {
    "ts": 1548374400,
    "supply": 15938321000000,
    "trustlines": {
      "total": 1420,
      "authorized": 1420,
      "funded": 160
    },
    "payments": 15,
    "payments_amount": 46389932587,
    "trades": 25,
    "traded_amount": 76852578914,
    "price": [
      9.925313001839157,
      10.17259979998645,
      9.925313001839157,
      10.080218375
    ],
    "volume": 775270933815
  },
  {
    "ts": 1548460800,
    "supply": 15968321000000,
    "trustlines": {
      "total": 1420,
      "authorized": 1420,
      "funded": 163
    },
    "payments": 5,
    "payments_amount": 30119990327,
    "trades": 24,
    "traded_amount": 90807578644,
    "price": [
      10.080218375,
      10.251983348698639,
      9.638433446094943,
      10.15985516
    ],
    "volume": 897870901869
  },
  {
    "ts": 1548547200,
    "supply": 15994656700000,
    "trustlines": {
      "total": 1427,
      "authorized": 1427,
      "funded": 160
    },
    "payments": 4,
    "payments_amount": 26336476643,
    "trades": 20,
    "traded_amount": 38648884846,
    "price": [
      10.15985516,
      10.739869412143825,
      10.15985516,
      10.739869412143825
    ],
    "volume": 394206836866
  },
  {
    "ts": 1548633600,
    "supply": 16002656700000,
    "trustlines": {
      "total": 1431,
      "authorized": 1431,
      "funded": 155
    },
    "payments": 7,
    "payments_amount": 8009406126,
    "trades": 44,
    "traded_amount": 107735277653,
    "price": [
      10.739869412143825,
      11.977619977619977,
      10.686592354234065,
      11.73591836
    ],
    "volume": 1187916615088
  },
  {
    "ts": 1548720000,
    "supply": 16012656700000,
    "trustlines": {
      "total": 1437,
      "authorized": 1437,
      "funded": 152
    },
    "payments": 9,
    "payments_amount": 65095573824,
    "trades": 41,
    "traded_amount": 172279702768,
    "price": [
      11.73591836,
      12.361886695771187,
      11.73591836,
      12.23972300068112
    ],
    "volume": 2082673636777
  },
  {
    "ts": 1548806400,
    "supply": 16024656700000,
    "trustlines": {
      "total": 1439,
      "authorized": 1439,
      "funded": 153
    },
    "payments": 8,
    "payments_amount": 13058653850,
    "trades": 11,
    "traded_amount": 54622397691,
    "price": [
      12.23972300068112,
      12.23972300068112,
      11.61940859526494,
      11.790589693676308
    ],
    "volume": 650587404218
  },
  {
    "ts": 1548892800,
    "supply": 16044656700000,
    "trustlines": {
      "total": 1446,
      "authorized": 1446,
      "funded": 155
    },
    "payments": 8,
    "payments_amount": 50663090557,
    "trades": 21,
    "traded_amount": 34509745383,
    "price": [
      11.790589693676308,
      12.538491300128909,
      11.574449171,
      12.348102898
    ],
    "volume": 426252530474
  },
  {
    "ts": 1548979200,
    "supply": 11024711189900,
    "trustlines": {
      "total": 1448,
      "authorized": 1448,
      "funded": 157
    },
    "payments": 15,
    "payments_amount": 5068644634644,
    "trades": 17,
    "traded_amount": 42878325057,
    "price": [
      12.348102898,
      12.788424209959032,
      12.177963200041997,
      12.302347400993728
    ],
    "volume": 531898776943
  },
  {
    "ts": 1549065600,
    "supply": 11014711189900,
    "trustlines": {
      "total": 1450,
      "authorized": 1450,
      "funded": 155
    },
    "payments": 8,
    "payments_amount": 410310466397,
    "trades": 27,
    "traded_amount": 88178370190,
    "price": [
      12.302347400993728,
      12.516351635163517,
      12.112706332317249,
      12.112706332317249
    ],
    "volume": 1088195860341
  },
  {
    "ts": 1549152000,
    "supply": 11014711189900,
    "trustlines": {
      "total": 1450,
      "authorized": 1450,
      "funded": 153
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 18,
    "traded_amount": 131073376059,
    "price": [
      12.112706332317249,
      12.842872590998173,
      12.112706332317249,
      12.820299148782285
    ],
    "volume": 1647943973114
  },
  {
    "ts": 1549238400,
    "supply": 11051711189900,
    "trustlines": {
      "total": 1453,
      "authorized": 1453,
      "funded": 154
    },
    "payments": 9,
    "payments_amount": 47179500979,
    "trades": 14,
    "traded_amount": 32870529017,
    "price": [
      12.820299148782285,
      12.820299148782285,
      12.5999599959996,
      12.795103058
    ],
    "volume": 418173180031
  },
  {
    "ts": 1549324800,
    "supply": 11056711189900,
    "trustlines": {
      "total": 1454,
      "authorized": 1454,
      "funded": 155
    },
    "payments": 3,
    "payments_amount": 5190149821,
    "trades": 12,
    "traded_amount": 59910794043,
    "price": [
      12.795103058,
      13.394176200059766,
      12.795103058,
      13.2433734
    ],
    "volume": 788490387652
  },
  {
    "ts": 1549411200,
    "supply": 11056711189900,
    "trustlines": {
      "total": 1462,
      "authorized": 1462,
      "funded": 157
    },
    "payments": 2,
    "payments_amount": 91102500000,
    "trades": 17,
    "traded_amount": 61270254349,
    "price": [
      13.2433734,
      13.977349767711008,
      13.193253588003333,
      13.977349767711008
    ],
    "volume": 817902439245
  },
  {
    "ts": 1549497600,
    "supply": 11187911189900,
    "trustlines": {
      "total": 1466,
      "authorized": 1466,
      "funded": 159
    },
    "payments": 8,
    "payments_amount": 141200000000,
    "trades": 10,
    "traded_amount": 7379702717,
    "price": [
      13.977349767711008,
      13.977349767711008,
      13.298541732233586,
      13.59015094
    ],
    "volume": 99480398161
  },
  {
    "ts": 1549584000,
    "supply": 11192911189900,
    "trustlines": {
      "total": 1467,
      "authorized": 1467,
      "funded": 157
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 19,
    "traded_amount": 112599737103,
    "price": [
      13.59015094,
      13.79376797500569,
      12.165760935718323,
      12.329984920143227
    ],
    "volume": 1523675180627
  },
  {
    "ts": 1549670400,
    "supply": 11272811189900,
    "trustlines": {
      "total": 1469,
      "authorized": 1469,
      "funded": 159
    },
    "payments": 8,
    "payments_amount": 79900000000,
    "trades": 11,
    "traded_amount": 41787450039,
    "price": [
      12.329984920143227,
      12.722227222500111,
      12.329984920143227,
      12.661500319241592
    ],
    "volume": 520819042227
  },
  {
    "ts": 1549756800,
    "supply": 11272811182525,
    "trustlines": {
      "total": 1469,
      "authorized": 1469,
      "funded": 157
    },
    "payments": 1,
    "payments_amount": 7375,
    "trades": 23,
    "traded_amount": 28990812252,
    "price": [
      12.661500319241592,
      13.250543934828524,
      12.452695323639325,
      12.628399249367948
    ],
    "volume": 368389958288
  },
  {
    "ts": 1549843200,
    "supply": 11272811182525,
    "trustlines": {
      "total": 1470,
      "authorized": 1470,
      "funded": 158
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 27,
    "traded_amount": 5240359129,
    "price": [
      12.628399249367948,
      13.19637510808114,
      12.552536113635565,
      12.98662507
    ],
    "volume": 66908371364
  },
  {
    "ts": 1549929600,
    "supply": 11227259931623,
    "trustlines": {
      "total": 1476,
      "authorized": 1476,
      "funded": 158
    },
    "payments": 9,
    "payments_amount": 46118227665,
    "trades": 11,
    "traded_amount": 19730172376,
    "price": [
      12.98662507,
      13.40471840996125,
      12.943241297986589,
      13.40471840996125
    ],
    "volume": 258784895011
  },
  {
    "ts": 1550016000,
    "supply": 11257339931623,
    "trustlines": {
      "total": 1480,
      "authorized": 1480,
      "funded": 157
    },
    "payments": 5,
    "payments_amount": 74741903606,
    "trades": 9,
    "traded_amount": 5543531723,
    "price": [
      13.40471840996125,
      13.40471840996125,
      12.914473895,
      13.162536261742172
    ],
    "volume": 73317948879
  },
  {
    "ts": 1550102400,
    "supply": 11257339931623,
    "trustlines": {
      "total": 1486,
      "authorized": 1486,
      "funded": 155
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 2354652822,
    "price": [
      13.162536261742172,
      13.389999994284794,
      13.162536261742172,
      13.27356688
    ],
    "volume": 31159782354
  },
  {
    "ts": 1550188800,
    "supply": 11329839931623,
    "trustlines": {
      "total": 1490,
      "authorized": 1490,
      "funded": 158
    },
    "payments": 5,
    "payments_amount": 72634110647,
    "trades": 58,
    "traded_amount": 70871378320,
    "price": [
      13.27356688,
      13.27356688,
      12.419475227440712,
      12.815261986742463
    ],
    "volume": 889851898828
  },
  {
    "ts": 1550275200,
    "supply": 11329839931623,
    "trustlines": {
      "total": 1492,
      "authorized": 1492,
      "funded": 158
    },
    "payments": 2,
    "payments_amount": 175965218,
    "trades": 6,
    "traded_amount": 23061408130,
    "price": [
      12.815261986742463,
      12.815261986742463,
      12.551823340539173,
      12.69270702
    ],
    "volume": 292434629685
  },
  {
    "ts": 1550361600,
    "supply": 11329839931623,
    "trustlines": {
      "total": 1495,
      "authorized": 1495,
      "funded": 159
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 3067657000,
    "price": [
      12.69270702,
      13.03303690011303,
      12.69270702,
      13.03303690011303
    ],
    "volume": 39929649379
  },
  {
    "ts": 1550448000,
    "supply": 11329839931623,
    "trustlines": {
      "total": 1495,
      "authorized": 1495,
      "funded": 157
    },
    "payments": 4,
    "payments_amount": 10038500000,
    "trades": 8,
    "traded_amount": 13428834485,
    "price": [
      13.03303690011303,
      13.03303690011303,
      12.021701499973705,
      12.133443699987371
    ],
    "volume": 162028717904
  },
  {
    "ts": 1550534400,
    "supply": 11364839931623,
    "trustlines": {
      "total": 1498,
      "authorized": 1498,
      "funded": 160
    },
    "payments": 12,
    "payments_amount": 386194895387,
    "trades": 47,
    "traded_amount": 166973985237,
    "price": [
      12.133443699987371,
      12.133443699987371,
      10.845846405473976,
      11.141055793206023
    ],
    "volume": 1868756905721
  },
  {
    "ts": 1550620800,
    "supply": 11434839931623,
    "trustlines": {
      "total": 1501,
      "authorized": 1501,
      "funded": 160
    },
    "payments": 15,
    "payments_amount": 84509230735,
    "trades": 32,
    "traded_amount": 86429221225,
    "price": [
      11.141055793206023,
      11.523739002259294,
      11.029407709594597,
      11.029407709594597
    ],
    "volume": 967060225306
  },
  {
    "ts": 1550707200,
    "supply": 11479389931623,
    "trustlines": {
      "total": 1507,
      "authorized": 1507,
      "funded": 163
    },
    "payments": 11,
    "payments_amount": 44803720234,
    "trades": 31,
    "traded_amount": 77764004769,
    "price": [
      11.029407709594597,
      11.505515,
      10.729210812876874,
      11.505515
    ],
    "volume": 852562490201
  },
  {
    "ts": 1550793600,
    "supply": 11480639931623,
    "trustlines": {
      "total": 1507,
      "authorized": 1507,
      "funded": 162
    },
    "payments": 1,
    "payments_amount": 1250000000,
    "trades": 11,
    "traded_amount": 28536667437,
    "price": [
      11.505515,
      11.505515,
      11.022636085,
      11.022636085
    ],
    "volume": 318516995860
  },
  {
    "ts": 1550880000,
    "supply": 11535639931623,
    "trustlines": {
      "total": 1515,
      "authorized": 1515,
      "funded": 163
    },
    "payments": 2,
    "payments_amount": 55000000000,
    "trades": 28,
    "traded_amount": 45517942820,
    "price": [
      11.022636085,
      11.213500654741903,
      10.30174851,
      10.622635125748504
    ],
    "volume": 482337336817
  },
  {
    "ts": 1550966400,
    "supply": 11535639931623,
    "trustlines": {
      "total": 1528,
      "authorized": 1528,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 24,
    "traded_amount": 16902249520,
    "price": [
      10.622635125748504,
      12.309872480799983,
      10.344353172143649,
      11.914917952
    ],
    "volume": 194152821956
  },
  {
    "ts": 1551052800,
    "supply": 11535639931623,
    "trustlines": {
      "total": 1530,
      "authorized": 1530,
      "funded": 163
    },
    "payments": 3,
    "payments_amount": 121500000000,
    "trades": 25,
    "traded_amount": 100737048995,
    "price": [
      11.914917952,
      12.08968520025598,
      11.4144739,
      11.6418907825
    ],
    "volume": 1175522854994
  },
  {
    "ts": 1551139200,
    "supply": 11582639931623,
    "trustlines": {
      "total": 1535,
      "authorized": 1535,
      "funded": 165
    },
    "payments": 14,
    "payments_amount": 11098552861895,
    "trades": 13,
    "traded_amount": 31567912382,
    "price": [
      11.6418907825,
      11.9864854,
      11.581951902871818,
      11.8574731724
    ],
    "volume": 371502637989
  },
  {
    "ts": 1551225600,
    "supply": 11792139931623,
    "trustlines": {
      "total": 1555,
      "authorized": 1555,
      "funded": 167
    },
    "payments": 13,
    "payments_amount": 210093824766,
    "trades": 33,
    "traded_amount": 43907908256,
    "price": [
      11.8574731724,
      12.232011909055814,
      11.518547675021377,
      12.05448542559295
    ],
    "volume": 520982162901
  },
  {
    "ts": 1551312000,
    "supply": 11792139931623,
    "trustlines": {
      "total": 1553,
      "authorized": 1553,
      "funded": 165
    },
    "payments": 2,
    "payments_amount": 181073,
    "trades": 10,
    "traded_amount": 15518011334,
    "price": [
      12.05448542559295,
      12.077396789586386,
      11.912115221064955,
      11.92632868246269
    ],
    "volume": 185344542765
  },
  {
    "ts": 1551398400,
    "supply": 11826139931623,
    "trustlines": {
      "total": 1586,
      "authorized": 1586,
      "funded": 165
    },
    "payments": 5,
    "payments_amount": 34000000000,
    "trades": 6,
    "traded_amount": 14977429997,
    "price": [
      11.92632868246269,
      11.92632868246269,
      11.660216623444445,
      11.736469317867435
    ],
    "volume": 175389506383
  },
  {
    "ts": 1551484800,
    "supply": 11826139931623,
    "trustlines": {
      "total": 1588,
      "authorized": 1588,
      "funded": 165
    },
    "payments": 2,
    "payments_amount": 6000000,
    "trades": 5,
    "traded_amount": 905220618,
    "price": [
      11.736469317867435,
      12.059987564771394,
      11.736469317867435,
      11.948158
    ],
    "volume": 10785883430
  },
  {
    "ts": 1551571200,
    "supply": 11826139931623,
    "trustlines": {
      "total": 1590,
      "authorized": 1590,
      "funded": 172
    },
    "payments": 28,
    "payments_amount": 22994598699,
    "trades": 48,
    "traded_amount": 62137110770,
    "price": [
      11.948158,
      11.981279813862457,
      11.243128964059197,
      11.513168923275305
    ],
    "volume": 717198079749
  },
  {
    "ts": 1551657600,
    "supply": 11800799612262,
    "trustlines": {
      "total": 1589,
      "authorized": 1589,
      "funded": 171
    },
    "payments": 10,
    "payments_amount": 29868583147,
    "trades": 35,
    "traded_amount": 54889218114,
    "price": [
      11.513168923275305,
      12.330213903377215,
      11.513168923275305,
      12.205705184746602
    ],
    "volume": 661714719012
  },
  {
    "ts": 1551744000,
    "supply": 6291799612262,
    "trustlines": {
      "total": 1592,
      "authorized": 1592,
      "funded": 168
    },
    "payments": 8,
    "payments_amount": 5511397532413,
    "trades": 9,
    "traded_amount": 11684201807,
    "price": [
      12.205705184746602,
      12.339382288600508,
      11.73557404555135,
      11.809125003777158
    ],
    "volume": 135380752804
  },
  {
    "ts": 1551830400,
    "supply": 6371799612262,
    "trustlines": {
      "total": 1629,
      "authorized": 1629,
      "funded": 169
    },
    "payments": 13,
    "payments_amount": 692974681798,
    "trades": 32,
    "traded_amount": 261819766454,
    "price": [
      11.809125003777158,
      11.97012254483817,
      11.577383812239693,
      11.776
    ],
    "volume": 3065969331104
  },
  {
    "ts": 1551916800,
    "supply": 6555699612262,
    "trustlines": {
      "total": 1631,
      "authorized": 1631,
      "funded": 173
    },
    "payments": 7,
    "payments_amount": 184001886868,
    "trades": 11,
    "traded_amount": 10619362521,
    "price": [
      11.776,
      11.987487218338941,
      11.697260852,
      11.757246647675407
    ],
    "volume": 124592659666
  },
  {
    "ts": 1552003200,
    "supply": 6605699612262,
    "trustlines": {
      "total": 1633,
      "authorized": 1633,
      "funded": 172
    },
    "payments": 8,
    "payments_amount": 54374002480,
    "trades": 34,
    "traded_amount": 47523085213,
    "price": [
      11.757246647675407,
      11.757246647675407,
      11.269514563106796,
      11.657000894634617
    ],
    "volume": 545713564214
  },
  {
    "ts": 1552089600,
    "supply": 6720699612262,
    "trustlines": {
      "total": 1633,
      "authorized": 1633,
      "funded": 170
    },
    "payments": 21,
    "payments_amount": 118610436618,
    "trades": 55,
    "traded_amount": 42597024815,
    "price": [
      11.657000894634617,
      11.657000894634617,
      11.013360804307641,
      11.174183167123175
    ],
    "volume": 473085666815
  },
  {
    "ts": 1552176000,
    "supply": 6720699612262,
    "trustlines": {
      "total": 1634,
      "authorized": 1634,
      "funded": 174
    },
    "payments": 127,
    "payments_amount": 16962254658,
    "trades": 267,
    "traded_amount": 97856057461,
    "price": [
      11.174183167123175,
      11.174183167123175,
      9.7,
      9.89273580900326
    ],
    "volume": 993592808874
  },
  {
    "ts": 1552262400,
    "supply": 6745699612262,
    "trustlines": {
      "total": 1636,
      "authorized": 1636,
      "funded": 177
    },
    "payments": 38,
    "payments_amount": 131670802420,
    "trades": 53,
    "traded_amount": 55724610530,
    "price": [
      9.89273580900326,
      10.0748155,
      9.232099690268674,
      9.743986498666667
    ],
    "volume": 529671825692
  },
  {
    "ts": 1552348800,
    "supply": 6761699612262,
    "trustlines": {
      "total": 1644,
      "authorized": 1644,
      "funded": 172
    },
    "payments": 34,
    "payments_amount": 36903176451,
    "trades": 56,
    "traded_amount": 144096093325,
    "price": [
      9.743986498666667,
      10.3942506,
      9.366909271064271,
      9.447277800173916
    ],
    "volume": 1404685724219
  },
  {
    "ts": 1552435200,
    "supply": 6887749612262,
    "trustlines": {
      "total": 1647,
      "authorized": 1647,
      "funded": 172
    },
    "payments": 18,
    "payments_amount": 158152204581,
    "trades": 46,
    "traded_amount": 285443501465,
    "price": [
      9.447277800173916,
      9.828454161072639,
      8.985234564031115,
      9.142873861005386
    ],
    "volume": 2608863190833
  },
  {
    "ts": 1552521600,
    "supply": 6923249612262,
    "trustlines": {
      "total": 1654,
      "authorized": 1654,
      "funded": 173
    },
    "payments": 9,
    "payments_amount": 36061111923,
    "trades": 31,
    "traded_amount": 28243748267,
    "price": [
      9.142873861005386,
      9.478366174213413,
      8.957579564267935,
      9.37999999725212
    ],
    "volume": 261419329900
  },
  {
    "ts": 1552608000,
    "supply": 6953249612262,
    "trustlines": {
      "total": 1662,
      "authorized": 1662,
      "funded": 174
    },
    "payments": 8,
    "payments_amount": 71848841838,
    "trades": 14,
    "traded_amount": 17165126317,
    "price": [
      9.37999999725212,
      9.561088664158829,
      9.348274400528577,
      9.471519679415465
    ],
    "volume": 162131577501
  },
  {
    "ts": 1552694400,
    "supply": 6970199612262,
    "trustlines": {
      "total": 1663,
      "authorized": 1663,
      "funded": 174
    },
    "payments": 16,
    "payments_amount": 20512152974,
    "trades": 22,
    "traded_amount": 6182414297,
    "price": [
      9.471519679415465,
      9.471519679415465,
      9.16964297973038,
      9.256414889772316
    ],
    "volume": 57304969279
  },
  {
    "ts": 1552780800,
    "supply": 6970199612262,
    "trustlines": {
      "total": 1674,
      "authorized": 1674,
      "funded": 177
    },
    "payments": 11,
    "payments_amount": 97217333285,
    "trades": 15,
    "traded_amount": 11531117796,
    "price": [
      9.256414889772316,
      9.426257941669967,
      9.214347845,
      9.214347845
    ],
    "volume": 107049546410
  },
  {
    "ts": 1552867200,
    "supply": 6980199612262,
    "trustlines": {
      "total": 1687,
      "authorized": 1687,
      "funded": 177
    },
    "payments": 30,
    "payments_amount": 40760257020,
    "trades": 51,
    "traded_amount": 140511291568,
    "price": [
      9.214347845,
      9.43317913226415,
      8.419691290743042,
      8.655062688203323
    ],
    "volume": 1272925574704
  },
  {
    "ts": 1552953600,
    "supply": 7136999612262,
    "trustlines": {
      "total": 1705,
      "authorized": 1705,
      "funded": 177
    },
    "payments": 20,
    "payments_amount": 160680790309,
    "trades": 22,
    "traded_amount": 37561826276,
    "price": [
      8.655062688203323,
      8.995650000161364,
      8.514739866,
      8.995650000161364
    ],
    "volume": 331759295370
  },
  {
    "ts": 1553040000,
    "supply": 7176999612262,
    "trustlines": {
      "total": 1719,
      "authorized": 1719,
      "funded": 177
    },
    "payments": 8,
    "payments_amount": 80010000008,
    "trades": 6,
    "traded_amount": 10508486033,
    "price": [
      8.995650000161364,
      9.1933815,
      8.952230895826208,
      9.137886132667495
    ],
    "volume": 95253918832
  },
  {
    "ts": 1553126400,
    "supply": 7176999612262,
    "trustlines": {
      "total": 1720,
      "authorized": 1720,
      "funded": 178
    },
    "payments": 7,
    "payments_amount": 1193239805,
    "trades": 29,
    "traded_amount": 71382798225,
    "price": [
      9.137886132667495,
      9.904343838257418,
      9.137886132667495,
      9.567509665552008
    ],
    "volume": 677095438144
  },
  {
    "ts": 1553212800,
    "supply": 7328149612262,
    "trustlines": {
      "total": 1733,
      "authorized": 1733,
      "funded": 180
    },
    "payments": 9,
    "payments_amount": 152133539886,
    "trades": 13,
    "traded_amount": 27410043020,
    "price": [
      9.567509665552008,
      9.567509665552008,
      9.141870863829046,
      9.2837031
    ],
    "volume": 253414471291
  },
  {
    "ts": 1553299200,
    "supply": 7328149612262,
    "trustlines": {
      "total": 1738,
      "authorized": 1738,
      "funded": 178
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 17798254366,
    "price": [
      9.2837031,
      9.41120667368421,
      9.230959530550322,
      9.4055047
    ],
    "volume": 164565524376
  },
  {
    "ts": 1553385600,
    "supply": 7328149612262,
    "trustlines": {
      "total": 1748,
      "authorized": 1748,
      "funded": 178
    },
    "payments": 13,
    "payments_amount": 4968070262,
    "trades": 16,
    "traded_amount": 6487322283,
    "price": [
      9.4055047,
      9.592463600142402,
      9.4055047,
      9.527057716167706
    ],
    "volume": 61845953558
  },
  {
    "ts": 1553472000,
    "supply": 7328149612262,
    "trustlines": {
      "total": 1751,
      "authorized": 1751,
      "funded": 178
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 52514082,
    "price": [
      9.527057716167706,
      9.79853236574746,
      9.527057716167706,
      9.79853236574746
    ],
    "volume": 513964901
  },
  {
    "ts": 1553558400,
    "supply": 7343149612262,
    "trustlines": {
      "total": 1762,
      "authorized": 1762,
      "funded": 181
    },
    "payments": 13,
    "payments_amount": 15172035538,
    "trades": 27,
    "traded_amount": 69368931811,
    "price": [
      9.79853236574746,
      10.228303100091052,
      9.69079251831855,
      9.8250284
    ],
    "volume": 700968379842
  },
  {
    "ts": 1553644800,
    "supply": 7343149612262,
    "trustlines": {
      "total": 1777,
      "authorized": 1777,
      "funded": 183
    },
    "payments": 10,
    "payments_amount": 3497755561,
    "trades": 24,
    "traded_amount": 153049786672,
    "price": [
      9.8250284,
      9.874551900156355,
      9.264276267960867,
      9.264276267960867
    ],
    "volume": 1497632658998
  },
  {
    "ts": 1553731200,
    "supply": 7357999612262,
    "trustlines": {
      "total": 1785,
      "authorized": 1785,
      "funded": 184
    },
    "payments": 82,
    "payments_amount": 15264686228,
    "trades": 91,
    "traded_amount": 47450634316,
    "price": [
      9.264276267960867,
      9.690139429439323,
      9.264276267960867,
      9.469068568319969
    ],
    "volume": 453948710010
  },
  {
    "ts": 1553817600,
    "supply": 7363999612262,
    "trustlines": {
      "total": 1795,
      "authorized": 1795,
      "funded": 187
    },
    "payments": 39,
    "payments_amount": 6025517632,
    "trades": 48,
    "traded_amount": 81050160636,
    "price": [
      9.469068568319969,
      9.539874199988992,
      9.277126317333334,
      9.398461
    ],
    "volume": 757825779197
  },
  {
    "ts": 1553904000,
    "supply": 7373199612262,
    "trustlines": {
      "total": 1806,
      "authorized": 1806,
      "funded": 190
    },
    "payments": 5,
    "payments_amount": 14200000101,
    "trades": 21,
    "traded_amount": 13310287595,
    "price": [
      9.398461,
      9.475676172508502,
      9.3114291274,
      9.342301699634453
    ],
    "volume": 124831902779
  },
  {
    "ts": 1553990400,
    "supply": 7374449612262,
    "trustlines": {
      "total": 1815,
      "authorized": 1815,
      "funded": 189
    },
    "payments": 1,
    "payments_amount": 1250000000,
    "trades": 5,
    "traded_amount": 33080930772,
    "price": [
      9.342301699634453,
      9.414316539193475,
      9.299000544696806,
      9.299000544696806
    ],
    "volume": 309517546578
  },
  {
    "ts": 1554076800,
    "supply": 7374449612262,
    "trustlines": {
      "total": 1829,
      "authorized": 1829,
      "funded": 191
    },
    "payments": 4,
    "payments_amount": 1799060279,
    "trades": 29,
    "traded_amount": 207206011060,
    "price": [
      9.299000544696806,
      9.336451699985133,
      9.011477017129016,
      9.052428042202639
    ],
    "volume": 1894111491011
  },
  {
    "ts": 1554163200,
    "supply": 7379449612262,
    "trustlines": {
      "total": 1841,
      "authorized": 1841,
      "funded": 198
    },
    "payments": 64,
    "payments_amount": 33191269801,
    "trades": 130,
    "traded_amount": 453477259687,
    "price": [
      9.052428042202639,
      9.0818223,
      8.183993100317513,
      8.190536001747644
    ],
    "volume": 3916560972106
  },
  {
    "ts": 1554249600,
    "supply": 7379449612262,
    "trustlines": {
      "total": 1855,
      "authorized": 1855,
      "funded": 203
    },
    "payments": 98,
    "payments_amount": 37177132262,
    "trades": 246,
    "traded_amount": 314684704453,
    "price": [
      8.190536001747644,
      8.286187100044948,
      7.452411100181993,
      8.286187100044948
    ],
    "volume": 2482289718204
  },
  {
    "ts": 1554336000,
    "supply": 7460929612262,
    "trustlines": {
      "total": 1863,
      "authorized": 1863,
      "funded": 204
    },
    "payments": 28,
    "payments_amount": 82366307646,
    "trades": 46,
    "traded_amount": 152231136653,
    "price": [
      8.286187100044948,
      8.6852338,
      8.048013204398194,
      8.576725
    ],
    "volume": 1264162544593
  },
  {
    "ts": 1554422400,
    "supply": 7466379612262,
    "trustlines": {
      "total": 1871,
      "authorized": 1871,
      "funded": 205
    },
    "payments": 21,
    "payments_amount": 20262262206,
    "trades": 44,
    "traded_amount": 166508318242,
    "price": [
      8.576725,
      8.576725,
      7.703580900105058,
      7.863839700108263
    ],
    "volume": 1331918220531
  },
  {
    "ts": 1554508800,
    "supply": 7466379612262,
    "trustlines": {
      "total": 1881,
      "authorized": 1881,
      "funded": 203
    },
    "payments": 27,
    "payments_amount": 6604983477,
    "trades": 38,
    "traded_amount": 26235049897,
    "price": [
      7.863839700108263,
      8.142374269807105,
      7.7701317715959,
      8.083333333333334
    ],
    "volume": 209944933366
  },
  {
    "ts": 1554595200,
    "supply": 7466379612262,
    "trustlines": {
      "total": 1884,
      "authorized": 1884,
      "funded": 208
    },
    "payments": 102,
    "payments_amount": 25198876239,
    "trades": 189,
    "traded_amount": 28331250362,
    "price": [
      8.083333333333334,
      8.132814400000894,
      7.48718013615508,
      7.585087939215711
    ],
    "volume": 221396400906
  },
  {
    "ts": 1554681600,
    "supply": 7465609332824,
    "trustlines": {
      "total": 1894,
      "authorized": 1894,
      "funded": 206
    },
    "payments": 84,
    "payments_amount": 13073296534,
    "trades": 179,
    "traded_amount": 197322874292,
    "price": [
      7.585087939215711,
      7.955417838433419,
      7.3893712,
      7.634254927564544
    ],
    "volume": 1504989921905
  },
  {
    "ts": 1554768000,
    "supply": 7471658232824,
    "trustlines": {
      "total": 1902,
      "authorized": 1902,
      "funded": 207
    },
    "payments": 74,
    "payments_amount": 10346635521,
    "trades": 98,
    "traded_amount": 138130030998,
    "price": [
      7.634254927564544,
      8.001158639221636,
      7.634254927564544,
      8
    ],
    "volume": 1094312858969
  },
  {
    "ts": 1554854400,
    "supply": 7471658232824,
    "trustlines": {
      "total": 1920,
      "authorized": 1920,
      "funded": 210
    },
    "payments": 32,
    "payments_amount": 6445607713,
    "trades": 57,
    "traded_amount": 78995115596,
    "price": [
      8,
      8,
      7.728323699421965,
      7.9973409
    ],
    "volume": 626164245550
  },
  {
    "ts": 1554940800,
    "supply": 7484158232824,
    "trustlines": {
      "total": 1931,
      "authorized": 1931,
      "funded": 202
    },
    "payments": 15,
    "payments_amount": 14290262891,
    "trades": 44,
    "traded_amount": 80239134104,
    "price": [
      7.9973409,
      8.91333120966691,
      7.9894714089104495,
      8.697449428320141
    ],
    "volume": 661773218629
  },
  {
    "ts": 1555027200,
    "supply": 7484158232824,
    "trustlines": {
      "total": 1941,
      "authorized": 1941,
      "funded": 204
    },
    "payments": 1,
    "payments_amount": 4493930,
    "trades": 15,
    "traded_amount": 16810717434,
    "price": [
      8.697449428320141,
      9,
      8.579572890543467,
      8.74089198680318
    ],
    "volume": 150552669708
  },
  {
    "ts": 1555113600,
    "supply": 7581498232824,
    "trustlines": {
      "total": 1946,
      "authorized": 1946,
      "funded": 204
    },
    "payments": 2,
    "payments_amount": 194680000000,
    "trades": 4,
    "traded_amount": 1279406770,
    "price": [
      8.74089198680318,
      8.758837673043201,
      8.71591297304127,
      8.726944
    ],
    "volume": 11184138846
  },
  {
    "ts": 1555200000,
    "supply": 7581498232824,
    "trustlines": {
      "total": 1953,
      "authorized": 1953,
      "funded": 206
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 1170742960,
    "price": [
      8.726944,
      8.726944,
      8.532394087234042,
      8.532394087234042
    ],
    "volume": 10016233130
  },
  {
    "ts": 1555286400,
    "supply": 7580339732824,
    "trustlines": {
      "total": 1960,
      "authorized": 1960,
      "funded": 207
    },
    "payments": 3,
    "payments_amount": 16158500000,
    "trades": 32,
    "traded_amount": 62640870458,
    "price": [
      8.532394087234042,
      9,
      8.422825856,
      8.635891807134884
    ],
    "volume": 533371587676
  },
  {
    "ts": 1555372800,
    "supply": 7580339732824,
    "trustlines": {
      "total": 1964,
      "authorized": 1964,
      "funded": 209
    },
    "payments": 1,
    "payments_amount": 7963198,
    "trades": 10,
    "traded_amount": 2955918825,
    "price": [
      8.635891807134884,
      8.92695940101101,
      8.635891807134884,
      8.788553920171267
    ],
    "volume": 26040978937
  },
  {
    "ts": 1555459200,
    "supply": 7610339732824,
    "trustlines": {
      "total": 1968,
      "authorized": 1968,
      "funded": 208
    },
    "payments": 2,
    "payments_amount": 30000000000,
    "trades": 14,
    "traded_amount": 426425473,
    "price": [
      8.788553920171267,
      8.788553920171267,
      8.590880768217064,
      8.593908629441625
    ],
    "volume": 1874704122
  },
  {
    "ts": 1555545600,
    "supply": 7635339732824,
    "trustlines": {
      "total": 1979,
      "authorized": 1979,
      "funded": 214
    },
    "payments": 1,
    "payments_amount": 25000000000,
    "trades": 16,
    "traded_amount": 4826074061,
    "price": [
      8.593908629441625,
      8.6247,
      8.40129984874014,
      8.437630683189353
    ],
    "volume": 40754781052
  },
  {
    "ts": 1555632000,
    "supply": 7635339732824,
    "trustlines": {
      "total": 1992,
      "authorized": 1992,
      "funded": 213
    },
    "payments": 14,
    "payments_amount": 11816280016,
    "trades": 35,
    "traded_amount": 191228410000,
    "price": [
      8.437630683189353,
      8.697542940472358,
      8.437629399768777,
      8.679570500172657
    ],
    "volume": 1632779333509
  },
  {
    "ts": 1555718400,
    "supply": 7733487632824,
    "trustlines": {
      "total": 2011,
      "authorized": 2011,
      "funded": 215
    },
    "payments": 5,
    "payments_amount": 104847900000,
    "trades": 11,
    "traded_amount": 4932086137,
    "price": [
      8.679570500172657,
      8.8067395,
      8.560529792868204,
      8.8067395
    ],
    "volume": 42581233555
  },
  {
    "ts": 1555804800,
    "supply": 7733487632824,
    "trustlines": {
      "total": 2022,
      "authorized": 2022,
      "funded": 212
    },
    "payments": 1,
    "payments_amount": 98147900000,
    "trades": 22,
    "traded_amount": 33969400124,
    "price": [
      8.8067395,
      9.152273,
      8.62210189564624,
      8.983135450050565
    ],
    "volume": 306467491542
  },
  {
    "ts": 1555891200,
    "supply": 7730187632824,
    "trustlines": {
      "total": 2033,
      "authorized": 2033,
      "funded": 211
    },
    "payments": 2,
    "payments_amount": 3300000000,
    "trades": 11,
    "traded_amount": 65012297077,
    "price": [
      8.983135450050565,
      9.126179200037079,
      8.788226588333334,
      8.788226588333334
    ],
    "volume": 587544615509
  },
  {
    "ts": 1555977600,
    "supply": 7730187632824,
    "trustlines": {
      "total": 2042,
      "authorized": 2042,
      "funded": 210
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 53073799444,
    "price": [
      8.788226588333334,
      9.122231516306854,
      8.788226588333334,
      9.122231516306854
    ],
    "volume": 479562867988
  },
  {
    "ts": 1556064000,
    "supply": 7730187632823,
    "trustlines": {
      "total": 2044,
      "authorized": 2044,
      "funded": 206
    },
    "payments": 22,
    "payments_amount": 30631445029,
    "trades": 95,
    "traded_amount": 602559408407,
    "price": [
      9.122231516306854,
      10.093785601412678,
      9.122231516306854,
      9.73686132
    ],
    "volume": 5798235962179
  },
  {
    "ts": 1556150400,
    "supply": 7730187632823,
    "trustlines": {
      "total": 2047,
      "authorized": 2047,
      "funded": 206
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 49,
    "traded_amount": 48068770439,
    "price": [
      9.73686132,
      10.59462322874189,
      9.522576119821071,
      10.370732962017474
    ],
    "volume": 475472267175
  },
  {
    "ts": 1556236800,
    "supply": 7632187632823,
    "trustlines": {
      "total": 2054,
      "authorized": 2054,
      "funded": 206
    },
    "payments": 8,
    "payments_amount": 294000500000,
    "trades": 63,
    "traded_amount": 140019145011,
    "price": [
      10.370732962017474,
      10.495669500066201,
      10.0875701,
      10.495669500066201
    ],
    "volume": 1442500868518
  },
  {
    "ts": 1556323200,
    "supply": 7637536832823,
    "trustlines": {
      "total": 2061,
      "authorized": 2061,
      "funded": 212
    },
    "payments": 4,
    "payments_amount": 5349228163,
    "trades": 20,
    "traded_amount": 88558021979,
    "price": [
      10.495669500066201,
      10.495669500066201,
      10.171418799863595,
      10.2228208
    ],
    "volume": 912662800350
  },
  {
    "ts": 1556409600,
    "supply": 7637536832823,
    "trustlines": {
      "total": 2067,
      "authorized": 2067,
      "funded": 210
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 1070080038,
    "price": [
      10.2228208,
      10.465921911663433,
      10.156769733485673,
      10.465921911663433
    ],
    "volume": 11056914867
  },
  {
    "ts": 1556496000,
    "supply": 7637836832823,
    "trustlines": {
      "total": 2075,
      "authorized": 2075,
      "funded": 213
    },
    "payments": 3,
    "payments_amount": 300739640,
    "trades": 37,
    "traded_amount": 74716227986,
    "price": [
      10.465921911663433,
      10.750034903116573,
      10.283643299456195,
      10.679835615970198
    ],
    "volume": 782707989208
  },
  {
    "ts": 1556582400,
    "supply": 7638836832823,
    "trustlines": {
      "total": 2088,
      "authorized": 2088,
      "funded": 218
    },
    "payments": 3,
    "payments_amount": 1001000000,
    "trades": 28,
    "traded_amount": 85405803027,
    "price": [
      10.679835615970198,
      10.679835615970198,
      10.0068,
      10.0068
    ],
    "volume": 893468311058
  },
  {
    "ts": 1556668800,
    "supply": 7638836832823,
    "trustlines": {
      "total": 2092,
      "authorized": 2092,
      "funded": 218
    },
    "payments": 5,
    "payments_amount": 8184949555,
    "trades": 17,
    "traded_amount": 15623103932,
    "price": [
      10.0068,
      10.0931755,
      9.81597018,
      10.06854666371719
    ],
    "volume": 155536062911
  },
  {
    "ts": 1556755200,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2106,
      "authorized": 2106,
      "funded": 222
    },
    "payments": 3,
    "payments_amount": 15200000000,
    "trades": 19,
    "traded_amount": 73052875037,
    "price": [
      10.06854666371719,
      10.2610316,
      9.996081534960695,
      10.21838737497803
    ],
    "volume": 739250877513
  },
  {
    "ts": 1556841600,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2118,
      "authorized": 2118,
      "funded": 219
    },
    "payments": 5,
    "payments_amount": 386704987,
    "trades": 42,
    "traded_amount": 57901577574,
    "price": [
      10.21838737497803,
      10.2581724,
      9.839672376780593,
      9.957431978203491
    ],
    "volume": 576283672436
  },
  {
    "ts": 1556928000,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2129,
      "authorized": 2129,
      "funded": 220
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 13816746804,
    "price": [
      9.957431978203491,
      10.4,
      9.774215646119014,
      10.197557215944492
    ],
    "volume": 136160279490
  },
  {
    "ts": 1557014400,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2140,
      "authorized": 2140,
      "funded": 221
    },
    "payments": 2,
    "payments_amount": 1636,
    "trades": 24,
    "traded_amount": 37365769588,
    "price": [
      10.197557215944492,
      10.425625,
      10.139530070833333,
      10.404393409885172
    ],
    "volume": 386757112815
  },
  {
    "ts": 1557100800,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2145,
      "authorized": 2145,
      "funded": 218
    },
    "payments": 1,
    "payments_amount": 35641412,
    "trades": 33,
    "traded_amount": 42172190496,
    "price": [
      10.404393409885172,
      10.716306100059082,
      10.275606170913147,
      10.281232840596875
    ],
    "volume": 447968583028
  },
  {
    "ts": 1557187200,
    "supply": 7654036832823,
    "trustlines": {
      "total": 2154,
      "authorized": 2154,
      "funded": 219
    },
    "payments": 1,
    "payments_amount": 1041,
    "trades": 20,
    "traded_amount": 19940692501,
    "price": [
      10.281232840596875,
      10.776905600053398,
      10.16944326355254,
      10.77690559931269
    ],
    "volume": 210934695180
  },
  {
    "ts": 1557273600,
    "supply": 7674036832823,
    "trustlines": {
      "total": 2161,
      "authorized": 2161,
      "funded": 222
    },
    "payments": 1,
    "payments_amount": 20000000000,
    "trades": 16,
    "traded_amount": 50961952637,
    "price": [
      10.77690559931269,
      11.018195400569587,
      10.77690559931269,
      10.951612199853214
    ],
    "volume": 526521255427
  },
  {
    "ts": 1557360000,
    "supply": 7684036832823,
    "trustlines": {
      "total": 2181,
      "authorized": 2181,
      "funded": 225
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 29,
    "traded_amount": 112998512641,
    "price": [
      10.951612199853214,
      11.615866500374441,
      10.951612199853214,
      11.426912893329407
    ],
    "volume": 1282757592691
  },
  {
    "ts": 1557446400,
    "supply": 7696536832823,
    "trustlines": {
      "total": 2189,
      "authorized": 2189,
      "funded": 229
    },
    "payments": 5,
    "payments_amount": 12500461476,
    "trades": 29,
    "traded_amount": 141385194257,
    "price": [
      11.426912893329407,
      11.426912893329407,
      10.791013675508072,
      10.875582000279449
    ],
    "volume": 1533259702679
  },
  {
    "ts": 1557532800,
    "supply": 7696536832823,
    "trustlines": {
      "total": 2198,
      "authorized": 2198,
      "funded": 227
    },
    "payments": 51,
    "payments_amount": 4685052656,
    "trades": 203,
    "traded_amount": 444350236660,
    "price": [
      10.875582000279449,
      10.875582000279449,
      9.302569765811247,
      9.518894936018976
    ],
    "volume": 4473758994064
  },
  {
    "ts": 1557619200,
    "supply": 7696536832823,
    "trustlines": {
      "total": 2210,
      "authorized": 2210,
      "funded": 234
    },
    "payments": 44,
    "payments_amount": 18135522272,
    "trades": 187,
    "traded_amount": 413415494833,
    "price": [
      9.518894936018976,
      10.455544200155112,
      9.518894936018976,
      10.18092787189935
    ],
    "volume": 4100558664839
  },
  {
    "ts": 1557705600,
    "supply": 7688494383463,
    "trustlines": {
      "total": 2221,
      "authorized": 2221,
      "funded": 230
    },
    "payments": 106,
    "payments_amount": 15188232384,
    "trades": 156,
    "traded_amount": 128654436833,
    "price": [
      10.18092787189935,
      10.18092787189935,
      9.661994430926784,
      10.06065837450448
    ],
    "volume": 1248229601869
  },
  {
    "ts": 1557792000,
    "supply": 7700744383463,
    "trustlines": {
      "total": 2242,
      "authorized": 2242,
      "funded": 236
    },
    "payments": 75,
    "payments_amount": 46401115176,
    "trades": 147,
    "traded_amount": 410374074885,
    "price": [
      10.06065837450448,
      10.066709502381281,
      8.591788655745374,
      8.799999997858484
    ],
    "volume": 3721979179276
  },
  {
    "ts": 1557878400,
    "supply": 7710744383463,
    "trustlines": {
      "total": 2266,
      "authorized": 2266,
      "funded": 247
    },
    "payments": 58,
    "payments_amount": 32407743645,
    "trades": 142,
    "traded_amount": 522010156161,
    "price": [
      8.799999997858484,
      8.800000000030161,
      7.082974000209553,
      7.085555241814776
    ],
    "volume": 4112051687399
  },
  {
    "ts": 1557964800,
    "supply": 7654494383463,
    "trustlines": {
      "total": 2300,
      "authorized": 2300,
      "funded": 257
    },
    "payments": 195,
    "payments_amount": 258723764201,
    "trades": 479,
    "traded_amount": 2445413794905,
    "price": [
      7.085555241814776,
      7.61001416226506,
      6.15327930008906,
      7.225032422336913
    ],
    "volume": 16761713730362
  },
  {
    "ts": 1558051200,
    "supply": 7657938981201,
    "trustlines": {
      "total": 2320,
      "authorized": 2320,
      "funded": 261
    },
    "payments": 101,
    "payments_amount": 89344264019,
    "trades": 304,
    "traded_amount": 1260893356834,
    "price": [
      7.225032422336913,
      8.621670741843037,
      7.100729528861613,
      7.636689098168111
    ],
    "volume": 10088126806811
  },
  {
    "ts": 1558137600,
    "supply": 7759638981201,
    "trustlines": {
      "total": 2335,
      "authorized": 2335,
      "funded": 263
    },
    "payments": 67,
    "payments_amount": 124679062475,
    "trades": 132,
    "traded_amount": 425725977280,
    "price": [
      7.636689098168111,
      7.709619727929483,
      7.086132650771264,
      7.615531403897215
    ],
    "volume": 3167165909421
  },
  {
    "ts": 1558224000,
    "supply": 7759638981201,
    "trustlines": {
      "total": 2352,
      "authorized": 2352,
      "funded": 267
    },
    "payments": 72,
    "payments_amount": 4414879471,
    "trades": 188,
    "traded_amount": 199931432888,
    "price": [
      7.615531403897215,
      7.662981825960906,
      6.896533153820262,
      6.974906110013424
    ],
    "volume": 1394201141871
  },
  {
    "ts": 1558310400,
    "supply": 7859638981201,
    "trustlines": {
      "total": 2363,
      "authorized": 2363,
      "funded": 266
    },
    "payments": 77,
    "payments_amount": 103639468229,
    "trades": 229,
    "traded_amount": 336803645793,
    "price": [
      6.974906110013424,
      7.727663869022408,
      6.974906110013424,
      7.369164200084113
    ],
    "volume": 2065077572590
  },
  {
    "ts": 1558396800,
    "supply": 8009638981201,
    "trustlines": {
      "total": 2376,
      "authorized": 2376,
      "funded": 269
    },
    "payments": 55,
    "payments_amount": 155399252880,
    "trades": 123,
    "traded_amount": 36771676596,
    "price": [
      7.369164200084113,
      7.557510767563466,
      7.328881999920512,
      7.368387700225589
    ],
    "volume": 134276168686
  },
  {
    "ts": 1558483200,
    "supply": 8046288981201,
    "trustlines": {
      "total": 2384,
      "authorized": 2384,
      "funded": 265
    },
    "payments": 25,
    "payments_amount": 37283633636,
    "trades": 160,
    "traded_amount": 265412146998,
    "price": [
      7.368387700225589,
      8.175672303722392,
      7.2763784371793845,
      8.13730564037759
    ],
    "volume": 2067076156257
  },
  {
    "ts": 1558569600,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2393,
      "authorized": 2393,
      "funded": 266
    },
    "payments": 58,
    "payments_amount": 170447637765,
    "trades": 75,
    "traded_amount": 84722004208,
    "price": [
      8.13730564037759,
      8.41345744492092,
      7.940706003120104,
      8
    ],
    "volume": 661908680288
  },
  {
    "ts": 1558656000,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2401,
      "authorized": 2401,
      "funded": 265
    },
    "payments": 7,
    "payments_amount": 3857456783,
    "trades": 119,
    "traded_amount": 38562802715,
    "price": [
      8,
      8.245284932396888,
      7.725520410441506,
      7.993554106965294
    ],
    "volume": 286019646899
  },
  {
    "ts": 1558742400,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2407,
      "authorized": 2407,
      "funded": 265
    },
    "payments": 10,
    "payments_amount": 3674525773,
    "trades": 60,
    "traded_amount": 109507410015,
    "price": [
      7.993554106965294,
      8.01947135433374,
      7.800774346758546,
      8.01947135433374
    ],
    "volume": 815326512693
  },
  {
    "ts": 1558828800,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2420,
      "authorized": 2420,
      "funded": 266
    },
    "payments": 13,
    "payments_amount": 10000594505,
    "trades": 66,
    "traded_amount": 180557785595,
    "price": [
      8.01947135433374,
      8.165331653316533,
      7.418227028729759,
      7.46893480073234
    ],
    "volume": 1337472147555
  },
  {
    "ts": 1558915200,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2433,
      "authorized": 2433,
      "funded": 274
    },
    "payments": 15,
    "payments_amount": 9444963591,
    "trades": 2508,
    "traded_amount": 165717182111,
    "price": [
      7.46893480073234,
      7.533955293796511,
      6.980487300001,
      7.2037493610132906
    ],
    "volume": 1150305645089
  },
  {
    "ts": 1559001600,
    "supply": 8157367462287,
    "trustlines": {
      "total": 2452,
      "authorized": 2452,
      "funded": 280
    },
    "payments": 9,
    "payments_amount": 3307931388,
    "trades": 229,
    "traded_amount": 175307881486,
    "price": [
      7.2037493610132906,
      7.523361686279317,
      7.075546646858709,
      7.196216982059664
    ],
    "volume": 1247572930689
  },
  {
    "ts": 1559088000,
    "supply": 8227867462287,
    "trustlines": {
      "total": 2456,
      "authorized": 2456,
      "funded": 276
    },
    "payments": 30,
    "payments_amount": 91090302671,
    "trades": 4677,
    "traded_amount": 79918561204,
    "price": [
      7.196216982059664,
      7.625717507294222,
      7.181818181818182,
      7.347203749357051
    ],
    "volume": 587931298000
  },
  {
    "ts": 1559174400,
    "supply": 8243801562287,
    "trustlines": {
      "total": 2462,
      "authorized": 2462,
      "funded": 273
    },
    "payments": 42,
    "payments_amount": 19758044544,
    "trades": 12270,
    "traded_amount": 329159443171,
    "price": [
      7.347203749357051,
      8.008957215898892,
      6.953469468008478,
      7.787961835769454
    ],
    "volume": 2376846093918
  },
  {
    "ts": 1559260800,
    "supply": 8243801562287,
    "trustlines": {
      "total": 2477,
      "authorized": 2477,
      "funded": 270
    },
    "payments": 3,
    "payments_amount": 1289230882,
    "trades": 15111,
    "traded_amount": 149559150353,
    "price": [
      7.787961835769454,
      8.045402396802114,
      7.454464404092613,
      7.501094800063112
    ],
    "volume": 1126588047920
  },
  {
    "ts": 1559347200,
    "supply": 8243801562287,
    "trustlines": {
      "total": 2489,
      "authorized": 2489,
      "funded": 274
    },
    "payments": 6,
    "payments_amount": 53738158725,
    "trades": 41,
    "traded_amount": 154842107061,
    "price": [
      7.501094800063112,
      7.7100764530790675,
      7.444058102896646,
      7.496039126541296
    ],
    "volume": 1158755965877
  },
  {
    "ts": 1559433600,
    "supply": 8243801562287,
    "trustlines": {
      "total": 2504,
      "authorized": 2504,
      "funded": 273
    },
    "payments": 7,
    "payments_amount": 16114183,
    "trades": 42,
    "traded_amount": 119912115489,
    "price": [
      7.496039126541296,
      7.5125115001232645,
      7.293577981651376,
      7.30763918669422
    ],
    "volume": 882372081898
  },
  {
    "ts": 1559520000,
    "supply": 8218597062287,
    "trustlines": {
      "total": 2515,
      "authorized": 2515,
      "funded": 277
    },
    "payments": 2,
    "payments_amount": 25214500000,
    "trades": 61,
    "traded_amount": 353570871630,
    "price": [
      7.30763918669422,
      7.867894700134875,
      7.1914456,
      7.830829034140842
    ],
    "volume": 2612330710017
  },
  {
    "ts": 1559606400,
    "supply": 8218597062287,
    "trustlines": {
      "total": 2525,
      "authorized": 2525,
      "funded": 277
    },
    "payments": 26,
    "payments_amount": 97705371418,
    "trades": 26540,
    "traded_amount": 848406933905,
    "price": [
      7.830829034140842,
      8.545842200249266,
      7.830829034140842,
      8.312420909428281
    ],
    "volume": 6804441649637
  },
  {
    "ts": 1559692800,
    "supply": 8238597062287,
    "trustlines": {
      "total": 2535,
      "authorized": 2535,
      "funded": 273
    },
    "payments": 3,
    "payments_amount": 20000117635,
    "trades": 20802,
    "traded_amount": 219412589096,
    "price": [
      8.312420909428281,
      8.386552930785829,
      7.987545818071498,
      8.164964951825624
    ],
    "volume": 1780584686586
  },
  {
    "ts": 1559779200,
    "supply": 8345806162287,
    "trustlines": {
      "total": 2551,
      "authorized": 2551,
      "funded": 280
    },
    "payments": 14,
    "payments_amount": 125937778405,
    "trades": 5015,
    "traded_amount": 197105351266,
    "price": [
      8.164964951825624,
      8.413396100023474,
      7.978056450963407,
      8.018728397346775
    ],
    "volume": 1480014595520
  },
  {
    "ts": 1559865600,
    "supply": 8345806162287,
    "trustlines": {
      "total": 2558,
      "authorized": 2558,
      "funded": 282
    },
    "payments": 10,
    "payments_amount": 8520192,
    "trades": 18919,
    "traded_amount": 214543432157,
    "price": [
      8.018728397346775,
      8.05560472109593,
      7.657841499987754,
      7.8362709863248305
    ],
    "volume": 1624827473603
  },
  {
    "ts": 1559952000,
    "supply": 8345806162287,
    "trustlines": {
      "total": 2559,
      "authorized": 2559,
      "funded": 283
    },
    "payments": 11,
    "payments_amount": 203977973,
    "trades": 4774,
    "traded_amount": 34332527116,
    "price": [
      7.8362709863248305,
      8.155226007723751,
      7.782007359919838,
      7.999776006012621
    ],
    "volume": 257250880014
  },
  {
    "ts": 1560038400,
    "supply": 8345806162287,
    "trustlines": {
      "total": 2581,
      "authorized": 2581,
      "funded": 281
    },
    "payments": 2,
    "payments_amount": 200000,
    "trades": 25,
    "traded_amount": 40536279369,
    "price": [
      7.999776006012621,
      8.451999405016242,
      7.999776006012621,
      8.451999405016242
    ],
    "volume": 336527217575
  },
  {
    "ts": 1560124800,
    "supply": 8412956162287,
    "trustlines": {
      "total": 2590,
      "authorized": 2590,
      "funded": 279
    },
    "payments": 7,
    "payments_amount": 127150011451,
    "trades": 29,
    "traded_amount": 129024286191,
    "price": [
      8.451999405016242,
      8.463817181548878,
      8.032354323201677,
      8.083002736427126
    ],
    "volume": 1018940796279
  },
  {
    "ts": 1560211200,
    "supply": 8452956162287,
    "trustlines": {
      "total": 2593,
      "authorized": 2593,
      "funded": 278
    },
    "payments": 7,
    "payments_amount": 80133493314,
    "trades": 26,
    "traded_amount": 649476519,
    "price": [
      8.083002736427126,
      8.395465994962217,
      8.083002736427126,
      8.330396864537727
    ],
    "volume": 5057343406
  },
  {
    "ts": 1560297600,
    "supply": 8455456162287,
    "trustlines": {
      "total": 2595,
      "authorized": 2595,
      "funded": 280
    },
    "payments": 8,
    "payments_amount": 2710435389,
    "trades": 56,
    "traded_amount": 17561240351,
    "price": [
      8.330396864537727,
      8.330396864537727,
      7.854527857142857,
      7.854527857142857
    ],
    "volume": 124424011982
  },
  {
    "ts": 1560384000,
    "supply": 8455456162287,
    "trustlines": {
      "total": 2603,
      "authorized": 2603,
      "funded": 279
    },
    "payments": 1,
    "payments_amount": 24654,
    "trades": 18,
    "traded_amount": 391267966,
    "price": [
      7.854527857142857,
      7.993112857142857,
      7.847672857142857,
      7.993112857142857
    ],
    "volume": 2984169601
  },
  {
    "ts": 1560470400,
    "supply": 8455706162287,
    "trustlines": {
      "total": 2623,
      "authorized": 2623,
      "funded": 287
    },
    "payments": 11,
    "payments_amount": 136139395118,
    "trades": 29,
    "traded_amount": 69885599889,
    "price": [
      7.993112857142857,
      8.264462809917354,
      7.993112857142857,
      7.99875470060397
    ],
    "volume": 562837669910
  },
  {
    "ts": 1560556800,
    "supply": 8387789795098,
    "trustlines": {
      "total": 2627,
      "authorized": 2627,
      "funded": 285
    },
    "payments": 4,
    "payments_amount": 67918389747,
    "trades": 28,
    "traded_amount": 19410979924,
    "price": [
      7.99875470060397,
      8.01583970004279,
      7.8649613,
      7.900004898003036
    ],
    "volume": 154973652387
  },
  {
    "ts": 1560643200,
    "supply": 8387789795098,
    "trustlines": {
      "total": 2630,
      "authorized": 2630,
      "funded": 286
    },
    "payments": 17,
    "payments_amount": 2058048675,
    "trades": 66,
    "traded_amount": 108547439473,
    "price": [
      7.900004898003036,
      7.900004898003036,
      7.546800300022395,
      7.684962600230457
    ],
    "volume": 745866543422
  },
  {
    "ts": 1560729600,
    "supply": 8388789795098,
    "trustlines": {
      "total": 2633,
      "authorized": 2633,
      "funded": 288
    },
    "payments": 14,
    "payments_amount": 3508204461,
    "trades": 67,
    "traded_amount": 100427315685,
    "price": [
      7.684962600230457,
      7.808620079407757,
      7.607074100047721,
      7.67704320010443
    ],
    "volume": 638145934287
  },
  {
    "ts": 1560816000,
    "supply": 8388789795098,
    "trustlines": {
      "total": 2640,
      "authorized": 2640,
      "funded": 284
    },
    "payments": 8,
    "payments_amount": 2206416168,
    "trades": 54,
    "traded_amount": 68918974855,
    "price": [
      7.67704320010443,
      8.176794700127202,
      7.672575500852183,
      8.161259954026375
    ],
    "volume": 513520535674
  },
  {
    "ts": 1560902400,
    "supply": 8388789795098,
    "trustlines": {
      "total": 2650,
      "authorized": 2650,
      "funded": 288
    },
    "payments": 13,
    "payments_amount": 1079276247,
    "trades": 26,
    "traded_amount": 27713895029,
    "price": [
      8.161259954026375,
      8.161259954026375,
      7.956482035776252,
      8.062512747297573
    ],
    "volume": 222149743686
  },
  {
    "ts": 1560988800,
    "supply": 8409239795098,
    "trustlines": {
      "total": 2658,
      "authorized": 2658,
      "funded": 287
    },
    "payments": 9,
    "payments_amount": 41266614437,
    "trades": 29,
    "traded_amount": 19475203101,
    "price": [
      8.062512747297573,
      8.281026000068506,
      8.059391915656207,
      8.281026000068506
    ],
    "volume": 158829517025
  },
  {
    "ts": 1561075200,
    "supply": 8409239795098,
    "trustlines": {
      "total": 2666,
      "authorized": 2666,
      "funded": 289
    },
    "payments": 14,
    "payments_amount": 447555938,
    "trades": 63,
    "traded_amount": 37830231670,
    "price": [
      8.281026000068506,
      8.281026000068506,
      8.053982605474264,
      8.185605801511041
    ],
    "volume": 305005904591
  },
  {
    "ts": 1561161600,
    "supply": 8409239795098,
    "trustlines": {
      "total": 2668,
      "authorized": 2668,
      "funded": 293
    },
    "payments": 28,
    "payments_amount": 4639090755,
    "trades": 153,
    "traded_amount": 369581295096,
    "price": [
      8.185605801511041,
      8.185605801511041,
      7.472239599776501,
      7.850542119348687
    ],
    "volume": 2746260839767
  },
  {
    "ts": 1561248000,
    "supply": 8409239795098,
    "trustlines": {
      "total": 2683,
      "authorized": 2683,
      "funded": 295
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 13,
    "traded_amount": 7135972877,
    "price": [
      7.850542119348687,
      7.850542119348687,
      7.65276,
      7.774224093062833
    ],
    "volume": 54075098856
  },
  {
    "ts": 1561334400,
    "supply": 8409239795098,
    "trustlines": {
      "total": 2686,
      "authorized": 2686,
      "funded": 295
    },
    "payments": 6,
    "payments_amount": 749844,
    "trades": 28,
    "traded_amount": 48772187325,
    "price": [
      7.774224093062833,
      8.057809952473084,
      7.774224093062833,
      7.880239200726276
    ],
    "volume": 384482611155
  },
  {
    "ts": 1561420800,
    "supply": 8409539795098,
    "trustlines": {
      "total": 2701,
      "authorized": 2701,
      "funded": 296
    },
    "payments": 8,
    "payments_amount": 301545237,
    "trades": 45,
    "traded_amount": 57381220483,
    "price": [
      7.880239200726276,
      8.196725116039195,
      7.776061800091909,
      8.120254305166199
    ],
    "volume": 456045794141
  },
  {
    "ts": 1561507200,
    "supply": 8539989795098,
    "trustlines": {
      "total": 2712,
      "authorized": 2712,
      "funded": 297
    },
    "payments": 37,
    "payments_amount": 131499095535,
    "trades": 200,
    "traded_amount": 593881106509,
    "price": [
      8.120254305166199,
      8.362997501846344,
      7.607825000057339,
      8.27662465079365
    ],
    "volume": 4381304830587
  },
  {
    "ts": 1561593600,
    "supply": 8554989795098,
    "trustlines": {
      "total": 2716,
      "authorized": 2716,
      "funded": 289
    },
    "payments": 30,
    "payments_amount": 20585941117,
    "trades": 330,
    "traded_amount": 1071426128034,
    "price": [
      8.27662465079365,
      9.714207997783317,
      8.27662465079365,
      9.298173838626768
    ],
    "volume": 8870820592445
  },
  {
    "ts": 1561680000,
    "supply": 8690489795098,
    "trustlines": {
      "total": 2723,
      "authorized": 2723,
      "funded": 293
    },
    "payments": 13,
    "payments_amount": 135533085282,
    "trades": 70,
    "traded_amount": 194268134171,
    "price": [
      9.298173838626768,
      9.316017316017316,
      8.97704670671323,
      9.00022770561666
    ],
    "volume": 1706661031332
  },
  {
    "ts": 1561766400,
    "supply": 8690489795098,
    "trustlines": {
      "total": 2730,
      "authorized": 2730,
      "funded": 302
    },
    "payments": 8,
    "payments_amount": 479515306,
    "trades": 80,
    "traded_amount": 231978314374,
    "price": [
      9.00022770561666,
      9.433962264150944,
      8.491921199983436,
      8.727272727272727
    ],
    "volume": 1988751821788
  },
  {
    "ts": 1561852800,
    "supply": 8690489795098,
    "trustlines": {
      "total": 2735,
      "authorized": 2735,
      "funded": 301
    },
    "payments": 6,
    "payments_amount": 117967463,
    "trades": 80,
    "traded_amount": 176082187353,
    "price": [
      8.727272727272727,
      9.654295969909482,
      8.727272727272727,
      9.654295969909482
    ],
    "volume": 1517388796630
  },
  {
    "ts": 1561939200,
    "supply": 8690489795098,
    "trustlines": {
      "total": 2737,
      "authorized": 2737,
      "funded": 298
    },
    "payments": 13,
    "payments_amount": 1325865671,
    "trades": 87,
    "traded_amount": 275283761467,
    "price": [
      9.654295969909482,
      9.900990099009901,
      9.155454545454546,
      9.519647300317565
    ],
    "volume": 2466179146567
  },
  {
    "ts": 1562025600,
    "supply": 8780489795098,
    "trustlines": {
      "total": 2745,
      "authorized": 2745,
      "funded": 306
    },
    "payments": 18,
    "payments_amount": 95757993638,
    "trades": 88,
    "traded_amount": 264313168796,
    "price": [
      9.519647300317565,
      10.001490222043085,
      9.468325136499,
      9.801811700811873
    ],
    "volume": 2456889159974
  },
  {
    "ts": 1562112000,
    "supply": 8860273270996,
    "trustlines": {
      "total": 2753,
      "authorized": 2753,
      "funded": 306
    },
    "payments": 23,
    "payments_amount": 269580853315,
    "trades": 76,
    "traded_amount": 188017705692,
    "price": [
      9.801811700811873,
      9.801811700811873,
      9.440606313499877,
      9.440606313499877
    ],
    "volume": 1764004252499
  },
  {
    "ts": 1562198400,
    "supply": 8860773270996,
    "trustlines": {
      "total": 2763,
      "authorized": 2763,
      "funded": 303
    },
    "payments": 33,
    "payments_amount": 54723420878,
    "trades": 93,
    "traded_amount": 455319855879,
    "price": [
      9.440606313499877,
      10.0913881,
      9.306307815426388,
      10.0913881
    ],
    "volume": 4226753948440
  },
  {
    "ts": 1562284800,
    "supply": 8860773270996,
    "trustlines": {
      "total": 2770,
      "authorized": 2770,
      "funded": 304
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 29,
    "traded_amount": 25800951349,
    "price": [
      10.0913881,
      10.109999998640943,
      9.863636363636363,
      10.015743206282353
    ],
    "volume": 187782842532
  },
  {
    "ts": 1562371200,
    "supply": 8860773270996,
    "trustlines": {
      "total": 2774,
      "authorized": 2774,
      "funded": 311
    },
    "payments": 32,
    "payments_amount": 4419584138,
    "trades": 135,
    "traded_amount": 81992039938,
    "price": [
      10.015743206282353,
      10.015743206282353,
      7,
      9.634358343583436
    ],
    "volume": 748787087910
  },
  {
    "ts": 1562457600,
    "supply": 8860773270996,
    "trustlines": {
      "total": 2784,
      "authorized": 2784,
      "funded": 313
    },
    "payments": 54,
    "payments_amount": 135065334,
    "trades": 19,
    "traded_amount": 1083796113,
    "price": [
      9.634358343583436,
      9.708737865397305,
      9.380097486869246,
      9.489939400169856
    ],
    "volume": 8073254341
  },
  {
    "ts": 1562544000,
    "supply": 8861273270996,
    "trustlines": {
      "total": 2787,
      "authorized": 2787,
      "funded": 313
    },
    "payments": 13,
    "payments_amount": 1153156066,
    "trades": 140,
    "traded_amount": 5451142160,
    "price": [
      9.489939400169856,
      9.616354535794478,
      9.273941018632671,
      9.615288461538462
    ],
    "volume": 44498309851
  },
  {
    "ts": 1562630400,
    "supply": 8866273270995,
    "trustlines": {
      "total": 2798,
      "authorized": 2798,
      "funded": 313
    },
    "payments": 8,
    "payments_amount": 5026145525,
    "trades": 114,
    "traded_amount": 26317273751,
    "price": [
      9.615288461538462,
      9.967196719671968,
      9.344920831720238,
      9.791499792339302
    ],
    "volume": 183071943321
  },
  {
    "ts": 1562716800,
    "supply": 8866273270995,
    "trustlines": {
      "total": 2807,
      "authorized": 2807,
      "funded": 314
    },
    "payments": 13,
    "payments_amount": 7306280371,
    "trades": 98,
    "traded_amount": 172671876626,
    "price": [
      9.791499792339302,
      10.455660377358491,
      9.610828,
      10.45555
    ],
    "volume": 1643128525615
  },
  {
    "ts": 1562803200,
    "supply": 8906723270995,
    "trustlines": {
      "total": 2816,
      "authorized": 2816,
      "funded": 313
    },
    "payments": 17,
    "payments_amount": 43116353339,
    "trades": 166,
    "traded_amount": 209641608374,
    "price": [
      10.45555,
      11.629188901943,
      9.907169224076265,
      11.496420013040048
    ],
    "volume": 2102622902598
  },
  {
    "ts": 1562889600,
    "supply": 8906723270995,
    "trustlines": {
      "total": 2824,
      "authorized": 2824,
      "funded": 317
    },
    "payments": 23,
    "payments_amount": 1521876323,
    "trades": 128,
    "traded_amount": 193349854493,
    "price": [
      11.496420013040048,
      11.496420013040048,
      9.8039,
      10.207778477595825
    ],
    "volume": 1942211572224
  },
  {
    "ts": 1562976000,
    "supply": 8906723270995,
    "trustlines": {
      "total": 2832,
      "authorized": 2832,
      "funded": 317
    },
    "payments": 11,
    "payments_amount": 2760715260,
    "trades": 38,
    "traded_amount": 25308143778,
    "price": [
      10.207778477595825,
      10.908257881531581,
      9.929254064193309,
      10.668646764467004
    ],
    "volume": 211317340234
  },
  {
    "ts": 1563062400,
    "supply": 8906723270995,
    "trustlines": {
      "total": 2840,
      "authorized": 2840,
      "funded": 318
    },
    "payments": 6,
    "payments_amount": 1897354092,
    "trades": 92,
    "traded_amount": 221139333923,
    "price": [
      10.668646764467004,
      11.683256500028548,
      10.428078606453555,
      10.9891
    ],
    "volume": 2310381116716
  },
  {
    "ts": 1563148800,
    "supply": 8919723270995,
    "trustlines": {
      "total": 2849,
      "authorized": 2849,
      "funded": 319
    },
    "payments": 27,
    "payments_amount": 95296835245,
    "trades": 621,
    "traded_amount": 280850909734,
    "price": [
      10.9891,
      12.173521373660153,
      10.9891,
      11.637440090217085
    ],
    "volume": 2828296453406
  },
  {
    "ts": 1563235200,
    "supply": 8883071874309,
    "trustlines": {
      "total": 2857,
      "authorized": 2857,
      "funded": 318
    },
    "payments": 5,
    "payments_amount": 36731765018,
    "trades": 1162,
    "traded_amount": 223284815625,
    "price": [
      11.637440090217085,
      12.819795221843004,
      11.300935494397912,
      12.66956720861003
    ],
    "volume": 2486363822039
  },
  {
    "ts": 1563321600,
    "supply": 8883071874309,
    "trustlines": {
      "total": 2865,
      "authorized": 2865,
      "funded": 319
    },
    "payments": 6,
    "payments_amount": 47619620,
    "trades": 64,
    "traded_amount": 220393468105,
    "price": [
      12.66956720861003,
      13.091318492141935,
      11.976011121528563,
      12.402577751686769
    ],
    "volume": 2620505022167
  },
  {
    "ts": 1563408000,
    "supply": 8883570976076,
    "trustlines": {
      "total": 2881,
      "authorized": 2881,
      "funded": 322
    },
    "payments": 9,
    "payments_amount": 902628501,
    "trades": 720,
    "traded_amount": 506006560392,
    "price": [
      12.402577751686769,
      12.549196787148594,
      10.787300699928036,
      11.27659574468085
    ],
    "volume": 5329874699701
  },
  {
    "ts": 1563494400,
    "supply": 8883570976076,
    "trustlines": {
      "total": 2885,
      "authorized": 2885,
      "funded": 320
    },
    "payments": 16,
    "payments_amount": 962925118,
    "trades": 257,
    "traded_amount": 124584140311,
    "price": [
      11.27659574468085,
      11.419129402091993,
      10.58435145939332,
      10.706183960185314
    ],
    "volume": 1211147975094
  },
  {
    "ts": 1563580800,
    "supply": 9018570976076,
    "trustlines": {
      "total": 2894,
      "authorized": 2894,
      "funded": 326
    },
    "payments": 4,
    "payments_amount": 135000000000,
    "trades": 72,
    "traded_amount": 71194490403,
    "price": [
      10.706183960185314,
      10.899123300091015,
      10.231066899993227,
      10.5223726585669
    ],
    "volume": 595105489444
  },
  {
    "ts": 1563667200,
    "supply": 9018570976076,
    "trustlines": {
      "total": 2910,
      "authorized": 2910,
      "funded": 324
    },
    "payments": 2,
    "payments_amount": 157861,
    "trades": 14,
    "traded_amount": 24966307000,
    "price": [
      10.5223726585669,
      11.175731674594594,
      10.5223726585669,
      10.911026998627314
    ],
    "volume": 271046743679
  },
  {
    "ts": 1563753600,
    "supply": 9007716413948,
    "trustlines": {
      "total": 2920,
      "authorized": 2920,
      "funded": 328
    },
    "payments": 10,
    "payments_amount": 32635482127,
    "trades": 36,
    "traded_amount": 34748378207,
    "price": [
      10.911026998627314,
      11.588035,
      10.911026998627314,
      11.40924705055227
    ],
    "volume": 390302158354
  },
  {
    "ts": 1563840000,
    "supply": 9007716413948,
    "trustlines": {
      "total": 2937,
      "authorized": 2937,
      "funded": 334
    },
    "payments": 12,
    "payments_amount": 605417322,
    "trades": 485,
    "traded_amount": 17925385728,
    "price": [
      11.40924705055227,
      12.028217821782178,
      11.31421382,
      11.895741556534508
    ],
    "volume": 196859526243
  },
  {
    "ts": 1563926400,
    "supply": 9037966413948,
    "trustlines": {
      "total": 2951,
      "authorized": 2951,
      "funded": 336
    },
    "payments": 11,
    "payments_amount": 30300301555,
    "trades": 669,
    "traded_amount": 43242080064,
    "price": [
      11.895741556534508,
      12.278423446184942,
      11.589473046666667,
      11.656923806826063
    ],
    "volume": 484511270627
  },
  {
    "ts": 1564012800,
    "supply": 9049416413948,
    "trustlines": {
      "total": 2962,
      "authorized": 2962,
      "funded": 336
    },
    "payments": 14,
    "payments_amount": 13176807249,
    "trades": 136,
    "traded_amount": 58779839321,
    "price": [
      11.656923806826063,
      11.767538305293614,
      11.38928489833219,
      11.653059877540406
    ],
    "volume": 627267250028
  },
  {
    "ts": 1564099200,
    "supply": 9059916413948,
    "trustlines": {
      "total": 2966,
      "authorized": 2966,
      "funded": 337
    },
    "payments": 8,
    "payments_amount": 10952736503,
    "trades": 169,
    "traded_amount": 66484211847,
    "price": [
      11.653059877540406,
      11.906763515122648,
      11.145159661629085,
      11.397178
    ],
    "volume": 750546962005
  },
  {
    "ts": 1564185600,
    "supply": 9059916413948,
    "trustlines": {
      "total": 2969,
      "authorized": 2969,
      "funded": 337
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 57,
    "traded_amount": 101316641675,
    "price": [
      11.397178,
      12.04190041900419,
      11.282275770376318,
      11.868171021377671
    ],
    "volume": 1053984224227
  },
  {
    "ts": 1564272000,
    "supply": 9059916413948,
    "trustlines": {
      "total": 2972,
      "authorized": 2972,
      "funded": 335
    },
    "payments": 3,
    "payments_amount": 105440784,
    "trades": 80,
    "traded_amount": 116250499838,
    "price": [
      11.868171021377671,
      12.370675800087604,
      11.836346687011579,
      11.93489412256869
    ],
    "volume": 1264551317422
  },
  {
    "ts": 1564358400,
    "supply": 9059736274435,
    "trustlines": {
      "total": 2974,
      "authorized": 2974,
      "funded": 336
    },
    "payments": 8,
    "payments_amount": 600418513,
    "trades": 78,
    "traded_amount": 91687414698,
    "price": [
      11.93489412256869,
      12.079566000158556,
      11.749003689491234,
      12.023783042858774
    ],
    "volume": 1032522103637
  },
  {
    "ts": 1564444800,
    "supply": 9059886274435,
    "trustlines": {
      "total": 2985,
      "authorized": 2985,
      "funded": 335
    },
    "payments": 4,
    "payments_amount": 255000000,
    "trades": 30,
    "traded_amount": 63459585392,
    "price": [
      12.023783042858774,
      12.164179104477611,
      11.81545674375888,
      11.976119655172413
    ],
    "volume": 737234165041
  },
  {
    "ts": 1564531200,
    "supply": 9060536274435,
    "trustlines": {
      "total": 2992,
      "authorized": 2992,
      "funded": 334
    },
    "payments": 11,
    "payments_amount": 660139848,
    "trades": 46,
    "traded_amount": 16484160924,
    "price": [
      11.976119655172413,
      12.0722164,
      11.724470258958153,
      12.051598269056752
    ],
    "volume": 173922247896
  },
  {
    "ts": 1564617600,
    "supply": 9061536274435,
    "trustlines": {
      "total": 2997,
      "authorized": 2997,
      "funded": 334
    },
    "payments": 8,
    "payments_amount": 1001436950,
    "trades": 28,
    "traded_amount": 5448685897,
    "price": [
      12.051598269056752,
      12.21475,
      11.956735969234467,
      12.091592706343256
    ],
    "volume": 65798626568
  },
  {
    "ts": 1564704000,
    "supply": 9061536274435,
    "trustlines": {
      "total": 2998,
      "authorized": 2998,
      "funded": 334
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 76,
    "traded_amount": 38664915233,
    "price": [
      12.091592706343256,
      12.143305598856433,
      11.977611442540816,
      12.13714240161266
    ],
    "volume": 237481742150
  },
  {
    "ts": 1564790400,
    "supply": 9061536274435,
    "trustlines": {
      "total": 3004,
      "authorized": 3004,
      "funded": 334
    },
    "payments": 2,
    "payments_amount": 3511601,
    "trades": 49,
    "traded_amount": 16143012843,
    "price": [
      12.13714240161266,
      12.175790723902868,
      12.071973827699018,
      12.13721810140862
    ],
    "volume": 195396461603
  },
  {
    "ts": 1564876800,
    "supply": 9076536274435,
    "trustlines": {
      "total": 3009,
      "authorized": 3009,
      "funded": 335
    },
    "payments": 11,
    "payments_amount": 20315915558,
    "trades": 84,
    "traded_amount": 181740979889,
    "price": [
      12.13721810140862,
      12.290191740412979,
      11.956716685445263,
      12.1757926221336
    ],
    "volume": 2194417410694
  },
  {
    "ts": 1564963200,
    "supply": 9075547944760,
    "trustlines": {
      "total": 3017,
      "authorized": 3017,
      "funded": 335
    },
    "payments": 14,
    "payments_amount": 2976523874,
    "trades": 64,
    "traded_amount": 34589690661,
    "price": [
      12.1757926221336,
      12.237864883978041,
      11.897696625009354,
      12.116556425
    ],
    "volume": 418812731570
  },
  {
    "ts": 1565049600,
    "supply": 9075547944760,
    "trustlines": {
      "total": 3021,
      "authorized": 3021,
      "funded": 337
    },
    "payments": 3,
    "payments_amount": 1048512,
    "trades": 83,
    "traded_amount": 12766013118,
    "price": [
      12.116556425,
      12.987012987012987,
      12.116556425,
      12.778656359431023
    ],
    "volume": 159272039713
  },
  {
    "ts": 1565136000,
    "supply": 9600128835860,
    "trustlines": {
      "total": 3028,
      "authorized": 3028,
      "funded": 336
    },
    "payments": 3,
    "payments_amount": 524580901100,
    "trades": 77,
    "traded_amount": 46765174908,
    "price": [
      12.778656359431023,
      12.942350332594234,
      12.678970222782622,
      12.728168337494859
    ],
    "volume": 597327956954
  },
  {
    "ts": 1565222400,
    "supply": 9610128835860,
    "trustlines": {
      "total": 3035,
      "authorized": 3035,
      "funded": 337
    },
    "payments": 10,
    "payments_amount": 533630891100,
    "trades": 44,
    "traded_amount": 38363733746,
    "price": [
      12.728168337494859,
      13.121366100712784,
      12.728168337494859,
      13.019545031971402
    ],
    "volume": 500892360586
  },
  {
    "ts": 1565308800,
    "supply": 9610628835860,
    "trustlines": {
      "total": 3040,
      "authorized": 3040,
      "funded": 333
    },
    "payments": 3,
    "payments_amount": 1156677342,
    "trades": 60,
    "traded_amount": 152402829710,
    "price": [
      13.019545031971402,
      13.888888889033943,
      13.014580234236416,
      13.888888888059702
    ],
    "volume": 1988536792667
  },
  {
    "ts": 1565395200,
    "supply": 9612628835860,
    "trustlines": {
      "total": 3047,
      "authorized": 3047,
      "funded": 333
    },
    "payments": 4,
    "payments_amount": 2164010306,
    "trades": 90,
    "traded_amount": 101996518004,
    "price": [
      13.888888888059702,
      13.9387388140838,
      13.500949954771817,
      13.54244
    ],
    "volume": 1035055299838
  },
  {
    "ts": 1565481600,
    "supply": 9622628835860,
    "trustlines": {
      "total": 3056,
      "authorized": 3056,
      "funded": 338
    },
    "payments": 4,
    "payments_amount": 10120000000,
    "trades": 23,
    "traded_amount": 34895110194,
    "price": [
      13.54244,
      13.54244,
      12.605756043202469,
      12.779312724438027
    ],
    "volume": 450887701032
  },
  {
    "ts": 1565568000,
    "supply": 9657628835860,
    "trustlines": {
      "total": 3069,
      "authorized": 3069,
      "funded": 333
    },
    "payments": 2,
    "payments_amount": 35050000000,
    "trades": 30,
    "traded_amount": 11574374633,
    "price": [
      12.779312724438027,
      13.245033112582782,
      12.779312724438027,
      13.245033112582782
    ],
    "volume": 152305844377
  },
  {
    "ts": 1565654400,
    "supply": 9657628835860,
    "trustlines": {
      "total": 3071,
      "authorized": 3071,
      "funded": 334
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 47,
    "traded_amount": 19066942573,
    "price": [
      13.245033112582782,
      13.88888888888889,
      13.245033112582782,
      13.538985415594166
    ],
    "volume": 257610732831
  },
  {
    "ts": 1565740800,
    "supply": 9678059835860,
    "trustlines": {
      "total": 3082,
      "authorized": 3082,
      "funded": 338
    },
    "payments": 10,
    "payments_amount": 20561193214,
    "trades": 146,
    "traded_amount": 148011828174,
    "price": [
      13.538985415594166,
      14.809483400360058,
      13.455990551734043,
      14.498153660131383
    ],
    "volume": 1616229657683
  },
  {
    "ts": 1565827200,
    "supply": 9678059835855,
    "trustlines": {
      "total": 3085,
      "authorized": 3085,
      "funded": 338
    },
    "payments": 27,
    "payments_amount": 797293259,
    "trades": 92,
    "traded_amount": 130688368079,
    "price": [
      14.498153660131383,
      15.151515151515152,
      14.183652760927819,
      14.183652760927819
    ],
    "volume": 1755401359608
  },
  {
    "ts": 1565913600,
    "supply": 9678059835855,
    "trustlines": {
      "total": 3088,
      "authorized": 3088,
      "funded": 337
    },
    "payments": 1,
    "payments_amount": 145,
    "trades": 36,
    "traded_amount": 69169866776,
    "price": [
      14.183652760927819,
      14.847611538969783,
      14.183652760927819,
      14.52413
    ],
    "volume": 882929030707
  },
  {
    "ts": 1566000000,
    "supply": 9682748535855,
    "trustlines": {
      "total": 3065,
      "authorized": 3065,
      "funded": 337
    },
    "payments": 1,
    "payments_amount": 4688700000,
    "trades": 14,
    "traded_amount": 15703906493,
    "price": [
      14.52413,
      14.880267300116511,
      14.50909090909091,
      14.745454545454546
    ],
    "volume": 231649886388
  },
  {
    "ts": 1566086400,
    "supply": 9682748535855,
    "trustlines": {
      "total": 3074,
      "authorized": 3074,
      "funded": 342
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 51,
    "traded_amount": 13678512007,
    "price": [
      14.745454545454546,
      14.79906096977676,
      13.977272727272727,
      14.085260870241457
    ],
    "volume": 150710638755
  },
  {
    "ts": 1566172800,
    "supply": 9682748535855,
    "trustlines": {
      "total": 3082,
      "authorized": 3082,
      "funded": 344
    },
    "payments": 20,
    "payments_amount": 3126985192,
    "trades": 50,
    "traded_amount": 23867196754,
    "price": [
      14.085260870241457,
      14.25,
      13.947752977679912,
      13.959417400260632
    ],
    "volume": 297700036617
  },
  {
    "ts": 1566259200,
    "supply": 9682748535855,
    "trustlines": {
      "total": 3089,
      "authorized": 3089,
      "funded": 344
    },
    "payments": 20,
    "payments_amount": 726169807,
    "trades": 64,
    "traded_amount": 14575914283,
    "price": [
      13.959417400260632,
      14.54304828388598,
      13.912254259501966,
      14.505971806502814
    ],
    "volume": 136411267003
  },
  {
    "ts": 1566345600,
    "supply": 9685648535855,
    "trustlines": {
      "total": 3104,
      "authorized": 3104,
      "funded": 348
    },
    "payments": 17,
    "payments_amount": 5529702311,
    "trades": 37,
    "traded_amount": 4020661320,
    "price": [
      14.505971806502814,
      15.19459552516096,
      14.505971806502814,
      15.037255500089376
    ],
    "volume": 53475906470
  },
  {
    "ts": 1566432000,
    "supply": 9686598535855,
    "trustlines": {
      "total": 3112,
      "authorized": 3112,
      "funded": 348
    },
    "payments": 13,
    "payments_amount": 5831593988,
    "trades": 70,
    "traded_amount": 46318774162,
    "price": [
      15.037255500089376,
      15.247063021286024,
      14.4013146882941,
      14.4013146882941
    ],
    "volume": 658371824815
  },
  {
    "ts": 1566518400,
    "supply": 9686598535855,
    "trustlines": {
      "total": 3121,
      "authorized": 3121,
      "funded": 349
    },
    "payments": 4,
    "payments_amount": 690590698,
    "trades": 48,
    "traded_amount": 12018161089,
    "price": [
      14.4013146882941,
      14.787757830530254,
      14.4013146882941,
      14.482053166727919
    ],
    "volume": 146560418754
  },
  {
    "ts": 1566604800,
    "supply": 9686598535855,
    "trustlines": {
      "total": 3127,
      "authorized": 3127,
      "funded": 349
    },
    "payments": 10,
    "payments_amount": 1844082045,
    "trades": 103,
    "traded_amount": 57704234131,
    "price": [
      14.482053166727919,
      14.772442500100581,
      14.131883500063415,
      14.170327334561428
    ],
    "volume": 652213265614
  },
  {
    "ts": 1566691200,
    "supply": 9686598535855,
    "trustlines": {
      "total": 3141,
      "authorized": 3141,
      "funded": 350
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 30,
    "traded_amount": 24169506847,
    "price": [
      14.170327334561428,
      14.481567137269327,
      13.974213300918677,
      14.481567137269327
    ],
    "volume": 279673556213
  },
  {
    "ts": 1566777600,
    "supply": 9716598535855,
    "trustlines": {
      "total": 3150,
      "authorized": 3150,
      "funded": 351
    },
    "payments": 4,
    "payments_amount": 30024889320,
    "trades": 23,
    "traded_amount": 10350702935,
    "price": [
      14.481567137269327,
      14.481567137269327,
      14.047483900639113,
      14.399553037873705
    ],
    "volume": 115670760854
  },
  {
    "ts": 1566864000,
    "supply": 9716598525855,
    "trustlines": {
      "total": 3159,
      "authorized": 3159,
      "funded": 356
    },
    "payments": 17,
    "payments_amount": 889456285,
    "trades": 54,
    "traded_amount": 41674344134,
    "price": [
      14.399553037873705,
      14.785303200382312,
      14.399553037873705,
      14.756149204025537
    ],
    "volume": 598972902513
  },
  {
    "ts": 1566950400,
    "supply": 9716598525855,
    "trustlines": {
      "total": 3171,
      "authorized": 3171,
      "funded": 355
    },
    "payments": 23,
    "payments_amount": 40633791204,
    "trades": 76,
    "traded_amount": 149204072075,
    "price": [
      14.756149204025537,
      15.778830963665087,
      14.613665802669143,
      15.644408108418494
    ],
    "volume": 2188013228676
  },
  {
    "ts": 1567036800,
    "supply": 9731598525855,
    "trustlines": {
      "total": 3180,
      "authorized": 3180,
      "funded": 352
    },
    "payments": 9,
    "payments_amount": 15092295550,
    "trades": 44,
    "traded_amount": 94479573696,
    "price": [
      15.644408108418494,
      16.512718300158774,
      15.644408108418494,
      16.27148649785741
    ],
    "volume": 1471623594113
  },
  {
    "ts": 1567123200,
    "supply": 9731748525855,
    "trustlines": {
      "total": 3187,
      "authorized": 3187,
      "funded": 352
    },
    "payments": 8,
    "payments_amount": 506936355,
    "trades": 35,
    "traded_amount": 109980880494,
    "price": [
      16.27148649785741,
      16.2971878,
      15.86664390896921,
      16.078178528534536
    ],
    "volume": 1754631234788
  },
  {
    "ts": 1567209600,
    "supply": 9731748525855,
    "trustlines": {
      "total": 3190,
      "authorized": 3190,
      "funded": 351
    },
    "payments": 6,
    "payments_amount": 445879422,
    "trades": 26,
    "traded_amount": 19818609650,
    "price": [
      16.078178528534536,
      16.447899943524693,
      16,
      16.332343517821602
    ],
    "volume": 258202583119
  },
  {
    "ts": 1567296000,
    "supply": 9731748525855,
    "trustlines": {
      "total": 3194,
      "authorized": 3194,
      "funded": 353
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 11377285626,
    "price": [
      16.332343517821602,
      16.332343517821602,
      15.977654184229259,
      15.977654184229259
    ],
    "volume": 181949124176
  },
  {
    "ts": 1567382400,
    "supply": 9731748525855,
    "trustlines": {
      "total": 3197,
      "authorized": 3197,
      "funded": 354
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 69,
    "traded_amount": 20213745447,
    "price": [
      15.977654184229259,
      16.259503755975416,
      15.794451503566352,
      15.801924002276516
    ],
    "volume": 205943416182
  },
  {
    "ts": 1567468800,
    "supply": 9731748525845,
    "trustlines": {
      "total": 3205,
      "authorized": 3205,
      "funded": 356
    },
    "payments": 9,
    "payments_amount": 1243313560,
    "trades": 82,
    "traded_amount": 26326045539,
    "price": [
      15.801924002276516,
      16.014064354128667,
      15.654660113491456,
      15.87372133750787
    ],
    "volume": 336733107644
  },
  {
    "ts": 1567555200,
    "supply": 9747748525845,
    "trustlines": {
      "total": 3212,
      "authorized": 3212,
      "funded": 359
    },
    "payments": 28,
    "payments_amount": 28612156005,
    "trades": 89,
    "traded_amount": 54541337757,
    "price": [
      15.87372133750787,
      16.2934777,
      15.87372133750787,
      16.15427983404134
    ],
    "volume": 833377313367
  },
  {
    "ts": 1567641600,
    "supply": 10249698525845,
    "trustlines": {
      "total": 3221,
      "authorized": 3221,
      "funded": 361
    },
    "payments": 8,
    "payments_amount": 503014534162,
    "trades": 66,
    "traded_amount": 14847945119,
    "price": [
      16.15427983404134,
      16.647161644747793,
      16.15427983404134,
      16.552399878506737
    ],
    "volume": 180862927289
  },
  {
    "ts": 1567728000,
    "supply": 10252498525845,
    "trustlines": {
      "total": 3228,
      "authorized": 3228,
      "funded": 363
    },
    "payments": 14,
    "payments_amount": 5434428801,
    "trades": 138,
    "traded_amount": 101532926201,
    "price": [
      16.552399878506737,
      17.25411163790968,
      16.457764782326187,
      17.25236320087425
    ],
    "volume": 1314497459882
  },
  {
    "ts": 1567814400,
    "supply": 10252498525845,
    "trustlines": {
      "total": 3234,
      "authorized": 3234,
      "funded": 364
    },
    "payments": 8,
    "payments_amount": 1531191578,
    "trades": 114,
    "traded_amount": 35797583564,
    "price": [
      17.25236320087425,
      17.25236320087425,
      16.3563797023554,
      16.436333802663693
    ],
    "volume": 401876168129
  },
  {
    "ts": 1567900800,
    "supply": 10252498525845,
    "trustlines": {
      "total": 3238,
      "authorized": 3238,
      "funded": 365
    },
    "payments": 11,
    "payments_amount": 2260736030,
    "trades": 74,
    "traded_amount": 24992756214,
    "price": [
      16.436333802663693,
      16.482703154974697,
      16.18635310681782,
      16.346373570943847
    ],
    "volume": 305688175118
  },
  {
    "ts": 1567987200,
    "supply": 10294998525845,
    "trustlines": {
      "total": 3346,
      "authorized": 3346,
      "funded": 387
    },
    "payments": 94,
    "payments_amount": 47500138528,
    "trades": 254,
    "traded_amount": 40751152194,
    "price": [
      16.346373570943847,
      16.781395951372836,
      16.29811560408937,
      16.61248527679623
    ],
    "volume": 580829195379
  },
  {
    "ts": 1568073600,
    "supply": 10277788113806,
    "trustlines": {
      "total": 3720,
      "authorized": 3720,
      "funded": 402
    },
    "payments": 70,
    "payments_amount": 74461309622,
    "trades": 165,
    "traded_amount": 64716517534,
    "price": [
      16.61248527679623,
      16.902783700100144,
      16.3701219,
      16.809265267015178
    ],
    "volume": 1066713203862
  },
  {
    "ts": 1568160000,
    "supply": 10257207051978,
    "trustlines": {
      "total": 3994,
      "authorized": 3994,
      "funded": 427
    },
    "payments": 24,
    "payments_amount": 63816069194,
    "trades": 158,
    "traded_amount": 41084669772,
    "price": [
      16.809265267015178,
      17.216346900831436,
      16.7317904,
      17.0637421
    ],
    "volume": 590318687785
  },
  {
    "ts": 1568246400,
    "supply": 10258202451978,
    "trustlines": {
      "total": 4212,
      "authorized": 4212,
      "funded": 440
    },
    "payments": 30,
    "payments_amount": 3094325531,
    "trades": 73,
    "traded_amount": 14288278533,
    "price": [
      17.0637421,
      17.3321917,
      17,
      17.220489448009953
    ],
    "volume": 211248953042
  },
  {
    "ts": 1568332800,
    "supply": 10260652451978,
    "trustlines": {
      "total": 4367,
      "authorized": 4367,
      "funded": 449
    },
    "payments": 31,
    "payments_amount": 7948691558,
    "trades": 126,
    "traded_amount": 42129397186,
    "price": [
      17.220489448009953,
      17.411552100205057,
      17.066503201027682,
      17.32455000202986
    ],
    "volume": 578733887187
  },
  {
    "ts": 1568419200,
    "supply": 10260652451978,
    "trustlines": {
      "total": 4479,
      "authorized": 4479,
      "funded": 461
    },
    "payments": 18,
    "payments_amount": 2761294591,
    "trades": 103,
    "traded_amount": 41309123378,
    "price": [
      17.32455000202986,
      17.47363889415477,
      16.893145023182043,
      16.993563888016702
    ],
    "volume": 530317091833
  },
  {
    "ts": 1568505600,
    "supply": 10261652451978,
    "trustlines": {
      "total": 4542,
      "authorized": 4542,
      "funded": 465
    },
    "payments": 15,
    "payments_amount": 2243024100,
    "trades": 41,
    "traded_amount": 2145731243,
    "price": [
      16.993563888016702,
      17.211910903585757,
      16.93511645,
      17.14487632508834
    ],
    "volume": 23828802420
  },
  {
    "ts": 1568592000,
    "supply": 10261652451978,
    "trustlines": {
      "total": 4612,
      "authorized": 4612,
      "funded": 475
    },
    "payments": 22,
    "payments_amount": 1589020072,
    "trades": 43,
    "traded_amount": 2136424168,
    "price": [
      17.14487632508834,
      17.213506139154163,
      16.96059374,
      17.0180966
    ],
    "volume": 26798262005
  },
  {
    "ts": 1568678400,
    "supply": 10562302451978,
    "trustlines": {
      "total": 4660,
      "authorized": 4660,
      "funded": 487
    },
    "payments": 111,
    "payments_amount": 302216356464,
    "trades": 1086,
    "traded_amount": 35152272850,
    "price": [
      17.0180966,
      17.21723440242442,
      15.007863430810316,
      15.714127566854733
    ],
    "volume": 508793566286
  },
  {
    "ts": 1568764800,
    "supply": 10563252451978,
    "trustlines": {
      "total": 4742,
      "authorized": 4742,
      "funded": 505
    },
    "payments": 276,
    "payments_amount": 24495862179,
    "trades": 1950,
    "traded_amount": 1249928063722,
    "price": [
      15.714127566854733,
      15.72972551421888,
      11.138313341667564,
      12.031492109038737
    ],
    "volume": 13768030005880
  },
  {
    "ts": 1568851200,
    "supply": 10618752451978,
    "trustlines": {
      "total": 4810,
      "authorized": 4810,
      "funded": 520
    },
    "payments": 111,
    "payments_amount": 73636750158,
    "trades": 1114,
    "traded_amount": 2052234147932,
    "price": [
      12.031492109038737,
      13.441492534163322,
      11.290649699719864,
      12.372508809044538
    ],
    "volume": 23583378181627
  },
  {
    "ts": 1568937600,
    "supply": 10639701662527,
    "trustlines": {
      "total": 4868,
      "authorized": 4868,
      "funded": 526
    },
    "payments": 80,
    "payments_amount": 35636217629,
    "trades": 536,
    "traded_amount": 339753624928,
    "price": [
      12.372508809044538,
      14.103439300205796,
      12.372508809044538,
      13.847040046498785
    ],
    "volume": 3990239738050
  },
  {
    "ts": 1569024000,
    "supply": 10639701662527,
    "trustlines": {
      "total": 4935,
      "authorized": 4935,
      "funded": 526
    },
    "payments": 20,
    "payments_amount": 5559002163,
    "trades": 186,
    "traded_amount": 73246386263,
    "price": [
      13.847040046498785,
      14.366701402363512,
      13.566665507565935,
      14.366701402363512
    ],
    "volume": 854049432379
  },
  {
    "ts": 1569110400,
    "supply": 10639701662527,
    "trustlines": {
      "total": 4978,
      "authorized": 4978,
      "funded": 529
    },
    "payments": 3,
    "payments_amount": 240208899,
    "trades": 136,
    "traded_amount": 128334424406,
    "price": [
      14.366701402363512,
      14.981273400008416,
      14.365797407560178,
      14.660907921337989
    ],
    "volume": 1786998420787
  },
  {
    "ts": 1569196800,
    "supply": 10640103698048,
    "trustlines": {
      "total": 5024,
      "authorized": 5024,
      "funded": 536
    },
    "payments": 26,
    "payments_amount": 45474971272,
    "trades": 944,
    "traded_amount": 229531237762,
    "price": [
      14.660907921337989,
      15.804790304584008,
      14.531321401869159,
      15.742997817907282
    ],
    "volume": 3086523204709
  },
  {
    "ts": 1569283200,
    "supply": 10639578161308,
    "trustlines": {
      "total": 5065,
      "authorized": 5065,
      "funded": 526
    },
    "payments": 57,
    "payments_amount": 7505358665,
    "trades": 2242,
    "traded_amount": 639063112965,
    "price": [
      15.742997817907282,
      19.077925694707606,
      15.42250044700293,
      18.62193930226223
    ],
    "volume": 10777337611105
  },
  {
    "ts": 1569369600,
    "supply": 10641228161308,
    "trustlines": {
      "total": 5096,
      "authorized": 5096,
      "funded": 533
    },
    "payments": 69,
    "payments_amount": 9068885586,
    "trades": 487,
    "traded_amount": 559789234567,
    "price": [
      18.62193930226223,
      18.994721038375772,
      16.64216849989808,
      17.44777443223301
    ],
    "volume": 9363286531507
  },
  {
    "ts": 1569456000,
    "supply": 10653728161308,
    "trustlines": {
      "total": 5131,
      "authorized": 5131,
      "funded": 537
    },
    "payments": 99,
    "payments_amount": 25685704165,
    "trades": 788,
    "traded_amount": 543639717972,
    "price": [
      17.44777443223301,
      18.580893500068683,
      16.127215103840445,
      17.028653000052145
    ],
    "volume": 8293326883328
  },
  {
    "ts": 1569542400,
    "supply": 10655228161308,
    "trustlines": {
      "total": 5170,
      "authorized": 5170,
      "funded": 548
    },
    "payments": 39,
    "payments_amount": 7221268738,
    "trades": 550,
    "traded_amount": 256308563537,
    "price": [
      17.028653000052145,
      17.90000000064043,
      16.838779603873895,
      17.01607360544917
    ],
    "volume": 3907672513975
  },
  {
    "ts": 1569628800,
    "supply": 10659228161308,
    "trustlines": {
      "total": 5204,
      "authorized": 5204,
      "funded": 550
    },
    "payments": 15,
    "payments_amount": 6314039605,
    "trades": 126,
    "traded_amount": 124676522053,
    "price": [
      17.01607360544917,
      17.391969779611674,
      16.904713203041407,
      17.0939
    ],
    "volume": 1942929952513
  },
  {
    "ts": 1569715200,
    "supply": 10659228161308,
    "trustlines": {
      "total": 5233,
      "authorized": 5233,
      "funded": 545
    },
    "payments": 23,
    "payments_amount": 1551685360,
    "trades": 215,
    "traded_amount": 76064234976,
    "price": [
      17.0939,
      17.613758378961965,
      17.07956515349006,
      17.284292057388548
    ],
    "volume": 1092409191979
  },
  {
    "ts": 1569801600,
    "supply": 10658973161308,
    "trustlines": {
      "total": 5249,
      "authorized": 5249,
      "funded": 555
    },
    "payments": 39,
    "payments_amount": 8223912748,
    "trades": 232,
    "traded_amount": 118750685717,
    "price": [
      17.284292057388548,
      17.642191676936783,
      16.129032116355248,
      16.447124960432234
    ],
    "volume": 1875611513878
  },
  {
    "ts": 1569888000,
    "supply": 10664123161308,
    "trustlines": {
      "total": 5274,
      "authorized": 5274,
      "funded": 551
    },
    "payments": 17,
    "payments_amount": 7273568902,
    "trades": 207,
    "traded_amount": 135227261264,
    "price": [
      16.447124960432234,
      17.175431542048518,
      16.08307989991299,
      16.952737103266248
    ],
    "volume": 2085747039509
  },
  {
    "ts": 1569974400,
    "supply": 10684523161308,
    "trustlines": {
      "total": 5308,
      "authorized": 5308,
      "funded": 564
    },
    "payments": 309,
    "payments_amount": 21144385074,
    "trades": 2762,
    "traded_amount": 35404214383,
    "price": [
      16.952737103266248,
      17.32,
      16.7390578,
      16.7390578
    ],
    "volume": 508785907218
  },
  {
    "ts": 1570060800,
    "supply": 10699320061308,
    "trustlines": {
      "total": 5342,
      "authorized": 5342,
      "funded": 559
    },
    "payments": 16,
    "payments_amount": 26676527478,
    "trades": 151,
    "traded_amount": 87292406340,
    "price": [
      16.7390578,
      17.290917100192136,
      16.7390578,
      17.04690450234009
    ],
    "volume": 1347790813682
  },
  {
    "ts": 1570147200,
    "supply": 10702970061308,
    "trustlines": {
      "total": 5383,
      "authorized": 5383,
      "funded": 569
    },
    "payments": 15,
    "payments_amount": 4448585833,
    "trades": 117,
    "traded_amount": 50137859481,
    "price": [
      17.04690450234009,
      17.250953,
      16.70843776106934,
      17.019999999707608
    ],
    "volume": 724339691723
  },
  {
    "ts": 1570233600,
    "supply": 10725920061308,
    "trustlines": {
      "total": 5426,
      "authorized": 5426,
      "funded": 574
    },
    "payments": 19,
    "payments_amount": 24749450814,
    "trades": 145,
    "traded_amount": 60724535634,
    "price": [
      17.019999999707608,
      17.14504188921068,
      16.666666666666668,
      16.977611624353838
    ],
    "volume": 852292233790
  },
  {
    "ts": 1570320000,
    "supply": 10725920061308,
    "trustlines": {
      "total": 5451,
      "authorized": 5451,
      "funded": 569
    },
    "payments": 16,
    "payments_amount": 60569374292,
    "trades": 169,
    "traded_amount": 160148920408,
    "price": [
      16.977611624353838,
      17.39333103317195,
      16.89090909090909,
      17.12071917808219
    ],
    "volume": 2515339088325
  },
  {
    "ts": 1570406400,
    "supply": 11025920061308,
    "trustlines": {
      "total": 5482,
      "authorized": 5482,
      "funded": 579
    },
    "payments": 39,
    "payments_amount": 304136636684,
    "trades": 342,
    "traded_amount": 228664420586,
    "price": [
      17.12071917808219,
      17.21818181818182,
      15.702252700412853,
      16.096514804219954
    ],
    "volume": 3316315979231
  },
  {
    "ts": 1570492800,
    "supply": 11027120061308,
    "trustlines": {
      "total": 5531,
      "authorized": 5531,
      "funded": 586
    },
    "payments": 18,
    "payments_amount": 301631356986,
    "trades": 154,
    "traded_amount": 65302981502,
    "price": [
      16.096514804219954,
      16.404952819719057,
      15.70777109996834,
      16.29347785648433
    ],
    "volume": 856187371400
  },
  {
    "ts": 1570579200,
    "supply": 11008520061308,
    "trustlines": {
      "total": 5588,
      "authorized": 5588,
      "funded": 593
    },
    "payments": 69,
    "payments_amount": 43836449008,
    "trades": 290,
    "traded_amount": 65168214080,
    "price": [
      16.29347785648433,
      16.343835616438355,
      15.384615384615385,
      15.8520476
    ],
    "volume": 795179738256
  },
  {
    "ts": 1570665600,
    "supply": 11009970061308,
    "trustlines": {
      "total": 5631,
      "authorized": 5631,
      "funded": 595
    },
    "payments": 37,
    "payments_amount": 2708466762,
    "trades": 189,
    "traded_amount": 22409526630,
    "price": [
      15.8520476,
      16.411798019669952,
      15.823134979620049,
      16.20713995940867
    ],
    "volume": 277326769819
  },
  {
    "ts": 1570752000,
    "supply": 11008670702849,
    "trustlines": {
      "total": 5676,
      "authorized": 5676,
      "funded": 598
    },
    "payments": 34,
    "payments_amount": 31020900373,
    "trades": 166,
    "traded_amount": 121266894223,
    "price": [
      16.20713995940867,
      16.839204100162483,
      15.916636203987649,
      16.7
    ],
    "volume": 1900072924448
  },
  {
    "ts": 1570838400,
    "supply": 11008670702849,
    "trustlines": {
      "total": 5707,
      "authorized": 5707,
      "funded": 595
    },
    "payments": 5,
    "payments_amount": 116197263,
    "trades": 71,
    "traded_amount": 60184235835,
    "price": [
      16.7,
      16.7,
      16.2445,
      16.35198695849702
    ],
    "volume": 965829193630
  },
  {
    "ts": 1570924800,
    "supply": 11011670702849,
    "trustlines": {
      "total": 5750,
      "authorized": 5750,
      "funded": 598
    },
    "payments": 7,
    "payments_amount": 3295227616,
    "trades": 51,
    "traded_amount": 18959422324,
    "price": [
      16.35198695849702,
      16.37646290011283,
      16.006735584788576,
      16.312841906491926
    ],
    "volume": 240972594639
  },
  {
    "ts": 1571011200,
    "supply": 11011670702849,
    "trustlines": {
      "total": 5777,
      "authorized": 5777,
      "funded": 603
    },
    "payments": 65,
    "payments_amount": 3517451211,
    "trades": 222,
    "traded_amount": 68401111001,
    "price": [
      16.312841906491926,
      16.3257842,
      15.265101819272376,
      15.265101819272376
    ],
    "volume": 1020999824677
  },
  {
    "ts": 1571097600,
    "supply": 11011470702849,
    "trustlines": {
      "total": 5825,
      "authorized": 5825,
      "funded": 606
    },
    "payments": 11,
    "payments_amount": 1884377659,
    "trades": 259,
    "traded_amount": 106102523320,
    "price": [
      15.265101819272376,
      15.975128875156814,
      15.051712132949957,
      15.75728734759094
    ],
    "volume": 1545534403976
  },
  {
    "ts": 1571184000,
    "supply": 11128620702849,
    "trustlines": {
      "total": 5860,
      "authorized": 5860,
      "funded": 604
    },
    "payments": 27,
    "payments_amount": 117358127442,
    "trades": 204,
    "traded_amount": 184297282520,
    "price": [
      15.75728734759094,
      16.487588126281914,
      15.527272727272727,
      16.161354833424998
    ],
    "volume": 2874068540284
  },
  {
    "ts": 1571270400,
    "supply": 11128620702849,
    "trustlines": {
      "total": 5897,
      "authorized": 5897,
      "funded": 606
    },
    "payments": 33,
    "payments_amount": 598341555,
    "trades": 150,
    "traded_amount": 230959903057,
    "price": [
      16.161354833424998,
      16.3391766,
      15.358010575035426,
      15.39856461114011
    ],
    "volume": 3607368925849
  },
  {
    "ts": 1571356800,
    "supply": 11146763928655,
    "trustlines": {
      "total": 5929,
      "authorized": 5929,
      "funded": 612
    },
    "payments": 36,
    "payments_amount": 97102502803,
    "trades": 200,
    "traded_amount": 162905898550,
    "price": [
      15.39856461114011,
      16.121647434677154,
      15.39856461114011,
      15.77486112894511
    ],
    "volume": 2486283193501
  },
  {
    "ts": 1571443200,
    "supply": 11148963928655,
    "trustlines": {
      "total": 5959,
      "authorized": 5959,
      "funded": 614
    },
    "payments": 8,
    "payments_amount": 2593167952,
    "trades": 108,
    "traded_amount": 49828909273,
    "price": [
      15.77486112894511,
      16.045454545454547,
      15.683568325625018,
      16.019377032547386
    ],
    "volume": 738089369848
  },
  {
    "ts": 1571529600,
    "supply": 11148963928655,
    "trustlines": {
      "total": 5985,
      "authorized": 5985,
      "funded": 617
    },
    "payments": 10,
    "payments_amount": 2479620855,
    "trades": 85,
    "traded_amount": 80981717881,
    "price": [
      16.019377032547386,
      16.130332550046106,
      15.692539005809756,
      15.751082450605699
    ],
    "volume": 1016038520615
  },
  {
    "ts": 1571616000,
    "supply": 11148963928655,
    "trustlines": {
      "total": 6009,
      "authorized": 6009,
      "funded": 619
    },
    "payments": 9,
    "payments_amount": 1246183162,
    "trades": 117,
    "traded_amount": 41182431826,
    "price": [
      15.751082450605699,
      15.898074980147264,
      15.568627450980392,
      15.639511467231872
    ],
    "volume": 594415129247
  },
  {
    "ts": 1571702400,
    "supply": 11148963928655,
    "trustlines": {
      "total": 6028,
      "authorized": 6028,
      "funded": 626
    },
    "payments": 6,
    "payments_amount": 341877968,
    "trades": 158,
    "traded_amount": 27328184652,
    "price": [
      15.639511467231872,
      15.8703761,
      15.416396879542592,
      15.797788309636651
    ],
    "volume": 401444497901
  },
  {
    "ts": 1571788800,
    "supply": 11159913928655,
    "trustlines": {
      "total": 6057,
      "authorized": 6057,
      "funded": 641
    },
    "payments": 332,
    "payments_amount": 13843210299,
    "trades": 3407,
    "traded_amount": 171808174074,
    "price": [
      15.797788309636651,
      17.52033991659454,
      15.747857897629475,
      16.744372617480398
    ],
    "volume": 2181306376004
  },
  {
    "ts": 1571875200,
    "supply": 11161513928655,
    "trustlines": {
      "total": 6089,
      "authorized": 6089,
      "funded": 651
    },
    "payments": 387,
    "payments_amount": 3401541969,
    "trades": 2150,
    "traded_amount": 104093077905,
    "price": [
      16.744372617480398,
      17.086729600017872,
      16.233766186040427,
      16.501161
    ],
    "volume": 1412238704372
  },
  {
    "ts": 1571961600,
    "supply": 11165022128655,
    "trustlines": {
      "total": 6115,
      "authorized": 6115,
      "funded": 656
    },
    "payments": 51,
    "payments_amount": 10040575728,
    "trades": 518,
    "traded_amount": 250961837325,
    "price": [
      16.501161,
      16.734777207039958,
      15.221255100022388,
      15.612218700339103
    ],
    "volume": 2966852214118
  },
  {
    "ts": 1572048000,
    "supply": 11167772128655,
    "trustlines": {
      "total": 6152,
      "authorized": 6152,
      "funded": 663
    },
    "payments": 62,
    "payments_amount": 12919124780,
    "trades": 653,
    "traded_amount": 374076922794,
    "price": [
      15.612218700339103,
      16.163314109871376,
      14.835738717423448,
      15.936889915932905
    ],
    "volume": 4695973573293
  },
  {
    "ts": 1572134400,
    "supply": 11171222128655,
    "trustlines": {
      "total": 6186,
      "authorized": 6186,
      "funded": 673
    },
    "payments": 47,
    "payments_amount": 6337058496,
    "trades": 291,
    "traded_amount": 80258943337,
    "price": [
      15.936889915932905,
      16.063636363636363,
      15.243369100526449,
      15.407562344752245
    ],
    "volume": 1051396017615
  },
  {
    "ts": 1572220800,
    "supply": 11162822128655,
    "trustlines": {
      "total": 6214,
      "authorized": 6214,
      "funded": 685
    },
    "payments": 40,
    "payments_amount": 9585797578,
    "trades": 270,
    "traded_amount": 123767665659,
    "price": [
      15.407562344752245,
      15.482776178997478,
      14.491997500859336,
      15.27684
    ],
    "volume": 1498741873686
  },
  {
    "ts": 1572307200,
    "supply": 11174822128655,
    "trustlines": {
      "total": 6261,
      "authorized": 6261,
      "funded": 689
    },
    "payments": 33,
    "payments_amount": 13845625358,
    "trades": 235,
    "traded_amount": 160417414947,
    "price": [
      15.27684,
      15.4496114003302,
      14.3837704990271,
      14.983136498752366
    ],
    "volume": 2124888908000
  },
  {
    "ts": 1572393600,
    "supply": 11176641228655,
    "trustlines": {
      "total": 6290,
      "authorized": 6290,
      "funded": 691
    },
    "payments": 31,
    "payments_amount": 5439218945,
    "trades": 285,
    "traded_amount": 126116951691,
    "price": [
      14.983136498752366,
      15.873873873873874,
      14.86937823123027,
      15.681818181818182
    ],
    "volume": 1410145037682
  },
  {
    "ts": 1572480000,
    "supply": 11183292328655,
    "trustlines": {
      "total": 6315,
      "authorized": 6315,
      "funded": 695
    },
    "payments": 39,
    "payments_amount": 61415335880,
    "trades": 448,
    "traded_amount": 167508150228,
    "price": [
      15.681818181818182,
      16.010964236594813,
      15.381988612233915,
      15.4061124
    ],
    "volume": 1943600019463
  },
  {
    "ts": 1572566400,
    "supply": 11194766828655,
    "trustlines": {
      "total": 6343,
      "authorized": 6343,
      "funded": 704
    },
    "payments": 71,
    "payments_amount": 15960716707,
    "trades": 446,
    "traded_amount": 264958217614,
    "price": [
      15.4061124,
      15.42198394022391,
      13.932020086480366,
      14.434117546263113
    ],
    "volume": 3295999612083
  },
  {
    "ts": 1572652800,
    "supply": 11296766828655,
    "trustlines": {
      "total": 6358,
      "authorized": 6358,
      "funded": 710
    },
    "payments": 21,
    "payments_amount": 105708744781,
    "trades": 160,
    "traded_amount": 99402661531,
    "price": [
      14.434117546263113,
      14.434117546263113,
      14.051817800040796,
      14.073486100368159
    ],
    "volume": 1239603415211
  },
  {
    "ts": 1572739200,
    "supply": 11297216828655,
    "trustlines": {
      "total": 6373,
      "authorized": 6373,
      "funded": 706
    },
    "payments": 19,
    "payments_amount": 642066816,
    "trades": 121,
    "traded_amount": 184032585603,
    "price": [
      14.073486100368159,
      14.652508409109043,
      13.963636363636363,
      14.527272727272727
    ],
    "volume": 2646095222381
  },
  {
    "ts": 1572825600,
    "supply": 11305082828655,
    "trustlines": {
      "total": 6387,
      "authorized": 6387,
      "funded": 711
    },
    "payments": 30,
    "payments_amount": 8318907469,
    "trades": 292,
    "traded_amount": 331455922345,
    "price": [
      14.527272727272727,
      14.747152622355566,
      12.661586899920106,
      12.661586899920106
    ],
    "volume": 3921898306940
  },
  {
    "ts": 1572912000,
    "supply": 11318642828655,
    "trustlines": {
      "total": 6425,
      "authorized": 6425,
      "funded": 719
    },
    "payments": 82,
    "payments_amount": 17163013974,
    "trades": 1130,
    "traded_amount": 2469061153697,
    "price": [
      12.661586899920106,
      12.753800774929191,
      11.287420620151108,
      12.145969800035482
    ],
    "volume": 28473399465979
  },
  {
    "ts": 1572998400,
    "supply": 11397592828655,
    "trustlines": {
      "total": 6467,
      "authorized": 6467,
      "funded": 726
    },
    "payments": 26,
    "payments_amount": 81309256950,
    "trades": 484,
    "traded_amount": 547832570533,
    "price": [
      12.145969800035482,
      13.047894401256258,
      12.086914585404603,
      13.008932311076247
    ],
    "volume": 6001949762594
  },
  {
    "ts": 1573084800,
    "supply": 11399792828655,
    "trustlines": {
      "total": 6500,
      "authorized": 6500,
      "funded": 730
    },
    "payments": 10,
    "payments_amount": 7469749839,
    "trades": 538,
    "traded_amount": 1008273488500,
    "price": [
      13.008932311076247,
      13.791239301272926,
      12.997799472221251,
      13.389461957770907
    ],
    "volume": 12868476360694
  },
  {
    "ts": 1573171200,
    "supply": 11460742828655,
    "trustlines": {
      "total": 6520,
      "authorized": 6520,
      "funded": 728
    },
    "payments": 18,
    "payments_amount": 62716011750,
    "trades": 887,
    "traded_amount": 526220175840,
    "price": [
      13.389461957770907,
      14.6320093,
      13.043148947108303,
      14.07326816832435
    ],
    "volume": 5696051381317
  },
  {
    "ts": 1573257600,
    "supply": 11529992828655,
    "trustlines": {
      "total": 6538,
      "authorized": 6538,
      "funded": 732
    },
    "payments": 16,
    "payments_amount": 69698642625,
    "trades": 721,
    "traded_amount": 381546697391,
    "price": [
      14.07326816832435,
      14.15334011832323,
      13.691165615220148,
      13.768653611963162
    ],
    "volume": 4059534934056
  },
  {
    "ts": 1573344000,
    "supply": 11531142828655,
    "trustlines": {
      "total": 6551,
      "authorized": 6551,
      "funded": 740
    },
    "payments": 53,
    "payments_amount": 17835768103,
    "trades": 1107,
    "traded_amount": 822655139264,
    "price": [
      13.768653611963162,
      13.768653611963162,
      12.306936505632539,
      12.63096686715572
    ],
    "volume": 8350897748531
  },
  {
    "ts": 1573430400,
    "supply": 11531142828655,
    "trustlines": {
      "total": 6580,
      "authorized": 6580,
      "funded": 751
    },
    "payments": 50,
    "payments_amount": 12626187723,
    "trades": 1033,
    "traded_amount": 751400795786,
    "price": [
      12.63096686715572,
      13.143741600083926,
      12.345672185082776,
      12.670577608250781
    ],
    "volume": 7098109778309
  },
  {
    "ts": 1573516800,
    "supply": 11560721028655,
    "trustlines": {
      "total": 6600,
      "authorized": 6600,
      "funded": 756
    },
    "payments": 47,
    "payments_amount": 42712375923,
    "trades": 1998,
    "traded_amount": 1028612641802,
    "price": [
      12.670577608250781,
      13.465420942594983,
      12.154568899269965,
      13.011498243938219
    ],
    "volume": 10089853586464
  },
  {
    "ts": 1573603200,
    "supply": 11579071028655,
    "trustlines": {
      "total": 6624,
      "authorized": 6624,
      "funded": 759
    },
    "payments": 41,
    "payments_amount": 22097913686,
    "trades": 631,
    "traded_amount": 327526224247,
    "price": [
      13.011498243938219,
      13.292198410413196,
      12.939104685241178,
      13.142941003815674
    ],
    "volume": 2539073722569
  },
  {
    "ts": 1573689600,
    "supply": 11593671028655,
    "trustlines": {
      "total": 6651,
      "authorized": 6651,
      "funded": 767
    },
    "payments": 25,
    "payments_amount": 17736513267,
    "trades": 235,
    "traded_amount": 94314672206,
    "price": [
      13.142941003815674,
      13.83614800010019,
      13.142941003815674,
      13.528047023470316
    ],
    "volume": 991972237153
  },
  {
    "ts": 1573776000,
    "supply": 9487489961183,
    "trustlines": {
      "total": 6722,
      "authorized": 6722,
      "funded": 768
    },
    "payments": 49,
    "payments_amount": 6422938424644,
    "trades": 498,
    "traded_amount": 432496074576,
    "price": [
      13.528047023470316,
      14.159492519220956,
      13.394304007661438,
      13.80098416196884
    ],
    "volume": 4739865853788
  },
  {
    "ts": 1573862400,
    "supply": 9484078252503,
    "trustlines": {
      "total": 6833,
      "authorized": 6833,
      "funded": 793
    },
    "payments": 35,
    "payments_amount": 46709126506,
    "trades": 450,
    "traded_amount": 187744913148,
    "price": [
      13.80098416196884,
      14.096075102202146,
      13.80098416196884,
      14.0739232
    ],
    "volume": 1587794876953
  },
  {
    "ts": 1573948800,
    "supply": 9482641114849,
    "trustlines": {
      "total": 6957,
      "authorized": 6957,
      "funded": 816
    },
    "payments": 70,
    "payments_amount": 17263729003,
    "trades": 997,
    "traded_amount": 415739965371,
    "price": [
      14.0739232,
      14.083814205994768,
      13.76197458455523,
      13.987726769554673
    ],
    "volume": 2342818593575
  },
  {
    "ts": 1574035200,
    "supply": 9471230003370,
    "trustlines": {
      "total": 7072,
      "authorized": 7072,
      "funded": 818
    },
    "payments": 84,
    "payments_amount": 52242919821,
    "trades": 1010,
    "traded_amount": 599444173135,
    "price": [
      13.987726769554673,
      15.624027401950142,
      13.987726769554673,
      14.850698502327267
    ],
    "volume": 7043755766472
  },
  {
    "ts": 1574121600,
    "supply": 9523511899964,
    "trustlines": {
      "total": 7245,
      "authorized": 7245,
      "funded": 871
    },
    "payments": 56,
    "payments_amount": 70243610673,
    "trades": 548,
    "traded_amount": 265985693721,
    "price": [
      14.850698502327267,
      15.779403300386873,
      14.850698502327267,
      15.31761851937301
    ],
    "volume": 3646614806683
  },
  {
    "ts": 1574208000,
    "supply": 9561268782635,
    "trustlines": {
      "total": 7339,
      "authorized": 7339,
      "funded": 893
    },
    "payments": 39,
    "payments_amount": 52463822084,
    "trades": 196,
    "traded_amount": 68231239830,
    "price": [
      15.31761851937301,
      15.681818181818182,
      15.126419043527905,
      15.66999
    ],
    "volume": 1006845801584
  },
  {
    "ts": 1574294400,
    "supply": 9565081717152,
    "trustlines": {
      "total": 7391,
      "authorized": 7391,
      "funded": 889
    },
    "payments": 66,
    "payments_amount": 20657967350,
    "trades": 731,
    "traded_amount": 526562134824,
    "price": [
      15.66999,
      17.469076100181013,
      15.66998999980904,
      16.484648703193482
    ],
    "volume": 7572508441938
  },
  {
    "ts": 1574380800,
    "supply": 9572775311593,
    "trustlines": {
      "total": 7441,
      "authorized": 7441,
      "funded": 896
    },
    "payments": 69,
    "payments_amount": 20092919834,
    "trades": 1163,
    "traded_amount": 762029227671,
    "price": [
      16.484648703193482,
      18.744138230854475,
      16.36246124081856,
      16.810254255227008
    ],
    "volume": 11998343221500
  },
  {
    "ts": 1574467200,
    "supply": 9590415311593,
    "trustlines": {
      "total": 7503,
      "authorized": 7503,
      "funded": 909
    },
    "payments": 34,
    "payments_amount": 20955785810,
    "trades": 580,
    "traded_amount": 443855867806,
    "price": [
      16.810254255227008,
      17.216135011528653,
      14.841415400263381,
      16.24355988475397
    ],
    "volume": 6812301956174
  },
  {
    "ts": 1574553600,
    "supply": 9596994145927,
    "trustlines": {
      "total": 7538,
      "authorized": 7538,
      "funded": 905
    },
    "payments": 28,
    "payments_amount": 9180703070,
    "trades": 1139,
    "traded_amount": 491359543003,
    "price": [
      16.24355988475397,
      18.010131605566286,
      16.11741212311557,
      18.010131605566286
    ],
    "volume": 7686134522082
  },
  {
    "ts": 1574640000,
    "supply": 9595524695936,
    "trustlines": {
      "total": 7574,
      "authorized": 7574,
      "funded": 925
    },
    "payments": 23,
    "payments_amount": 6813668235,
    "trades": 1340,
    "traded_amount": 991105717087,
    "price": [
      18.010131605566286,
      18.789814125835232,
      16.788269699310916,
      17.451867380151157
    ],
    "volume": 16268701240618
  },
  {
    "ts": 1574726400,
    "supply": 9625536804009,
    "trustlines": {
      "total": 7608,
      "authorized": 7608,
      "funded": 926
    },
    "payments": 24,
    "payments_amount": 32297452483,
    "trades": 541,
    "traded_amount": 322928036557,
    "price": [
      17.451867380151157,
      18.1066263020386,
      17.210607928911024,
      17.409407665505228
    ],
    "volume": 5395705912602
  },
  {
    "ts": 1574812800,
    "supply": 9635316804009,
    "trustlines": {
      "total": 7642,
      "authorized": 7642,
      "funded": 933
    },
    "payments": 22,
    "payments_amount": 14301729977,
    "trades": 534,
    "traded_amount": 319687986896,
    "price": [
      17.409407665505228,
      18.252407150947345,
      16.860383279206864,
      17.005240994364833
    ],
    "volume": 5044296092362
  },
  {
    "ts": 1574899200,
    "supply": 9764238004009,
    "trustlines": {
      "total": 7666,
      "authorized": 7666,
      "funded": 923
    },
    "payments": 44,
    "payments_amount": 129964881262,
    "trades": 274,
    "traded_amount": 137662942945,
    "price": [
      17.005240994364833,
      17.378821820976277,
      16.94992821111111,
      17.28346191711691
    ],
    "volume": 2096306591621
  },
  {
    "ts": 1574985600,
    "supply": 9772238004009,
    "trustlines": {
      "total": 7691,
      "authorized": 7691,
      "funded": 935
    },
    "payments": 16,
    "payments_amount": 11537397022,
    "trades": 867,
    "traded_amount": 285054977853,
    "price": [
      17.28346191711691,
      17.286592,
      16.681342832797153,
      16.9210463457199
    ],
    "volume": 4558900990424
  },
  {
    "ts": 1575072000,
    "supply": 9774698004009,
    "trustlines": {
      "total": 7728,
      "authorized": 7728,
      "funded": 933
    },
    "payments": 17,
    "payments_amount": 3148862821,
    "trades": 307,
    "traded_amount": 205180271832,
    "price": [
      16.9210463457199,
      17.71269790012484,
      16.89685816562405,
      17.418181818181818
    ],
    "volume": 3480689227416
  },
  {
    "ts": 1575158400,
    "supply": 9774698004009,
    "trustlines": {
      "total": 7740,
      "authorized": 7740,
      "funded": 931
    },
    "payments": 18,
    "payments_amount": 931681302,
    "trades": 396,
    "traded_amount": 76152764808,
    "price": [
      17.418181818181818,
      18.09895783346124,
      17.359001410878076,
      17.406107813305894
    ],
    "volume": 1085027020123
  },
  {
    "ts": 1575244800,
    "supply": 9774698004009,
    "trustlines": {
      "total": 7759,
      "authorized": 7759,
      "funded": 931
    },
    "payments": 13,
    "payments_amount": 116493483,
    "trades": 260,
    "traded_amount": 294940510342,
    "price": [
      17.406107813305894,
      18.18181818201353,
      17.406107813305894,
      17.911549187748612
    ],
    "volume": 4966170087016
  },
  {
    "ts": 1575331200,
    "supply": 9790329177888,
    "trustlines": {
      "total": 7776,
      "authorized": 7776,
      "funded": 939
    },
    "payments": 32,
    "payments_amount": 18546816473,
    "trades": 455,
    "traded_amount": 128931996186,
    "price": [
      17.911549187748612,
      18.168901701974242,
      17.771995944933945,
      17.90001102819542
    ],
    "volume": 2031798736367
  },
  {
    "ts": 1575417600,
    "supply": 9836987377888,
    "trustlines": {
      "total": 7793,
      "authorized": 7793,
      "funded": 938
    },
    "payments": 37,
    "payments_amount": 54649992880,
    "trades": 743,
    "traded_amount": 530570224510,
    "price": [
      17.90001102819542,
      18.56389693,
      17.566927900866467,
      18.154545454545456
    ],
    "volume": 8913631483659
  },
  {
    "ts": 1575504000,
    "supply": 9868382193553,
    "trustlines": {
      "total": 7817,
      "authorized": 7817,
      "funded": 940
    },
    "payments": 37,
    "payments_amount": 39068591625,
    "trades": 175,
    "traded_amount": 192439854518,
    "price": [
      18.154545454545456,
      18.42623077500345,
      17.993928799991174,
      18.045454545454547
    ],
    "volume": 3189976122129
  },
  {
    "ts": 1575590400,
    "supply": 9868382193553,
    "trustlines": {
      "total": 7839,
      "authorized": 7839,
      "funded": 934
    },
    "payments": 26,
    "payments_amount": 1287719458,
    "trades": 119,
    "traded_amount": 70308062723,
    "price": [
      18.045454545454547,
      18.2724857,
      17.92133965176014,
      18.099874500598442
    ],
    "volume": 1094422499104
  },
  {
    "ts": 1575676800,
    "supply": 9875742193553,
    "trustlines": {
      "total": 7859,
      "authorized": 7859,
      "funded": 943
    },
    "payments": 18,
    "payments_amount": 10333541909,
    "trades": 167,
    "traded_amount": 24511025833,
    "price": [
      18.099874500598442,
      18.184727703715804,
      17.914130207879257,
      18.022636700607265
    ],
    "volume": 256332672273
  },
  {
    "ts": 1575763200,
    "supply": 9873522193549,
    "trustlines": {
      "total": 7891,
      "authorized": 7891,
      "funded": 956
    },
    "payments": 50,
    "payments_amount": 7043762247,
    "trades": 210,
    "traded_amount": 29209501072,
    "price": [
      18.022636700607265,
      18.209408203315338,
      17.827272727272728,
      17.903674340491513
    ],
    "volume": 429631004044
  },
  {
    "ts": 1575849600,
    "supply": 9875022193549,
    "trustlines": {
      "total": 7904,
      "authorized": 7904,
      "funded": 951
    },
    "payments": 11,
    "payments_amount": 3319057663,
    "trades": 252,
    "traded_amount": 72695203466,
    "price": [
      17.903674340491513,
      18.474693,
      17.84748603126154,
      18.31417974237702
    ],
    "volume": 621818855324
  },
  {
    "ts": 1575936000,
    "supply": 9875753633941,
    "trustlines": {
      "total": 7925,
      "authorized": 7925,
      "funded": 955
    },
    "payments": 29,
    "payments_amount": 12561779589,
    "trades": 346,
    "traded_amount": 64034206619,
    "price": [
      18.31417974237702,
      19.108036841955947,
      18.31417974237702,
      18.787160831817584
    ],
    "volume": 772269762575
  },
  {
    "ts": 1576022400,
    "supply": 9886064233941,
    "trustlines": {
      "total": 7956,
      "authorized": 7956,
      "funded": 954
    },
    "payments": 31,
    "payments_amount": 11655445131,
    "trades": 247,
    "traded_amount": 47718679106,
    "price": [
      18.787160831817584,
      18.990214306244667,
      18.652018450181064,
      18.990214306244667
    ],
    "volume": 622883951962
  },
  {
    "ts": 1576108800,
    "supply": 9926361331736,
    "trustlines": {
      "total": 7980,
      "authorized": 7980,
      "funded": 962
    },
    "payments": 53,
    "payments_amount": 46118714279,
    "trades": 1843,
    "traded_amount": 106682388014,
    "price": [
      18.990214306244667,
      19.47749054189993,
      18.812250854214124,
      19.317525137028568
    ],
    "volume": 1670292639938
  },
  {
    "ts": 1576195200,
    "supply": 10156617145609,
    "trustlines": {
      "total": 8005,
      "authorized": 8005,
      "funded": 972
    },
    "payments": 30,
    "payments_amount": 233945222669,
    "trades": 190,
    "traded_amount": 43003995718,
    "price": [
      19.317525137028568,
      19.317525137028568,
      18.940183113690342,
      18.998073713955012
    ],
    "volume": 504958535127
  },
  {
    "ts": 1576281600,
    "supply": 10186241158814,
    "trustlines": {
      "total": 8038,
      "authorized": 8038,
      "funded": 970
    },
    "payments": 43,
    "payments_amount": 41871118246,
    "trades": 341,
    "traded_amount": 102997507232,
    "price": [
      18.998073713955012,
      19.9094914,
      18.972727272727273,
      19.706180801451726
    ],
    "volume": 1504029980259
  },
  {
    "ts": 1576368000,
    "supply": 10186082534258,
    "trustlines": {
      "total": 8078,
      "authorized": 8078,
      "funded": 977
    },
    "payments": 20,
    "payments_amount": 2499114606,
    "trades": 215,
    "traded_amount": 126007959568,
    "price": [
      19.706180801451726,
      19.85,
      19.46869870003106,
      19.565259919926568
    ],
    "volume": 2004130554696
  },
  {
    "ts": 1576454400,
    "supply": 10208079563781,
    "trustlines": {
      "total": 8171,
      "authorized": 8171,
      "funded": 988
    },
    "payments": 68,
    "payments_amount": 64421884831,
    "trades": 899,
    "traded_amount": 245893512713,
    "price": [
      19.565259919926568,
      21.38992363037386,
      19.565259919926568,
      21.24999999911975
    ],
    "volume": 3800591790536
  },
  {
    "ts": 1576540800,
    "supply": 10204941280375,
    "trustlines": {
      "total": 8232,
      "authorized": 8232,
      "funded": 1004
    },
    "payments": 45,
    "payments_amount": 111558075961,
    "trades": 1097,
    "traded_amount": 762923273459,
    "price": [
      21.24999999911975,
      23.62524688382176,
      21.21842105263158,
      23.13500030560252
    ],
    "volume": 11816353012253
  },
  {
    "ts": 1576627200,
    "supply": 10214825441779,
    "trustlines": {
      "total": 8291,
      "authorized": 8291,
      "funded": 1017
    },
    "payments": 98,
    "payments_amount": 133775450474,
    "trades": 1736,
    "traded_amount": 2019246973537,
    "price": [
      23.13500030560252,
      23.721029502323237,
      20.949910858000706,
      21.450820571815598
    ],
    "volume": 32089279254796
  },
  {
    "ts": 1576713600,
    "supply": 10218301549729,
    "trustlines": {
      "total": 8362,
      "authorized": 8362,
      "funded": 1034
    },
    "payments": 52,
    "payments_amount": 16661030636,
    "trades": 940,
    "traded_amount": 594072801945,
    "price": [
      21.450820571815598,
      22.492583100257953,
      21.2284929,
      22.17663710919612
    ],
    "volume": 8023307461993
  },
  {
    "ts": 1576800000,
    "supply": 10216378349074,
    "trustlines": {
      "total": 8400,
      "authorized": 8400,
      "funded": 1038
    },
    "payments": 28,
    "payments_amount": 6356909111,
    "trades": 4439,
    "traded_amount": 184401514557,
    "price": [
      22.17663710919612,
      22.360830600673097,
      21.54280900001702,
      21.653937760096316
    ],
    "volume": 2500011229436
  },
  {
    "ts": 1576886400,
    "supply": 10220736033642,
    "trustlines": {
      "total": 8439,
      "authorized": 8439,
      "funded": 1044
    },
    "payments": 38,
    "payments_amount": 8387121926,
    "trades": 827,
    "traded_amount": 118176454526,
    "price": [
      21.653937760096316,
      22.065995,
      21.563636363636363,
      22.033274693234638
    ],
    "volume": 1347291734057
  },
  {
    "ts": 1576972800,
    "supply": 10218854960804,
    "trustlines": {
      "total": 8460,
      "authorized": 8460,
      "funded": 1050
    },
    "payments": 40,
    "payments_amount": 14207895681,
    "trades": 391,
    "traded_amount": 414591117079,
    "price": [
      22.033274693234638,
      22.076517201379946,
      21.12454251213075,
      21.2130835012111
    ],
    "volume": 4808430739050
  },
  {
    "ts": 1577059200,
    "supply": 10413944960804,
    "trustlines": {
      "total": 8483,
      "authorized": 8483,
      "funded": 1049
    },
    "payments": 58,
    "payments_amount": 199162819384,
    "trades": 371,
    "traded_amount": 588617900731,
    "price": [
      21.2130835012111,
      22.344020714497407,
      21.04820034593366,
      22.276229703570213
    ],
    "volume": 6172837860939
  },
  {
    "ts": 1577145600,
    "supply": 10413944960804,
    "trustlines": {
      "total": 8502,
      "authorized": 8502,
      "funded": 1056
    },
    "payments": 10,
    "payments_amount": 1097896312,
    "trades": 335,
    "traded_amount": 188443026841,
    "price": [
      22.276229703570213,
      22.496632508419797,
      21.880356951628137,
      22.2
    ],
    "volume": 2203400968273
  },
  {
    "ts": 1577232000,
    "supply": 10416594160804,
    "trustlines": {
      "total": 8513,
      "authorized": 8513,
      "funded": 1058
    },
    "payments": 16,
    "payments_amount": 3045254534,
    "trades": 2146,
    "traded_amount": 161558763447,
    "price": [
      22.2,
      22.88423768151482,
      22.2,
      22.53338315011681
    ],
    "volume": 2338704342688
  },
  {
    "ts": 1577318400,
    "supply": 10403080660583,
    "trustlines": {
      "total": 8528,
      "authorized": 8528,
      "funded": 1063
    },
    "payments": 22,
    "payments_amount": 57188191948,
    "trades": 238,
    "traded_amount": 113901513667,
    "price": [
      22.53338315011681,
      22.718512815682953,
      21.978021450977955,
      22.410972467637446
    ],
    "volume": 2055245323649
  },
  {
    "ts": 1577404800,
    "supply": 10403080660583,
    "trustlines": {
      "total": 8545,
      "authorized": 8545,
      "funded": 1065
    },
    "payments": 12,
    "payments_amount": 2436579700,
    "trades": 708,
    "traded_amount": 42756560814,
    "price": [
      22.410972467637446,
      22.725000025320867,
      21.835634960224738,
      21.926463990710687
    ],
    "volume": 804177084835
  },
  {
    "ts": 1577491200,
    "supply": 10403080660583,
    "trustlines": {
      "total": 8550,
      "authorized": 8550,
      "funded": 1065
    },
    "payments": 6,
    "payments_amount": 2391176870,
    "trades": 1583,
    "traded_amount": 78584135536,
    "price": [
      21.926463990710687,
      21.938013590391908,
      21.592268199832287,
      21.795173253117177
    ],
    "volume": 898969432091
  },
  {
    "ts": 1577577600,
    "supply": 10403080660583,
    "trustlines": {
      "total": 8558,
      "authorized": 8558,
      "funded": 1064
    },
    "payments": 7,
    "payments_amount": 457116449,
    "trades": 115,
    "traded_amount": 86131859120,
    "price": [
      21.795173253117177,
      21.880834611667147,
      21.423185014221247,
      21.57497303055799
    ],
    "volume": 919332146622
  },
  {
    "ts": 1577664000,
    "supply": 10715159735728,
    "trustlines": {
      "total": 8566,
      "authorized": 8566,
      "funded": 1060
    },
    "payments": 70,
    "payments_amount": 315836057047,
    "trades": 60,
    "traded_amount": 16022000877,
    "price": [
      21.57497303055799,
      22.072727272727274,
      21.47426629947239,
      21.98444820134237
    ],
    "volume": 328106530514
  },
  {
    "ts": 1577750400,
    "supply": 10714081890566,
    "trustlines": {
      "total": 8580,
      "authorized": 8580,
      "funded": 1062
    },
    "payments": 9,
    "payments_amount": 2471776657,
    "trades": 94,
    "traded_amount": 142157318835,
    "price": [
      21.98444820134237,
      22.4050474,
      21.976669541762348,
      22.347217233814725
    ],
    "volume": 1747746462821
  },
  {
    "ts": 1577836800,
    "supply": 10789018390566,
    "trustlines": {
      "total": 8593,
      "authorized": 8593,
      "funded": 1059
    },
    "payments": 44,
    "payments_amount": 74939101699,
    "trades": 78,
    "traded_amount": 78303417651,
    "price": [
      22.347217233814725,
      22.425650742993916,
      22.04998076467228,
      22.16808284344655
    ],
    "volume": 885110263493
  },
  {
    "ts": 1577923200,
    "supply": 10787348349511,
    "trustlines": {
      "total": 8603,
      "authorized": 8603,
      "funded": 1060
    },
    "payments": 16,
    "payments_amount": 8368887958,
    "trades": 87,
    "traded_amount": 26033630768,
    "price": [
      22.16808284344655,
      22.933832,
      22.12727272727273,
      22.933832
    ],
    "volume": 535059670324
  },
  {
    "ts": 1578009600,
    "supply": 10765831449511,
    "trustlines": {
      "total": 8617,
      "authorized": 8617,
      "funded": 1066
    },
    "payments": 16,
    "payments_amount": 30897186355,
    "trades": 240,
    "traded_amount": 274638694957,
    "price": [
      22.933832,
      23.156066198605078,
      21.94878907057816,
      22.053585802783605
    ],
    "volume": 4039696665883
  },
  {
    "ts": 1578096000,
    "supply": 10784481449511,
    "trustlines": {
      "total": 8623,
      "authorized": 8623,
      "funded": 1072
    },
    "payments": 11,
    "payments_amount": 18681600665,
    "trades": 129,
    "traded_amount": 253371589659,
    "price": [
      22.053585802783605,
      22.201000936922664,
      21.88538103529494,
      21.963636978424276
    ],
    "volume": 2951855267572
  },
  {
    "ts": 1578182400,
    "supply": 10809913849511,
    "trustlines": {
      "total": 8635,
      "authorized": 8635,
      "funded": 1070
    },
    "payments": 30,
    "payments_amount": 36235864966,
    "trades": 246,
    "traded_amount": 15972418472,
    "price": [
      21.963636978424276,
      22.131127587824107,
      21.718633229935357,
      22.129972691711565
    ],
    "volume": 330903075285
  },
  {
    "ts": 1578268800,
    "supply": 10809213849511,
    "trustlines": {
      "total": 8646,
      "authorized": 8646,
      "funded": 1093
    },
    "payments": 59,
    "payments_amount": 7583012325,
    "trades": 445,
    "traded_amount": 769020875826,
    "price": [
      22.129972691711565,
      22.129972691711565,
      19.871687032786674,
      19.871687032786674
    ],
    "volume": 8979011889870
  },
  {
    "ts": 1578355200,
    "supply": 10816678149511,
    "trustlines": {
      "total": 8654,
      "authorized": 8654,
      "funded": 1079
    },
    "payments": 110,
    "payments_amount": 12021093059,
    "trades": 524,
    "traded_amount": 402350986644,
    "price": [
      19.871687032786674,
      21.05263160975401,
      19.64385528117001,
      20.62407380084615
    ],
    "volume": 4776656180047
  },
  {
    "ts": 1578441600,
    "supply": 10878264155879,
    "trustlines": {
      "total": 8674,
      "authorized": 8674,
      "funded": 1076
    },
    "payments": 42,
    "payments_amount": 68486814467,
    "trades": 357,
    "traded_amount": 305288198755,
    "price": [
      20.62407380084615,
      21.343216801752412,
      20.181818181818183,
      20.934210052506902
    ],
    "volume": 5394492631439
  },
  {
    "ts": 1578528000,
    "supply": 10884372730894,
    "trustlines": {
      "total": 8691,
      "authorized": 8691,
      "funded": 1085
    },
    "payments": 50,
    "payments_amount": 10920844021,
    "trades": 471,
    "traded_amount": 97801527171,
    "price": [
      20.934210052506902,
      21.360688865533653,
      20.833399053627762,
      21.16110958699811
    ],
    "volume": 1988197261415
  },
  {
    "ts": 1578614400,
    "supply": 10893093530894,
    "trustlines": {
      "total": 8697,
      "authorized": 8697,
      "funded": 1082
    },
    "payments": 26,
    "payments_amount": 10175183712,
    "trades": 376,
    "traded_amount": 562845076182,
    "price": [
      21.16110958699811,
      21.915117883736794,
      20.861965872317153,
      21.173297084648723
    ],
    "volume": 7540693450943
  },
  {
    "ts": 1578700800,
    "supply": 10942228870510,
    "trustlines": {
      "total": 8710,
      "authorized": 8710,
      "funded": 1064
    },
    "payments": 53,
    "payments_amount": 53527015364,
    "trades": 168,
    "traded_amount": 44906813418,
    "price": [
      21.173297084648723,
      21.173297084648723,
      20.244223617547316,
      20.719603000147508
    ],
    "volume": 716825475231
  },
  {
    "ts": 1578787200,
    "supply": 10942228870510,
    "trustlines": {
      "total": 8724,
      "authorized": 8724,
      "funded": 1070
    },
    "payments": 11,
    "payments_amount": 490558503,
    "trades": 162,
    "traded_amount": 466372425211,
    "price": [
      20.719603000147508,
      20.778354253835424,
      20.311497140763972,
      20.466513751154306
    ],
    "volume": 3134292034221
  },
  {
    "ts": 1578873600,
    "supply": 10979878962542,
    "trustlines": {
      "total": 8722,
      "authorized": 8722,
      "funded": 1062
    },
    "payments": 80,
    "payments_amount": 45311798933,
    "trades": 300,
    "traded_amount": 529050636944,
    "price": [
      20.466513751154306,
      21.048120610141353,
      20.377721440879338,
      20.826304500001736
    ],
    "volume": 4501014893153
  },
  {
    "ts": 1578960000,
    "supply": 10979714362542,
    "trustlines": {
      "total": 8722,
      "authorized": 8722,
      "funded": 1097
    },
    "payments": 207,
    "payments_amount": 88244199048,
    "trades": 1486,
    "traded_amount": 3070873697534,
    "price": [
      20.826304500001736,
      20.85718647649466,
      18.12262619997153,
      18.906808300707915
    ],
    "volume": 30585563655723
  },
  {
    "ts": 1579046400,
    "supply": 10991168762542,
    "trustlines": {
      "total": 8731,
      "authorized": 8731,
      "funded": 1121
    },
    "payments": 154,
    "payments_amount": 49413250291,
    "trades": 1205,
    "traded_amount": 3143290842952,
    "price": [
      18.906808300707915,
      19.362733700147807,
      17.754799074902014,
      18.272182066780164
    ],
    "volume": 26942981297660
  },
  {
    "ts": 1579132800,
    "supply": 10996226230275,
    "trustlines": {
      "total": 8746,
      "authorized": 8746,
      "funded": 1127
    },
    "payments": 111,
    "payments_amount": 37957024256,
    "trades": 4850,
    "traded_amount": 22995344235614,
    "price": [
      18.272182066780164,
      19.169280101571257,
      18.20676120013686,
      18.638738570366634
    ],
    "volume": 208172401564709
  },
  {
    "ts": 1579219200,
    "supply": 11251912230275,
    "trustlines": {
      "total": 8763,
      "authorized": 8763,
      "funded": 1125
    },
    "payments": 119,
    "payments_amount": 380528550663,
    "trades": 2761,
    "traded_amount": 10106422923025,
    "price": [
      18.638738570366634,
      18.93637570000336,
      15.551543257395148,
      16.34732099981422
    ],
    "volume": 84984328720617
  },
  {
    "ts": 1579305600,
    "supply": 11263012230275,
    "trustlines": {
      "total": 8782,
      "authorized": 8782,
      "funded": 1123
    },
    "payments": 112,
    "payments_amount": 13361675080,
    "trades": 1556,
    "traded_amount": 3887771117688,
    "price": [
      16.34732099981422,
      17.38119810036429,
      15.17660250002526,
      16.203929899097187
    ],
    "volume": 43271653348170
  },
  {
    "ts": 1579392000,
    "supply": 11262797912910,
    "trustlines": {
      "total": 8797,
      "authorized": 8797,
      "funded": 1133
    },
    "payments": 63,
    "payments_amount": 17178849399,
    "trades": 1174,
    "traded_amount": 2117888858140,
    "price": [
      16.203929899097187,
      17.13490650166894,
      15.110076899364534,
      16.604317499953243
    ],
    "volume": 24282543412707
  },
  {
    "ts": 1579478400,
    "supply": 11262797912910,
    "trustlines": {
      "total": 8811,
      "authorized": 8811,
      "funded": 1143
    },
    "payments": 61,
    "payments_amount": 995624185,
    "trades": 1315,
    "traded_amount": 2336467315201,
    "price": [
      16.604317499953243,
      16.89870391342645,
      15.450935600006995,
      15.984040447111138
    ],
    "volume": 26200119720462
  },
  {
    "ts": 1579564800,
    "supply": 11277458712910,
    "trustlines": {
      "total": 8823,
      "authorized": 8823,
      "funded": 1148
    },
    "payments": 42,
    "payments_amount": 18190496907,
    "trades": 888,
    "traded_amount": 712260710346,
    "price": [
      15.984040447111138,
      16.27918310023319,
      15.49479793522159,
      15.928171251776904
    ],
    "volume": 8139498894118
  },
  {
    "ts": 1579651200,
    "supply": 11279458712910,
    "trustlines": {
      "total": 8838,
      "authorized": 8838,
      "funded": 1150
    },
    "payments": 16,
    "payments_amount": 2334615677,
    "trades": 398,
    "traded_amount": 568195260541,
    "price": [
      15.928171251776904,
      16.444231000173477,
      15.817500100012223,
      16.385802200735334
    ],
    "volume": 5880456314726
  },
  {
    "ts": 1579737600,
    "supply": 11285214112910,
    "trustlines": {
      "total": 8843,
      "authorized": 8843,
      "funded": 1154
    },
    "payments": 52,
    "payments_amount": 11770283997,
    "trades": 600,
    "traded_amount": 1383083034501,
    "price": [
      16.385802200735334,
      17.603035700430954,
      16.378943230491537,
      17.21170395869191
    ],
    "volume": 11935172636712
  },
  {
    "ts": 1579824000,
    "supply": 11372876812907,
    "trustlines": {
      "total": 8858,
      "authorized": 8858,
      "funded": 1135
    },
    "payments": 60,
    "payments_amount": 95438466598,
    "trades": 652,
    "traded_amount": 842166363969,
    "price": [
      17.21170395869191,
      18.59000000543664,
      17.160694500364077,
      17.5917554004322
    ],
    "volume": 9323636331473
  },
  {
    "ts": 1579910400,
    "supply": 11455570093800,
    "trustlines": {
      "total": 8867,
      "authorized": 8867,
      "funded": 1140
    },
    "payments": 23,
    "payments_amount": 89179857101,
    "trades": 247,
    "traded_amount": 138001289006,
    "price": [
      17.5917554004322,
      17.962641298627116,
      17.5917554004322,
      17.827272727272728
    ],
    "volume": 2400775243977
  },
  {
    "ts": 1579996800,
    "supply": 11464550093800,
    "trustlines": {
      "total": 8878,
      "authorized": 8878,
      "funded": 1139
    },
    "payments": 4,
    "payments_amount": 9242808270,
    "trades": 91,
    "traded_amount": 47797705259,
    "price": [
      17.827272727272728,
      17.91844955239713,
      17.112270123419663,
      17.163636363636364
    ],
    "volume": 756344152758
  },
  {
    "ts": 1580083200,
    "supply": 11464717793800,
    "trustlines": {
      "total": 8884,
      "authorized": 8884,
      "funded": 1148
    },
    "payments": 53,
    "payments_amount": 55227828860,
    "trades": 375,
    "traded_amount": 295669795890,
    "price": [
      17.163636363636364,
      17.365835100379165,
      16.490909090909092,
      16.815042072242864
    ],
    "volume": 2462268932347
  },
  {
    "ts": 1580169600,
    "supply": 11484985493680,
    "trustlines": {
      "total": 8897,
      "authorized": 8897,
      "funded": 1147
    },
    "payments": 29,
    "payments_amount": 21046022888,
    "trades": 511,
    "traded_amount": 839989938044,
    "price": [
      16.815042072242864,
      16.894426700096556,
      16.081818181818182,
      16.405220347879563
    ],
    "volume": 8775528499237
  },
  {
    "ts": 1580256000,
    "supply": 11494974098420,
    "trustlines": {
      "total": 8916,
      "authorized": 8916,
      "funded": 1142
    },
    "payments": 50,
    "payments_amount": 17282455893,
    "trades": 477,
    "traded_amount": 983547711225,
    "price": [
      16.405220347879563,
      16.62727272727273,
      15.938001043747999,
      16.439419094886983
    ],
    "volume": 9685844296846
  },
  {
    "ts": 1580342400,
    "supply": 11596062098416,
    "trustlines": {
      "total": 8921,
      "authorized": 8921,
      "funded": 1151
    },
    "payments": 39,
    "payments_amount": 281972656541,
    "trades": 525,
    "traded_amount": 924982843412,
    "price": [
      16.439419094886983,
      16.77629390007224,
      15.897088606893677,
      15.923668302764144
    ],
    "volume": 7675535897467
  },
  {
    "ts": 1580428800,
    "supply": 11589301676262,
    "trustlines": {
      "total": 8936,
      "authorized": 8936,
      "funded": 1150
    },
    "payments": 11,
    "payments_amount": 20281624401,
    "trades": 575,
    "traded_amount": 137899519800,
    "price": [
      15.923668302764144,
      16.70459393680198,
      15.909090909090908,
      16.536572574256883
    ],
    "volume": 2044937154954
  },
  {
    "ts": 1580515200,
    "supply": 11668231276256,
    "trustlines": {
      "total": 8955,
      "authorized": 8955,
      "funded": 1157
    },
    "payments": 37,
    "payments_amount": 78979599994,
    "trades": 149,
    "traded_amount": 174304607358,
    "price": [
      16.536572574256883,
      16.536572574256883,
      16.017786149740672,
      16.137604720959814
    ],
    "volume": 2191412338949
  },
  {
    "ts": 1580601600,
    "supply": 11667334876256,
    "trustlines": {
      "total": 8968,
      "authorized": 8968,
      "funded": 1159
    },
    "payments": 3,
    "payments_amount": 1353300000,
    "trades": 467,
    "traded_amount": 538139622028,
    "price": [
      16.137604720959814,
      16.375883702988457,
      15.16997937742361,
      15.754978109917255
    ],
    "volume": 5689878055548
  },
  {
    "ts": 1580688000,
    "supply": 11670598159656,
    "trustlines": {
      "total": 8979,
      "authorized": 8979,
      "funded": 1158
    },
    "payments": 18,
    "payments_amount": 10487800467,
    "trades": 578,
    "traded_amount": 533676581685,
    "price": [
      15.754978109917255,
      15.931839229486942,
      15.212896881334816,
      15.527272727272727
    ],
    "volume": 5638899859561
  },
  {
    "ts": 1580774400,
    "supply": 11674376946680,
    "trustlines": {
      "total": 8989,
      "authorized": 8989,
      "funded": 1164
    },
    "payments": 10,
    "payments_amount": 7713638776,
    "trades": 350,
    "traded_amount": 240738677045,
    "price": [
      15.527272727272727,
      16.263636363636362,
      14.847809948032666,
      15.174346144812116
    ],
    "volume": 3436865690814
  },
  {
    "ts": 1580860800,
    "supply": 11676131746680,
    "trustlines": {
      "total": 9009,
      "authorized": 9009,
      "funded": 1167
    },
    "payments": 11,
    "payments_amount": 6723381220,
    "trades": 362,
    "traded_amount": 743620007939,
    "price": [
      15.174346144812116,
      15.174346144812116,
      14.390456248491892,
      14.529741431219177
    ],
    "volume": 10786148834691
  },
  {
    "ts": 1580947200,
    "supply": 11668961678258,
    "trustlines": {
      "total": 9023,
      "authorized": 9023,
      "funded": 1178
    },
    "payments": 17,
    "payments_amount": 100683478936,
    "trades": 704,
    "traded_amount": 1943765589834,
    "price": [
      14.529741431219177,
      14.814441709535647,
      13.999994399863636,
      14.136070306711133
    ],
    "volume": 26302872982475
  },
  {
    "ts": 1581033600,
    "supply": 11726409978257,
    "trustlines": {
      "total": 9045,
      "authorized": 9045,
      "funded": 1190
    },
    "payments": 44,
    "payments_amount": 69320609131,
    "trades": 378,
    "traded_amount": 1026190586520,
    "price": [
      14.136070306711133,
      14.201882603877527,
      13.643067343535938,
      13.924963924963924
    ],
    "volume": 11832571679455
  },
  {
    "ts": 1581120000,
    "supply": 11731659978257,
    "trustlines": {
      "total": 9058,
      "authorized": 9058,
      "funded": 1192
    },
    "payments": 7,
    "payments_amount": 11941423888,
    "trades": 266,
    "traded_amount": 319033296786,
    "price": [
      13.924963924963924,
      14.818320300341972,
      13.906793911237799,
      14.027272727272727
    ],
    "volume": 3441021231162
  },
  {
    "ts": 1581206400,
    "supply": 11731659978257,
    "trustlines": {
      "total": 9077,
      "authorized": 9077,
      "funded": 1175
    },
    "payments": 25,
    "payments_amount": 1989045932,
    "trades": 175,
    "traded_amount": 458159474715,
    "price": [
      14.027272727272727,
      14.082428600156568,
      13.535114824967764,
      13.744387348585937
    ],
    "volume": 5924752402736
  },
  {
    "ts": 1581292800,
    "supply": 11699155673907,
    "trustlines": {
      "total": 9087,
      "authorized": 9087,
      "funded": 1177
    },
    "payments": 29,
    "payments_amount": 70943925055,
    "trades": 713,
    "traded_amount": 1373907758377,
    "price": [
      13.744387348585937,
      14.586785539198353,
      13.618201099942675,
      14.178605100653867
    ],
    "volume": 14816283944879
  },
  {
    "ts": 1581379200,
    "supply": 11711307673906,
    "trustlines": {
      "total": 9105,
      "authorized": 9105,
      "funded": 1180
    },
    "payments": 16,
    "payments_amount": 18971199999,
    "trades": 445,
    "traded_amount": 583671816363,
    "price": [
      14.178605100653867,
      14.521651560926486,
      13.36898395706438,
      13.48215824125744
    ],
    "volume": 6197694179454
  },
  {
    "ts": 1581465600,
    "supply": 12167218773906,
    "trustlines": {
      "total": 9119,
      "authorized": 9119,
      "funded": 1189
    },
    "payments": 54,
    "payments_amount": 865907102845,
    "trades": 1387,
    "traded_amount": 911868156333,
    "price": [
      13.48215824125744,
      13.573622602096654,
      12.299999934933824,
      12.684832320708752
    ],
    "volume": 8660967671933
  },
  {
    "ts": 1581552000,
    "supply": 12689658773906,
    "trustlines": {
      "total": 9155,
      "authorized": 9155,
      "funded": 1217
    },
    "payments": 18,
    "payments_amount": 1024796263753,
    "trades": 1443,
    "traded_amount": 511980912065,
    "price": [
      12.684832320708752,
      13.280177150778915,
      11.554155196145905,
      11.99090909090909
    ],
    "volume": 4510711365313
  },
  {
    "ts": 1581638400,
    "supply": 12688815538535,
    "trustlines": {
      "total": 9167,
      "authorized": 9167,
      "funded": 1219
    },
    "payments": 12,
    "payments_amount": 5247774390,
    "trades": 406,
    "traded_amount": 106595807698,
    "price": [
      11.99090909090909,
      12.461507866816806,
      11.535714285714286,
      11.607511218557555
    ],
    "volume": 1132529480657
  },
  {
    "ts": 1581724800,
    "supply": 12764632038483,
    "trustlines": {
      "total": 9181,
      "authorized": 9181,
      "funded": 1226
    },
    "payments": 38,
    "payments_amount": 92740987932,
    "trades": 629,
    "traded_amount": 376803351967,
    "price": [
      11.607511218557555,
      13.13180403682126,
      11.37250490108715,
      12.666666666666666
    ],
    "volume": 3848844983005
  },
  {
    "ts": 1581811200,
    "supply": 12764632038483,
    "trustlines": {
      "total": 9190,
      "authorized": 9190,
      "funded": 1223
    },
    "payments": 3,
    "payments_amount": 20549087,
    "trades": 731,
    "traded_amount": 595107203137,
    "price": [
      12.666666666666666,
      14.912152513421598,
      12.37527271982358,
      13.430375200257402
    ],
    "volume": 8004889993121
  },
  {
    "ts": 1581897600,
    "supply": 12813738565776,
    "trustlines": {
      "total": 9205,
      "authorized": 9205,
      "funded": 1225
    },
    "payments": 15,
    "payments_amount": 57269582500,
    "trades": 543,
    "traded_amount": 251691019319,
    "price": [
      13.430375200257402,
      14.867023990618314,
      13.293965469480142,
      13.74865263204206
    ],
    "volume": 3523353162064
  },
  {
    "ts": 1581984000,
    "supply": 12943266465776,
    "trustlines": {
      "total": 9216,
      "authorized": 9216,
      "funded": 1224
    },
    "payments": 24,
    "payments_amount": 131016286381,
    "trades": 603,
    "traded_amount": 196727725214,
    "price": [
      13.74865263204206,
      14.200061912294702,
      13.063636363636364,
      13.144715428160913
    ],
    "volume": 2292508833604
  },
  {
    "ts": 1582070400,
    "supply": 12943266465776,
    "trustlines": {
      "total": 9228,
      "authorized": 9228,
      "funded": 1225
    },
    "payments": 15,
    "payments_amount": 281758716,
    "trades": 315,
    "traded_amount": 183723398103,
    "price": [
      13.144715428160913,
      14.509050019613504,
      12.737945279028043,
      14.3
    ],
    "volume": 2043116092147
  },
  {
    "ts": 1582156800,
    "supply": 12995344289175,
    "trustlines": {
      "total": 9239,
      "authorized": 9239,
      "funded": 1222
    },
    "payments": 53,
    "payments_amount": 60541469983,
    "trades": 374,
    "traded_amount": 200222874779,
    "price": [
      14.3,
      14.8269975,
      14.02634719012876,
      14.418181818181818
    ],
    "volume": 2522088233740
  },
  {
    "ts": 1582243200,
    "supply": 13275596838498,
    "trustlines": {
      "total": 9326,
      "authorized": 9326,
      "funded": 1228
    },
    "payments": 58,
    "payments_amount": 496116945624,
    "trades": 135,
    "traded_amount": 69995953698,
    "price": [
      14.418181818181818,
      14.491905592499727,
      13.916053295523259,
      14.268021938510532
    ],
    "volume": 788405185077
  },
  {
    "ts": 1582329600,
    "supply": 13275596838498,
    "trustlines": {
      "total": 9338,
      "authorized": 9338,
      "funded": 1231
    },
    "payments": 9,
    "payments_amount": 10059778064,
    "trades": 198,
    "traded_amount": 19488598566,
    "price": [
      14.268021938510532,
      14.5,
      14.1,
      14.201116206738208
    ],
    "volume": 35829109240
  },
  {
    "ts": 1582416000,
    "supply": 13275596838498,
    "trustlines": {
      "total": 9351,
      "authorized": 9351,
      "funded": 1231
    },
    "payments": 11,
    "payments_amount": 458246831,
    "trades": 173,
    "traded_amount": 2945932893,
    "price": [
      14.201116206738208,
      14.336363636363636,
      13.747459605026929,
      13.807162469141655
    ],
    "volume": 21223464319
  },
  {
    "ts": 1582502400,
    "supply": 13275075474024,
    "trustlines": {
      "total": 9361,
      "authorized": 9361,
      "funded": 1236
    },
    "payments": 10,
    "payments_amount": 4575227484,
    "trades": 246,
    "traded_amount": 60247606493,
    "price": [
      13.807162469141655,
      14.85000001209646,
      13.807162469141655,
      14.662735022812733
    ],
    "volume": 557944441017
  },
  {
    "ts": 1582588800,
    "supply": 13338625474024,
    "trustlines": {
      "total": 9371,
      "authorized": 9371,
      "funded": 1236
    },
    "payments": 23,
    "payments_amount": 66564711561,
    "trades": 231,
    "traded_amount": 134231924278,
    "price": [
      14.662735022812733,
      15.830725112207283,
      14.662732239921374,
      15.554545454545455
    ],
    "volume": 1751184406827
  },
  {
    "ts": 1582675200,
    "supply": 13340208674022,
    "trustlines": {
      "total": 9382,
      "authorized": 9382,
      "funded": 1230
    },
    "payments": 32,
    "payments_amount": 4647541501,
    "trades": 353,
    "traded_amount": 255604056971,
    "price": [
      15.554545454545455,
      17.754263242525724,
      15.552380952380952,
      17.38565020013796
    ],
    "volume": 4241293828036
  },
  {
    "ts": 1582761600,
    "supply": 13477064083345,
    "trustlines": {
      "total": 9393,
      "authorized": 9393,
      "funded": 1231
    },
    "payments": 53,
    "payments_amount": 239881519196,
    "trades": 481,
    "traded_amount": 797684326917,
    "price": [
      17.38565020013796,
      17.857142857142858,
      16.003456746618365,
      16.829067045964752
    ],
    "volume": 8110942232198
  },
  {
    "ts": 1582848000,
    "supply": 13964214083345,
    "trustlines": {
      "total": 9408,
      "authorized": 9408,
      "funded": 1237
    },
    "payments": 22,
    "payments_amount": 887221740571,
    "trades": 530,
    "traded_amount": 371232269010,
    "price": [
      16.829067045964752,
      17.857142912038523,
      16.45373807981134,
      17.347624565469292
    ],
    "volume": 5141962216941
  },
  {
    "ts": 1582934400,
    "supply": 13964214083345,
    "trustlines": {
      "total": 9415,
      "authorized": 9415,
      "funded": 1241
    },
    "payments": 10,
    "payments_amount": 1421200378,
    "trades": 174,
    "traded_amount": 172694887268,
    "price": [
      17.347624565469292,
      17.84140418891858,
      16.796759213516452,
      17.533247418999157
    ],
    "volume": 2449138055449
  },
  {
    "ts": 1583020800,
    "supply": 13964214083345,
    "trustlines": {
      "total": 9415,
      "authorized": 9415,
      "funded": 1221
    },
    "payments": 23,
    "payments_amount": 149596716,
    "trades": 194,
    "traded_amount": 85399119031,
    "price": [
      17.533247418999157,
      18.379999120895683,
      17.149216994639577,
      17.99972594487175
    ],
    "volume": 1501820731306
  },
  {
    "ts": 1583107200,
    "supply": 13964214083345,
    "trustlines": {
      "total": 9435,
      "authorized": 9435,
      "funded": 1226
    },
    "payments": 39,
    "payments_amount": 738570949,
    "trades": 229,
    "traded_amount": 134686474277,
    "price": [
      17.99972594487175,
      18.314112700039214,
      16.799999999194917,
      17.19604700463898
    ],
    "volume": 2266948539497
  },
  {
    "ts": 1583193600,
    "supply": 14441373883341,
    "trustlines": {
      "total": 9450,
      "authorized": 9450,
      "funded": 1235
    },
    "payments": 80,
    "payments_amount": 9038431525421,
    "trades": 319,
    "traded_amount": 95033237095,
    "price": [
      17.19604700463898,
      17.51818181818182,
      16.79999777995847,
      17.49909566311485
    ],
    "volume": 761299870737
  },
  {
    "ts": 1583280000,
    "supply": 14406333883341,
    "trustlines": {
      "total": 9463,
      "authorized": 9463,
      "funded": 1237
    },
    "payments": 41,
    "payments_amount": 527865441587,
    "trades": 163,
    "traded_amount": 53547816884,
    "price": [
      17.49909566311485,
      17.642378466715574,
      17.125,
      17.396750318201775
    ],
    "volume": 578740666336
  },
  {
    "ts": 1583366400,
    "supply": 14468508683338,
    "trustlines": {
      "total": 9486,
      "authorized": 9486,
      "funded": 1239
    },
    "payments": 42,
    "payments_amount": 77624124450,
    "trades": 286,
    "traded_amount": 131374288372,
    "price": [
      17.396750318201775,
      17.396750318201775,
      16.569953361395022,
      16.8354136292
    ],
    "volume": 1682572710407
  },
  {
    "ts": 1583452800,
    "supply": 14485128083338,
    "trustlines": {
      "total": 9501,
      "authorized": 9501,
      "funded": 1249
    },
    "payments": 30,
    "payments_amount": 20625634948,
    "trades": 265,
    "traded_amount": 70449763895,
    "price": [
      16.8354136292,
      17,
      16.453742232894175,
      16.453742232894175
    ],
    "volume": 1099151774431
  },
  {
    "ts": 1583539200,
    "supply": 14498931246767,
    "trustlines": {
      "total": 9511,
      "authorized": 9511,
      "funded": 1257
    },
    "payments": 122,
    "payments_amount": 44445342891,
    "trades": 298,
    "traded_amount": 111266462051,
    "price": [
      16.453742232894175,
      17.524613300026918,
      16.386377445332226,
      17.15145231204474
    ],
    "volume": 1598138557768
  },
  {
    "ts": 1583625600,
    "supply": 14503186246767,
    "trustlines": {
      "total": 9525,
      "authorized": 9525,
      "funded": 1252
    },
    "payments": 19,
    "payments_amount": 4348017937,
    "trades": 219,
    "traded_amount": 64791608599,
    "price": [
      17.15145231204474,
      20.072727272727274,
      17.15145231204474,
      20.03759050829669
    ],
    "volume": 849143016510
  },
  {
    "ts": 1583712000,
    "supply": 14513226646767,
    "trustlines": {
      "total": 9530,
      "authorized": 9530,
      "funded": 1250
    },
    "payments": 22,
    "payments_amount": 13791434343,
    "trades": 337,
    "traded_amount": 373538317492,
    "price": [
      20.03759050829669,
      20.955676464262382,
      19.39330000562118,
      19.4944
    ],
    "volume": 4264297678169
  },
  {
    "ts": 1583798400,
    "supply": 14555726646767,
    "trustlines": {
      "total": 9541,
      "authorized": 9541,
      "funded": 1253
    },
    "payments": 10,
    "payments_amount": 42792146583,
    "trades": 179,
    "traded_amount": 154370564135,
    "price": [
      19.4944,
      19.731110290448644,
      18.85717949394873,
      19.272093009677096
    ],
    "volume": 1578996829529
  },
  {
    "ts": 1583884800,
    "supply": 14603508904372,
    "trustlines": {
      "total": 9554,
      "authorized": 9554,
      "funded": 1257
    },
    "payments": 44,
    "payments_amount": 90995459594,
    "trades": 259,
    "traded_amount": 479925039048,
    "price": [
      19.272093009677096,
      20.90567505853224,
      19.056294170915436,
      19.77394426
    ],
    "volume": 4508322948667
  },
  {
    "ts": 1583971200,
    "supply": 14632388504366,
    "trustlines": {
      "total": 9561,
      "authorized": 9561,
      "funded": 1251
    },
    "payments": 40,
    "payments_amount": 161240338942,
    "trades": 832,
    "traded_amount": 505551084792,
    "price": [
      19.77394426,
      30.984789620820965,
      19.773942296360577,
      30.76752558418614
    ],
    "volume": 7171735806156
  },
  {
    "ts": 1584057600,
    "supply": 15033148704366,
    "trustlines": {
      "total": 9576,
      "authorized": 9576,
      "funded": 1255
    },
    "payments": 73,
    "payments_amount": 912716841470,
    "trades": 2416,
    "traded_amount": 173233222539,
    "price": [
      30.76752558418614,
      36.31385305957461,
      19.8,
      24.911067341765758
    ],
    "volume": 3476706829536
  },
  {
    "ts": 1584144000,
    "supply": 15047970304365,
    "trustlines": {
      "total": 9589,
      "authorized": 9589,
      "funded": 1249
    },
    "payments": 34,
    "payments_amount": 16265373361,
    "trades": 587,
    "traded_amount": 445699674732,
    "price": [
      24.911067341765758,
      27.23000000001367,
      24.911067341765758,
      26.785594707166485
    ],
    "volume": 8172300772550
  },
  {
    "ts": 1584230400,
    "supply": 15047970304365,
    "trustlines": {
      "total": 9599,
      "authorized": 9599,
      "funded": 1254
    },
    "payments": 6,
    "payments_amount": 1149265161,
    "trades": 667,
    "traded_amount": 323478161341,
    "price": [
      26.785594707166485,
      26.785594707166485,
      20,
      25.282658159511303
    ],
    "volume": 5032060440563
  },
  {
    "ts": 1584316800,
    "supply": 15232043404364,
    "trustlines": {
      "total": 9600,
      "authorized": 9600,
      "funded": 1248
    },
    "payments": 27,
    "payments_amount": 184341023820,
    "trades": 622,
    "traded_amount": 105380390566,
    "price": [
      25.282658159511303,
      30.441485371342836,
      25.282658159511303,
      27.698222281213155
    ],
    "volume": 1602705225689
  },
  {
    "ts": 1584403200,
    "supply": 15309855304363,
    "trustlines": {
      "total": 9620,
      "authorized": 9620,
      "funded": 1252
    },
    "payments": 42,
    "payments_amount": 80423219808,
    "trades": 274,
    "traded_amount": 225336064999,
    "price": [
      27.698222281213155,
      27.778357235984355,
      25.30722975626818,
      25.598691550867226
    ],
    "volume": 4057330374981
  },
  {
    "ts": 1584489600,
    "supply": 15392376804356,
    "trustlines": {
      "total": 9634,
      "authorized": 9634,
      "funded": 1253
    },
    "payments": 41,
    "payments_amount": 84617304427,
    "trades": 681,
    "traded_amount": 396648097004,
    "price": [
      25.598691550867226,
      28.4879173,
      25.598691550867226,
      27.128208
    ],
    "volume": 6471920919860
  },
  {
    "ts": 1584576000,
    "supply": 15414917904356,
    "trustlines": {
      "total": 9647,
      "authorized": 9647,
      "funded": 1262
    },
    "payments": 29,
    "payments_amount": 26679597610,
    "trades": 656,
    "traded_amount": 523345509714,
    "price": [
      27.128208,
      27.128208,
      22.78428520555592,
      23.43380184952978
    ],
    "volume": 6519236163930
  },
  {
    "ts": 1584662400,
    "supply": 15590789504356,
    "trustlines": {
      "total": 9668,
      "authorized": 9668,
      "funded": 1259
    },
    "payments": 47,
    "payments_amount": 281255633532,
    "trades": 904,
    "traded_amount": 621840928331,
    "price": [
      23.43380184952978,
      27.092928734367764,
      21.908876588930024,
      24.53253258632547
    ],
    "volume": 8700890119306
  },
  {
    "ts": 1584748800,
    "supply": 15590939504356,
    "trustlines": {
      "total": 9703,
      "authorized": 9703,
      "funded": 1259
    },
    "payments": 9,
    "payments_amount": 3468642075,
    "trades": 1132,
    "traded_amount": 962224177334,
    "price": [
      24.53253258632547,
      25.670975108270234,
      24.097082318547873,
      24.90591207909151
    ],
    "volume": 12336935987620
  },
  {
    "ts": 1584835200,
    "supply": 15590939504356,
    "trustlines": {
      "total": 9720,
      "authorized": 9720,
      "funded": 1258
    },
    "payments": 12,
    "payments_amount": 12800579375,
    "trades": 741,
    "traded_amount": 284600260264,
    "price": [
      24.90591207909151,
      26.69090909090909,
      24.370260465882332,
      26.69090909090909
    ],
    "volume": 4022225653207
  },
  {
    "ts": 1584921600,
    "supply": 15604789404356,
    "trustlines": {
      "total": 9726,
      "authorized": 9726,
      "funded": 1262
    },
    "payments": 20,
    "payments_amount": 31071498690,
    "trades": 427,
    "traded_amount": 188063187681,
    "price": [
      26.69090909090909,
      26.92323142814946,
      24.659756988587013,
      25.10034134195208
    ],
    "volume": 2716896683162
  },
  {
    "ts": 1585008000,
    "supply": 11523756080407,
    "trustlines": {
      "total": 9736,
      "authorized": 9736,
      "funded": 1271
    },
    "payments": 31,
    "payments_amount": 4183455554420,
    "trades": 472,
    "traded_amount": 352114493322,
    "price": [
      25.10034134195208,
      25.52446392244647,
      24.258522727272727,
      24.819940476190474
    ],
    "volume": 5911973757782
  },
  {
    "ts": 1585094400,
    "supply": 11705307217900,
    "trustlines": {
      "total": 9743,
      "authorized": 9743,
      "funded": 1269
    },
    "payments": 62,
    "payments_amount": 184101766433,
    "trades": 185,
    "traded_amount": 11084363422,
    "price": [
      24.819940476190474,
      25.519727978443576,
      24.44501669818533,
      24.8
    ],
    "volume": 165985669063
  },
  {
    "ts": 1585180800,
    "supply": 11713157217900,
    "trustlines": {
      "total": 9748,
      "authorized": 9748,
      "funded": 1274
    },
    "payments": 27,
    "payments_amount": 259694582946,
    "trades": 112,
    "traded_amount": 11591318308,
    "price": [
      24.8,
      25.06297075477581,
      23.390390290704822,
      23.390390290704822
    ],
    "volume": 189770088893
  },
  {
    "ts": 1585267200,
    "supply": 11763157217900,
    "trustlines": {
      "total": 9748,
      "authorized": 9748,
      "funded": 1275
    },
    "payments": 14,
    "payments_amount": 650546945047,
    "trades": 384,
    "traded_amount": 301311680426,
    "price": [
      23.390390290704822,
      24.791933620404777,
      23.329165200432694,
      24.791933620404777
    ],
    "volume": 5233782336795
  },
  {
    "ts": 1585353600,
    "supply": 11763157217900,
    "trustlines": {
      "total": 9760,
      "authorized": 9760,
      "funded": 1271
    },
    "payments": 2,
    "payments_amount": 183801181,
    "trades": 81,
    "traded_amount": 7409689720,
    "price": [
      24.791933620404777,
      26.1,
      24.791933620404777,
      25.019890807133663
    ],
    "volume": 184343479072
  },
  {
    "ts": 1585440000,
    "supply": 11763157217900,
    "trustlines": {
      "total": 9774,
      "authorized": 9774,
      "funded": 1270
    },
    "payments": 6,
    "payments_amount": 213866353,
    "trades": 46,
    "traded_amount": 10412557165,
    "price": [
      25.019890807133663,
      26.727272727272727,
      25.019890807133663,
      26.727272727272727
    ],
    "volume": 266481576833
  },
  {
    "ts": 1585526400,
    "supply": 11362406806204,
    "trustlines": {
      "total": 9782,
      "authorized": 9782,
      "funded": 1271
    },
    "payments": 13,
    "payments_amount": 1528066695004,
    "trades": 136,
    "traded_amount": 5189537206,
    "price": [
      26.727272727272727,
      26.727272727272727,
      24.563636363636363,
      25.418466176545532
    ],
    "volume": 112487033198
  },
  {
    "ts": 1585612800,
    "supply": 11362406806204,
    "trustlines": {
      "total": 9793,
      "authorized": 9793,
      "funded": 1272
    },
    "payments": 6,
    "payments_amount": 272400022,
    "trades": 52,
    "traded_amount": 5187566284,
    "price": [
      25.418466176545532,
      25.463304805858616,
      24.757575757575758,
      24.87568409805112
    ],
    "volume": 125347611321
  },
  {
    "ts": 1585699200,
    "supply": 11361406406204,
    "trustlines": {
      "total": 9807,
      "authorized": 9807,
      "funded": 1275
    },
    "payments": 12,
    "payments_amount": 2908632605,
    "trades": 44,
    "traded_amount": 9163413899,
    "price": [
      24.87568409805112,
      26.250001638681628,
      24.87568409805112,
      25.084598
    ],
    "volume": 195993556590
  },
  {
    "ts": 1585785600,
    "supply": 11369006406204,
    "trustlines": {
      "total": 9819,
      "authorized": 9819,
      "funded": 1288
    },
    "payments": 25,
    "payments_amount": 20244937014,
    "trades": 165,
    "traded_amount": 55065135534,
    "price": [
      25.084598,
      25.14628856,
      23.775653587301587,
      24.90480146494581
    ],
    "volume": 544037627805
  },
  {
    "ts": 1585872000,
    "supply": 11369206406204,
    "trustlines": {
      "total": 10020,
      "authorized": 10020,
      "funded": 1293
    },
    "payments": 30,
    "payments_amount": 23397584143,
    "trades": 111,
    "traded_amount": 43348384255,
    "price": [
      24.90480146494581,
      25,
      23.83040371948857,
      24.472195914611614
    ],
    "volume": 651162534938
  },
  {
    "ts": 1585958400,
    "supply": 11369359006204,
    "trustlines": {
      "total": 10313,
      "authorized": 10313,
      "funded": 1295
    },
    "payments": 19,
    "payments_amount": 5437968021,
    "trades": 328,
    "traded_amount": 77750775811,
    "price": [
      24.472195914611614,
      25.252526262631314,
      23.776237081341712,
      24.09090909090909
    ],
    "volume": 971675373332
  },
  {
    "ts": 1586044800,
    "supply": 11361859006204,
    "trustlines": {
      "total": 10655,
      "authorized": 10655,
      "funded": 1295
    },
    "payments": 8,
    "payments_amount": 9847575738,
    "trades": 265,
    "traded_amount": 10000680329,
    "price": [
      24.09090909090909,
      24.514670801350935,
      24.072776804899508,
      24.201940587689794
    ],
    "volume": 157311202009
  },
  {
    "ts": 1586131200,
    "supply": 11362859006204,
    "trustlines": {
      "total": 10845,
      "authorized": 10845,
      "funded": 1307
    },
    "payments": 17,
    "payments_amount": 501508998549,
    "trades": 729,
    "traded_amount": 305012896827,
    "price": [
      24.201940587689794,
      24.201940587689794,
      20.360997135926727,
      20.42483660111166
    ],
    "volume": 3960376101303
  },
  {
    "ts": 1586217600,
    "supply": 11362709006204,
    "trustlines": {
      "total": 11342,
      "authorized": 11342,
      "funded": 1311
    },
    "payments": 18,
    "payments_amount": 3849657970,
    "trades": 353,
    "traded_amount": 151855993107,
    "price": [
      20.42483660111166,
      21.144375888391284,
      19.640114538869934,
      20.83233908948195
    ],
    "volume": 1764238579844
  },
  {
    "ts": 1586304000,
    "supply": 11363309006204,
    "trustlines": {
      "total": 11562,
      "authorized": 11562,
      "funded": 1320
    },
    "payments": 16,
    "payments_amount": 10790297774,
    "trades": 133,
    "traded_amount": 41478415532,
    "price": [
      20.83233908948195,
      20.91923290809487,
      19.52026948625986,
      19.52026948625986
    ],
    "volume": 465400806180
  },
  {
    "ts": 1586390400,
    "supply": 11363309006204,
    "trustlines": {
      "total": 11770,
      "authorized": 11770,
      "funded": 1321
    },
    "payments": 11,
    "payments_amount": 628076086,
    "trades": 147,
    "traded_amount": 29599005144,
    "price": [
      19.52026948625986,
      20.592105382030475,
      19.223489004436388,
      19.392585480713766
    ],
    "volume": 478868668354
  },
  {
    "ts": 1586476800,
    "supply": 11365459006204,
    "trustlines": {
      "total": 11919,
      "authorized": 11919,
      "funded": 1318
    },
    "payments": 9,
    "payments_amount": 2324323488,
    "trades": 685,
    "traded_amount": 165143213827,
    "price": [
      19.392585480713766,
      21.800000090245693,
      19.21491502814369,
      21.12142071124272
    ],
    "volume": 2690188767971
  },
  {
    "ts": 1586563200,
    "supply": 11365459006204,
    "trustlines": {
      "total": 12036,
      "authorized": 12036,
      "funded": 1323
    },
    "payments": 11,
    "payments_amount": 68765016,
    "trades": 322,
    "traded_amount": 9835105582,
    "price": [
      21.12142071124272,
      21.12142071124272,
      20,
      20.27406482086468
    ],
    "volume": 165929769027
  },
  {
    "ts": 1586649600,
    "supply": 11365609006204,
    "trustlines": {
      "total": 12088,
      "authorized": 12088,
      "funded": 1324
    },
    "payments": 6,
    "payments_amount": 1616021516,
    "trades": 340,
    "traded_amount": 108345090583,
    "price": [
      20.27406482086468,
      20.756749600368654,
      19.556268261558895,
      20.265354428464274
    ],
    "volume": 1043342394874
  },
  {
    "ts": 1586736000,
    "supply": 11365609006204,
    "trustlines": {
      "total": 12169,
      "authorized": 12169,
      "funded": 1327
    },
    "payments": 11,
    "payments_amount": 122121215,
    "trades": 628,
    "traded_amount": 53836265695,
    "price": [
      20.265354428464274,
      21.732657845331264,
      20.24557735749344,
      20.678032691969687
    ],
    "volume": 861331541445
  },
  {
    "ts": 1586822400,
    "supply": 11365609006204,
    "trustlines": {
      "total": 12241,
      "authorized": 12241,
      "funded": 1325
    },
    "payments": 25,
    "payments_amount": 1978895571,
    "trades": 330,
    "traded_amount": 11819062232,
    "price": [
      20.678032691969687,
      21.09086153706318,
      20.546537895608935,
      20.971395000172844
    ],
    "volume": 178930263043
  },
  {
    "ts": 1586908800,
    "supply": 11367529006204,
    "trustlines": {
      "total": 12314,
      "authorized": 12314,
      "funded": 1323
    },
    "payments": 18,
    "payments_amount": 2262162877,
    "trades": 264,
    "traded_amount": 3959083006,
    "price": [
      20.971395000172844,
      21.87054395456456,
      20.79625870051099,
      21.87054395456456
    ],
    "volume": 57835078313
  },
  {
    "ts": 1586995200,
    "supply": 12367529006204,
    "trustlines": {
      "total": 12386,
      "authorized": 12386,
      "funded": 1326
    },
    "payments": 8,
    "payments_amount": 1001328602617,
    "trades": 334,
    "traded_amount": 73174736600,
    "price": [
      21.87054395456456,
      22.339429796897623,
      20.24999838000013,
      20.250000033644735
    ],
    "volume": 915504494178
  },
  {
    "ts": 1587081600,
    "supply": 12374279006204,
    "trustlines": {
      "total": 12428,
      "authorized": 12428,
      "funded": 1328
    },
    "payments": 18,
    "payments_amount": 10935292344,
    "trades": 118,
    "traded_amount": 32052777630,
    "price": [
      20.250000033644735,
      20.727582238419487,
      20.250000033644735,
      20.572195
    ],
    "volume": 560167383864
  },
  {
    "ts": 1587168000,
    "supply": 12374279006204,
    "trustlines": {
      "total": 12474,
      "authorized": 12474,
      "funded": 1334
    },
    "payments": 7,
    "payments_amount": 76221652,
    "trades": 116,
    "traded_amount": 13241488553,
    "price": [
      20.572195,
      20.572195,
      19.798806181169752,
      19.936363636363637
    ],
    "volume": 176724041235
  },
  {
    "ts": 1587254400,
    "supply": 12374829006204,
    "trustlines": {
      "total": 12501,
      "authorized": 12501,
      "funded": 1336
    },
    "payments": 4,
    "payments_amount": 6676650000,
    "trades": 67,
    "traded_amount": 10385183870,
    "price": [
      19.936363636363637,
      20.554545454545455,
      19.754545454545454,
      20.43790249894234
    ],
    "volume": 130414605687
  },
  {
    "ts": 1587340800,
    "supply": 12374829006204,
    "trustlines": {
      "total": 12532,
      "authorized": 12532,
      "funded": 1341
    },
    "payments": 14,
    "payments_amount": 1508098120,
    "trades": 390,
    "traded_amount": 42220878130,
    "price": [
      20.43790249894234,
      20.504786905136168,
      18.918181818181818,
      20.03004506666875
    ],
    "volume": 475206174477
  },
  {
    "ts": 1587427200,
    "supply": 12384979006204,
    "trustlines": {
      "total": 12557,
      "authorized": 12557,
      "funded": 1337
    },
    "payments": 6,
    "payments_amount": 10381241733,
    "trades": 146,
    "traded_amount": 21638499902,
    "price": [
      20.03004506666875,
      20.273331524742886,
      19.443904335990666,
      19.645454545454545
    ],
    "volume": 226419655765
  },
  {
    "ts": 1587513600,
    "supply": 12385475806204,
    "trustlines": {
      "total": 12572,
      "authorized": 12572,
      "funded": 1336
    },
    "payments": 12,
    "payments_amount": 79402396814,
    "trades": 124,
    "traded_amount": 38534476187,
    "price": [
      19.645454545454545,
      19.70156043919769,
      18.237592054245894,
      18.4119339001946
    ],
    "volume": 616204592279
  },
  {
    "ts": 1587600000,
    "supply": 12385475806204,
    "trustlines": {
      "total": 12605,
      "authorized": 12605,
      "funded": 1350
    },
    "payments": 24,
    "payments_amount": 3151197408,
    "trades": 941,
    "traded_amount": 116073437407,
    "price": [
      18.4119339001946,
      18.4119339001946,
      15.236363636363636,
      16.2879183002709
    ],
    "volume": 1462131249221
  },
  {
    "ts": 1587686400,
    "supply": 9452575106204,
    "trustlines": {
      "total": 12623,
      "authorized": 12623,
      "funded": 1360
    },
    "payments": 16,
    "payments_amount": 2951038573131,
    "trades": 173,
    "traded_amount": 36952432550,
    "price": [
      16.2879183002709,
      16.538028369333865,
      15.290543257711404,
      16.346012379703215
    ],
    "volume": 429214557410
  },
  {
    "ts": 1587772800,
    "supply": 9452575106204,
    "trustlines": {
      "total": 12642,
      "authorized": 12642,
      "funded": 1361
    },
    "payments": 6,
    "payments_amount": 1644000000,
    "trades": 88,
    "traded_amount": 4496070780,
    "price": [
      16.346012379703215,
      16.46784488607545,
      15.940909090909091,
      16.25092390113111
    ],
    "volume": 59558097663
  },
  {
    "ts": 1587859200,
    "supply": 9452975106204,
    "trustlines": {
      "total": 12667,
      "authorized": 12667,
      "funded": 1361
    },
    "payments": 4,
    "payments_amount": 437876153,
    "trades": 62,
    "traded_amount": 22005020420,
    "price": [
      16.25092390113111,
      16.572452291820106,
      15.930796609200044,
      16.3
    ],
    "volume": 350961220244
  },
  {
    "ts": 1587945600,
    "supply": 9453125106204,
    "trustlines": {
      "total": 12682,
      "authorized": 12682,
      "funded": 1361
    },
    "payments": 5,
    "payments_amount": 999506848,
    "trades": 167,
    "traded_amount": 14598535880,
    "price": [
      16.3,
      16.34328754342668,
      14.652573943867015,
      14.962421877454773
    ],
    "volume": 173347372049
  },
  {
    "ts": 1588032000,
    "supply": 9456125106204,
    "trustlines": {
      "total": 12704,
      "authorized": 12704,
      "funded": 1373
    },
    "payments": 6,
    "payments_amount": 5208600000,
    "trades": 384,
    "traded_amount": 104006578436,
    "price": [
      14.962421877454773,
      14.962421877454773,
      14.16378437311427,
      14.573583441603901
    ],
    "volume": 1113320948302
  },
  {
    "ts": 1588118400,
    "supply": 9456125106204,
    "trustlines": {
      "total": 12739,
      "authorized": 12739,
      "funded": 1378
    },
    "payments": 12,
    "payments_amount": 29888226134,
    "trades": 408,
    "traded_amount": 160843058000,
    "price": [
      14.573583441603901,
      14.717569898755794,
      13.783065569658506,
      13.873396928984857
    ],
    "volume": 1048753873920
  },
  {
    "ts": 1588204800,
    "supply": 9456125106204,
    "trustlines": {
      "total": 12752,
      "authorized": 12752,
      "funded": 1382
    },
    "payments": 30,
    "payments_amount": 39850352985,
    "trades": 1083,
    "traded_amount": 676862555056,
    "price": [
      13.873396928984857,
      16.4,
      13.587381115354939,
      14.904840045
    ],
    "volume": 5103710275011
  },
  {
    "ts": 1588291200,
    "supply": 9462360106204,
    "trustlines": {
      "total": 12766,
      "authorized": 12766,
      "funded": 1384
    },
    "payments": 15,
    "payments_amount": 8612317614,
    "trades": 375,
    "traded_amount": 65946727105,
    "price": [
      14.904840045,
      14.904840045,
      13.67035133524552,
      13.77065253607196
    ],
    "volume": 527699604368
  },
  {
    "ts": 1588377600,
    "supply": 9462360106204,
    "trustlines": {
      "total": 12860,
      "authorized": 12860,
      "funded": 1388
    },
    "payments": 3,
    "payments_amount": 2494400000,
    "trades": 211,
    "traded_amount": 40019508380,
    "price": [
      13.77065253607196,
      14.10909090909091,
      13.16083860855129,
      13.280212483399735
    ],
    "volume": 289284088909
  },
  {
    "ts": 1588464000,
    "supply": 9462360106204,
    "trustlines": {
      "total": 12800,
      "authorized": 12800,
      "funded": 1391
    },
    "payments": 8,
    "payments_amount": 9939933819,
    "trades": 195,
    "traded_amount": 93822257253,
    "price": [
      13.280212483399735,
      14.036363636363637,
      13.134580900106878,
      13.84083044982699
    ],
    "volume": 725543288408
  },
  {
    "ts": 1588550400,
    "supply": 9462360106204,
    "trustlines": {
      "total": 12804,
      "authorized": 12804,
      "funded": 1385
    },
    "payments": 21,
    "payments_amount": 8649034078,
    "trades": 557,
    "traded_amount": 105936098559,
    "price": [
      13.84083044982699,
      14.749066752801216,
      13.572727272727272,
      13.8177849
    ],
    "volume": 749317985240
  },
  {
    "ts": 1588636800,
    "supply": 9460475346634,
    "trustlines": {
      "total": 12825,
      "authorized": 12825,
      "funded": 1381
    },
    "payments": 7,
    "payments_amount": 1990193639,
    "trades": 198,
    "traded_amount": 12549996034,
    "price": [
      13.8177849,
      14.07602171333288,
      13.554545454545455,
      13.872727272727273
    ],
    "volume": 122169639897
  },
  {
    "ts": 1588723200,
    "supply": 9472091146634,
    "trustlines": {
      "total": 12843,
      "authorized": 12843,
      "funded": 1379
    },
    "payments": 22,
    "payments_amount": 25829298108,
    "trades": 155,
    "traded_amount": 44805683734,
    "price": [
      13.872727272727273,
      14.358119520847852,
      13.463636363636363,
      14.358119520847852
    ],
    "volume": 408314278523
  },
  {
    "ts": 1588809600,
    "supply": 9470863746634,
    "trustlines": {
      "total": 12871,
      "authorized": 12871,
      "funded": 1391
    },
    "payments": 29,
    "payments_amount": 14920109312,
    "trades": 272,
    "traded_amount": 47856426211,
    "price": [
      14.358119520847852,
      14.466839090869787,
      13.701107576386732,
      13.871568740211043
    ],
    "volume": 392604043549
  },
  {
    "ts": 1588896000,
    "supply": 9884633146634,
    "trustlines": {
      "total": 12895,
      "authorized": 12895,
      "funded": 1399
    },
    "payments": 30,
    "payments_amount": 700429580637,
    "trades": 188,
    "traded_amount": 19167926306,
    "price": [
      13.871568740211043,
      14.193960392884936,
      13.542963628805616,
      13.789128344079854
    ],
    "volume": 199900498146
  },
  {
    "ts": 1588982400,
    "supply": 9879929546634,
    "trustlines": {
      "total": 12929,
      "authorized": 12929,
      "funded": 1407
    },
    "payments": 19,
    "payments_amount": 30747799694,
    "trades": 123,
    "traded_amount": 34967631500,
    "price": [
      13.789128344079854,
      14.055499406187359,
      13.745454545454546,
      14.018973046681701
    ],
    "volume": 101504940705
  },
  {
    "ts": 1589068800,
    "supply": 9879929546634,
    "trustlines": {
      "total": 12982,
      "authorized": 12982,
      "funded": 1412
    },
    "payments": 27,
    "payments_amount": 35739408772,
    "trades": 414,
    "traded_amount": 151488164738,
    "price": [
      14.018973046681701,
      16.683210900539297,
      14.018973046681701,
      15.727272727272727
    ],
    "volume": 1099679929355
  },
  {
    "ts": 1589155200,
    "supply": 9879929546634,
    "trustlines": {
      "total": 13022,
      "authorized": 13022,
      "funded": 1415
    },
    "payments": 10,
    "payments_amount": 2089429010,
    "trades": 197,
    "traded_amount": 68058301739,
    "price": [
      15.727272727272727,
      16.54081860805965,
      15.495683704798719,
      16.145646653707512
    ],
    "volume": 567485058385
  },
  {
    "ts": 1589241600,
    "supply": 9930954546634,
    "trustlines": {
      "total": 13040,
      "authorized": 13040,
      "funded": 1413
    },
    "payments": 13,
    "payments_amount": 56153802446,
    "trades": 871,
    "traded_amount": 37169458644,
    "price": [
      16.145646653707512,
      16.19910650801472,
      14.321723204691102,
      14.32172374571493
    ],
    "volume": 343249886439
  },
  {
    "ts": 1589328000,
    "supply": 9934904546634,
    "trustlines": {
      "total": 13077,
      "authorized": 13077,
      "funded": 1414
    },
    "payments": 11,
    "payments_amount": 4173674094,
    "trades": 258,
    "traded_amount": 21381075965,
    "price": [
      14.32172374571493,
      14.728603410607436,
      14.188775510204081,
      14.315708057938405
    ],
    "volume": 150151966945
  },
  {
    "ts": 1589414400,
    "supply": 9933104546634,
    "trustlines": {
      "total": 13105,
      "authorized": 13105,
      "funded": 1417
    },
    "payments": 14,
    "payments_amount": 2609567994,
    "trades": 622,
    "traded_amount": 16337114983,
    "price": [
      14.315708057938405,
      14.708239938820608,
      14.254984602524356,
      14.433309809456599
    ],
    "volume": 138126105055
  },
  {
    "ts": 1589500800,
    "supply": 9933104546634,
    "trustlines": {
      "total": 13134,
      "authorized": 13134,
      "funded": 1422
    },
    "payments": 14,
    "payments_amount": 1244697225879,
    "trades": 899,
    "traded_amount": 53212666076,
    "price": [
      14.433309809456599,
      15.056884900546606,
      14.409090909090908,
      14.94590101035938
    ],
    "volume": 387586190755
  },
  {
    "ts": 1589587200,
    "supply": 9991054546634,
    "trustlines": {
      "total": 13159,
      "authorized": 13159,
      "funded": 1423
    },
    "payments": 4,
    "payments_amount": 58015222898,
    "trades": 689,
    "traded_amount": 36528375019,
    "price": [
      14.94590101035938,
      15.052352111428831,
      14.667487596335873,
      14.782600373259319
    ],
    "volume": 479500561315
  },
  {
    "ts": 1589673600,
    "supply": 9989524838001,
    "trustlines": {
      "total": 13189,
      "authorized": 13189,
      "funded": 1429
    },
    "payments": 5,
    "payments_amount": 2042705144,
    "trades": 573,
    "traded_amount": 32856153240,
    "price": [
      14.782600373259319,
      14.845454545454546,
      14.100000001931507,
      14.549785612196285
    ],
    "volume": 310138142365
  },
  {
    "ts": 1589760000,
    "supply": 9988555238002,
    "trustlines": {
      "total": 13224,
      "authorized": 13224,
      "funded": 1425
    },
    "payments": 11,
    "payments_amount": 3001823297,
    "trades": 834,
    "traded_amount": 50822652395,
    "price": [
      14.549785612196285,
      14.581956968221302,
      14.12994632796613,
      14.27849374632584
    ],
    "volume": 217754464985
  },
  {
    "ts": 1589846400,
    "supply": 9988555238002,
    "trustlines": {
      "total": 13251,
      "authorized": 13251,
      "funded": 1430
    },
    "payments": 14,
    "payments_amount": 869452799,
    "trades": 313,
    "traded_amount": 34818288212,
    "price": [
      14.27849374632584,
      14.584785700123637,
      14.27849374632584,
      14.441805225653207
    ],
    "volume": 258476612032
  },
  {
    "ts": 1589932800,
    "supply": 10002109138002,
    "trustlines": {
      "total": 13281,
      "authorized": 13281,
      "funded": 1435
    },
    "payments": 19,
    "payments_amount": 24291484313,
    "trades": 718,
    "traded_amount": 58308080948,
    "price": [
      14.441805225653207,
      14.645684389320085,
      13.931787200997807,
      14.016988530628062
    ],
    "volume": 449832977761
  },
  {
    "ts": 1590019200,
    "supply": 10002109138002,
    "trustlines": {
      "total": 13320,
      "authorized": 13320,
      "funded": 1432
    },
    "payments": 4,
    "payments_amount": 742380196,
    "trades": 1075,
    "traded_amount": 63965752683,
    "price": [
      14.016988530628062,
      15.537118129182783,
      14.016988530628062,
      14.993242409133249
    ],
    "volume": 494883095241
  },
  {
    "ts": 1590105600,
    "supply": 10002109138002,
    "trustlines": {
      "total": 13363,
      "authorized": 13363,
      "funded": 1436
    },
    "payments": 20,
    "payments_amount": 215891122,
    "trades": 1017,
    "traded_amount": 34091074830,
    "price": [
      14.993242409133249,
      15.170616300203633,
      14.418181818181818,
      14.636363636363637
    ],
    "volume": 260115909009
  },
  {
    "ts": 1590192000,
    "supply": 10002259138002,
    "trustlines": {
      "total": 13395,
      "authorized": 13395,
      "funded": 1440
    },
    "payments": 4,
    "payments_amount": 1088924495,
    "trades": 454,
    "traded_amount": 18114309465,
    "price": [
      14.636363636363637,
      14.992503752979452,
      14.513787945286296,
      14.8
    ],
    "volume": 155663775230
  },
  {
    "ts": 1590278400,
    "supply": 10002259138002,
    "trustlines": {
      "total": 13427,
      "authorized": 13427,
      "funded": 1440
    },
    "payments": 1,
    "payments_amount": 55000000,
    "trades": 408,
    "traded_amount": 36566043923,
    "price": [
      14.8,
      15.486700654510926,
      14.712654510459656,
      15.486700654510926
    ],
    "volume": 256990866525
  },
  {
    "ts": 1590364800,
    "supply": 10008630138002,
    "trustlines": {
      "total": 13452,
      "authorized": 13452,
      "funded": 1428
    },
    "payments": 25,
    "payments_amount": 9869060122,
    "trades": 244,
    "traded_amount": 25089335392,
    "price": [
      15.486700654510926,
      15.614045802075276,
      15.163636363636364,
      15.164547698490468
    ],
    "volume": 283574865744
  },
  {
    "ts": 1590451200,
    "supply": 10006981938003,
    "trustlines": {
      "total": 13478,
      "authorized": 13478,
      "funded": 1427
    },
    "payments": 9,
    "payments_amount": 3511069471,
    "trades": 362,
    "traded_amount": 36194440069,
    "price": [
      15.164547698490468,
      15.631594432225134,
      15.124909133165508,
      15.51869690046951
    ],
    "volume": 296463594822
  },
  {
    "ts": 1590537600,
    "supply": 10008761646636,
    "trustlines": {
      "total": 13498,
      "authorized": 13498,
      "funded": 1430
    },
    "payments": 17,
    "payments_amount": 4167514334,
    "trades": 637,
    "traded_amount": 32782442353,
    "price": [
      15.51869690046951,
      15.51869690046951,
      14.86927372238058,
      15.179113539769277
    ],
    "volume": 234714009894
  },
  {
    "ts": 1590624000,
    "supply": 10010108846636,
    "trustlines": {
      "total": 13517,
      "authorized": 13517,
      "funded": 1435
    },
    "payments": 22,
    "payments_amount": 1351176799,
    "trades": 481,
    "traded_amount": 16808556323,
    "price": [
      15.179113539769277,
      15.268694779241672,
      14.364204408275457,
      14.513080148235595
    ],
    "volume": 154300605506
  },
  {
    "ts": 1590710400,
    "supply": 10009723657476,
    "trustlines": {
      "total": 13548,
      "authorized": 13548,
      "funded": 1439
    },
    "payments": 19,
    "payments_amount": 4107196201,
    "trades": 320,
    "traded_amount": 16022025938,
    "price": [
      14.513080148235595,
      14.947638402355775,
      14.376966901702533,
      14.81788821192928
    ],
    "volume": 141481638923
  },
  {
    "ts": 1590796800,
    "supply": 10009723657476,
    "trustlines": {
      "total": 13569,
      "authorized": 13569,
      "funded": 1442
    },
    "payments": 4,
    "payments_amount": 754513891,
    "trades": 242,
    "traded_amount": 25869033856,
    "price": [
      14.81788821192928,
      14.817893710385178,
      13.927272727272728,
      13.929998444822536
    ],
    "volume": 198703147857
  },
  {
    "ts": 1590883200,
    "supply": 10009723657476,
    "trustlines": {
      "total": 13574,
      "authorized": 13574,
      "funded": 1443
    },
    "payments": 7,
    "payments_amount": 657241126,
    "trades": 638,
    "traded_amount": 58744957780,
    "price": [
      13.929998444822536,
      14.319063733784546,
      13.145454545454545,
      14.319063733784546
    ],
    "volume": 429208166251
  },
  {
    "ts": 1590969600,
    "supply": 10014860957476,
    "trustlines": {
      "total": 13600,
      "authorized": 13600,
      "funded": 1454
    },
    "payments": 39,
    "payments_amount": 30145419375,
    "trades": 744,
    "traded_amount": 78017294689,
    "price": [
      14.319063733784546,
      14.319063733784546,
      13.070220904166007,
      13.169469985302982
    ],
    "volume": 404853288338
  },
  {
    "ts": 1591056000,
    "supply": 9915620957476,
    "trustlines": {
      "total": 13618,
      "authorized": 13618,
      "funded": 1466
    },
    "payments": 33,
    "payments_amount": 324941442611,
    "trades": 1250,
    "traded_amount": 350426494697,
    "price": [
      13.169469985302982,
      13.201228114240951,
      11.9472315,
      12.503907683182362
    ],
    "volume": 2900280263529
  },
  {
    "ts": 1591142400,
    "supply": 9934070957476,
    "trustlines": {
      "total": 13645,
      "authorized": 13645,
      "funded": 1466
    },
    "payments": 18,
    "payments_amount": 20604515522,
    "trades": 284,
    "traded_amount": 18089273123,
    "price": [
      12.503907683182362,
      12.96382961476938,
      12.06740422044663,
      12.078695171134797
    ],
    "volume": 116533056392
  },
  {
    "ts": 1591228800,
    "supply": 9892670957476,
    "trustlines": {
      "total": 13680,
      "authorized": 13680,
      "funded": 1474
    },
    "payments": 40,
    "payments_amount": 1183850838138,
    "trades": 259,
    "traded_amount": 68277717793,
    "price": [
      12.078695171134797,
      12.538461538461538,
      11.658123200086228,
      12.219028802519063
    ],
    "volume": 486315496710
  },
  {
    "ts": 1591315200,
    "supply": 9897535157476,
    "trustlines": {
      "total": 13701,
      "authorized": 13701,
      "funded": 1474
    },
    "payments": 7,
    "payments_amount": 9417500000,
    "trades": 297,
    "traded_amount": 16806834488,
    "price": [
      12.219028802519063,
      12.62739459523084,
      12.122675281189457,
      12.573318200168757
    ],
    "volume": 155942320102
  },
  {
    "ts": 1591401600,
    "supply": 9898485157476,
    "trustlines": {
      "total": 13729,
      "authorized": 13729,
      "funded": 1474
    },
    "payments": 3,
    "payments_amount": 1213882482,
    "trades": 159,
    "traded_amount": 4222757884,
    "price": [
      12.573318200168757,
      12.658228002968105,
      12.333333333333334,
      12.417905399952247
    ],
    "volume": 35821129760
  },
  {
    "ts": 1591488000,
    "supply": 9897784657476,
    "trustlines": {
      "total": 13751,
      "authorized": 13751,
      "funded": 1474
    },
    "payments": 8,
    "payments_amount": 12250496322,
    "trades": 507,
    "traded_amount": 24144349912,
    "price": [
      12.417905399952247,
      13.168200115008625,
      12.392203319060345,
      12.75471168672149
    ],
    "volume": 226702758844
  },
  {
    "ts": 1591574400,
    "supply": 9899306057476,
    "trustlines": {
      "total": 13774,
      "authorized": 13774,
      "funded": 1476
    },
    "payments": 25,
    "payments_amount": 3514751762,
    "trades": 339,
    "traded_amount": 8439133668,
    "price": [
      12.75471168672149,
      12.854545454545455,
      12.459920972340319,
      12.5878315
    ],
    "volume": 54884362567
  },
  {
    "ts": 1591660800,
    "supply": 9899156057476,
    "trustlines": {
      "total": 13817,
      "authorized": 13817,
      "funded": 1487
    },
    "payments": 47,
    "payments_amount": 7097956091,
    "trades": 557,
    "traded_amount": 16395910458,
    "price": [
      12.5878315,
      12.878622496741436,
      12.409694818984654,
      12.84999655141953
    ],
    "volume": 36451777535
  },
  {
    "ts": 1591747200,
    "supply": 9899156057476,
    "trustlines": {
      "total": 13840,
      "authorized": 13840,
      "funded": 1490
    },
    "payments": 21,
    "payments_amount": 53207553144,
    "trades": 128,
    "traded_amount": 6577158606,
    "price": [
      12.84999655141953,
      12.85443760969122,
      12.636497064579256,
      12.6774847
    ],
    "volume": 25405972518
  },
  {
    "ts": 1591833600,
    "supply": 9905040057476,
    "trustlines": {
      "total": 13870,
      "authorized": 13870,
      "funded": 1493
    },
    "payments": 18,
    "payments_amount": 9978433225,
    "trades": 572,
    "traded_amount": 52052153844,
    "price": [
      12.6774847,
      14.320322583725869,
      12.6774847,
      14.0886672155316
    ],
    "volume": 375067074658
  },
  {
    "ts": 1591920000,
    "supply": 10113489457476,
    "trustlines": {
      "total": 13896,
      "authorized": 13896,
      "funded": 1511
    },
    "payments": 69,
    "payments_amount": 434806980586,
    "trades": 397,
    "traded_amount": 129950722017,
    "price": [
      14.0886672155316,
      14.186327402145036,
      13.4766628,
      13.823612992233135
    ],
    "volume": 1149431596199
  },
  {
    "ts": 1592006400,
    "supply": 10111989457476,
    "trustlines": {
      "total": 13912,
      "authorized": 13912,
      "funded": 1515
    },
    "payments": 16,
    "payments_amount": 9050467084,
    "trades": 101,
    "traded_amount": 45971009369,
    "price": [
      13.823612992233135,
      13.885886910326878,
      13.366203009338864,
      13.447005174303625
    ],
    "volume": 268315893952
  },
  {
    "ts": 1592092800,
    "supply": 10110972257476,
    "trustlines": {
      "total": 13935,
      "authorized": 13935,
      "funded": 1514
    },
    "payments": 8,
    "payments_amount": 5979277196,
    "trades": 80,
    "traded_amount": 6397994404,
    "price": [
      13.447005174303625,
      14.113134409453105,
      13.447005174303625,
      14.097512997049318
    ],
    "volume": 15728536555
  },
  {
    "ts": 1592179200,
    "supply": 10110972257476,
    "trustlines": {
      "total": 13995,
      "authorized": 13995,
      "funded": 1522
    },
    "payments": 40,
    "payments_amount": 27542823783,
    "trades": 380,
    "traded_amount": 178361142568,
    "price": [
      14.097512997049318,
      15.2200000024352,
      13.981699346405229,
      14.216672707172936
    ],
    "volume": 1428763389733
  },
  {
    "ts": 1592265600,
    "supply": 10119422257476,
    "trustlines": {
      "total": 14099,
      "authorized": 14099,
      "funded": 1540
    },
    "payments": 46,
    "payments_amount": 14358298726,
    "trades": 160,
    "traded_amount": 10084144331,
    "price": [
      14.216672707172936,
      14.383917192594222,
      13.809468100549875,
      14.209332663858472
    ],
    "volume": 46149044053
  },
  {
    "ts": 1592352000,
    "supply": 10120722257476,
    "trustlines": {
      "total": 14153,
      "authorized": 14153,
      "funded": 1550
    },
    "payments": 34,
    "payments_amount": 10154780670,
    "trades": 310,
    "traded_amount": 18894221381,
    "price": [
      14.209332663858472,
      14.341210565477875,
      13.594632841519541,
      13.818181818181818
    ],
    "volume": 132200903627
  },
  {
    "ts": 1592438400,
    "supply": 10129072257476,
    "trustlines": {
      "total": 14192,
      "authorized": 14192,
      "funded": 1555
    },
    "payments": 37,
    "payments_amount": 46357672988,
    "trades": 79,
    "traded_amount": 12462265996,
    "price": [
      13.818181818181818,
      14.308065528078506,
      13.8,
      14.2053212
    ],
    "volume": 97104529522
  },
  {
    "ts": 1592524800,
    "supply": 10134447257476,
    "trustlines": {
      "total": 14212,
      "authorized": 14212,
      "funded": 1558
    },
    "payments": 16,
    "payments_amount": 9099357148,
    "trades": 115,
    "traded_amount": 4935446353,
    "price": [
      14.2053212,
      14.47343559579804,
      13.988438383960546,
      14.348935560174898
    ],
    "volume": 14988162036
  },
  {
    "ts": 1592611200,
    "supply": 10150527257476,
    "trustlines": {
      "total": 14241,
      "authorized": 14241,
      "funded": 1562
    },
    "payments": 24,
    "payments_amount": 17434870330,
    "trades": 65,
    "traded_amount": 1823219145,
    "price": [
      14.348935560174898,
      14.572212543327526,
      14.209090909090909,
      14.429912196428571
    ],
    "volume": 7353854033
  },
  {
    "ts": 1592697600,
    "supply": 10153727257476,
    "trustlines": {
      "total": 14268,
      "authorized": 14268,
      "funded": 1563
    },
    "payments": 18,
    "payments_amount": 362617588332,
    "trades": 35,
    "traded_amount": 4185933635,
    "price": [
      14.429912196428571,
      14.541819413159018,
      14.209090909090909,
      14.290349803825196
    ],
    "volume": 14941716347
  },
  {
    "ts": 1592784000,
    "supply": 10153727257476,
    "trustlines": {
      "total": 14294,
      "authorized": 14294,
      "funded": 1567
    },
    "payments": 8,
    "payments_amount": 2210285987,
    "trades": 63,
    "traded_amount": 3436129603,
    "price": [
      14.290349803825196,
      14.618393038161214,
      13.94004098249704,
      13.940041
    ],
    "volume": 7691134219
  },
  {
    "ts": 1592870400,
    "supply": 10226127257476,
    "trustlines": {
      "total": 14333,
      "authorized": 14333,
      "funded": 1570
    },
    "payments": 18,
    "payments_amount": 77289146151,
    "trades": 60,
    "traded_amount": 13363344745,
    "price": [
      13.940041,
      14.205356631907987,
      13.76982209993161,
      13.97301810291582
    ],
    "volume": 132978088120
  },
  {
    "ts": 1592956800,
    "supply": 10540027257476,
    "trustlines": {
      "total": 14366,
      "authorized": 14366,
      "funded": 1572
    },
    "payments": 31,
    "payments_amount": 2389945448908,
    "trades": 140,
    "traded_amount": 15519523740,
    "price": [
      13.97301810291582,
      14.65243931999316,
      13.772727272727273,
      14.605425272206121
    ],
    "volume": 116365791526
  },
  {
    "ts": 1593043200,
    "supply": 10565477257476,
    "trustlines": {
      "total": 14394,
      "authorized": 14394,
      "funded": 1569
    },
    "payments": 12,
    "payments_amount": 283325005598,
    "trades": 156,
    "traded_amount": 189523282219,
    "price": [
      14.605425272206121,
      15.046749948520832,
      14.545454545454545,
      14.78366
    ],
    "volume": 2775539208850
  },
  {
    "ts": 1593129600,
    "supply": 10662305657476,
    "trustlines": {
      "total": 14434,
      "authorized": 14434,
      "funded": 1569
    },
    "payments": 26,
    "payments_amount": 113580619442,
    "trades": 562,
    "traded_amount": 32210770075,
    "price": [
      14.78366,
      15.047770302199138,
      14.392857142857142,
      14.99830112757025
    ],
    "volume": 219061592792
  },
  {
    "ts": 1593216000,
    "supply": 10668105657476,
    "trustlines": {
      "total": 14459,
      "authorized": 14459,
      "funded": 1567
    },
    "payments": 13,
    "payments_amount": 7104494406,
    "trades": 430,
    "traded_amount": 184176309817,
    "price": [
      14.99830112757025,
      16.46314763278672,
      14.967359015308105,
      15.972727272727273
    ],
    "volume": 1561538845496
  },
  {
    "ts": 1593302400,
    "supply": 10664493257476,
    "trustlines": {
      "total": 14484,
      "authorized": 14484,
      "funded": 1571
    },
    "payments": 17,
    "payments_amount": 6018339442,
    "trades": 203,
    "traded_amount": 46561499428,
    "price": [
      15.972727272727273,
      15.975895765888785,
      15.385847638933626,
      15.678007305914585
    ],
    "volume": 352882107258
  },
  {
    "ts": 1593388800,
    "supply": 10666643257476,
    "trustlines": {
      "total": 14501,
      "authorized": 14501,
      "funded": 1575
    },
    "payments": 17,
    "payments_amount": 2219259200,
    "trades": 282,
    "traded_amount": 121774177699,
    "price": [
      15.678007305914585,
      15.8659294,
      15.230586806807187,
      15.47291362629047
    ],
    "volume": 904557526032
  },
  {
    "ts": 1593475200,
    "supply": 10736793257476,
    "trustlines": {
      "total": 14521,
      "authorized": 14521,
      "funded": 1577
    },
    "payments": 42,
    "payments_amount": 71093436042,
    "trades": 288,
    "traded_amount": 14665670527,
    "price": [
      15.47291362629047,
      15.536076725772276,
      14.860106098030602,
      14.976920565408705
    ],
    "volume": 141673814304
  },
  {
    "ts": 1593561600,
    "supply": 10814143257476,
    "trustlines": {
      "total": 14543,
      "authorized": 14543,
      "funded": 1579
    },
    "payments": 55,
    "payments_amount": 101032514352,
    "trades": 706,
    "traded_amount": 46688389401,
    "price": [
      14.976920565408705,
      15.121066919501763,
      14.412739596300895,
      14.691795520919515
    ],
    "volume": 430654654852
  },
  {
    "ts": 1593648000,
    "supply": 10824392957476,
    "trustlines": {
      "total": 14567,
      "authorized": 14567,
      "funded": 1586
    },
    "payments": 51,
    "payments_amount": 706336441898,
    "trades": 571,
    "traded_amount": 48188479618,
    "price": [
      14.691795520919515,
      15.020564782061275,
      14.194097597564474,
      14.756097382491308
    ],
    "volume": 453297087005
  },
  {
    "ts": 1593734400,
    "supply": 10849322557476,
    "trustlines": {
      "total": 14591,
      "authorized": 14591,
      "funded": 1586
    },
    "payments": 37,
    "payments_amount": 28664970862,
    "trades": 553,
    "traded_amount": 28354351018,
    "price": [
      14.756097382491308,
      15.026137966993586,
      14.638037515934196,
      14.888313487527437
    ],
    "volume": 307897851373
  },
  {
    "ts": 1593820800,
    "supply": 10968072557476,
    "trustlines": {
      "total": 14610,
      "authorized": 14610,
      "funded": 1586
    },
    "payments": 44,
    "payments_amount": 356653405782,
    "trades": 231,
    "traded_amount": 10639275507,
    "price": [
      14.888313487527437,
      15.078225101818868,
      14.590909090909092,
      14.809090909090909
    ],
    "volume": 134645700331
  },
  {
    "ts": 1593907200,
    "supply": 10992221357476,
    "trustlines": {
      "total": 14638,
      "authorized": 14638,
      "funded": 1585
    },
    "payments": 39,
    "payments_amount": 724581434764,
    "trades": 203,
    "traded_amount": 22473490750,
    "price": [
      14.809090909090909,
      15.27059824034287,
      14.672727272727272,
      14.885164950619878
    ],
    "volume": 242841007895
  },
  {
    "ts": 1593993600,
    "supply": 10999221357476,
    "trustlines": {
      "total": 14670,
      "authorized": 14670,
      "funded": 1588
    },
    "payments": 54,
    "payments_amount": 11905897154,
    "trades": 481,
    "traded_amount": 38630482706,
    "price": [
      14.885164950619878,
      15.015015015015015,
      13.891112741437459,
      13.903909807281954
    ],
    "volume": 351802991819
  },
  {
    "ts": 1594080000,
    "supply": 11084171357476,
    "trustlines": {
      "total": 14693,
      "authorized": 14693,
      "funded": 1590
    },
    "payments": 64,
    "payments_amount": 92303587817,
    "trades": 584,
    "traded_amount": 28596126421,
    "price": [
      13.903909807281954,
      14.19025497,
      13.48407740277045,
      13.573249783923941
    ],
    "volume": 226646172896
  },
  {
    "ts": 1594166400,
    "supply": 11300064357476,
    "trustlines": {
      "total": 14707,
      "authorized": 14707,
      "funded": 1565
    },
    "payments": 110,
    "payments_amount": 572239485636,
    "trades": 1371,
    "traded_amount": 87112538886,
    "price": [
      13.573249783923941,
      13.798874493513164,
      11.764719113542254,
      11.764719577633116
    ],
    "volume": 853442534725
  },
  {
    "ts": 1594252800,
    "supply": 11338048857476,
    "trustlines": {
      "total": 14726,
      "authorized": 14726,
      "funded": 1580
    },
    "payments": 45,
    "payments_amount": 1007969647488,
    "trades": 2067,
    "traded_amount": 345050418043,
    "price": [
      11.764719577633116,
      11.878063725490197,
      10.221654949323527,
      11.05624603653861
    ],
    "volume": 1998056714171
  },
  {
    "ts": 1594339200,
    "supply": 11771203857476,
    "trustlines": {
      "total": 14757,
      "authorized": 14757,
      "funded": 1588
    },
    "payments": 77,
    "payments_amount": 443426413246,
    "trades": 1648,
    "traded_amount": 135973825635,
    "price": [
      11.05624603653861,
      11.976048436454997,
      11.038064949213721,
      11.225845939602214
    ],
    "volume": 1295893991332
  },
  {
    "ts": 1594425600,
    "supply": 12025344757476,
    "trustlines": {
      "total": 14873,
      "authorized": 14873,
      "funded": 1578
    },
    "payments": 78,
    "payments_amount": 293170282161,
    "trades": 1384,
    "traded_amount": 158241194935,
    "price": [
      11.225845939602214,
      11.225845939602214,
      9.960288816527237,
      10.563105944102702
    ],
    "volume": 889089924066
  },
  {
    "ts": 1594512000,
    "supply": 12025744757476,
    "trustlines": {
      "total": 14900,
      "authorized": 14900,
      "funded": 1583
    },
    "payments": 35,
    "payments_amount": 11447866362,
    "trades": 776,
    "traded_amount": 84693750595,
    "price": [
      10.563105944102702,
      11.000994940297467,
      10.331218877203133,
      10.493540058103473
    ],
    "volume": 461537982479
  },
  {
    "ts": 1594598400,
    "supply": 12011697557477,
    "trustlines": {
      "total": 14919,
      "authorized": 14919,
      "funded": 1595
    },
    "payments": 55,
    "payments_amount": 56551114012,
    "trades": 1295,
    "traded_amount": 154787046805,
    "price": [
      10.493540058103473,
      11.41115055860005,
      10.221145992263473,
      11.081849490730564
    ],
    "volume": 794712537373
  },
  {
    "ts": 1594684800,
    "supply": 12074844957477,
    "trustlines": {
      "total": 14954,
      "authorized": 14954,
      "funded": 1599
    },
    "payments": 55,
    "payments_amount": 73612702149,
    "trades": 924,
    "traded_amount": 60316783200,
    "price": [
      11.081849490730564,
      11.671650185449852,
      10.829764453961456,
      10.88095058611934
    ],
    "volume": 361726123349
  },
  {
    "ts": 1594771200,
    "supply": 12456826357477,
    "trustlines": {
      "total": 14981,
      "authorized": 14981,
      "funded": 1602
    },
    "payments": 103,
    "payments_amount": 432115601428,
    "trades": 1317,
    "traded_amount": 150567783507,
    "price": [
      10.88095058611934,
      11.41532350625622,
      10.425907414075212,
      10.586779801917816
    ],
    "volume": 780492571389
  },
  {
    "ts": 1594857600,
    "supply": 12998443657477,
    "trustlines": {
      "total": 15022,
      "authorized": 15022,
      "funded": 1615
    },
    "payments": 103,
    "payments_amount": 555020950751,
    "trades": 1259,
    "traded_amount": 155300753150,
    "price": [
      10.586779801917816,
      11.285595031344693,
      9.708692055051324,
      9.806130233985012
    ],
    "volume": 791267565940
  },
  {
    "ts": 1594944000,
    "supply": 13254510557477,
    "trustlines": {
      "total": 15054,
      "authorized": 15054,
      "funded": 1617
    },
    "payments": 77,
    "payments_amount": 267773387342,
    "trades": 1015,
    "traded_amount": 116733738067,
    "price": [
      9.806130233985012,
      10.099940483500705,
      9.345794373831776,
      9.555734
    ],
    "volume": 674097910580
  },
  {
    "ts": 1595030400,
    "supply": 13254360557477,
    "trustlines": {
      "total": 15085,
      "authorized": 15085,
      "funded": 1618
    },
    "payments": 23,
    "payments_amount": 11361344856,
    "trades": 391,
    "traded_amount": 62165035102,
    "price": [
      9.555734,
      10.045454545454545,
      9.555734,
      9.888937263783104
    ],
    "volume": 373482045077
  },
  {
    "ts": 1595116800,
    "supply": 13455021657477,
    "trustlines": {
      "total": 15124,
      "authorized": 15124,
      "funded": 1623
    },
    "payments": 64,
    "payments_amount": 315633802003,
    "trades": 484,
    "traded_amount": 41683391090,
    "price": [
      9.888937263783104,
      10.35927391346439,
      9.817409286621265,
      9.988655123430721
    ],
    "volume": 212339350974
  },
  {
    "ts": 1595203200,
    "supply": 13452512957477,
    "trustlines": {
      "total": 15155,
      "authorized": 15155,
      "funded": 1623
    },
    "payments": 32,
    "payments_amount": 305148596843,
    "trades": 558,
    "traded_amount": 31320716865,
    "price": [
      9.988655123430721,
      10.6604445,
      9.849114886539146,
      10.619988615109376
    ],
    "volume": 185766829205
  },
  {
    "ts": 1595289600,
    "supply": 13578630057477,
    "trustlines": {
      "total": 15181,
      "authorized": 15181,
      "funded": 1626
    },
    "payments": 61,
    "payments_amount": 136462920820,
    "trades": 370,
    "traded_amount": 34746938008,
    "price": [
      10.619988615109376,
      10.619988615109376,
      10.160967135519822,
      10.370575659197087
    ],
    "volume": 253314034126
  },
  {
    "ts": 1595376000,
    "supply": 14050014157477,
    "trustlines": {
      "total": 15201,
      "authorized": 15201,
      "funded": 1627
    },
    "payments": 66,
    "payments_amount": 494501469982,
    "trades": 246,
    "traded_amount": 26900087540,
    "price": [
      10.370575659197087,
      10.593333735440519,
      10.213134948408968,
      10.298836933301684
    ],
    "volume": 212781776167
  },
  {
    "ts": 1595462400,
    "supply": 14331337957477,
    "trustlines": {
      "total": 15217,
      "authorized": 15217,
      "funded": 1626
    },
    "payments": 63,
    "payments_amount": 288120074663,
    "trades": 435,
    "traded_amount": 20208560045,
    "price": [
      10.298836933301684,
      10.330233530118594,
      9.868892233215265,
      10.180859540856982
    ],
    "volume": 113776921927
  },
  {
    "ts": 1595548800,
    "supply": 14643190557477,
    "trustlines": {
      "total": 15243,
      "authorized": 15243,
      "funded": 1630
    },
    "payments": 54,
    "payments_amount": 447375787111,
    "trades": 383,
    "traded_amount": 35164881302,
    "price": [
      10.180859540856982,
      10.51317828682062,
      10.180859540856982,
      10.41521086512588
    ],
    "volume": 166622594964
  },
  {
    "ts": 1595635200,
    "supply": 14835826157477,
    "trustlines": {
      "total": 15265,
      "authorized": 15265,
      "funded": 1631
    },
    "payments": 55,
    "payments_amount": 237044227489,
    "trades": 524,
    "traded_amount": 54862197850,
    "price": [
      10.41521086512588,
      10.415210889928805,
      9.86950745,
      9.86950745
    ],
    "volume": 377821494180
  },
  {
    "ts": 1595721600,
    "supply": 14949296457477,
    "trustlines": {
      "total": 15308,
      "authorized": 15308,
      "funded": 1637
    },
    "payments": 42,
    "payments_amount": 121430727615,
    "trades": 459,
    "traded_amount": 60460822277,
    "price": [
      9.86950745,
      10.295730498911151,
      9.48731875,
      10.077702202108473
    ],
    "volume": 343383298777
  },
  {
    "ts": 1595808000,
    "supply": 15487855957477,
    "trustlines": {
      "total": 15336,
      "authorized": 15336,
      "funded": 1635
    },
    "payments": 71,
    "payments_amount": 680506192231,
    "trades": 659,
    "traded_amount": 180887122450,
    "price": [
      10.077702202108473,
      11.180488381038735,
      10.027781565207855,
      10.541047864628558
    ],
    "volume": 1100532279193
  },
  {
    "ts": 1595894400,
    "supply": 15804276357477,
    "trustlines": {
      "total": 15382,
      "authorized": 15382,
      "funded": 1637
    },
    "payments": 69,
    "payments_amount": 4406113336178,
    "trades": 363,
    "traded_amount": 123565312512,
    "price": [
      10.541047864628558,
      10.660000001540942,
      10.19598918918919,
      10.3953204871011
    ],
    "volume": 986729615857
  },
  {
    "ts": 1595980800,
    "supply": 9774352857477,
    "trustlines": {
      "total": 15424,
      "authorized": 15424,
      "funded": 1638
    },
    "payments": 33,
    "payments_amount": 18079468674581,
    "trades": 323,
    "traded_amount": 84735860088,
    "price": [
      10.3953204871011,
      10.529159047619048,
      10.006933526977996,
      10.512517723823027
    ],
    "volume": 671721030447
  },
  {
    "ts": 1596067200,
    "supply": 8224216257476,
    "trustlines": {
      "total": 15452,
      "authorized": 15452,
      "funded": 1639
    },
    "payments": 109,
    "payments_amount": 14087711763244,
    "trades": 176,
    "traded_amount": 39433119720,
    "price": [
      10.512517723823027,
      10.581818181818182,
      10.268947097347105,
      10.268947097347105
    ],
    "volume": 367665901248
  },
  {
    "ts": 1596153600,
    "supply": 9259167457476,
    "trustlines": {
      "total": 15477,
      "authorized": 15477,
      "funded": 1640
    },
    "payments": 79,
    "payments_amount": 1074060155934,
    "trades": 219,
    "traded_amount": 77243651933,
    "price": [
      10.268947097347105,
      10.510000004870374,
      10.268947097347105,
      10.319989689904038
    ],
    "volume": 454269761902
  },
  {
    "ts": 1596240000,
    "supply": 9281901057476,
    "trustlines": {
      "total": 15509,
      "authorized": 15509,
      "funded": 1650
    },
    "payments": 32,
    "payments_amount": 132335095105,
    "trades": 397,
    "traded_amount": 79468682367,
    "price": [
      10.319989689904038,
      10.372968031699054,
      9.23606847005158,
      9.40852751611304
    ],
    "volume": 458152910462
  },
  {
    "ts": 1596326400,
    "supply": 11856515657475,
    "trustlines": {
      "total": 15550,
      "authorized": 15550,
      "funded": 1637
    },
    "payments": 84,
    "payments_amount": 2637708589300,
    "trades": 665,
    "traded_amount": 232395850086,
    "price": [
      9.40852751611304,
      10.014719170195832,
      8.62201717063539,
      9.691951542466176
    ],
    "volume": 1245232181037
  },
  {
    "ts": 1596412800,
    "supply": 11976504157476,
    "trustlines": {
      "total": 15580,
      "authorized": 15580,
      "funded": 1638
    },
    "payments": 41,
    "payments_amount": 233783240947,
    "trades": 234,
    "traded_amount": 36082593492,
    "price": [
      9.691951542466176,
      9.69899970218879,
      9.14225877793111,
      9.582180761904763
    ],
    "volume": 227439368673
  },
  {
    "ts": 1596499200,
    "supply": 12462156557476,
    "trustlines": {
      "total": 15621,
      "authorized": 15621,
      "funded": 1644
    },
    "payments": 60,
    "payments_amount": 512493166527,
    "trades": 262,
    "traded_amount": 46373037305,
    "price": [
      9.582180761904763,
      9.582180761904763,
      9,
      9.0204
    ],
    "volume": 256119121583
  },
  {
    "ts": 1596585600,
    "supply": 7915916757477,
    "trustlines": {
      "total": 15652,
      "authorized": 15652,
      "funded": 1642
    },
    "payments": 48,
    "payments_amount": 25480465724917,
    "trades": 123,
    "traded_amount": 29964994800,
    "price": [
      9.0204,
      9.361900847125806,
      8.993753364673129,
      9.33862515524211
    ],
    "volume": 192747078511
  },
  {
    "ts": 1596672000,
    "supply": 10028729557476,
    "trustlines": {
      "total": 15672,
      "authorized": 15672,
      "funded": 1643
    },
    "payments": 145,
    "payments_amount": 2175918193133,
    "trades": 237,
    "traded_amount": 105070033672,
    "price": [
      9.33862515524211,
      9.531104528486681,
      9.14821674990097,
      9.42914
    ],
    "volume": 845530182541
  },
  {
    "ts": 1596758400,
    "supply": 8197205557475,
    "trustlines": {
      "total": 15698,
      "authorized": 15698,
      "funded": 1646
    },
    "payments": 61,
    "payments_amount": 11879791199543,
    "trades": 330,
    "traded_amount": 100683684575,
    "price": [
      9.42914,
      10.003781135018736,
      9.299983352223354,
      9.772727272727273
    ],
    "volume": 466616917655
  },
  {
    "ts": 1596844800,
    "supply": 8773655557475,
    "trustlines": {
      "total": 15728,
      "authorized": 15728,
      "funded": 1646
    },
    "payments": 28,
    "payments_amount": 612315213970,
    "trades": 93,
    "traded_amount": 20882184968,
    "price": [
      9.772727272727273,
      9.809400127561412,
      9.601793499450904,
      9.704381910456508
    ],
    "volume": 117981490220
  },
  {
    "ts": 1596931200,
    "supply": 8980081057475,
    "trustlines": {
      "total": 15752,
      "authorized": 15752,
      "funded": 1646
    },
    "payments": 30,
    "payments_amount": 215191478075,
    "trades": 129,
    "traded_amount": 30493449720,
    "price": [
      9.704381910456508,
      9.704381910456508,
      9.417633764757882,
      9.590909090909092
    ],
    "volume": 160390751233
  },
  {
    "ts": 1597017600,
    "supply": 8992747557475,
    "trustlines": {
      "total": 15779,
      "authorized": 15779,
      "funded": 1646
    },
    "payments": 18,
    "payments_amount": 16313251956,
    "trades": 141,
    "traded_amount": 34836438431,
    "price": [
      9.590909090909092,
      9.635606288884556,
      9.4,
      9.490653899127697
    ],
    "volume": 139542616928
  },
  {
    "ts": 1597104000,
    "supply": 12189462257475,
    "trustlines": {
      "total": 15817,
      "authorized": 15817,
      "funded": 1645
    },
    "payments": 79,
    "payments_amount": 3248855669345,
    "trades": 398,
    "traded_amount": 242179308050,
    "price": [
      9.490653899127697,
      10.49991153936969,
      9.25925925925926,
      9.84682522152373
    ],
    "volume": 1209479865753
  },
  {
    "ts": 1597190400,
    "supply": 13502695657472,
    "trustlines": {
      "total": 15841,
      "authorized": 15841,
      "funded": 1647
    },
    "payments": 106,
    "payments_amount": 1521372590357,
    "trades": 188,
    "traded_amount": 93963488347,
    "price": [
      9.84682522152373,
      10.388250666666666,
      9.76807338343626,
      9.845346823084796
    ],
    "volume": 830261111923
  },
  {
    "ts": 1597276800,
    "supply": 14067123957471,
    "trustlines": {
      "total": 15882,
      "authorized": 15882,
      "funded": 1658
    },
    "payments": 77,
    "payments_amount": 1140948533312,
    "trades": 269,
    "traded_amount": 89004005688,
    "price": [
      9.845346823084796,
      10.302309201857568,
      9.76372584590536,
      9.873783426459568
    ],
    "volume": 664452096217
  },
  {
    "ts": 1597363200,
    "supply": 14424763757471,
    "trustlines": {
      "total": 15924,
      "authorized": 15924,
      "funded": 1667
    },
    "payments": 60,
    "payments_amount": 366894773773,
    "trades": 365,
    "traded_amount": 117048734159,
    "price": [
      9.873783426459568,
      10.00497862,
      9.357795458438815,
      9.567981495053685
    ],
    "volume": 831933305933
  },
  {
    "ts": 1597449600,
    "supply": 14567995057471,
    "trustlines": {
      "total": 15958,
      "authorized": 15958,
      "funded": 1673
    },
    "payments": 42,
    "payments_amount": 185711317121,
    "trades": 774,
    "traded_amount": 70404281383,
    "price": [
      9.567981495053685,
      9.650458300920256,
      9.215738952474501,
      9.329277600912814
    ],
    "volume": 556590538101
  },
  {
    "ts": 1597536000,
    "supply": 14567707657471,
    "trustlines": {
      "total": 15984,
      "authorized": 15984,
      "funded": 1678
    },
    "payments": 25,
    "payments_amount": 10303880229,
    "trades": 655,
    "traded_amount": 112270079389,
    "price": [
      9.329277600912814,
      9.599646733000226,
      8.704727798809367,
      8.704727798809367
    ],
    "volume": 730110382352
  },
  {
    "ts": 1597622400,
    "supply": 7747670257471,
    "trustlines": {
      "total": 16100,
      "authorized": 16100,
      "funded": 1681
    },
    "payments": 57,
    "payments_amount": 27503934186002,
    "trades": 1132,
    "traded_amount": 155398081677,
    "price": [
      8.704727798809367,
      9.023096571428571,
      8.41,
      8.760040569901793
    ],
    "volume": 934929360321
  },
  {
    "ts": 1597708800,
    "supply": 8659365707470,
    "trustlines": {
      "total": 16204,
      "authorized": 16204,
      "funded": 1689
    },
    "payments": 138,
    "payments_amount": 1328654043266,
    "trades": 878,
    "traded_amount": 126907725509,
    "price": [
      8.760040569901793,
      9.312625523809524,
      8.61942143651873,
      9.20734029
    ],
    "volume": 609231345025
  },
  {
    "ts": 1597795200,
    "supply": 9554614207470,
    "trustlines": {
      "total": 16296,
      "authorized": 16296,
      "funded": 1687
    },
    "payments": 194,
    "payments_amount": 1638315223746,
    "trades": 1090,
    "traded_amount": 100663381405,
    "price": [
      9.20734029,
      10.09701977799389,
      9.158618001449822,
      9.800203146965904
    ],
    "volume": 563215098933
  },
  {
    "ts": 1597881600,
    "supply": 9860688207470,
    "trustlines": {
      "total": 16403,
      "authorized": 16403,
      "funded": 1690
    },
    "payments": 70,
    "payments_amount": 1298777257352,
    "trades": 694,
    "traded_amount": 33504916886,
    "price": [
      9.800203146965904,
      9.931404601262898,
      9.349999551162156,
      9.350000047772054
    ],
    "volume": 162968333371
  },
  {
    "ts": 1597968000,
    "supply": 8381346707470,
    "trustlines": {
      "total": 16526,
      "authorized": 16526,
      "funded": 1692
    },
    "payments": 69,
    "payments_amount": 11625725857475,
    "trades": 1209,
    "traded_amount": 214972842692,
    "price": [
      9.350000047772054,
      10.057104770369442,
      9.2,
      10.045387135609166
    ],
    "volume": 1453689430195
  },
  {
    "ts": 1598054400,
    "supply": 8357468207470,
    "trustlines": {
      "total": 16592,
      "authorized": 16592,
      "funded": 1686
    },
    "payments": 97,
    "payments_amount": 11205092725642,
    "trades": 494,
    "traded_amount": 111756079248,
    "price": [
      10.045387135609166,
      10.260929788740343,
      9.769652044587348,
      9.80053038308365
    ],
    "volume": 776120832529
  },
  {
    "ts": 1598140800,
    "supply": 7881257207470,
    "trustlines": {
      "total": 16657,
      "authorized": 16657,
      "funded": 1688
    },
    "payments": 17,
    "payments_amount": 1880673272062,
    "trades": 756,
    "traded_amount": 22020687008,
    "price": [
      9.80053038308365,
      10.09224,
      9.70873786407767,
      9.70873786407767
    ],
    "volume": 129282639595
  },
  {
    "ts": 1598227200,
    "supply": 7841061807470,
    "trustlines": {
      "total": 16711,
      "authorized": 16711,
      "funded": 1695
    },
    "payments": 24,
    "payments_amount": 48332035210,
    "trades": 1048,
    "traded_amount": 69174727810,
    "price": [
      9.70873786407767,
      9.924784217016029,
      9.436134220101735,
      9.69640165524925
    ],
    "volume": 355772292082
  },
  {
    "ts": 1598313600,
    "supply": 7884151107470,
    "trustlines": {
      "total": 16862,
      "authorized": 16862,
      "funded": 1696
    },
    "payments": 92,
    "payments_amount": 1955463612555,
    "trades": 956,
    "traded_amount": 47315276186,
    "price": [
      9.69640165524925,
      10.439365174970263,
      9.69640165524925,
      10.24835782
    ],
    "volume": 260822438114
  },
  {
    "ts": 1598400000,
    "supply": 7884651307470,
    "trustlines": {
      "total": 17015,
      "authorized": 17015,
      "funded": 1702
    },
    "payments": 102,
    "payments_amount": 2331713805884,
    "trades": 983,
    "traded_amount": 107739613540,
    "price": [
      10.24835782,
      10.373819829808507,
      9.99791043601886,
      10.208430775115783
    ],
    "volume": 541421351346
  },
  {
    "ts": 1598486400,
    "supply": 7819651307470,
    "trustlines": {
      "total": 17147,
      "authorized": 17147,
      "funded": 1706
    },
    "payments": 43,
    "payments_amount": 71449615335,
    "trades": 1244,
    "traded_amount": 62518320173,
    "price": [
      10.208430775115783,
      11.002870542802855,
      10.15947266,
      10.617108499924637
    ],
    "volume": 409357678172
  },
  {
    "ts": 1598572800,
    "supply": 7885656107470,
    "trustlines": {
      "total": 17225,
      "authorized": 17225,
      "funded": 1721
    },
    "payments": 95,
    "payments_amount": 2661932370784,
    "trades": 687,
    "traded_amount": 57032618890,
    "price": [
      10.617108499924637,
      10.690277293333324,
      10.36659212071941,
      10.380495803641029
    ],
    "volume": 354519368856
  },
  {
    "ts": 1598659200,
    "supply": 7855687707470,
    "trustlines": {
      "total": 17400,
      "authorized": 17400,
      "funded": 1715
    },
    "payments": 44,
    "payments_amount": 52596873183,
    "trades": 1161,
    "traded_amount": 52802761624,
    "price": [
      10.380495803641029,
      10.560442695178821,
      10.235152370744274,
      10.350000004415737
    ],
    "volume": 296775201151
  },
  {
    "ts": 1598745600,
    "supply": 7890850707470,
    "trustlines": {
      "total": 17555,
      "authorized": 17555,
      "funded": 1712
    },
    "payments": 59,
    "payments_amount": 2358012658400,
    "trades": 2345,
    "traded_amount": 93935339863,
    "price": [
      10.350000004415737,
      10.370063206302177,
      10.142769367222497,
      10.216779525472738
    ],
    "volume": 84721803922
  },
  {
    "ts": 1598832000,
    "supply": 7806482207470,
    "trustlines": {
      "total": 17630,
      "authorized": 17630,
      "funded": 1717
    },
    "payments": 204,
    "payments_amount": 193606548089,
    "trades": 3530,
    "traded_amount": 125397500373,
    "price": [
      10.216779525472738,
      10.519,
      10.117980390542504,
      10.289375635090888
    ],
    "volume": 381725533271
  },
  {
    "ts": 1598918400,
    "supply": 7848258207470,
    "trustlines": {
      "total": 17745,
      "authorized": 17745,
      "funded": 1715
    },
    "payments": 43,
    "payments_amount": 2798667624222,
    "trades": 3442,
    "traded_amount": 138215208767,
    "price": [
      10.289375635090888,
      10.447655265179705,
      9.88930537429718,
      10.086700797073512
    ],
    "volume": 475565825876
  },
  {
    "ts": 1599004800,
    "supply": 7853528407470,
    "trustlines": {
      "total": 17819,
      "authorized": 17819,
      "funded": 1710
    },
    "payments": 1427,
    "payments_amount": 3964803736006,
    "trades": 4605,
    "traded_amount": 200981235179,
    "price": [
      10.086700797073512,
      11.14401657213129,
      10.033781311720283,
      10.756179927056598
    ],
    "volume": 1083797434622
  },
  {
    "ts": 1599091200,
    "supply": 7886281807470,
    "trustlines": {
      "total": 17887,
      "authorized": 17887,
      "funded": 1707
    },
    "payments": 207,
    "payments_amount": 5034680406647,
    "trades": 3609,
    "traded_amount": 317597306004,
    "price": [
      10.756179927056598,
      12.82051270018912,
      10.746270409961236,
      12.563710357462234
    ],
    "volume": 2128376471758
  },
  {
    "ts": 1599177600,
    "supply": 8385761491640,
    "trustlines": {
      "total": 17954,
      "authorized": 17954,
      "funded": 1713
    },
    "payments": 153,
    "payments_amount": 3834537095707,
    "trades": 3201,
    "traded_amount": 204041820063,
    "price": [
      12.563710357462234,
      13.267091875088806,
      12.105373525557011,
      12.28673930374701
    ],
    "volume": 1281892280902
  },
  {
    "ts": 1599264000,
    "supply": 7836131591640,
    "trustlines": {
      "total": 18033,
      "authorized": 18033,
      "funded": 1712
    },
    "payments": 28,
    "payments_amount": 2034409105217,
    "trades": 2272,
    "traded_amount": 147809683957,
    "price": [
      12.28673930374701,
      13.66002052484973,
      12.060736677115987,
      13.284142857142857
    ],
    "volume": 1178209262365
  },
  {
    "ts": 1599350400,
    "supply": 7749131591640,
    "trustlines": {
      "total": 18106,
      "authorized": 18106,
      "funded": 1710
    },
    "payments": 24,
    "payments_amount": 90857160790,
    "trades": 1660,
    "traded_amount": 95789049864,
    "price": [
      13.284142857142857,
      13.740980372738095,
      12.64319825351344,
      12.977901438173756
    ],
    "volume": 720714224940
  },
  {
    "ts": 1599436800,
    "supply": 7673009491640,
    "trustlines": {
      "total": 18193,
      "authorized": 18193,
      "funded": 1710
    },
    "payments": 28,
    "payments_amount": 79372395607,
    "trades": 2084,
    "traded_amount": 89832624076,
    "price": [
      12.977901438173756,
      13.53588586465216,
      12.623433722743824,
      12.72140954722393
    ],
    "volume": 498158112024
  },
  {
    "ts": 1599523200,
    "supply": 7851068791640,
    "trustlines": {
      "total": 18279,
      "authorized": 18279,
      "funded": 1714
    },
    "payments": 77,
    "payments_amount": 714182784710,
    "trades": 1779,
    "traded_amount": 70272167357,
    "price": [
      12.72140954722393,
      13.29246028600717,
      12.57326548888889,
      12.992405075624207
    ],
    "volume": 351819230413
  },
  {
    "ts": 1599609600,
    "supply": 7771496491640,
    "trustlines": {
      "total": 18387,
      "authorized": 18387,
      "funded": 1716
    },
    "payments": 106,
    "payments_amount": 1956922547042,
    "trades": 1614,
    "traded_amount": 97133497371,
    "price": [
      12.992405075624207,
      13.231568460005693,
      12.49365721997301,
      12.632583503749148
    ],
    "volume": 684735957317
  },
  {
    "ts": 1599696000,
    "supply": 7815622391518,
    "trustlines": {
      "total": 18436,
      "authorized": 18436,
      "funded": 1718
    },
    "payments": 130,
    "payments_amount": 4519575687874,
    "trades": 1728,
    "traded_amount": 63878890423,
    "price": [
      12.632583503749148,
      12.792958755500973,
      12.072613906545683,
      12.321055592106973
    ],
    "volume": 203317186905
  },
  {
    "ts": 1599782400,
    "supply": 10404108391518,
    "trustlines": {
      "total": 18551,
      "authorized": 18551,
      "funded": 1721
    },
    "payments": 176,
    "payments_amount": 4760738274402,
    "trades": 1780,
    "traded_amount": 64449956753,
    "price": [
      12.321055592106973,
      12.634418858155062,
      11.865671641791044,
      11.937741386513945
    ],
    "volume": 250032160902
  },
  {
    "ts": 1599868800,
    "supply": 10376898091519,
    "trustlines": {
      "total": 18729,
      "authorized": 18729,
      "funded": 1720
    },
    "payments": 13,
    "payments_amount": 27765865377,
    "trades": 1147,
    "traded_amount": 51619985126,
    "price": [
      11.937741386513945,
      12.137435517393806,
      11.811402946828956,
      11.946543033246384
    ],
    "volume": 194721044218
  },
  {
    "ts": 1599955200,
    "supply": 10305748691519,
    "trustlines": {
      "total": 18915,
      "authorized": 18915,
      "funded": 1721
    },
    "payments": 13,
    "payments_amount": 73836194634,
    "trades": 1261,
    "traded_amount": 76765977440,
    "price": [
      11.946543033246384,
      12.683800433879217,
      11.821669723006595,
      12.608766361707534
    ],
    "volume": 473409985024
  },
  {
    "ts": 1600041600,
    "supply": 13327458191519,
    "trustlines": {
      "total": 19057,
      "authorized": 19057,
      "funded": 1723
    },
    "payments": 115,
    "payments_amount": 3059828427895,
    "trades": 1237,
    "traded_amount": 51532164112,
    "price": [
      12.608766361707534,
      12.660382513661203,
      12.185103830299843,
      12.457228429747266
    ],
    "volume": 153885316720
  },
  {
    "ts": 1600128000,
    "supply": 13934909991518,
    "trustlines": {
      "total": 19184,
      "authorized": 19184,
      "funded": 1725
    },
    "payments": 79,
    "payments_amount": 779430534551,
    "trades": 1391,
    "traded_amount": 49585945356,
    "price": [
      12.457228429747266,
      12.868377253814147,
      12.255198487712665,
      12.828582876698226
    ],
    "volume": 290006295300
  },
  {
    "ts": 1600214400,
    "supply": 13806243891518,
    "trustlines": {
      "total": 19322,
      "authorized": 19322,
      "funded": 1729
    },
    "payments": 33,
    "payments_amount": 137348348688,
    "trades": 1412,
    "traded_amount": 84310090789,
    "price": [
      12.828582876698226,
      13.262599503603383,
      12.284942908428462,
      12.325337331334334
    ],
    "volume": 688433993499
  },
  {
    "ts": 1600300800,
    "supply": 10157495091518,
    "trustlines": {
      "total": 19434,
      "authorized": 19434,
      "funded": 1730
    },
    "payments": 207,
    "payments_amount": 23160079858864,
    "trades": 1466,
    "traded_amount": 241161941052,
    "price": [
      12.325337331334334,
      12.753880421586034,
      12.194307008162461,
      12.351315262384832
    ],
    "volume": 2336793220522
  },
  {
    "ts": 1600387200,
    "supply": 11288635891518,
    "trustlines": {
      "total": 19598,
      "authorized": 19598,
      "funded": 1735
    },
    "payments": 139,
    "payments_amount": 5214764171847,
    "trades": 1725,
    "traded_amount": 70882819489,
    "price": [
      12.351315262384832,
      12.901677291246685,
      12.351315262384832,
      12.855697252941177
    ],
    "volume": 182457809349
  },
  {
    "ts": 1600473600,
    "supply": 11285889691518,
    "trustlines": {
      "total": 19716,
      "authorized": 19716,
      "funded": 1736
    },
    "payments": 14,
    "payments_amount": 3834908897,
    "trades": 1437,
    "traded_amount": 62349555445,
    "price": [
      12.855697252941177,
      12.984335664335664,
      12.599972796517955,
      12.768619839881508
    ],
    "volume": 119102450028
  },
  {
    "ts": 1600560000,
    "supply": 11269457691518,
    "trustlines": {
      "total": 19814,
      "authorized": 19814,
      "funded": 1733
    },
    "payments": 8,
    "payments_amount": 20427700000,
    "trades": 1300,
    "traded_amount": 60218511344,
    "price": [
      12.768619839881508,
      13.262607267971859,
      12.754470426409904,
      13.011719059307943
    ],
    "volume": 110312962008
  },
  {
    "ts": 1600646400,
    "supply": 11042922791518,
    "trustlines": {
      "total": 19945,
      "authorized": 19945,
      "funded": 1736
    },
    "payments": 37,
    "payments_amount": 7813207281355,
    "trades": 1997,
    "traded_amount": 79236115623,
    "price": [
      13.011719059307943,
      14.391257503463137,
      12.916624281790657,
      14.249779610408076
    ],
    "volume": 306576577871
  },
  {
    "ts": 1600732800,
    "supply": 11640514891517,
    "trustlines": {
      "total": 20037,
      "authorized": 20037,
      "funded": 1743
    },
    "payments": 86,
    "payments_amount": 958033186378,
    "trades": 2043,
    "traded_amount": 65115708986,
    "price": [
      14.249779610408076,
      14.352,
      13.846175346850293,
      13.993667094727211
    ],
    "volume": 150270480335
  },
  {
    "ts": 1600819200,
    "supply": 11606372891517,
    "trustlines": {
      "total": 20157,
      "authorized": 20157,
      "funded": 1744
    },
    "payments": 48,
    "payments_amount": 93669084870,
    "trades": 2146,
    "traded_amount": 74640410441,
    "price": [
      13.993667094727211,
      14.925465838509316,
      13.833003534824236,
      14.88766485
    ],
    "volume": 264325683656
  },
  {
    "ts": 1600905600,
    "supply": 10241332891516,
    "trustlines": {
      "total": 20281,
      "authorized": 20281,
      "funded": 1747
    },
    "payments": 300,
    "payments_amount": 3816364951589,
    "trades": 1876,
    "traded_amount": 65408461671,
    "price": [
      14.88766485,
      14.930719139425173,
      14.13485696988043,
      14.238421331686052
    ],
    "volume": 151944386618
  },
  {
    "ts": 1600992000,
    "supply": 9124069891516,
    "trustlines": {
      "total": 20431,
      "authorized": 20431,
      "funded": 1752
    },
    "payments": 183,
    "payments_amount": 10363769809074,
    "trades": 2011,
    "traded_amount": 90154556057,
    "price": [
      14.238421331686052,
      14.41957261802859,
      13.264881243804666,
      13.43698213215327
    ],
    "volume": 279070964263
  },
  {
    "ts": 1601078400,
    "supply": 9119069891516,
    "trustlines": {
      "total": 20531,
      "authorized": 20531,
      "funded": 1753
    },
    "payments": 10,
    "payments_amount": 5804852688,
    "trades": 1951,
    "traded_amount": 69293918952,
    "price": [
      13.43698213215327,
      13.751341748733518,
      13.089257656481653,
      13.498984918793504
    ],
    "volume": 164704037911
  },
  {
    "ts": 1601164800,
    "supply": 8959329891516,
    "trustlines": {
      "total": 20634,
      "authorized": 20634,
      "funded": 1754
    },
    "payments": 32,
    "payments_amount": 172327642564,
    "trades": 1810,
    "traded_amount": 90086362927,
    "price": [
      13.498984918793504,
      13.989667051627316,
      13.417675893886967,
      13.652256740914419
    ],
    "volume": 321066764861
  },
  {
    "ts": 1601251200,
    "supply": 9011116091516,
    "trustlines": {
      "total": 20741,
      "authorized": 20741,
      "funded": 1755
    },
    "payments": 132,
    "payments_amount": 1790610920151,
    "trades": 2137,
    "traded_amount": 80979124762,
    "price": [
      13.652256740914419,
      13.731150171042403,
      13.114507299270073,
      13.731150141940308
    ],
    "volume": 233202874926
  },
  {
    "ts": 1601337600,
    "supply": 9728321091516,
    "trustlines": {
      "total": 20851,
      "authorized": 20851,
      "funded": 1765
    },
    "payments": 138,
    "payments_amount": 927795038419,
    "trades": 1941,
    "traded_amount": 92071704809,
    "price": [
      13.731150141940308,
      13.85001485001485,
      13.318487755978806,
      13.50253807106599
    ],
    "volume": 377829865980
  },
  {
    "ts": 1601424000,
    "supply": 9698503591516,
    "trustlines": {
      "total": 20948,
      "authorized": 20948,
      "funded": 1765
    },
    "payments": 24,
    "payments_amount": 49884630572,
    "trades": 1966,
    "traded_amount": 81883539674,
    "price": [
      13.50253807106599,
      13.85001485001485,
      13.374230666309874,
      13.45359930615785
    ],
    "volume": 472106077916
  },
  {
    "ts": 1601510400,
    "supply": 10296081291514,
    "trustlines": {
      "total": 21040,
      "authorized": 21040,
      "funded": 1770
    },
    "payments": 162,
    "payments_amount": 654689638776,
    "trades": 2216,
    "traded_amount": 133912048045,
    "price": [
      13.45359930615785,
      13.971253181614014,
      12.995940649496081,
      13.749668092639032
    ],
    "volume": 907696675790
  },
  {
    "ts": 1601596800,
    "supply": 10868331491513,
    "trustlines": {
      "total": 21154,
      "authorized": 21154,
      "funded": 1777
    },
    "payments": 165,
    "payments_amount": 581645733739,
    "trades": 1760,
    "traded_amount": 106372228815,
    "price": [
      13.749668092639032,
      14.427182968219686,
      13.536347775516138,
      14.106800225733634
    ],
    "volume": 562705883929
  },
  {
    "ts": 1601683200,
    "supply": 10807389991513,
    "trustlines": {
      "total": 21260,
      "authorized": 21260,
      "funded": 1778
    },
    "payments": 12,
    "payments_amount": 120769457278,
    "trades": 1662,
    "traded_amount": 45394402655,
    "price": [
      14.106800225733634,
      14.163936912344555,
      13.84265993765771,
      14.063907622564752
    ],
    "volume": 59956910790
  },
  {
    "ts": 1601769600,
    "supply": 10802179991513,
    "trustlines": {
      "total": 21380,
      "authorized": 21380,
      "funded": 1780
    },
    "payments": 12,
    "payments_amount": 5430652708,
    "trades": 1771,
    "traded_amount": 45757878880,
    "price": [
      14.063907622564752,
      14.191583105439076,
      13.597810218978102,
      13.67727111086796
    ],
    "volume": 75861072590
  },
  {
    "ts": 1601856000,
    "supply": 11656768491513,
    "trustlines": {
      "total": 21479,
      "authorized": 21479,
      "funded": 1786
    },
    "payments": 147,
    "payments_amount": 1107756569629,
    "trades": 1946,
    "traded_amount": 88979420050,
    "price": [
      13.67727111086796,
      13.86723163841808,
      13.509505151647076,
      13.59954439042581
    ],
    "volume": 321518329111
  },
  {
    "ts": 1601942400,
    "supply": 11633538291513,
    "trustlines": {
      "total": 21617,
      "authorized": 21617,
      "funded": 1787
    },
    "payments": 28,
    "payments_amount": 39011992449,
    "trades": 2675,
    "traded_amount": 175713426032,
    "price": [
      13.59954439042581,
      14.03439999716278,
      13.314211385164286,
      13.845341555877042
    ],
    "volume": 1236804754800
  },
  {
    "ts": 1602028800,
    "supply": 12286271591513,
    "trustlines": {
      "total": 21764,
      "authorized": 21764,
      "funded": 1796
    },
    "payments": 128,
    "payments_amount": 928488112176,
    "trades": 1989,
    "traded_amount": 62849693840,
    "price": [
      13.845341555877042,
      14.115026389320088,
      13.640691372491577,
      13.911273486430062
    ],
    "volume": 245110316311
  },
  {
    "ts": 1602115200,
    "supply": 12996888191511,
    "trustlines": {
      "total": 21909,
      "authorized": 21909,
      "funded": 1803
    },
    "payments": 139,
    "payments_amount": 756337492502,
    "trades": 1941,
    "traded_amount": 76780503291,
    "price": [
      13.911273486430062,
      14.201733049559136,
      13.579906678331875,
      13.724046532174937
    ],
    "volume": 277791940234
  },
  {
    "ts": 1602201600,
    "supply": 9406957191511,
    "trustlines": {
      "total": 22075,
      "authorized": 22075,
      "funded": 1807
    },
    "payments": 108,
    "payments_amount": 16705577159435,
    "trades": 2105,
    "traded_amount": 99068823298,
    "price": [
      13.724046532174937,
      13.79464417813286,
      13.219852104664392,
      13.358642360944373
    ],
    "volume": 504043863298
  },
  {
    "ts": 1602288000,
    "supply": 9392980891511,
    "trustlines": {
      "total": 22240,
      "authorized": 22240,
      "funded": 1810
    },
    "payments": 6,
    "payments_amount": 14045300000,
    "trades": 2188,
    "traded_amount": 82704130331,
    "price": [
      13.358642360944373,
      13.358642360944373,
      12.73410245845351,
      12.96550279329609
    ],
    "volume": 375193651232
  },
  {
    "ts": 1602374400,
    "supply": 9293846791511,
    "trustlines": {
      "total": 22402,
      "authorized": 22402,
      "funded": 1810
    },
    "payments": 15,
    "payments_amount": 100618774461,
    "trades": 2068,
    "traded_amount": 58044270192,
    "price": [
      12.96550279329609,
      13.016961031679282,
      12.711738891936369,
      12.921202840038982
    ],
    "volume": 165456990262
  },
  {
    "ts": 1602460800,
    "supply": 8714723691511,
    "trustlines": {
      "total": 22526,
      "authorized": 22526,
      "funded": 1812
    },
    "payments": 114,
    "payments_amount": 5392262818066,
    "trades": 2350,
    "traded_amount": 126491638588,
    "price": [
      12.921202840038982,
      13.217508586111876,
      12.055555555555555,
      12.931187122762328
    ],
    "volume": 562360322020
  },
  {
    "ts": 1602547200,
    "supply": 8682249391511,
    "trustlines": {
      "total": 22678,
      "authorized": 22678,
      "funded": 1815
    },
    "payments": 45,
    "payments_amount": 37932011216,
    "trades": 2449,
    "traded_amount": 72242666009,
    "price": [
      12.931187122762328,
      13.266647193214894,
      12.874705147772998,
      13.116492455011187
    ],
    "volume": 170183878565
  },
  {
    "ts": 1602633600,
    "supply": 9168330891511,
    "trustlines": {
      "total": 22817,
      "authorized": 22817,
      "funded": 1821
    },
    "payments": 97,
    "payments_amount": 534804446814,
    "trades": 2415,
    "traded_amount": 75665849019,
    "price": [
      13.116492455011187,
      13.595604116351783,
      13.028855125484426,
      13.463802397282928
    ],
    "volume": 372094938844
  },
  {
    "ts": 1602720000,
    "supply": 8714007391511,
    "trustlines": {
      "total": 22934,
      "authorized": 22934,
      "funded": 1823
    },
    "payments": 98,
    "payments_amount": 7599525979082,
    "trades": 2260,
    "traded_amount": 110924758800,
    "price": [
      13.463802397282928,
      13.95005980861244,
      13.463802397282928,
      13.542723631508679
    ],
    "volume": 671415821079
  },
  {
    "ts": 1602806400,
    "supply": 9282171091511,
    "trustlines": {
      "total": 23051,
      "authorized": 23051,
      "funded": 1812
    },
    "payments": 183,
    "payments_amount": 590036858965,
    "trades": 2781,
    "traded_amount": 80291752471,
    "price": [
      13.542723631508679,
      13.808321351733518,
      12.670266575529734,
      12.711835114236095
    ],
    "volume": 289539397157
  },
  {
    "ts": 1602892800,
    "supply": 9252716091511,
    "trustlines": {
      "total": 23179,
      "authorized": 23179,
      "funded": 1815
    },
    "payments": 9,
    "payments_amount": 29784842081,
    "trades": 2785,
    "traded_amount": 92218575938,
    "price": [
      12.711835114236095,
      12.928541579607188,
      11.991873003194888,
      12.20686705767351
    ],
    "volume": 423997321288
  },
  {
    "ts": 1602979200,
    "supply": 9094495991511,
    "trustlines": {
      "total": 23279,
      "authorized": 23279,
      "funded": 1820
    },
    "payments": 22,
    "payments_amount": 187107129725,
    "trades": 1774,
    "traded_amount": 95334419091,
    "price": [
      12.20686705767351,
      12.600108813928182,
      12.19007852199262,
      12.56981951418103
    ],
    "volume": 560828026450
  },
  {
    "ts": 1603065600,
    "supply": 9714673691510,
    "trustlines": {
      "total": 23412,
      "authorized": 23412,
      "funded": 1826
    },
    "payments": 139,
    "payments_amount": 641301322906,
    "trades": 2402,
    "traded_amount": 93421994079,
    "price": [
      12.56981951418103,
      12.575422688010557,
      11.519980829612397,
      11.681163896438818
    ],
    "volume": 453112981930
  },
  {
    "ts": 1603152000,
    "supply": 9544625091510,
    "trustlines": {
      "total": 23569,
      "authorized": 23569,
      "funded": 1827
    },
    "payments": 39,
    "payments_amount": 181726590159,
    "trades": 2742,
    "traded_amount": 123764176775,
    "price": [
      11.681163896438818,
      12.391465205313674,
      11.539936440677966,
      12.355796929094321
    ],
    "volume": 590711309064
  },
  {
    "ts": 1603238400,
    "supply": 9543483691510,
    "trustlines": {
      "total": 23751,
      "authorized": 23751,
      "funded": 1838
    },
    "payments": 30,
    "payments_amount": 4001628629,
    "trades": 718,
    "traded_amount": 48334327316,
    "price": [
      12.355796929094321,
      12.366576599697973,
      11.54621068894842,
      11.906257421942398
    ],
    "volume": 271436599888
  },
  {
    "ts": 1603324800,
    "supply": 8998802091509,
    "trustlines": {
      "total": 23902,
      "authorized": 23902,
      "funded": 1845
    },
    "payments": 252,
    "payments_amount": 7555618196142,
    "trades": 733,
    "traded_amount": 117633829972,
    "price": [
      11.906257421942398,
      11.925916261416202,
      11.463350354142646,
      11.724877733333333
    ],
    "volume": 1171312654766
  },
  {
    "ts": 1603411200,
    "supply": 9065907791509,
    "trustlines": {
      "total": 24006,
      "authorized": 24006,
      "funded": 1850
    },
    "payments": 62,
    "payments_amount": 141372874920,
    "trades": 1039,
    "traded_amount": 90456754211,
    "price": [
      11.724877733333333,
      12.051732563200195,
      11.547692909138009,
      11.91407230014825
    ],
    "volume": 862579016711
  },
  {
    "ts": 1603497600,
    "supply": 10061143491508,
    "trustlines": {
      "total": 24129,
      "authorized": 24129,
      "funded": 1855
    },
    "payments": 170,
    "payments_amount": 1045671080708,
    "trades": 905,
    "traded_amount": 29803149338,
    "price": [
      11.91407230014825,
      11.985461319775283,
      11.627900487791822,
      11.68545594460548
    ],
    "volume": 186119283341
  },
  {
    "ts": 1603584000,
    "supply": 10050289091508,
    "trustlines": {
      "total": 24254,
      "authorized": 24254,
      "funded": 1856
    },
    "payments": 45,
    "payments_amount": 23224837411,
    "trades": 414,
    "traded_amount": 31796558488,
    "price": [
      11.68545594460548,
      12.116419294990724,
      11.673904193394572,
      11.969811799118022
    ],
    "volume": 201301133229
  },
  {
    "ts": 1603670400,
    "supply": 10503493191507,
    "trustlines": {
      "total": 24378,
      "authorized": 24378,
      "funded": 1861
    },
    "payments": 141,
    "payments_amount": 523206461562,
    "trades": 707,
    "traded_amount": 33520402520,
    "price": [
      11.969811799118022,
      12.464627541688799,
      11.854441885216042,
      12.321024939148852
    ],
    "volume": 224393641431
  },
  {
    "ts": 1603756800,
    "supply": 10238996291507,
    "trustlines": {
      "total": 24520,
      "authorized": 24520,
      "funded": 1875
    },
    "payments": 36,
    "payments_amount": 268547569713,
    "trades": 890,
    "traded_amount": 91854976729,
    "price": [
      12.321024939148852,
      12.382271793366598,
      12.0059,
      12.23172639908173
    ],
    "volume": 860618841600
  },
  {
    "ts": 1603843200,
    "supply": 11266830191507,
    "trustlines": {
      "total": 24622,
      "authorized": 24622,
      "funded": 1874
    },
    "payments": 237,
    "payments_amount": 1234967200904,
    "trades": 841,
    "traded_amount": 94946839211,
    "price": [
      12.23172639908173,
      12.83395890384726,
      12.231726294319131,
      12.716681982276693
    ],
    "volume": 976265034312
  },
  {
    "ts": 1603929600,
    "supply": 8737696291507,
    "trustlines": {
      "total": 24737,
      "authorized": 24737,
      "funded": 1887
    },
    "payments": 131,
    "payments_amount": 10464975930769,
    "trades": 1118,
    "traded_amount": 492370027156,
    "price": [
      12.716681982276693,
      13.166666666666666,
      12.523809523809524,
      12.787770676659566
    ],
    "volume": 3917915798361
  },
  {
    "ts": 1604016000,
    "supply": 9338411591507,
    "trustlines": {
      "total": 24825,
      "authorized": 24825,
      "funded": 1909
    },
    "payments": 139,
    "payments_amount": 801462200277,
    "trades": 1088,
    "traded_amount": 41828216801,
    "price": [
      12.787770676659566,
      13.374421746432981,
      12.614392490896789,
      12.968176739448616
    ],
    "volume": 234209500555
  },
  {
    "ts": 1604102400,
    "supply": 9326260291507,
    "trustlines": {
      "total": 24922,
      "authorized": 24922,
      "funded": 1912
    },
    "payments": 28,
    "payments_amount": 17427722768,
    "trades": 586,
    "traded_amount": 15677957596,
    "price": [
      12.968176739448616,
      12.998985867148596,
      12.564999947740171,
      12.85372162217413
    ],
    "volume": 38264918231
  },
  {
    "ts": 1604188800,
    "supply": 9289293891507,
    "trustlines": {
      "total": 25032,
      "authorized": 25032,
      "funded": 1915
    },
    "payments": 9,
    "payments_amount": 38132052403,
    "trades": 452,
    "traded_amount": 9641093046,
    "price": [
      12.85372162217413,
      13.054919919068587,
      12.751357490315133,
      12.751357490315133
    ],
    "volume": 88118295148
  },
  {
    "ts": 1604275200,
    "supply": 10744013191507,
    "trustlines": {
      "total": 25138,
      "authorized": 25138,
      "funded": 1923
    },
    "payments": 346,
    "payments_amount": 1628453482059,
    "trades": 822,
    "traded_amount": 29646996470,
    "price": [
      12.751357490315133,
      13.21435425062368,
      12.642639047822302,
      13.19393473
    ],
    "volume": 237129475228
  },
  {
    "ts": 1604361600,
    "supply": 11180859091507,
    "trustlines": {
      "total": 25248,
      "authorized": 25248,
      "funded": 1920
    },
    "payments": 99,
    "payments_amount": 506901586017,
    "trades": 1059,
    "traded_amount": 56424909173,
    "price": [
      13.19393473,
      13.610401971291148,
      13.13225365676017,
      13.285190918428862
    ],
    "volume": 480940459755
  },
  {
    "ts": 1604448000,
    "supply": 11926066791504,
    "trustlines": {
      "total": 25347,
      "authorized": 25347,
      "funded": 1919
    },
    "payments": 193,
    "payments_amount": 886309874379,
    "trades": 1079,
    "traded_amount": 62289342450,
    "price": [
      13.285190918428862,
      13.720000183184013,
      13.179254536537519,
      13.395993381799212
    ],
    "volume": 497168809184
  },
  {
    "ts": 1604534400,
    "supply": 12375881791505,
    "trustlines": {
      "total": 25463,
      "authorized": 25463,
      "funded": 1925
    },
    "payments": 86,
    "payments_amount": 496846749000,
    "trades": 1988,
    "traded_amount": 93078605376,
    "price": [
      13.395993381799212,
      13.60207024130145,
      12.119999940249556,
      12.469821641440193
    ],
    "volume": 615580206252
  },
  {
    "ts": 1604620800,
    "supply": 8923799291505,
    "trustlines": {
      "total": 25579,
      "authorized": 25579,
      "funded": 1938
    },
    "payments": 70,
    "payments_amount": 14654960690279,
    "trades": 1357,
    "traded_amount": 50220561497,
    "price": [
      12.469821641440193,
      12.469821641440193,
      11.731240690380696,
      11.87769208264316
    ],
    "volume": 445026832648
  },
  {
    "ts": 1604707200,
    "supply": 9726708191505,
    "trustlines": {
      "total": 25710,
      "authorized": 25710,
      "funded": 1938
    },
    "payments": 114,
    "payments_amount": 809476709965,
    "trades": 2340,
    "traded_amount": 108731823334,
    "price": [
      11.87769208264316,
      12.711910240014017,
      11.62144668228302,
      12.53499765007274
    ],
    "volume": 690979107739
  },
  {
    "ts": 1604793600,
    "supply": 9711794791505,
    "trustlines": {
      "total": 25829,
      "authorized": 25829,
      "funded": 1941
    },
    "payments": 16,
    "payments_amount": 15186578631,
    "trades": 1040,
    "traded_amount": 37325718893,
    "price": [
      12.53499765007274,
      12.649113731936891,
      12.091146368283045,
      12.239493707789448
    ],
    "volume": 258606675797
  },
  {
    "ts": 1604880000,
    "supply": 9473876591505,
    "trustlines": {
      "total": 25985,
      "authorized": 25985,
      "funded": 1944
    },
    "payments": 46,
    "payments_amount": 241425854498,
    "trades": 1297,
    "traded_amount": 71392007083,
    "price": [
      12.239493707789448,
      12.774540008975528,
      12.201219512195122,
      12.542280563471373
    ],
    "volume": 485106849533
  },
  {
    "ts": 1604966400,
    "supply": 9374883191503,
    "trustlines": {
      "total": 26168,
      "authorized": 26168,
      "funded": 1942
    },
    "payments": 89,
    "payments_amount": 715515132487,
    "trades": 860,
    "traded_amount": 37111021197,
    "price": [
      12.542280563471373,
      12.614607887659329,
      12.166989474484716,
      12.53141616493841
    ],
    "volume": 274816374364
  },
  {
    "ts": 1605052800,
    "supply": 9770132791503,
    "trustlines": {
      "total": 26353,
      "authorized": 26353,
      "funded": 1943
    },
    "payments": 100,
    "payments_amount": 516742899587,
    "trades": 972,
    "traded_amount": 30661866769,
    "price": [
      12.53141616493841,
      12.53141616493841,
      12.167701863354038,
      12.384775709345838
    ],
    "volume": 247230966934
  },
  {
    "ts": 1605139200,
    "supply": 10737902291501,
    "trustlines": {
      "total": 26534,
      "authorized": 26534,
      "funded": 1947
    },
    "payments": 164,
    "payments_amount": 1260365730006,
    "trades": 570,
    "traded_amount": 11874953658,
    "price": [
      12.384775709345838,
      12.6,
      12.36499992996954,
      12.36499992996954
    ],
    "volume": 80438713581
  },
  {
    "ts": 1605225600,
    "supply": 9578323891501,
    "trustlines": {
      "total": 26677,
      "authorized": 26677,
      "funded": 1953
    },
    "payments": 104,
    "payments_amount": 8890505095247,
    "trades": 501,
    "traded_amount": 8762245441,
    "price": [
      12.36499992996954,
      12.465020576131687,
      12.11999998028764,
      12.11999998028764
    ],
    "volume": 75150628487
  },
  {
    "ts": 1605312000,
    "supply": 9566080091501,
    "trustlines": {
      "total": 26746,
      "authorized": 26746,
      "funded": 1954
    },
    "payments": 17,
    "payments_amount": 14157738527,
    "trades": 751,
    "traded_amount": 22456163477,
    "price": [
      12.11999998028764,
      12.370000007808283,
      11.9,
      12.344260282709167
    ],
    "volume": 152227716703
  },
  {
    "ts": 1605398400,
    "supply": 9559570091501,
    "trustlines": {
      "total": 26832,
      "authorized": 26832,
      "funded": 1957
    },
    "payments": 16,
    "payments_amount": 7103283811,
    "trades": 645,
    "traded_amount": 15926263702,
    "price": [
      12.344260282709167,
      12.635010848033204,
      12.171999993453337,
      12.484000000024553
    ],
    "volume": 135124195976
  },
  {
    "ts": 1605484800,
    "supply": 10479341903100,
    "trustlines": {
      "total": 27082,
      "authorized": 27082,
      "funded": 1965
    },
    "payments": 166,
    "payments_amount": 1341015509038,
    "trades": 650,
    "traded_amount": 81883287782,
    "price": [
      12.484000000024553,
      12.562337697241954,
      11.842105263157896,
      12.140000002566259
    ],
    "volume": 798318029020
  },
  {
    "ts": 1605571200,
    "supply": 9283446103100,
    "trustlines": {
      "total": 27389,
      "authorized": 27389,
      "funded": 1976
    },
    "payments": 150,
    "payments_amount": 7368368954998,
    "trades": 980,
    "traded_amount": 94278528820,
    "price": [
      12.140000002566259,
      12.140000002566259,
      11.287419797395053,
      11.535817669595687
    ],
    "volume": 752480571981
  },
  {
    "ts": 1605657600,
    "supply": 10003323603097,
    "trustlines": {
      "total": 27739,
      "authorized": 27739,
      "funded": 1980
    },
    "payments": 151,
    "payments_amount": 787449141336,
    "trades": 523,
    "traded_amount": 43986162844,
    "price": [
      11.535817669595687,
      12.200000003569432,
      11.503540829799855,
      11.906346110802625
    ],
    "volume": 417699481412
  },
  {
    "ts": 1605744000,
    "supply": 10956545503097,
    "trustlines": {
      "total": 27926,
      "authorized": 27926,
      "funded": 1985
    },
    "payments": 112,
    "payments_amount": 1282082205313,
    "trades": 549,
    "traded_amount": 27878205406,
    "price": [
      11.906346110802625,
      12.252000000654483,
      11.611684823254164,
      11.868652613219654
    ],
    "volume": 271509959234
  },
  {
    "ts": 1605830400,
    "supply": 10906395503097,
    "trustlines": {
      "total": 28042,
      "authorized": 28042,
      "funded": 1987
    },
    "payments": 27,
    "payments_amount": 51705412402,
    "trades": 588,
    "traded_amount": 103342299258,
    "price": [
      11.868652613219654,
      11.9174355,
      11.316560818178568,
      11.331119264162643
    ],
    "volume": 966732065227
  },
  {
    "ts": 1605916800,
    "supply": 11500928203096,
    "trustlines": {
      "total": 28095,
      "authorized": 28095,
      "funded": 1996
    },
    "payments": 156,
    "payments_amount": 661114387590,
    "trades": 1477,
    "traded_amount": 161394524997,
    "price": [
      11.331119264162643,
      11.331119264162643,
      9.149991038177365,
      9.149999996603851
    ],
    "volume": 1375104729171
  },
  {
    "ts": 1606003200,
    "supply": 11447293003096,
    "trustlines": {
      "total": 28145,
      "authorized": 28145,
      "funded": 2008
    },
    "payments": 43,
    "payments_amount": 60075501698,
    "trades": 1686,
    "traded_amount": 64132275881,
    "price": [
      9.149999996603851,
      10.275944806702498,
      8.771856609008928,
      9.625379441402245
    ],
    "volume": 368466685119
  },
  {
    "ts": 1606089600,
    "supply": 8751628203096,
    "trustlines": {
      "total": 28204,
      "authorized": 28204,
      "funded": 2017
    },
    "payments": 94,
    "payments_amount": 11560081513591,
    "trades": 1676,
    "traded_amount": 176069393167,
    "price": [
      9.625379441402245,
      9.821406758700755,
      7.575757575757576,
      7.575757575757576
    ],
    "volume": 1222363996735
  },
  {
    "ts": 1606176000,
    "supply": 8173387903096,
    "trustlines": {
      "total": 28338,
      "authorized": 28338,
      "funded": 2039
    },
    "payments": 89,
    "payments_amount": 1066828568018,
    "trades": 5288,
    "traded_amount": 839629450730,
    "price": [
      7.575757575757576,
      7.603118727806083,
      4.9860415708578465,
      5.166252906415799
    ],
    "volume": 3339007570349
  },
  {
    "ts": 1606262400,
    "supply": 8632331003096,
    "trustlines": {
      "total": 28465,
      "authorized": 28465,
      "funded": 2032
    },
    "payments": 245,
    "payments_amount": 1503839746846,
    "trades": 5190,
    "traded_amount": 421341583902,
    "price": [
      5.166252906415799,
      5.496524843063486,
      4.334126282320573,
      5.130281498658431
    ],
    "volume": 1032013034214
  },
  {
    "ts": 1606348800,
    "supply": 9391671603096,
    "trustlines": {
      "total": 28671,
      "authorized": 28671,
      "funded": 2051
    },
    "payments": 113,
    "payments_amount": 1183921379524,
    "trades": 6820,
    "traded_amount": 803966304584,
    "price": [
      5.130281498658431,
      6.896551725369305,
      4.946602426802666,
      6.026744002259436
    ],
    "volume": 2711113443729
  },
  {
    "ts": 1606435200,
    "supply": 9533514803093,
    "trustlines": {
      "total": 28934,
      "authorized": 28934,
      "funded": 2057
    },
    "payments": 455,
    "payments_amount": 11492380779304,
    "trades": 4871,
    "traded_amount": 558968058173,
    "price": [
      6.026744002259436,
      6.530352403227078,
      4.760767342300633,
      4.760767342300633
    ],
    "volume": 1776506741827
  },
  {
    "ts": 1606521600,
    "supply": 9304355803093,
    "trustlines": {
      "total": 29160,
      "authorized": 29160,
      "funded": 2072
    },
    "payments": 69,
    "payments_amount": 359156428044,
    "trades": 2807,
    "traded_amount": 326133713633,
    "price": [
      4.760767342300633,
      5.066364910144411,
      4.549085866363573,
      5.005603206885372
    ],
    "volume": 1094670292368
  },
  {
    "ts": 1606608000,
    "supply": 10508652603093,
    "trustlines": {
      "total": 29456,
      "authorized": 29456,
      "funded": 2070
    },
    "payments": 286,
    "payments_amount": 1722389006520,
    "trades": 2916,
    "traded_amount": 244795798923,
    "price": [
      5.005603206885372,
      5.268634337451226,
      4.916949609790892,
      5.2058659697747425
    ],
    "volume": 768567511530
  },
  {
    "ts": 1606694400,
    "supply": 10415543503094,
    "trustlines": {
      "total": 29769,
      "authorized": 29769,
      "funded": 2076
    },
    "payments": 37,
    "payments_amount": 106758350233,
    "trades": 1859,
    "traded_amount": 214774908897,
    "price": [
      5.2058659697747425,
      5.239170259000619,
      4.873474967489276,
      4.980002438726984
    ],
    "volume": 653198747493
  },
  {
    "ts": 1606780800,
    "supply": 10877413103094,
    "trustlines": {
      "total": 29932,
      "authorized": 29932,
      "funded": 2081
    },
    "payments": 115,
    "payments_amount": 639991289089,
    "trades": 2296,
    "traded_amount": 214644718543,
    "price": [
      4.980002438726984,
      5.800000028290807,
      4.920642141419125,
      5.458515130173715
    ],
    "volume": 600163959106
  },
  {
    "ts": 1606867200,
    "supply": 16303507403089,
    "trustlines": {
      "total": 30060,
      "authorized": 30060,
      "funded": 2078
    },
    "payments": 617,
    "payments_amount": 5857456783073,
    "trades": 2727,
    "traded_amount": 174174344584,
    "price": [
      5.458515130173715,
      5.613612895622896,
      5.298205530849548,
      5.418522740446148
    ],
    "volume": 372508857491
  },
  {
    "ts": 1606953600,
    "supply": 12619877803089,
    "trustlines": {
      "total": 30141,
      "authorized": 30141,
      "funded": 2077
    },
    "payments": 35,
    "payments_amount": 15151990714412,
    "trades": 2719,
    "traded_amount": 187353074657,
    "price": [
      5.418522740446148,
      5.559995918713548,
      5.263957192393897,
      5.449063841483616
    ],
    "volume": 474705944993
  },
  {
    "ts": 1607040000,
    "supply": 18150939603089,
    "trustlines": {
      "total": 30259,
      "authorized": 30259,
      "funded": 2072
    },
    "payments": 360,
    "payments_amount": 5994424308833,
    "trades": 1974,
    "traded_amount": 133257986086,
    "price": [
      5.449063841483616,
      6.231142523393126,
      5.3,
      6.130080304051983
    ],
    "volume": 424113175273
  },
  {
    "ts": 1607126400,
    "supply": 17985642103089,
    "trustlines": {
      "total": 30362,
      "authorized": 30362,
      "funded": 2075
    },
    "payments": 21,
    "payments_amount": 166642398134,
    "trades": 1199,
    "traded_amount": 182261144105,
    "price": [
      6.130080304051983,
      6.206080954521182,
      5.7,
      5.849663644340451
    ],
    "volume": 914137548227
  },
  {
    "ts": 1607212800,
    "supply": 19593116703090,
    "trustlines": {
      "total": 30484,
      "authorized": 30484,
      "funded": 2073
    },
    "payments": 146,
    "payments_amount": 1751563736830,
    "trades": 1465,
    "traded_amount": 80006661798,
    "price": [
      5.849663644340451,
      5.967280700095061,
      5.576768156267038,
      5.675210888767289
    ],
    "volume": 274808197891
  },
  {
    "ts": 1607299200,
    "supply": 14473289903090,
    "trustlines": {
      "total": 30581,
      "authorized": 30581,
      "funded": 2078
    },
    "payments": 47,
    "payments_amount": 19184868393581,
    "trades": 1442,
    "traded_amount": 102546929439,
    "price": [
      5.675210888767289,
      6.101873215419734,
      5.675210888767289,
      5.957974737637144
    ],
    "volume": 288094409094
  },
  {
    "ts": 1607385600,
    "supply": 19524036303090,
    "trustlines": {
      "total": 30662,
      "authorized": 30662,
      "funded": 2079
    },
    "payments": 354,
    "payments_amount": 5262256781033,
    "trades": 2008,
    "traded_amount": 107588335351,
    "price": [
      5.957974737637144,
      6.666666674108776,
      5.9429359292077475,
      6.536074980962778
    ],
    "volume": 400256914550
  },
  {
    "ts": 1607472000,
    "supply": 25712866103089,
    "trustlines": {
      "total": 30746,
      "authorized": 30746,
      "funded": 2097
    },
    "payments": 297,
    "payments_amount": 6350511741300,
    "trades": 2988,
    "traded_amount": 266846088483,
    "price": [
      6.536074980962778,
      7.115961061190606,
      5.70520449194504,
      5.790692757503042
    ],
    "volume": 992224176885
  },
  {
    "ts": 1607558400,
    "supply": 26332762203090,
    "trustlines": {
      "total": 30826,
      "authorized": 30826,
      "funded": 2102
    },
    "payments": 139,
    "payments_amount": 915794074365,
    "trades": 2200,
    "traded_amount": 123582483943,
    "price": [
      5.790692757503042,
      6.200026481881979,
      5.649999989969319,
      6.183573697922214
    ],
    "volume": 430066587596
  },
  {
    "ts": 1607644800,
    "supply": 20493934403087,
    "trustlines": {
      "total": 30872,
      "authorized": 30872,
      "funded": 2099
    },
    "payments": 169,
    "payments_amount": 29571563873862,
    "trades": 1619,
    "traded_amount": 120277245764,
    "price": [
      6.183573697922214,
      6.60000478377344,
      6.1383441896409865,
      6.597560975609756
    ],
    "volume": 446358242450
  },
  {
    "ts": 1607731200,
    "supply": 20318100003087,
    "trustlines": {
      "total": 30905,
      "authorized": 30905,
      "funded": 2098
    },
    "payments": 16,
    "payments_amount": 186552703827,
    "trades": 1138,
    "traded_amount": 126511449429,
    "price": [
      6.597560975609756,
      6.623481572469632,
      6.09474506303693,
      6.278668452963701
    ],
    "volume": 490467222573
  },
  {
    "ts": 1607817600,
    "supply": 20302835603087,
    "trustlines": {
      "total": 30949,
      "authorized": 30949,
      "funded": 2104
    },
    "payments": 17,
    "payments_amount": 15598038596,
    "trades": 1520,
    "traded_amount": 103184849501,
    "price": [
      6.278668452963701,
      6.40570379499393,
      5.582310584265771,
      5.705800912947163
    ],
    "volume": 375478399976
  },
  {
    "ts": 1607904000,
    "supply": 18890433003083,
    "trustlines": {
      "total": 30987,
      "authorized": 30987,
      "funded": 2104
    },
    "payments": 393,
    "payments_amount": 30970102678281,
    "trades": 1205,
    "traded_amount": 56463525555,
    "price": [
      5.705800912947163,
      5.984216730300695,
      5.621372209691852,
      5.883673843506238
    ],
    "volume": 180410132781
  },
  {
    "ts": 1607990400,
    "supply": 18589188303083,
    "trustlines": {
      "total": 31042,
      "authorized": 31042,
      "funded": 2108
    },
    "payments": 28,
    "payments_amount": 302826993876,
    "trades": 1084,
    "traded_amount": 40721834657,
    "price": [
      5.883673843506238,
      6.045437195417162,
      5.687860722830758,
      6.027776216299239
    ],
    "volume": 126386379172
  },
  {
    "ts": 1608076800,
    "supply": 25005372703080,
    "trustlines": {
      "total": 31135,
      "authorized": 31135,
      "funded": 2117
    },
    "payments": 449,
    "payments_amount": 6901927773926,
    "trades": 2623,
    "traded_amount": 344558938938,
    "price": [
      6.027776216299239,
      6.2460983116537685,
      5.1267328349298795,
      5.1267328349298795
    ],
    "volume": 1110409353049
  },
  {
    "ts": 1608163200,
    "supply": 21180552803080,
    "trustlines": {
      "total": 31275,
      "authorized": 31275,
      "funded": 2121
    },
    "payments": 205,
    "payments_amount": 20399119653714,
    "trades": 2873,
    "traded_amount": 398204572514,
    "price": [
      5.1267328349298795,
      5.529759318856675,
      4.75,
      5.372619704863024
    ],
    "volume": 1240777946782
  },
  {
    "ts": 1608249600,
    "supply": 18407657703079,
    "trustlines": {
      "total": 31419,
      "authorized": 31419,
      "funded": 2129
    },
    "payments": 318,
    "payments_amount": 23606226091602,
    "trades": 1665,
    "traded_amount": 229355935464,
    "price": [
      5.372619704863024,
      5.632427241671057,
      5.279999318643997,
      5.409999996801802
    ],
    "volume": 666535133874
  },
  {
    "ts": 1608336000,
    "supply": 18358298403080,
    "trustlines": {
      "total": 31572,
      "authorized": 31572,
      "funded": 2135
    },
    "payments": 33,
    "payments_amount": 61715546128,
    "trades": 1048,
    "traded_amount": 81136273467,
    "price": [
      5.409999996801802,
      5.5140534404979515,
      5.299999367131449,
      5.490114421603931
    ],
    "volume": 266832860366
  },
  {
    "ts": 1608422400,
    "supply": 18257098103080,
    "trustlines": {
      "total": 31738,
      "authorized": 31738,
      "funded": 2139
    },
    "payments": 23,
    "payments_amount": 112800926154,
    "trades": 797,
    "traded_amount": 77445739355,
    "price": [
      5.490114421603931,
      5.715015877617938,
      5.475665595591825,
      5.682052332379916
    ],
    "volume": 301925468929
  },
  {
    "ts": 1608508800,
    "supply": 12913012803080,
    "trustlines": {
      "total": 31903,
      "authorized": 31903,
      "funded": 2142
    },
    "payments": 208,
    "payments_amount": 31707768629431,
    "trades": 1213,
    "traded_amount": 135156089691,
    "price": [
      5.682052332379916,
      6.200006572857386,
      5.547536101022216,
      5.965167589462432
    ],
    "volume": 460477473029
  },
  {
    "ts": 1608595200,
    "supply": 12669661903080,
    "trustlines": {
      "total": 32043,
      "authorized": 32043,
      "funded": 2139
    },
    "payments": 39,
    "payments_amount": 253612335996,
    "trades": 1521,
    "traded_amount": 67025348453,
    "price": [
      5.965167589462432,
      6.508290951675961,
      5.921796500777428,
      6.205262560277218
    ],
    "volume": 222563179596
  },
  {
    "ts": 1608681600,
    "supply": 12205280303081,
    "trustlines": {
      "total": 32249,
      "authorized": 32249,
      "funded": 2144
    },
    "payments": 122,
    "payments_amount": 675960898105,
    "trades": 2946,
    "traded_amount": 413156118926,
    "price": [
      6.205262560277218,
      9.038289991771414,
      6.205262560277218,
      7.871580178132753
    ],
    "volume": 1680165320267
  },
  {
    "ts": 1608768000,
    "supply": 9890801303081,
    "trustlines": {
      "total": 32471,
      "authorized": 32471,
      "funded": 2149
    },
    "payments": 41,
    "payments_amount": 8870712070841,
    "trades": 1083,
    "traded_amount": 184380455279,
    "price": [
      7.871580178132753,
      8.022510494296835,
      6.029788401156169,
      6.309353570617353
    ],
    "volume": 671424264356
  },
  {
    "ts": 1608854400,
    "supply": 9883113303081,
    "trustlines": {
      "total": 32626,
      "authorized": 32626,
      "funded": 2155
    },
    "payments": 42,
    "payments_amount": 15077945482,
    "trades": 846,
    "traded_amount": 143480548400,
    "price": [
      6.309353570617353,
      6.757994414242068,
      5.998767399103599,
      6.577840995524165
    ],
    "volume": 562712487049
  },
  {
    "ts": 1608940800,
    "supply": 9757020203081,
    "trustlines": {
      "total": 32826,
      "authorized": 32826,
      "funded": 2166
    },
    "payments": 29,
    "payments_amount": 130052734757,
    "trades": 630,
    "traded_amount": 78872132003,
    "price": [
      6.577840995524165,
      6.959435071333588,
      6.4751436966962075,
      6.855668300829468
    ],
    "volume": 413117964447
  },
  {
    "ts": 1609027200,
    "supply": 9753420203081,
    "trustlines": {
      "total": 32980,
      "authorized": 32980,
      "funded": 2171
    },
    "payments": 41,
    "payments_amount": 26976489641,
    "trades": 1060,
    "traded_amount": 162526907634,
    "price": [
      6.855668300829468,
      7.406992160875876,
      6.57580969119586,
      6.856729554622876
    ],
    "volume": 760005254998
  },
  {
    "ts": 1609113600,
    "supply": 8486327603081,
    "trustlines": {
      "total": 33021,
      "authorized": 33021,
      "funded": 2178
    },
    "payments": 46,
    "payments_amount": 2653582105606,
    "trades": 1141,
    "traded_amount": 508576139119,
    "price": [
      6.856729554622876,
      7.089604088510043,
      6.573270663479425,
      6.973987029661665
    ],
    "volume": 1546009429723
  },
  {
    "ts": 1609200000,
    "supply": 11814283603076,
    "trustlines": {
      "total": 33043,
      "authorized": 33043,
      "funded": 2175
    },
    "payments": 862,
    "payments_amount": 30375976242275,
    "trades": 1689,
    "traded_amount": 172055218986,
    "price": [
      6.973987029661665,
      7.780072790654602,
      6.797455261941335,
      7.239819004524887
    ],
    "volume": 788304029485
  },
  {
    "ts": 1609286400,
    "supply": 12939319603074,
    "trustlines": {
      "total": 33088,
      "authorized": 33088,
      "funded": 2183
    },
    "payments": 291,
    "payments_amount": 2103551411427,
    "trades": 1368,
    "traded_amount": 290192582623,
    "price": [
      7.239819004524887,
      7.796706239522904,
      7.193925736787055,
      7.6086441926106305
    ],
    "volume": 885093707900
  },
  {
    "ts": 1609372800,
    "supply": 12815336303074,
    "trustlines": {
      "total": 33121,
      "authorized": 33121,
      "funded": 2185
    },
    "payments": 70,
    "payments_amount": 145508595673,
    "trades": 5311,
    "traded_amount": 296024516904,
    "price": [
      7.6086441926106305,
      8.172,
      7.58109468349034,
      7.797046405665751
    ],
    "volume": 1087774768374
  },
  {
    "ts": 1609459200,
    "supply": 18570028803072,
    "trustlines": {
      "total": 33148,
      "authorized": 33148,
      "funded": 2188
    },
    "payments": 585,
    "payments_amount": 5974898167613,
    "trades": 928,
    "traded_amount": 102498422375,
    "price": [
      7.797046405665751,
      7.797046405665751,
      7.210583827635775,
      7.406414398434784
    ],
    "volume": 367956115067
  },
  {
    "ts": 1609545600,
    "supply": 18554212503073,
    "trustlines": {
      "total": 33188,
      "authorized": 33188,
      "funded": 2192
    },
    "payments": 26,
    "payments_amount": 23444145620,
    "trades": 660,
    "traded_amount": 83004626813,
    "price": [
      7.406414398434784,
      7.881167586238631,
      7.406414398434784,
      7.857833622183708
    ],
    "volume": 436956618093
  },
  {
    "ts": 1609632000,
    "supply": 18333905603074,
    "trustlines": {
      "total": 33219,
      "authorized": 33219,
      "funded": 2202
    },
    "payments": 45,
    "payments_amount": 235102820106,
    "trades": 994,
    "traded_amount": 104692230899,
    "price": [
      7.857833622183708,
      8.021086288712995,
      7.211548952283156,
      7.3152033631798865
    ],
    "volume": 452372848883
  },
  {
    "ts": 1609718400,
    "supply": 17708054503074,
    "trustlines": {
      "total": 33262,
      "authorized": 33262,
      "funded": 2209
    },
    "payments": 87,
    "payments_amount": 707215468723,
    "trades": 2349,
    "traded_amount": 629330691690,
    "price": [
      7.3152033631798865,
      7.90625,
      6.0219371594404505,
      6.105334207012755
    ],
    "volume": 2734851245823
  },
  {
    "ts": 1609804800,
    "supply": 8856153003075,
    "trustlines": {
      "total": 33310,
      "authorized": 33310,
      "funded": 2141
    },
    "payments": 186,
    "payments_amount": 33371695136464,
    "trades": 1725,
    "traded_amount": 477318968337,
    "price": [
      6.105334207012755,
      6.60000001260265,
      4.85,
      5.134021675685056
    ],
    "volume": 1919567656622
  },
  {
    "ts": 1609891200,
    "supply": 12479726703072,
    "trustlines": {
      "total": 33396,
      "authorized": 33396,
      "funded": 2202
    },
    "payments": 658,
    "payments_amount": 4773423302536,
    "trades": 3578,
    "traded_amount": 1827678519216,
    "price": [
      5.134021675685056,
      5.274581883024894,
      2.898550621918693,
      2.8985507229292504
    ],
    "volume": 4454810427167
  },
  {
    "ts": 1609977600,
    "supply": 9383316403072,
    "trustlines": {
      "total": 33509,
      "authorized": 33509,
      "funded": 2211
    },
    "payments": 54,
    "payments_amount": 3109865038407,
    "trades": 1470,
    "traded_amount": 289642741396,
    "price": [
      2.8985507229292504,
      3.504854368932039,
      2.7777777737712834,
      3.2257861635220126
    ],
    "volume": 597846952765
  },
  {
    "ts": 1610064000,
    "supply": 10100727778069,
    "trustlines": {
      "total": 33605,
      "authorized": 33605,
      "funded": 2221
    },
    "payments": 659,
    "payments_amount": 22525248028390,
    "trades": 1535,
    "traded_amount": 407553634679,
    "price": [
      3.2257861635220126,
      4,
      2.9411764704679375,
      3.4182554003390653
    ],
    "volume": 857881883194
  },
  {
    "ts": 1610150400,
    "supply": 12967823878065,
    "trustlines": {
      "total": 33679,
      "authorized": 33679,
      "funded": 2234
    },
    "payments": 462,
    "payments_amount": 3456941085899,
    "trades": 1727,
    "traded_amount": 190558162796,
    "price": [
      3.4182554003390653,
      3.5498757604991718,
      3.0935483870967744,
      3.19028145068073
    ],
    "volume": 376271344819
  },
  {
    "ts": 1610236800,
    "supply": 12709299678066,
    "trustlines": {
      "total": 33766,
      "authorized": 33766,
      "funded": 2235
    },
    "payments": 24,
    "payments_amount": 317323142585,
    "trades": 1379,
    "traded_amount": 230937852348,
    "price": [
      3.19028145068073,
      3.8500000031719703,
      2.7999999998836,
      3.5503
    ],
    "volume": 561973980841
  },
  {
    "ts": 1610323200,
    "supply": 15053679078064,
    "trustlines": {
      "total": 33881,
      "authorized": 33881,
      "funded": 2249
    },
    "payments": 381,
    "payments_amount": 4769981821730,
    "trades": 3773,
    "traded_amount": 905571211926,
    "price": [
      3.5503,
      4.728132389796269,
      3.5257662685349236,
      3.7302100235070377
    ],
    "volume": 2887396756723
  },
  {
    "ts": 1610409600,
    "supply": 13890023178065,
    "trustlines": {
      "total": 33972,
      "authorized": 33972,
      "funded": 2251
    },
    "payments": 58,
    "payments_amount": 1176827735871,
    "trades": 1718,
    "traded_amount": 327252161397,
    "price": [
      3.7302100235070377,
      3.938227399904993,
      3.180004134005374,
      3.599525438
    ],
    "volume": 871625915304
  },
  {
    "ts": 1610496000,
    "supply": 13681247178065,
    "trustlines": {
      "total": 34082,
      "authorized": 34082,
      "funded": 2260
    },
    "payments": 81,
    "payments_amount": 214394163671,
    "trades": 1434,
    "traded_amount": 85112240298,
    "price": [
      3.599525438,
      3.636125654450262,
      3.2484097493954267,
      3.2639407081191054
    ],
    "volume": 217351328688
  },
  {
    "ts": 1610582400,
    "supply": 13562210578065,
    "trustlines": {
      "total": 34196,
      "authorized": 34196,
      "funded": 2263
    },
    "payments": 42,
    "payments_amount": 123043480232,
    "trades": 1663,
    "traded_amount": 127991760112,
    "price": [
      3.2639407081191054,
      3.4674879201087574,
      3.124999998112284,
      3.303030303030303
    ],
    "volume": 271002435625
  },
  {
    "ts": 1610668800,
    "supply": 11669168833560,
    "trustlines": {
      "total": 34265,
      "authorized": 34265,
      "funded": 2262
    },
    "payments": 626,
    "payments_amount": 34412322092884,
    "trades": 1261,
    "traded_amount": 156588048856,
    "price": [
      3.303030303030303,
      3.8081669166613556,
      3.2017132080454047,
      3.526809433778583
    ],
    "volume": 381894561693
  },
  {
    "ts": 1610755200,
    "supply": 14926609133557,
    "trustlines": {
      "total": 34353,
      "authorized": 34353,
      "funded": 2272
    },
    "payments": 656,
    "payments_amount": 3966436702868,
    "trades": 1112,
    "traded_amount": 128361255049,
    "price": [
      3.526809433778583,
      3.600768902651828,
      3.174606197747656,
      3.44961342
    ],
    "volume": 309594067995
  },
  {
    "ts": 1610841600,
    "supply": 14802598333557,
    "trustlines": {
      "total": 34430,
      "authorized": 34430,
      "funded": 2281
    },
    "payments": 26,
    "payments_amount": 130831147956,
    "trades": 1363,
    "traded_amount": 297614890866,
    "price": [
      3.44961342,
      3.4855226050640438,
      3.1500001732500094,
      3.261909
    ],
    "volume": 801126034147
  },
  {
    "ts": 1610928000,
    "supply": 14380676333558,
    "trustlines": {
      "total": 34504,
      "authorized": 34504,
      "funded": 2285
    },
    "payments": 61,
    "payments_amount": 456299841636,
    "trades": 849,
    "traded_amount": 104706987908,
    "price": [
      3.261909,
      3.39819268,
      3.222917394045551,
      3.291836030464024
    ],
    "volume": 276009032691
  },
  {
    "ts": 1611014400,
    "supply": 13936137433557,
    "trustlines": {
      "total": 34585,
      "authorized": 34585,
      "funded": 2296
    },
    "payments": 64,
    "payments_amount": 607882348478,
    "trades": 942,
    "traded_amount": 340774059426,
    "price": [
      3.291836030464024,
      3.3699973100769673,
      3.171757890576458,
      3.332358288888038
    ],
    "volume": 1041838290003
  },
  {
    "ts": 1611100800,
    "supply": 12805395833554,
    "trustlines": {
      "total": 34665,
      "authorized": 34665,
      "funded": 2295
    },
    "payments": 429,
    "payments_amount": 24119202703457,
    "trades": 885,
    "traded_amount": 112099462082,
    "price": [
      3.332358288888038,
      3.572,
      3.3217880983599786,
      3.3917136896650533
    ],
    "volume": 318567452819
  },
  {
    "ts": 1611187200,
    "supply": 12767530033554,
    "trustlines": {
      "total": 34726,
      "authorized": 34726,
      "funded": 2291
    },
    "payments": 23,
    "payments_amount": 49374137782,
    "trades": 1348,
    "traded_amount": 454816345218,
    "price": [
      3.3917136896650533,
      4.0608635579060675,
      3.3917136896650533,
      3.952569169991912
    ],
    "volume": 1341776946385
  },
  {
    "ts": 1611273600,
    "supply": 9536449533552,
    "trustlines": {
      "total": 34821,
      "authorized": 34821,
      "funded": 2296
    },
    "payments": 321,
    "payments_amount": 25177289401330,
    "trades": 1525,
    "traded_amount": 160911578094,
    "price": [
      3.952569169991912,
      4.319999913508277,
      3.571428571141753,
      3.696220234037123
    ],
    "volume": 415611163100
  },
  {
    "ts": 1611360000,
    "supply": 9536449533552,
    "trustlines": {
      "total": 34897,
      "authorized": 34897,
      "funded": 2301
    },
    "payments": 21,
    "payments_amount": 12279189947,
    "trades": 969,
    "traded_amount": 82549145891,
    "price": [
      3.696220234037123,
      3.7836558144502392,
      3.571067593177511,
      3.719446588345377
    ],
    "volume": 268562226874
  },
  {
    "ts": 1611446400,
    "supply": 9536449533552,
    "trustlines": {
      "total": 34968,
      "authorized": 34968,
      "funded": 2306
    },
    "payments": 14,
    "payments_amount": 8777865007,
    "trades": 1094,
    "traded_amount": 51922451007,
    "price": [
      3.719446588345377,
      3.7901176136498096,
      3.5499999851589914,
      3.675174636355718
    ],
    "volume": 128582848820
  },
  {
    "ts": 1611532800,
    "supply": 9387485283552,
    "trustlines": {
      "total": 35053,
      "authorized": 35053,
      "funded": 2305
    },
    "payments": 352,
    "payments_amount": 2558270648270,
    "trades": 1064,
    "traded_amount": 85564904330,
    "price": [
      3.675174636355718,
      3.8302059120665928,
      3.6130826999232313,
      3.798186708718532
    ],
    "volume": 281144802638
  },
  {
    "ts": 1611619200,
    "supply": 11738394283553,
    "trustlines": {
      "total": 35132,
      "authorized": 35132,
      "funded": 2300
    },
    "payments": 353,
    "payments_amount": 3642622114744,
    "trades": 2038,
    "traded_amount": 234475612733,
    "price": [
      3.798186708718532,
      3.9898418423530537,
      3.7209302325581395,
      3.8331829469345156
    ],
    "volume": 684474968887
  },
  {
    "ts": 1611705600,
    "supply": 14579092583554,
    "trustlines": {
      "total": 35197,
      "authorized": 35197,
      "funded": 2304
    },
    "payments": 352,
    "payments_amount": 3873922580669,
    "trades": 2472,
    "traded_amount": 320383059318,
    "price": [
      3.8331829469345156,
      4.328349816715914,
      3.8129301876135853,
      4.176880845046502
    ],
    "volume": 1089306193366
  },
  {
    "ts": 1611792000,
    "supply": 10261584433551,
    "trustlines": {
      "total": 35294,
      "authorized": 35294,
      "funded": 2309
    },
    "payments": 231,
    "payments_amount": 22113628863346,
    "trades": 3075,
    "traded_amount": 363484855766,
    "price": [
      4.176880845046502,
      4.336688339995614,
      3.333333328645866,
      3.4217069079194453
    ],
    "volume": 1231096644945
  },
  {
    "ts": 1611878400,
    "supply": 11959665146046,
    "trustlines": {
      "total": 35441,
      "authorized": 35441,
      "funded": 2334
    },
    "payments": 592,
    "payments_amount": 6653053368369,
    "trades": 5123,
    "traded_amount": 803052193374,
    "price": [
      3.4217069079194453,
      3.5714285721398626,
      2.706364337886116,
      3.3727497756934413
    ],
    "volume": 1934038349348
  },
  {
    "ts": 1611964800,
    "supply": 14248966346044,
    "trustlines": {
      "total": 35579,
      "authorized": 35579,
      "funded": 2362
    },
    "payments": 630,
    "payments_amount": 2521433181228,
    "trades": 4659,
    "traded_amount": 517535297416,
    "price": [
      3.3727497756934413,
      3.571429699883619,
      2.083333333293264,
      3.079235660240074
    ],
    "volume": 1024978963785
  },
  {
    "ts": 1612051200,
    "supply": 13973145446044,
    "trustlines": {
      "total": 35704,
      "authorized": 35704,
      "funded": 2349
    },
    "payments": 177,
    "payments_amount": 317727465768,
    "trades": 3467,
    "traded_amount": 244285753960,
    "price": [
      3.079235660240074,
      3.3680000055658783,
      2.9849728283756103,
      3.2548150950167436
    ],
    "volume": 486489926105
  },
  {
    "ts": 1612137600,
    "supply": 13188486446044,
    "trustlines": {
      "total": 35812,
      "authorized": 35812,
      "funded": 2305
    },
    "payments": 183,
    "payments_amount": 16351347318105,
    "trades": 4376,
    "traded_amount": 157373192166,
    "price": [
      3.2548150950167436,
      3.399444225423565,
      2.6315796397856,
      3.075824955917469
    ],
    "volume": 355467538523
  },
  {
    "ts": 1612224000,
    "supply": 9640946335427,
    "trustlines": {
      "total": 35863,
      "authorized": 35863,
      "funded": 2308
    },
    "payments": 1099,
    "payments_amount": 7198415505816,
    "trades": 2222,
    "traded_amount": 111162171085,
    "price": [
      3.075824955917469,
      3.1347364915427316,
      2.8727178162279507,
      2.937615966059961
    ],
    "volume": 233761500648
  },
  {
    "ts": 1612310400,
    "supply": 8366263276423,
    "trustlines": {
      "total": 35926,
      "authorized": 35926,
      "funded": 2316
    },
    "payments": 383,
    "payments_amount": 4639070386192,
    "trades": 2178,
    "traded_amount": 110754210937,
    "price": [
      2.937615966059961,
      3.027012433848893,
      2.749999988596868,
      2.898550717725826
    ],
    "volume": 191056716074
  },
  {
    "ts": 1612396800,
    "supply": 8857785740053,
    "trustlines": {
      "total": 35988,
      "authorized": 35988,
      "funded": 2312
    },
    "payments": 254,
    "payments_amount": 1233621050207,
    "trades": 1744,
    "traded_amount": 118311006379,
    "price": [
      2.898550717725826,
      3.169355553975241,
      2.864155184507441,
      3.0486115599403996
    ],
    "volume": 224317247456
  },
  {
    "ts": 1612483200,
    "supply": 18100782740056,
    "trustlines": {
      "total": 36032,
      "authorized": 36032,
      "funded": 2322
    },
    "payments": 92,
    "payments_amount": 48132881925412,
    "trades": 2146,
    "traded_amount": 176072381422,
    "price": [
      3.0486115599403996,
      3.067241238238102,
      2.777775068019175,
      2.8699996463796498
    ],
    "volume": 304014724677
  },
  {
    "ts": 1612569600,
    "supply": 17853125440058,
    "trustlines": {
      "total": 36074,
      "authorized": 36074,
      "funded": 2325
    },
    "payments": 70,
    "payments_amount": 298580870430,
    "trades": 3165,
    "traded_amount": 366962920217,
    "price": [
      2.8699996463796498,
      2.9412436325311786,
      2.6315788862039273,
      2.702702703300004
    ],
    "volume": 597520297099
  },
  {
    "ts": 1612656000,
    "supply": 16779841540064,
    "trustlines": {
      "total": 36111,
      "authorized": 36111,
      "funded": 2332
    },
    "payments": 303,
    "payments_amount": 1390731917435,
    "trades": 1698,
    "traded_amount": 265810938696,
    "price": [
      2.702702703300004,
      2.881844380403458,
      2.4999996893026273,
      2.519998101181431
    ],
    "volume": 390138131667
  },
  {
    "ts": 1612742400,
    "supply": 13607324240081,
    "trustlines": {
      "total": 36152,
      "authorized": 36152,
      "funded": 2341
    },
    "payments": 729,
    "payments_amount": 3890336515189,
    "trades": 666,
    "traded_amount": 191184155031,
    "price": [
      2.519998101181431,
      2.5200120933044956,
      2.438995463575177,
      2.439024386188083
    ],
    "volume": 441293973670
  },
  {
    "ts": 1612828800,
    "supply": 11660113940090,
    "trustlines": {
      "total": 36192,
      "authorized": 36192,
      "funded": 2340
    },
    "payments": 543,
    "payments_amount": 1981253935354,
    "trades": 914,
    "traded_amount": 235421772168,
    "price": [
      2.439024386188083,
      2.5501578132769445,
      2.2494117647058824,
      2.522007158440671
    ],
    "volume": 482953445144
  },
  {
    "ts": 1612915200,
    "supply": 11326044540091,
    "trustlines": {
      "total": 36224,
      "authorized": 36224,
      "funded": 2338
    },
    "payments": 103,
    "payments_amount": 365216757024,
    "trades": 664,
    "traded_amount": 328595050111,
    "price": [
      2.522007158440671,
      2.522007158440671,
      2.1141647497446248,
      2.3148136928203575
    ],
    "volume": 479207742028
  },
  {
    "ts": 1613001600,
    "supply": 11126388440092,
    "trustlines": {
      "total": 36247,
      "authorized": 36247,
      "funded": 2344
    },
    "payments": 135,
    "payments_amount": 471183186462,
    "trades": 674,
    "traded_amount": 165051264524,
    "price": [
      2.3148136928203575,
      2.4539877300613497,
      2.112824846827413,
      2.2300002
    ],
    "volume": 195726536983
  },
  {
    "ts": 1613088000,
    "supply": 16856032840106,
    "trustlines": {
      "total": 36281,
      "authorized": 36281,
      "funded": 2327
    },
    "payments": 610,
    "payments_amount": 14302600936084,
    "trades": 908,
    "traded_amount": 337660704158,
    "price": [
      2.2300002,
      2.2499969648734464,
      1.800019979806616,
      1.9735547971993217
    ],
    "volume": 338530940196
  },
  {
    "ts": 1613174400,
    "supply": 9962571307007,
    "trustlines": {
      "total": 36373,
      "authorized": 36373,
      "funded": 2323
    },
    "payments": 1254,
    "payments_amount": 6983704894881,
    "trades": 2186,
    "traded_amount": 860457402859,
    "price": [
      1.9735547971993217,
      1.9735547971993217,
      1.6666654296751784,
      1.8
    ],
    "volume": 651694669284
  },
  {
    "ts": 1613260800,
    "supply": 7675574207018,
    "trustlines": {
      "total": 36477,
      "authorized": 36477,
      "funded": 2327
    },
    "payments": 458,
    "payments_amount": 2464299185958,
    "trades": 801,
    "traded_amount": 460656203863,
    "price": [
      1.8,
      1.9999006165811943,
      1.7094017090672318,
      1.8590408295528191
    ],
    "volume": 131569273976
  },
  {
    "ts": 1613347200,
    "supply": 7403129107017,
    "trustlines": {
      "total": 36568,
      "authorized": 36568,
      "funded": 2321
    },
    "payments": 39,
    "payments_amount": 315971435658,
    "trades": 704,
    "traded_amount": 241157490389,
    "price": [
      1.8590408295528191,
      2.300000002708846,
      1.840908642309082,
      1.8656715897507419
    ],
    "volume": 111321473864
  },
  {
    "ts": 1613433600,
    "supply": 8765305507078,
    "trustlines": {
      "total": 36653,
      "authorized": 36653,
      "funded": 2326
    },
    "payments": 2470,
    "payments_amount": 309007550340894,
    "trades": 889,
    "traded_amount": 404976016279,
    "price": [
      1.8656715897507419,
      2.1344060117316443,
      1.8319999796400372,
      2.1001978921051223
    ],
    "volume": 375752764260
  },
  {
    "ts": 1613520000,
    "supply": 24859173402386,
    "trustlines": {
      "total": 36715,
      "authorized": 36715,
      "funded": 2333
    },
    "payments": 602,
    "payments_amount": 24009215510958,
    "trades": 720,
    "traded_amount": 189096510474,
    "price": [
      2.1001978921051223,
      2.2228823316810518,
      1.9673469387755103,
      2.02272
    ],
    "volume": 257220919783
  },
  {
    "ts": 1613606400,
    "supply": 32065923802408,
    "trustlines": {
      "total": 36777,
      "authorized": 36777,
      "funded": 2329
    },
    "payments": 542,
    "payments_amount": 252933797016885,
    "trades": 520,
    "traded_amount": 182719457783,
    "price": [
      2.02272,
      2.0637835347336444,
      1.9444715277447409,
      1.9781818181818183
    ],
    "volume": 248593875320
  },
  {
    "ts": 1613692800,
    "supply": 28773910202424,
    "trustlines": {
      "total": 36855,
      "authorized": 36855,
      "funded": 2332
    },
    "payments": 483,
    "payments_amount": 303314778158669,
    "trades": 412,
    "traded_amount": 79226153954,
    "price": [
      1.9781818181818183,
      2.06320491227443,
      1.9319999619086206,
      1.9602516963178072
    ],
    "volume": 92587840251
  },
  {
    "ts": 1613779200,
    "supply": 26922010702437,
    "trustlines": {
      "total": 36949,
      "authorized": 36949,
      "funded": 2320
    },
    "payments": 304,
    "payments_amount": 1933028821863,
    "trades": 542,
    "traded_amount": 499887851647,
    "price": [
      1.9602516963178072,
      2.1214210788789796,
      1.719999999951381,
      2.0359177237129202
    ],
    "volume": 569416805298
  },
  {
    "ts": 1613865600,
    "supply": 25858184402444,
    "trustlines": {
      "total": 37029,
      "authorized": 37029,
      "funded": 2318
    },
    "payments": 212,
    "payments_amount": 1100680671350,
    "trades": 117,
    "traded_amount": 65683032133,
    "price": [
      2.0359177237129202,
      2.0799352114260974,
      1.9695385909353427,
      2.0256
    ],
    "volume": 81123538708
  },
  {
    "ts": 1613952000,
    "supply": 23867165202448,
    "trustlines": {
      "total": 37082,
      "authorized": 37082,
      "funded": 2318
    },
    "payments": 314,
    "payments_amount": 302111113593244,
    "trades": 1604,
    "traded_amount": 519031915206,
    "price": [
      2.0256,
      2.5,
      1.921727438433397,
      2.1449905809094023
    ],
    "volume": 661233659867
  },
  {
    "ts": 1614038400,
    "supply": 21432472902464,
    "trustlines": {
      "total": 37159,
      "authorized": 37159,
      "funded": 2310
    },
    "payments": 366,
    "payments_amount": 2489549139542,
    "trades": 1689,
    "traded_amount": 302980756659,
    "price": [
      2.1449905809094023,
      2.9381976533234107,
      2.1449905809094023,
      2.592822062712505
    ],
    "volume": 526867253801
  },
  {
    "ts": 1614124800,
    "supply": 17428756702472,
    "trustlines": {
      "total": 37262,
      "authorized": 37262,
      "funded": 2310
    },
    "payments": 250,
    "payments_amount": 4023153683966,
    "trades": 1455,
    "traded_amount": 49087465497,
    "price": [
      2.592822062712505,
      2.6979187472536252,
      2.3055555555555554,
      2.4785300698182344
    ],
    "volume": 88926986280
  },
  {
    "ts": 1614211200,
    "supply": 17428756702472,
    "trustlines": {
      "total": 37341,
      "authorized": 37341,
      "funded": 2308
    },
    "payments": 26,
    "payments_amount": 39894856957,
    "trades": 987,
    "traded_amount": 80142225027,
    "price": [
      2.4785300698182344,
      2.6413171895155623,
      2.3736626540819405,
      2.6413171895155623
    ],
    "volume": 94805611694
  },
  {
    "ts": 1614297600,
    "supply": 26048156839427,
    "trustlines": {
      "total": 37421,
      "authorized": 37421,
      "funded": 2304
    },
    "payments": 981,
    "payments_amount": 271422264351921,
    "trades": 1251,
    "traded_amount": 107980353692,
    "price": [
      2.6413171895155623,
      2.8,
      2.4995555555555558,
      2.5306679631984443
    ],
    "volume": 211312530823
  },
  {
    "ts": 1614384000,
    "supply": 25291545339434,
    "trustlines": {
      "total": 37476,
      "authorized": 37476,
      "funded": 2308
    },
    "payments": 297,
    "payments_amount": 792793964088,
    "trades": 1782,
    "traded_amount": 185142098477,
    "price": [
      2.5306679631984443,
      2.5306679631984443,
      2.131080639385776,
      2.288900760552377
    ],
    "volume": 201915889674
  },
  {
    "ts": 1614470400,
    "supply": 24424131139438,
    "trustlines": {
      "total": 37566,
      "authorized": 37566,
      "funded": 2311
    },
    "payments": 517,
    "payments_amount": 922084012046,
    "trades": 696,
    "traded_amount": 93652160501,
    "price": [
      2.288900760552377,
      2.6153828701445394,
      2.211543768204814,
      2.46106073152244
    ],
    "volume": 169767653594
  },
  {
    "ts": 1614556800,
    "supply": 29228609739446,
    "trustlines": {
      "total": 37637,
      "authorized": 37637,
      "funded": 2323
    },
    "payments": 382,
    "payments_amount": 15205060954747,
    "trades": 667,
    "traded_amount": 27259523727,
    "price": [
      2.46106073152244,
      2.4779014987283614,
      2.308135630182251,
      2.3096660343516033
    ],
    "volume": 44564561359
  },
  {
    "ts": 1614643200,
    "supply": 27427131339453,
    "trustlines": {
      "total": 37694,
      "authorized": 37694,
      "funded": 2305
    },
    "payments": 441,
    "payments_amount": 1843379040798,
    "trades": 1608,
    "traded_amount": 335974190753,
    "price": [
      2.3096660343516033,
      2.5452293925774363,
      2.302752293577982,
      2.374710871241326
    ],
    "volume": 454411669078
  },
  {
    "ts": 1614729600,
    "supply": 25841802239464,
    "trustlines": {
      "total": 37771,
      "authorized": 37771,
      "funded": 2301
    },
    "payments": 427,
    "payments_amount": 1643468998359,
    "trades": 906,
    "traded_amount": 84914242664,
    "price": [
      2.374710871241326,
      2.4446846189770937,
      2.298129320045267,
      2.367834782726506
    ],
    "volume": 73871771523
  },
  {
    "ts": 1614816000,
    "supply": 23803752239472,
    "trustlines": {
      "total": 37847,
      "authorized": 37847,
      "funded": 2293
    },
    "payments": 294,
    "payments_amount": 302101590525333,
    "trades": 691,
    "traded_amount": 76610981426,
    "price": [
      2.367834782726506,
      2.4865554516205695,
      2.354172898594077,
      2.454688897638491
    ],
    "volume": 85282520475
  },
  {
    "ts": 1614902400,
    "supply": 22285539939478,
    "trustlines": {
      "total": 37920,
      "authorized": 37920,
      "funded": 2291
    },
    "payments": 239,
    "payments_amount": 1568498498920,
    "trades": 792,
    "traded_amount": 68898237986,
    "price": [
      2.454688897638491,
      2.5882237992091053,
      2.4369978798534127,
      2.4615445835598506
    ],
    "volume": 124474474140
  },
  {
    "ts": 1614988800,
    "supply": 31209024139482,
    "trustlines": {
      "total": 37981,
      "authorized": 37981,
      "funded": 2290
    },
    "payments": 188,
    "payments_amount": 21094776722537,
    "trades": 351,
    "traded_amount": 20985719052,
    "price": [
      2.4615445835598506,
      2.5400001198545366,
      2.4325864634073344,
      2.4818956541857866
    ],
    "volume": 27023246134
  },
  {
    "ts": 1615075200,
    "supply": 28135220239493,
    "trustlines": {
      "total": 38042,
      "authorized": 38042,
      "funded": 2297
    },
    "payments": 245,
    "payments_amount": 3086853467126,
    "trades": 822,
    "traded_amount": 29992208110,
    "price": [
      2.4818956541857866,
      2.4908898668582817,
      2.364934549781833,
      2.4347279861025726
    ],
    "volume": 55756798670
  },
  {
    "ts": 1615161600,
    "supply": 26506373839503,
    "trustlines": {
      "total": 38094,
      "authorized": 38094,
      "funded": 2298
    },
    "payments": 257,
    "payments_amount": 1740383454933,
    "trades": 1168,
    "traded_amount": 205792834108,
    "price": [
      2.4347279861025726,
      2.483608220314312,
      2.3586971582066143,
      2.408763986138755
    ],
    "volume": 328323734160
  },
  {
    "ts": 1615248000,
    "supply": 24035057139512,
    "trustlines": {
      "total": 38142,
      "authorized": 38142,
      "funded": 2286
    },
    "payments": 309,
    "payments_amount": 2479054704514,
    "trades": 2907,
    "traded_amount": 115019564222,
    "price": [
      2.408763986138755,
      2.473007712082262,
      2.3062522077004592,
      2.3273287030506467
    ],
    "volume": 83088521673
  },
  {
    "ts": 1615334400,
    "supply": 22318344839523,
    "trustlines": {
      "total": 38186,
      "authorized": 38186,
      "funded": 2286
    },
    "payments": 309,
    "payments_amount": 1777095683549,
    "trades": 1137,
    "traded_amount": 196080190663,
    "price": [
      2.3273287030506467,
      2.4450133516254686,
      2.313796563739269,
      2.4275396218928447
    ],
    "volume": 143907400071
  },
  {
    "ts": 1615420800,
    "supply": 20945054039525,
    "trustlines": {
      "total": 38231,
      "authorized": 38231,
      "funded": 2283
    },
    "payments": 299,
    "payments_amount": 1396404867839,
    "trades": 584,
    "traded_amount": 59880020396,
    "price": [
      2.4275396218928447,
      2.521894994242845,
      2.3909833129956093,
      2.479372342277373
    ],
    "volume": 93736443915
  },
  {
    "ts": 1615507200,
    "supply": 17566401639531,
    "trustlines": {
      "total": 38261,
      "authorized": 38261,
      "funded": 2280
    },
    "payments": 235,
    "payments_amount": 3462154250298,
    "trades": 370,
    "traded_amount": 134113970349,
    "price": [
      2.479372342277373,
      2.6293403424891455,
      2.479140127305874,
      2.579326991963778
    ],
    "volume": 86629377818
  },
  {
    "ts": 1615593600,
    "supply": 36125126239532,
    "trustlines": {
      "total": 38304,
      "authorized": 38304,
      "funded": 2285
    },
    "payments": 197,
    "payments_amount": 41475895809787,
    "trades": 398,
    "traded_amount": 93166336535,
    "price": [
      2.579326991963778,
      2.6695786675540467,
      2.4396118398576387,
      2.4608358706739883
    ],
    "volume": 64345115177
  },
  {
    "ts": 1615680000,
    "supply": 34104298439537,
    "trustlines": {
      "total": 38342,
      "authorized": 38342,
      "funded": 2284
    },
    "payments": 304,
    "payments_amount": 2066187805196,
    "trades": 351,
    "traded_amount": 97072281554,
    "price": [
      2.4608358706739883,
      2.5784313725490198,
      2.4608358706739883,
      2.5589650770049723
    ],
    "volume": 34171110443
  },
  {
    "ts": 1615766400,
    "supply": 32926111939546,
    "trustlines": {
      "total": 38374,
      "authorized": 38374,
      "funded": 2283
    },
    "payments": 200,
    "payments_amount": 1250509209643,
    "trades": 432,
    "traded_amount": 82150763104,
    "price": [
      2.5589650770049723,
      2.7119722825323485,
      2.5269435354467005,
      2.6234232788134193
    ],
    "volume": 85143890035
  },
  {
    "ts": 1615852800,
    "supply": 30508262439553,
    "trustlines": {
      "total": 38409,
      "authorized": 38409,
      "funded": 2283
    },
    "payments": 312,
    "payments_amount": 2439056633546,
    "trades": 629,
    "traded_amount": 187311543791,
    "price": [
      2.6234232788134193,
      2.6930693069306932,
      2.444987774368801,
      2.532898552853741
    ],
    "volume": 152015655106
  },
  {
    "ts": 1615939200,
    "supply": 28923337939558,
    "trustlines": {
      "total": 38454,
      "authorized": 38454,
      "funded": 2278
    },
    "payments": 163,
    "payments_amount": 1590078447941,
    "trades": 501,
    "traded_amount": 88540811858,
    "price": [
      2.532898552853741,
      2.5738488718305623,
      2.4612565556166492,
      2.4612565556166492
    ],
    "volume": 23079039440
  },
  {
    "ts": 1616025600,
    "supply": 27358496539570,
    "trustlines": {
      "total": 38488,
      "authorized": 38488,
      "funded": 2275
    },
    "payments": 252,
    "payments_amount": 1568364251877,
    "trades": 227,
    "traded_amount": 90487041205,
    "price": [
      2.4612565556166492,
      2.5341058296386576,
      2.4603853572301073,
      2.5103143131870747
    ],
    "volume": 134322680147
  },
  {
    "ts": 1616112000,
    "supply": 24706496839571,
    "trustlines": {
      "total": 38520,
      "authorized": 38520,
      "funded": 2275
    },
    "payments": 177,
    "payments_amount": 2700538987395,
    "trades": 130,
    "traded_amount": 32027756594,
    "price": [
      2.5103143131870747,
      2.5654072127851975,
      2.491724164468368,
      2.497342197244663
    ],
    "volume": 30098857275
  },
  {
    "ts": 1616198400,
    "supply": 23544654339576,
    "trustlines": {
      "total": 38560,
      "authorized": 38560,
      "funded": 2281
    },
    "payments": 173,
    "payments_amount": 1177726606487,
    "trades": 470,
    "traded_amount": 121966563296,
    "price": [
      2.497342197244663,
      2.535030828166581,
      2.299999999228138,
      2.3988696526196858
    ],
    "volume": 102200179078
  },
  {
    "ts": 1616284800,
    "supply": 22717380439578,
    "trustlines": {
      "total": 38608,
      "authorized": 38608,
      "funded": 2274
    },
    "payments": 180,
    "payments_amount": 899901153256,
    "trades": 399,
    "traded_amount": 117785205370,
    "price": [
      2.3988696526196858,
      2.543123690123696,
      2.379839522661308,
      2.4663539987490655
    ],
    "volume": 180932606388
  },
  {
    "ts": 1616371200,
    "supply": 35707005039583,
    "trustlines": {
      "total": 38655,
      "authorized": 38655,
      "funded": 2273
    },
    "payments": 201,
    "payments_amount": 47019274318717,
    "trades": 571,
    "traded_amount": 242951747945,
    "price": [
      2.4663539987490655,
      2.5500000730951653,
      2.3793558809757682,
      2.5170004502913805
    ],
    "volume": 250847004553
  },
  {
    "ts": 1616457600,
    "supply": 34928547739591,
    "trustlines": {
      "total": 38690,
      "authorized": 38690,
      "funded": 2273
    },
    "payments": 163,
    "payments_amount": 808062411188,
    "trades": 307,
    "traded_amount": 67902478939,
    "price": [
      2.5170004502913805,
      2.5662884041565563,
      2.4541031708592316,
      2.5260154329438893
    ],
    "volume": 122283519450
  },
  {
    "ts": 1616544000,
    "supply": 33479188739593,
    "trustlines": {
      "total": 38735,
      "authorized": 38735,
      "funded": 2269
    },
    "payments": 204,
    "payments_amount": 1451730374902,
    "trades": 867,
    "traded_amount": 253645058758,
    "price": [
      2.5260154329438893,
      2.7520009409726534,
      2.4866111111111113,
      2.75200000913664
    ],
    "volume": 121548737800
  },
  {
    "ts": 1616630400,
    "supply": 30430710639596,
    "trustlines": {
      "total": 38839,
      "authorized": 38839,
      "funded": 2269
    },
    "payments": 187,
    "payments_amount": 363093888621610,
    "trades": 962,
    "traded_amount": 329516858518,
    "price": [
      2.75200000913664,
      2.9000000008252167,
      2.5999998442679813,
      2.756382196673212
    ],
    "volume": 186179035138
  },
  {
    "ts": 1616716800,
    "supply": 28911896339599,
    "trustlines": {
      "total": 38963,
      "authorized": 38963,
      "funded": 2271
    },
    "payments": 130,
    "payments_amount": 1520264954716,
    "trades": 396,
    "traded_amount": 71943099941,
    "price": [
      2.756382196673212,
      2.9000000022066184,
      2.599993287351692,
      2.6
    ],
    "volume": 117081742960
  },
  {
    "ts": 1616803200,
    "supply": 27697291939601,
    "trustlines": {
      "total": 39082,
      "authorized": 39082,
      "funded": 2268
    },
    "payments": 135,
    "payments_amount": 1252675964743,
    "trades": 713,
    "traded_amount": 64572567626,
    "price": [
      2.6,
      2.7,
      2.5116792911115806,
      2.6388445056031036
    ],
    "volume": 44430940067
  },
  {
    "ts": 1616889600,
    "supply": 26512812939609,
    "trustlines": {
      "total": 39214,
      "authorized": 39214,
      "funded": 2270
    },
    "payments": 148,
    "payments_amount": 1237931445729,
    "trades": 418,
    "traded_amount": 244748868947,
    "price": [
      2.6388445056031036,
      2.6388445056031036,
      2.3584905659434234,
      2.5261523845070664
    ],
    "volume": 23075045473
  },
  {
    "ts": 1616976000,
    "supply": 24402945539615,
    "trustlines": {
      "total": 39321,
      "authorized": 39321,
      "funded": 2274
    },
    "payments": 248,
    "payments_amount": 2129029369750,
    "trades": 236,
    "traded_amount": 28993120023,
    "price": [
      2.5261523845070664,
      2.580000069660009,
      2.3999999990661527,
      2.4745525469633898
    ],
    "volume": 1597269151
  },
  {
    "ts": 1617062400,
    "supply": 21990027039625,
    "trustlines": {
      "total": 39393,
      "authorized": 39393,
      "funded": 2273
    },
    "payments": 201,
    "payments_amount": 2417451097128,
    "trades": 143,
    "traded_amount": 25356762861,
    "price": [
      2.4745525469633898,
      2.55000001785,
      2.4251295807363227,
      2.5097057323912884
    ],
    "volume": 17760129683
  },
  {
    "ts": 1617148800,
    "supply": 21341635439628,
    "trustlines": {
      "total": 39447,
      "authorized": 39447,
      "funded": 2274
    },
    "payments": 75,
    "payments_amount": 653775822190,
    "trades": 432,
    "traded_amount": 90758775086,
    "price": [
      2.5097057323912884,
      2.5990000226203613,
      2.466795980083697,
      2.466795980083697
    ],
    "volume": 15987816364
  },
  {
    "ts": 1617235200,
    "supply": 19765411739633,
    "trustlines": {
      "total": 39478,
      "authorized": 39478,
      "funded": 2272
    },
    "payments": 179,
    "payments_amount": 1607332339328,
    "trades": 337,
    "traded_amount": 129515281797,
    "price": [
      2.466795980083697,
      2.5,
      2.3096643126632483,
      2.3096643126632483
    ],
    "volume": 113025776715
  },
  {
    "ts": 1617321600,
    "supply": 25210027939654,
    "trustlines": {
      "total": 39510,
      "authorized": 39510,
      "funded": 2278
    },
    "payments": 473,
    "payments_amount": 54644599579497,
    "trades": 833,
    "traded_amount": 189970353667,
    "price": [
      2.3096643126632483,
      2.5,
      2.099984531497738,
      2.275069905314624
    ],
    "volume": 14295773748
  },
  {
    "ts": 1617408000,
    "supply": 23847230339655,
    "trustlines": {
      "total": 39523,
      "authorized": 39523,
      "funded": 2271
    },
    "payments": 163,
    "payments_amount": 1420021895929,
    "trades": 395,
    "traded_amount": 107809765877,
    "price": [
      2.275069905314624,
      2.439999795040017,
      2.097083648456154,
      2.4343197005690236
    ],
    "volume": 52295716183
  },
  {
    "ts": 1617494400,
    "supply": 23131763839659,
    "trustlines": {
      "total": 39545,
      "authorized": 39545,
      "funded": 2270
    },
    "payments": 106,
    "payments_amount": 724155021193,
    "trades": 139,
    "traded_amount": 9185264082,
    "price": [
      2.4343197005690236,
      2.450001592501035,
      2.317465919578662,
      2.3426368814443013
    ],
    "volume": 5451831638
  },
  {
    "ts": 1617580800,
    "supply": 21258165939661,
    "trustlines": {
      "total": 39578,
      "authorized": 39578,
      "funded": 2274
    },
    "payments": 204,
    "payments_amount": 1894048042837,
    "trades": 1406,
    "traded_amount": 285654416278,
    "price": [
      2.3426368814443013,
      2.4000000144,
      1.8517862452780482,
      1.895205376773462
    ],
    "volume": 48319480583
  },
  {
    "ts": 1617667200,
    "supply": 18742566639666,
    "trustlines": {
      "total": 39609,
      "authorized": 39609,
      "funded": 2279
    },
    "payments": 246,
    "payments_amount": 2638799339256,
    "trades": 620,
    "traded_amount": 89463484072,
    "price": [
      1.895205376773462,
      2.1993489974698033,
      1.8517863457908517,
      1.9018771932959802
    ],
    "volume": 11159468107
  },
  {
    "ts": 1617753600,
    "supply": 36855674339671,
    "trustlines": {
      "total": 39644,
      "authorized": 39644,
      "funded": 2276
    },
    "payments": 197,
    "payments_amount": 41972000410171,
    "trades": 1066,
    "traded_amount": 535545854966,
    "price": [
      1.9018771932959802,
      2.300000022928214,
      1.8195049983356777,
      2.111111098997433
    ],
    "volume": 65417182656
  },
  {
    "ts": 1617840000,
    "supply": 31110230839675,
    "trustlines": {
      "total": 39673,
      "authorized": 39673,
      "funded": 2276
    },
    "payments": 153,
    "payments_amount": 5753914710632,
    "trades": 269,
    "traded_amount": 28301526765,
    "price": [
      2.111111098997433,
      2.201264488935722,
      1.9047619000424068,
      1.9047619000424068
    ],
    "volume": 24468790817
  },
  {
    "ts": 1617926400,
    "supply": 29060299839679,
    "trustlines": {
      "total": 39709,
      "authorized": 39709,
      "funded": 2277
    },
    "payments": 197,
    "payments_amount": 2068549319484,
    "trades": 182,
    "traded_amount": 60136717658,
    "price": [
      1.9047619000424068,
      2.200000026940906,
      1.9047619000424068,
      2.197806883626907
    ],
    "volume": 65745717
  },
  {
    "ts": 1618012800,
    "supply": 27357776439685,
    "trustlines": {
      "total": 39759,
      "authorized": 39759,
      "funded": 2281
    },
    "payments": 155,
    "payments_amount": 1715557197843,
    "trades": 422,
    "traded_amount": 276026897412,
    "price": [
      2.197806883626907,
      2.197806883626907,
      1.7249999993800977,
      2
    ],
    "volume": 180833547384
  },
  {
    "ts": 1618099200,
    "supply": 25604026639690,
    "trustlines": {
      "total": 39818,
      "authorized": 39818,
      "funded": 2286
    },
    "payments": 186,
    "payments_amount": 1797848742122,
    "trades": 688,
    "traded_amount": 425945940510,
    "price": [
      2,
      2,
      1.1251125109743092,
      1.70016864760681
    ],
    "volume": 229064746537
  },
  {
    "ts": 1618185600,
    "supply": 22343582139696,
    "trustlines": {
      "total": 39868,
      "authorized": 39868,
      "funded": 2284
    },
    "payments": 204,
    "payments_amount": 3265462234344,
    "trades": 198,
    "traded_amount": 61736872022,
    "price": [
      1.70016864760681,
      1.8165297690112656,
      1.6914893617021276,
      1.8000000012413793
    ],
    "volume": 4605957295
  },
  {
    "ts": 1618272000,
    "supply": 17894678739705,
    "trustlines": {
      "total": 39936,
      "authorized": 39936,
      "funded": 2290
    },
    "payments": 366,
    "payments_amount": 4451007567985,
    "trades": 1082,
    "traded_amount": 316040734476,
    "price": [
      1.8000000012413793,
      1.819672131147541,
      1.4545454545454546,
      1.5175602707430222
    ],
    "volume": 231087269624
  },
  {
    "ts": 1618358400,
    "supply": 65772143239714,
    "trustlines": {
      "total": 40013,
      "authorized": 40013,
      "funded": 2290
    },
    "payments": 293,
    "payments_amount": 462142256019984,
    "trades": 1493,
    "traded_amount": 488839179915,
    "price": [
      1.5175602707430222,
      1.6800005026070006,
      1.452095138348059,
      1.5727690914709267
    ],
    "volume": 357231796855
  },
  {
    "ts": 1618444800,
    "supply": 51929565339730,
    "trustlines": {
      "total": 40076,
      "authorized": 40076,
      "funded": 2290
    },
    "payments": 579,
    "payments_amount": 13914030356408,
    "trades": 2067,
    "traded_amount": 129722390626,
    "price": [
      1.5727690914709267,
      1.6232474161957822,
      1.5156960381034856,
      1.5157816005983544
    ],
    "volume": 45750987188
  },
  {
    "ts": 1618531200,
    "supply": 45500097139755,
    "trustlines": {
      "total": 40205,
      "authorized": 40205,
      "funded": 2284
    },
    "payments": 765,
    "payments_amount": 246461116101760,
    "trades": 5379,
    "traded_amount": 220533041120,
    "price": [
      1.5157816005983544,
      1.7479359368570722,
      1.5040650406504066,
      1.627916594281044
    ],
    "volume": 123644957363
  },
  {
    "ts": 1618617600,
    "supply": 42355650091127,
    "trustlines": {
      "total": 40295,
      "authorized": 40295,
      "funded": 2286
    },
    "payments": 371,
    "payments_amount": 3173938320477,
    "trades": 4805,
    "traded_amount": 52040067953,
    "price": [
      1.627916594281044,
      1.6796942434645414,
      1.5,
      1.6564554514072958
    ],
    "volume": 27428085645
  },
  {
    "ts": 1618704000,
    "supply": 39811335091133,
    "trustlines": {
      "total": 40362,
      "authorized": 40362,
      "funded": 2284
    },
    "payments": 289,
    "payments_amount": 2584134168262,
    "trades": 4354,
    "traded_amount": 419141995202,
    "price": [
      1.6564554514072958,
      2.099998172980095,
      1.515784647629028,
      1.8
    ],
    "volume": 117179917512
  },
  {
    "ts": 1618790400,
    "supply": 36402413291141,
    "trustlines": {
      "total": 40435,
      "authorized": 40435,
      "funded": 2283
    },
    "payments": 366,
    "payments_amount": 123444841022096,
    "trades": 1024,
    "traded_amount": 327957562194,
    "price": [
      1.8,
      2.011133133686119,
      1.4285714285545679,
      1.9848294705376153
    ],
    "volume": 244568281100
  },
  {
    "ts": 1618876800,
    "supply": 19672863091152,
    "trustlines": {
      "total": 40525,
      "authorized": 40525,
      "funded": 2287
    },
    "payments": 648,
    "payments_amount": 16805148213883,
    "trades": 6371,
    "traded_amount": 340565003052,
    "price": [
      1.9848294705376153,
      2.099998206017669,
      1.8489871086556169,
      1.8511326860841424
    ],
    "volume": 267692559340
  },
  {
    "ts": 1618963200,
    "supply": 34805485591166,
    "trustlines": {
      "total": 40607,
      "authorized": 40607,
      "funded": 2285
    },
    "payments": 514,
    "payments_amount": 44889559040971,
    "trades": 5110,
    "traded_amount": 36810020199,
    "price": [
      1.8511326860841424,
      2.0443808570544437,
      1.811764705882353,
      1.8991722349655928
    ],
    "volume": 11826940012
  },
  {
    "ts": 1619049600,
    "supply": 30624772191173,
    "trustlines": {
      "total": 40667,
      "authorized": 40667,
      "funded": 2282
    },
    "payments": 324,
    "payments_amount": 4687913457034,
    "trades": 5863,
    "traded_amount": 233632097365,
    "price": [
      1.8991722349655928,
      2.189020578400854,
      1.8177548144668858,
      2.189019744279231
    ],
    "volume": 212650053650
  },
  {
    "ts": 1619136000,
    "supply": 27749235891181,
    "trustlines": {
      "total": 40732,
      "authorized": 40732,
      "funded": 2281
    },
    "payments": 265,
    "payments_amount": 2913045457165,
    "trades": 5183,
    "traded_amount": 54397802591,
    "price": [
      2.189019744279231,
      2.458306566779279,
      1.4503263209033344,
      2.2315805452346846
    ],
    "volume": 87327107004
  },
  {
    "ts": 1619222400,
    "supply": 26840866591185,
    "trustlines": {
      "total": 40836,
      "authorized": 40836,
      "funded": 2287
    },
    "payments": 157,
    "payments_amount": 1024460696006,
    "trades": 4295,
    "traded_amount": 143969435813,
    "price": [
      2.2315805452346846,
      2.370890540712185,
      1.7467648552078174,
      2.297619758377479
    ],
    "volume": 237060918479
  },
  {
    "ts": 1619308800,
    "supply": 25662045991189,
    "trustlines": {
      "total": 40940,
      "authorized": 40940,
      "funded": 2300
    },
    "payments": 171,
    "payments_amount": 1471513296230,
    "trades": 7159,
    "traded_amount": 346363644266,
    "price": [
      2.297619758377479,
      2.370889698766652,
      1.4285714283091968,
      2.3148150505130856
    ],
    "volume": 556074709400
  },
  {
    "ts": 1619395200,
    "supply": 23575161391195,
    "trustlines": {
      "total": 41002,
      "authorized": 41002,
      "funded": 2311
    },
    "payments": 256,
    "payments_amount": 362124998449277,
    "trades": 6787,
    "traded_amount": 218794837563,
    "price": [
      2.3148150505130856,
      2.3148152960073647,
      1.4285730610480558,
      1.8319999840759555
    ],
    "volume": 158383532505
  },
  {
    "ts": 1619481600,
    "supply": 36131355891205,
    "trustlines": {
      "total": 41084,
      "authorized": 41084,
      "funded": 2315
    },
    "payments": 341,
    "payments_amount": 47635653781424,
    "trades": 7604,
    "traded_amount": 242948910918,
    "price": [
      1.8319999840759555,
      2.0882702985765786,
      1.5999999979768318,
      1.9457669186262028
    ],
    "volume": 224911594182
  },
  {
    "ts": 1619568000,
    "supply": 33053864991211,
    "trustlines": {
      "total": 41175,
      "authorized": 41175,
      "funded": 2331
    },
    "payments": 398,
    "payments_amount": 3198495803716,
    "trades": 6557,
    "traded_amount": 272674105371,
    "price": [
      1.9457669186262028,
      2.068359022465748,
      1.6666666666666667,
      1.950003039259666
    ],
    "volume": 310316387617
  },
  {
    "ts": 1619654400,
    "supply": 29031811691221,
    "trustlines": {
      "total": 41298,
      "authorized": 41298,
      "funded": 2350
    },
    "payments": 653,
    "payments_amount": 4357014677718,
    "trades": 2337,
    "traded_amount": 404333087933,
    "price": [
      1.950003039259666,
      2.083931372554596,
      1.9455445544554455,
      2.014144621799558
    ],
    "volume": 537218549814
  },
  {
    "ts": 1619740800,
    "supply": 20955882691227,
    "trustlines": {
      "total": 41389,
      "authorized": 41389,
      "funded": 2359
    },
    "payments": 530,
    "payments_amount": 129370814701595,
    "trades": 3679,
    "traded_amount": 461665105584,
    "price": [
      2.014144621799558,
      2.041407162881798,
      1.5403274645717286,
      1.7800002594201958
    ],
    "volume": 544679317559
  },
  {
    "ts": 1619827200,
    "supply": 19020495991234,
    "trustlines": {
      "total": 41489,
      "authorized": 41489,
      "funded": 2356
    },
    "payments": 320,
    "payments_amount": 2009699274031,
    "trades": 6723,
    "traded_amount": 163452776916,
    "price": [
      1.7800002594201958,
      1.9271069590506436,
      1.5410004747617043,
      1.8571329162450028
    ],
    "volume": 224612741033
  },
  {
    "ts": 1619913600,
    "supply": 37295974991242,
    "trustlines": {
      "total": 41580,
      "authorized": 41580,
      "funded": 2369
    },
    "payments": 336,
    "payments_amount": 41818585161670,
    "trades": 7515,
    "traded_amount": 145130538024,
    "price": [
      1.8571329162450028,
      1.8580000029751536,
      1.6666666666666667,
      1.7845101396981458
    ],
    "volume": 181867452609
  },
  {
    "ts": 1620000000,
    "supply": 33559969991258,
    "trustlines": {
      "total": 41661,
      "authorized": 41661,
      "funded": 2368
    },
    "payments": 483,
    "payments_amount": 3872698475181,
    "trades": 6772,
    "traded_amount": 494039047080,
    "price": [
      1.7845101396981458,
      1.8345393932310592,
      1.4999999967724724,
      1.7907441695244628
    ],
    "volume": 477232475729
  },
  {
    "ts": 1620086400,
    "supply": 24824284991274,
    "trustlines": {
      "total": 41777,
      "authorized": 41777,
      "funded": 2375
    },
    "payments": 810,
    "payments_amount": 8957969581243,
    "trades": 5272,
    "traded_amount": 159408850280,
    "price": [
      1.7907441695244628,
      1.9910650074192755,
      1.7678571428571428,
      1.9718856874075188
    ],
    "volume": 194153855311
  },
  {
    "ts": 1620172800,
    "supply": 41675514891281,
    "trustlines": {
      "total": 41964,
      "authorized": 41964,
      "funded": 2416
    },
    "payments": 456,
    "payments_amount": 343887771495411,
    "trades": 3342,
    "traded_amount": 989802436484,
    "price": [
      1.9718856874075188,
      2.1400000003698896,
      1.4285713698630138,
      1.6801080926580276
    ],
    "volume": 1256633860833
  },
  {
    "ts": 1620259200,
    "supply": 26956844491296,
    "trustlines": {
      "total": 42155,
      "authorized": 42155,
      "funded": 2433
    },
    "payments": 952,
    "payments_amount": 14962453111505,
    "trades": 947,
    "traded_amount": 821715508761,
    "price": [
      1.6801080926580276,
      2.139820137395621,
      1.4,
      1.51
    ],
    "volume": 613993451409
  },
  {
    "ts": 1620345600,
    "supply": 21905702691302,
    "trustlines": {
      "total": 42288,
      "authorized": 42288,
      "funded": 2449
    },
    "payments": 505,
    "payments_amount": 162845907455388,
    "trades": 581,
    "traded_amount": 417664691729,
    "price": [
      1.51,
      1.6450000005351773,
      1.3649999966197406,
      1.5847860541252865
    ],
    "volume": 393364925275
  },
  {
    "ts": 1620432000,
    "supply": 21785138291303,
    "trustlines": {
      "total": 42401,
      "authorized": 42401,
      "funded": 2462
    },
    "payments": 123,
    "payments_amount": 635037048701,
    "trades": 547,
    "traded_amount": 500490286975,
    "price": [
      1.5847860541252865,
      1.6182417020596203,
      1.3079999907055675,
      1.4367818155
    ],
    "volume": 589915186173
  },
  {
    "ts": 1620518400,
    "supply": 40595563691312,
    "trustlines": {
      "total": 42495,
      "authorized": 42495,
      "funded": 2457
    },
    "payments": 503,
    "payments_amount": 56547319329940,
    "trades": 561,
    "traded_amount": 337007117761,
    "price": [
      1.4367818155,
      1.7743980621269833,
      1.3342228143410486,
      1.6
    ],
    "volume": 447561447122
  },
  {
    "ts": 1620604800,
    "supply": 34144986591324,
    "trustlines": {
      "total": 42603,
      "authorized": 42603,
      "funded": 2472
    },
    "payments": 672,
    "payments_amount": 6692759161120,
    "trades": 572,
    "traded_amount": 337955545498,
    "price": [
      1.6,
      1.6,
      1.2499999998940015,
      1.407999999934177
    ],
    "volume": 283159668361
  },
  {
    "ts": 1620691200,
    "supply": 50216295391327,
    "trustlines": {
      "total": 42686,
      "authorized": 42686,
      "funded": 2473
    },
    "payments": 276,
    "payments_amount": 254117081092710,
    "trades": 539,
    "traded_amount": 264708647213,
    "price": [
      1.407999999934177,
      1.612907406853508,
      1.3499997357323625,
      1.3619999270921532
    ],
    "volume": 212891540798
  },
  {
    "ts": 1620777600,
    "supply": 36269540591362,
    "trustlines": {
      "total": 42778,
      "authorized": 42778,
      "funded": 2469
    },
    "payments": 1311,
    "payments_amount": 14359881473310,
    "trades": 539,
    "traded_amount": 426190658053,
    "price": [
      1.3619999270921532,
      1.5700560448358687,
      1.2499999979839576,
      1.538461539136992
    ],
    "volume": 446192897656
  },
  {
    "ts": 1620864000,
    "supply": 28529931491374,
    "trustlines": {
      "total": 42853,
      "authorized": 42853,
      "funded": 2465
    },
    "payments": 553,
    "payments_amount": 7918090113551,
    "trades": 487,
    "traded_amount": 219167288311,
    "price": [
      1.538461539136992,
      1.7241379310749396,
      1.4867507994455735,
      1.637007158884496
    ],
    "volume": 167402474872
  },
  {
    "ts": 1620950400,
    "supply": 23393298491380,
    "trustlines": {
      "total": 42924,
      "authorized": 42924,
      "funded": 2474
    },
    "payments": 522,
    "payments_amount": 5259338752917,
    "trades": 684,
    "traded_amount": 208333161518,
    "price": [
      1.637007158884496,
      1.637800102043656,
      1.2531328316933061,
      1.3900426742532006
    ],
    "volume": 176884394059
  },
  {
    "ts": 1621036800,
    "supply": 20669985891387,
    "trustlines": {
      "total": 43010,
      "authorized": 43010,
      "funded": 2481
    },
    "payments": 296,
    "payments_amount": 3110518529278,
    "trades": 1383,
    "traded_amount": 295801723975,
    "price": [
      1.3900426742532006,
      1.501800378091739,
      1.299999998721711,
      1.4285714285714286
    ],
    "volume": 297686687064
  },
  {
    "ts": 1621123200,
    "supply": 18273913391391,
    "trustlines": {
      "total": 43064,
      "authorized": 43064,
      "funded": 2488
    },
    "payments": 312,
    "payments_amount": 2606011029555,
    "trades": 731,
    "traded_amount": 206401349199,
    "price": [
      1.4285714285714286,
      1.5010206163757984,
      1.2666244458108409,
      1.492537313432836
    ],
    "volume": 233146107298
  },
  {
    "ts": 1621209600,
    "supply": 34500394691400,
    "trustlines": {
      "total": 43128,
      "authorized": 43128,
      "funded": 2496
    },
    "payments": 422,
    "payments_amount": 464232177100830,
    "trades": 1018,
    "traded_amount": 559139828683,
    "price": [
      1.492537313432836,
      1.6,
      1.3139150852785162,
      1.4560067000172934
    ],
    "volume": 477982330207
  },
  {
    "ts": 1621296000,
    "supply": 31183496191413,
    "trustlines": {
      "total": 43167,
      "authorized": 43167,
      "funded": 2499
    },
    "payments": 395,
    "payments_amount": 3373252187695,
    "trades": 390,
    "traded_amount": 60945791994,
    "price": [
      1.4560067000172934,
      1.5980000091741502,
      1.3819999998140633,
      1.4599403317851116
    ],
    "volume": 50720680872
  },
  {
    "ts": 1621382400,
    "supply": 24295296491425,
    "trustlines": {
      "total": 43218,
      "authorized": 43218,
      "funded": 2493
    },
    "payments": 481,
    "payments_amount": 7188978906003,
    "trades": 1080,
    "traded_amount": 471039384770,
    "price": [
      1.4599403317851116,
      3.000000017175446,
      1.455882536408121,
      1.7787590203093369
    ],
    "volume": 373145358336
  },
  {
    "ts": 1621468800,
    "supply": 51695601691431,
    "trustlines": {
      "total": 43269,
      "authorized": 43269,
      "funded": 2493
    },
    "payments": 317,
    "payments_amount": 62772485560695,
    "trades": 339,
    "traded_amount": 107418654990,
    "price": [
      1.7787590203093369,
      2.103873686348175,
      1.7787561727227224,
      1.835
    ],
    "volume": 105837965921
  },
  {
    "ts": 1621555200,
    "supply": 43483043491435,
    "trustlines": {
      "total": 43320,
      "authorized": 43320,
      "funded": 2489
    },
    "payments": 300,
    "payments_amount": 98258050317981,
    "trades": 468,
    "traded_amount": 31044821013,
    "price": [
      1.835,
      2.564102667602597,
      1.834862380740119,
      2.564102667602597
    ],
    "volume": 36543621805
  },
  {
    "ts": 1621641600,
    "supply": 41760608891439,
    "trustlines": {
      "total": 43361,
      "authorized": 43361,
      "funded": 2489
    },
    "payments": 199,
    "payments_amount": 1807001150483,
    "trades": 271,
    "traded_amount": 64536901764,
    "price": [
      2.564102667602597,
      2.579710144927536,
      2.125037639678107,
      2.579710144927536
    ],
    "volume": 88308379794
  },
  {
    "ts": 1621728000,
    "supply": 38381227791448,
    "trustlines": {
      "total": 43384,
      "authorized": 43384,
      "funded": 2487
    },
    "payments": 283,
    "payments_amount": 3450695069320,
    "trades": 467,
    "traded_amount": 81375266654,
    "price": [
      2.579710144927536,
      3.49998882,
      2.2800004733226062,
      2.610989010989011
    ],
    "volume": 117624038583
  },
  {
    "ts": 1621814400,
    "supply": 25439269291452,
    "trustlines": {
      "total": 43411,
      "authorized": 43411,
      "funded": 2492
    },
    "payments": 264,
    "payments_amount": 12981803515645,
    "trades": 381,
    "traded_amount": 63616510176,
    "price": [
      2.610989010989011,
      2.857143464192978,
      2.159999789619498,
      2.645502647977799
    ],
    "volume": 80001884905
  },
  {
    "ts": 1621900800,
    "supply": 22244852691458,
    "trustlines": {
      "total": 43438,
      "authorized": 43438,
      "funded": 2493
    },
    "payments": 289,
    "payments_amount": 3209757987011,
    "trades": 348,
    "traded_amount": 25969038285,
    "price": [
      2.645502647977799,
      2.645502647977799,
      2.1502219187435987,
      2.1551724967061925
    ],
    "volume": 13527578467
  },
  {
    "ts": 1621987200,
    "supply": 17856212991469,
    "trustlines": {
      "total": 43491,
      "authorized": 43491,
      "funded": 2495
    },
    "payments": 377,
    "payments_amount": 4452885890587,
    "trades": 430,
    "traded_amount": 86249438047,
    "price": [
      2.1551724967061925,
      2.3988000282603763,
      2.0499984072995234,
      2.0618556699802197
    ],
    "volume": 41089129170
  },
  {
    "ts": 1622073600,
    "supply": 35650017891471,
    "trustlines": {
      "total": 43529,
      "authorized": 43529,
      "funded": 2494
    },
    "payments": 252,
    "payments_amount": 282265146695810,
    "trades": 427,
    "traded_amount": 93685184861,
    "price": [
      2.0618556699802197,
      2.583938601489168,
      1.8526315789473684,
      2.2988506339924766
    ],
    "volume": 130210156699
  },
  {
    "ts": 1622160000,
    "supply": 33181627891479,
    "trustlines": {
      "total": 43568,
      "authorized": 43568,
      "funded": 2499
    },
    "payments": 306,
    "payments_amount": 2477722107763,
    "trades": 320,
    "traded_amount": 14379974438,
    "price": [
      2.2988506339924766,
      2.65100000351835,
      2.1300002990610287,
      2.5
    ],
    "volume": 14654015461
  },
  {
    "ts": 1622246400,
    "supply": 31603930391484,
    "trustlines": {
      "total": 43593,
      "authorized": 43593,
      "funded": 2503
    },
    "payments": 184,
    "payments_amount": 1598655775874,
    "trades": 333,
    "traded_amount": 35574018499,
    "price": [
      2.5,
      2.8100006363636365,
      2.4043478260869566,
      2.6363636363636362
    ],
    "volume": 36961956184
  },
  {
    "ts": 1622332800,
    "supply": 30602750691489,
    "trustlines": {
      "total": 43627,
      "authorized": 43627,
      "funded": 2506
    },
    "payments": 123,
    "payments_amount": 1015363709249,
    "trades": 139,
    "traded_amount": 28491177438,
    "price": [
      2.6363636363636362,
      2.7699999983063046,
      2.4679999984426577,
      2.6199999498042463
    ],
    "volume": 39378771521
  },
  {
    "ts": 1622419200,
    "supply": 28766153491494,
    "trustlines": {
      "total": 43655,
      "authorized": 43655,
      "funded": 2515
    },
    "payments": 228,
    "payments_amount": 1866713308830,
    "trades": 440,
    "traded_amount": 51684823243,
    "price": [
      2.6199999498042463,
      2.9239766088851082,
      2.3649999764071623,
      2.6719848053181385
    ],
    "volume": 61563658502
  },
  {
    "ts": 1622505600,
    "supply": 25865032291504,
    "trustlines": {
      "total": 43689,
      "authorized": 43689,
      "funded": 2520
    },
    "payments": 322,
    "payments_amount": 2920489776638,
    "trades": 198,
    "traded_amount": 50707539221,
    "price": [
      2.6719848053181385,
      2.695149677534049,
      2.1499999808448904,
      2.400000014022972
    ],
    "volume": 51176104379
  },
  {
    "ts": 1622592000,
    "supply": 20158548891514,
    "trustlines": {
      "total": 43733,
      "authorized": 43733,
      "funded": 2524
    },
    "payments": 484,
    "payments_amount": 5715229360418,
    "trades": 163,
    "traded_amount": 11437343610,
    "price": [
      2.400000014022972,
      2.44,
      2.271939736346516,
      2.2720007461233274
    ],
    "volume": 15003828251
  },
  {
    "ts": 1622678400,
    "supply": 41076594391521,
    "trustlines": {
      "total": 43753,
      "authorized": 43753,
      "funded": 2524
    },
    "payments": 323,
    "payments_amount": 54092949506078,
    "trades": 242,
    "traded_amount": 18342454847,
    "price": [
      2.2720007461233274,
      2.413999906780748,
      2.264744559006299,
      2.282
    ],
    "volume": 9292486858
  },
  {
    "ts": 1622764800,
    "supply": 38794705791526,
    "trustlines": {
      "total": 43774,
      "authorized": 43774,
      "funded": 2526
    },
    "payments": 267,
    "payments_amount": 2319000722134,
    "trades": 271,
    "traded_amount": 46758206701,
    "price": [
      2.282,
      2.570000068549287,
      2.2819999142596794,
      2.54452926222554
    ],
    "volume": 41436130210
  },
  {
    "ts": 1622851200,
    "supply": 37197714591531,
    "trustlines": {
      "total": 43799,
      "authorized": 43799,
      "funded": 2529
    },
    "payments": 208,
    "payments_amount": 1606599825572,
    "trades": 160,
    "traded_amount": 11505237770,
    "price": [
      2.54452926222554,
      2.7000000017656838,
      2.4999999552873393,
      2.6315789494060593
    ],
    "volume": 11735018147
  },
  {
    "ts": 1622937600,
    "supply": 35487504091535,
    "trustlines": {
      "total": 43818,
      "authorized": 43818,
      "funded": 2528
    },
    "payments": 166,
    "payments_amount": 1736852880473,
    "trades": 116,
    "traded_amount": 20726647589,
    "price": [
      2.6315789494060593,
      2.7100059692583196,
      2.3699999940850893,
      2.6000015644596486
    ],
    "volume": 21734706203
  },
  {
    "ts": 1623024000,
    "supply": 33792350091539,
    "trustlines": {
      "total": 43839,
      "authorized": 43839,
      "funded": 2528
    },
    "payments": 257,
    "payments_amount": 1746483888316,
    "trades": 280,
    "traded_amount": 51004713063,
    "price": [
      2.6000015644596486,
      2.9239766102031552,
      2.1907199794715986,
      2.4327940578567007
    ],
    "volume": 98068652033
  },
  {
    "ts": 1623110400,
    "supply": 30518266991543,
    "trustlines": {
      "total": 43852,
      "authorized": 43852,
      "funded": 2521
    },
    "payments": 227,
    "payments_amount": 3276992775564,
    "trades": 296,
    "traded_amount": 37660266047,
    "price": [
      2.4327940578567007,
      3.2300002511476333,
      2.4327940578567007,
      2.821041559074087
    ],
    "volume": 25281519436
  },
  {
    "ts": 1623196800,
    "supply": 28786880891549,
    "trustlines": {
      "total": 43870,
      "authorized": 43870,
      "funded": 2519
    },
    "payments": 210,
    "payments_amount": 1737199506256,
    "trades": 133,
    "traded_amount": 8461899722,
    "price": [
      2.821041559074087,
      2.988310779975793,
      2.680000920610657,
      2.680000920610657
    ],
    "volume": 6040287625
  },
  {
    "ts": 1623283200,
    "supply": 20152067891554,
    "trustlines": {
      "total": 43890,
      "authorized": 43890,
      "funded": 2522
    },
    "payments": 244,
    "payments_amount": 8647206311440,
    "trades": 510,
    "traded_amount": 17248822068,
    "price": [
      2.680000920610657,
      2.9883115691063646,
      2.6702269683069124,
      2.9883115691063646
    ],
    "volume": 23415745248
  },
  {
    "ts": 1623369600,
    "supply": 31988221491561,
    "trustlines": {
      "total": 43905,
      "authorized": 43905,
      "funded": 2521
    },
    "payments": 199,
    "payments_amount": 48172852618606,
    "trades": 508,
    "traded_amount": 45311627173,
    "price": [
      2.9883115691063646,
      3.1169378515756576,
      2.6903417982940057,
      3.1169378515756576
    ],
    "volume": 47950702955
  },
  {
    "ts": 1623456000,
    "supply": 30386391191567,
    "trustlines": {
      "total": 43923,
      "authorized": 43923,
      "funded": 2525
    },
    "payments": 150,
    "payments_amount": 1613330918687,
    "trades": 266,
    "traded_amount": 27540890728,
    "price": [
      3.1169378515756576,
      3.5200001136329493,
      2.7686832740213525,
      3
    ],
    "volume": 44435084785
  },
  {
    "ts": 1623542400,
    "supply": 29441204591573,
    "trustlines": {
      "total": 43934,
      "authorized": 43934,
      "funded": 2536
    },
    "payments": 138,
    "payments_amount": 948238191511,
    "trades": 195,
    "traded_amount": 16084692436,
    "price": [
      3,
      3.173,
      2.8649841894875006,
      3.173
    ],
    "volume": 15008225688
  },
  {
    "ts": 1623628800,
    "supply": 26993790991581,
    "trustlines": {
      "total": 43952,
      "authorized": 43952,
      "funded": 2540
    },
    "payments": 230,
    "payments_amount": 2452629313446,
    "trades": 193,
    "traded_amount": 34020899978,
    "price": [
      3.173,
      3.173,
      2.863511987571016,
      2.9035120064740094
    ],
    "volume": 50827188086
  },
  {
    "ts": 1623715200,
    "supply": 24470345791588,
    "trustlines": {
      "total": 43969,
      "authorized": 43969,
      "funded": 2541
    },
    "payments": 245,
    "payments_amount": 2538646657772,
    "trades": 118,
    "traded_amount": 33404858233,
    "price": [
      2.9035120064740094,
      3.0513601917255815,
      2.799999663907871,
      2.834999973244817
    ],
    "volume": 27013539229
  },
  {
    "ts": 1623801600,
    "supply": 22175811791597,
    "trustlines": {
      "total": 43982,
      "authorized": 43982,
      "funded": 2542
    },
    "payments": 247,
    "payments_amount": 2301477913561,
    "trades": 365,
    "traded_amount": 27922675187,
    "price": [
      2.834999973244817,
      3.173039957098321,
      2.834999973244817,
      3.15308214
    ],
    "volume": 20207084366
  },
  {
    "ts": 1623888000,
    "supply": 20190084691604,
    "trustlines": {
      "total": 43991,
      "authorized": 43991,
      "funded": 2539
    },
    "payments": 156,
    "payments_amount": 1993369752436,
    "trades": 89,
    "traded_amount": 13876716909,
    "price": [
      3.15308214,
      3.1730400031072454,
      2.999977452594079,
      3.1730400031072454
    ],
    "volume": 3414558998
  },
  {
    "ts": 1623974400,
    "supply": 20190084691604,
    "trustlines": {
      "total": 44003,
      "authorized": 44003,
      "funded": 2538
    },
    "payments": 13,
    "payments_amount": 12175985260,
    "trades": 196,
    "traded_amount": 27202920478,
    "price": [
      3.1730400031072454,
      3.5500000148669177,
      3.019995623581983,
      3.321988821988822
    ],
    "volume": 27618665658
  },
  {
    "ts": 1624060800,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44022,
      "authorized": 44022,
      "funded": 2537
    },
    "payments": 17,
    "payments_amount": 16588663875,
    "trades": 343,
    "traded_amount": 24310703391,
    "price": [
      3.321988821988822,
      3.4550012105445846,
      2.999999994462704,
      3.410000002207252
    ],
    "volume": 34406363803
  },
  {
    "ts": 1624147200,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44032,
      "authorized": 44032,
      "funded": 2538
    },
    "payments": 1,
    "payments_amount": 767981667,
    "trades": 155,
    "traded_amount": 12322478827,
    "price": [
      3.410000002207252,
      3.482174688057041,
      3.410000002207252,
      3.480898192808464
    ],
    "volume": 2116416560
  },
  {
    "ts": 1624233600,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44049,
      "authorized": 44049,
      "funded": 2538
    },
    "payments": 20,
    "payments_amount": 11386128085,
    "trades": 306,
    "traded_amount": 27265821271,
    "price": [
      3.480898192808464,
      4,
      3.259199991536318,
      4
    ],
    "volume": 42955710412
  },
  {
    "ts": 1624320000,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44061,
      "authorized": 44061,
      "funded": 2540
    },
    "payments": 26,
    "payments_amount": 41278659104,
    "trades": 638,
    "traded_amount": 230731911537,
    "price": [
      4,
      5,
      3.8505933396546275,
      3.8506071446566232
    ],
    "volume": 516341658345
  },
  {
    "ts": 1624406400,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44075,
      "authorized": 44075,
      "funded": 2538
    },
    "payments": 15,
    "payments_amount": 36494941618,
    "trades": 199,
    "traded_amount": 48221326052,
    "price": [
      3.8506071446566232,
      4.122941312920436,
      3.6509712052973264,
      3.802081813056523
    ],
    "volume": 13872762162
  },
  {
    "ts": 1624492800,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44077,
      "authorized": 44077,
      "funded": 2535
    },
    "payments": 25,
    "payments_amount": 37740983162,
    "trades": 203,
    "traded_amount": 43848611593,
    "price": [
      3.802081813056523,
      3.944437,
      3.5351193996122703,
      3.6501956001113443
    ],
    "volume": 48194274946
  },
  {
    "ts": 1624579200,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44086,
      "authorized": 44086,
      "funded": 2538
    },
    "payments": 24,
    "payments_amount": 13103432501,
    "trades": 175,
    "traded_amount": 22673003424,
    "price": [
      3.6501956001113443,
      3.9928140326244868,
      3.6144092509105428,
      3.9928140326244868
    ],
    "volume": 27060084002
  },
  {
    "ts": 1624665600,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44102,
      "authorized": 44102,
      "funded": 2541
    },
    "payments": 13,
    "payments_amount": 4154395719,
    "trades": 127,
    "traded_amount": 34983342487,
    "price": [
      3.9928140326244868,
      4.469992931785113,
      3.79864148435738,
      3.9899956696724233
    ],
    "volume": 9683850927
  },
  {
    "ts": 1624752000,
    "supply": 20190084691506,
    "trustlines": {
      "total": 44115,
      "authorized": 44115,
      "funded": 2542
    },
    "payments": 23,
    "payments_amount": 14253683741,
    "trades": 193,
    "traded_amount": 45354263646,
    "price": [
      3.9899956696724233,
      4.444444446995722,
      3.645150398943246,
      3.9586408522421705
    ],
    "volume": 57354823197
  },
  {
    "ts": 1624838400,
    "supply": 20190084668336,
    "trustlines": {
      "total": 44127,
      "authorized": 44127,
      "funded": 2544
    },
    "payments": 8,
    "payments_amount": 4452036367,
    "trades": 324,
    "traded_amount": 34126146022,
    "price": [
      3.9586408522421705,
      4.122001240043878,
      3.65,
      3.65401585419181
    ],
    "volume": 9068059253
  },
  {
    "ts": 1624924800,
    "supply": 20190084668336,
    "trustlines": {
      "total": 44132,
      "authorized": 44132,
      "funded": 2543
    },
    "payments": 10,
    "payments_amount": 5153298810,
    "trades": 282,
    "traded_amount": 32998524483,
    "price": [
      3.65401585419181,
      3.895000036695593,
      3.4444096979507743,
      3.444444394778011
    ],
    "volume": 17587549324
  },
  {
    "ts": 1625011200,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44133,
      "authorized": 44133,
      "funded": 2543
    },
    "payments": 54,
    "payments_amount": 896617682020,
    "trades": 125,
    "traded_amount": 23748385192,
    "price": [
      3.444444394778011,
      3.478,
      3.444444394778011,
      3.478
    ],
    "volume": 347800000
  },
  {
    "ts": 1625097600,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44148,
      "authorized": 44148,
      "funded": 2547
    },
    "payments": 13,
    "payments_amount": 18940239792,
    "trades": 230,
    "traded_amount": 41910870407,
    "price": [
      3.478,
      3.74499999590495,
      3.478,
      3.5800013873097862
    ],
    "volume": 49678701687
  },
  {
    "ts": 1625184000,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44158,
      "authorized": 44158,
      "funded": 2549
    },
    "payments": 4,
    "payments_amount": 1531819385,
    "trades": 195,
    "traded_amount": 18638445197,
    "price": [
      3.5800013873097862,
      3.840000002516983,
      3.5800013873097862,
      3.773586800093711
    ],
    "volume": 2055617625
  },
  {
    "ts": 1625270400,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44166,
      "authorized": 44166,
      "funded": 2550
    },
    "payments": 3,
    "payments_amount": 948606221,
    "trades": 201,
    "traded_amount": 18684957032,
    "price": [
      3.773586800093711,
      3.773586800093711,
      3.598,
      3.598
    ],
    "volume": 18001864
  },
  {
    "ts": 1625356800,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44173,
      "authorized": 44173,
      "funded": 2551
    },
    "payments": 10,
    "payments_amount": 6947888504,
    "trades": 86,
    "traded_amount": 29116615873,
    "price": [
      3.598,
      3.800000673417841,
      3.584999999475619,
      3.768
    ],
    "volume": 10466768439
  },
  {
    "ts": 1625443200,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44186,
      "authorized": 44186,
      "funded": 2549
    },
    "payments": 21,
    "payments_amount": 4547161965,
    "trades": 84,
    "traded_amount": 27557980325,
    "price": [
      3.768,
      3.9500000623519917,
      3.6399992046367737,
      3.6800009806144773
    ],
    "volume": 6745708783
  },
  {
    "ts": 1625529600,
    "supply": 19293473068339,
    "trustlines": {
      "total": 44196,
      "authorized": 44196,
      "funded": 2548
    },
    "payments": 11,
    "payments_amount": 4464499710,
    "trades": 271,
    "traded_amount": 52974403753,
    "price": [
      3.6800009806144773,
      3.95000943811741,
      3.6800009806144773,
      3.855549930402038
    ],
    "volume": 4665100123
  },
  {
    "ts": 1625616000,
    "supply": 17580555468339,
    "trustlines": {
      "total": 44210,
      "authorized": 44210,
      "funded": 2550
    },
    "payments": 71,
    "payments_amount": 1713302503830,
    "trades": 241,
    "traded_amount": 53395934801,
    "price": [
      3.855549930402038,
      3.950071101279823,
      3.721999998885136,
      3.875968992248062
    ],
    "volume": 4566886290
  },
  {
    "ts": 1625702400,
    "supply": 37451005168339,
    "trustlines": {
      "total": 44221,
      "authorized": 44221,
      "funded": 2547
    },
    "payments": 17,
    "payments_amount": 40132222516998,
    "trades": 491,
    "traded_amount": 66960212526,
    "price": [
      3.875968992248062,
      4.200000016344353,
      3.87590852685694,
      4.139992483031549
    ],
    "volume": 18955030368
  },
  {
    "ts": 1625788800,
    "supply": 34826112768343,
    "trustlines": {
      "total": 44236,
      "authorized": 44236,
      "funded": 2549
    },
    "payments": 206,
    "payments_amount": 2626766283584,
    "trades": 385,
    "traded_amount": 28387001463,
    "price": [
      4.139992483031549,
      4.449056374780023,
      4.087706768649496,
      4.08775
    ],
    "volume": 17304744025
  },
  {
    "ts": 1625875200,
    "supply": 34068259868343,
    "trustlines": {
      "total": 44250,
      "authorized": 44250,
      "funded": 2550
    },
    "payments": 66,
    "payments_amount": 758680208852,
    "trades": 98,
    "traded_amount": 21027046922,
    "price": [
      4.08775,
      4.28000109568028,
      4.08775,
      4.16
    ],
    "volume": 1338737102
  },
  {
    "ts": 1625961600,
    "supply": 33127527468343,
    "trustlines": {
      "total": 44262,
      "authorized": 44262,
      "funded": 2555
    },
    "payments": 33,
    "payments_amount": 947379444307,
    "trades": 99,
    "traded_amount": 25230990383,
    "price": [
      4.16,
      4.5,
      4.031999999655442,
      4.032
    ],
    "volume": 20274581526
  },
  {
    "ts": 1626048000,
    "supply": 32269672568343,
    "trustlines": {
      "total": 44273,
      "authorized": 44273,
      "funded": 2559
    },
    "payments": 54,
    "payments_amount": 858867053846,
    "trades": 149,
    "traded_amount": 35041942360,
    "price": [
      4.032,
      4.358283100240946,
      3.999999928563659,
      4.22950758363887
    ],
    "volume": 22909095858
  },
  {
    "ts": 1626134400,
    "supply": 31382141068343,
    "trustlines": {
      "total": 44290,
      "authorized": 44290,
      "funded": 2565
    },
    "payments": 89,
    "payments_amount": 896954075507,
    "trades": 163,
    "traded_amount": 35772942633,
    "price": [
      4.22950758363887,
      4.444445333333333,
      4.021999939148182,
      4.42
    ],
    "volume": 11494933514
  },
  {
    "ts": 1626220800,
    "supply": 29975403868345,
    "trustlines": {
      "total": 44295,
      "authorized": 44295,
      "funded": 2563
    },
    "payments": 98,
    "payments_amount": 1409289303459,
    "trades": 213,
    "traded_amount": 32265437609,
    "price": [
      4.42,
      4.500000926470779,
      4.207968476357268,
      4.207968476357268
    ],
    "volume": 41321432215
  },
  {
    "ts": 1626307200,
    "supply": 28177761968346,
    "trustlines": {
      "total": 44305,
      "authorized": 44305,
      "funded": 2562
    },
    "payments": 145,
    "payments_amount": 1799614793719,
    "trades": 102,
    "traded_amount": 20325646992,
    "price": [
      4.207968476357268,
      4.500001205209881,
      4.02220255,
      4.194999134146984
    ],
    "volume": 1617857181
  },
  {
    "ts": 1626393600,
    "supply": 26114265568351,
    "trustlines": {
      "total": 44309,
      "authorized": 44309,
      "funded": 2563
    },
    "payments": 145,
    "payments_amount": 2066518162821,
    "trades": 159,
    "traded_amount": 48615512161,
    "price": [
      4.194999134146984,
      4.540000008822466,
      4.074965146385182,
      4.540000008822466
    ],
    "volume": 29589914220
  },
  {
    "ts": 1626480000,
    "supply": 25023434368355,
    "trustlines": {
      "total": 44318,
      "authorized": 44318,
      "funded": 2563
    },
    "payments": 110,
    "payments_amount": 1092212882941,
    "trades": 44,
    "traded_amount": 13131705318,
    "price": [
      4.540000008822466,
      4.540000008822466,
      4.209090909090909,
      4.213337400673972
    ],
    "volume": 8261467597
  },
  {
    "ts": 1626566400,
    "supply": 24546404968356,
    "trustlines": {
      "total": 44326,
      "authorized": 44326,
      "funded": 2567
    },
    "payments": 37,
    "payments_amount": 477216764021,
    "trades": 189,
    "traded_amount": 26780089603,
    "price": [
      4.213337400673972,
      4.566666666666666,
      4.213337400673972,
      4.240000105831235
    ],
    "volume": 27746436316
  },
  {
    "ts": 1626652800,
    "supply": 22948732268358,
    "trustlines": {
      "total": 44343,
      "authorized": 44343,
      "funded": 2568
    },
    "payments": 129,
    "payments_amount": 1601459484744,
    "trades": 435,
    "traded_amount": 101026818708,
    "price": [
      4.240000105831235,
      4.620000432784899,
      4.213338163729459,
      4.620000432784899
    ],
    "volume": 25643126686
  },
  {
    "ts": 1626739200,
    "supply": 21349909068365,
    "trustlines": {
      "total": 44354,
      "authorized": 44354,
      "funded": 2572
    },
    "payments": 193,
    "payments_amount": 1634315288035,
    "trades": 578,
    "traded_amount": 80830193332,
    "price": [
      4.620000432784899,
      4.95,
      4.013999880834156,
      4.9
    ],
    "volume": 125532731858
  },
  {
    "ts": 1626825600,
    "supply": 19629264368367,
    "trustlines": {
      "total": 44359,
      "authorized": 44359,
      "funded": 2575
    },
    "payments": 194,
    "payments_amount": 1723878345101,
    "trades": 81,
    "traded_amount": 20482524509,
    "price": [
      4.9,
      4.995004995937233,
      4.437966101694915,
      4.71
    ],
    "volume": 15756582927
  },
  {
    "ts": 1626912000,
    "supply": 19629264368367,
    "trustlines": {
      "total": 44366,
      "authorized": 44366,
      "funded": 2573
    },
    "payments": 14,
    "payments_amount": 8211937106,
    "trades": 460,
    "traded_amount": 60836609293,
    "price": [
      4.71,
      4.71,
      3.8399726838151604,
      3.8399726838151604
    ],
    "volume": 67380532026
  },
  {
    "ts": 1626998400,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44376,
      "authorized": 44376,
      "funded": 2574
    },
    "payments": 9,
    "payments_amount": 332832060551,
    "trades": 141,
    "traded_amount": 29098608150,
    "price": [
      3.8399726838151604,
      4.510000036210944,
      3.722999997290678,
      3.7599999877161503
    ],
    "volume": 8865882328
  },
  {
    "ts": 1627084800,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44388,
      "authorized": 44388,
      "funded": 2575
    },
    "payments": 5,
    "payments_amount": 1519722920,
    "trades": 66,
    "traded_amount": 26171417555,
    "price": [
      3.7599999877161503,
      3.7650615612853913,
      3.7599999877161503,
      3.7649746192893403
    ],
    "volume": 26260930
  },
  {
    "ts": 1627171200,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44392,
      "authorized": 44392,
      "funded": 2576
    },
    "payments": 9,
    "payments_amount": 2782725275,
    "trades": 164,
    "traded_amount": 138591119854,
    "price": [
      3.7649746192893403,
      4.28999877,
      3.7598966742008395,
      4.175000869990457
    ],
    "volume": 360513911703
  },
  {
    "ts": 1627257600,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44399,
      "authorized": 44399,
      "funded": 2576
    },
    "payments": 10,
    "payments_amount": 1425509350,
    "trades": 76,
    "traded_amount": 38517854820,
    "price": [
      4.175000869990457,
      4.175000869990457,
      3.7222222222222223,
      3.7453393002159467
    ],
    "volume": 34541929722
  },
  {
    "ts": 1627344000,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44406,
      "authorized": 44406,
      "funded": 2578
    },
    "payments": 5,
    "payments_amount": 2207196194,
    "trades": 66,
    "traded_amount": 27367540831,
    "price": [
      3.7453393002159467,
      4.289998835362132,
      3.717948717948718,
      4.27000002424267
    ],
    "volume": 5536703556
  },
  {
    "ts": 1627430400,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44418,
      "authorized": 44418,
      "funded": 2579
    },
    "payments": 3,
    "payments_amount": 2445116778,
    "trades": 249,
    "traded_amount": 36725709665,
    "price": [
      4.27000002424267,
      4.27000002424267,
      3.5833333333333335,
      3.6977432778489114
    ],
    "volume": 15519150780
  },
  {
    "ts": 1627516800,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44424,
      "authorized": 44424,
      "funded": 2580
    },
    "payments": 13,
    "payments_amount": 14700839606,
    "trades": 65,
    "traded_amount": 34651804336,
    "price": [
      3.6977432778489114,
      3.8713,
      3.5529999987065284,
      3.5529999987065284
    ],
    "volume": 10630407819
  },
  {
    "ts": 1627603200,
    "supply": 19297859568367,
    "trustlines": {
      "total": 44429,
      "authorized": 44429,
      "funded": 2576
    },
    "payments": 7,
    "payments_amount": 3340335114,
    "trades": 119,
    "traded_amount": 46452217753,
    "price": [
      3.5529999987065284,
      3.8780487804878048,
      3.5529999987065284,
      3.5559204429909044
    ],
    "volume": 1553119987
  },
  {
    "ts": 1627689600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44435,
      "authorized": 44435,
      "funded": 2578
    },
    "payments": 8,
    "payments_amount": 2232678536,
    "trades": 43,
    "traded_amount": 5336087651,
    "price": [
      3.5559204429909044,
      3.8,
      3.552998372540038,
      3.760167568511084
    ],
    "volume": 789117837
  },
  {
    "ts": 1627776000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44442,
      "authorized": 44442,
      "funded": 2581
    },
    "payments": 7,
    "payments_amount": 858010145,
    "trades": 197,
    "traded_amount": 47589767080,
    "price": [
      3.760167568511084,
      3.760167568511084,
      3.419983948635634,
      3.72
    ],
    "volume": 2047286006
  },
  {
    "ts": 1627862400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44448,
      "authorized": 44448,
      "funded": 2580
    },
    "payments": 6,
    "payments_amount": 3338191231,
    "trades": 104,
    "traded_amount": 39571575624,
    "price": [
      3.72,
      3.720002305242987,
      3.502430961020096,
      3.502430961020096
    ],
    "volume": 3572161601
  },
  {
    "ts": 1627948800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44458,
      "authorized": 44458,
      "funded": 2581
    },
    "payments": 12,
    "payments_amount": 11514017194,
    "trades": 108,
    "traded_amount": 70057474974,
    "price": [
      3.502430961020096,
      3.7599992509591216,
      3.502430961020096,
      3.6222222222222222
    ],
    "volume": 24012603286
  },
  {
    "ts": 1628035200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44472,
      "authorized": 44472,
      "funded": 2585
    },
    "payments": 12,
    "payments_amount": 2809844093,
    "trades": 85,
    "traded_amount": 27709613764,
    "price": [
      3.6222222222222222,
      3.8200005339390852,
      3.6219999988174494,
      3.639999693200214
    ],
    "volume": 3910369844
  },
  {
    "ts": 1628121600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44485,
      "authorized": 44485,
      "funded": 2586
    },
    "payments": 7,
    "payments_amount": 3996385147,
    "trades": 73,
    "traded_amount": 55993729435,
    "price": [
      3.639999693200214,
      3.785000083807404,
      3.622,
      3.785
    ],
    "volume": 7491723927
  },
  {
    "ts": 1628208000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44484,
      "authorized": 44484,
      "funded": 2586
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 122,
    "traded_amount": 56440664723,
    "price": [
      3.785,
      3.785,
      3.6099935153829525,
      3.6099999738648942
    ],
    "volume": 17099452486
  },
  {
    "ts": 1628294400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44496,
      "authorized": 44496,
      "funded": 2586
    },
    "payments": 3,
    "payments_amount": 296782141,
    "trades": 155,
    "traded_amount": 62539608261,
    "price": [
      3.6099999738648942,
      3.76200000410058,
      3.393999460306289,
      3.3940000499090006
    ],
    "volume": 18497646288
  },
  {
    "ts": 1628380800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44502,
      "authorized": 44502,
      "funded": 2588
    },
    "payments": 6,
    "payments_amount": 5675263512,
    "trades": 115,
    "traded_amount": 55703246774,
    "price": [
      3.3940000499090006,
      3.5100000106000007,
      2.999897002780925,
      3.5100000106000007
    ],
    "volume": 15158136135
  },
  {
    "ts": 1628467200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44519,
      "authorized": 44519,
      "funded": 2590
    },
    "payments": 5,
    "payments_amount": 446406995,
    "trades": 214,
    "traded_amount": 73927679063,
    "price": [
      3.5100000106000007,
      3.63265306122449,
      3.1793960923623446,
      3.222533331079775
    ],
    "volume": 16014337103
  },
  {
    "ts": 1628553600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44536,
      "authorized": 44536,
      "funded": 2591
    },
    "payments": 8,
    "payments_amount": 6107119283,
    "trades": 220,
    "traded_amount": 98018709553,
    "price": [
      3.222533331079775,
      3.448275890831362,
      3.068000387642147,
      3.250117348203455
    ],
    "volume": 57271579411
  },
  {
    "ts": 1628640000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44550,
      "authorized": 44550,
      "funded": 2589
    },
    "payments": 7,
    "payments_amount": 5015968098,
    "trades": 207,
    "traded_amount": 37336514571,
    "price": [
      3.250117348203455,
      3.4400148711642404,
      2.9199999963931984,
      2.9199999963931984
    ],
    "volume": 14149507752
  },
  {
    "ts": 1628726400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44561,
      "authorized": 44561,
      "funded": 2587
    },
    "payments": 11,
    "payments_amount": 2012630504,
    "trades": 90,
    "traded_amount": 71799143799,
    "price": [
      2.9199999963931984,
      3.199999904,
      2.9199999963931984,
      3.142086900959559
    ],
    "volume": 9240601775
  },
  {
    "ts": 1628812800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44579,
      "authorized": 44579,
      "funded": 2591
    },
    "payments": 15,
    "payments_amount": 732906851,
    "trades": 195,
    "traded_amount": 78448519540,
    "price": [
      3.142086900959559,
      3.157409314374836,
      2.8549532710280374,
      2.9604401194572803
    ],
    "volume": 17881952951
  },
  {
    "ts": 1628899200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44604,
      "authorized": 44604,
      "funded": 2595
    },
    "payments": 11,
    "payments_amount": 4446755693,
    "trades": 202,
    "traded_amount": 46891164584,
    "price": [
      2.9604401194572803,
      2.980000027416001,
      2.594976951200127,
      2.594976951200127
    ],
    "volume": 27581650534
  },
  {
    "ts": 1628985600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44622,
      "authorized": 44622,
      "funded": 2602
    },
    "payments": 13,
    "payments_amount": 4740570630,
    "trades": 155,
    "traded_amount": 74656063722,
    "price": [
      2.594976951200127,
      2.8727272727272726,
      2.51004,
      2.75000003553083
    ],
    "volume": 10323461144
  },
  {
    "ts": 1629072000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44634,
      "authorized": 44634,
      "funded": 2600
    },
    "payments": 25,
    "payments_amount": 13976620860,
    "trades": 200,
    "traded_amount": 122579628830,
    "price": [
      2.75000003553083,
      2.75000003553083,
      2.507936507936508,
      2.6427921487066164
    ],
    "volume": 64978794027
  },
  {
    "ts": 1629158400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44652,
      "authorized": 44652,
      "funded": 2599
    },
    "payments": 30,
    "payments_amount": 33054310849,
    "trades": 455,
    "traded_amount": 204985568392,
    "price": [
      2.6427921487066164,
      3.174603209296838,
      2.2222222217930745,
      2.9100001523088888
    ],
    "volume": 174396646134
  },
  {
    "ts": 1629244800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44663,
      "authorized": 44663,
      "funded": 2600
    },
    "payments": 28,
    "payments_amount": 12209438057,
    "trades": 63,
    "traded_amount": 47060839056,
    "price": [
      2.9100001523088888,
      2.9521285826803187,
      2.5420008308400166,
      2.7100006597489026
    ],
    "volume": 16025582171
  },
  {
    "ts": 1629331200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44673,
      "authorized": 44673,
      "funded": 2599
    },
    "payments": 11,
    "payments_amount": 3561484898,
    "trades": 83,
    "traded_amount": 74077614236,
    "price": [
      2.7100006597489026,
      2.952127509874375,
      2.7100006597489026,
      2.7349999954521285
    ],
    "volume": 8721392949
  },
  {
    "ts": 1629417600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44690,
      "authorized": 44690,
      "funded": 2599
    },
    "payments": 9,
    "payments_amount": 3547025094,
    "trades": 128,
    "traded_amount": 62580395221,
    "price": [
      2.7349999954521285,
      2.882000026104574,
      2.660792038150529,
      2.660792038150529
    ],
    "volume": 15183018739
  },
  {
    "ts": 1629504000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44695,
      "authorized": 44695,
      "funded": 2599
    },
    "payments": 4,
    "payments_amount": 335924724,
    "trades": 98,
    "traded_amount": 15889910580,
    "price": [
      2.660792038150529,
      2.8820000046309007,
      2.6607806691449816,
      2.6965681467856317
    ],
    "volume": 3407301619
  },
  {
    "ts": 1629590400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44709,
      "authorized": 44709,
      "funded": 2601
    },
    "payments": 1,
    "payments_amount": 36365374,
    "trades": 65,
    "traded_amount": 55188532351,
    "price": [
      2.6965681467856317,
      3.002241200415126,
      2.6609836387756105,
      2.671999996105438
    ],
    "volume": 13904980884
  },
  {
    "ts": 1629676800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44718,
      "authorized": 44718,
      "funded": 2600
    },
    "payments": 7,
    "payments_amount": 6300391109,
    "trades": 154,
    "traded_amount": 65527611643,
    "price": [
      2.671999996105438,
      2.900000046434327,
      2.529999989712151,
      2.740701400327702
    ],
    "volume": 56840091358
  },
  {
    "ts": 1629763200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44732,
      "authorized": 44732,
      "funded": 2598
    },
    "payments": 23,
    "payments_amount": 150744681223,
    "trades": 111,
    "traded_amount": 143960410534,
    "price": [
      2.740701400327702,
      2.7918853101226673,
      2.4899999986145076,
      2.791885300135565
    ],
    "volume": 221790231565
  },
  {
    "ts": 1629849600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44752,
      "authorized": 44752,
      "funded": 2600
    },
    "payments": 24,
    "payments_amount": 51584642031,
    "trades": 141,
    "traded_amount": 37217532673,
    "price": [
      2.791885300135565,
      2.9002320185614847,
      2.6097852028639617,
      2.8727272727272726
    ],
    "volume": 42175397653
  },
  {
    "ts": 1629936000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44762,
      "authorized": 44762,
      "funded": 2594
    },
    "payments": 28,
    "payments_amount": 25334449272,
    "trades": 99,
    "traded_amount": 66139724046,
    "price": [
      2.8727272727272726,
      2.9412,
      2.6255558221484754,
      2.727272727272727
    ],
    "volume": 40043082134
  },
  {
    "ts": 1630022400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44777,
      "authorized": 44777,
      "funded": 2596
    },
    "payments": 22,
    "payments_amount": 19623251227,
    "trades": 83,
    "traded_amount": 36370278454,
    "price": [
      2.727272727272727,
      2.960000013884264,
      2.7078052034689795,
      2.8080006361504544
    ],
    "volume": 36380652062
  },
  {
    "ts": 1630108800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44783,
      "authorized": 44783,
      "funded": 2598
    },
    "payments": 11,
    "payments_amount": 4730502088,
    "trades": 70,
    "traded_amount": 75063526691,
    "price": [
      2.8080006361504544,
      2.972000229601605,
      2.6700130820664034,
      2.972000229601605
    ],
    "volume": 14058334167
  },
  {
    "ts": 1630195200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44801,
      "authorized": 44801,
      "funded": 2597
    },
    "payments": 5,
    "payments_amount": 729957807,
    "trades": 34,
    "traded_amount": 25209198623,
    "price": [
      2.972000229601605,
      2.972000229601605,
      2.670000245068657,
      2.7799442896935935
    ],
    "volume": 989538006
  },
  {
    "ts": 1630281600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44806,
      "authorized": 44806,
      "funded": 2606
    },
    "payments": 12,
    "payments_amount": 110822837949,
    "trades": 215,
    "traded_amount": 135368503004,
    "price": [
      2.7799442896935935,
      2.9850746409473197,
      1.0000000999806933,
      2.892935233355953
    ],
    "volume": 156992171183
  },
  {
    "ts": 1630368000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44814,
      "authorized": 44814,
      "funded": 2612
    },
    "payments": 33,
    "payments_amount": 16744708481,
    "trades": 91,
    "traded_amount": 55555965530,
    "price": [
      2.892935233355953,
      3.0010357327809425,
      2.8320006826971045,
      3.0010357327809425
    ],
    "volume": 32394714908
  },
  {
    "ts": 1630454400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44833,
      "authorized": 44833,
      "funded": 2615
    },
    "payments": 24,
    "payments_amount": 20065285604,
    "trades": 96,
    "traded_amount": 75660997736,
    "price": [
      3.0010357327809425,
      3.0161963841418338,
      2.7777778999602187,
      3.0161963841418338
    ],
    "volume": 49178753073
  },
  {
    "ts": 1630540800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44849,
      "authorized": 44849,
      "funded": 2617
    },
    "payments": 9,
    "payments_amount": 12512394890,
    "trades": 161,
    "traded_amount": 58668068624,
    "price": [
      3.0161963841418338,
      3.018018018018018,
      2.6666687922478234,
      3.017188318412331
    ],
    "volume": 6653752640
  },
  {
    "ts": 1630627200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44866,
      "authorized": 44866,
      "funded": 2620
    },
    "payments": 8,
    "payments_amount": 8312687874,
    "trades": 163,
    "traded_amount": 56174738544,
    "price": [
      3.017188318412331,
      3.017188318412331,
      2.6493506493506493,
      2.758618325160569
    ],
    "volume": 9145426185
  },
  {
    "ts": 1630713600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44879,
      "authorized": 44879,
      "funded": 2624
    },
    "payments": 8,
    "payments_amount": 8783304330,
    "trades": 237,
    "traded_amount": 31246641685,
    "price": [
      2.758618325160569,
      2.772727272727273,
      2.6319950732224204,
      2.7016736142546756
    ],
    "volume": 2561561350
  },
  {
    "ts": 1630800000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44893,
      "authorized": 44893,
      "funded": 2624
    },
    "payments": 16,
    "payments_amount": 2580355845,
    "trades": 229,
    "traded_amount": 57092952175,
    "price": [
      2.7016736142546756,
      2.7360070826412612,
      2.5155335,
      2.5316455313186434
    ],
    "volume": 14498588095
  },
  {
    "ts": 1630886400,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44903,
      "authorized": 44903,
      "funded": 2626
    },
    "payments": 22,
    "payments_amount": 264532830935,
    "trades": 325,
    "traded_amount": 147381547262,
    "price": [
      2.5316455313186434,
      2.702702733219159,
      1.408450704118833,
      2.3501762648601408
    ],
    "volume": 149234186892
  },
  {
    "ts": 1630972800,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44924,
      "authorized": 44924,
      "funded": 2629
    },
    "payments": 42,
    "payments_amount": 58071368892,
    "trades": 590,
    "traded_amount": 153611734009,
    "price": [
      2.3501762648601408,
      3.1000000173805686,
      2.222139673105498,
      2.8985589971433856
    ],
    "volume": 114624005382
  },
  {
    "ts": 1631059200,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44936,
      "authorized": 44936,
      "funded": 2628
    },
    "payments": 28,
    "payments_amount": 18716408171,
    "trades": 170,
    "traded_amount": 105625519307,
    "price": [
      2.8985589971433856,
      3.199999910400002,
      2.857143696954485,
      2.9154547992222426
    ],
    "volume": 28956551050
  },
  {
    "ts": 1631145600,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44955,
      "authorized": 44955,
      "funded": 2632
    },
    "payments": 29,
    "payments_amount": 6661820953,
    "trades": 139,
    "traded_amount": 89724677612,
    "price": [
      2.9154547992222426,
      3.125,
      2.7888873018140266,
      2.868181818181818
    ],
    "volume": 18464388399
  },
  {
    "ts": 1631232000,
    "supply": 19297859568296,
    "trustlines": {
      "total": 44948,
      "authorized": 44948,
      "funded": 2629
    },
    "payments": 31,
    "payments_amount": 3715726007,
    "trades": 189,
    "traded_amount": 95721848101,
    "price": [
      2.868181818181818,
      3.1783726630177087,
      2.850645823076623,
      3.1783726630177087
    ],
    "volume": 30742174452
  },
  {
    "ts": 1631318400,
    "supply": 19297859568292,
    "trustlines": {
      "total": 44955,
      "authorized": 44955,
      "funded": 2628
    },
    "payments": 12,
    "payments_amount": 2875400691,
    "trades": 79,
    "traded_amount": 113583454930,
    "price": [
      3.1783726630177087,
      3.19632040569209,
      2.8425587861116535,
      2.9221556886227544
    ],
    "volume": 13605119338
  },
  {
    "ts": 1631404800,
    "supply": 19297859568292,
    "trustlines": {
      "total": 44969,
      "authorized": 44969,
      "funded": 2630
    },
    "payments": 11,
    "payments_amount": 647946658,
    "trades": 60,
    "traded_amount": 85659035363,
    "price": [
      2.9221556886227544,
      3.1000000154999996,
      2.915743291574329,
      2.9546548
    ],
    "volume": 3268725652
  },
  {
    "ts": 1631491200,
    "supply": 19297859568292,
    "trustlines": {
      "total": 44975,
      "authorized": 44975,
      "funded": 2630
    },
    "payments": 24,
    "payments_amount": 4707880557,
    "trades": 82,
    "traded_amount": 50877512223,
    "price": [
      2.9546548,
      3.100126742712294,
      2.909999997833524,
      3.0999999756898076
    ],
    "volume": 7857586756
  },
  {
    "ts": 1631577600,
    "supply": 19297859568292,
    "trustlines": {
      "total": 44986,
      "authorized": 44986,
      "funded": 2633
    },
    "payments": 9,
    "payments_amount": 403415028,
    "trades": 36,
    "traded_amount": 24553880260,
    "price": [
      3.0999999756898076,
      3.100000004787782,
      2.980952380952381,
      2.999208462408663
    ],
    "volume": 1822382441
  },
  {
    "ts": 1631664000,
    "supply": 19297859568292,
    "trustlines": {
      "total": 44997,
      "authorized": 44997,
      "funded": 2634
    },
    "payments": 15,
    "payments_amount": 1971392007,
    "trades": 135,
    "traded_amount": 187264724618,
    "price": [
      2.999208462408663,
      3.192388700146749,
      2.8366553928108464,
      3.192388700146749
    ],
    "volume": 31888833667
  },
  {
    "ts": 1631750400,
    "supply": 19297859568292,
    "trustlines": {
      "total": 45010,
      "authorized": 45010,
      "funded": 2633
    },
    "payments": 33,
    "payments_amount": 4514623683,
    "trades": 81,
    "traded_amount": 52066412801,
    "price": [
      3.192388700146749,
      3.192388700146749,
      2.8636363636363638,
      3.048644544533138
    ],
    "volume": 2615604895
  },
  {
    "ts": 1631836800,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45011,
      "authorized": 45011,
      "funded": 2633
    },
    "payments": 21,
    "payments_amount": 20932333940,
    "trades": 144,
    "traded_amount": 112949890204,
    "price": [
      3.048644544533138,
      3.366992048699965,
      2.912004182,
      3.155575288368007
    ],
    "volume": 31868823822
  },
  {
    "ts": 1631923200,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45020,
      "authorized": 45020,
      "funded": 2634
    },
    "payments": 12,
    "payments_amount": 1993111567,
    "trades": 34,
    "traded_amount": 61630304739,
    "price": [
      3.155575288368007,
      3.2182611863540576,
      3.155575288368007,
      3.2182611863540576
    ],
    "volume": 100000000
  },
  {
    "ts": 1632009600,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45026,
      "authorized": 45026,
      "funded": 2634
    },
    "payments": 20,
    "payments_amount": 23399580680,
    "trades": 101,
    "traded_amount": 97709394735,
    "price": [
      3.2182611863540576,
      3.2200037553983853,
      3.1349996535825366,
      3.1386017700322126
    ],
    "volume": 977928895
  },
  {
    "ts": 1632096000,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45035,
      "authorized": 45035,
      "funded": 2637
    },
    "payments": 41,
    "payments_amount": 13523241715,
    "trades": 139,
    "traded_amount": 93538668793,
    "price": [
      3.1386017700322126,
      3.500001054635998,
      3.1386017700322126,
      3.44863569734049
    ],
    "volume": 45524852518
  },
  {
    "ts": 1632182400,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45043,
      "authorized": 45043,
      "funded": 2639
    },
    "payments": 14,
    "payments_amount": 3767677557,
    "trades": 85,
    "traded_amount": 56070218498,
    "price": [
      3.44863569734049,
      3.7600000094202963,
      3.390185896675089,
      3.6508572381039577
    ],
    "volume": 10443251753
  },
  {
    "ts": 1632268800,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45055,
      "authorized": 45055,
      "funded": 2640
    },
    "payments": 14,
    "payments_amount": 3909044464,
    "trades": 143,
    "traded_amount": 128384147927,
    "price": [
      3.6508572381039577,
      3.6508572381039577,
      3.3779999134536327,
      3.3779999134536327
    ],
    "volume": 19009556877
  },
  {
    "ts": 1632355200,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45060,
      "authorized": 45060,
      "funded": 2643
    },
    "payments": 16,
    "payments_amount": 22884127322,
    "trades": 151,
    "traded_amount": 110299996076,
    "price": [
      3.3779999134536327,
      3.5943591044776118,
      2.871,
      3.3963486040115294
    ],
    "volume": 20842303253
  },
  {
    "ts": 1632441600,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45066,
      "authorized": 45066,
      "funded": 2645
    },
    "payments": 17,
    "payments_amount": 5355737687,
    "trades": 140,
    "traded_amount": 123305540983,
    "price": [
      3.3963486040115294,
      3.608000006001174,
      3.373722627737226,
      3.607833154168339
    ],
    "volume": 17117165549
  },
  {
    "ts": 1632528000,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45079,
      "authorized": 45079,
      "funded": 2648
    },
    "payments": 15,
    "payments_amount": 2310109431,
    "trades": 47,
    "traded_amount": 44168590337,
    "price": [
      3.607833154168339,
      3.627486413117099,
      3.5939837,
      3.627486413117099
    ],
    "volume": 1769908939
  },
  {
    "ts": 1632614400,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45085,
      "authorized": 45085,
      "funded": 2647
    },
    "payments": 11,
    "payments_amount": 7703104987,
    "trades": 240,
    "traded_amount": 80470310487,
    "price": [
      3.627486413117099,
      3.71,
      3.4239999960643663,
      3.4239999960643663
    ],
    "volume": 16454537657
  },
  {
    "ts": 1632700800,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45096,
      "authorized": 45096,
      "funded": 2645
    },
    "payments": 22,
    "payments_amount": 4079452694,
    "trades": 70,
    "traded_amount": 37494474700,
    "price": [
      3.4239999960643663,
      3.750000005903141,
      3.4239999960643663,
      3.750000005903141
    ],
    "volume": 6631920470
  },
  {
    "ts": 1632787200,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45103,
      "authorized": 45103,
      "funded": 2649
    },
    "payments": 12,
    "payments_amount": 8550244684,
    "trades": 78,
    "traded_amount": 77418540768,
    "price": [
      3.750000005903141,
      3.8999993206627406,
      3.5,
      3.67999999882439
    ],
    "volume": 5290783038
  },
  {
    "ts": 1632873600,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45109,
      "authorized": 45109,
      "funded": 2648
    },
    "payments": 21,
    "payments_amount": 9513931851,
    "trades": 111,
    "traded_amount": 112554547696,
    "price": [
      3.67999999882439,
      3.9081632653061225,
      3.5709514949924928,
      3.5709514949924928
    ],
    "volume": 15032493293
  },
  {
    "ts": 1632960000,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45115,
      "authorized": 45115,
      "funded": 2652
    },
    "payments": 25,
    "payments_amount": 1009760082,
    "trades": 82,
    "traded_amount": 36274250136,
    "price": [
      3.5709514949924928,
      3.9100000107525,
      3.569991526712582,
      3.57963625
    ],
    "volume": 7300199879
  },
  {
    "ts": 1633046400,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45124,
      "authorized": 45124,
      "funded": 2654
    },
    "payments": 11,
    "payments_amount": 7811312536,
    "trades": 466,
    "traded_amount": 51304873183,
    "price": [
      3.57963625,
      3.8393296,
      3.3102386606028897,
      3.3102388975510197
    ],
    "volume": 16539109556
  },
  {
    "ts": 1633132800,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45132,
      "authorized": 45132,
      "funded": 2655
    },
    "payments": 18,
    "payments_amount": 1089714912,
    "trades": 89,
    "traded_amount": 37767229748,
    "price": [
      3.3102388975510197,
      3.5969235781439077,
      3.15,
      3.168000003258249
    ],
    "volume": 3452908329
  },
  {
    "ts": 1633219200,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45144,
      "authorized": 45144,
      "funded": 2658
    },
    "payments": 18,
    "payments_amount": 29641239310,
    "trades": 113,
    "traded_amount": 107340361476,
    "price": [
      3.168000003258249,
      3.2383420077383787,
      2.5706940903857873,
      3.2383419758461884
    ],
    "volume": 36539318306
  },
  {
    "ts": 1633305600,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45154,
      "authorized": 45154,
      "funded": 2655
    },
    "payments": 15,
    "payments_amount": 24918209711,
    "trades": 139,
    "traded_amount": 88205918414,
    "price": [
      3.2383419758461884,
      3.5984283592002466,
      2.5040491,
      3.100298691857643
    ],
    "volume": 9722590306
  },
  {
    "ts": 1633392000,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45168,
      "authorized": 45168,
      "funded": 2657
    },
    "payments": 16,
    "payments_amount": 18818939287,
    "trades": 158,
    "traded_amount": 79284546510,
    "price": [
      3.100298691857643,
      3.224400871459695,
      3,
      3.100297426900585
    ],
    "volume": 4076090623
  },
  {
    "ts": 1633478400,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45185,
      "authorized": 45185,
      "funded": 2661
    },
    "payments": 24,
    "payments_amount": 42553425004,
    "trades": 109,
    "traded_amount": 34962508597,
    "price": [
      3.100297426900585,
      3.4404761904761907,
      2.7999999746983315,
      2.9999000073648254
    ],
    "volume": 8593344572
  },
  {
    "ts": 1633564800,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45194,
      "authorized": 45194,
      "funded": 2662
    },
    "payments": 21,
    "payments_amount": 13192678802,
    "trades": 72,
    "traded_amount": 72192194780,
    "price": [
      2.9999000073648254,
      2.9999000073648254,
      2.7032470077117035,
      2.9220003306331623
    ],
    "volume": 1825347990
  },
  {
    "ts": 1633651200,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45214,
      "authorized": 45214,
      "funded": 2660
    },
    "payments": 18,
    "payments_amount": 18116574220,
    "trades": 108,
    "traded_amount": 75450343097,
    "price": [
      2.9220003306331623,
      2.999900004093109,
      2.745264371602701,
      2.8534976916594723
    ],
    "volume": 3543128065
  },
  {
    "ts": 1633737600,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45238,
      "authorized": 45238,
      "funded": 2661
    },
    "payments": 35,
    "payments_amount": 2977152768,
    "trades": 59,
    "traded_amount": 24252777066,
    "price": [
      2.8534976916594723,
      2.926056338028169,
      2.7027025714285715,
      2.7027025714285715
    ],
    "volume": 9020813706
  },
  {
    "ts": 1633824000,
    "supply": 19297859568281,
    "trustlines": {
      "total": 45247,
      "authorized": 45247,
      "funded": 2661
    },
    "payments": 22,
    "payments_amount": 41491727927,
    "trades": 89,
    "traded_amount": 60929014423,
    "price": [
      2.7027025714285715,
      2.9585798861861914,
      2.701657458563536,
      2.9418000115575476
    ],
    "volume": 19523088838
  },
  {
    "ts": 1633910400,
    "supply": 19297859568272,
    "trustlines": {
      "total": 45255,
      "authorized": 45255,
      "funded": 2662
    },
    "payments": 13,
    "payments_amount": 21720041489,
    "trades": 113,
    "traded_amount": 83340447917,
    "price": [
      2.9418000115575476,
      2.972000070103499,
      2.795999999617173,
      2.807999973632949
    ],
    "volume": 7319805377
  },
  {
    "ts": 1633996800,
    "supply": 19297859568272,
    "trustlines": {
      "total": 45261,
      "authorized": 45261,
      "funded": 2661
    },
    "payments": 9,
    "payments_amount": 931139061,
    "trades": 126,
    "traded_amount": 27142736865,
    "price": [
      2.807999973632949,
      3.0468791,
      2.801994183631076,
      3.0346570397111914
    ],
    "volume": 3699957796
  },
  {
    "ts": 1634083200,
    "supply": 19297859568272,
    "trustlines": {
      "total": 45271,
      "authorized": 45271,
      "funded": 2665
    },
    "payments": 12,
    "payments_amount": 3614961960,
    "trades": 343,
    "traded_amount": 48881940368,
    "price": [
      3.0346570397111914,
      3.1290000087237764,
      2.73299997717945,
      2.7731054352400375
    ],
    "volume": 10758437521
  },
  {
    "ts": 1634169600,
    "supply": 19297859568272,
    "trustlines": {
      "total": 45286,
      "authorized": 45286,
      "funded": 2663
    },
    "payments": 25,
    "payments_amount": 21115193051,
    "trades": 150,
    "traded_amount": 60054722225,
    "price": [
      2.7731054352400375,
      2.9125595101858024,
      2.6151991711282863,
      2.8568697242209176
    ],
    "volume": 7198782201
  },
  {
    "ts": 1634256000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45293,
      "authorized": 45293,
      "funded": 2662
    },
    "payments": 19,
    "payments_amount": 5442660255,
    "trades": 205,
    "traded_amount": 64667921922,
    "price": [
      2.8568697242209176,
      2.8568697242209176,
      2.5072570273746098,
      2.6709646143782835
    ],
    "volume": 4018525152
  },
  {
    "ts": 1634342400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45300,
      "authorized": 45300,
      "funded": 2661
    },
    "payments": 15,
    "payments_amount": 5849728022,
    "trades": 171,
    "traded_amount": 40569842315,
    "price": [
      2.6709646143782835,
      2.691729419104155,
      2.2597065973742083,
      2.5903285487351515
    ],
    "volume": 16990449878
  },
  {
    "ts": 1634428800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45306,
      "authorized": 45306,
      "funded": 2663
    },
    "payments": 40,
    "payments_amount": 3744257866,
    "trades": 97,
    "traded_amount": 61721166210,
    "price": [
      2.5903285487351515,
      2.6914937324886297,
      2.2802192084961295,
      2.660281546316557
    ],
    "volume": 8926579507
  },
  {
    "ts": 1634515200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45315,
      "authorized": 45315,
      "funded": 2664
    },
    "payments": 34,
    "payments_amount": 5149933005,
    "trades": 99,
    "traded_amount": 28898374909,
    "price": [
      2.660281546316557,
      2.6602837595617377,
      2.2813370473537606,
      2.2946832072241694
    ],
    "volume": 7477014685
  },
  {
    "ts": 1634601600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45320,
      "authorized": 45320,
      "funded": 2660
    },
    "payments": 24,
    "payments_amount": 10299152340,
    "trades": 247,
    "traded_amount": 52062481529,
    "price": [
      2.2946832072241694,
      2.738154403451449,
      2.294675014732839,
      2.738154403451449
    ],
    "volume": 26559371807
  },
  {
    "ts": 1634688000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45331,
      "authorized": 45331,
      "funded": 2664
    },
    "payments": 18,
    "payments_amount": 935745998,
    "trades": 164,
    "traded_amount": 49727324272,
    "price": [
      2.738154403451449,
      2.75,
      2.510241778005704,
      2.510241778005704
    ],
    "volume": 9859966666
  },
  {
    "ts": 1634774400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45333,
      "authorized": 45333,
      "funded": 2663
    },
    "payments": 14,
    "payments_amount": 2579748056,
    "trades": 30,
    "traded_amount": 4453112139,
    "price": [
      2.510241778005704,
      2.6965706033237775,
      2.510241778005704,
      2.6965706033237775
    ],
    "volume": 2009703943
  },
  {
    "ts": 1634860800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45332,
      "authorized": 45332,
      "funded": 2661
    },
    "payments": 23,
    "payments_amount": 2483972310,
    "trades": 70,
    "traded_amount": 23709371249,
    "price": [
      2.6965706033237775,
      2.75,
      2.510846745976207,
      2.725194901397276
    ],
    "volume": 2870800934
  },
  {
    "ts": 1634947200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45331,
      "authorized": 45331,
      "funded": 2661
    },
    "payments": 30,
    "payments_amount": 16807087799,
    "trades": 125,
    "traded_amount": 84755852607,
    "price": [
      2.725194901397276,
      2.750219804598627,
      2.518181818181818,
      2.5525591
    ],
    "volume": 5070946604
  },
  {
    "ts": 1635033600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45343,
      "authorized": 45343,
      "funded": 2660
    },
    "payments": 28,
    "payments_amount": 6768724169,
    "trades": 63,
    "traded_amount": 44590128306,
    "price": [
      2.5525591,
      2.7559055118110236,
      2.5398920002425807,
      2.6699029126213594
    ],
    "volume": 4022361077
  },
  {
    "ts": 1635120000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45347,
      "authorized": 45347,
      "funded": 2662
    },
    "payments": 20,
    "payments_amount": 2312566149,
    "trades": 187,
    "traded_amount": 62404934439,
    "price": [
      2.6699029126213594,
      2.6699029126213594,
      2.392548783351145,
      2.3925488978642995
    ],
    "volume": 11422350479
  },
  {
    "ts": 1635206400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45346,
      "authorized": 45346,
      "funded": 2662
    },
    "payments": 24,
    "payments_amount": 1806790262,
    "trades": 125,
    "traded_amount": 21050461047,
    "price": [
      2.3925488978642995,
      2.6400000007659243,
      2.3925488978642995,
      2.5652546
    ],
    "volume": 5720833905
  },
  {
    "ts": 1635292800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45364,
      "authorized": 45364,
      "funded": 2660
    },
    "payments": 18,
    "payments_amount": 7644196169,
    "trades": 306,
    "traded_amount": 56790434472,
    "price": [
      2.5652546,
      2.9426936911496244,
      2.5652546,
      2.9426936911496244
    ],
    "volume": 42869933205
  },
  {
    "ts": 1635379200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45379,
      "authorized": 45379,
      "funded": 2655
    },
    "payments": 15,
    "payments_amount": 15310877217,
    "trades": 162,
    "traded_amount": 41438933216,
    "price": [
      2.9426936911496244,
      2.9426936911496244,
      2.57960897382911,
      2.7622872971476835
    ],
    "volume": 35577414109
  },
  {
    "ts": 1635465600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45386,
      "authorized": 45386,
      "funded": 2656
    },
    "payments": 16,
    "payments_amount": 7178369644,
    "trades": 96,
    "traded_amount": 34286070054,
    "price": [
      2.7622872971476835,
      2.931000004058038,
      2.5806451612903225,
      2.9097222222222223
    ],
    "volume": 9858851440
  },
  {
    "ts": 1635552000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45392,
      "authorized": 45392,
      "funded": 2656
    },
    "payments": 7,
    "payments_amount": 12819226845,
    "trades": 62,
    "traded_amount": 35150205195,
    "price": [
      2.9097222222222223,
      2.9097222222222223,
      2.677088194120392,
      2.7034553899182656
    ],
    "volume": 450577139
  },
  {
    "ts": 1635638400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45395,
      "authorized": 45395,
      "funded": 2654
    },
    "payments": 8,
    "payments_amount": 2320801915,
    "trades": 115,
    "traded_amount": 16912159712,
    "price": [
      2.7034553899182656,
      2.8378292,
      2.601999996770574,
      2.644431865198563
    ],
    "volume": 9975057620
  },
  {
    "ts": 1635724800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45404,
      "authorized": 45404,
      "funded": 2651
    },
    "payments": 16,
    "payments_amount": 278810362,
    "trades": 127,
    "traded_amount": 1194378258,
    "price": [
      2.644431865198563,
      2.79092928710565,
      2.602995992175503,
      2.7786981157050725
    ],
    "volume": 1019947187
  },
  {
    "ts": 1635811200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45407,
      "authorized": 45407,
      "funded": 2652
    },
    "payments": 15,
    "payments_amount": 1565928077,
    "trades": 61,
    "traded_amount": 3172097038,
    "price": [
      2.7786981157050725,
      2.8154295058292886,
      2.5964912280701755,
      2.8154295036764707
    ],
    "volume": 2492499956
  },
  {
    "ts": 1635897600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45418,
      "authorized": 45418,
      "funded": 2651
    },
    "payments": 18,
    "payments_amount": 2023611309,
    "trades": 178,
    "traded_amount": 10498457471,
    "price": [
      2.8154295036764707,
      2.8154295036764707,
      2.479367938589687,
      2.6351150803823193
    ],
    "volume": 5160111596
  },
  {
    "ts": 1635984000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45420,
      "authorized": 45420,
      "funded": 2648
    },
    "payments": 9,
    "payments_amount": 6154981124,
    "trades": 217,
    "traded_amount": 8787965704,
    "price": [
      2.6351150803823193,
      2.693241708170619,
      2.4517219361417575,
      2.676200219405553
    ],
    "volume": 3369959369
  },
  {
    "ts": 1636070400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45408,
      "authorized": 45408,
      "funded": 2650
    },
    "payments": 9,
    "payments_amount": 1707962449,
    "trades": 142,
    "traded_amount": 6348370621,
    "price": [
      2.676200219405553,
      2.762000031885732,
      2.6600491736690244,
      2.7558260115457607
    ],
    "volume": 10016028004
  },
  {
    "ts": 1636156800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45410,
      "authorized": 45410,
      "funded": 2652
    },
    "payments": 13,
    "payments_amount": 9562977753,
    "trades": 245,
    "traded_amount": 17063386309,
    "price": [
      2.7558260115457607,
      2.859502383821118,
      2.728090638660408,
      2.797469632767769
    ],
    "volume": 14413162269
  },
  {
    "ts": 1636243200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45406,
      "authorized": 45406,
      "funded": 2654
    },
    "payments": 8,
    "payments_amount": 1699047869,
    "trades": 246,
    "traded_amount": 8178475632,
    "price": [
      2.797469632767769,
      2.8999990087124954,
      2.666905804762624,
      2.7447870275882003
    ],
    "volume": 5064457338
  },
  {
    "ts": 1636329600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45413,
      "authorized": 45413,
      "funded": 2655
    },
    "payments": 8,
    "payments_amount": 5786391362,
    "trades": 346,
    "traded_amount": 13162420649,
    "price": [
      2.7447870275882003,
      2.7601529952861314,
      2.6199999993044556,
      2.673768790278011
    ],
    "volume": 14745090384
  },
  {
    "ts": 1636416000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45418,
      "authorized": 45418,
      "funded": 2655
    },
    "payments": 26,
    "payments_amount": 838385187,
    "trades": 682,
    "traded_amount": 16868991816,
    "price": [
      2.673768790278011,
      2.689079418374016,
      2.4137485842710573,
      2.4291671104213894
    ],
    "volume": 17909334944
  },
  {
    "ts": 1636502400,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45412,
      "authorized": 45412,
      "funded": 2656
    },
    "payments": 78,
    "payments_amount": 16334945512,
    "trades": 677,
    "traded_amount": 33317239041,
    "price": [
      2.4291671104213894,
      2.668481807833997,
      2.329999996081137,
      2.5341946969002196
    ],
    "volume": 61585101038
  },
  {
    "ts": 1636588800,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45410,
      "authorized": 45410,
      "funded": 2657
    },
    "payments": 38,
    "payments_amount": 1442261181,
    "trades": 272,
    "traded_amount": 2118819979,
    "price": [
      2.5341946969002196,
      2.5788268478359773,
      2.4144,
      2.512144431177857
    ],
    "volume": 2999279416
  },
  {
    "ts": 1636675200,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45405,
      "authorized": 45405,
      "funded": 2657
    },
    "payments": 15,
    "payments_amount": 43407499,
    "trades": 173,
    "traded_amount": 236523719,
    "price": [
      2.512144431177857,
      2.5976521197549283,
      2.481753684972235,
      2.554603392916814
    ],
    "volume": 238002862
  },
  {
    "ts": 1636761600,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45408,
      "authorized": 45408,
      "funded": 2656
    },
    "payments": 10,
    "payments_amount": 764027704,
    "trades": 99,
    "traded_amount": 375678563,
    "price": [
      2.554603392916814,
      2.607467595279551,
      2.54755984111719,
      2.571912013536379
    ],
    "volume": 278525941
  },
  {
    "ts": 1636848000,
    "supply": 19297859568270,
    "trustlines": {
      "total": 45410,
      "authorized": 45410,
      "funded": 2653
    },
    "payments": 10,
    "payments_amount": 769937192,
    "trades": 107,
    "traded_amount": 968834175,
    "price": [
      2.571912013536379,
      2.67384140105966,
      2.571912013536379,
      2.596836014999325
    ],
    "volume": 2423968370
  },
  {
    "ts": 1636934400,
    "supply": 19297859559590,
    "trustlines": {
      "total": 45409,
      "authorized": 45409,
      "funded": 2650
    },
    "payments": 33,
    "payments_amount": 4141408759,
    "trades": 196,
    "traded_amount": 416558569,
    "price": [
      2.596836014999325,
      2.6450387902921286,
      2.5262030117485796,
      2.628630222742786
    ],
    "volume": 479181206
  },
  {
    "ts": 1637020800,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45405,
      "authorized": 45405,
      "funded": 2648
    },
    "payments": 33,
    "payments_amount": 2712289723,
    "trades": 559,
    "traded_amount": 14039574391,
    "price": [
      2.628630222742786,
      2.849999983681666,
      2.624962085150887,
      2.7195838584525664
    ],
    "volume": 26083902452
  },
  {
    "ts": 1637107200,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45410,
      "authorized": 45410,
      "funded": 2647
    },
    "payments": 42,
    "payments_amount": 285660481,
    "trades": 365,
    "traded_amount": 4594425678,
    "price": [
      2.7195838584525664,
      2.7420000052589866,
      2.6632000113486627,
      2.734335266690656
    ],
    "volume": 8467414371
  },
  {
    "ts": 1637193600,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45410,
      "authorized": 45410,
      "funded": 2648
    },
    "payments": 12,
    "payments_amount": 1409781369,
    "trades": 625,
    "traded_amount": 10209819474,
    "price": [
      2.734335266690656,
      2.8420002425516433,
      2.668261617488024,
      2.841999356175911
    ],
    "volume": 19731316098
  },
  {
    "ts": 1637280000,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45409,
      "authorized": 45409,
      "funded": 2646
    },
    "payments": 74,
    "payments_amount": 6345959655,
    "trades": 306,
    "traded_amount": 9627350998,
    "price": [
      2.841999356175911,
      2.929055210146484,
      2.789892306609418,
      2.8106992366412213
    ],
    "volume": 22985767170
  },
  {
    "ts": 1637366400,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45406,
      "authorized": 45406,
      "funded": 2647
    },
    "payments": 35,
    "payments_amount": 4013412420,
    "trades": 277,
    "traded_amount": 7141552165,
    "price": [
      2.8106992366412213,
      2.9402630050205687,
      2.756520593962054,
      2.8647959549081117
    ],
    "volume": 10465047559
  },
  {
    "ts": 1637452800,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45423,
      "authorized": 45423,
      "funded": 2647
    },
    "payments": 24,
    "payments_amount": 12249792005,
    "trades": 262,
    "traded_amount": 13416991621,
    "price": [
      2.8647959549081117,
      2.939999980247647,
      2.6670078976235527,
      2.8084751164821062
    ],
    "volume": 36618318170
  },
  {
    "ts": 1637539200,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45427,
      "authorized": 45427,
      "funded": 2645
    },
    "payments": 13,
    "payments_amount": 1625435454,
    "trades": 463,
    "traded_amount": 9396946172,
    "price": [
      2.8084751164821062,
      2.819802357165456,
      2.5962177628402654,
      2.7053011119674117
    ],
    "volume": 12020193691
  },
  {
    "ts": 1637625600,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45438,
      "authorized": 45438,
      "funded": 2642
    },
    "payments": 23,
    "payments_amount": 49862894837,
    "trades": 699,
    "traded_amount": 27250658800,
    "price": [
      2.7053011119674117,
      2.930000002363795,
      2.535930779201449,
      2.7231928891987276
    ],
    "volume": 71212633660
  },
  {
    "ts": 1637712000,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45439,
      "authorized": 45439,
      "funded": 2638
    },
    "payments": 13,
    "payments_amount": 82305974258,
    "trades": 628,
    "traded_amount": 42512582801,
    "price": [
      2.7231928891987276,
      2.9953571964114247,
      2.570288186883073,
      2.8530756155135237
    ],
    "volume": 123844828776
  },
  {
    "ts": 1637798400,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45438,
      "authorized": 45438,
      "funded": 2637
    },
    "payments": 25,
    "payments_amount": 777625353,
    "trades": 191,
    "traded_amount": 2313193405,
    "price": [
      2.8530756155135237,
      2.967533416543167,
      2.7616147993713724,
      2.849498449606127
    ],
    "volume": 2671914227
  },
  {
    "ts": 1637884800,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45439,
      "authorized": 45439,
      "funded": 2637
    },
    "payments": 15,
    "payments_amount": 24890803442,
    "trades": 342,
    "traded_amount": 13567566174,
    "price": [
      2.849498449606127,
      3.0303030303030303,
      2.8379876328612545,
      2.886339501766969
    ],
    "volume": 40223122790
  },
  {
    "ts": 1637971200,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45438,
      "authorized": 45438,
      "funded": 2636
    },
    "payments": 5,
    "payments_amount": 14906839525,
    "trades": 289,
    "traded_amount": 17340824173,
    "price": [
      2.886339501766969,
      3.110419906732178,
      2.8620631463957427,
      3.0161831351201207
    ],
    "volume": 52240267673
  },
  {
    "ts": 1638057600,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45443,
      "authorized": 45443,
      "funded": 2634
    },
    "payments": 34,
    "payments_amount": 73425457390,
    "trades": 817,
    "traded_amount": 43951863926,
    "price": [
      3.0161831351201207,
      3.125,
      2.817157298767994,
      2.9725199448657
    ],
    "volume": 129531543236
  },
  {
    "ts": 1638144000,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45441,
      "authorized": 45441,
      "funded": 2635
    },
    "payments": 11,
    "payments_amount": 7243484049,
    "trades": 679,
    "traded_amount": 6447334436,
    "price": [
      2.9725199448657,
      3.070783950403843,
      2.8956262958758447,
      2.8956262958758447
    ],
    "volume": 10163243407
  },
  {
    "ts": 1638230400,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45452,
      "authorized": 45452,
      "funded": 2635
    },
    "payments": 12,
    "payments_amount": 5811625083,
    "trades": 1161,
    "traded_amount": 20086725093,
    "price": [
      2.8956262958758447,
      3.055534505909411,
      2.7999999761943135,
      2.89520822771855
    ],
    "volume": 32668086465
  },
  {
    "ts": 1638316800,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45455,
      "authorized": 45455,
      "funded": 2635
    },
    "payments": 12,
    "payments_amount": 2232110956,
    "trades": 749,
    "traded_amount": 7433794174,
    "price": [
      2.89520822771855,
      2.9823468328141227,
      2.739999994893332,
      2.982002048291938
    ],
    "volume": 12213295774
  },
  {
    "ts": 1638403200,
    "supply": 19297859558077,
    "trustlines": {
      "total": 45458,
      "authorized": 45458,
      "funded": 2632
    },
    "payments": 9,
    "payments_amount": 26590435,
    "trades": 516,
    "traded_amount": 2059943097,
    "price": [
      2.982002048291938,
      2.9938672021421624,
      2.8603972613229747,
      2.940501884861708
    ],
    "volume": 2754861397
  },
  {
    "ts": 1638489600,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45465,
      "authorized": 45465,
      "funded": 2631
    },
    "payments": 13,
    "payments_amount": 5990204873,
    "trades": 671,
    "traded_amount": 12416507663,
    "price": [
      2.940501884861708,
      3.0000901713255184,
      2.734999990330454,
      2.972505734760601
    ],
    "volume": 9984872298
  },
  {
    "ts": 1638576000,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45462,
      "authorized": 45462,
      "funded": 2628
    },
    "payments": 27,
    "payments_amount": 136868803844,
    "trades": 1028,
    "traded_amount": 82176313567,
    "price": [
      2.972505734760601,
      3.3898305084994296,
      2.951247486407984,
      3.2299741602067185
    ],
    "volume": 248434710112
  },
  {
    "ts": 1638662400,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45460,
      "authorized": 45460,
      "funded": 2624
    },
    "payments": 20,
    "payments_amount": 51151766483,
    "trades": 958,
    "traded_amount": 40622915186,
    "price": [
      3.2299741602067185,
      3.5087719298432107,
      3.049954887277458,
      3.3840986432138593
    ],
    "volume": 129269070891
  },
  {
    "ts": 1638748800,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45461,
      "authorized": 45461,
      "funded": 2625
    },
    "payments": 17,
    "payments_amount": 131198084141,
    "trades": 688,
    "traded_amount": 44025634205,
    "price": [
      3.3840986432138593,
      3.5415796651575397,
      3.194700421940928,
      3.3880997302205844
    ],
    "volume": 148121922114
  },
  {
    "ts": 1638835200,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45468,
      "authorized": 45468,
      "funded": 2624
    },
    "payments": 11,
    "payments_amount": 7475445497,
    "trades": 603,
    "traded_amount": 5841520737,
    "price": [
      3.3880997302205844,
      3.443171451909213,
      3.1950533995284163,
      3.384715852036801
    ],
    "volume": 14461222270
  },
  {
    "ts": 1638921600,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45473,
      "authorized": 45473,
      "funded": 2627
    },
    "payments": 5,
    "payments_amount": 66537001,
    "trades": 679,
    "traded_amount": 9384169719,
    "price": [
      3.384715852036801,
      3.4873026760633468,
      3.1990961364766117,
      3.244446319013429
    ],
    "volume": 15863849185
  },
  {
    "ts": 1639008000,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45479,
      "authorized": 45479,
      "funded": 2624
    },
    "payments": 4,
    "payments_amount": 58202128,
    "trades": 774,
    "traded_amount": 7291434819,
    "price": [
      3.244446319013429,
      3.514998859105226,
      3.194714708071299,
      3.477579017211757
    ],
    "volume": 13725598778
  },
  {
    "ts": 1639094400,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45482,
      "authorized": 45482,
      "funded": 2623
    },
    "payments": 7,
    "payments_amount": 5347661491,
    "trades": 654,
    "traded_amount": 7941085338,
    "price": [
      3.477579017211757,
      3.6500708044119174,
      3.2509999959578035,
      3.5147967700691947
    ],
    "volume": 17538652577
  },
  {
    "ts": 1639180800,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45487,
      "authorized": 45487,
      "funded": 2620
    },
    "payments": 5,
    "payments_amount": 419138305,
    "trades": 703,
    "traded_amount": 17541237655,
    "price": [
      3.5147967700691947,
      3.75,
      3.446455179680619,
      3.63034314843591
    ],
    "volume": 25560193874
  },
  {
    "ts": 1639267200,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45490,
      "authorized": 45490,
      "funded": 2622
    },
    "payments": 4,
    "payments_amount": 10829707,
    "trades": 355,
    "traded_amount": 3434898538,
    "price": [
      3.63034314843591,
      3.673541621782117,
      3.4464580990039666,
      3.5752446005427965
    ],
    "volume": 4642076921
  },
  {
    "ts": 1639353600,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45488,
      "authorized": 45488,
      "funded": 2623
    },
    "payments": 26,
    "payments_amount": 95344385943,
    "trades": 668,
    "traded_amount": 54928627197,
    "price": [
      3.5752446005427965,
      3.8351701658776842,
      3.443714095261691,
      3.769854172615968
    ],
    "volume": 196739233744
  },
  {
    "ts": 1639440000,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45495,
      "authorized": 45495,
      "funded": 2628
    },
    "payments": 19,
    "payments_amount": 5137496731,
    "trades": 530,
    "traded_amount": 8379688476,
    "price": [
      3.769854172615968,
      3.8423076923076924,
      3.6279999971718317,
      3.737365735471252
    ],
    "volume": 10302169220
  },
  {
    "ts": 1639526400,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45498,
      "authorized": 45498,
      "funded": 2625
    },
    "payments": 9,
    "payments_amount": 1013772861,
    "trades": 696,
    "traded_amount": 11335635266,
    "price": [
      3.737365735471252,
      3.8387553041018387,
      3.5779999855686877,
      3.6113686969992145
    ],
    "volume": 18467094431
  },
  {
    "ts": 1639612800,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45494,
      "authorized": 45494,
      "funded": 2631
    },
    "payments": 24,
    "payments_amount": 10164395165,
    "trades": 726,
    "traded_amount": 19539859828,
    "price": [
      3.6113686969992145,
      3.760643380868999,
      3.4532239236007163,
      3.7502858001276898
    ],
    "volume": 36449961586
  },
  {
    "ts": 1639699200,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45491,
      "authorized": 45491,
      "funded": 2631
    },
    "payments": 19,
    "payments_amount": 9135315304,
    "trades": 667,
    "traded_amount": 10703400720,
    "price": [
      3.7502858001276898,
      3.8950001619801276,
      3.6183904961664006,
      3.8344680290046145
    ],
    "volume": 28967763215
  },
  {
    "ts": 1639785600,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45490,
      "authorized": 45490,
      "funded": 2630
    },
    "payments": 25,
    "payments_amount": 6996963872,
    "trades": 744,
    "traded_amount": 9048605381,
    "price": [
      3.8344680290046145,
      3.8459005628155456,
      3.472725460495191,
      3.705323274918481
    ],
    "volume": 19597473420
  },
  {
    "ts": 1639872000,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45493,
      "authorized": 45493,
      "funded": 2629
    },
    "payments": 18,
    "payments_amount": 8500126296,
    "trades": 789,
    "traded_amount": 10615771388,
    "price": [
      3.705323274918481,
      3.86800000341401,
      3.4121889989056333,
      3.5993626743626743
    ],
    "volume": 23973125531
  },
  {
    "ts": 1639958400,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45500,
      "authorized": 45500,
      "funded": 2633
    },
    "payments": 28,
    "payments_amount": 11074264468,
    "trades": 1139,
    "traded_amount": 11553626841,
    "price": [
      3.5993626743626743,
      3.890330456506927,
      3.39600779214208,
      3.5566089440890405
    ],
    "volume": 25315685531
  },
  {
    "ts": 1640044800,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45502,
      "authorized": 45502,
      "funded": 2631
    },
    "payments": 27,
    "payments_amount": 12778601691,
    "trades": 1352,
    "traded_amount": 15924305880,
    "price": [
      3.5566089440890405,
      3.8897725219119907,
      3.07729339996733,
      3.5319472428999585
    ],
    "volume": 43425530033
  },
  {
    "ts": 1640131200,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45503,
      "authorized": 45503,
      "funded": 2629
    },
    "payments": 29,
    "payments_amount": 89351720724,
    "trades": 771,
    "traded_amount": 64565031326,
    "price": [
      3.5319472428999585,
      3.642694711368809,
      3.076931607969089,
      3.260620576702818
    ],
    "volume": 100852546977
  },
  {
    "ts": 1640217600,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45502,
      "authorized": 45502,
      "funded": 2628
    },
    "payments": 23,
    "payments_amount": 47330337033,
    "trades": 686,
    "traded_amount": 27203770833,
    "price": [
      3.260620576702818,
      3.6549651111922516,
      3.011860826462175,
      3.4064614034446192
    ],
    "volume": 85925036408
  },
  {
    "ts": 1640304000,
    "supply": 19297859558062,
    "trustlines": {
      "total": 45506,
      "authorized": 45506,
      "funded": 2629
    },
    "payments": 18,
    "payments_amount": 16855950145,
    "trades": 512,
    "traded_amount": 9938610826,
    "price": [
      3.4064614034446192,
      3.447048,
      3.1347962378585597,
      3.332878324311603
    ],
    "volume": 30714067565
  },
  {
    "ts": 1640390400,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45507,
      "authorized": 45507,
      "funded": 2628
    },
    "payments": 9,
    "payments_amount": 9314820602,
    "trades": 277,
    "traded_amount": 9680753340,
    "price": [
      3.332878324311603,
      3.4381471,
      3.323003223977728,
      3.3826792163527704
    ],
    "volume": 720263093
  },
  {
    "ts": 1640476800,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45489,
      "authorized": 45489,
      "funded": 2630
    },
    "payments": 14,
    "payments_amount": 8395313453,
    "trades": 295,
    "traded_amount": 5231554618,
    "price": [
      3.3826792163527704,
      3.402938700648102,
      3.2058053858410074,
      3.3164978030252485
    ],
    "volume": 8251783266
  },
  {
    "ts": 1640563200,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45487,
      "authorized": 45487,
      "funded": 2629
    },
    "payments": 7,
    "payments_amount": 629366148,
    "trades": 540,
    "traded_amount": 21294737719,
    "price": [
      3.3164978030252485,
      3.421968008021093,
      3.2361242167129483,
      3.2535984799187903
    ],
    "volume": 35710010357
  },
  {
    "ts": 1640649600,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45489,
      "authorized": 45489,
      "funded": 2628
    },
    "payments": 30,
    "payments_amount": 39385567857,
    "trades": 749,
    "traded_amount": 27443158222,
    "price": [
      3.2535984799187903,
      3.5552636013745627,
      3.2399504683938343,
      3.446235465922325
    ],
    "volume": 70211963881
  },
  {
    "ts": 1640736000,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45489,
      "authorized": 45489,
      "funded": 2627
    },
    "payments": 7,
    "payments_amount": 6753234950,
    "trades": 441,
    "traded_amount": 9262927995,
    "price": [
      3.446235465922325,
      3.5738025974397276,
      3.428214731585518,
      3.5738025974397276
    ],
    "volume": 25615202964
  },
  {
    "ts": 1640822400,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45495,
      "authorized": 45495,
      "funded": 2627
    },
    "payments": 15,
    "payments_amount": 3131896860,
    "trades": 815,
    "traded_amount": 24178053625,
    "price": [
      3.5738025974397276,
      3.6997115401631655,
      3.416040597189326,
      3.679025668528474
    ],
    "volume": 53935277834
  },
  {
    "ts": 1640908800,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45499,
      "authorized": 45499,
      "funded": 2626
    },
    "payments": 6,
    "payments_amount": 13504299863,
    "trades": 585,
    "traded_amount": 10417600688,
    "price": [
      3.679025668528474,
      3.8127999073380523,
      3.4965034961945185,
      3.7211450526625796
    ],
    "volume": 26287356970
  },
  {
    "ts": 1640995200,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45502,
      "authorized": 45502,
      "funded": 2626
    },
    "payments": 6,
    "payments_amount": 2410132184,
    "trades": 682,
    "traded_amount": 11050999484,
    "price": [
      3.7211450526625796,
      3.7211450526625796,
      3.485142499959121,
      3.5667953786078517
    ],
    "volume": 9869981094
  },
  {
    "ts": 1641081600,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45506,
      "authorized": 45506,
      "funded": 2625
    },
    "payments": 7,
    "payments_amount": 40926372,
    "trades": 610,
    "traded_amount": 5679181040,
    "price": [
      3.5667953786078517,
      3.585000000870569,
      3.3738109278254527,
      3.380366298184799
    ],
    "volume": 12566891117
  },
  {
    "ts": 1641168000,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45507,
      "authorized": 45507,
      "funded": 2626
    },
    "payments": 21,
    "payments_amount": 14434169599,
    "trades": 891,
    "traded_amount": 15747335012,
    "price": [
      3.380366298184799,
      3.452979137392701,
      3.076932541947967,
      3.395479577787976
    ],
    "volume": 27201009579
  },
  {
    "ts": 1641254400,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45512,
      "authorized": 45512,
      "funded": 2626
    },
    "payments": 12,
    "payments_amount": 905795137,
    "trades": 966,
    "traded_amount": 6926734369,
    "price": [
      3.395479577787976,
      3.5159238004312163,
      3.3720293760606634,
      3.5062621842610904
    ],
    "volume": 6359064029
  },
  {
    "ts": 1641340800,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45513,
      "authorized": 45513,
      "funded": 2629
    },
    "payments": 13,
    "payments_amount": 1326937099,
    "trades": 1595,
    "traded_amount": 18949514536,
    "price": [
      3.5062621842610904,
      3.790388007054674,
      3.2443987964252736,
      3.69210775047259
    ],
    "volume": 23601568795
  },
  {
    "ts": 1641427200,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45517,
      "authorized": 45517,
      "funded": 2629
    },
    "payments": 16,
    "payments_amount": 1877890171,
    "trades": 950,
    "traded_amount": 4608589418,
    "price": [
      3.69210775047259,
      3.7615338154756013,
      3.3819956974674135,
      3.5880770954728822
    ],
    "volume": 10409530140
  },
  {
    "ts": 1641513600,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45513,
      "authorized": 45513,
      "funded": 2627
    },
    "payments": 9,
    "payments_amount": 39155135856,
    "trades": 1213,
    "traded_amount": 5900051309,
    "price": [
      3.5880770954728822,
      3.877410318720953,
      3.5602133920215335,
      3.77247581935843
    ],
    "volume": 18836023523
  },
  {
    "ts": 1641600000,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45515,
      "authorized": 45515,
      "funded": 2626
    },
    "payments": 30,
    "payments_amount": 8323303409,
    "trades": 1301,
    "traded_amount": 7466502560,
    "price": [
      3.77247581935843,
      3.927276202145868,
      3.374024279913316,
      3.802278120550546
    ],
    "volume": 18371245440
  },
  {
    "ts": 1641686400,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45515,
      "authorized": 45515,
      "funded": 2627
    },
    "payments": 3,
    "payments_amount": 605448665,
    "trades": 700,
    "traded_amount": 2862412530,
    "price": [
      3.802278120550546,
      3.8929597701149423,
      3.6439007948281974,
      3.7200913952053973
    ],
    "volume": 3170445987
  },
  {
    "ts": 1641772800,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45521,
      "authorized": 45521,
      "funded": 2626
    },
    "payments": 15,
    "payments_amount": 18937607606,
    "trades": 994,
    "traded_amount": 14230858391,
    "price": [
      3.7200913952053973,
      3.9840637451863032,
      3.582999999105663,
      3.8404301281743556
    ],
    "volume": 44493370432
  },
  {
    "ts": 1641859200,
    "supply": 19297859558061,
    "trustlines": {
      "total": 45522,
      "authorized": 45522,
      "funded": 2627
    },
    "payments": 6,
    "payments_amount": 1250387822,
    "trades": 1032,
    "traded_amount": 4366485928,
    "price": [
      3.8404301281743556,
      3.949043792319942,
      3.5928753874411665,
      3.5928753874411665
    ],
    "volume": 6888026006
  },
  {
    "ts": 1641945600,
    "supply": 19297859548629,
    "trustlines": {
      "total": 45519,
      "authorized": 45519,
      "funded": 2624
    },
    "payments": 9,
    "payments_amount": 5733125158,
    "trades": 1061,
    "traded_amount": 12420064436,
    "price": [
      3.5928753874411665,
      3.7050479688202667,
      3.3356953945895906,
      3.377622407147049
    ],
    "volume": 13053798984
  },
  {
    "ts": 1642032000,
    "supply": 19297859548629,
    "trustlines": {
      "total": 45525,
      "authorized": 45525,
      "funded": 2622
    },
    "payments": 19,
    "payments_amount": 4827798854,
    "trades": 908,
    "traded_amount": 14892194653,
    "price": [
      3.377622407147049,
      3.4054054054054053,
      2.50063876525294,
      3.1709266886326195
    ],
    "volume": 21463167835
  },
  {
    "ts": 1642118400,
    "supply": 19297859548629,
    "trustlines": {
      "total": 45522,
      "authorized": 45522,
      "funded": 2619
    },
    "payments": 9,
    "payments_amount": 3958649312,
    "trades": 415,
    "traded_amount": 4223759550,
    "price": [
      3.1709266886326195,
      3.253979635737846,
      3.1242187621764,
      3.1913630169262706
    ],
    "volume": 486640410
  }
]
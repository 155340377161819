[
  {
    "ts": 1575936000,
    "supply": 3000000000,
    "trustlines": {
      "total": 7,
      "authorized": 7,
      "funded": 2
    },
    "payments": 2,
    "payments_amount": 4000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1576022400,
    "supply": 3000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1576108800,
    "supply": 3000000000,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 18,
    "traded_amount": 999921042,
    "price": [
      4.5865,
      30,
      4.5864514,
      30
    ],
    "volume": 4713003821
  },
  {
    "ts": 1576195200,
    "supply": 4000000000,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 8
    },
    "payments": 6,
    "payments_amount": 3105000000,
    "trades": 29,
    "traded_amount": 262110885,
    "price": [
      30,
      80,
      7.499999982982031,
      55
    ],
    "volume": 5654572910
  },
  {
    "ts": 1576281600,
    "supply": 6000000000,
    "trustlines": {
      "total": 32,
      "authorized": 32,
      "funded": 22
    },
    "payments": 13,
    "payments_amount": 4100000000,
    "trades": 17,
    "traded_amount": 1004363390,
    "price": [
      55,
      55,
      5,
      5
    ],
    "volume": 5510645379
  },
  {
    "ts": 1576368000,
    "supply": 6000000000,
    "trustlines": {
      "total": 34,
      "authorized": 34,
      "funded": 23
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 6,
    "traded_amount": 920103371,
    "price": [
      5,
      5,
      4.774985941951615,
      4.774985941951615
    ],
    "volume": 4454375939
  },
  {
    "ts": 1576454400,
    "supply": 6000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 25
    },
    "payments": 5,
    "payments_amount": 721400000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.774985941951615,
      4.774985941951615,
      4.6946197,
      4.6946197
    ],
    "volume": 46946197
  },
  {
    "ts": 1576540800,
    "supply": 6000000000,
    "trustlines": {
      "total": 37,
      "authorized": 37,
      "funded": 25
    },
    "payments": 2,
    "payments_amount": 1000000000,
    "trades": 11,
    "traded_amount": 549237128,
    "price": [
      4.6946197,
      4.69462,
      4.643211010788233,
      4.643211010788233
    ],
    "volume": 2568347551
  },
  {
    "ts": 1576627200,
    "supply": 6000000000,
    "trustlines": {
      "total": 39,
      "authorized": 39,
      "funded": 25
    },
    "payments": 1,
    "payments_amount": 5888888,
    "trades": 6,
    "traded_amount": 56193241,
    "price": [
      4.643211010788233,
      4.694619775320893,
      4,
      4.6946197652256245
    ],
    "volume": 248555515
  },
  {
    "ts": 1576713600,
    "supply": 6000000000,
    "trustlines": {
      "total": 42,
      "authorized": 42,
      "funded": 25
    },
    "payments": 4,
    "payments_amount": 1205000000,
    "trades": 3,
    "traded_amount": 754886382,
    "price": [
      4.6946197652256245,
      4.98,
      4.694619699696244,
      4.98
    ],
    "volume": 3572442510
  },
  {
    "ts": 1576800000,
    "supply": 6000000000,
    "trustlines": {
      "total": 43,
      "authorized": 43,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.98,
      5.3,
      4.98,
      5.3
    ],
    "volume": 53000000
  },
  {
    "ts": 1576886400,
    "supply": 6000000000,
    "trustlines": {
      "total": 45,
      "authorized": 45,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.3,
      5.3,
      5.3,
      5.3
    ],
    "volume": 0
  },
  {
    "ts": 1576972800,
    "supply": 6000000000,
    "trustlines": {
      "total": 46,
      "authorized": 46,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.3,
      5.3,
      5.3,
      5.3
    ],
    "volume": 0
  },
  {
    "ts": 1577059200,
    "supply": 6000000000,
    "trustlines": {
      "total": 46,
      "authorized": 46,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 190000000,
    "price": [
      5.3,
      5.3,
      5.128107821052631,
      5.128107821052631
    ],
    "volume": 974340486
  },
  {
    "ts": 1577145600,
    "supply": 6000000000,
    "trustlines": {
      "total": 48,
      "authorized": 48,
      "funded": 25
    },
    "payments": 2,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.128107821052631,
      5.128107821052631,
      5.128107821052631,
      5.128107821052631
    ],
    "volume": 0
  },
  {
    "ts": 1577232000,
    "supply": 6000000000,
    "trustlines": {
      "total": 48,
      "authorized": 48,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      5.128107821052631,
      5.3793348,
      5.128107821052631,
      5.3793348
    ],
    "volume": 53793348
  },
  {
    "ts": 1577318400,
    "supply": 6000000000,
    "trustlines": {
      "total": 49,
      "authorized": 49,
      "funded": 24
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 284768777,
    "price": [
      5.3793348,
      5.3793348,
      5.378009531571644,
      5.378009531571644
    ],
    "volume": 1531489197
  },
  {
    "ts": 1577404800,
    "supply": 6000000000,
    "trustlines": {
      "total": 54,
      "authorized": 54,
      "funded": 24
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.378009531571644,
      5.378009531571644,
      5.378009531571644,
      5.378009531571644
    ],
    "volume": 0
  },
  {
    "ts": 1577491200,
    "supply": 6000000000,
    "trustlines": {
      "total": 54,
      "authorized": 54,
      "funded": 24
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.378009531571644,
      5.378009531571644,
      5.378009531571644,
      5.378009531571644
    ],
    "volume": 0
  },
  {
    "ts": 1577577600,
    "supply": 6000000000,
    "trustlines": {
      "total": 56,
      "authorized": 56,
      "funded": 24
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.378009531571644,
      5.378009531571644,
      5.378009531571644,
      5.378009531571644
    ],
    "volume": 0
  },
  {
    "ts": 1577664000,
    "supply": 6000000000,
    "trustlines": {
      "total": 57,
      "authorized": 57,
      "funded": 24
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.378009531571644,
      5.378009531571644,
      5.378009531571644,
      5.378009531571644
    ],
    "volume": 0
  },
  {
    "ts": 1577750400,
    "supply": 16000000000,
    "trustlines": {
      "total": 59,
      "authorized": 59,
      "funded": 25
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      5.378009531571644,
      5.5,
      5.378009531571644,
      5.5
    ],
    "volume": 55000000
  },
  {
    "ts": 1577836800,
    "supply": 16000000000,
    "trustlines": {
      "total": 59,
      "authorized": 59,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.5,
      5.5,
      5.5,
      5.5
    ],
    "volume": 0
  },
  {
    "ts": 1577923200,
    "supply": 16000000000,
    "trustlines": {
      "total": 59,
      "authorized": 59,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 30000000,
    "price": [
      5.5,
      5.5899428,
      5.5,
      5.5841882
    ],
    "volume": 167583192
  },
  {
    "ts": 1578009600,
    "supply": 16000000000,
    "trustlines": {
      "total": 60,
      "authorized": 60,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 33000000,
    "price": [
      5.5841882,
      5.5841882,
      5.430317333333333,
      5.479184833333333
    ],
    "volume": 180080087
  },
  {
    "ts": 1578096000,
    "supply": 16000000000,
    "trustlines": {
      "total": 60,
      "authorized": 60,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 30000000,
    "price": [
      5.479184833333333,
      5.479184833333333,
      5.357276766666667,
      5.357276766666667
    ],
    "volume": 160718303
  },
  {
    "ts": 1578182400,
    "supply": 16000000000,
    "trustlines": {
      "total": 60,
      "authorized": 60,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 18000000,
    "price": [
      5.357276766666667,
      5.357276766666667,
      5.304460055555555,
      5.304460055555555
    ],
    "volume": 95480281
  },
  {
    "ts": 1578268800,
    "supply": 36000000000,
    "trustlines": {
      "total": 61,
      "authorized": 61,
      "funded": 25
    },
    "payments": 3,
    "payments_amount": 21000000000,
    "trades": 4,
    "traded_amount": 230500000,
    "price": [
      5.304460055555555,
      5.304460055555555,
      4.910033368421052,
      4.910033368421052
    ],
    "volume": 1166823252
  },
  {
    "ts": 1578355200,
    "supply": 36000000000,
    "trustlines": {
      "total": 61,
      "authorized": 61,
      "funded": 25
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 9000000,
    "price": [
      4.910033368421052,
      5.029100888888889,
      4.910033368421052,
      5.029100888888889
    ],
    "volume": 45261908
  },
  {
    "ts": 1578441600,
    "supply": 36000000000,
    "trustlines": {
      "total": 62,
      "authorized": 62,
      "funded": 26
    },
    "payments": 2,
    "payments_amount": 800000000,
    "trades": 1,
    "traded_amount": 20000000,
    "price": [
      5.029100888888889,
      5.0712944,
      5.029100888888889,
      5.0712944
    ],
    "volume": 101425888
  },
  {
    "ts": 1578528000,
    "supply": 36000000000,
    "trustlines": {
      "total": 63,
      "authorized": 63,
      "funded": 26
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 21000000,
    "price": [
      5.0712944,
      5.196248,
      5.0712944,
      5.196248
    ],
    "volume": 109065564
  },
  {
    "ts": 1578614400,
    "supply": 36000000000,
    "trustlines": {
      "total": 64,
      "authorized": 64,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 44100000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      5.196248,
      5.2369579,
      5.196248,
      5.2369579
    ],
    "volume": 52369579
  },
  {
    "ts": 1578700800,
    "supply": 36000000000,
    "trustlines": {
      "total": 64,
      "authorized": 64,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 27600000,
    "price": [
      5.2369579,
      5.2369579,
      5.0861245,
      5.0861245
    ],
    "volume": 141844881
  },
  {
    "ts": 1578787200,
    "supply": 36000000000,
    "trustlines": {
      "total": 64,
      "authorized": 64,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 9800000,
    "price": [
      5.0861245,
      5.0861245,
      5.055257448979591,
      5.055257448979591
    ],
    "volume": 49541523
  },
  {
    "ts": 1578873600,
    "supply": 36000000000,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 27
    },
    "payments": 3,
    "payments_amount": 60000000,
    "trades": 2,
    "traded_amount": 28000000,
    "price": [
      5.055257448979591,
      5.126343,
      5.055257448979591,
      5.0978938125
    ],
    "volume": 143082417
  },
  {
    "ts": 1578960000,
    "supply": 36000000000,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 46000000,
    "price": [
      5.0978938125,
      5.0978938125,
      4.75,
      4.75
    ],
    "volume": 225002596
  },
  {
    "ts": 1579046400,
    "supply": 36000000000,
    "trustlines": {
      "total": 70,
      "authorized": 70,
      "funded": 31
    },
    "payments": 3,
    "payments_amount": 128000000,
    "trades": 5,
    "traded_amount": 189500000,
    "price": [
      4.75,
      4.75,
      4.3041432,
      4.304143219512195
    ],
    "volume": 836958321
  },
  {
    "ts": 1579132800,
    "supply": 36000000000,
    "trustlines": {
      "total": 70,
      "authorized": 70,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 22000000,
    "price": [
      4.304143219512195,
      4.304143227272728,
      4.304143219512195,
      4.304143227272728
    ],
    "volume": 94691151
  },
  {
    "ts": 1579219200,
    "supply": 36000000000,
    "trustlines": {
      "total": 70,
      "authorized": 70,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 47500000,
    "price": [
      4.304143227272728,
      4.304143227272728,
      3.8249721333333335,
      3.8249721333333335
    ],
    "volume": 190620081
  },
  {
    "ts": 1579305600,
    "supply": 36000000000,
    "trustlines": {
      "total": 71,
      "authorized": 71,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 33000000,
    "price": [
      3.8249721333333335,
      3.8249721333333335,
      3.6304703,
      3.6304703
    ],
    "volume": 121483902
  },
  {
    "ts": 1579392000,
    "supply": 36000000000,
    "trustlines": {
      "total": 71,
      "authorized": 71,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 48000000,
    "price": [
      3.6304703,
      3.6868735217391304,
      3.6304703,
      3.68687352
    ],
    "volume": 176969929
  },
  {
    "ts": 1579478400,
    "supply": 36000000000,
    "trustlines": {
      "total": 72,
      "authorized": 72,
      "funded": 32
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 70000000,
    "price": [
      3.68687352,
      3.686874,
      3.6868735,
      3.6868735217391304
    ],
    "volume": 258081146
  },
  {
    "ts": 1579564800,
    "supply": 36000000000,
    "trustlines": {
      "total": 75,
      "authorized": 75,
      "funded": 33
    },
    "payments": 1,
    "payments_amount": 20000000,
    "trades": 4,
    "traded_amount": 79600000,
    "price": [
      3.6868735217391304,
      3.6868735416666665,
      3.6142274,
      3.61422745
    ],
    "volume": 288389908
  },
  {
    "ts": 1579651200,
    "supply": 36000000000,
    "trustlines": {
      "total": 75,
      "authorized": 75,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.61422745,
      3.61422745,
      3.6142274,
      3.6142274
    ],
    "volume": 36142274
  },
  {
    "ts": 1579737600,
    "supply": 36000000000,
    "trustlines": {
      "total": 75,
      "authorized": 75,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 65000000,
    "price": [
      3.6142274,
      3.9535708421052633,
      3.6142274,
      3.9535708421052633
    ],
    "volume": 249177207
  },
  {
    "ts": 1579824000,
    "supply": 36000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 44000000,
    "price": [
      3.9535708421052633,
      3.9535708421052633,
      3.9535708095238093,
      3.9535708095238093
    ],
    "volume": 173957116
  },
  {
    "ts": 1579910400,
    "supply": 36000000000,
    "trustlines": {
      "total": 77,
      "authorized": 77,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.9535708095238093,
      3.9535708095238093,
      3.9535708,
      3.9535708
    ],
    "volume": 39535708
  },
  {
    "ts": 1579996800,
    "supply": 36000000000,
    "trustlines": {
      "total": 77,
      "authorized": 77,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 9500000,
    "price": [
      3.9535708,
      3.9535708,
      3.953570666666667,
      3.9535707692307693
    ],
    "volume": 37558922
  },
  {
    "ts": 1580083200,
    "supply": 36000000000,
    "trustlines": {
      "total": 79,
      "authorized": 79,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 33400000,
    "price": [
      3.9535707692307693,
      3.9535707692307693,
      3.8792257142857145,
      3.8792257142857145
    ],
    "volume": 130488017
  },
  {
    "ts": 1580169600,
    "supply": 36000000000,
    "trustlines": {
      "total": 79,
      "authorized": 79,
      "funded": 33
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 8000000,
    "price": [
      3.8792257142857145,
      3.880161125,
      3.8792257142857145,
      3.880161125
    ],
    "volume": 31041289
  },
  {
    "ts": 1580256000,
    "supply": 36000000000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 35
    },
    "payments": 3,
    "payments_amount": 24500000,
    "trades": 2,
    "traded_amount": 9077212,
    "price": [
      3.880161125,
      3.8801612307692306,
      3.880161125,
      3.880161197449026
    ],
    "volume": 35221046
  },
  {
    "ts": 1580342400,
    "supply": 36000000000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 34
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 53000000,
    "price": [
      3.880161197449026,
      3.880161197449026,
      3.8037059,
      3.8801611304347827
    ],
    "volume": 204883988
  },
  {
    "ts": 1580428800,
    "supply": 36000000000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 34
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.8801611304347827,
      3.8801611304347827,
      3.8801611,
      3.8801611
    ],
    "volume": 38801611
  },
  {
    "ts": 1580601600,
    "supply": 36000000000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 34
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.8801611,
      3.8801611,
      3.8801611,
      3.8801611
    ],
    "volume": 38801611
  },
  {
    "ts": 1580688000,
    "supply": 36000000000,
    "trustlines": {
      "total": 82,
      "authorized": 82,
      "funded": 35
    },
    "payments": 1,
    "payments_amount": 15000000,
    "trades": 2,
    "traded_amount": 8500000,
    "price": [
      3.8801611,
      3.8801611,
      3.80370575,
      3.80370575
    ],
    "volume": 32331499
  },
  {
    "ts": 1580774400,
    "supply": 36000000000,
    "trustlines": {
      "total": 83,
      "authorized": 83,
      "funded": 36
    },
    "payments": 1,
    "payments_amount": 13500000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.80370575,
      3.80370575,
      3.80370575,
      3.80370575
    ],
    "volume": 0
  },
  {
    "ts": 1580860800,
    "supply": 36000000000,
    "trustlines": {
      "total": 83,
      "authorized": 83,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 8700000,
    "price": [
      3.80370575,
      3.8801611494252874,
      3.80370575,
      3.8801611494252874
    ],
    "volume": 33757402
  },
  {
    "ts": 1580947200,
    "supply": 36000000000,
    "trustlines": {
      "total": 83,
      "authorized": 83,
      "funded": 36
    },
    "payments": 2,
    "payments_amount": 8700000,
    "trades": 2,
    "traded_amount": 12000000,
    "price": [
      3.8801611494252874,
      3.8801612,
      3.803705857142857,
      3.803705857142857
    ],
    "volume": 46026747
  },
  {
    "ts": 1581033600,
    "supply": 36000000000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.803705857142857,
      3.803705857142857,
      3.803705857142857,
      3.803705857142857
    ],
    "volume": 0
  },
  {
    "ts": 1581120000,
    "supply": 36000000000,
    "trustlines": {
      "total": 85,
      "authorized": 85,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.803705857142857,
      3.803705857142857,
      3.803705857142857,
      3.803705857142857
    ],
    "volume": 0
  },
  {
    "ts": 1581292800,
    "supply": 36000000000,
    "trustlines": {
      "total": 86,
      "authorized": 86,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 22500000,
    "price": [
      3.803705857142857,
      3.803705857142857,
      3.3589647142857144,
      3.3589647142857144
    ],
    "volume": 76243817
  },
  {
    "ts": 1581379200,
    "supply": 36000000000,
    "trustlines": {
      "total": 88,
      "authorized": 88,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 20050000,
    "price": [
      3.3589647142857144,
      3.3589647142857144,
      3.1369818,
      3.1369818
    ],
    "volume": 63300215
  },
  {
    "ts": 1581465600,
    "supply": 36000000000,
    "trustlines": {
      "total": 91,
      "authorized": 91,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.1369818,
      3.1369818,
      3.1369818,
      3.1369818
    ],
    "volume": 0
  },
  {
    "ts": 1581552000,
    "supply": 36000000000,
    "trustlines": {
      "total": 93,
      "authorized": 93,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 120000000,
    "price": [
      3.1369818,
      3.1369818,
      2.7809931,
      2.7809931
    ],
    "volume": 334718091
  },
  {
    "ts": 1581638400,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 17000000,
    "price": [
      2.7809931,
      2.7809931,
      2.684928888888889,
      2.684928888888889
    ],
    "volume": 46029343
  },
  {
    "ts": 1581724800,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.684928888888889,
      2.684928888888889,
      2.6028117,
      2.6028117
    ],
    "volume": 26028117
  },
  {
    "ts": 1581811200,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.6028117,
      2.6028117,
      2.6028117,
      2.6028117
    ],
    "volume": 0
  },
  {
    "ts": 1581897600,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.6028117,
      2.6028117,
      2.6028117,
      2.6028117
    ],
    "volume": 0
  },
  {
    "ts": 1581984000,
    "supply": 36000000000,
    "trustlines": {
      "total": 91,
      "authorized": 91,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 23000000,
    "price": [
      2.6028117,
      2.8344655,
      2.6028117,
      2.8344655
    ],
    "volume": 65192706
  },
  {
    "ts": 1582070400,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      2.8344655,
      2.8914207,
      2.8344655,
      2.8914207
    ],
    "volume": 57828414
  },
  {
    "ts": 1582156800,
    "supply": 36000000000,
    "trustlines": {
      "total": 91,
      "authorized": 91,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.8914207,
      2.8914207,
      2.8344655,
      2.8344655
    ],
    "volume": 28344655
  },
  {
    "ts": 1582243200,
    "supply": 36000000000,
    "trustlines": {
      "total": 93,
      "authorized": 93,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.8344655,
      2.8344655,
      2.8344655,
      2.8344655
    ],
    "volume": 28344655
  },
  {
    "ts": 1582329600,
    "supply": 36000000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.8344655,
      2.8344655,
      2.8344655,
      2.8344655
    ],
    "volume": 0
  },
  {
    "ts": 1582416000,
    "supply": 36000000000,
    "trustlines": {
      "total": 93,
      "authorized": 93,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.8344655,
      3.1719669,
      2.8344655,
      3.1719669
    ],
    "volume": 31719669
  },
  {
    "ts": 1582502400,
    "supply": 36000000000,
    "trustlines": {
      "total": 94,
      "authorized": 94,
      "funded": 38
    },
    "payments": 1,
    "payments_amount": 2500000,
    "trades": 3,
    "traded_amount": 25000000,
    "price": [
      3.1719669,
      3.1719669,
      3.1263027,
      3.1263028
    ],
    "volume": 78157568
  },
  {
    "ts": 1582588800,
    "supply": 36000000000,
    "trustlines": {
      "total": 94,
      "authorized": 94,
      "funded": 38
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.1263028,
      3.1263028,
      3.0673077,
      3.0673077
    ],
    "volume": 30673077
  },
  {
    "ts": 1582675200,
    "supply": 36000000000,
    "trustlines": {
      "total": 94,
      "authorized": 94,
      "funded": 38
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.0673077,
      3.0673077,
      3.0673077,
      3.0673077
    ],
    "volume": 0
  },
  {
    "ts": 1582761600,
    "supply": 36000000000,
    "trustlines": {
      "total": 95,
      "authorized": 95,
      "funded": 40
    },
    "payments": 5,
    "payments_amount": 453753324,
    "trades": 4,
    "traded_amount": 371853324,
    "price": [
      3.0673077,
      3.1263027889903494,
      3.0673077,
      3.1263027010264297
    ],
    "volume": 1161936104
  },
  {
    "ts": 1582848000,
    "supply": 36000000000,
    "trustlines": {
      "total": 98,
      "authorized": 98,
      "funded": 40
    },
    "payments": 7,
    "payments_amount": 1272000000,
    "trades": 3,
    "traded_amount": 80000000,
    "price": [
      3.1263027010264297,
      3.1263027010264297,
      3.0673077,
      3.0673077
    ],
    "volume": 249514266
  },
  {
    "ts": 1582934400,
    "supply": 36000000000,
    "trustlines": {
      "total": 98,
      "authorized": 98,
      "funded": 40
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.0673077,
      3.0673077,
      3.0673077,
      3.0673077
    ],
    "volume": 30673077
  },
  {
    "ts": 1583020800,
    "supply": 36000000000,
    "trustlines": {
      "total": 98,
      "authorized": 98,
      "funded": 40
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.0673077,
      3.1263027,
      3.0673077,
      3.1263027
    ],
    "volume": 31263027
  },
  {
    "ts": 1583107200,
    "supply": 36000000000,
    "trustlines": {
      "total": 99,
      "authorized": 99,
      "funded": 40
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.1263027,
      3.1263027,
      3.0673077,
      3.0673077
    ],
    "volume": 30673077
  },
  {
    "ts": 1583193600,
    "supply": 36000000000,
    "trustlines": {
      "total": 105,
      "authorized": 105,
      "funded": 42
    },
    "payments": 6,
    "payments_amount": 430371906,
    "trades": 5,
    "traded_amount": 130371906,
    "price": [
      3.0673077,
      3.7407573050313316,
      3.0673077,
      3.7407573050313316
    ],
    "volume": 482835932
  },
  {
    "ts": 1583280000,
    "supply": 36000000000,
    "trustlines": {
      "total": 108,
      "authorized": 108,
      "funded": 42
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.7407573050313316,
      3.7407573050313316,
      3.6670495,
      3.6670495
    ],
    "volume": 36670495
  },
  {
    "ts": 1583366400,
    "supply": 36000000000,
    "trustlines": {
      "total": 109,
      "authorized": 109,
      "funded": 43
    },
    "payments": 3,
    "payments_amount": 906138230,
    "trades": 3,
    "traded_amount": 611138230,
    "price": [
      3.6670495,
      3.726132,
      3.5432734655737703,
      3.6144928096585165
    ],
    "volume": 2188349230
  },
  {
    "ts": 1583452800,
    "supply": 36000000000,
    "trustlines": {
      "total": 111,
      "authorized": 111,
      "funded": 45
    },
    "payments": 5,
    "payments_amount": 300280000,
    "trades": 3,
    "traded_amount": 70023208,
    "price": [
      3.6144928096585165,
      3.6532049388138064,
      3.582669801151126,
      3.582669801151126
    ],
    "volume": 252282047
  },
  {
    "ts": 1583539200,
    "supply": 36000000000,
    "trustlines": {
      "total": 112,
      "authorized": 112,
      "funded": 45
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.582669801151126,
      3.582669801151126,
      3.582669801151126,
      3.582669801151126
    ],
    "volume": 0
  },
  {
    "ts": 1583625600,
    "supply": 36000000000,
    "trustlines": {
      "total": 112,
      "authorized": 112,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 133960952,
    "price": [
      3.582669801151126,
      3.582669801151126,
      3.546372406377581,
      3.5463724163434596
    ],
    "volume": 475075426
  },
  {
    "ts": 1583712000,
    "supply": 36000000000,
    "trustlines": {
      "total": 113,
      "authorized": 113,
      "funded": 47
    },
    "payments": 1,
    "payments_amount": 6000000,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      3.5463724163434596,
      3.5463724163434596,
      3.4764386,
      3.4764386
    ],
    "volume": 70228110
  },
  {
    "ts": 1583798400,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 46
    },
    "payments": 2,
    "payments_amount": 300700000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      3.4764386,
      3.5463724,
      3.4764386,
      3.5463724
    ],
    "volume": 35463724
  },
  {
    "ts": 1583884800,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 47
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      3.5463724,
      3.5463724,
      3.4764386,
      3.4764386
    ],
    "volume": 69528772
  },
  {
    "ts": 1583971200,
    "supply": 36000000000,
    "trustlines": {
      "total": 113,
      "authorized": 113,
      "funded": 47
    },
    "payments": 2,
    "payments_amount": 4000000,
    "trades": 2,
    "traded_amount": 13000000,
    "price": [
      3.4764386,
      4.8859065,
      3.4764386,
      4.8859065
    ],
    "volume": 63227968
  },
  {
    "ts": 1584057600,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 47
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 3,
    "traded_amount": 21000000,
    "price": [
      4.8859065,
      5.2122805,
      4.8859065,
      5.054737
    ],
    "volume": 106036607
  },
  {
    "ts": 1584144000,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 47
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      5.054737,
      5.3437859,
      4.983507,
      5.3437859
    ],
    "volume": 103272929
  },
  {
    "ts": 1584230400,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 47
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.3437859,
      5.3437859,
      5.3437859,
      5.3437859
    ],
    "volume": 0
  },
  {
    "ts": 1584316800,
    "supply": 36000000000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 89931410,
    "price": [
      5.3437859,
      6.202739,
      5.3437859,
      6.202739
    ],
    "volume": 501650145
  },
  {
    "ts": 1584403200,
    "supply": 36300000000,
    "trustlines": {
      "total": 121,
      "authorized": 121,
      "funded": 46
    },
    "payments": 15,
    "payments_amount": 2985981518,
    "trades": 4,
    "traded_amount": 76981518,
    "price": [
      6.202739,
      6.202739,
      5.2035958,
      5.2035958
    ],
    "volume": 409335021
  },
  {
    "ts": 1584489600,
    "supply": 36800000000,
    "trustlines": {
      "total": 126,
      "authorized": 126,
      "funded": 48
    },
    "payments": 12,
    "payments_amount": 2985757693,
    "trades": 6,
    "traded_amount": 296757693,
    "price": [
      5.2035958,
      5.260502833075928,
      5.156715,
      5.1567151
    ],
    "volume": 1558915134
  },
  {
    "ts": 1584576000,
    "supply": 36800000000,
    "trustlines": {
      "total": 126,
      "authorized": 126,
      "funded": 47
    },
    "payments": 3,
    "payments_amount": 820000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      5.1567151,
      5.1567151,
      5.0275383,
      5.0275383
    ],
    "volume": 50275383
  },
  {
    "ts": 1584662400,
    "supply": 36800000000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 47
    },
    "payments": 3,
    "payments_amount": 1200000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      5.0275383,
      5.0275383,
      4.6169932,
      4.6169932
    ],
    "volume": 46169932
  },
  {
    "ts": 1584748800,
    "supply": 36800000000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 47
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.6169932,
      4.8497099,
      4.6169932,
      4.8497099
    ],
    "volume": 48497099
  },
  {
    "ts": 1584835200,
    "supply": 36800000000,
    "trustlines": {
      "total": 131,
      "authorized": 131,
      "funded": 47
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.8497099,
      4.9320192,
      4.8497099,
      4.9320192
    ],
    "volume": 49320192
  },
  {
    "ts": 1584921600,
    "supply": 36800000000,
    "trustlines": {
      "total": 134,
      "authorized": 134,
      "funded": 51
    },
    "payments": 4,
    "payments_amount": 904780404,
    "trades": 6,
    "traded_amount": 624780604,
    "price": [
      4.9320192,
      5.00454172170328,
      4.8414239,
      5.00454172170328
    ],
    "volume": 3097802588
  },
  {
    "ts": 1585008000,
    "supply": 36800000000,
    "trustlines": {
      "total": 134,
      "authorized": 134,
      "funded": 54
    },
    "payments": 4,
    "payments_amount": 370039334,
    "trades": 7,
    "traded_amount": 380040044,
    "price": [
      5.00454172170328,
      5.0467591,
      4.8144464,
      4.911216185038375
    ],
    "volume": 1896566520
  },
  {
    "ts": 1585094400,
    "supply": 36800000000,
    "trustlines": {
      "total": 134,
      "authorized": 134,
      "funded": 55
    },
    "payments": 4,
    "payments_amount": 559705437,
    "trades": 5,
    "traded_amount": 279705437,
    "price": [
      4.911216185038375,
      4.911216185038375,
      4.7525987,
      4.7525987
    ],
    "volume": 1354068699
  },
  {
    "ts": 1585180800,
    "supply": 36800000000,
    "trustlines": {
      "total": 134,
      "authorized": 134,
      "funded": 55
    },
    "payments": 9,
    "payments_amount": 1946405331,
    "trades": 4,
    "traded_amount": 246405331,
    "price": [
      4.7525987,
      4.893557679664995,
      4.7525987,
      4.893557679664995
    ],
    "volume": 1183120000
  },
  {
    "ts": 1585267200,
    "supply": 119800000000,
    "trustlines": {
      "total": 135,
      "authorized": 135,
      "funded": 56
    },
    "payments": 4,
    "payments_amount": 183000000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.893557679664995,
      4.893557679664995,
      4.6290686,
      4.6290686
    ],
    "volume": 46290686
  },
  {
    "ts": 1585353600,
    "supply": 119800000000,
    "trustlines": {
      "total": 135,
      "authorized": 135,
      "funded": 56
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.6290686,
      4.9579319,
      4.6290686,
      4.9579319
    ],
    "volume": 49579319
  },
  {
    "ts": 1585440000,
    "supply": 119800000000,
    "trustlines": {
      "total": 136,
      "authorized": 136,
      "funded": 56
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.9579319,
      4.9579319,
      4.9579319,
      4.9579319
    ],
    "volume": 0
  },
  {
    "ts": 1585526400,
    "supply": 119800000000,
    "trustlines": {
      "total": 138,
      "authorized": 138,
      "funded": 56
    },
    "payments": 4,
    "payments_amount": 100267920000,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      4.9579319,
      5.1587163,
      4.9034198,
      4.9034198
    ],
    "volume": 100621361
  },
  {
    "ts": 1585612800,
    "supply": 119800000000,
    "trustlines": {
      "total": 143,
      "authorized": 143,
      "funded": 58
    },
    "payments": 5,
    "payments_amount": 1017778151,
    "trades": 3,
    "traded_amount": 283878151,
    "price": [
      4.9034198,
      4.9034198,
      4.719999814574565,
      4.719999814574565
    ],
    "volume": 1351700321
  },
  {
    "ts": 1585699200,
    "supply": 119800000000,
    "trustlines": {
      "total": 144,
      "authorized": 144,
      "funded": 59
    },
    "payments": 2,
    "payments_amount": 46207094,
    "trades": 2,
    "traded_amount": 46207094,
    "price": [
      4.719999814574565,
      4.787052276761399,
      4.719999814574565,
      4.72
    ],
    "volume": 219854729
  },
  {
    "ts": 1585785600,
    "supply": 119800000000,
    "trustlines": {
      "total": 149,
      "authorized": 149,
      "funded": 59
    },
    "payments": 16,
    "payments_amount": 103393602799,
    "trades": 4,
    "traded_amount": 96102799,
    "price": [
      4.72,
      10.0000001570719,
      4.72,
      4.72
    ],
    "volume": 818269291
  },
  {
    "ts": 1585872000,
    "supply": 119800000000,
    "trustlines": {
      "total": 153,
      "authorized": 153,
      "funded": 62
    },
    "payments": 5,
    "payments_amount": 1218460468,
    "trades": 5,
    "traded_amount": 384732980,
    "price": [
      4.72,
      4.8,
      4.5440025,
      4.577487218419765
    ],
    "volume": 1789286307
  },
  {
    "ts": 1585958400,
    "supply": 119800000000,
    "trustlines": {
      "total": 154,
      "authorized": 154,
      "funded": 61
    },
    "payments": 3,
    "payments_amount": 585544244,
    "trades": 2,
    "traded_amount": 285544244,
    "price": [
      4.577487218419765,
      4.586823806280659,
      4.51598565,
      4.586823806280659
    ],
    "volume": 1308324373
  },
  {
    "ts": 1586044800,
    "supply": 119800000000,
    "trustlines": {
      "total": 155,
      "authorized": 155,
      "funded": 61
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 18000000,
    "price": [
      4.586823806280659,
      4.586823806280659,
      4.5159856,
      4.522148
    ],
    "volume": 81337040
  },
  {
    "ts": 1586131200,
    "supply": 119800000000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 61
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 5000000,
    "price": [
      4.522148,
      4.6535276,
      4.522148,
      4.6535276
    ],
    "volume": 23267638
  },
  {
    "ts": 1586217600,
    "supply": 119800000000,
    "trustlines": {
      "total": 161,
      "authorized": 161,
      "funded": 64
    },
    "payments": 8,
    "payments_amount": 2634334568,
    "trades": 7,
    "traded_amount": 1095934668,
    "price": [
      4.6535276,
      4.66,
      4.515985686,
      4.653527674511388
    ],
    "volume": 5031191275
  },
  {
    "ts": 1586304000,
    "supply": 119800000000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 64
    },
    "payments": 5,
    "payments_amount": 4464233499,
    "trades": 6,
    "traded_amount": 7512433499,
    "price": [
      4.653527674511388,
      4.653527968236204,
      3.7838827,
      3.7838827
    ],
    "volume": 33852379411
  },
  {
    "ts": 1586390400,
    "supply": 119800000000,
    "trustlines": {
      "total": 167,
      "authorized": 167,
      "funded": 65
    },
    "payments": 7,
    "payments_amount": 414102602,
    "trades": 8,
    "traded_amount": 409702702,
    "price": [
      3.7838827,
      3.861877,
      3.7477326,
      3.7477326
    ],
    "volume": 1566223354
  },
  {
    "ts": 1586476800,
    "supply": 119800000000,
    "trustlines": {
      "total": 167,
      "authorized": 167,
      "funded": 65
    },
    "payments": 4,
    "payments_amount": 98000,
    "trades": 4,
    "traded_amount": 7235000,
    "price": [
      3.7477326,
      3.862,
      3.7477326,
      3.861876823338736
    ],
    "volume": 27640099
  },
  {
    "ts": 1586563200,
    "supply": 119800000000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 66
    },
    "payments": 1,
    "payments_amount": 52792801,
    "trades": 1,
    "traded_amount": 52792801,
    "price": [
      3.861876823338736,
      3.861876823338736,
      3.861876432735592,
      3.861876432735592
    ],
    "volume": 203879274
  },
  {
    "ts": 1586649600,
    "supply": 119800000000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 66
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.861876432735592,
      3.861876432735592,
      3.861876432735592,
      3.861876432735592
    ],
    "volume": 0
  },
  {
    "ts": 1586736000,
    "supply": 119800000000,
    "trustlines": {
      "total": 170,
      "authorized": 170,
      "funded": 67
    },
    "payments": 3,
    "payments_amount": 664735370,
    "trades": 3,
    "traded_amount": 72999151,
    "price": [
      3.861876432735592,
      3.8618797332210812,
      3.747732621763833,
      3.8618764363283935
    ],
    "volume": 280999999
  },
  {
    "ts": 1586822400,
    "supply": 119800000000,
    "trustlines": {
      "total": 170,
      "authorized": 170,
      "funded": 65
    },
    "payments": 11,
    "payments_amount": 1910273593,
    "trades": 7,
    "traded_amount": 664435933,
    "price": [
      3.8618764363283935,
      3.861877,
      3.8458994248098333,
      3.861876442998635
    ],
    "volume": 2563132997
  },
  {
    "ts": 1586908800,
    "supply": 119800000000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 63
    },
    "payments": 8,
    "payments_amount": 989222685,
    "trades": 6,
    "traded_amount": 109222685,
    "price": [
      3.861876442998635,
      3.861876442998635,
      3.7477325454545456,
      3.7477326
    ],
    "volume": 409337418
  },
  {
    "ts": 1586995200,
    "supply": 379800000000,
    "trustlines": {
      "total": 170,
      "authorized": 170,
      "funded": 63
    },
    "payments": 5,
    "payments_amount": 760064735370,
    "trades": 2,
    "traded_amount": 65735370,
    "price": [
      3.7477326,
      3.861877,
      3.7477326,
      3.8618764363283935
    ],
    "volume": 253861877
  },
  {
    "ts": 1587081600,
    "supply": 379800000000,
    "trustlines": {
      "total": 171,
      "authorized": 171,
      "funded": 64
    },
    "payments": 8,
    "payments_amount": 972010000,
    "trades": 10,
    "traded_amount": 489011000,
    "price": [
      3.8618764363283935,
      3.9293464,
      3.8592485359234394,
      3.8592485666666665
    ],
    "volume": 1916525717
  },
  {
    "ts": 1587168000,
    "supply": 379800000000,
    "trustlines": {
      "total": 172,
      "authorized": 172,
      "funded": 64
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.8592485666666665,
      3.8592485666666665,
      3.8592485666666665,
      3.8592485666666665
    ],
    "volume": 0
  },
  {
    "ts": 1587254400,
    "supply": 379800000000,
    "trustlines": {
      "total": 172,
      "authorized": 172,
      "funded": 64
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.8592485666666665,
      3.8592485666666665,
      3.8592485666666665,
      3.8592485666666665
    ],
    "volume": 0
  },
  {
    "ts": 1587340800,
    "supply": 379800000000,
    "trustlines": {
      "total": 174,
      "authorized": 174,
      "funded": 66
    },
    "payments": 4,
    "payments_amount": 553511581,
    "trades": 6,
    "traded_amount": 573511581,
    "price": [
      3.8592485666666665,
      3.929346418890508,
      3.8592485,
      3.85924856
    ],
    "volume": 2248618820
  },
  {
    "ts": 1587427200,
    "supply": 379800000000,
    "trustlines": {
      "total": 175,
      "authorized": 175,
      "funded": 67
    },
    "payments": 1,
    "payments_amount": 5089905,
    "trades": 3,
    "traded_amount": 15090105,
    "price": [
      3.85924856,
      3.93,
      3.8592485,
      3.929346421986265
    ],
    "volume": 58593271
  },
  {
    "ts": 1587513600,
    "supply": 379800000000,
    "trustlines": {
      "total": 176,
      "authorized": 176,
      "funded": 66
    },
    "payments": 1,
    "payments_amount": 157630854,
    "trades": 2,
    "traded_amount": 167630854,
    "price": [
      3.929346421986265,
      3.929346421986265,
      3.8592485,
      3.859248570714462
    ],
    "volume": 646929133
  },
  {
    "ts": 1587600000,
    "supply": 379800000000,
    "trustlines": {
      "total": 177,
      "authorized": 177,
      "funded": 66
    },
    "payments": 12,
    "payments_amount": 17674353917,
    "trades": 10,
    "traded_amount": 11359940462,
    "price": [
      3.859248570714462,
      3.8592485736142224,
      2.9035439741808102,
      2.9827123177454253
    ],
    "volume": 38092074808
  },
  {
    "ts": 1587686400,
    "supply": 379800000000,
    "trustlines": {
      "total": 180,
      "authorized": 180,
      "funded": 67
    },
    "payments": 5,
    "payments_amount": 1500000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.9827123177454253,
      2.9827123177454253,
      2.9827116,
      2.9827116
    ],
    "volume": 29827116
  },
  {
    "ts": 1587772800,
    "supply": 379800000000,
    "trustlines": {
      "total": 180,
      "authorized": 180,
      "funded": 67
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.9827116,
      2.9827116,
      2.9827116,
      2.9827116
    ],
    "volume": 29827116
  },
  {
    "ts": 1587859200,
    "supply": 379800000000,
    "trustlines": {
      "total": 180,
      "authorized": 180,
      "funded": 67
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.9827116,
      2.9827116,
      2.8945528,
      2.8945528
    ],
    "volume": 28945528
  },
  {
    "ts": 1587945600,
    "supply": 379800000000,
    "trustlines": {
      "total": 188,
      "authorized": 188,
      "funded": 66
    },
    "payments": 8,
    "payments_amount": 2545400000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.8945528,
      2.8945528,
      2.8945528,
      2.8945528
    ],
    "volume": 28945528
  },
  {
    "ts": 1588032000,
    "supply": 379800000000,
    "trustlines": {
      "total": 190,
      "authorized": 190,
      "funded": 67
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 234685780,
    "price": [
      2.8945528,
      2.9827116027225853,
      2.8945528,
      2.9827116027225853
    ],
    "volume": 699999999
  },
  {
    "ts": 1588118400,
    "supply": 379800000000,
    "trustlines": {
      "total": 200,
      "authorized": 200,
      "funded": 67
    },
    "payments": 7,
    "payments_amount": 1058137259,
    "trades": 3,
    "traded_amount": 110100579,
    "price": [
      2.9827116027225853,
      2.9827299933385696,
      2.89455288,
      2.9827299933385696
    ],
    "volume": 319582404
  },
  {
    "ts": 1588204800,
    "supply": 379800000000,
    "trustlines": {
      "total": 202,
      "authorized": 202,
      "funded": 67
    },
    "payments": 12,
    "payments_amount": 13797707261,
    "trades": 9,
    "traded_amount": 8408832804,
    "price": [
      2.9827299933385696,
      2.983,
      2.699099000480771,
      2.699099000480771
    ],
    "volume": 23746812389
  },
  {
    "ts": 1588291200,
    "supply": 379800000000,
    "trustlines": {
      "total": 203,
      "authorized": 203,
      "funded": 67
    },
    "payments": 10,
    "payments_amount": 10411750612,
    "trades": 15,
    "traded_amount": 13431750612,
    "price": [
      2.699099000480771,
      2.7020384134226214,
      2.5552339366666668,
      2.5552339366666668
    ],
    "volume": 35502590210
  },
  {
    "ts": 1588377600,
    "supply": 379800000000,
    "trustlines": {
      "total": 202,
      "authorized": 202,
      "funded": 67
    },
    "payments": 4,
    "payments_amount": 1200000000,
    "trades": 2,
    "traded_amount": 20000000,
    "price": [
      2.5552339366666668,
      2.6016456,
      2.5552339,
      2.5552339
    ],
    "volume": 51568795
  },
  {
    "ts": 1588464000,
    "supply": 379800000000,
    "trustlines": {
      "total": 202,
      "authorized": 202,
      "funded": 67
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 16000000,
    "price": [
      2.5552339,
      2.5552339,
      2.555233875,
      2.555233875
    ],
    "volume": 40883742
  },
  {
    "ts": 1588550400,
    "supply": 379800000000,
    "trustlines": {
      "total": 201,
      "authorized": 201,
      "funded": 68
    },
    "payments": 7,
    "payments_amount": 10726412668,
    "trades": 6,
    "traded_amount": 5726402668,
    "price": [
      2.555233875,
      2.6192409439744364,
      2.5417,
      2.5417789757412397
    ],
    "volume": 14899959107
  },
  {
    "ts": 1588636800,
    "supply": 379800000000,
    "trustlines": {
      "total": 204,
      "authorized": 204,
      "funded": 68
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 2,
    "traded_amount": 124538641,
    "price": [
      2.5417789757412397,
      2.6192034005362435,
      2.5417789757412397,
      2.6192034005362435
    ],
    "volume": 325417880
  },
  {
    "ts": 1588723200,
    "supply": 379800000000,
    "trustlines": {
      "total": 204,
      "authorized": 204,
      "funded": 70
    },
    "payments": 10,
    "payments_amount": 2642736109,
    "trades": 2,
    "traded_amount": 29932696,
    "price": [
      2.6192034005362435,
      2.6192034005362435,
      2.5084414070229135,
      2.5084414070229135
    ],
    "volume": 75177819
  },
  {
    "ts": 1588809600,
    "supply": 379800000000,
    "trustlines": {
      "total": 205,
      "authorized": 205,
      "funded": 69
    },
    "payments": 2,
    "payments_amount": 320906247,
    "trades": 2,
    "traded_amount": 30906247,
    "price": [
      2.5084414070229135,
      2.5084414070229135,
      2.391629640652385,
      2.391629640652385
    ],
    "volume": 74428781
  },
  {
    "ts": 1588896000,
    "supply": 379800000000,
    "trustlines": {
      "total": 204,
      "authorized": 204,
      "funded": 69
    },
    "payments": 4,
    "payments_amount": 1066584106,
    "trades": 3,
    "traded_amount": 77567264,
    "price": [
      2.391629640652385,
      2.454239,
      2.391629640652385,
      2.454239
    ],
    "volume": 187081099
  },
  {
    "ts": 1588982400,
    "supply": 379800000000,
    "trustlines": {
      "total": 204,
      "authorized": 204,
      "funded": 69
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.454239,
      2.454239,
      2.454239,
      2.454239
    ],
    "volume": 0
  },
  {
    "ts": 1589068800,
    "supply": 379800000000,
    "trustlines": {
      "total": 206,
      "authorized": 206,
      "funded": 70
    },
    "payments": 1,
    "payments_amount": 362114849,
    "trades": 1,
    "traded_amount": 362114849,
    "price": [
      2.454239,
      2.76155480163698,
      2.454239,
      2.76155480163698
    ],
    "volume": 1000000000
  },
  {
    "ts": 1589155200,
    "supply": 379800000000,
    "trustlines": {
      "total": 208,
      "authorized": 208,
      "funded": 71
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 71000000,
    "price": [
      2.76155480163698,
      2.8234051,
      2.6732635,
      2.8234051
    ],
    "volume": 197398419
  },
  {
    "ts": 1589241600,
    "supply": 379800000000,
    "trustlines": {
      "total": 209,
      "authorized": 209,
      "funded": 71
    },
    "payments": 4,
    "payments_amount": 1200000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.8234051,
      2.8234051,
      2.6209009,
      2.6209009
    ],
    "volume": 26209009
  },
  {
    "ts": 1589328000,
    "supply": 379800000000,
    "trustlines": {
      "total": 212,
      "authorized": 212,
      "funded": 75
    },
    "payments": 4,
    "payments_amount": 1077926866,
    "trades": 5,
    "traded_amount": 488914466,
    "price": [
      2.6209009,
      2.6209009,
      2.3944393454846726,
      2.5056536073558258
    ],
    "volume": 1184230042
  },
  {
    "ts": 1589414400,
    "supply": 379800000000,
    "trustlines": {
      "total": 212,
      "authorized": 212,
      "funded": 78
    },
    "payments": 15,
    "payments_amount": 1750421188,
    "trades": 5,
    "traded_amount": 793021188,
    "price": [
      2.5056536073558258,
      2.5232072182159597,
      2.4582875,
      2.520476814715986
    ],
    "volume": 1964345791
  },
  {
    "ts": 1589500800,
    "supply": 379800000000,
    "trustlines": {
      "total": 215,
      "authorized": 215,
      "funded": 80
    },
    "payments": 16,
    "payments_amount": 14284328463,
    "trades": 1,
    "traded_amount": 115728463,
    "price": [
      2.520476814715986,
      2.520476814715986,
      2.5143970157108195,
      2.5143970157108195
    ],
    "volume": 290987302
  },
  {
    "ts": 1589587200,
    "supply": 379800000000,
    "trustlines": {
      "total": 215,
      "authorized": 215,
      "funded": 79
    },
    "payments": 13,
    "payments_amount": 14680815138,
    "trades": 2,
    "traded_amount": 782415138,
    "price": [
      2.5143970157108195,
      2.5561383056705744,
      2.480221472392638,
      2.480221472392638
    ],
    "volume": 1970262640
  },
  {
    "ts": 1589673600,
    "supply": 379800000000,
    "trustlines": {
      "total": 215,
      "authorized": 215,
      "funded": 79
    },
    "payments": 11,
    "payments_amount": 5996068777,
    "trades": 1,
    "traded_amount": 58068777,
    "price": [
      2.480221472392638,
      2.534197715236882,
      2.480221472392638,
      2.534197715236882
    ],
    "volume": 147157762
  },
  {
    "ts": 1589760000,
    "supply": 379800000000,
    "trustlines": {
      "total": 215,
      "authorized": 215,
      "funded": 80
    },
    "payments": 7,
    "payments_amount": 1610860973,
    "trades": 2,
    "traded_amount": 307060973,
    "price": [
      2.534197715236882,
      2.5391226,
      2.524734206670763,
      2.524734206670763
    ],
    "volume": 775391226
  },
  {
    "ts": 1589846400,
    "supply": 379800000000,
    "trustlines": {
      "total": 223,
      "authorized": 223,
      "funded": 82
    },
    "payments": 5,
    "payments_amount": 1512724345,
    "trades": 7,
    "traded_amount": 1512727545,
    "price": [
      2.524734206670763,
      2.57585122942835,
      2.4886102495593576,
      2.4886102495593576
    ],
    "volume": 3826293048
  },
  {
    "ts": 1589932800,
    "supply": 379800000000,
    "trustlines": {
      "total": 225,
      "authorized": 225,
      "funded": 83
    },
    "payments": 2,
    "payments_amount": 177726700,
    "trades": 2,
    "traded_amount": 177726700,
    "price": [
      2.4886102495593576,
      2.5594457,
      2.4886102495593576,
      2.506601000317779
    ],
    "volume": 446018371
  },
  {
    "ts": 1590019200,
    "supply": 379800000000,
    "trustlines": {
      "total": 229,
      "authorized": 229,
      "funded": 83
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 2,
    "traded_amount": 107465058,
    "price": [
      2.506601000317779,
      2.6791485076204364,
      2.506601000317779,
      2.6702783
    ],
    "volume": 287027829
  },
  {
    "ts": 1590105600,
    "supply": 379800000000,
    "trustlines": {
      "total": 230,
      "authorized": 230,
      "funded": 86
    },
    "payments": 15,
    "payments_amount": 2437734676,
    "trades": 10,
    "traded_amount": 381135076,
    "price": [
      2.6702783,
      2.6913565985275714,
      2.58,
      2.6866931738230644
    ],
    "volume": 1007972259
  },
  {
    "ts": 1590192000,
    "supply": 379800000000,
    "trustlines": {
      "total": 231,
      "authorized": 231,
      "funded": 86
    },
    "payments": 1,
    "payments_amount": 2041302,
    "trades": 1,
    "traded_amount": 2041302,
    "price": [
      2.6866931738230644,
      2.6866931738230644,
      2.600772448172784,
      2.600772448172784
    ],
    "volume": 5308962
  },
  {
    "ts": 1590278400,
    "supply": 379800000000,
    "trustlines": {
      "total": 232,
      "authorized": 232,
      "funded": 86
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.600772448172784,
      2.600772448172784,
      2.600772448172784,
      2.600772448172784
    ],
    "volume": 0
  },
  {
    "ts": 1590364800,
    "supply": 379800000000,
    "trustlines": {
      "total": 232,
      "authorized": 232,
      "funded": 86
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 10562848,
    "price": [
      2.600772448172784,
      2.84288653419751,
      2.600772448172784,
      2.8178438
    ],
    "volume": 29776543
  },
  {
    "ts": 1590451200,
    "supply": 379800000000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 87
    },
    "payments": 10,
    "payments_amount": 1205857573,
    "trades": 3,
    "traded_amount": 6253754,
    "price": [
      2.8178438,
      2.9467820988885935,
      2.8178438,
      2.9467820988885935
    ],
    "volume": 18335303
  },
  {
    "ts": 1590537600,
    "supply": 379800000000,
    "trustlines": {
      "total": 238,
      "authorized": 238,
      "funded": 87
    },
    "payments": 8,
    "payments_amount": 362658291,
    "trades": 7,
    "traded_amount": 62658291,
    "price": [
      2.9467820988885935,
      2.9467820988885935,
      2.80201,
      2.8817368089752744
    ],
    "volume": 179803977
  },
  {
    "ts": 1590624000,
    "supply": 379800000000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 86
    },
    "payments": 3,
    "payments_amount": 620000000,
    "trades": 3,
    "traded_amount": 20263447,
    "price": [
      2.8817368089752744,
      2.927400468384075,
      2.7536686,
      2.7536686
    ],
    "volume": 55819059
  },
  {
    "ts": 1590710400,
    "supply": 379800000000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 86
    },
    "payments": 4,
    "payments_amount": 910000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.7536686,
      2.7536686,
      2.6946435,
      2.6946435
    ],
    "volume": 26946435
  },
  {
    "ts": 1590796800,
    "supply": 379800000000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 86
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      2.6946435,
      2.7390037,
      2.6946435,
      2.7390037
    ],
    "volume": 27390037
  },
  {
    "ts": 1590883200,
    "supply": 379800000000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 86
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.7390037,
      2.7390037,
      2.7390037,
      2.7390037
    ],
    "volume": 0
  },
  {
    "ts": 1590969600,
    "supply": 379800000000,
    "trustlines": {
      "total": 239,
      "authorized": 239,
      "funded": 87
    },
    "payments": 12,
    "payments_amount": 2286408166,
    "trades": 4,
    "traded_amount": 123008146,
    "price": [
      2.7390037,
      2.7390037,
      2.45,
      2.45
    ],
    "volume": 305725637
  },
  {
    "ts": 1591056000,
    "supply": 379800000000,
    "trustlines": {
      "total": 238,
      "authorized": 238,
      "funded": 86
    },
    "payments": 1,
    "payments_amount": 20530255,
    "trades": 8,
    "traded_amount": 31994255,
    "price": [
      2.45,
      2.45,
      2.2265261669024046,
      2.435430051891708
    ],
    "volume": 77812767
  },
  {
    "ts": 1591142400,
    "supply": 379800000000,
    "trustlines": {
      "total": 245,
      "authorized": 245,
      "funded": 87
    },
    "payments": 7,
    "payments_amount": 1014702666,
    "trades": 2,
    "traded_amount": 71302666,
    "price": [
      2.435430051891708,
      2.479970300178942,
      2.4010872,
      2.4010872
    ],
    "volume": 175250832
  },
  {
    "ts": 1591228800,
    "supply": 379800000000,
    "trustlines": {
      "total": 248,
      "authorized": 248,
      "funded": 88
    },
    "payments": 14,
    "payments_amount": 2185754254,
    "trades": 10,
    "traded_amount": 873405801,
    "price": [
      2.4010872,
      2.4010872,
      2.3416139634530673,
      2.3941339545603784
    ],
    "volume": 2063092448
  },
  {
    "ts": 1591315200,
    "supply": 379800000000,
    "trustlines": {
      "total": 251,
      "authorized": 251,
      "funded": 93
    },
    "payments": 16,
    "payments_amount": 3359135020,
    "trades": 2,
    "traded_amount": 21618557,
    "price": [
      2.3941339545603784,
      2.463264,
      2.37531448005794,
      2.463264
    ],
    "volume": 51438821
  },
  {
    "ts": 1591401600,
    "supply": 379800000000,
    "trustlines": {
      "total": 253,
      "authorized": 253,
      "funded": 91
    },
    "payments": 2,
    "payments_amount": 376189986,
    "trades": 2,
    "traded_amount": 376189986,
    "price": [
      2.463264,
      2.5420155794176553,
      2.463264,
      2.5094792033785778
    ],
    "volume": 954390482
  },
  {
    "ts": 1591488000,
    "supply": 379800000000,
    "trustlines": {
      "total": 254,
      "authorized": 254,
      "funded": 91
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.5094792033785778,
      2.5094792033785778,
      2.5094792033785778,
      2.5094792033785778
    ],
    "volume": 0
  },
  {
    "ts": 1591574400,
    "supply": 379800000000,
    "trustlines": {
      "total": 256,
      "authorized": 256,
      "funded": 92
    },
    "payments": 8,
    "payments_amount": 890720445,
    "trades": 7,
    "traded_amount": 247180445,
    "price": [
      2.5094792033785778,
      2.6296779380625677,
      2.50245,
      2.6296779380625677
    ],
    "volume": 632351929
  },
  {
    "ts": 1591660800,
    "supply": 379800000000,
    "trustlines": {
      "total": 269,
      "authorized": 269,
      "funded": 94
    },
    "payments": 11,
    "payments_amount": 1345557913,
    "trades": 7,
    "traded_amount": 724557913,
    "price": [
      2.6296779380625677,
      2.6607674492117046,
      2.59168844444056,
      2.6034157968188922
    ],
    "volume": 1899704639
  },
  {
    "ts": 1591747200,
    "supply": 379800000000,
    "trustlines": {
      "total": 272,
      "authorized": 272,
      "funded": 95
    },
    "payments": 5,
    "payments_amount": 1159683156,
    "trades": 6,
    "traded_amount": 909483156,
    "price": [
      2.6034157968188922,
      2.6034157968188922,
      0.1,
      2.5619798179404727
    ],
    "volume": 552526340
  },
  {
    "ts": 1591833600,
    "supply": 379800000000,
    "trustlines": {
      "total": 280,
      "authorized": 280,
      "funded": 95
    },
    "payments": 4,
    "payments_amount": 663395440,
    "trades": 8,
    "traded_amount": 255133997,
    "price": [
      2.5619798179404727,
      2.8030619,
      1,
      2.8030619
    ],
    "volume": 441115737
  },
  {
    "ts": 1591920000,
    "supply": 379800000000,
    "trustlines": {
      "total": 282,
      "authorized": 282,
      "funded": 95
    },
    "payments": 18,
    "payments_amount": 13723704837,
    "trades": 4,
    "traded_amount": 112004837,
    "price": [
      2.8030619,
      2.8073508,
      2.68922791,
      2.68922791
    ],
    "volume": 296996299
  },
  {
    "ts": 1592006400,
    "supply": 379800000000,
    "trustlines": {
      "total": 286,
      "authorized": 286,
      "funded": 98
    },
    "payments": 3,
    "payments_amount": 43400000,
    "trades": 24,
    "traded_amount": 226655679284,
    "price": [
      2.68922791,
      3.0000005360154116,
      0.014687503281689924,
      2.71912
    ],
    "volume": 35907701695
  },
  {
    "ts": 1592092800,
    "supply": 379800000000,
    "trustlines": {
      "total": 287,
      "authorized": 287,
      "funded": 97
    },
    "payments": 1,
    "payments_amount": 9991100000,
    "trades": 3,
    "traded_amount": 9992186050,
    "price": [
      2.71912,
      2.800679526725289,
      2.71912,
      2.800679526725289
    ],
    "volume": 1520839
  },
  {
    "ts": 1592179200,
    "supply": 379800000000,
    "trustlines": {
      "total": 330,
      "authorized": 330,
      "funded": 104
    },
    "payments": 31,
    "payments_amount": 4763282581,
    "trades": 157,
    "traded_amount": 596629817047,
    "price": [
      2.800679526725289,
      3.012,
      2.655737433131438,
      2.7632199224796334
    ],
    "volume": 824569116384
  },
  {
    "ts": 1592265600,
    "supply": 419800000000,
    "trustlines": {
      "total": 417,
      "authorized": 417,
      "funded": 111
    },
    "payments": 33,
    "payments_amount": 44565914174,
    "trades": 56,
    "traded_amount": 16472227382,
    "price": [
      2.7632199224796334,
      2.7923154965998926,
      2.687716942778544,
      2.7121955515151517
    ],
    "volume": 24182904907
  },
  {
    "ts": 1592352000,
    "supply": 619800000000,
    "trustlines": {
      "total": 448,
      "authorized": 448,
      "funded": 114
    },
    "payments": 23,
    "payments_amount": 202171459032,
    "trades": 27,
    "traded_amount": 11612883320,
    "price": [
      2.7121955515151517,
      2.7318988390589767,
      2.60336811210805,
      2.684848129591959
    ],
    "volume": 16127099166
  },
  {
    "ts": 1592438400,
    "supply": 619800000000,
    "trustlines": {
      "total": 462,
      "authorized": 462,
      "funded": 115
    },
    "payments": 26,
    "payments_amount": 5290527400,
    "trades": 21,
    "traded_amount": 2481723409,
    "price": [
      2.684848129591959,
      2.6926304179018645,
      2.652,
      2.659706413246907
    ],
    "volume": 4118118179
  },
  {
    "ts": 1592524800,
    "supply": 719800000000,
    "trustlines": {
      "total": 470,
      "authorized": 470,
      "funded": 117
    },
    "payments": 11,
    "payments_amount": 116800000080,
    "trades": 9,
    "traded_amount": 2722614878,
    "price": [
      2.659706413246907,
      2.679257222927049,
      2.6423967245813866,
      2.6423967245813866
    ],
    "volume": 4776457988
  },
  {
    "ts": 1592611200,
    "supply": 719800000000,
    "trustlines": {
      "total": 474,
      "authorized": 474,
      "funded": 116
    },
    "payments": 3,
    "payments_amount": 10535830182,
    "trades": 6,
    "traded_amount": 10553924024,
    "price": [
      2.6423967245813866,
      2.7067199721545174,
      2.6423967245813866,
      2.7067126456310517
    ],
    "volume": 27067135292
  },
  {
    "ts": 1592697600,
    "supply": 719800000000,
    "trustlines": {
      "total": 477,
      "authorized": 477,
      "funded": 116
    },
    "payments": 5,
    "payments_amount": 856230974,
    "trades": 3,
    "traded_amount": 456230974,
    "price": [
      2.7067126456310517,
      2.720309476914411,
      2.7067126456310517,
      2.720309476914411
    ],
    "volume": 480000000
  },
  {
    "ts": 1592784000,
    "supply": 719800000000,
    "trustlines": {
      "total": 486,
      "authorized": 486,
      "funded": 118
    },
    "payments": 18,
    "payments_amount": 4939483711,
    "trades": 19,
    "traded_amount": 10135155032,
    "price": [
      2.720309476914411,
      2.7931114459437594,
      2.6813126845921085,
      2.697356101335358
    ],
    "volume": 24418763548
  },
  {
    "ts": 1592870400,
    "supply": 719800000000,
    "trustlines": {
      "total": 496,
      "authorized": 496,
      "funded": 119
    },
    "payments": 4,
    "payments_amount": 1200100000,
    "trades": 6,
    "traded_amount": 336611634,
    "price": [
      2.697356101335358,
      2.7231442309331206,
      2.6946154069021464,
      2.7231442309331206
    ],
    "volume": 247894891
  },
  {
    "ts": 1592956800,
    "supply": 719800000000,
    "trustlines": {
      "total": 505,
      "authorized": 505,
      "funded": 121
    },
    "payments": 12,
    "payments_amount": 368538003176,
    "trades": 15,
    "traded_amount": 19459228336,
    "price": [
      2.7231442309331206,
      2.7631853113307554,
      2.7186769539007716,
      2.72047314
    ],
    "volume": 22698616321
  },
  {
    "ts": 1593043200,
    "supply": 719800000000,
    "trustlines": {
      "total": 517,
      "authorized": 517,
      "funded": 124
    },
    "payments": 12,
    "payments_amount": 2421533459,
    "trades": 6,
    "traded_amount": 18801293,
    "price": [
      2.72047314,
      2.820448017147982,
      2.72047314,
      2.771283266380359
    ],
    "volume": 28585730
  },
  {
    "ts": 1593129600,
    "supply": 719800000000,
    "trustlines": {
      "total": 526,
      "authorized": 526,
      "funded": 124
    },
    "payments": 22,
    "payments_amount": 4872090158,
    "trades": 41,
    "traded_amount": 28881639223,
    "price": [
      2.771283266380359,
      2.771283266380359,
      2.709922658843321,
      2.73401
    ],
    "volume": 41682899368
  },
  {
    "ts": 1593216000,
    "supply": 719800000000,
    "trustlines": {
      "total": 532,
      "authorized": 532,
      "funded": 123
    },
    "payments": 1,
    "payments_amount": 1040810,
    "trades": 62,
    "traded_amount": 436297777327,
    "price": [
      2.73401,
      2.9907887497161045,
      2.73401,
      2.9191545965119716
    ],
    "volume": 639479973582
  },
  {
    "ts": 1593302400,
    "supply": 719800000000,
    "trustlines": {
      "total": 536,
      "authorized": 536,
      "funded": 123
    },
    "payments": 1,
    "payments_amount": 200000000,
    "trades": 27,
    "traded_amount": 133460124707,
    "price": [
      2.9191545965119716,
      2.9543220408016873,
      2.830905095,
      2.830905095
    ],
    "volume": 194004938538
  },
  {
    "ts": 1593388800,
    "supply": 719800000000,
    "trustlines": {
      "total": 540,
      "authorized": 540,
      "funded": 123
    },
    "payments": 4,
    "payments_amount": 500034816,
    "trades": 119,
    "traded_amount": 525874427815,
    "price": [
      2.830905095,
      2.9476133643466182,
      2.830905095,
      2.8450474957712766
    ],
    "volume": 765080007851
  },
  {
    "ts": 1593475200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 548,
      "authorized": 548,
      "funded": 128
    },
    "payments": 8,
    "payments_amount": 401154206924,
    "trades": 12,
    "traded_amount": 54613040,
    "price": [
      2.8450474957712766,
      2.87,
      2.8450474957712766,
      2.87
    ],
    "volume": 153793656
  },
  {
    "ts": 1593561600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 550,
      "authorized": 550,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 19,
    "traded_amount": 1001876,
    "price": [
      2.87,
      2.87,
      2.740560949298813,
      2.742335
    ],
    "volume": 1647661
  },
  {
    "ts": 1593648000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 554,
      "authorized": 554,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 2146814183,
    "price": [
      2.742335,
      2.836007442639083,
      2.742335,
      2.7740620757418815
    ],
    "volume": 6038254785
  },
  {
    "ts": 1593734400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 564,
      "authorized": 564,
      "funded": 129
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.7740620757418815,
      2.7740620757418815,
      2.7740620757418815,
      2.7740620757418815
    ],
    "volume": 0
  },
  {
    "ts": 1593820800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 566,
      "authorized": 566,
      "funded": 129
    },
    "payments": 1,
    "payments_amount": 67650200,
    "trades": 1,
    "traded_amount": 67650200,
    "price": [
      2.7740620757418815,
      2.7803471830090674,
      2.7740620757418815,
      2.7803471830090674
    ],
    "volume": 188091043
  },
  {
    "ts": 1593907200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 567,
      "authorized": 567,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.7803471830090674,
      2.7803471830090674,
      2.7803471830090674,
      2.7803471830090674
    ],
    "volume": 0
  },
  {
    "ts": 1593993600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 572,
      "authorized": 572,
      "funded": 129
    },
    "payments": 10,
    "payments_amount": 1584330449,
    "trades": 24,
    "traded_amount": 46156271238,
    "price": [
      2.7803471830090674,
      2.8274555127960337,
      2.648113996428396,
      2.648113996428396
    ],
    "volume": 69350621152
  },
  {
    "ts": 1594080000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 577,
      "authorized": 577,
      "funded": 130
    },
    "payments": 11,
    "payments_amount": 661386685,
    "trades": 13,
    "traded_amount": 17137700448,
    "price": [
      2.648113996428396,
      2.648113996428396,
      2.5576100488250275,
      2.5576100488250275
    ],
    "volume": 23948748153
  },
  {
    "ts": 1594166400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 578,
      "authorized": 578,
      "funded": 129
    },
    "payments": 4,
    "payments_amount": 887950154,
    "trades": 56,
    "traded_amount": 22485234385,
    "price": [
      2.5576100488250275,
      2.5576100488250275,
      2.196910519589379,
      2.196910519589379
    ],
    "volume": 26742037225
  },
  {
    "ts": 1594252800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 584,
      "authorized": 584,
      "funded": 132
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 90,
    "traded_amount": 336242799086,
    "price": [
      2.196910519589379,
      2.221198142133383,
      1.9609523678920902,
      2.1527876447211494
    ],
    "volume": 342178658840
  },
  {
    "ts": 1594339200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 587,
      "authorized": 587,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 46498429,
    "trades": 25,
    "traded_amount": 52280531230,
    "price": [
      2.1527876447211494,
      2.2446478477728897,
      2.0969282480334446,
      2.125398513520486
    ],
    "volume": 61511835832
  },
  {
    "ts": 1594425600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 591,
      "authorized": 591,
      "funded": 134
    },
    "payments": 1,
    "payments_amount": 5700000,
    "trades": 39,
    "traded_amount": 167032399094,
    "price": [
      2.125398513520486,
      2.125398513520486,
      1.9717843084267042,
      1.9945714035087718
    ],
    "volume": 166737369604
  },
  {
    "ts": 1594512000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 591,
      "authorized": 591,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 20,
    "traded_amount": 21314312582,
    "price": [
      1.9945714035087718,
      2.051151533624003,
      1.9945714035087718,
      2.0133686367309247
    ],
    "volume": 23028594424
  },
  {
    "ts": 1594598400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 594,
      "authorized": 594,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 353798667,
    "trades": 169,
    "traded_amount": 1244819125356,
    "price": [
      2.0133686367309247,
      2.1176791587907307,
      0.025,
      2.056249893245858
    ],
    "volume": 1228843552081
  },
  {
    "ts": 1594684800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 606,
      "authorized": 606,
      "funded": 134
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 3,
    "traded_amount": 1508531964,
    "price": [
      2.056249893245858,
      2.09800000065229,
      2.056249893245858,
      2.0698924731182795
    ],
    "volume": 3164899666
  },
  {
    "ts": 1594771200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 609,
      "authorized": 609,
      "funded": 133
    },
    "payments": 5,
    "payments_amount": 13157161,
    "trades": 16,
    "traded_amount": 13647772919,
    "price": [
      2.0698924731182795,
      2.0779024069455625,
      1.9517056809386164,
      1.9957905997813903
    ],
    "volume": 17045909780
  },
  {
    "ts": 1594857600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 616,
      "authorized": 616,
      "funded": 133
    },
    "payments": 1,
    "payments_amount": 199995,
    "trades": 32,
    "traded_amount": 129810154017,
    "price": [
      1.9957905997813903,
      2.1024897003251573,
      1.8327922420532226,
      1.8327922420532226
    ],
    "volume": 136977252568
  },
  {
    "ts": 1594944000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 618,
      "authorized": 618,
      "funded": 134
    },
    "payments": 3,
    "payments_amount": 1645395172,
    "trades": 6,
    "traded_amount": 29417662908,
    "price": [
      1.8327922420532226,
      1.8327922420532226,
      1.7506860870597676,
      1.759419073287611
    ],
    "volume": 25764511990
  },
  {
    "ts": 1595030400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 619,
      "authorized": 619,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 14,
    "traded_amount": 21052984440,
    "price": [
      1.759419073287611,
      1.8457269890587178,
      1.759419073287611,
      1.8281922134494963
    ],
    "volume": 20653675165
  },
  {
    "ts": 1595116800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 620,
      "authorized": 620,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 13,
    "traded_amount": 37818740891,
    "price": [
      1.8281922134494963,
      1.9087031718002594,
      1.8281922134494963,
      1.8990512333965845
    ],
    "volume": 35977909652
  },
  {
    "ts": 1595203200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 621,
      "authorized": 621,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 12,
    "traded_amount": 21246146692,
    "price": [
      1.8990512333965845,
      1.9770781487694158,
      1.8577046554750458,
      1.9770781487694158
    ],
    "volume": 20581263526
  },
  {
    "ts": 1595289600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 624,
      "authorized": 624,
      "funded": 136
    },
    "payments": 13,
    "payments_amount": 1223902526,
    "trades": 7,
    "traded_amount": 3722526,
    "price": [
      1.9770781487694158,
      2.02662,
      1.961085,
      2.02662
    ],
    "volume": 2458092
  },
  {
    "ts": 1595376000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 628,
      "authorized": 628,
      "funded": 138
    },
    "payments": 2,
    "payments_amount": 10281606942,
    "trades": 7,
    "traded_amount": 10326736439,
    "price": [
      2.02662,
      2.0406596366170224,
      2.0140298202676865,
      2.0140298202676865
    ],
    "volume": 1143888215
  },
  {
    "ts": 1595462400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 632,
      "authorized": 632,
      "funded": 136
    },
    "payments": 5,
    "payments_amount": 810047345,
    "trades": 8,
    "traded_amount": 656763811,
    "price": [
      2.0140298202676865,
      2.0140298202676865,
      1.954340512820513,
      1.969042156788106
    ],
    "volume": 850103412
  },
  {
    "ts": 1595548800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 636,
      "authorized": 636,
      "funded": 136
    },
    "payments": 1,
    "payments_amount": 320000000,
    "trades": 26,
    "traded_amount": 31620436818,
    "price": [
      1.969042156788106,
      2.010828255954286,
      1.969042156788106,
      1.9876764062810575
    ],
    "volume": 31882371405
  },
  {
    "ts": 1595635200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 640,
      "authorized": 640,
      "funded": 136
    },
    "payments": 2,
    "payments_amount": 1043202,
    "trades": 16,
    "traded_amount": 702392976,
    "price": [
      1.9876764062810575,
      1.9876764062810575,
      1.8883994003086755,
      1.8959757599277105
    ],
    "volume": 1335434979
  },
  {
    "ts": 1595721600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 641,
      "authorized": 641,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 16468412422,
    "price": [
      1.8959757599277105,
      1.9248246568676994,
      1.8511246858202428,
      1.9248246568676994
    ],
    "volume": 21255425781
  },
  {
    "ts": 1595808000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 643,
      "authorized": 643,
      "funded": 135
    },
    "payments": 5,
    "payments_amount": 1740476687,
    "trades": 61,
    "traded_amount": 130506541648,
    "price": [
      1.9248246568676994,
      2.1470273398770403,
      1.9248246568676994,
      2.070007716666667
    ],
    "volume": 139800155068
  },
  {
    "ts": 1595894400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 644,
      "authorized": 644,
      "funded": 136
    },
    "payments": 1,
    "payments_amount": 300500000,
    "trades": 10,
    "traded_amount": 21828030411,
    "price": [
      2.070007716666667,
      2.070007716666667,
      2.0201149716483338,
      2.0326562536395967
    ],
    "volume": 23611116569
  },
  {
    "ts": 1595980800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 650,
      "authorized": 650,
      "funded": 138
    },
    "payments": 4,
    "payments_amount": 446272626,
    "trades": 5,
    "traded_amount": 487207289,
    "price": [
      2.0326562536395967,
      2.0326562536395967,
      1.98864605980419,
      2.0153508059432688
    ],
    "volume": 984129095
  },
  {
    "ts": 1596067200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 651,
      "authorized": 651,
      "funded": 138
    },
    "payments": 3,
    "payments_amount": 6165698,
    "trades": 7,
    "traded_amount": 112147779,
    "price": [
      2.0153508059432688,
      2.0207166582328275,
      2.0001395378506386,
      2.0041572032043034
    ],
    "volume": 223883839
  },
  {
    "ts": 1596153600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 654,
      "authorized": 654,
      "funded": 139
    },
    "payments": 11,
    "payments_amount": 3502391064,
    "trades": 7,
    "traded_amount": 1168997142,
    "price": [
      2.0041572032043034,
      2.0206041844892297,
      1.9928491124298355,
      1.9928491124298355
    ],
    "volume": 2336627324
  },
  {
    "ts": 1596240000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 654,
      "authorized": 654,
      "funded": 139
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 26,
    "traded_amount": 41128164750,
    "price": [
      1.9928491124298355,
      1.9928491124298355,
      1.7961970339853568,
      1.7961970339853568
    ],
    "volume": 38974750724
  },
  {
    "ts": 1596326400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 655,
      "authorized": 655,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 17,
    "traded_amount": 95664371702,
    "price": [
      1.7961970339853568,
      1.9154163975531673,
      1.6878335651293093,
      1.8134639
    ],
    "volume": 85679571573
  },
  {
    "ts": 1596412800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 658,
      "authorized": 658,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 472908799,
    "price": [
      1.8134639,
      1.855322511613903,
      1.7469883312964427,
      1.7469883312964427
    ],
    "volume": 860381479
  },
  {
    "ts": 1596499200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 661,
      "authorized": 661,
      "funded": 134
    },
    "payments": 14,
    "payments_amount": 3471320017,
    "trades": 13,
    "traded_amount": 1974068942,
    "price": [
      1.7469883312964427,
      1.7853402543304133,
      1.706285,
      1.706285
    ],
    "volume": 2522561993
  },
  {
    "ts": 1596585600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 664,
      "authorized": 664,
      "funded": 134
    },
    "payments": 12,
    "payments_amount": 7365794285,
    "trades": 7,
    "traded_amount": 4718882614,
    "price": [
      1.706285,
      1.7695649218822416,
      1.706285,
      1.7389778044028554
    ],
    "volume": 8276885008
  },
  {
    "ts": 1596672000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 672,
      "authorized": 672,
      "funded": 134
    },
    "payments": 4,
    "payments_amount": 5069478,
    "trades": 30,
    "traded_amount": 18743235026,
    "price": [
      1.7389778044028554,
      1.7885687372181664,
      1.724515733559837,
      1.7885687372181664
    ],
    "volume": 16610588269
  },
  {
    "ts": 1596758400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 676,
      "authorized": 676,
      "funded": 135
    },
    "payments": 5,
    "payments_amount": 1026488417,
    "trades": 11,
    "traded_amount": 20798830209,
    "price": [
      1.7885687372181664,
      1.8167123053493361,
      1.7250699841772152,
      1.8167123053493361
    ],
    "volume": 19754366843
  },
  {
    "ts": 1596844800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 687,
      "authorized": 687,
      "funded": 134
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 3,
    "traded_amount": 6462281049,
    "price": [
      1.8167123053493361,
      1.8167123053493361,
      1.792177274871872,
      1.792177274871872
    ],
    "volume": 11581707466
  },
  {
    "ts": 1596931200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 687,
      "authorized": 687,
      "funded": 135
    },
    "payments": 3,
    "payments_amount": 219169841,
    "trades": 3,
    "traded_amount": 219169841,
    "price": [
      1.792177274871872,
      1.792177274871872,
      1.7625848913289561,
      1.7701357947283975
    ],
    "volume": 387764613
  },
  {
    "ts": 1597017600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 690,
      "authorized": 690,
      "funded": 135
    },
    "payments": 5,
    "payments_amount": 135200000,
    "trades": 10,
    "traded_amount": 10939846134,
    "price": [
      1.7701357947283975,
      1.7701357947283975,
      1.749795177489803,
      1.7501464
    ],
    "volume": 9575998175
  },
  {
    "ts": 1597104000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 694,
      "authorized": 694,
      "funded": 136
    },
    "payments": 3,
    "payments_amount": 22452338,
    "trades": 13,
    "traded_amount": 18262204219,
    "price": [
      1.7501464,
      1.9224083900054931,
      1.7501464,
      1.8968091716665194
    ],
    "volume": 18518607696
  },
  {
    "ts": 1597190400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 698,
      "authorized": 698,
      "funded": 138
    },
    "payments": 12,
    "payments_amount": 4503428273,
    "trades": 25,
    "traded_amount": 20217399306,
    "price": [
      1.8968091716665194,
      1.8968091716665194,
      0.1,
      1.8365060363203245
    ],
    "volume": 19928252916
  },
  {
    "ts": 1597276800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 711,
      "authorized": 711,
      "funded": 137
    },
    "payments": 15,
    "payments_amount": 3761305572,
    "trades": 10,
    "traded_amount": 2840694093,
    "price": [
      1.8365060363203245,
      1.8764171646920005,
      1.825,
      1.8676479689792496
    ],
    "volume": 3261154553
  },
  {
    "ts": 1597363200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 730,
      "authorized": 730,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 32209696518,
    "price": [
      1.8676479689792496,
      1.8676479689792496,
      1.7567949294406429,
      1.7567949294406429
    ],
    "volume": 28325406567
  },
  {
    "ts": 1597449600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 743,
      "authorized": 743,
      "funded": 136
    },
    "payments": 1,
    "payments_amount": 390000000,
    "trades": 27,
    "traded_amount": 408966270,
    "price": [
      1.7567949294406429,
      1.7657879,
      1.712917920068758,
      1.712917920068758
    ],
    "volume": 705177305
  },
  {
    "ts": 1597536000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 747,
      "authorized": 747,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 44614010524,
    "price": [
      1.712917920068758,
      1.7399434373712868,
      1.6090849656722037,
      1.6090849656722037
    ],
    "volume": 38201268474
  },
  {
    "ts": 1597622400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 835,
      "authorized": 835,
      "funded": 136
    },
    "payments": 5,
    "payments_amount": 1500000000,
    "trades": 29,
    "traded_amount": 65677897856,
    "price": [
      1.6090849656722037,
      1.649969762271833,
      1.5578612110418963,
      1.5618939214758751
    ],
    "volume": 54446753272
  },
  {
    "ts": 1597708800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 909,
      "authorized": 909,
      "funded": 135
    },
    "payments": 3,
    "payments_amount": 32699853405,
    "trades": 14,
    "traded_amount": 39177010652,
    "price": [
      1.5618939214758751,
      1.7239420998870565,
      1.5618939214758751,
      1.715299153068952
    ],
    "volume": 31042164292
  },
  {
    "ts": 1597795200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 970,
      "authorized": 970,
      "funded": 135
    },
    "payments": 6,
    "payments_amount": 16854187849,
    "trades": 11,
    "traded_amount": 8083425790,
    "price": [
      1.715299153068952,
      1.7888262209823582,
      1.6970811307624432,
      1.7888262209823582
    ],
    "volume": 4648516564
  },
  {
    "ts": 1597881600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1052,
      "authorized": 1052,
      "funded": 132
    },
    "payments": 7,
    "payments_amount": 11446066986,
    "trades": 5,
    "traded_amount": 10846066986,
    "price": [
      1.7888262209823582,
      1.7888262209823582,
      1.689922580392157,
      1.689922580392157
    ],
    "volume": 1870297842
  },
  {
    "ts": 1597968000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1135,
      "authorized": 1135,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 40,
    "traded_amount": 148320346600,
    "price": [
      1.689922580392157,
      1.713606940766256,
      1.689922580392157,
      1.7136068199421592
    ],
    "volume": 126516950723
  },
  {
    "ts": 1598054400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1187,
      "authorized": 1187,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.7136068199421592,
      1.7136068199421592,
      1.7136068199421592,
      1.7136068199421592
    ],
    "volume": 0
  },
  {
    "ts": 1598140800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1231,
      "authorized": 1231,
      "funded": 132
    },
    "payments": 1,
    "payments_amount": 171900000,
    "trades": 1,
    "traded_amount": 171900000,
    "price": [
      1.7136068199421592,
      1.7512281035485748,
      1.7136068199421592,
      1.7512281035485748
    ],
    "volume": 301036111
  },
  {
    "ts": 1598227200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1262,
      "authorized": 1262,
      "funded": 132
    },
    "payments": 1,
    "payments_amount": 218600000,
    "trades": 1,
    "traded_amount": 218600000,
    "price": [
      1.7512281035485748,
      1.7512281035485748,
      1.7235115096065874,
      1.7235115096065874
    ],
    "volume": 376759616
  },
  {
    "ts": 1598313600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1391,
      "authorized": 1391,
      "funded": 131
    },
    "payments": 1,
    "payments_amount": 163134844,
    "trades": 11,
    "traded_amount": 22511872978,
    "price": [
      1.7235115096065874,
      1.8521792763157894,
      1.7235115096065874,
      1.8428865763855078
    ],
    "volume": 20685573615
  },
  {
    "ts": 1598400000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1521,
      "authorized": 1521,
      "funded": 131
    },
    "payments": 3,
    "payments_amount": 122459,
    "trades": 15,
    "traded_amount": 206647227,
    "price": [
      1.8428865763855078,
      1.8702891965574384,
      1.8019709958752883,
      1.8019709958752883
    ],
    "volume": 188388541
  },
  {
    "ts": 1598486400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1631,
      "authorized": 1631,
      "funded": 130
    },
    "payments": 1,
    "payments_amount": 60000000,
    "trades": 5,
    "traded_amount": 4633600748,
    "price": [
      1.8019709958752883,
      1.8592312735376788,
      1.8019709958752883,
      1.8578908722180458
    ],
    "volume": 4251690465
  },
  {
    "ts": 1598572800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1643,
      "authorized": 1643,
      "funded": 131
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 49999999,
    "price": [
      1.8578908722180458,
      1.9120740582414812,
      1.8578908722180458,
      1.9120740582414812
    ],
    "volume": 95603701
  },
  {
    "ts": 1598659200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1653,
      "authorized": 1653,
      "funded": 131
    },
    "payments": 1,
    "payments_amount": 117300000,
    "trades": 1,
    "traded_amount": 117300000,
    "price": [
      1.9120740582414812,
      1.9120740582414812,
      1.90268515771526,
      1.90268515771526
    ],
    "volume": 223184969
  },
  {
    "ts": 1598745600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1653,
      "authorized": 1653,
      "funded": 131
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.90268515771526,
      1.90268515771526,
      1.90268515771526,
      1.90268515771526
    ],
    "volume": 0
  },
  {
    "ts": 1598832000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1656,
      "authorized": 1656,
      "funded": 132
    },
    "payments": 6,
    "payments_amount": 1248888126,
    "trades": 2,
    "traded_amount": 48888126,
    "price": [
      1.90268515771526,
      1.90268515771526,
      1.8956668223611721,
      1.8956668223611721
    ],
    "volume": 46421328
  },
  {
    "ts": 1598918400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1658,
      "authorized": 1658,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 15166485572,
    "price": [
      1.8956668223611721,
      1.8956668223611721,
      1.8526029283616452,
      1.8526029283616452
    ],
    "volume": 14174488719
  },
  {
    "ts": 1599004800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1661,
      "authorized": 1661,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 40,
    "traded_amount": 100045755594,
    "price": [
      1.8526029283616452,
      2.0748144547740246,
      1.8526029283616452,
      2.056286592681088
    ],
    "volume": 102023459676
  },
  {
    "ts": 1599091200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1663,
      "authorized": 1663,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 37,
    "traded_amount": 267085887393,
    "price": [
      2.056286592681088,
      2.3468800229322726,
      2.056286592681088,
      2.3468800229322726
    ],
    "volume": 308106618532
  },
  {
    "ts": 1599177600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1666,
      "authorized": 1666,
      "funded": 133
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 41,
    "traded_amount": 170648544096,
    "price": [
      2.3468800229322726,
      2.4590553410993956,
      2.2959699262205557,
      2.4506055722183473
    ],
    "volume": 209985781609
  },
  {
    "ts": 1599264000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1667,
      "authorized": 1667,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 26,
    "traded_amount": 47651882489,
    "price": [
      2.4506055722183473,
      2.5527189173116738,
      2.2871645502203917,
      2.5153725015164103
    ],
    "volume": 59792484030
  },
  {
    "ts": 1599350400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1666,
      "authorized": 1666,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.5153725015164103,
      2.5153725015164103,
      2.5153725015164103,
      2.5153725015164103
    ],
    "volume": 0
  },
  {
    "ts": 1599436800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1666,
      "authorized": 1666,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 42146170410,
    "price": [
      2.5153725015164103,
      2.519234345340303,
      2.4952190822334273,
      2.4975437145301935
    ],
    "volume": 52931798993
  },
  {
    "ts": 1599523200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1665,
      "authorized": 1665,
      "funded": 135
    },
    "payments": 3,
    "payments_amount": 1111505260,
    "trades": 11,
    "traded_amount": 32830636540,
    "price": [
      2.4975437145301935,
      2.4975437145301935,
      2.3975605412050256,
      2.4231601603782833
    ],
    "volume": 40980721448
  },
  {
    "ts": 1599609600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1664,
      "authorized": 1664,
      "funded": 134
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 2,
    "traded_amount": 6115331378,
    "price": [
      2.4231601603782833,
      2.4231601603782833,
      2.3894958452405226,
      2.3894958452405226
    ],
    "volume": 7306279460
  },
  {
    "ts": 1599696000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1664,
      "authorized": 1664,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.3894958452405226,
      2.3894958452405226,
      2.3894958452405226,
      2.3894958452405226
    ],
    "volume": 0
  },
  {
    "ts": 1599782400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1664,
      "authorized": 1664,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.3894958452405226,
      2.3894958452405226,
      2.3894958452405226,
      2.3894958452405226
    ],
    "volume": 0
  },
  {
    "ts": 1599868800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1665,
      "authorized": 1665,
      "funded": 133
    },
    "payments": 1,
    "payments_amount": 56168259,
    "trades": 4,
    "traded_amount": 162940366,
    "price": [
      2.3894958452405226,
      2.3894958452405226,
      2.2199987006896547,
      2.2435130529535963
    ],
    "volume": 364865956
  },
  {
    "ts": 1599955200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1666,
      "authorized": 1666,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 17,
    "traded_amount": 23501492732,
    "price": [
      2.2435130529535963,
      2.3565604450153312,
      2.239621493217463,
      2.356382667137554
    ],
    "volume": 29074432157
  },
  {
    "ts": 1600041600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1667,
      "authorized": 1667,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 105796527,
    "price": [
      2.356382667137554,
      2.356382667137554,
      2.34742675718792,
      2.353645442566897
    ],
    "volume": 119452703
  },
  {
    "ts": 1600128000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1669,
      "authorized": 1669,
      "funded": 134
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 7,
    "traded_amount": 1066860876,
    "price": [
      2.353645442566897,
      2.43780208,
      2.353645442566897,
      2.43780208
    ],
    "volume": 1278873432
  },
  {
    "ts": 1600214400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1670,
      "authorized": 1670,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 11169526258,
    "price": [
      2.43780208,
      2.488953837153382,
      2.385811171674994,
      2.385811171674994
    ],
    "volume": 13328463273
  },
  {
    "ts": 1600300800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1672,
      "authorized": 1672,
      "funded": 134
    },
    "payments": 4,
    "payments_amount": 1200000000,
    "trades": 13,
    "traded_amount": 55735877468,
    "price": [
      2.385811171674994,
      2.4171329111954103,
      2.344379442330273,
      2.3757164487272013
    ],
    "volume": 66226245561
  },
  {
    "ts": 1600387200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1674,
      "authorized": 1674,
      "funded": 133
    },
    "payments": 3,
    "payments_amount": 1574800000,
    "trades": 2,
    "traded_amount": 70538104,
    "price": [
      2.3757164487272013,
      2.3757164487272013,
      2.3680544631593725,
      2.3680544631593725
    ],
    "volume": 83519036
  },
  {
    "ts": 1600473600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1675,
      "authorized": 1675,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 1434113712,
    "price": [
      2.3680544631593725,
      2.3680544631593725,
      2.36,
      2.36
    ],
    "volume": 1812664350
  },
  {
    "ts": 1600560000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1675,
      "authorized": 1675,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 99500000,
    "price": [
      2.36,
      2.38,
      2.36,
      2.38
    ],
    "volume": 236810000
  },
  {
    "ts": 1600646400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1675,
      "authorized": 1675,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 10881208502,
    "price": [
      2.38,
      2.5737051792828685,
      2.38,
      2.5737051792828685
    ],
    "volume": 13921099000
  },
  {
    "ts": 1600732800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1676,
      "authorized": 1676,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 104718872,
    "trades": 2,
    "traded_amount": 104718872,
    "price": [
      2.5737051792828685,
      2.5737051792828685,
      2.571639261477046,
      2.571639261477046
    ],
    "volume": 128839127
  },
  {
    "ts": 1600819200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1679,
      "authorized": 1679,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 1178646160,
    "price": [
      2.571639261477046,
      2.587693783,
      2.5269703419597365,
      2.587693783
    ],
    "volume": 3040913782
  },
  {
    "ts": 1600905600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1680,
      "authorized": 1680,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.587693783,
      2.587693783,
      2.587693783,
      2.587693783
    ],
    "volume": 0
  },
  {
    "ts": 1600992000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1683,
      "authorized": 1683,
      "funded": 135
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 2,
    "traded_amount": 268313357,
    "price": [
      2.587693783,
      2.587693783,
      2.4055311391708694,
      2.409969882382966
    ],
    "volume": 646488718
  },
  {
    "ts": 1601078400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1684,
      "authorized": 1684,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 6769427373,
    "price": [
      2.409969882382966,
      2.442889438990475,
      2.389221362314907,
      2.442889438990475
    ],
    "volume": 9206712400
  },
  {
    "ts": 1601164800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1685,
      "authorized": 1685,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.442889438990475,
      2.442889438990475,
      2.442889438990475,
      2.442889438990475
    ],
    "volume": 0
  },
  {
    "ts": 1601251200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1686,
      "authorized": 1686,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1760,
    "price": [
      2.442889438990475,
      2.442889438990475,
      2.3886363636363637,
      2.3886363636363637
    ],
    "volume": 4204
  },
  {
    "ts": 1601337600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1688,
      "authorized": 1688,
      "funded": 137
    },
    "payments": 4,
    "payments_amount": 14067819,
    "trades": 7,
    "traded_amount": 39493596,
    "price": [
      2.3886363636363637,
      2.4436203,
      2.352229101717692,
      2.4436203
    ],
    "volume": 88440922
  },
  {
    "ts": 1601424000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1687,
      "authorized": 1687,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.4436203,
      2.4436203,
      2.4436203,
      2.4436203
    ],
    "volume": 0
  },
  {
    "ts": 1601510400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1688,
      "authorized": 1688,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 39,
    "traded_amount": 33877259927,
    "price": [
      2.4436203,
      2.453468952096136,
      2.340329722095744,
      2.42101611
    ],
    "volume": 42338611136
  },
  {
    "ts": 1601596800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1689,
      "authorized": 1689,
      "funded": 136
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 5,
    "traded_amount": 11533880406,
    "price": [
      2.42101611,
      2.50267817751428,
      2.42101611,
      2.50267817751428
    ],
    "volume": 14683537550
  },
  {
    "ts": 1601683200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1689,
      "authorized": 1689,
      "funded": 135
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 226371607,
    "price": [
      2.50267817751428,
      2.50267817751428,
      2.476896031400263,
      2.476896031400263
    ],
    "volume": 560698935
  },
  {
    "ts": 1601769600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1689,
      "authorized": 1689,
      "funded": 135
    },
    "payments": 2,
    "payments_amount": 117630403,
    "trades": 4,
    "traded_amount": 117711527,
    "price": [
      2.476896031400263,
      2.482535601240982,
      2.465361668556777,
      2.4701517184942716
    ],
    "volume": 291365008
  },
  {
    "ts": 1601856000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1690,
      "authorized": 1690,
      "funded": 135
    },
    "payments": 6,
    "payments_amount": 1840000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.4701517184942716,
      2.4701517184942716,
      2.4701517184942716,
      2.4701517184942716
    ],
    "volume": 0
  },
  {
    "ts": 1601942400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1691,
      "authorized": 1691,
      "funded": 135
    },
    "payments": 2,
    "payments_amount": 623400000,
    "trades": 48,
    "traded_amount": 9999503900,
    "price": [
      2.4701517184942716,
      2.513661751620055,
      2.4254771207243304,
      2.4819392006088825
    ],
    "volume": 12259100241
  },
  {
    "ts": 1602028800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1692,
      "authorized": 1692,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 82397119,
    "price": [
      2.4819392006088825,
      2.49228,
      2.4556309291126186,
      2.4556309291126186
    ],
    "volume": 203030772
  },
  {
    "ts": 1602115200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1693,
      "authorized": 1693,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 29375675247,
    "price": [
      2.4556309291126186,
      2.5166110100998025,
      2.4551845689445404,
      2.465626326947527
    ],
    "volume": 38358643467
  },
  {
    "ts": 1602201600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1693,
      "authorized": 1693,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 23216,
    "price": [
      2.465626326947527,
      2.465626326947527,
      2.4129,
      2.4133448873483534
    ],
    "volume": 56021
  },
  {
    "ts": 1602288000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1694,
      "authorized": 1694,
      "funded": 135
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 7639820796,
    "price": [
      2.4133448873483534,
      2.4133448873483534,
      2.3268134460389227,
      2.3268134460389227
    ],
    "volume": 9315233126
  },
  {
    "ts": 1602374400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1696,
      "authorized": 1696,
      "funded": 135
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.3268134460389227,
      2.3268134460389227,
      2.3268134460389227,
      2.3268134460389227
    ],
    "volume": 0
  },
  {
    "ts": 1602460800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1698,
      "authorized": 1698,
      "funded": 136
    },
    "payments": 7,
    "payments_amount": 2166800000,
    "trades": 32,
    "traded_amount": 9933400577,
    "price": [
      2.3268134460389227,
      2.3526922819197797,
      2.290113,
      2.290113
    ],
    "volume": 12493221259
  },
  {
    "ts": 1602547200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1702,
      "authorized": 1702,
      "funded": 136
    },
    "payments": 4,
    "payments_amount": 1223400000,
    "trades": 2,
    "traded_amount": 85316,
    "price": [
      2.290113,
      2.3442261709409724,
      2.290113,
      2.3442261709409724
    ],
    "volume": 100000
  },
  {
    "ts": 1602633600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1704,
      "authorized": 1704,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.3442261709409724,
      2.3442261709409724,
      2.3442261709409724,
      2.3442261709409724
    ],
    "volume": 0
  },
  {
    "ts": 1602720000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1706,
      "authorized": 1706,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 34979421624,
    "price": [
      2.3442261709409724,
      2.4386053269795296,
      2.3442261709409724,
      2.422818791946309
    ],
    "volume": 42620991633
  },
  {
    "ts": 1602806400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1709,
      "authorized": 1709,
      "funded": 136
    },
    "payments": 4,
    "payments_amount": 1433500000,
    "trades": 9,
    "traded_amount": 73729036,
    "price": [
      2.422818791946309,
      2.422818791946309,
      2.2609450913334963,
      2.2609450913334963
    ],
    "volume": 84186556
  },
  {
    "ts": 1602892800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1714,
      "authorized": 1714,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 7601590972,
    "price": [
      2.2609450913334963,
      2.2609450913334963,
      2.1854734111836924,
      2.1854734111836924
    ],
    "volume": 8306537476
  },
  {
    "ts": 1602979200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1718,
      "authorized": 1718,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 11296770596,
    "price": [
      2.1854734111836924,
      2.1854734111836924,
      2.1652769999305193,
      2.1778523489932886
    ],
    "volume": 12230318777
  },
  {
    "ts": 1603065600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1719,
      "authorized": 1719,
      "funded": 136
    },
    "payments": 10,
    "payments_amount": 4097000000,
    "trades": 14,
    "traded_amount": 3707279318,
    "price": [
      2.1778523489932886,
      2.2024747774088427,
      2.0618109602939905,
      2.0618109602939905
    ],
    "volume": 3921367042
  },
  {
    "ts": 1603152000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1720,
      "authorized": 1720,
      "funded": 135
    },
    "payments": 9,
    "payments_amount": 32939734,
    "trades": 11,
    "traded_amount": 2426118769,
    "price": [
      2.0618109602939905,
      2.219571662750993,
      2.0618109602939905,
      2.219571662750993
    ],
    "volume": 2638431269
  },
  {
    "ts": 1603238400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1728,
      "authorized": 1728,
      "funded": 136
    },
    "payments": 3,
    "payments_amount": 709000000,
    "trades": 14,
    "traded_amount": 123209419,
    "price": [
      2.219571662750993,
      2.219571662750993,
      2.1163658,
      2.1163658
    ],
    "volume": 183098292
  },
  {
    "ts": 1603324800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1734,
      "authorized": 1734,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 20,
    "traded_amount": 39084224966,
    "price": [
      2.1163658,
      2.1163658,
      2.022222222222222,
      2.069160899482646
    ],
    "volume": 40503276354
  },
  {
    "ts": 1603411200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1734,
      "authorized": 1734,
      "funded": 136
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 10,
    "traded_amount": 8119489598,
    "price": [
      2.069160899482646,
      2.131042435223461,
      2.069160899482646,
      2.131042435223461
    ],
    "volume": 8626943393
  },
  {
    "ts": 1603497600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1738,
      "authorized": 1738,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1182607591,
    "price": [
      2.131042435223461,
      2.131042435223461,
      2.113972562011062,
      2.113972562011062
    ],
    "volume": 2499999999
  },
  {
    "ts": 1603584000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1739,
      "authorized": 1739,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 1637363513,
    "price": [
      2.113972562011062,
      2.1392969688482,
      2.113972562011062,
      2.1392969688482
    ],
    "volume": 3488902805
  },
  {
    "ts": 1603670400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1738,
      "authorized": 1738,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 470132159,
    "price": [
      2.1392969688482,
      2.1392969688482,
      2.1270614631576397,
      2.1270614631576397
    ],
    "volume": 999999998
  },
  {
    "ts": 1603756800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1741,
      "authorized": 1741,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 16356261037,
    "price": [
      2.1270614631576397,
      2.1677224114853098,
      2.1270614631576397,
      2.150343032450835
    ],
    "volume": 18093756963
  },
  {
    "ts": 1603843200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1744,
      "authorized": 1744,
      "funded": 137
    },
    "payments": 5,
    "payments_amount": 900000085,
    "trades": 21,
    "traded_amount": 48738593067,
    "price": [
      2.150343032450835,
      2.214233671859172,
      2.150343032450835,
      2.1837210572303647
    ],
    "volume": 53489931872
  },
  {
    "ts": 1603929600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1744,
      "authorized": 1744,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 48,
    "traded_amount": 167538540945,
    "price": [
      2.1837210572303647,
      2.2619538484998207,
      2.1837210572303647,
      2.185185185185185
    ],
    "volume": 186191844644
  },
  {
    "ts": 1604016000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1745,
      "authorized": 1745,
      "funded": 137
    },
    "payments": 1,
    "payments_amount": 2154,
    "trades": 7,
    "traded_amount": 12685810652,
    "price": [
      2.185185185185185,
      2.3231197771587744,
      2.185185185185185,
      2.27152406
    ],
    "volume": 15650843876
  },
  {
    "ts": 1604102400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1747,
      "authorized": 1747,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 12518626312,
    "price": [
      2.27152406,
      2.27152406,
      2.2032526190568356,
      2.21993280156826
    ],
    "volume": 14324461081
  },
  {
    "ts": 1604188800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1749,
      "authorized": 1749,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.21993280156826,
      2.21993280156826,
      2.21993280156826,
      2.21993280156826
    ],
    "volume": 0
  },
  {
    "ts": 1604275200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1748,
      "authorized": 1748,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 899233390,
    "price": [
      2.21993280156826,
      2.2914400575664735,
      2.21993280156826,
      2.2914400575664735
    ],
    "volume": 2049690050
  },
  {
    "ts": 1604361600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1749,
      "authorized": 1749,
      "funded": 137
    },
    "payments": 9,
    "payments_amount": 2400000010,
    "trades": 7,
    "traded_amount": 3364406394,
    "price": [
      2.2914400575664735,
      2.378787878787879,
      2.2914400575664735,
      2.332075281330368
    ],
    "volume": 7892510835
  },
  {
    "ts": 1604448000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1751,
      "authorized": 1751,
      "funded": 147
    },
    "payments": 10,
    "payments_amount": 5000000000,
    "trades": 8,
    "traded_amount": 12034347774,
    "price": [
      2.332075281330368,
      2.412196263120092,
      2.332075281330368,
      2.3675700263374355
    ],
    "volume": 14498756889
  },
  {
    "ts": 1604534400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1754,
      "authorized": 1754,
      "funded": 150
    },
    "payments": 3,
    "payments_amount": 200001484,
    "trades": 20,
    "traded_amount": 35000136766,
    "price": [
      2.3675700263374355,
      2.405441994511829,
      2.2523383035480675,
      2.2523383035480675
    ],
    "volume": 42369622507
  },
  {
    "ts": 1604620800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1759,
      "authorized": 1759,
      "funded": 152
    },
    "payments": 12,
    "payments_amount": 900106536,
    "trades": 8,
    "traded_amount": 600106536,
    "price": [
      2.2523383035480675,
      2.2523383035480675,
      2.13475,
      2.18189556
    ],
    "volume": 1309594803
  },
  {
    "ts": 1604707200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1762,
      "authorized": 1762,
      "funded": 152
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 27,
    "traded_amount": 17509244156,
    "price": [
      2.18189556,
      2.353344365336599,
      2.18189556,
      2.3019367122049097
    ],
    "volume": 21674634034
  },
  {
    "ts": 1604793600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1773,
      "authorized": 1773,
      "funded": 152
    },
    "payments": 5,
    "payments_amount": 353274727,
    "trades": 2,
    "traded_amount": 53274727,
    "price": [
      2.3019367122049097,
      2.3019367122049097,
      2.2852360213470977,
      2.2852360213470977
    ],
    "volume": 60958047
  },
  {
    "ts": 1604880000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1781,
      "authorized": 1781,
      "funded": 153
    },
    "payments": 9,
    "payments_amount": 1807418836,
    "trades": 4,
    "traded_amount": 12234594,
    "price": [
      2.2852360213470977,
      2.3119459868864296,
      2.2852360213470977,
      2.306109584987843
    ],
    "volume": 28240351
  },
  {
    "ts": 1604966400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1785,
      "authorized": 1785,
      "funded": 154
    },
    "payments": 4,
    "payments_amount": 300013837,
    "trades": 3,
    "traded_amount": 13837,
    "price": [
      2.306109584987843,
      2.306109584987843,
      2.279469164715066,
      2.2888
    ],
    "volume": 31640
  },
  {
    "ts": 1605052800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1788,
      "authorized": 1788,
      "funded": 156
    },
    "payments": 4,
    "payments_amount": 100962985,
    "trades": 4,
    "traded_amount": 50962985,
    "price": [
      2.2888,
      2.295143212951432,
      2.27863862,
      2.27863862
    ],
    "volume": 116133800
  },
  {
    "ts": 1605139200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1795,
      "authorized": 1795,
      "funded": 158
    },
    "payments": 8,
    "payments_amount": 2000099586,
    "trades": 12,
    "traded_amount": 1988946339,
    "price": [
      2.27863862,
      2.327433628318584,
      2.27863862,
      2.2977646835010437
    ],
    "volume": 4577976613
  },
  {
    "ts": 1605225600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1804,
      "authorized": 1804,
      "funded": 158
    },
    "payments": 1,
    "payments_amount": 33000,
    "trades": 8,
    "traded_amount": 4189752466,
    "price": [
      2.2977646835010437,
      2.2977646835010437,
      2.2292549815354903,
      2.2292549815354903
    ],
    "volume": 4921283018
  },
  {
    "ts": 1605312000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1806,
      "authorized": 1806,
      "funded": 157
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 993087175,
    "price": [
      2.2292549815354903,
      2.2492479700279078,
      2.2292549815354903,
      2.2492479700279078
    ],
    "volume": 2232580194
  },
  {
    "ts": 1605398400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1811,
      "authorized": 1811,
      "funded": 156
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 6092197891,
    "price": [
      2.2492479700279078,
      2.2885940176875454,
      2.2343120267797296,
      2.2885940176875454
    ],
    "volume": 8614166690
  },
  {
    "ts": 1605484800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1812,
      "authorized": 1812,
      "funded": 157
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 4425181564,
    "price": [
      2.2885940176875454,
      2.2885940176875454,
      2.2449401606621278,
      2.2449401606621278
    ],
    "volume": 5975713919
  },
  {
    "ts": 1605571200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1817,
      "authorized": 1817,
      "funded": 159
    },
    "payments": 1,
    "payments_amount": 100,
    "trades": 13,
    "traded_amount": 15256879405,
    "price": [
      2.2449401606621278,
      2.2449401606621278,
      2.1447542675711606,
      2.1447542675711606
    ],
    "volume": 16757093767
  },
  {
    "ts": 1605657600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1817,
      "authorized": 1817,
      "funded": 159
    },
    "payments": 1,
    "payments_amount": 350000000,
    "trades": 2,
    "traded_amount": 98707229,
    "price": [
      2.1447542675711606,
      2.2296149316776868,
      2.1447542675711606,
      2.2296149316776868
    ],
    "volume": 219999998
  },
  {
    "ts": 1605744000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1818,
      "authorized": 1818,
      "funded": 159
    },
    "payments": 1,
    "payments_amount": 4500,
    "trades": 1,
    "traded_amount": 4500,
    "price": [
      2.2296149316776868,
      2.2296149316776868,
      2.2053333333333334,
      2.2053333333333334
    ],
    "volume": 9924
  },
  {
    "ts": 1605830400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1822,
      "authorized": 1822,
      "funded": 160
    },
    "payments": 3,
    "payments_amount": 70200,
    "trades": 12,
    "traded_amount": 27055089133,
    "price": [
      2.2053333333333334,
      2.2490935,
      2.14837158094979,
      2.1568053571594183
    ],
    "volume": 29219118181
  },
  {
    "ts": 1605916800,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1822,
      "authorized": 1822,
      "funded": 163
    },
    "payments": 3,
    "payments_amount": 215778687,
    "trades": 17,
    "traded_amount": 23110636973,
    "price": [
      2.1568053571594183,
      2.1568053571594183,
      1.714345750762175,
      1.7165092988981872
    ],
    "volume": 22249628262
  },
  {
    "ts": 1606003200,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1822,
      "authorized": 1822,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 96,
    "traded_amount": 61114080390,
    "price": [
      1.7165092988981872,
      1.922026508545518,
      1.6437996122568095,
      1.780701754385965
    ],
    "volume": 58562471095
  },
  {
    "ts": 1606089600,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1826,
      "authorized": 1826,
      "funded": 162
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 79,
    "traded_amount": 63544796010,
    "price": [
      1.780701754385965,
      1.8263472507081662,
      1.3961905941728145,
      1.3961905941728145
    ],
    "volume": 52141865661
  },
  {
    "ts": 1606176000,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1827,
      "authorized": 1827,
      "funded": 162
    },
    "payments": 4,
    "payments_amount": 600112000,
    "trades": 153,
    "traded_amount": 193905425022,
    "price": [
      1.3961905941728145,
      1.3961905941728145,
      0.9419037809852421,
      0.9789238777132833
    ],
    "volume": 108022627169
  },
  {
    "ts": 1606262400,
    "supply": 1119800000000,
    "trustlines": {
      "total": 1828,
      "authorized": 1828,
      "funded": 161
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 143,
    "traded_amount": 208348817908,
    "price": [
      0.9789238777132833,
      1.0028166242363017,
      0.8280342911866774,
      0.9694431551671612
    ],
    "volume": 99183069931
  },
  {
    "ts": 1606348800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1831,
      "authorized": 1831,
      "funded": 161
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 134,
    "traded_amount": 278273516758,
    "price": [
      0.9694431551671612,
      1.259432718187224,
      0.9444039459816439,
      1.1181282836505342
    ],
    "volume": 158796428041
  },
  {
    "ts": 1606435200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1832,
      "authorized": 1832,
      "funded": 162
    },
    "payments": 3,
    "payments_amount": 650000000,
    "trades": 62,
    "traded_amount": 123374339743,
    "price": [
      1.1181282836505342,
      1.1271408732100456,
      0.9395706926736456,
      0.9489422133464581
    ],
    "volume": 62859848968
  },
  {
    "ts": 1606521600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1837,
      "authorized": 1837,
      "funded": 162
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 52,
    "traded_amount": 56953027357,
    "price": [
      0.9489422133464581,
      0.9489422133464581,
      0.8688407711560987,
      0.9275222814814815
    ],
    "volume": 26451295283
  },
  {
    "ts": 1606608000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1838,
      "authorized": 1838,
      "funded": 162
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 47,
    "traded_amount": 80149576096,
    "price": [
      0.9275222814814815,
      0.9862483753396329,
      0.9275222814814815,
      0.9617316708347374
    ],
    "volume": 39304568320
  },
  {
    "ts": 1606694400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1842,
      "authorized": 1842,
      "funded": 162
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 91,
    "traded_amount": 155796988537,
    "price": [
      0.9617316708347374,
      0.9617316708347374,
      0.9089260340223771,
      0.9225280304257243
    ],
    "volume": 74973580885
  },
  {
    "ts": 1606780800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1852,
      "authorized": 1852,
      "funded": 163
    },
    "payments": 4,
    "payments_amount": 803563164,
    "trades": 38,
    "traded_amount": 68378418870,
    "price": [
      0.9225280304257243,
      1.0431921099048909,
      0.9225280304257243,
      1.0431921099048909
    ],
    "volume": 32454135912
  },
  {
    "ts": 1606867200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1853,
      "authorized": 1853,
      "funded": 163
    },
    "payments": 6,
    "payments_amount": 300108721,
    "trades": 28,
    "traded_amount": 33212956783,
    "price": [
      1.0431921099048909,
      1.0634883176291638,
      1.029994205351312,
      1.0333790627333812
    ],
    "volume": 17840967751
  },
  {
    "ts": 1606953600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1856,
      "authorized": 1856,
      "funded": 165
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 13,
    "traded_amount": 5076998976,
    "price": [
      1.0333790627333812,
      1.0581658848065678,
      1.0296556342983403,
      1.0528491897142676
    ],
    "volume": 5268896710
  },
  {
    "ts": 1607040000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1858,
      "authorized": 1858,
      "funded": 164
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 83,
    "traded_amount": 69067491473,
    "price": [
      1.0528491897142676,
      1.1921232064576803,
      1.0317125722776714,
      1.1902158745588662
    ],
    "volume": 39376515783
  },
  {
    "ts": 1607126400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1859,
      "authorized": 1859,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 16,
    "traded_amount": 23147482366,
    "price": [
      1.1902158745588662,
      1.2010615952995498,
      1.1220649306553139,
      1.1418391127230287
    ],
    "volume": 14049838448
  },
  {
    "ts": 1607212800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1860,
      "authorized": 1860,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 21435333364,
    "price": [
      1.1418391127230287,
      1.1457079819841218,
      1.0869448866842615,
      1.1457079819841218
    ],
    "volume": 12394095295
  },
  {
    "ts": 1607299200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1861,
      "authorized": 1861,
      "funded": 164
    },
    "payments": 8,
    "payments_amount": 2400000000,
    "trades": 51,
    "traded_amount": 23895150004,
    "price": [
      1.1457079819841218,
      1.1913679081099895,
      1.1457079819841218,
      1.18757004345124
    ],
    "volume": 14195009682
  },
  {
    "ts": 1607385600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1860,
      "authorized": 1860,
      "funded": 164
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 33,
    "traded_amount": 31544927460,
    "price": [
      1.18757004345124,
      1.245367206264279,
      1.18757004345124,
      1.2177828538918185
    ],
    "volume": 19774391761
  },
  {
    "ts": 1607472000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1861,
      "authorized": 1861,
      "funded": 164
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 72,
    "traded_amount": 84693982118,
    "price": [
      1.2177828538918185,
      1.3583437287374807,
      1.1131751142137907,
      1.1402764503814709
    ],
    "volume": 52602123697
  },
  {
    "ts": 1607558400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1865,
      "authorized": 1865,
      "funded": 166
    },
    "payments": 2,
    "payments_amount": 593490769,
    "trades": 28,
    "traded_amount": 12634394783,
    "price": [
      1.1402764503814709,
      1.2336079031680294,
      1.1040073166422237,
      1.2336079031680294
    ],
    "volume": 8294510676
  },
  {
    "ts": 1607644800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1864,
      "authorized": 1864,
      "funded": 165
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 37,
    "traded_amount": 39374239281,
    "price": [
      1.2336079031680294,
      1.2884361225880363,
      1.2140382346862162,
      1.2884361225880363
    ],
    "volume": 25171241986
  },
  {
    "ts": 1607731200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1864,
      "authorized": 1864,
      "funded": 166
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 10103523966,
    "price": [
      1.2884361225880363,
      1.3040332983671614,
      1.2884361225880363,
      1.3040332983671614
    ],
    "volume": 6550228422
  },
  {
    "ts": 1607817600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1866,
      "authorized": 1866,
      "funded": 167
    },
    "payments": 3,
    "payments_amount": 99813898,
    "trades": 5,
    "traded_amount": 120575086,
    "price": [
      1.3040332983671614,
      1.3040332983671614,
      1.1467520532258886,
      1.1467520532258886
    ],
    "volume": 23828442
  },
  {
    "ts": 1607904000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1866,
      "authorized": 1866,
      "funded": 167
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.1467520532258886,
      1.1467520532258886,
      1.1467520532258886,
      1.1467520532258886
    ],
    "volume": 0
  },
  {
    "ts": 1607990400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1868,
      "authorized": 1868,
      "funded": 168
    },
    "payments": 1,
    "payments_amount": 103694247,
    "trades": 1,
    "traded_amount": 103694247,
    "price": [
      1.1467520532258886,
      1.151462018910268,
      1.1467520532258886,
      1.151462018910268
    ],
    "volume": 119399987
  },
  {
    "ts": 1608076800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1868,
      "authorized": 1868,
      "funded": 168
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 338019321,
    "price": [
      1.151462018910268,
      1.151462018910268,
      1.151462018910268,
      1.151462018910268
    ],
    "volume": 0
  },
  {
    "ts": 1608163200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1870,
      "authorized": 1870,
      "funded": 168
    },
    "payments": 5,
    "payments_amount": 29517007,
    "trades": 6,
    "traded_amount": 129517007,
    "price": [
      1.151462018910268,
      1.151462018910268,
      0.9806162,
      1.0050283291982547
    ],
    "volume": 127881006
  },
  {
    "ts": 1608249600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1871,
      "authorized": 1871,
      "funded": 169
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 2,
    "traded_amount": 110000000,
    "price": [
      1.0050283291982547,
      1.1064368,
      1.0050283291982547,
      1.0934208
    ],
    "volume": 120406448
  },
  {
    "ts": 1608336000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1874,
      "authorized": 1874,
      "funded": 169
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.0934208,
      1.0934208,
      1.0934208,
      1.0934208
    ],
    "volume": 0
  },
  {
    "ts": 1608422400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1877,
      "authorized": 1877,
      "funded": 169
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.0934208,
      1.0934208,
      1.0934208,
      1.0934208
    ],
    "volume": 0
  },
  {
    "ts": 1608508800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1877,
      "authorized": 1877,
      "funded": 168
    },
    "payments": 3,
    "payments_amount": 600000120,
    "trades": 24,
    "traded_amount": 3528379140,
    "price": [
      1.0934208,
      1.175,
      1.0934208,
      1.1699999996944406
    ],
    "volume": 3440545156
  },
  {
    "ts": 1608595200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1879,
      "authorized": 1879,
      "funded": 169
    },
    "payments": 7,
    "payments_amount": 1800001232,
    "trades": 2,
    "traded_amount": 377152583,
    "price": [
      1.1699999996944406,
      1.2581168831168832,
      1.1699999996944406,
      1.1851344660833523
    ],
    "volume": 446976615
  },
  {
    "ts": 1608681600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1881,
      "authorized": 1881,
      "funded": 170
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 19,
    "traded_amount": 21957032935,
    "price": [
      1.1851344660833523,
      1.6588750442211184,
      1.1851344660833523,
      1.658875044121408
    ],
    "volume": 17692955558
  },
  {
    "ts": 1608768000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1884,
      "authorized": 1884,
      "funded": 170
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.658875044121408,
      1.658875044121408,
      1.658875044121408,
      1.658875044121408
    ],
    "volume": 0
  },
  {
    "ts": 1608854400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1886,
      "authorized": 1886,
      "funded": 170
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.658875044121408,
      1.658875044121408,
      1.658875044121408,
      1.658875044121408
    ],
    "volume": 0
  },
  {
    "ts": 1608940800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1887,
      "authorized": 1887,
      "funded": 171
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 4050172320,
    "price": [
      1.658875044121408,
      1.658875044121408,
      1.2794842,
      1.3013027900643224
    ],
    "volume": 5209714069
  },
  {
    "ts": 1609027200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1888,
      "authorized": 1888,
      "funded": 172
    },
    "payments": 2,
    "payments_amount": 1400000000,
    "trades": 1,
    "traded_amount": 700000000,
    "price": [
      1.3013027900643224,
      1.3013027900643224,
      1.3,
      1.3
    ],
    "volume": 910000000
  },
  {
    "ts": 1609113600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1890,
      "authorized": 1890,
      "funded": 173
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 29921563,
    "price": [
      1.3,
      1.3,
      1.2836449584557956,
      1.2836449584557956
    ],
    "volume": 20000001
  },
  {
    "ts": 1609200000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1889,
      "authorized": 1889,
      "funded": 173
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1.2836449584557956,
      1.2836449584557956,
      1.2836449584557956,
      1.2836449584557956
    ],
    "volume": 0
  },
  {
    "ts": 1609286400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1893,
      "authorized": 1893,
      "funded": 174
    },
    "payments": 1,
    "payments_amount": 3000000000,
    "trades": 2,
    "traded_amount": 4000000000,
    "price": [
      1.2836449584557956,
      1.456228993,
      1.2836449584557956,
      1.456228993
    ],
    "volume": 5808585294
  },
  {
    "ts": 1609372800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1898,
      "authorized": 1898,
      "funded": 173
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 1103771500,
    "price": [
      1.456228993,
      1.5354907657690213,
      1.456228993,
      1.5354907657690213
    ],
    "volume": 1659340180
  },
  {
    "ts": 1609459200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1898,
      "authorized": 1898,
      "funded": 175
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 22,
    "traded_amount": 1523255349,
    "price": [
      1.5354907657690213,
      1.5354907657690213,
      1.4320889278233573,
      1.4634541818181819
    ],
    "volume": 787977936
  },
  {
    "ts": 1609545600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1898,
      "authorized": 1898,
      "funded": 175
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 680488526,
    "price": [
      1.4634541818181819,
      1.5251485589255604,
      1.4634541818181819,
      1.5251485589255604
    ],
    "volume": 1029356462
  },
  {
    "ts": 1609632000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1901,
      "authorized": 1901,
      "funded": 175
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 764183512,
    "price": [
      1.5251485589255604,
      1.5577326115074543,
      1.4906251979648268,
      1.4906251979648268
    ],
    "volume": 1156496130
  },
  {
    "ts": 1609718400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1906,
      "authorized": 1906,
      "funded": 177
    },
    "payments": 3,
    "payments_amount": 3010000175,
    "trades": 35,
    "traded_amount": 10718174821,
    "price": [
      1.4906251979648268,
      1.4906251979648268,
      1.182872155804088,
      1.1931122266045178
    ],
    "volume": 7238632054
  },
  {
    "ts": 1609804800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1907,
      "authorized": 1907,
      "funded": 177
    },
    "payments": 2,
    "payments_amount": 576283084,
    "trades": 6,
    "traded_amount": 762282261,
    "price": [
      1.1931122266045178,
      1.1931122266045178,
      0.9667699602470599,
      0.9667699602470599
    ],
    "volume": 212058642
  },
  {
    "ts": 1609891200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1907,
      "authorized": 1907,
      "funded": 178
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 135,
    "traded_amount": 739557244674,
    "price": [
      0.9667699602470599,
      0.9667699602470599,
      0.5147499097368979,
      0.5609616835645514
    ],
    "volume": 256783875587
  },
  {
    "ts": 1609977600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1909,
      "authorized": 1909,
      "funded": 178
    },
    "payments": 6,
    "payments_amount": 756905422,
    "trades": 18,
    "traded_amount": 126598651682,
    "price": [
      0.5609616835645514,
      0.6190673452680383,
      0.4846448,
      0.6190670000082035
    ],
    "volume": 72097939650
  },
  {
    "ts": 1610064000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1912,
      "authorized": 1912,
      "funded": 178
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 46,
    "traded_amount": 241146483894,
    "price": [
      0.6190670000082035,
      0.6774527000193828,
      0.6088837000718741,
      0.61844068
    ],
    "volume": 97617234781
  },
  {
    "ts": 1610150400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1912,
      "authorized": 1912,
      "funded": 181
    },
    "payments": 4,
    "payments_amount": 2100984147,
    "trades": 10,
    "traded_amount": 3429371328,
    "price": [
      0.61844068,
      0.6392688976688189,
      0.6033557299993971,
      0.6094778937783578
    ],
    "volume": 1441956707
  },
  {
    "ts": 1610236800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1915,
      "authorized": 1915,
      "funded": 182
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 1533276562,
    "price": [
      0.6094778937783578,
      0.6852110143507969,
      0.6094778937783578,
      0.6535792307158461
    ],
    "volume": 750074135
  },
  {
    "ts": 1610323200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1914,
      "authorized": 1914,
      "funded": 180
    },
    "payments": 8,
    "payments_amount": 1864977246,
    "trades": 40,
    "traded_amount": 66765567238,
    "price": [
      0.6535792307158461,
      0.8332060713417329,
      0.6535792307158461,
      0.8332060713417329
    ],
    "volume": 26764035093
  },
  {
    "ts": 1610409600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1920,
      "authorized": 1920,
      "funded": 182
    },
    "payments": 12,
    "payments_amount": 2693054977,
    "trades": 9,
    "traded_amount": 1780064483,
    "price": [
      0.8332060713417329,
      0.8332060713417329,
      0.6131611484565938,
      0.6730145
    ],
    "volume": 498551499
  },
  {
    "ts": 1610496000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1923,
      "authorized": 1923,
      "funded": 183
    },
    "payments": 3,
    "payments_amount": 258473,
    "trades": 4,
    "traded_amount": 70258470,
    "price": [
      0.6730145,
      0.6730145,
      0.6396462785556374,
      0.6628366894585556
    ],
    "volume": 46869793
  },
  {
    "ts": 1610582400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1922,
      "authorized": 1922,
      "funded": 184
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 15261602,
    "price": [
      0.6628366894585556,
      0.6628366894585556,
      0.655239207522251,
      0.655239207522251
    ],
    "volume": 10000000
  },
  {
    "ts": 1610668800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1924,
      "authorized": 1924,
      "funded": 186
    },
    "payments": 2,
    "payments_amount": 1000000,
    "trades": 5,
    "traded_amount": 9839810,
    "price": [
      0.655239207522251,
      0.7042724689327807,
      0.655239207522251,
      0.695388
    ],
    "volume": 1347694
  },
  {
    "ts": 1610755200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1924,
      "authorized": 1924,
      "funded": 187
    },
    "payments": 7,
    "payments_amount": 60000000000,
    "trades": 13,
    "traded_amount": 35449020375,
    "price": [
      0.695388,
      0.695388,
      0.6266727063440944,
      0.6293139023531754
    ],
    "volume": 6009816514
  },
  {
    "ts": 1610841600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1924,
      "authorized": 1924,
      "funded": 185
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 2145172315,
    "price": [
      0.6293139023531754,
      0.6293139023531754,
      0.6249043107992943,
      0.6273644511555375
    ],
    "volume": 1340650858
  },
  {
    "ts": 1610928000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1925,
      "authorized": 1925,
      "funded": 185
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 62415078,
    "price": [
      0.6273644511555375,
      0.6273644511555375,
      0.6273644511555375,
      0.6273644511555375
    ],
    "volume": 0
  },
  {
    "ts": 1611014400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1926,
      "authorized": 1926,
      "funded": 185
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 25000000,
    "price": [
      0.6273644511555375,
      0.6273644511555375,
      0.6240872,
      0.6240872
    ],
    "volume": 15602180
  },
  {
    "ts": 1611100800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1927,
      "authorized": 1927,
      "funded": 186
    },
    "payments": 2,
    "payments_amount": 10100000,
    "trades": 2,
    "traded_amount": 10100000,
    "price": [
      0.6240872,
      0.6714034,
      0.6240872,
      0.66256
    ],
    "volume": 6780290
  },
  {
    "ts": 1611187200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1928,
      "authorized": 1928,
      "funded": 186
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1420651,
    "price": [
      0.66256,
      0.7039019435455999,
      0.66256,
      0.7039019435455999
    ],
    "volume": 999999
  },
  {
    "ts": 1611273600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1928,
      "authorized": 1928,
      "funded": 186
    },
    "payments": 1,
    "payments_amount": 2000,
    "trades": 4,
    "traded_amount": 320038,
    "price": [
      0.7039019435455999,
      0.7919494187873076,
      0.673,
      0.673
    ],
    "volume": 148577
  },
  {
    "ts": 1611360000,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1929,
      "authorized": 1929,
      "funded": 186
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.673,
      0.673,
      0.673,
      0.673
    ],
    "volume": 0
  },
  {
    "ts": 1611446400,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1931,
      "authorized": 1931,
      "funded": 186
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.673,
      0.673,
      0.673,
      0.673
    ],
    "volume": 0
  },
  {
    "ts": 1611532800,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1934,
      "authorized": 1934,
      "funded": 186
    },
    "payments": 6,
    "payments_amount": 1800000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.673,
      0.673,
      0.673,
      0.673
    ],
    "volume": 0
  },
  {
    "ts": 1611619200,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1936,
      "authorized": 1936,
      "funded": 187
    },
    "payments": 5,
    "payments_amount": 842866836,
    "trades": 4,
    "traded_amount": 342189836,
    "price": [
      0.673,
      0.7331794530546835,
      0.673,
      0.7243071
    ],
    "volume": 247967379
  },
  {
    "ts": 1611705600,
    "supply": 1119790000000,
    "trustlines": {
      "total": 1939,
      "authorized": 1939,
      "funded": 189
    },
    "payments": 3,
    "payments_amount": 270011000000,
    "trades": 3,
    "traded_amount": 76221743,
    "price": [
      0.7243071,
      0.7674486,
      0.7243071,
      0.7674486
    ],
    "volume": 58245104
  },
  {
    "ts": 1611792000,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1941,
      "authorized": 1941,
      "funded": 190
    },
    "payments": 10,
    "payments_amount": 1120931917746,
    "trades": 314,
    "traded_amount": 736947712637,
    "price": [
      0.7674486,
      0.7674486,
      0.7288961038961039,
      0.7296296699668327
    ],
    "volume": 500020800000
  },
  {
    "ts": 1611878400,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1944,
      "authorized": 1944,
      "funded": 191
    },
    "payments": 2,
    "payments_amount": 956162000000,
    "trades": 15,
    "traded_amount": 4741788180,
    "price": [
      0.7296296699668327,
      0.7296296699668327,
      0.5030411769314282,
      0.6014026804208041
    ],
    "volume": 1450447180
  },
  {
    "ts": 1611964800,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1948,
      "authorized": 1948,
      "funded": 192
    },
    "payments": 7,
    "payments_amount": 2507955417,
    "trades": 6,
    "traded_amount": 1236206271,
    "price": [
      0.6014026804208041,
      0.6014026804208041,
      0.5171167374548208,
      0.5970273653234246
    ],
    "volume": 436181862
  },
  {
    "ts": 1612051200,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1950,
      "authorized": 1950,
      "funded": 193
    },
    "payments": 1,
    "payments_amount": 231866836,
    "trades": 7,
    "traded_amount": 5232682127,
    "price": [
      0.5970273653234246,
      0.6144374002874966,
      0.573343783411958,
      0.6144374002874966
    ],
    "volume": 3205034028
  },
  {
    "ts": 1612137600,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1952,
      "authorized": 1952,
      "funded": 193
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 280463306,
    "price": [
      0.6144374002874966,
      0.6144374002874966,
      0.5358486717688481,
      0.5358486717688481
    ],
    "volume": 150285890
  },
  {
    "ts": 1612224000,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1955,
      "authorized": 1955,
      "funded": 193
    },
    "payments": 8,
    "payments_amount": 1358602586,
    "trades": 15,
    "traded_amount": 17615193967,
    "price": [
      0.5358486717688481,
      0.5691576656129762,
      0.5358486717688481,
      0.5475
    ],
    "volume": 5134230887
  },
  {
    "ts": 1612310400,
    "supply": 2019790000000,
    "trustlines": {
      "total": 1956,
      "authorized": 1956,
      "funded": 193
    },
    "payments": 2,
    "payments_amount": 63441020,
    "trades": 12,
    "traded_amount": 6131252168,
    "price": [
      0.5475,
      0.5602072,
      0.5475,
      0.559
    ],
    "volume": 72723756
  },
  {
    "ts": 1612396800,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1960,
      "authorized": 1960,
      "funded": 195
    },
    "payments": 5,
    "payments_amount": 150773818919,
    "trades": 34,
    "traded_amount": 10409363665,
    "price": [
      0.559,
      0.5839781033752153,
      0.5439519,
      0.5439519
    ],
    "volume": 1132393772
  },
  {
    "ts": 1612483200,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1961,
      "authorized": 1961,
      "funded": 195
    },
    "payments": 3,
    "payments_amount": 456915410,
    "trades": 4,
    "traded_amount": 357115408,
    "price": [
      0.5439519,
      0.5603568643898004,
      0.5119651196511965,
      0.5119651196511965
    ],
    "volume": 197660239
  },
  {
    "ts": 1612569600,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1960,
      "authorized": 1960,
      "funded": 194
    },
    "payments": 2,
    "payments_amount": 147091704,
    "trades": 13,
    "traded_amount": 807139938,
    "price": [
      0.5119651196511965,
      0.5506113481659555,
      0.5119651196511965,
      0.5448038485771965
    ],
    "volume": 304339910
  },
  {
    "ts": 1612656000,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1957,
      "authorized": 1957,
      "funded": 195
    },
    "payments": 1,
    "payments_amount": 494878329,
    "trades": 8,
    "traded_amount": 4095350183,
    "price": [
      0.5448038485771965,
      0.5448038485771965,
      0.4878320742619798,
      0.49476410068402754
    ],
    "volume": 1773696323
  },
  {
    "ts": 1612742400,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1956,
      "authorized": 1956,
      "funded": 195
    },
    "payments": 16,
    "payments_amount": 66265147255,
    "trades": 79,
    "traded_amount": 26440686307,
    "price": [
      0.49476410068402754,
      0.49476410068402754,
      0.4712601965901595,
      0.47913839889524706
    ],
    "volume": 6048184325
  },
  {
    "ts": 1612828800,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1959,
      "authorized": 1959,
      "funded": 198
    },
    "payments": 4,
    "payments_amount": 2280437218,
    "trades": 16,
    "traded_amount": 2668274920,
    "price": [
      0.47913839889524706,
      0.47965303628476363,
      0.4603394603394603,
      0.47472479812829804
    ],
    "volume": 1250960794
  },
  {
    "ts": 1612915200,
    "supply": 2170000000000,
    "trustlines": {
      "total": 1961,
      "authorized": 1961,
      "funded": 197
    },
    "payments": 2,
    "payments_amount": 321290723,
    "trades": 36,
    "traded_amount": 14039946598,
    "price": [
      0.47472479812829804,
      0.47472479812829804,
      0.42386599630282945,
      0.4497424836701642
    ],
    "volume": 3731628200
  },
  {
    "ts": 1613001600,
    "supply": 2820000000000,
    "trustlines": {
      "total": 1960,
      "authorized": 1960,
      "funded": 197
    },
    "payments": 3,
    "payments_amount": 650235263412,
    "trades": 2,
    "traded_amount": 235263412,
    "price": [
      0.4497424836701642,
      0.4497424836701642,
      0.42573284013605445,
      0.42573284013605445
    ],
    "volume": 101531306
  },
  {
    "ts": 1613088000,
    "supply": 2820000000000,
    "trustlines": {
      "total": 1958,
      "authorized": 1958,
      "funded": 197
    },
    "payments": 10,
    "payments_amount": 1056917036752,
    "trades": 89,
    "traded_amount": 102483522141,
    "price": [
      0.42573284013605445,
      0.42573284013605445,
      0.35999999946666666,
      0.3600000038399998
    ],
    "volume": 22667761336
  },
  {
    "ts": 1613174400,
    "supply": 2819999999998,
    "trustlines": {
      "total": 1960,
      "authorized": 1960,
      "funded": 197
    },
    "payments": 3,
    "payments_amount": 4348793216,
    "trades": 43,
    "traded_amount": 9109578604,
    "price": [
      0.3600000038399998,
      0.3600000038399998,
      0.31410869328600016,
      0.3346632444009871
    ],
    "volume": 1921972376
  },
  {
    "ts": 1613260800,
    "supply": 2819999999998,
    "trustlines": {
      "total": 1961,
      "authorized": 1961,
      "funded": 197
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 107014002,
    "price": [
      0.3346632444009871,
      0.3631507736922814,
      0.3346632444009871,
      0.3631507736922814
    ],
    "volume": 19430735
  },
  {
    "ts": 1613347200,
    "supply": 2819999999998,
    "trustlines": {
      "total": 1967,
      "authorized": 1967,
      "funded": 196
    },
    "payments": 3,
    "payments_amount": 28137582012,
    "trades": 50,
    "traded_amount": 26758238005,
    "price": [
      0.3631507736922814,
      0.41218825153374233,
      0.35607646226040507,
      0.3570196336153783
    ],
    "volume": 5559468637
  },
  {
    "ts": 1613433600,
    "supply": 2819999999998,
    "trustlines": {
      "total": 1968,
      "authorized": 1968,
      "funded": 197
    },
    "payments": 9,
    "payments_amount": 501964600000,
    "trades": 89,
    "traded_amount": 26080810992,
    "price": [
      0.3570196336153783,
      0.3967652462372973,
      0.3570196336153783,
      0.3853564547206166
    ],
    "volume": 4978412685
  },
  {
    "ts": 1613520000,
    "supply": 2819999999998,
    "trustlines": {
      "total": 1968,
      "authorized": 1968,
      "funded": 198
    },
    "payments": 5,
    "payments_amount": 2971790793,
    "trades": 43,
    "traded_amount": 27136885587,
    "price": [
      0.3853564547206166,
      0.40113488663633157,
      0.37158778734809567,
      0.3719436179333164
    ],
    "volume": 5207909460
  },
  {
    "ts": 1613606400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1971,
      "authorized": 1971,
      "funded": 197
    },
    "payments": 15,
    "payments_amount": 622703883122,
    "trades": 187,
    "traded_amount": 62187289748,
    "price": [
      0.3719436179333164,
      0.38017140514215425,
      0.35887054300405175,
      0.3600791
    ],
    "volume": 12629515308
  },
  {
    "ts": 1613692800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1977,
      "authorized": 1977,
      "funded": 200
    },
    "payments": 12,
    "payments_amount": 61832601605,
    "trades": 195,
    "traded_amount": 132955794378,
    "price": [
      0.3600791,
      0.383136304663799,
      0.3560135933226079,
      0.3560135933226079
    ],
    "volume": 19260141905
  },
  {
    "ts": 1613779200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1979,
      "authorized": 1979,
      "funded": 202
    },
    "payments": 3,
    "payments_amount": 61469100000,
    "trades": 32,
    "traded_amount": 25201116732,
    "price": [
      0.3560135933226079,
      0.38843929159468266,
      0.3510958125853939,
      0.38843929159468266
    ],
    "volume": 210554841
  },
  {
    "ts": 1613865600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1979,
      "authorized": 1979,
      "funded": 203
    },
    "payments": 3,
    "payments_amount": 3339790734,
    "trades": 10,
    "traded_amount": 174478068,
    "price": [
      0.38843929159468266,
      0.38843929159468266,
      0.36699973155999643,
      0.36699973155999643
    ],
    "volume": 59333633
  },
  {
    "ts": 1613952000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1979,
      "authorized": 1979,
      "funded": 204
    },
    "payments": 13,
    "payments_amount": 155622327550,
    "trades": 237,
    "traded_amount": 202140911932,
    "price": [
      0.36699973155999643,
      0.4582360066185525,
      0.36125639520726543,
      0.38429430655703684
    ],
    "volume": 31106840041
  },
  {
    "ts": 1614038400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1980,
      "authorized": 1980,
      "funded": 203
    },
    "payments": 5,
    "payments_amount": 1209165162,
    "trades": 47,
    "traded_amount": 8704489025,
    "price": [
      0.38429430655703684,
      0.5504283467069855,
      0.38429430655703684,
      0.49520990075594573
    ],
    "volume": 1498858748
  },
  {
    "ts": 1614124800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1981,
      "authorized": 1981,
      "funded": 202
    },
    "payments": 2,
    "payments_amount": 2229216836,
    "trades": 5,
    "traded_amount": 2231216826,
    "price": [
      0.49520990075594573,
      0.49520990075594573,
      0.4408562765802231,
      0.4408562765802231
    ],
    "volume": 982992463
  },
  {
    "ts": 1614211200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1981,
      "authorized": 1981,
      "funded": 204
    },
    "payments": 8,
    "payments_amount": 581909460000,
    "trades": 10,
    "traded_amount": 2627296283,
    "price": [
      0.4408562765802231,
      0.46635700133821173,
      0.4384734019919313,
      0.4660393321740494
    ],
    "volume": 573819755
  },
  {
    "ts": 1614297600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1984,
      "authorized": 1984,
      "funded": 204
    },
    "payments": 6,
    "payments_amount": 50086935831,
    "trades": 12,
    "traded_amount": 33586481761,
    "price": [
      0.4660393321740494,
      0.4785560989093928,
      0.4551510144077599,
      0.4628400151308689
    ],
    "volume": 14933247783
  },
  {
    "ts": 1614384000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1985,
      "authorized": 1985,
      "funded": 204
    },
    "payments": 4,
    "payments_amount": 49808474377,
    "trades": 40,
    "traded_amount": 51946524007,
    "price": [
      0.4628400151308689,
      0.4628400151308689,
      0.38688444588599025,
      0.38688444588599025
    ],
    "volume": 4546598374
  },
  {
    "ts": 1614470400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1985,
      "authorized": 1985,
      "funded": 204
    },
    "payments": 3,
    "payments_amount": 13359217320,
    "trades": 62,
    "traded_amount": 20741615825,
    "price": [
      0.38688444588599025,
      0.4644958844343512,
      0.38688444588599025,
      0.4467160735501748
    ],
    "volume": 3060750576
  },
  {
    "ts": 1614556800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1985,
      "authorized": 1985,
      "funded": 204
    },
    "payments": 9,
    "payments_amount": 121154093000,
    "trades": 47,
    "traded_amount": 16682929523,
    "price": [
      0.4467160735501748,
      0.4467160735501748,
      0.4240402864887291,
      0.4262266103304453
    ],
    "volume": 2991269644
  },
  {
    "ts": 1614643200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1986,
      "authorized": 1986,
      "funded": 205
    },
    "payments": 10,
    "payments_amount": 9219034744,
    "trades": 49,
    "traded_amount": 27792107711,
    "price": [
      0.4262266103304453,
      0.42643923239153997,
      0.41084439888224655,
      0.42643923239153997
    ],
    "volume": 7647672540
  },
  {
    "ts": 1614729600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1986,
      "authorized": 1986,
      "funded": 205
    },
    "payments": 1,
    "payments_amount": 1351400000,
    "trades": 324,
    "traded_amount": 322843353044,
    "price": [
      0.42643923239153997,
      0.42643923253816723,
      0.41152262882308593,
      0.4115226339463337
    ],
    "volume": 97289643507
  },
  {
    "ts": 1614816000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1993,
      "authorized": 1993,
      "funded": 207
    },
    "payments": 16,
    "payments_amount": 21836391639,
    "trades": 49,
    "traded_amount": 7530826425,
    "price": [
      0.4115226339463337,
      0.4414306548015231,
      0.4115226339463337,
      0.42167277282803445
    ],
    "volume": 1912449763
  },
  {
    "ts": 1614902400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 1998,
      "authorized": 1998,
      "funded": 209
    },
    "payments": 12,
    "payments_amount": 64486056475,
    "trades": 11,
    "traded_amount": 3920853972,
    "price": [
      0.42167277282803445,
      0.4415202105076128,
      0.42167277282803445,
      0.4388066
    ],
    "volume": 1469448720
  },
  {
    "ts": 1614988800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2000,
      "authorized": 2000,
      "funded": 209
    },
    "payments": 13,
    "payments_amount": 41488403142,
    "trades": 9,
    "traded_amount": 15010903142,
    "price": [
      0.4388066,
      0.4462816903373077,
      0.4388066,
      0.4444113
    ],
    "volume": 6687803779
  },
  {
    "ts": 1615075200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2000,
      "authorized": 2000,
      "funded": 209
    },
    "payments": 2,
    "payments_amount": 50000000000,
    "trades": 2,
    "traded_amount": 457006,
    "price": [
      0.4444113,
      0.4444113,
      0.4323094226334009,
      0.4323094226334009
    ],
    "volume": 98784
  },
  {
    "ts": 1615161600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2004,
      "authorized": 2004,
      "funded": 212
    },
    "payments": 17,
    "payments_amount": 531795478884,
    "trades": 63,
    "traded_amount": 25518992879,
    "price": [
      0.4323094226334009,
      0.4338640445024592,
      0.41163298823265976,
      0.41163298823265976
    ],
    "volume": 4248565344
  },
  {
    "ts": 1615248000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2007,
      "authorized": 2007,
      "funded": 210
    },
    "payments": 12,
    "payments_amount": 25182650200,
    "trades": 267,
    "traded_amount": 201119779477,
    "price": [
      0.41163298823265976,
      0.41389651337628963,
      0.39999999931926644,
      0.41100794368943794
    ],
    "volume": 68487319189
  },
  {
    "ts": 1615334400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2012,
      "authorized": 2012,
      "funded": 209
    },
    "payments": 12,
    "payments_amount": 26924979028,
    "trades": 12,
    "traded_amount": 2001970439,
    "price": [
      0.41100794368943794,
      0.423728813559322,
      0.41100794368943794,
      0.423728813559322
    ],
    "volume": 720013589
  },
  {
    "ts": 1615420800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2010,
      "authorized": 2010,
      "funded": 208
    },
    "payments": 5,
    "payments_amount": 26612400000,
    "trades": 156,
    "traded_amount": 53889446600,
    "price": [
      0.423728813559322,
      0.44260076327073555,
      0.423728813559322,
      0.4410292140064514
    ],
    "volume": 15912070589
  },
  {
    "ts": 1615507200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2012,
      "authorized": 2012,
      "funded": 209
    },
    "payments": 17,
    "payments_amount": 44142471617,
    "trades": 163,
    "traded_amount": 109694391514,
    "price": [
      0.4410292140064514,
      0.465116331232684,
      0.4410292140064514,
      0.4651163134965952
    ],
    "volume": 37997969327
  },
  {
    "ts": 1615593600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2013,
      "authorized": 2013,
      "funded": 210
    },
    "payments": 7,
    "payments_amount": 93435673400,
    "trades": 96,
    "traded_amount": 51704039432,
    "price": [
      0.4651163134965952,
      0.4717208498153145,
      0.4541705854292907,
      0.4541708002536098
    ],
    "volume": 21457271966
  },
  {
    "ts": 1615680000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2015,
      "authorized": 2015,
      "funded": 211
    },
    "payments": 6,
    "payments_amount": 14283400000,
    "trades": 8,
    "traded_amount": 649199317,
    "price": [
      0.4541708002536098,
      0.4722222222222222,
      0.44861786608807397,
      0.4649038072181523
    ],
    "volume": 300000054
  },
  {
    "ts": 1615766400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2014,
      "authorized": 2014,
      "funded": 211
    },
    "payments": 14,
    "payments_amount": 78248604100,
    "trades": 50,
    "traded_amount": 61087601154,
    "price": [
      0.4649038072181523,
      0.47619047627624844,
      0.45796465933013103,
      0.4591036829518278
    ],
    "volume": 24957704721
  },
  {
    "ts": 1615852800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2013,
      "authorized": 2013,
      "funded": 211
    },
    "payments": 16,
    "payments_amount": 28116836383,
    "trades": 5,
    "traded_amount": 1645936380,
    "price": [
      0.4591036829518278,
      0.4591036829518278,
      0.44864388084979356,
      0.4506242833333333
    ],
    "volume": 583831175
  },
  {
    "ts": 1615939200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2012,
      "authorized": 2012,
      "funded": 210
    },
    "payments": 14,
    "payments_amount": 7344493237,
    "trades": 3,
    "traded_amount": 752101773,
    "price": [
      0.4506242833333333,
      0.4506242833333333,
      0.44462287714285714,
      0.44462287714285714
    ],
    "volume": 155618007
  },
  {
    "ts": 1616025600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2013,
      "authorized": 2013,
      "funded": 210
    },
    "payments": 6,
    "payments_amount": 5617189676,
    "trades": 1,
    "traded_amount": 56689676,
    "price": [
      0.44462287714285714,
      0.44462287714285714,
      0.44462287714285714,
      0.44462287714285714
    ],
    "volume": 0
  },
  {
    "ts": 1616112000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2015,
      "authorized": 2015,
      "funded": 210
    },
    "payments": 6,
    "payments_amount": 12019192988,
    "trades": 2,
    "traded_amount": 606434192,
    "price": [
      0.44462287714285714,
      0.4619866278007542,
      0.44462287714285714,
      0.4619866278007542
    ],
    "volume": 250000000
  },
  {
    "ts": 1616198400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2019,
      "authorized": 2019,
      "funded": 211
    },
    "payments": 12,
    "payments_amount": 434169829409,
    "trades": 41,
    "traded_amount": 24831237795,
    "price": [
      0.4619866278007542,
      0.4619866278007542,
      0.423728813559322,
      0.4373684334723962
    ],
    "volume": 9491400000
  },
  {
    "ts": 1616284800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2019,
      "authorized": 2019,
      "funded": 212
    },
    "payments": 4,
    "payments_amount": 79055500000,
    "trades": 3,
    "traded_amount": 1028467061,
    "price": [
      0.4373684334723962,
      0.46108859616799774,
      0.4267235998534472,
      0.45454545454545453
    ],
    "volume": 439705488
  },
  {
    "ts": 1616371200,
    "supply": 3319999999998,
    "trustlines": {
      "total": 2023,
      "authorized": 2023,
      "funded": 212
    },
    "payments": 19,
    "payments_amount": 276472678800,
    "trades": 8,
    "traded_amount": 5501268035,
    "price": [
      0.45454545454545453,
      0.45454545454545453,
      0.4396177928219106,
      0.4517695364399295
    ],
    "volume": 2435233500
  },
  {
    "ts": 1616457600,
    "supply": 3119999999998,
    "trustlines": {
      "total": 2022,
      "authorized": 2022,
      "funded": 213
    },
    "payments": 17,
    "payments_amount": 477320160757,
    "trades": 22,
    "traded_amount": 24866822243,
    "price": [
      0.4517695364399295,
      0.46511627913570774,
      0.4517695364399295,
      0.4633415090342765
    ],
    "volume": 8330721301
  },
  {
    "ts": 1616544000,
    "supply": 3119999999998,
    "trustlines": {
      "total": 2023,
      "authorized": 2023,
      "funded": 213
    },
    "payments": 10,
    "payments_amount": 47307556009,
    "trades": 114,
    "traded_amount": 91717404210,
    "price": [
      0.4633415090342765,
      0.5050505063770492,
      0.45594101723892294,
      0.4972738325876151
    ],
    "volume": 35112673714
  },
  {
    "ts": 1616630400,
    "supply": 3119999999998,
    "trustlines": {
      "total": 2021,
      "authorized": 2021,
      "funded": 213
    },
    "payments": 15,
    "payments_amount": 155843387900,
    "trades": 317,
    "traded_amount": 176435567663,
    "price": [
      0.4972738325876151,
      0.5128207507987591,
      0.4826158940397351,
      0.48929038067228126
    ],
    "volume": 40454582702
  },
  {
    "ts": 1616716800,
    "supply": 2819999999998,
    "trustlines": {
      "total": 2025,
      "authorized": 2025,
      "funded": 215
    },
    "payments": 18,
    "payments_amount": 1013539499170,
    "trades": 196,
    "traded_amount": 195740255855,
    "price": [
      0.48929038067228126,
      0.48929038067228126,
      0.45731357751010304,
      0.45731357993157834
    ],
    "volume": 75808827199
  },
  {
    "ts": 1616803200,
    "supply": 2819999999998,
    "trustlines": {
      "total": 2027,
      "authorized": 2027,
      "funded": 216
    },
    "payments": 5,
    "payments_amount": 109891185300,
    "trades": 108,
    "traded_amount": 55511708316,
    "price": [
      0.45731357993157834,
      0.4662044296937773,
      0.4522367857029,
      0.45847916084301704
    ],
    "volume": 11778102028
  },
  {
    "ts": 1616889600,
    "supply": 2819999999998,
    "trustlines": {
      "total": 2026,
      "authorized": 2026,
      "funded": 216
    },
    "payments": 3,
    "payments_amount": 8298070000,
    "trades": 64,
    "traded_amount": 113784713915,
    "price": [
      0.45847916084301704,
      0.46384772613427333,
      0.4329004329004329,
      0.44913226438444726
    ],
    "volume": 42526894971
  },
  {
    "ts": 1616976000,
    "supply": 2819999999998,
    "trustlines": {
      "total": 2027,
      "authorized": 2027,
      "funded": 217
    },
    "payments": 20,
    "payments_amount": 159205217336,
    "trades": 14,
    "traded_amount": 6500013126,
    "price": [
      0.44913226438444726,
      0.44913226438444726,
      0.42735042666666667,
      0.42735042666666667
    ],
    "volume": 2545093989
  },
  {
    "ts": 1617062400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2031,
      "authorized": 2031,
      "funded": 218
    },
    "payments": 11,
    "payments_amount": 2151638504652,
    "trades": 13,
    "traded_amount": 13794253412,
    "price": [
      0.42735042666666667,
      0.43668122306831136,
      0.4255319147372067,
      0.43668122306831136
    ],
    "volume": 5885176811
  },
  {
    "ts": 1617148800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2033,
      "authorized": 2033,
      "funded": 218
    },
    "payments": 7,
    "payments_amount": 9140295000,
    "trades": 74,
    "traded_amount": 24402593529,
    "price": [
      0.43668122306831136,
      0.45413690101879683,
      0.43144988847500904,
      0.43144988847500904
    ],
    "volume": 5425081680
  },
  {
    "ts": 1617235200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2033,
      "authorized": 2033,
      "funded": 218
    },
    "payments": 14,
    "payments_amount": 1696762500000,
    "trades": 80,
    "traded_amount": 50112483961,
    "price": [
      0.43144988847500904,
      0.43144988847500904,
      0.4166666666666667,
      0.4166666666666667
    ],
    "volume": 17547837448
  },
  {
    "ts": 1617321600,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2032,
      "authorized": 2032,
      "funded": 217
    },
    "payments": 16,
    "payments_amount": 1959110439649,
    "trades": 176,
    "traded_amount": 247715080324,
    "price": [
      0.4166666666666667,
      0.4166666666666667,
      0.3846153839377793,
      0.40020661775042266
    ],
    "volume": 91674148328
  },
  {
    "ts": 1617408000,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2032,
      "authorized": 2032,
      "funded": 217
    },
    "payments": 20,
    "payments_amount": 533423979000,
    "trades": 112,
    "traded_amount": 46949265405,
    "price": [
      0.40020661775042266,
      0.4273504320993525,
      0.38810175946903996,
      0.4273504320993525
    ],
    "volume": 9511467472
  },
  {
    "ts": 1617494400,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2035,
      "authorized": 2035,
      "funded": 219
    },
    "payments": 6,
    "payments_amount": 13526612267,
    "trades": 28,
    "traded_amount": 11063981765,
    "price": [
      0.4273504320993525,
      0.4291846757596148,
      0.4101032978014657,
      0.4163156496324506
    ],
    "volume": 2616134325
  },
  {
    "ts": 1617580800,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2036,
      "authorized": 2036,
      "funded": 217
    },
    "payments": 30,
    "payments_amount": 1536443820241,
    "trades": 216,
    "traded_amount": 106042860296,
    "price": [
      0.4163156496324506,
      0.4163156496324506,
      0.32894736842105265,
      0.32894736842105265
    ],
    "volume": 31129498275
  },
  {
    "ts": 1617667200,
    "supply": 3419999999998,
    "trustlines": {
      "total": 2043,
      "authorized": 2043,
      "funded": 217
    },
    "payments": 47,
    "payments_amount": 4766685094000,
    "trades": 14,
    "traded_amount": 117429940733,
    "price": [
      0.32894736842105265,
      0.3496503797388297,
      0.32894736842105265,
      0.3465146038938676
    ],
    "volume": 40363340570
  },
  {
    "ts": 1617753600,
    "supply": 3419999999903,
    "trustlines": {
      "total": 2041,
      "authorized": 2041,
      "funded": 214
    },
    "payments": 43,
    "payments_amount": 6024859449301,
    "trades": 184,
    "traded_amount": 52392016755,
    "price": [
      0.3465146038938676,
      0.3759398506133756,
      0.33444816053511706,
      0.3759398506133756
    ],
    "volume": 9217492953
  },
  {
    "ts": 1617840000,
    "supply": 3919999999903,
    "trustlines": {
      "total": 2041,
      "authorized": 2041,
      "funded": 215
    },
    "payments": 42,
    "payments_amount": 6784545600000,
    "trades": 89,
    "traded_amount": 13204453504,
    "price": [
      0.3759398506133756,
      0.3759398506133756,
      0.35459308417743624,
      0.35459308417743624
    ],
    "volume": 300005076
  },
  {
    "ts": 1617926400,
    "supply": 3919999999903,
    "trustlines": {
      "total": 2043,
      "authorized": 2043,
      "funded": 216
    },
    "payments": 36,
    "payments_amount": 3460484532846,
    "trades": 14,
    "traded_amount": 7541561462,
    "price": [
      0.35459308417743624,
      0.36901188618081404,
      0.35398227803653864,
      0.35778175203303675
    ],
    "volume": 225989577
  },
  {
    "ts": 1618012800,
    "supply": 3919999999903,
    "trustlines": {
      "total": 2044,
      "authorized": 2044,
      "funded": 216
    },
    "payments": 11,
    "payments_amount": 435806095000,
    "trades": 82,
    "traded_amount": 46357896864,
    "price": [
      0.35778175203303675,
      0.3577817544938804,
      0.30303030303030304,
      0.3271875764085237
    ],
    "volume": 11588042795
  },
  {
    "ts": 1618099200,
    "supply": 3919999999903,
    "trustlines": {
      "total": 2044,
      "authorized": 2044,
      "funded": 217
    },
    "payments": 6,
    "payments_amount": 4312286400,
    "trades": 116,
    "traded_amount": 47963043149,
    "price": [
      0.3271875764085237,
      0.3271875764085237,
      0.28116180086101583,
      0.29041095890410956
    ],
    "volume": 5220962159
  },
  {
    "ts": 1618185600,
    "supply": 3814369999903,
    "trustlines": {
      "total": 2044,
      "authorized": 2044,
      "funded": 218
    },
    "payments": 84,
    "payments_amount": 9622965198900,
    "trades": 118,
    "traded_amount": 36283110622,
    "price": [
      0.29041095890410956,
      0.3083107352157244,
      0.29041095890410956,
      0.30831070005457756
    ],
    "volume": 3868100710
  },
  {
    "ts": 1618272000,
    "supply": 3814369999903,
    "trustlines": {
      "total": 2046,
      "authorized": 2046,
      "funded": 219
    },
    "payments": 22,
    "payments_amount": 777584395110,
    "trades": 160,
    "traded_amount": 43281217887,
    "price": [
      0.30831070005457756,
      0.30831070005457756,
      0.25974002374194366,
      0.2698217003991272
    ],
    "volume": 7916145455
  },
  {
    "ts": 1618358400,
    "supply": 3814369999903,
    "trustlines": {
      "total": 2047,
      "authorized": 2047,
      "funded": 222
    },
    "payments": 40,
    "payments_amount": 2280904929599,
    "trades": 91,
    "traded_amount": 33823772389,
    "price": [
      0.2698217003991272,
      0.2932551333544086,
      0.2531645332557404,
      0.2908550106666667
    ],
    "volume": 6931828987
  },
  {
    "ts": 1618444800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2045,
      "authorized": 2045,
      "funded": 221
    },
    "payments": 7,
    "payments_amount": 489457500003,
    "trades": 89,
    "traded_amount": 20082583375,
    "price": [
      0.2908550106666667,
      0.2908550106666667,
      0.2732240276638861,
      0.2732240276638861
    ],
    "volume": 2374283787
  },
  {
    "ts": 1618531200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2047,
      "authorized": 2047,
      "funded": 220
    },
    "payments": 21,
    "payments_amount": 2173095431098,
    "trades": 174,
    "traded_amount": 53878768206,
    "price": [
      0.2732240276638861,
      0.31069400879715126,
      0.27247552807831016,
      0.28867122845472776
    ],
    "volume": 10380796644
  },
  {
    "ts": 1618617600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2048,
      "authorized": 2048,
      "funded": 220
    },
    "payments": 5,
    "payments_amount": 218265300000,
    "trades": 66,
    "traded_amount": 10300792733,
    "price": [
      0.28867122845472776,
      0.2959182000736465,
      0.2793296086926485,
      0.2959181989991952
    ],
    "volume": 1832166691
  },
  {
    "ts": 1618704000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2051,
      "authorized": 2051,
      "funded": 220
    },
    "payments": 2,
    "payments_amount": 20729540000,
    "trades": 220,
    "traded_amount": 122882575203,
    "price": [
      0.2959181989991952,
      0.3696858698084727,
      0.2959181989991952,
      0.32573289674804484
    ],
    "volume": 25494190403
  },
  {
    "ts": 1618790400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2052,
      "authorized": 2052,
      "funded": 220
    },
    "payments": 22,
    "payments_amount": 796720395000,
    "trades": 167,
    "traded_amount": 35261948886,
    "price": [
      0.32573289674804484,
      0.3597122302158273,
      0.3194795847147095,
      0.3523809523809524
    ],
    "volume": 8159512275
  },
  {
    "ts": 1618876800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2054,
      "authorized": 2054,
      "funded": 221
    },
    "payments": 8,
    "payments_amount": 5882599456,
    "trades": 147,
    "traded_amount": 34864767467,
    "price": [
      0.3523809523809524,
      0.37735849056603776,
      0.3412918730688116,
      0.3412918730688116
    ],
    "volume": 8171119973
  },
  {
    "ts": 1618963200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2056,
      "authorized": 2056,
      "funded": 221
    },
    "payments": 4,
    "payments_amount": 25338700000,
    "trades": 50,
    "traded_amount": 2976216182,
    "price": [
      0.3412918730688116,
      0.36496354613039367,
      0.33783783783783783,
      0.36496352506622937
    ],
    "volume": 675291031
  },
  {
    "ts": 1619049600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2057,
      "authorized": 2057,
      "funded": 220
    },
    "payments": 8,
    "payments_amount": 68587899995,
    "trades": 223,
    "traded_amount": 49985277960,
    "price": [
      0.36496352506622937,
      0.40485833264480536,
      0.3558697249204147,
      0.4048583041158653
    ],
    "volume": 11685661389
  },
  {
    "ts": 1619136000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2057,
      "authorized": 2057,
      "funded": 219
    },
    "payments": 10,
    "payments_amount": 215203682359,
    "trades": 170,
    "traded_amount": 70897115215,
    "price": [
      0.4048583041158653,
      0.46948356998122764,
      0.4006905980638195,
      0.4098360655737705
    ],
    "volume": 23369126706
  },
  {
    "ts": 1619222400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2056,
      "authorized": 2056,
      "funded": 220
    },
    "payments": 17,
    "payments_amount": 21385402518,
    "trades": 14,
    "traded_amount": 7454262398,
    "price": [
      0.4098360655737705,
      0.43739392672862537,
      0.3999899145256045,
      0.43739392672862537
    ],
    "volume": 3000031728
  },
  {
    "ts": 1619308800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2058,
      "authorized": 2058,
      "funded": 220
    },
    "payments": 8,
    "payments_amount": 30899289000,
    "trades": 57,
    "traded_amount": 7946087260,
    "price": [
      0.43739392672862537,
      0.4484306742030495,
      0.4219427086190237,
      0.4484306742030495
    ],
    "volume": 2122840494
  },
  {
    "ts": 1619395200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2059,
      "authorized": 2059,
      "funded": 220
    },
    "payments": 7,
    "payments_amount": 145338083000,
    "trades": 65,
    "traded_amount": 25034980236,
    "price": [
      0.4484306742030495,
      0.4484306742030495,
      0.3816793649862662,
      0.3816793649862662
    ],
    "volume": 6426968700
  },
  {
    "ts": 1619481600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2061,
      "authorized": 2061,
      "funded": 221
    },
    "payments": 8,
    "payments_amount": 95701300000,
    "trades": 47,
    "traded_amount": 19659990361,
    "price": [
      0.3816793649862662,
      0.3816793904144055,
      0.35587117780042127,
      0.35587117780042127
    ],
    "volume": 6041941830
  },
  {
    "ts": 1619568000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2062,
      "authorized": 2062,
      "funded": 221
    },
    "payments": 41,
    "payments_amount": 52464475655,
    "trades": 39,
    "traded_amount": 15770451068,
    "price": [
      0.35587117780042127,
      0.38314176921199944,
      0.35587117780042127,
      0.3780067997811622
    ],
    "volume": 5223322942
  },
  {
    "ts": 1619654400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2065,
      "authorized": 2065,
      "funded": 223
    },
    "payments": 15,
    "payments_amount": 62057515169,
    "trades": 82,
    "traded_amount": 26828998712,
    "price": [
      0.3780067997811622,
      0.39309526682942925,
      0.373280656981254,
      0.39309526682942925
    ],
    "volume": 4393716528
  },
  {
    "ts": 1619740800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2069,
      "authorized": 2069,
      "funded": 225
    },
    "payments": 24,
    "payments_amount": 28297942604,
    "trades": 107,
    "traded_amount": 59580487996,
    "price": [
      0.39309526682942925,
      0.39309526682942925,
      0.34843205574912894,
      0.3525640689876083
    ],
    "volume": 13203474433
  },
  {
    "ts": 1619827200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2070,
      "authorized": 2070,
      "funded": 225
    },
    "payments": 23,
    "payments_amount": 36943789414,
    "trades": 47,
    "traded_amount": 11783492770,
    "price": [
      0.3525640689876083,
      0.35971223038836364,
      0.3460207612456747,
      0.3460207612456747
    ],
    "volume": 2159305009
  },
  {
    "ts": 1619913600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2073,
      "authorized": 2073,
      "funded": 225
    },
    "payments": 3,
    "payments_amount": 33892740000,
    "trades": 132,
    "traded_amount": 77966494862,
    "price": [
      0.3460207612456747,
      0.3560060090821867,
      0.33235639800995026,
      0.333630448346555
    ],
    "volume": 15373619272
  },
  {
    "ts": 1620000000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2075,
      "authorized": 2075,
      "funded": 226
    },
    "payments": 25,
    "payments_amount": 130069278686,
    "trades": 134,
    "traded_amount": 46569684890,
    "price": [
      0.333630448346555,
      0.3372897146666442,
      0.3257328990228013,
      0.3372897146666442
    ],
    "volume": 9716645170
  },
  {
    "ts": 1620086400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2076,
      "authorized": 2076,
      "funded": 224
    },
    "payments": 35,
    "payments_amount": 74240809109,
    "trades": 122,
    "traded_amount": 29169896286,
    "price": [
      0.3372897146666442,
      0.3669724936340625,
      0.33718966163046293,
      0.3669724936340625
    ],
    "volume": 8092719929
  },
  {
    "ts": 1620172800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2082,
      "authorized": 2082,
      "funded": 227
    },
    "payments": 14,
    "payments_amount": 313412418040,
    "trades": 160,
    "traded_amount": 244309528962,
    "price": [
      0.3669724936340625,
      0.3669724936340625,
      0.3105590045045045,
      0.32212446924591687
    ],
    "volume": 55716128714
  },
  {
    "ts": 1620259200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2091,
      "authorized": 2091,
      "funded": 230
    },
    "payments": 21,
    "payments_amount": 322646865133,
    "trades": 432,
    "traded_amount": 336298674316,
    "price": [
      0.32212446924591687,
      0.32212446924591687,
      0.28011051873759585,
      0.289171329867514
    ],
    "volume": 47655458104
  },
  {
    "ts": 1620345600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2098,
      "authorized": 2098,
      "funded": 234
    },
    "payments": 13,
    "payments_amount": 272042253123,
    "trades": 196,
    "traded_amount": 195785416108,
    "price": [
      0.289171329867514,
      0.3210168125415726,
      0.27932960893854747,
      0.3210168125415726
    ],
    "volume": 47419971071
  },
  {
    "ts": 1620432000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2103,
      "authorized": 2103,
      "funded": 238
    },
    "payments": 8,
    "payments_amount": 29749776227,
    "trades": 113,
    "traded_amount": 39249165126,
    "price": [
      0.3210168125415726,
      0.3210168125415726,
      0.29662149990091413,
      0.30223300024438804
    ],
    "volume": 5555186061
  },
  {
    "ts": 1620518400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2107,
      "authorized": 2107,
      "funded": 239
    },
    "payments": 4,
    "payments_amount": 131289654000,
    "trades": 56,
    "traded_amount": 111987613300,
    "price": [
      0.30223300024438804,
      0.3276250356291021,
      0.30223300024438804,
      0.3169437982496508
    ],
    "volume": 33285254093
  },
  {
    "ts": 1620604800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2110,
      "authorized": 2110,
      "funded": 241
    },
    "payments": 12,
    "payments_amount": 1190327945000,
    "trades": 347,
    "traded_amount": 170109154779,
    "price": [
      0.3169437982496508,
      0.3169437982496508,
      0.2531645572704242,
      0.27820849528531055
    ],
    "volume": 38753420598
  },
  {
    "ts": 1620691200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2113,
      "authorized": 2113,
      "funded": 244
    },
    "payments": 25,
    "payments_amount": 1798636576998,
    "trades": 85,
    "traded_amount": 24725505662,
    "price": [
      0.27820849528531055,
      0.3055463755844491,
      0.262370748048485,
      0.26329999974840224
    ],
    "volume": 6450746422
  },
  {
    "ts": 1620777600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2118,
      "authorized": 2118,
      "funded": 246
    },
    "payments": 17,
    "payments_amount": 633566804841,
    "trades": 129,
    "traded_amount": 22927985836,
    "price": [
      0.26329999974840224,
      0.31446552313678644,
      0.2632999381245145,
      0.31446540796828887
    ],
    "volume": 4824081450
  },
  {
    "ts": 1620864000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2119,
      "authorized": 2119,
      "funded": 246
    },
    "payments": 10,
    "payments_amount": 90316189261,
    "trades": 169,
    "traded_amount": 149359774252,
    "price": [
      0.31446540796828887,
      0.32786885245901637,
      0.291970802919708,
      0.291970802919708
    ],
    "volume": 33058978667
  },
  {
    "ts": 1620950400,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2120,
      "authorized": 2120,
      "funded": 246
    },
    "payments": 20,
    "payments_amount": 50626648159,
    "trades": 84,
    "traded_amount": 9430010103,
    "price": [
      0.291970802919708,
      0.2941176723157217,
      0.27,
      0.2941176723157217
    ],
    "volume": 1739424616
  },
  {
    "ts": 1621036800,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2121,
      "authorized": 2121,
      "funded": 247
    },
    "payments": 8,
    "payments_amount": 45789994860,
    "trades": 49,
    "traded_amount": 31264384940,
    "price": [
      0.2941176723157217,
      0.2941176723157217,
      0.2597402597402597,
      0.2597402597402597
    ],
    "volume": 5698867934
  },
  {
    "ts": 1621123200,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2124,
      "authorized": 2124,
      "funded": 248
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 98,
    "traded_amount": 39064653209,
    "price": [
      0.2597402597402597,
      0.2824101569446094,
      0.2415458934172109,
      0.26666666666666666
    ],
    "volume": 7961244249
  },
  {
    "ts": 1621209600,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2133,
      "authorized": 2133,
      "funded": 249
    },
    "payments": 33,
    "payments_amount": 907795346744,
    "trades": 208,
    "traded_amount": 84973672238,
    "price": [
      0.26666666666666666,
      0.2994011991132744,
      0.26666666666666666,
      0.29761904831020697
    ],
    "volume": 17934678192
  },
  {
    "ts": 1621296000,
    "supply": 3814369999900,
    "trustlines": {
      "total": 2136,
      "authorized": 2136,
      "funded": 250
    },
    "payments": 38,
    "payments_amount": 965751978107,
    "trades": 154,
    "traded_amount": 31409065331,
    "price": [
      0.29761904831020697,
      0.29761904831020697,
      0.2739725792270494,
      0.2871952927991034
    ],
    "volume": 5796095058
  },
  {
    "ts": 1621382400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2141,
      "authorized": 2141,
      "funded": 249
    },
    "payments": 30,
    "payments_amount": 679627086052,
    "trades": 1062,
    "traded_amount": 677929775156,
    "price": [
      0.2871952927991034,
      0.5970719669612635,
      0.2871952927991034,
      0.4178696863491797
    ],
    "volume": 149962770014
  },
  {
    "ts": 1621468800,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2142,
      "authorized": 2142,
      "funded": 248
    },
    "payments": 20,
    "payments_amount": 138619435883,
    "trades": 234,
    "traded_amount": 62060155111,
    "price": [
      0.4178696863491797,
      0.43859649122807015,
      0.35714268488261325,
      0.3840494279046342
    ],
    "volume": 14684950924
  },
  {
    "ts": 1621555200,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2144,
      "authorized": 2144,
      "funded": 250
    },
    "payments": 14,
    "payments_amount": 1323316559447,
    "trades": 239,
    "traded_amount": 77178174536,
    "price": [
      0.3840494279046342,
      0.49242493876741256,
      0.3738317710098006,
      0.4636812818801574
    ],
    "volume": 20768413727
  },
  {
    "ts": 1621641600,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2146,
      "authorized": 2146,
      "funded": 251
    },
    "payments": 6,
    "payments_amount": 30372352415,
    "trades": 112,
    "traded_amount": 33931396158,
    "price": [
      0.4636812818801574,
      0.5000002039646652,
      0.4576209007662748,
      0.47498940227786113
    ],
    "volume": 8418263710
  },
  {
    "ts": 1621728000,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2150,
      "authorized": 2150,
      "funded": 254
    },
    "payments": 9,
    "payments_amount": 73278491411,
    "trades": 275,
    "traded_amount": 73818785423,
    "price": [
      0.47498940227786113,
      0.6666670732465936,
      0.4749889458428181,
      0.5102040793415725
    ],
    "volume": 20890141568
  },
  {
    "ts": 1621814400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2152,
      "authorized": 2152,
      "funded": 254
    },
    "payments": 10,
    "payments_amount": 143032196221,
    "trades": 164,
    "traded_amount": 20271464236,
    "price": [
      0.5102040793415725,
      0.5102183295252603,
      0.4219409282700422,
      0.43604953852973943
    ],
    "volume": 5345190216
  },
  {
    "ts": 1621900800,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2153,
      "authorized": 2153,
      "funded": 255
    },
    "payments": 8,
    "payments_amount": 94983154483,
    "trades": 361,
    "traded_amount": 113850085923,
    "price": [
      0.43604953852973943,
      0.46762171582630696,
      0.4106776180698152,
      0.4376339259364627
    ],
    "volume": 20652576255
  },
  {
    "ts": 1621987200,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2161,
      "authorized": 2161,
      "funded": 256
    },
    "payments": 12,
    "payments_amount": 39189824457,
    "trades": 342,
    "traded_amount": 115968616471,
    "price": [
      0.4376339259364627,
      0.4376379750965146,
      0.4032258034642828,
      0.4248383695750891
    ],
    "volume": 30578029819
  },
  {
    "ts": 1622073600,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2163,
      "authorized": 2163,
      "funded": 256
    },
    "payments": 11,
    "payments_amount": 41349339476,
    "trades": 100,
    "traded_amount": 26530813463,
    "price": [
      0.4248383695750891,
      0.45662100857015175,
      0.4248383695750891,
      0.45662100857015175
    ],
    "volume": 5395939709
  },
  {
    "ts": 1622160000,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2167,
      "authorized": 2167,
      "funded": 259
    },
    "payments": 18,
    "payments_amount": 99486576630,
    "trades": 259,
    "traded_amount": 85315604348,
    "price": [
      0.45662100857015175,
      0.5154657302586327,
      0.45584045584045585,
      0.48479089822728116
    ],
    "volume": 22191533671
  },
  {
    "ts": 1622246400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2171,
      "authorized": 2171,
      "funded": 260
    },
    "payments": 10,
    "payments_amount": 74423600000,
    "trades": 155,
    "traded_amount": 29113998419,
    "price": [
      0.48479089822728116,
      0.546448087431694,
      0.48084175355482306,
      0.5291156337293182
    ],
    "volume": 7960521694
  },
  {
    "ts": 1622332800,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2173,
      "authorized": 2173,
      "funded": 260
    },
    "payments": 2,
    "payments_amount": 4537600000,
    "trades": 95,
    "traded_amount": 13364801044,
    "price": [
      0.5291156337293182,
      0.5494505495714654,
      0.48543689182120625,
      0.5194805231249597
    ],
    "volume": 3399615509
  },
  {
    "ts": 1622419200,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2174,
      "authorized": 2174,
      "funded": 260
    },
    "payments": 14,
    "payments_amount": 678873386048,
    "trades": 67,
    "traded_amount": 5203161430,
    "price": [
      0.5194805231249597,
      0.5221934639805735,
      0.4739336491992204,
      0.49019608256612696
    ],
    "volume": 1101128935
  },
  {
    "ts": 1622505600,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2178,
      "authorized": 2178,
      "funded": 261
    },
    "payments": 15,
    "payments_amount": 103202755984,
    "trades": 149,
    "traded_amount": 22552254390,
    "price": [
      0.49019608256612696,
      0.4975124388342477,
      0.43290043263775435,
      0.46342447089136624
    ],
    "volume": 3352807521
  },
  {
    "ts": 1622592000,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2177,
      "authorized": 2177,
      "funded": 261
    },
    "payments": 15,
    "payments_amount": 106690500000,
    "trades": 59,
    "traded_amount": 6212444326,
    "price": [
      0.46342447089136624,
      0.47393365688658506,
      0.46342447089136624,
      0.4739336568575864
    ],
    "volume": 99903782
  },
  {
    "ts": 1622678400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2181,
      "authorized": 2181,
      "funded": 260
    },
    "payments": 11,
    "payments_amount": 250853230416,
    "trades": 56,
    "traded_amount": 155471975676,
    "price": [
      0.4739336568575864,
      0.4750595659936533,
      0.4520204651008274,
      0.47241838397437413
    ],
    "volume": 64907728426
  },
  {
    "ts": 1622764800,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2189,
      "authorized": 2189,
      "funded": 264
    },
    "payments": 24,
    "payments_amount": 1469498100652,
    "trades": 689,
    "traded_amount": 610041256298,
    "price": [
      0.47241838397437413,
      0.5291005011057935,
      0.47241838397437413,
      0.5025125624148122
    ],
    "volume": 226287211635
  },
  {
    "ts": 1622851200,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2192,
      "authorized": 2192,
      "funded": 266
    },
    "payments": 6,
    "payments_amount": 27355500000,
    "trades": 186,
    "traded_amount": 140969195671,
    "price": [
      0.5025125624148122,
      0.5347596671988329,
      0.4866179996354744,
      0.5347593582310314
    ],
    "volume": 48195857911
  },
  {
    "ts": 1622937600,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2195,
      "authorized": 2195,
      "funded": 268
    },
    "payments": 5,
    "payments_amount": 296227334784,
    "trades": 13,
    "traded_amount": 685584769,
    "price": [
      0.5347593582310314,
      0.5347593582310314,
      0.5290993119889583,
      0.5291017738672188
    ],
    "volume": 26455025
  },
  {
    "ts": 1623024000,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2199,
      "authorized": 2199,
      "funded": 269
    },
    "payments": 18,
    "payments_amount": 728926021938,
    "trades": 257,
    "traded_amount": 638359279638,
    "price": [
      0.5291017738672188,
      0.5681818215155482,
      0.49180066187844407,
      0.5681818215155482
    ],
    "volume": 235088114710
  },
  {
    "ts": 1623110400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2200,
      "authorized": 2200,
      "funded": 270
    },
    "payments": 16,
    "payments_amount": 345902803200,
    "trades": 402,
    "traded_amount": 413959730597,
    "price": [
      0.5681818215155482,
      0.6296107151976601,
      0.5586591974454402,
      0.5586591974454402
    ],
    "volume": 120323834987
  },
  {
    "ts": 1623196800,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2202,
      "authorized": 2202,
      "funded": 272
    },
    "payments": 10,
    "payments_amount": 58161135672,
    "trades": 100,
    "traded_amount": 14183056941,
    "price": [
      0.5586591974454402,
      0.6042296072507553,
      0.5494501166532885,
      0.5494533935735063
    ],
    "volume": 3861682429
  },
  {
    "ts": 1623283200,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2200,
      "authorized": 2200,
      "funded": 272
    },
    "payments": 3,
    "payments_amount": 10131663055,
    "trades": 14,
    "traded_amount": 662684342,
    "price": [
      0.5494533935735063,
      0.594963598490317,
      0.546448086237142,
      0.594963598490317
    ],
    "volume": 203793865
  },
  {
    "ts": 1623369600,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2199,
      "authorized": 2199,
      "funded": 272
    },
    "payments": 5,
    "payments_amount": 11915273110,
    "trades": 127,
    "traded_amount": 35816048454,
    "price": [
      0.594963598490317,
      0.6250000003918815,
      0.5900352377101266,
      0.6250000003918815
    ],
    "volume": 10965083860
  },
  {
    "ts": 1623456000,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2201,
      "authorized": 2201,
      "funded": 272
    },
    "payments": 2,
    "payments_amount": 10237700000,
    "trades": 78,
    "traded_amount": 68528768012,
    "price": [
      0.6250000003918815,
      0.6535947737104912,
      0.5780346812779488,
      0.5780352792293322
    ],
    "volume": 21892852502
  },
  {
    "ts": 1623542400,
    "supply": 3814369326328,
    "trustlines": {
      "total": 2202,
      "authorized": 2202,
      "funded": 273
    },
    "payments": 1,
    "payments_amount": 2447000000,
    "trades": 53,
    "traded_amount": 3786941957,
    "price": [
      0.5780352792293322,
      0.6135256569756934,
      0.5649717507526822,
      0.5649717507526822
    ],
    "volume": 383860666
  },
  {
    "ts": 1623628800,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2205,
      "authorized": 2205,
      "funded": 274
    },
    "payments": 7,
    "payments_amount": 430260086699,
    "trades": 30,
    "traded_amount": 7733884524,
    "price": [
      0.5649717507526822,
      0.5813962175126803,
      0.5649717507526822,
      0.5742583455962698
    ],
    "volume": 2167677549
  },
  {
    "ts": 1623715200,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2205,
      "authorized": 2205,
      "funded": 274
    },
    "payments": 5,
    "payments_amount": 10748310140,
    "trades": 12,
    "traded_amount": 2312066640,
    "price": [
      0.5742583455962698,
      0.5742583455962698,
      0.5681817881331002,
      0.5681818168072877
    ],
    "volume": 299883689
  },
  {
    "ts": 1623801600,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2205,
      "authorized": 2205,
      "funded": 275
    },
    "payments": 2,
    "payments_amount": 10095300000,
    "trades": 35,
    "traded_amount": 3581915173,
    "price": [
      0.5681818168072877,
      0.625005244828599,
      0.5681818168072877,
      0.625005244828599
    ],
    "volume": 789887468
  },
  {
    "ts": 1623888000,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2205,
      "authorized": 2205,
      "funded": 274
    },
    "payments": 5,
    "payments_amount": 10105633948,
    "trades": 14,
    "traded_amount": 1335589877,
    "price": [
      0.625005244828599,
      0.6329113924050633,
      0.6249972230244596,
      0.626148107138752
    ],
    "volume": 202547560
  },
  {
    "ts": 1623974400,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2206,
      "authorized": 2206,
      "funded": 274
    },
    "payments": 6,
    "payments_amount": 80301615030,
    "trades": 67,
    "traded_amount": 14206842590,
    "price": [
      0.626148107138752,
      0.6896551752891232,
      0.626148107138752,
      0.67569734723635
    ],
    "volume": 5647142452
  },
  {
    "ts": 1624060800,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2208,
      "authorized": 2208,
      "funded": 274
    },
    "payments": 6,
    "payments_amount": 8546600005,
    "trades": 29,
    "traded_amount": 4268139295,
    "price": [
      0.67569734723635,
      0.67569734723635,
      0.6567981020824669,
      0.6567992708241451
    ],
    "volume": 596253343
  },
  {
    "ts": 1624147200,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2211,
      "authorized": 2211,
      "funded": 275
    },
    "payments": 1,
    "payments_amount": 5070000000,
    "trades": 25,
    "traded_amount": 1824836729,
    "price": [
      0.6567992708241451,
      0.7272727290360577,
      0.6567992708241451,
      0.6691562376742567
    ],
    "volume": 663848073
  },
  {
    "ts": 1624233600,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2213,
      "authorized": 2213,
      "funded": 275
    },
    "payments": 4,
    "payments_amount": 10707316926,
    "trades": 38,
    "traded_amount": 7197613766,
    "price": [
      0.6691562376742567,
      0.8333333340284996,
      0.6691562376742567,
      0.8333326645506383
    ],
    "volume": 1900020840
  },
  {
    "ts": 1624320000,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2214,
      "authorized": 2214,
      "funded": 275
    },
    "payments": 5,
    "payments_amount": 253671000000,
    "trades": 112,
    "traded_amount": 382692864293,
    "price": [
      0.8333326645506383,
      0.9898398314424498,
      0.813008126290151,
      0.8516363068056609
    ],
    "volume": 49061398062
  },
  {
    "ts": 1624406400,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2213,
      "authorized": 2213,
      "funded": 275
    },
    "payments": 4,
    "payments_amount": 10160255576,
    "trades": 53,
    "traded_amount": 11277114872,
    "price": [
      0.8516363068056609,
      0.8771929824561403,
      0.7575757539015399,
      0.8217191299188439
    ],
    "volume": 3901823501
  },
  {
    "ts": 1624492800,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2214,
      "authorized": 2214,
      "funded": 275
    },
    "payments": 2,
    "payments_amount": 9936400000,
    "trades": 35,
    "traded_amount": 1383238899,
    "price": [
      0.8217191299188439,
      0.8264563877771425,
      0.7462554823049127,
      0.7794762005603613
    ],
    "volume": 536154554
  },
  {
    "ts": 1624579200,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2214,
      "authorized": 2214,
      "funded": 275
    },
    "payments": 2,
    "payments_amount": 9824700000,
    "trades": 66,
    "traded_amount": 10969149348,
    "price": [
      0.7794762005603613,
      0.8333333383400698,
      0.7462686518935128,
      0.8333333333333334
    ],
    "volume": 810062650
  },
  {
    "ts": 1624665600,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2217,
      "authorized": 2217,
      "funded": 278
    },
    "payments": 5,
    "payments_amount": 10577679592,
    "trades": 51,
    "traded_amount": 5645655089,
    "price": [
      0.8333333333333334,
      0.8771929889991231,
      0.8333333333333334,
      0.8439957621041189
    ],
    "volume": 2090396853
  },
  {
    "ts": 1624752000,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2219,
      "authorized": 2219,
      "funded": 279
    },
    "payments": 2,
    "payments_amount": 2079000000,
    "trades": 21,
    "traded_amount": 9423992008,
    "price": [
      0.8439957621041189,
      0.8469602590350075,
      0.8439957621041189,
      0.8469602590350075
    ],
    "volume": 4510846
  },
  {
    "ts": 1624838400,
    "supply": 3414369326328,
    "trustlines": {
      "total": 2223,
      "authorized": 2223,
      "funded": 280
    },
    "payments": 4,
    "payments_amount": 1200000000,
    "trades": 60,
    "traded_amount": 15564327663,
    "price": [
      0.8469602590350075,
      0.8469602590350075,
      0.7575757465648141,
      0.7575757465648141
    ],
    "volume": 1173968349
  },
  {
    "ts": 1624924800,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2226,
      "authorized": 2226,
      "funded": 281
    },
    "payments": 10,
    "payments_amount": 560852140661,
    "trades": 125,
    "traded_amount": 28757250166,
    "price": [
      0.7575757465648141,
      0.7575768853821628,
      0.6944444435857234,
      0.7252128023605873
    ],
    "volume": 3025477414
  },
  {
    "ts": 1625011200,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2227,
      "authorized": 2227,
      "funded": 282
    },
    "payments": 4,
    "payments_amount": 10526800000,
    "trades": 41,
    "traded_amount": 14957428252,
    "price": [
      0.7252128023605873,
      0.7633587966689815,
      0.71942445725654,
      0.71942445725654
    ],
    "volume": 1122778253
  },
  {
    "ts": 1625097600,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2227,
      "authorized": 2227,
      "funded": 282
    },
    "payments": 2,
    "payments_amount": 9940500000,
    "trades": 15,
    "traded_amount": 1612124396,
    "price": [
      0.71942445725654,
      0.7633587877573125,
      0.7168458762015892,
      0.7400402872532567
    ],
    "volume": 336094397
  },
  {
    "ts": 1625184000,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2228,
      "authorized": 2228,
      "funded": 282
    },
    "payments": 4,
    "payments_amount": 10698400000,
    "trades": 43,
    "traded_amount": 12177684680,
    "price": [
      0.7400402872532567,
      0.7812516795236065,
      0.7400402872532567,
      0.7696883847637568
    ],
    "volume": 450989285
  },
  {
    "ts": 1625270400,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2229,
      "authorized": 2229,
      "funded": 282
    },
    "payments": 2,
    "payments_amount": 10121400000,
    "trades": 7,
    "traded_amount": 917980492,
    "price": [
      0.7696883847637568,
      0.7696883847637568,
      0.7483405598860164,
      0.7483405598860164
    ],
    "volume": 341915338
  },
  {
    "ts": 1625356800,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2229,
      "authorized": 2229,
      "funded": 281
    },
    "payments": 1,
    "payments_amount": 11892519,
    "trades": 3,
    "traded_amount": 13444573,
    "price": [
      0.7483405598860164,
      0.7483405598860164,
      0.7402152244702827,
      0.7402152244702827
    ],
    "volume": 574427
  },
  {
    "ts": 1625443200,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2230,
      "authorized": 2230,
      "funded": 281
    },
    "payments": 5,
    "payments_amount": 3359669766,
    "trades": 6,
    "traded_amount": 560089182,
    "price": [
      0.7402152244702827,
      0.7707231040564374,
      0.7402152244702827,
      0.7707231040564374
    ],
    "volume": 122435
  },
  {
    "ts": 1625529600,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2231,
      "authorized": 2231,
      "funded": 281
    },
    "payments": 5,
    "payments_amount": 913399600000,
    "trades": 71,
    "traded_amount": 50395703168,
    "price": [
      0.7707231040564374,
      0.7707231040564374,
      0.7506993412893512,
      0.7618519447708837
    ],
    "volume": 5064538729
  },
  {
    "ts": 1625616000,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2232,
      "authorized": 2232,
      "funded": 281
    },
    "payments": 2,
    "payments_amount": 10397000000,
    "trades": 19,
    "traded_amount": 4151210601,
    "price": [
      0.7618519447708837,
      0.7618519447708837,
      0.7299270072992701,
      0.7299270072992701
    ],
    "volume": 300097421
  },
  {
    "ts": 1625702400,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2232,
      "authorized": 2232,
      "funded": 282
    },
    "payments": 5,
    "payments_amount": 91044751521,
    "trades": 97,
    "traded_amount": 11519671192,
    "price": [
      0.7299270072992701,
      0.7936509175163573,
      0.7299270072992701,
      0.7916385433170955
    ],
    "volume": 2025680133
  },
  {
    "ts": 1625788800,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2235,
      "authorized": 2235,
      "funded": 284
    },
    "payments": 38,
    "payments_amount": 20546889249,
    "trades": 137,
    "traded_amount": 39048546260,
    "price": [
      0.7916385433170955,
      0.8130081338845652,
      0.7691802047140577,
      0.7692307724633867
    ],
    "volume": 16106017968
  },
  {
    "ts": 1625875200,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2235,
      "authorized": 2235,
      "funded": 285
    },
    "payments": 9,
    "payments_amount": 11969962009,
    "trades": 21,
    "traded_amount": 5442448743,
    "price": [
      0.7692307724633867,
      0.7692308284023714,
      0.7604526347395286,
      0.7692300873974012
    ],
    "volume": 2119744095
  },
  {
    "ts": 1625961600,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2241,
      "authorized": 2241,
      "funded": 290
    },
    "payments": 3,
    "payments_amount": 770184892,
    "trades": 17,
    "traded_amount": 2289291074,
    "price": [
      0.7692300873974012,
      0.7879944394825661,
      0.7692300873974012,
      0.7795636102365089
    ],
    "volume": 1194906749
  },
  {
    "ts": 1626048000,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2241,
      "authorized": 2241,
      "funded": 290
    },
    "payments": 4,
    "payments_amount": 6442381476,
    "trades": 5,
    "traded_amount": 1720386568,
    "price": [
      0.7795636102365089,
      0.7795636102365089,
      0.7795636102365089,
      0.7795636102365089
    ],
    "volume": 0
  },
  {
    "ts": 1626134400,
    "supply": 2914369326328,
    "trustlines": {
      "total": 2245,
      "authorized": 2245,
      "funded": 293
    },
    "payments": 5,
    "payments_amount": 10867234112,
    "trades": 53,
    "traded_amount": 5221000832,
    "price": [
      0.7795636102365089,
      0.84033614,
      0.7795636102365089,
      0.8403361232509883
    ],
    "volume": 1400000005
  },
  {
    "ts": 1626220800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2245,
      "authorized": 2245,
      "funded": 292
    },
    "payments": 4,
    "payments_amount": 110633800000,
    "trades": 182,
    "traded_amount": 31911536486,
    "price": [
      0.8403361232509883,
      0.8733624470675324,
      0.8,
      0.8
    ],
    "volume": 3101344901
  },
  {
    "ts": 1626307200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2248,
      "authorized": 2248,
      "funded": 292
    },
    "payments": 10,
    "payments_amount": 12004500000,
    "trades": 82,
    "traded_amount": 11860677648,
    "price": [
      0.8,
      0.851063840499925,
      0.8,
      0.8504978510372296
    ],
    "volume": 2018595448
  },
  {
    "ts": 1626393600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2249,
      "authorized": 2249,
      "funded": 292
    },
    "payments": 2,
    "payments_amount": 10221700000,
    "trades": 48,
    "traded_amount": 4224000006,
    "price": [
      0.8504978510372296,
      0.8504978510372296,
      0.7662835249042146,
      0.7662835249042146
    ],
    "volume": 1650000000
  },
  {
    "ts": 1626480000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2248,
      "authorized": 2248,
      "funded": 294
    },
    "payments": 2,
    "payments_amount": 131405780,
    "trades": 2,
    "traded_amount": 131405780,
    "price": [
      0.7662835249042146,
      0.7662835249042146,
      0.7662835249042146,
      0.7662835249042146
    ],
    "volume": 0
  },
  {
    "ts": 1626566400,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2249,
      "authorized": 2249,
      "funded": 295
    },
    "payments": 2,
    "payments_amount": 794698706,
    "trades": 3,
    "traded_amount": 1343698624,
    "price": [
      0.7662835249042146,
      0.8547008547777508,
      0.7662835249042146,
      0.8547008547777508
    ],
    "volume": 474999965
  },
  {
    "ts": 1626652800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2249,
      "authorized": 2249,
      "funded": 295
    },
    "payments": 4,
    "payments_amount": 7636761527,
    "trades": 104,
    "traded_amount": 13827202301,
    "price": [
      0.8547008547777508,
      0.8968609960434576,
      0.8547007728832139,
      0.8968609864777686
    ],
    "volume": 5015869010
  },
  {
    "ts": 1626739200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2250,
      "authorized": 2250,
      "funded": 295
    },
    "payments": 3,
    "payments_amount": 10829800000,
    "trades": 59,
    "traded_amount": 7752386618,
    "price": [
      0.8968609864777686,
      0.9433962275793447,
      0.8847992351816444,
      0.8847992351816444
    ],
    "volume": 3576087927
  },
  {
    "ts": 1626825600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2251,
      "authorized": 2251,
      "funded": 296
    },
    "payments": 5,
    "payments_amount": 10722957849,
    "trades": 43,
    "traded_amount": 3403917129,
    "price": [
      0.8847992351816444,
      0.8850034487480767,
      0.8368200836820083,
      0.8368200836820083
    ],
    "volume": 1349998149
  },
  {
    "ts": 1626912000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2251,
      "authorized": 2251,
      "funded": 297
    },
    "payments": 5,
    "payments_amount": 10909081716,
    "trades": 170,
    "traded_amount": 16884534519,
    "price": [
      0.8368200836820083,
      0.8368200836820083,
      0.7220216558505925,
      0.729336755035879
    ],
    "volume": 6630594828
  },
  {
    "ts": 1626998400,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2255,
      "authorized": 2255,
      "funded": 301
    },
    "payments": 7,
    "payments_amount": 19761854154,
    "trades": 91,
    "traded_amount": 154607414894,
    "price": [
      0.729336755035879,
      0.7660301744989287,
      0.6993006967208667,
      0.7220216606498195
    ],
    "volume": 41183300755
  },
  {
    "ts": 1627084800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2256,
      "authorized": 2256,
      "funded": 301
    },
    "payments": 4,
    "payments_amount": 15980900000,
    "trades": 10,
    "traded_amount": 1383277099,
    "price": [
      0.7220216606498195,
      0.7283141158185791,
      0.7142829731916431,
      0.7224917401283539
    ],
    "volume": 594721711
  },
  {
    "ts": 1627171200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2256,
      "authorized": 2256,
      "funded": 302
    },
    "payments": 1,
    "payments_amount": 663421639,
    "trades": 12,
    "traded_amount": 732212799,
    "price": [
      0.7224917401283539,
      0.7536685193454786,
      0.7224917401283539,
      0.7477773987035662
    ],
    "volume": 524065258
  },
  {
    "ts": 1627257600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2256,
      "authorized": 2256,
      "funded": 302
    },
    "payments": 2,
    "payments_amount": 32734900000,
    "trades": 73,
    "traded_amount": 6104578018,
    "price": [
      0.7477773987035662,
      0.7477773987035662,
      0.6849315057206918,
      0.7434944093698157
    ],
    "volume": 2815433907
  },
  {
    "ts": 1627344000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2259,
      "authorized": 2259,
      "funded": 304
    },
    "payments": 4,
    "payments_amount": 11086036943,
    "trades": 47,
    "traded_amount": 2782940910,
    "price": [
      0.7434944093698157,
      0.7633588008090535,
      0.7401663700831851,
      0.7470011653967052
    ],
    "volume": 798227174
  },
  {
    "ts": 1627430400,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2260,
      "authorized": 2260,
      "funded": 304
    },
    "payments": 7,
    "payments_amount": 10437067446,
    "trades": 81,
    "traded_amount": 19231495149,
    "price": [
      0.7470011653967052,
      0.7470011653967052,
      0.6968641114982579,
      0.7260843544165371
    ],
    "volume": 7506480071
  },
  {
    "ts": 1627516800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2262,
      "authorized": 2262,
      "funded": 305
    },
    "payments": 2,
    "payments_amount": 10123500000,
    "trades": 33,
    "traded_amount": 3030086641,
    "price": [
      0.7260843544165371,
      0.7380073841774085,
      0.7260843544165371,
      0.7380073841774085
    ],
    "volume": 630265445
  },
  {
    "ts": 1627603200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2259,
      "authorized": 2259,
      "funded": 305
    },
    "payments": 6,
    "payments_amount": 41430884339,
    "trades": 415,
    "traded_amount": 207950188667,
    "price": [
      0.7380073841774085,
      0.749063674143276,
      0.6968641114982579,
      0.7024432701477011
    ],
    "volume": 45179823690
  },
  {
    "ts": 1627689600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2259,
      "authorized": 2259,
      "funded": 306
    },
    "payments": 4,
    "payments_amount": 47985509729,
    "trades": 139,
    "traded_amount": 59262705105,
    "price": [
      0.7024432701477011,
      0.7081863574801278,
      0.6756756756756757,
      0.6756756756756757
    ],
    "volume": 34895752743
  },
  {
    "ts": 1627776000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2265,
      "authorized": 2265,
      "funded": 310
    },
    "payments": 7,
    "payments_amount": 6738438422,
    "trades": 31,
    "traded_amount": 17587619081,
    "price": [
      0.6756756756756757,
      0.6920415262386997,
      0.6493506493506493,
      0.6920415262386997
    ],
    "volume": 6556166209
  },
  {
    "ts": 1627862400,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2265,
      "authorized": 2265,
      "funded": 310
    },
    "payments": 2,
    "payments_amount": 4460100000,
    "trades": 168,
    "traded_amount": 26299213253,
    "price": [
      0.6920415262386997,
      0.704225553465817,
      0.676923076923077,
      0.704225553465817
    ],
    "volume": 709658404
  },
  {
    "ts": 1627948800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2266,
      "authorized": 2266,
      "funded": 312
    },
    "payments": 7,
    "payments_amount": 15750239409,
    "trades": 49,
    "traded_amount": 16305389884,
    "price": [
      0.704225553465817,
      0.7220216615556194,
      0.704225350838316,
      0.7103375274556794
    ],
    "volume": 1279367118
  },
  {
    "ts": 1628035200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2269,
      "authorized": 2269,
      "funded": 315
    },
    "payments": 5,
    "payments_amount": 11298200000,
    "trades": 26,
    "traded_amount": 598533978,
    "price": [
      0.7103375274556794,
      0.7179856115107913,
      0.6802721088435374,
      0.6982585396517079
    ],
    "volume": 207183416
  },
  {
    "ts": 1628121600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2272,
      "authorized": 2272,
      "funded": 315
    },
    "payments": 5,
    "payments_amount": 12175156353,
    "trades": 64,
    "traded_amount": 5769731614,
    "price": [
      0.6982585396517079,
      0.7194244685057707,
      0.6825938566552902,
      0.6825938566552902
    ],
    "volume": 1200000003
  },
  {
    "ts": 1628208000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2274,
      "authorized": 2274,
      "funded": 316
    },
    "payments": 6,
    "payments_amount": 12162831156,
    "trades": 10,
    "traded_amount": 3274198097,
    "price": [
      0.6825938566552902,
      0.6926227696023048,
      0.6779660738569294,
      0.6779661051866247
    ],
    "volume": 850000000
  },
  {
    "ts": 1628294400,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2280,
      "authorized": 2280,
      "funded": 315
    },
    "payments": 2,
    "payments_amount": 950000000,
    "trades": 57,
    "traded_amount": 7994648800,
    "price": [
      0.6779661051866247,
      0.6779661051866247,
      0.6211173420075261,
      0.6211173420075261
    ],
    "volume": 4225419630
  },
  {
    "ts": 1628380800,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2283,
      "authorized": 2283,
      "funded": 316
    },
    "payments": 12,
    "payments_amount": 11541084687,
    "trades": 94,
    "traded_amount": 15473121182,
    "price": [
      0.6211173420075261,
      0.6622520809055316,
      0.6179775280898876,
      0.6622516556291391
    ],
    "volume": 4911761763
  },
  {
    "ts": 1628467200,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2284,
      "authorized": 2284,
      "funded": 318
    },
    "payments": 8,
    "payments_amount": 23154224889,
    "trades": 51,
    "traded_amount": 3486773317,
    "price": [
      0.6622516556291391,
      0.6734006736870761,
      0.62305295,
      0.64
    ],
    "volume": 1364421030
  },
  {
    "ts": 1628553600,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2287,
      "authorized": 2287,
      "funded": 317
    },
    "payments": 5,
    "payments_amount": 20956108784,
    "trades": 22,
    "traded_amount": 8065042099,
    "price": [
      0.64,
      0.64,
      0.6153846144615385,
      0.6153846144615385
    ],
    "volume": 1029471031
  },
  {
    "ts": 1628640000,
    "supply": 2814369326328,
    "trustlines": {
      "total": 2291,
      "authorized": 2291,
      "funded": 318
    },
    "payments": 2,
    "payments_amount": 10445500000,
    "trades": 159,
    "traded_amount": 24785414235,
    "price": [
      0.6153846144615385,
      0.6153887180216537,
      0.5494505461491606,
      0.5494505527506179
    ],
    "volume": 7450698541
  },
  {
    "ts": 1628726400,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2293,
      "authorized": 2293,
      "funded": 318
    },
    "payments": 3,
    "payments_amount": 10495900001,
    "trades": 65,
    "traded_amount": 6004560569,
    "price": [
      0.5494505527506179,
      0.6042296190071507,
      0.5449591280653951,
      0.5792829421548698
    ],
    "volume": 1729530365
  },
  {
    "ts": 1628812800,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2298,
      "authorized": 2298,
      "funded": 320
    },
    "payments": 5,
    "payments_amount": 27506902518,
    "trades": 96,
    "traded_amount": 46339539426,
    "price": [
      0.5792829421548698,
      0.5792829421548698,
      0.5277044851956726,
      0.5444733230889467
    ],
    "volume": 15586657220
  },
  {
    "ts": 1628899200,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2303,
      "authorized": 2303,
      "funded": 322
    },
    "payments": 5,
    "payments_amount": 18869342132,
    "trades": 96,
    "traded_amount": 46567686025,
    "price": [
      0.5444733230889467,
      0.5444733230889467,
      0.48426150121065376,
      0.48426150121065376
    ],
    "volume": 12518452572
  },
  {
    "ts": 1628985600,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2305,
      "authorized": 2305,
      "funded": 323
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 80,
    "traded_amount": 3316768911,
    "price": [
      0.48426150121065376,
      0.48426150121065376,
      0.47961630695443647,
      0.4828445337170338
    ],
    "volume": 900000000
  },
  {
    "ts": 1629072000,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2309,
      "authorized": 2309,
      "funded": 325
    },
    "payments": 3,
    "payments_amount": 22158700000,
    "trades": 63,
    "traded_amount": 30323164743,
    "price": [
      0.4828445337170338,
      0.5120495696118224,
      0.4702881118671165,
      0.5120495696118224
    ],
    "volume": 9781998712
  },
  {
    "ts": 1629158400,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2314,
      "authorized": 2314,
      "funded": 326
    },
    "payments": 8,
    "payments_amount": 16244821228,
    "trades": 246,
    "traded_amount": 90354023813,
    "price": [
      0.5120495696118224,
      0.5555555634872905,
      0.5062386071618642,
      0.5555555604694546
    ],
    "volume": 24553537820
  },
  {
    "ts": 1629244800,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2316,
      "authorized": 2316,
      "funded": 329
    },
    "payments": 7,
    "payments_amount": 16208378493,
    "trades": 132,
    "traded_amount": 37903819176,
    "price": [
      0.5555555604694546,
      0.5829083649144585,
      0.542280913369137,
      0.54912307931088
    ],
    "volume": 17944616824
  },
  {
    "ts": 1629331200,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2323,
      "authorized": 2323,
      "funded": 332
    },
    "payments": 3,
    "payments_amount": 10958515789,
    "trades": 106,
    "traded_amount": 29215604829,
    "price": [
      0.54912307931088,
      0.5602241091606353,
      0.5089058524173028,
      0.5089058524173028
    ],
    "volume": 10139238913
  },
  {
    "ts": 1629417600,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2327,
      "authorized": 2327,
      "funded": 332
    },
    "payments": 5,
    "payments_amount": 11637178447,
    "trades": 102,
    "traded_amount": 24170336397,
    "price": [
      0.5089058524173028,
      0.5089058524173028,
      0.4830917853247338,
      0.48542229078287696
    ],
    "volume": 6297801116
  },
  {
    "ts": 1629504000,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2329,
      "authorized": 2329,
      "funded": 334
    },
    "payments": 3,
    "payments_amount": 10781359829,
    "trades": 12,
    "traded_amount": 92070690,
    "price": [
      0.48542229078287696,
      0.5024710900494218,
      0.48542229078287696,
      0.5024710900494218
    ],
    "volume": 40582328
  },
  {
    "ts": 1629590400,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2331,
      "authorized": 2331,
      "funded": 333
    },
    "payments": 1,
    "payments_amount": 14420753,
    "trades": 26,
    "traded_amount": 8600046300,
    "price": [
      0.5024710900494218,
      0.509920248978798,
      0.4886053557274432,
      0.5071470498268008
    ],
    "volume": 2157758923
  },
  {
    "ts": 1629676800,
    "supply": 2814369326327,
    "trustlines": {
      "total": 2335,
      "authorized": 2335,
      "funded": 335
    },
    "payments": 10,
    "payments_amount": 18015937273,
    "trades": 49,
    "traded_amount": 8023279860,
    "price": [
      0.5071470498268008,
      0.5071470498268008,
      0.4825090466953693,
      0.5
    ],
    "volume": 1744075596
  },
  {
    "ts": 1629763200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2335,
      "authorized": 2335,
      "funded": 333
    },
    "payments": 3,
    "payments_amount": 10688600001,
    "trades": 196,
    "traded_amount": 89423501304,
    "price": [
      0.5,
      0.5390835600659575,
      0.5,
      0.5390835600659575
    ],
    "volume": 23512003884
  },
  {
    "ts": 1629849600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2339,
      "authorized": 2339,
      "funded": 331
    },
    "payments": 10,
    "payments_amount": 11940562510,
    "trades": 401,
    "traded_amount": 28810602480,
    "price": [
      0.5390835600659575,
      0.5494505559349008,
      0.5376344086021505,
      0.5376344375173876
    ],
    "volume": 7133503582
  },
  {
    "ts": 1629936000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2342,
      "authorized": 2342,
      "funded": 332
    },
    "payments": 5,
    "payments_amount": 11434107908,
    "trades": 152,
    "traded_amount": 18356883985,
    "price": [
      0.5376344375173876,
      0.5681840330997333,
      0.5376343967305,
      0.5681840330997333
    ],
    "volume": 5116646575
  },
  {
    "ts": 1630022400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2347,
      "authorized": 2347,
      "funded": 334
    },
    "payments": 9,
    "payments_amount": 25803215238,
    "trades": 72,
    "traded_amount": 25758039511,
    "price": [
      0.5681840330997333,
      0.5681840330997333,
      0.5361930290305745,
      0.536193029950584
    ],
    "volume": 2763196826
  },
  {
    "ts": 1630108800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2349,
      "authorized": 2349,
      "funded": 335
    },
    "payments": 7,
    "payments_amount": 54821490143,
    "trades": 67,
    "traded_amount": 63485283927,
    "price": [
      0.536193029950584,
      0.562488107308357,
      0.5275866364019826,
      0.5624881046324676
    ],
    "volume": 4725257125
  },
  {
    "ts": 1630195200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2350,
      "authorized": 2350,
      "funded": 335
    },
    "payments": 5,
    "payments_amount": 58030397972,
    "trades": 34,
    "traded_amount": 38539365912,
    "price": [
      0.5624881046324676,
      0.563798560948067,
      0.5620401958955645,
      0.562574656986306
    ],
    "volume": 3666161282
  },
  {
    "ts": 1630281600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2349,
      "authorized": 2349,
      "funded": 336
    },
    "payments": 4,
    "payments_amount": 315287700000,
    "trades": 27,
    "traded_amount": 2994177269,
    "price": [
      0.562574656986306,
      0.5763688753996794,
      0.562574656986306,
      0.5763688753996794
    ],
    "volume": 1113293789
  },
  {
    "ts": 1630368000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2349,
      "authorized": 2349,
      "funded": 336
    },
    "payments": 4,
    "payments_amount": 10900800000,
    "trades": 59,
    "traded_amount": 5230731878,
    "price": [
      0.5763688753996794,
      0.5830903872850773,
      0.5509641873278237,
      0.5509641873278237
    ],
    "volume": 1588871723
  },
  {
    "ts": 1630454400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2351,
      "authorized": 2351,
      "funded": 336
    },
    "payments": 2,
    "payments_amount": 10313000000,
    "trades": 34,
    "traded_amount": 1211306056,
    "price": [
      0.5509641873278237,
      0.5596886922048587,
      0.5449555318925566,
      0.5449555318925566
    ],
    "volume": 330519824
  },
  {
    "ts": 1630540800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2354,
      "authorized": 2354,
      "funded": 337
    },
    "payments": 11,
    "payments_amount": 30662415597,
    "trades": 81,
    "traded_amount": 11931211863,
    "price": [
      0.5449555318925566,
      0.5549294632722556,
      0.5304951565293311,
      0.5307123781748586
    ],
    "volume": 3738118679
  },
  {
    "ts": 1630627200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2357,
      "authorized": 2357,
      "funded": 338
    },
    "payments": 5,
    "payments_amount": 305921654361,
    "trades": 89,
    "traded_amount": 13280041935,
    "price": [
      0.5307123781748586,
      0.5307123781748586,
      0.5141388135732827,
      0.5141388172993162
    ],
    "volume": 3465981055
  },
  {
    "ts": 1630713600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2361,
      "authorized": 2361,
      "funded": 340
    },
    "payments": 3,
    "payments_amount": 22407900000,
    "trades": 94,
    "traded_amount": 14515933261,
    "price": [
      0.5141388172993162,
      0.5267936957134867,
      0.5050505050505051,
      0.5267934418236119
    ],
    "volume": 3251227307
  },
  {
    "ts": 1630800000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2362,
      "authorized": 2362,
      "funded": 340
    },
    "payments": 7,
    "payments_amount": 16990050634,
    "trades": 62,
    "traded_amount": 36946310075,
    "price": [
      0.5267934418236119,
      0.5267934418236119,
      0.4914004914004914,
      0.49839440000460916
    ],
    "volume": 14041808010
  },
  {
    "ts": 1630886400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2364,
      "authorized": 2364,
      "funded": 341
    },
    "payments": 13,
    "payments_amount": 152668726680,
    "trades": 307,
    "traded_amount": 163569740863,
    "price": [
      0.49839440000460916,
      0.49839440000460916,
      0.45402951101412853,
      0.45555915649193307
    ],
    "volume": 31729714413
  },
  {
    "ts": 1630972800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2366,
      "authorized": 2366,
      "funded": 340
    },
    "payments": 13,
    "payments_amount": 174179919960,
    "trades": 522,
    "traded_amount": 508559210443,
    "price": [
      0.45555915649193307,
      0.6658391133562992,
      0.45555915649193307,
      0.5808233269749643
    ],
    "volume": 128176308723
  },
  {
    "ts": 1631059200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2371,
      "authorized": 2371,
      "funded": 341
    },
    "payments": 7,
    "payments_amount": 13218500000,
    "trades": 187,
    "traded_amount": 52173770870,
    "price": [
      0.5808233269749643,
      0.623053001240222,
      0.56657223796034,
      0.56657223796034
    ],
    "volume": 9343237718
  },
  {
    "ts": 1631145600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2375,
      "authorized": 2375,
      "funded": 343
    },
    "payments": 7,
    "payments_amount": 15020782630,
    "trades": 176,
    "traded_amount": 27049745613,
    "price": [
      0.56657223796034,
      0.5733534761070376,
      0.5555555555555556,
      0.5677929116809117
    ],
    "volume": 2770975093
  },
  {
    "ts": 1631232000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2375,
      "authorized": 2375,
      "funded": 342
    },
    "payments": 8,
    "payments_amount": 37691131106,
    "trades": 210,
    "traded_amount": 82122985350,
    "price": [
      0.5677929116809117,
      0.6097561032880627,
      0.5449591280653951,
      0.6097561032880627
    ],
    "volume": 23495834901
  },
  {
    "ts": 1631318400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2376,
      "authorized": 2376,
      "funded": 342
    },
    "payments": 2,
    "payments_amount": 1153407115,
    "trades": 13,
    "traded_amount": 1594594893,
    "price": [
      0.6097561032880627,
      0.6097561032880627,
      0.5729554840424558,
      0.5729554840424558
    ],
    "volume": 126372390
  },
  {
    "ts": 1631404800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2378,
      "authorized": 2378,
      "funded": 343
    },
    "payments": 4,
    "payments_amount": 25052456120,
    "trades": 43,
    "traded_amount": 25562531094,
    "price": [
      0.5729554840424558,
      0.5909034627925214,
      0.5729554840424558,
      0.5734641523486392
    ],
    "volume": 7155889276
  },
  {
    "ts": 1631491200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2380,
      "authorized": 2380,
      "funded": 345
    },
    "payments": 5,
    "payments_amount": 17414326628,
    "trades": 41,
    "traded_amount": 15660860870,
    "price": [
      0.5734641523486392,
      0.6116238572050501,
      0.5734641523486392,
      0.6116238572050501
    ],
    "volume": 2412450114
  },
  {
    "ts": 1631577600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2382,
      "authorized": 2382,
      "funded": 346
    },
    "payments": 3,
    "payments_amount": 6245672185,
    "trades": 32,
    "traded_amount": 4733276050,
    "price": [
      0.6116238572050501,
      0.6116238572050501,
      0.5882838283828383,
      0.5906190926275993
    ],
    "volume": 72171
  },
  {
    "ts": 1631664000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2382,
      "authorized": 2382,
      "funded": 346
    },
    "payments": 1,
    "payments_amount": 3821000000,
    "trades": 50,
    "traded_amount": 11151214097,
    "price": [
      0.5906190926275993,
      0.5906190926275993,
      0.5617977525999833,
      0.5617977525999833
    ],
    "volume": 5084076395
  },
  {
    "ts": 1631750400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2385,
      "authorized": 2385,
      "funded": 348
    },
    "payments": 9,
    "payments_amount": 24322540811,
    "trades": 108,
    "traded_amount": 36621880395,
    "price": [
      0.5617977525999833,
      0.5847953249398891,
      0.560213343314307,
      0.5680612266666667
    ],
    "volume": 7919827079
  },
  {
    "ts": 1631836800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2390,
      "authorized": 2390,
      "funded": 350
    },
    "payments": 10,
    "payments_amount": 3168500062,
    "trades": 178,
    "traded_amount": 22800072403,
    "price": [
      0.5680612266666667,
      0.5934718321150585,
      0.5669398907103825,
      0.5899705312115268
    ],
    "volume": 6725490023
  },
  {
    "ts": 1631923200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2393,
      "authorized": 2393,
      "funded": 352
    },
    "payments": 1,
    "payments_amount": 16947430,
    "trades": 11,
    "traded_amount": 1349313575,
    "price": [
      0.5899705312115268,
      0.5970149618513196,
      0.5899705312115268,
      0.5970149618513196
    ],
    "volume": 399407462
  },
  {
    "ts": 1632009600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2395,
      "authorized": 2395,
      "funded": 354
    },
    "payments": 1,
    "payments_amount": 50250063,
    "trades": 34,
    "traded_amount": 8880315108,
    "price": [
      0.5970149618513196,
      0.6006006015818601,
      0.5900944808160196,
      0.6006006015818601
    ],
    "volume": 2725024842
  },
  {
    "ts": 1632096000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2398,
      "authorized": 2398,
      "funded": 356
    },
    "payments": 1,
    "payments_amount": 2155959880,
    "trades": 174,
    "traded_amount": 31307995104,
    "price": [
      0.6006006015818601,
      0.6920415231331546,
      0.6006005284650492,
      0.6920415231331546
    ],
    "volume": 7949949220
  },
  {
    "ts": 1632182400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2399,
      "authorized": 2399,
      "funded": 357
    },
    "payments": 2,
    "payments_amount": 28589690350,
    "trades": 82,
    "traded_amount": 38926283811,
    "price": [
      0.6920415231331546,
      0.7246376862318843,
      0.6920333356875485,
      0.7246376862318843
    ],
    "volume": 3588341202
  },
  {
    "ts": 1632268800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2406,
      "authorized": 2406,
      "funded": 357
    },
    "payments": 5,
    "payments_amount": 1794992620,
    "trades": 46,
    "traded_amount": 18609183836,
    "price": [
      0.7246376862318843,
      0.7272727277222806,
      0.6596613563977426,
      0.6596613563977426
    ],
    "volume": 4142588406
  },
  {
    "ts": 1632355200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2408,
      "authorized": 2408,
      "funded": 357
    },
    "payments": 7,
    "payments_amount": 129944361002,
    "trades": 46,
    "traded_amount": 37011191103,
    "price": [
      0.6596613563977426,
      0.6596613563977426,
      0.6289307821230572,
      0.6289308173285983
    ],
    "volume": 14242538086
  },
  {
    "ts": 1632441600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2408,
      "authorized": 2408,
      "funded": 356
    },
    "payments": 1,
    "payments_amount": 57359347914,
    "trades": 140,
    "traded_amount": 75633826858,
    "price": [
      0.6289308173285983,
      0.6968641141089025,
      0.6249841189175455,
      0.6804524660162756
    ],
    "volume": 5102094233
  },
  {
    "ts": 1632528000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2409,
      "authorized": 2409,
      "funded": 357
    },
    "payments": 5,
    "payments_amount": 557832502,
    "trades": 14,
    "traded_amount": 5593357846,
    "price": [
      0.6804524660162756,
      0.6804524660162756,
      0.6651198762567672,
      0.6744609620079561
    ],
    "volume": 1820225051
  },
  {
    "ts": 1632614400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2426,
      "authorized": 2426,
      "funded": 369
    },
    "payments": 10,
    "payments_amount": 3653801933,
    "trades": 148,
    "traded_amount": 79732536235,
    "price": [
      0.6744609620079561,
      0.7079649947546652,
      0.6744609620079561,
      0.68110350040695
    ],
    "volume": 27709830106
  },
  {
    "ts": 1632700800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2433,
      "authorized": 2433,
      "funded": 374
    },
    "payments": 7,
    "payments_amount": 217703875,
    "trades": 42,
    "traded_amount": 8287818630,
    "price": [
      0.68110350040695,
      0.6968641170800528,
      0.6740072555455102,
      0.6968641108871756
    ],
    "volume": 2858027156
  },
  {
    "ts": 1632787200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2438,
      "authorized": 2438,
      "funded": 376
    },
    "payments": 2,
    "payments_amount": 3014222582,
    "trades": 99,
    "traded_amount": 15778120740,
    "price": [
      0.6968641108871756,
      0.7194244611149174,
      0.6883963533693708,
      0.7194244611149174
    ],
    "volume": 5904185066
  },
  {
    "ts": 1632873600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2440,
      "authorized": 2440,
      "funded": 376
    },
    "payments": 15,
    "payments_amount": 9625333867,
    "trades": 51,
    "traded_amount": 11507529961,
    "price": [
      0.7194244611149174,
      0.7220221655528122,
      0.6659999152897841,
      0.6983507318060522
    ],
    "volume": 2685995808
  },
  {
    "ts": 1632960000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2442,
      "authorized": 2442,
      "funded": 380
    },
    "payments": 11,
    "payments_amount": 11678928769,
    "trades": 45,
    "traded_amount": 8541136788,
    "price": [
      0.6983507318060522,
      0.6983507318060522,
      0.6600658685256444,
      0.6727060722934792
    ],
    "volume": 840888010
  },
  {
    "ts": 1633046400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2444,
      "authorized": 2444,
      "funded": 382
    },
    "payments": 4,
    "payments_amount": 423606218,
    "trades": 264,
    "traded_amount": 22882819442,
    "price": [
      0.6727060722934792,
      0.6727060722934792,
      0.6191950220549756,
      0.6191950499440215
    ],
    "volume": 7121269920
  },
  {
    "ts": 1633132800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2447,
      "authorized": 2447,
      "funded": 384
    },
    "payments": 8,
    "payments_amount": 5259402211,
    "trades": 426,
    "traded_amount": 40139668475,
    "price": [
      0.6191950499440215,
      0.6268000063883681,
      0.5730656710495464,
      0.5880108316117404
    ],
    "volume": 9068401095
  },
  {
    "ts": 1633219200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2446,
      "authorized": 2446,
      "funded": 382
    },
    "payments": 7,
    "payments_amount": 9351621325,
    "trades": 33,
    "traded_amount": 2668680067,
    "price": [
      0.5880108316117404,
      0.5943207835809402,
      0.5784070446574547,
      0.5922415920638009
    ],
    "volume": 38806632
  },
  {
    "ts": 1633305600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2448,
      "authorized": 2448,
      "funded": 381
    },
    "payments": 4,
    "payments_amount": 326473735,
    "trades": 167,
    "traded_amount": 13209919330,
    "price": [
      0.5922415920638009,
      0.6191950479581464,
      0.5922415920638009,
      0.5928499328307464
    ],
    "volume": 4191231092
  },
  {
    "ts": 1633392000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2449,
      "authorized": 2449,
      "funded": 383
    },
    "payments": 4,
    "payments_amount": 11921400053,
    "trades": 194,
    "traded_amount": 32655737446,
    "price": [
      0.5928499328307464,
      0.5980392156862745,
      0.5763688705972364,
      0.5763688705972364
    ],
    "volume": 11823561230
  },
  {
    "ts": 1633478400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2452,
      "authorized": 2452,
      "funded": 385
    },
    "payments": 2,
    "payments_amount": 494699976,
    "trades": 448,
    "traded_amount": 121024627361,
    "price": [
      0.5763688705972364,
      0.5986842105263158,
      0.5095541401273885,
      0.5170109528403609
    ],
    "volume": 37687345593
  },
  {
    "ts": 1633564800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2454,
      "authorized": 2454,
      "funded": 388
    },
    "payments": 2,
    "payments_amount": 2504746794,
    "trades": 129,
    "traded_amount": 28833074825,
    "price": [
      0.5170109528403609,
      0.5335515548281505,
      0.5012078046610084,
      0.5230718067208379
    ],
    "volume": 7321045133
  },
  {
    "ts": 1633651200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2458,
      "authorized": 2458,
      "funded": 388
    },
    "payments": 1,
    "payments_amount": 150000000,
    "trades": 113,
    "traded_amount": 24523686140,
    "price": [
      0.5230718067208379,
      0.5434783005745419,
      0.523059796437659,
      0.5434783005745419
    ],
    "volume": 6594934659
  },
  {
    "ts": 1633737600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2458,
      "authorized": 2458,
      "funded": 389
    },
    "payments": 4,
    "payments_amount": 2930650614,
    "trades": 61,
    "traded_amount": 6066972400,
    "price": [
      0.5434783005745419,
      0.5434783005745419,
      0.5027314379349309,
      0.513634835297013
    ],
    "volume": 1600534091
  },
  {
    "ts": 1633824000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2459,
      "authorized": 2459,
      "funded": 391
    },
    "payments": 2,
    "payments_amount": 276164639,
    "trades": 103,
    "traded_amount": 60971811449,
    "price": [
      0.513634835297013,
      0.5439832708796655,
      0.513634835297013,
      0.5439832708796655
    ],
    "volume": 16419894047
  },
  {
    "ts": 1633910400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2461,
      "authorized": 2461,
      "funded": 392
    },
    "payments": 2,
    "payments_amount": 600000000,
    "trades": 74,
    "traded_amount": 101496292521,
    "price": [
      0.5439832708796655,
      0.547945205479452,
      0.5177435975808237,
      0.53565
    ],
    "volume": 21333790218
  },
  {
    "ts": 1633996800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2463,
      "authorized": 2463,
      "funded": 393
    },
    "payments": 2,
    "payments_amount": 70501016536,
    "trades": 66,
    "traded_amount": 39490674876,
    "price": [
      0.53565,
      0.5586592183557559,
      0.53565,
      0.5484843934722338
    ],
    "volume": 10918324550
  },
  {
    "ts": 1634083200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2471,
      "authorized": 2471,
      "funded": 397
    },
    "payments": 14,
    "payments_amount": 32117601628,
    "trades": 209,
    "traded_amount": 80122696358,
    "price": [
      0.5484843934722338,
      0.5484843934722338,
      0.48356659338071006,
      0.4938489377572628
    ],
    "volume": 29452771022
  },
  {
    "ts": 1634169600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2470,
      "authorized": 2470,
      "funded": 395
    },
    "payments": 5,
    "payments_amount": 14179339544,
    "trades": 69,
    "traded_amount": 15327897432,
    "price": [
      0.4938489377572628,
      0.4950688360450563,
      0.4778972518795225,
      0.4950545753963871
    ],
    "volume": 3428611360
  },
  {
    "ts": 1634256000,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2469,
      "authorized": 2469,
      "funded": 396
    },
    "payments": 3,
    "payments_amount": 24965774126,
    "trades": 138,
    "traded_amount": 54329956226,
    "price": [
      0.4950545753963871,
      0.5148687780386426,
      0.4920422983358458,
      0.49542158032227324
    ],
    "volume": 10930293078
  },
  {
    "ts": 1634342400,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2474,
      "authorized": 2474,
      "funded": 398
    },
    "payments": 4,
    "payments_amount": 16770522544,
    "trades": 444,
    "traded_amount": 87585828437,
    "price": [
      0.49542158032227324,
      0.49542158032227324,
      0.4395601557503194,
      0.4597428044843365
    ],
    "volume": 20042708260
  },
  {
    "ts": 1634428800,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2480,
      "authorized": 2480,
      "funded": 400
    },
    "payments": 6,
    "payments_amount": 2351224381,
    "trades": 72,
    "traded_amount": 10055160237,
    "price": [
      0.4597428044843365,
      0.48599970088168526,
      0.45972644376899696,
      0.47855888691031373
    ],
    "volume": 1873829124
  },
  {
    "ts": 1634515200,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2477,
      "authorized": 2477,
      "funded": 400
    },
    "payments": 4,
    "payments_amount": 3045722563,
    "trades": 88,
    "traded_amount": 24948062086,
    "price": [
      0.47855888691031373,
      0.4860510805500982,
      0.467430586487824,
      0.47164302850750556
    ],
    "volume": 5072779415
  },
  {
    "ts": 1634601600,
    "supply": 2814369326326,
    "trustlines": {
      "total": 2478,
      "authorized": 2478,
      "funded": 400
    },
    "payments": 4,
    "payments_amount": 75302318408,
    "trades": 138,
    "traded_amount": 96388399916,
    "price": [
      0.47164302850750556,
      0.49140123093852317,
      0.47164302850750556,
      0.4880495207349322
    ],
    "volume": 8299844075
  },
  {
    "ts": 1634688000,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2481,
      "authorized": 2481,
      "funded": 401
    },
    "payments": 4,
    "payments_amount": 20000000097,
    "trades": 160,
    "traded_amount": 39476285387,
    "price": [
      0.4880495207349322,
      0.49048230760247574,
      0.46082944537497494,
      0.46875
    ],
    "volume": 6525835819
  },
  {
    "ts": 1634774400,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2486,
      "authorized": 2486,
      "funded": 401
    },
    "payments": 2,
    "payments_amount": 8648950762,
    "trades": 112,
    "traded_amount": 16663690383,
    "price": [
      0.46875,
      0.47400853215357874,
      0.4484304928523803,
      0.4740079996189656
    ],
    "volume": 4330955237
  },
  {
    "ts": 1634860800,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2482,
      "authorized": 2482,
      "funded": 399
    },
    "payments": 2,
    "payments_amount": 28454484,
    "trades": 116,
    "traded_amount": 10763578000,
    "price": [
      0.4740079996189656,
      0.48034934497816595,
      0.47270308506292014,
      0.48034934497816595
    ],
    "volume": 268258766
  },
  {
    "ts": 1634947200,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2483,
      "authorized": 2483,
      "funded": 398
    },
    "payments": 4,
    "payments_amount": 1279837469,
    "trades": 15,
    "traded_amount": 2527978695,
    "price": [
      0.48034934497816595,
      0.48034934497816595,
      0.46,
      0.46
    ],
    "volume": 581952290
  },
  {
    "ts": 1635033600,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2489,
      "authorized": 2489,
      "funded": 399
    },
    "payments": 1,
    "payments_amount": 208500000,
    "trades": 30,
    "traded_amount": 2355445400,
    "price": [
      0.46,
      0.47961630695443647,
      0.46,
      0.47057627128600044
    ],
    "volume": 251840529
  },
  {
    "ts": 1635120000,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2492,
      "authorized": 2492,
      "funded": 400
    },
    "payments": 5,
    "payments_amount": 8128254729,
    "trades": 124,
    "traded_amount": 36971926338,
    "price": [
      0.47057627128600044,
      0.4710654027825154,
      0.46330179910001373,
      0.46810441789237817
    ],
    "volume": 2888250163
  },
  {
    "ts": 1635206400,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2492,
      "authorized": 2492,
      "funded": 398
    },
    "payments": 6,
    "payments_amount": 8455513538,
    "trades": 31,
    "traded_amount": 8003755752,
    "price": [
      0.46810441789237817,
      0.48192846941243916,
      0.4637699997035488,
      0.48192846941243916
    ],
    "volume": 776020453
  },
  {
    "ts": 1635292800,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2496,
      "authorized": 2496,
      "funded": 398
    },
    "payments": 9,
    "payments_amount": 2692896557,
    "trades": 281,
    "traded_amount": 298198857253,
    "price": [
      0.48192846941243916,
      0.5633802994560184,
      0.4819277094383335,
      0.5395908787543868
    ],
    "volume": 39869906263
  },
  {
    "ts": 1635379200,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2497,
      "authorized": 2497,
      "funded": 399
    },
    "payments": 4,
    "payments_amount": 814105648,
    "trades": 72,
    "traded_amount": 8741772184,
    "price": [
      0.5395908787543868,
      0.5462081029019672,
      0.5152342933094307,
      0.5152342933094307
    ],
    "volume": 1871400826
  },
  {
    "ts": 1635465600,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2501,
      "authorized": 2501,
      "funded": 400
    },
    "payments": 5,
    "payments_amount": 46234062120,
    "trades": 33,
    "traded_amount": 25367988191,
    "price": [
      0.5152342933094307,
      0.5172488141440276,
      0.49740579624732606,
      0.5029290259816547
    ],
    "volume": 987757832
  },
  {
    "ts": 1635552000,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2504,
      "authorized": 2504,
      "funded": 402
    },
    "payments": 4,
    "payments_amount": 509999676,
    "trades": 30,
    "traded_amount": 2823308578,
    "price": [
      0.5029290259816547,
      0.5029290259816547,
      0.4946968986071989,
      0.49715269164648435
    ],
    "volume": 632735205
  },
  {
    "ts": 1635638400,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2506,
      "authorized": 2506,
      "funded": 403
    },
    "payments": 3,
    "payments_amount": 76947667,
    "trades": 69,
    "traded_amount": 12392398070,
    "price": [
      0.49715269164648435,
      0.49715269164648435,
      0.46296296296296297,
      0.4856422066979676
    ],
    "volume": 4232422697
  },
  {
    "ts": 1635724800,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2511,
      "authorized": 2511,
      "funded": 407
    },
    "payments": 7,
    "payments_amount": 681656961,
    "trades": 35,
    "traded_amount": 1557297890,
    "price": [
      0.4856422066979676,
      0.49192424366647536,
      0.4751769197101707,
      0.48633434557284166
    ],
    "volume": 351197453
  },
  {
    "ts": 1635811200,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2515,
      "authorized": 2515,
      "funded": 408
    },
    "payments": 3,
    "payments_amount": 10006865,
    "trades": 55,
    "traded_amount": 29187861388,
    "price": [
      0.48633434557284166,
      0.48646158289847163,
      0.46511627680101225,
      0.46511627680101225
    ],
    "volume": 7080355680
  },
  {
    "ts": 1635897600,
    "supply": 2814369326325,
    "trustlines": {
      "total": 2518,
      "authorized": 2518,
      "funded": 411
    },
    "payments": 6,
    "payments_amount": 21035929224,
    "trades": 348,
    "traded_amount": 59423911961,
    "price": [
      0.46511627680101225,
      0.4746162250324717,
      0.4474272,
      0.47279700439827094
    ],
    "volume": 10461647412
  },
  {
    "ts": 1635984000,
    "supply": 5614369326325,
    "trustlines": {
      "total": 2519,
      "authorized": 2519,
      "funded": 412
    },
    "payments": 7,
    "payments_amount": 5600031068665,
    "trades": 171,
    "traded_amount": 13715530766,
    "price": [
      0.47279700439827094,
      0.4842105263157895,
      0.4581571560718231,
      0.4842105263157895
    ],
    "volume": 3094037878
  },
  {
    "ts": 1636070400,
    "supply": 5614369326325,
    "trustlines": {
      "total": 2522,
      "authorized": 2522,
      "funded": 415
    },
    "payments": 31,
    "payments_amount": 2926018081817,
    "trades": 332,
    "traded_amount": 106894080093,
    "price": [
      0.4842105263157895,
      0.506993169388322,
      0.48294589961107387,
      0.5021599612463903
    ],
    "volume": 7717357523
  },
  {
    "ts": 1636156800,
    "supply": 5614369326325,
    "trustlines": {
      "total": 2524,
      "authorized": 2524,
      "funded": 415
    },
    "payments": 2,
    "payments_amount": 10108554,
    "trades": 525,
    "traded_amount": 55200899677,
    "price": [
      0.5021599612463903,
      0.51078758231909,
      0.49770132410364787,
      0.5045093979346819
    ],
    "volume": 11741677404
  },
  {
    "ts": 1636243200,
    "supply": 5614369326325,
    "trustlines": {
      "total": 2528,
      "authorized": 2528,
      "funded": 418
    },
    "payments": 3,
    "payments_amount": 8460431284,
    "trades": 252,
    "traded_amount": 35909779900,
    "price": [
      0.5045093979346819,
      0.5092592592592593,
      0.4991363548732965,
      0.5018808248385809
    ],
    "volume": 6083971420
  },
  {
    "ts": 1636329600,
    "supply": 5614369326325,
    "trustlines": {
      "total": 2533,
      "authorized": 2533,
      "funded": 419
    },
    "payments": 30,
    "payments_amount": 10511922082,
    "trades": 1046,
    "traded_amount": 118117686979,
    "price": [
      0.5018808248385809,
      0.5018808248385809,
      0.47913039749376474,
      0.47913039749376474
    ],
    "volume": 29396611576
  },
  {
    "ts": 1636416000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2533,
      "authorized": 2533,
      "funded": 421
    },
    "payments": 38,
    "payments_amount": 12020628043292,
    "trades": 1373,
    "traded_amount": 259520461278,
    "price": [
      0.47913039749376474,
      0.4820770674513984,
      0.43946619954080013,
      0.4447518449285044
    ],
    "volume": 63902679319
  },
  {
    "ts": 1636502400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2537,
      "authorized": 2537,
      "funded": 422
    },
    "payments": 11,
    "payments_amount": 6008054297047,
    "trades": 2349,
    "traded_amount": 678529906101,
    "price": [
      0.4447518449285044,
      0.4761904931765353,
      0.42283298097251587,
      0.4700624599853237
    ],
    "volume": 156953430554
  },
  {
    "ts": 1636588800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2545,
      "authorized": 2545,
      "funded": 423
    },
    "payments": 7,
    "payments_amount": 20348782488,
    "trades": 1643,
    "traded_amount": 417162974300,
    "price": [
      0.4700624599853237,
      0.47623708871944637,
      0.45861696803681984,
      0.4680064421981165
    ],
    "volume": 99552101813
  },
  {
    "ts": 1636675200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2551,
      "authorized": 2551,
      "funded": 424
    },
    "payments": 35,
    "payments_amount": 31401069117,
    "trades": 1383,
    "traded_amount": 234790218005,
    "price": [
      0.4680064421981165,
      0.4955069443470415,
      0.4680064421981165,
      0.48564190470411556
    ],
    "volume": 73737719680
  },
  {
    "ts": 1636761600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2553,
      "authorized": 2553,
      "funded": 426
    },
    "payments": 9,
    "payments_amount": 640021539,
    "trades": 573,
    "traded_amount": 68803119076,
    "price": [
      0.48564190470411556,
      0.4941543995834714,
      0.4791302433637576,
      0.4863156783335973
    ],
    "volume": 16533718248
  },
  {
    "ts": 1636848000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2566,
      "authorized": 2566,
      "funded": 428
    },
    "payments": 4,
    "payments_amount": 368967314,
    "trades": 590,
    "traded_amount": 93625752422,
    "price": [
      0.4863156783335973,
      0.49461407388674816,
      0.4828723,
      0.48756804127813447
    ],
    "volume": 22993195817
  },
  {
    "ts": 1636934400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2569,
      "authorized": 2569,
      "funded": 428
    },
    "payments": 14,
    "payments_amount": 3711967864,
    "trades": 1397,
    "traded_amount": 226522446172,
    "price": [
      0.48756804127813447,
      0.48756804127813447,
      0.46809306679568824,
      0.47846525866104983
    ],
    "volume": 54238873021
  },
  {
    "ts": 1637020800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2568,
      "authorized": 2568,
      "funded": 427
    },
    "payments": 24,
    "payments_amount": 20147174579,
    "trades": 1460,
    "traded_amount": 476363297361,
    "price": [
      0.47846525866104983,
      0.5445285475036018,
      0.47846525866104983,
      0.5290250024223242
    ],
    "volume": 144625183578
  },
  {
    "ts": 1637107200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2572,
      "authorized": 2572,
      "funded": 426
    },
    "payments": 1,
    "payments_amount": 19634653,
    "trades": 1141,
    "traded_amount": 184992004447,
    "price": [
      0.5290250024223242,
      0.5413715989563921,
      0.5241405951265216,
      0.5294903119495425
    ],
    "volume": 49695087756
  },
  {
    "ts": 1637193600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2584,
      "authorized": 2584,
      "funded": 427
    },
    "payments": 8,
    "payments_amount": 10903238842,
    "trades": 3905,
    "traded_amount": 450024623483,
    "price": [
      0.5294903119495425,
      0.5652870145555169,
      0.5185172115464401,
      0.5581292120710853
    ],
    "volume": 145317457168
  },
  {
    "ts": 1637280000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2591,
      "authorized": 2591,
      "funded": 430
    },
    "payments": 4,
    "payments_amount": 1423037931,
    "trades": 2854,
    "traded_amount": 732233891365,
    "price": [
      0.5581292120710853,
      0.5581292120710853,
      0.5076142131979695,
      0.5106487708157125
    ],
    "volume": 213374135209
  },
  {
    "ts": 1637366400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2590,
      "authorized": 2590,
      "funded": 433
    },
    "payments": 3,
    "payments_amount": 6385079085,
    "trades": 1604,
    "traded_amount": 142982206015,
    "price": [
      0.5106487708157125,
      0.5235008243232051,
      0.5051488665116146,
      0.515391975182021
    ],
    "volume": 37138504268
  },
  {
    "ts": 1637452800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2593,
      "authorized": 2593,
      "funded": 435
    },
    "payments": 7,
    "payments_amount": 2612426514,
    "trades": 647,
    "traded_amount": 36368285887,
    "price": [
      0.515391975182021,
      0.5232342091873818,
      0.5153344547734345,
      0.5178081979652198
    ],
    "volume": 9908548640
  },
  {
    "ts": 1637539200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2601,
      "authorized": 2601,
      "funded": 440
    },
    "payments": 5,
    "payments_amount": 3413901765,
    "trades": 2974,
    "traded_amount": 382346191017,
    "price": [
      0.5178081979652198,
      0.5296367916596604,
      0.5037969818268438,
      0.5275261349128446
    ],
    "volume": 99871909252
  },
  {
    "ts": 1637625600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2603,
      "authorized": 2603,
      "funded": 438
    },
    "payments": 3,
    "payments_amount": 423502138,
    "trades": 2388,
    "traded_amount": 144973052248,
    "price": [
      0.5275261349128446,
      0.5372502737440565,
      0.5206083082929208,
      0.5235417273378407
    ],
    "volume": 37095956589
  },
  {
    "ts": 1637712000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2606,
      "authorized": 2606,
      "funded": 441
    },
    "payments": 12,
    "payments_amount": 31173995377,
    "trades": 3310,
    "traded_amount": 562200343370,
    "price": [
      0.5235417273378407,
      0.5504587155963303,
      0.5203543002810455,
      0.5403359691569298
    ],
    "volume": 185329559863
  },
  {
    "ts": 1637798400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2609,
      "authorized": 2609,
      "funded": 444
    },
    "payments": 4,
    "payments_amount": 16813707354,
    "trades": 1322,
    "traded_amount": 114440395272,
    "price": [
      0.5403359691569298,
      0.5403359691569298,
      0.5193891353940099,
      0.5203843411027287
    ],
    "volume": 29539665383
  },
  {
    "ts": 1637884800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2612,
      "authorized": 2612,
      "funded": 444
    },
    "payments": 8,
    "payments_amount": 3007361391,
    "trades": 3420,
    "traded_amount": 638368110977,
    "price": [
      0.5203843411027287,
      0.5732266029761367,
      0.5163504966517252,
      0.5417793263559932
    ],
    "volume": 199861055921
  },
  {
    "ts": 1637971200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2614,
      "authorized": 2614,
      "funded": 446
    },
    "payments": 9,
    "payments_amount": 25654063671,
    "trades": 1093,
    "traded_amount": 97056843871,
    "price": [
      0.5417793263559932,
      0.5554271,
      0.5372291001056305,
      0.5483225013174073
    ],
    "volume": 26386676486
  },
  {
    "ts": 1638057600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2616,
      "authorized": 2616,
      "funded": 447
    },
    "payments": 4,
    "payments_amount": 1282460521,
    "trades": 1928,
    "traded_amount": 274638876285,
    "price": [
      0.5483225013174073,
      0.5817139899919674,
      0.5483225013174073,
      0.5563689458057964
    ],
    "volume": 75054995531
  },
  {
    "ts": 1638144000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2619,
      "authorized": 2619,
      "funded": 447
    },
    "payments": 8,
    "payments_amount": 21074398409,
    "trades": 1256,
    "traded_amount": 204581434847,
    "price": [
      0.5563689458057964,
      0.5604458637456333,
      0.5384234363058105,
      0.541261435253664
    ],
    "volume": 55456508640
  },
  {
    "ts": 1638230400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2620,
      "authorized": 2620,
      "funded": 446
    },
    "payments": 3,
    "payments_amount": 620000000,
    "trades": 2029,
    "traded_amount": 459810096700,
    "price": [
      0.541261435253664,
      0.542426450758213,
      0.5162242241289096,
      0.5254542724132752
    ],
    "volume": 123357745140
  },
  {
    "ts": 1638316800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2623,
      "authorized": 2623,
      "funded": 449
    },
    "payments": 8,
    "payments_amount": 91569365133,
    "trades": 1511,
    "traded_amount": 242365067425,
    "price": [
      0.5254542724132752,
      0.541004482588062,
      0.5216910285637656,
      0.541004482588062
    ],
    "volume": 55743278266
  },
  {
    "ts": 1638403200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2624,
      "authorized": 2624,
      "funded": 448
    },
    "payments": 11,
    "payments_amount": 66726175230,
    "trades": 1465,
    "traded_amount": 294743562862,
    "price": [
      0.541004482588062,
      0.5479492359898785,
      0.5278827806335329,
      0.5278827806335329
    ],
    "volume": 79849831756
  },
  {
    "ts": 1638489600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2623,
      "authorized": 2623,
      "funded": 447
    },
    "payments": 12,
    "payments_amount": 47843264993,
    "trades": 2990,
    "traded_amount": 820600397909,
    "price": [
      0.5278827806335329,
      0.5380196938780017,
      0.49403197502474344,
      0.5343949980628181
    ],
    "volume": 251163813314
  },
  {
    "ts": 1638576000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2634,
      "authorized": 2634,
      "funded": 451
    },
    "payments": 10,
    "payments_amount": 40234887817,
    "trades": 3770,
    "traded_amount": 1523115637534,
    "price": [
      0.5343949980628181,
      0.6494650645050712,
      0.5343949980628181,
      0.6088364158
    ],
    "volume": 560098047880
  },
  {
    "ts": 1638662400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2636,
      "authorized": 2636,
      "funded": 452
    },
    "payments": 6,
    "payments_amount": 8797664228,
    "trades": 2144,
    "traded_amount": 618136495136,
    "price": [
      0.6088364158,
      0.6644518279589844,
      0.6037523990777778,
      0.6258073416356271
    ],
    "volume": 265906203036
  },
  {
    "ts": 1638748800,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2649,
      "authorized": 2649,
      "funded": 454
    },
    "payments": 4,
    "payments_amount": 77640772200,
    "trades": 3661,
    "traded_amount": 1141482803766,
    "price": [
      0.6258073416356271,
      0.697062519635564,
      0.6052399526498593,
      0.6059190780529202
    ],
    "volume": 516717085774
  },
  {
    "ts": 1638835200,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2654,
      "authorized": 2654,
      "funded": 456
    },
    "payments": 5,
    "payments_amount": 1195369792,
    "trades": 1375,
    "traded_amount": 331425966742,
    "price": [
      0.6059190780529202,
      0.6217065242103909,
      0.59917328581528,
      0.6173367595852737
    ],
    "volume": 111113770289
  },
  {
    "ts": 1638921600,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2658,
      "authorized": 2658,
      "funded": 458
    },
    "payments": 4,
    "payments_amount": 56085741573,
    "trades": 1721,
    "traded_amount": 814048259579,
    "price": [
      0.6173367595852737,
      0.6173367595852737,
      0.5847952979745534,
      0.5897519131181945
    ],
    "volume": 226307682720
  },
  {
    "ts": 1639008000,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2664,
      "authorized": 2664,
      "funded": 459
    },
    "payments": 4,
    "payments_amount": 2332579740,
    "trades": 2210,
    "traded_amount": 752588590884,
    "price": [
      0.5897519131181945,
      0.6446956585785999,
      0.5897519131181945,
      0.6429188982831991
    ],
    "volume": 255077931966
  },
  {
    "ts": 1639094400,
    "supply": 11614369326315,
    "trustlines": {
      "total": 2674,
      "authorized": 2674,
      "funded": 459
    },
    "payments": 5,
    "payments_amount": 30490431031,
    "trades": 1655,
    "traded_amount": 477753039609,
    "price": [
      0.6429188982831991,
      0.6737354352423397,
      0.6396533167721689,
      0.6733274651344304
    ],
    "volume": 169968024734
  },
  {
    "ts": 1639180800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2675,
      "authorized": 2675,
      "funded": 462
    },
    "payments": 5,
    "payments_amount": 10211096724,
    "trades": 1238,
    "traded_amount": 326687483670,
    "price": [
      0.6733274651344304,
      0.6812718668718759,
      0.6482899481181652,
      0.6515723441921518
    ],
    "volume": 121479562073
  },
  {
    "ts": 1639267200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2678,
      "authorized": 2678,
      "funded": 462
    },
    "payments": 2,
    "payments_amount": 1913452169,
    "trades": 3361,
    "traded_amount": 1405568750685,
    "price": [
      0.6515723441921518,
      0.6706251250917339,
      0.6416700922107237,
      0.6481919536205694
    ],
    "volume": 477974464501
  },
  {
    "ts": 1639353600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2681,
      "authorized": 2681,
      "funded": 459
    },
    "payments": 14,
    "payments_amount": 247902641580,
    "trades": 1959,
    "traded_amount": 591805086116,
    "price": [
      0.6481919536205694,
      0.6866282080208912,
      0.6481919536205694,
      0.685346590597445
    ],
    "volume": 196059490342
  },
  {
    "ts": 1639440000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2683,
      "authorized": 2683,
      "funded": 459
    },
    "payments": 3,
    "payments_amount": 7679944567,
    "trades": 1363,
    "traded_amount": 489758096190,
    "price": [
      0.685346590597445,
      0.6988744814491679,
      0.6592863002433667,
      0.6609359224403238
    ],
    "volume": 195184658147
  },
  {
    "ts": 1639526400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2692,
      "authorized": 2692,
      "funded": 461
    },
    "payments": 4,
    "payments_amount": 35104176,
    "trades": 1857,
    "traded_amount": 532427829481,
    "price": [
      0.6609359224403238,
      0.6968308326032104,
      0.6502980070512155,
      0.6511456356946009
    ],
    "volume": 210464045576
  },
  {
    "ts": 1639612800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2706,
      "authorized": 2706,
      "funded": 463
    },
    "payments": 15,
    "payments_amount": 84616430860,
    "trades": 1085,
    "traded_amount": 204759519899,
    "price": [
      0.6511456356946009,
      0.6773749070207377,
      0.6500833861959473,
      0.6773749070207377
    ],
    "volume": 64318484927
  },
  {
    "ts": 1639699200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2712,
      "authorized": 2712,
      "funded": 467
    },
    "payments": 17,
    "payments_amount": 35372325170,
    "trades": 1354,
    "traded_amount": 335635230336,
    "price": [
      0.6773749070207377,
      0.6997969189341253,
      0.6695437448970446,
      0.6877239386568574
    ],
    "volume": 136867133647
  },
  {
    "ts": 1639785600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2724,
      "authorized": 2724,
      "funded": 474
    },
    "payments": 14,
    "payments_amount": 19499446057,
    "trades": 1165,
    "traded_amount": 283374778603,
    "price": [
      0.6877239386568574,
      0.6940214668035227,
      0.6655296616753646,
      0.6784844177911641
    ],
    "volume": 104831699456
  },
  {
    "ts": 1639872000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2727,
      "authorized": 2727,
      "funded": 474
    },
    "payments": 9,
    "payments_amount": 46435283302,
    "trades": 727,
    "traded_amount": 213516501577,
    "price": [
      0.6784844177911641,
      0.6853702763506759,
      0.6675273531105456,
      0.6853702763506759
    ],
    "volume": 90620696994
  },
  {
    "ts": 1639958400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2733,
      "authorized": 2733,
      "funded": 473
    },
    "payments": 10,
    "payments_amount": 4096059685,
    "trades": 1345,
    "traded_amount": 505669676910,
    "price": [
      0.6853702763506759,
      0.6901130611665641,
      0.6526578805458401,
      0.6745067921086737
    ],
    "volume": 205879020019
  },
  {
    "ts": 1640044800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2736,
      "authorized": 2736,
      "funded": 474
    },
    "payments": 9,
    "payments_amount": 3352498878,
    "trades": 792,
    "traded_amount": 205285177792,
    "price": [
      0.6745067921086737,
      0.6815962643099593,
      0.6552228288786182,
      0.6576578874212891
    ],
    "volume": 85543055493
  },
  {
    "ts": 1640131200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2735,
      "authorized": 2735,
      "funded": 474
    },
    "payments": 16,
    "payments_amount": 9487206876,
    "trades": 1405,
    "traded_amount": 292867461221,
    "price": [
      0.6576578874212891,
      0.6593281683322835,
      0.6343700664688398,
      0.6508282378267073
    ],
    "volume": 101362575112
  },
  {
    "ts": 1640217600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2741,
      "authorized": 2741,
      "funded": 476
    },
    "payments": 3,
    "payments_amount": 103046628,
    "trades": 1108,
    "traded_amount": 304369393957,
    "price": [
      0.6508282378267073,
      0.6586496,
      0.6157229395276609,
      0.6159023881161643
    ],
    "volume": 110455987908
  },
  {
    "ts": 1640304000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2744,
      "authorized": 2744,
      "funded": 480
    },
    "payments": 13,
    "payments_amount": 27703808857,
    "trades": 760,
    "traded_amount": 217347371322,
    "price": [
      0.6159023881161643,
      0.6335673051675843,
      0.615473313846665,
      0.6298210144246351
    ],
    "volume": 54819264415
  },
  {
    "ts": 1640390400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2749,
      "authorized": 2749,
      "funded": 483
    },
    "payments": 14,
    "payments_amount": 702974772,
    "trades": 667,
    "traded_amount": 224986015051,
    "price": [
      0.6298210144246351,
      0.6337556431662174,
      0.6054479422593093,
      0.6082991012087076
    ],
    "volume": 106325786757
  },
  {
    "ts": 1640476800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2737,
      "authorized": 2737,
      "funded": 485
    },
    "payments": 5,
    "payments_amount": 4433595865,
    "trades": 537,
    "traded_amount": 86410414787,
    "price": [
      0.6082991012087076,
      0.6180413,
      0.6024096385542169,
      0.60548944688683
    ],
    "volume": 29231781055
  },
  {
    "ts": 1640563200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2736,
      "authorized": 2736,
      "funded": 484
    },
    "payments": 20,
    "payments_amount": 17519017374,
    "trades": 617,
    "traded_amount": 184175415668,
    "price": [
      0.60548944688683,
      0.6056101642315446,
      0.5853935091434933,
      0.5900575691711214
    ],
    "volume": 56198056472
  },
  {
    "ts": 1640649600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2740,
      "authorized": 2740,
      "funded": 485
    },
    "payments": 17,
    "payments_amount": 9167390243,
    "trades": 1410,
    "traded_amount": 551547254821,
    "price": [
      0.5900575691711214,
      0.6392082552142226,
      0.5893226643519297,
      0.6356545513154609
    ],
    "volume": 224803548514
  },
  {
    "ts": 1640736000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2740,
      "authorized": 2740,
      "funded": 482
    },
    "payments": 3,
    "payments_amount": 768172497,
    "trades": 884,
    "traded_amount": 253216614365,
    "price": [
      0.6356545513154609,
      0.6549099927744731,
      0.6327464994682468,
      0.6523605918027802
    ],
    "volume": 96016081672
  },
  {
    "ts": 1640822400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2742,
      "authorized": 2742,
      "funded": 484
    },
    "payments": 5,
    "payments_amount": 112689560708,
    "trades": 1392,
    "traded_amount": 192779287147,
    "price": [
      0.6523605918027802,
      0.6644518272425249,
      0.646630601922562,
      0.6641276005124055
    ],
    "volume": 81225536156
  },
  {
    "ts": 1640908800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2746,
      "authorized": 2746,
      "funded": 485
    },
    "payments": 3,
    "payments_amount": 127125936,
    "trades": 976,
    "traded_amount": 286120383793,
    "price": [
      0.6641276005124055,
      0.680716367659596,
      0.6515966898116231,
      0.6684717397399007
    ],
    "volume": 117677522899
  },
  {
    "ts": 1640995200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2746,
      "authorized": 2746,
      "funded": 485
    },
    "payments": 16,
    "payments_amount": 18325840481,
    "trades": 580,
    "traded_amount": 192537555438,
    "price": [
      0.6684717397399007,
      0.6722913,
      0.6536504458755235,
      0.653651221194259
    ],
    "volume": 49003593089
  },
  {
    "ts": 1641081600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2750,
      "authorized": 2750,
      "funded": 485
    },
    "payments": 4,
    "payments_amount": 12988274980,
    "trades": 759,
    "traded_amount": 297209538771,
    "price": [
      0.653651221194259,
      0.653651221194259,
      0.6229709835067186,
      0.6229709835067186
    ],
    "volume": 104263083760
  },
  {
    "ts": 1641168000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2753,
      "authorized": 2753,
      "funded": 485
    },
    "payments": 5,
    "payments_amount": 28642140,
    "trades": 985,
    "traded_amount": 344391374545,
    "price": [
      0.6229709835067186,
      0.62305299056971,
      0.6066037996291778,
      0.6119545269403669
    ],
    "volume": 134817612383
  },
  {
    "ts": 1641254400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2758,
      "authorized": 2758,
      "funded": 486
    },
    "payments": 4,
    "payments_amount": 19080104613,
    "trades": 742,
    "traded_amount": 271831326786,
    "price": [
      0.6119545269403669,
      0.626557697331957,
      0.6112986622082729,
      0.6204696204604384
    ],
    "volume": 77168412707
  },
  {
    "ts": 1641340800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2759,
      "authorized": 2759,
      "funded": 487
    },
    "payments": 11,
    "payments_amount": 29404292411,
    "trades": 1453,
    "traded_amount": 657311570293,
    "price": [
      0.6204696204604384,
      0.6691956148069824,
      0.6024267619796023,
      0.6591697098334938
    ],
    "volume": 264957207798
  },
  {
    "ts": 1641427200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2762,
      "authorized": 2762,
      "funded": 488
    },
    "payments": 6,
    "payments_amount": 25015186070,
    "trades": 881,
    "traded_amount": 272820738252,
    "price": [
      0.6591697098334938,
      0.6686481405906236,
      0.6478711690661876,
      0.6521403107603151
    ],
    "volume": 78713524186
  },
  {
    "ts": 1641513600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2762,
      "authorized": 2762,
      "funded": 486
    },
    "payments": 7,
    "payments_amount": 103861054642,
    "trades": 2308,
    "traded_amount": 633314792168,
    "price": [
      0.6521403107603151,
      0.6866169300977251,
      0.6513620542,
      0.6866049459205891
    ],
    "volume": 261161295360
  },
  {
    "ts": 1641600000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2762,
      "authorized": 2762,
      "funded": 488
    },
    "payments": 7,
    "payments_amount": 6853348074,
    "trades": 1375,
    "traded_amount": 317406715849,
    "price": [
      0.6866049459205891,
      0.703833782613898,
      0.6709373472003806,
      0.6870678377873295
    ],
    "volume": 138029263239
  },
  {
    "ts": 1641686400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2769,
      "authorized": 2769,
      "funded": 492
    },
    "payments": 7,
    "payments_amount": 14356181951,
    "trades": 785,
    "traded_amount": 206378995903,
    "price": [
      0.6870678377873295,
      0.6936709577484156,
      0.6777703100058808,
      0.6819021546248011
    ],
    "volume": 61460350132
  },
  {
    "ts": 1641772800,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2776,
      "authorized": 2776,
      "funded": 494
    },
    "payments": 11,
    "payments_amount": 2825544873,
    "trades": 1202,
    "traded_amount": 440992284263,
    "price": [
      0.6819021546248011,
      0.7246384211802852,
      0.6782397689048428,
      0.7001137859930685
    ],
    "volume": 176503112589
  },
  {
    "ts": 1641859200,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2777,
      "authorized": 2777,
      "funded": 494
    },
    "payments": 41,
    "payments_amount": 33933309198,
    "trades": 1385,
    "traded_amount": 260855850118,
    "price": [
      0.7001137859930685,
      0.7047879913147567,
      0.6791636176869935,
      0.684911802391327
    ],
    "volume": 73844423329
  },
  {
    "ts": 1641945600,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2780,
      "authorized": 2780,
      "funded": 493
    },
    "payments": 257,
    "payments_amount": 88913106848,
    "trades": 2190,
    "traded_amount": 427852860304,
    "price": [
      0.684911802391327,
      0.684911802391327,
      0.6310795229244205,
      0.6445795264253812
    ],
    "volume": 152491680595
  },
  {
    "ts": 1642032000,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2784,
      "authorized": 2784,
      "funded": 496
    },
    "payments": 224,
    "payments_amount": 20678710693,
    "trades": 1371,
    "traded_amount": 308463748867,
    "price": [
      0.6445795264253812,
      0.6626162837951344,
      0.6313639386094726,
      0.6626162837951344
    ],
    "volume": 108756831601
  },
  {
    "ts": 1642118400,
    "supply": 11614369326314,
    "trustlines": {
      "total": 2787,
      "authorized": 2787,
      "funded": 495
    },
    "payments": 111,
    "payments_amount": 87585356263,
    "trades": 1703,
    "traded_amount": 463773471334,
    "price": [
      0.6626162837951344,
      0.683330276187066,
      0.652507327008097,
      0.6627734606622351
    ],
    "volume": 199936461913
  }
]
import * as utils from './utils.js';
import * as rates from './rates.js';

import Decimal from 'decimal.js';

export function mapAccounts(accountsTableData) {
    const accounts = {};

    for (const d of accountsTableData) {
        accounts[d[0]] = {
            rel: "me",
            name: d[1],
            dateLost: d[2] || null,
        }
    }

    return accounts;
}

export function mapContacts(contactsTableData) {
    const contacts = {};

    for (const d of contactsTableData) {
        contacts[d[0]] = {
            rel: "external",
            name: d[1],
        }
    }

    return contacts;
}

export function mapTransactionFee(accounts, transaction) {
    const id = transaction.id
    const time = transaction.created_at;
    const from = transaction.fee_account;
    if ((accounts[from] || {}).rel != "me") {
        return;
    }
    const fee = new Decimal(transaction.fee_charged).dividedBy(new Decimal('10000000')).toFixed(7);
    const memo = `Stellar ${transaction.successful ? 'successful' : 'unsuccessful'} transaction fee.${utils.getMemoSuffix(transaction)}`;
    const asset = utils.getLongAssetID("native");
    return [{
        _Type: "DEBIT",
        Date: time,
        Account: from,
        Action: "SPEND",
        Symbol: asset,
        Volume: fee,
        Currency: "USD",
        Total: rates.getUsdAmount(asset, time, fee),
        Memo: memo,
        TxHash: id,
        Sender: from,
    }];
}

export function mapLost(accountAddress, dateLost, asset, amount) {
    return [{
        _Type: "DEBIT",
        Date: dateLost,
        Account: accountAddress,
        Action: "LOST",
        Symbol: asset,
        Volume: amount,
        Currency: "USD",
        Total: rates.getUsdAmount(asset, dateLost, amount),
        Memo: "Account lost or abandoned.",
        TxHash: `LOST-${accountAddress}-${asset}`,
        Sender: accountAddress,
    }];
}

export function mapEffect(accounts, transaction, operation, effect) {
    const trade = mapTrade(accounts, transaction, operation, effect);
    if (trade) {
        return trade;
    }
    const income = mapIncome(accounts, transaction, operation, effect);
    if (income) {
        return income;
    }
    const spend = mapSpends(accounts, transaction, operation, effect);
    if (spend) {
        return spend;
    }
}

function mapIncome(accounts, transaction, operation, effect) {
    const id = utils.getRecordID(transaction, operation, effect)
    const time = effect.created_at;
    switch (effect.type) {
        case "account_created": {
            const from = operation.funder;
            let action = "INCOME";
            if ((accounts[from] || {}).rel == "me") {
                action = "TRANSFER";
            }
            const to = effect.account;
            const amount = effect.starting_balance;
            const asset = utils.getLongAssetID("native")
            return [{
                _Type: "CREDIT",
                Date: time,
                Account: to,
                Action: action,
                Symbol: asset,
                Volume: amount,
                Currency: "USD",
                Total: rates.getUsdAmount(asset, time, amount),
                Memo: `Account created by ${utils.getAccountName(accounts, from)}.${utils.getMemoSuffix(transaction)}`,
                TxHash: id,
                Sender: from,
                Recipient: to,
            }];
        }
        case "account_credited": {
            // From is undefined for claim_claimable_balance, see https://github.com/stellar/go/issues/3380.
            const from = operation.from || operation.account;
            let action = "INCOME";
            if ((accounts[from] || {}).rel == "me") {
                action = "TRANSFER";
            }
            const to = effect.account;
            switch (operation.type) {
                case "path_payment_strict_send":
                case "path_payment_strict_receive": {
                    if (operation.to == operation.from) {
                        break;
                    }
                    const amount = operation.amount;
                    const asset = utils.getLongAssetID(operation.asset_type, operation.asset_code, operation.asset_issuer);
                    return [{
                        _Type: "CREDIT",
                        Date: time,
                        Account: to,
                        Action: action,
                        Symbol: asset,
                        Volume: amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, amount),
                        Memo: `Path payment from ${utils.getAccountName(accounts, from)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
                case "claim_claimable_balance": {
                    const amount = effect.amount;
                    const asset = utils.getLongAssetID(effect.asset_type, effect.asset_code, effect.asset_issuer);
                    return [{
                        _Type: "CREDIT",
                        Date: time,
                        Account: to,
                        Action: action,
                        Symbol: asset,
                        Volume: amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, amount),
                        Memo: `Claimed claimable balance ${operation.balance_id}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: undefined, // TODO: Add sender here and in the memo, dependent on https://github.com/stellar/go/issues/3380.
                        Recipient: to,
                    }];
                }
                default: {
                    const amount = effect.amount;
                    const asset = utils.getLongAssetID(effect.asset_type, effect.asset_code, effect.asset_issuer);
                    return [{
                        _Type: "CREDIT",
                        Date: time,
                        Account: to,
                        Action: action,
                        Symbol: asset,
                        Volume: amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, amount),
                        Memo: `Payment from ${utils.getAccountName(accounts, from)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
            }
            break;
        }
    }
}

function mapSpends(accounts, transaction, operation, effect) {
    const id = utils.getRecordID(transaction, operation, effect)
    const time = effect.created_at;
    switch (effect.type) {
        case "account_debited": {
            const to = operation.to || operation.into || operation.account;
            let action = "SPEND";
            if ((accounts[to] || {}).rel == "me") {
                action = "TRANSFER";
            }
            const from = effect.account;
            switch (operation.type) {
                case "path_payment_strict_send":
                case "path_payment_strict_receive": {
                    if (operation.to == operation.from) {
                        break;
                    }
                    const asset = utils.getLongAssetID(operation.asset_type, operation.asset_code, operation.asset_issuer)
                    return [{
                        _Type: "DEBIT",
                        Date: time,
                        Account: from,
                        Action: action,
                        Symbol: asset,
                        Volume: operation.amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, operation.amount),
                        Memo: `Path payment to ${utils.getAccountName(accounts, to)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
                case "create_account": {
                    const asset = utils.getLongAssetID(effect.asset_type, effect.asset_code, effect.asset_issuer)
                    return [{
                        _Type: "DEBIT",
                        Date: time,
                        Account: from,
                        Action: action,
                        Symbol: asset,
                        Volume: effect.amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, effect.amount),
                        Memo: `Created account ${utils.getAccountName(accounts, to)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
                case "account_merge": {
                    const asset = utils.getLongAssetID(effect.asset_type, effect.asset_code, effect.asset_issuer)
                    return [{
                        _Type: "DEBIT",
                        Date: time,
                        Account: from,
                        Action: action,
                        Symbol: asset,
                        Volume: effect.amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, effect.amount),
                        Memo: `Account merged into ${utils.getAccountName(accounts, to)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
                default: {
                    const asset = utils.getLongAssetID(effect.asset_type, effect.asset_code, effect.asset_issuer)
                    return [{
                        _Type: "DEBIT",
                        Date: time,
                        Account: from,
                        Action: action,
                        Symbol: asset,
                        Volume: effect.amount,
                        Currency: "USD",
                        Total: rates.getUsdAmount(asset, time, effect.amount),
                        Memo: `Payment to ${utils.getAccountName(accounts, to)}.${utils.getMemoSuffix(transaction)}`,
                        TxHash: id,
                        Sender: from,
                        Recipient: to,
                    }];
                }
            }
            break;
        }
    }
}

function mapTrade(accounts, transaction, operation, effect) {
    const id = utils.getRecordID(transaction, operation, effect)
    const time = effect.created_at;
    switch (effect.type) {
        case "trade":
            const buyer = effect.account;
            const boughtAsset = utils.getLongAssetID(effect.bought_asset_type, effect.bought_asset_code, effect.bought_asset_issuer);
            const boughtShortAsset = utils.getShortAssetID(effect.bought_asset_type, effect.bought_asset_code, effect.bought_asset_issuer);
            const boughtAmount = effect.bought_amount;
            const boughtAmountUsd = rates.getUsdAmount(boughtAsset, time, boughtAmount);
            const seller = effect.seller;
            const soldAsset = utils.getLongAssetID(effect.sold_asset_type, effect.sold_asset_code, effect.sold_asset_issuer);
            const soldShortAsset = utils.getShortAssetID(effect.sold_asset_type, effect.sold_asset_code, effect.sold_asset_issuer);
            const soldAmount = effect.sold_amount;
            const memo = `Traded ${soldShortAsset}=>${boughtShortAsset} with ${utils.getAccountName(accounts, seller)}.${utils.getMemoSuffix(transaction)}`;
            return [
                {
                    _Type: "EXCHANGE",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: soldAsset,
                    Total: soldAmount,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "EXCHANGE_VIA_USD",
                    Date: time,
                    Account: buyer,
                    Action: "SELL",
                    Symbol: soldAsset,
                    Volume: soldAmount,
                    Currency: "USD",
                    Total: boughtAmountUsd,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "EXCHANGE_VIA_USD",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: "USD",
                    Total: boughtAmountUsd,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "DEBIT",
                    Date: time,
                    Account: buyer,
                    Action: "SELL",
                    Symbol: soldAsset,
                    Volume: soldAmount,
                    Currency: "USD",
                    Total: boughtAmountUsd,
                    Memo: memo,
                    TxHash: id,
                    Sender: buyer,
                    Recipient: seller,
                },
                {
                    _Type: "CREDIT",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: "USD",
                    Total: boughtAmountUsd,
                    Memo: memo,
                    TxHash: id,
                    Sender: seller,
                    Recipient: buyer,
                },
            ];
    }
}

[
  {
    "ts": 1611014400,
    "supply": 0,
    "trustlines": {
      "total": 0
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1611100800,
    "supply": 0,
    "trustlines": {
      "total": 2,
      "authorized": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1611532800,
    "supply": 135000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 2
    },
    "payments": 7,
    "payments_amount": 453200000,
    "trades": 3,
    "traded_amount": 243100000,
    "volume": 0
  },
  {
    "ts": 1611619200,
    "supply": 135200000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 1,
    "traded_amount": 200000,
    "volume": 0
  },
  {
    "ts": 1611705600,
    "supply": 135000000,
    "trustlines": {
      "total": 28,
      "authorized": 28,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1611792000,
    "supply": 135000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1611878400,
    "supply": 134900000,
    "trustlines": {
      "total": 49,
      "authorized": 49,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1611964800,
    "supply": 134900000,
    "trustlines": {
      "total": 69,
      "authorized": 69,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1612051200,
    "supply": 134900000,
    "trustlines": {
      "total": 82,
      "authorized": 82,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1612137600,
    "supply": 22496409300000,
    "trustlines": {
      "total": 116,
      "authorized": 116,
      "funded": 13
    },
    "payments": 116,
    "payments_amount": 54292761376078,
    "trades": 47,
    "traded_amount": 35894310252298,
    "price": [
      3.7659999116089677,
      3.766000454514744,
      3.09911122,
      3.1044650812635917
    ],
    "volume": 1371911155
  },
  {
    "ts": 1612224000,
    "supply": 30968802900000,
    "trustlines": {
      "total": 335,
      "authorized": 335,
      "funded": 116
    },
    "payments": 387,
    "payments_amount": 99423583205586,
    "trades": 268,
    "traded_amount": 54237554840549,
    "price": [
      3.1044650812635917,
      3.1613343192619605,
      2.875924174763529,
      2.9397482
    ],
    "volume": 905924651288
  },
  {
    "ts": 1612310400,
    "supply": 55939683900000,
    "trustlines": {
      "total": 447,
      "authorized": 447,
      "funded": 156
    },
    "payments": 340,
    "payments_amount": 361457694158245,
    "trades": 578,
    "traded_amount": 193189516270776,
    "price": [
      2.9397482,
      3.0303030303030303,
      2.878577749435145,
      2.9022967632966763
    ],
    "volume": 1325452403678
  },
  {
    "ts": 1612396800,
    "supply": 65123761100000,
    "trustlines": {
      "total": 522,
      "authorized": 522,
      "funded": 194
    },
    "payments": 345,
    "payments_amount": 171488132631897,
    "trades": 1307,
    "traded_amount": 91770749877503,
    "price": [
      2.9022967632966763,
      3.17788956129777,
      2.8855323482499298,
      3.063360760329124
    ],
    "volume": 5625530107349
  },
  {
    "ts": 1612483200,
    "supply": 87527983100000,
    "trustlines": {
      "total": 580,
      "authorized": 580,
      "funded": 221
    },
    "payments": 376,
    "payments_amount": 276521121788040,
    "trades": 1946,
    "traded_amount": 150282934405127,
    "price": [
      3.063360760329124,
      3.0701213049110825,
      2.7645845658772856,
      2.843239087501855
    ],
    "volume": 3185962535732
  },
  {
    "ts": 1612569600,
    "supply": 159222414700000,
    "trustlines": {
      "total": 638,
      "authorized": 638,
      "funded": 238
    },
    "payments": 199,
    "payments_amount": 280442558505974,
    "trades": 2597,
    "traded_amount": 176923104062549,
    "price": [
      2.843239087501855,
      2.9569633781397524,
      2.5204012494942782,
      2.6546559236887335
    ],
    "volume": 2030975759650
  },
  {
    "ts": 1612656000,
    "supply": 86531023000000,
    "trustlines": {
      "total": 683,
      "authorized": 683,
      "funded": 259
    },
    "payments": 249,
    "payments_amount": 290910363901114,
    "trades": 1521,
    "traded_amount": 111267993259573,
    "price": [
      2.6546559236887335,
      2.7423028353427537,
      2.37796597689665,
      2.5850865940032426
    ],
    "volume": 7071804805483
  },
  {
    "ts": 1612742400,
    "supply": 40390911400000,
    "trustlines": {
      "total": 738,
      "authorized": 738,
      "funded": 283
    },
    "payments": 414,
    "payments_amount": 261732253898987,
    "trades": 1404,
    "traded_amount": 108519728126128,
    "price": [
      2.5850865940032426,
      2.6703736883591627,
      2.482932836747362,
      2.5161953973175066
    ],
    "volume": 1837181133840
  },
  {
    "ts": 1612828800,
    "supply": 36900523800000,
    "trustlines": {
      "total": 781,
      "authorized": 781,
      "funded": 286
    },
    "payments": 427,
    "payments_amount": 84135263669203,
    "trades": 1266,
    "traded_amount": 41393589711687,
    "price": [
      2.5161953973175066,
      2.6048449997058247,
      2.47449749006063,
      2.494856950826436
    ],
    "volume": 2475170338246
  },
  {
    "ts": 1612915200,
    "supply": 20400487200000,
    "trustlines": {
      "total": 825,
      "authorized": 825,
      "funded": 297
    },
    "payments": 646,
    "payments_amount": 175698760269499,
    "trades": 1929,
    "traded_amount": 81772111888221,
    "price": [
      2.494856950826436,
      2.6083448683732193,
      2.251036421133874,
      2.431652302511504
    ],
    "volume": 5638419877273
  },
  {
    "ts": 1613001600,
    "supply": 46382999000000,
    "trustlines": {
      "total": 873,
      "authorized": 873,
      "funded": 314
    },
    "payments": 583,
    "payments_amount": 52879037807044,
    "trades": 1841,
    "traded_amount": 41064359072618,
    "price": [
      2.431652302511504,
      2.431652302511504,
      2.1311928183512183,
      2.202643171806167
    ],
    "volume": 3532423431117
  },
  {
    "ts": 1613088000,
    "supply": 82503281800000,
    "trustlines": {
      "total": 938,
      "authorized": 938,
      "funded": 350
    },
    "payments": 574,
    "payments_amount": 74500379594957,
    "trades": 1726,
    "traded_amount": 56819227088022,
    "price": [
      2.202643171806167,
      2.281114514016741,
      1.8703750538178292,
      1.8811959574546815
    ],
    "volume": 3258780798625
  },
  {
    "ts": 1613174400,
    "supply": 24529204300000,
    "trustlines": {
      "total": 1015,
      "authorized": 1015,
      "funded": 387
    },
    "payments": 441,
    "payments_amount": 82455302056611,
    "trades": 3134,
    "traded_amount": 15572058927640,
    "price": [
      1.8811959574546815,
      1.9569382834603495,
      1.6542377011952634,
      1.7832861103927093
    ],
    "volume": 6588760015648
  },
  {
    "ts": 1613260800,
    "supply": 25095056033636,
    "trustlines": {
      "total": 1058,
      "authorized": 1058,
      "funded": 401
    },
    "payments": 374,
    "payments_amount": 6195430417871,
    "trades": 1271,
    "traded_amount": 4803886512595,
    "price": [
      1.7832861103927093,
      2.006360077064489,
      1.7351534113715437,
      1.9309715508173269
    ],
    "volume": 3269957898731
  },
  {
    "ts": 1613347200,
    "supply": 21778311033636,
    "trustlines": {
      "total": 1133,
      "authorized": 1133,
      "funded": 424
    },
    "payments": 382,
    "payments_amount": 24547651744546,
    "trades": 2542,
    "traded_amount": 14623789966192,
    "price": [
      1.9309715508173269,
      2.32162394,
      1.8535163446855512,
      2.02253946808136
    ],
    "volume": 8890868840919
  },
  {
    "ts": 1613433600,
    "supply": 21003302733636,
    "trustlines": {
      "total": 1188,
      "authorized": 1188,
      "funded": 440
    },
    "payments": 525,
    "payments_amount": 17274017080120,
    "trades": 2277,
    "traded_amount": 12504231509160,
    "price": [
      2.02253946808136,
      2.133858588,
      1.9475744557609693,
      2.057613168877815
    ],
    "volume": 9574768489745
  },
  {
    "ts": 1613520000,
    "supply": 19365483533636,
    "trustlines": {
      "total": 1218,
      "authorized": 1218,
      "funded": 454
    },
    "payments": 556,
    "payments_amount": 27187356762259,
    "trades": 1956,
    "traded_amount": 15770450773924,
    "price": [
      2.057613168877815,
      2.193256925110925,
      1.968255205025305,
      2.00465874809251
    ],
    "volume": 6921880586411
  },
  {
    "ts": 1613606400,
    "supply": 22077950333635,
    "trustlines": {
      "total": 1251,
      "authorized": 1251,
      "funded": 462
    },
    "payments": 534,
    "payments_amount": 43452625675791,
    "trades": 1778,
    "traded_amount": 23739702961805,
    "price": [
      2.00465874809251,
      2.064720633991794,
      1.947187045038852,
      2.016487201373806
    ],
    "volume": 1078122883110
  },
  {
    "ts": 1613692800,
    "supply": 25626126433635,
    "trustlines": {
      "total": 1299,
      "authorized": 1299,
      "funded": 480
    },
    "payments": 550,
    "payments_amount": 37954353867898,
    "trades": 1692,
    "traded_amount": 22526973438004,
    "price": [
      2.016487201373806,
      2.090000863332112,
      1.9205047243245021,
      1.9400465805827893
    ],
    "volume": 4039151376458
  },
  {
    "ts": 1613779200,
    "supply": 25879839433635,
    "trustlines": {
      "total": 1338,
      "authorized": 1338,
      "funded": 486
    },
    "payments": 311,
    "payments_amount": 14008290102042,
    "trades": 1614,
    "traded_amount": 8869131000932,
    "price": [
      1.9400465805827893,
      2.158453442108854,
      1.8653385508268034,
      2.0544295383693605
    ],
    "volume": 3082593553231
  },
  {
    "ts": 1613865600,
    "supply": 31864965033635,
    "trustlines": {
      "total": 1378,
      "authorized": 1378,
      "funded": 499
    },
    "payments": 247,
    "payments_amount": 13850740194570,
    "trades": 1449,
    "traded_amount": 11869126238788,
    "price": [
      2.0544295383693605,
      2.0996918685112766,
      1.974475826113049,
      2.0155601241585037
    ],
    "volume": 4163406912572
  },
  {
    "ts": 1613952000,
    "supply": 29974075633635,
    "trustlines": {
      "total": 1426,
      "authorized": 1426,
      "funded": 511
    },
    "payments": 522,
    "payments_amount": 55658897837280,
    "trades": 3929,
    "traded_amount": 29787081363468,
    "price": [
      2.0155601241585037,
      2.5387154134171555,
      1.879999999998794,
      2.1522378677520533
    ],
    "volume": 5406894190620
  },
  {
    "ts": 1614038400,
    "supply": 27669531233535,
    "trustlines": {
      "total": 1492,
      "authorized": 1492,
      "funded": 528
    },
    "payments": 521,
    "payments_amount": 24530104036275,
    "trades": 5311,
    "traded_amount": 15693380742596,
    "price": [
      2.1522378677520533,
      3.031489874191834,
      2.1522378677520533,
      2.6084248749330583
    ],
    "volume": 13254831142600
  },
  {
    "ts": 1614124800,
    "supply": 39698845533535,
    "trustlines": {
      "total": 1536,
      "authorized": 1536,
      "funded": 547
    },
    "payments": 377,
    "payments_amount": 27397622511485,
    "trades": 3606,
    "traded_amount": 22003115122000,
    "price": [
      2.6084248749330583,
      2.7095379627127802,
      2.305535039197036,
      2.4769363960742115
    ],
    "volume": 4800732139326
  },
  {
    "ts": 1614211200,
    "supply": 29830724533535,
    "trustlines": {
      "total": 1573,
      "authorized": 1573,
      "funded": 572
    },
    "payments": 242,
    "payments_amount": 22574301038953,
    "trades": 2766,
    "traded_amount": 9952940576582,
    "price": [
      2.4769363960742115,
      2.6473558864104847,
      2.355460529226477,
      2.6299319727891155
    ],
    "volume": 9672429046775
  },
  {
    "ts": 1614297600,
    "supply": 36292994033535,
    "trustlines": {
      "total": 1605,
      "authorized": 1605,
      "funded": 585
    },
    "payments": 96,
    "payments_amount": 17556955177127,
    "trades": 3737,
    "traded_amount": 14610793139748,
    "price": [
      2.6299319727891155,
      2.850207380966444,
      2.4626686656671666,
      2.5097844008244534
    ],
    "volume": 7744025836534
  },
  {
    "ts": 1614384000,
    "supply": 35323222233535,
    "trustlines": {
      "total": 1661,
      "authorized": 1661,
      "funded": 611
    },
    "payments": 84,
    "payments_amount": 1353011708385,
    "trades": 4477,
    "traded_amount": 2923897515486,
    "price": [
      2.5097844008244534,
      2.5097844008244534,
      2.132186060859793,
      2.272879142564033
    ],
    "volume": 5072773558544
  },
  {
    "ts": 1614470400,
    "supply": 33384090033535,
    "trustlines": {
      "total": 1710,
      "authorized": 1710,
      "funded": 632
    },
    "payments": 64,
    "payments_amount": 18588245914159,
    "trades": 3280,
    "traded_amount": 10251134290599,
    "price": [
      2.272879142564033,
      2.6179758730957485,
      2.2198523379819526,
      2.4779160959332325
    ],
    "volume": 4896943319427
  },
  {
    "ts": 1614556800,
    "supply": 26167992433535,
    "trustlines": {
      "total": 1751,
      "authorized": 1751,
      "funded": 647
    },
    "payments": 92,
    "payments_amount": 20560608223828,
    "trades": 1693,
    "traded_amount": 8517660622101,
    "price": [
      2.4779160959332325,
      2.495855616027587,
      2.301675722453514,
      2.329197073437126
    ],
    "volume": 4955778755689
  },
  {
    "ts": 1614643200,
    "supply": 24741122433535,
    "trustlines": {
      "total": 1847,
      "authorized": 1847,
      "funded": 689
    },
    "payments": 379,
    "payments_amount": 12043722686459,
    "trades": 2611,
    "traded_amount": 6820279856361,
    "price": [
      2.329197073437126,
      2.5208443760024632,
      2.297296068951391,
      2.3897141374153503
    ],
    "volume": 3938225576153
  },
  {
    "ts": 1614729600,
    "supply": 28774415182287,
    "trustlines": {
      "total": 1943,
      "authorized": 1943,
      "funded": 724
    },
    "payments": 126,
    "payments_amount": 12875241075914,
    "trades": 3362,
    "traded_amount": 10617016991714,
    "price": [
      2.3897141374153503,
      2.421762297978075,
      2.2926821913788697,
      2.3759408428745177
    ],
    "volume": 4432347383168
  },
  {
    "ts": 1614816000,
    "supply": 30010245382287,
    "trustlines": {
      "total": 2008,
      "authorized": 2008,
      "funded": 740
    },
    "payments": 159,
    "payments_amount": 19017702930855,
    "trades": 3141,
    "traded_amount": 11733135796849,
    "price": [
      2.3759408428745177,
      2.492502553409213,
      2.3333333333333335,
      2.431557357034722
    ],
    "volume": 3324551425162
  },
  {
    "ts": 1614902400,
    "supply": 33705636282287,
    "trustlines": {
      "total": 2067,
      "authorized": 2067,
      "funded": 765
    },
    "payments": 108,
    "payments_amount": 15610884416559,
    "trades": 2257,
    "traded_amount": 10402654356415,
    "price": [
      2.431557357034722,
      2.5843228266711584,
      2.4209667889521373,
      2.476235420365702
    ],
    "volume": 1365952239696
  },
  {
    "ts": 1614988800,
    "supply": 36075000482287,
    "trustlines": {
      "total": 2111,
      "authorized": 2111,
      "funded": 777
    },
    "payments": 56,
    "payments_amount": 2582683011724,
    "trades": 1110,
    "traded_amount": 2797278004119,
    "price": [
      2.476235420365702,
      2.5483305300110275,
      2.4551586545354898,
      2.4832535479484688
    ],
    "volume": 885847122312
  },
  {
    "ts": 1615075200,
    "supply": 36075000482287,
    "trustlines": {
      "total": 2163,
      "authorized": 2163,
      "funded": 801
    },
    "payments": 65,
    "payments_amount": 62454394855,
    "trades": 1609,
    "traded_amount": 391857380561,
    "price": [
      2.4832535479484688,
      2.4876381474145273,
      2.3712092856162257,
      2.4303447262755853
    ],
    "volume": 714837009366
  },
  {
    "ts": 1615161600,
    "supply": 33203924082287,
    "trustlines": {
      "total": 2218,
      "authorized": 2218,
      "funded": 810
    },
    "payments": 97,
    "payments_amount": 22423738226511,
    "trades": 2177,
    "traded_amount": 11860435460707,
    "price": [
      2.4303447262755853,
      2.486479657588102,
      2.3892967542503865,
      2.40041352744576
    ],
    "volume": 4406222970603
  },
  {
    "ts": 1615248000,
    "supply": 32038783982287,
    "trustlines": {
      "total": 2280,
      "authorized": 2280,
      "funded": 825
    },
    "payments": 107,
    "payments_amount": 25370572564616,
    "trades": 2610,
    "traded_amount": 12778265336531,
    "price": [
      2.40041352744576,
      2.4171634326225404,
      2.2965991451403083,
      2.3215201435022443
    ],
    "volume": 1374175408724
  },
  {
    "ts": 1615334400,
    "supply": 39957119982287,
    "trustlines": {
      "total": 2319,
      "authorized": 2319,
      "funded": 834
    },
    "payments": 113,
    "payments_amount": 34752477126335,
    "trades": 2248,
    "traded_amount": 22216536114658,
    "price": [
      2.3215201435022443,
      2.457700650759219,
      2.3143993666937246,
      2.4370291367735626
    ],
    "volume": 1795363273174
  },
  {
    "ts": 1615420800,
    "supply": 38327764782287,
    "trustlines": {
      "total": 2365,
      "authorized": 2365,
      "funded": 844
    },
    "payments": 125,
    "payments_amount": 17307931425895,
    "trades": 1585,
    "traded_amount": 8665034877507,
    "price": [
      2.4370291367735626,
      2.525519304188295,
      2.427908177076479,
      2.463687218045113
    ],
    "volume": 1854343575106
  },
  {
    "ts": 1615507200,
    "supply": 32765191182287,
    "trustlines": {
      "total": 2404,
      "authorized": 2404,
      "funded": 862
    },
    "payments": 70,
    "payments_amount": 27834962465926,
    "trades": 1013,
    "traded_amount": 11565447539036,
    "price": [
      2.463687218045113,
      2.639182919895721,
      2.4567327266642365,
      2.6039563130216865
    ],
    "volume": 1041700899077
  },
  {
    "ts": 1615593600,
    "supply": 29785749082287,
    "trustlines": {
      "total": 2434,
      "authorized": 2434,
      "funded": 870
    },
    "payments": 79,
    "payments_amount": 181260009600488,
    "trades": 1438,
    "traded_amount": 89739688526938,
    "price": [
      2.6039563130216865,
      2.6564496705586147,
      2.4299763132252825,
      2.456075271458753
    ],
    "volume": 1266272877508
  },
  {
    "ts": 1615680000,
    "supply": 32556930482287,
    "trustlines": {
      "total": 2468,
      "authorized": 2468,
      "funded": 875
    },
    "payments": 65,
    "payments_amount": 4371221347172,
    "trades": 1025,
    "traded_amount": 5817664883879,
    "price": [
      2.456075271458753,
      2.575000000006421,
      2.446507674103546,
      2.563430456637432
    ],
    "volume": 5442380680911
  },
  {
    "ts": 1615766400,
    "supply": 35814362482286,
    "trustlines": {
      "total": 2512,
      "authorized": 2512,
      "funded": 876
    },
    "payments": 87,
    "payments_amount": 24473252936550,
    "trades": 1524,
    "traded_amount": 14158855404899,
    "price": [
      2.563430456637432,
      2.71051645663553,
      2.51164129049963,
      2.600780234070221
    ],
    "volume": 1627664401754
  },
  {
    "ts": 1615852800,
    "supply": 35225512982286,
    "trustlines": {
      "total": 2551,
      "authorized": 2551,
      "funded": 888
    },
    "payments": 81,
    "payments_amount": 23367741126340,
    "trades": 2225,
    "traded_amount": 11643634662371,
    "price": [
      2.600780234070221,
      2.672471215478253,
      2.438282670910675,
      2.537778115216519
    ],
    "volume": 1288636898549
  },
  {
    "ts": 1615939200,
    "supply": 36245773682286,
    "trustlines": {
      "total": 2590,
      "authorized": 2590,
      "funded": 904
    },
    "payments": 169,
    "payments_amount": 27143199926843,
    "trades": 2333,
    "traded_amount": 14805092247954,
    "price": [
      2.537778115216519,
      2.6315789473684212,
      2.470544608328014,
      2.491300001453669
    ],
    "volume": 2061397181112
  },
  {
    "ts": 1616025600,
    "supply": 37413340082286,
    "trustlines": {
      "total": 2620,
      "authorized": 2620,
      "funded": 913
    },
    "payments": 72,
    "payments_amount": 18041873321225,
    "trades": 1248,
    "traded_amount": 10387196635271,
    "price": [
      2.491300001453669,
      2.543923137522213,
      2.45295622780798,
      2.5307879720903355
    ],
    "volume": 1512298955521
  },
  {
    "ts": 1616112000,
    "supply": 38687344382286,
    "trustlines": {
      "total": 2652,
      "authorized": 2652,
      "funded": 912
    },
    "payments": 75,
    "payments_amount": 39402952768134,
    "trades": 970,
    "traded_amount": 20935935167651,
    "price": [
      2.5307879720903355,
      2.5780484843266254,
      2.486099752044837,
      2.5229139962643727
    ],
    "volume": 1271554255840
  },
  {
    "ts": 1616198400,
    "supply": 37710217382286,
    "trustlines": {
      "total": 2684,
      "authorized": 2684,
      "funded": 932
    },
    "payments": 71,
    "payments_amount": 29566946289088,
    "trades": 1828,
    "traded_amount": 14726591762416,
    "price": [
      2.5229139962643727,
      2.542439547218628,
      2.294837431049492,
      2.410130482931292
    ],
    "volume": 1014974577597
  },
  {
    "ts": 1616284800,
    "supply": 45173674982286,
    "trustlines": {
      "total": 2722,
      "authorized": 2722,
      "funded": 938
    },
    "payments": 53,
    "payments_amount": 7968247052227,
    "trades": 1427,
    "traded_amount": 8562934622032,
    "price": [
      2.410130482931292,
      2.530556469367614,
      2.3736945304786223,
      2.48593342780608
    ],
    "volume": 1640764893804
  },
  {
    "ts": 1616371200,
    "supply": 37623316682286,
    "trustlines": {
      "total": 2742,
      "authorized": 2742,
      "funded": 947
    },
    "payments": 75,
    "payments_amount": 20829106431577,
    "trades": 2344,
    "traded_amount": 8384335061667,
    "price": [
      2.48593342780608,
      2.5584819095624227,
      2.364161554763042,
      2.5392555993243136
    ],
    "volume": 3902048918197
  },
  {
    "ts": 1616457600,
    "supply": 39799970282286,
    "trustlines": {
      "total": 2782,
      "authorized": 2782,
      "funded": 958
    },
    "payments": 62,
    "payments_amount": 25578423003071,
    "trades": 1459,
    "traded_amount": 14304438103670,
    "price": [
      2.5392555993243136,
      2.5737733511734655,
      2.4583284728179766,
      2.5238102291254694
    ],
    "volume": 726710762238
  },
  {
    "ts": 1616544000,
    "supply": 39409125282286,
    "trustlines": {
      "total": 2824,
      "authorized": 2824,
      "funded": 969
    },
    "payments": 81,
    "payments_amount": 23845787015976,
    "trades": 1555,
    "traded_amount": 13039039517280,
    "price": [
      2.5238102291254694,
      2.8423704670899594,
      2.4889385881797166,
      2.728800805141243
    ],
    "volume": 2964641656306
  },
  {
    "ts": 1616630400,
    "supply": 48782295682286,
    "trustlines": {
      "total": 2928,
      "authorized": 2928,
      "funded": 971
    },
    "payments": 53,
    "payments_amount": 15022292535901,
    "trades": 1518,
    "traded_amount": 13489564387642,
    "price": [
      2.728800805141243,
      2.9112057710081345,
      2.696551034403932,
      2.7595772763914357
    ],
    "volume": 3245859835461
  },
  {
    "ts": 1616716800,
    "supply": 48782295682285,
    "trustlines": {
      "total": 3062,
      "authorized": 3062,
      "funded": 984
    },
    "payments": 54,
    "payments_amount": 5190535787571,
    "trades": 1180,
    "traded_amount": 1300650916313,
    "price": [
      2.7595772763914357,
      2.760305668716884,
      2.593675460704947,
      2.593675460704947
    ],
    "volume": 2827095061255
  },
  {
    "ts": 1616803200,
    "supply": 45719047182285,
    "trustlines": {
      "total": 3163,
      "authorized": 3163,
      "funded": 984
    },
    "payments": 33,
    "payments_amount": 3702096829774,
    "trades": 1188,
    "traded_amount": 936743658149,
    "price": [
      2.593675460704947,
      2.695514826704579,
      2.5769251943086218,
      2.631618500029723
    ],
    "volume": 1394637302619
  },
  {
    "ts": 1616889600,
    "supply": 45152771782285,
    "trustlines": {
      "total": 3305,
      "authorized": 3305,
      "funded": 991
    },
    "payments": 39,
    "payments_amount": 832806089746,
    "trades": 1035,
    "traded_amount": 1173772031452,
    "price": [
      2.631618500029723,
      2.6416266339524195,
      2.444894789608868,
      2.5432460869668407
    ],
    "volume": 2328511790172
  },
  {
    "ts": 1616976000,
    "supply": 40100230682285,
    "trustlines": {
      "total": 3413,
      "authorized": 3413,
      "funded": 1008
    },
    "payments": 85,
    "payments_amount": 38762838252864,
    "trades": 938,
    "traded_amount": 15309196487217,
    "price": [
      2.5432460869668407,
      2.5432460869668407,
      2.4411610111360718,
      2.47734532618418
    ],
    "volume": 1819498684823
  },
  {
    "ts": 1617062400,
    "supply": 43640308082285,
    "trustlines": {
      "total": 3494,
      "authorized": 3494,
      "funded": 1030
    },
    "payments": 93,
    "payments_amount": 28371978178130,
    "trades": 742,
    "traded_amount": 17172990968469,
    "price": [
      2.47734532618418,
      2.5300000000586658,
      2.4338138616994405,
      2.4903731424408946
    ],
    "volume": 6786081790141
  },
  {
    "ts": 1617148800,
    "supply": 44312819982285,
    "trustlines": {
      "total": 3552,
      "authorized": 3552,
      "funded": 1033
    },
    "payments": 108,
    "payments_amount": 38405145401717,
    "trades": 1027,
    "traded_amount": 20851412787890,
    "price": [
      2.4903731424408946,
      2.6207600361857137,
      2.476467317629188,
      2.48004835
    ],
    "volume": 3158638550344
  },
  {
    "ts": 1617235200,
    "supply": 46900304382285,
    "trustlines": {
      "total": 3614,
      "authorized": 3614,
      "funded": 1042
    },
    "payments": 76,
    "payments_amount": 30843043650760,
    "trades": 2029,
    "traded_amount": 18067480196596,
    "price": [
      2.48004835,
      2.4875450248232083,
      2.3369225346122957,
      2.347495004916666
    ],
    "volume": 2933006587096
  },
  {
    "ts": 1617321600,
    "supply": 46428285082285,
    "trustlines": {
      "total": 3698,
      "authorized": 3698,
      "funded": 1068
    },
    "payments": 106,
    "payments_amount": 12338528272596,
    "trades": 1850,
    "traded_amount": 7238967872542,
    "price": [
      2.347495004916666,
      2.347495004916666,
      2.147869967378273,
      2.272304273546316
    ],
    "volume": 4698139929878
  },
  {
    "ts": 1617408000,
    "supply": 53358141982285,
    "trustlines": {
      "total": 3761,
      "authorized": 3761,
      "funded": 3717
    },
    "payments": 15765,
    "payments_amount": 17463607661701,
    "trades": 2640,
    "traded_amount": 12997735877704,
    "price": [
      2.272304273546316,
      2.4539877302026456,
      2.1897931451004076,
      2.44462280025
    ],
    "volume": 1701089255172
  },
  {
    "ts": 1617494400,
    "supply": 53358141982285,
    "trustlines": {
      "total": 3829,
      "authorized": 3829,
      "funded": 3707
    },
    "payments": 35,
    "payments_amount": 126097820169,
    "trades": 928,
    "traded_amount": 643103350452,
    "price": [
      2.44462280025,
      2.4871767871036834,
      2.3069603813859585,
      2.3484600017285477
    ],
    "volume": 1398891260847
  },
  {
    "ts": 1617580800,
    "supply": 47402664882285,
    "trustlines": {
      "total": 3913,
      "authorized": 3913,
      "funded": 3852
    },
    "payments": 44286,
    "payments_amount": 44746801687008,
    "trades": 4315,
    "traded_amount": 21837493396570,
    "price": [
      2.3484600017285477,
      2.364656964683667,
      1.8320304593654277,
      1.87943697404369
    ],
    "volume": 5766286916520
  },
  {
    "ts": 1617667200,
    "supply": 46631341182285,
    "trustlines": {
      "total": 3980,
      "authorized": 3980,
      "funded": 3858
    },
    "payments": 75,
    "payments_amount": 4151550557890,
    "trades": 1469,
    "traded_amount": 4492928482622,
    "price": [
      1.87943697404369,
      2.0576102384087553,
      1.8656716417910448,
      1.919999997002927
    ],
    "volume": 5116347992703
  },
  {
    "ts": 1617753600,
    "supply": 46631341182285,
    "trustlines": {
      "total": 4030,
      "authorized": 4030,
      "funded": 3855
    },
    "payments": 127,
    "payments_amount": 625591317371,
    "trades": 1880,
    "traded_amount": 1398358905781,
    "price": [
      1.919999997002927,
      2.36972440117467,
      1.873956326268465,
      2.1067018576458385
    ],
    "volume": 2282833676364
  },
  {
    "ts": 1617840000,
    "supply": 46002448982285,
    "trustlines": {
      "total": 4100,
      "authorized": 4100,
      "funded": 3856
    },
    "payments": 86,
    "payments_amount": 907481643104,
    "trades": 1241,
    "traded_amount": 997325189034,
    "price": [
      2.1067018576458385,
      2.1381226996583713,
      1.9920318711698946,
      1.9957932988174454
    ],
    "volume": 1803361270498
  },
  {
    "ts": 1617926400,
    "supply": 46002448982285,
    "trustlines": {
      "total": 4148,
      "authorized": 4148,
      "funded": 3866
    },
    "payments": 80,
    "payments_amount": 2369832235232,
    "trades": 1067,
    "traded_amount": 1323188364293,
    "price": [
      1.9957932988174454,
      2.0847088072834015,
      1.980950272134523,
      2.0576115464678093
    ],
    "volume": 2360526985881
  },
  {
    "ts": 1618012800,
    "supply": 46002448982285,
    "trustlines": {
      "total": 4200,
      "authorized": 4200,
      "funded": 3867
    },
    "payments": 63,
    "payments_amount": 77650246869,
    "trades": 2912,
    "traded_amount": 2659376638714,
    "price": [
      2.0576115464678093,
      2.0830965504410974,
      1.7001087311796168,
      1.7824218734748623
    ],
    "volume": 4238849491571
  },
  {
    "ts": 1618099200,
    "supply": 46002448982285,
    "trustlines": {
      "total": 4259,
      "authorized": 4259,
      "funded": 3881
    },
    "payments": 163,
    "payments_amount": 5503585192091,
    "trades": 4153,
    "traded_amount": 6833391138340,
    "price": [
      1.7824218734748623,
      1.7920041963620266,
      1.5432108790844978,
      1.7125125473675498
    ],
    "volume": 10265872298396
  },
  {
    "ts": 1618185600,
    "supply": 50106938282285,
    "trustlines": {
      "total": 4317,
      "authorized": 4317,
      "funded": 3880
    },
    "payments": 101,
    "payments_amount": 22879796077896,
    "trades": 2487,
    "traded_amount": 13552339147679,
    "price": [
      1.7125125473675498,
      1.7688750000051239,
      1.6629361406743224,
      1.6955861333426467
    ],
    "volume": 3413951024903
  },
  {
    "ts": 1618272000,
    "supply": 61848710782285,
    "trustlines": {
      "total": 4394,
      "authorized": 4394,
      "funded": 3893
    },
    "payments": 110,
    "payments_amount": 47731499573836,
    "trades": 4048,
    "traded_amount": 31956171846798,
    "price": [
      1.6955861333426467,
      1.7271416618902975,
      1.4689297999980617,
      1.5236364967490934
    ],
    "volume": 7665057225491
  },
  {
    "ts": 1618358400,
    "supply": 55986447082285,
    "trustlines": {
      "total": 4454,
      "authorized": 4454,
      "funded": 3901
    },
    "payments": 132,
    "payments_amount": 51893071646975,
    "trades": 2453,
    "traded_amount": 23093418996302,
    "price": [
      1.5236364967490934,
      1.754385964990041,
      1.4580091270539954,
      1.5737377167804034
    ],
    "volume": 4158503634162
  },
  {
    "ts": 1618444800,
    "supply": 61195109582285,
    "trustlines": {
      "total": 4541,
      "authorized": 4541,
      "funded": 3909
    },
    "payments": 66,
    "payments_amount": 19164762831128,
    "trades": 1382,
    "traded_amount": 13300009583212,
    "price": [
      1.5737377167804034,
      1.6296356412024096,
      1.5492745212199484,
      1.5734928142228282
    ],
    "volume": 1833675200409
  },
  {
    "ts": 1618531200,
    "supply": 60700511882285,
    "trustlines": {
      "total": 4687,
      "authorized": 4687,
      "funded": 3919
    },
    "payments": 138,
    "payments_amount": 26764663177850,
    "trades": 1997,
    "traded_amount": 16462299945438,
    "price": [
      1.5734928142228282,
      1.765467616151471,
      1.5384615345514805,
      1.6494437251037088
    ],
    "volume": 5836149439876
  },
  {
    "ts": 1618617600,
    "supply": 60036960782285,
    "trustlines": {
      "total": 4843,
      "authorized": 4843,
      "funded": 3919
    },
    "payments": 94,
    "payments_amount": 20990956323348,
    "trades": 1396,
    "traded_amount": 10482525111225,
    "price": [
      1.6494437251037088,
      1.6904139991864506,
      1.5622197768275317,
      1.6858078560624097
    ],
    "volume": 5063413284280
  },
  {
    "ts": 1618704000,
    "supply": 68621040282285,
    "trustlines": {
      "total": 5027,
      "authorized": 5027,
      "funded": 3920
    },
    "payments": 101,
    "payments_amount": 29217939464276,
    "trades": 3428,
    "traded_amount": 24213205971738,
    "price": [
      1.6858078560624097,
      2.1021015961033647,
      1.6301369192960407,
      1.8442568314474839
    ],
    "volume": 10035540122478
  },
  {
    "ts": 1618790400,
    "supply": 66111069382285,
    "trustlines": {
      "total": 5214,
      "authorized": 5214,
      "funded": 3940
    },
    "payments": 109,
    "payments_amount": 30011783217748,
    "trades": 3626,
    "traded_amount": 15518416118175,
    "price": [
      1.8442568314474839,
      2.039854006288662,
      1.7748192320815335,
      2.00493772444547
    ],
    "volume": 3007120107929
  },
  {
    "ts": 1618876800,
    "supply": 64653614582285,
    "trustlines": {
      "total": 5413,
      "authorized": 5413,
      "funded": 3958
    },
    "payments": 115,
    "payments_amount": 11564899638213,
    "trades": 6667,
    "traded_amount": 8871397958894,
    "price": [
      2.00493772444547,
      2.133871552007719,
      1.893826135957217,
      1.893826135957217
    ],
    "volume": 6304646989576
  },
  {
    "ts": 1618963200,
    "supply": 62841086082285,
    "trustlines": {
      "total": 5570,
      "authorized": 5570,
      "funded": 3968
    },
    "payments": 161,
    "payments_amount": 30965240476687,
    "trades": 4551,
    "traded_amount": 17298704766685,
    "price": [
      1.893826135957217,
      2.0621761139894232,
      1.868775535023654,
      2.0380652333039424
    ],
    "volume": 5189609379068
  },
  {
    "ts": 1619049600,
    "supply": 70797794382285,
    "trustlines": {
      "total": 5714,
      "authorized": 5714,
      "funded": 3982
    },
    "payments": 173,
    "payments_amount": 34623071713202,
    "trades": 6340,
    "traded_amount": 25151562507820,
    "price": [
      2.0380652333039424,
      2.3697244016244086,
      1.9565447710689008,
      2.233669625004593
    ],
    "volume": 6950708512298
  },
  {
    "ts": 1619136000,
    "supply": 64665678282285,
    "trustlines": {
      "total": 5886,
      "authorized": 5886,
      "funded": 3989
    },
    "payments": 190,
    "payments_amount": 65333073649755,
    "trades": 3729,
    "traded_amount": 34379125462438,
    "price": [
      2.233669625004593,
      2.6158304254371245,
      2.1747214725474033,
      2.229624575908332
    ],
    "volume": 10751481142719
  },
  {
    "ts": 1619222400,
    "supply": 66722932482258,
    "trustlines": {
      "total": 6129,
      "authorized": 6129,
      "funded": 4006
    },
    "payments": 164,
    "payments_amount": 13491977549562,
    "trades": 2212,
    "traded_amount": 9079175332916,
    "price": [
      2.229624575908332,
      2.422225364847939,
      2.1783094401831873,
      2.3487701847365927
    ],
    "volume": 2902197911918
  },
  {
    "ts": 1619308800,
    "supply": 62446361682242,
    "trustlines": {
      "total": 6400,
      "authorized": 6400,
      "funded": 4048
    },
    "payments": 296,
    "payments_amount": 25871125159004,
    "trades": 2905,
    "traded_amount": 13548814695015,
    "price": [
      2.3487701847365927,
      2.5,
      2.2488618465422094,
      2.4008004971913013
    ],
    "volume": 9819420110224
  },
  {
    "ts": 1619395200,
    "supply": 70905982582242,
    "trustlines": {
      "total": 6658,
      "authorized": 6658,
      "funded": 4096
    },
    "payments": 229,
    "payments_amount": 23231143924941,
    "trades": 3888,
    "traded_amount": 19386008128590,
    "price": [
      2.4008004971913013,
      2.4186198724043377,
      2.056453278278803,
      2.066329209473815
    ],
    "volume": 7777109502211
  },
  {
    "ts": 1619481600,
    "supply": 63517260982242,
    "trustlines": {
      "total": 6891,
      "authorized": 6891,
      "funded": 4128
    },
    "payments": 228,
    "payments_amount": 15976905965623,
    "trades": 3227,
    "traded_amount": 7692500036015,
    "price": [
      2.066329209473815,
      2.1041131720734287,
      1.917491824938901,
      1.9749118779078159
    ],
    "volume": 8883189309278
  },
  {
    "ts": 1619568000,
    "supply": 72179948182242,
    "trustlines": {
      "total": 7162,
      "authorized": 7162,
      "funded": 4173
    },
    "payments": 402,
    "payments_amount": 41140096242792,
    "trades": 4466,
    "traded_amount": 27664922788319,
    "price": [
      1.9749118779078159,
      2.098421062579939,
      1.9193857960899339,
      2.024014521390842
    ],
    "volume": 6629772262349
  },
  {
    "ts": 1619654400,
    "supply": 68329169982225,
    "trustlines": {
      "total": 7487,
      "authorized": 7487,
      "funded": 4237
    },
    "payments": 624,
    "payments_amount": 41912073154039,
    "trades": 3535,
    "traded_amount": 23296722800817,
    "price": [
      2.024014521390842,
      2.1052374000775114,
      1.9702180808908225,
      2.0470087157527668
    ],
    "volume": 12330404389208
  },
  {
    "ts": 1619740800,
    "supply": 70361431082225,
    "trustlines": {
      "total": 7773,
      "authorized": 7773,
      "funded": 4284
    },
    "payments": 498,
    "payments_amount": 30827517886208,
    "trades": 3688,
    "traded_amount": 20352620567853,
    "price": [
      2.0470087157527668,
      2.0697915675085885,
      1.8525872240513834,
      1.8987925446223306
    ],
    "volume": 8405619185678
  },
  {
    "ts": 1619827200,
    "supply": 65057000782225,
    "trustlines": {
      "total": 8012,
      "authorized": 8012,
      "funded": 4319
    },
    "payments": 254,
    "payments_amount": 33149034199312,
    "trades": 1751,
    "traded_amount": 17330380017388,
    "price": [
      1.8987925446223306,
      1.9491167482303893,
      1.865462096112202,
      1.8654621000029845
    ],
    "volume": 7903482081512
  },
  {
    "ts": 1619913600,
    "supply": 66031383882225,
    "trustlines": {
      "total": 8257,
      "authorized": 8257,
      "funded": 4362
    },
    "payments": 276,
    "payments_amount": 40872894413600,
    "trades": 1888,
    "traded_amount": 23214539206972,
    "price": [
      1.8654621000029845,
      1.9522995000488232,
      1.7879136614924824,
      1.7984851389106709
    ],
    "volume": 6858803317868
  },
  {
    "ts": 1620000000,
    "supply": 74585909282225,
    "trustlines": {
      "total": 8506,
      "authorized": 8506,
      "funded": 4374
    },
    "payments": 358,
    "payments_amount": 70010969412467,
    "trades": 1642,
    "traded_amount": 42195014131751,
    "price": [
      1.7984851389106709,
      1.8514090797650393,
      1.747619288124752,
      1.8071920006814721
    ],
    "volume": 7171985689964
  },
  {
    "ts": 1620086400,
    "supply": 74023891482225,
    "trustlines": {
      "total": 8714,
      "authorized": 8714,
      "funded": 4377
    },
    "payments": 408,
    "payments_amount": 56572455584769,
    "trades": 3335,
    "traded_amount": 31495969318687,
    "price": [
      1.8071920006814721,
      2.01626073884088,
      1.807191999307968,
      1.9882028319029454
    ],
    "volume": 12119458652135
  },
  {
    "ts": 1620172800,
    "supply": 76958331382225,
    "trustlines": {
      "total": 9129,
      "authorized": 9129,
      "funded": 4504
    },
    "payments": 756,
    "payments_amount": 40916977354299,
    "trades": 5031,
    "traded_amount": 29373877613883,
    "price": [
      1.9882028319029454,
      2.0100878990776243,
      1.6568416754528785,
      1.6568416754528785
    ],
    "volume": 17287768937868
  },
  {
    "ts": 1620259200,
    "supply": 79076327482225,
    "trustlines": {
      "total": 9639,
      "authorized": 9639,
      "funded": 4621
    },
    "payments": 756,
    "payments_amount": 61071737777273,
    "trades": 6628,
    "traded_amount": 46328967329356,
    "price": [
      1.6568416754528785,
      1.684394742376783,
      1.4493011698554203,
      1.5492404132372783
    ],
    "volume": 26617185315129
  },
  {
    "ts": 1620345600,
    "supply": 73686451082208,
    "trustlines": {
      "total": 10045,
      "authorized": 10045,
      "funded": 4692
    },
    "payments": 777,
    "payments_amount": 71467908821223,
    "trades": 4867,
    "traded_amount": 40236925116950,
    "price": [
      1.5492404132372783,
      1.6510496343537648,
      1.4553452655760044,
      1.5930025768807934
    ],
    "volume": 19952562048205
  },
  {
    "ts": 1620432000,
    "supply": 79418373082208,
    "trustlines": {
      "total": 10362,
      "authorized": 10362,
      "funded": 4730
    },
    "payments": 651,
    "payments_amount": 56747109222943,
    "trades": 3337,
    "traded_amount": 33466732149307,
    "price": [
      1.5930025768807934,
      1.6699843695946013,
      1.5611737764565718,
      1.6225828467126877
    ],
    "volume": 8487592186316
  },
  {
    "ts": 1620518400,
    "supply": 92911186582207,
    "trustlines": {
      "total": 10662,
      "authorized": 10662,
      "funded": 4789
    },
    "payments": 762,
    "payments_amount": 27816035623398,
    "trades": 3415,
    "traded_amount": 26502378275820,
    "price": [
      1.6225828467126877,
      1.7743980628016776,
      1.5671328362851313,
      1.5899814596899553
    ],
    "volume": 13212770571610
  },
  {
    "ts": 1620604800,
    "supply": 77305670282207,
    "trustlines": {
      "total": 11006,
      "authorized": 11006,
      "funded": 4843
    },
    "payments": 858,
    "payments_amount": 57272686931663,
    "trades": 7497,
    "traded_amount": 34696527304639,
    "price": [
      1.5899814596899553,
      1.6363933890679612,
      1.2970943629559264,
      1.5151744961523952
    ],
    "volume": 25941373776730
  },
  {
    "ts": 1620691200,
    "supply": 83097856482207,
    "trustlines": {
      "total": 11307,
      "authorized": 11307,
      "funded": 4889
    },
    "payments": 676,
    "payments_amount": 50569802178807,
    "trades": 5836,
    "traded_amount": 35863046615692,
    "price": [
      1.5151744961523952,
      1.6103059583749324,
      1.355767260011076,
      1.3730764742676074
    ],
    "volume": 14290869390375
  },
  {
    "ts": 1620777600,
    "supply": 84282884782207,
    "trustlines": {
      "total": 11640,
      "authorized": 11640,
      "funded": 4915
    },
    "payments": 630,
    "payments_amount": 62712848405717,
    "trades": 6048,
    "traded_amount": 41741960512556,
    "price": [
      1.3730764742676074,
      1.6951038314782578,
      1.339851738986366,
      1.6951038314782578
    ],
    "volume": 15292440089141
  },
  {
    "ts": 1620864000,
    "supply": 86380218782207,
    "trustlines": {
      "total": 11872,
      "authorized": 11872,
      "funded": 4934
    },
    "payments": 459,
    "payments_amount": 54094334836611,
    "trades": 7806,
    "traded_amount": 35788490544537,
    "price": [
      1.6951038314782578,
      1.8180089400588022,
      1.5243112074983043,
      1.5446250201212381
    ],
    "volume": 10596585110266
  },
  {
    "ts": 1620950400,
    "supply": 87682685782207,
    "trustlines": {
      "total": 12113,
      "authorized": 12113,
      "funded": 4982
    },
    "payments": 543,
    "payments_amount": 55669550022713,
    "trades": 3376,
    "traded_amount": 32162858089465,
    "price": [
      1.5446250201212381,
      1.5685821510561313,
      1.4221090769729052,
      1.4934988398789406
    ],
    "volume": 5067944227595
  },
  {
    "ts": 1621036800,
    "supply": 89909728482207,
    "trustlines": {
      "total": 12319,
      "authorized": 12319,
      "funded": 5031
    },
    "payments": 353,
    "payments_amount": 44257052278411,
    "trades": 5093,
    "traded_amount": 28229532907961,
    "price": [
      1.4934988398789406,
      1.53694724387571,
      1.3513513513513513,
      1.4753904617586182
    ],
    "volume": 7212826151415
  },
  {
    "ts": 1621123200,
    "supply": 89371967882207,
    "trustlines": {
      "total": 12537,
      "authorized": 12537,
      "funded": 5073
    },
    "payments": 423,
    "payments_amount": 23844589023198,
    "trades": 4498,
    "traded_amount": 17676759799315,
    "price": [
      1.4753904617586182,
      1.5165550718539786,
      1.2606384714949952,
      1.4034098930854275
    ],
    "volume": 9882180971933
  },
  {
    "ts": 1621209600,
    "supply": 100031863882176,
    "trustlines": {
      "total": 12739,
      "authorized": 12739,
      "funded": 5089
    },
    "payments": 523,
    "payments_amount": 88523822469157,
    "trades": 4015,
    "traded_amount": 52229233778767,
    "price": [
      1.4034098930854275,
      1.5987210231814548,
      1.4034098930854275,
      1.5534559136534158
    ],
    "volume": 9724798418243
  },
  {
    "ts": 1621296000,
    "supply": 94634360082176,
    "trustlines": {
      "total": 12918,
      "authorized": 12918,
      "funded": 5120
    },
    "payments": 403,
    "payments_amount": 46844876800246,
    "trades": 2593,
    "traded_amount": 22279095985246,
    "price": [
      1.5534559136534158,
      1.5764089805134902,
      1.4354444631669245,
      1.5451846290355606
    ],
    "volume": 3469152332553
  },
  {
    "ts": 1621382400,
    "supply": 87945428882176,
    "trustlines": {
      "total": 13117,
      "authorized": 13117,
      "funded": 5102
    },
    "payments": 479,
    "payments_amount": 41917700354036,
    "trades": 7430,
    "traded_amount": 24053828603265,
    "price": [
      1.5451846290355606,
      3.225806452031692,
      1.533630023667048,
      2.158541529799043
    ],
    "volume": 18409818149304
  },
  {
    "ts": 1621468800,
    "supply": 89135248382176,
    "trustlines": {
      "total": 13319,
      "authorized": 13319,
      "funded": 5148
    },
    "payments": 467,
    "payments_amount": 50485925774926,
    "trades": 4006,
    "traded_amount": 24781980707712,
    "price": [
      2.158541529799043,
      2.3743197198506913,
      1.8684286083265342,
      2.022898439276558
    ],
    "volume": 8942732151224
  },
  {
    "ts": 1621555200,
    "supply": 93610272582176,
    "trustlines": {
      "total": 13543,
      "authorized": 13543,
      "funded": 5192
    },
    "payments": 410,
    "payments_amount": 31627269268861,
    "trades": 3664,
    "traded_amount": 21460178478956,
    "price": [
      2.022898439276558,
      2.618394998685885,
      1.9547067983953799,
      2.4144089503974286
    ],
    "volume": 10127103498812
  },
  {
    "ts": 1621641600,
    "supply": 95128016882176,
    "trustlines": {
      "total": 13797,
      "authorized": 13797,
      "funded": 5205
    },
    "payments": 324,
    "payments_amount": 50849796388996,
    "trades": 2872,
    "traded_amount": 29187190928963,
    "price": [
      2.4144089503974286,
      2.6976007949208256,
      2.374416015103382,
      2.5620338850584163
    ],
    "volume": 7461690759328
  },
  {
    "ts": 1621728000,
    "supply": 82540431882175,
    "trustlines": {
      "total": 14018,
      "authorized": 14018,
      "funded": 5243
    },
    "payments": 326,
    "payments_amount": 32809487234439,
    "trades": 5275,
    "traded_amount": 15993259157381,
    "price": [
      2.5620338850584163,
      3.641730401867529,
      2.493239984150915,
      2.727628115302846
    ],
    "volume": 15279307442787
  },
  {
    "ts": 1621814400,
    "supply": 80593356076644,
    "trustlines": {
      "total": 14231,
      "authorized": 14231,
      "funded": 5283
    },
    "payments": 438,
    "payments_amount": 68969209518913,
    "trades": 3430,
    "traded_amount": 36351257179201,
    "price": [
      2.727628115302846,
      2.77882292216469,
      2.2399086539671442,
      2.289701251090797
    ],
    "volume": 10611480005006
  },
  {
    "ts": 1621900800,
    "supply": 83779786576644,
    "trustlines": {
      "total": 14456,
      "authorized": 14456,
      "funded": 5319
    },
    "payments": 584,
    "payments_amount": 58630603148591,
    "trades": 5663,
    "traded_amount": 18985803164371,
    "price": [
      2.289701251090797,
      2.5018764073110393,
      2.1888128695867586,
      2.3435859621773245
    ],
    "volume": 9336806444902
  },
  {
    "ts": 1621987200,
    "supply": 98889505576644,
    "trustlines": {
      "total": 14809,
      "authorized": 14809,
      "funded": 5396
    },
    "payments": 628,
    "payments_amount": 25910018746052,
    "trades": 4740,
    "traded_amount": 20618058396147,
    "price": [
      2.3435859621773245,
      2.3662772527779006,
      2.12,
      2.25185705763665
    ],
    "volume": 10606329387546
  },
  {
    "ts": 1622073600,
    "supply": 106459097176644,
    "trustlines": {
      "total": 15106,
      "authorized": 15106,
      "funded": 5459
    },
    "payments": 568,
    "payments_amount": 37911377542069,
    "trades": 3136,
    "traded_amount": 21938458574279,
    "price": [
      2.25185705763665,
      2.4372410483663356,
      2.2348740983606556,
      2.4016638752575346
    ],
    "volume": 6923803358539
  },
  {
    "ts": 1622160000,
    "supply": 86058443376644,
    "trustlines": {
      "total": 15363,
      "authorized": 15363,
      "funded": 5508
    },
    "payments": 583,
    "payments_amount": 42976837945120,
    "trades": 5291,
    "traded_amount": 14915632836496,
    "price": [
      2.4016638752575346,
      2.7346448690774303,
      2.343132857121124,
      2.526597764026719
    ],
    "volume": 12195162187333
  },
  {
    "ts": 1622246400,
    "supply": 82641724376644,
    "trustlines": {
      "total": 15543,
      "authorized": 15543,
      "funded": 5529
    },
    "payments": 334,
    "payments_amount": 23029370807467,
    "trades": 3860,
    "traded_amount": 13626316001270,
    "price": [
      2.526597764026719,
      2.9718544064353525,
      2.4572718932445414,
      2.7222219070406526
    ],
    "volume": 10462038788449
  },
  {
    "ts": 1622332800,
    "supply": 90591079576644,
    "trustlines": {
      "total": 15756,
      "authorized": 15756,
      "funded": 5568
    },
    "payments": 371,
    "payments_amount": 26566184229725,
    "trades": 4039,
    "traded_amount": 20510641360723,
    "price": [
      2.7222219070406526,
      2.8823316397595242,
      2.5033322505267255,
      2.681114839238097
    ],
    "volume": 9230405852103
  },
  {
    "ts": 1622419200,
    "supply": 92800562476644,
    "trustlines": {
      "total": 15931,
      "authorized": 15931,
      "funded": 5624
    },
    "payments": 413,
    "payments_amount": 7662228022339,
    "trades": 3788,
    "traded_amount": 5436343499470,
    "price": [
      2.681114839238097,
      2.73528661991795,
      2.4551367287187884,
      2.4993204403296594
    ],
    "volume": 4292328771094
  },
  {
    "ts": 1622505600,
    "supply": 84896592276644,
    "trustlines": {
      "total": 16094,
      "authorized": 16094,
      "funded": 5664
    },
    "payments": 420,
    "payments_amount": 21259137922450,
    "trades": 3792,
    "traded_amount": 7769402788125,
    "price": [
      2.4993204403296594,
      2.617129364927622,
      2.231098984578112,
      2.398196590697826
    ],
    "volume": 9581733415721
  },
  {
    "ts": 1622592000,
    "supply": 94558852176644,
    "trustlines": {
      "total": 16270,
      "authorized": 16270,
      "funded": 5682
    },
    "payments": 481,
    "payments_amount": 19646206545339,
    "trades": 4036,
    "traded_amount": 16594193740422,
    "price": [
      2.398196590697826,
      2.4617353361544176,
      2.3146874860546904,
      2.3835098128710395
    ],
    "volume": 7269420301447
  },
  {
    "ts": 1622678400,
    "supply": 93772587876644,
    "trustlines": {
      "total": 16428,
      "authorized": 16428,
      "funded": 5703
    },
    "payments": 503,
    "payments_amount": 22163098991395,
    "trades": 2475,
    "traded_amount": 10962661077791,
    "price": [
      2.3835098128710395,
      2.425680748776149,
      2.312513805762131,
      2.368212371444721
    ],
    "volume": 2147777853158
  },
  {
    "ts": 1622764800,
    "supply": 105110052176644,
    "trustlines": {
      "total": 16592,
      "authorized": 16592,
      "funded": 5708
    },
    "payments": 325,
    "payments_amount": 19912228463015,
    "trades": 3991,
    "traded_amount": 17891180817187,
    "price": [
      2.368212371444721,
      2.698523905991395,
      2.3590338984523536,
      2.600232460781994
    ],
    "volume": 7345668525369
  },
  {
    "ts": 1622851200,
    "supply": 104357180676644,
    "trustlines": {
      "total": 16749,
      "authorized": 16749,
      "funded": 5721
    },
    "payments": 261,
    "payments_amount": 3543483521242,
    "trades": 2553,
    "traded_amount": 3578574752676,
    "price": [
      2.600232460781994,
      2.7408862107615946,
      2.4781136466666664,
      2.6711985541772774
    ],
    "volume": 6534821198386
  },
  {
    "ts": 1622937600,
    "supply": 104357180676644,
    "trustlines": {
      "total": 16884,
      "authorized": 16884,
      "funded": 5745
    },
    "payments": 345,
    "payments_amount": 590084877540,
    "trades": 1918,
    "traded_amount": 939578950153,
    "price": [
      2.6711985541772774,
      2.688056564987704,
      2.575770408177297,
      2.6450884552595277
    ],
    "volume": 1954071039801
  },
  {
    "ts": 1623024000,
    "supply": 112699509476638,
    "trustlines": {
      "total": 17039,
      "authorized": 17039,
      "funded": 5765
    },
    "payments": 933,
    "payments_amount": 52117259202639,
    "trades": 2988,
    "traded_amount": 12879908201588,
    "price": [
      2.6450884552595277,
      2.919417453335197,
      2.567871857148442,
      2.887752162757096
    ],
    "volume": 5542290362830
  },
  {
    "ts": 1623110400,
    "supply": 120633098076638,
    "trustlines": {
      "total": 17194,
      "authorized": 17194,
      "funded": 5796
    },
    "payments": 1240,
    "payments_amount": 43590563439717,
    "trades": 4289,
    "traded_amount": 28324828786193,
    "price": [
      2.887752162757096,
      3.1564456599497337,
      2.8187383651217592,
      2.851345543624587
    ],
    "volume": 7757170580035
  },
  {
    "ts": 1623196800,
    "supply": 110862625776638,
    "trustlines": {
      "total": 17325,
      "authorized": 17325,
      "funded": 5823
    },
    "payments": 759,
    "payments_amount": 42401500264053,
    "trades": 3662,
    "traded_amount": 15098996110685,
    "price": [
      2.851345543624587,
      3.075617122575645,
      2.7514403253491606,
      2.752818126435497
    ],
    "volume": 5381680540211
  },
  {
    "ts": 1623283200,
    "supply": 110582327576638,
    "trustlines": {
      "total": 17482,
      "authorized": 17482,
      "funded": 5836
    },
    "payments": 772,
    "payments_amount": 22117611324862,
    "trades": 3002,
    "traded_amount": 12286484637980,
    "price": [
      2.752818126435497,
      3.030303030309233,
      2.7327697546775647,
      2.954427386561095
    ],
    "volume": 4431393219270
  },
  {
    "ts": 1623369600,
    "supply": 111278106776638,
    "trustlines": {
      "total": 17667,
      "authorized": 17667,
      "funded": 5862
    },
    "payments": 535,
    "payments_amount": 39378567265081,
    "trades": 2699,
    "traded_amount": 17235224115320,
    "price": [
      2.954427386561095,
      3.1845219022483584,
      2.9321532991627763,
      3.1364500964972026
    ],
    "volume": 5632251547925
  },
  {
    "ts": 1623456000,
    "supply": 110686478976638,
    "trustlines": {
      "total": 17788,
      "authorized": 17788,
      "funded": 5881
    },
    "payments": 664,
    "payments_amount": 9916349602641,
    "trades": 3324,
    "traded_amount": 5877725329748,
    "price": [
      3.1364500964972026,
      3.378264253368155,
      2.9096153415012465,
      3.046603854389722
    ],
    "volume": 5228739909544
  },
  {
    "ts": 1623542400,
    "supply": 113898766466854,
    "trustlines": {
      "total": 17920,
      "authorized": 17920,
      "funded": 5914
    },
    "payments": 622,
    "payments_amount": 9749140342851,
    "trades": 3495,
    "traded_amount": 7953876731154,
    "price": [
      3.046603854389722,
      3.2163048049841683,
      2.877241565225835,
      2.9175279419120246
    ],
    "volume": 4844918844882
  },
  {
    "ts": 1623628800,
    "supply": 94385294666854,
    "trustlines": {
      "total": 18061,
      "authorized": 18061,
      "funded": 5945
    },
    "payments": 509,
    "payments_amount": 48546792043950,
    "trades": 2488,
    "traded_amount": 15663920094071,
    "price": [
      2.9175279419120246,
      3.0625,
      2.873563211595009,
      2.902917457835205
    ],
    "volume": 3399298289050
  },
  {
    "ts": 1623715200,
    "supply": 91770315766854,
    "trustlines": {
      "total": 18223,
      "authorized": 18223,
      "funded": 5936
    },
    "payments": 507,
    "payments_amount": 22316630351715,
    "trades": 2063,
    "traded_amount": 7989370822863,
    "price": [
      2.902917457835205,
      3.032710419758142,
      2.856999167360533,
      3.011862076229335
    ],
    "volume": 4261797697376
  },
  {
    "ts": 1623801600,
    "supply": 98495573466854,
    "trustlines": {
      "total": 18378,
      "authorized": 18378,
      "funded": 5950
    },
    "payments": 522,
    "payments_amount": 51220799929369,
    "trades": 2502,
    "traded_amount": 21328384214574,
    "price": [
      3.011862076229335,
      3.1862056335595232,
      2.938276836158192,
      3.154718267837354
    ],
    "volume": 3258566769145
  },
  {
    "ts": 1623888000,
    "supply": 98047099866854,
    "trustlines": {
      "total": 18510,
      "authorized": 18510,
      "funded": 5956
    },
    "payments": 428,
    "payments_amount": 29603878259251,
    "trades": 1810,
    "traded_amount": 7650605301524,
    "price": [
      3.154718267837354,
      3.225198870585844,
      3.0303030303030303,
      3.16641211163216
    ],
    "volume": 1799625775552
  },
  {
    "ts": 1623974400,
    "supply": 128997121361932,
    "trustlines": {
      "total": 18667,
      "authorized": 18667,
      "funded": 5968
    },
    "payments": 346,
    "payments_amount": 96811179202335,
    "trades": 2051,
    "traded_amount": 58902129795346,
    "price": [
      3.16641211163216,
      3.508771933092709,
      3.129032258064516,
      3.371498075539971
    ],
    "volume": 2209212427241
  },
  {
    "ts": 1624060800,
    "supply": 128996685353920,
    "trustlines": {
      "total": 18797,
      "authorized": 18797,
      "funded": 5984
    },
    "payments": 438,
    "payments_amount": 2476356257696,
    "trades": 3069,
    "traded_amount": 2082227668847,
    "price": [
      3.371498075539971,
      3.5264841316761486,
      3.263388375379265,
      3.4216959798994977
    ],
    "volume": 6357771042070
  },
  {
    "ts": 1624147200,
    "supply": 117278356753920,
    "trustlines": {
      "total": 18944,
      "authorized": 18944,
      "funded": 6009
    },
    "payments": 453,
    "payments_amount": 20372715236576,
    "trades": 2300,
    "traded_amount": 4695611870759,
    "price": [
      3.4216959798994977,
      3.7305551660736462,
      3.3510936141070724,
      3.3593482397027397
    ],
    "volume": 3563622866841
  },
  {
    "ts": 1624233600,
    "supply": 111171171853920,
    "trustlines": {
      "total": 19117,
      "authorized": 19117,
      "funded": 6030
    },
    "payments": 583,
    "payments_amount": 67675616398302,
    "trades": 2969,
    "traded_amount": 30514257217513,
    "price": [
      3.3593482397027397,
      4.250487163933764,
      3.318003581020591,
      4.250487163933764
    ],
    "volume": 13778317244388
  },
  {
    "ts": 1624320000,
    "supply": 105219413653920,
    "trustlines": {
      "total": 19334,
      "authorized": 19334,
      "funded": 6068
    },
    "payments": 1136,
    "payments_amount": 149335473689320,
    "trades": 5924,
    "traded_amount": 67679721954075,
    "price": [
      4.250487163933764,
      5.0111498609343395,
      3.857059863945578,
      4.255319143582436
    ],
    "volume": 21190134622852
  },
  {
    "ts": 1624406400,
    "supply": 107845193653920,
    "trustlines": {
      "total": 19518,
      "authorized": 19518,
      "funded": 6088
    },
    "payments": 639,
    "payments_amount": 75814309980360,
    "trades": 2728,
    "traded_amount": 42145986123841,
    "price": [
      4.255319143582436,
      4.451765098795416,
      3.7313432773583766,
      3.922872867300744
    ],
    "volume": 13694601586341
  },
  {
    "ts": 1624492800,
    "supply": 107036157053920,
    "trustlines": {
      "total": 19704,
      "authorized": 19704,
      "funded": 6122
    },
    "payments": 527,
    "payments_amount": 40833873461204,
    "trades": 2341,
    "traded_amount": 11261407691009,
    "price": [
      3.922872867300744,
      4.115104875630786,
      3.676470588013677,
      3.748369716066091
    ],
    "volume": 4107943045686
  },
  {
    "ts": 1624579200,
    "supply": 81167739853920,
    "trustlines": {
      "total": 19839,
      "authorized": 19839,
      "funded": 6138
    },
    "payments": 573,
    "payments_amount": 99928866108343,
    "trades": 2746,
    "traded_amount": 24875239478718,
    "price": [
      3.748369716066091,
      4.150000000062066,
      3.654751648695981,
      4.1310349090844865
    ],
    "volume": 4728760427723
  },
  {
    "ts": 1624665600,
    "supply": 112793173053920,
    "trustlines": {
      "total": 19967,
      "authorized": 19967,
      "funded": 6139
    },
    "payments": 268,
    "payments_amount": 37495109110850,
    "trades": 1963,
    "traded_amount": 35119112514373,
    "price": [
      4.1310349090844865,
      4.389644784164559,
      4.04013706701259,
      4.04013706701259
    ],
    "volume": 2921107808285
  },
  {
    "ts": 1624752000,
    "supply": 113306979753920,
    "trustlines": {
      "total": 20105,
      "authorized": 20105,
      "funded": 6148
    },
    "payments": 209,
    "payments_amount": 10213541352542,
    "trades": 1952,
    "traded_amount": 6209803097619,
    "price": [
      4.04013706701259,
      4.177795614433363,
      3.8741854340229565,
      3.906249998231075
    ],
    "volume": 3386665644930
  },
  {
    "ts": 1624838400,
    "supply": 107785729053920,
    "trustlines": {
      "total": 20262,
      "authorized": 20262,
      "funded": 6165
    },
    "payments": 468,
    "payments_amount": 34608573851517,
    "trades": 2169,
    "traded_amount": 11513127376477,
    "price": [
      3.906249998231075,
      3.9631218639578827,
      3.746,
      3.8121646570743404
    ],
    "volume": 6800125103380
  },
  {
    "ts": 1624924800,
    "supply": 109363832153920,
    "trustlines": {
      "total": 20396,
      "authorized": 20396,
      "funded": 6178
    },
    "payments": 429,
    "payments_amount": 6725998567479,
    "trades": 3965,
    "traded_amount": 5282936066710,
    "price": [
      3.8121646570743404,
      3.8244796101830123,
      3.41119084,
      3.5641805562931625
    ],
    "volume": 6824071026358
  },
  {
    "ts": 1625011200,
    "supply": 89808040053920,
    "trustlines": {
      "total": 19683,
      "authorized": 19683,
      "funded": 6189
    },
    "payments": 361,
    "payments_amount": 40785416175642,
    "trades": 2986,
    "traded_amount": 12915159628655,
    "price": [
      3.5641805562931625,
      3.7985564802607983,
      3.507831195764212,
      3.5426814347822
    ],
    "volume": 14971846380875
  },
  {
    "ts": 1625097600,
    "supply": 94489433953920,
    "trustlines": {
      "total": 19866,
      "authorized": 19866,
      "funded": 6244
    },
    "payments": 583,
    "payments_amount": 28243093597821,
    "trades": 2865,
    "traded_amount": 18483916313080,
    "price": [
      3.5426814347822,
      3.8475202177414833,
      3.5371559031745923,
      3.711616802919708
    ],
    "volume": 8240526378227
  },
  {
    "ts": 1625184000,
    "supply": 112527378453920,
    "trustlines": {
      "total": 19999,
      "authorized": 19999,
      "funded": 6262
    },
    "payments": 257,
    "payments_amount": 26053111670517,
    "trades": 2064,
    "traded_amount": 22577167737452,
    "price": [
      3.711616802919708,
      3.968060203295274,
      3.670773542843776,
      3.8189917523376247
    ],
    "volume": 2798443204684
  },
  {
    "ts": 1625270400,
    "supply": 112527378453920,
    "trustlines": {
      "total": 20147,
      "authorized": 20147,
      "funded": 6264
    },
    "payments": 287,
    "payments_amount": 776304113991,
    "trades": 1883,
    "traded_amount": 579110768591,
    "price": [
      3.8189917523376247,
      3.8590248354402323,
      3.715971454123099,
      3.8048990367588735
    ],
    "volume": 1580577181087
  },
  {
    "ts": 1625356800,
    "supply": 112527377653920,
    "trustlines": {
      "total": 20302,
      "authorized": 20302,
      "funded": 6264
    },
    "payments": 287,
    "payments_amount": 324116086683,
    "trades": 1552,
    "traded_amount": 1942236412353,
    "price": [
      3.8048990367588735,
      3.855993223210504,
      3.6806505509208947,
      3.726285758183152
    ],
    "volume": 1894255243587
  },
  {
    "ts": 1625443200,
    "supply": 110977328153920,
    "trustlines": {
      "total": 20452,
      "authorized": 20452,
      "funded": 6276
    },
    "payments": 264,
    "payments_amount": 9899971006450,
    "trades": 1684,
    "traded_amount": 1095577647209,
    "price": [
      3.726285758183152,
      3.99082353345775,
      3.726285758183152,
      3.9237817213827975
    ],
    "volume": 2652426367786
  },
  {
    "ts": 1625529600,
    "supply": 113138948853920,
    "trustlines": {
      "total": 20593,
      "authorized": 20593,
      "funded": 6288
    },
    "payments": 349,
    "payments_amount": 30797114780618,
    "trades": 1933,
    "traded_amount": 7008496106521,
    "price": [
      3.9237817213827975,
      3.9725944532970634,
      3.745318352059925,
      3.8513365384615383
    ],
    "volume": 3428791183907
  },
  {
    "ts": 1625616000,
    "supply": 117061629753920,
    "trustlines": {
      "total": 20775,
      "authorized": 20775,
      "funded": 6300
    },
    "payments": 406,
    "payments_amount": 12365508666742,
    "trades": 1932,
    "traded_amount": 4785456313707,
    "price": [
      3.8513365384615383,
      3.9175565013903415,
      3.775325898784816,
      3.8903309817473533
    ],
    "volume": 2351729573301
  },
  {
    "ts": 1625702400,
    "supply": 127746587752720,
    "trustlines": {
      "total": 20979,
      "authorized": 20979,
      "funded": 6311
    },
    "payments": 371,
    "payments_amount": 29779310912421,
    "trades": 2525,
    "traded_amount": 15662754582056,
    "price": [
      3.8903309817473533,
      4.1928606,
      3.8766380160631253,
      4.119727253088601
    ],
    "volume": 9488231148612
  },
  {
    "ts": 1625788800,
    "supply": 122245978152720,
    "trustlines": {
      "total": 21168,
      "authorized": 21168,
      "funded": 6320
    },
    "payments": 356,
    "payments_amount": 17021222099075,
    "trades": 2437,
    "traded_amount": 2468375659455,
    "price": [
      4.119727253088601,
      4.290591331462892,
      4.0033275658420875,
      4.08063990832647
    ],
    "volume": 4824729605502
  },
  {
    "ts": 1625875200,
    "supply": 122397464152720,
    "trustlines": {
      "total": 21369,
      "authorized": 21369,
      "funded": 6339
    },
    "payments": 321,
    "payments_amount": 2625875473560,
    "trades": 2120,
    "traded_amount": 1667807567819,
    "price": [
      4.08063990832647,
      4.1990211793609244,
      3.966924973622258,
      4.109262263679592
    ],
    "volume": 4323322811761
  },
  {
    "ts": 1625961600,
    "supply": 122397464152718,
    "trustlines": {
      "total": 21594,
      "authorized": 21594,
      "funded": 6357
    },
    "payments": 371,
    "payments_amount": 2763650467961,
    "trades": 2215,
    "traded_amount": 1310447776912,
    "price": [
      4.109262263679592,
      4.177143953727866,
      4.030128787773182,
      4.105643633462957
    ],
    "volume": 4752579801317
  },
  {
    "ts": 1626048000,
    "supply": 126325487452718,
    "trustlines": {
      "total": 21807,
      "authorized": 21807,
      "funded": 6067
    },
    "payments": 766,
    "payments_amount": 11683334014046,
    "trades": 1989,
    "traded_amount": 10988694903551,
    "price": [
      4.105643633462957,
      4.3708697021675,
      3.9399999998051207,
      4.19438323848959
    ],
    "volume": 23375652172927
  },
  {
    "ts": 1626134400,
    "supply": 123970748352718,
    "trustlines": {
      "total": 22021,
      "authorized": 22021,
      "funded": 6092
    },
    "payments": 376,
    "payments_amount": 9243160056392,
    "trades": 1985,
    "traded_amount": 3970762678086,
    "price": [
      4.19438323848959,
      4.398524229852461,
      4.141308051642325,
      4.3136411373665435
    ],
    "volume": 5655798848132
  },
  {
    "ts": 1626220800,
    "supply": 134403252352718,
    "trustlines": {
      "total": 22252,
      "authorized": 22252,
      "funded": 6130
    },
    "payments": 368,
    "payments_amount": 15635819177090,
    "trades": 3261,
    "traded_amount": 13624883014504,
    "price": [
      4.3136411373665435,
      4.579962596668756,
      4.096764662905134,
      4.2012398170559315
    ],
    "volume": 8520428371767
  },
  {
    "ts": 1626307200,
    "supply": 134403251952718,
    "trustlines": {
      "total": 22455,
      "authorized": 22455,
      "funded": 6145
    },
    "payments": 576,
    "payments_amount": 2491762920114,
    "trades": 2876,
    "traded_amount": 2664733377403,
    "price": [
      4.2012398170559315,
      4.428739376542718,
      4.119654295960858,
      4.1682523931964806
    ],
    "volume": 9136161344119
  },
  {
    "ts": 1626393600,
    "supply": 134403251552718,
    "trustlines": {
      "total": 22655,
      "authorized": 22655,
      "funded": 6167
    },
    "payments": 414,
    "payments_amount": 3489270643481,
    "trades": 3529,
    "traded_amount": 2192209156331,
    "price": [
      4.1682523931964806,
      4.292148806529152,
      3.8770368151879686,
      4.250128566239619
    ],
    "volume": 7266814057173
  },
  {
    "ts": 1626480000,
    "supply": 134403251552718,
    "trustlines": {
      "total": 22853,
      "authorized": 22853,
      "funded": 6163
    },
    "payments": 274,
    "payments_amount": 1723697891437,
    "trades": 1699,
    "traded_amount": 656917925439,
    "price": [
      4.250128566239619,
      4.377810593843638,
      4.209857660824591,
      4.318385836835879
    ],
    "volume": 1893020442625
  },
  {
    "ts": 1626566400,
    "supply": 134403251552718,
    "trustlines": {
      "total": 23118,
      "authorized": 23118,
      "funded": 6179
    },
    "payments": 292,
    "payments_amount": 455882177571,
    "trades": 1533,
    "traded_amount": 582261767068,
    "price": [
      4.318385836835879,
      4.404103050980029,
      4.162968881095154,
      4.322408414204368
    ],
    "volume": 2163167951653
  },
  {
    "ts": 1626652800,
    "supply": 120729670852718,
    "trustlines": {
      "total": 23356,
      "authorized": 23356,
      "funded": 6182
    },
    "payments": 436,
    "payments_amount": 38329622994396,
    "trades": 2386,
    "traded_amount": 6268056313316,
    "price": [
      4.322408414204368,
      4.735297349025471,
      4.322408414204368,
      4.727113519755755
    ],
    "volume": 27035989074236
  },
  {
    "ts": 1626739200,
    "supply": 119463153052718,
    "trustlines": {
      "total": 23535,
      "authorized": 23535,
      "funded": 6194
    },
    "payments": 449,
    "payments_amount": 5628078566283,
    "trades": 3004,
    "traded_amount": 3598243461645,
    "price": [
      4.727113519755755,
      5.033143107897126,
      4.657238608781654,
      4.707154403794911
    ],
    "volume": 7771859669082
  },
  {
    "ts": 1626825600,
    "supply": 125915268352718,
    "trustlines": {
      "total": 23727,
      "authorized": 23727,
      "funded": 6219
    },
    "payments": 467,
    "payments_amount": 15088054682045,
    "trades": 2553,
    "traded_amount": 11600930431186,
    "price": [
      4.707154403794911,
      4.855050652309293,
      4.356876657032027,
      4.4114130392241275
    ],
    "volume": 5653274636248
  },
  {
    "ts": 1626912000,
    "supply": 118498920452718,
    "trustlines": {
      "total": 23989,
      "authorized": 23989,
      "funded": 6265
    },
    "payments": 410,
    "payments_amount": 8553972063882,
    "trades": 3326,
    "traded_amount": 1752585586371,
    "price": [
      4.4114130392241275,
      4.477917019098072,
      3.757209139520379,
      3.809763366558392
    ],
    "volume": 6167815037038
  },
  {
    "ts": 1626998400,
    "supply": 117846997152718,
    "trustlines": {
      "total": 24246,
      "authorized": 24246,
      "funded": 6287
    },
    "payments": 440,
    "payments_amount": 4265614090883,
    "trades": 3070,
    "traded_amount": 3782037501038,
    "price": [
      3.809763366558392,
      4.038223181800008,
      3.610918644399649,
      3.723728622612429
    ],
    "volume": 12066753448483
  },
  {
    "ts": 1627084800,
    "supply": 117846997152718,
    "trustlines": {
      "total": 24487,
      "authorized": 24487,
      "funded": 6284
    },
    "payments": 226,
    "payments_amount": 3026203591782,
    "trades": 1725,
    "traded_amount": 2898323128674,
    "price": [
      3.723728622612429,
      3.9221458802016826,
      3.695419546893611,
      3.773832756803952
    ],
    "volume": 10610433173522
  },
  {
    "ts": 1627171200,
    "supply": 117526811052718,
    "trustlines": {
      "total": 24754,
      "authorized": 24754,
      "funded": 6293
    },
    "payments": 386,
    "payments_amount": 928198204171,
    "trades": 1831,
    "traded_amount": 1178990208870,
    "price": [
      3.773832756803952,
      3.9568013258853982,
      3.76469783492903,
      3.842375191777129
    ],
    "volume": 4217194124377
  },
  {
    "ts": 1627257600,
    "supply": 117387931752718,
    "trustlines": {
      "total": 25071,
      "authorized": 25071,
      "funded": 6314
    },
    "payments": 584,
    "payments_amount": 11416686276449,
    "trades": 3298,
    "traded_amount": 7180140909768,
    "price": [
      3.842375191777129,
      3.9023504273504273,
      3.5129944104094584,
      3.862715977796002
    ],
    "volume": 10035693366129
  },
  {
    "ts": 1627344000,
    "supply": 118322260052718,
    "trustlines": {
      "total": 25453,
      "authorized": 25453,
      "funded": 6328
    },
    "payments": 384,
    "payments_amount": 9736342459670,
    "trades": 2324,
    "traded_amount": 4884021446732,
    "price": [
      3.862715977796002,
      3.9760660338041878,
      3.71504309059541,
      3.8058856014685967
    ],
    "volume": 3135473543029
  },
  {
    "ts": 1627430400,
    "supply": 119046776852718,
    "trustlines": {
      "total": 25746,
      "authorized": 25746,
      "funded": 6364
    },
    "payments": 458,
    "payments_amount": 6009550710558,
    "trades": 3681,
    "traded_amount": 6586624581467,
    "price": [
      3.8058856014685967,
      3.886386374888998,
      3.572602426511568,
      3.7133925058706763
    ],
    "volume": 13919138850231
  },
  {
    "ts": 1627516800,
    "supply": 101911357952718,
    "trustlines": {
      "total": 26091,
      "authorized": 26091,
      "funded": 6387
    },
    "payments": 380,
    "payments_amount": 44998491330449,
    "trades": 1934,
    "traded_amount": 14006990034184,
    "price": [
      3.7133925058706763,
      3.8275862068965516,
      3.6378518106716444,
      3.6926683058093386
    ],
    "volume": 3240359526597
  },
  {
    "ts": 1627603200,
    "supply": 103328418952718,
    "trustlines": {
      "total": 26426,
      "authorized": 26426,
      "funded": 6404
    },
    "payments": 466,
    "payments_amount": 7717989628978,
    "trades": 2943,
    "traded_amount": 5640582490615,
    "price": [
      3.6926683058093386,
      3.8124860458245116,
      3.5134812273603235,
      3.5800012812734465
    ],
    "volume": 4802216297444
  },
  {
    "ts": 1627689600,
    "supply": 102073770552718,
    "trustlines": {
      "total": 26755,
      "authorized": 26755,
      "funded": 6411
    },
    "payments": 372,
    "payments_amount": 2104474098732,
    "trades": 2453,
    "traded_amount": 1326639984649,
    "price": [
      3.5800012812734465,
      3.650000551684134,
      3.4533515,
      3.513281756618734
    ],
    "volume": 4264500466997
  },
  {
    "ts": 1627776000,
    "supply": 102446259752718,
    "trustlines": {
      "total": 27058,
      "authorized": 27058,
      "funded": 6410
    },
    "payments": 400,
    "payments_amount": 6390169772958,
    "trades": 3127,
    "traded_amount": 4170957006225,
    "price": [
      3.513281756618734,
      3.6650839569945295,
      3.344129278606753,
      3.636622310904027
    ],
    "volume": 4181036670524
  },
  {
    "ts": 1627862400,
    "supply": 102467487752718,
    "trustlines": {
      "total": 27382,
      "authorized": 27382,
      "funded": 6422
    },
    "payments": 515,
    "payments_amount": 11589726758268,
    "trades": 2899,
    "traded_amount": 5796023034677,
    "price": [
      3.636622310904027,
      3.7243947925117538,
      3.5132355102040815,
      3.634413794893648
    ],
    "volume": 5866133783080
  },
  {
    "ts": 1627948800,
    "supply": 107552970552443,
    "trustlines": {
      "total": 27719,
      "authorized": 27719,
      "funded": 6456
    },
    "payments": 504,
    "payments_amount": 15851020516338,
    "trades": 2416,
    "traded_amount": 8344233525985,
    "price": [
      3.634413794893648,
      3.7840088012686426,
      3.593765059934536,
      3.675502255545469
    ],
    "volume": 3722264033584
  },
  {
    "ts": 1628035200,
    "supply": 104918090152443,
    "trustlines": {
      "total": 27642,
      "authorized": 27642,
      "funded": 6467
    },
    "payments": 745,
    "payments_amount": 21807118108226,
    "trades": 3242,
    "traded_amount": 4162113290114,
    "price": [
      3.675502255545469,
      3.7807115885388973,
      3.5175860363912195,
      3.568367936510196
    ],
    "volume": 4674042902604
  },
  {
    "ts": 1628121600,
    "supply": 101024877452443,
    "trustlines": {
      "total": 27890,
      "authorized": 27890,
      "funded": 6469
    },
    "payments": 976,
    "payments_amount": 7564799380506,
    "trades": 3340,
    "traded_amount": 3544746377261,
    "price": [
      3.568367936510196,
      3.8095238095238093,
      3.533270507344557,
      3.560806926780197
    ],
    "volume": 8295663126624
  },
  {
    "ts": 1628208000,
    "supply": 101129975352443,
    "trustlines": {
      "total": 28173,
      "authorized": 28173,
      "funded": 6480
    },
    "payments": 331,
    "payments_amount": 10881607176444,
    "trades": 1837,
    "traded_amount": 6042814395574,
    "price": [
      3.560806926780197,
      3.662061147634321,
      3.494999996957933,
      3.532908304836993
    ],
    "volume": 4217850779000
  },
  {
    "ts": 1628294400,
    "supply": 102294224952443,
    "trustlines": {
      "total": 28457,
      "authorized": 28457,
      "funded": 6509
    },
    "payments": 1112,
    "payments_amount": 8265941921397,
    "trades": 5258,
    "traded_amount": 5529333148878,
    "price": [
      3.532908304836993,
      3.550791756362945,
      3.2223762021629128,
      3.270154749926974
    ],
    "volume": 7229761214031
  },
  {
    "ts": 1628380800,
    "supply": 102192385752443,
    "trustlines": {
      "total": 28774,
      "authorized": 28774,
      "funded": 6529
    },
    "payments": 508,
    "payments_amount": 8532540035080,
    "trades": 3713,
    "traded_amount": 4980141197100,
    "price": [
      3.270154749926974,
      3.514786932381947,
      3.206642416854196,
      3.4732168080544747
    ],
    "volume": 4340993061044
  },
  {
    "ts": 1628467200,
    "supply": 98846953952443,
    "trustlines": {
      "total": 29068,
      "authorized": 29068,
      "funded": 6555
    },
    "payments": 769,
    "payments_amount": 14469075427459,
    "trades": 4496,
    "traded_amount": 2770795981064,
    "price": [
      3.4732168080544747,
      3.576599416679459,
      3.2764980474397047,
      3.3715146666666667
    ],
    "volume": 5741591300861
  },
  {
    "ts": 1628553600,
    "supply": 99392210452443,
    "trustlines": {
      "total": 29358,
      "authorized": 29358,
      "funded": 6594
    },
    "payments": 1042,
    "payments_amount": 15287052198162,
    "trades": 3250,
    "traded_amount": 6124746722651,
    "price": [
      3.3715146666666667,
      3.397445115205836,
      3.1844770644681923,
      3.2290824066666666
    ],
    "volume": 6328414789355
  },
  {
    "ts": 1628640000,
    "supply": 102954901952441,
    "trustlines": {
      "total": 29628,
      "authorized": 29628,
      "funded": 6618
    },
    "payments": 1125,
    "payments_amount": 9964438398995,
    "trades": 4261,
    "traded_amount": 8123270836032,
    "price": [
      3.2290824066666666,
      3.2300357302242397,
      2.8241805039518204,
      2.974764490411404
    ],
    "volume": 7886551782883
  },
  {
    "ts": 1628726400,
    "supply": 102309290252441,
    "trustlines": {
      "total": 29927,
      "authorized": 29927,
      "funded": 6643
    },
    "payments": 607,
    "payments_amount": 19911596978686,
    "trades": 3281,
    "traded_amount": 11107318245887,
    "price": [
      2.974764490411404,
      3.1790592790225123,
      2.840909090494819,
      3.0636356787814183
    ],
    "volume": 4266140427126
  },
  {
    "ts": 1628812800,
    "supply": 101238029952441,
    "trustlines": {
      "total": 30127,
      "authorized": 30127,
      "funded": 6652
    },
    "payments": 1137,
    "payments_amount": 11660078494522,
    "trades": 3994,
    "traded_amount": 6727533955290,
    "price": [
      3.0636356787814183,
      3.084143769212288,
      2.754051623045243,
      2.7867938299269888
    ],
    "volume": 8557652493805
  },
  {
    "ts": 1628899200,
    "supply": 101555659252441,
    "trustlines": {
      "total": 30312,
      "authorized": 30312,
      "funded": 6688
    },
    "payments": 935,
    "payments_amount": 1833821431483,
    "trades": 5158,
    "traded_amount": 2287283371394,
    "price": [
      2.7867938299269888,
      2.857142857142857,
      2.503409389864885,
      2.544975388421594
    ],
    "volume": 4067777720133
  },
  {
    "ts": 1628985600,
    "supply": 101555659252441,
    "trustlines": {
      "total": 30728,
      "authorized": 30728,
      "funded": 6773
    },
    "payments": 1252,
    "payments_amount": 636370872992,
    "trades": 5767,
    "traded_amount": 2535252739821,
    "price": [
      2.544975388421594,
      2.692502065046702,
      2.4874433987996745,
      2.5515642223846196
    ],
    "volume": 5570922511479
  },
  {
    "ts": 1629072000,
    "supply": 101291188152441,
    "trustlines": {
      "total": 31481,
      "authorized": 31481,
      "funded": 7010
    },
    "payments": 1465,
    "payments_amount": 11932306942049,
    "trades": 6398,
    "traded_amount": 7894608801598,
    "price": [
      2.5515642223846196,
      2.7010667401856328,
      2.439556579702725,
      2.689545735256324
    ],
    "volume": 5605920748772
  },
  {
    "ts": 1629158400,
    "supply": 100918961152441,
    "trustlines": {
      "total": 31895,
      "authorized": 31895,
      "funded": 7094
    },
    "payments": 920,
    "payments_amount": 9931165220269,
    "trades": 6729,
    "traded_amount": 8108428244673,
    "price": [
      2.689545735256324,
      2.9411770389013143,
      2.6099999970567387,
      2.9267047282053653
    ],
    "volume": 8220565834514
  },
  {
    "ts": 1629244800,
    "supply": 100956643052441,
    "trustlines": {
      "total": 32185,
      "authorized": 32185,
      "funded": 7127
    },
    "payments": 824,
    "payments_amount": 6170364312224,
    "trades": 5719,
    "traded_amount": 4826872386281,
    "price": [
      2.9267047282053653,
      3.0718995674461533,
      2.7922712144522923,
      2.917358996287521
    ],
    "volume": 9544489869217
  },
  {
    "ts": 1629331200,
    "supply": 97125603352441,
    "trustlines": {
      "total": 32535,
      "authorized": 32535,
      "funded": 7256
    },
    "payments": 1336,
    "payments_amount": 11823873164899,
    "trades": 7505,
    "traded_amount": 6240040902697,
    "price": [
      2.917358996287521,
      3.04480617585454,
      2.723304863834757,
      2.7346602551020407
    ],
    "volume": 12005854976846
  },
  {
    "ts": 1629417600,
    "supply": 105959952952441,
    "trustlines": {
      "total": 32765,
      "authorized": 32765,
      "funded": 7290
    },
    "payments": 1842,
    "payments_amount": 16168319152764,
    "trades": 9726,
    "traded_amount": 13837663064135,
    "price": [
      2.7346602551020407,
      2.7784125012712795,
      2.5851346071228143,
      2.603619602757169
    ],
    "volume": 5785007805004
  },
  {
    "ts": 1629504000,
    "supply": 97868816152441,
    "trustlines": {
      "total": 32963,
      "authorized": 32963,
      "funded": 7318
    },
    "payments": 1038,
    "payments_amount": 12329327767658,
    "trades": 7051,
    "traded_amount": 3814966094117,
    "price": [
      2.603619602757169,
      2.718653131451206,
      2.60100754706515,
      2.6884197496905515
    ],
    "volume": 4403861871816
  },
  {
    "ts": 1629590400,
    "supply": 99611464052441,
    "trustlines": {
      "total": 33233,
      "authorized": 33233,
      "funded": 7375
    },
    "payments": 767,
    "payments_amount": 3091932550118,
    "trades": 4454,
    "traded_amount": 3669668198369,
    "price": [
      2.6884197496905515,
      2.773978967691467,
      2.576245176450157,
      2.693857452260659
    ],
    "volume": 3494079961072
  },
  {
    "ts": 1629676800,
    "supply": 93740891452441,
    "trustlines": {
      "total": 33519,
      "authorized": 33519,
      "funded": 7407
    },
    "payments": 2007,
    "payments_amount": 8488345715044,
    "trades": 7237,
    "traded_amount": 7876274890712,
    "price": [
      2.693857452260659,
      2.7173210207465197,
      2.5826459620186246,
      2.647253358981085
    ],
    "volume": 13540986236858
  },
  {
    "ts": 1629763200,
    "supply": 100937871552441,
    "trustlines": {
      "total": 33815,
      "authorized": 33815,
      "funded": 7439
    },
    "payments": 5383,
    "payments_amount": 38545764848841,
    "trades": 30173,
    "traded_amount": 29639879628166,
    "price": [
      2.647253358981085,
      2.898910850223253,
      2.6353938769564067,
      2.8545776006101793
    ],
    "volume": 25641980998812
  },
  {
    "ts": 1629849600,
    "supply": 100730671752441,
    "trustlines": {
      "total": 34109,
      "authorized": 34109,
      "funded": 7491
    },
    "payments": 959,
    "payments_amount": 11202892625967,
    "trades": 9029,
    "traded_amount": 10133366100450,
    "price": [
      2.8545776006101793,
      2.9411764728164442,
      2.777981011526866,
      2.8037518246906616
    ],
    "volume": 12347884431447
  },
  {
    "ts": 1629936000,
    "supply": 95462497752441,
    "trustlines": {
      "total": 34383,
      "authorized": 34383,
      "funded": 7513
    },
    "payments": 730,
    "payments_amount": 17601153896143,
    "trades": 7012,
    "traded_amount": 8009613160410,
    "price": [
      2.8037518246906616,
      3.02453550356364,
      2.7623004513770026,
      2.978600050228441
    ],
    "volume": 12826900205743
  },
  {
    "ts": 1630022400,
    "supply": 111452475068713,
    "trustlines": {
      "total": 34589,
      "authorized": 34589,
      "funded": 7575
    },
    "payments": 714,
    "payments_amount": 23191198242827,
    "trades": 5885,
    "traded_amount": 21874257410003,
    "price": [
      2.978600050228441,
      3.0479150710705585,
      2.8138124675024554,
      2.817575544602887
    ],
    "volume": 11488021006611
  },
  {
    "ts": 1630108800,
    "supply": 97839027968713,
    "trustlines": {
      "total": 34817,
      "authorized": 34817,
      "funded": 7587
    },
    "payments": 1103,
    "payments_amount": 14348648287930,
    "trades": 8849,
    "traded_amount": 4190047869237,
    "price": [
      2.817575544602887,
      2.9670295570831957,
      2.7918046443579336,
      2.887669052258357
    ],
    "volume": 5299650533521
  },
  {
    "ts": 1630195200,
    "supply": 99404774668712,
    "trustlines": {
      "total": 35056,
      "authorized": 35056,
      "funded": 7614
    },
    "payments": 2242,
    "payments_amount": 13143121051443,
    "trades": 14142,
    "traded_amount": 10893131004905,
    "price": [
      2.887669052258357,
      2.967963731791576,
      2.8384869238585706,
      2.907168413844632
    ],
    "volume": 11776481293715
  },
  {
    "ts": 1630281600,
    "supply": 114243348868712,
    "trustlines": {
      "total": 35270,
      "authorized": 35270,
      "funded": 7646
    },
    "payments": 782,
    "payments_amount": 27285797374707,
    "trades": 11249,
    "traded_amount": 24628915488641,
    "price": [
      2.907168413844632,
      3.043213453397693,
      2.8910370842713373,
      3.033316914810254
    ],
    "volume": 13701536121894
  },
  {
    "ts": 1630368000,
    "supply": 114582312268712,
    "trustlines": {
      "total": 35530,
      "authorized": 35530,
      "funded": 7689
    },
    "payments": 928,
    "payments_amount": 58211239045613,
    "trades": 14805,
    "traded_amount": 30507859667414,
    "price": [
      3.033316914810254,
      3.066026894074155,
      2.8391191259416733,
      2.9411764705882355
    ],
    "volume": 14355398735552
  },
  {
    "ts": 1630454400,
    "supply": 108951836568711,
    "trustlines": {
      "total": 35736,
      "authorized": 35736,
      "funded": 7718
    },
    "payments": 953,
    "payments_amount": 27279271167480,
    "trades": 8172,
    "traded_amount": 4921399725900,
    "price": [
      2.9411764705882355,
      2.991059413010209,
      2.8089888002237458,
      2.8876696554924868
    ],
    "volume": 7060744152563
  },
  {
    "ts": 1630540800,
    "supply": 125875782168711,
    "trustlines": {
      "total": 36029,
      "authorized": 36029,
      "funded": 7767
    },
    "payments": 2444,
    "payments_amount": 39391100079917,
    "trades": 15635,
    "traded_amount": 26022287093569,
    "price": [
      2.8876696554924868,
      2.8876696554924868,
      2.720951256607426,
      2.7855152808484327
    ],
    "volume": 12761511941090
  },
  {
    "ts": 1630627200,
    "supply": 113350345168711,
    "trustlines": {
      "total": 36254,
      "authorized": 36254,
      "funded": 7801
    },
    "payments": 1536,
    "payments_amount": 32509349144533,
    "trades": 14632,
    "traded_amount": 11774221206796,
    "price": [
      2.7855152808484327,
      2.857142857142857,
      2.6519461038861207,
      2.720348204570185
    ],
    "volume": 6247145587933
  },
  {
    "ts": 1630713600,
    "supply": 113350345168711,
    "trustlines": {
      "total": 36379,
      "authorized": 36379,
      "funded": 7804
    },
    "payments": 1553,
    "payments_amount": 982418218002,
    "trades": 14265,
    "traded_amount": 3701716778786,
    "price": [
      2.720348204570185,
      2.744237104405687,
      2.6164710130895124,
      2.693965525803748
    ],
    "volume": 5869565386409
  },
  {
    "ts": 1630800000,
    "supply": 114233568368711,
    "trustlines": {
      "total": 36581,
      "authorized": 36581,
      "funded": 7847
    },
    "payments": 1295,
    "payments_amount": 8063181753511,
    "trades": 11004,
    "traded_amount": 4628834682595,
    "price": [
      2.693965525803748,
      2.722370401580178,
      2.51570710729058,
      2.522704279399974
    ],
    "volume": 6166864335022
  },
  {
    "ts": 1630886400,
    "supply": 113797721168711,
    "trustlines": {
      "total": 36785,
      "authorized": 36785,
      "funded": 7894
    },
    "payments": 865,
    "payments_amount": 7912488602654,
    "trades": 9507,
    "traded_amount": 6182817051678,
    "price": [
      2.522704279399974,
      2.577100968670914,
      2.3267707402868085,
      2.3666457291243765
    ],
    "volume": 9969582215262
  },
  {
    "ts": 1630972800,
    "supply": 119054792468711,
    "trustlines": {
      "total": 37197,
      "authorized": 37197,
      "funded": 7968
    },
    "payments": 1032,
    "payments_amount": 25021612839962,
    "trades": 21354,
    "traded_amount": 16036823394247,
    "price": [
      2.3666457291243765,
      5.555555555997692,
      2.3544335771038956,
      2.9969866263802003
    ],
    "volume": 21704931605827
  },
  {
    "ts": 1631059200,
    "supply": 122088292968711,
    "trustlines": {
      "total": 37497,
      "authorized": 37497,
      "funded": 8042
    },
    "payments": 673,
    "payments_amount": 41501108343657,
    "trades": 14534,
    "traded_amount": 15215044415345,
    "price": [
      2.9969866263802003,
      3.275568313805629,
      2.917461091035882,
      3.0516221802821963
    ],
    "volume": 9076627909242
  },
  {
    "ts": 1631145600,
    "supply": 111118135568711,
    "trustlines": {
      "total": 37717,
      "authorized": 37717,
      "funded": 8107
    },
    "payments": 535,
    "payments_amount": 29129733196114,
    "trades": 10354,
    "traded_amount": 8830075435709,
    "price": [
      3.0516221802821963,
      3.094603696900929,
      2.941174762637554,
      3.024482274794027
    ],
    "volume": 6816821629682
  },
  {
    "ts": 1631232000,
    "supply": 109162162268711,
    "trustlines": {
      "total": 38064,
      "authorized": 38064,
      "funded": 8197
    },
    "payments": 878,
    "payments_amount": 25469046133696,
    "trades": 10358,
    "traded_amount": 14329394449620,
    "price": [
      3.024482274794027,
      3.2195278360688864,
      2.804278205502671,
      3.1555291084836927
    ],
    "volume": 9870435087554
  },
  {
    "ts": 1631318400,
    "supply": 110492844053004,
    "trustlines": {
      "total": 38284,
      "authorized": 38284,
      "funded": 8249
    },
    "payments": 463,
    "payments_amount": 4915027769310,
    "trades": 7155,
    "traded_amount": 4589658410137,
    "price": [
      3.1555291084836927,
      3.168103448275862,
      3.0077481465552283,
      3.0886097064465607
    ],
    "volume": 8025940395983
  },
  {
    "ts": 1631404800,
    "supply": 110610577753004,
    "trustlines": {
      "total": 38502,
      "authorized": 38502,
      "funded": 8301
    },
    "payments": 474,
    "payments_amount": 4768735226330,
    "trades": 5924,
    "traded_amount": 3349210137683,
    "price": [
      3.0886097064465607,
      3.160337552742616,
      2.9585804555837423,
      3.0120481927710845
    ],
    "volume": 4452781530555
  },
  {
    "ts": 1631491200,
    "supply": 115067522053004,
    "trustlines": {
      "total": 38722,
      "authorized": 38722,
      "funded": 8353
    },
    "payments": 497,
    "payments_amount": 24591499686352,
    "trades": 8677,
    "traded_amount": 7657061678822,
    "price": [
      3.0120481927710845,
      3.2374035592275234,
      2.9607843137254903,
      3.1387339611966536
    ],
    "volume": 5456706412852
  },
  {
    "ts": 1631577600,
    "supply": 123076128953004,
    "trustlines": {
      "total": 38964,
      "authorized": 38964,
      "funded": 8400
    },
    "payments": 656,
    "payments_amount": 14530708878678,
    "trades": 9616,
    "traded_amount": 9872765431590,
    "price": [
      3.1387339611966536,
      3.225806451612903,
      3.0303023523335963,
      3.0370883403540736
    ],
    "volume": 3536808681801
  },
  {
    "ts": 1631664000,
    "supply": 123076128953004,
    "trustlines": {
      "total": 39180,
      "authorized": 39180,
      "funded": 8455
    },
    "payments": 794,
    "payments_amount": 35152003220082,
    "trades": 8429,
    "traded_amount": 4791697891093,
    "price": [
      3.0370883403540736,
      3.0683781237885763,
      2.9239757766669947,
      2.9239767685966433
    ],
    "volume": 11727866665207
  },
  {
    "ts": 1631750400,
    "supply": 106944919353004,
    "trustlines": {
      "total": 39568,
      "authorized": 39568,
      "funded": 8566
    },
    "payments": 1007,
    "payments_amount": 60026372574901,
    "trades": 8532,
    "traded_amount": 8501700983504,
    "price": [
      2.9239767685966433,
      3.094823777317065,
      2.9239767685966433,
      3.0203423480193523
    ],
    "volume": 5559365625270
  },
  {
    "ts": 1631836800,
    "supply": 107843006353001,
    "trustlines": {
      "total": 39786,
      "authorized": 39786,
      "funded": 8593
    },
    "payments": 692,
    "payments_amount": 15277422644162,
    "trades": 7958,
    "traded_amount": 6384909559411,
    "price": [
      3.0203423480193523,
      3.175346690400298,
      3,
      3.150082244538602
    ],
    "volume": 7774992164480
  },
  {
    "ts": 1631923200,
    "supply": 112047871053001,
    "trustlines": {
      "total": 40029,
      "authorized": 40029,
      "funded": 8630
    },
    "payments": 518,
    "payments_amount": 15500660894429,
    "trades": 5609,
    "traded_amount": 6572569588737,
    "price": [
      3.150082244538602,
      3.1779929829138225,
      3.04274703157096,
      3.1055900617994223
    ],
    "volume": 5617386392231
  },
  {
    "ts": 1632009600,
    "supply": 113853602122039,
    "trustlines": {
      "total": 40227,
      "authorized": 40227,
      "funded": 8659
    },
    "payments": 479,
    "payments_amount": 5609961595397,
    "trades": 3930,
    "traded_amount": 3201601363239,
    "price": [
      3.1055900617994223,
      3.2189142826657036,
      3.077702105552302,
      3.2030932187003343
    ],
    "volume": 2645840815421
  },
  {
    "ts": 1632096000,
    "supply": 117513428122039,
    "trustlines": {
      "total": 40562,
      "authorized": 40562,
      "funded": 8722
    },
    "payments": 1319,
    "payments_amount": 34960178657131,
    "trades": 8497,
    "traded_amount": 17913403708843,
    "price": [
      3.2030932187003343,
      3.7037039262399247,
      3.1854588201961502,
      3.554614704478609
    ],
    "volume": 20397318691086
  },
  {
    "ts": 1632182400,
    "supply": 1608159904722039,
    "trustlines": {
      "total": 40791,
      "authorized": 40791,
      "funded": 8771
    },
    "payments": 1149,
    "payments_amount": 3041736589731019,
    "trades": 7228,
    "traded_amount": 1498232989492804,
    "price": [
      3.554614704478609,
      3.8343706177763384,
      3.4883984471390828,
      3.759401256140253
    ],
    "volume": 10321313593108
  },
  {
    "ts": 1632268800,
    "supply": 1620690213222039,
    "trustlines": {
      "total": 40992,
      "authorized": 40992,
      "funded": 8780
    },
    "payments": 1168,
    "payments_amount": 44483726519618,
    "trades": 6872,
    "traded_amount": 21525455247087,
    "price": [
      3.759401256140253,
      3.8378083392997486,
      3.414042407058415,
      3.414042407058415
    ],
    "volume": 4713856878700
  },
  {
    "ts": 1632355200,
    "supply": 1618379019022039,
    "trustlines": {
      "total": 41274,
      "authorized": 41274,
      "funded": 8861
    },
    "payments": 1009,
    "payments_amount": 18109435328045,
    "trades": 5937,
    "traded_amount": 6231908358554,
    "price": [
      3.414042407058415,
      3.472223014683453,
      3.3074339183187282,
      3.3195989160118096
    ],
    "volume": 5591673012958
  },
  {
    "ts": 1632441600,
    "supply": 1614488353222039,
    "trustlines": {
      "total": 41667,
      "authorized": 41667,
      "funded": 8961
    },
    "payments": 1047,
    "payments_amount": 7155499593681,
    "trades": 7193,
    "traded_amount": 3146940365470,
    "price": [
      3.3195989160118096,
      3.712001789969252,
      3.278084887293587,
      3.574501713529519
    ],
    "volume": 5899796946516
  },
  {
    "ts": 1632528000,
    "supply": 1614488337222039,
    "trustlines": {
      "total": 41908,
      "authorized": 41908,
      "funded": 8988
    },
    "payments": 754,
    "payments_amount": 8198397176343,
    "trades": 5895,
    "traded_amount": 5216757125503,
    "price": [
      3.574501713529519,
      3.7459212518861906,
      3.5087699473094123,
      3.6232134107205707
    ],
    "volume": 17139745812201
  },
  {
    "ts": 1632614400,
    "supply": 1614488337222039,
    "trustlines": {
      "total": 42208,
      "authorized": 42208,
      "funded": 9063
    },
    "payments": 684,
    "payments_amount": 7973067850482,
    "trades": 7036,
    "traded_amount": 5751073580642,
    "price": [
      3.6232134107205707,
      3.8446854055646495,
      3.5545331133856433,
      3.6365263448814167
    ],
    "volume": 18931252778303
  },
  {
    "ts": 1632700800,
    "supply": 1615215390122039,
    "trustlines": {
      "total": 42486,
      "authorized": 42486,
      "funded": 9105
    },
    "payments": 715,
    "payments_amount": 25349558555608,
    "trades": 6861,
    "traded_amount": 14545965822737,
    "price": [
      3.6365263448814167,
      3.7688660078167384,
      3.5310449869413003,
      3.7688660009844277
    ],
    "volume": 11161976957328
  },
  {
    "ts": 1632787200,
    "supply": 1620677336322039,
    "trustlines": {
      "total": 42837,
      "authorized": 42837,
      "funded": 9161
    },
    "payments": 674,
    "payments_amount": 28236372388123,
    "trades": 7286,
    "traded_amount": 17413050353224,
    "price": [
      3.7688660009844277,
      4,
      3.70600554471225,
      3.8969333096589986
    ],
    "volume": 4808557068968
  },
  {
    "ts": 1632873600,
    "supply": 1613054836422008,
    "trustlines": {
      "total": 43136,
      "authorized": 43136,
      "funded": 9202
    },
    "payments": 604,
    "payments_amount": 12677470775111,
    "trades": 6189,
    "traded_amount": 3533811476200,
    "price": [
      3.8969333096589986,
      3.915223016861432,
      3.595518058599476,
      3.7254807484540495
    ],
    "volume": 6024338478828
  },
  {
    "ts": 1632960000,
    "supply": 1614429273722008,
    "trustlines": {
      "total": 43331,
      "authorized": 43331,
      "funded": 9224
    },
    "payments": 585,
    "payments_amount": 12855768028188,
    "trades": 6349,
    "traded_amount": 8020748343375,
    "price": [
      3.7254807484540495,
      3.731365569107721,
      3.571455012293358,
      3.606554000894012
    ],
    "volume": 4711749686191
  },
  {
    "ts": 1633046400,
    "supply": 1613927418763648,
    "trustlines": {
      "total": 43613,
      "authorized": 43613,
      "funded": 9312
    },
    "payments": 961,
    "payments_amount": 28227779299071,
    "trades": 12526,
    "traded_amount": 18898954965316,
    "price": [
      3.606554000894012,
      3.640800186550074,
      3.32,
      3.336113427689642
    ],
    "volume": 25243249705728
  },
  {
    "ts": 1633132800,
    "supply": 1614233052963648,
    "trustlines": {
      "total": 43847,
      "authorized": 43847,
      "funded": 9360
    },
    "payments": 811,
    "payments_amount": 5160564091755,
    "trades": 10480,
    "traded_amount": 6438689647089,
    "price": [
      3.336113427689642,
      3.44548386144971,
      3.0612551020657572,
      3.1749516333319416
    ],
    "volume": 15600598012813
  },
  {
    "ts": 1633219200,
    "supply": 1614919437563648,
    "trustlines": {
      "total": 44166,
      "authorized": 44166,
      "funded": 9429
    },
    "payments": 676,
    "payments_amount": 8304551666344,
    "trades": 7370,
    "traded_amount": 7495934230398,
    "price": [
      3.1749516333319416,
      3.2374870013302757,
      3.1015766707211303,
      3.1914494273637937
    ],
    "volume": 13357293378666
  },
  {
    "ts": 1633305600,
    "supply": 1618194745663648,
    "trustlines": {
      "total": 44426,
      "authorized": 44426,
      "funded": 9488
    },
    "payments": 748,
    "payments_amount": 28266428658985,
    "trades": 7637,
    "traded_amount": 16026784595607,
    "price": [
      3.1914494273637937,
      3.3793103448275863,
      3.1694766616041683,
      3.238789280849539
    ],
    "volume": 8319863651048
  },
  {
    "ts": 1633392000,
    "supply": 1624155327963648,
    "trustlines": {
      "total": 44679,
      "authorized": 44679,
      "funded": 9525
    },
    "payments": 647,
    "payments_amount": 20157036429652,
    "trades": 6302,
    "traded_amount": 12966242051825,
    "price": [
      3.238789280849539,
      3.2540992707643692,
      3.125030668625837,
      3.1500858932680877
    ],
    "volume": 6573390151562
  },
  {
    "ts": 1633478400,
    "supply": 1619420511863648,
    "trustlines": {
      "total": 44995,
      "authorized": 44995,
      "funded": 9629
    },
    "payments": 1041,
    "payments_amount": 13769658251191,
    "trades": 10541,
    "traded_amount": 13062821279763,
    "price": [
      3.1500858932680877,
      3.2856283693307216,
      2.7839226990401964,
      2.819579107103205
    ],
    "volume": 31952521200355
  },
  {
    "ts": 1633564800,
    "supply": 1620433048463648,
    "trustlines": {
      "total": 45340,
      "authorized": 45340,
      "funded": 9753
    },
    "payments": 1291,
    "payments_amount": 11734917438525,
    "trades": 9446,
    "traded_amount": 11183747889027,
    "price": [
      2.819579107103205,
      2.9321639852256474,
      2.716284176017361,
      2.89578451588715
    ],
    "volume": 15125679042483
  },
  {
    "ts": 1633651200,
    "supply": 1620851889163648,
    "trustlines": {
      "total": 45700,
      "authorized": 45700,
      "funded": 9799
    },
    "payments": 1078,
    "payments_amount": 14271783525339,
    "trades": 7209,
    "traded_amount": 11231822378251,
    "price": [
      2.89578451588715,
      3.028518324454429,
      2.8406852190906116,
      2.976227299416505
    ],
    "volume": 13015259194025
  },
  {
    "ts": 1633737600,
    "supply": 1620851889163648,
    "trustlines": {
      "total": 46048,
      "authorized": 46048,
      "funded": 9850
    },
    "payments": 997,
    "payments_amount": 4225373971295,
    "trades": 5187,
    "traded_amount": 3160296332096,
    "price": [
      2.976227299416505,
      3.0000290043301105,
      2.728384915714837,
      2.8388052200659373
    ],
    "volume": 8064677313445
  },
  {
    "ts": 1633824000,
    "supply": 1620654165163642,
    "trustlines": {
      "total": 46348,
      "authorized": 46348,
      "funded": 9868
    },
    "payments": 650,
    "payments_amount": 2340338764320,
    "trades": 4452,
    "traded_amount": 2762009391123,
    "price": [
      2.8388052200659373,
      3.03030300544881,
      2.7913307996117083,
      3.0164728488220813
    ],
    "volume": 5424129689551
  },
  {
    "ts": 1633910400,
    "supply": 1620088671063642,
    "trustlines": {
      "total": 46689,
      "authorized": 46689,
      "funded": 9928
    },
    "payments": 708,
    "payments_amount": 3791148443934,
    "trades": 4255,
    "traded_amount": 3166675696529,
    "price": [
      3.0164728488220813,
      3.0674845071504517,
      2.808984161660295,
      2.9069767347825657
    ],
    "volume": 6754192104600
  },
  {
    "ts": 1633996800,
    "supply": 1620737090963642,
    "trustlines": {
      "total": 47035,
      "authorized": 47035,
      "funded": 9951
    },
    "payments": 784,
    "payments_amount": 6391873161066,
    "trades": 4403,
    "traded_amount": 4115055881716,
    "price": [
      2.9069767347825657,
      3.1120632264904122,
      2.9069767347825657,
      3.004001204734495
    ],
    "volume": 9544588136684
  },
  {
    "ts": 1634083200,
    "supply": 1629285409563642,
    "trustlines": {
      "total": 47366,
      "authorized": 47366,
      "funded": 10037
    },
    "payments": 1298,
    "payments_amount": 16004313833032,
    "trades": 6293,
    "traded_amount": 16170714759437,
    "price": [
      3.004001204734495,
      3.0248033743912455,
      2.6722720235755775,
      2.7163529999936524
    ],
    "volume": 18466586993684
  },
  {
    "ts": 1634169600,
    "supply": 1618653957263642,
    "trustlines": {
      "total": 47812,
      "authorized": 47812,
      "funded": 10181
    },
    "payments": 1260,
    "payments_amount": 21207939652854,
    "trades": 6383,
    "traded_amount": 5862076110981,
    "price": [
      2.7163529999936524,
      2.7559335743593767,
      2.631579992717402,
      2.712115623165105
    ],
    "volume": 7313673309946
  },
  {
    "ts": 1634256000,
    "supply": 1622933554663641,
    "trustlines": {
      "total": 48184,
      "authorized": 48184,
      "funded": 10218
    },
    "payments": 1636,
    "payments_amount": 32551325430153,
    "trades": 7922,
    "traded_amount": 16372530210868,
    "price": [
      2.712115623165105,
      2.8384680128674202,
      2.6882011803398798,
      2.7610230414024044
    ],
    "volume": 22599496553082
  },
  {
    "ts": 1634342400,
    "supply": 1622676700063641,
    "trustlines": {
      "total": 48768,
      "authorized": 48768,
      "funded": 10442
    },
    "payments": 2844,
    "payments_amount": 10951910894270,
    "trades": 12487,
    "traded_amount": 13507493702379,
    "price": [
      2.7610230414024044,
      2.7753361812734285,
      2.386634741700107,
      2.534999157541646
    ],
    "volume": 20770230166941
  },
  {
    "ts": 1634428800,
    "supply": 1624154937563641,
    "trustlines": {
      "total": 49149,
      "authorized": 49149,
      "funded": 10473
    },
    "payments": 1501,
    "payments_amount": 9660189371897,
    "trades": 8358,
    "traded_amount": 8712015562685,
    "price": [
      2.534999157541646,
      2.737256497152115,
      2.489421806239026,
      2.610016217081196
    ],
    "volume": 10364172738166
  },
  {
    "ts": 1634515200,
    "supply": 1633454958263641,
    "trustlines": {
      "total": 49476,
      "authorized": 49476,
      "funded": 10516
    },
    "payments": 1140,
    "payments_amount": 47420721199761,
    "trades": 5996,
    "traded_amount": 29626134152329,
    "price": [
      2.610016217081196,
      2.6596055927857694,
      2.5385027403137084,
      2.6309672993832702
    ],
    "volume": 7407342336073
  },
  {
    "ts": 1634601600,
    "supply": 1636983248963641,
    "trustlines": {
      "total": 49832,
      "authorized": 49832,
      "funded": 10579
    },
    "payments": 1921,
    "payments_amount": 45267554047596,
    "trades": 7359,
    "traded_amount": 20770751124873,
    "price": [
      2.6309672993832702,
      2.75,
      2.5974025974025974,
      2.6738182391837366
    ],
    "volume": 19122408126549
  },
  {
    "ts": 1634688000,
    "supply": 1626676592363641,
    "trustlines": {
      "total": 50210,
      "authorized": 50210,
      "funded": 10683
    },
    "payments": 1273,
    "payments_amount": 24239488943655,
    "trades": 6656,
    "traded_amount": 8704742488099,
    "price": [
      2.6738182391837366,
      2.75,
      2.553073747967449,
      2.56441537098363
    ],
    "volume": 15484515817217
  },
  {
    "ts": 1634774400,
    "supply": 1633241284263641,
    "trustlines": {
      "total": 50572,
      "authorized": 50572,
      "funded": 10739
    },
    "payments": 2475,
    "payments_amount": 46064464628985,
    "trades": 9393,
    "traded_amount": 23655117299267,
    "price": [
      2.56441537098363,
      2.725,
      2.517976642381665,
      2.678608709068511
    ],
    "volume": 15931965721429
  },
  {
    "ts": 1634860800,
    "supply": 1632762911563641,
    "trustlines": {
      "total": 50923,
      "authorized": 50923,
      "funded": 10756
    },
    "payments": 1697,
    "payments_amount": 23747110926032,
    "trades": 9702,
    "traded_amount": 9482715587598,
    "price": [
      2.678608709068511,
      2.73224043715847,
      2.619047619047619,
      2.6945263847918715
    ],
    "volume": 7649797224887
  },
  {
    "ts": 1634947200,
    "supply": 1633212944063641,
    "trustlines": {
      "total": 51276,
      "authorized": 51276,
      "funded": 10782
    },
    "payments": 1526,
    "payments_amount": 6891792485115,
    "trades": 9405,
    "traded_amount": 6272111783824,
    "price": [
      2.6945263847918715,
      2.75,
      2.6502065237239045,
      2.650207657894139
    ],
    "volume": 7615251926661
  },
  {
    "ts": 1635033600,
    "supply": 1631557296063641,
    "trustlines": {
      "total": 51643,
      "authorized": 51643,
      "funded": 10796
    },
    "payments": 1647,
    "payments_amount": 5102316657544,
    "trades": 9769,
    "traded_amount": 6166018999245,
    "price": [
      2.650207657894139,
      2.765957446808511,
      2.5951869144447324,
      2.6860203480669886
    ],
    "volume": 12183958829581
  },
  {
    "ts": 1635120000,
    "supply": 1633659163363641,
    "trustlines": {
      "total": 52028,
      "authorized": 52028,
      "funded": 10866
    },
    "payments": 1274,
    "payments_amount": 24112555838364,
    "trades": 9264,
    "traded_amount": 10983777371623,
    "price": [
      2.6860203480669886,
      2.6961816331884996,
      2.5934959349593494,
      2.5974023213016295
    ],
    "volume": 6170226638352
  },
  {
    "ts": 1635206400,
    "supply": 1634858514763641,
    "trustlines": {
      "total": 52352,
      "authorized": 52352,
      "funded": 10900
    },
    "payments": 2659,
    "payments_amount": 11326293251595,
    "trades": 14324,
    "traded_amount": 10434528933628,
    "price": [
      2.5974023213016295,
      2.7076378859900796,
      2.5844155844155843,
      2.6910524040709385
    ],
    "volume": 9055403202043
  },
  {
    "ts": 1635292800,
    "supply": 1630342263863637,
    "trustlines": {
      "total": 52692,
      "authorized": 52692,
      "funded": 10886
    },
    "payments": 2829,
    "payments_amount": 55835868471931,
    "trades": 22045,
    "traded_amount": 38543484893253,
    "price": [
      2.6910524040709385,
      3.200040815676061,
      2.6595744680851063,
      3.0188354293638118
    ],
    "volume": 21904318206903
  },
  {
    "ts": 1635379200,
    "supply": 1634385570763637,
    "trustlines": {
      "total": 53032,
      "authorized": 53032,
      "funded": 10963
    },
    "payments": 3029,
    "payments_amount": 53990179462980,
    "trades": 25412,
    "traded_amount": 39413019201174,
    "price": [
      3.0188354293638118,
      3.073800129712222,
      2.8739306933878885,
      2.92415
    ],
    "volume": 15607407123706
  },
  {
    "ts": 1635465600,
    "supply": 1626342449663637,
    "trustlines": {
      "total": 53322,
      "authorized": 53322,
      "funded": 11023
    },
    "payments": 3160,
    "payments_amount": 50959588206890,
    "trades": 27323,
    "traded_amount": 18260831850286,
    "price": [
      2.92415,
      2.972972972972973,
      2.7534480022086525,
      2.768410477562931
    ],
    "volume": 21606013529452
  },
  {
    "ts": 1635552000,
    "supply": 1626342449663635,
    "trustlines": {
      "total": 53594,
      "authorized": 53594,
      "funded": 11061
    },
    "payments": 2062,
    "payments_amount": 3658614277081,
    "trades": 25006,
    "traded_amount": 16339648112484,
    "price": [
      2.768410477562931,
      2.844141069397042,
      2.7372572390441348,
      2.7931974884774453
    ],
    "volume": 27591403239551
  },
  {
    "ts": 1635638400,
    "supply": 1627043164163635,
    "trustlines": {
      "total": 53874,
      "authorized": 53874,
      "funded": 11111
    },
    "payments": 2503,
    "payments_amount": 19491174743573,
    "trades": 24158,
    "traded_amount": 25946537961596,
    "price": [
      2.7931974884774453,
      2.8205128205128207,
      2.5876038753952892,
      2.6881727636146597
    ],
    "volume": 33002132541674
  },
  {
    "ts": 1635724800,
    "supply": 1627366543442289,
    "trustlines": {
      "total": 54133,
      "authorized": 54133,
      "funded": 11130
    },
    "payments": 1839,
    "payments_amount": 2727439868431,
    "trades": 19181,
    "traded_amount": 12074002207103,
    "price": [
      2.6881727636146597,
      2.8205128205128207,
      2.6619133954333978,
      2.7280009985249
    ],
    "volume": 21971180293069
  },
  {
    "ts": 1635811200,
    "supply": 1627366543442289,
    "trustlines": {
      "total": 54449,
      "authorized": 54449,
      "funded": 11226
    },
    "payments": 1695,
    "payments_amount": 78542137983937,
    "trades": 17920,
    "traded_amount": 17584757163210,
    "price": [
      2.7280009985249,
      2.755405029299979,
      2.6136363636363638,
      2.6166462998286937
    ],
    "volume": 30395454389606
  },
  {
    "ts": 1635897600,
    "supply": 1633948572742289,
    "trustlines": {
      "total": 55630,
      "authorized": 55630,
      "funded": 11331
    },
    "payments": 1931,
    "payments_amount": 84677486388095,
    "trades": 21532,
    "traded_amount": 24007173640123,
    "price": [
      2.6166462998286937,
      2.718129926610492,
      2.5161290322580645,
      2.6138153922465532
    ],
    "volume": 28057016013186
  },
  {
    "ts": 1635984000,
    "supply": 1633948572242289,
    "trustlines": {
      "total": 55908,
      "authorized": 55908,
      "funded": 11354
    },
    "payments": 5255,
    "payments_amount": 6348235812779,
    "trades": 23299,
    "traded_amount": 14899473184339,
    "price": [
      2.6138153922465532,
      2.744186046511628,
      2.567122396860728,
      2.7107686093919114
    ],
    "volume": 20489542538134
  },
  {
    "ts": 1636070400,
    "supply": 1635045701342289,
    "trustlines": {
      "total": 56175,
      "authorized": 56175,
      "funded": 11383
    },
    "payments": 4008,
    "payments_amount": 29456144164836,
    "trades": 26163,
    "traded_amount": 25446411046071,
    "price": [
      2.7107686093919114,
      2.791697500428855,
      2.6759664172630235,
      2.78940879684401
    ],
    "volume": 24193906822317
  },
  {
    "ts": 1636156800,
    "supply": 1635045724942289,
    "trustlines": {
      "total": 56412,
      "authorized": 56412,
      "funded": 11445
    },
    "payments": 3740,
    "payments_amount": 7836370880658,
    "trades": 35325,
    "traded_amount": 15772425110713,
    "price": [
      2.78940879684401,
      2.8643872493391784,
      2.6944444444444446,
      2.787354999952932
    ],
    "volume": 25123750460629
  },
  {
    "ts": 1636243200,
    "supply": 1635045724942289,
    "trustlines": {
      "total": 56697,
      "authorized": 56697,
      "funded": 11507
    },
    "payments": 3389,
    "payments_amount": 6245836741595,
    "trades": 53208,
    "traded_amount": 16106801789818,
    "price": [
      2.787354999952932,
      2.8076923076923075,
      2.743055477522703,
      2.75778915919761
    ],
    "volume": 31098360907373
  },
  {
    "ts": 1636329600,
    "supply": 1623845865642289,
    "trustlines": {
      "total": 56978,
      "authorized": 56978,
      "funded": 11577
    },
    "payments": 3907,
    "payments_amount": 48158916264436,
    "trades": 52371,
    "traded_amount": 44289767725906,
    "price": [
      2.75778915919761,
      2.75778915919761,
      2.6357888802515235,
      2.6398624998082734
    ],
    "volume": 49159836678691
  },
  {
    "ts": 1636416000,
    "supply": 1629054513842289,
    "trustlines": {
      "total": 57264,
      "authorized": 57264,
      "funded": 11730
    },
    "payments": 5488,
    "payments_amount": 45656737080168,
    "trades": 62842,
    "traded_amount": 40884693558763,
    "price": [
      2.6398624998082734,
      2.6875063001374477,
      2.393532566083444,
      2.4331633642823465
    ],
    "volume": 45035953906759
  },
  {
    "ts": 1636502400,
    "supply": 1635665382742289,
    "trustlines": {
      "total": 57688,
      "authorized": 57688,
      "funded": 11815
    },
    "payments": 5827,
    "payments_amount": 57943759054625,
    "trades": 82058,
    "traded_amount": 86814097541011,
    "price": [
      2.4331633642823465,
      2.702606285519913,
      2.307723372617069,
      2.6158979511002687
    ],
    "volume": 97288729539419
  },
  {
    "ts": 1636588800,
    "supply": 1645933244442289,
    "trustlines": {
      "total": 58013,
      "authorized": 58013,
      "funded": 11863
    },
    "payments": 3763,
    "payments_amount": 39837530899489,
    "trades": 62535,
    "traded_amount": 45708028117106,
    "price": [
      2.6158979511002687,
      2.657142857142857,
      2.473684210526316,
      2.5520055999143048
    ],
    "volume": 46026142638552
  },
  {
    "ts": 1636675200,
    "supply": 1636280996442288,
    "trustlines": {
      "total": 58330,
      "authorized": 58330,
      "funded": 11894
    },
    "payments": 6329,
    "payments_amount": 43234712936670,
    "trades": 57033,
    "traded_amount": 43332275811497,
    "price": [
      2.5520055999143048,
      2.75,
      2.543002094592697,
      2.636574999857506
    ],
    "volume": 54963378481893
  },
  {
    "ts": 1636761600,
    "supply": 1643281369742288,
    "trustlines": {
      "total": 58689,
      "authorized": 58689,
      "funded": 11945
    },
    "payments": 4107,
    "payments_amount": 16520286328384,
    "trades": 64146,
    "traded_amount": 53104857646503,
    "price": [
      2.636574999857506,
      2.7024602285513892,
      2.610755549983346,
      2.6416491921572605
    ],
    "volume": 42293676502008
  },
  {
    "ts": 1636848000,
    "supply": 1643281369742287,
    "trustlines": {
      "total": 58975,
      "authorized": 58975,
      "funded": 11957
    },
    "payments": 2232,
    "payments_amount": 6861895474355,
    "trades": 35894,
    "traded_amount": 41211777688273,
    "price": [
      2.6416491921572605,
      2.715449400660225,
      2.6237353378278203,
      2.6531835941163884
    ],
    "volume": 30483503509396
  },
  {
    "ts": 1636934400,
    "supply": 1638703984042287,
    "trustlines": {
      "total": 59815,
      "authorized": 59815,
      "funded": 12561
    },
    "payments": 4708,
    "payments_amount": 27013762619778,
    "trades": 46477,
    "traded_amount": 39527401127211,
    "price": [
      2.6531835941163884,
      2.663825299765303,
      2.5256410256410255,
      2.649212985333889
    ],
    "volume": 44728060067504
  },
  {
    "ts": 1637020800,
    "supply": 1632965132942287,
    "trustlines": {
      "total": 60160,
      "authorized": 60160,
      "funded": 12579
    },
    "payments": 3930,
    "payments_amount": 73809673246965,
    "trades": 57909,
    "traded_amount": 72196551912095,
    "price": [
      2.649212985333889,
      3.00029368864147,
      2.649212985333889,
      2.908279
    ],
    "volume": 74295868635059
  },
  {
    "ts": 1637107200,
    "supply": 1633102422942287,
    "trustlines": {
      "total": 60513,
      "authorized": 60513,
      "funded": 12646
    },
    "payments": 2256,
    "payments_amount": 36590853844692,
    "trades": 51726,
    "traded_amount": 54602327514336,
    "price": [
      2.908279,
      2.9940119767773976,
      2.865329509733623,
      2.8897691758948416
    ],
    "volume": 67814532855530
  },
  {
    "ts": 1637193600,
    "supply": 1641428837742287,
    "trustlines": {
      "total": 60969,
      "authorized": 60969,
      "funded": 12711
    },
    "payments": 2269,
    "payments_amount": 44879660674951,
    "trades": 79040,
    "traded_amount": 73862300804091,
    "price": [
      2.8897691758948416,
      3.183699510265813,
      2.8214285714285716,
      3.056863468555727
    ],
    "volume": 87647517985156
  },
  {
    "ts": 1637280000,
    "supply": 1647134727642286,
    "trustlines": {
      "total": 61388,
      "authorized": 61388,
      "funded": 12772
    },
    "payments": 1502,
    "payments_amount": 52496566697319,
    "trades": 79183,
    "traded_amount": 65873080236593,
    "price": [
      3.056863468555727,
      3.071341103505822,
      2.814442760330678,
      2.826689717379082
    ],
    "volume": 67917201345634
  },
  {
    "ts": 1637366400,
    "supply": 1648079121042285,
    "trustlines": {
      "total": 61678,
      "authorized": 61678,
      "funded": 12829
    },
    "payments": 1701,
    "payments_amount": 8860029016310,
    "trades": 57669,
    "traded_amount": 30777629838014,
    "price": [
      2.826689717379082,
      2.9932173437807177,
      2.7985390495474585,
      2.8732659839786687
    ],
    "volume": 47649814878798
  },
  {
    "ts": 1637452800,
    "supply": 1648079120792285,
    "trustlines": {
      "total": 61923,
      "authorized": 61923,
      "funded": 12895
    },
    "payments": 1692,
    "payments_amount": 11708190441218,
    "trades": 65764,
    "traded_amount": 35426848550114,
    "price": [
      2.8732659839786687,
      2.972972972972973,
      2.8275862068965516,
      2.9086677
    ],
    "volume": 49763515656770
  },
  {
    "ts": 1637539200,
    "supply": 1649968290392285,
    "trustlines": {
      "total": 62169,
      "authorized": 62169,
      "funded": 12922
    },
    "payments": 3504,
    "payments_amount": 71140264376821,
    "trades": 97820,
    "traded_amount": 109541030024967,
    "price": [
      2.9086677,
      3.0031070161500897,
      2.7996440151157094,
      2.966627000449465
    ],
    "volume": 89172171354823
  },
  {
    "ts": 1637625600,
    "supply": 1639205886492285,
    "trustlines": {
      "total": 62475,
      "authorized": 62475,
      "funded": 12974
    },
    "payments": 3855,
    "payments_amount": 49474332487015,
    "trades": 79350,
    "traded_amount": 92615411805195,
    "price": [
      2.966627000449465,
      3.0395568779311635,
      2.9207067900169106,
      2.9367101
    ],
    "volume": 65566411475819
  },
  {
    "ts": 1637712000,
    "supply": 1641371119392285,
    "trustlines": {
      "total": 62751,
      "authorized": 62751,
      "funded": 12975
    },
    "payments": 3795,
    "payments_amount": 56267971759278,
    "trades": 87115,
    "traded_amount": 126208522679072,
    "price": [
      2.9367101,
      3.114294580160565,
      2.9336043172602126,
      3.057314316650405
    ],
    "volume": 73920654494379
  },
  {
    "ts": 1637798400,
    "supply": 1641371119392285,
    "trustlines": {
      "total": 63025,
      "authorized": 63025,
      "funded": 13027
    },
    "payments": 3989,
    "payments_amount": 33035147510256,
    "trades": 105610,
    "traded_amount": 99198099032440,
    "price": [
      3.057314316650405,
      3.0651535495826563,
      2.8823529411764706,
      2.9263170993973544
    ],
    "volume": 72419188669095
  },
  {
    "ts": 1637884800,
    "supply": 1644684470090892,
    "trustlines": {
      "total": 63306,
      "authorized": 63306,
      "funded": 13081
    },
    "payments": 5573,
    "payments_amount": 86817709595996,
    "trades": 129412,
    "traded_amount": 166466613650311,
    "price": [
      2.9263170993973544,
      3.247520623995291,
      2.8669646685308625,
      3.043213600005334
    ],
    "volume": 122249772882967
  },
  {
    "ts": 1637971200,
    "supply": 1651312828290892,
    "trustlines": {
      "total": 63582,
      "authorized": 63582,
      "funded": 13101
    },
    "payments": 3434,
    "payments_amount": 41262246395136,
    "trades": 111138,
    "traded_amount": 92508692318889,
    "price": [
      3.043213600005334,
      3.1147580000430475,
      2.995192110922308,
      3.078716310349874
    ],
    "volume": 68609002876694
  },
  {
    "ts": 1638057600,
    "supply": 1651312828290892,
    "trustlines": {
      "total": 63901,
      "authorized": 63901,
      "funded": 13087
    },
    "payments": 3528,
    "payments_amount": 34521457295220,
    "trades": 141922,
    "traded_amount": 92104135039896,
    "price": [
      3.078716310349874,
      3.310260751138921,
      3.0698924731182795,
      3.101672510651281
    ],
    "volume": 98857739038582
  },
  {
    "ts": 1638144000,
    "supply": 1645690338390892,
    "trustlines": {
      "total": 64241,
      "authorized": 64241,
      "funded": 13162
    },
    "payments": 2900,
    "payments_amount": 39792112216383,
    "trades": 83444,
    "traded_amount": 133096463068930,
    "price": [
      3.101672510651281,
      3.142857142857143,
      2.9961038798853816,
      3.046917966304134
    ],
    "volume": 79433521132420
  },
  {
    "ts": 1638230400,
    "supply": 1644439915690892,
    "trustlines": {
      "total": 64512,
      "authorized": 64512,
      "funded": 13177
    },
    "payments": 3157,
    "payments_amount": 74178524995723,
    "trades": 111317,
    "traded_amount": 122734294279576,
    "price": [
      3.046917966304134,
      3.0472664000988607,
      2.8604118954068194,
      2.975936224312521
    ],
    "volume": 120311350507781
  },
  {
    "ts": 1638316800,
    "supply": 1653246405790621,
    "trustlines": {
      "total": 64843,
      "authorized": 64843,
      "funded": 13213
    },
    "payments": 2042,
    "payments_amount": 53612900348378,
    "trades": 74236,
    "traded_amount": 85206275614639,
    "price": [
      2.975936224312521,
      3.064636180055929,
      2.905208343088407,
      3.04997973905549
    ],
    "volume": 60993841590684
  },
  {
    "ts": 1638403200,
    "supply": 1656698828390621,
    "trustlines": {
      "total": 65258,
      "authorized": 65258,
      "funded": 13267
    },
    "payments": 2197,
    "payments_amount": 44377411228836,
    "trades": 61053,
    "traded_amount": 84829312704815,
    "price": [
      3.04997973905549,
      3.142857142857143,
      2.9480011976210596,
      2.9557434173525077
    ],
    "volume": 74528946057392
  },
  {
    "ts": 1638489600,
    "supply": 1653173449290621,
    "trustlines": {
      "total": 65651,
      "authorized": 65651,
      "funded": 13378
    },
    "payments": 4914,
    "payments_amount": 63849434021804,
    "trades": 85454,
    "traded_amount": 95434875461069,
    "price": [
      2.9557434173525077,
      3.082608263099273,
      2.7584250369990446,
      3.069587235674006
    ],
    "volume": 97530165588176
  },
  {
    "ts": 1638576000,
    "supply": 1670138612986450,
    "trustlines": {
      "total": 66065,
      "authorized": 66065,
      "funded": 13339
    },
    "payments": 2500,
    "payments_amount": 73154323751872,
    "trades": 90567,
    "traded_amount": 116576917284439,
    "price": [
      3.069587235674006,
      3.8300449681835422,
      3.065236439898874,
      3.4418644993016456
    ],
    "volume": 120676260045804
  },
  {
    "ts": 1638662400,
    "supply": 1670138612986450,
    "trustlines": {
      "total": 66486,
      "authorized": 66486,
      "funded": 13405
    },
    "payments": 1114,
    "payments_amount": 24725773183292,
    "trades": 60135,
    "traded_amount": 60599635822596,
    "price": [
      3.4418644993016456,
      3.77358348173035,
      3.407153833743845,
      3.4992954526844446
    ],
    "volume": 64629802249327
  },
  {
    "ts": 1638748800,
    "supply": 1656011589386450,
    "trustlines": {
      "total": 66969,
      "authorized": 66969,
      "funded": 13503
    },
    "payments": 2316,
    "payments_amount": 48550457719303,
    "trades": 72098,
    "traded_amount": 60899208932768,
    "price": [
      3.4992954526844446,
      4.074074074074074,
      3.3915019983810724,
      3.3927567286196174
    ],
    "volume": 104788224200868
  },
  {
    "ts": 1638835200,
    "supply": 1653784137786450,
    "trustlines": {
      "total": 67433,
      "authorized": 67433,
      "funded": 13705
    },
    "payments": 3046,
    "payments_amount": 39200366050259,
    "trades": 55743,
    "traded_amount": 50633029863605,
    "price": [
      3.3927567286196174,
      3.5566558405315356,
      3.3546228999880663,
      3.4912052088228696
    ],
    "volume": 73125730290637
  },
  {
    "ts": 1638921600,
    "supply": 1654198656786450,
    "trustlines": {
      "total": 67929,
      "authorized": 67929,
      "funded": 13832
    },
    "payments": 2822,
    "payments_amount": 18480355410057,
    "trades": 55505,
    "traded_amount": 41154846764253,
    "price": [
      3.4912052088228696,
      3.509412384674564,
      3.255253107295723,
      3.270114391871542
    ],
    "volume": 90696147540893
  },
  {
    "ts": 1639008000,
    "supply": 1656257937686448,
    "trustlines": {
      "total": 74254,
      "authorized": 74254,
      "funded": 13975
    },
    "payments": 2952,
    "payments_amount": 12855429443353,
    "trades": 67019,
    "traded_amount": 40236322056062,
    "price": [
      3.270114391871542,
      3.645658005964757,
      3.2430550553654833,
      3.645656047318313
    ],
    "volume": 79000298768076
  },
  {
    "ts": 1639094400,
    "supply": 1656257937686448,
    "trustlines": {
      "total": 80339,
      "authorized": 80339,
      "funded": 14119
    },
    "payments": 2407,
    "payments_amount": 15561247586339,
    "trades": 79512,
    "traded_amount": 45999987838078,
    "price": [
      3.645656047318313,
      3.8446746036789565,
      3.5568051244809284,
      3.83105059826515
    ],
    "volume": 105104111714692
  },
  {
    "ts": 1639180800,
    "supply": 1656257937686447,
    "trustlines": {
      "total": 80862,
      "authorized": 80862,
      "funded": 14320
    },
    "payments": 4049,
    "payments_amount": 25384268228189,
    "trades": 73189,
    "traded_amount": 53588405613085,
    "price": [
      3.83105059826515,
      3.891714283668407,
      3.585810185775953,
      3.6509794912407862
    ],
    "volume": 98133108343945
  },
  {
    "ts": 1639267200,
    "supply": 1656257937686447,
    "trustlines": {
      "total": 81344,
      "authorized": 81344,
      "funded": 14373
    },
    "payments": 3327,
    "payments_amount": 19894039029931,
    "trades": 59548,
    "traded_amount": 39574566689355,
    "price": [
      3.6509794912407862,
      3.899992395044593,
      3.56,
      3.716481517751588
    ],
    "volume": 64799010564624
  },
  {
    "ts": 1639353600,
    "supply": 1656257937686447,
    "trustlines": {
      "total": 81876,
      "authorized": 81876,
      "funded": 14545
    },
    "payments": 11252,
    "payments_amount": 25752054414149,
    "trades": 69568,
    "traded_amount": 44261777155169,
    "price": [
      3.716481517751588,
      3.984103872046254,
      3.6470931998611493,
      3.9291013787276534
    ],
    "volume": 95952781271980
  },
  {
    "ts": 1639440000,
    "supply": 1676382657786447,
    "trustlines": {
      "total": 82421,
      "authorized": 82421,
      "funded": 14686
    },
    "payments": 10421,
    "payments_amount": 57496304683734,
    "trades": 69427,
    "traded_amount": 71426647670659,
    "price": [
      3.9291013787276534,
      4.076640848009709,
      3.688136885668756,
      3.7416357048122837
    ],
    "volume": 125237383178584
  },
  {
    "ts": 1639526400,
    "supply": 1682384900286447,
    "trustlines": {
      "total": 83010,
      "authorized": 83010,
      "funded": 14788
    },
    "payments": 12456,
    "payments_amount": 30329416320558,
    "trades": 78949,
    "traded_amount": 63046015005311,
    "price": [
      3.7416357048122837,
      4.056057912655988,
      3.6451938781349056,
      3.682405759871793
    ],
    "volume": 126977297054998
  },
  {
    "ts": 1639612800,
    "supply": 1682384900286447,
    "trustlines": {
      "total": 83613,
      "authorized": 83613,
      "funded": 15105
    },
    "payments": 13646,
    "payments_amount": 15948523487092,
    "trades": 67057,
    "traded_amount": 45056236633332,
    "price": [
      3.682405759871793,
      3.917816032970517,
      3.658361068395359,
      3.904130179316699
    ],
    "volume": 95030144659659
  },
  {
    "ts": 1639699200,
    "supply": 1683089525286447,
    "trustlines": {
      "total": 94029,
      "authorized": 94029,
      "funded": 15454
    },
    "payments": 16873,
    "payments_amount": 13114433184938,
    "trades": 72854,
    "traded_amount": 43111680772023,
    "price": [
      3.904130179316699,
      4.0600044791355225,
      3.8088273189549917,
      3.939758781688104
    ],
    "volume": 112871343727174
  },
  {
    "ts": 1639785600,
    "supply": 1688966671286547,
    "trustlines": {
      "total": 95750,
      "authorized": 95750,
      "funded": 15391
    },
    "payments": 12648,
    "payments_amount": 31030708370659,
    "trades": 69676,
    "traded_amount": 57204616165965,
    "price": [
      3.939758781688104,
      3.996860080379667,
      3.739958120777969,
      3.914761916182984
    ],
    "volume": 125948459299159
  },
  {
    "ts": 1639872000,
    "supply": 1687925143086547,
    "trustlines": {
      "total": 96272,
      "authorized": 96272,
      "funded": 15430
    },
    "payments": 9005,
    "payments_amount": 14022697429124,
    "trades": 46857,
    "traded_amount": 35391020713669,
    "price": [
      3.914761916182984,
      3.9500000106793416,
      3.749085029930078,
      3.941786829241704
    ],
    "volume": 66023542693547
  },
  {
    "ts": 1639958400,
    "supply": 1686796435986547,
    "trustlines": {
      "total": 96648,
      "authorized": 96648,
      "funded": 15425
    },
    "payments": 6433,
    "payments_amount": 25057441629007,
    "trades": 61074,
    "traded_amount": 51748025923720,
    "price": [
      3.941786829241704,
      4.014160226524135,
      3.671173264368126,
      3.885528
    ],
    "volume": 141284710023380
  },
  {
    "ts": 1640044800,
    "supply": 1535785643686747,
    "trustlines": {
      "total": 97020,
      "authorized": 97020,
      "funded": 15696
    },
    "payments": 10265,
    "payments_amount": 462036060630569,
    "trades": 56727,
    "traded_amount": 40986416319549,
    "price": [
      3.885528,
      3.972861972185352,
      3.7187369536753336,
      3.7436302026999866
    ],
    "volume": 97064717691613
  },
  {
    "ts": 1640131200,
    "supply": 1498886752486830,
    "trustlines": {
      "total": 97292,
      "authorized": 97292,
      "funded": 16043
    },
    "payments": 10121,
    "payments_amount": 97252325007600,
    "trades": 55715,
    "traded_amount": 41667514505518,
    "price": [
      3.7436302026999866,
      3.744953581408573,
      3.5283913838874454,
      3.733627535533876
    ],
    "volume": 92237496775905
  },
  {
    "ts": 1640217600,
    "supply": 1454664327266829,
    "trustlines": {
      "total": 97521,
      "authorized": 97521,
      "funded": 16141
    },
    "payments": 8945,
    "payments_amount": 123644585819039,
    "trades": 62732,
    "traded_amount": 50239172952778,
    "price": [
      3.733627535533876,
      3.76763080278484,
      3.4662044571187565,
      3.4842671401553424
    ],
    "volume": 88451815897174
  },
  {
    "ts": 1640304000,
    "supply": 1458996405766912,
    "trustlines": {
      "total": 97657,
      "authorized": 97657,
      "funded": 16123
    },
    "payments": 9321,
    "payments_amount": 27346101825569,
    "trades": 44908,
    "traded_amount": 42335713297753,
    "price": [
      3.4842671401553424,
      3.7142857142857144,
      3.4722222222222223,
      3.6146831754274507
    ],
    "volume": 66798065215965
  },
  {
    "ts": 1640390400,
    "supply": 1457055664266912,
    "trustlines": {
      "total": 97882,
      "authorized": 97882,
      "funded": 16174
    },
    "payments": 7593,
    "payments_amount": 36605656990487,
    "trades": 45726,
    "traded_amount": 50223931969957,
    "price": [
      3.6146831754274507,
      3.636383129764942,
      3.4276214865122383,
      3.4690649998044485
    ],
    "volume": 50863480023094
  },
  {
    "ts": 1640476800,
    "supply": 1458340124666912,
    "trustlines": {
      "total": 98022,
      "authorized": 98022,
      "funded": 16171
    },
    "payments": 9701,
    "payments_amount": 48820956504812,
    "trades": 46975,
    "traded_amount": 53729963448763,
    "price": [
      3.4690649998044485,
      3.543821723946617,
      3.362351451003758,
      3.4266079397468436
    ],
    "volume": 47933227541597
  },
  {
    "ts": 1640563200,
    "supply": 1467696266966911,
    "trustlines": {
      "total": 98933,
      "authorized": 98933,
      "funded": 16214
    },
    "payments": 15914,
    "payments_amount": 61337182291009,
    "trades": 60010,
    "traded_amount": 58718932141943,
    "price": [
      3.4266079397468436,
      3.464109995984396,
      3.263276263683083,
      3.3334310167311627
    ],
    "volume": 49506169045129
  },
  {
    "ts": 1640649600,
    "supply": 1469893671766911,
    "trustlines": {
      "total": 99150,
      "authorized": 99150,
      "funded": 16255
    },
    "payments": 13998,
    "payments_amount": 84256536692582,
    "trades": 75081,
    "traded_amount": 83249843507700,
    "price": [
      3.3334310167311627,
      3.68250158820931,
      3.3230827308030495,
      3.632939763959403
    ],
    "volume": 75298298201874
  },
  {
    "ts": 1640736000,
    "supply": 1470062524866911,
    "trustlines": {
      "total": 99379,
      "authorized": 99379,
      "funded": 16266
    },
    "payments": 13233,
    "payments_amount": 77867783503909,
    "trades": 75851,
    "traded_amount": 84577271608133,
    "price": [
      3.632939763959403,
      3.7592487008078805,
      3.584231960020139,
      3.741883516434341
    ],
    "volume": 86318621390578
  },
  {
    "ts": 1640822400,
    "supply": 1479065706466911,
    "trustlines": {
      "total": 99570,
      "authorized": 99570,
      "funded": 16275
    },
    "payments": 11894,
    "payments_amount": 58469688311573,
    "trades": 75196,
    "traded_amount": 75108990061901,
    "price": [
      3.741883516434341,
      3.8505363316269716,
      3.6253806538212525,
      3.7421759917203934
    ],
    "volume": 90227313791704
  },
  {
    "ts": 1640908800,
    "supply": 1475869734266911,
    "trustlines": {
      "total": 99720,
      "authorized": 99720,
      "funded": 16304
    },
    "payments": 13110,
    "payments_amount": 49618267762472,
    "trades": 71005,
    "traded_amount": 59375256643907,
    "price": [
      3.7421759917203934,
      3.9523324468015257,
      3.6184210526315788,
      3.751212058650105
    ],
    "volume": 63166873014205
  },
  {
    "ts": 1640995200,
    "supply": 1476929143834432,
    "trustlines": {
      "total": 99995,
      "authorized": 99995,
      "funded": 16332
    },
    "payments": 13759,
    "payments_amount": 101949911185469,
    "trades": 64612,
    "traded_amount": 82654918965556,
    "price": [
      3.751212058650105,
      3.757131505318958,
      3.619976332212603,
      3.631100004374278
    ],
    "volume": 55673050844287
  },
  {
    "ts": 1641081600,
    "supply": 1479771347933505,
    "trustlines": {
      "total": 100437,
      "authorized": 100437,
      "funded": 16411
    },
    "payments": 16358,
    "payments_amount": 42529504715401,
    "trades": 75225,
    "traded_amount": 59308079529211,
    "price": [
      3.631100004374278,
      3.6795498480824964,
      3.4293552285477618,
      3.435039399562159
    ],
    "volume": 71294772389491
  },
  {
    "ts": 1641168000,
    "supply": 1482366014833505,
    "trustlines": {
      "total": 100876,
      "authorized": 100876,
      "funded": 16469
    },
    "payments": 12046,
    "payments_amount": 46406644966610,
    "trades": 72048,
    "traded_amount": 66687175318747,
    "price": [
      3.435039399562159,
      3.5072320100634613,
      3.3833129978017302,
      3.4523603607824738
    ],
    "volume": 88820884834861
  },
  {
    "ts": 1641254400,
    "supply": 1482366002933605,
    "trustlines": {
      "total": 101070,
      "authorized": 101070,
      "funded": 16535
    },
    "payments": 14792,
    "payments_amount": 90902964805468,
    "trades": 76213,
    "traded_amount": 69043827973892,
    "price": [
      3.4523603607824738,
      3.6270369350452927,
      3.4379679527169054,
      3.5836909801306813
    ],
    "volume": 89323260969752
  },
  {
    "ts": 1641340800,
    "supply": 1485471700233605,
    "trustlines": {
      "total": 101334,
      "authorized": 101334,
      "funded": 16632
    },
    "payments": 21381,
    "payments_amount": 58939390297487,
    "trades": 100134,
    "traded_amount": 77540732575039,
    "price": [
      3.5836909801306813,
      3.8522091043682134,
      3.3929236039321147,
      3.7593985999977244
    ],
    "volume": 107457955088576
  },
  {
    "ts": 1641427200,
    "supply": 1489597771133605,
    "trustlines": {
      "total": 101558,
      "authorized": 101558,
      "funded": 16627
    },
    "payments": 17830,
    "payments_amount": 59966755122357,
    "trades": 91991,
    "traded_amount": 79012907814030,
    "price": [
      3.7593985999977244,
      3.9285714285714284,
      3.64,
      3.748266430082792
    ],
    "volume": 110280103859505
  },
  {
    "ts": 1641513600,
    "supply": 1489597771133605,
    "trustlines": {
      "total": 101782,
      "authorized": 101782,
      "funded": 16767
    },
    "payments": 16755,
    "payments_amount": 49641257826020,
    "trades": 97796,
    "traded_amount": 64553153684785,
    "price": [
      3.748266430082792,
      3.975940380652519,
      3.729777582850857,
      3.89326535035808
    ],
    "volume": 111589481210706
  },
  {
    "ts": 1641600000,
    "supply": 1484844177433194,
    "trustlines": {
      "total": 101992,
      "authorized": 101992,
      "funded": 16791
    },
    "payments": 10563,
    "payments_amount": 34677258026176,
    "trades": 76369,
    "traded_amount": 46866246647584,
    "price": [
      3.89326535035808,
      4.03806400612,
      3.7950657620041754,
      3.9164087272520005
    ],
    "volume": 79127150663423
  },
  {
    "ts": 1641686400,
    "supply": 1484844177433193,
    "trustlines": {
      "total": 102220,
      "authorized": 102220,
      "funded": 16823
    },
    "payments": 13611,
    "payments_amount": 25213955973386,
    "trades": 70488,
    "traded_amount": 39384731729947,
    "price": [
      3.9164087272520005,
      3.9463361347058163,
      3.784596361524818,
      3.8346389602169553
    ],
    "volume": 53927567343314
  },
  {
    "ts": 1641772800,
    "supply": 1484841194733293,
    "trustlines": {
      "total": 102398,
      "authorized": 102398,
      "funded": 16978
    },
    "payments": 13540,
    "payments_amount": 29818772167494,
    "trades": 80126,
    "traded_amount": 45318880905803,
    "price": [
      3.8346389602169553,
      4.168388219070189,
      3.802781712031337,
      3.9612934098338317
    ],
    "volume": 91792056344729
  },
  {
    "ts": 1641859200,
    "supply": 1492102752116491,
    "trustlines": {
      "total": 102628,
      "authorized": 102628,
      "funded": 17110
    },
    "payments": 14501,
    "payments_amount": 43591212036264,
    "trades": 100305,
    "traded_amount": 52843053740712,
    "price": [
      3.9612934098338317,
      4.030632799861853,
      3.8064794372083006,
      3.80917118881223
    ],
    "volume": 72028161330929
  },
  {
    "ts": 1641945600,
    "supply": 1496797613716491,
    "trustlines": {
      "total": 103077,
      "authorized": 103077,
      "funded": 17480
    },
    "payments": 14778,
    "payments_amount": 55352312492906,
    "trades": 101419,
    "traded_amount": 57303491059974,
    "price": [
      3.80917118881223,
      3.8144256492152455,
      3.4347826086956523,
      3.5345768972569482
    ],
    "volume": 83226755257644
  },
  {
    "ts": 1642032000,
    "supply": 1505271441516491,
    "trustlines": {
      "total": 103442,
      "authorized": 103442,
      "funded": 17562
    },
    "payments": 11180,
    "payments_amount": 42080924542852,
    "trades": 80535,
    "traded_amount": 58422341472025,
    "price": [
      3.5345768972569482,
      3.739807438772663,
      3.4541484716157207,
      3.705641
    ],
    "volume": 97143152674117
  },
  {
    "ts": 1642118400,
    "supply": 1505271401816590,
    "trustlines": {
      "total": 103805,
      "authorized": 103805,
      "funded": 17509
    },
    "payments": 13578,
    "payments_amount": 42849880825872,
    "trades": 97262,
    "traded_amount": 55311656037480,
    "price": [
      3.705641,
      3.830156262995795,
      3.596425004780887,
      3.631998739206916
    ],
    "volume": 104612951321945
  }
]
[
  [
    1642197600,
    0.2771
  ],
  [
    1642114800,
    0.27218
  ],
  [
    1642028400,
    0.28566
  ],
  [
    1641942000,
    0.26323
  ],
  [
    1641855600,
    0.25062
  ],
  [
    1641769200,
    0.26239
  ],
  [
    1641682800,
    0.25951
  ],
  [
    1641596400,
    0.25581
  ],
  [
    1641510000,
    0.26887
  ],
  [
    1641423600,
    0.26596
  ],
  [
    1641337200,
    0.28211
  ],
  [
    1641250800,
    0.29135
  ],
  [
    1641164400,
    0.29297
  ],
  [
    1641078000,
    0.27499
  ],
  [
    1640991600,
    0.26731
  ],
  [
    1640905200,
    0.26832
  ],
  [
    1640818800,
    0.27219
  ],
  [
    1640732400,
    0.27718
  ],
  [
    1640646000,
    0.30128
  ],
  [
    1640559600,
    0.29206
  ],
  [
    1640473200,
    0.29089
  ],
  [
    1640386800,
    0.27999
  ],
  [
    1640300400,
    0.28783
  ],
  [
    1640214000,
    0.27058
  ],
  [
    1640127600,
    0.26981
  ],
  [
    1640041200,
    0.25902
  ],
  [
    1639954800,
    0.25688
  ],
  [
    1639868400,
    0.25616
  ],
  [
    1639782000,
    0.25388
  ],
  [
    1639695600,
    0.25886
  ],
  [
    1639609200,
    0.26948
  ],
  [
    1639522800,
    0.26707
  ],
  [
    1639436400,
    0.25524
  ],
  [
    1639350000,
    0.27834
  ],
  [
    1639263600,
    0.27482
  ],
  [
    1639177200,
    0.26589
  ],
  [
    1639090800,
    0.28039
  ],
  [
    1639004400,
    0.30482
  ],
  [
    1638918000,
    0.28589
  ],
  [
    1638831600,
    0.29177
  ],
  [
    1638745200,
    0.28053
  ],
  [
    1638658800,
    0.29255
  ],
  [
    1638572400,
    0.32856
  ],
  [
    1638486000,
    0.33667
  ],
  [
    1638399600,
    0.33055
  ],
  [
    1638313200,
    0.33969
  ],
  [
    1638226800,
    0.32929
  ],
  [
    1638140400,
    0.32504
  ],
  [
    1638054000,
    0.32708
  ],
  [
    1637967600,
    0.32852
  ],
  [
    1637881200,
    0.34456
  ],
  [
    1637794800,
    0.32791
  ],
  [
    1637708400,
    0.33986
  ],
  [
    1637622000,
    0.33928
  ],
  [
    1637535600,
    0.34729
  ],
  [
    1637449200,
    0.35007
  ],
  [
    1637362800,
    0.35744
  ],
  [
    1637276400,
    0.32646
  ],
  [
    1637190000,
    0.34685
  ],
  [
    1637103600,
    0.34822
  ],
  [
    1637017200,
    0.38141
  ],
  [
    1636930800,
    0.3788
  ],
  [
    1636844400,
    0.37996
  ],
  [
    1636758000,
    0.38126
  ],
  [
    1636671600,
    0.39428
  ],
  [
    1636585200,
    0.38677
  ],
  [
    1636498800,
    0.41657
  ],
  [
    1636412400,
    0.37825
  ],
  [
    1636326000,
    0.36512
  ],
  [
    1636239600,
    0.35997
  ],
  [
    1636153200,
    0.3613
  ],
  [
    1636066800,
    0.36975
  ],
  [
    1635980400,
    0.3849
  ],
  [
    1635894000,
    0.37721
  ],
  [
    1635807600,
    0.36653
  ],
  [
    1635721200,
    0.37391
  ],
  [
    1635634800,
    0.35823
  ],
  [
    1635548400,
    0.35966
  ],
  [
    1635462000,
    0.34347
  ],
  [
    1635375600,
    0.33467
  ],
  [
    1635289200,
    0.37496
  ],
  [
    1635202800,
    0.38476
  ],
  [
    1635116400,
    0.37358
  ],
  [
    1635030000,
    0.37915
  ],
  [
    1634943600,
    0.37382
  ],
  [
    1634857200,
    0.37458
  ],
  [
    1634770800,
    0.38999
  ],
  [
    1634684400,
    0.37618
  ],
  [
    1634598000,
    0.3817
  ],
  [
    1634511600,
    0.3842
  ],
  [
    1634425200,
    0.40369
  ],
  [
    1634338800,
    0.36464
  ],
  [
    1634252400,
    0.36771
  ],
  [
    1634166000,
    0.36448
  ],
  [
    1634079600,
    0.33684
  ],
  [
    1633993200,
    0.34342
  ],
  [
    1633906800,
    0.33895
  ],
  [
    1633820400,
    0.35319
  ],
  [
    1633734000,
    0.33636
  ],
  [
    1633647600,
    0.34568
  ],
  [
    1633561200,
    0.35051
  ],
  [
    1633474800,
    0.31931
  ],
  [
    1633388400,
    0.3092
  ],
  [
    1633302000,
    0.31542
  ],
  [
    1633215600,
    0.32279
  ],
  [
    1633129200,
    0.29905
  ],
  [
    1633042800,
    0.27866
  ],
  [
    1632956400,
    0.27023
  ],
  [
    1632870000,
    0.26141
  ],
  [
    1632783600,
    0.27125
  ],
  [
    1632697200,
    0.27375
  ],
  [
    1632610800,
    0.2782
  ],
  [
    1632524400,
    0.28508
  ],
  [
    1632438000,
    0.30108
  ],
  [
    1632351600,
    0.29416
  ],
  [
    1632265200,
    0.26599
  ],
  [
    1632178800,
    0.28374
  ],
  [
    1632092400,
    0.31491
  ],
  [
    1632006000,
    0.3231
  ],
  [
    1631919600,
    0.3206
  ],
  [
    1631833200,
    0.33312
  ],
  [
    1631746800,
    0.34318
  ],
  [
    1631660400,
    0.33054
  ],
  [
    1631574000,
    0.31953
  ],
  [
    1631487600,
    0.33694
  ],
  [
    1631401200,
    0.32536
  ],
  [
    1631314800,
    0.3153
  ],
  [
    1631228400,
    0.33696
  ],
  [
    1631142000,
    0.33253
  ],
  [
    1631055600,
    0.33636
  ],
  [
    1630969200,
    0.42301
  ],
  [
    1630882800,
    0.39183
  ],
  [
    1630796400,
    0.37236
  ],
  [
    1630710000,
    0.36976
  ],
  [
    1630623600,
    0.36223
  ],
  [
    1630537200,
    0.35395
  ],
  [
    1630450800,
    0.3424
  ],
  [
    1630364400,
    0.33409
  ],
  [
    1630278000,
    0.34957
  ],
  [
    1630191600,
    0.34657
  ],
  [
    1630105200,
    0.35433
  ],
  [
    1630018800,
    0.34
  ],
  [
    1629932400,
    0.35784
  ],
  [
    1629846000,
    0.36037
  ],
  [
    1629759600,
    0.37739
  ],
  [
    1629673200,
    0.37042
  ],
  [
    1629586800,
    0.37413
  ],
  [
    1629500400,
    0.38128
  ],
  [
    1629414000,
    0.36253
  ],
  [
    1629327600,
    0.34566
  ],
  [
    1629241200,
    0.34513
  ],
  [
    1629154800,
    0.37441
  ],
  [
    1629068400,
    0.3936
  ],
  [
    1628982000,
    0.38554
  ],
  [
    1628895600,
    0.35872
  ],
  [
    1628809200,
    0.32742
  ],
  [
    1628722800,
    0.33489
  ],
  [
    1628636400,
    0.30853
  ],
  [
    1628550000,
    0.30056
  ],
  [
    1628463600,
    0.29268
  ],
  [
    1628377200,
    0.30601
  ],
  [
    1628290800,
    0.28468
  ],
  [
    1628204400,
    0.28222
  ],
  [
    1628118000,
    0.28119
  ],
  [
    1628031600,
    0.27553
  ],
  [
    1627945200,
    0.27885
  ],
  [
    1627858800,
    0.28429
  ],
  [
    1627772400,
    0.28748
  ],
  [
    1627686000,
    0.27912
  ],
  [
    1627599600,
    0.2738
  ],
  [
    1627513200,
    0.26668
  ],
  [
    1627426800,
    0.26242
  ],
  [
    1627340400,
    0.26231
  ],
  [
    1627254000,
    0.26132
  ],
  [
    1627167600,
    0.26554
  ],
  [
    1627081200,
    0.26742
  ],
  [
    1626994800,
    0.2612
  ],
  [
    1626908400,
    0.23035
  ],
  [
    1626822000,
    0.21193
  ],
  [
    1626735600,
    0.21441
  ],
  [
    1626649200,
    0.23105
  ],
  [
    1626562800,
    0.2339
  ],
  [
    1626476400,
    0.2407
  ],
  [
    1626390000,
    0.23898
  ],
  [
    1626303600,
    0.24291
  ],
  [
    1626217200,
    0.23531
  ],
  [
    1626130800,
    0.24087
  ],
  [
    1626044400,
    0.24776
  ],
  [
    1625958000,
    0.24633
  ],
  [
    1625871600,
    0.24906
  ],
  [
    1625785200,
    0.24506
  ],
  [
    1625698800,
    0.25954
  ],
  [
    1625612400,
    0.26051
  ],
  [
    1625526000,
    0.25916
  ],
  [
    1625439600,
    0.2688
  ],
  [
    1625353200,
    0.26357
  ],
  [
    1625266800,
    0.26417
  ],
  [
    1625180400,
    0.27106
  ],
  [
    1625094000,
    0.28245
  ],
  [
    1625007600,
    0.28237
  ],
  [
    1624921200,
    0.26393
  ],
  [
    1624834800,
    0.25653
  ],
  [
    1624748400,
    0.24842
  ],
  [
    1624662000,
    0.24329
  ],
  [
    1624575600,
    0.26631
  ],
  [
    1624489200,
    0.25268
  ],
  [
    1624402800,
    0.23239
  ],
  [
    1624316400,
    0.24019
  ],
  [
    1624230000,
    0.29948
  ],
  [
    1624143600,
    0.29443
  ],
  [
    1624057200,
    0.29817
  ],
  [
    1623970800,
    0.31766
  ],
  [
    1623884400,
    0.31699
  ],
  [
    1623798000,
    0.33633
  ],
  [
    1623711600,
    0.34112
  ],
  [
    1623625200,
    0.34521
  ],
  [
    1623538800,
    0.33825
  ],
  [
    1623452400,
    0.31966
  ],
  [
    1623366000,
    0.34082
  ],
  [
    1623279600,
    0.36176
  ],
  [
    1623193200,
    0.35507
  ],
  [
    1623106800,
    0.35288
  ],
  [
    1623020400,
    0.38099
  ],
  [
    1622934000,
    0.37541
  ],
  [
    1622847600,
    0.38525
  ],
  [
    1622761200,
    0.42476
  ],
  [
    1622674800,
    0.42147
  ],
  [
    1622588400,
    0.4234
  ],
  [
    1622502000,
    0.39868
  ],
  [
    1622415600,
    0.37388
  ],
  [
    1622329200,
    0.36592
  ],
  [
    1622242800,
    0.39464
  ],
  [
    1622156400,
    0.42205
  ],
  [
    1622070000,
    0.44044
  ],
  [
    1621983600,
    0.43465
  ],
  [
    1621897200,
    0.42669
  ],
  [
    1621810800,
    0.36149
  ],
  [
    1621724400,
    0.40062
  ],
  [
    1621638000,
    0.41597
  ],
  [
    1621551600,
    0.49251
  ],
  [
    1621465200,
    0.4651
  ],
  [
    1621378800,
    0.64181
  ],
  [
    1621292400,
    0.63552
  ],
  [
    1621206000,
    0.70295
  ],
  [
    1621119600,
    0.69321
  ],
  [
    1621033200,
    0.66682
  ],
  [
    1620946800,
    0.63485
  ],
  [
    1620860400,
    0.65245
  ],
  [
    1620774000,
    0.70611
  ],
  [
    1620687600,
    0.6887
  ],
  [
    1620601200,
    0.61895
  ],
  [
    1620514800,
    0.61868
  ],
  [
    1620428400,
    0.62559
  ],
  [
    1620342000,
    0.66374
  ],
  [
    1620255600,
    0.59348
  ],
  [
    1620169200,
    0.51654
  ],
  [
    1620082800,
    0.5525
  ],
  [
    1619996400,
    0.55041
  ],
  [
    1619910000,
    0.53899
  ],
  [
    1619823600,
    0.52784
  ],
  [
    1619737200,
    0.49276
  ],
  [
    1619650800,
    0.49267
  ],
  [
    1619564400,
    0.50593
  ],
  [
    1619478000,
    0.48531
  ],
  [
    1619391600,
    0.41733
  ],
  [
    1619305200,
    0.42731
  ],
  [
    1619218800,
    0.44832
  ],
  [
    1619132400,
    0.45578
  ],
  [
    1619046000,
    0.48621
  ],
  [
    1618959600,
    0.51884
  ],
  [
    1618873200,
    0.50444
  ],
  [
    1618786800,
    0.54625
  ],
  [
    1618700400,
    0.60579
  ],
  [
    1618614000,
    0.605
  ],
  [
    1618527600,
    0.64587
  ],
  [
    1618441200,
    0.63476
  ],
  [
    1618354800,
    0.65595
  ],
  [
    1618268400,
    0.58335
  ],
  [
    1618182000,
    0.59805
  ],
  [
    1618095600,
    0.56697
  ],
  [
    1618009200,
    0.48441
  ],
  [
    1617922800,
    0.50893
  ],
  [
    1617836400,
    0.4931
  ],
  [
    1617750000,
    0.52884
  ],
  [
    1617663600,
    0.52527
  ],
  [
    1617577200,
    0.42982
  ],
  [
    1617490800,
    0.41611
  ],
  [
    1617404400,
    0.44022
  ],
  [
    1617318000,
    0.42814
  ],
  [
    1617231600,
    0.40469
  ],
  [
    1617145200,
    0.40243
  ],
  [
    1617058800,
    0.40452
  ],
  [
    1616972400,
    0.39752
  ],
  [
    1616886000,
    0.38422
  ],
  [
    1616799600,
    0.38392
  ],
  [
    1616713200,
    0.36435
  ],
  [
    1616626800,
    0.3634
  ],
  [
    1616540400,
    0.4
  ],
  [
    1616454000,
    0.39824
  ],
  [
    1616367600,
    0.40861
  ],
  [
    1616281200,
    0.42101
  ],
  [
    1616194800,
    0.39791
  ],
  [
    1616108400,
    0.39812
  ],
  [
    1616022000,
    0.40416
  ],
  [
    1615935600,
    0.39695
  ],
  [
    1615849200,
    0.38258
  ],
  [
    1615762800,
    0.39786
  ],
  [
    1615676400,
    0.40602
  ],
  [
    1615590000,
    0.38809
  ],
  [
    1615503600,
    0.4063
  ],
  [
    1615417200,
    0.41506
  ],
  [
    1615330800,
    0.42869
  ],
  [
    1615244400,
    0.41779
  ],
  [
    1615158000,
    0.41143
  ],
  [
    1615071600,
    0.40417
  ],
  [
    1614985200,
    0.40931
  ],
  [
    1614898800,
    0.41074
  ],
  [
    1614812400,
    0.42611
  ],
  [
    1614726000,
    0.41595
  ],
  [
    1614639600,
    0.43175
  ],
  [
    1614553200,
    0.41434
  ],
  [
    1614466800,
    0.43701
  ],
  [
    1614380400,
    0.39175
  ],
  [
    1614294000,
    0.39118
  ],
  [
    1614207600,
    0.40694
  ],
  [
    1614121200,
    0.38146
  ],
  [
    1614034800,
    0.4567
  ],
  [
    1613948400,
    0.4979
  ],
  [
    1613862000,
    0.49035
  ],
  [
    1613775600,
    0.51317
  ],
  [
    1613689200,
    0.49991
  ],
  [
    1613602800,
    0.49669
  ],
  [
    1613516400,
    0.48757
  ],
  [
    1613430000,
    0.49722
  ],
  [
    1613343600,
    0.52495
  ],
  [
    1613257200,
    0.57175
  ],
  [
    1613170800,
    0.52428
  ],
  [
    1613084400,
    0.45194
  ],
  [
    1612998000,
    0.41058
  ],
  [
    1612911600,
    0.39597
  ],
  [
    1612825200,
    0.39296
  ],
  [
    1612738800,
    0.37982
  ],
  [
    1612652400,
    0.38816
  ],
  [
    1612566000,
    0.34813
  ],
  [
    1612479600,
    0.33013
  ],
  [
    1612393200,
    0.34215
  ],
  [
    1612306800,
    0.34307
  ],
  [
    1612220400,
    0.3179
  ],
  [
    1612134000,
    0.30263
  ],
  [
    1612047600,
    0.3265
  ],
  [
    1611961200,
    0.29081
  ],
  [
    1611874800,
    0.28699
  ],
  [
    1611788400,
    0.23844
  ],
  [
    1611702000,
    0.26168
  ],
  [
    1611615600,
    0.26248
  ],
  [
    1611529200,
    0.27094
  ],
  [
    1611442800,
    0.26971
  ],
  [
    1611356400,
    0.26777
  ],
  [
    1611270000,
    0.25944
  ],
  [
    1611183600,
    0.29351
  ],
  [
    1611097200,
    0.30212
  ],
  [
    1611010800,
    0.30115
  ],
  [
    1610924400,
    0.30746
  ],
  [
    1610838000,
    0.29213
  ],
  [
    1610751600,
    0.28174
  ],
  [
    1610665200,
    0.29841
  ],
  [
    1610578800,
    0.30537
  ],
  [
    1610492400,
    0.28008
  ],
  [
    1610406000,
    0.25628
  ],
  [
    1610319600,
    0.28851
  ],
  [
    1610233200,
    0.31409
  ],
  [
    1610146800,
    0.30323
  ],
  [
    1610060400,
    0.30766
  ],
  [
    1609974000,
    0.3261
  ],
  [
    1609887600,
    0.19836
  ],
  [
    1609801200,
    0.1609
  ],
  [
    1609714800,
    0.13587
  ],
  [
    1609628400,
    0.12824
  ],
  [
    1609542000,
    0.13358
  ],
  [
    1609455600,
    0.12842
  ],
  [
    1609369200,
    0.13094
  ],
  [
    1609282800,
    0.13691
  ],
  [
    1609196400,
    0.14277
  ],
  [
    1609110000,
    0.14692
  ],
  [
    1609023600,
    0.14711
  ],
  [
    1608937200,
    0.15088
  ],
  [
    1608850800,
    0.16421
  ],
  [
    1608764400,
    0.13089
  ],
  [
    1608678000,
    0.16004
  ],
  [
    1608591600,
    0.16857
  ],
  [
    1608505200,
    0.17631
  ],
  [
    1608418800,
    0.18186
  ],
  [
    1608332400,
    0.18393
  ],
  [
    1608246000,
    0.18549
  ],
  [
    1608159600,
    0.18253
  ],
  [
    1608073200,
    0.1659
  ],
  [
    1607986800,
    0.17137
  ],
  [
    1607900400,
    0.17496
  ],
  [
    1607814000,
    0.15954
  ],
  [
    1607727600,
    0.1563
  ],
  [
    1607641200,
    0.16498
  ],
  [
    1607554800,
    0.17282
  ],
  [
    1607468400,
    0.15213
  ],
  [
    1607382000,
    0.16849
  ],
  [
    1607295600,
    0.17509
  ],
  [
    1607209200,
    0.17049
  ],
  [
    1607122800,
    0.16302
  ],
  [
    1607036400,
    0.18489
  ],
  [
    1606950000,
    0.18411
  ],
  [
    1606863600,
    0.18553
  ],
  [
    1606777200,
    0.19896
  ],
  [
    1606690800,
    0.19355
  ],
  [
    1606604400,
    0.19905
  ],
  [
    1606518000,
    0.19397
  ],
  [
    1606431600,
    0.16788
  ],
  [
    1606345200,
    0.19785
  ],
  [
    1606258800,
    0.18124
  ],
  [
    1606172400,
    0.12988
  ],
  [
    1606086000,
    0.10545
  ],
  [
    1605999600,
    0.1058
  ],
  [
    1605913200,
    0.0863
  ],
  [
    1605826800,
    0.08364
  ],
  [
    1605740400,
    0.08368
  ],
  [
    1605654000,
    0.08602
  ],
  [
    1605567600,
    0.08199
  ],
  [
    1605481200,
    0.08028
  ],
  [
    1605394800,
    0.08131
  ],
  [
    1605308400,
    0.08241
  ],
  [
    1605222000,
    0.08069
  ],
  [
    1605135600,
    0.081
  ],
  [
    1605049200,
    0.07996
  ],
  [
    1604962800,
    0.07971
  ],
  [
    1604876400,
    0.08174
  ],
  [
    1604790000,
    0.08061
  ],
  [
    1604703600,
    0.08386
  ],
  [
    1604617200,
    0.08068
  ],
  [
    1604530800,
    0.07463
  ],
  [
    1604444400,
    0.07501
  ],
  [
    1604358000,
    0.0757
  ],
  [
    1604271600,
    0.07819
  ],
  [
    1604185200,
    0.07822
  ],
  [
    1604098800,
    0.07697
  ],
  [
    1604012400,
    0.07686
  ],
  [
    1603926000,
    0.07846
  ],
  [
    1603839600,
    0.08174
  ],
  [
    1603753200,
    0.08113
  ],
  [
    1603666800,
    0.08277
  ],
  [
    1603580400,
    0.08532
  ],
  [
    1603494000,
    0.08388
  ],
  [
    1603407600,
    0.08502
  ],
  [
    1603321200,
    0.0838
  ],
  [
    1603234800,
    0.08152
  ],
  [
    1603148400,
    0.08539
  ],
  [
    1603062000,
    0.07959
  ],
  [
    1602975600,
    0.08091
  ],
  [
    1602889200,
    0.07816
  ],
  [
    1602802800,
    0.07354
  ],
  [
    1602716400,
    0.07427
  ],
  [
    1602630000,
    0.07629
  ],
  [
    1602543600,
    0.07819
  ],
  [
    1602457200,
    0.07727
  ],
  [
    1602370800,
    0.0782
  ],
  [
    1602284400,
    0.07534
  ],
  [
    1602198000,
    0.07311
  ],
  [
    1602111600,
    0.07218
  ],
  [
    1602025200,
    0.07203
  ],
  [
    1601938800,
    0.07317
  ],
  [
    1601852400,
    0.0731
  ],
  [
    1601766000,
    0.07159
  ],
  [
    1601679600,
    0.07094
  ],
  [
    1601593200,
    0.07307
  ],
  [
    1601506800,
    0.0745
  ],
  [
    1601420400,
    0.07312
  ],
  [
    1601334000,
    0.07505
  ],
  [
    1601247600,
    0.0733
  ],
  [
    1601161200,
    0.07366
  ],
  [
    1601074800,
    0.07469
  ],
  [
    1600988400,
    0.06997
  ],
  [
    1600902000,
    0.06779
  ],
  [
    1600815600,
    0.0715
  ],
  [
    1600729200,
    0.07088
  ],
  [
    1600642800,
    0.07669
  ],
  [
    1600556400,
    0.07876
  ],
  [
    1600470000,
    0.07856
  ],
  [
    1600383600,
    0.08049
  ],
  [
    1600297200,
    0.0805
  ],
  [
    1600210800,
    0.07834
  ],
  [
    1600124400,
    0.08045
  ],
  [
    1600038000,
    0.08002
  ],
  [
    1599951600,
    0.08367
  ],
  [
    1599865200,
    0.08404
  ],
  [
    1599778800,
    0.08115
  ],
  [
    1599692400,
    0.07886
  ],
  [
    1599606000,
    0.07742
  ],
  [
    1599519600,
    0.07844
  ],
  [
    1599433200,
    0.07777
  ],
  [
    1599346800,
    0.07421
  ],
  [
    1599260400,
    0.08116
  ],
  [
    1599174000,
    0.084
  ],
  [
    1599087600,
    0.09283
  ],
  [
    1599001200,
    0.09911
  ],
  [
    1598914800,
    0.09759
  ],
  [
    1598828400,
    0.09787
  ],
  [
    1598742000,
    0.09641
  ],
  [
    1598655600,
    0.0953
  ],
  [
    1598569200,
    0.09292
  ],
  [
    1598482800,
    0.09836
  ],
  [
    1598396400,
    0.09782
  ],
  [
    1598310000,
    0.10359
  ],
  [
    1598223600,
    0.10155
  ],
  [
    1598137200,
    0.10214
  ],
  [
    1598050800,
    0.10091
  ],
  [
    1597964400,
    0.1055
  ],
  [
    1597878000,
    0.1023
  ],
  [
    1597791600,
    0.10895
  ],
  [
    1597705200,
    0.11596
  ],
  [
    1597618800,
    0.11274
  ],
  [
    1597532400,
    0.10709
  ],
  [
    1597446000,
    0.1053
  ],
  [
    1597359600,
    0.10193
  ],
  [
    1597273200,
    0.10126
  ],
  [
    1597186800,
    0.10012
  ],
  [
    1597100400,
    0.10524
  ],
  [
    1597014000,
    0.10488
  ],
  [
    1596927600,
    0.10336
  ],
  [
    1596841200,
    0.10288
  ],
  [
    1596754800,
    0.10605
  ],
  [
    1596668400,
    0.10724
  ],
  [
    1596582000,
    0.11083
  ],
  [
    1596495600,
    0.10532
  ],
  [
    1596409200,
    0.10349
  ],
  [
    1596322800,
    0.10665
  ],
  [
    1596236400,
    0.09652
  ],
  [
    1596150000,
    0.09645
  ],
  [
    1596063600,
    0.09561
  ],
  [
    1595977200,
    0.09664
  ],
  [
    1595890800,
    0.09434
  ],
  [
    1595804400,
    0.09907
  ],
  [
    1595718000,
    0.1002
  ],
  [
    1595631600,
    0.09561
  ],
  [
    1595545200,
    0.09812
  ],
  [
    1595458800,
    0.09677
  ],
  [
    1595372400,
    0.0957
  ],
  [
    1595286000,
    0.09454
  ],
  [
    1595199600,
    0.09959
  ],
  [
    1595113200,
    0.10088
  ],
  [
    1595026800,
    0.10414
  ],
  [
    1594940400,
    0.09763
  ],
  [
    1594854000,
    0.094
  ],
  [
    1594767600,
    0.09082
  ],
  [
    1594681200,
    0.08964
  ],
  [
    1594594800,
    0.09312
  ],
  [
    1594508400,
    0.09387
  ],
  [
    1594422000,
    0.08829
  ],
  [
    1594335600,
    0.09213
  ],
  [
    1594249200,
    0.08194
  ],
  [
    1594162800,
    0.07375
  ],
  [
    1594076400,
    0.07159
  ],
  [
    1593990000,
    0.06703
  ],
  [
    1593903600,
    0.06755
  ],
  [
    1593817200,
    0.06781
  ],
  [
    1593730800,
    0.06773
  ],
  [
    1593644400,
    0.06807
  ],
  [
    1593558000,
    0.06687
  ],
  [
    1593471600,
    0.06505
  ],
  [
    1593385200,
    0.06377
  ],
  [
    1593298800,
    0.06261
  ],
  [
    1593212400,
    0.06697
  ],
  [
    1593126000,
    0.06771
  ],
  [
    1593039600,
    0.06893
  ],
  [
    1592953200,
    0.07096
  ],
  [
    1592866800,
    0.07177
  ],
  [
    1592780400,
    0.06884
  ],
  [
    1592694000,
    0.06991
  ],
  [
    1592607600,
    0.06992
  ],
  [
    1592521200,
    0.07059
  ],
  [
    1592434800,
    0.07139
  ],
  [
    1592348400,
    0.07093
  ],
  [
    1592262000,
    0.07073
  ],
  [
    1592175600,
    0.07157
  ],
  [
    1592089200,
    0.07373
  ],
  [
    1592002800,
    0.07314
  ],
  [
    1591916400,
    0.0717
  ],
  [
    1591830000,
    0.07874
  ],
  [
    1591743600,
    0.07824
  ],
  [
    1591657200,
    0.07932
  ],
  [
    1591570800,
    0.0786
  ],
  [
    1591484400,
    0.08003
  ],
  [
    1591398000,
    0.0797
  ],
  [
    1591311600,
    0.08204
  ],
  [
    1591225200,
    0.08252
  ],
  [
    1591138800,
    0.08014
  ],
  [
    1591052400,
    0.07578
  ],
  [
    1590966000,
    0.07079
  ],
  [
    1590879600,
    0.07267
  ],
  [
    1590793200,
    0.06749
  ],
  [
    1590706800,
    0.06925
  ],
  [
    1590620400,
    0.06576
  ],
  [
    1590534000,
    0.06481
  ],
  [
    1590447600,
    0.06561
  ],
  [
    1590361200,
    0.06633
  ],
  [
    1590274800,
    0.0675
  ],
  [
    1590188400,
    0.06837
  ],
  [
    1590102000,
    0.06685
  ],
  [
    1590015600,
    0.07079
  ],
  [
    1589929200,
    0.06953
  ],
  [
    1589842800,
    0.06995
  ],
  [
    1589756400,
    0.0696
  ],
  [
    1589670000,
    0.068
  ],
  [
    1589583600,
    0.06753
  ],
  [
    1589497200,
    0.06865
  ],
  [
    1589410800,
    0.06976
  ],
  [
    1589324400,
    0.0696
  ],
  [
    1589238000,
    0.0619
  ],
  [
    1589151600,
    0.0641
  ],
  [
    1589065200,
    0.07162
  ],
  [
    1588978800,
    0.0729
  ],
  [
    1588892400,
    0.07249
  ],
  [
    1588806000,
    0.07172
  ],
  [
    1588719600,
    0.07217
  ],
  [
    1588633200,
    0.07321
  ],
  [
    1588546800,
    0.07292
  ],
  [
    1588460400,
    0.07531
  ],
  [
    1588374000,
    0.072
  ],
  [
    1588287600,
    0.06892
  ],
  [
    1588201200,
    0.07194
  ],
  [
    1588114800,
    0.06831
  ],
  [
    1588028400,
    0.06815
  ],
  [
    1587942000,
    0.06205
  ],
  [
    1587855600,
    0.06164
  ],
  [
    1587769200,
    0.06128
  ],
  [
    1587682800,
    0.06334
  ],
  [
    1587596400,
    0.05431
  ],
  [
    1587510000,
    0.05166
  ],
  [
    1587423600,
    0.0506
  ],
  [
    1587337200,
    0.04948
  ],
  [
    1587250800,
    0.05097
  ],
  [
    1587164400,
    0.04884
  ],
  [
    1587078000,
    0.04938
  ],
  [
    1586991600,
    0.04732
  ],
  [
    1586905200,
    0.0483
  ],
  [
    1586818800,
    0.04853
  ],
  [
    1586732400,
    0.04951
  ],
  [
    1586646000,
    0.04955
  ],
  [
    1586559600,
    0.04755
  ],
  [
    1586473200,
    0.05188
  ],
  [
    1586386800,
    0.05075
  ],
  [
    1586300400,
    0.04849
  ],
  [
    1586214000,
    0.04932
  ],
  [
    1586127600,
    0.04328
  ],
  [
    1586041200,
    0.04167
  ],
  [
    1585954800,
    0.04147
  ],
  [
    1585868400,
    0.04154
  ],
  [
    1585782000,
    0.04066
  ],
  [
    1585695600,
    0.04066
  ],
  [
    1585609200,
    0.04043
  ],
  [
    1585522800,
    0.03813
  ],
  [
    1585436400,
    0.0398
  ],
  [
    1585350000,
    0.04228
  ],
  [
    1585263600,
    0.04173
  ],
  [
    1585177200,
    0.04169
  ],
  [
    1585090800,
    0.04053
  ],
  [
    1585004400,
    0.03915
  ],
  [
    1584918000,
    0.03743
  ],
  [
    1584831600,
    0.03948
  ],
  [
    1584745200,
    0.03969
  ],
  [
    1584658800,
    0.04221
  ],
  [
    1584572400,
    0.03637
  ],
  [
    1584486000,
    0.03788
  ],
  [
    1584399600,
    0.0348
  ],
  [
    1584313200,
    0.03808
  ],
  [
    1584226800,
    0.0378
  ],
  [
    1584140400,
    0.03996
  ],
  [
    1584054000,
    0.03883
  ],
  [
    1583967600,
    0.05035
  ],
  [
    1583881200,
    0.05236
  ],
  [
    1583794800,
    0.0513
  ],
  [
    1583708400,
    0.05165
  ],
  [
    1583622000,
    0.05869
  ],
  [
    1583535600,
    0.0605
  ],
  [
    1583449200,
    0.05995
  ],
  [
    1583362800,
    0.05846
  ],
  [
    1583276400,
    0.05832
  ],
  [
    1583190000,
    0.05959
  ],
  [
    1583103600,
    0.0564
  ],
  [
    1583017200,
    0.05777
  ],
  [
    1582930800,
    0.05876
  ],
  [
    1582844400,
    0.0602
  ],
  [
    1582758000,
    0.05906
  ],
  [
    1582671600,
    0.06526
  ],
  [
    1582585200,
    0.06911
  ],
  [
    1582498800,
    0.07284
  ],
  [
    1582412400,
    0.07016
  ],
  [
    1582326000,
    0.07042
  ],
  [
    1582239600,
    0.07071
  ],
  [
    1582153200,
    0.07194
  ],
  [
    1582066800,
    0.07598
  ],
  [
    1581980400,
    0.07121
  ],
  [
    1581894000,
    0.07327
  ],
  [
    1581807600,
    0.07955
  ],
  [
    1581721200,
    0.08565
  ],
  [
    1581634800,
    0.08274
  ],
  [
    1581548400,
    0.08101
  ],
  [
    1581462000,
    0.07432
  ],
  [
    1581375600,
    0.07037
  ],
  [
    1581289200,
    0.07247
  ],
  [
    1581202800,
    0.07106
  ],
  [
    1581116400,
    0.07177
  ],
  [
    1581030000,
    0.07046
  ],
  [
    1580943600,
    0.06859
  ],
  [
    1580857200,
    0.06565
  ],
  [
    1580770800,
    0.06379
  ],
  [
    1580684400,
    0.06431
  ],
  [
    1580598000,
    0.06187
  ],
  [
    1580511600,
    0.06102
  ],
  [
    1580425200,
    0.06275
  ],
  [
    1580338800,
    0.06096
  ],
  [
    1580252400,
    0.06037
  ],
  [
    1580166000,
    0.05989
  ],
  [
    1580079600,
    0.05787
  ],
  [
    1579993200,
    0.05667
  ],
  [
    1579906800,
    0.05747
  ],
  [
    1579820400,
    0.05825
  ],
  [
    1579734000,
    0.06171
  ],
  [
    1579647600,
    0.06289
  ],
  [
    1579561200,
    0.06327
  ],
  [
    1579474800,
    0.05973
  ],
  [
    1579388400,
    0.06169
  ],
  [
    1579302000,
    0.06135
  ],
  [
    1579215600,
    0.05364
  ],
  [
    1579129200,
    0.05509
  ],
  [
    1579042800,
    0.05272
  ],
  [
    1578956400,
    0.04806
  ],
  [
    1578870000,
    0.0486
  ],
  [
    1578783600,
    0.0486
  ],
  [
    1578697200,
    0.04753
  ],
  [
    1578610800,
    0.04709
  ],
  [
    1578524400,
    0.04712
  ],
  [
    1578438000,
    0.04853
  ],
  [
    1578351600,
    0.0495
  ],
  [
    1578265200,
    0.04535
  ],
  [
    1578178800,
    0.04552
  ],
  [
    1578092400,
    0.0455
  ],
  [
    1578006000,
    0.04382
  ],
  [
    1577919600,
    0.04528
  ],
  [
    1577833200,
    0.045
  ],
  [
    1577746800,
    0.04574
  ],
  [
    1577660400,
    0.0463
  ],
  [
    1577574000,
    0.04603
  ],
  [
    1577487600,
    0.04578
  ],
  [
    1577401200,
    0.04467
  ],
  [
    1577314800,
    0.04446
  ],
  [
    1577228400,
    0.04514
  ],
  [
    1577142000,
    0.04486
  ],
  [
    1577055600,
    0.04723
  ],
  [
    1576969200,
    0.04563
  ],
  [
    1576882800,
    0.04625
  ],
  [
    1576796400,
    0.0451
  ],
  [
    1576710000,
    0.04728
  ],
  [
    1576623600,
    0.04357
  ],
  [
    1576537200,
    0.04765
  ],
  [
    1576450800,
    0.05097
  ],
  [
    1576364400,
    0.05086
  ],
  [
    1576278000,
    0.05262
  ],
  [
    1576191600,
    0.0521
  ],
  [
    1576105200,
    0.05274
  ],
  [
    1576018800,
    0.05314
  ],
  [
    1575932400,
    0.05444
  ],
  [
    1575846000,
    0.05583
  ],
  [
    1575759600,
    0.05549
  ],
  [
    1575673200,
    0.05524
  ],
  [
    1575586800,
    0.05535
  ],
  [
    1575500400,
    0.05476
  ],
  [
    1575414000,
    0.05599
  ],
  [
    1575327600,
    0.05676
  ],
  [
    1575241200,
    0.05731
  ],
  [
    1575154800,
    0.05746
  ],
  [
    1575068400,
    0.059
  ],
  [
    1574982000,
    0.05784
  ],
  [
    1574895600,
    0.05832
  ],
  [
    1574809200,
    0.0576
  ],
  [
    1574722800,
    0.05802
  ],
  [
    1574636400,
    0.05743
  ],
  [
    1574550000,
    0.06092
  ],
  [
    1574463600,
    0.05962
  ],
  [
    1574377200,
    0.06071
  ],
  [
    1574290800,
    0.06413
  ],
  [
    1574204400,
    0.06533
  ],
  [
    1574118000,
    0.06685
  ],
  [
    1574031600,
    0.07188
  ],
  [
    1573945200,
    0.0713
  ],
  [
    1573858800,
    0.07238
  ],
  [
    1573772400,
    0.07382
  ],
  [
    1573686000,
    0.07585
  ],
  [
    1573599600,
    0.07672
  ],
  [
    1573513200,
    0.0786
  ],
  [
    1573426800,
    0.0797
  ],
  [
    1573340400,
    0.07284
  ],
  [
    1573254000,
    0.07155
  ],
  [
    1573167600,
    0.07503
  ],
  [
    1573081200,
    0.07828
  ],
  [
    1572994800,
    0.08298
  ],
  [
    1572908400,
    0.06947
  ],
  [
    1572822000,
    0.06896
  ],
  [
    1572735600,
    0.07004
  ],
  [
    1572649200,
    0.06753
  ],
  [
    1572562800,
    0.06386
  ],
  [
    1572476400,
    0.0646
  ],
  [
    1572390000,
    0.06629
  ],
  [
    1572303600,
    0.0651
  ],
  [
    1572217200,
    0.06374
  ],
  [
    1572130800,
    0.06246
  ],
  [
    1572044400,
    0.06354
  ],
  [
    1571958000,
    0.06041
  ],
  [
    1571871600,
    0.05967
  ],
  [
    1571785200,
    0.06331
  ],
  [
    1571698800,
    0.06375
  ],
  [
    1571612400,
    0.06312
  ],
  [
    1571526000,
    0.06295
  ],
  [
    1571439600,
    0.06392
  ],
  [
    1571353200,
    0.06371
  ],
  [
    1571266800,
    0.06194
  ],
  [
    1571180400,
    0.06361
  ],
  [
    1571094000,
    0.06401
  ],
  [
    1571007600,
    0.06053
  ],
  [
    1570921200,
    0.05956
  ],
  [
    1570834800,
    0.06076
  ],
  [
    1570748400,
    0.06201
  ],
  [
    1570662000,
    0.0629
  ],
  [
    1570575600,
    0.0614
  ],
  [
    1570489200,
    0.06122
  ],
  [
    1570402800,
    0.05813
  ],
  [
    1570316400,
    0.05886
  ],
  [
    1570230000,
    0.05914
  ],
  [
    1570143600,
    0.05876
  ],
  [
    1570057200,
    0.05935
  ],
  [
    1569970800,
    0.05944
  ],
  [
    1569884400,
    0.06004
  ],
  [
    1569798000,
    0.0578
  ],
  [
    1569711600,
    0.05939
  ],
  [
    1569625200,
    0.05896
  ],
  [
    1569538800,
    0.05809
  ],
  [
    1569452400,
    0.05682
  ],
  [
    1569366000,
    0.0564
  ],
  [
    1569279600,
    0.06389
  ],
  [
    1569193200,
    0.06894
  ],
  [
    1569106800,
    0.07101
  ],
  [
    1569020400,
    0.07432
  ],
  [
    1568934000,
    0.08096
  ],
  [
    1568847600,
    0.08223
  ],
  [
    1568761200,
    0.0629
  ],
  [
    1568674800,
    0.05858
  ],
  [
    1568588400,
    0.05792
  ],
  [
    1568502000,
    0.05812
  ],
  [
    1568415600,
    0.05679
  ],
  [
    1568325600,
    0.05538
  ],
  [
    1568242800,
    0.0593
  ],
  [
    1568156400,
    0.05874
  ],
  [
    1568070000,
    0.05961
  ],
  [
    1567983600,
    0.05837
  ],
  [
    1567897200,
    0.05954
  ],
  [
    1567810800,
    0.05838
  ],
  [
    1567724400,
    0.06047
  ],
  [
    1567638000,
    0.06143
  ],
  [
    1567551600,
    0.06351
  ],
  [
    1567465200,
    0.06299
  ],
  [
    1567378800,
    0.06241
  ],
  [
    1567292400,
    0.06316
  ],
  [
    1567206000,
    0.06216
  ],
  [
    1567119600,
    0.06172
  ],
  [
    1567033200,
    0.06576
  ],
  [
    1566946800,
    0.06975
  ],
  [
    1566860400,
    0.06939
  ],
  [
    1566774000,
    0.07215
  ],
  [
    1566687600,
    0.06808
  ],
  [
    1566601200,
    0.0687
  ],
  [
    1566514800,
    0.06664
  ],
  [
    1566428400,
    0.06803
  ],
  [
    1566342000,
    0.07007
  ],
  [
    1566255600,
    0.07105
  ],
  [
    1566169200,
    0.06963
  ],
  [
    1566082800,
    0.06821
  ],
  [
    1565996400,
    0.06941
  ],
  [
    1565910000,
    0.07083
  ],
  [
    1565823600,
    0.07114
  ],
  [
    1565737200,
    0.07529
  ],
  [
    1565650800,
    0.07613
  ],
  [
    1565564400,
    0.07752
  ],
  [
    1565478000,
    0.07428
  ],
  [
    1565391600,
    0.07319
  ],
  [
    1565305200,
    0.07702
  ],
  [
    1565218800,
    0.07854
  ],
  [
    1565132400,
    0.07885
  ],
  [
    1565046000,
    0.08267
  ],
  [
    1564959600,
    0.08181
  ],
  [
    1564873200,
    0.08219
  ],
  [
    1564786800,
    0.08225
  ],
  [
    1564700400,
    0.08299
  ],
  [
    1564614000,
    0.08352
  ],
  [
    1564527600,
    0.08326
  ],
  [
    1564441200,
    0.08296
  ],
  [
    1564354800,
    0.08485
  ],
  [
    1564268400,
    0.0851
  ],
  [
    1564182000,
    0.08748
  ],
  [
    1564095600,
    0.08615
  ],
  [
    1564009200,
    0.08133
  ],
  [
    1563922800,
    0.08633
  ],
  [
    1563836400,
    0.08852
  ],
  [
    1563750000,
    0.09099
  ],
  [
    1563663600,
    0.09578
  ],
  [
    1563577200,
    0.09142
  ],
  [
    1563490800,
    0.08871
  ],
  [
    1563404400,
    0.08217
  ],
  [
    1563318000,
    0.07956
  ],
  [
    1563231600,
    0.08591
  ],
  [
    1563145200,
    0.09027
  ],
  [
    1563058800,
    0.09556
  ],
  [
    1562972400,
    0.09841
  ],
  [
    1562886000,
    0.08785
  ],
  [
    1562799600,
    0.09573
  ],
  [
    1562713200,
    0.10173
  ],
  [
    1562626800,
    0.10458
  ],
  [
    1562540400,
    0.10461
  ],
  [
    1562454000,
    0.10333
  ],
  [
    1562367600,
    0.1004
  ],
  [
    1562281200,
    0.10254
  ],
  [
    1562194800,
    0.10494
  ],
  [
    1562108400,
    0.1032
  ],
  [
    1562022000,
    0.10481
  ],
  [
    1561935600,
    0.1072
  ],
  [
    1561849200,
    0.1153
  ],
  [
    1561762800,
    0.11104
  ],
  [
    1561676400,
    0.11016
  ],
  [
    1561590000,
    0.12262
  ],
  [
    1561503600,
    0.12418
  ],
  [
    1561417200,
    0.12735
  ],
  [
    1561330800,
    0.12954
  ],
  [
    1561244400,
    0.12758
  ],
  [
    1561158000,
    0.12272
  ],
  [
    1561071600,
    0.12195
  ],
  [
    1560985200,
    0.12428
  ],
  [
    1560898800,
    0.12368
  ],
  [
    1560812400,
    0.13097
  ],
  [
    1560726000,
    0.12894
  ],
  [
    1560639600,
    0.12599
  ],
  [
    1560553200,
    0.12441
  ],
  [
    1560466800,
    0.12499
  ],
  [
    1560380400,
    0.12643
  ],
  [
    1560294000,
    0.12254
  ],
  [
    1560207600,
    0.12365
  ],
  [
    1560121200,
    0.11802
  ],
  [
    1560034800,
    0.12467
  ],
  [
    1559948400,
    0.12846
  ],
  [
    1559862000,
    0.12409
  ],
  [
    1559775600,
    0.12249
  ],
  [
    1559689200,
    0.11974
  ],
  [
    1559602800,
    0.13253
  ],
  [
    1559516400,
    0.13631
  ],
  [
    1559430000,
    0.13411
  ],
  [
    1559343600,
    0.13246
  ],
  [
    1559257200,
    0.12913
  ],
  [
    1559170800,
    0.13693
  ],
  [
    1559084400,
    0.13921
  ],
  [
    1558998000,
    0.13569
  ],
  [
    1558911600,
    0.13203
  ],
  [
    1558825200,
    0.12518
  ],
  [
    1558738800,
    0.12663
  ],
  [
    1558652400,
    0.1255
  ],
  [
    1558566000,
    0.12439
  ],
  [
    1558479600,
    0.13436
  ],
  [
    1558393200,
    0.13431
  ],
  [
    1558306800,
    0.14359
  ],
  [
    1558220400,
    0.13201
  ],
  [
    1558134000,
    0.12619
  ],
  [
    1558047600,
    0.13862
  ],
  [
    1557961200,
    0.13534
  ],
  [
    1557874800,
    0.11105
  ],
  [
    1557788400,
    0.10073
  ],
  [
    1557702000,
    0.09882
  ],
  [
    1557615600,
    0.10413
  ],
  [
    1557529200,
    0.0923
  ],
  [
    1557442800,
    0.0898
  ],
  [
    1557356400,
    0.09408
  ],
  [
    1557270000,
    0.09526
  ],
  [
    1557183600,
    0.09769
  ],
  [
    1557097200,
    0.09821
  ],
  [
    1557010800,
    0.09904
  ],
  [
    1556924400,
    0.10328
  ],
  [
    1556838000,
    0.09988
  ],
  [
    1556751600,
    0.10027
  ],
  [
    1556665200,
    0.09958
  ],
  [
    1556578800,
    0.09546
  ],
  [
    1556492400,
    0.09742
  ],
  [
    1556406000,
    0.10031
  ],
  [
    1556319600,
    0.09933
  ],
  [
    1556233200,
    0.09923
  ],
  [
    1556146800,
    0.10314
  ],
  [
    1556060400,
    0.1111
  ],
  [
    1555974000,
    0.11461
  ],
  [
    1555887600,
    0.11281
  ],
  [
    1555801200,
    0.11536
  ],
  [
    1555714800,
    0.11524
  ],
  [
    1555628400,
    0.11788
  ],
  [
    1555542000,
    0.11594
  ],
  [
    1555455600,
    0.1154
  ],
  [
    1555369200,
    0.11403
  ],
  [
    1555282800,
    0.11784
  ],
  [
    1555196400,
    0.11563
  ],
  [
    1555110000,
    0.11427
  ],
  [
    1555023600,
    0.11633
  ],
  [
    1554937200,
    0.12591
  ],
  [
    1554850800,
    0.1256
  ],
  [
    1554764400,
    0.13144
  ],
  [
    1554678000,
    0.12966
  ],
  [
    1554591600,
    0.12448
  ],
  [
    1554505200,
    0.12636
  ],
  [
    1554418800,
    0.11917
  ],
  [
    1554332400,
    0.12101
  ],
  [
    1554246000,
    0.12098
  ],
  [
    1554159600,
    0.10981
  ],
  [
    1554073200,
    0.10755
  ],
  [
    1553986800,
    0.10743
  ],
  [
    1553900400,
    0.10793
  ],
  [
    1553814000,
    0.10749
  ],
  [
    1553727600,
    0.10793
  ],
  [
    1553641200,
    0.103
  ],
  [
    1553554800,
    0.10279
  ],
  [
    1553468400,
    0.10604
  ],
  [
    1553382000,
    0.10869
  ],
  [
    1553295600,
    0.10895
  ],
  [
    1553209200,
    0.10799
  ],
  [
    1553122800,
    0.11137
  ],
  [
    1553036400,
    0.11345
  ],
  [
    1552950000,
    0.1167
  ],
  [
    1552863600,
    0.10913
  ],
  [
    1552777200,
    0.10977
  ],
  [
    1552690800,
    0.1081
  ],
  [
    1552604400,
    0.10768
  ],
  [
    1552518000,
    0.10772
  ],
  [
    1552431600,
    0.10575
  ],
  [
    1552345200,
    0.10163
  ],
  [
    1552258800,
    0.10023
  ],
  [
    1552172400,
    0.08935
  ],
  [
    1552086000,
    0.08685
  ],
  [
    1551999600,
    0.08571
  ],
  [
    1551913200,
    0.08587
  ],
  [
    1551826800,
    0.08582
  ],
  [
    1551740400,
    0.08317
  ],
  [
    1551654000,
    0.0868
  ],
  [
    1551567600,
    0.08385
  ],
  [
    1551481200,
    0.087
  ],
  [
    1551394800,
    0.08551
  ],
  [
    1551308400,
    0.08483
  ],
  [
    1551222000,
    0.08675
  ],
  [
    1551135600,
    0.08755
  ],
  [
    1551049200,
    0.0849
  ],
  [
    1550962800,
    0.09449
  ],
  [
    1550876400,
    0.09089
  ],
  [
    1550790000,
    0.08868
  ],
  [
    1550703600,
    0.09095
  ],
  [
    1550617200,
    0.09175
  ],
  [
    1550530800,
    0.08304
  ],
  [
    1550444400,
    0.0795
  ],
  [
    1550358000,
    0.07843
  ],
  [
    1550271600,
    0.07931
  ],
  [
    1550185200,
    0.07635
  ],
  [
    1550098800,
    0.07687
  ],
  [
    1550012400,
    0.07697
  ],
  [
    1549926000,
    0.07772
  ],
  [
    1549839600,
    0.08008
  ],
  [
    1549753200,
    0.08047
  ],
  [
    1549666800,
    0.08007
  ],
  [
    1549580400,
    0.07505
  ],
  [
    1549494000,
    0.07445
  ],
  [
    1549407600,
    0.07773
  ],
  [
    1549321200,
    0.08064
  ],
  [
    1549234800,
    0.08108
  ],
  [
    1549148400,
    0.08246
  ],
  [
    1549062000,
    0.08363
  ],
  [
    1548975600,
    0.0833
  ],
  [
    1548889200,
    0.08557
  ],
  [
    1548802800,
    0.08382
  ],
  [
    1548716400,
    0.08773
  ],
  [
    1548630000,
    0.09574
  ],
  [
    1548543600,
    0.10001
  ],
  [
    1548457200,
    0.10155
  ],
  [
    1548370800,
    0.10209
  ],
  [
    1548284400,
    0.10254
  ],
  [
    1548198000,
    0.10447
  ],
  [
    1548111600,
    0.10317
  ],
  [
    1548025200,
    0.10535
  ],
  [
    1547938800,
    0.10829
  ],
  [
    1547852400,
    0.10686
  ],
  [
    1547766000,
    0.10799
  ],
  [
    1547679600,
    0.10674
  ],
  [
    1547593200,
    0.10573
  ],
  [
    1547506800,
    0.10917
  ],
  [
    1547420400,
    0.10374
  ],
  [
    1547334000,
    0.10684
  ],
  [
    1547247600,
    0.10774
  ],
  [
    1547161200,
    0.10941
  ],
  [
    1547074800,
    0.12327
  ],
  [
    1546988400,
    0.12355
  ],
  [
    1546902000,
    0.12337
  ],
  [
    1546815600,
    0.12035
  ],
  [
    1546729200,
    0.11361
  ],
  [
    1546642800,
    0.11506
  ],
  [
    1546556400,
    0.11384
  ],
  [
    1546470000,
    0.11891
  ],
  [
    1546383600,
    0.1136
  ],
  [
    1546297200,
    0.11279
  ],
  [
    1546210800,
    0.11757
  ],
  [
    1546124400,
    0.12125
  ],
  [
    1546038000,
    0.1209
  ],
  [
    1545951600,
    0.11102
  ],
  [
    1545865200,
    0.12068
  ],
  [
    1545778800,
    0.1224
  ],
  [
    1545692400,
    0.13475
  ],
  [
    1545606000,
    0.12623
  ],
  [
    1545519600,
    0.11975
  ],
  [
    1545433200,
    0.1188
  ],
  [
    1545346800,
    0.12548
  ],
  [
    1545260400,
    0.11607
  ],
  [
    1545174000,
    0.1102
  ],
  [
    1545087600,
    0.10894
  ],
  [
    1545001200,
    0.09687
  ],
  [
    1544914800,
    0.09623
  ],
  [
    1544828400,
    0.0983
  ],
  [
    1544742000,
    0.10478
  ],
  [
    1544655600,
    0.11405
  ],
  [
    1544569200,
    0.11318
  ],
  [
    1544482800,
    0.11804
  ],
  [
    1544396400,
    0.12442
  ],
  [
    1544310000,
    0.1149
  ],
  [
    1544223600,
    0.11515
  ],
  [
    1544137200,
    0.12579
  ],
  [
    1544050800,
    0.13668
  ],
  [
    1543964400,
    0.14813
  ],
  [
    1543878000,
    0.1502
  ],
  [
    1543791600,
    0.16067
  ],
  [
    1543705200,
    0.16612
  ],
  [
    1543618800,
    0.15549
  ],
  [
    1543532400,
    0.1632
  ],
  [
    1543446000,
    0.1605
  ],
  [
    1543359600,
    0.14624
  ],
  [
    1543273200,
    0.14199
  ],
  [
    1543186800,
    0.15807
  ],
  [
    1543100400,
    0.15209
  ],
  [
    1543014000,
    0.17979
  ],
  [
    1542927600,
    0.19244
  ],
  [
    1542841200,
    0.2017
  ],
  [
    1542754800,
    0.19161
  ],
  [
    1542668400,
    0.22619
  ],
  [
    1542582000,
    0.24742
  ],
  [
    1542495600,
    0.24421
  ],
  [
    1542409200,
    0.23673
  ],
  [
    1542322800,
    0.22888
  ],
  [
    1542236400,
    0.22868
  ],
  [
    1542150000,
    0.25895
  ],
  [
    1542063600,
    0.26889
  ],
  [
    1541977200,
    0.27164
  ],
  [
    1541890800,
    0.25998
  ],
  [
    1541804400,
    0.25724
  ],
  [
    1541718000,
    0.2559
  ],
  [
    1541631600,
    0.25563
  ],
  [
    1541545200,
    0.26508
  ],
  [
    1541458800,
    0.24669
  ],
  [
    1541372400,
    0.24276
  ],
  [
    1541286000,
    0.23722
  ],
  [
    1541199600,
    0.23283
  ],
  [
    1541113200,
    0.22316
  ],
  [
    1541026800,
    0.2235
  ],
  [
    1540940400,
    0.22591
  ],
  [
    1540854000,
    0.22273
  ],
  [
    1540767600,
    0.23161
  ],
  [
    1540681200,
    0.22825
  ],
  [
    1540594800,
    0.23372
  ],
  [
    1540508400,
    0.23614
  ],
  [
    1540422000,
    0.23689
  ],
  [
    1540335600,
    0.24183
  ],
  [
    1540249200,
    0.24284
  ],
  [
    1540162800,
    0.23986
  ],
  [
    1540076400,
    0.2439
  ],
  [
    1539990000,
    0.24383
  ],
  [
    1539903600,
    0.23844
  ],
  [
    1539817200,
    0.24027
  ],
  [
    1539730800,
    0.2269
  ],
  [
    1539644400,
    0.22205
  ],
  [
    1539558000,
    0.21043
  ],
  [
    1539471600,
    0.21528
  ],
  [
    1539385200,
    0.21615
  ],
  [
    1539298800,
    0.21163
  ],
  [
    1539212400,
    0.24063
  ],
  [
    1539126000,
    0.24694
  ],
  [
    1539039600,
    0.24816
  ],
  [
    1538953200,
    0.24176
  ],
  [
    1538866800,
    0.24082
  ],
  [
    1538780400,
    0.24548
  ],
  [
    1538694000,
    0.24369
  ],
  [
    1538607600,
    0.24061
  ],
  [
    1538521200,
    0.25055
  ],
  [
    1538434800,
    0.25805
  ],
  [
    1538348400,
    0.25952
  ],
  [
    1538262000,
    0.25554
  ],
  [
    1538175600,
    0.25287
  ],
  [
    1538089200,
    0.25964
  ],
  [
    1538002800,
    0.24441
  ],
  [
    1537916400,
    0.2489
  ],
  [
    1537830000,
    0.26431
  ],
  [
    1537743600,
    0.28082
  ],
  [
    1537657200,
    0.23767
  ],
  [
    1537570800,
    0.2489
  ],
  [
    1537484400,
    0.24082
  ],
  [
    1537398000,
    0.20613
  ],
  [
    1537311600,
    0.20837
  ],
  [
    1537225200,
    0.19697
  ],
  [
    1537138800,
    0.20726
  ],
  [
    1537052400,
    0.20282
  ],
  [
    1536966000,
    0.20322
  ],
  [
    1536879600,
    0.20778
  ],
  [
    1536793200,
    0.2016
  ],
  [
    1536706800,
    0.19992
  ],
  [
    1536620400,
    0.19034
  ],
  [
    1536534000,
    0.19193
  ],
  [
    1536447600,
    0.1943
  ],
  [
    1536361200,
    0.20758
  ],
  [
    1536274800,
    0.20405
  ],
  [
    1536188400,
    0.21198
  ],
  [
    1536102000,
    0.23278
  ],
  [
    1536015600,
    0.22218
  ],
  [
    1535929200,
    0.22424
  ],
  [
    1535842800,
    0.23019
  ],
  [
    1535756400,
    0.22089
  ],
  [
    1535670000,
    0.22022
  ],
  [
    1535583600,
    0.22759
  ],
  [
    1535497200,
    0.23263
  ],
  [
    1535410800,
    0.21922
  ],
  [
    1535324400,
    0.21408
  ],
  [
    1535238000,
    0.217
  ],
  [
    1535151600,
    0.22315
  ],
  [
    1535065200,
    0.21774
  ],
  [
    1534978800,
    0.20702
  ],
  [
    1534892400,
    0.22338
  ],
  [
    1534806000,
    0.21765
  ],
  [
    1534719600,
    0.22849
  ],
  [
    1534633200,
    0.22793
  ],
  [
    1534546800,
    0.23658
  ],
  [
    1534460400,
    0.2155
  ],
  [
    1534374000,
    0.22272
  ],
  [
    1534287600,
    0.21288
  ],
  [
    1534201200,
    0.22418
  ],
  [
    1534114800,
    0.22458
  ],
  [
    1534028400,
    0.22265
  ],
  [
    1533942000,
    0.21665
  ],
  [
    1533855600,
    0.22384
  ],
  [
    1533769200,
    0.19971
  ],
  [
    1533682800,
    0.23305
  ],
  [
    1533596400,
    0.233
  ],
  [
    1533510000,
    0.24436
  ],
  [
    1533423600,
    0.24049
  ],
  [
    1533337200,
    0.25969
  ],
  [
    1533250800,
    0.26508
  ],
  [
    1533164400,
    0.27258
  ],
  [
    1533078000,
    0.2747
  ],
  [
    1532991600,
    0.29466
  ],
  [
    1532905200,
    0.30632
  ],
  [
    1532818800,
    0.31305
  ],
  [
    1532732400,
    0.32114
  ],
  [
    1532646000,
    0.30179
  ],
  [
    1532559600,
    0.33997
  ],
  [
    1532473200,
    0.29982
  ],
  [
    1532386800,
    0.28479
  ],
  [
    1532300400,
    0.28715
  ],
  [
    1532214000,
    0.29303
  ],
  [
    1532127600,
    0.27169
  ],
  [
    1532041200,
    0.30675
  ],
  [
    1531954800,
    0.28941
  ],
  [
    1531868400,
    0.25096
  ],
  [
    1531782000,
    0.23373
  ],
  [
    1531695600,
    0.2189
  ],
  [
    1531609200,
    0.20852
  ],
  [
    1531522800,
    0.20044
  ],
  [
    1531436400,
    0.18318
  ],
  [
    1531350000,
    0.1897
  ],
  [
    1531263600,
    0.19362
  ],
  [
    1531177200,
    0.20855
  ],
  [
    1531090800,
    0.21087
  ],
  [
    1531004400,
    0.20173
  ],
  [
    1530918000,
    0.20723
  ],
  [
    1530831600,
    0.20232
  ],
  [
    1530745200,
    0.21175
  ],
  [
    1530658800,
    0.20618
  ],
  [
    1530572400,
    0.21251
  ],
  [
    1530486000,
    0.1991
  ],
  [
    1530399600,
    0.19217
  ],
  [
    1530313200,
    0.18591
  ],
  [
    1530226800,
    0.17884
  ],
  [
    1530140400,
    0.19106
  ],
  [
    1530054000,
    0.18855
  ],
  [
    1529967600,
    0.19756
  ],
  [
    1529881200,
    0.1937
  ],
  [
    1529794800,
    0.20292
  ],
  [
    1529708400,
    0.20183
  ],
  [
    1529622000,
    0.22737
  ],
  [
    1529535600,
    0.23208
  ],
  [
    1529449200,
    0.23464
  ],
  [
    1529362800,
    0.23451
  ],
  [
    1529276400,
    0.23209
  ],
  [
    1529190000,
    0.23355
  ],
  [
    1529103600,
    0.23256
  ],
  [
    1529017200,
    0.24067
  ],
  [
    1528930800,
    0.22141
  ],
  [
    1528844400,
    0.22931
  ],
  [
    1528758000,
    0.25106
  ],
  [
    1528671600,
    0.24402
  ],
  [
    1528585200,
    0.28342
  ],
  [
    1528498800,
    0.29123
  ],
  [
    1528412400,
    0.29236
  ],
  [
    1528326000,
    0.29988
  ],
  [
    1528239600,
    0.29394
  ],
  [
    1528153200,
    0.29168
  ],
  [
    1528066800,
    0.30304
  ],
  [
    1527980400,
    0.29857
  ],
  [
    1527894000,
    0.28849
  ],
  [
    1527807600,
    0.29247
  ],
  [
    1527721200,
    0.27299
  ],
  [
    1527634800,
    0.28154
  ],
  [
    1527548400,
    0.25327
  ],
  [
    1527462000,
    0.27616
  ],
  [
    1527375600,
    0.28416
  ],
  [
    1527289200,
    0.28562
  ],
  [
    1527202800,
    0.29393
  ],
  [
    1527116400,
    0.28275
  ],
  [
    1527030000,
    0.30497
  ],
  [
    1526943600,
    0.31763
  ],
  [
    1526857200,
    0.33274
  ],
  [
    1526770800,
    0.31634
  ],
  [
    1526684400,
    0.31855
  ],
  [
    1526598000,
    0.31425
  ],
  [
    1526511600,
    0.33194
  ],
  [
    1526425200,
    0.34992
  ],
  [
    1526338800,
    0.36641
  ],
  [
    1526252400,
    0.3782
  ],
  [
    1526166000,
    0.35656
  ],
  [
    1526079600,
    0.31579
  ],
  [
    1525993200,
    0.36041
  ],
  [
    1525906800,
    0.3784
  ],
  [
    1525820400,
    0.38701
  ],
  [
    1525734000,
    0.39467
  ],
  [
    1525647600,
    0.41512
  ],
  [
    1525561200,
    0.43135
  ],
  [
    1525474800,
    0.42912
  ],
  [
    1525388400,
    0.43932
  ],
  [
    1525302000,
    0.4416
  ],
  [
    1525215600,
    0.43753
  ],
  [
    1525129200,
    0.4192
  ],
  [
    1525042800,
    0.45787
  ],
  [
    1524956400,
    0.43109
  ],
  [
    1524870000,
    0.39986
  ],
  [
    1524783600,
    0.38439
  ],
  [
    1524697200,
    0.34337
  ],
  [
    1524610800,
    0.40501
  ],
  [
    1524524400,
    0.37244
  ],
  [
    1524438000,
    0.37069
  ],
  [
    1524351600,
    0.36849
  ],
  [
    1524265200,
    0.39247
  ],
  [
    1524178800,
    0.36417
  ],
  [
    1524092400,
    0.35236
  ],
  [
    1524006000,
    0.3028
  ],
  [
    1523919600,
    0.28335
  ],
  [
    1523833200,
    0.29089
  ],
  [
    1523746800,
    0.25183
  ],
  [
    1523660400,
    0.24927
  ],
  [
    1523574000,
    0.23938
  ],
  [
    1523487600,
    0.20768
  ],
  [
    1523401200,
    0.19814
  ],
  [
    1523314800,
    0.19595
  ],
  [
    1523228400,
    0.20617
  ],
  [
    1523142000,
    0.20273
  ],
  [
    1523055600,
    0.1931
  ],
  [
    1522969200,
    0.19757
  ],
  [
    1522882800,
    0.20266
  ],
  [
    1522796400,
    0.23257
  ],
  [
    1522710000,
    0.22385
  ],
  [
    1522623600,
    0.2061
  ],
  [
    1522537200,
    0.20875
  ],
  [
    1522450800,
    0.18615
  ],
  [
    1522364400,
    0.19668
  ],
  [
    1522278000,
    0.21948
  ],
  [
    1522191600,
    0.22317
  ],
  [
    1522105200,
    0.22845
  ],
  [
    1522018800,
    0.24241
  ],
  [
    1521932400,
    0.23665
  ],
  [
    1521846000,
    0.23331
  ],
  [
    1521759600,
    0.2402
  ],
  [
    1521673200,
    0.25457
  ],
  [
    1521586800,
    0.27236
  ],
  [
    1521500400,
    0.24779
  ],
  [
    1521414000,
    0.2192
  ],
  [
    1521327600,
    0.20986
  ],
  [
    1521241200,
    0.23562
  ],
  [
    1521154800,
    0.2419
  ],
  [
    1521068400,
    0.2479
  ],
  [
    1520982000,
    0.28642
  ],
  [
    1520895600,
    0.29182
  ],
  [
    1520809200,
    0.3032
  ],
  [
    1520722800,
    0.29351
  ],
  [
    1520636400,
    0.30727
  ],
  [
    1520550000,
    0.31317
  ],
  [
    1520463600,
    0.32835
  ],
  [
    1520377200,
    0.3407
  ],
  [
    1520290800,
    0.35835
  ],
  [
    1520204400,
    0.35879
  ],
  [
    1520118000,
    0.32775
  ],
  [
    1520031600,
    0.32256
  ],
  [
    1519945200,
    0.34196
  ],
  [
    1519858800,
    0.3359
  ],
  [
    1519772400,
    0.36172
  ],
  [
    1519686000,
    0.36633
  ],
  [
    1519599600,
    0.36224
  ],
  [
    1519513200,
    0.35314
  ],
  [
    1519426800,
    0.38099
  ],
  [
    1519340400,
    0.36678
  ],
  [
    1519254000,
    0.39775
  ],
  [
    1519167600,
    0.40686
  ],
  [
    1519081200,
    0.45291
  ],
  [
    1518994800,
    0.44998
  ],
  [
    1518908400,
    0.48125
  ],
  [
    1518822000,
    0.45721
  ],
  [
    1518735600,
    0.45425
  ],
  [
    1518649200,
    0.45202
  ],
  [
    1518562800,
    0.41126
  ],
  [
    1518476400,
    0.40058
  ],
  [
    1518390000,
    0.37612
  ],
  [
    1518303600,
    0.40885
  ],
  [
    1518217200,
    0.39914
  ],
  [
    1518130800,
    0.35945
  ],
  [
    1518044400,
    0.34609
  ],
  [
    1517958000,
    0.37007
  ],
  [
    1517871600,
    0.3354
  ],
  [
    1517785200,
    0.38782
  ],
  [
    1517698800,
    0.42599
  ],
  [
    1517612400,
    0.39756
  ],
  [
    1517526000,
    0.45327
  ],
  [
    1517439600,
    0.53418
  ],
  [
    1517353200,
    0.51009
  ],
  [
    1517266800,
    0.58878
  ],
  [
    1517180400,
    0.62888
  ],
  [
    1517094000,
    0.61529
  ],
  [
    1517007600,
    0.62867
  ],
  [
    1516921200,
    0.60191
  ],
  [
    1516834800,
    0.56235
  ],
  [
    1516748400,
    0.50145
  ],
  [
    1516662000,
    0.47361
  ],
  [
    1516575600,
    0.45602
  ],
  [
    1516489200,
    0.53866
  ],
  [
    1516402800,
    0.49811
  ],
  [
    1516316400,
    0.51112
  ],
  [
    1516230000,
    0.46145
  ],
  [
    1516143600,
    0.38299
  ],
  [
    1516057200,
    0.61592
  ],
  [
    1515970800,
    0.64095
  ],
  [
    1515884400,
    0.66594
  ],
  [
    1515798000,
    0.67618
  ],
  [
    1515711600,
    0.5368
  ],
  [
    1515625200,
    0.56709
  ],
  [
    1515538800,
    0.56816
  ],
  [
    1515452400,
    0.62789
  ],
  [
    1515366000,
    0.69716
  ],
  [
    1515279600,
    0.69697
  ],
  [
    1515193200,
    0.64272
  ],
  [
    1515106800,
    0.77567
  ],
  [
    1515020400,
    0.8509
  ],
  [
    1514934000,
    0.5602
  ],
  [
    1514847600,
    0.48898
  ],
  [
    1514761200,
    0.35629
  ],
  [
    1514674800,
    0.32474
  ],
  [
    1514588400,
    0.31959
  ],
  [
    1514502000,
    0.22358
  ],
  [
    1514415600,
    0.2313
  ],
  [
    1514329200,
    0.22214
  ],
  [
    1514242800,
    0.22832
  ],
  [
    1514156400,
    0.22209
  ],
  [
    1514070000,
    0.24726
  ],
  [
    1513983600,
    0.23685
  ],
  [
    1513897200,
    0.25614
  ],
  [
    1513810800,
    0.25119
  ],
  [
    1513724400,
    0.25725
  ],
  [
    1513638000,
    0.2723
  ],
  [
    1513551600,
    0.26887
  ],
  [
    1513465200,
    0.23307
  ],
  [
    1513378800,
    0.19592
  ],
  [
    1513292400,
    0.18028
  ],
  [
    1513206000,
    0.15098
  ],
  [
    1513119600,
    0.16013
  ],
  [
    1513033200,
    0.14335
  ],
  [
    1512946800,
    0.1184
  ],
  [
    1512860400,
    0.13509
  ],
  [
    1512774000,
    0.13497
  ],
  [
    1512687600,
    0.14062
  ],
  [
    1512601200,
    0.14245
  ],
  [
    1512514800,
    0.12152
  ],
  [
    1512428400,
    0.09534
  ],
  [
    1512342000,
    0.0914
  ],
  [
    1512255600,
    0.0958
  ],
  [
    1512169200,
    0.09018
  ],
  [
    1512082800,
    0.0706
  ],
  [
    1511996400,
    0.06877
  ],
  [
    1511910000,
    0.0772
  ],
  [
    1511823600,
    0.05626
  ],
  [
    1511737200,
    0.0481
  ],
  [
    1511650800,
    0.04389
  ],
  [
    1511564400,
    0.04035
  ],
  [
    1511478000,
    0.04242
  ],
  [
    1511391600,
    0.04059
  ],
  [
    1511305200,
    0.03755
  ],
  [
    1511218800,
    0.03931
  ],
  [
    1511132400,
    0.03701
  ],
  [
    1511046000,
    0.03638
  ],
  [
    1510959600,
    0.03613
  ],
  [
    1510873200,
    0.0373
  ],
  [
    1510786800,
    0.03625
  ],
  [
    1510700400,
    0.03535
  ],
  [
    1510614000,
    0.03055
  ],
  [
    1510527600,
    0.0278
  ],
  [
    1510441200,
    0.03312
  ],
  [
    1510354800,
    0.03408
  ],
  [
    1510268400,
    0.03874
  ],
  [
    1510182000,
    0.03404
  ],
  [
    1510095600,
    0.03146
  ],
  [
    1510009200,
    0.02821
  ],
  [
    1509922800,
    0.02689
  ],
  [
    1509836400,
    0.02747
  ],
  [
    1509750000,
    0.02812
  ],
  [
    1509663600,
    0.02741
  ],
  [
    1509577200,
    0.02731
  ],
  [
    1509490800,
    0.02902
  ],
  [
    1509404400,
    0.03167
  ],
  [
    1509318000,
    0.03137
  ],
  [
    1509231600,
    0.03198
  ],
  [
    1509145200,
    0.03419
  ],
  [
    1509058800,
    0.0349
  ],
  [
    1508972400,
    0.03595
  ],
  [
    1508886000,
    0.03744
  ],
  [
    1508799600,
    0.03535
  ],
  [
    1508713200,
    0.03159
  ],
  [
    1508626800,
    0.03615
  ],
  [
    1508540400,
    0.02614
  ],
  [
    1508454000,
    0.03124
  ],
  [
    1508367600,
    0.03451
  ],
  [
    1508281200,
    0.044
  ],
  [
    1508194800,
    0.03926
  ],
  [
    1508108400,
    0.01846
  ],
  [
    1508022000,
    0.01833
  ],
  [
    1507935600,
    0.01794
  ],
  [
    1507849200,
    0.01791
  ],
  [
    1507762800,
    0.01957
  ],
  [
    1507676400,
    0.01824
  ],
  [
    1507590000,
    0.01719
  ],
  [
    1507503600,
    0.01846
  ],
  [
    1507417200,
    0.01987
  ],
  [
    1507330800,
    0.01806
  ],
  [
    1507244400,
    0.01469
  ],
  [
    1507158000,
    0.01201
  ],
  [
    1507071600,
    0.01212
  ],
  [
    1506985200,
    0.01285
  ],
  [
    1506898800,
    0.0137
  ],
  [
    1506812400,
    0.01381
  ],
  [
    1506726000,
    0.01322
  ],
  [
    1506639600,
    0.01388
  ],
  [
    1506553200,
    0.01462
  ],
  [
    1506466800,
    0.01246
  ],
  [
    1506380400,
    0.01232
  ],
  [
    1506294000,
    0.0117
  ],
  [
    1506207600,
    0.01172
  ],
  [
    1506121200,
    0.01082
  ],
  [
    1506034800,
    0.01088
  ],
  [
    1505948400,
    0.01255
  ],
  [
    1505862000,
    0.01156
  ],
  [
    1505775600,
    0.01318
  ],
  [
    1505689200,
    0.01129
  ],
  [
    1505602800,
    0.01156
  ],
  [
    1505516400,
    0.01222
  ],
  [
    1505430000,
    0.01174
  ],
  [
    1505343600,
    0.0157
  ],
  [
    1505257200,
    0.01645
  ],
  [
    1505170800,
    0.01724
  ],
  [
    1505084400,
    0.0167
  ],
  [
    1504998000,
    0.01719
  ],
  [
    1504911600,
    0.01728
  ],
  [
    1504825200,
    0.0199
  ],
  [
    1504738800,
    0.02032
  ],
  [
    1504652400,
    0.0189
  ],
  [
    1504566000,
    0.01782
  ],
  [
    1504479600,
    0.02138
  ],
  [
    1504393200,
    0.02053
  ],
  [
    1504306800,
    0.02366
  ],
  [
    1504220400,
    0.0225
  ],
  [
    1504134000,
    0.02087
  ],
  [
    1504047600,
    0.02005
  ],
  [
    1503961200,
    0.02053
  ],
  [
    1503874800,
    0.01979
  ],
  [
    1503788400,
    0.02097
  ],
  [
    1503702000,
    0.0203
  ],
  [
    1503615600,
    0.01958
  ],
  [
    1503529200,
    0.02069
  ],
  [
    1503442800,
    0.02066
  ],
  [
    1503356400,
    0.01903
  ],
  [
    1503270000,
    0.01771
  ],
  [
    1503183600,
    0.0162
  ],
  [
    1503097200,
    0.0169
  ],
  [
    1503010800,
    0.01713
  ],
  [
    1502924400,
    0.01764
  ],
  [
    1502838000,
    0.0172
  ],
  [
    1502751600,
    0.0177
  ],
  [
    1502665200,
    0.01796
  ],
  [
    1502578800,
    0.02003
  ],
  [
    1502492400,
    0.02117
  ],
  [
    1502406000,
    0.02224
  ],
  [
    1502319600,
    0.0228
  ],
  [
    1502233200,
    0.0233
  ],
  [
    1502146800,
    0.02232
  ],
  [
    1502060400,
    0.02344
  ],
  [
    1501974000,
    0.02393
  ],
  [
    1501887600,
    0.02165
  ],
  [
    1501801200,
    0.01865
  ],
  [
    1501714800,
    0.01885
  ],
  [
    1501628400,
    0.01776
  ],
  [
    1501542000,
    0.01624
  ],
  [
    1501455600,
    0.01594
  ],
  [
    1501369200,
    0.01686
  ],
  [
    1501282800,
    0.01714
  ],
  [
    1501196400,
    0.0184
  ],
  [
    1501110000,
    0.0184
  ],
  [
    1501023600,
    0.01854
  ],
  [
    1500937200,
    0.02257
  ],
  [
    1500850800,
    0.02144
  ],
  [
    1500764400,
    0.02269
  ],
  [
    1500678000,
    0.019
  ],
  [
    1500591600,
    0.01926
  ],
  [
    1500505200,
    0.01728
  ],
  [
    1500418800,
    0.01887
  ],
  [
    1500332400,
    0.01877
  ],
  [
    1500246000,
    0.01552
  ],
  [
    1500159600,
    0.01771
  ],
  [
    1500073200,
    0.01665
  ],
  [
    1499986800,
    0.01731
  ],
  [
    1499900400,
    0.01741
  ],
  [
    1499814000,
    0.01447
  ],
  [
    1499727600,
    0.01397
  ],
  [
    1499641200,
    0.01978
  ],
  [
    1499554800,
    0.02147
  ],
  [
    1499468400,
    0.02016
  ],
  [
    1499382000,
    0.02553
  ],
  [
    1499295600,
    0.02546
  ],
  [
    1499209200,
    0.02592
  ],
  [
    1499122800,
    0.02621
  ],
  [
    1499036400,
    0.02541
  ],
  [
    1498950000,
    0.0239
  ],
  [
    1498863600,
    0.02701
  ],
  [
    1498777200,
    0.02931
  ],
  [
    1498690800,
    0.03231
  ],
  [
    1498604400,
    0.02919
  ],
  [
    1498510800,
    0.02585
  ],
  [
    1498431600,
    0.03252
  ],
  [
    1498345200,
    0.03815
  ],
  [
    1498258800,
    0.04148
  ],
  [
    1498172400,
    0.03835
  ],
  [
    1498086000,
    0.03779
  ],
  [
    1497999600,
    0.03897
  ],
  [
    1497913200,
    0.03904
  ],
  [
    1497826800,
    0.03847
  ],
  [
    1497740400,
    0.03942
  ],
  [
    1497654000,
    0.03742
  ],
  [
    1497567600,
    0.0374
  ],
  [
    1497481200,
    0.03807
  ],
  [
    1497394800,
    0.04225
  ],
  [
    1497308400,
    0.03909
  ],
  [
    1497222000,
    0.04555
  ],
  [
    1497135600,
    0.04283
  ],
  [
    1497049200,
    0.04796
  ],
  [
    1496962800,
    0.05019
  ],
  [
    1496876400,
    0.0476
  ],
  [
    1496790000,
    0.04383
  ],
  [
    1496703600,
    0.04155
  ],
  [
    1496617200,
    0.03953
  ],
  [
    1496530800,
    0.0388
  ],
  [
    1496444400,
    0.04063
  ],
  [
    1496358000,
    0.04259
  ],
  [
    1496271600,
    0.03471
  ],
  [
    1496185200,
    0.03171
  ],
  [
    1496098800,
    0.03758
  ],
  [
    1496012400,
    0.03544
  ],
  [
    1495926000,
    0.03202
  ],
  [
    1495839600,
    0.0378
  ],
  [
    1495753200,
    0.04205
  ],
  [
    1495666800,
    0.05006
  ],
  [
    1495580400,
    0.05469
  ],
  [
    1495494000,
    0.05288
  ],
  [
    1495407600,
    0.06562
  ],
  [
    1495321200,
    0.05359
  ],
  [
    1495234800,
    0.04877
  ],
  [
    1495148400,
    0.04593
  ],
  [
    1495062000,
    0.04935
  ],
  [
    1494975600,
    0.04965
  ],
  [
    1494889200,
    0.04214
  ],
  [
    1494802800,
    0.0382
  ],
  [
    1494716400,
    0.0381
  ],
  [
    1494630000,
    0.03941
  ],
  [
    1494543600,
    0.0375
  ],
  [
    1494457200,
    0.03056
  ],
  [
    1494370800,
    0.03066
  ],
  [
    1494284400,
    0.04338
  ],
  [
    1494198000,
    0.04853
  ],
  [
    1494111600,
    0.01956
  ],
  [
    1494025200,
    0.01075
  ],
  [
    1493938800,
    0.00637
  ],
  [
    1493852400,
    0.00509
  ],
  [
    1493766000,
    0.00488
  ],
  [
    1493679600,
    0.00496
  ],
  [
    1493593200,
    0.00488
  ],
  [
    1493506800,
    0.00496
  ],
  [
    1493420400,
    0.00451
  ],
  [
    1493334000,
    0.00396
  ],
  [
    1493247600,
    0.00384
  ],
  [
    1493161200,
    0.00381
  ],
  [
    1493074800,
    0.00385
  ],
  [
    1492988400,
    0.00351
  ],
  [
    1492902000,
    0.00354
  ],
  [
    1492815600,
    0.00345
  ],
  [
    1492729200,
    0.00335
  ],
  [
    1492642800,
    0.00358
  ],
  [
    1492556400,
    0.00371
  ],
  [
    1492470000,
    0.00351
  ],
  [
    1492383600,
    0.00387
  ],
  [
    1492297200,
    0.00336
  ],
  [
    1492210800,
    0.00313
  ],
  [
    1492124400,
    0.00307
  ],
  [
    1492038000,
    0.00306
  ],
  [
    1491951600,
    0.00277
  ],
  [
    1491865200,
    0.00282
  ],
  [
    1491778800,
    0.00276
  ],
  [
    1491692400,
    0.00298
  ],
  [
    1491606000,
    0.00279
  ],
  [
    1491519600,
    0.00286
  ],
  [
    1491433200,
    0.00298
  ],
  [
    1491346800,
    0.00301
  ],
  [
    1491260400,
    0.00323
  ],
  [
    1491174000,
    0.00386
  ],
  [
    1491087600,
    0.00271
  ],
  [
    1491001200,
    0.00247
  ],
  [
    1490914800,
    0.00227
  ],
  [
    1490828400,
    0.00194
  ],
  [
    1490742000,
    0.00205
  ],
  [
    1490655600,
    0.00189
  ],
  [
    1490569200,
    0.00198
  ],
  [
    1490482800,
    0.00205
  ],
  [
    1490396400,
    0.00217
  ],
  [
    1490310000,
    0.0021
  ],
  [
    1490223600,
    0.00196
  ],
  [
    1490137200,
    0.00218
  ],
  [
    1490050800,
    0.00197
  ],
  [
    1489964400,
    0.00193
  ],
  [
    1489878000,
    0.00192
  ],
  [
    1489791600,
    0.00195
  ],
  [
    1489705200,
    0.00196
  ],
  [
    1489618800,
    0.00193
  ],
  [
    1489532400,
    0.00186
  ],
  [
    1489446000,
    0.00197
  ],
  [
    1489359600,
    0.00184
  ],
  [
    1489273200,
    0.00176
  ],
  [
    1489186800,
    0.00168
  ],
  [
    1489100400,
    0.00177
  ],
  [
    1489014000,
    0.00182
  ],
  [
    1488927600,
    0.00199
  ],
  [
    1488841200,
    0.00178
  ],
  [
    1488754800,
    0.00176
  ],
  [
    1488668400,
    0.00176
  ],
  [
    1488582000,
    0.00179
  ],
  [
    1488495600,
    0.00177
  ],
  [
    1488409200,
    0.00174
  ],
  [
    1488322800,
    0.00185
  ],
  [
    1488236400,
    0.0018
  ],
  [
    1488150000,
    0.0019
  ],
  [
    1488063600,
    0.00188
  ],
  [
    1487977200,
    0.00184
  ],
  [
    1487890800,
    0.00196
  ],
  [
    1487804400,
    0.00198
  ],
  [
    1487718000,
    0.00202
  ],
  [
    1487631600,
    0.00203
  ],
  [
    1487545200,
    0.00203
  ],
  [
    1487458800,
    0.00202
  ],
  [
    1487372400,
    0.00213
  ],
  [
    1487286000,
    0.00214
  ],
  [
    1487199600,
    0.0022
  ],
  [
    1487113200,
    0.00209
  ],
  [
    1487026800,
    0.00206
  ],
  [
    1486940400,
    0.00208
  ],
  [
    1486854000,
    0.00211
  ],
  [
    1486767600,
    0.00215
  ],
  [
    1486681200,
    0.00214
  ],
  [
    1486594800,
    0.00228
  ],
  [
    1486508400,
    0.00232
  ],
  [
    1486422000,
    0.00236
  ],
  [
    1486335600,
    0.00233
  ],
  [
    1486249200,
    0.00241
  ],
  [
    1486162800,
    0.00237
  ],
  [
    1486076400,
    0.00241
  ],
  [
    1485990000,
    0.00233
  ],
  [
    1485903600,
    0.00234
  ],
  [
    1485817200,
    0.00244
  ],
  [
    1485730800,
    0.00223
  ],
  [
    1485644400,
    0.00221
  ],
  [
    1485558000,
    0.0022
  ],
  [
    1485471600,
    0.00218
  ],
  [
    1485385200,
    0.00217
  ],
  [
    1485298800,
    0.00224
  ],
  [
    1485212400,
    0.00233
  ],
  [
    1485126000,
    0.00232
  ],
  [
    1485039600,
    0.00232
  ],
  [
    1484953200,
    0.00236
  ],
  [
    1484866800,
    0.00233
  ],
  [
    1484780400,
    0.00243
  ],
  [
    1484694000,
    0.00253
  ],
  [
    1484607600,
    0.00238
  ],
  [
    1484521200,
    0.00244
  ],
  [
    1484434800,
    0.00246
  ],
  [
    1484348400,
    0.0024
  ],
  [
    1484262000,
    0.00247
  ],
  [
    1484175600,
    0.00206
  ],
  [
    1484089200,
    0.00235
  ],
  [
    1484002800,
    0.00234
  ],
  [
    1483916400,
    0.00237
  ],
  [
    1483830000,
    0.00237
  ],
  [
    1483743600,
    0.00247
  ],
  [
    1483657200,
    0.00256
  ],
  [
    1483570800,
    0.00273
  ],
  [
    1483484400,
    0.00254
  ],
  [
    1483398000,
    0.0025
  ],
  [
    1483311600,
    0.00249
  ],
  [
    1483225200,
    0.00245
  ],
  [
    1483138800,
    0.00254
  ],
  [
    1483052400,
    0.00257
  ],
  [
    1482966000,
    0.00247
  ],
  [
    1482879600,
    0.0025
  ],
  [
    1482793200,
    0.00268
  ],
  [
    1482706800,
    0.00258
  ],
  [
    1482620400,
    0.00269
  ],
  [
    1482534000,
    0.00261
  ],
  [
    1482447600,
    0.00276
  ],
  [
    1482361200,
    0.00279
  ],
  [
    1482274800,
    0.00298
  ],
  [
    1482188400,
    0.00288
  ],
  [
    1482102000,
    0.00289
  ],
  [
    1482015600,
    0.00279
  ],
  [
    1481929200,
    0.00315
  ],
  [
    1481842800,
    0.00287
  ],
  [
    1481756400,
    0.00214
  ],
  [
    1481670000,
    0.00212
  ],
  [
    1481583600,
    0.0023
  ],
  [
    1481497200,
    0.00241
  ],
  [
    1481410800,
    0.00221
  ],
  [
    1481324400,
    0.00252
  ],
  [
    1481238000,
    0.00242
  ],
  [
    1481151600,
    0.0027
  ],
  [
    1481065200,
    0.00249
  ],
  [
    1480978800,
    0.00155
  ],
  [
    1480892400,
    0.00158
  ],
  [
    1480806000,
    0.0016
  ],
  [
    1480719600,
    0.00152
  ],
  [
    1480633200,
    0.00161
  ],
  [
    1480546800,
    0.00166
  ],
  [
    1480460400,
    0.00166
  ],
  [
    1480374000,
    0.00166
  ],
  [
    1480287600,
    0.00167
  ],
  [
    1480201200,
    0.00165
  ],
  [
    1480114800,
    0.00172
  ],
  [
    1480028400,
    0.0017
  ],
  [
    1479942000,
    0.00173
  ],
  [
    1479855600,
    0.00173
  ],
  [
    1479769200,
    0.00175
  ],
  [
    1479682800,
    0.00177
  ],
  [
    1479596400,
    0.00181
  ],
  [
    1479510000,
    0.00181
  ],
  [
    1479423600,
    0.00184
  ],
  [
    1479337200,
    0.00179
  ],
  [
    1479250800,
    0.00186
  ],
  [
    1479164400,
    0.0019
  ],
  [
    1479078000,
    0.0019
  ],
  [
    1478991600,
    0.00189
  ],
  [
    1478905200,
    0.00191
  ],
  [
    1478818800,
    0.00196
  ],
  [
    1478732400,
    0.00196
  ],
  [
    1478646000,
    0.002
  ],
  [
    1478559600,
    0.00201
  ],
  [
    1478473200,
    0.00196
  ],
  [
    1478386800,
    0.00202
  ],
  [
    1478300400,
    0.00199
  ],
  [
    1478214000,
    0.00199
  ],
  [
    1478127600,
    0.00202
  ],
  [
    1478041200,
    0.00201
  ],
  [
    1477954800,
    0.00201
  ],
  [
    1477868400,
    0.00212
  ],
  [
    1477782000,
    0.00195
  ],
  [
    1477695600,
    0.00208
  ],
  [
    1477609200,
    0.00222
  ],
  [
    1477522800,
    0.00229
  ],
  [
    1477436400,
    0.00236
  ],
  [
    1477350000,
    0.00246
  ],
  [
    1477263600,
    0.00243
  ],
  [
    1477177200,
    0.00238
  ],
  [
    1477090800,
    0.00251
  ],
  [
    1477004400,
    0.00268
  ],
  [
    1476918000,
    0.00235
  ],
  [
    1476831600,
    0.0024
  ],
  [
    1476745200,
    0.00231
  ],
  [
    1476658800,
    0.00241
  ],
  [
    1476572400,
    0.00243
  ],
  [
    1476486000,
    0.00248
  ],
  [
    1476399600,
    0.00249
  ],
  [
    1476313200,
    0.00265
  ],
  [
    1476226800,
    0.00265
  ],
  [
    1476140400,
    0.00245
  ],
  [
    1476054000,
    0.00237
  ],
  [
    1475967600,
    0.00232
  ],
  [
    1475881200,
    0.0024
  ],
  [
    1475794800,
    0.0023
  ],
  [
    1475708400,
    0.00248
  ],
  [
    1475622000,
    0.00258
  ],
  [
    1475535600,
    0.0028
  ],
  [
    1475449200,
    0.00284
  ],
  [
    1475362800,
    0.00273
  ],
  [
    1475276400,
    0.00285
  ],
  [
    1475190000,
    0.00309
  ],
  [
    1475103600,
    0.00319
  ],
  [
    1475017200,
    0.00252
  ],
  [
    1474930800,
    0.00236
  ],
  [
    1474844400,
    0.00246
  ],
  [
    1474758000,
    0.00224
  ],
  [
    1474671600,
    0.00207
  ],
  [
    1474585200,
    0.00213
  ],
  [
    1474498800,
    0.00208
  ],
  [
    1474412400,
    0.00205
  ],
  [
    1474326000,
    0.00213
  ],
  [
    1474239600,
    0.0021
  ],
  [
    1474153200,
    0.00216
  ],
  [
    1474066800,
    0.0021
  ],
  [
    1473980400,
    0.00231
  ],
  [
    1473894000,
    0.00196
  ],
  [
    1473807600,
    0.00196
  ],
  [
    1473721200,
    0.00194
  ],
  [
    1473634800,
    0.00198
  ],
  [
    1473548400,
    0.00203
  ],
  [
    1473462000,
    0.00202
  ],
  [
    1473375600,
    0.00195
  ],
  [
    1473289200,
    0.00193
  ],
  [
    1473202800,
    0.00191
  ],
  [
    1473116400,
    0.00192
  ],
  [
    1473030000,
    0.00195
  ],
  [
    1472943600,
    0.0019
  ],
  [
    1472857200,
    0.00189
  ],
  [
    1472770800,
    0.00189
  ],
  [
    1472684400,
    0.0019
  ],
  [
    1472598000,
    0.00185
  ],
  [
    1472511600,
    0.00183
  ],
  [
    1472425200,
    0.00181
  ],
  [
    1472338800,
    0.00189
  ],
  [
    1472252400,
    0.00189
  ],
  [
    1472166000,
    0.00187
  ],
  [
    1472079600,
    0.00189
  ],
  [
    1471993200,
    0.00188
  ],
  [
    1471906800,
    0.00193
  ],
  [
    1471820400,
    0.00191
  ],
  [
    1471734000,
    0.00194
  ],
  [
    1471647600,
    0.00196
  ],
  [
    1471561200,
    0.00198
  ],
  [
    1471474800,
    0.00199
  ],
  [
    1471388400,
    0.00202
  ],
  [
    1471302000,
    0.00203
  ],
  [
    1471215600,
    0.002
  ],
  [
    1471129200,
    0.00203
  ],
  [
    1471042800,
    0.00185
  ],
  [
    1470956400,
    0.0019
  ],
  [
    1470870000,
    0.00189
  ],
  [
    1470783600,
    0.00188
  ],
  [
    1470697200,
    0.00182
  ],
  [
    1470610800,
    0.00192
  ],
  [
    1470524400,
    0.00193
  ],
  [
    1470438000,
    0.00191
  ],
  [
    1470351600,
    0.00187
  ],
  [
    1470265200,
    0.00179
  ],
  [
    1470178800,
    0.00174
  ],
  [
    1470092400,
    0.0019
  ],
  [
    1470006000,
    0.00193
  ],
  [
    1469919600,
    0.00194
  ],
  [
    1469833200,
    0.00202
  ],
  [
    1469746800,
    0.00195
  ],
  [
    1469660400,
    0.00189
  ],
  [
    1469574000,
    0.00194
  ],
  [
    1469487600,
    0.00214
  ],
  [
    1469401200,
    0.00225
  ],
  [
    1469314800,
    0.00218
  ],
  [
    1469228400,
    0.00182
  ],
  [
    1469142000,
    0.00184
  ],
  [
    1469055600,
    0.00186
  ],
  [
    1468969200,
    0.00192
  ],
  [
    1468882800,
    0.00196
  ],
  [
    1468796400,
    0.00191
  ],
  [
    1468710000,
    0.00191
  ],
  [
    1468623600,
    0.00198
  ],
  [
    1468537200,
    0.00203
  ],
  [
    1468450800,
    0.00209
  ],
  [
    1468364400,
    0.00198
  ],
  [
    1468278000,
    0.00181
  ],
  [
    1468191600,
    0.00185
  ],
  [
    1468105200,
    0.00177
  ],
  [
    1468018800,
    0.00183
  ],
  [
    1467932400,
    0.00174
  ],
  [
    1467846000,
    0.00187
  ],
  [
    1467759600,
    0.00189
  ],
  [
    1467673200,
    0.00193
  ],
  [
    1467586800,
    0.00194
  ],
  [
    1467500400,
    0.00203
  ],
  [
    1467414000,
    0.00204
  ],
  [
    1467327600,
    0.0021
  ],
  [
    1467241200,
    0.00205
  ],
  [
    1467154800,
    0.00232
  ],
  [
    1467068400,
    0.00262
  ],
  [
    1466982000,
    0.00209
  ],
  [
    1466895600,
    0.0018
  ],
  [
    1466809200,
    0.0018
  ],
  [
    1466722800,
    0.00166
  ],
  [
    1466636400,
    0.00171
  ],
  [
    1466550000,
    0.0017
  ],
  [
    1466463600,
    0.00177
  ],
  [
    1466377200,
    0.0018
  ],
  [
    1466290800,
    0.00176
  ],
  [
    1466204400,
    0.00168
  ],
  [
    1466118000,
    0.00169
  ],
  [
    1466031600,
    0.00168
  ],
  [
    1465945200,
    0.00174
  ],
  [
    1465858800,
    0.00158
  ],
  [
    1465772400,
    0.00162
  ],
  [
    1465686000,
    0.00154
  ],
  [
    1465599600,
    0.00158
  ],
  [
    1465513200,
    0.00154
  ],
  [
    1465426800,
    0.0015
  ],
  [
    1465340400,
    0.00148
  ],
  [
    1465254000,
    0.0015
  ],
  [
    1465167600,
    0.00151
  ],
  [
    1465081200,
    0.00152
  ],
  [
    1464994800,
    0.00154
  ],
  [
    1464908400,
    0.00152
  ],
  [
    1464822000,
    0.00152
  ],
  [
    1464735600,
    0.00155
  ],
  [
    1464649200,
    0.0015
  ],
  [
    1464562800,
    0.00151
  ],
  [
    1464476400,
    0.00146
  ],
  [
    1464390000,
    0.00147
  ],
  [
    1464303600,
    0.0015
  ],
  [
    1464217200,
    0.00155
  ],
  [
    1464130800,
    0.0016
  ],
  [
    1464044400,
    0.00159
  ],
  [
    1463958000,
    0.00164
  ],
  [
    1463871600,
    0.00163
  ],
  [
    1463785200,
    0.00163
  ],
  [
    1463698800,
    0.00167
  ],
  [
    1463612400,
    0.00171
  ],
  [
    1463526000,
    0.0017
  ],
  [
    1463439600,
    0.00171
  ],
  [
    1463353200,
    0.00177
  ],
  [
    1463266800,
    0.00178
  ],
  [
    1463180400,
    0.00177
  ],
  [
    1463094000,
    0.0018
  ],
  [
    1463007600,
    0.0018
  ],
  [
    1462921200,
    0.00176
  ],
  [
    1462834800,
    0.0018
  ],
  [
    1462748400,
    0.00178
  ],
  [
    1462662000,
    0.00182
  ],
  [
    1462575600,
    0.00184
  ],
  [
    1462489200,
    0.00186
  ],
  [
    1462402800,
    0.00188
  ],
  [
    1462316400,
    0.00188
  ],
  [
    1462230000,
    0.00187
  ],
  [
    1462143600,
    0.00188
  ],
  [
    1462057200,
    0.00187
  ],
  [
    1461970800,
    0.00185
  ],
  [
    1461884400,
    0.00191
  ],
  [
    1461798000,
    0.00184
  ],
  [
    1461711600,
    0.0018
  ],
  [
    1461625200,
    0.00187
  ],
  [
    1461538800,
    0.00191
  ],
  [
    1461452400,
    0.00192
  ],
  [
    1461366000,
    0.00191
  ],
  [
    1461279600,
    0.00183
  ],
  [
    1461193200,
    0.00192
  ],
  [
    1461106800,
    0.0019
  ],
  [
    1461020400,
    0.0019
  ],
  [
    1460934000,
    0.00188
  ],
  [
    1460847600,
    0.00192
  ],
  [
    1460761200,
    0.00188
  ],
  [
    1460674800,
    0.00188
  ],
  [
    1460588400,
    0.00185
  ],
  [
    1460502000,
    0.00188
  ],
  [
    1460415600,
    0.0019
  ],
  [
    1460329200,
    0.00184
  ],
  [
    1460242800,
    0.00202
  ],
  [
    1460156400,
    0.00222
  ],
  [
    1460070000,
    0.00217
  ],
  [
    1459983600,
    0.00214
  ],
  [
    1459897200,
    0.00223
  ],
  [
    1459810800,
    0.0023
  ],
  [
    1459724400,
    0.00217
  ],
  [
    1459638000,
    0.00209
  ],
  [
    1459551600,
    0.00205
  ],
  [
    1459465200,
    0.00204
  ],
  [
    1459378800,
    0.00204
  ],
  [
    1459292400,
    0.00208
  ],
  [
    1459206000,
    0.00212
  ],
  [
    1459119600,
    0.002
  ],
  [
    1459033200,
    0.00206
  ],
  [
    1458946800,
    0.00208
  ],
  [
    1458860400,
    0.00207
  ],
  [
    1458774000,
    0.00217
  ],
  [
    1458687600,
    0.00208
  ],
  [
    1458601200,
    0.00208
  ],
  [
    1458514800,
    0.00209
  ],
  [
    1458428400,
    0.002
  ],
  [
    1458342000,
    0.002
  ],
  [
    1458255600,
    0.00215
  ],
  [
    1458169200,
    0.00212
  ],
  [
    1458082800,
    0.00215
  ],
  [
    1457996400,
    0.00213
  ],
  [
    1457910000,
    0.00219
  ],
  [
    1457823600,
    0.00226
  ],
  [
    1457737200,
    0.00261
  ],
  [
    1457650800,
    0.00235
  ],
  [
    1457564400,
    0.00231
  ],
  [
    1457478000,
    0.0022
  ],
  [
    1457391600,
    0.00237
  ],
  [
    1457305200,
    0.00188
  ],
  [
    1457218800,
    0.00182
  ],
  [
    1457132400,
    0.00191
  ],
  [
    1457046000,
    0.00189
  ],
  [
    1456959600,
    0.00187
  ],
  [
    1456873200,
    0.00193
  ],
  [
    1456786800,
    0.00195
  ],
  [
    1456700400,
    0.00197
  ],
  [
    1456614000,
    0.002
  ],
  [
    1456527600,
    0.00196
  ],
  [
    1456441200,
    0.00198
  ],
  [
    1456354800,
    0.00195
  ],
  [
    1456268400,
    0.00192
  ],
  [
    1456182000,
    0.00199
  ],
  [
    1456095600,
    0.00215
  ],
  [
    1456009200,
    0.00215
  ],
  [
    1455922800,
    0.00216
  ],
  [
    1455836400,
    0.00212
  ],
  [
    1455750000,
    0.00204
  ],
  [
    1455663600,
    0.00241
  ],
  [
    1455577200,
    0.0022
  ],
  [
    1455490800,
    0.00219
  ],
  [
    1455404400,
    0.00227
  ],
  [
    1455318000,
    0.00235
  ],
  [
    1455231600,
    0.00185
  ],
  [
    1455145200,
    0.00185
  ],
  [
    1455058800,
    0.00181
  ],
  [
    1454972400,
    0.00187
  ],
  [
    1454886000,
    0.00186
  ],
  [
    1454799600,
    0.00187
  ],
  [
    1454713200,
    0.00183
  ],
  [
    1454626800,
    0.0018
  ],
  [
    1454540400,
    0.0018
  ],
  [
    1454454000,
    0.0018
  ],
  [
    1454367600,
    0.00179
  ],
  [
    1454281200,
    0.00178
  ],
  [
    1454194800,
    0.00173
  ],
  [
    1454108400,
    0.0019
  ],
  [
    1454022000,
    0.00196
  ],
  [
    1453935600,
    0.00222
  ],
  [
    1453849200,
    0.00192
  ],
  [
    1453762800,
    0.00172
  ],
  [
    1453676400,
    0.00172
  ],
  [
    1453590000,
    0.0017
  ],
  [
    1453503600,
    0.00171
  ],
  [
    1453417200,
    0.00174
  ],
  [
    1453330800,
    0.00177
  ],
  [
    1453244400,
    0.00193
  ],
  [
    1453158000,
    0.00172
  ],
  [
    1453071600,
    0.00174
  ],
  [
    1452985200,
    0.00171
  ],
  [
    1452898800,
    0.00177
  ],
  [
    1452812400,
    0.00176
  ],
  [
    1452726000,
    0.00181
  ],
  [
    1452639600,
    0.00175
  ],
  [
    1452553200,
    0.00174
  ],
  [
    1452466800,
    0.00176
  ],
  [
    1452380400,
    0.00174
  ],
  [
    1452294000,
    0.00168
  ],
  [
    1452207600,
    0.00172
  ],
  [
    1452121200,
    0.0017
  ],
  [
    1452034800,
    0.00172
  ],
  [
    1451948400,
    0.00175
  ],
  [
    1451862000,
    0.00173
  ],
  [
    1451775600,
    0.00175
  ],
  [
    1451689200,
    0.00175
  ],
  [
    1451602800,
    0.00175
  ],
  [
    1451516400,
    0.00175
  ],
  [
    1451430000,
    0.00174
  ],
  [
    1451343600,
    0.00171
  ],
  [
    1451257200,
    0.00173
  ],
  [
    1451170800,
    0.00166
  ],
  [
    1451084400,
    0.00183
  ],
  [
    1450998000,
    0.00176
  ],
  [
    1450911600,
    0.00175
  ],
  [
    1450825200,
    0.00173
  ],
  [
    1450738800,
    0.00177
  ],
  [
    1450652400,
    0.00175
  ],
  [
    1450566000,
    0.00182
  ],
  [
    1450479600,
    0.00184
  ],
  [
    1450393200,
    0.00185
  ],
  [
    1450306800,
    0.00184
  ],
  [
    1450220400,
    0.00188
  ],
  [
    1450134000,
    0.00202
  ],
  [
    1450047600,
    0.00207
  ],
  [
    1449961200,
    0.00198
  ],
  [
    1449874800,
    0.00214
  ],
  [
    1449788400,
    0.00213
  ],
  [
    1449702000,
    0.00223
  ],
  [
    1449615600,
    0.00232
  ],
  [
    1449529200,
    0.00196
  ],
  [
    1449442800,
    0.00192
  ],
  [
    1449356400,
    0.00188
  ],
  [
    1449270000,
    0.002
  ],
  [
    1449183600,
    0.00171
  ],
  [
    1449097200,
    0.00174
  ],
  [
    1449010800,
    0.00174
  ],
  [
    1448924400,
    0.00174
  ],
  [
    1448838000,
    0.00174
  ],
  [
    1448751600,
    0.00175
  ],
  [
    1448665200,
    0.00181
  ],
  [
    1448578800,
    0.00193
  ],
  [
    1448492400,
    0.00187
  ],
  [
    1448406000,
    0.00185
  ],
  [
    1448319600,
    0.0019
  ],
  [
    1448233200,
    0.00197
  ],
  [
    1448146800,
    0.00191
  ],
  [
    1448060400,
    0.0019
  ],
  [
    1447974000,
    0.00193
  ],
  [
    1447887600,
    0.00189
  ],
  [
    1447801200,
    0.00203
  ],
  [
    1447714800,
    0.0019
  ],
  [
    1447628400,
    0.00199
  ],
  [
    1447542000,
    0.00197
  ],
  [
    1447455600,
    0.00198
  ],
  [
    1447369200,
    0.0021
  ],
  [
    1447282800,
    0.00198
  ],
  [
    1447196400,
    0.00204
  ],
  [
    1447110000,
    0.00203
  ],
  [
    1447023600,
    0.00195
  ],
  [
    1446937200,
    0.00201
  ],
  [
    1446850800,
    0.00194
  ],
  [
    1446764400,
    0.002
  ],
  [
    1446678000,
    0.0022
  ],
  [
    1446591600,
    0.00219
  ],
  [
    1446505200,
    0.00218
  ],
  [
    1446418800,
    0.00214
  ],
  [
    1446332400,
    0.00217
  ],
  [
    1446246000,
    0.00215
  ],
  [
    1446159600,
    0.0021
  ],
  [
    1446073200,
    0.00206
  ],
  [
    1445986800,
    0.002
  ],
  [
    1445900400,
    0.00185
  ],
  [
    1445814000,
    0.0019
  ],
  [
    1445727600,
    0.00194
  ],
  [
    1445641200,
    0.00195
  ],
  [
    1445554800,
    0.00196
  ],
  [
    1445468400,
    0.00198
  ],
  [
    1445382000,
    0.00187
  ],
  [
    1445295600,
    0.00193
  ],
  [
    1445209200,
    0.00197
  ],
  [
    1445122800,
    0.00198
  ],
  [
    1445036400,
    0.0019
  ],
  [
    1444950000,
    0.00194
  ],
  [
    1444863600,
    0.00192
  ],
  [
    1444777200,
    0.0021
  ],
  [
    1444690800,
    0.00204
  ],
  [
    1444604400,
    0.00211
  ],
  [
    1444518000,
    0.00207
  ],
  [
    1444431600,
    0.00211
  ],
  [
    1444345200,
    0.002
  ],
  [
    1444258800,
    0.00208
  ],
  [
    1444172400,
    0.00208
  ],
  [
    1444086000,
    0.00203
  ],
  [
    1443999600,
    0.00205
  ],
  [
    1443913200,
    0.00204
  ],
  [
    1443826800,
    0.00208
  ],
  [
    1443740400,
    0.00218
  ],
  [
    1443654000,
    0.00192
  ],
  [
    1443567600,
    0.00204
  ],
  [
    1443481200,
    0.00207
  ],
  [
    1443394800,
    0.00206
  ],
  [
    1443308400,
    0.00208
  ],
  [
    1443222000,
    0.00206
  ],
  [
    1443135600,
    0.00207
  ],
  [
    1443049200,
    0.00209
  ],
  [
    1442962800,
    0.00214
  ],
  [
    1442876400,
    0.00214
  ],
  [
    1442790000,
    0.00223
  ],
  [
    1442703600,
    0.0023
  ],
  [
    1442617200,
    0.0022
  ],
  [
    1442530800,
    0.00222
  ],
  [
    1442444400,
    0.00214
  ],
  [
    1442358000,
    0.00218
  ],
  [
    1442271600,
    0.00214
  ],
  [
    1442185200,
    0.00214
  ],
  [
    1442098800,
    0.00224
  ],
  [
    1442012400,
    0.00221
  ],
  [
    1441926000,
    0.00225
  ],
  [
    1441839600,
    0.00219
  ],
  [
    1441753200,
    0.0024
  ],
  [
    1441666800,
    0.0025
  ],
  [
    1441580400,
    0.00253
  ],
  [
    1441494000,
    0.00247
  ],
  [
    1441407600,
    0.00237
  ],
  [
    1441321200,
    0.00238
  ],
  [
    1441234800,
    0.00242
  ],
  [
    1441148400,
    0.00243
  ],
  [
    1441062000,
    0.00248
  ],
  [
    1440975600,
    0.00235
  ]
]
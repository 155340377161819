[
  {
    "ts": 1576195200,
    "supply": 1000000000000,
    "trustlines": {
      "total": 1,
      "authorized": 1,
      "funded": 1
    },
    "payments": 1,
    "payments_amount": 1000000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1576281600,
    "supply": 1000000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1576454400,
    "supply": 1000000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1576627200,
    "supply": 1000000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1577145600,
    "supply": 1000000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1577664000,
    "supply": 1000000000000,
    "trustlines": {
      "total": 6,
      "authorized": 6,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1578009600,
    "supply": 1000000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1578441600,
    "supply": 1000000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1578614400,
    "supply": 1000000000000,
    "trustlines": {
      "total": 14,
      "authorized": 14,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1578873600,
    "supply": 1000000000000,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1578960000,
    "supply": 1000000000000,
    "trustlines": {
      "total": 16,
      "authorized": 16,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1579046400,
    "supply": 1000000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1579132800,
    "supply": 1000000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1579478400,
    "supply": 1000000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1579564800,
    "supply": 1000000000000,
    "trustlines": {
      "total": 26,
      "authorized": 26,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1579824000,
    "supply": 1000000000000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1580256000,
    "supply": 1000000000000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1580342400,
    "supply": 995000000000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 5
    },
    "payments": 10,
    "payments_amount": 11500000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1580688000,
    "supply": 995000000000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1580860800,
    "supply": 995000000000,
    "trustlines": {
      "total": 33,
      "authorized": 33,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1581379200,
    "supply": 995000000000,
    "trustlines": {
      "total": 34,
      "authorized": 34,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1581465600,
    "supply": 995000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1581638400,
    "supply": 995000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1581984000,
    "supply": 994000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1582070400,
    "supply": 989000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 4
    },
    "payments": 7,
    "payments_amount": 13000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1582156800,
    "supply": 988000000000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1582761600,
    "supply": 988000000000,
    "trustlines": {
      "total": 36,
      "authorized": 36,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1582848000,
    "supply": 988000000000,
    "trustlines": {
      "total": 40,
      "authorized": 40,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583107200,
    "supply": 988000000000,
    "trustlines": {
      "total": 41,
      "authorized": 41,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583193600,
    "supply": 988000000000,
    "trustlines": {
      "total": 44,
      "authorized": 44,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583280000,
    "supply": 988000000000,
    "trustlines": {
      "total": 45,
      "authorized": 45,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583366400,
    "supply": 988000000000,
    "trustlines": {
      "total": 46,
      "authorized": 46,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583539200,
    "supply": 988000000000,
    "trustlines": {
      "total": 47,
      "authorized": 47,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1583884800,
    "supply": 987000000000,
    "trustlines": {
      "total": 47,
      "authorized": 47,
      "funded": 5
    },
    "payments": 2,
    "payments_amount": 2970000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584403200,
    "supply": 987000000000,
    "trustlines": {
      "total": 52,
      "authorized": 52,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584489600,
    "supply": 987000000000,
    "trustlines": {
      "total": 55,
      "authorized": 55,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584576000,
    "supply": 987000000000,
    "trustlines": {
      "total": 56,
      "authorized": 56,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584662400,
    "supply": 987000000000,
    "trustlines": {
      "total": 58,
      "authorized": 58,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584835200,
    "supply": 987000000000,
    "trustlines": {
      "total": 59,
      "authorized": 59,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1584921600,
    "supply": 987000000000,
    "trustlines": {
      "total": 61,
      "authorized": 61,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585008000,
    "supply": 987000000000,
    "trustlines": {
      "total": 62,
      "authorized": 62,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585267200,
    "supply": 986000000000,
    "trustlines": {
      "total": 62,
      "authorized": 62,
      "funded": 5
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585612800,
    "supply": 986000000000,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585699200,
    "supply": 985000000000,
    "trustlines": {
      "total": 66,
      "authorized": 66,
      "funded": 5
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585785600,
    "supply": 985000000000,
    "trustlines": {
      "total": 69,
      "authorized": 69,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585872000,
    "supply": 985000000000,
    "trustlines": {
      "total": 69,
      "authorized": 69,
      "funded": 5
    },
    "payments": 1,
    "payments_amount": 985000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1585958400,
    "supply": 985000000000,
    "trustlines": {
      "total": 70,
      "authorized": 70,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586131200,
    "supply": 985000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586217600,
    "supply": 984000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 5
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586304000,
    "supply": 983000000000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 5
    },
    "payments": 2,
    "payments_amount": 5925000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586390400,
    "supply": 980950000000,
    "trustlines": {
      "total": 80,
      "authorized": 80,
      "funded": 5
    },
    "payments": 2,
    "payments_amount": 2050000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586476800,
    "supply": 973950000000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 5
    },
    "payments": 9,
    "payments_amount": 16850000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586563200,
    "supply": 973950000000,
    "trustlines": {
      "total": 82,
      "authorized": 82,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586736000,
    "supply": 970950000000,
    "trustlines": {
      "total": 83,
      "authorized": 83,
      "funded": 5
    },
    "payments": 3,
    "payments_amount": 3000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586822400,
    "supply": 969950000000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 5
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586908800,
    "supply": 969950000000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1586995200,
    "supply": 969950000000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587081600,
    "supply": 969950000000,
    "trustlines": {
      "total": 86,
      "authorized": 86,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587340800,
    "supply": 969950000000,
    "trustlines": {
      "total": 88,
      "authorized": 88,
      "funded": 5
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587427200,
    "supply": 969950000000,
    "trustlines": {
      "total": 91,
      "authorized": 91,
      "funded": 6
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587513600,
    "supply": 969950000000,
    "trustlines": {
      "total": 92,
      "authorized": 92,
      "funded": 6
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587600000,
    "supply": 968920000000,
    "trustlines": {
      "total": 98,
      "authorized": 98,
      "funded": 7
    },
    "payments": 2,
    "payments_amount": 3098500000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587686400,
    "supply": 968920000000,
    "trustlines": {
      "total": 100,
      "authorized": 100,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1587945600,
    "supply": 968920000000,
    "trustlines": {
      "total": 110,
      "authorized": 110,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588032000,
    "supply": 968920000000,
    "trustlines": {
      "total": 113,
      "authorized": 113,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588118400,
    "supply": 967920000000,
    "trustlines": {
      "total": 123,
      "authorized": 123,
      "funded": 8
    },
    "payments": 3,
    "payments_amount": 4970000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588204800,
    "supply": 967920000000,
    "trustlines": {
      "total": 126,
      "authorized": 126,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588550400,
    "supply": 967920000000,
    "trustlines": {
      "total": 127,
      "authorized": 127,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588636800,
    "supply": 967920000000,
    "trustlines": {
      "total": 132,
      "authorized": 132,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588723200,
    "supply": 967920000000,
    "trustlines": {
      "total": 131,
      "authorized": 131,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588809600,
    "supply": 967920000000,
    "trustlines": {
      "total": 133,
      "authorized": 133,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588896000,
    "supply": 967920000000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1588982400,
    "supply": 967920000000,
    "trustlines": {
      "total": 131,
      "authorized": 131,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589068800,
    "supply": 967920000000,
    "trustlines": {
      "total": 132,
      "authorized": 132,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589155200,
    "supply": 967920000000,
    "trustlines": {
      "total": 134,
      "authorized": 134,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589241600,
    "supply": 967920000000,
    "trustlines": {
      "total": 135,
      "authorized": 135,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589328000,
    "supply": 967920000000,
    "trustlines": {
      "total": 138,
      "authorized": 138,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589414400,
    "supply": 967920000000,
    "trustlines": {
      "total": 139,
      "authorized": 139,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589500800,
    "supply": 967920000000,
    "trustlines": {
      "total": 141,
      "authorized": 141,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 5003800000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589846400,
    "supply": 967920000000,
    "trustlines": {
      "total": 148,
      "authorized": 148,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1589932800,
    "supply": 966915300000,
    "trustlines": {
      "total": 150,
      "authorized": 150,
      "funded": 8
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590019200,
    "supply": 966915300000,
    "trustlines": {
      "total": 153,
      "authorized": 153,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590105600,
    "supply": 966915300000,
    "trustlines": {
      "total": 156,
      "authorized": 156,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590192000,
    "supply": 966915300000,
    "trustlines": {
      "total": 157,
      "authorized": 157,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590278400,
    "supply": 966915300000,
    "trustlines": {
      "total": 157,
      "authorized": 157,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590364800,
    "supply": 966915300000,
    "trustlines": {
      "total": 157,
      "authorized": 157,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590451200,
    "supply": 966915300000,
    "trustlines": {
      "total": 158,
      "authorized": 158,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590537600,
    "supply": 965915300000,
    "trustlines": {
      "total": 159,
      "authorized": 159,
      "funded": 8
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590710400,
    "supply": 965915300000,
    "trustlines": {
      "total": 158,
      "authorized": 158,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1590969600,
    "supply": 963915300000,
    "trustlines": {
      "total": 159,
      "authorized": 159,
      "funded": 10
    },
    "payments": 3,
    "payments_amount": 3004700000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591056000,
    "supply": 959215300000,
    "trustlines": {
      "total": 157,
      "authorized": 157,
      "funded": 11
    },
    "payments": 8,
    "payments_amount": 14156000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591142400,
    "supply": 958215300000,
    "trustlines": {
      "total": 166,
      "authorized": 166,
      "funded": 11
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591228800,
    "supply": 957210600000,
    "trustlines": {
      "total": 167,
      "authorized": 167,
      "funded": 10
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591315200,
    "supply": 957210600000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 10
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591401600,
    "supply": 957210600000,
    "trustlines": {
      "total": 171,
      "authorized": 171,
      "funded": 11
    },
    "payments": 2,
    "payments_amount": 1970000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591488000,
    "supply": 957210600000,
    "trustlines": {
      "total": 172,
      "authorized": 172,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591574400,
    "supply": 957210600000,
    "trustlines": {
      "total": 173,
      "authorized": 173,
      "funded": 11
    },
    "payments": 1,
    "payments_amount": 985000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591660800,
    "supply": 957210600000,
    "trustlines": {
      "total": 185,
      "authorized": 185,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591747200,
    "supply": 957210600000,
    "trustlines": {
      "total": 187,
      "authorized": 187,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591833600,
    "supply": 957210600000,
    "trustlines": {
      "total": 195,
      "authorized": 195,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1591920000,
    "supply": 957210600000,
    "trustlines": {
      "total": 197,
      "authorized": 197,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1592092800,
    "supply": 957210600000,
    "trustlines": {
      "total": 198,
      "authorized": 198,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1592179200,
    "supply": 957210600000,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1592265600,
    "supply": 956010600000,
    "trustlines": {
      "total": 323,
      "authorized": 323,
      "funded": 11
    },
    "payments": 2,
    "payments_amount": 7110000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1592352000,
    "supply": 956010600000,
    "trustlines": {
      "total": 353,
      "authorized": 353,
      "funded": 14
    },
    "payments": 4,
    "payments_amount": 6993495723,
    "trades": 4,
    "traded_amount": 3496745723,
    "price": [
      10000,
      10000,
      10000,
      10000
    ],
    "volume": 10000000
  },
  {
    "ts": 1592438400,
    "supply": 956010600000,
    "trustlines": {
      "total": 369,
      "authorized": 369,
      "funded": 14
    },
    "payments": 1,
    "payments_amount": 100,
    "trades": 1,
    "traded_amount": 100,
    "price": [
      10000,
      10000,
      1,
      1
    ],
    "volume": 100
  },
  {
    "ts": 1592524800,
    "supply": 956010600000,
    "trustlines": {
      "total": 377,
      "authorized": 377,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1592611200,
    "supply": 956010600000,
    "trustlines": {
      "total": 382,
      "authorized": 382,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1592697600,
    "supply": 956010600000,
    "trustlines": {
      "total": 385,
      "authorized": 385,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1592784000,
    "supply": 956010600000,
    "trustlines": {
      "total": 393,
      "authorized": 393,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1592870400,
    "supply": 956010600000,
    "trustlines": {
      "total": 405,
      "authorized": 405,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1592956800,
    "supply": 956010600000,
    "trustlines": {
      "total": 414,
      "authorized": 414,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593043200,
    "supply": 956010600000,
    "trustlines": {
      "total": 430,
      "authorized": 430,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593129600,
    "supply": 956010600000,
    "trustlines": {
      "total": 441,
      "authorized": 441,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593216000,
    "supply": 956010600000,
    "trustlines": {
      "total": 447,
      "authorized": 447,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593302400,
    "supply": 956010600000,
    "trustlines": {
      "total": 452,
      "authorized": 452,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593388800,
    "supply": 956010600000,
    "trustlines": {
      "total": 456,
      "authorized": 456,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593475200,
    "supply": 956010600000,
    "trustlines": {
      "total": 463,
      "authorized": 463,
      "funded": 15
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593561600,
    "supply": 953460600000,
    "trustlines": {
      "total": 468,
      "authorized": 468,
      "funded": 16
    },
    "payments": 3,
    "payments_amount": 5505000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593648000,
    "supply": 952460600000,
    "trustlines": {
      "total": 474,
      "authorized": 474,
      "funded": 16
    },
    "payments": 2,
    "payments_amount": 6713000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593734400,
    "supply": 951455900000,
    "trustlines": {
      "total": 480,
      "authorized": 480,
      "funded": 15
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593820800,
    "supply": 951455900000,
    "trustlines": {
      "total": 482,
      "authorized": 482,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593907200,
    "supply": 951455900000,
    "trustlines": {
      "total": 483,
      "authorized": 483,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1593993600,
    "supply": 951455900000,
    "trustlines": {
      "total": 486,
      "authorized": 486,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1594080000,
    "supply": 951455900000,
    "trustlines": {
      "total": 492,
      "authorized": 492,
      "funded": 15
    },
    "payments": 5,
    "payments_amount": 14331600000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1594166400,
    "supply": 951455900000,
    "trustlines": {
      "total": 493,
      "authorized": 493,
      "funded": 15
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1594252800,
    "supply": 951455900000,
    "trustlines": {
      "total": 496,
      "authorized": 496,
      "funded": 15
    },
    "payments": 5,
    "payments_amount": 47849000000,
    "trades": 1,
    "traded_amount": 19700000000,
    "price": [
      1,
      1,
      1,
      1
    ],
    "volume": 0
  },
  {
    "ts": 1594339200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 499,
      "authorized": 499,
      "funded": 15
    },
    "payments": 4,
    "payments_amount": 35100000000000,
    "trades": 11,
    "traded_amount": 92515399346,
    "price": [
      1,
      1,
      0.09832955553314628,
      0.0992663836510548
    ],
    "volume": 4578476363
  },
  {
    "ts": 1594425600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 503,
      "authorized": 503,
      "funded": 21
    },
    "payments": 8,
    "payments_amount": 7539046015,
    "trades": 5,
    "traded_amount": 7509046015,
    "price": [
      0.0992663836510548,
      0.0992663836510548,
      0.08921729577201912,
      0.0925427309119281
    ],
    "volume": 693460273
  },
  {
    "ts": 1594512000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 504,
      "authorized": 504,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.0925427309119281,
      0.0925427309119281,
      0.0925427309119281,
      0.0925427309119281
    ],
    "volume": 0
  },
  {
    "ts": 1594598400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 506,
      "authorized": 506,
      "funded": 23
    },
    "payments": 6,
    "payments_amount": 9079953094,
    "trades": 6,
    "traded_amount": 9079953094,
    "price": [
      0.0925427309119281,
      0.0925427309119281,
      0.084192004,
      0.0852709
    ],
    "volume": 817530265
  },
  {
    "ts": 1594684800,
    "supply": 12651765900000,
    "trustlines": {
      "total": 515,
      "authorized": 515,
      "funded": 26
    },
    "payments": 6,
    "payments_amount": 2579434386,
    "trades": 3,
    "traded_amount": 2269434386,
    "price": [
      0.0852709,
      0.09297246253606171,
      0.0852709,
      0.09297246253606171
    ],
    "volume": 210927884
  },
  {
    "ts": 1594771200,
    "supply": 12651765900000,
    "trustlines": {
      "total": 518,
      "authorized": 518,
      "funded": 29
    },
    "payments": 5,
    "payments_amount": 10927901144,
    "trades": 4,
    "traded_amount": 9933901144,
    "price": [
      0.09297246253606171,
      0.09459707128992645,
      0.083985478,
      0.09459707128992645
    ],
    "volume": 890728270
  },
  {
    "ts": 1594857600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 525,
      "authorized": 525,
      "funded": 28
    },
    "payments": 2,
    "payments_amount": 368773888,
    "trades": 1,
    "traded_amount": 58773888,
    "price": [
      0.09459707128992645,
      0.09459707128992645,
      0.09011428680709366,
      0.09011428680709366
    ],
    "volume": 5296367
  },
  {
    "ts": 1594944000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 527,
      "authorized": 527,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.09011428680709366,
      0.09011428680709366,
      0.09011428680709366,
      0.09011428680709366
    ],
    "volume": 0
  },
  {
    "ts": 1595030400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 527,
      "authorized": 527,
      "funded": 28
    },
    "payments": 1,
    "payments_amount": 100811712,
    "trades": 1,
    "traded_amount": 100811712,
    "price": [
      0.09011428680709366,
      0.09011428680709366,
      0.08333611078839728,
      0.08333611078839728
    ],
    "volume": 8401256
  },
  {
    "ts": 1595116800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 528,
      "authorized": 528,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08333611078839728,
      0.08333611078839728,
      0.08333611078839728,
      0.08333611078839728
    ],
    "volume": 0
  },
  {
    "ts": 1595203200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 530,
      "authorized": 530,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 994000000,
    "trades": 1,
    "traded_amount": 994000000,
    "price": [
      0.08333611078839728,
      0.08333611078839728,
      0.07803374044265593,
      0.07803374044265593
    ],
    "volume": 77565538
  },
  {
    "ts": 1595289600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 532,
      "authorized": 532,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593
    ],
    "volume": 0
  },
  {
    "ts": 1595376000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 536,
      "authorized": 536,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593
    ],
    "volume": 0
  },
  {
    "ts": 1595462400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 540,
      "authorized": 540,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593
    ],
    "volume": 0
  },
  {
    "ts": 1595548800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 544,
      "authorized": 544,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593,
      0.07803374044265593
    ],
    "volume": 0
  },
  {
    "ts": 1595635200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 547,
      "authorized": 547,
      "funded": 29
    },
    "payments": 2,
    "payments_amount": 227867972,
    "trades": 1,
    "traded_amount": 117867972,
    "price": [
      0.07803374044265593,
      0.08670746451801173,
      0.07803374044265593,
      0.08670746451801173
    ],
    "volume": 10220033
  },
  {
    "ts": 1595721600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 548,
      "authorized": 548,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173
    ],
    "volume": 0
  },
  {
    "ts": 1595808000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 550,
      "authorized": 550,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 49700000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173
    ],
    "volume": 0
  },
  {
    "ts": 1595894400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 553,
      "authorized": 553,
      "funded": 30
    },
    "payments": 4,
    "payments_amount": 6778400000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173,
      0.08670746451801173
    ],
    "volume": 0
  },
  {
    "ts": 1595980800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 560,
      "authorized": 560,
      "funded": 30
    },
    "payments": 5,
    "payments_amount": 51316223835,
    "trades": 5,
    "traded_amount": 49925223834,
    "price": [
      0.08670746451801173,
      0.08778962155679608,
      0.07994756070422535,
      0.07994756070422535
    ],
    "volume": 3992209101
  },
  {
    "ts": 1596067200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 558,
      "authorized": 558,
      "funded": 29
    },
    "payments": 1,
    "payments_amount": 110000000,
    "trades": 1,
    "traded_amount": 110000000,
    "price": [
      0.07994756070422535,
      0.08055932727272727,
      0.07994756070422535,
      0.08055932727272727
    ],
    "volume": 8861526
  },
  {
    "ts": 1596153600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 561,
      "authorized": 561,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727
    ],
    "volume": 0
  },
  {
    "ts": 1596240000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 563,
      "authorized": 563,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727
    ],
    "volume": 0
  },
  {
    "ts": 1596326400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 563,
      "authorized": 563,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727
    ],
    "volume": 0
  },
  {
    "ts": 1596412800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 566,
      "authorized": 566,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727,
      0.08055932727272727
    ],
    "volume": 0
  },
  {
    "ts": 1596499200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 568,
      "authorized": 568,
      "funded": 30
    },
    "payments": 2,
    "payments_amount": 17779725796,
    "trades": 2,
    "traded_amount": 17779725796,
    "price": [
      0.08055932727272727,
      0.08055932727272727,
      0.07816922949832018,
      0.07816922949832018
    ],
    "volume": 1399472441
  },
  {
    "ts": 1596585600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 569,
      "authorized": 569,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 102537269,
    "trades": 1,
    "traded_amount": 102537269,
    "price": [
      0.07816922949832018,
      0.08088511700072683,
      0.07816922949832018,
      0.08088511700072683
    ],
    "volume": 8293739
  },
  {
    "ts": 1596672000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 578,
      "authorized": 578,
      "funded": 30
    },
    "payments": 5,
    "payments_amount": 4196032260,
    "trades": 2,
    "traded_amount": 1164332260,
    "price": [
      0.08088511700072683,
      0.08088511700072683,
      0.0733768,
      0.0733768
    ],
    "volume": 93773931
  },
  {
    "ts": 1596758400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 582,
      "authorized": 582,
      "funded": 34
    },
    "payments": 11,
    "payments_amount": 4699560231,
    "trades": 8,
    "traded_amount": 3685480231,
    "price": [
      0.0733768,
      0.08120369692178912,
      0.0733768,
      0.08120369692178912
    ],
    "volume": 284746154
  },
  {
    "ts": 1596844800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 594,
      "authorized": 594,
      "funded": 34
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08120369692178912,
      0.08120369692178912,
      0.08120369692178912,
      0.08120369692178912
    ],
    "volume": 0
  },
  {
    "ts": 1596931200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 595,
      "authorized": 595,
      "funded": 34
    },
    "payments": 2,
    "payments_amount": 21205373,
    "trades": 2,
    "traded_amount": 21205373,
    "price": [
      0.08120369692178912,
      0.08120369692178912,
      0.07202595,
      0.07881377797578011
    ],
    "volume": 1535519
  },
  {
    "ts": 1597017600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 598,
      "authorized": 598,
      "funded": 35
    },
    "payments": 5,
    "payments_amount": 35391852229,
    "trades": 3,
    "traded_amount": 32407752229,
    "price": [
      0.07881377797578011,
      0.07962231575019135,
      0.072352,
      0.072352
    ],
    "volume": 2575129423
  },
  {
    "ts": 1597104000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 603,
      "authorized": 603,
      "funded": 37
    },
    "payments": 8,
    "payments_amount": 116863475197,
    "trades": 6,
    "traded_amount": 91013475197,
    "price": [
      0.072352,
      0.08333704249533416,
      0.072352,
      0.07816813
    ],
    "volume": 7253238196
  },
  {
    "ts": 1597190400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 606,
      "authorized": 606,
      "funded": 39
    },
    "payments": 17,
    "payments_amount": 123698378723,
    "trades": 4,
    "traded_amount": 99913378723,
    "price": [
      0.07816813,
      0.08165936625235364,
      0.07465153825714285,
      0.07465153825714285
    ],
    "volume": 7709545588
  },
  {
    "ts": 1597276800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 619,
      "authorized": 619,
      "funded": 39
    },
    "payments": 2,
    "payments_amount": 11240000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07465153825714285,
      0.07465153825714285,
      0.07465153825714285,
      0.07465153825714285
    ],
    "volume": 0
  },
  {
    "ts": 1597363200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 637,
      "authorized": 637,
      "funded": 39
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 1,
    "traded_amount": 10000000000,
    "price": [
      0.07465153825714285,
      0.07465153825714285,
      0.0723791389,
      0.0723791389
    ],
    "volume": 723791389
  },
  {
    "ts": 1597449600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 650,
      "authorized": 650,
      "funded": 40
    },
    "payments": 8,
    "payments_amount": 2991451173,
    "trades": 7,
    "traded_amount": 2981451173,
    "price": [
      0.0723791389,
      0.080329785,
      0.0723791389,
      0.07757142886778948
    ],
    "volume": 231350616
  },
  {
    "ts": 1597536000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 653,
      "authorized": 653,
      "funded": 40
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.07757142886778948,
      0.07757142886778948,
      0.07757142886778948,
      0.07757142886778948
    ],
    "volume": 0
  },
  {
    "ts": 1597622400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 740,
      "authorized": 740,
      "funded": 41
    },
    "payments": 3,
    "payments_amount": 2202800000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      0.07757142886778948,
      0.07757142886778948,
      0.0658768,
      0.0658768
    ],
    "volume": 658768
  },
  {
    "ts": 1597708800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 812,
      "authorized": 812,
      "funded": 41
    },
    "payments": 8,
    "payments_amount": 20333042124,
    "trades": 5,
    "traded_amount": 7609842124,
    "price": [
      0.0658768,
      0.07612311215430795,
      0.0658768,
      0.069295
    ],
    "volume": 536564549
  },
  {
    "ts": 1597795200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 871,
      "authorized": 871,
      "funded": 43
    },
    "payments": 10,
    "payments_amount": 119970124940,
    "trades": 7,
    "traded_amount": 108042124940,
    "price": [
      0.069295,
      0.08108163043951273,
      0.069295,
      0.07402037585
    ],
    "volume": 8395638200
  },
  {
    "ts": 1597881600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 954,
      "authorized": 954,
      "funded": 45
    },
    "payments": 13,
    "payments_amount": 87700921027,
    "trades": 9,
    "traded_amount": 61272921027,
    "price": [
      0.07402037585,
      0.07913641183331273,
      0.07047036820925554,
      0.07726103336612751
    ],
    "volume": 4555737071
  },
  {
    "ts": 1597968000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1041,
      "authorized": 1041,
      "funded": 48
    },
    "payments": 11,
    "payments_amount": 130049251578,
    "trades": 8,
    "traded_amount": 76363311578,
    "price": [
      0.07726103336612751,
      0.07786057504481447,
      0.07056516,
      0.0733866438832998
    ],
    "volume": 5695466077
  },
  {
    "ts": 1598054400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1093,
      "authorized": 1093,
      "funded": 48
    },
    "payments": 3,
    "payments_amount": 1499616112,
    "trades": 3,
    "traded_amount": 1499616112,
    "price": [
      0.0733866438832998,
      0.08107767701626935,
      0.0731705009033896,
      0.0731705009033896
    ],
    "volume": 110435458
  },
  {
    "ts": 1598140800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1138,
      "authorized": 1138,
      "funded": 48
    },
    "payments": 5,
    "payments_amount": 131058450213,
    "trades": 4,
    "traded_amount": 130938450213,
    "price": [
      0.0731705009033896,
      0.08104681741110378,
      0.0731705009033896,
      0.07384876611111112
    ],
    "volume": 10170631059
  },
  {
    "ts": 1598227200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1171,
      "authorized": 1171,
      "funded": 49
    },
    "payments": 4,
    "payments_amount": 124194292205,
    "trades": 3,
    "traded_amount": 74194292205,
    "price": [
      0.07384876611111112,
      0.07862999334904702,
      0.0713871,
      0.0713871
    ],
    "volume": 5806134570
  },
  {
    "ts": 1598313600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1302,
      "authorized": 1302,
      "funded": 54
    },
    "payments": 27,
    "payments_amount": 356111308466,
    "trades": 11,
    "traded_amount": 188916188466,
    "price": [
      0.0713871,
      0.0836531690939662,
      0.0713871,
      0.0765433385110664
    ],
    "volume": 14930335317
  },
  {
    "ts": 1598400000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1431,
      "authorized": 1431,
      "funded": 57
    },
    "payments": 24,
    "payments_amount": 240289301710,
    "trades": 15,
    "traded_amount": 132757721710,
    "price": [
      0.0765433385110664,
      0.0842850059081443,
      0.07531062633299798,
      0.076032999955883
    ],
    "volume": 10177705152
  },
  {
    "ts": 1598486400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1543,
      "authorized": 1543,
      "funded": 59
    },
    "payments": 13,
    "payments_amount": 214322440299,
    "trades": 9,
    "traded_amount": 119913800299,
    "price": [
      0.076032999955883,
      0.0881562,
      0.076032999955883,
      0.08799726209543622
    ],
    "volume": 9657565883
  },
  {
    "ts": 1598572800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1595,
      "authorized": 1595,
      "funded": 61
    },
    "payments": 17,
    "payments_amount": 363083795100,
    "trades": 8,
    "traded_amount": 180467695100,
    "price": [
      0.08799726209543622,
      0.08799726209543622,
      0.07867280080482897,
      0.08662486233443037
    ],
    "volume": 14771938342
  },
  {
    "ts": 1598659200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1763,
      "authorized": 1763,
      "funded": 64
    },
    "payments": 21,
    "payments_amount": 7829204521,
    "trades": 20,
    "traded_amount": 7819204521,
    "price": [
      0.08662486233443037,
      0.08713636467943534,
      0.07920579560838337,
      0.0853960565140706
    ],
    "volume": 648760241
  },
  {
    "ts": 1598745600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1890,
      "authorized": 1890,
      "funded": 64
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      0.0853960565140706,
      0.085597,
      0.0853960565140706,
      0.085597
    ],
    "volume": 855970
  },
  {
    "ts": 1598832000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 1922,
      "authorized": 1922,
      "funded": 66
    },
    "payments": 8,
    "payments_amount": 174344460866,
    "trades": 4,
    "traded_amount": 86867260866,
    "price": [
      0.085597,
      0.085597,
      0.07739798705035972,
      0.0855017667231651
    ],
    "volume": 7025086100
  },
  {
    "ts": 1598918400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2009,
      "authorized": 2009,
      "funded": 65
    },
    "payments": 15,
    "payments_amount": 120868102766,
    "trades": 9,
    "traded_amount": 61198102766,
    "price": [
      0.0855017667231651,
      0.0855017667231651,
      0.07543443910561709,
      0.0833309175
    ],
    "volume": 4677963435
  },
  {
    "ts": 1599004800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2051,
      "authorized": 2051,
      "funded": 65
    },
    "payments": 8,
    "payments_amount": 104638193217,
    "trades": 5,
    "traded_amount": 51950193217,
    "price": [
      0.0833309175,
      0.08417989050671133,
      0.07689225589225589,
      0.08080751951710262
    ],
    "volume": 4197895193
  },
  {
    "ts": 1599091200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2092,
      "authorized": 2092,
      "funded": 66
    },
    "payments": 6,
    "payments_amount": 41751665127,
    "trades": 6,
    "traded_amount": 41751665127,
    "price": [
      0.08080751951710262,
      0.10246124769682785,
      0.08080751951710262,
      0.10246124759409986
    ],
    "volume": 4244126136
  },
  {
    "ts": 1599177600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2140,
      "authorized": 2140,
      "funded": 67
    },
    "payments": 8,
    "payments_amount": 142327787653,
    "trades": 5,
    "traded_amount": 91755387653,
    "price": [
      0.10246124759409986,
      0.10246124759409986,
      0.09284392224012893,
      0.10131997977096587
    ],
    "volume": 8703071716
  },
  {
    "ts": 1599264000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2191,
      "authorized": 2191,
      "funded": 66
    },
    "payments": 1,
    "payments_amount": 111935747,
    "trades": 1,
    "traded_amount": 111935747,
    "price": [
      0.10131997977096587,
      0.10131997977096587,
      0.10009846095010203,
      0.10009846095010203
    ],
    "volume": 11204596
  },
  {
    "ts": 1599350400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2247,
      "authorized": 2247,
      "funded": 65
    },
    "payments": 1,
    "payments_amount": 1988000000,
    "trades": 1,
    "traded_amount": 1988000000,
    "price": [
      0.10009846095010203,
      0.10009846095010203,
      0.09799135110663984,
      0.09799135110663984
    ],
    "volume": 194806806
  },
  {
    "ts": 1599436800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2307,
      "authorized": 2307,
      "funded": 68
    },
    "payments": 7,
    "payments_amount": 108736900237,
    "trades": 3,
    "traded_amount": 54066900227,
    "price": [
      0.09799135110663984,
      0.10586360459412407,
      0.09799135110663984,
      0.09947467307847083
    ],
    "volume": 5393977421
  },
  {
    "ts": 1599523200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2373,
      "authorized": 2373,
      "funded": 73
    },
    "payments": 11,
    "payments_amount": 32047984901,
    "trades": 6,
    "traded_amount": 22628624901,
    "price": [
      0.09947467307847083,
      0.10804384396368759,
      0.09704279729266564,
      0.10802192073327274
    ],
    "volume": 2347210236
  },
  {
    "ts": 1599609600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2458,
      "authorized": 2458,
      "funded": 75
    },
    "payments": 14,
    "payments_amount": 164506503741,
    "trades": 9,
    "traded_amount": 90473903741,
    "price": [
      0.10802192073327274,
      0.10802192073327274,
      0.0968956332,
      0.0968956332
    ],
    "volume": 9326266299
  },
  {
    "ts": 1599696000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2483,
      "authorized": 2483,
      "funded": 79
    },
    "payments": 8,
    "payments_amount": 65622897032,
    "trades": 5,
    "traded_amount": 49663197032,
    "price": [
      0.0968956332,
      0.10286039440939808,
      0.09371394941508439,
      0.09371394941508439
    ],
    "volume": 4991398044
  },
  {
    "ts": 1599782400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2572,
      "authorized": 2572,
      "funded": 79
    },
    "payments": 12,
    "payments_amount": 75738598811,
    "trades": 7,
    "traded_amount": 58046798811,
    "price": [
      0.09371394941508439,
      0.1027135077634786,
      0.09032607806053776,
      0.09919201040590343
    ],
    "volume": 5614393478
  },
  {
    "ts": 1599868800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2723,
      "authorized": 2723,
      "funded": 78
    },
    "payments": 5,
    "payments_amount": 48473906592,
    "trades": 4,
    "traded_amount": 28444606592,
    "price": [
      0.09919201040590343,
      0.1003063902518264,
      0.09153092606892278,
      0.09169808563562105
    ],
    "volume": 2790634583
  },
  {
    "ts": 1599955200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2873,
      "authorized": 2873,
      "funded": 78
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.09169808563562105,
      0.09169808563562105,
      0.09169808563562105,
      0.09169808563562105
    ],
    "volume": 0
  },
  {
    "ts": 1600041600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 2990,
      "authorized": 2990,
      "funded": 80
    },
    "payments": 8,
    "payments_amount": 24389527069,
    "trades": 4,
    "traded_amount": 10985527069,
    "price": [
      0.09169808563562105,
      0.10338647005143521,
      0.09169808563562105,
      0.0944824467682049
    ],
    "volume": 1104797379
  },
  {
    "ts": 1600128000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3103,
      "authorized": 3103,
      "funded": 82
    },
    "payments": 22,
    "payments_amount": 36736648187,
    "trades": 3,
    "traded_amount": 10511948187,
    "price": [
      0.0944824467682049,
      0.10432017995074055,
      0.0944824467682049,
      0.09567399245472837
    ],
    "volume": 1037764200
  },
  {
    "ts": 1600214400,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3218,
      "authorized": 3218,
      "funded": 88
    },
    "payments": 16,
    "payments_amount": 46930252205,
    "trades": 6,
    "traded_amount": 11834272205,
    "price": [
      0.09567399245472837,
      0.10397766803653913,
      0.08807526438734338,
      0.09089426666666667
    ],
    "volume": 1083600023
  },
  {
    "ts": 1600300800,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3294,
      "authorized": 3294,
      "funded": 90
    },
    "payments": 19,
    "payments_amount": 216559734313,
    "trades": 7,
    "traded_amount": 90071734313,
    "price": [
      0.09089426666666667,
      0.09992623564328713,
      0.08870097545714285,
      0.091218505
    ],
    "volume": 8406277585
  },
  {
    "ts": 1600387200,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3433,
      "authorized": 3433,
      "funded": 97
    },
    "payments": 30,
    "payments_amount": 65029979787,
    "trades": 13,
    "traded_amount": 28886479787,
    "price": [
      0.091218505,
      0.10373552830714654,
      0.091218505,
      0.10056986277045296
    ],
    "volume": 2820066594
  },
  {
    "ts": 1600473600,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3534,
      "authorized": 3534,
      "funded": 97
    },
    "payments": 9,
    "payments_amount": 67726179872,
    "trades": 5,
    "traded_amount": 33248979872,
    "price": [
      0.10056986277045296,
      0.10074155861294992,
      0.09211666666666667,
      0.09938858685035763
    ],
    "volume": 3306542545
  },
  {
    "ts": 1600560000,
    "supply": 12651455900000,
    "trustlines": {
      "total": 3613,
      "authorized": 3613,
      "funded": 98
    },
    "payments": 2,
    "payments_amount": 4219764903,
    "trades": 2,
    "traded_amount": 10194564902,
    "price": [
      0.09938858685035763,
      0.10279346218983801,
      0.09202977830920298,
      0.10279346218983801
    ],
    "volume": 940297783
  },
  {
    "ts": 1600646400,
    "supply": 19551455900000,
    "trustlines": {
      "total": 3698,
      "authorized": 3698,
      "funded": 99
    },
    "payments": 10,
    "payments_amount": 14136297698930,
    "trades": 4,
    "traded_amount": 156417498930,
    "price": [
      0.10279346218983801,
      0.11098226126175587,
      0.10071852576134438,
      0.10071852576134438
    ],
    "volume": 15910853346
  },
  {
    "ts": 1600732800,
    "supply": 19551455900000,
    "trustlines": {
      "total": 3772,
      "authorized": 3772,
      "funded": 100
    },
    "payments": 11,
    "payments_amount": 46667054513,
    "trades": 7,
    "traded_amount": 24485354513,
    "price": [
      0.10071852576134438,
      0.11025074549491806,
      0.09791097971079475,
      0.09791097971079475
    ],
    "volume": 2642586284
  },
  {
    "ts": 1600819200,
    "supply": 19551455900000,
    "trustlines": {
      "total": 3877,
      "authorized": 3877,
      "funded": 103
    },
    "payments": 8,
    "payments_amount": 95644198147,
    "trades": 2,
    "traded_amount": 21411898147,
    "price": [
      0.09791097971079475,
      0.10786584627192254,
      0.0974667654594232,
      0.0974667654594232
    ],
    "volume": 2154562219
  },
  {
    "ts": 1600905600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 3982,
      "authorized": 3982,
      "funded": 103
    },
    "payments": 10,
    "payments_amount": 10470426521533,
    "trades": 7,
    "traded_amount": 234940121533,
    "price": [
      0.0974667654594232,
      0.10872068344891456,
      0.0974667654594232,
      0.09858300804062632
    ],
    "volume": 23442788298
  },
  {
    "ts": 1600992000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4106,
      "authorized": 4106,
      "funded": 102
    },
    "payments": 5,
    "payments_amount": 5228000000,
    "trades": 3,
    "traded_amount": 4114000000,
    "price": [
      0.09858300804062632,
      0.09858300804062632,
      0.09412252615694165,
      0.09412252615694165
    ],
    "volume": 391289927
  },
  {
    "ts": 1601078400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4188,
      "authorized": 4188,
      "funded": 102
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.09412252615694165,
      0.09412252615694165,
      0.09412252615694165,
      0.09412252615694165
    ],
    "volume": 0
  },
  {
    "ts": 1601164800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4280,
      "authorized": 4280,
      "funded": 102
    },
    "payments": 3,
    "payments_amount": 66012526138,
    "trades": 2,
    "traded_amount": 33292526138,
    "price": [
      0.09412252615694165,
      0.10404823150186505,
      0.09381904246024812,
      0.10404823150186505
    ],
    "volume": 3458174302
  },
  {
    "ts": 1601251200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4374,
      "authorized": 4374,
      "funded": 108
    },
    "payments": 16,
    "payments_amount": 63338833769,
    "trades": 4,
    "traded_amount": 20944633769,
    "price": [
      0.10404823150186505,
      0.10404823150186505,
      0.09251375392354125,
      0.10116508065549831
    ],
    "volume": 1978281391
  },
  {
    "ts": 1601337600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4463,
      "authorized": 4463,
      "funded": 110
    },
    "payments": 16,
    "payments_amount": 71019673850,
    "trades": 10,
    "traded_amount": 50909873850,
    "price": [
      0.10116508065549831,
      0.10366533237565917,
      0.09441253525,
      0.10366533237565917
    ],
    "volume": 5093898789
  },
  {
    "ts": 1601424000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4543,
      "authorized": 4543,
      "funded": 112
    },
    "payments": 16,
    "payments_amount": 90825043157,
    "trades": 8,
    "traded_amount": 43878243157,
    "price": [
      0.10366533237565917,
      0.10375860806929528,
      0.092786682,
      0.092786682
    ],
    "volume": 4177840748
  },
  {
    "ts": 1601510400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4606,
      "authorized": 4606,
      "funded": 113
    },
    "payments": 16,
    "payments_amount": 231349790893,
    "trades": 10,
    "traded_amount": 105860290893,
    "price": [
      0.092786682,
      0.10379241721734399,
      0.09210761153846155,
      0.09455113038793103
    ],
    "volume": 10215721547
  },
  {
    "ts": 1601596800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4699,
      "authorized": 4699,
      "funded": 116
    },
    "payments": 32,
    "payments_amount": 436781340977,
    "trades": 18,
    "traded_amount": 222679740977,
    "price": [
      0.09455113038793103,
      0.10696457931895431,
      0.09455113038793103,
      0.09701681195013381
    ],
    "volume": 23377233296
  },
  {
    "ts": 1601683200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4792,
      "authorized": 4792,
      "funded": 116
    },
    "payments": 2,
    "payments_amount": 3918880032,
    "trades": 2,
    "traded_amount": 3918880032,
    "price": [
      0.09701681195013381,
      0.1035499930302536,
      0.0946318215846828,
      0.0946318215846828
    ],
    "volume": 388325378
  },
  {
    "ts": 1601769600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4889,
      "authorized": 4889,
      "funded": 116
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.0946318215846828,
      0.0946318215846828,
      0.0946318215846828,
      0.0946318215846828
    ],
    "volume": 0
  },
  {
    "ts": 1601856000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 4973,
      "authorized": 4973,
      "funded": 119
    },
    "payments": 10,
    "payments_amount": 163892262175,
    "trades": 4,
    "traded_amount": 81183262175,
    "price": [
      0.0946318215846828,
      0.10239078550724638,
      0.0924075396,
      0.10239078550724638
    ],
    "volume": 7534987477
  },
  {
    "ts": 1601942400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5093,
      "authorized": 5093,
      "funded": 124
    },
    "payments": 16,
    "payments_amount": 323616131069,
    "trades": 6,
    "traded_amount": 149937831069,
    "price": [
      0.10239078550724638,
      0.10297525340915421,
      0.0903897595959596,
      0.10297525340915421
    ],
    "volume": 15039474081
  },
  {
    "ts": 1602028800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5222,
      "authorized": 5222,
      "funded": 127
    },
    "payments": 13,
    "payments_amount": 300444822705,
    "trades": 6,
    "traded_amount": 141155622705,
    "price": [
      0.10297525340915421,
      0.10297525340915421,
      0.09296568041795936,
      0.09296568041795936
    ],
    "volume": 13206865181
  },
  {
    "ts": 1602115200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5350,
      "authorized": 5350,
      "funded": 127
    },
    "payments": 11,
    "payments_amount": 335054676855,
    "trades": 7,
    "traded_amount": 171728376855,
    "price": [
      0.09296568041795936,
      0.1013047243826074,
      0.09166439276859505,
      0.10087173897057229
    ],
    "volume": 17195697243
  },
  {
    "ts": 1602201600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5493,
      "authorized": 5493,
      "funded": 129
    },
    "payments": 18,
    "payments_amount": 269201681694,
    "trades": 11,
    "traded_amount": 211581681694,
    "price": [
      0.10087173897057229,
      0.10114456051650991,
      0.08402916677852348,
      0.08402916677852348
    ],
    "volume": 19190946989
  },
  {
    "ts": 1602288000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5649,
      "authorized": 5649,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.08402916677852348,
      0.08402916677852348,
      0.08402916677852348,
      0.08402916677852348
    ],
    "volume": 0
  },
  {
    "ts": 1602374400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5790,
      "authorized": 5790,
      "funded": 129
    },
    "payments": 4,
    "payments_amount": 94814377972,
    "trades": 3,
    "traded_amount": 45604377972,
    "price": [
      0.08402916677852348,
      0.09439740988610858,
      0.08032739235412475,
      0.09439740988610858
    ],
    "volume": 4284097229
  },
  {
    "ts": 1602460800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 5890,
      "authorized": 5890,
      "funded": 130
    },
    "payments": 3,
    "payments_amount": 37568049668,
    "trades": 2,
    "traded_amount": 19568049668,
    "price": [
      0.09439740988610858,
      0.09439740988610858,
      0.09361994284368992,
      0.09429884253399597
    ],
    "volume": 1833089285
  },
  {
    "ts": 1602547200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6023,
      "authorized": 6023,
      "funded": 134
    },
    "payments": 16,
    "payments_amount": 215052924903,
    "trades": 5,
    "traded_amount": 100236724903,
    "price": [
      0.09429884253399597,
      0.09632204870780589,
      0.08202292339685864,
      0.08202292339685864
    ],
    "volume": 9274054444
  },
  {
    "ts": 1602633600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6135,
      "authorized": 6135,
      "funded": 138
    },
    "payments": 27,
    "payments_amount": 394146358504,
    "trades": 14,
    "traded_amount": 97352658504,
    "price": [
      0.08202292339685864,
      0.09704535017334778,
      0.08174085101955889,
      0.08510191928306808
    ],
    "volume": 8501084660
  },
  {
    "ts": 1602720000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6230,
      "authorized": 6230,
      "funded": 138
    },
    "payments": 6,
    "payments_amount": 9465600000,
    "trades": 2,
    "traded_amount": 3081400000,
    "price": [
      0.08510191928306808,
      0.08510191928306808,
      0.08348310362173038,
      0.08348310362173038
    ],
    "volume": 258711226
  },
  {
    "ts": 1602806400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6324,
      "authorized": 6324,
      "funded": 139
    },
    "payments": 12,
    "payments_amount": 135831953136,
    "trades": 7,
    "traded_amount": 64154353124,
    "price": [
      0.08348310362173038,
      0.08348310362173038,
      0.0781983209987145,
      0.0781983209987145
    ],
    "volume": 5219500100
  },
  {
    "ts": 1602892800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6425,
      "authorized": 6425,
      "funded": 139
    },
    "payments": 4,
    "payments_amount": 6595047383,
    "trades": 3,
    "traded_amount": 4972147383,
    "price": [
      0.0781983209987145,
      0.0781983209987145,
      0.07295043730800332,
      0.0751706948874724
    ],
    "volume": 366907318
  },
  {
    "ts": 1602979200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6498,
      "authorized": 6498,
      "funded": 139
    },
    "payments": 4,
    "payments_amount": 5868005355,
    "trades": 3,
    "traded_amount": 4834005355,
    "price": [
      0.0751706948874724,
      0.07601508021203623,
      0.07343285141843972,
      0.07601508021203623
    ],
    "volume": 358437148
  },
  {
    "ts": 1603065600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6602,
      "authorized": 6602,
      "funded": 142
    },
    "payments": 8,
    "payments_amount": 116834840331,
    "trades": 3,
    "traded_amount": 52723440331,
    "price": [
      0.07601508021203623,
      0.07601508021203623,
      0.0698183971830986,
      0.07123143442045127
    ],
    "volume": 3739207328
  },
  {
    "ts": 1603152000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6729,
      "authorized": 6729,
      "funded": 141
    },
    "payments": 1,
    "payments_amount": 10039400000,
    "trades": 1,
    "traded_amount": 10039400000,
    "price": [
      0.07123143442045127,
      0.07222668276988664,
      0.07123143442045127,
      0.07222668276988664
    ],
    "volume": 725112559
  },
  {
    "ts": 1603238400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 6876,
      "authorized": 6876,
      "funded": 153
    },
    "payments": 33,
    "payments_amount": 503069747243,
    "trades": 16,
    "traded_amount": 232121729926,
    "price": [
      0.07222668276988664,
      0.07254648751571642,
      0.06635160714285715,
      0.06822514347178041
    ],
    "volume": 15564811177
  },
  {
    "ts": 1603324800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7003,
      "authorized": 7003,
      "funded": 159
    },
    "payments": 25,
    "payments_amount": 279538768415,
    "trades": 13,
    "traded_amount": 156226568415,
    "price": [
      0.06822514347178041,
      0.06822514347178041,
      0.06411273873310491,
      0.06411273873310491
    ],
    "volume": 10313743931
  },
  {
    "ts": 1603411200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7093,
      "authorized": 7093,
      "funded": 161
    },
    "payments": 24,
    "payments_amount": 139941103307,
    "trades": 16,
    "traded_amount": 48101903307,
    "price": [
      0.06411273873310491,
      0.0677010790052898,
      0.06391611673954917,
      0.0673258
    ],
    "volume": 3224220504
  },
  {
    "ts": 1603497600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7189,
      "authorized": 7189,
      "funded": 163
    },
    "payments": 9,
    "payments_amount": 142129233366,
    "trades": 9,
    "traded_amount": 133425948255,
    "price": [
      0.0673258,
      0.0673258,
      0.06313921974248927,
      0.06454517850876522
    ],
    "volume": 8778460037
  },
  {
    "ts": 1603584000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7294,
      "authorized": 7294,
      "funded": 164
    },
    "payments": 4,
    "payments_amount": 4141797616,
    "trades": 5,
    "traded_amount": 34444973792,
    "price": [
      0.06454517850876522,
      0.06611166645168748,
      0.06393771812799487,
      0.06611166645168748
    ],
    "volume": 2272821916
  },
  {
    "ts": 1603670400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7396,
      "authorized": 7396,
      "funded": 168
    },
    "payments": 20,
    "payments_amount": 282389163892,
    "trades": 11,
    "traded_amount": 156819034746,
    "price": [
      0.06611166645168748,
      0.06883827857142857,
      0.06427875,
      0.06883827857142857
    ],
    "volume": 10224490220
  },
  {
    "ts": 1603756800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7511,
      "authorized": 7511,
      "funded": 178
    },
    "payments": 37,
    "payments_amount": 1312370712484,
    "trades": 20,
    "traded_amount": 607082053132,
    "price": [
      0.06883827857142857,
      0.07263411949651982,
      0.06765575798698265,
      0.07263411949651982
    ],
    "volume": 42961061082
  },
  {
    "ts": 1603843200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7589,
      "authorized": 7589,
      "funded": 179
    },
    "payments": 14,
    "payments_amount": 310762852874,
    "trades": 9,
    "traded_amount": 119505018401,
    "price": [
      0.07263411949651982,
      0.07493258157067977,
      0.07027738160845882,
      0.0726138871559633
    ],
    "volume": 8800980823
  },
  {
    "ts": 1603929600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7673,
      "authorized": 7673,
      "funded": 187
    },
    "payments": 21,
    "payments_amount": 170060868507,
    "trades": 8,
    "traded_amount": 71092054605,
    "price": [
      0.0726138871559633,
      0.07886766513872136,
      0.0726138871559633,
      0.07833361946308724
    ],
    "volume": 5512827420
  },
  {
    "ts": 1604016000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7750,
      "authorized": 7750,
      "funded": 196
    },
    "payments": 65,
    "payments_amount": 999102404124,
    "trades": 33,
    "traded_amount": 475158886786,
    "price": [
      0.07833361946308724,
      0.08124734056633943,
      0.076999888,
      0.07856033843373494
    ],
    "volume": 37198709914
  },
  {
    "ts": 1604102400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7826,
      "authorized": 7826,
      "funded": 195
    },
    "payments": 10,
    "payments_amount": 205518937630,
    "trades": 7,
    "traded_amount": 111928648144,
    "price": [
      0.07856033843373494,
      0.07875052338321432,
      0.07767906313253012,
      0.07767906313253012
    ],
    "volume": 8787603938
  },
  {
    "ts": 1604188800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7910,
      "authorized": 7910,
      "funded": 195
    },
    "payments": 2,
    "payments_amount": 19381894211,
    "trades": 2,
    "traded_amount": 18681194199,
    "price": [
      0.07767906313253012,
      0.08048059288508523,
      0.07767906313253012,
      0.08004929568704011
    ],
    "volume": 1499298113
  },
  {
    "ts": 1604275200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 7987,
      "authorized": 7987,
      "funded": 202
    },
    "payments": 26,
    "payments_amount": 1279792295376,
    "trades": 13,
    "traded_amount": 537077011154,
    "price": [
      0.08004929568704011,
      0.08634488236940169,
      0.0744517546894255,
      0.08278028588807786
    ],
    "volume": 42650251407
  },
  {
    "ts": 1604361600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8071,
      "authorized": 8071,
      "funded": 206
    },
    "payments": 27,
    "payments_amount": 2493449497550,
    "trades": 21,
    "traded_amount": 1210078693968,
    "price": [
      0.08278028588807786,
      0.08777044322516549,
      0.0799011278313253,
      0.08634805
    ],
    "volume": 101195651879
  },
  {
    "ts": 1604448000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8154,
      "authorized": 8154,
      "funded": 206
    },
    "payments": 24,
    "payments_amount": 381316855108,
    "trades": 17,
    "traded_amount": 137630466996,
    "price": [
      0.08634805,
      0.08777011252128425,
      0.08335224354594953,
      0.0848370537604175
    ],
    "volume": 11769432873
  },
  {
    "ts": 1604534400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8241,
      "authorized": 8241,
      "funded": 209
    },
    "payments": 12,
    "payments_amount": 77559157208,
    "trades": 7,
    "traded_amount": 47465947986,
    "price": [
      0.0848370537604175,
      0.08517168771427511,
      0.07796139180901729,
      0.07796139180901729
    ],
    "volume": 3966392741
  },
  {
    "ts": 1604620800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8305,
      "authorized": 8305,
      "funded": 211
    },
    "payments": 14,
    "payments_amount": 2024605997414,
    "trades": 8,
    "traded_amount": 947592897414,
    "price": [
      0.07796139180901729,
      0.08008660453124974,
      0.07638553374277651,
      0.07638553374277651
    ],
    "volume": 72453892702
  },
  {
    "ts": 1604707200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8382,
      "authorized": 8382,
      "funded": 211
    },
    "payments": 4,
    "payments_amount": 64840999413,
    "trades": 3,
    "traded_amount": 59870999413,
    "price": [
      0.07638553374277651,
      0.07741029401927492,
      0.07638553374277651,
      0.07741029401927492
    ],
    "volume": 4634605972
  },
  {
    "ts": 1604793600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8450,
      "authorized": 8450,
      "funded": 225
    },
    "payments": 23,
    "payments_amount": 99416939902,
    "trades": 6,
    "traded_amount": 46861339902,
    "price": [
      0.07741029401927492,
      0.08277326985135929,
      0.07741029401927492,
      0.08075786691542289
    ],
    "volume": 3816066971
  },
  {
    "ts": 1604880000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8548,
      "authorized": 8548,
      "funded": 227
    },
    "payments": 29,
    "payments_amount": 212994420188,
    "trades": 13,
    "traded_amount": 81375820188,
    "price": [
      0.08075786691542289,
      0.08269118111214954,
      0.08075197,
      0.08167133660647005
    ],
    "volume": 6635040165
  },
  {
    "ts": 1604966400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8672,
      "authorized": 8672,
      "funded": 229
    },
    "payments": 11,
    "payments_amount": 388170315897,
    "trades": 6,
    "traded_amount": 197047215897,
    "price": [
      0.08167133660647005,
      0.0848462777913332,
      0.08021009937357423,
      0.0845072190482588
    ],
    "volume": 16081119549
  },
  {
    "ts": 1605052800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8800,
      "authorized": 8800,
      "funded": 229
    },
    "payments": 16,
    "payments_amount": 1096378098329,
    "trades": 14,
    "traded_amount": 979541889014,
    "price": [
      0.0845072190482588,
      0.0845072190482588,
      0.08094390620891444,
      0.08358911423399391
    ],
    "volume": 81654057685
  },
  {
    "ts": 1605139200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8928,
      "authorized": 8928,
      "funded": 234
    },
    "payments": 33,
    "payments_amount": 126399514869,
    "trades": 25,
    "traded_amount": 95702646123,
    "price": [
      0.08358911423399391,
      0.08391782496423197,
      0.08058100841007869,
      0.08217289788621451
    ],
    "volume": 7867899208
  },
  {
    "ts": 1605225600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 8997,
      "authorized": 8997,
      "funded": 238
    },
    "payments": 14,
    "payments_amount": 1230927866256,
    "trades": 6,
    "traded_amount": 216131746293,
    "price": [
      0.08217289788621451,
      0.08217289788621451,
      0.07953450000751179,
      0.07973667099772998
    ],
    "volume": 17323145395
  },
  {
    "ts": 1605312000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9006,
      "authorized": 9006,
      "funded": 238
    },
    "payments": 1,
    "payments_amount": 1537278239,
    "trades": 2,
    "traded_amount": 7687742496,
    "price": [
      0.07973667099772998,
      0.08129467615894805,
      0.07973667099772998,
      0.08028369872735835
    ],
    "volume": 623418383
  },
  {
    "ts": 1605398400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9008,
      "authorized": 9008,
      "funded": 237
    },
    "payments": 11,
    "payments_amount": 30591692312,
    "trades": 11,
    "traded_amount": 35627078718,
    "price": [
      0.08028369872735835,
      0.08300749338569503,
      0.08007036600452669,
      0.08300749338569503
    ],
    "volume": 2917581086
  },
  {
    "ts": 1605484800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9182,
      "authorized": 9182,
      "funded": 241
    },
    "payments": 13,
    "payments_amount": 149373108681,
    "trades": 5,
    "traded_amount": 54591908681,
    "price": [
      0.08300749338569503,
      0.08300749338569503,
      0.0765758893360161,
      0.07666815478858509
    ],
    "volume": 4205776447
  },
  {
    "ts": 1605571200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9377,
      "authorized": 9377,
      "funded": 240
    },
    "payments": 17,
    "payments_amount": 69556281704,
    "trades": 14,
    "traded_amount": 70708091575,
    "price": [
      0.07666815478858509,
      0.07666815478858509,
      0.06959273779724656,
      0.07003413561847988
    ],
    "volume": 5165341210
  },
  {
    "ts": 1605657600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9618,
      "authorized": 9618,
      "funded": 242
    },
    "payments": 15,
    "payments_amount": 140169233588,
    "trades": 10,
    "traded_amount": 66644033588,
    "price": [
      0.07003413561847988,
      0.07645668878426669,
      0.07003413561847988,
      0.07245364703070879
    ],
    "volume": 4980117551
  },
  {
    "ts": 1605744000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9709,
      "authorized": 9709,
      "funded": 243
    },
    "payments": 7,
    "payments_amount": 61863559781,
    "trades": 5,
    "traded_amount": 43331559781,
    "price": [
      0.07245364703070879,
      0.07502740117161677,
      0.07081155027357769,
      0.07502740117161677
    ],
    "volume": 3202856656
  },
  {
    "ts": 1605830400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9764,
      "authorized": 9764,
      "funded": 247
    },
    "payments": 15,
    "payments_amount": 263588108550,
    "trades": 16,
    "traded_amount": 126248245788,
    "price": [
      0.07502740117161677,
      0.07502740117161677,
      0.068230279,
      0.06881290282131662
    ],
    "volume": 9148361984
  },
  {
    "ts": 1605916800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9779,
      "authorized": 9779,
      "funded": 249
    },
    "payments": 4,
    "payments_amount": 11164572778,
    "trades": 6,
    "traded_amount": 21243168009,
    "price": [
      0.06881290282131662,
      0.06881290282131662,
      0.05755018065173546,
      0.060586004076466034
    ],
    "volume": 1315608914
  },
  {
    "ts": 1606003200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9784,
      "authorized": 9784,
      "funded": 250
    },
    "payments": 2,
    "payments_amount": 35507525266,
    "trades": 4,
    "traded_amount": 31225551337,
    "price": [
      0.060586004076466034,
      0.06155971380842246,
      0.05627239538411378,
      0.06155971380842246
    ],
    "volume": 1851766533
  },
  {
    "ts": 1606089600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9793,
      "authorized": 9793,
      "funded": 252
    },
    "payments": 4,
    "payments_amount": 56393843417,
    "trades": 9,
    "traded_amount": 84802224767,
    "price": [
      0.06155971380842246,
      0.06155971380842246,
      0.04902159676286052,
      0.04902159676286052
    ],
    "volume": 4482078927
  },
  {
    "ts": 1606176000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9825,
      "authorized": 9825,
      "funded": 257
    },
    "payments": 26,
    "payments_amount": 341472865398,
    "trades": 11,
    "traded_amount": 156909401931,
    "price": [
      0.04902159676286052,
      0.04902159676286052,
      0.032232794960166726,
      0.032295098852553196
    ],
    "volume": 5775398832
  },
  {
    "ts": 1606262400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9827,
      "authorized": 9827,
      "funded": 258
    },
    "payments": 11,
    "payments_amount": 331056767623,
    "trades": 10,
    "traded_amount": 204112967623,
    "price": [
      0.032295098852553196,
      0.032295098852553196,
      0.02723120489145565,
      0.03191648954693825
    ],
    "volume": 5640017853
  },
  {
    "ts": 1606348800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9831,
      "authorized": 9831,
      "funded": 261
    },
    "payments": 12,
    "payments_amount": 43055401205,
    "trades": 12,
    "traded_amount": 301388955331,
    "price": [
      0.03191648954693825,
      0.04017989038579312,
      0.03030370208105148,
      0.039663008958338915
    ],
    "volume": 10127424899
  },
  {
    "ts": 1606435200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9831,
      "authorized": 9831,
      "funded": 261
    },
    "payments": 7,
    "payments_amount": 29216012464,
    "trades": 6,
    "traded_amount": 22812344835,
    "price": [
      0.039663008958338915,
      0.039663008958338915,
      0.03203331634712412,
      0.03203331634712412
    ],
    "volume": 761232658
  },
  {
    "ts": 1606521600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9852,
      "authorized": 9852,
      "funded": 264
    },
    "payments": 7,
    "payments_amount": 237231004108,
    "trades": 8,
    "traded_amount": 144028304108,
    "price": [
      0.03203331634712412,
      0.03203331634712412,
      0.02905069716013307,
      0.03170168838157076
    ],
    "volume": 4478202968
  },
  {
    "ts": 1606608000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9858,
      "authorized": 9858,
      "funded": 267
    },
    "payments": 7,
    "payments_amount": 291093422656,
    "trades": 7,
    "traded_amount": 224320122656,
    "price": [
      0.03170168838157076,
      0.03278748937572784,
      0.031169190375994877,
      0.03278748937572784
    ],
    "volume": 7223094510
  },
  {
    "ts": 1606694400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9870,
      "authorized": 9870,
      "funded": 271
    },
    "payments": 13,
    "payments_amount": 1354248035398,
    "trades": 16,
    "traded_amount": 688802335384,
    "price": [
      0.03278748937572784,
      0.03278748937572784,
      0.029748599986704476,
      0.029748599986704476
    ],
    "volume": 21946315154
  },
  {
    "ts": 1606780800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9879,
      "authorized": 9879,
      "funded": 272
    },
    "payments": 17,
    "payments_amount": 249775321556,
    "trades": 13,
    "traded_amount": 158320621556,
    "price": [
      0.029748599986704476,
      0.03416918329843019,
      0.029748599986704476,
      0.03416918329843019
    ],
    "volume": 5248272545
  },
  {
    "ts": 1606867200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9889,
      "authorized": 9889,
      "funded": 277
    },
    "payments": 10,
    "payments_amount": 493164205967,
    "trades": 7,
    "traded_amount": 216050805967,
    "price": [
      0.03416918329843019,
      0.0350648028142881,
      0.03413642046225122,
      0.0350648028142881
    ],
    "volume": 7454695194
  },
  {
    "ts": 1606953600,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9891,
      "authorized": 9891,
      "funded": 275
    },
    "payments": 6,
    "payments_amount": 217633220016,
    "trades": 6,
    "traded_amount": 167535620016,
    "price": [
      0.0350648028142881,
      0.0350648028142881,
      0.032235489365657635,
      0.032235489365657635
    ],
    "volume": 5610867943
  },
  {
    "ts": 1607040000,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9910,
      "authorized": 9910,
      "funded": 275
    },
    "payments": 4,
    "payments_amount": 119600987921,
    "trades": 5,
    "traded_amount": 62620987921,
    "price": [
      0.032235489365657635,
      0.03611969919517102,
      0.032235489365657635,
      0.03611969919517102
    ],
    "volume": 2171068514
  },
  {
    "ts": 1607126400,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9926,
      "authorized": 9926,
      "funded": 275
    },
    "payments": 3,
    "payments_amount": 34933961449,
    "trades": 3,
    "traded_amount": 34933961449,
    "price": [
      0.03611969919517102,
      0.03614824056554327,
      0.0355761068278501,
      0.03614824056554327
    ],
    "volume": 1250000000
  },
  {
    "ts": 1607212800,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9944,
      "authorized": 9944,
      "funded": 275
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.03614824056554327,
      0.03614824056554327,
      0.03614824056554327,
      0.03614824056554327
    ],
    "volume": 0
  },
  {
    "ts": 1607299200,
    "supply": 29551455900000,
    "trustlines": {
      "total": 9959,
      "authorized": 9959,
      "funded": 275
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.03614824056554327,
      0.03614824056554327,
      0.03614824056554327,
      0.03614824056554327
    ],
    "volume": 0
  },
  {
    "ts": 1607385600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 9977,
      "authorized": 9977,
      "funded": 276
    },
    "payments": 4,
    "payments_amount": 30703237085,
    "trades": 2,
    "traded_amount": 15377837085,
    "price": [
      0.03614824056554327,
      0.04037316391490119,
      0.03614824056554327,
      0.04037316391490119
    ],
    "volume": 620650819
  },
  {
    "ts": 1607472000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 9998,
      "authorized": 9998,
      "funded": 277
    },
    "payments": 15,
    "payments_amount": 189173171866,
    "trades": 9,
    "traded_amount": 108597971866,
    "price": [
      0.04037316391490119,
      0.04330419061666161,
      0.0381907950854709,
      0.04021090452587936
    ],
    "volume": 4282526788
  },
  {
    "ts": 1607558400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10027,
      "authorized": 10027,
      "funded": 278
    },
    "payments": 7,
    "payments_amount": 84600943294,
    "trades": 4,
    "traded_amount": 19866743294,
    "price": [
      0.04021090452587936,
      0.04034631915569601,
      0.0384397021577608,
      0.0384397021577608
    ],
    "volume": 772415049
  },
  {
    "ts": 1607644800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10052,
      "authorized": 10052,
      "funded": 278
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.0384397021577608,
      0.0384397021577608,
      0.0384397021577608,
      0.0384397021577608
    ],
    "volume": 0
  },
  {
    "ts": 1607731200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10066,
      "authorized": 10066,
      "funded": 277
    },
    "payments": 3,
    "payments_amount": 3003957138,
    "trades": 5,
    "traded_amount": 3003957138,
    "price": [
      0.0384397021577608,
      0.044110122006151296,
      0.0384397021577608,
      0.044110122006151296
    ],
    "volume": 132288319
  },
  {
    "ts": 1607817600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10083,
      "authorized": 10083,
      "funded": 277
    },
    "payments": 2,
    "payments_amount": 19069769447,
    "trades": 1,
    "traded_amount": 9069769447,
    "price": [
      0.044110122006151296,
      0.044110122006151296,
      0.037784489341496266,
      0.037784489341496266
    ],
    "volume": 342696607
  },
  {
    "ts": 1607904000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10102,
      "authorized": 10102,
      "funded": 278
    },
    "payments": 4,
    "payments_amount": 11350722163,
    "trades": 3,
    "traded_amount": 9940722163,
    "price": [
      0.037784489341496266,
      0.039134987120303756,
      0.03724370402414487,
      0.03896960806641119
    ],
    "volume": 379130024
  },
  {
    "ts": 1607990400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10127,
      "authorized": 10127,
      "funded": 277
    },
    "payments": 6,
    "payments_amount": 18329723552,
    "trades": 5,
    "traded_amount": 14701623552,
    "price": [
      0.03896960806641119,
      0.03896960806641119,
      0.036944014084507044,
      0.036944014084507044
    ],
    "volume": 560149668
  },
  {
    "ts": 1608076800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10147,
      "authorized": 10147,
      "funded": 277
    },
    "payments": 1,
    "payments_amount": 2450000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.036944014084507044,
      0.036944014084507044,
      0.036944014084507044,
      0.036944014084507044
    ],
    "volume": 0
  },
  {
    "ts": 1608163200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10181,
      "authorized": 10181,
      "funded": 281
    },
    "payments": 5,
    "payments_amount": 61944588284,
    "trades": 4,
    "traded_amount": 49887388284,
    "price": [
      0.036944014084507044,
      0.03751050283306682,
      0.03513818487906617,
      0.03751050283306682
    ],
    "volume": 1786908604
  },
  {
    "ts": 1608249600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10219,
      "authorized": 10219,
      "funded": 282
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 3999999990,
    "price": [
      0.03751050283306682,
      0.038349290501529194,
      0.03751050283306682,
      0.038222
    ],
    "volume": 153015290
  },
  {
    "ts": 1608336000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10258,
      "authorized": 10258,
      "funded": 285
    },
    "payments": 5,
    "payments_amount": 1823438787529,
    "trades": 13,
    "traded_amount": 1913496305229,
    "price": [
      0.038222,
      0.03850412663251685,
      0.03539071155958612,
      0.03539071155958612
    ],
    "volume": 70830112441
  },
  {
    "ts": 1608422400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10296,
      "authorized": 10296,
      "funded": 285
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.03539071155958612,
      0.03539071155958612,
      0.03539071155958612,
      0.03539071155958612
    ],
    "volume": 0
  },
  {
    "ts": 1608508800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10334,
      "authorized": 10334,
      "funded": 285
    },
    "payments": 8,
    "payments_amount": 37594687020,
    "trades": 5,
    "traded_amount": 104936438713,
    "price": [
      0.03539071155958612,
      0.041813821856807994,
      0.03539071155958612,
      0.04017530120724346
    ],
    "volume": 4243685863
  },
  {
    "ts": 1608595200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10361,
      "authorized": 10361,
      "funded": 284
    },
    "payments": 12,
    "payments_amount": 206679230322,
    "trades": 7,
    "traded_amount": 181302130322,
    "price": [
      0.04017530120724346,
      0.04279387581834786,
      0.03972717474849095,
      0.04279387581834786
    ],
    "volume": 7451389777
  },
  {
    "ts": 1608681600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10399,
      "authorized": 10399,
      "funded": 289
    },
    "payments": 11,
    "payments_amount": 21868000000,
    "trades": 8,
    "traded_amount": 59113820558,
    "price": [
      0.04279387581834786,
      0.05165810261569417,
      0.04279387576795244,
      0.05165810261569417
    ],
    "volume": 2683982913
  },
  {
    "ts": 1608768000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10429,
      "authorized": 10429,
      "funded": 288
    },
    "payments": 3,
    "payments_amount": 47498013074,
    "trades": 5,
    "traded_amount": 83990244321,
    "price": [
      0.05165810261569417,
      0.05165810261569417,
      0.04228798948062931,
      0.043606216010591135
    ],
    "volume": 3604901676
  },
  {
    "ts": 1608854400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10436,
      "authorized": 10436,
      "funded": 290
    },
    "payments": 2,
    "payments_amount": 263130243464,
    "trades": 7,
    "traded_amount": 206743055034,
    "price": [
      0.043606216010591135,
      0.044977698130954466,
      0.04102970896512306,
      0.0439763
    ],
    "volume": 8967779093
  },
  {
    "ts": 1608940800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10440,
      "authorized": 10440,
      "funded": 292
    },
    "payments": 6,
    "payments_amount": 288717870323,
    "trades": 7,
    "traded_amount": 146326668006,
    "price": [
      0.0439763,
      0.04831779019210786,
      0.0439763,
      0.04831779019210786
    ],
    "volume": 7006362573
  },
  {
    "ts": 1609027200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10443,
      "authorized": 10443,
      "funded": 291
    },
    "payments": 3,
    "payments_amount": 19258589056,
    "trades": 4,
    "traded_amount": 19258589056,
    "price": [
      0.04831779019210786,
      0.04831779019210786,
      0.04505447669679013,
      0.04505447669679013
    ],
    "volume": 896524470
  },
  {
    "ts": 1609113600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10450,
      "authorized": 10450,
      "funded": 295
    },
    "payments": 5,
    "payments_amount": 43378287203,
    "trades": 5,
    "traded_amount": 22369672361,
    "price": [
      0.04505447669679013,
      0.04850557073023348,
      0.04505447669679013,
      0.047040203327507316
    ],
    "volume": 1058368783
  },
  {
    "ts": 1609200000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10451,
      "authorized": 10451,
      "funded": 296
    },
    "payments": 4,
    "payments_amount": 39792000000,
    "trades": 1,
    "traded_amount": 16708500000,
    "price": [
      0.047040203327507316,
      0.048655169883592184,
      0.047040203327507316,
      0.048655169883592184
    ],
    "volume": 812954906
  },
  {
    "ts": 1609286400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10456,
      "authorized": 10456,
      "funded": 296
    },
    "payments": 4,
    "payments_amount": 111558311490,
    "trades": 5,
    "traded_amount": 65973511490,
    "price": [
      0.048655169883592184,
      0.05354008372062891,
      0.04626356021476409,
      0.053089014109572265
    ],
    "volume": 3363388035
  },
  {
    "ts": 1609372800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10465,
      "authorized": 10465,
      "funded": 297
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 25435756836,
    "price": [
      0.053089014109572265,
      0.05517499752977851,
      0.053089014109572265,
      0.05517499752977851
    ],
    "volume": 1403417813
  },
  {
    "ts": 1609459200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10468,
      "authorized": 10468,
      "funded": 297
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.05517499752977851,
      0.05517499752977851,
      0.05517499752977851,
      0.05517499752977851
    ],
    "volume": 0
  },
  {
    "ts": 1609545600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10474,
      "authorized": 10474,
      "funded": 297
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.05517499752977851,
      0.05517499752977851,
      0.05517499752977851,
      0.05517499752977851
    ],
    "volume": 0
  },
  {
    "ts": 1609632000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10478,
      "authorized": 10478,
      "funded": 298
    },
    "payments": 3,
    "payments_amount": 141147164204,
    "trades": 4,
    "traded_amount": 166582921031,
    "price": [
      0.05517499752977851,
      0.05517499752977851,
      0.043342892428887426,
      0.0523986240414759
    ],
    "volume": 7397530161
  },
  {
    "ts": 1609718400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10480,
      "authorized": 10480,
      "funded": 303
    },
    "payments": 11,
    "payments_amount": 41567105233,
    "trades": 12,
    "traded_amount": 469105229,
    "price": [
      0.0523986240414759,
      0.05405405405405406,
      0.03682359648140093,
      0.03682359648140093
    ],
    "volume": 20384940
  },
  {
    "ts": 1609804800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10488,
      "authorized": 10488,
      "funded": 305
    },
    "payments": 11,
    "payments_amount": 155120529057,
    "trades": 8,
    "traded_amount": 112112269826,
    "price": [
      0.03682359648140093,
      0.03972259654003722,
      0.0295625,
      0.031619786292134834
    ],
    "volume": 3770362589
  },
  {
    "ts": 1609891200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10495,
      "authorized": 10495,
      "funded": 306
    },
    "payments": 4,
    "payments_amount": 155963218787,
    "trades": 14,
    "traded_amount": 305831181388,
    "price": [
      0.031619786292134834,
      0.031619786292134834,
      0.016986595858496534,
      0.01829931391274173
    ],
    "volume": 6458568291
  },
  {
    "ts": 1609977600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10516,
      "authorized": 10516,
      "funded": 308
    },
    "payments": 7,
    "payments_amount": 147872445644,
    "trades": 7,
    "traded_amount": 221953571197,
    "price": [
      0.01829931391274173,
      0.02114019406648139,
      0.01829931391274173,
      0.019048893661971832
    ],
    "volume": 4307162824
  },
  {
    "ts": 1610064000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10541,
      "authorized": 10541,
      "funded": 306
    },
    "payments": 5,
    "payments_amount": 23618800000,
    "trades": 5,
    "traded_amount": 77548798630,
    "price": [
      0.019048893661971832,
      0.021523799585543273,
      0.019048893661971832,
      0.021523799585543273
    ],
    "volume": 1629206981
  },
  {
    "ts": 1610150400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10560,
      "authorized": 10560,
      "funded": 306
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 999999955,
    "price": [
      0.021523799585543273,
      0.021819099981859498,
      0.021523799585543273,
      0.021819099981859498
    ],
    "volume": 21819099
  },
  {
    "ts": 1610236800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10599,
      "authorized": 10599,
      "funded": 307
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 9999959,
    "price": [
      0.021819099981859498,
      0.02274599325857236,
      0.021819099981859498,
      0.02274599325857236
    ],
    "volume": 227459
  },
  {
    "ts": 1610323200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10640,
      "authorized": 10640,
      "funded": 308
    },
    "payments": 7,
    "payments_amount": 528102200000,
    "trades": 8,
    "traded_amount": 369378641533,
    "price": [
      0.02274599325857236,
      0.029199198024943706,
      0.02274599325857236,
      0.028608989014084506
    ],
    "volume": 10529135338
  },
  {
    "ts": 1610409600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10666,
      "authorized": 10666,
      "funded": 307
    },
    "payments": 12,
    "payments_amount": 298576994079,
    "trades": 4,
    "traded_amount": 113496775388,
    "price": [
      0.028608989014084506,
      0.028608989014084506,
      0.02105348447829836,
      0.022929298789219826
    ],
    "volume": 2627021520
  },
  {
    "ts": 1610496000,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10712,
      "authorized": 10712,
      "funded": 307
    },
    "payments": 6,
    "payments_amount": 234015454959,
    "trades": 4,
    "traded_amount": 121452554959,
    "price": [
      0.022929298789219826,
      0.022929298789219826,
      0.021240891529582227,
      0.021240891529582227
    ],
    "volume": 2602877494
  },
  {
    "ts": 1610582400,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10747,
      "authorized": 10747,
      "funded": 308
    },
    "payments": 8,
    "payments_amount": 309900000000,
    "trades": 4,
    "traded_amount": 144450000000,
    "price": [
      0.021240891529582227,
      0.02137315,
      0.01972220256,
      0.01972220256
    ],
    "volume": 2952899370
  },
  {
    "ts": 1610668800,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10777,
      "authorized": 10777,
      "funded": 309
    },
    "payments": 6,
    "payments_amount": 160834637238,
    "trades": 4,
    "traded_amount": 79576137196,
    "price": [
      0.01972220256,
      0.02259210865191147,
      0.01972220256,
      0.02259210865191147
    ],
    "volume": 1796471541
  },
  {
    "ts": 1610755200,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10814,
      "authorized": 10814,
      "funded": 307
    },
    "payments": 2,
    "payments_amount": 6709500000,
    "trades": 4,
    "traded_amount": 7309499928,
    "price": [
      0.02259210865191147,
      0.02259210865191147,
      0.02040510523138833,
      0.02040510523138833
    ],
    "volume": 151035808
  },
  {
    "ts": 1610841600,
    "supply": 29550455900000,
    "trustlines": {
      "total": 10845,
      "authorized": 10845,
      "funded": 307
    },
    "payments": 2,
    "payments_amount": 30649814615,
    "trades": 2,
    "traded_amount": 16833414604,
    "price": [
      0.02040510523138833,
      0.021358010913927875,
      0.02040510523138833,
      0.021358010913927875
    ],
    "volume": 359249903
  },
  {
    "ts": 1610928000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 10867,
      "authorized": 10867,
      "funded": 306
    },
    "payments": 12,
    "payments_amount": 88877899870,
    "trades": 6,
    "traded_amount": 44484302176,
    "price": [
      0.021358010913927875,
      0.021848601752288598,
      0.021067387323943663,
      0.021127114871044447
    ],
    "volume": 953024607
  },
  {
    "ts": 1611014400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 10908,
      "authorized": 10908,
      "funded": 309
    },
    "payments": 9,
    "payments_amount": 254593351632,
    "trades": 5,
    "traded_amount": 29563351658,
    "price": [
      0.021127114871044447,
      0.02157168869029404,
      0.02031599698189135,
      0.02147251493652403
    ],
    "volume": 616427617
  },
  {
    "ts": 1611100800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 10951,
      "authorized": 10951,
      "funded": 311
    },
    "payments": 22,
    "payments_amount": 1266559013464,
    "trades": 21,
    "traded_amount": 750415813464,
    "price": [
      0.02147251493652403,
      0.0229242,
      0.02105949727603855,
      0.02274049296969697
    ],
    "volume": 16481989353
  },
  {
    "ts": 1611187200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 10982,
      "authorized": 10982,
      "funded": 312
    },
    "payments": 4,
    "payments_amount": 94288120851,
    "trades": 4,
    "traded_amount": 46760420851,
    "price": [
      0.02274049296969697,
      0.02322100761718079,
      0.022644390610479875,
      0.02322100761718079
    ],
    "volume": 1059745009
  },
  {
    "ts": 1611273600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11048,
      "authorized": 11048,
      "funded": 311
    },
    "payments": 4,
    "payments_amount": 22594020000,
    "trades": 3,
    "traded_amount": 5994020000,
    "price": [
      0.02322100761718079,
      0.02378719718309859,
      0.0231623016,
      0.02378719718309859
    ],
    "volume": 139456456
  },
  {
    "ts": 1611360000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11088,
      "authorized": 11088,
      "funded": 311
    },
    "payments": 5,
    "payments_amount": 411170100370,
    "trades": 9,
    "traded_amount": 411170100370,
    "price": [
      0.02378719718309859,
      0.024503122092386156,
      0.023461594720656075,
      0.023717781870088536
    ],
    "volume": 9893334933
  },
  {
    "ts": 1611446400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11120,
      "authorized": 11120,
      "funded": 311
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.023717781870088536,
      0.023717781870088536,
      0.023717781870088536,
      0.023717781870088536
    ],
    "volume": 0
  },
  {
    "ts": 1611532800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11167,
      "authorized": 11167,
      "funded": 318
    },
    "payments": 19,
    "payments_amount": 532079772215,
    "trades": 12,
    "traded_amount": 268087572140,
    "price": [
      0.023717781870088536,
      0.025340800066201652,
      0.02343539013973214,
      0.02424469176811409
    ],
    "volume": 6492793298
  },
  {
    "ts": 1611619200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11213,
      "authorized": 11213,
      "funded": 319
    },
    "payments": 5,
    "payments_amount": 43409126412,
    "trades": 3,
    "traded_amount": 19739226412,
    "price": [
      0.02424469176811409,
      0.025916912889189528,
      0.02424469176811409,
      0.025536179142665472
    ],
    "volume": 505791089
  },
  {
    "ts": 1611705600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11260,
      "authorized": 11260,
      "funded": 319
    },
    "payments": 3,
    "payments_amount": 6711719113,
    "trades": 2,
    "traded_amount": 3729719113,
    "price": [
      0.025536179142665472,
      0.0273395123860631,
      0.025536179142665472,
      0.0273395123860631
    ],
    "volume": 101959253
  },
  {
    "ts": 1611792000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11291,
      "authorized": 11291,
      "funded": 319
    },
    "payments": 2,
    "payments_amount": 39760000000,
    "trades": 2,
    "traded_amount": 19880000000,
    "price": [
      0.0273395123860631,
      0.0273395123860631,
      0.02512831688351655,
      0.02512831688351655
    ],
    "volume": 499550945
  },
  {
    "ts": 1611878400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11339,
      "authorized": 11339,
      "funded": 320
    },
    "payments": 5,
    "payments_amount": 79991335386,
    "trades": 5,
    "traded_amount": 70660524990,
    "price": [
      0.02512831688351655,
      0.02512831688351655,
      0.01804429826866469,
      0.021757893558942303
    ],
    "volume": 1384832487
  },
  {
    "ts": 1611964800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11396,
      "authorized": 11396,
      "funded": 321
    },
    "payments": 4,
    "payments_amount": 15328321999,
    "trades": 5,
    "traded_amount": 45076534842,
    "price": [
      0.021757893558942303,
      0.023228976923076922,
      0.019270798519074588,
      0.020141512279040236
    ],
    "volume": 890938717
  },
  {
    "ts": 1612051200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11456,
      "authorized": 11456,
      "funded": 321
    },
    "payments": 1,
    "payments_amount": 5404372290,
    "trades": 2,
    "traded_amount": 5426827969,
    "price": [
      0.020141512279040236,
      0.02226608244622663,
      0.020141512279040236,
      0.02226608244622663
    ],
    "volume": 112411576
  },
  {
    "ts": 1612137600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11510,
      "authorized": 11510,
      "funded": 324
    },
    "payments": 16,
    "payments_amount": 219160020394,
    "trades": 10,
    "traded_amount": 180088801889,
    "price": [
      0.02226608244622663,
      0.02226608244622663,
      0.019567900026528834,
      0.020662596931589537
    ],
    "volume": 3611881319
  },
  {
    "ts": 1612224000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11515,
      "authorized": 11515,
      "funded": 325
    },
    "payments": 5,
    "payments_amount": 53156717430,
    "trades": 5,
    "traded_amount": 39781949635,
    "price": [
      0.020662596931589537,
      0.020662596931589537,
      0.019083493333333333,
      0.019083493333333333
    ],
    "volume": 787019232
  },
  {
    "ts": 1612310400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11523,
      "authorized": 11523,
      "funded": 328
    },
    "payments": 10,
    "payments_amount": 438165967539,
    "trades": 7,
    "traded_amount": 18274002691,
    "price": [
      0.019083493333333333,
      0.019808099532916325,
      0.019072707440980177,
      0.019072707440980177
    ],
    "volume": 355324872
  },
  {
    "ts": 1612396800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11534,
      "authorized": 11534,
      "funded": 329
    },
    "payments": 19,
    "payments_amount": 116470000120,
    "trades": 18,
    "traded_amount": 116689550547,
    "price": [
      0.019072707440980177,
      0.02099918949465365,
      0.019072707440980177,
      0.020439795197977156
    ],
    "volume": 2373717361
  },
  {
    "ts": 1612483200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11538,
      "authorized": 11538,
      "funded": 330
    },
    "payments": 12,
    "payments_amount": 374939504725,
    "trades": 14,
    "traded_amount": 276949416716,
    "price": [
      0.020439795197977156,
      0.020439795197977156,
      0.018940901,
      0.0190958925
    ],
    "volume": 5296030214
  },
  {
    "ts": 1612569600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11541,
      "authorized": 11541,
      "funded": 331
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 54916082904,
    "price": [
      0.0190958925,
      0.01976508388471205,
      0.0190958925,
      0.019731010231468987
    ],
    "volume": 1078417425
  },
  {
    "ts": 1612656000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11542,
      "authorized": 11542,
      "funded": 332
    },
    "payments": 7,
    "payments_amount": 86819491223,
    "trades": 9,
    "traded_amount": 91755331685,
    "price": [
      0.019731010231468987,
      0.019731010231468987,
      0.016665592590205756,
      0.01774020000013868
    ],
    "volume": 1637774615
  },
  {
    "ts": 1612742400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11542,
      "authorized": 11542,
      "funded": 331
    },
    "payments": 13,
    "payments_amount": 131311880015,
    "trades": 7,
    "traded_amount": 28848980015,
    "price": [
      0.01774020000013868,
      0.01774020000013868,
      0.016544005835612295,
      0.016851391438356164
    ],
    "volume": 482093681
  },
  {
    "ts": 1612828800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11547,
      "authorized": 11547,
      "funded": 332
    },
    "payments": 13,
    "payments_amount": 403840033397,
    "trades": 153,
    "traded_amount": 5425553774453,
    "price": [
      0.016851391438356164,
      0.016851391438356164,
      0.015533598049334896,
      0.015533598049334896
    ],
    "volume": 42032001027
  },
  {
    "ts": 1612915200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11547,
      "authorized": 11547,
      "funded": 335
    },
    "payments": 4,
    "payments_amount": 17354565733,
    "trades": 228,
    "traded_amount": 31659495522643,
    "price": [
      0.015533598049334896,
      0.01603480149535374,
      0.014548577036310108,
      0.015086998424485472
    ],
    "volume": 244409363715
  },
  {
    "ts": 1613001600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11547,
      "authorized": 11547,
      "funded": 335
    },
    "payments": 6,
    "payments_amount": 50071069411,
    "trades": 34,
    "traded_amount": 8601059573443,
    "price": [
      0.015086998424485472,
      0.015363500804828973,
      0.013665991885076483,
      0.013749200793530125
    ],
    "volume": 59836307039
  },
  {
    "ts": 1613088000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11550,
      "authorized": 11550,
      "funded": 334
    },
    "payments": 6,
    "payments_amount": 420102899017,
    "trades": 20,
    "traded_amount": 8308056137923,
    "price": [
      0.013749200793530125,
      0.013749200793530125,
      0.011917698360339428,
      0.011917698360339428
    ],
    "volume": 55097211772
  },
  {
    "ts": 1613174400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11559,
      "authorized": 11559,
      "funded": 338
    },
    "payments": 3,
    "payments_amount": 287308970836,
    "trades": 269,
    "traded_amount": 86947844444689,
    "price": [
      0.011917698360339428,
      0.011931188368243001,
      0.010919796367981623,
      0.011537411910895915
    ],
    "volume": 481715058800
  },
  {
    "ts": 1613260800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11559,
      "authorized": 11559,
      "funded": 337
    },
    "payments": 1,
    "payments_amount": 20925730,
    "trades": 45,
    "traded_amount": 16306547829224,
    "price": [
      0.011537411910895915,
      0.012360599910692414,
      0.011537411910895915,
      0.012360599910692414
    ],
    "volume": 99741648719
  },
  {
    "ts": 1613347200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11565,
      "authorized": 11565,
      "funded": 338
    },
    "payments": 3,
    "payments_amount": 10940223554,
    "trades": 3,
    "traded_amount": 447108,
    "price": [
      0.012360599910692414,
      0.012360599910692414,
      0.011952369449886828,
      0.011952369449886828
    ],
    "volume": 5429
  },
  {
    "ts": 1613433600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11568,
      "authorized": 11568,
      "funded": 336
    },
    "payments": 3,
    "payments_amount": 12989494490,
    "trades": 9,
    "traded_amount": 5229738696361,
    "price": [
      0.011952369449886828,
      0.013570796465248626,
      0.011952369449886828,
      0.013295005130784708
    ],
    "volume": 35182152351
  },
  {
    "ts": 1613520000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11567,
      "authorized": 11567,
      "funded": 335
    },
    "payments": 7,
    "payments_amount": 189534266177,
    "trades": 5,
    "traded_amount": 94824866177,
    "price": [
      0.013295005130784708,
      0.013295005130784708,
      0.01280820078834426,
      0.01280820078834426
    ],
    "volume": 583433390
  },
  {
    "ts": 1613606400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11569,
      "authorized": 11569,
      "funded": 333
    },
    "payments": 10,
    "payments_amount": 1882969630500,
    "trades": 16,
    "traded_amount": 1864198528207,
    "price": [
      0.01280820078834426,
      0.01320069036587267,
      0.0127672015,
      0.013088498342864399
    ],
    "volume": 20680153918
  },
  {
    "ts": 1613692800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11572,
      "authorized": 11572,
      "funded": 332
    },
    "payments": 16,
    "payments_amount": 402926836760,
    "trades": 13,
    "traded_amount": 353046836760,
    "price": [
      0.013088498342864399,
      0.013088498342864399,
      0.012568395,
      0.012760399310509036
    ],
    "volume": 2217299510
  },
  {
    "ts": 1613779200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11573,
      "authorized": 11573,
      "funded": 331
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 11,
    "traded_amount": 15069798936689,
    "price": [
      0.012760399310509036,
      0.014031691831270248,
      0.012527604355992827,
      0.014031691831270248
    ],
    "volume": 94788737934
  },
  {
    "ts": 1613865600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11574,
      "authorized": 11574,
      "funded": 331
    },
    "payments": 3,
    "payments_amount": 11991562944,
    "trades": 5,
    "traded_amount": 34283136684,
    "price": [
      0.014031691831270248,
      0.014031691831270248,
      0.013246995410395987,
      0.013457993800446919
    ],
    "volume": 388866497
  },
  {
    "ts": 1613952000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11573,
      "authorized": 11573,
      "funded": 331
    },
    "payments": 10,
    "payments_amount": 20681729135,
    "trades": 29,
    "traded_amount": 14757944886047,
    "price": [
      0.013457993800446919,
      0.017744896472631656,
      0.013457993800446919,
      0.014656891367726622
    ],
    "volume": 130510006093
  },
  {
    "ts": 1614038400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11575,
      "authorized": 11575,
      "funded": 333
    },
    "payments": 5,
    "payments_amount": 41779190332,
    "trades": 15,
    "traded_amount": 6363468900756,
    "price": [
      0.014656891367726622,
      0.01984880287265835,
      0.014656891367726622,
      0.01872505640893599
    ],
    "volume": 62866327127
  },
  {
    "ts": 1614124800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11580,
      "authorized": 11580,
      "funded": 334
    },
    "payments": 12,
    "payments_amount": 361014261717,
    "trades": 12,
    "traded_amount": 186614261717,
    "price": [
      0.01872505640893599,
      0.01872505640893599,
      0.01616499575,
      0.01644849964064149
    ],
    "volume": 1429632171
  },
  {
    "ts": 1614211200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11582,
      "authorized": 11582,
      "funded": 334
    },
    "payments": 12,
    "payments_amount": 165400000000,
    "trades": 11,
    "traded_amount": 82700000000,
    "price": [
      0.01644849964064149,
      0.01644849964064149,
      0.015085499,
      0.015144495375
    ],
    "volume": 1252911577
  },
  {
    "ts": 1614297600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11585,
      "authorized": 11585,
      "funded": 335
    },
    "payments": 5,
    "payments_amount": 720680803104,
    "trades": 16,
    "traded_amount": 978223387252,
    "price": [
      0.015144495375,
      0.0168504006267001,
      0.015144495375,
      0.01560520213343541
    ],
    "volume": 7285009344
  },
  {
    "ts": 1614384000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11583,
      "authorized": 11583,
      "funded": 336
    },
    "payments": 2,
    "payments_amount": 287494801523,
    "trades": 5,
    "traded_amount": 287710160750,
    "price": [
      0.01560520213343541,
      0.016281502493952715,
      0.014575785972708753,
      0.014575785972708753
    ],
    "volume": 4677407959
  },
  {
    "ts": 1614470400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11584,
      "authorized": 11584,
      "funded": 336
    },
    "payments": 3,
    "payments_amount": 578067920033,
    "trades": 9,
    "traded_amount": 578431244041,
    "price": [
      0.014575785972708753,
      0.016597799290035922,
      0.014575785972708753,
      0.01642449659812337
    ],
    "volume": 9538887050
  },
  {
    "ts": 1614556800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11586,
      "authorized": 11586,
      "funded": 337
    },
    "payments": 9,
    "payments_amount": 91535005868,
    "trades": 6,
    "traded_amount": 51878105868,
    "price": [
      0.01642449659812337,
      0.01642449659812337,
      0.015693102411029662,
      0.015693102411029662
    ],
    "volume": 643479660
  },
  {
    "ts": 1614643200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11592,
      "authorized": 11592,
      "funded": 339
    },
    "payments": 17,
    "payments_amount": 175499902902,
    "trades": 17,
    "traded_amount": 138856748814,
    "price": [
      0.015693102411029662,
      0.016005304,
      0.015033590102321795,
      0.015679900295983994
    ],
    "volume": 891087975
  },
  {
    "ts": 1614729600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11604,
      "authorized": 11604,
      "funded": 340
    },
    "payments": 18,
    "payments_amount": 314168911444,
    "trades": 14,
    "traded_amount": 255662650051,
    "price": [
      0.015679900295983994,
      0.015679900295983994,
      0.014648796643528979,
      0.015078499799301783
    ],
    "volume": 1387692148
  },
  {
    "ts": 1614816000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11613,
      "authorized": 11613,
      "funded": 340
    },
    "payments": 17,
    "payments_amount": 455785262814,
    "trades": 20,
    "traded_amount": 250359949534,
    "price": [
      0.015078499799301783,
      0.01585378698464083,
      0.0149641014,
      0.01560399
    ],
    "volume": 3171812828
  },
  {
    "ts": 1614902400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11626,
      "authorized": 11626,
      "funded": 339
    },
    "payments": 11,
    "payments_amount": 655724463893,
    "trades": 12,
    "traded_amount": 477663463201,
    "price": [
      0.01560399,
      0.016059696804763515,
      0.015513589741049985,
      0.015981502449903044
    ],
    "volume": 7467018144
  },
  {
    "ts": 1614988800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11633,
      "authorized": 11633,
      "funded": 339
    },
    "payments": 3,
    "payments_amount": 32407725316,
    "trades": 5,
    "traded_amount": 32407725316,
    "price": [
      0.015981502449903044,
      0.01621051743075698,
      0.01572409748634889,
      0.01621051743075698
    ],
    "volume": 524559864
  },
  {
    "ts": 1615075200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11633,
      "authorized": 11633,
      "funded": 339
    },
    "payments": 1,
    "payments_amount": 1004700000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      0.01621051743075698,
      0.01621051743075698,
      0.01621051743075698,
      0.01621051743075698
    ],
    "volume": 0
  },
  {
    "ts": 1615161600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11641,
      "authorized": 11641,
      "funded": 342
    },
    "payments": 17,
    "payments_amount": 114223366792,
    "trades": 17,
    "traded_amount": 21948153879,
    "price": [
      0.01621051743075698,
      0.01621051743075698,
      0.01504030189652948,
      0.01509140896524117
    ],
    "volume": 305580141
  },
  {
    "ts": 1615248000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11649,
      "authorized": 11649,
      "funded": 344
    },
    "payments": 27,
    "payments_amount": 487903447677,
    "trades": 33,
    "traded_amount": 480417358334,
    "price": [
      0.01509140896524117,
      0.015577495875173243,
      0.0146072975,
      0.0146072975
    ],
    "volume": 7262004264
  },
  {
    "ts": 1615334400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11653,
      "authorized": 11653,
      "funded": 346
    },
    "payments": 6,
    "payments_amount": 38934602189,
    "trades": 7,
    "traded_amount": 45380576188,
    "price": [
      0.0146072975,
      0.015695397960671053,
      0.0146072975,
      0.015695397960671053
    ],
    "volume": 708409143
  },
  {
    "ts": 1615420800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11659,
      "authorized": 11659,
      "funded": 349
    },
    "payments": 7,
    "payments_amount": 573234504959,
    "trades": 14,
    "traded_amount": 399156307116,
    "price": [
      0.015695397960671053,
      0.016383600000581647,
      0.015452697612421072,
      0.016207200000190065
    ],
    "volume": 6278392717
  },
  {
    "ts": 1615507200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11665,
      "authorized": 11665,
      "funded": 352
    },
    "payments": 4,
    "payments_amount": 82071878972,
    "trades": 6,
    "traded_amount": 72131878972,
    "price": [
      0.016207200000190065,
      0.016733607736078137,
      0.016207200000190065,
      0.01650870169773159
    ],
    "volume": 1193178746
  },
  {
    "ts": 1615593600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11670,
      "authorized": 11670,
      "funded": 352
    },
    "payments": 5,
    "payments_amount": 126530837602,
    "trades": 8,
    "traded_amount": 134030837602,
    "price": [
      0.01650870169773159,
      0.01650870169773159,
      0.015694903820258774,
      0.015947588199697226
    ],
    "volume": 2161594818
  },
  {
    "ts": 1615680000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11673,
      "authorized": 11673,
      "funded": 351
    },
    "payments": 11,
    "payments_amount": 3706090004049,
    "trades": 13,
    "traded_amount": 1896409997731,
    "price": [
      0.015947588199697226,
      0.016438496389060368,
      0.015947588199697226,
      0.016438496389060368
    ],
    "volume": 25937412017
  },
  {
    "ts": 1615766400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11674,
      "authorized": 11674,
      "funded": 351
    },
    "payments": 18,
    "payments_amount": 985650339674,
    "trades": 18,
    "traded_amount": 561375939669,
    "price": [
      0.016438496389060368,
      0.017192495881561284,
      0.01611460120263673,
      0.016642009286187295
    ],
    "volume": 9381907857
  },
  {
    "ts": 1615852800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11678,
      "authorized": 11678,
      "funded": 352
    },
    "payments": 10,
    "payments_amount": 122684484188,
    "trades": 6,
    "traded_amount": 96550484188,
    "price": [
      0.016642009286187295,
      0.016996095519531015,
      0.015980182796780686,
      0.01631819718309859
    ],
    "volume": 1611461833
  },
  {
    "ts": 1615939200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11680,
      "authorized": 11680,
      "funded": 353
    },
    "payments": 12,
    "payments_amount": 365025605130,
    "trades": 12,
    "traded_amount": 237649259941,
    "price": [
      0.01631819718309859,
      0.01631819718309859,
      0.015773902111975536,
      0.016151505703194714
    ],
    "volume": 3853962153
  },
  {
    "ts": 1616025600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11680,
      "authorized": 11680,
      "funded": 350
    },
    "payments": 12,
    "payments_amount": 228947724794,
    "trades": 11,
    "traded_amount": 168426024794,
    "price": [
      0.016151505703194714,
      0.01618200079380926,
      0.015592401864035087,
      0.016033298491810476
    ],
    "volume": 2540948321
  },
  {
    "ts": 1616112000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11684,
      "authorized": 11684,
      "funded": 351
    },
    "payments": 11,
    "payments_amount": 101089935757,
    "trades": 10,
    "traded_amount": 82148765958,
    "price": [
      0.016033298491810476,
      0.016625405334455133,
      0.01599158,
      0.016625405334455133
    ],
    "volume": 1307150824
  },
  {
    "ts": 1616198400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11688,
      "authorized": 11688,
      "funded": 352
    },
    "payments": 3,
    "payments_amount": 63226130244,
    "trades": 3,
    "traded_amount": 41226130244,
    "price": [
      0.016625405334455133,
      0.016625405334455133,
      0.016094698897543575,
      0.016245886696262528
    ],
    "volume": 666936949
  },
  {
    "ts": 1616284800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11691,
      "authorized": 11691,
      "funded": 352
    },
    "payments": 2,
    "payments_amount": 44972048467,
    "trades": 5,
    "traded_amount": 54878249180,
    "price": [
      0.016245886696262528,
      0.016496302808694806,
      0.0152962,
      0.01601230197485058
    ],
    "volume": 867765690
  },
  {
    "ts": 1616371200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11697,
      "authorized": 11697,
      "funded": 351
    },
    "payments": 6,
    "payments_amount": 489732067884,
    "trades": 6,
    "traded_amount": 244866067884,
    "price": [
      0.01601230197485058,
      0.01601230197485058,
      0.015306098147200398,
      0.015306098147200398
    ],
    "volume": 3791621467
  },
  {
    "ts": 1616457600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11701,
      "authorized": 11701,
      "funded": 353
    },
    "payments": 10,
    "payments_amount": 216288682967,
    "trades": 12,
    "traded_amount": 172822704562,
    "price": [
      0.015306098147200398,
      0.016677398991934923,
      0.015306098147200398,
      0.01641060221785845
    ],
    "volume": 2586526768
  },
  {
    "ts": 1616544000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11714,
      "authorized": 11714,
      "funded": 353
    },
    "payments": 7,
    "payments_amount": 9693196204,
    "trades": 29,
    "traded_amount": 2130093442896,
    "price": [
      0.01641060221785845,
      0.01823789765630957,
      0.01641060221785845,
      0.01823789765630957
    ],
    "volume": 19178177169
  },
  {
    "ts": 1616630400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11783,
      "authorized": 11783,
      "funded": 352
    },
    "payments": 17,
    "payments_amount": 1482075609885,
    "trades": 16,
    "traded_amount": 763304995079,
    "price": [
      0.01823789765630957,
      0.018473506317907444,
      0.017736610503939487,
      0.017894702512596238
    ],
    "volume": 13740432252
  },
  {
    "ts": 1616716800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11888,
      "authorized": 11888,
      "funded": 355
    },
    "payments": 10,
    "payments_amount": 77060547561,
    "trades": 5,
    "traded_amount": 28135247554,
    "price": [
      0.017894702512596238,
      0.017894702512596238,
      0.01681619718309859,
      0.017494384008164048
    ],
    "volume": 488079108
  },
  {
    "ts": 1616803200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 11974,
      "authorized": 11974,
      "funded": 354
    },
    "payments": 1,
    "payments_amount": 49700000000,
    "trades": 3,
    "traded_amount": 59640000000,
    "price": [
      0.017494384008164048,
      0.017494384008164048,
      0.0173,
      0.017327494640808766
    ],
    "volume": 1033138493
  },
  {
    "ts": 1616889600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12083,
      "authorized": 12083,
      "funded": 355
    },
    "payments": 2,
    "payments_amount": 3703872671,
    "trades": 22,
    "traded_amount": 7752125702553,
    "price": [
      0.017327494640808766,
      0.017327494640808766,
      0.016622302304661732,
      0.017142505662817095
    ],
    "volume": 64644373068
  },
  {
    "ts": 1616976000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12163,
      "authorized": 12163,
      "funded": 358
    },
    "payments": 18,
    "payments_amount": 87637942886,
    "trades": 9,
    "traded_amount": 49657242886,
    "price": [
      0.017142505662817095,
      0.017142505662817095,
      0.015879400955734407,
      0.016594895628696663
    ],
    "volume": 794509142
  },
  {
    "ts": 1617062400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12210,
      "authorized": 12210,
      "funded": 360
    },
    "payments": 3,
    "payments_amount": 62608658179,
    "trades": 3,
    "traded_amount": 59016266649,
    "price": [
      0.016594895628696663,
      0.01714450628108037,
      0.016594895628696663,
      0.01714450628108037
    ],
    "volume": 456389874
  },
  {
    "ts": 1617148800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12246,
      "authorized": 12246,
      "funded": 360
    },
    "payments": 9,
    "payments_amount": 79850199205,
    "trades": 11,
    "traded_amount": 85289626445,
    "price": [
      0.01714450628108037,
      0.017749690902378972,
      0.016261803760259947,
      0.016757098922990044
    ],
    "volume": 741535546
  },
  {
    "ts": 1617235200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12274,
      "authorized": 12274,
      "funded": 361
    },
    "payments": 6,
    "payments_amount": 509779777175,
    "trades": 2,
    "traded_amount": 30695777175,
    "price": [
      0.016757098922990044,
      0.016757098922990044,
      0.016117405432595572,
      0.016117405432595572
    ],
    "volume": 16020701
  },
  {
    "ts": 1617321600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12314,
      "authorized": 12314,
      "funded": 362
    },
    "payments": 5,
    "payments_amount": 734314299846,
    "trades": 8,
    "traded_amount": 680198571925,
    "price": [
      0.016117405432595572,
      0.016117405432595572,
      0.014749304507294298,
      0.015295009950541751
    ],
    "volume": 9243010779
  },
  {
    "ts": 1617408000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12339,
      "authorized": 12339,
      "funded": 362
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 6696618939,
    "price": [
      0.015295009950541751,
      0.015295009950541751,
      0.014896099998939785,
      0.014969899998121936
    ],
    "volume": 100000000
  },
  {
    "ts": 1617494400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12383,
      "authorized": 12383,
      "funded": 364
    },
    "payments": 2,
    "payments_amount": 6618276245,
    "trades": 4,
    "traded_amount": 10082091124,
    "price": [
      0.014969899998121936,
      0.016174000021624637,
      0.014969899998121936,
      0.01579969808878312
    ],
    "volume": 160000107
  },
  {
    "ts": 1617580800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12413,
      "authorized": 12413,
      "funded": 366
    },
    "payments": 20,
    "payments_amount": 1476765901900,
    "trades": 18,
    "traded_amount": 314740945279,
    "price": [
      0.01579969808878312,
      0.01579969808878312,
      0.012070700001910068,
      0.012070700001910068
    ],
    "volume": 2851909880
  },
  {
    "ts": 1617667200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12438,
      "authorized": 12438,
      "funded": 367
    },
    "payments": 10,
    "payments_amount": 2838004747669,
    "trades": 29,
    "traded_amount": 1453575906962,
    "price": [
      0.012070700001910068,
      0.013057710925588795,
      0.012070700001910068,
      0.012723300327303287
    ],
    "volume": 18916736505
  },
  {
    "ts": 1617753600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12447,
      "authorized": 12447,
      "funded": 370
    },
    "payments": 33,
    "payments_amount": 850342778473,
    "trades": 111,
    "traded_amount": 2326243316485,
    "price": [
      0.012723300327303287,
      0.13947900048220738,
      0.0009999999460237193,
      0.013695496263740484
    ],
    "volume": 15420343367
  },
  {
    "ts": 1617840000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12471,
      "authorized": 12471,
      "funded": 371
    },
    "payments": 11,
    "payments_amount": 4029539377443,
    "trades": 10,
    "traded_amount": 2229851377443,
    "price": [
      0.013695496263740484,
      0.013730099873112663,
      0.01320915611167002,
      0.013531800000101213
    ],
    "volume": 30230900086
  },
  {
    "ts": 1617926400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12486,
      "authorized": 12486,
      "funded": 370
    },
    "payments": 9,
    "payments_amount": 124262523420,
    "trades": 6,
    "traded_amount": 38014315096,
    "price": [
      0.013531800000101213,
      0.013532206587503008,
      0.013151294413483498,
      0.013532206587503008
    ],
    "volume": 473111439
  },
  {
    "ts": 1618012800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12503,
      "authorized": 12503,
      "funded": 370
    },
    "payments": 6,
    "payments_amount": 624622759551,
    "trades": 80,
    "traded_amount": 6359476830197,
    "price": [
      0.013532206587503008,
      0.013532206587503008,
      0.011442303990909746,
      0.011625608773206652
    ],
    "volume": 41742178750
  },
  {
    "ts": 1618099200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12515,
      "authorized": 12515,
      "funded": 370
    },
    "payments": 3,
    "payments_amount": 4296260653,
    "trades": 265,
    "traded_amount": 15686823746289,
    "price": [
      0.011625608773206652,
      0.011625608773206652,
      0.010205396544898633,
      0.010870692819915479
    ],
    "volume": 83163728240
  },
  {
    "ts": 1618185600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12537,
      "authorized": 12537,
      "funded": 371
    },
    "payments": 9,
    "payments_amount": 123336552004,
    "trades": 109,
    "traded_amount": 8291174585158,
    "price": [
      0.010870692819915479,
      0.011617500238248608,
      0.010870692819915479,
      0.011434995665725539
    ],
    "volume": 47047200225
  },
  {
    "ts": 1618272000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12568,
      "authorized": 12568,
      "funded": 372
    },
    "payments": 4,
    "payments_amount": 11928000000,
    "trades": 124,
    "traded_amount": 7921257347456,
    "price": [
      0.011434995665725539,
      0.011434995665725539,
      0.009661254527162978,
      0.009850201207243461
    ],
    "volume": 44307512888
  },
  {
    "ts": 1618358400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12591,
      "authorized": 12591,
      "funded": 372
    },
    "payments": 6,
    "payments_amount": 488197221986,
    "trades": 8,
    "traded_amount": 498882371192,
    "price": [
      0.009850201207243461,
      0.011073499461273954,
      0.009615973596837189,
      0.011073499461273954
    ],
    "volume": 5486390293
  },
  {
    "ts": 1618444800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12634,
      "authorized": 12634,
      "funded": 373
    },
    "payments": 9,
    "payments_amount": 135791277673,
    "trades": 5,
    "traded_amount": 68013277673,
    "price": [
      0.011073499461273954,
      0.011073499461273954,
      0.011073499461273954,
      0.011073499461273954
    ],
    "volume": 0
  },
  {
    "ts": 1618531200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12720,
      "authorized": 12720,
      "funded": 374
    },
    "payments": 26,
    "payments_amount": 493590466911,
    "trades": 120,
    "traded_amount": 8959274205183,
    "price": [
      0.011073499461273954,
      0.011317797790913355,
      0.01043300069929106,
      0.01043300069929106
    ],
    "volume": 44191637440
  },
  {
    "ts": 1618617600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12840,
      "authorized": 12840,
      "funded": 374
    },
    "payments": 11,
    "payments_amount": 136173392195,
    "trades": 19,
    "traded_amount": 125789120595,
    "price": [
      0.01043300069929106,
      0.01066649846600717,
      0.01001680829092402,
      0.01066649846600717
    ],
    "volume": 930208506
  },
  {
    "ts": 1618704000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 12979,
      "authorized": 12979,
      "funded": 377
    },
    "payments": 2,
    "payments_amount": 125970659375,
    "trades": 121,
    "traded_amount": 11623536163634,
    "price": [
      0.01066649846600717,
      0.013023892544940723,
      0.01066649846600717,
      0.012003893985160621
    ],
    "volume": 71828813073
  },
  {
    "ts": 1618790400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13130,
      "authorized": 13130,
      "funded": 378
    },
    "payments": 35,
    "payments_amount": 949296848305,
    "trades": 39,
    "traded_amount": 7231165669595,
    "price": [
      0.012003893985160621,
      0.012748907236522583,
      0.011348891484176544,
      0.012269797786720322
    ],
    "volume": 43107168282
  },
  {
    "ts": 1618876800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13281,
      "authorized": 13281,
      "funded": 385
    },
    "payments": 24,
    "payments_amount": 217016057665,
    "trades": 39,
    "traded_amount": 1159287451269,
    "price": [
      0.012269797786720322,
      0.014606498765867658,
      0.012269797786720322,
      0.01291929689472671
    ],
    "volume": 3623687424
  },
  {
    "ts": 1618963200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13406,
      "authorized": 13406,
      "funded": 394
    },
    "payments": 29,
    "payments_amount": 857612871090,
    "trades": 36,
    "traded_amount": 3858758524039,
    "price": [
      0.01291929689472671,
      0.013386898034044359,
      0.01222220262902304,
      0.012760200617710955
    ],
    "volume": 22445340484
  },
  {
    "ts": 1619049600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13525,
      "authorized": 13525,
      "funded": 397
    },
    "payments": 31,
    "payments_amount": 1832132134071,
    "trades": 307,
    "traded_amount": 5942899496078,
    "price": [
      0.012760200617710955,
      0.013416602004613216,
      0.011977198120561998,
      0.01326889587525151
    ],
    "volume": 36828395169
  },
  {
    "ts": 1619136000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13640,
      "authorized": 13640,
      "funded": 403
    },
    "payments": 36,
    "payments_amount": 835374820440,
    "trades": 38,
    "traded_amount": 2736676797186,
    "price": [
      0.01326889587525151,
      0.015445402708919172,
      0.013166669095088275,
      0.013851408544451459
    ],
    "volume": 17121661658
  },
  {
    "ts": 1619222400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13754,
      "authorized": 13754,
      "funded": 405
    },
    "payments": 6,
    "payments_amount": 21161392045,
    "trades": 5,
    "traded_amount": 15061392045,
    "price": [
      0.013851408544451459,
      0.015222111313179071,
      0.013851408544451459,
      0.015222111313179071
    ],
    "volume": 121539531
  },
  {
    "ts": 1619308800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 13867,
      "authorized": 13867,
      "funded": 407
    },
    "payments": 13,
    "payments_amount": 287986292836,
    "trades": 19,
    "traded_amount": 604556048288,
    "price": [
      0.015222111313179071,
      0.015520604732666459,
      0.014756496701114731,
      0.014756497051725171
    ],
    "volume": 2698686000
  },
  {
    "ts": 1619395200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14010,
      "authorized": 14010,
      "funded": 420
    },
    "payments": 45,
    "payments_amount": 1203759606253,
    "trades": 27,
    "traded_amount": 724802759785,
    "price": [
      0.014756497051725171,
      0.014756497051725171,
      0.01274090239520958,
      0.013097095174317657
    ],
    "volume": 901512295
  },
  {
    "ts": 1619481600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14153,
      "authorized": 14153,
      "funded": 416
    },
    "payments": 17,
    "payments_amount": 136348986015,
    "trades": 15,
    "traded_amount": 311010214113,
    "price": [
      0.013097095174317657,
      0.013301397898697137,
      0.012176700201207244,
      0.01268619650011795
    ],
    "volume": 3353024873
  },
  {
    "ts": 1619568000,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14311,
      "authorized": 14311,
      "funded": 425
    },
    "payments": 30,
    "payments_amount": 1203949906490,
    "trades": 30,
    "traded_amount": 2026064454390,
    "price": [
      0.01268619650011795,
      0.013140098628917702,
      0.012362498993963782,
      0.012844701095627745
    ],
    "volume": 11866056195
  },
  {
    "ts": 1619654400,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14436,
      "authorized": 14436,
      "funded": 433
    },
    "payments": 32,
    "payments_amount": 348414718240,
    "trades": 18,
    "traded_amount": 181931718240,
    "price": [
      0.012844701095627745,
      0.012985595233153104,
      0.01242921068643008,
      0.012941811683590845
    ],
    "volume": 1487654936
  },
  {
    "ts": 1619740800,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14577,
      "authorized": 14577,
      "funded": 435
    },
    "payments": 34,
    "payments_amount": 7450692104601,
    "trades": 34,
    "traded_amount": 2306146487170,
    "price": [
      0.012941811683590845,
      0.012941811683590845,
      0.011570599235427284,
      0.012074900000083601
    ],
    "volume": 20791000054
  },
  {
    "ts": 1619827200,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14735,
      "authorized": 14735,
      "funded": 432
    },
    "payments": 3,
    "payments_amount": 11725588865,
    "trades": 4,
    "traded_amount": 12675463816,
    "price": [
      0.012074900000083601,
      0.012074900000083601,
      0.011613793992973713,
      0.011763796523262381
    ],
    "volume": 44380827
  },
  {
    "ts": 1619913600,
    "supply": 29550455802306,
    "trustlines": {
      "total": 14887,
      "authorized": 14887,
      "funded": 433
    },
    "payments": 12,
    "payments_amount": 1722922427310,
    "trades": 12,
    "traded_amount": 315997408258,
    "price": [
      0.011763796523262381,
      0.012267300018210664,
      0.011496596584238988,
      0.011496596584238988
    ],
    "volume": 3635561656
  },
  {
    "ts": 1620000000,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15051,
      "authorized": 15051,
      "funded": 444
    },
    "payments": 57,
    "payments_amount": 34972708198485,
    "trades": 38,
    "traded_amount": 2543053126626,
    "price": [
      0.011496596584238988,
      0.011496596584238988,
      0.01095500472837022,
      0.011002801925927863
    ],
    "volume": 14827589103
  },
  {
    "ts": 1620086400,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15165,
      "authorized": 15165,
      "funded": 458
    },
    "payments": 50,
    "payments_amount": 918050971623,
    "trades": 35,
    "traded_amount": 442723171623,
    "price": [
      0.011002801925927863,
      0.012412799935612256,
      0.011002801925927863,
      0.011860100100603621
    ],
    "volume": 1646716972
  },
  {
    "ts": 1620172800,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15291,
      "authorized": 15291,
      "funded": 459
    },
    "payments": 39,
    "payments_amount": 1351518251545,
    "trades": 36,
    "traded_amount": 888484287765,
    "price": [
      0.011860100100603621,
      0.0118695,
      0.01035068,
      0.01035068
    ],
    "volume": 4747566271
  },
  {
    "ts": 1620259200,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15414,
      "authorized": 15414,
      "funded": 464
    },
    "payments": 35,
    "payments_amount": 1351687900896,
    "trades": 31,
    "traded_amount": 12710662794471,
    "price": [
      0.01035068,
      0.01035068,
      0.009397392152920061,
      0.009420303616182426
    ],
    "volume": 61550236740
  },
  {
    "ts": 1620345600,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15530,
      "authorized": 15530,
      "funded": 473
    },
    "payments": 28,
    "payments_amount": 1728001383928,
    "trades": 33,
    "traded_amount": 1649001415157,
    "price": [
      0.009420303616182426,
      0.010096400831131445,
      0.009135697148655406,
      0.010049595589823988
    ],
    "volume": 14110116223
  },
  {
    "ts": 1620432000,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15634,
      "authorized": 15634,
      "funded": 474
    },
    "payments": 20,
    "payments_amount": 304272103126,
    "trades": 13,
    "traded_amount": 98400303126,
    "price": [
      0.010049595589823988,
      0.010111201717817007,
      0.00998170157699398,
      0.010111201717817007
    ],
    "volume": 302085311
  },
  {
    "ts": 1620518400,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15744,
      "authorized": 15744,
      "funded": 475
    },
    "payments": 12,
    "payments_amount": 914297059664,
    "trades": 30,
    "traded_amount": 1452097119212,
    "price": [
      0.010111201717817007,
      0.010609699251035025,
      0.009794504598892997,
      0.0101020084004684
    ],
    "volume": 13188507487
  },
  {
    "ts": 1620604800,
    "supply": 44550455802306,
    "trustlines": {
      "total": 15901,
      "authorized": 15901,
      "funded": 484
    },
    "payments": 32,
    "payments_amount": 805996769940,
    "trades": 37,
    "traded_amount": 6643123249883,
    "price": [
      0.0101020084004684,
      0.0101020084004684,
      0.00808920075530383,
      0.009060786180951136
    ],
    "volume": 26740509967
  },
  {
    "ts": 1620691200,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16048,
      "authorized": 16048,
      "funded": 488
    },
    "payments": 27,
    "payments_amount": 350737967937,
    "trades": 24,
    "traded_amount": 482634358952,
    "price": [
      0.009060786180951136,
      0.00983069030412384,
      0.008743399690278656,
      0.008743399690278656
    ],
    "volume": 3640874391
  },
  {
    "ts": 1620777600,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16193,
      "authorized": 16193,
      "funded": 491
    },
    "payments": 44,
    "payments_amount": 1518221574607,
    "trades": 42,
    "traded_amount": 4330547422227,
    "price": [
      0.008743399690278656,
      0.010162097775923454,
      0.008743399690278656,
      0.010162097775923454
    ],
    "volume": 17936476697
  },
  {
    "ts": 1620864000,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16335,
      "authorized": 16335,
      "funded": 501
    },
    "payments": 71,
    "payments_amount": 731520155965,
    "trades": 44,
    "traded_amount": 473499000116,
    "price": [
      0.010162097775923454,
      0.010820098045532491,
      0.009733694783599338,
      0.009733694783599338
    ],
    "volume": 2742429245
  },
  {
    "ts": 1620950400,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16454,
      "authorized": 16454,
      "funded": 505
    },
    "payments": 45,
    "payments_amount": 2194924362060,
    "trades": 37,
    "traded_amount": 1117661124081,
    "price": [
      0.009733694783599338,
      0.009733694783599338,
      0.008977200639099348,
      0.009445597910022174
    ],
    "volume": 9642767602
  },
  {
    "ts": 1621036800,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16540,
      "authorized": 16540,
      "funded": 505
    },
    "payments": 6,
    "payments_amount": 155095773818,
    "trades": 5,
    "traded_amount": 138172073818,
    "price": [
      0.009445597910022174,
      0.009445597910022174,
      0.008755297061918918,
      0.008755297061918918
    ],
    "volume": 1203422171
  },
  {
    "ts": 1621123200,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16630,
      "authorized": 16630,
      "funded": 509
    },
    "payments": 18,
    "payments_amount": 458445328141,
    "trades": 13,
    "traded_amount": 378402444274,
    "price": [
      0.008755297061918918,
      0.009142896280890713,
      0.008246999429689199,
      0.008952003486248257
    ],
    "volume": 3032254223
  },
  {
    "ts": 1621209600,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16724,
      "authorized": 16724,
      "funded": 513
    },
    "payments": 67,
    "payments_amount": 6249820840161,
    "trades": 49,
    "traded_amount": 2286981833110,
    "price": [
      0.008952003486248257,
      0.009607802053218482,
      0.00891529846066468,
      0.009578197596041112
    ],
    "volume": 20274869227
  },
  {
    "ts": 1621296000,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16847,
      "authorized": 16847,
      "funded": 525
    },
    "payments": 69,
    "payments_amount": 482233715583,
    "trades": 51,
    "traded_amount": 202703775946,
    "price": [
      0.009578197596041112,
      0.009643099915005351,
      0.008987598766750406,
      0.009563503718273548
    ],
    "volume": 454475143
  },
  {
    "ts": 1621382400,
    "supply": 44550455802306,
    "trustlines": {
      "total": 16935,
      "authorized": 16935,
      "funded": 526
    },
    "payments": 33,
    "payments_amount": 890888591494,
    "trades": 113,
    "traded_amount": 4659036741948,
    "price": [
      0.009563503718273548,
      0.015833509735854078,
      0.009563503718273548,
      0.012781598553610527
    ],
    "volume": 36011559461
  },
  {
    "ts": 1621468800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17022,
      "authorized": 17022,
      "funded": 532
    },
    "payments": 49,
    "payments_amount": 406974835933,
    "trades": 31,
    "traded_amount": 749758030589,
    "price": [
      0.012781598553610527,
      0.013244601546533237,
      0.011368594117647058,
      0.012284801326175973
    ],
    "volume": 8135460130
  },
  {
    "ts": 1621555200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17126,
      "authorized": 17126,
      "funded": 534
    },
    "payments": 22,
    "payments_amount": 191695676193,
    "trades": 14,
    "traded_amount": 64044691483,
    "price": [
      0.012284801326175973,
      0.014424494,
      0.012284801326175973,
      0.014424494
    ],
    "volume": 76569671
  },
  {
    "ts": 1621641600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17226,
      "authorized": 17226,
      "funded": 536
    },
    "payments": 15,
    "payments_amount": 134405163836,
    "trades": 13,
    "traded_amount": 105505163836,
    "price": [
      0.014424494,
      0.01537958917726768,
      0.014424494,
      0.014782801052128779
    ],
    "volume": 291542366
  },
  {
    "ts": 1621728000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17337,
      "authorized": 17337,
      "funded": 540
    },
    "payments": 27,
    "payments_amount": 770096210629,
    "trades": 35,
    "traded_amount": 1760663678410,
    "price": [
      0.014782801052128779,
      0.020811694366197182,
      0.014782801052128779,
      0.01711199952170148
    ],
    "volume": 21238572717
  },
  {
    "ts": 1621814400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17426,
      "authorized": 17426,
      "funded": 541
    },
    "payments": 22,
    "payments_amount": 447018719993,
    "trades": 23,
    "traded_amount": 866827422744,
    "price": [
      0.01711199952170148,
      0.01711199952170148,
      0.01365730171940421,
      0.01365730171940421
    ],
    "volume": 8981964086
  },
  {
    "ts": 1621900800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17530,
      "authorized": 17530,
      "funded": 545
    },
    "payments": 23,
    "payments_amount": 282442042839,
    "trades": 24,
    "traded_amount": 654188502069,
    "price": [
      0.01365730171940421,
      0.014324197645605143,
      0.013555492951770607,
      0.014324197645605143
    ],
    "volume": 4717583315
  },
  {
    "ts": 1621987200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17659,
      "authorized": 17659,
      "funded": 551
    },
    "payments": 36,
    "payments_amount": 371304125193,
    "trades": 20,
    "traded_amount": 228193905150,
    "price": [
      0.014324197645605143,
      0.014324197645605143,
      0.013002997782365156,
      0.013694993264332968
    ],
    "volume": 1375512617
  },
  {
    "ts": 1622073600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17774,
      "authorized": 17774,
      "funded": 559
    },
    "payments": 45,
    "payments_amount": 685743102523,
    "trades": 27,
    "traded_amount": 496822702523,
    "price": [
      0.013694993264332968,
      0.01425220390341684,
      0.013140795428571429,
      0.014106607884694934
    ],
    "volume": 1639986807
  },
  {
    "ts": 1622160000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17854,
      "authorized": 17854,
      "funded": 563
    },
    "payments": 40,
    "payments_amount": 1585229992747,
    "trades": 43,
    "traded_amount": 5572937137566,
    "price": [
      0.014106607884694934,
      0.015671298744155324,
      0.014106607884694934,
      0.015539000000133606
    ],
    "volume": 53244770387
  },
  {
    "ts": 1622246400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 17927,
      "authorized": 17927,
      "funded": 562
    },
    "payments": 17,
    "payments_amount": 427807956282,
    "trades": 13,
    "traded_amount": 32953956282,
    "price": [
      0.015539000000133606,
      0.01679219291369893,
      0.014609998987057593,
      0.01679219291369893
    ],
    "volume": 127731220
  },
  {
    "ts": 1622332800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18010,
      "authorized": 18010,
      "funded": 561
    },
    "payments": 10,
    "payments_amount": 196488704748,
    "trades": 11,
    "traded_amount": 99890858946,
    "price": [
      0.01679219291369893,
      0.01679219291369893,
      0.01572699615577386,
      0.01572699615577386
    ],
    "volume": 965795
  },
  {
    "ts": 1622419200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18084,
      "authorized": 18084,
      "funded": 573
    },
    "payments": 62,
    "payments_amount": 1220432162331,
    "trades": 58,
    "traded_amount": 16620132466001,
    "price": [
      0.01572699615577386,
      0.01614900364556034,
      0.014730600127376519,
      0.014962304
    ],
    "volume": 127144099796
  },
  {
    "ts": 1622505600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18155,
      "authorized": 18155,
      "funded": 577
    },
    "payments": 20,
    "payments_amount": 67827528290,
    "trades": 16,
    "traded_amount": 44032862818,
    "price": [
      0.014962304,
      0.014962304,
      0.013643604933333333,
      0.013734000718665125
    ],
    "volume": 187858163
  },
  {
    "ts": 1622592000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18228,
      "authorized": 18228,
      "funded": 581
    },
    "payments": 31,
    "payments_amount": 7321062955645,
    "trades": 39,
    "traded_amount": 3576453943773,
    "price": [
      0.013734000718665125,
      0.01431670708672499,
      0.013607994184923061,
      0.013724899971556518
    ],
    "volume": 28218053886
  },
  {
    "ts": 1622678400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18314,
      "authorized": 18314,
      "funded": 586
    },
    "payments": 34,
    "payments_amount": 886896361790,
    "trades": 25,
    "traded_amount": 785094386742,
    "price": [
      0.013724899971556518,
      0.01414680777789677,
      0.013527713169386722,
      0.014024602702729795
    ],
    "volume": 9565327062
  },
  {
    "ts": 1622764800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18382,
      "authorized": 18382,
      "funded": 586
    },
    "payments": 31,
    "payments_amount": 869594661047,
    "trades": 28,
    "traded_amount": 400504473229,
    "price": [
      0.014024602702729795,
      0.01550109429545207,
      0.014024602702729795,
      0.015392592074055394
    ],
    "volume": 4964712572
  },
  {
    "ts": 1622851200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18468,
      "authorized": 18468,
      "funded": 588
    },
    "payments": 16,
    "payments_amount": 1117304350081,
    "trades": 26,
    "traded_amount": 572953512526,
    "price": [
      0.015392592074055394,
      0.015758900030501097,
      0.015262285533976537,
      0.015529196778376745
    ],
    "volume": 7815818390
  },
  {
    "ts": 1622937600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18549,
      "authorized": 18549,
      "funded": 593
    },
    "payments": 20,
    "payments_amount": 2001509842637,
    "trades": 26,
    "traded_amount": 1025902320151,
    "price": [
      0.015529196778376745,
      0.01580330083977337,
      0.015188605265312278,
      0.015760597971276893
    ],
    "volume": 15074319191
  },
  {
    "ts": 1623024000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18628,
      "authorized": 18628,
      "funded": 595
    },
    "payments": 26,
    "payments_amount": 4771052528671,
    "trades": 30,
    "traded_amount": 2300632448893,
    "price": [
      0.015760597971276893,
      0.016981500638001096,
      0.014986580972199052,
      0.016981500638001096
    ],
    "volume": 30874201537
  },
  {
    "ts": 1623110400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18702,
      "authorized": 18702,
      "funded": 604
    },
    "payments": 19,
    "payments_amount": 2371622937042,
    "trades": 19,
    "traded_amount": 2216164340294,
    "price": [
      0.016981500638001096,
      0.017822394899415624,
      0.016937396387688423,
      0.016937396387688423
    ],
    "volume": 38912729233
  },
  {
    "ts": 1623196800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18762,
      "authorized": 18762,
      "funded": 611
    },
    "payments": 24,
    "payments_amount": 1165918752488,
    "trades": 26,
    "traded_amount": 1278483582602,
    "price": [
      0.016937396387688423,
      0.017643591019407993,
      0.016530801733979073,
      0.016530801733979073
    ],
    "volume": 20774483457
  },
  {
    "ts": 1623283200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18849,
      "authorized": 18849,
      "funded": 612
    },
    "payments": 16,
    "payments_amount": 765725032422,
    "trades": 16,
    "traded_amount": 74007087724,
    "price": [
      0.016530801733979073,
      0.01798842677099525,
      0.016530801733979073,
      0.0176162045636487
    ],
    "volume": 862434766
  },
  {
    "ts": 1623369600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18925,
      "authorized": 18925,
      "funded": 613
    },
    "payments": 18,
    "payments_amount": 36553400841,
    "trades": 15,
    "traded_amount": 19531658193,
    "price": [
      0.0176162045636487,
      0.018373204750816583,
      0.0176162045636487,
      0.01790009189012172
    ],
    "volume": 38042885
  },
  {
    "ts": 1623456000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 18984,
      "authorized": 18984,
      "funded": 618
    },
    "payments": 23,
    "payments_amount": 322854130554,
    "trades": 35,
    "traded_amount": 711935726643,
    "price": [
      0.01790009189012172,
      0.019868491014941668,
      0.017886011736790247,
      0.018363796494991008
    ],
    "volume": 10512855722
  },
  {
    "ts": 1623542400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19041,
      "authorized": 19041,
      "funded": 623
    },
    "payments": 18,
    "payments_amount": 1347722739236,
    "trades": 12,
    "traded_amount": 452723439236,
    "price": [
      0.018363796494991008,
      0.018563603711990784,
      0.01808910652624125,
      0.01814500038454442
    ],
    "volume": 7629343244
  },
  {
    "ts": 1623628800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19120,
      "authorized": 19120,
      "funded": 626
    },
    "payments": 24,
    "payments_amount": 5545995149135,
    "trades": 20,
    "traded_amount": 1149741157858,
    "price": [
      0.01814500038454442,
      0.01814500038454442,
      0.01700163944380351,
      0.0172794049
    ],
    "volume": 18586330523
  },
  {
    "ts": 1623715200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19217,
      "authorized": 19217,
      "funded": 633
    },
    "payments": 23,
    "payments_amount": 1283197188754,
    "trades": 13,
    "traded_amount": 147022320956,
    "price": [
      0.0172794049,
      0.018619288903755544,
      0.0172794049,
      0.018388906123523806
    ],
    "volume": 297590457
  },
  {
    "ts": 1623801600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19306,
      "authorized": 19306,
      "funded": 640
    },
    "payments": 18,
    "payments_amount": 703424202018,
    "trades": 16,
    "traded_amount": 64480074344,
    "price": [
      0.018388906123523806,
      0.019535407295102176,
      0.018388906123523806,
      0.019535407295102176
    ],
    "volume": 199090564
  },
  {
    "ts": 1623888000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19384,
      "authorized": 19384,
      "funded": 643
    },
    "payments": 22,
    "payments_amount": 642730926262,
    "trades": 18,
    "traded_amount": 706842887025,
    "price": [
      0.019535407295102176,
      0.019535407295102176,
      0.01878989989939638,
      0.018980996666439893
    ],
    "volume": 9582954488
  },
  {
    "ts": 1623974400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19483,
      "authorized": 19483,
      "funded": 647
    },
    "payments": 28,
    "payments_amount": 680610040023,
    "trades": 24,
    "traded_amount": 341813192101,
    "price": [
      0.018980996666439893,
      0.021507002168654275,
      0.018980996666439893,
      0.021507002168654275
    ],
    "volume": 853357511
  },
  {
    "ts": 1624060800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19568,
      "authorized": 19568,
      "funded": 650
    },
    "payments": 10,
    "payments_amount": 321643096044,
    "trades": 8,
    "traded_amount": 7082821232,
    "price": [
      0.021507002168654275,
      0.021507002168654275,
      0.019918285,
      0.02130329928733052
    ],
    "volume": 95969364
  },
  {
    "ts": 1624147200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19663,
      "authorized": 19663,
      "funded": 648
    },
    "payments": 12,
    "payments_amount": 391220529910,
    "trades": 12,
    "traded_amount": 204639054542,
    "price": [
      0.02130329928733052,
      0.02222750205438135,
      0.02019159580564814,
      0.02019159580564814
    ],
    "volume": 427820219
  },
  {
    "ts": 1624233600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19764,
      "authorized": 19764,
      "funded": 655
    },
    "payments": 30,
    "payments_amount": 665425777108,
    "trades": 21,
    "traded_amount": 475069557275,
    "price": [
      0.02019159580564814,
      0.024041905283700125,
      0.020146986861059486,
      0.024041905283700125
    ],
    "volume": 7332349590
  },
  {
    "ts": 1624320000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 19907,
      "authorized": 19907,
      "funded": 656
    },
    "payments": 19,
    "payments_amount": 588277183010,
    "trades": 21,
    "traded_amount": 409625863701,
    "price": [
      0.024041905283700125,
      0.03,
      0.024041905283700125,
      0.025516003925539064
    ],
    "volume": 669880728
  },
  {
    "ts": 1624406400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20018,
      "authorized": 20018,
      "funded": 662
    },
    "payments": 38,
    "payments_amount": 2348331008761,
    "trades": 34,
    "traded_amount": 1206196299119,
    "price": [
      0.025516003925539064,
      0.025516003925539064,
      0.0231119826,
      0.024203110102840836
    ],
    "volume": 3278828145
  },
  {
    "ts": 1624492800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20127,
      "authorized": 20127,
      "funded": 665
    },
    "payments": 29,
    "payments_amount": 142435529186,
    "trades": 20,
    "traded_amount": 413124850448,
    "price": [
      0.024203110102840836,
      0.024203110102840836,
      0.0228566032,
      0.0228659962
    ],
    "volume": 411169087
  },
  {
    "ts": 1624579200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20212,
      "authorized": 20212,
      "funded": 667
    },
    "payments": 44,
    "payments_amount": 2304207877859,
    "trades": 36,
    "traded_amount": 2202460534855,
    "price": [
      0.0228659962,
      0.025467898120452238,
      0.0228659962,
      0.025467898120452238
    ],
    "volume": 28781305771
  },
  {
    "ts": 1624665600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20306,
      "authorized": 20306,
      "funded": 667
    },
    "payments": 15,
    "payments_amount": 2310855021975,
    "trades": 21,
    "traded_amount": 4642265854428,
    "price": [
      0.025467898120452238,
      0.025770491708608626,
      0.018788291354663037,
      0.025380090275910292
    ],
    "volume": 68754526746
  },
  {
    "ts": 1624752000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20416,
      "authorized": 20416,
      "funded": 666
    },
    "payments": 11,
    "payments_amount": 162081010989,
    "trades": 11,
    "traded_amount": 85641278119,
    "price": [
      0.025380090275910292,
      0.025380090275910292,
      0.024293792377192792,
      0.024306690313016927
    ],
    "volume": 173731453
  },
  {
    "ts": 1624838400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20547,
      "authorized": 20547,
      "funded": 669
    },
    "payments": 24,
    "payments_amount": 473856613303,
    "trades": 16,
    "traded_amount": 43041013303,
    "price": [
      0.024306690313016927,
      0.024306690313016927,
      0.022890000450888043,
      0.023568792443743703
    ],
    "volume": 543761354
  },
  {
    "ts": 1624924800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20657,
      "authorized": 20657,
      "funded": 675
    },
    "payments": 37,
    "payments_amount": 3866866643842,
    "trades": 44,
    "traded_amount": 4822075483539,
    "price": [
      0.023568792443743703,
      0.023568792443743703,
      0.020420090178571427,
      0.020420090178571427
    ],
    "volume": 61113443469
  },
  {
    "ts": 1625011200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20780,
      "authorized": 20780,
      "funded": 677
    },
    "payments": 46,
    "payments_amount": 6245591913539,
    "trades": 34,
    "traded_amount": 3068998119462,
    "price": [
      0.020420090178571427,
      0.022710612781356686,
      0.020420090178571427,
      0.02208879719365075
    ],
    "volume": 43072639145
  },
  {
    "ts": 1625097600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20870,
      "authorized": 20870,
      "funded": 679
    },
    "payments": 28,
    "payments_amount": 1191651414322,
    "trades": 13,
    "traded_amount": 167593414322,
    "price": [
      0.02208879719365075,
      0.0227389,
      0.02208879719365075,
      0.0227389
    ],
    "volume": 76175315
  },
  {
    "ts": 1625184000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 20971,
      "authorized": 20971,
      "funded": 686
    },
    "payments": 51,
    "payments_amount": 3111472261847,
    "trades": 34,
    "traded_amount": 1399109390383,
    "price": [
      0.0227389,
      0.02378998586182001,
      0.022196300239644796,
      0.0226532
    ],
    "volume": 9718663743
  },
  {
    "ts": 1625270400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21085,
      "authorized": 21085,
      "funded": 689
    },
    "payments": 15,
    "payments_amount": 114692132115,
    "trades": 9,
    "traded_amount": 73185532115,
    "price": [
      0.0226532,
      0.02272910000003819,
      0.02182651357525424,
      0.02182651357525424
    ],
    "volume": 1300643151
  },
  {
    "ts": 1625356800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21214,
      "authorized": 21214,
      "funded": 693
    },
    "payments": 31,
    "payments_amount": 1331858133436,
    "trades": 23,
    "traded_amount": 662508233436,
    "price": [
      0.02182651357525424,
      0.022759201079788773,
      0.021522304363636363,
      0.02247690855452694
    ],
    "volume": 12818390582
  },
  {
    "ts": 1625443200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21332,
      "authorized": 21332,
      "funded": 698
    },
    "payments": 45,
    "payments_amount": 7230279520853,
    "trades": 43,
    "traded_amount": 2951229696586,
    "price": [
      0.02247690855452694,
      0.023861809664962232,
      0.02247690855452694,
      0.023632705585517663
    ],
    "volume": 28474309082
  },
  {
    "ts": 1625529600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21443,
      "authorized": 21443,
      "funded": 709
    },
    "payments": 51,
    "payments_amount": 629704322986,
    "trades": 28,
    "traded_amount": 452337017138,
    "price": [
      0.023632705585517663,
      0.023632705585517663,
      0.023048308205986696,
      0.023286700000382063
    ],
    "volume": 5966202611
  },
  {
    "ts": 1625616000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21586,
      "authorized": 21586,
      "funded": 718
    },
    "payments": 35,
    "payments_amount": 2323287835432,
    "trades": 18,
    "traded_amount": 762891635432,
    "price": [
      0.023286700000382063,
      0.023286700000382063,
      0.02212289877899268,
      0.0221229
    ],
    "volume": 752387890
  },
  {
    "ts": 1625702400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21723,
      "authorized": 21723,
      "funded": 716
    },
    "payments": 25,
    "payments_amount": 661756710517,
    "trades": 16,
    "traded_amount": 557523110517,
    "price": [
      0.0221229,
      0.024756108829682082,
      0.0221229,
      0.024482200004984907
    ],
    "volume": 12361097208
  },
  {
    "ts": 1625788800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 21876,
      "authorized": 21876,
      "funded": 718
    },
    "payments": 22,
    "payments_amount": 7409707612955,
    "trades": 12,
    "traded_amount": 325923712955,
    "price": [
      0.024482200004984907,
      0.02471831,
      0.02404598375169769,
      0.02404598375169769
    ],
    "volume": 553149437
  },
  {
    "ts": 1625875200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22046,
      "authorized": 22046,
      "funded": 722
    },
    "payments": 30,
    "payments_amount": 335969837740,
    "trades": 28,
    "traded_amount": 216555960455,
    "price": [
      0.02404598375169769,
      0.024487000002701733,
      0.023243201884891554,
      0.023243299999507958
    ],
    "volume": 2186360159
  },
  {
    "ts": 1625961600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22233,
      "authorized": 22233,
      "funded": 732
    },
    "payments": 24,
    "payments_amount": 256325293232,
    "trades": 20,
    "traded_amount": 229275965302,
    "price": [
      0.023243299999507958,
      0.02457909915531544,
      0.023243299999507958,
      0.02400359988641296
    ],
    "volume": 4673731212
  },
  {
    "ts": 1626048000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22416,
      "authorized": 22416,
      "funded": 733
    },
    "payments": 70,
    "payments_amount": 1134414102945,
    "trades": 40,
    "traded_amount": 460973002945,
    "price": [
      0.02400359988641296,
      0.02478099889577238,
      0.023084810511036974,
      0.02478099889577238
    ],
    "volume": 2745779173
  },
  {
    "ts": 1626134400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22605,
      "authorized": 22605,
      "funded": 742
    },
    "payments": 56,
    "payments_amount": 360025310487,
    "trades": 31,
    "traded_amount": 174731894931,
    "price": [
      0.02478099889577238,
      0.025768900000019548,
      0.024665599999832782,
      0.025491200026902396
    ],
    "volume": 1139169677
  },
  {
    "ts": 1626220800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22802,
      "authorized": 22802,
      "funded": 740
    },
    "payments": 75,
    "payments_amount": 740990131377,
    "trades": 38,
    "traded_amount": 432491631377,
    "price": [
      0.025491200026902396,
      0.025792100003771773,
      0.02357540018942602,
      0.023917400071654885
    ],
    "volume": 1319341216
  },
  {
    "ts": 1626307200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 22978,
      "authorized": 22978,
      "funded": 748
    },
    "payments": 60,
    "payments_amount": 680406166799,
    "trades": 36,
    "traded_amount": 309515878877,
    "price": [
      0.023917400071654885,
      0.025712100001413853,
      0.023917400071654885,
      0.024692589034950092
    ],
    "volume": 2407919819
  },
  {
    "ts": 1626393600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23149,
      "authorized": 23149,
      "funded": 765
    },
    "payments": 83,
    "payments_amount": 2148438234976,
    "trades": 53,
    "traded_amount": 1095945934976,
    "price": [
      0.024692589034950092,
      0.024838000033890913,
      0.022866596613756063,
      0.0246430000029079
    ],
    "volume": 8853200669
  },
  {
    "ts": 1626480000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23309,
      "authorized": 23309,
      "funded": 775
    },
    "payments": 49,
    "payments_amount": 3591700002063,
    "trades": 45,
    "traded_amount": 2199271002024,
    "price": [
      0.0246430000029079,
      0.02558570270590158,
      0.024491804699311014,
      0.024491804699311014
    ],
    "volume": 28924920635
  },
  {
    "ts": 1626566400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23480,
      "authorized": 23480,
      "funded": 777
    },
    "payments": 34,
    "payments_amount": 592988597143,
    "trades": 24,
    "traded_amount": 304495827336,
    "price": [
      0.024491804699311014,
      0.02590889718266061,
      0.024491804699311014,
      0.024899
    ],
    "volume": 6201963573
  },
  {
    "ts": 1626652800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23645,
      "authorized": 23645,
      "funded": 782
    },
    "payments": 53,
    "payments_amount": 775871903778,
    "trades": 32,
    "traded_amount": 341741208272,
    "price": [
      0.024899,
      0.02735230000055337,
      0.024899,
      0.02735230000055337
    ],
    "volume": 170539065
  },
  {
    "ts": 1626739200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23795,
      "authorized": 23795,
      "funded": 791
    },
    "payments": 53,
    "payments_amount": 872894964965,
    "trades": 29,
    "traded_amount": 448729564965,
    "price": [
      0.02735230000055337,
      0.028735700001107773,
      0.02735230000055337,
      0.027565503243939506
    ],
    "volume": 3886234472
  },
  {
    "ts": 1626825600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 23934,
      "authorized": 23934,
      "funded": 800
    },
    "payments": 57,
    "payments_amount": 3074533810002,
    "trades": 31,
    "traded_amount": 1519689403046,
    "price": [
      0.027565503243939506,
      0.027565503243939506,
      0.0246572,
      0.0246572
    ],
    "volume": 4106658587
  },
  {
    "ts": 1626912000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 24142,
      "authorized": 24142,
      "funded": 806
    },
    "payments": 52,
    "payments_amount": 1875781455158,
    "trades": 38,
    "traded_amount": 484452038790,
    "price": [
      0.0246572,
      0.025378306999220283,
      0.021255499971098695,
      0.02212299902229333
    ],
    "volume": 5610673114
  },
  {
    "ts": 1626998400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 24384,
      "authorized": 24384,
      "funded": 810
    },
    "payments": 56,
    "payments_amount": 587127356390,
    "trades": 42,
    "traded_amount": 307099569137,
    "price": [
      0.02212299902229333,
      0.023134700018861462,
      0.021062200000256024,
      0.02187199300311522
    ],
    "volume": 2328125081
  },
  {
    "ts": 1627084800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 24602,
      "authorized": 24602,
      "funded": 820
    },
    "payments": 42,
    "payments_amount": 513882401994,
    "trades": 26,
    "traded_amount": 267540701994,
    "price": [
      0.02187199300311522,
      0.02216349951535624,
      0.020962099541013426,
      0.021232599879392633
    ],
    "volume": 1000712577
  },
  {
    "ts": 1627171200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 24841,
      "authorized": 24841,
      "funded": 823
    },
    "payments": 37,
    "payments_amount": 329321363483,
    "trades": 19,
    "traded_amount": 120914363483,
    "price": [
      0.021232599879392633,
      0.022703299903222087,
      0.021232599879392633,
      0.022298599923064544
    ],
    "volume": 996190140
  },
  {
    "ts": 1627257600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 25123,
      "authorized": 25123,
      "funded": 831
    },
    "payments": 64,
    "payments_amount": 980870025456,
    "trades": 49,
    "traded_amount": 491132034564,
    "price": [
      0.022298599923064544,
      0.022341600010249048,
      0.0203227,
      0.022341600010249048
    ],
    "volume": 3450155586
  },
  {
    "ts": 1627344000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 25469,
      "authorized": 25469,
      "funded": 845
    },
    "payments": 64,
    "payments_amount": 1380141892595,
    "trades": 44,
    "traded_amount": 678007673082,
    "price": [
      0.022341600010249048,
      0.023123994929599292,
      0.0212698,
      0.021547499995079624
    ],
    "volume": 3395810228
  },
  {
    "ts": 1627430400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 25723,
      "authorized": 25723,
      "funded": 852
    },
    "payments": 64,
    "payments_amount": 481733431580,
    "trades": 37,
    "traded_amount": 273701631580,
    "price": [
      0.021547499995079624,
      0.022403599901050182,
      0.0206562,
      0.02141760131040905
    ],
    "volume": 1508575807
  },
  {
    "ts": 1627516800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 26030,
      "authorized": 26030,
      "funded": 856
    },
    "payments": 53,
    "payments_amount": 424528358599,
    "trades": 38,
    "traded_amount": 276405354783,
    "price": [
      0.02141760131040905,
      0.02150610000000575,
      0.021276098846884178,
      0.02138358942324657
    ],
    "volume": 1178293421
  },
  {
    "ts": 1627603200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 26322,
      "authorized": 26322,
      "funded": 866
    },
    "payments": 69,
    "payments_amount": 2697490235514,
    "trades": 42,
    "traded_amount": 1028078993750,
    "price": [
      0.02138358942324657,
      0.02138358942324657,
      0.019862399769279036,
      0.019862399769279036
    ],
    "volume": 2343585878
  },
  {
    "ts": 1627689600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 26612,
      "authorized": 26612,
      "funded": 875
    },
    "payments": 30,
    "payments_amount": 387638404975,
    "trades": 33,
    "traded_amount": 256412827839,
    "price": [
      0.019862399769279036,
      0.020734000574881166,
      0.019614899898648903,
      0.01991359586992954
    ],
    "volume": 3531422524
  },
  {
    "ts": 1627776000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 26878,
      "authorized": 26878,
      "funded": 886
    },
    "payments": 38,
    "payments_amount": 3060160600461,
    "trades": 22,
    "traded_amount": 1001992500240,
    "price": [
      0.01991359586992954,
      0.020088800001598194,
      0.018759199981798882,
      0.0200369000004997
    ],
    "volume": 1509097973
  },
  {
    "ts": 1627862400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 27167,
      "authorized": 27167,
      "funded": 898
    },
    "payments": 55,
    "payments_amount": 3593777138090,
    "trades": 40,
    "traded_amount": 1617287380233,
    "price": [
      0.0200369000004997,
      0.021102700002230416,
      0.01969459112316255,
      0.0201587852298351
    ],
    "volume": 4520808853
  },
  {
    "ts": 1627948800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 27459,
      "authorized": 27459,
      "funded": 895
    },
    "payments": 76,
    "payments_amount": 5749938243159,
    "trades": 56,
    "traded_amount": 2479382872151,
    "price": [
      0.0201587852298351,
      0.021340598239566626,
      0.020061094828335468,
      0.021052099850519947
    ],
    "volume": 24521868223
  },
  {
    "ts": 1628035200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 27671,
      "authorized": 27671,
      "funded": 902
    },
    "payments": 42,
    "payments_amount": 310091890064,
    "trades": 21,
    "traded_amount": 144871441426,
    "price": [
      0.021052099850519947,
      0.021052099850519947,
      0.02005180522507337,
      0.02007440231716757
    ],
    "volume": 1735323866
  },
  {
    "ts": 1628121600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 27876,
      "authorized": 27876,
      "funded": 907
    },
    "payments": 68,
    "payments_amount": 3074917407769,
    "trades": 48,
    "traded_amount": 1594472781689,
    "price": [
      0.02007440231716757,
      0.02127080956499969,
      0.0197512,
      0.020414306336285746
    ],
    "volume": 20743465926
  },
  {
    "ts": 1628208000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 28117,
      "authorized": 28117,
      "funded": 914
    },
    "payments": 56,
    "payments_amount": 796103783930,
    "trades": 32,
    "traded_amount": 493381983930,
    "price": [
      0.020414306336285746,
      0.02059258637558399,
      0.019883600074326317,
      0.02006679844994462
    ],
    "volume": 782725859
  },
  {
    "ts": 1628294400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 28336,
      "authorized": 28336,
      "funded": 924
    },
    "payments": 30,
    "payments_amount": 541056425379,
    "trades": 22,
    "traded_amount": 256805625360,
    "price": [
      0.02006679844994462,
      0.02013430700208611,
      0.0180816,
      0.018648188027490045
    ],
    "volume": 2811928653
  },
  {
    "ts": 1628380800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 28620,
      "authorized": 28620,
      "funded": 923
    },
    "payments": 32,
    "payments_amount": 629568635161,
    "trades": 30,
    "traded_amount": 538248035122,
    "price": [
      0.018648188027490045,
      0.01967898964915233,
      0.01798619974195766,
      0.01967898964915233
    ],
    "volume": 839544345
  },
  {
    "ts": 1628467200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 28850,
      "authorized": 28850,
      "funded": 932
    },
    "payments": 65,
    "payments_amount": 842110252715,
    "trades": 40,
    "traded_amount": 528353074469,
    "price": [
      0.01967898964915233,
      0.020227896381810398,
      0.0182262,
      0.018793497
    ],
    "volume": 1577904666
  },
  {
    "ts": 1628553600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29088,
      "authorized": 29088,
      "funded": 936
    },
    "payments": 44,
    "payments_amount": 602636060059,
    "trades": 29,
    "traded_amount": 337437260020,
    "price": [
      0.018793497,
      0.019214200308537786,
      0.01832380456273824,
      0.018697407414768136
    ],
    "volume": 1132549329
  },
  {
    "ts": 1628640000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29255,
      "authorized": 29255,
      "funded": 945
    },
    "payments": 24,
    "payments_amount": 1173139644365,
    "trades": 14,
    "traded_amount": 138944244332,
    "price": [
      0.018697407414768136,
      0.018697407414768136,
      0.015879800133618387,
      0.017125697970956696
    ],
    "volume": 1753532307
  },
  {
    "ts": 1628726400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29472,
      "authorized": 29472,
      "funded": 950
    },
    "payments": 52,
    "payments_amount": 2471622881536,
    "trades": 60,
    "traded_amount": 3556667722492,
    "price": [
      0.017125697970956696,
      0.018077300010141094,
      0.015976499719201835,
      0.017850006962902863
    ],
    "volume": 22637285102
  },
  {
    "ts": 1628812800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29625,
      "authorized": 29625,
      "funded": 960
    },
    "payments": 70,
    "payments_amount": 1011488640286,
    "trades": 44,
    "traded_amount": 507185340227,
    "price": [
      0.017850006962902863,
      0.017850006962902863,
      0.015806099874812962,
      0.01600019969280116
    ],
    "volume": 601521930
  },
  {
    "ts": 1628899200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29753,
      "authorized": 29753,
      "funded": 967
    },
    "payments": 44,
    "payments_amount": 2066940705334,
    "trades": 28,
    "traded_amount": 803684410671,
    "price": [
      0.01600019969280116,
      0.01600019969280116,
      0.01465871,
      0.014658710092424464
    ],
    "volume": 968504767
  },
  {
    "ts": 1628985600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 29917,
      "authorized": 29917,
      "funded": 972
    },
    "payments": 29,
    "payments_amount": 441332782597,
    "trades": 14,
    "traded_amount": 206964582597,
    "price": [
      0.014658710092424464,
      0.014774911029390375,
      0.014323899999268589,
      0.014665303599924634
    ],
    "volume": 498423289
  },
  {
    "ts": 1629072000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30084,
      "authorized": 30084,
      "funded": 972
    },
    "payments": 26,
    "payments_amount": 280477767101,
    "trades": 20,
    "traded_amount": 66815516693,
    "price": [
      0.014665303599924634,
      0.01515290065541053,
      0.0139586,
      0.01515290065541053
    ],
    "volume": 311490651
  },
  {
    "ts": 1629158400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30263,
      "authorized": 30263,
      "funded": 981
    },
    "payments": 86,
    "payments_amount": 1591898044715,
    "trades": 70,
    "traded_amount": 1339091643208,
    "price": [
      0.01515290065541053,
      0.0164081258702164,
      0.014428266668618246,
      0.0164081258702164
    ],
    "volume": 7715905740
  },
  {
    "ts": 1629244800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30410,
      "authorized": 30410,
      "funded": 990
    },
    "payments": 85,
    "payments_amount": 2567826150033,
    "trades": 60,
    "traded_amount": 1440345748521,
    "price": [
      0.0164081258702164,
      0.016689699944637666,
      0.015476208026500664,
      0.016689699944637666
    ],
    "volume": 3615546712
  },
  {
    "ts": 1629331200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30571,
      "authorized": 30571,
      "funded": 991
    },
    "payments": 67,
    "payments_amount": 809475851361,
    "trades": 55,
    "traded_amount": 990525700240,
    "price": [
      0.016689699944637666,
      0.016689699944637666,
      0.0150139,
      0.015770399968318076
    ],
    "volume": 6091044203
  },
  {
    "ts": 1629417600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30716,
      "authorized": 30716,
      "funded": 1004
    },
    "payments": 48,
    "payments_amount": 914348625967,
    "trades": 25,
    "traded_amount": 469456925967,
    "price": [
      0.015770399968318076,
      0.015770399968318076,
      0.014658900002708867,
      0.014799799963320696
    ],
    "volume": 702159063
  },
  {
    "ts": 1629504000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30844,
      "authorized": 30844,
      "funded": 1008
    },
    "payments": 40,
    "payments_amount": 435359323972,
    "trades": 26,
    "traded_amount": 230672958816,
    "price": [
      0.014799799963320696,
      0.0152868,
      0.0147,
      0.0152739
    ],
    "volume": 212180789
  },
  {
    "ts": 1629590400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 30988,
      "authorized": 30988,
      "funded": 1016
    },
    "payments": 48,
    "payments_amount": 453364282862,
    "trades": 34,
    "traded_amount": 292002238921,
    "price": [
      0.0152739,
      0.01566141465649463,
      0.014699899999674053,
      0.01566141465649463
    ],
    "volume": 1400012033
  },
  {
    "ts": 1629676800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31120,
      "authorized": 31120,
      "funded": 1027
    },
    "payments": 80,
    "payments_amount": 2138761018585,
    "trades": 52,
    "traded_amount": 1102372918519,
    "price": [
      0.01566141465649463,
      0.01566141465649463,
      0.0143746,
      0.01448390294117647
    ],
    "volume": 2553912306
  },
  {
    "ts": 1629763200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31277,
      "authorized": 31277,
      "funded": 1040
    },
    "payments": 87,
    "payments_amount": 1429278692225,
    "trades": 56,
    "traded_amount": 905652016749,
    "price": [
      0.01448390294117647,
      0.01595860492278587,
      0.01448390294117647,
      0.01595860492278587
    ],
    "volume": 6893813264
  },
  {
    "ts": 1629849600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31429,
      "authorized": 31429,
      "funded": 1049
    },
    "payments": 77,
    "payments_amount": 921019409239,
    "trades": 77,
    "traded_amount": 329266694595,
    "price": [
      0.01595860492278587,
      0.016577099946259968,
      0.015341199997548727,
      0.01587580000071788
    ],
    "volume": 870460472
  },
  {
    "ts": 1629936000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31569,
      "authorized": 31569,
      "funded": 1056
    },
    "payments": 39,
    "payments_amount": 655815451609,
    "trades": 36,
    "traded_amount": 340416619707,
    "price": [
      0.01587580000071788,
      0.016952100000161847,
      0.01587580000071788,
      0.01663070000238068
    ],
    "volume": 3396883932
  },
  {
    "ts": 1630022400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31668,
      "authorized": 31668,
      "funded": 1070
    },
    "payments": 115,
    "payments_amount": 14388168550053,
    "trades": 110,
    "traded_amount": 11787387201626,
    "price": [
      0.01663070000238068,
      0.017146104391693624,
      0.015396244262302005,
      0.015396244262302005
    ],
    "volume": 73141545294
  },
  {
    "ts": 1630108800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31797,
      "authorized": 31797,
      "funded": 1076
    },
    "payments": 24,
    "payments_amount": 775931908265,
    "trades": 24,
    "traded_amount": 721495508455,
    "price": [
      0.015396244262302005,
      0.01641000180791012,
      0.015396244262302005,
      0.016390015702293655
    ],
    "volume": 8209084901
  },
  {
    "ts": 1630195200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 31950,
      "authorized": 31950,
      "funded": 1081
    },
    "payments": 46,
    "payments_amount": 326369004114,
    "trades": 26,
    "traded_amount": 174085504114,
    "price": [
      0.016390015702293655,
      0.016470598507252486,
      0.01602209568576625,
      0.016158400000070225
    ],
    "volume": 909716347
  },
  {
    "ts": 1630281600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32083,
      "authorized": 32083,
      "funded": 1087
    },
    "payments": 64,
    "payments_amount": 1577592665549,
    "trades": 41,
    "traded_amount": 1308953316162,
    "price": [
      0.016158400000070225,
      0.017326499833415235,
      0.016158400000070225,
      0.017326499833415235
    ],
    "volume": 2217612687
  },
  {
    "ts": 1630368000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32252,
      "authorized": 32252,
      "funded": 1096
    },
    "payments": 89,
    "payments_amount": 10090852641371,
    "trades": 59,
    "traded_amount": 3135585241371,
    "price": [
      0.017326499833415235,
      0.017326499833415235,
      0.015912503778348314,
      0.0161025
    ],
    "volume": 1888852550
  },
  {
    "ts": 1630454400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32385,
      "authorized": 32385,
      "funded": 1103
    },
    "payments": 78,
    "payments_amount": 1110691213468,
    "trades": 59,
    "traded_amount": 823357564249,
    "price": [
      0.0161025,
      0.017012504977135925,
      0.015603396551861076,
      0.016185793751053647
    ],
    "volume": 9069828433
  },
  {
    "ts": 1630540800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32566,
      "authorized": 32566,
      "funded": 1118
    },
    "payments": 73,
    "payments_amount": 1601731242950,
    "trades": 68,
    "traded_amount": 933471609332,
    "price": [
      0.016185793751053647,
      0.0162373,
      0.0151853,
      0.01594590000184627
    ],
    "volume": 1514641945
  },
  {
    "ts": 1630627200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32701,
      "authorized": 32701,
      "funded": 1125
    },
    "payments": 60,
    "payments_amount": 750912810830,
    "trades": 41,
    "traded_amount": 363024237874,
    "price": [
      0.01594590000184627,
      0.016310300000951763,
      0.014769999999918848,
      0.015459912915459914
    ],
    "volume": 2860071841
  },
  {
    "ts": 1630713600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32799,
      "authorized": 32799,
      "funded": 1136
    },
    "payments": 41,
    "payments_amount": 473663194436,
    "trades": 34,
    "traded_amount": 349044816817,
    "price": [
      0.015459912915459914,
      0.0156466,
      0.01465289999963972,
      0.015317000005841278
    ],
    "volume": 3992823093
  },
  {
    "ts": 1630800000,
    "supply": 44550441940619,
    "trustlines": {
      "total": 32907,
      "authorized": 32907,
      "funded": 1142
    },
    "payments": 33,
    "payments_amount": 641670519793,
    "trades": 21,
    "traded_amount": 352691103316,
    "price": [
      0.015317000005841278,
      0.015493792017209197,
      0.014585292069863847,
      0.014624602093775944
    ],
    "volume": 3374899415
  },
  {
    "ts": 1630886400,
    "supply": 44550441940619,
    "trustlines": {
      "total": 33000,
      "authorized": 33000,
      "funded": 1150
    },
    "payments": 45,
    "payments_amount": 1000032460691,
    "trades": 25,
    "traded_amount": 457430760691,
    "price": [
      0.014624602093775944,
      0.014624602093775944,
      0.013441091273176536,
      0.01353790325836674
    ],
    "volume": 1962360751
  },
  {
    "ts": 1630972800,
    "supply": 44550441940619,
    "trustlines": {
      "total": 33119,
      "authorized": 33119,
      "funded": 1159
    },
    "payments": 85,
    "payments_amount": 1755177267896,
    "trades": 80,
    "traded_amount": 4465598523824,
    "price": [
      0.01353790325836674,
      58.900584795321635,
      0.01315070090909091,
      0.0171100086440623
    ],
    "volume": 37682909963
  },
  {
    "ts": 1631059200,
    "supply": 44550441940619,
    "trustlines": {
      "total": 33244,
      "authorized": 33244,
      "funded": 1168
    },
    "payments": 57,
    "payments_amount": 2210871882196,
    "trades": 36,
    "traded_amount": 800388688430,
    "price": [
      0.0171100086440623,
      0.01734409615921364,
      0.01659319921733709,
      0.017187701401040867
    ],
    "volume": 1357370879
  },
  {
    "ts": 1631145600,
    "supply": 44550441940619,
    "trustlines": {
      "total": 33349,
      "authorized": 33349,
      "funded": 1174
    },
    "payments": 88,
    "payments_amount": 4790160533431,
    "trades": 65,
    "traded_amount": 1713738037109,
    "price": [
      0.017187701401040867,
      0.017187701401040867,
      0.016130699840091384,
      0.016869173987996536
    ],
    "volume": 10454903635
  },
  {
    "ts": 1631232000,
    "supply": 44550441940423,
    "trustlines": {
      "total": 33443,
      "authorized": 33443,
      "funded": 1176
    },
    "payments": 65,
    "payments_amount": 2116865510797,
    "trades": 65,
    "traded_amount": 1201575711744,
    "price": [
      0.016869173987996536,
      0.0173471993858688,
      0.015478393684210527,
      0.01716920011104784
    ],
    "volume": 10903805528
  },
  {
    "ts": 1631318400,
    "supply": 44550441940408,
    "trustlines": {
      "total": 33518,
      "authorized": 33518,
      "funded": 1184
    },
    "payments": 43,
    "payments_amount": 1260552818781,
    "trades": 29,
    "traded_amount": 451419718766,
    "price": [
      0.01716920011104784,
      0.0175563,
      0.01689309965538641,
      0.017184992499768844
    ],
    "volume": 2426390989
  },
  {
    "ts": 1631404800,
    "supply": 44550441940408,
    "trustlines": {
      "total": 33595,
      "authorized": 33595,
      "funded": 1190
    },
    "payments": 23,
    "payments_amount": 1208758962951,
    "trades": 14,
    "traded_amount": 483326002034,
    "price": [
      0.017184992499768844,
      0.017184992499768844,
      0.01648919909267246,
      0.016814698964469414
    ],
    "volume": 7004347898
  },
  {
    "ts": 1631491200,
    "supply": 44550441940408,
    "trustlines": {
      "total": 33691,
      "authorized": 33691,
      "funded": 1200
    },
    "payments": 50,
    "payments_amount": 4668957379528,
    "trades": 40,
    "traded_amount": 2343277501673,
    "price": [
      0.016814698964469414,
      0.017950996565371205,
      0.016009692807136395,
      0.01764720287584269
    ],
    "volume": 20086859032
  },
  {
    "ts": 1631577600,
    "supply": 44550441940408,
    "trustlines": {
      "total": 33792,
      "authorized": 33792,
      "funded": 1207
    },
    "payments": 47,
    "payments_amount": 1879429782893,
    "trades": 33,
    "traded_amount": 1009877566923,
    "price": [
      0.01764720287584269,
      0.017648399331964913,
      0.01687060888191628,
      0.01726920833093391
    ],
    "volume": 9569404686
  },
  {
    "ts": 1631664000,
    "supply": 44550441940408,
    "trustlines": {
      "total": 33917,
      "authorized": 33917,
      "funded": 1213
    },
    "payments": 50,
    "payments_amount": 1194439772435,
    "trades": 30,
    "traded_amount": 783177044921,
    "price": [
      0.01726920833093391,
      0.01726920833093391,
      0.0166868924954255,
      0.01673519965605773
    ],
    "volume": 5875710142
  },
  {
    "ts": 1631750400,
    "supply": 44550441940408,
    "trustlines": {
      "total": 34055,
      "authorized": 34055,
      "funded": 1223
    },
    "payments": 43,
    "payments_amount": 2441590877326,
    "trades": 25,
    "traded_amount": 175788826595,
    "price": [
      0.01673519965605773,
      0.017081689258675602,
      0.01597460405125624,
      0.01703720215253379
    ],
    "volume": 1956814803
  },
  {
    "ts": 1631836800,
    "supply": 44550441940408,
    "trustlines": {
      "total": 34196,
      "authorized": 34196,
      "funded": 1243
    },
    "payments": 74,
    "payments_amount": 2775974991517,
    "trades": 45,
    "traded_amount": 1274022079587,
    "price": [
      0.01703720215253379,
      0.01759839477054957,
      0.016228450459986263,
      0.01759839477054957
    ],
    "volume": 1294232437
  },
  {
    "ts": 1631923200,
    "supply": 44550441940408,
    "trustlines": {
      "total": 34337,
      "authorized": 34337,
      "funded": 1249
    },
    "payments": 42,
    "payments_amount": 358426135510,
    "trades": 27,
    "traded_amount": 213441235455,
    "price": [
      0.01759839477054957,
      0.017624701272924207,
      0.0168573,
      0.01756040559462922
    ],
    "volume": 2671831689
  },
  {
    "ts": 1632009600,
    "supply": 44550441940408,
    "trustlines": {
      "total": 34467,
      "authorized": 34467,
      "funded": 1252
    },
    "payments": 48,
    "payments_amount": 445202137022,
    "trades": 28,
    "traded_amount": 183023137022,
    "price": [
      0.01756040559462922,
      0.01788609637532411,
      0.01658060204117645,
      0.01788609637532411
    ],
    "volume": 993490769
  },
  {
    "ts": 1632096000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 34613,
      "authorized": 34613,
      "funded": 1255
    },
    "payments": 48,
    "payments_amount": 21133923654072,
    "trades": 29,
    "traded_amount": 348753554072,
    "price": [
      0.01788609637532411,
      0.019997795895541407,
      0.01788609637532411,
      0.019997795895541407
    ],
    "volume": 1317205711
  },
  {
    "ts": 1632182400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 34731,
      "authorized": 34731,
      "funded": 1272
    },
    "payments": 60,
    "payments_amount": 3088639121103,
    "trades": 36,
    "traded_amount": 759922088967,
    "price": [
      0.019997795895541407,
      0.020880494248019604,
      0.019294287333333333,
      0.020535199921126762
    ],
    "volume": 1014624484
  },
  {
    "ts": 1632268800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 34847,
      "authorized": 34847,
      "funded": 1273
    },
    "payments": 74,
    "payments_amount": 35852483504132,
    "trades": 39,
    "traded_amount": 2174337882395,
    "price": [
      0.020535199921126762,
      0.020643703469420908,
      0.019033002030131366,
      0.019033002030131366
    ],
    "volume": 1964810448
  },
  {
    "ts": 1632355200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 34980,
      "authorized": 34980,
      "funded": 1282
    },
    "payments": 73,
    "payments_amount": 2502129009620,
    "trades": 47,
    "traded_amount": 2735535016563,
    "price": [
      0.019033002030131366,
      0.019033002030131366,
      0.017601897746508156,
      0.017849098546998433
    ],
    "volume": 2923556084
  },
  {
    "ts": 1632441600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 35131,
      "authorized": 35131,
      "funded": 1286
    },
    "payments": 44,
    "payments_amount": 653165619949,
    "trades": 37,
    "traded_amount": 1801806365256,
    "price": [
      0.017849098546998433,
      0.0203722,
      0.01675977653631285,
      0.018802597706264853
    ],
    "volume": 4440754416
  },
  {
    "ts": 1632528000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 35269,
      "authorized": 35269,
      "funded": 1297
    },
    "payments": 41,
    "payments_amount": 452617826358,
    "trades": 28,
    "traded_amount": 264696526358,
    "price": [
      0.018802597706264853,
      0.020216409615462238,
      0.018802597706264853,
      0.020032300290462966
    ],
    "volume": 3859465282
  },
  {
    "ts": 1632614400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 35440,
      "authorized": 35440,
      "funded": 1317
    },
    "payments": 42,
    "payments_amount": 669848627981,
    "trades": 42,
    "traded_amount": 266027141807,
    "price": [
      0.020032300290462966,
      0.020472623,
      0.019164616283908992,
      0.019882000001258313
    ],
    "volume": 1916560812
  },
  {
    "ts": 1632700800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 35615,
      "authorized": 35615,
      "funded": 1332
    },
    "payments": 50,
    "payments_amount": 565580613084,
    "trades": 37,
    "traded_amount": 171862013028,
    "price": [
      0.019882000001258313,
      0.02102619903685169,
      0.018838276234589554,
      0.020175303157894738
    ],
    "volume": 2763895670
  },
  {
    "ts": 1632787200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 35855,
      "authorized": 35855,
      "funded": 1351
    },
    "payments": 66,
    "payments_amount": 1464959161366,
    "trades": 38,
    "traded_amount": 689210461366,
    "price": [
      0.020175303157894738,
      0.021575218487145293,
      0.019867549668874173,
      0.0204583905
    ],
    "volume": 2684255059
  },
  {
    "ts": 1632873600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36062,
      "authorized": 36062,
      "funded": 1361
    },
    "payments": 54,
    "payments_amount": 589469566305,
    "trades": 33,
    "traded_amount": 2523402660052,
    "price": [
      0.0204583905,
      0.020602900392685492,
      0.018507099219184277,
      0.020266805686905514
    ],
    "volume": 409385634
  },
  {
    "ts": 1632960000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36215,
      "authorized": 36215,
      "funded": 1375
    },
    "payments": 76,
    "payments_amount": 6695218198276,
    "trades": 69,
    "traded_amount": 4913057021513,
    "price": [
      0.020266805686905514,
      0.022222222222222223,
      0.0176524,
      0.0198079
    ],
    "volume": 4720976666
  },
  {
    "ts": 1633046400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36384,
      "authorized": 36384,
      "funded": 1383
    },
    "payments": 57,
    "payments_amount": 1204473892816,
    "trades": 50,
    "traded_amount": 836320802688,
    "price": [
      0.0198079,
      0.0198079,
      0.0168207,
      0.0169474
    ],
    "volume": 12439409841
  },
  {
    "ts": 1633132800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36546,
      "authorized": 36546,
      "funded": 1388
    },
    "payments": 43,
    "payments_amount": 307705761758,
    "trades": 53,
    "traded_amount": 213308553301,
    "price": [
      0.0169474,
      0.018353099939950893,
      0.016036300040028036,
      0.01670000106099099
    ],
    "volume": 1705093975
  },
  {
    "ts": 1633219200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36727,
      "authorized": 36727,
      "funded": 1394
    },
    "payments": 47,
    "payments_amount": 417676730412,
    "trades": 40,
    "traded_amount": 312112749828,
    "price": [
      0.01670000106099099,
      0.01734580002230578,
      0.015710699992314673,
      0.016030899932744917
    ],
    "volume": 2465034183
  },
  {
    "ts": 1633305600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 36899,
      "authorized": 36899,
      "funded": 1399
    },
    "payments": 44,
    "payments_amount": 490196420731,
    "trades": 34,
    "traded_amount": 252917985891,
    "price": [
      0.016030899932744917,
      0.01805559987331252,
      0.016030899932744917,
      0.017862399936878565
    ],
    "volume": 3121008852
  },
  {
    "ts": 1633392000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 37085,
      "authorized": 37085,
      "funded": 1407
    },
    "payments": 55,
    "payments_amount": 1637866489851,
    "trades": 56,
    "traded_amount": 2590669434219,
    "price": [
      0.017862399936878565,
      0.017893400000900714,
      0.015978995704883345,
      0.0174772
    ],
    "volume": 23671191673
  },
  {
    "ts": 1633478400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 37251,
      "authorized": 37251,
      "funded": 1408
    },
    "payments": 51,
    "payments_amount": 2887263395038,
    "trades": 44,
    "traded_amount": 1161046410846,
    "price": [
      0.0174772,
      0.017775296976225135,
      0.0154924,
      0.0154924
    ],
    "volume": 2399027181
  },
  {
    "ts": 1633564800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 37392,
      "authorized": 37392,
      "funded": 1420
    },
    "payments": 49,
    "payments_amount": 1331295247640,
    "trades": 45,
    "traded_amount": 819213025100,
    "price": [
      0.0154924,
      0.016582898274809018,
      0.015017202385143336,
      0.015886710742393215
    ],
    "volume": 9977528117
  },
  {
    "ts": 1633651200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 37607,
      "authorized": 37607,
      "funded": 1428
    },
    "payments": 40,
    "payments_amount": 813361981639,
    "trades": 31,
    "traded_amount": 403332981639,
    "price": [
      0.015886710742393215,
      0.016340698166668933,
      0.015098500087571954,
      0.015512700191057029
    ],
    "volume": 4547135309
  },
  {
    "ts": 1633737600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 37817,
      "authorized": 37817,
      "funded": 1433
    },
    "payments": 48,
    "payments_amount": 846578707819,
    "trades": 34,
    "traded_amount": 314224810747,
    "price": [
      0.015512700191057029,
      0.016343200006633845,
      0.014230701691098536,
      0.015692598037668797
    ],
    "volume": 3478987104
  },
  {
    "ts": 1633824000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 38040,
      "authorized": 38040,
      "funded": 1437
    },
    "payments": 40,
    "payments_amount": 184571144315,
    "trades": 31,
    "traded_amount": 75938740083,
    "price": [
      0.015692598037668797,
      0.016437096561951563,
      0.015089299844300715,
      0.016405091336403502
    ],
    "volume": 625401475
  },
  {
    "ts": 1633910400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 38287,
      "authorized": 38287,
      "funded": 1438
    },
    "payments": 8,
    "payments_amount": 228744096179,
    "trades": 8,
    "traded_amount": 118634396179,
    "price": [
      0.016405091336403502,
      0.016633307163742114,
      0.015760399986109515,
      0.01622520183687105
    ],
    "volume": 209091284
  },
  {
    "ts": 1633996800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 38483,
      "authorized": 38483,
      "funded": 1459
    },
    "payments": 111,
    "payments_amount": 1868731546949,
    "trades": 63,
    "traded_amount": 903783346949,
    "price": [
      0.01622520183687105,
      0.01669661483107141,
      0.015538999934246976,
      0.016281100010464337
    ],
    "volume": 2782801143
  },
  {
    "ts": 1634083200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 38667,
      "authorized": 38667,
      "funded": 1465
    },
    "payments": 41,
    "payments_amount": 312917081593,
    "trades": 33,
    "traded_amount": 129160921512,
    "price": [
      0.016281100010464337,
      0.016281100010464337,
      0.0141058,
      0.0141058
    ],
    "volume": 1852513791
  },
  {
    "ts": 1634169600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 38857,
      "authorized": 38857,
      "funded": 1472
    },
    "payments": 62,
    "payments_amount": 1148482206507,
    "trades": 59,
    "traded_amount": 751699945602,
    "price": [
      0.0141058,
      0.015123804901816154,
      0.01358819998677188,
      0.015021391756951292
    ],
    "volume": 3236647260
  },
  {
    "ts": 1634256000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 39059,
      "authorized": 39059,
      "funded": 1481
    },
    "payments": 58,
    "payments_amount": 2986580971198,
    "trades": 50,
    "traded_amount": 1427050078600,
    "price": [
      0.015021391756951292,
      0.0160857908847185,
      0.0146673076,
      0.014747600016866536
    ],
    "volume": 3945965499
  },
  {
    "ts": 1634342400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 39239,
      "authorized": 39239,
      "funded": 1492
    },
    "payments": 27,
    "payments_amount": 210389774320,
    "trades": 61,
    "traded_amount": 500526757089,
    "price": [
      0.014747600016866536,
      0.014747600016866536,
      0.0127967,
      0.01312389205948565
    ],
    "volume": 2062945022
  },
  {
    "ts": 1634428800,
    "supply": 64550441940408,
    "trustlines": {
      "total": 39452,
      "authorized": 39452,
      "funded": 1498
    },
    "payments": 25,
    "payments_amount": 136263322721,
    "trades": 29,
    "traded_amount": 113090052722,
    "price": [
      0.01312389205948565,
      0.02,
      0.01312389205948565,
      0.014115100000354376
    ],
    "volume": 839131125
  },
  {
    "ts": 1634515200,
    "supply": 64550441940408,
    "trustlines": {
      "total": 39665,
      "authorized": 39665,
      "funded": 1508
    },
    "payments": 60,
    "payments_amount": 817105676235,
    "trades": 60,
    "traded_amount": 965540304198,
    "price": [
      0.014115100000354376,
      0.01448580005230959,
      0.013796805197190978,
      0.014065100463328413
    ],
    "volume": 4910170259
  },
  {
    "ts": 1634601600,
    "supply": 64550441940408,
    "trustlines": {
      "total": 39893,
      "authorized": 39893,
      "funded": 1519
    },
    "payments": 68,
    "payments_amount": 2380117032193,
    "trades": 59,
    "traded_amount": 1187825885139,
    "price": [
      0.014065100463328413,
      0.014763097702240505,
      0.014065100463328413,
      0.014505497898277644
    ],
    "volume": 4955975541
  },
  {
    "ts": 1634688000,
    "supply": 64550441940408,
    "trustlines": {
      "total": 40148,
      "authorized": 40148,
      "funded": 1530
    },
    "payments": 91,
    "payments_amount": 1330476675493,
    "trades": 79,
    "traded_amount": 829735668566,
    "price": [
      0.014505497898277644,
      0.014582200004103913,
      0.013299499536695786,
      0.013711151736745886
    ],
    "volume": 3088556618
  },
  {
    "ts": 1634774400,
    "supply": 64550441940408,
    "trustlines": {
      "total": 40412,
      "authorized": 40412,
      "funded": 1543
    },
    "payments": 67,
    "payments_amount": 1008331432521,
    "trades": 74,
    "traded_amount": 547631546724,
    "price": [
      0.013711151736745886,
      0.014268899953387891,
      0.013063200626126401,
      0.014157293636936711
    ],
    "volume": 3188049723
  },
  {
    "ts": 1634860800,
    "supply": 64550082763068,
    "trustlines": {
      "total": 40687,
      "authorized": 40687,
      "funded": 1550
    },
    "payments": 57,
    "payments_amount": 2974000690264,
    "trades": 42,
    "traded_amount": 1508177858176,
    "price": [
      0.014157293636936711,
      0.01430471241030068,
      0.013653096490957545,
      0.0136827
    ],
    "volume": 1062265912
  },
  {
    "ts": 1634947200,
    "supply": 64550082763068,
    "trustlines": {
      "total": 40943,
      "authorized": 40943,
      "funded": 1570
    },
    "payments": 55,
    "payments_amount": 1616581308131,
    "trades": 34,
    "traded_amount": 792160421161,
    "price": [
      0.0136827,
      0.013966497972746647,
      0.013591108675106194,
      0.013897600019849646
    ],
    "volume": 998561296
  },
  {
    "ts": 1635033600,
    "supply": 64550082763068,
    "trustlines": {
      "total": 41205,
      "authorized": 41205,
      "funded": 1569
    },
    "payments": 45,
    "payments_amount": 634784796885,
    "trades": 49,
    "traded_amount": 525429225879,
    "price": [
      0.013897600019849646,
      0.014045891130515364,
      0.013406201093729397,
      0.0140225
    ],
    "volume": 1512824199
  },
  {
    "ts": 1635120000,
    "supply": 64550082763068,
    "trustlines": {
      "total": 41475,
      "authorized": 41475,
      "funded": 1588
    },
    "payments": 96,
    "payments_amount": 1342091106801,
    "trades": 62,
    "traded_amount": 726992788734,
    "price": [
      0.0140225,
      0.0140225,
      0.013475999995645936,
      0.013614192228012844
    ],
    "volume": 4545369376
  },
  {
    "ts": 1635206400,
    "supply": 64550082763068,
    "trustlines": {
      "total": 41719,
      "authorized": 41719,
      "funded": 1608
    },
    "payments": 130,
    "payments_amount": 1666684583382,
    "trades": 110,
    "traded_amount": 743185763452,
    "price": [
      0.013614192228012844,
      0.013771813079275158,
      0.013197094146452306,
      0.013481574852776082
    ],
    "volume": 432660370
  },
  {
    "ts": 1635292800,
    "supply": 64550082763068,
    "trustlines": {
      "total": 41951,
      "authorized": 41951,
      "funded": 1616
    },
    "payments": 103,
    "payments_amount": 184504709186,
    "trades": 120,
    "traded_amount": 6749907566021,
    "price": [
      0.013481574852776082,
      54,
      0.013481574852776082,
      0.014992407095426522
    ],
    "volume": 52220380767
  },
  {
    "ts": 1635379200,
    "supply": 64550082763068,
    "trustlines": {
      "total": 42138,
      "authorized": 42138,
      "funded": 1633
    },
    "payments": 132,
    "payments_amount": 3131601957228,
    "trades": 106,
    "traded_amount": 787579519502,
    "price": [
      0.014992407095426522,
      0.015535900003505918,
      0.01446229772079772,
      0.014891000002231338
    ],
    "volume": 2782620121
  },
  {
    "ts": 1635465600,
    "supply": 64550082763068,
    "trustlines": {
      "total": 42327,
      "authorized": 42327,
      "funded": 1645
    },
    "payments": 104,
    "payments_amount": 2487572214017,
    "trades": 96,
    "traded_amount": 1587093962187,
    "price": [
      0.014891000002231338,
      0.014891000038910184,
      0.013628098,
      0.013678290978949771
    ],
    "volume": 10653963245
  },
  {
    "ts": 1635552000,
    "supply": 64550082763068,
    "trustlines": {
      "total": 42498,
      "authorized": 42498,
      "funded": 1653
    },
    "payments": 47,
    "payments_amount": 3674604258889,
    "trades": 46,
    "traded_amount": 1535488042959,
    "price": [
      0.013678290978949771,
      0.0142516,
      0.01346310148177507,
      0.013944400329163931
    ],
    "volume": 2527664191
  },
  {
    "ts": 1635638400,
    "supply": 64550082763068,
    "trustlines": {
      "total": 42678,
      "authorized": 42678,
      "funded": 1661
    },
    "payments": 47,
    "payments_amount": 1279261640529,
    "trades": 52,
    "traded_amount": 317994326532,
    "price": [
      0.013944400329163931,
      0.017241379310344827,
      0.012864295904105675,
      0.0131998
    ],
    "volume": 2097026969
  },
  {
    "ts": 1635724800,
    "supply": 64550082763068,
    "trustlines": {
      "total": 42849,
      "authorized": 42849,
      "funded": 1670
    },
    "payments": 88,
    "payments_amount": 5517101427475,
    "trades": 61,
    "traded_amount": 2050044127546,
    "price": [
      0.0131998,
      0.01378380334188312,
      0.013177203157894737,
      0.013249182389937107
    ],
    "volume": 2951992124
  },
  {
    "ts": 1635811200,
    "supply": 64550082763068,
    "trustlines": {
      "total": 43062,
      "authorized": 43062,
      "funded": 1675
    },
    "payments": 82,
    "payments_amount": 1734630912839,
    "trades": 102,
    "traded_amount": 9713717858832,
    "price": [
      0.013249182389937107,
      0.013766399835937982,
      0.012860199295704363,
      0.013161301229288533
    ],
    "volume": 38337856216
  },
  {
    "ts": 1635897600,
    "supply": 106834762763068,
    "trustlines": {
      "total": 43289,
      "authorized": 43289,
      "funded": 1702
    },
    "payments": 111,
    "payments_amount": 132996592283168,
    "trades": 113,
    "traded_amount": 1782283430522,
    "price": [
      0.013161301229288533,
      0.013452914798206279,
      0.012115904576892206,
      0.01246729880610653
    ],
    "volume": 8049533883
  },
  {
    "ts": 1635984000,
    "supply": 106834762763068,
    "trustlines": {
      "total": 43468,
      "authorized": 43468,
      "funded": 1709
    },
    "payments": 90,
    "payments_amount": 72836152761246,
    "trades": 113,
    "traded_amount": 1276468162378,
    "price": [
      0.01246729880610653,
      0.013221177758616844,
      0.0124145,
      0.013221177758616844
    ],
    "volume": 9020249634
  },
  {
    "ts": 1636070400,
    "supply": 863506272563068,
    "trustlines": {
      "total": 43656,
      "authorized": 43656,
      "funded": 1728
    },
    "payments": 143,
    "payments_amount": 1516598479940068,
    "trades": 171,
    "traded_amount": 1426239899059,
    "price": [
      0.013221177758616844,
      0.013488800005979707,
      0.012895298694311668,
      0.013479600002186532
    ],
    "volume": 870349266
  },
  {
    "ts": 1636156800,
    "supply": 863506272563068,
    "trustlines": {
      "total": 43797,
      "authorized": 43797,
      "funded": 1731
    },
    "payments": 60,
    "payments_amount": 1200095292619,
    "trades": 161,
    "traded_amount": 1366680767206,
    "price": [
      0.013479600002186532,
      0.013710900003905232,
      0.01329919141084617,
      0.013467184
    ],
    "volume": 445895383
  },
  {
    "ts": 1636243200,
    "supply": 863506272563068,
    "trustlines": {
      "total": 43976,
      "authorized": 43976,
      "funded": 1739
    },
    "payments": 66,
    "payments_amount": 2601465031893,
    "trades": 119,
    "traded_amount": 1754895572843,
    "price": [
      0.013467184,
      0.013467184,
      0.01302129999956263,
      0.013186286262287222
    ],
    "volume": 89104188
  },
  {
    "ts": 1636329600,
    "supply": 863506272563068,
    "trustlines": {
      "total": 44159,
      "authorized": 44159,
      "funded": 1752
    },
    "payments": 130,
    "payments_amount": 758281977206299,
    "trades": 414,
    "traded_amount": 3561347278039,
    "price": [
      0.013186286262287222,
      0.013186286262287222,
      0.012655225136678314,
      0.012655225136678314
    ],
    "volume": 14804060296
  },
  {
    "ts": 1636416000,
    "supply": 863506272563068,
    "trustlines": {
      "total": 44327,
      "authorized": 44327,
      "funded": 1765
    },
    "payments": 129,
    "payments_amount": 6715796438032,
    "trades": 2170,
    "traded_amount": 29616973513649,
    "price": [
      0.012655225136678314,
      0.012772807852694303,
      0.011689226757306208,
      0.011808030099181117
    ],
    "volume": 259112091899
  },
  {
    "ts": 1636502400,
    "supply": 863506272563068,
    "trustlines": {
      "total": 44548,
      "authorized": 44548,
      "funded": 1794
    },
    "payments": 149,
    "payments_amount": 8420749518581,
    "trades": 4750,
    "traded_amount": 107479857850872,
    "price": [
      0.011808030099181117,
      0.012519103052347979,
      0.0111403932,
      0.012451958497724423
    ],
    "volume": 866806022425
  },
  {
    "ts": 1636588800,
    "supply": 863506272563068,
    "trustlines": {
      "total": 44759,
      "authorized": 44759,
      "funded": 1808
    },
    "payments": 128,
    "payments_amount": 5128111586932,
    "trades": 3270,
    "traded_amount": 63254601787050,
    "price": [
      0.012451958497724423,
      0.012516514484956089,
      0.011821897658657697,
      0.011997509182958688
    ],
    "volume": 564156872762
  },
  {
    "ts": 1636675200,
    "supply": 863506272563068,
    "trustlines": {
      "total": 44960,
      "authorized": 44960,
      "funded": 1824
    },
    "payments": 148,
    "payments_amount": 8522784756416,
    "trades": 1842,
    "traded_amount": 24748471113245,
    "price": [
      0.011997509182958688,
      0.012477804397600534,
      0.011997509182958688,
      0.012200866459223388
    ],
    "volume": 190626981519
  },
  {
    "ts": 1636761600,
    "supply": 863506272563032,
    "trustlines": {
      "total": 45149,
      "authorized": 45149,
      "funded": 1834
    },
    "payments": 92,
    "payments_amount": 3899651811923,
    "trades": 690,
    "traded_amount": 8284277213045,
    "price": [
      0.012200866459223388,
      0.012383542452714948,
      0.012191112048621144,
      0.012265834076717217
    ],
    "volume": 51204273363
  },
  {
    "ts": 1636848000,
    "supply": 863506272563032,
    "trustlines": {
      "total": 45350,
      "authorized": 45350,
      "funded": 1863
    },
    "payments": 96,
    "payments_amount": 2090578624872,
    "trades": 983,
    "traded_amount": 9424605917517,
    "price": [
      0.012265834076717217,
      0.012618400011433844,
      0.0121876555,
      0.012247622
    ],
    "volume": 58094062449
  },
  {
    "ts": 1636934400,
    "supply": 863506272563032,
    "trustlines": {
      "total": 45539,
      "authorized": 45539,
      "funded": 1870
    },
    "payments": 140,
    "payments_amount": 7296507348889,
    "trades": 1308,
    "traded_amount": 34007898477443,
    "price": [
      0.012247622,
      0.012373004671734062,
      0.011923498272018164,
      0.012267244
    ],
    "volume": 279248733733
  },
  {
    "ts": 1637020800,
    "supply": 875506272563016,
    "trustlines": {
      "total": 45724,
      "authorized": 45724,
      "funded": 1888
    },
    "payments": 161,
    "payments_amount": 29448014973104,
    "trades": 1431,
    "traded_amount": 63401004237715,
    "price": [
      0.012267244,
      0.0138799,
      0.012267244,
      0.0138799
    ],
    "volume": 640783003073
  },
  {
    "ts": 1637107200,
    "supply": 875506272563016,
    "trustlines": {
      "total": 45918,
      "authorized": 45918,
      "funded": 1912
    },
    "payments": 189,
    "payments_amount": 6644516611916,
    "trades": 1483,
    "traded_amount": 51189334061691,
    "price": [
      0.0138799,
      0.01414740389053607,
      0.013570099064668224,
      0.013736408403624688
    ],
    "volume": 473892780058
  },
  {
    "ts": 1637193600,
    "supply": 875506272563016,
    "trustlines": {
      "total": 46134,
      "authorized": 46134,
      "funded": 1927
    },
    "payments": 119,
    "payments_amount": 8204851266681,
    "trades": 3726,
    "traded_amount": 68297739055673,
    "price": [
      0.013736408403624688,
      0.014371148045165247,
      0.013530717625991331,
      0.014221624938193164
    ],
    "volume": 542026792476
  },
  {
    "ts": 1637280000,
    "supply": 875506272563016,
    "trustlines": {
      "total": 46279,
      "authorized": 46279,
      "funded": 1939
    },
    "payments": 108,
    "payments_amount": 8654575425625,
    "trades": 2378,
    "traded_amount": 61823983002845,
    "price": [
      0.014221624938193164,
      0.014266836690460909,
      0.012987012987012988,
      0.013232117526
    ],
    "volume": 531508563314
  },
  {
    "ts": 1637366400,
    "supply": 875506272533026,
    "trustlines": {
      "total": 46414,
      "authorized": 46414,
      "funded": 1950
    },
    "payments": 67,
    "payments_amount": 5466993192968,
    "trades": 1491,
    "traded_amount": 15769474401987,
    "price": [
      0.013232117526,
      0.015,
      0.01319845136278454,
      0.013492289503974932
    ],
    "volume": 124980364738
  },
  {
    "ts": 1637452800,
    "supply": 875506272532969,
    "trustlines": {
      "total": 46541,
      "authorized": 46541,
      "funded": 1955
    },
    "payments": 47,
    "payments_amount": 1798519737595,
    "trades": 950,
    "traded_amount": 5644932689562,
    "price": [
      0.013492289503974932,
      0.013918600003909782,
      0.013364799809093945,
      0.01354774
    ],
    "volume": 39307400224
  },
  {
    "ts": 1637539200,
    "supply": 875506272532969,
    "trustlines": {
      "total": 46695,
      "authorized": 46695,
      "funded": 1970
    },
    "payments": 93,
    "payments_amount": 5685165932492,
    "trades": 2042,
    "traded_amount": 45928801252417,
    "price": [
      0.01354774,
      0.015810276679841896,
      0.013268139100579328,
      0.013692808195824292
    ],
    "volume": 365642104772
  },
  {
    "ts": 1637625600,
    "supply": 875506272532969,
    "trustlines": {
      "total": 46866,
      "authorized": 46866,
      "funded": 2002
    },
    "payments": 135,
    "payments_amount": 7740917855514,
    "trades": 4107,
    "traded_amount": 27834129102720,
    "price": [
      0.013692808195824292,
      0.014042902375107915,
      0.0136751046902491,
      0.0138827808
    ],
    "volume": 276891735130
  },
  {
    "ts": 1637712000,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47038,
      "authorized": 47038,
      "funded": 2013
    },
    "payments": 144,
    "payments_amount": 14003008636403,
    "trades": 2907,
    "traded_amount": 44535933804027,
    "price": [
      0.0138827808,
      0.014876303009392881,
      0.0138811731,
      0.014698696995889597
    ],
    "volume": 356210787511
  },
  {
    "ts": 1637798400,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47185,
      "authorized": 47185,
      "funded": 2024
    },
    "payments": 95,
    "payments_amount": 7724498331812,
    "trades": 1744,
    "traded_amount": 27223022472147,
    "price": [
      0.014698696995889597,
      0.014698696995889597,
      0.013890209058697695,
      0.014025942117479056
    ],
    "volume": 316857419287
  },
  {
    "ts": 1637884800,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47346,
      "authorized": 47346,
      "funded": 2038
    },
    "payments": 86,
    "payments_amount": 4782589045239,
    "trades": 4387,
    "traded_amount": 71642958520159,
    "price": [
      0.014025942117479056,
      0.0151474,
      0.013809504062789628,
      0.01439784422588471
    ],
    "volume": 739717994056
  },
  {
    "ts": 1637971200,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47492,
      "authorized": 47492,
      "funded": 2055
    },
    "payments": 90,
    "payments_amount": 11961032791786,
    "trades": 1937,
    "traded_amount": 28435228967287,
    "price": [
      0.01439784422588471,
      0.01470200078206465,
      0.014197699385955482,
      0.014647716699624922
    ],
    "volume": 277129964476
  },
  {
    "ts": 1638057600,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47689,
      "authorized": 47689,
      "funded": 2066
    },
    "payments": 76,
    "payments_amount": 8261309871357,
    "trades": 2875,
    "traded_amount": 33794673474422,
    "price": [
      0.014647716699624922,
      0.015320175590615133,
      0.01455388549293972,
      0.014675476
    ],
    "volume": 314322524978
  },
  {
    "ts": 1638144000,
    "supply": 875506272532969,
    "trustlines": {
      "total": 47892,
      "authorized": 47892,
      "funded": 2093
    },
    "payments": 153,
    "payments_amount": 21093334367077,
    "trades": 1725,
    "traded_amount": 38877925502341,
    "price": [
      0.014675476,
      0.014687503283852002,
      0.014096699995207157,
      0.0145252528
    ],
    "volume": 343751130724
  },
  {
    "ts": 1638230400,
    "supply": 875506272532969,
    "trustlines": {
      "total": 48059,
      "authorized": 48059,
      "funded": 2105
    },
    "payments": 125,
    "payments_amount": 9942802368706,
    "trades": 2127,
    "traded_amount": 45814148458880,
    "price": [
      0.0145252528,
      0.014603841145833333,
      0.013900501698553547,
      0.014231152588313235
    ],
    "volume": 362439993813
  },
  {
    "ts": 1638316800,
    "supply": 875506272532969,
    "trustlines": {
      "total": 48290,
      "authorized": 48290,
      "funded": 2121
    },
    "payments": 127,
    "payments_amount": 18718882876423,
    "trades": 2137,
    "traded_amount": 80918159460319,
    "price": [
      0.014231152588313235,
      0.014628902850074982,
      0.0140662206675964,
      0.014392311340172739
    ],
    "volume": 546260723570
  },
  {
    "ts": 1638403200,
    "supply": 875506272532969,
    "trustlines": {
      "total": 48614,
      "authorized": 48614,
      "funded": 2191
    },
    "payments": 356,
    "payments_amount": 79196704823975,
    "trades": 2115,
    "traded_amount": 75417891479665,
    "price": [
      0.014392311340172739,
      0.014875260083017561,
      0.014388244721394858,
      0.014450202347058233
    ],
    "volume": 625883788411
  },
  {
    "ts": 1638489600,
    "supply": 875506272532969,
    "trustlines": {
      "total": 48858,
      "authorized": 48858,
      "funded": 2213
    },
    "payments": 247,
    "payments_amount": 43006627084099,
    "trades": 1937,
    "traded_amount": 44876444132649,
    "price": [
      0.014450202347058233,
      0.01484000006182616,
      0.013747499388650901,
      0.014721295125489435
    ],
    "volume": 288743054455
  },
  {
    "ts": 1638576000,
    "supply": 875506272532969,
    "trustlines": {
      "total": 49135,
      "authorized": 49135,
      "funded": 2242
    },
    "payments": 197,
    "payments_amount": 15250500952582,
    "trades": 2813,
    "traded_amount": 130716136769361,
    "price": [
      0.014721295125489435,
      0.024,
      0.014721295125489435,
      0.01650285569036914
    ],
    "volume": 1399564582697
  },
  {
    "ts": 1638662400,
    "supply": 875506272532969,
    "trustlines": {
      "total": 49435,
      "authorized": 49435,
      "funded": 2262
    },
    "payments": 189,
    "payments_amount": 15396003114359,
    "trades": 1995,
    "traded_amount": 49341174980532,
    "price": [
      0.01650285569036914,
      0.0178259955196313,
      0.016453766846559168,
      0.01693830632054176
    ],
    "volume": 603484689968
  },
  {
    "ts": 1638748800,
    "supply": 875506272532969,
    "trustlines": {
      "total": 49742,
      "authorized": 49742,
      "funded": 2303
    },
    "payments": 323,
    "payments_amount": 16369389609020,
    "trades": 2976,
    "traded_amount": 108943931735950,
    "price": [
      0.01693830632054176,
      0.019070800080764647,
      0.016364648741363483,
      0.016392800348230034
    ],
    "volume": 1487318911561
  },
  {
    "ts": 1638835200,
    "supply": 875506272532969,
    "trustlines": {
      "total": 50015,
      "authorized": 50015,
      "funded": 2399
    },
    "payments": 473,
    "payments_amount": 60160217060431,
    "trades": 765,
    "traded_amount": 21896717071289,
    "price": [
      0.016392800348230034,
      0.01674343508873114,
      0.01620939708266206,
      0.016639337636363636
    ],
    "volume": 202748664041
  },
  {
    "ts": 1638921600,
    "supply": 875506272532969,
    "trustlines": {
      "total": 50322,
      "authorized": 50322,
      "funded": 2459
    },
    "payments": 407,
    "payments_amount": 6251461045467,
    "trades": 906,
    "traded_amount": 41460358594463,
    "price": [
      0.016639337636363636,
      0.016733289300917487,
      0.015864829293189227,
      0.015864829293189227
    ],
    "volume": 380230277495
  },
  {
    "ts": 1639008000,
    "supply": 875506272532969,
    "trustlines": {
      "total": 50608,
      "authorized": 50608,
      "funded": 2534
    },
    "payments": 395,
    "payments_amount": 22524006377224,
    "trades": 1814,
    "traded_amount": 64760076419933,
    "price": [
      0.015864829293189227,
      0.017465366387554367,
      0.015864829293189227,
      0.01742706390909091
    ],
    "volume": 567921056035
  },
  {
    "ts": 1639094400,
    "supply": 875506272532969,
    "trustlines": {
      "total": 50893,
      "authorized": 50893,
      "funded": 2618
    },
    "payments": 456,
    "payments_amount": 23131507728301,
    "trades": 2149,
    "traded_amount": 40910415320410,
    "price": [
      0.01742706390909091,
      0.018634898777334676,
      0.017377079544011166,
      0.01860593577
    ],
    "volume": 420657029958
  },
  {
    "ts": 1639180800,
    "supply": 875506272532928,
    "trustlines": {
      "total": 51130,
      "authorized": 51130,
      "funded": 2684
    },
    "payments": 332,
    "payments_amount": 9430339459011,
    "trades": 1151,
    "traded_amount": 20385968282970,
    "price": [
      0.01860593577,
      0.018867924528301886,
      0.017378197795673747,
      0.017960692736931915
    ],
    "volume": 163270788853
  },
  {
    "ts": 1639267200,
    "supply": 875506272532928,
    "trustlines": {
      "total": 51418,
      "authorized": 51418,
      "funded": 2728
    },
    "payments": 243,
    "payments_amount": 9843170084933,
    "trades": 1568,
    "traded_amount": 56540569895852,
    "price": [
      0.017960692736931915,
      0.018570800034556426,
      0.017552631363636363,
      0.017768443541249533
    ],
    "volume": 545981693918
  },
  {
    "ts": 1639353600,
    "supply": 875506272532928,
    "trustlines": {
      "total": 51749,
      "authorized": 51749,
      "funded": 2813
    },
    "payments": 533,
    "payments_amount": 66375795350320,
    "trades": 1679,
    "traded_amount": 65083483126246,
    "price": [
      0.017768443541249533,
      0.01990899212170606,
      0.01766210121955926,
      0.019845461272504594
    ],
    "volume": 420803754365
  },
  {
    "ts": 1639440000,
    "supply": 875506272532928,
    "trustlines": {
      "total": 52079,
      "authorized": 52079,
      "funded": 2908
    },
    "payments": 588,
    "payments_amount": 42247495527553,
    "trades": 1432,
    "traded_amount": 28024111781870,
    "price": [
      0.019845461272504594,
      0.0219959,
      0.019047685784313727,
      0.019138155133333334
    ],
    "volume": 260255931576
  },
  {
    "ts": 1639526400,
    "supply": 875506272532928,
    "trustlines": {
      "total": 52386,
      "authorized": 52386,
      "funded": 2985
    },
    "payments": 467,
    "payments_amount": 10321067934002,
    "trades": 1976,
    "traded_amount": 29820442283522,
    "price": [
      0.019138155133333334,
      0.020439011757467908,
      0.019002880882956864,
      0.019002880882956864
    ],
    "volume": 282625180078
  },
  {
    "ts": 1639612800,
    "supply": 875506272532928,
    "trustlines": {
      "total": 52688,
      "authorized": 52688,
      "funded": 3108
    },
    "payments": 546,
    "payments_amount": 12659384440170,
    "trades": 1148,
    "traded_amount": 15616976596933,
    "price": [
      0.019002880882956864,
      0.021998100328172305,
      0.017701999819550486,
      0.019888198431041455
    ],
    "volume": 132302828099
  },
  {
    "ts": 1639699200,
    "supply": 875506272532928,
    "trustlines": {
      "total": 62982,
      "authorized": 62982,
      "funded": 3980
    },
    "payments": 2408,
    "payments_amount": 74288128251288,
    "trades": 2202,
    "traded_amount": 49313948921176,
    "price": [
      0.019888198431041455,
      0.022000101502322175,
      0.017703845398674707,
      0.020614531723679195
    ],
    "volume": 320516179057
  },
  {
    "ts": 1639785600,
    "supply": 875506272532928,
    "trustlines": {
      "total": 64573,
      "authorized": 64573,
      "funded": 4306
    },
    "payments": 1619,
    "payments_amount": 31223504401817,
    "trades": 1560,
    "traded_amount": 26135608993680,
    "price": [
      0.020614531723679195,
      0.026301900370086586,
      0.017699999992157045,
      0.020876984783614744
    ],
    "volume": 272864128866
  },
  {
    "ts": 1639872000,
    "supply": 875506272532928,
    "trustlines": {
      "total": 64993,
      "authorized": 64993,
      "funded": 4445
    },
    "payments": 738,
    "payments_amount": 80636985280481,
    "trades": 1175,
    "traded_amount": 42267815244491,
    "price": [
      0.020876984783614744,
      0.060325000013142775,
      0.0176999999980894,
      0.01976783186608849
    ],
    "volume": 269151668866
  },
  {
    "ts": 1639958400,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65255,
      "authorized": 65255,
      "funded": 4527
    },
    "payments": 381,
    "payments_amount": 16879745835436,
    "trades": 1342,
    "traded_amount": 34291592615009,
    "price": [
      0.01976783186608849,
      0.065585257199224,
      0.01793619999085355,
      0.01958327941398575
    ],
    "volume": 303083995887
  },
  {
    "ts": 1640044800,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65449,
      "authorized": 65449,
      "funded": 4758
    },
    "payments": 968,
    "payments_amount": 11750315389821,
    "trades": 1166,
    "traded_amount": 17209929214599,
    "price": [
      0.01958327941398575,
      0.019854305033749298,
      0.018768399153621353,
      0.018855547
    ],
    "volume": 127415559965
  },
  {
    "ts": 1640131200,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65602,
      "authorized": 65602,
      "funded": 5199
    },
    "payments": 1656,
    "payments_amount": 20852801359680,
    "trades": 1479,
    "traded_amount": 28877707707197,
    "price": [
      0.018855547,
      0.04950495049504951,
      0.018342498836938774,
      0.018521854662907674
    ],
    "volume": 219114733059
  },
  {
    "ts": 1640217600,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65719,
      "authorized": 65719,
      "funded": 5220
    },
    "payments": 589,
    "payments_amount": 105438341326598,
    "trades": 1460,
    "traded_amount": 35448966875602,
    "price": [
      0.018521854662907674,
      0.01862681492396878,
      0.016774468308,
      0.016792039925340358
    ],
    "volume": 363562323801
  },
  {
    "ts": 1640304000,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65772,
      "authorized": 65772,
      "funded": 5242
    },
    "payments": 264,
    "payments_amount": 15456825570881,
    "trades": 788,
    "traded_amount": 25062979445723,
    "price": [
      0.016792039925340358,
      0.0175653,
      0.01674849482,
      0.017387987667660306
    ],
    "volume": 154599973348
  },
  {
    "ts": 1640390400,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65806,
      "authorized": 65806,
      "funded": 5249
    },
    "payments": 113,
    "payments_amount": 3523988829206,
    "trades": 741,
    "traded_amount": 12921845772667,
    "price": [
      0.017387987667660306,
      0.017387987667660306,
      0.0165629986241206,
      0.016689769740980062
    ],
    "volume": 152155426869
  },
  {
    "ts": 1640476800,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65843,
      "authorized": 65843,
      "funded": 5265
    },
    "payments": 136,
    "payments_amount": 4756196795069,
    "trades": 532,
    "traded_amount": 7658373971189,
    "price": [
      0.016689769740980062,
      0.01699420000469695,
      0.016320192494800396,
      0.01658972661474139
    ],
    "volume": 63753006328
  },
  {
    "ts": 1640563200,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65906,
      "authorized": 65906,
      "funded": 5282
    },
    "payments": 267,
    "payments_amount": 13901025351298,
    "trades": 666,
    "traded_amount": 8407137458834,
    "price": [
      0.01658972661474139,
      0.01660289312474236,
      0.015996480006567514,
      0.016140843394345898
    ],
    "volume": 65177779372
  },
  {
    "ts": 1640649600,
    "supply": 875506272532928,
    "trustlines": {
      "total": 65968,
      "authorized": 65968,
      "funded": 5314
    },
    "payments": 339,
    "payments_amount": 10160497825888,
    "trades": 1381,
    "traded_amount": 35338818656367,
    "price": [
      0.016140843394345898,
      0.01771242639738021,
      0.016140843394345898,
      0.017679894959418584
    ],
    "volume": 358158361819
  },
  {
    "ts": 1640736000,
    "supply": 875506272532928,
    "trustlines": {
      "total": 66006,
      "authorized": 66006,
      "funded": 5331
    },
    "payments": 141,
    "payments_amount": 5746178055614,
    "trades": 768,
    "traded_amount": 17884909288053,
    "price": [
      0.017679894959418584,
      0.018083607165372252,
      0.01749051943030303,
      0.018083607165372252
    ],
    "volume": 178811899696
  },
  {
    "ts": 1640822400,
    "supply": 875506272532928,
    "trustlines": {
      "total": 66052,
      "authorized": 66052,
      "funded": 5353
    },
    "payments": 173,
    "payments_amount": 8612195122767,
    "trades": 776,
    "traded_amount": 11918600676726,
    "price": [
      0.018083607165372252,
      0.018334095427938932,
      0.017763118799573596,
      0.01813046927239728
    ],
    "volume": 105172356303
  },
  {
    "ts": 1640908800,
    "supply": 875506272532928,
    "trustlines": {
      "total": 66082,
      "authorized": 66082,
      "funded": 5363
    },
    "payments": 115,
    "payments_amount": 3843085399370,
    "trades": 687,
    "traded_amount": 16638555472598,
    "price": [
      0.01813046927239728,
      0.018503824083552316,
      0.017672601666666666,
      0.01812442887554844
    ],
    "volume": 148575005899
  },
  {
    "ts": 1640995200,
    "supply": 875506272532928,
    "trustlines": {
      "total": 66099,
      "authorized": 66099,
      "funded": 5370
    },
    "payments": 49,
    "payments_amount": 460539651905,
    "trades": 617,
    "traded_amount": 5969121883420,
    "price": [
      0.01812442887554844,
      0.018230798209905332,
      0.017702566062862992,
      0.017702566062862992
    ],
    "volume": 51446051148
  },
  {
    "ts": 1641081600,
    "supply": 875506272532928,
    "trustlines": {
      "total": 66130,
      "authorized": 66130,
      "funded": 5383
    },
    "payments": 63,
    "payments_amount": 611462683547,
    "trades": 928,
    "traded_amount": 21031928877926,
    "price": [
      0.017702566062862992,
      0.01776259499997104,
      0.016788054955383967,
      0.016788054955383967
    ],
    "volume": 201099341623
  },
  {
    "ts": 1641168000,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66170,
      "authorized": 66170,
      "funded": 5391
    },
    "payments": 141,
    "payments_amount": 10849602996031,
    "trades": 963,
    "traded_amount": 23777817224701,
    "price": [
      0.016788054955383967,
      0.017073005051934976,
      0.01650844646904619,
      0.016824083243393385
    ],
    "volume": 189983908179
  },
  {
    "ts": 1641254400,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66210,
      "authorized": 66210,
      "funded": 5614
    },
    "payments": 515,
    "payments_amount": 7246838554220,
    "trades": 673,
    "traded_amount": 11343228754839,
    "price": [
      0.016824083243393385,
      0.017477799781023262,
      0.016824041833015387,
      0.0174172666769893
    ],
    "volume": 90287874896
  },
  {
    "ts": 1641340800,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66241,
      "authorized": 66241,
      "funded": 5612
    },
    "payments": 254,
    "payments_amount": 7156190250939,
    "trades": 1703,
    "traded_amount": 41262374079306,
    "price": [
      0.0174172666769893,
      0.023529411764705882,
      0.016803331695077472,
      0.018579950965275413
    ],
    "volume": 479543816178
  },
  {
    "ts": 1641427200,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66284,
      "authorized": 66284,
      "funded": 5619
    },
    "payments": 147,
    "payments_amount": 1944109553541,
    "trades": 878,
    "traded_amount": 25062825670093,
    "price": [
      0.018579950965275413,
      0.019018611980407406,
      0.018309168,
      0.018309168
    ],
    "volume": 211230670707
  },
  {
    "ts": 1641513600,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66317,
      "authorized": 66317,
      "funded": 5636
    },
    "payments": 253,
    "payments_amount": 18021112416048,
    "trades": 2391,
    "traded_amount": 111824965730279,
    "price": [
      0.018309168,
      0.01946751074953489,
      0.017710192818666877,
      0.019256677924550242
    ],
    "volume": 1052220802804
  },
  {
    "ts": 1641600000,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66350,
      "authorized": 66350,
      "funded": 5638
    },
    "payments": 100,
    "payments_amount": 2466106197699,
    "trades": 1378,
    "traded_amount": 23163429631667,
    "price": [
      0.019256677924550242,
      0.019715726730857405,
      0.01869145405218317,
      0.01912749135781489
    ],
    "volume": 252336207270
  },
  {
    "ts": 1641686400,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66400,
      "authorized": 66400,
      "funded": 5660
    },
    "payments": 123,
    "payments_amount": 6063795833285,
    "trades": 812,
    "traded_amount": 11435792556636,
    "price": [
      0.01912749135781489,
      0.01921331357807174,
      0.018643978833333335,
      0.018695933929766033
    ],
    "volume": 94313506901
  },
  {
    "ts": 1641772800,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66430,
      "authorized": 66430,
      "funded": 5679
    },
    "payments": 152,
    "payments_amount": 6741913591522,
    "trades": 1316,
    "traded_amount": 32594721416773,
    "price": [
      0.018695933929766033,
      0.019906268644679682,
      0.018667385930210754,
      0.019175398594503824
    ],
    "volume": 328679351502
  },
  {
    "ts": 1641859200,
    "supply": 875506272532925,
    "trustlines": {
      "total": 66501,
      "authorized": 66501,
      "funded": 5719
    },
    "payments": 391,
    "payments_amount": 10512202610614,
    "trades": 1092,
    "traded_amount": 17923052820801,
    "price": [
      0.019175398594503824,
      0.019348393520567567,
      0.01849562999190093,
      0.018495633268862433
    ],
    "volume": 229016892846
  },
  {
    "ts": 1641945600,
    "supply": 875506272532892,
    "trustlines": {
      "total": 66682,
      "authorized": 66682,
      "funded": 5801
    },
    "payments": 639,
    "payments_amount": 17392675692947,
    "trades": 1389,
    "traded_amount": 29425544644189,
    "price": [
      0.018495633268862433,
      0.018504510680083946,
      0.01669079990678936,
      0.017245735842900952
    ],
    "volume": 331160138704
  },
  {
    "ts": 1642032000,
    "supply": 875506272532892,
    "trustlines": {
      "total": 66821,
      "authorized": 66821,
      "funded": 5882
    },
    "payments": 514,
    "payments_amount": 12001914074316,
    "trades": 1085,
    "traded_amount": 27773509838167,
    "price": [
      0.017245735842900952,
      0.017845560958386388,
      0.016666697957403293,
      0.017845560958386388
    ],
    "volume": 273851979691
  },
  {
    "ts": 1642118400,
    "supply": 875506272532892,
    "trustlines": {
      "total": 66918,
      "authorized": 66918,
      "funded": 5914
    },
    "payments": 291,
    "payments_amount": 4945633223107,
    "trades": 1178,
    "traded_amount": 20507734271169,
    "price": [
      0.017845560958386388,
      0.018406007377321332,
      0.017464992167637257,
      0.017476255275884905
    ],
    "volume": 224130303403
  }
]
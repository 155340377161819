import * as utils from './utils.js';

export function modifyEvent(accounts, modification, event) {
    switch (event.Action) {
        case "INCOME":
            return modifyIncomeEvent(accounts, modification, event);
        case "SPEND":
            return modifySpendEvent(accounts, modification, event);
        default:
            return [event];
    }
}

function modifyIncomeEvent(accounts, modification, event) {
    switch (modification.Action) {
        case "TRANSFER": {
            return [{ ...event, Action: "TRANSFER", Total: modification.TotalUSD || event.Total }];
        }
        case "GIFT": {
            return [{ ...event, Action: "GIFTIN", Total: modification.TotalUSD || event.Total }];
        }
        case "TRADE": {
            const id = event.TxHash;
            const time = event.Date;
            const buyer = event.Account;
            const boughtAsset = event.Symbol;
            const boughtShortAsset = utils.getShortAssetIDFromLong(boughtAsset);
            const boughtAmount = event.Volume;
            const seller = event.Sender;
            const soldAsset = "USD";
            const soldAmount = modification.TotalUSD || "";
            const memo = `Bought ${boughtShortAsset} off-network. ${event.Memo}`;
            return [
                {
                    _Type: "EXCHANGE",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: soldAsset,
                    Total: soldAmount,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "EXCHANGE_VIA_USD",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: soldAsset,
                    Total: soldAmount,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "CREDIT",
                    Date: time,
                    Account: buyer,
                    Action: "BUY",
                    Symbol: boughtAsset,
                    Volume: boughtAmount,
                    Currency: soldAsset,
                    Total: soldAmount,
                    Memo: memo,
                    TxHash: id,
                    Sender: seller,
                    Recipient: buyer,
                },
            ];
        }
    }
}

function modifySpendEvent(accounts, modification, event) {
    switch (modification.Action) {
        case "TRANSFER": {
            return [{ ...event, Action: "TRANSFER", Total: modification.TotalUSD || event.Total }];
        }
        case "GIFT": {
            return [{ ...event, Action: "GIFT", Total: modification.TotalUSD || event.Total }];
        }
        case "TRADE": {
            const id = event.TxHash;
            const time = event.Date;
            const seller = event.Account;
            const soldAsset = event.Symbol;
            const soldShortAsset = utils.getShortAssetIDFromLong(soldAsset);
            const soldAmount = event.Volume;
            const buyer = event.Recipient;
            const boughtAsset = "USD";
            const boughtAmount = modification.TotalUSD || "";
            const memo = `Sold ${soldShortAsset} off-network. ${event.Memo}`;
            return [
                {
                    _Type: "EXCHANGE",
                    Date: time,
                    Account: seller,
                    Action: "SELL",
                    Symbol: soldAsset,
                    Volume: soldAmount,
                    Currency: boughtAsset,
                    Total: boughtAmount,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "EXCHANGE_VIA_USD",
                    Date: time,
                    Account: seller,
                    Action: "SELL",
                    Symbol: soldAsset,
                    Volume: soldAmount,
                    Currency: boughtAsset,
                    Total: boughtAmount,
                    Memo: memo,
                    ExchangeId: id,
                },
                {
                    _Type: "DEBIT",
                    Date: time,
                    Account: seller,
                    Action: "SELL",
                    Symbol: soldAsset,
                    Volume: soldAmount,
                    Currency: boughtAsset,
                    Total: boughtAmount,
                    Memo: memo,
                    TxHash: id,
                    Sender: seller,
                    Recipient: buyer,
                },
            ];
        }
    }
}

[
  {
    "ts": 1522886400,
    "supply": 100000000000,
    "trustlines": {
      "total": 1,
      "authorized": 1,
      "funded": 1
    },
    "payments": 1,
    "payments_amount": 100000000000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1522972800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523059200,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523145600,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523232000,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523318400,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523404800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523491200,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523577600,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523664000,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523750400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523836800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1523923200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524009600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524096000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524182400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524268800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524355200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524441600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524528000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524614400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1524700800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525046400,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525132800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525219200,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525305600,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525392000,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525478400,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525564800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1525651200,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1526342400,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 1,
    "traded_amount": 1000000,
    "price": [
      2.708757,
      2.708757,
      2.708757,
      2.708757
    ],
    "volume": 2708757
  },
  {
    "ts": 1526428800,
    "supply": 100000000000,
    "trustlines": {
      "total": 2,
      "authorized": 2,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.708757,
      2.708757,
      2.708757,
      2.708757
    ],
    "volume": 0
  },
  {
    "ts": 1526515200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.708757,
      2.708757,
      2.708757,
      2.708757
    ],
    "volume": 0
  },
  {
    "ts": 1527120000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 1,
    "traded_amount": 1000000,
    "price": [
      2.708757,
      3.471369,
      2.708757,
      3.471369
    ],
    "volume": 3471369
  },
  {
    "ts": 1527206400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527292800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527379200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527465600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527552000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527638400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527724800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1527811200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528156800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528243200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528329600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528416000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528502400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528588800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528675200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528761600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528848000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1528934400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529020800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529107200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529193600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529280000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529366400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529452800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529539200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529625600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529712000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529798400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529884800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1529971200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530057600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530144000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530230400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530316800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530403200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.471369,
      3.471369,
      3.471369,
      3.471369
    ],
    "volume": 0
  },
  {
    "ts": 1530489600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      3.471369,
      5.03582,
      3.471369,
      5.03582
    ],
    "volume": 503582
  },
  {
    "ts": 1530576000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1530662400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1530748800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1530835200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1530921600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531008000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531094400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531180800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531267200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531353600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531440000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531526400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531612800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531699200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531785600,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531872000,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1531958400,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1532044800,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1532131200,
    "supply": 100000000000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.03582,
      5.03582,
      5.03582,
      5.03582
    ],
    "volume": 0
  },
  {
    "ts": 1532217600,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 12000001,
    "price": [
      5.03582,
      5.03582,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 40693223
  },
  {
    "ts": 1532304000,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532390400,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532476800,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532563200,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532649600,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532736000,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532822400,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532908800,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1532995200,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533081600,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533168000,
    "supply": 100000000000,
    "trustlines": {
      "total": 4,
      "authorized": 4,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533254400,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533340800,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533427200,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533513600,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636,
      3.3911016340748636
    ],
    "volume": 0
  },
  {
    "ts": 1533600000,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 20000,
    "trades": 1,
    "traded_amount": 20000,
    "price": [
      3.3911016340748636,
      4.1431,
      3.3911016340748636,
      4.1431
    ],
    "volume": 82862
  },
  {
    "ts": 1533686400,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.1431,
      4.1431,
      4.1431,
      4.1431
    ],
    "volume": 0
  },
  {
    "ts": 1533772800,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.1431,
      4.1431,
      4.1431,
      4.1431
    ],
    "volume": 0
  },
  {
    "ts": 1533859200,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 1010000,
    "trades": 2,
    "traded_amount": 1010000,
    "price": [
      4.1431,
      4.5405,
      4.1431,
      4.491399
    ],
    "volume": 4536804
  },
  {
    "ts": 1533945600,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534032000,
    "supply": 100000000000,
    "trustlines": {
      "total": 5,
      "authorized": 5,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534118400,
    "supply": 100000000000,
    "trustlines": {
      "total": 6,
      "authorized": 6,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534204800,
    "supply": 100000000000,
    "trustlines": {
      "total": 8,
      "authorized": 8,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534291200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534377600,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534464000,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534550400,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534636800,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534723200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.491399,
      4.491399,
      4.491399,
      4.491399
    ],
    "volume": 0
  },
  {
    "ts": 1534809600,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      4.491399,
      4.6419372,
      4.491399,
      4.6419372
    ],
    "volume": 46419372
  },
  {
    "ts": 1534896000,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.6419372,
      4.6419372,
      4.6419372,
      4.6419372
    ],
    "volume": 0
  },
  {
    "ts": 1534982400,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.6419372,
      4.6419372,
      4.6419372,
      4.6419372
    ],
    "volume": 0
  },
  {
    "ts": 1535068800,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.6419372,
      4.6419372,
      4.6419372,
      4.6419372
    ],
    "volume": 0
  },
  {
    "ts": 1535155200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.6419372,
      4.6419372,
      4.6419372,
      4.6419372
    ],
    "volume": 0
  },
  {
    "ts": 1535241600,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.6419372,
      4.6419372,
      4.6419372,
      4.6419372
    ],
    "volume": 0
  },
  {
    "ts": 1535328000,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 1,
    "traded_amount": 50000000,
    "price": [
      4.6419372,
      4.6419372,
      4.5821999,
      4.5821999
    ],
    "volume": 229109995
  },
  {
    "ts": 1535414400,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.5821999,
      4.5821999,
      4.5821999,
      4.5821999
    ],
    "volume": 0
  },
  {
    "ts": 1535500800,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.5821999,
      4.5821999,
      4.5821999,
      4.5821999
    ],
    "volume": 0
  },
  {
    "ts": 1535587200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 184774338,
    "trades": 3,
    "traded_amount": 184774338,
    "price": [
      4.5821999,
      4.677667608225108,
      4.5821999,
      4.626168764739342
    ],
    "volume": 861016487
  },
  {
    "ts": 1535673600,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1535760000,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1535846400,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1535932800,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536019200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536105600,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536192000,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536278400,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536364800,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536451200,
    "supply": 100000000000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.626168764739342,
      4.626168764739342,
      4.626168764739342,
      4.626168764739342
    ],
    "volume": 0
  },
  {
    "ts": 1536537600,
    "supply": 100000000000,
    "trustlines": {
      "total": 10,
      "authorized": 10,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 188202407,
    "price": [
      4.626168764739342,
      5.313428324006504,
      4.626168764739342,
      5.313428324006504
    ],
    "volume": 1000000000
  },
  {
    "ts": 1536624000,
    "supply": 100000000000,
    "trustlines": {
      "total": 10,
      "authorized": 10,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1536710400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1536796800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1536883200,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1536969600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537056000,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537142400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537228800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537315200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537401600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537488000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537574400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537660800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537747200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537833600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.313428324006504,
      5.313428324006504,
      5.313428324006504,
      5.313428324006504
    ],
    "volume": 0
  },
  {
    "ts": 1537920000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      5.313428324006504,
      5.313428324006504,
      3.9804,
      3.9804
    ],
    "volume": 398040
  },
  {
    "ts": 1538006400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.9804,
      3.9804,
      3.9804,
      3.9804
    ],
    "volume": 0
  },
  {
    "ts": 1538092800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.9804,
      3.9804,
      3.9804,
      3.9804
    ],
    "volume": 0
  },
  {
    "ts": 1538179200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.9804,
      3.9804,
      3.9804,
      3.9804
    ],
    "volume": 0
  },
  {
    "ts": 1538265600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.9804,
      3.9804,
      3.9804,
      3.9804
    ],
    "volume": 0
  },
  {
    "ts": 1538352000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 781163,
    "trades": 1,
    "traded_amount": 781163,
    "price": [
      3.9804,
      3.9804,
      3.840427670025334,
      3.840427670025334
    ],
    "volume": 3000000
  },
  {
    "ts": 1538438400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.840427670025334,
      3.840427670025334,
      3.840427670025334,
      3.840427670025334
    ],
    "volume": 0
  },
  {
    "ts": 1538524800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.840427670025334,
      3.840427670025334,
      3.840427670025334,
      3.840427670025334
    ],
    "volume": 0
  },
  {
    "ts": 1538611200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      3.840427670025334,
      3.840427670025334,
      3.840427670025334,
      3.840427670025334
    ],
    "volume": 0
  },
  {
    "ts": 1538697600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 2451868,
    "trades": 1,
    "traded_amount": 2451868,
    "price": [
      3.840427670025334,
      4.078522987371262,
      3.840427670025334,
      4.078522987371262
    ],
    "volume": 10000000
  },
  {
    "ts": 1538784000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1538870400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1538956800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1539043200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1539129600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1539216000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.078522987371262,
      4.078522987371262,
      4.078522987371262,
      4.078522987371262
    ],
    "volume": 0
  },
  {
    "ts": 1539302400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      4.078522987371262,
      4.63129,
      4.078522987371262,
      4.63129
    ],
    "volume": 463129
  },
  {
    "ts": 1539388800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539475200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539561600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539648000,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539734400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539820800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539907200,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1539993600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.63129,
      4.63129,
      4.63129,
      4.63129
    ],
    "volume": 0
  },
  {
    "ts": 1540080000,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 2,
    "payments_amount": 997723,
    "trades": 2,
    "traded_amount": 997723,
    "price": [
      4.63129,
      4.63129,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 4025860
  },
  {
    "ts": 1540166400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540252800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540339200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540425600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540512000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540598400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540684800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540771200,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540857600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1540944000,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541030400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541116800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541203200,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541289600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541376000,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541462400,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541548800,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541635200,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541721600,
    "supply": 100000000000,
    "trustlines": {
      "total": 11,
      "authorized": 11,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541808000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541894400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1541980800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542067200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542153600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542240000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542326400,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542412800,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542499200,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542585600,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542672000,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542758400,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542844800,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1542931200,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543017600,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543104000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543190400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543276800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543363200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543449600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543536000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543622400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543708800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543795200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543881600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1543968000,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.018299335172375,
      4.018299335172375,
      4.018299335172375,
      4.018299335172375
    ],
    "volume": 0
  },
  {
    "ts": 1544054400,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 2,
    "payments_amount": 1996854630,
    "trades": 3,
    "traded_amount": 1996854630,
    "price": [
      4.018299335172375,
      7.853158503218126,
      4.018299335172375,
      7.82199638962773
    ],
    "volume": 15567270664
  },
  {
    "ts": 1544140800,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544227200,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544313600,
    "supply": 100000000000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544400000,
    "supply": 100000000000,
    "trustlines": {
      "total": 13,
      "authorized": 13,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544486400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544572800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544659200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544745600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544832000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1544918400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1545004800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.82199638962773,
      7.82199638962773,
      7.82199638962773,
      7.82199638962773
    ],
    "volume": 0
  },
  {
    "ts": 1545091200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 1450000000,
    "trades": 1,
    "traded_amount": 1450000000,
    "price": [
      7.82199638962773,
      9.4211886,
      7.82199638962773,
      9.4211886
    ],
    "volume": 13660723470
  },
  {
    "ts": 1545177600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.4211886,
      9.4211886,
      9.4211886,
      9.4211886
    ],
    "volume": 0
  },
  {
    "ts": 1545264000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.4211886,
      9.4211886,
      9.4211886,
      9.4211886
    ],
    "volume": 0
  },
  {
    "ts": 1545350400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.4211886,
      9.4211886,
      9.4211886,
      9.4211886
    ],
    "volume": 0
  },
  {
    "ts": 1545436800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 165000000,
    "trades": 1,
    "traded_amount": 165000000,
    "price": [
      9.4211886,
      9.4211886,
      8.4591462,
      8.4591462
    ],
    "volume": 1395759123
  },
  {
    "ts": 1545523200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.4591462,
      8.4591462,
      8.4591462,
      8.4591462
    ],
    "volume": 0
  },
  {
    "ts": 1545609600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.4591462,
      8.4591462,
      8.4591462,
      8.4591462
    ],
    "volume": 0
  },
  {
    "ts": 1545696000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 1574490922,
    "trades": 3,
    "traded_amount": 1574490922,
    "price": [
      8.4591462,
      8.4591462,
      8.166711973399135,
      8.166711973399135
    ],
    "volume": 12962970993
  },
  {
    "ts": 1545782400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.166711973399135,
      8.166711973399135,
      8.166711973399135,
      8.166711973399135
    ],
    "volume": 0
  },
  {
    "ts": 1545868800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.166711973399135,
      8.166711973399135,
      8.166711973399135,
      8.166711973399135
    ],
    "volume": 0
  },
  {
    "ts": 1545955200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.166711973399135,
      8.166711973399135,
      8.166711973399135,
      8.166711973399135
    ],
    "volume": 0
  },
  {
    "ts": 1546041600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.166711973399135,
      8.166711973399135,
      8.166711973399135,
      8.166711973399135
    ],
    "volume": 0
  },
  {
    "ts": 1546128000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 98794,
    "trades": 1,
    "traded_amount": 98794,
    "price": [
      8.166711973399135,
      8.451525396279127,
      8.166711973399135,
      8.451525396279127
    ],
    "volume": 834960
  },
  {
    "ts": 1546214400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 2,
    "payments_amount": 2190576,
    "trades": 2,
    "traded_amount": 2190576,
    "price": [
      8.451525396279127,
      8.900062727272728,
      8.451525396279127,
      8.710993089890113
    ],
    "volume": 19290069
  },
  {
    "ts": 1546300800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.710993089890113,
      8.710993089890113,
      8.710993089890113,
      8.710993089890113
    ],
    "volume": 0
  },
  {
    "ts": 1546387200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.710993089890113,
      8.710993089890113,
      8.710993089890113,
      8.710993089890113
    ],
    "volume": 0
  },
  {
    "ts": 1546473600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.710993089890113,
      8.710993089890113,
      8.710993089890113,
      8.710993089890113
    ],
    "volume": 0
  },
  {
    "ts": 1546560000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.710993089890113,
      8.710993089890113,
      8.710993089890113,
      8.710993089890113
    ],
    "volume": 0
  },
  {
    "ts": 1546646400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.710993089890113,
      8.710993089890113,
      8.710993089890113,
      8.710993089890113
    ],
    "volume": 0
  },
  {
    "ts": 1546732800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 2,
    "payments_amount": 23955306,
    "trades": 2,
    "traded_amount": 23955306,
    "price": [
      8.710993089890113,
      8.7305745,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 208266894
  },
  {
    "ts": 1546819200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1546905600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1546992000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547078400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547164800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547251200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547337600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547424000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547510400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547596800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547683200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547769600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.65724390492389,
      8.65724390492389,
      8.65724390492389,
      8.65724390492389
    ],
    "volume": 0
  },
  {
    "ts": 1547856000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 5,
    "payments_amount": 15626725,
    "trades": 6,
    "traded_amount": 17626726,
    "price": [
      8.65724390492389,
      9.32841,
      8.65724390492389,
      9.048111475944262
    ],
    "volume": 163179012
  },
  {
    "ts": 1547942400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.048111475944262,
      9.048111475944262,
      9.048111475944262,
      9.048111475944262
    ],
    "volume": 0
  },
  {
    "ts": 1548028800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.048111475944262,
      9.048111475944262,
      9.048111475944262,
      9.048111475944262
    ],
    "volume": 0
  },
  {
    "ts": 1548115200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 147650643,
    "trades": 2,
    "traded_amount": 147650643,
    "price": [
      9.048111475944262,
      9.715657702702703,
      9.048111475944262,
      9.64010592548391
    ],
    "volume": 1428958670
  },
  {
    "ts": 1548201600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 7,
    "payments_amount": 4797069,
    "trades": 7,
    "traded_amount": 4797069,
    "price": [
      9.64010592548391,
      9.781918,
      9.64010592548391,
      9.703967697023742
    ],
    "volume": 46735970
  },
  {
    "ts": 1548288000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.703967697023742,
      9.703967697023742,
      9.703967697023742,
      9.703967697023742
    ],
    "volume": 0
  },
  {
    "ts": 1548374400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 2172708,
    "trades": 3,
    "traded_amount": 2172708,
    "price": [
      9.703967697023742,
      9.938785454545455,
      9.703967697023742,
      9.846918129364651
    ],
    "volume": 21496176
  },
  {
    "ts": 1548460800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.846918129364651,
      9.846918129364651,
      9.846918129364651,
      9.846918129364651
    ],
    "volume": 0
  },
  {
    "ts": 1548547200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 372653,
    "trades": 2,
    "traded_amount": 372653,
    "price": [
      9.846918129364651,
      10.379575,
      9.846918129364651,
      10.296826582799024
    ],
    "volume": 3853693
  },
  {
    "ts": 1548633600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.296826582799024,
      10.296826582799024,
      10.296826582799024,
      10.296826582799024
    ],
    "volume": 0
  },
  {
    "ts": 1548720000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.296826582799024,
      10.296826582799024,
      10.296826582799024,
      10.296826582799024
    ],
    "volume": 0
  },
  {
    "ts": 1548806400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.296826582799024,
      10.296826582799024,
      10.296826582799024,
      10.296826582799024
    ],
    "volume": 0
  },
  {
    "ts": 1548892800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.296826582799024,
      10.296826582799024,
      10.296826582799024,
      10.296826582799024
    ],
    "volume": 0
  },
  {
    "ts": 1548979200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.296826582799024,
      10.296826582799024,
      10.296826582799024,
      10.296826582799024
    ],
    "volume": 0
  },
  {
    "ts": 1549065600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 5687624,
    "trades": 3,
    "traded_amount": 5687624,
    "price": [
      10.296826582799024,
      12.363208,
      10.296826582799024,
      12.289058368200838
    ],
    "volume": 69923303
  },
  {
    "ts": 1549152000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.289058368200838,
      12.289058368200838,
      12.289058368200838,
      12.289058368200838
    ],
    "volume": 0
  },
  {
    "ts": 1549238400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.289058368200838,
      12.289058368200838,
      12.289058368200838,
      12.289058368200838
    ],
    "volume": 0
  },
  {
    "ts": 1549324800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.289058368200838,
      12.289058368200838,
      12.289058368200838,
      12.289058368200838
    ],
    "volume": 0
  },
  {
    "ts": 1549411200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.289058368200838,
      12.289058368200838,
      12.289058368200838,
      12.289058368200838
    ],
    "volume": 0
  },
  {
    "ts": 1549497600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.289058368200838,
      12.289058368200838,
      12.289058368200838,
      12.289058368200838
    ],
    "volume": 0
  },
  {
    "ts": 1549584000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 74035,
    "trades": 1,
    "traded_amount": 74035,
    "price": [
      12.289058368200838,
      13.507125008441953,
      12.289058368200838,
      13.507125008441953
    ],
    "volume": 1000000
  },
  {
    "ts": 1549670400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1549756800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1549843200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1549929600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550016000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550102400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550188800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550275200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550361600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550448000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550534400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.507125008441953,
      13.507125008441953,
      13.507125008441953,
      13.507125008441953
    ],
    "volume": 0
  },
  {
    "ts": 1550620800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 100100000,
    "trades": 2,
    "traded_amount": 100100000,
    "price": [
      13.507125008441953,
      13.507125008441953,
      11.07208,
      11.07208
    ],
    "volume": 1128075518
  },
  {
    "ts": 1550707200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.07208,
      11.07208,
      11.07208,
      11.07208
    ],
    "volume": 0
  },
  {
    "ts": 1550793600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 5,
    "payments_amount": 1475375,
    "trades": 5,
    "traded_amount": 1475375,
    "price": [
      11.07208,
      11.20377881051721,
      11.07208,
      11.163455312688383
    ],
    "volume": 16500000
  },
  {
    "ts": 1550880000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.163455312688383,
      11.163455312688383,
      11.163455312688383,
      11.163455312688383
    ],
    "volume": 0
  },
  {
    "ts": 1550966400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 4,
    "payments_amount": 20364710,
    "trades": 4,
    "traded_amount": 20364710,
    "price": [
      11.163455312688383,
      11.163455312688383,
      10.565564079617864,
      10.565564079617864
    ],
    "volume": 216043186
  },
  {
    "ts": 1551052800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 506197,
    "trades": 1,
    "traded_amount": 506197,
    "price": [
      10.565564079617864,
      11.853092768230551,
      10.565564079617864,
      11.853092768230551
    ],
    "volume": 6000000
  },
  {
    "ts": 1551139200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.853092768230551,
      11.853092768230551,
      11.853092768230551,
      11.853092768230551
    ],
    "volume": 0
  },
  {
    "ts": 1551225600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 145000000,
    "trades": 1,
    "traded_amount": 145000000,
    "price": [
      11.853092768230551,
      11.9525722,
      11.853092768230551,
      11.9525722
    ],
    "volume": 1733122969
  },
  {
    "ts": 1551312000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.9525722,
      11.9525722,
      11.9525722,
      11.9525722
    ],
    "volume": 0
  },
  {
    "ts": 1551398400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.9525722,
      11.9525722,
      11.9525722,
      11.9525722
    ],
    "volume": 0
  },
  {
    "ts": 1551484800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.9525722,
      11.9525722,
      11.9525722,
      11.9525722
    ],
    "volume": 0
  },
  {
    "ts": 1551571200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.9525722,
      11.9525722,
      11.9525722,
      11.9525722
    ],
    "volume": 0
  },
  {
    "ts": 1551657600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.9525722,
      11.9525722,
      11.9525722,
      11.9525722
    ],
    "volume": 0
  },
  {
    "ts": 1551744000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 1,
    "traded_amount": 200000,
    "price": [
      11.9525722,
      11.9525722,
      11.80085,
      11.80085
    ],
    "volume": 2360170
  },
  {
    "ts": 1551830400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.80085,
      11.80085,
      11.80085,
      11.80085
    ],
    "volume": 0
  },
  {
    "ts": 1551916800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.80085,
      11.80085,
      11.80085,
      11.80085
    ],
    "volume": 0
  },
  {
    "ts": 1552003200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.80085,
      11.80085,
      11.80085,
      11.80085
    ],
    "volume": 0
  },
  {
    "ts": 1552089600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.80085,
      11.80085,
      11.80085,
      11.80085
    ],
    "volume": 0
  },
  {
    "ts": 1552176000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.80085,
      11.80085,
      11.80085,
      11.80085
    ],
    "volume": 0
  },
  {
    "ts": 1552262400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 5000000,
    "trades": 1,
    "traded_amount": 5000000,
    "price": [
      11.80085,
      11.80085,
      9.7657434,
      9.7657434
    ],
    "volume": 48828717
  },
  {
    "ts": 1552348800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 4974384,
    "trades": 2,
    "traded_amount": 4974384,
    "price": [
      9.7657434,
      9.90665914573135,
      9.7657434,
      9.904483872796327
    ],
    "volume": 49279450
  },
  {
    "ts": 1552435200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.904483872796327,
      9.904483872796327,
      9.904483872796327,
      9.904483872796327
    ],
    "volume": 0
  },
  {
    "ts": 1552521600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 24166042,
    "trades": 2,
    "traded_amount": 24166042,
    "price": [
      9.904483872796327,
      9.904483872796327,
      9.116850413747938,
      9.116850413747938
    ],
    "volume": 221244978
  },
  {
    "ts": 1552608000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.116850413747938,
      9.116850413747938,
      9.116850413747938,
      9.116850413747938
    ],
    "volume": 0
  },
  {
    "ts": 1552694400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 4,
    "payments_amount": 1089508128,
    "trades": 4,
    "traded_amount": 1089508128,
    "price": [
      9.116850413747938,
      9.3815741,
      9.116850413747938,
      9.212132231751601
    ],
    "volume": 10204590599
  },
  {
    "ts": 1552780800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.212132231751601,
      9.212132231751601,
      9.212132231751601,
      9.212132231751601
    ],
    "volume": 0
  },
  {
    "ts": 1552867200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.212132231751601,
      9.212132231751601,
      9.212132231751601,
      9.212132231751601
    ],
    "volume": 0
  },
  {
    "ts": 1552953600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.212132231751601,
      9.212132231751601,
      9.212132231751601,
      9.212132231751601
    ],
    "volume": 0
  },
  {
    "ts": 1553040000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 150000000,
    "trades": 1,
    "traded_amount": 150000000,
    "price": [
      9.212132231751601,
      9.212132231751601,
      9.063971,
      9.063971
    ],
    "volume": 1359595650
  },
  {
    "ts": 1553126400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.063971,
      9.063971,
      9.063971,
      9.063971
    ],
    "volume": 0
  },
  {
    "ts": 1553212800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.063971,
      9.063971,
      9.063971,
      9.063971
    ],
    "volume": 0
  },
  {
    "ts": 1553299200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.063971,
      9.063971,
      9.063971,
      9.063971
    ],
    "volume": 0
  },
  {
    "ts": 1553385600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 13,
    "payments_amount": 65774614,
    "trades": 13,
    "traded_amount": 65774614,
    "price": [
      9.063971,
      9.508628328267477,
      9.063971,
      9.442692300428698
    ],
    "volume": 623333872
  },
  {
    "ts": 1553472000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.442692300428698,
      9.442692300428698,
      9.442692300428698,
      9.442692300428698
    ],
    "volume": 0
  },
  {
    "ts": 1553558400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.442692300428698,
      9.442692300428698,
      9.442692300428698,
      9.442692300428698
    ],
    "volume": 0
  },
  {
    "ts": 1553644800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 150000000,
    "trades": 1,
    "traded_amount": 150000000,
    "price": [
      9.442692300428698,
      9.4960377,
      9.442692300428698,
      9.4960377
    ],
    "volume": 1424405655
  },
  {
    "ts": 1553731200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.4960377,
      9.4960377,
      9.4960377,
      9.4960377
    ],
    "volume": 0
  },
  {
    "ts": 1553817600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      9.4960377,
      9.4960377,
      9.42827,
      9.42827
    ],
    "volume": 942827
  },
  {
    "ts": 1553904000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1553990400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554076800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554163200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554249600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554336000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554422400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554508800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554595200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42827,
      9.42827,
      9.42827,
      9.42827
    ],
    "volume": 0
  },
  {
    "ts": 1554681600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 3000000,
    "trades": 2,
    "traded_amount": 3000000,
    "price": [
      9.42827,
      9.42827,
      7.521283,
      7.521283
    ],
    "volume": 22593205
  },
  {
    "ts": 1554768000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1554854400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1554940800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1555027200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1555113600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1555200000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1555286400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.521283,
      7.521283,
      7.521283,
      7.521283
    ],
    "volume": 0
  },
  {
    "ts": 1555372800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 1454738339,
    "trades": 3,
    "traded_amount": 1454738339,
    "price": [
      7.521283,
      8.619783093019617,
      7.521283,
      8.517974528369786
    ],
    "volume": 12500000000
  },
  {
    "ts": 1555459200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555545600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555632000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555718400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555804800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555891200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1555977600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1556064000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1556150400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1556236800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1556323200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.517974528369786,
      8.517974528369786,
      8.517974528369786,
      8.517974528369786
    ],
    "volume": 0
  },
  {
    "ts": 1556409600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 27750990,
    "trades": 2,
    "traded_amount": 27750990,
    "price": [
      8.517974528369786,
      10.072392302158274,
      8.517974528369786,
      9.98512164112457
    ],
    "volume": 278310073
  },
  {
    "ts": 1556496000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.98512164112457,
      9.98512164112457,
      9.98512164112457,
      9.98512164112457
    ],
    "volume": 0
  },
  {
    "ts": 1556582400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.98512164112457,
      9.98512164112457,
      9.98512164112457,
      9.98512164112457
    ],
    "volume": 0
  },
  {
    "ts": 1556668800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.98512164112457,
      9.98512164112457,
      9.98512164112457,
      9.98512164112457
    ],
    "volume": 0
  },
  {
    "ts": 1556755200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 20000000,
    "trades": 1,
    "traded_amount": 20000000,
    "price": [
      9.98512164112457,
      9.98512164112457,
      9.9782952,
      9.9782952
    ],
    "volume": 199565904
  },
  {
    "ts": 1556841600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 3009574,
    "trades": 2,
    "traded_amount": 3009574,
    "price": [
      9.9782952,
      9.9782952,
      9.839136862131713,
      9.839136862131713
    ],
    "volume": 29714344
  },
  {
    "ts": 1556928000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.839136862131713,
      9.839136862131713,
      9.839136862131713,
      9.839136862131713
    ],
    "volume": 0
  },
  {
    "ts": 1557014400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.839136862131713,
      9.839136862131713,
      9.839136862131713,
      9.839136862131713
    ],
    "volume": 0
  },
  {
    "ts": 1557100800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.839136862131713,
      9.839136862131713,
      9.839136862131713,
      9.839136862131713
    ],
    "volume": 0
  },
  {
    "ts": 1557187200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.839136862131713,
      9.839136862131713,
      9.839136862131713,
      9.839136862131713
    ],
    "volume": 0
  },
  {
    "ts": 1557273600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 3618207,
    "trades": 1,
    "traded_amount": 3618207,
    "price": [
      9.839136862131713,
      10.81450315031727,
      9.839136862131713,
      10.81450315031727
    ],
    "volume": 39129111
  },
  {
    "ts": 1557360000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.81450315031727,
      10.81450315031727,
      10.81450315031727,
      10.81450315031727
    ],
    "volume": 0
  },
  {
    "ts": 1557446400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.81450315031727,
      10.81450315031727,
      10.81450315031727,
      10.81450315031727
    ],
    "volume": 0
  },
  {
    "ts": 1557532800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 8780000,
    "trades": 2,
    "traded_amount": 8780000,
    "price": [
      10.81450315031727,
      10.81450315031727,
      10.17034108433735,
      10.277572916666667
    ],
    "volume": 89347066
  },
  {
    "ts": 1557619200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 2870000,
    "trades": 2,
    "traded_amount": 2870000,
    "price": [
      10.277572916666667,
      10.277572916666667,
      9.662363218390805,
      9.662363218390805
    ],
    "volume": 28073564
  },
  {
    "ts": 1557705600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 6,
    "payments_amount": 1855642,
    "trades": 6,
    "traded_amount": 1855642,
    "price": [
      9.662363218390805,
      10.029131578947368,
      9.662363218390805,
      9.9122925
    ],
    "volume": 18462153
  },
  {
    "ts": 1557792000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 5500000,
    "trades": 3,
    "traded_amount": 5500000,
    "price": [
      9.9122925,
      9.9122925,
      8.932527619047619,
      8.932527619047619
    ],
    "volume": 50850462
  },
  {
    "ts": 1557878400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 2600000,
    "trades": 1,
    "traded_amount": 2600000,
    "price": [
      8.932527619047619,
      8.932527619047619,
      8.461285,
      8.461285
    ],
    "volume": 21999341
  },
  {
    "ts": 1557964800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 14100000,
    "trades": 2,
    "traded_amount": 14100000,
    "price": [
      8.461285,
      8.461285,
      6.563912727272728,
      6.563912727272728
    ],
    "volume": 93418603
  },
  {
    "ts": 1558051200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 98193165,
    "trades": 1,
    "traded_amount": 98193165,
    "price": [
      6.563912727272728,
      8.184190029927235,
      6.563912727272728,
      8.184190029927235
    ],
    "volume": 803631522
  },
  {
    "ts": 1558137600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.184190029927235,
      8.184190029927235,
      8.184190029927235,
      8.184190029927235
    ],
    "volume": 0
  },
  {
    "ts": 1558224000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.184190029927235,
      8.184190029927235,
      8.184190029927235,
      8.184190029927235
    ],
    "volume": 0
  },
  {
    "ts": 1558310400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 1095443213,
    "trades": 3,
    "traded_amount": 1095443213,
    "price": [
      8.184190029927235,
      8.184190029927235,
      7.2844523,
      7.291903617517836
    ],
    "volume": 7996448765
  },
  {
    "ts": 1558396800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 4472157,
    "trades": 1,
    "traded_amount": 4472157,
    "price": [
      7.291903617517836,
      7.378989601662017,
      7.291903617517836,
      7.378989601662017
    ],
    "volume": 33000000
  },
  {
    "ts": 1558483200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 33557046,
    "trades": 2,
    "traded_amount": 33557046,
    "price": [
      7.378989601662017,
      7.6095585985230265,
      7.378989601662017,
      7.6095585985230265
    ],
    "volume": 255093103
  },
  {
    "ts": 1558569600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.6095585985230265,
      7.6095585985230265,
      7.6095585985230265,
      7.6095585985230265
    ],
    "volume": 0
  },
  {
    "ts": 1558656000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 1,
    "traded_amount": 200000,
    "price": [
      7.6095585985230265,
      7.75482,
      7.6095585985230265,
      7.75482
    ],
    "volume": 1550964
  },
  {
    "ts": 1558742400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.75482,
      7.75482,
      7.75482,
      7.75482
    ],
    "volume": 0
  },
  {
    "ts": 1558828800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.75482,
      7.75482,
      7.75482,
      7.75482
    ],
    "volume": 0
  },
  {
    "ts": 1558915200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 4,
    "payments_amount": 144150364,
    "trades": 4,
    "traded_amount": 144150364,
    "price": [
      7.75482,
      7.75482,
      7.432402213394336,
      7.492657380952381
    ],
    "volume": 1074532176
  },
  {
    "ts": 1559001600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.492657380952381,
      7.492657380952381,
      7.492657380952381,
      7.492657380952381
    ],
    "volume": 0
  },
  {
    "ts": 1559088000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.492657380952381,
      7.492657380952381,
      7.492657380952381,
      7.492657380952381
    ],
    "volume": 0
  },
  {
    "ts": 1559174400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.492657380952381,
      7.492657380952381,
      7.492657380952381,
      7.492657380952381
    ],
    "volume": 0
  },
  {
    "ts": 1559260800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 229579660,
    "trades": 2,
    "traded_amount": 229579660,
    "price": [
      7.492657380952381,
      7.936258504273504,
      7.492657380952381,
      7.905513304978892
    ],
    "volume": 1818542245
  },
  {
    "ts": 1559347200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559433600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559520000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559606400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559692800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559779200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559865600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1559952000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560038400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560124800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560211200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560297600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560384000,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560470400,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560556800,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560643200,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560729600,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560816000,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560902400,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1560988800,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561075200,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561161600,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561248000,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561334400,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561420800,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561507200,
    "supply": 100000000000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561593600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561680000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561766400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561852800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.905513304978892,
      7.905513304978892,
      7.905513304978892,
      7.905513304978892
    ],
    "volume": 0
  },
  {
    "ts": 1561939200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 198126,
    "trades": 2,
    "traded_amount": 198126,
    "price": [
      7.905513304978892,
      9.08448,
      7.905513304978892,
      9.033253164298962
    ],
    "volume": 1794845
  },
  {
    "ts": 1562025600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.033253164298962,
      9.033253164298962,
      9.033253164298962,
      9.033253164298962
    ],
    "volume": 0
  },
  {
    "ts": 1562112000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.033253164298962,
      9.033253164298962,
      9.033253164298962,
      9.033253164298962
    ],
    "volume": 0
  },
  {
    "ts": 1562198400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.033253164298962,
      9.033253164298962,
      9.033253164298962,
      9.033253164298962
    ],
    "volume": 0
  },
  {
    "ts": 1562284800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 9,
    "payments_amount": 573425605,
    "trades": 9,
    "traded_amount": 573425605,
    "price": [
      9.033253164298962,
      9.9882219375,
      9.033253164298962,
      9.775400348830603
    ],
    "volume": 5659619022
  },
  {
    "ts": 1562371200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 4,
    "payments_amount": 10140496,
    "trades": 4,
    "traded_amount": 10140496,
    "price": [
      9.775400348830603,
      9.775400348830603,
      9.58814121527737,
      9.58814121527737
    ],
    "volume": 97436366
  },
  {
    "ts": 1562457600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 212166,
    "trades": 1,
    "traded_amount": 212166,
    "price": [
      9.58814121527737,
      9.58814121527737,
      9.42658107331052,
      9.42658107331052
    ],
    "volume": 2000000
  },
  {
    "ts": 1562544000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42658107331052,
      9.42658107331052,
      9.42658107331052,
      9.42658107331052
    ],
    "volume": 0
  },
  {
    "ts": 1562630400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42658107331052,
      9.42658107331052,
      9.42658107331052,
      9.42658107331052
    ],
    "volume": 0
  },
  {
    "ts": 1562716800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9.42658107331052,
      9.42658107331052,
      9.42658107331052,
      9.42658107331052
    ],
    "volume": 0
  },
  {
    "ts": 1562803200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 61833768,
    "trades": 2,
    "traded_amount": 61833768,
    "price": [
      9.42658107331052,
      11.28559889274642,
      9.42658107331052,
      11.28559889274642
    ],
    "volume": 696514395
  },
  {
    "ts": 1562889600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1562976000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563062400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563148800,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563235200,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563321600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563408000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563494400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563580800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563667200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563753600,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.28559889274642,
      11.28559889274642,
      11.28559889274642,
      11.28559889274642
    ],
    "volume": 0
  },
  {
    "ts": 1563840000,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 4356729,
    "trades": 1,
    "traded_amount": 4356729,
    "price": [
      11.28559889274642,
      11.40196073705755,
      11.28559889274642,
      11.40196073705755
    ],
    "volume": 49675253
  },
  {
    "ts": 1563926400,
    "supply": 100000000000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.40196073705755,
      11.40196073705755,
      11.40196073705755,
      11.40196073705755
    ],
    "volume": 0
  },
  {
    "ts": 1564012800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 167000,
    "trades": 2,
    "traded_amount": 167000,
    "price": [
      11.40196073705755,
      11.62879104477612,
      11.40196073705755,
      11.62879104477612
    ],
    "volume": 1941737
  },
  {
    "ts": 1564099200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.62879104477612,
      11.62879104477612,
      11.62879104477612,
      11.62879104477612
    ],
    "volume": 0
  },
  {
    "ts": 1564185600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      11.62879104477612,
      11.62879104477612,
      11.3392,
      11.3392
    ],
    "volume": 1133920
  },
  {
    "ts": 1564272000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.3392,
      11.3392,
      11.3392,
      11.3392
    ],
    "volume": 0
  },
  {
    "ts": 1564358400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.3392,
      11.3392,
      11.3392,
      11.3392
    ],
    "volume": 0
  },
  {
    "ts": 1564444800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.3392,
      11.3392,
      11.3392,
      11.3392
    ],
    "volume": 0
  },
  {
    "ts": 1564531200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 951685,
    "trades": 3,
    "traded_amount": 951685,
    "price": [
      11.3392,
      11.93429,
      11.3392,
      11.93429
    ],
    "volume": 11269775
  },
  {
    "ts": 1564617600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.93429,
      11.93429,
      11.93429,
      11.93429
    ],
    "volume": 0
  },
  {
    "ts": 1564704000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 2,
    "payments_amount": 200000,
    "trades": 2,
    "traded_amount": 200000,
    "price": [
      11.93429,
      12.17505,
      11.93429,
      12.07926
    ],
    "volume": 2425431
  },
  {
    "ts": 1564790400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      12.07926,
      12.07926,
      12.07926,
      12.07926
    ],
    "volume": 0
  },
  {
    "ts": 1564876800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      12.07926,
      12.27404,
      12.07926,
      12.27404
    ],
    "volume": 1227404
  },
  {
    "ts": 1564963200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 3,
    "payments_amount": 500000,
    "trades": 3,
    "traded_amount": 500000,
    "price": [
      12.27404,
      12.27404,
      12.0821,
      12.0821
    ],
    "volume": 6094921
  },
  {
    "ts": 1565049600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 1,
    "traded_amount": 200000,
    "price": [
      12.0821,
      12.55103,
      12.0821,
      12.55103
    ],
    "volume": 2510206
  },
  {
    "ts": 1565136000,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 2000000000,
    "price": [
      12.55103,
      12.55103,
      0.0179906,
      0.0179906
    ],
    "volume": 17990600
  },
  {
    "ts": 1565222400,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 1450000000,
    "trades": 1,
    "traded_amount": 1450000000,
    "price": [
      0.0179906,
      13.0459434,
      0.0179906,
      13.0459434
    ],
    "volume": 18916617930
  },
  {
    "ts": 1565308800,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10000000,
    "price": [
      13.0459434,
      13.1517341,
      13.0459434,
      13.1517341
    ],
    "volume": 131517341
  },
  {
    "ts": 1565395200,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 1980000000,
    "price": [
      13.1517341,
      13.842631406440615,
      13.1517341,
      13.741869963349236
    ],
    "volume": 13624033400
  },
  {
    "ts": 1565481600,
    "supply": 100000000000,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1565568000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1565654400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1565740800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1565827200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1565913600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566000000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566086400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566172800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566259200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566345600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566432000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566518400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566604800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566691200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566777600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.741869963349236,
      13.741869963349236,
      13.741869963349236,
      13.741869963349236
    ],
    "volume": 0
  },
  {
    "ts": 1566864000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      13.741869963349236,
      14.84277,
      13.741869963349236,
      14.84277
    ],
    "volume": 1484277
  },
  {
    "ts": 1566950400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.84277,
      14.84277,
      14.84277,
      14.84277
    ],
    "volume": 0
  },
  {
    "ts": 1567036800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.84277,
      14.84277,
      14.84277,
      14.84277
    ],
    "volume": 0
  },
  {
    "ts": 1567123200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.84277,
      14.84277,
      14.84277,
      14.84277
    ],
    "volume": 0
  },
  {
    "ts": 1567209600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 74514349,
    "price": [
      14.84277,
      16.104280707062205,
      14.84277,
      16.104280707062205
    ],
    "volume": 1199999993
  },
  {
    "ts": 1567296000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 30000000,
    "price": [
      16.104280707062205,
      16.104280707062205,
      16.054920666666668,
      16.054920666666668
    ],
    "volume": 481647620
  },
  {
    "ts": 1567382400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      16.054920666666668,
      16.054920666666668,
      16.054920666666668,
      16.054920666666668
    ],
    "volume": 0
  },
  {
    "ts": 1567468800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 44514349,
    "price": [
      16.054920666666668,
      16.054920666666668,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 697086631
  },
  {
    "ts": 1567555200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1567641600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1567728000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1567814400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1567900800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1567987200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568073600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568160000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568246400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568332800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568419200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568505600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568592000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568678400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568764800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568851200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1568937600,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569024000,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569110400,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569196800,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569283200,
    "supply": 100000000000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569369600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569456000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569542400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569628800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569715200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569801600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569888000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1569974400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1570060800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1570147200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1570233600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      15.659818612645553,
      15.659818612645553,
      15.659818612645553,
      15.659818612645553
    ],
    "volume": 0
  },
  {
    "ts": 1570320000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 207500000,
    "trades": 1,
    "traded_amount": 207500000,
    "price": [
      15.659818612645553,
      17.07401420240964,
      15.659818612645553,
      17.07401420240964
    ],
    "volume": 3542857947
  },
  {
    "ts": 1570406400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570492800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570579200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570665600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570752000,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570838400,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1570924800,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571011200,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571097600,
    "supply": 100000000000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 3
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571184000,
    "supply": 1929956248,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 2
    },
    "payments": 1,
    "payments_amount": 98070043752,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571270400,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571356800,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571443200,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571529600,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571616000,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571702400,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571788800,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571875200,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1571961600,
    "supply": 1929956248,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572048000,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572134400,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572220800,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572307200,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572393600,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572480000,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572566400,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572652800,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1572825600,
    "supply": 1929956248,
    "trustlines": {
      "total": 17,
      "authorized": 17,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1574899200,
    "supply": 1929956248,
    "trustlines": {
      "total": 16,
      "authorized": 16,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1575590400,
    "supply": 1929956248,
    "trustlines": {
      "total": 15,
      "authorized": 15,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1577318400,
    "supply": 1929956248,
    "trustlines": {
      "total": 14,
      "authorized": 14,
      "funded": 2
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1585872000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 16,
      "authorized": 16,
      "funded": 4
    },
    "payments": 2,
    "payments_amount": 1000013000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586304000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586390400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 20,
      "authorized": 20,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586476800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586649600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 23,
      "authorized": 23,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586822400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586908800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 25,
      "authorized": 25,
      "funded": 4
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1586995200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 37,
      "authorized": 37,
      "funded": 9
    },
    "payments": 5,
    "payments_amount": 50000000,
    "trades": 38,
    "traded_amount": 807154,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1587081600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 42,
      "authorized": 42,
      "funded": 11
    },
    "payments": 7,
    "payments_amount": 523580181560,
    "trades": 219,
    "traded_amount": 1348529398,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1587168000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 45,
      "authorized": 45,
      "funded": 11
    },
    "payments": 3,
    "payments_amount": 6670687402,
    "trades": 91,
    "traded_amount": 19802253850,
    "price": [
      17.07401420240964,
      17.07401420240964,
      17.07401420240964,
      17.07401420240964
    ],
    "volume": 0
  },
  {
    "ts": 1587254400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 56,
      "authorized": 56,
      "funded": 18
    },
    "payments": 21,
    "payments_amount": 350537012090,
    "trades": 459,
    "traded_amount": 1501339554,
    "price": [
      17.07401420240964,
      24.426318651441,
      17.07401420240964,
      20.94560358413702
    ],
    "volume": 1042893796
  },
  {
    "ts": 1587340800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 59,
      "authorized": 59,
      "funded": 17
    },
    "payments": 6,
    "payments_amount": 41263902288,
    "trades": 516,
    "traded_amount": 5975908249,
    "price": [
      20.94560358413702,
      21.112587753876188,
      20.03995100714726,
      20.03995100714726
    ],
    "volume": 759733529
  },
  {
    "ts": 1587427200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 63,
      "authorized": 63,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 79,
    "traded_amount": 539857570,
    "price": [
      20.03995100714726,
      23.31117266851339,
      19.271664015733403,
      19.362675941169417
    ],
    "volume": 130612027
  },
  {
    "ts": 1587513600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 17
    },
    "payments": 3,
    "payments_amount": 200000000000,
    "trades": 89,
    "traded_amount": 940528435,
    "price": [
      19.362675941169417,
      23.00607111882047,
      18.893800207648546,
      19.155323726872886
    ],
    "volume": 6681720312
  },
  {
    "ts": 1587600000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 70,
      "authorized": 70,
      "funded": 19
    },
    "payments": 18,
    "payments_amount": 23004633850,
    "trades": 1539,
    "traded_amount": 90384285437,
    "price": [
      19.155323726872886,
      19.155323726872886,
      15.506842066987984,
      16.163082141642008
    ],
    "volume": 525468964107
  },
  {
    "ts": 1587686400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 73,
      "authorized": 73,
      "funded": 19
    },
    "payments": 4,
    "payments_amount": 2040178901,
    "trades": 421,
    "traded_amount": 16667777217,
    "price": [
      16.163082141642008,
      16.234023868974564,
      15.455188028304326,
      16.234023868974564
    ],
    "volume": 110550367528
  },
  {
    "ts": 1587772800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 23
    },
    "payments": 2,
    "payments_amount": 437815618,
    "trades": 257,
    "traded_amount": 7023829298,
    "price": [
      16.234023868974564,
      16.256697525174268,
      15.999836124989153,
      16.21155117000751
    ],
    "volume": 49534858408
  },
  {
    "ts": 1587859200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 102,
      "authorized": 102,
      "funded": 24
    },
    "payments": 9,
    "payments_amount": 2994716050,
    "trades": 244,
    "traded_amount": 11145493561,
    "price": [
      16.21155117000751,
      16.344046920314664,
      16.071890878979232,
      16.23552946577903
    ],
    "volume": 53910594988
  },
  {
    "ts": 1587945600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 99,
      "authorized": 99,
      "funded": 25
    },
    "payments": 5,
    "payments_amount": 2486900000,
    "trades": 590,
    "traded_amount": 18556841486,
    "price": [
      16.23552946577903,
      16.33311015900411,
      15.007851755450243,
      15.382963047152039
    ],
    "volume": 144337404255
  },
  {
    "ts": 1588032000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 107,
      "authorized": 107,
      "funded": 32
    },
    "payments": 13,
    "payments_amount": 26211104270,
    "trades": 882,
    "traded_amount": 73539875040,
    "price": [
      15.382963047152039,
      15.456755076094929,
      14.203054793425139,
      14.7079103585891
    ],
    "volume": 527928197537
  },
  {
    "ts": 1588118400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 113,
      "authorized": 113,
      "funded": 32
    },
    "payments": 8,
    "payments_amount": 38680909127,
    "trades": 1187,
    "traded_amount": 102624707800,
    "price": [
      14.7079103585891,
      14.763255459153893,
      13.596119648875327,
      13.983774850122902
    ],
    "volume": 544477885620
  },
  {
    "ts": 1588204800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 119,
      "authorized": 119,
      "funded": 33
    },
    "payments": 11,
    "payments_amount": 3406749564,
    "trades": 1278,
    "traded_amount": 83347842697,
    "price": [
      13.983774850122902,
      15.212546341631432,
      13.599997207912775,
      14.812705364679402
    ],
    "volume": 516129492760
  },
  {
    "ts": 1588291200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 116,
      "authorized": 116,
      "funded": 34
    },
    "payments": 1,
    "payments_amount": 39600000,
    "trades": 660,
    "traded_amount": 30952036049,
    "price": [
      14.812705364679402,
      14.812705364679402,
      13.764486774000705,
      13.93686336069789
    ],
    "volume": 183704383881
  },
  {
    "ts": 1588377600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 119,
      "authorized": 119,
      "funded": 35
    },
    "payments": 4,
    "payments_amount": 1670443430,
    "trades": 2603,
    "traded_amount": 87640343132,
    "price": [
      13.93686336069789,
      14.071596045594658,
      13.11951028848012,
      13.372335165519747
    ],
    "volume": 115741230402
  },
  {
    "ts": 1588464000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 120,
      "authorized": 120,
      "funded": 35
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 778,
    "traded_amount": 36052843364,
    "price": [
      13.372335165519747,
      13.990943679024433,
      13.26604711930752,
      13.726956352902667
    ],
    "volume": 144454696717
  },
  {
    "ts": 1588550400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 120,
      "authorized": 120,
      "funded": 35
    },
    "payments": 2,
    "payments_amount": 16073950131,
    "trades": 1079,
    "traded_amount": 68453374373,
    "price": [
      13.726956352902667,
      14.603749128296716,
      13.61249537633028,
      13.685444902769593
    ],
    "volume": 295567594366
  },
  {
    "ts": 1588636800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 124,
      "authorized": 124,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1256,
    "traded_amount": 43506849839,
    "price": [
      13.685444902769593,
      14.277612101433906,
      13.623489272028248,
      14.00463024171274
    ],
    "volume": 73197251924
  },
  {
    "ts": 1588723200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 124,
      "authorized": 124,
      "funded": 36
    },
    "payments": 3,
    "payments_amount": 200000000,
    "trades": 400,
    "traded_amount": 20881095420,
    "price": [
      14.00463024171274,
      14.21544205869047,
      13.810076019064397,
      14.158919323121628
    ],
    "volume": 110845299941
  },
  {
    "ts": 1588809600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 132,
      "authorized": 132,
      "funded": 39
    },
    "payments": 9,
    "payments_amount": 3702535701,
    "trades": 754,
    "traded_amount": 44678981263,
    "price": [
      14.158919323121628,
      14.300848428526018,
      13.845534427281757,
      14.055728071935398
    ],
    "volume": 239492302610
  },
  {
    "ts": 1588896000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 139,
      "authorized": 139,
      "funded": 40
    },
    "payments": 12,
    "payments_amount": 9644348593,
    "trades": 623,
    "traded_amount": 32211366011,
    "price": [
      14.055728071935398,
      14.28680800942285,
      13.457379499247784,
      13.856279798772201
    ],
    "volume": 190150865620
  },
  {
    "ts": 1588982400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 141,
      "authorized": 141,
      "funded": 39
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 619,
    "traded_amount": 19873274169,
    "price": [
      13.856279798772201,
      14.032893706841458,
      13.678910074971059,
      14.012105558477554
    ],
    "volume": 96855756344
  },
  {
    "ts": 1589068800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 143,
      "authorized": 143,
      "funded": 39
    },
    "payments": 20,
    "payments_amount": 6259948532,
    "trades": 795,
    "traded_amount": 70625409017,
    "price": [
      14.012105558477554,
      16.160892335313054,
      14.012105558477554,
      15.713523660651388
    ],
    "volume": 453040753650
  },
  {
    "ts": 1589155200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 147,
      "authorized": 147,
      "funded": 44
    },
    "payments": 7,
    "payments_amount": 3858390552,
    "trades": 1009,
    "traded_amount": 56565480275,
    "price": [
      15.713523660651388,
      16.61696379519778,
      13.956193475861092,
      15.838128498993864
    ],
    "volume": 390387313191
  },
  {
    "ts": 1589241600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 150,
      "authorized": 150,
      "funded": 47
    },
    "payments": 9,
    "payments_amount": 18367421390,
    "trades": 1355,
    "traded_amount": 59369990594,
    "price": [
      15.838128498993864,
      15.898116226874208,
      14.448765365290084,
      14.460816238646
    ],
    "volume": 194232811712
  },
  {
    "ts": 1589328000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 159,
      "authorized": 159,
      "funded": 50
    },
    "payments": 9,
    "payments_amount": 6328900155,
    "trades": 512,
    "traded_amount": 30639490086,
    "price": [
      14.460816238646,
      14.671198162950622,
      14.353680855064825,
      14.41722345682914
    ],
    "volume": 188148868851
  },
  {
    "ts": 1589414400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 160,
      "authorized": 160,
      "funded": 55
    },
    "payments": 22,
    "payments_amount": 16355881174,
    "trades": 1443,
    "traded_amount": 33762542191,
    "price": [
      14.41722345682914,
      14.685194640950735,
      14.359993201722938,
      14.502645601083543
    ],
    "volume": 124329168833
  },
  {
    "ts": 1589500800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 58
    },
    "payments": 6,
    "payments_amount": 1081899156,
    "trades": 1929,
    "traded_amount": 93223056712,
    "price": [
      14.502645601083543,
      14.968241397897724,
      14.335304047847035,
      14.876259038664267
    ],
    "volume": 257790765776
  },
  {
    "ts": 1589587200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 170,
      "authorized": 170,
      "funded": 56
    },
    "payments": 1,
    "payments_amount": 953563650,
    "trades": 855,
    "traded_amount": 55572723380,
    "price": [
      14.876259038664267,
      14.946707001384624,
      14.744156810364766,
      14.811566963842067
    ],
    "volume": 50745201781
  },
  {
    "ts": 1589673600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 176,
      "authorized": 176,
      "funded": 58
    },
    "payments": 20,
    "payments_amount": 31435957096,
    "trades": 1837,
    "traded_amount": 144703241362,
    "price": [
      14.811566963842067,
      14.874387421421211,
      14.120068526766072,
      14.436481123786509
    ],
    "volume": 301911467007
  },
  {
    "ts": 1589760000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 179,
      "authorized": 179,
      "funded": 57
    },
    "payments": 4,
    "payments_amount": 4279965060,
    "trades": 1339,
    "traded_amount": 27996632518,
    "price": [
      14.436481123786509,
      14.575175834204876,
      14.181581185096393,
      14.406906906906906
    ],
    "volume": 160545916803
  },
  {
    "ts": 1589846400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 179,
      "authorized": 179,
      "funded": 55
    },
    "payments": 6,
    "payments_amount": 14786593770,
    "trades": 660,
    "traded_amount": 13636069749,
    "price": [
      14.406906906906906,
      14.60271354210082,
      14.176157718224268,
      14.456679205960098
    ],
    "volume": 80927705470
  },
  {
    "ts": 1589932800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 183,
      "authorized": 183,
      "funded": 57
    },
    "payments": 2,
    "payments_amount": 24082999,
    "trades": 1208,
    "traded_amount": 69797230628,
    "price": [
      14.456679205960098,
      14.603842202616615,
      14.06153718390566,
      14.139630683516833
    ],
    "volume": 141714554975
  },
  {
    "ts": 1590019200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 186,
      "authorized": 186,
      "funded": 55
    },
    "payments": 9,
    "payments_amount": 16757689731,
    "trades": 1383,
    "traded_amount": 42157977482,
    "price": [
      14.139630683516833,
      15.433983430024984,
      14.07036497004905,
      15.035474390724158
    ],
    "volume": 228263342086
  },
  {
    "ts": 1590105600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 190,
      "authorized": 190,
      "funded": 57
    },
    "payments": 5,
    "payments_amount": 30011939698,
    "trades": 1422,
    "traded_amount": 37074502350,
    "price": [
      15.035474390724158,
      15.133333307784802,
      14.479887002811047,
      14.650084737314325
    ],
    "volume": 211610659590
  },
  {
    "ts": 1590192000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 191,
      "authorized": 191,
      "funded": 57
    },
    "payments": 1,
    "payments_amount": 3000000000,
    "trades": 1114,
    "traded_amount": 45009627843,
    "price": [
      14.650084737314325,
      14.993457426328435,
      14.577777777777778,
      14.781818181818181
    ],
    "volume": 113325345883
  },
  {
    "ts": 1590278400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 192,
      "authorized": 192,
      "funded": 55
    },
    "payments": 2,
    "payments_amount": 117481272,
    "trades": 868,
    "traded_amount": 41384397279,
    "price": [
      14.781818181818181,
      15.695700763481202,
      14.720448755971704,
      15.508132471095434
    ],
    "volume": 125763994458
  },
  {
    "ts": 1590364800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 194,
      "authorized": 194,
      "funded": 55
    },
    "payments": 4,
    "payments_amount": 352047930,
    "trades": 562,
    "traded_amount": 23464747743,
    "price": [
      15.508132471095434,
      15.571376761962707,
      15.191126626210144,
      15.191126626210144
    ],
    "volume": 60450671229
  },
  {
    "ts": 1590451200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 195,
      "authorized": 195,
      "funded": 57
    },
    "payments": 9,
    "payments_amount": 4586120607,
    "trades": 748,
    "traded_amount": 17222049739,
    "price": [
      15.191126626210144,
      15.557733757690174,
      15.172727272727272,
      15.454714795211377
    ],
    "volume": 67476623693
  },
  {
    "ts": 1590537600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 196,
      "authorized": 196,
      "funded": 58
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1059,
    "traded_amount": 22135488403,
    "price": [
      15.454714795211377,
      15.454890625570162,
      14.927272727272728,
      15.110483050110718
    ],
    "volume": 109429758630
  },
  {
    "ts": 1590624000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 197,
      "authorized": 197,
      "funded": 59
    },
    "payments": 1,
    "payments_amount": 4000000000,
    "trades": 876,
    "traded_amount": 18614867699,
    "price": [
      15.110483050110718,
      15.144859813084112,
      14.444697584408246,
      14.531492693211643
    ],
    "volume": 75708774960
  },
  {
    "ts": 1590710400,
    "supply": 1001929956248,
    "trustlines": {
      "total": 203,
      "authorized": 203,
      "funded": 60
    },
    "payments": 13,
    "payments_amount": 2589651315,
    "trades": 540,
    "traded_amount": 10030567587,
    "price": [
      14.531492693211643,
      14.962442823749727,
      14.409090909090908,
      14.871315364563882
    ],
    "volume": 46052447961
  },
  {
    "ts": 1590796800,
    "supply": 1001929956248,
    "trustlines": {
      "total": 203,
      "authorized": 203,
      "funded": 61
    },
    "payments": 25,
    "payments_amount": 9631849151,
    "trades": 1151,
    "traded_amount": 55187078584,
    "price": [
      14.871315364563882,
      15.029079726897903,
      13.836363636363636,
      13.908399282326597
    ],
    "volume": 329024596963
  },
  {
    "ts": 1590883200,
    "supply": 1001929956248,
    "trustlines": {
      "total": 206,
      "authorized": 206,
      "funded": 62
    },
    "payments": 14,
    "payments_amount": 29190635397,
    "trades": 1377,
    "traded_amount": 68094921537,
    "price": [
      13.908399282326597,
      14.437263067418488,
      13.203109761321143,
      14.254515484928419
    ],
    "volume": 480764813047
  },
  {
    "ts": 1590969600,
    "supply": 1001929956248,
    "trustlines": {
      "total": 207,
      "authorized": 207,
      "funded": 62
    },
    "payments": 6,
    "payments_amount": 9075910782,
    "trades": 1176,
    "traded_amount": 47803902440,
    "price": [
      14.254515484928419,
      14.254515484928419,
      13.096183106203027,
      13.096183269020687
    ],
    "volume": 186766564720
  },
  {
    "ts": 1591056000,
    "supply": 1001929956248,
    "trustlines": {
      "total": 211,
      "authorized": 211,
      "funded": 65
    },
    "payments": 20,
    "payments_amount": 60487067145,
    "trades": 2130,
    "traded_amount": 184361099498,
    "price": [
      13.096183269020687,
      13.188985575646736,
      11.948287233813007,
      12.542438353264895
    ],
    "volume": 896395025518
  },
  {
    "ts": 1591142400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 215,
      "authorized": 215,
      "funded": 63
    },
    "payments": 5,
    "payments_amount": 1484220042,
    "trades": 854,
    "traded_amount": 20681866359,
    "price": [
      12.542438353264895,
      12.928742751497714,
      12.174074074074074,
      12.251866554475823
    ],
    "volume": 122754636169
  },
  {
    "ts": 1591228800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 214,
      "authorized": 214,
      "funded": 64
    },
    "payments": 7,
    "payments_amount": 3215460658,
    "trades": 1032,
    "traded_amount": 46014614449,
    "price": [
      12.251866554475823,
      12.47533309615372,
      11.65350261138053,
      12.272533426069131
    ],
    "volume": 267692155522
  },
  {
    "ts": 1591315200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 213,
      "authorized": 213,
      "funded": 63
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 725,
    "traded_amount": 17669253570,
    "price": [
      12.272533426069131,
      12.654742218214073,
      12.217650789989621,
      12.51932592981096
    ],
    "volume": 82928328295
  },
  {
    "ts": 1591401600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 217,
      "authorized": 217,
      "funded": 64
    },
    "payments": 1,
    "payments_amount": 260000000,
    "trades": 232,
    "traded_amount": 5369994300,
    "price": [
      12.51932592981096,
      12.636210003146834,
      12.411143490008797,
      12.452677176950898
    ],
    "volume": 25962255930
  },
  {
    "ts": 1591488000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 219,
      "authorized": 219,
      "funded": 65
    },
    "payments": 4,
    "payments_amount": 515000,
    "trades": 883,
    "traded_amount": 28453290790,
    "price": [
      12.452677176950898,
      13.063636363636364,
      12.420192309984813,
      12.762770158109964
    ],
    "volume": 132665427992
  },
  {
    "ts": 1591574400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 220,
      "authorized": 220,
      "funded": 66
    },
    "payments": 3,
    "payments_amount": 600000000,
    "trades": 479,
    "traded_amount": 4764849736,
    "price": [
      12.762770158109964,
      12.77087539659648,
      12.564193797070509,
      12.632969100513785
    ],
    "volume": 23722295073
  },
  {
    "ts": 1591660800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 219,
      "authorized": 219,
      "funded": 66
    },
    "payments": 3,
    "payments_amount": 1600000,
    "trades": 687,
    "traded_amount": 12377216167,
    "price": [
      12.632969100513785,
      12.8694174834393,
      12.54188659402685,
      12.790536154744904
    ],
    "volume": 58189409944
  },
  {
    "ts": 1591747200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 222,
      "authorized": 222,
      "funded": 64
    },
    "payments": 1,
    "payments_amount": 16799859566,
    "trades": 326,
    "traded_amount": 18784273194,
    "price": [
      12.790536154744904,
      12.88575140753573,
      12.616696422812513,
      12.696056016004572
    ],
    "volume": 67348652583
  },
  {
    "ts": 1591833600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 226,
      "authorized": 226,
      "funded": 67
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 1351,
    "traded_amount": 74922802577,
    "price": [
      12.696056016004572,
      14.78200839277611,
      12.67743979721166,
      14.030341877961364
    ],
    "volume": 400656099558
  },
  {
    "ts": 1591920000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 228,
      "authorized": 228,
      "funded": 66
    },
    "payments": 2,
    "payments_amount": 747000000,
    "trades": 568,
    "traded_amount": 11180540141,
    "price": [
      14.030341877961364,
      14.054545454545455,
      13.564558579682874,
      13.776593875183144
    ],
    "volume": 53375618274
  },
  {
    "ts": 1592006400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 229,
      "authorized": 229,
      "funded": 64
    },
    "payments": 4,
    "payments_amount": 10166017750,
    "trades": 606,
    "traded_amount": 11658997753,
    "price": [
      13.776593875183144,
      13.86728493763991,
      13.521147446911945,
      13.578387387594827
    ],
    "volume": 40492180447
  },
  {
    "ts": 1592092800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 240,
      "authorized": 240,
      "funded": 68
    },
    "payments": 21,
    "payments_amount": 12215796883,
    "trades": 330,
    "traded_amount": 5511113495,
    "price": [
      13.578387387594827,
      14.04061636990191,
      13.578386884514963,
      14.01274167012721
    ],
    "volume": 37693006372
  },
  {
    "ts": 1592179200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 68
    },
    "payments": 3,
    "payments_amount": 3860161578,
    "trades": 964,
    "traded_amount": 45662287502,
    "price": [
      14.01274167012721,
      15.184390270161442,
      14.012740298135531,
      14.143859367225899
    ],
    "volume": 327121610492
  },
  {
    "ts": 1592265600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 235,
      "authorized": 235,
      "funded": 67
    },
    "payments": 3,
    "payments_amount": 38398314,
    "trades": 846,
    "traded_amount": 11481016919,
    "price": [
      14.143859367225899,
      14.51777975620438,
      13.987373347068342,
      14.142384269832666
    ],
    "volume": 82357897281
  },
  {
    "ts": 1592352000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 237,
      "authorized": 237,
      "funded": 68
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 640,
    "traded_amount": 11217947021,
    "price": [
      14.142384269832666,
      14.211832673585107,
      13.579804949433896,
      14.072727272727272
    ],
    "volume": 71195085637
  },
  {
    "ts": 1592438400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 239,
      "authorized": 239,
      "funded": 69
    },
    "payments": 2,
    "payments_amount": 4038700000,
    "trades": 167,
    "traded_amount": 7193805083,
    "price": [
      14.072727272727272,
      14.244308265882683,
      14.00909090909091,
      14.159277000125938
    ],
    "volume": 46847208394
  },
  {
    "ts": 1592524800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 242,
      "authorized": 242,
      "funded": 68
    },
    "payments": 3,
    "payments_amount": 4040383718,
    "trades": 275,
    "traded_amount": 9558311366,
    "price": [
      14.159277000125938,
      14.486902107806007,
      14.131047117414706,
      14.300881998891912
    ],
    "volume": 59444030432
  },
  {
    "ts": 1592611200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 245,
      "authorized": 245,
      "funded": 69
    },
    "payments": 6,
    "payments_amount": 706174995,
    "trades": 168,
    "traded_amount": 1464011099,
    "price": [
      14.300881998891912,
      14.425547858612644,
      14.244006227296316,
      14.368018145484331
    ],
    "volume": 6455375247
  },
  {
    "ts": 1592697600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 246,
      "authorized": 246,
      "funded": 67
    },
    "payments": 11,
    "payments_amount": 2400039591,
    "trades": 70,
    "traded_amount": 2699548192,
    "price": [
      14.368018145484331,
      14.452362208317396,
      14.309090909090909,
      14.40148458732546
    ],
    "volume": 14073992389
  },
  {
    "ts": 1592784000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 249,
      "authorized": 249,
      "funded": 67
    },
    "payments": 15,
    "payments_amount": 87305596,
    "trades": 597,
    "traded_amount": 37485418731,
    "price": [
      14.40148458732546,
      14.488032057886695,
      13.740889951613866,
      13.77826855123675
    ],
    "volume": 110742756892
  },
  {
    "ts": 1592870400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 250,
      "authorized": 250,
      "funded": 67
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 656,
    "traded_amount": 7156332884,
    "price": [
      13.77826855123675,
      14.083713593600361,
      13.77826855123675,
      13.928235275221253
    ],
    "volume": 53593504253
  },
  {
    "ts": 1592956800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 253,
      "authorized": 253,
      "funded": 67
    },
    "payments": 4,
    "payments_amount": 2005448524,
    "trades": 1193,
    "traded_amount": 29919380303,
    "price": [
      13.928235275221253,
      14.648878272811569,
      13.881818181818181,
      14.54492127199753
    ],
    "volume": 163085359684
  },
  {
    "ts": 1593043200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 255,
      "authorized": 255,
      "funded": 67
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 821,
    "traded_amount": 10566199851,
    "price": [
      14.54492127199753,
      14.954197399360893,
      14.517807661160198,
      14.754785975478597
    ],
    "volume": 46141883952
  },
  {
    "ts": 1593129600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 254,
      "authorized": 254,
      "funded": 66
    },
    "payments": 1,
    "payments_amount": 4000000000,
    "trades": 443,
    "traded_amount": 26344917370,
    "price": [
      14.754785975478597,
      15.065978393809647,
      14.710753744871923,
      14.949182452967896
    ],
    "volume": 180926392016
  },
  {
    "ts": 1593216000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 254,
      "authorized": 254,
      "funded": 66
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 642,
    "traded_amount": 31716981523,
    "price": [
      14.949182452967896,
      16.580076574395985,
      14.94911552740773,
      16.15904187704934
    ],
    "volume": 238190213273
  },
  {
    "ts": 1593302400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 255,
      "authorized": 255,
      "funded": 67
    },
    "payments": 6,
    "payments_amount": 16033738712,
    "trades": 363,
    "traded_amount": 9476946062,
    "price": [
      16.15904187704934,
      16.19129654026451,
      15.545285141604053,
      15.693402288557214
    ],
    "volume": 42525528183
  },
  {
    "ts": 1593388800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 256,
      "authorized": 256,
      "funded": 68
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 549,
    "traded_amount": 9464334600,
    "price": [
      15.693402288557214,
      15.799910680532077,
      15.400389663443912,
      15.452251417988194
    ],
    "volume": 63580141369
  },
  {
    "ts": 1593475200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 260,
      "authorized": 260,
      "funded": 69
    },
    "payments": 14,
    "payments_amount": 16197778286,
    "trades": 811,
    "traded_amount": 18173247601,
    "price": [
      15.452251417988194,
      15.566004342782355,
      14.777631139133433,
      14.896247635220687
    ],
    "volume": 117827819883
  },
  {
    "ts": 1593561600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 261,
      "authorized": 261,
      "funded": 69
    },
    "payments": 2,
    "payments_amount": 15200000000,
    "trades": 707,
    "traded_amount": 13866330757,
    "price": [
      14.896247635220687,
      15.097035363830582,
      14.44160960188453,
      14.591836734693878
    ],
    "volume": 61026857404
  },
  {
    "ts": 1593648000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 260,
      "authorized": 260,
      "funded": 70
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 942,
    "traded_amount": 22857860725,
    "price": [
      14.591836734693878,
      15.135247547221145,
      14.27756740442656,
      14.842912583299313
    ],
    "volume": 149451028444
  },
  {
    "ts": 1593734400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 262,
      "authorized": 262,
      "funded": 70
    },
    "payments": 7,
    "payments_amount": 11973487897,
    "trades": 632,
    "traded_amount": 24268968269,
    "price": [
      14.842912583299313,
      15.073401010709826,
      14.439715918709526,
      14.895833333333334
    ],
    "volume": 200378870607
  },
  {
    "ts": 1593820800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 265,
      "authorized": 265,
      "funded": 70
    },
    "payments": 6,
    "payments_amount": 16211744512,
    "trades": 305,
    "traded_amount": 3770540751,
    "price": [
      14.895833333333334,
      14.936260999809413,
      14.661184440113477,
      14.697018890200708
    ],
    "volume": 13250731775
  },
  {
    "ts": 1593907200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 267,
      "authorized": 267,
      "funded": 69
    },
    "payments": 3,
    "payments_amount": 4350000000,
    "trades": 587,
    "traded_amount": 16152592852,
    "price": [
      14.697018890200708,
      15.115400780510129,
      14.690909090909091,
      14.987779861839998
    ],
    "volume": 99027958208
  },
  {
    "ts": 1593993600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 269,
      "authorized": 269,
      "funded": 73
    },
    "payments": 16,
    "payments_amount": 21977444,
    "trades": 1010,
    "traded_amount": 22584558746,
    "price": [
      14.987779861839998,
      15.047322540473225,
      14.038285818019807,
      14.038285818019807
    ],
    "volume": 144826637297
  },
  {
    "ts": 1594080000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 272,
      "authorized": 272,
      "funded": 76
    },
    "payments": 7,
    "payments_amount": 7161162922,
    "trades": 970,
    "traded_amount": 27948480227,
    "price": [
      14.038285818019807,
      14.180917772331615,
      13.64641317556936,
      13.716620536445715
    ],
    "volume": 190680713788
  },
  {
    "ts": 1594166400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 278,
      "authorized": 278,
      "funded": 78
    },
    "payments": 9,
    "payments_amount": 15968351079,
    "trades": 1591,
    "traded_amount": 56517752703,
    "price": [
      13.716620536445715,
      13.724409448818898,
      11.740404392349406,
      11.852031383508258
    ],
    "volume": 353101548719
  },
  {
    "ts": 1594252800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 283,
      "authorized": 283,
      "funded": 77
    },
    "payments": 24,
    "payments_amount": 41406526892,
    "trades": 3252,
    "traded_amount": 177095406203,
    "price": [
      11.852031383508258,
      12.03919902083269,
      10.208492974247962,
      11.138410789199632
    ],
    "volume": 849483251542
  },
  {
    "ts": 1594339200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 288,
      "authorized": 288,
      "funded": 79
    },
    "payments": 13,
    "payments_amount": 7452360412,
    "trades": 1589,
    "traded_amount": 49052186441,
    "price": [
      11.138410789199632,
      12.103957690254791,
      11.123470522803114,
      11.32105783245589
    ],
    "volume": 269601906514
  },
  {
    "ts": 1594425600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 290,
      "authorized": 290,
      "funded": 79
    },
    "payments": 28,
    "payments_amount": 66901475847,
    "trades": 2382,
    "traded_amount": 127527856935,
    "price": [
      11.32105783245589,
      11.366396213657877,
      9.944805634754465,
      10.650871459694988
    ],
    "volume": 686034397743
  },
  {
    "ts": 1594512000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 296,
      "authorized": 296,
      "funded": 79
    },
    "payments": 8,
    "payments_amount": 17148669060,
    "trades": 1637,
    "traded_amount": 63331332945,
    "price": [
      10.650871459694988,
      11.147183869004296,
      10.374641513952373,
      10.600327764165142
    ],
    "volume": 323596196780
  },
  {
    "ts": 1594598400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 295,
      "authorized": 295,
      "funded": 80
    },
    "payments": 26,
    "payments_amount": 13401049441,
    "trades": 1701,
    "traded_amount": 82467453833,
    "price": [
      10.600327764165142,
      11.653217771916156,
      10.309546090965732,
      11.167203866761628
    ],
    "volume": 449031981703
  },
  {
    "ts": 1594684800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 301,
      "authorized": 301,
      "funded": 82
    },
    "payments": 3,
    "payments_amount": 7640000000,
    "trades": 1607,
    "traded_amount": 39860013375,
    "price": [
      11.167203866761628,
      11.779200035993881,
      10.88139281828074,
      10.93261965424107
    ],
    "volume": 185230577283
  },
  {
    "ts": 1594771200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 300,
      "authorized": 300,
      "funded": 84
    },
    "payments": 3,
    "payments_amount": 5950000000,
    "trades": 1560,
    "traded_amount": 39783962750,
    "price": [
      10.93261965424107,
      11.355309064137781,
      10.49035359006584,
      10.650909649748336
    ],
    "volume": 204453845183
  },
  {
    "ts": 1594857600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 305,
      "authorized": 305,
      "funded": 83
    },
    "payments": 6,
    "payments_amount": 25160899000,
    "trades": 2280,
    "traded_amount": 94399394327,
    "price": [
      10.650909649748336,
      11.209592974591082,
      9.531944669016589,
      9.882171211031023
    ],
    "volume": 543615703986
  },
  {
    "ts": 1594944000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 306,
      "authorized": 306,
      "funded": 83
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2228,
    "traded_amount": 49410745414,
    "price": [
      9.882171211031023,
      9.912685490585641,
      9.406215835868274,
      9.551224886682563
    ],
    "volume": 225515739724
  },
  {
    "ts": 1595030400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 308,
      "authorized": 308,
      "funded": 84
    },
    "payments": 1,
    "payments_amount": 3000000000,
    "trades": 1442,
    "traded_amount": 32399734551,
    "price": [
      9.551224886682563,
      10.036185639728728,
      9.551224886682563,
      9.89336120555492
    ],
    "volume": 137463586654
  },
  {
    "ts": 1595116800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 314,
      "authorized": 314,
      "funded": 84
    },
    "payments": 3,
    "payments_amount": 447369623,
    "trades": 893,
    "traded_amount": 20815085417,
    "price": [
      9.89336120555492,
      10.369697865422376,
      9.78077374797843,
      10.098280268213145
    ],
    "volume": 103052366220
  },
  {
    "ts": 1595203200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 314,
      "authorized": 314,
      "funded": 85
    },
    "payments": 5,
    "payments_amount": 7644148871,
    "trades": 938,
    "traded_amount": 18872289486,
    "price": [
      10.098280268213145,
      10.698856738411328,
      9.84014457553542,
      10.559443268761191
    ],
    "volume": 94424073502
  },
  {
    "ts": 1595289600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 321,
      "authorized": 321,
      "funded": 86
    },
    "payments": 5,
    "payments_amount": 5450192375,
    "trades": 761,
    "traded_amount": 24806494140,
    "price": [
      10.559443268761191,
      10.70442880303846,
      10.271364403459764,
      10.487314712779336
    ],
    "volume": 119448744812
  },
  {
    "ts": 1595376000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 320,
      "authorized": 320,
      "funded": 85
    },
    "payments": 3,
    "payments_amount": 6005442837,
    "trades": 643,
    "traded_amount": 19335349909,
    "price": [
      10.487314712779336,
      10.598002261590652,
      10.209563592622917,
      10.371576998282588
    ],
    "volume": 52353820123
  },
  {
    "ts": 1595462400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 322,
      "authorized": 322,
      "funded": 85
    },
    "payments": 7,
    "payments_amount": 10570000000,
    "trades": 1051,
    "traded_amount": 25543204007,
    "price": [
      10.371576998282588,
      10.393030540328896,
      9.956895922981548,
      10.1563768530296
    ],
    "volume": 74767552533
  },
  {
    "ts": 1595548800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 326,
      "authorized": 326,
      "funded": 84
    },
    "payments": 11,
    "payments_amount": 8528040692,
    "trades": 644,
    "traded_amount": 25875187620,
    "price": [
      10.1563768530296,
      10.59958582058842,
      10.1563768530296,
      10.440813951049684
    ],
    "volume": 100139048756
  },
  {
    "ts": 1595635200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 327,
      "authorized": 327,
      "funded": 85
    },
    "payments": 5,
    "payments_amount": 3450318928,
    "trades": 897,
    "traded_amount": 34617627261,
    "price": [
      10.440813951049684,
      10.467071575406466,
      9.89999945761339,
      9.92397456230022
    ],
    "volume": 103775850336
  },
  {
    "ts": 1595721600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 327,
      "authorized": 327,
      "funded": 83
    },
    "payments": 9,
    "payments_amount": 38320000000,
    "trades": 889,
    "traded_amount": 42583915313,
    "price": [
      9.92397456230022,
      10.20973875486806,
      9.560668009588987,
      10.114510977332905
    ],
    "volume": 194491590318
  },
  {
    "ts": 1595808000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 331,
      "authorized": 331,
      "funded": 83
    },
    "payments": 10,
    "payments_amount": 44881979218,
    "trades": 370,
    "traded_amount": 64602103409,
    "price": [
      10.114510977332905,
      11.224193955969156,
      10.094446664003197,
      10.7762269
    ],
    "volume": 332295597202
  },
  {
    "ts": 1595894400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 334,
      "authorized": 334,
      "funded": 84
    },
    "payments": 2,
    "payments_amount": 1060605047,
    "trades": 124,
    "traded_amount": 10489784198,
    "price": [
      10.7762269,
      10.790909090909091,
      10.354067055981467,
      10.431857420195486
    ],
    "volume": 34067050567
  },
  {
    "ts": 1595980800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 336,
      "authorized": 336,
      "funded": 83
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 107,
    "traded_amount": 7359236611,
    "price": [
      10.431857420195486,
      10.510698100464111,
      10.090909090909092,
      10.256315288212974
    ],
    "volume": 35316256651
  },
  {
    "ts": 1596067200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 335,
      "authorized": 335,
      "funded": 82
    },
    "payments": 3,
    "payments_amount": 2407457579,
    "trades": 54,
    "traded_amount": 1675142338,
    "price": [
      10.256315288212974,
      10.668645743923648,
      10.11111111111111,
      10.644038143631244
    ],
    "volume": 7287619561
  },
  {
    "ts": 1596153600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 338,
      "authorized": 338,
      "funded": 83
    },
    "payments": 8,
    "payments_amount": 8730125922,
    "trades": 107,
    "traded_amount": 20415055689,
    "price": [
      10.644038143631244,
      10.644038143631244,
      10.177910899914224,
      10.50909090909091
    ],
    "volume": 48254314650
  },
  {
    "ts": 1596240000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 347,
      "authorized": 347,
      "funded": 83
    },
    "payments": 6,
    "payments_amount": 133957945845,
    "trades": 170,
    "traded_amount": 19395672746,
    "price": [
      10.50909090909091,
      10.50909090909091,
      9.355444499234434,
      9.500545850602434
    ],
    "volume": 107985058653
  },
  {
    "ts": 1596326400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 348,
      "authorized": 348,
      "funded": 85
    },
    "payments": 15,
    "payments_amount": 49746463313,
    "trades": 217,
    "traded_amount": 40908018428,
    "price": [
      9.500545850602434,
      9.998309296649923,
      8.748219031242323,
      9.781562068510842
    ],
    "volume": 103969172672
  },
  {
    "ts": 1596412800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 350,
      "authorized": 350,
      "funded": 86
    },
    "payments": 3,
    "payments_amount": 4309427790,
    "trades": 7,
    "traded_amount": 940493079,
    "price": [
      9.781562068510842,
      9.781562068510842,
      9.45013808599612,
      9.450141757796679
    ],
    "volume": 3499999986
  },
  {
    "ts": 1596499200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 356,
      "authorized": 356,
      "funded": 86
    },
    "payments": 2,
    "payments_amount": 3890000000,
    "trades": 70,
    "traded_amount": 13826051632,
    "price": [
      9.450141757796679,
      9.536363636363637,
      8.90073041935016,
      9.227272727272727
    ],
    "volume": 45698852011
  },
  {
    "ts": 1596585600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 356,
      "authorized": 356,
      "funded": 87
    },
    "payments": 7,
    "payments_amount": 4457499018,
    "trades": 9,
    "traded_amount": 945976339,
    "price": [
      9.227272727272727,
      9.424696776423477,
      9.19735973918781,
      9.19735973918781
    ],
    "volume": 8388559647
  },
  {
    "ts": 1596672000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 362,
      "authorized": 362,
      "funded": 86
    },
    "payments": 15,
    "payments_amount": 427127344,
    "trades": 35,
    "traded_amount": 1983387984,
    "price": [
      9.19735973918781,
      9.536242052848321,
      9.14528471332217,
      9.51033565
    ],
    "volume": 9600379547
  },
  {
    "ts": 1596758400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 365,
      "authorized": 365,
      "funded": 84
    },
    "payments": 7,
    "payments_amount": 4130000000,
    "trades": 84,
    "traded_amount": 17179463074,
    "price": [
      9.51033565,
      9.89090909090909,
      9.346566059786959,
      9.89090909090909
    ],
    "volume": 58957343736
  },
  {
    "ts": 1596844800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 367,
      "authorized": 367,
      "funded": 84
    },
    "payments": 8,
    "payments_amount": 3001695543,
    "trades": 40,
    "traded_amount": 5172147945,
    "price": [
      9.89090909090909,
      9.926941680134933,
      9.484228801131685,
      9.625067061756837
    ],
    "volume": 30062182074
  },
  {
    "ts": 1596931200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 368,
      "authorized": 368,
      "funded": 85
    },
    "payments": 3,
    "payments_amount": 4747936350,
    "trades": 31,
    "traded_amount": 7372112669,
    "price": [
      9.625067061756837,
      9.784390190701183,
      9.39380110366101,
      9.718181818181819
    ],
    "volume": 10010479062
  },
  {
    "ts": 1597017600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 367,
      "authorized": 367,
      "funded": 85
    },
    "payments": 1,
    "payments_amount": 873546669,
    "trades": 57,
    "traded_amount": 3187268687,
    "price": [
      9.718181818181819,
      9.718181818181819,
      9.459643403436754,
      9.681818181818182
    ],
    "volume": 10577916649
  },
  {
    "ts": 1597104000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 374,
      "authorized": 374,
      "funded": 86
    },
    "payments": 7,
    "payments_amount": 6153646729,
    "trades": 43,
    "traded_amount": 10815431419,
    "price": [
      9.681818181818182,
      10.207436541972314,
      9.505666328264912,
      9.899737428571429
    ],
    "volume": 67010234291
  },
  {
    "ts": 1597190400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 374,
      "authorized": 374,
      "funded": 87
    },
    "payments": 3,
    "payments_amount": 5947162800,
    "trades": 14,
    "traded_amount": 5447408365,
    "price": [
      9.899737428571429,
      10.389156070081471,
      9.899737428571429,
      10.007462176604847
    ],
    "volume": 37035446819
  },
  {
    "ts": 1597276800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 375,
      "authorized": 375,
      "funded": 89
    },
    "payments": 4,
    "payments_amount": 1500098194,
    "trades": 76,
    "traded_amount": 9132237518,
    "price": [
      10.007462176604847,
      10.016597510373444,
      9.654305142269983,
      9.654305142269983
    ],
    "volume": 6405136801
  },
  {
    "ts": 1597363200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 378,
      "authorized": 378,
      "funded": 90
    },
    "payments": 7,
    "payments_amount": 8019555562,
    "trades": 50,
    "traded_amount": 13224752656,
    "price": [
      9.654305142269983,
      10.063636363636364,
      9.263953369465181,
      9.373215837160233
    ],
    "volume": 73159926301
  },
  {
    "ts": 1597449600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 379,
      "authorized": 379,
      "funded": 90
    },
    "payments": 2,
    "payments_amount": 5170000000,
    "trades": 49,
    "traded_amount": 4066563080,
    "price": [
      9.373215837160233,
      9.710464178194053,
      9.347466477161557,
      9.521931444655891
    ],
    "volume": 10316551946
  },
  {
    "ts": 1597536000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 383,
      "authorized": 383,
      "funded": 89
    },
    "payments": 3,
    "payments_amount": 3172051320,
    "trades": 57,
    "traded_amount": 2287333605,
    "price": [
      9.521931444655891,
      9.606626800461077,
      9.128341011128006,
      9.128341011128006
    ],
    "volume": 536310069
  },
  {
    "ts": 1597622400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 383,
      "authorized": 383,
      "funded": 92
    },
    "payments": 21,
    "payments_amount": 58038749667,
    "trades": 2127,
    "traded_amount": 110815379078,
    "price": [
      9.128341011128006,
      9.647256501965106,
      8.671033380725806,
      8.779131043272569
    ],
    "volume": 407771763808
  },
  {
    "ts": 1597708800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 388,
      "authorized": 388,
      "funded": 93
    },
    "payments": 5,
    "payments_amount": 4228474504,
    "trades": 1314,
    "traded_amount": 33922712048,
    "price": [
      8.779131043272569,
      9.49131777216572,
      8.68828140088166,
      9.286232489914793
    ],
    "volume": 136858328062
  },
  {
    "ts": 1597795200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 393,
      "authorized": 393,
      "funded": 92
    },
    "payments": 10,
    "payments_amount": 9687443666,
    "trades": 1316,
    "traded_amount": 54243578030,
    "price": [
      9.286232489914793,
      10.122870099251156,
      9.230097240000898,
      9.820716505484928
    ],
    "volume": 236639545231
  },
  {
    "ts": 1597881600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 391,
      "authorized": 391,
      "funded": 92
    },
    "payments": 6,
    "payments_amount": 10201215430,
    "trades": 837,
    "traded_amount": 27405138628,
    "price": [
      9.820716505484928,
      9.956669710707413,
      9.296296296296296,
      9.425216431561957
    ],
    "volume": 85774361438
  },
  {
    "ts": 1597968000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 395,
      "authorized": 395,
      "funded": 92
    },
    "payments": 6,
    "payments_amount": 8925745950,
    "trades": 1413,
    "traded_amount": 56381749215,
    "price": [
      9.425216431561957,
      10.03846076488228,
      9.29658481589431,
      10.032404341981179
    ],
    "volume": 206566772814
  },
  {
    "ts": 1598054400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 394,
      "authorized": 394,
      "funded": 91
    },
    "payments": 3,
    "payments_amount": 6557894431,
    "trades": 893,
    "traded_amount": 31039383979,
    "price": [
      10.032404341981179,
      10.295549957463138,
      9.79823488721378,
      9.855434622962111
    ],
    "volume": 113885133127
  },
  {
    "ts": 1598140800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 399,
      "authorized": 399,
      "funded": 91
    },
    "payments": 2,
    "payments_amount": 12174,
    "trades": 487,
    "traded_amount": 10887080619,
    "price": [
      9.855434622962111,
      10.08192060720558,
      9.80154183220722,
      9.801541871048025
    ],
    "volume": 42328765949
  },
  {
    "ts": 1598227200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 400,
      "authorized": 400,
      "funded": 94
    },
    "payments": 5,
    "payments_amount": 1331010109,
    "trades": 557,
    "traded_amount": 16363930469,
    "price": [
      9.801541871048025,
      9.881818181818181,
      9.487491312817436,
      9.759774095622433
    ],
    "volume": 60718693483
  },
  {
    "ts": 1598313600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 401,
      "authorized": 401,
      "funded": 92
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1124,
    "traded_amount": 35702876268,
    "price": [
      9.759774095622433,
      10.456270157465376,
      9.723349625989519,
      10.298611719253058
    ],
    "volume": 104149747964
  },
  {
    "ts": 1598400000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 404,
      "authorized": 404,
      "funded": 94
    },
    "payments": 5,
    "payments_amount": 12972451740,
    "trades": 787,
    "traded_amount": 39822678369,
    "price": [
      10.298611719253058,
      10.371642586064402,
      10.087763542822556,
      10.187573055039682
    ],
    "volume": 169282598034
  },
  {
    "ts": 1598486400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 408,
      "authorized": 408,
      "funded": 96
    },
    "payments": 7,
    "payments_amount": 15492597095,
    "trades": 1463,
    "traded_amount": 51584664588,
    "price": [
      10.187573055039682,
      11.046424567276619,
      10.177314271598839,
      10.68217285323568
    ],
    "volume": 203936471856
  },
  {
    "ts": 1598572800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 409,
      "authorized": 409,
      "funded": 93
    },
    "payments": 4,
    "payments_amount": 4378023140,
    "trades": 587,
    "traded_amount": 11087839052,
    "price": [
      10.68217285323568,
      10.763625592417062,
      10.475240595252975,
      10.54823673331646
    ],
    "volume": 34684533790
  },
  {
    "ts": 1598659200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 415,
      "authorized": 415,
      "funded": 97
    },
    "payments": 9,
    "payments_amount": 10034400000,
    "trades": 656,
    "traded_amount": 12214381557,
    "price": [
      10.54823673331646,
      10.57183858196459,
      10.277388301768337,
      10.297932266856353
    ],
    "volume": 77575523726
  },
  {
    "ts": 1598745600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 414,
      "authorized": 414,
      "funded": 99
    },
    "payments": 13,
    "payments_amount": 12670332200,
    "trades": 377,
    "traded_amount": 21096410266,
    "price": [
      10.297932266856353,
      10.339345819727443,
      10.223976663382166,
      10.315340047039205
    ],
    "volume": 31278942990
  },
  {
    "ts": 1598832000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 419,
      "authorized": 419,
      "funded": 102
    },
    "payments": 4,
    "payments_amount": 5169143200,
    "trades": 689,
    "traded_amount": 17496708413,
    "price": [
      10.315340047039205,
      10.474391352661884,
      10.16506666235581,
      10.278367682136697
    ],
    "volume": 57059459690
  },
  {
    "ts": 1598918400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 421,
      "authorized": 421,
      "funded": 104
    },
    "payments": 3,
    "payments_amount": 4730000000,
    "trades": 1111,
    "traded_amount": 38175605631,
    "price": [
      10.278367682136697,
      10.482261311581535,
      10.021405540456135,
      10.207554484112082
    ],
    "volume": 168732910479
  },
  {
    "ts": 1599004800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 423,
      "authorized": 423,
      "funded": 106
    },
    "payments": 4,
    "payments_amount": 13060848215,
    "trades": 1506,
    "traded_amount": 57743590180,
    "price": [
      10.207554484112082,
      11.29067779941066,
      10.153872586617194,
      10.867161972714529
    ],
    "volume": 197842779960
  },
  {
    "ts": 1599091200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 428,
      "authorized": 428,
      "funded": 101
    },
    "payments": 4,
    "payments_amount": 2748033461,
    "trades": 1827,
    "traded_amount": 91858716903,
    "price": [
      10.867161972714529,
      12.484363898387983,
      10.864201405963545,
      12.371462709287142
    ],
    "volume": 484756328535
  },
  {
    "ts": 1599177600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 429,
      "authorized": 429,
      "funded": 103
    },
    "payments": 3,
    "payments_amount": 20040781080,
    "trades": 1845,
    "traded_amount": 65646418978,
    "price": [
      12.371462709287142,
      12.914792999951576,
      12.181703710769817,
      12.267088621946357
    ],
    "volume": 319590714286
  },
  {
    "ts": 1599264000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 434,
      "authorized": 434,
      "funded": 102
    },
    "payments": 11,
    "payments_amount": 76039561613,
    "trades": 1550,
    "traded_amount": 79350475776,
    "price": [
      12.267088621946357,
      13.486754899483556,
      12.143173801584394,
      13.231865776663467
    ],
    "volume": 282586257599
  },
  {
    "ts": 1599350400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 438,
      "authorized": 438,
      "funded": 102
    },
    "payments": 7,
    "payments_amount": 5344749170,
    "trades": 1158,
    "traded_amount": 37437168171,
    "price": [
      13.231865776663467,
      13.641827660226925,
      12.760828893679953,
      12.945868802467688
    ],
    "volume": 156735374522
  },
  {
    "ts": 1599436800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 438,
      "authorized": 438,
      "funded": 101
    },
    "payments": 2,
    "payments_amount": 3736229850,
    "trades": 1636,
    "traded_amount": 37426881419,
    "price": [
      12.945868802467688,
      13.45818272021331,
      12.73798635106839,
      12.913444131741405
    ],
    "volume": 150875271787
  },
  {
    "ts": 1599523200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 440,
      "authorized": 440,
      "funded": 103
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 985,
    "traded_amount": 34611634439,
    "price": [
      12.913444131741405,
      13.26923076923077,
      12.573200894050656,
      13.021915798388491
    ],
    "volume": 145265740238
  },
  {
    "ts": 1599609600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 439,
      "authorized": 439,
      "funded": 103
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 693,
    "traded_amount": 18221159788,
    "price": [
      13.021915798388491,
      13.189119796271743,
      12.643921425472474,
      12.738070380151077
    ],
    "volume": 95556716217
  },
  {
    "ts": 1599696000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 438,
      "authorized": 438,
      "funded": 102
    },
    "payments": 3,
    "payments_amount": 1049696427,
    "trades": 978,
    "traded_amount": 32041128143,
    "price": [
      12.738070380151077,
      12.827842458669751,
      12.119206467069732,
      12.319038980933017
    ],
    "volume": 173105405925
  },
  {
    "ts": 1599782400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 444,
      "authorized": 444,
      "funded": 102
    },
    "payments": 6,
    "payments_amount": 8633536833,
    "trades": 880,
    "traded_amount": 17931659102,
    "price": [
      12.319038980933017,
      12.554233183228904,
      11.941176470588236,
      12.049526638809038
    ],
    "volume": 92543952276
  },
  {
    "ts": 1599868800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 447,
      "authorized": 447,
      "funded": 104
    },
    "payments": 6,
    "payments_amount": 3427299646,
    "trades": 518,
    "traded_amount": 16378999569,
    "price": [
      12.049526638809038,
      12.29296652312675,
      11.890971719088906,
      12.073725930298881
    ],
    "volume": 66894739147
  },
  {
    "ts": 1599955200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 454,
      "authorized": 454,
      "funded": 111
    },
    "payments": 10,
    "payments_amount": 8263802069,
    "trades": 760,
    "traded_amount": 25856389955,
    "price": [
      12.073725930298881,
      12.576753837766725,
      11.963975110626576,
      12.498391587347578
    ],
    "volume": 84737225333
  },
  {
    "ts": 1600041600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 451,
      "authorized": 451,
      "funded": 110
    },
    "payments": 2,
    "payments_amount": 295773118,
    "trades": 597,
    "traded_amount": 21142520204,
    "price": [
      12.498391587347578,
      12.627695356361704,
      12.292121629940482,
      12.478939061250127
    ],
    "volume": 67382661156
  },
  {
    "ts": 1600128000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 453,
      "authorized": 453,
      "funded": 108
    },
    "payments": 11,
    "payments_amount": 7523554909,
    "trades": 851,
    "traded_amount": 28880525384,
    "price": [
      12.478939061250127,
      12.836244871216204,
      12.317047685229564,
      12.836244871216204
    ],
    "volume": 125209833086
  },
  {
    "ts": 1600214400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 457,
      "authorized": 457,
      "funded": 111
    },
    "payments": 8,
    "payments_amount": 397972371,
    "trades": 1162,
    "traded_amount": 31864198383,
    "price": [
      12.836244871216204,
      13.251079474725627,
      12.360598844170749,
      12.453146776368628
    ],
    "volume": 112006814877
  },
  {
    "ts": 1600300800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 456,
      "authorized": 456,
      "funded": 110
    },
    "payments": 3,
    "payments_amount": 553284205,
    "trades": 1178,
    "traded_amount": 26623548759,
    "price": [
      12.453146776368628,
      12.74732266066859,
      11.692307692307692,
      12.433985187797619
    ],
    "volume": 124960593501
  },
  {
    "ts": 1600387200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 460,
      "authorized": 460,
      "funded": 113
    },
    "payments": 9,
    "payments_amount": 12562348131,
    "trades": 612,
    "traded_amount": 17243908501,
    "price": [
      12.433985187797619,
      12.873103300719357,
      12.433985187797619,
      12.774891990873835
    ],
    "volume": 66767446424
  },
  {
    "ts": 1600473600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 464,
      "authorized": 464,
      "funded": 112
    },
    "payments": 5,
    "payments_amount": 130983689,
    "trades": 547,
    "traded_amount": 13020874340,
    "price": [
      12.774891990873835,
      12.939038635549469,
      12.636237636556777,
      12.732365673542144
    ],
    "volume": 39474219640
  },
  {
    "ts": 1600560000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 470,
      "authorized": 470,
      "funded": 112
    },
    "payments": 6,
    "payments_amount": 8010000000,
    "trades": 484,
    "traded_amount": 18817680350,
    "price": [
      12.732365673542144,
      13.177881884795024,
      12.732365673542144,
      13.054923629062174
    ],
    "volume": 109304287658
  },
  {
    "ts": 1600646400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 469,
      "authorized": 469,
      "funded": 112
    },
    "payments": 3,
    "payments_amount": 3596687685,
    "trades": 1369,
    "traded_amount": 43962680923,
    "price": [
      13.054923629062174,
      14.288300967099914,
      12.936031821984649,
      14.16974529548915
    ],
    "volume": 245324389321
  },
  {
    "ts": 1600732800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 469,
      "authorized": 469,
      "funded": 109
    },
    "payments": 1,
    "payments_amount": 44291330,
    "trades": 1050,
    "traded_amount": 36655632172,
    "price": [
      14.16974529548915,
      14.548161880458778,
      13.736842105263158,
      13.983571136924484
    ],
    "volume": 177847214773
  },
  {
    "ts": 1600819200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 472,
      "authorized": 472,
      "funded": 109
    },
    "payments": 7,
    "payments_amount": 20050813696,
    "trades": 1382,
    "traded_amount": 47551838923,
    "price": [
      13.983571136924484,
      14.615539472286592,
      13.880406017901352,
      14.610915684515465
    ],
    "volume": 158259145107
  },
  {
    "ts": 1600905600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 471,
      "authorized": 471,
      "funded": 111
    },
    "payments": 1,
    "payments_amount": 3784147000,
    "trades": 888,
    "traded_amount": 24289590117,
    "price": [
      14.610915684515465,
      14.689714350825218,
      14.152650229123765,
      14.265185196419763
    ],
    "volume": 106113064957
  },
  {
    "ts": 1600992000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 475,
      "authorized": 475,
      "funded": 111
    },
    "payments": 3,
    "payments_amount": 180000000,
    "trades": 1064,
    "traded_amount": 37923588743,
    "price": [
      14.265185196419763,
      14.39007782837775,
      13.26755067044717,
      13.470797574342368
    ],
    "volume": 203770682538
  },
  {
    "ts": 1601078400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 476,
      "authorized": 476,
      "funded": 111
    },
    "payments": 2,
    "payments_amount": 10022042394,
    "trades": 778,
    "traded_amount": 20756844697,
    "price": [
      13.470797574342368,
      13.869893499090313,
      13.154171445264817,
      13.548844146159583
    ],
    "volume": 133296608853
  },
  {
    "ts": 1601164800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 478,
      "authorized": 478,
      "funded": 110
    },
    "payments": 2,
    "payments_amount": 4500000000,
    "trades": 604,
    "traded_amount": 32786604725,
    "price": [
      13.548844146159583,
      14.170398587754343,
      13.467891560839412,
      13.66495688301341
    ],
    "volume": 152653796675
  },
  {
    "ts": 1601251200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 479,
      "authorized": 479,
      "funded": 111
    },
    "payments": 3,
    "payments_amount": 4454790095,
    "trades": 960,
    "traded_amount": 37139764362,
    "price": [
      13.66495688301341,
      13.70158932155868,
      13.205142406649296,
      13.638563530233553
    ],
    "volume": 151476324732
  },
  {
    "ts": 1601337600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 482,
      "authorized": 482,
      "funded": 112
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 804,
    "traded_amount": 29352472071,
    "price": [
      13.638563530233553,
      13.814418358265264,
      13.390979380034759,
      13.484156223815553
    ],
    "volume": 118186856828
  },
  {
    "ts": 1601424000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 482,
      "authorized": 482,
      "funded": 114
    },
    "payments": 2,
    "payments_amount": 400000000,
    "trades": 727,
    "traded_amount": 18370255579,
    "price": [
      13.484156223815553,
      13.766754871578936,
      13.328146707799833,
      13.366666666666667
    ],
    "volume": 91035149980
  },
  {
    "ts": 1601510400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 484,
      "authorized": 484,
      "funded": 113
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1207,
    "traded_amount": 32790274865,
    "price": [
      13.366666666666667,
      13.791666666666666,
      13.149414614452967,
      13.609417534919142
    ],
    "volume": 108055315861
  },
  {
    "ts": 1601596800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 486,
      "authorized": 486,
      "funded": 113
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 645,
    "traded_amount": 20639143267,
    "price": [
      13.609417534919142,
      14.319380283646767,
      13.59369164388471,
      14.084272072272514
    ],
    "volume": 100666046318
  },
  {
    "ts": 1601683200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 490,
      "authorized": 490,
      "funded": 113
    },
    "payments": 1,
    "payments_amount": 4345104,
    "trades": 576,
    "traded_amount": 6457488274,
    "price": [
      14.084272072272514,
      14.1484340513192,
      13.882817194235507,
      13.988127655581787
    ],
    "volume": 28106624339
  },
  {
    "ts": 1601769600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 492,
      "authorized": 492,
      "funded": 113
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1183,
    "traded_amount": 13184781202,
    "price": [
      13.988127655581787,
      14.109745601286809,
      13.596190875907961,
      13.693825001185283
    ],
    "volume": 64064405352
  },
  {
    "ts": 1601856000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 492,
      "authorized": 492,
      "funded": 114
    },
    "payments": 6,
    "payments_amount": 19314872911,
    "trades": 1134,
    "traded_amount": 27583244908,
    "price": [
      13.693825001185283,
      13.830750154327989,
      13.391507821772212,
      13.652190012306887
    ],
    "volume": 118749908232
  },
  {
    "ts": 1601942400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 492,
      "authorized": 492,
      "funded": 114
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1785,
    "traded_amount": 50397463273,
    "price": [
      13.652190012306887,
      13.923076923076923,
      12.8,
      13.644505494505495
    ],
    "volume": 198663824824
  },
  {
    "ts": 1602028800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 499,
      "authorized": 499,
      "funded": 113
    },
    "payments": 7,
    "payments_amount": 3160177899,
    "trades": 1122,
    "traded_amount": 25623373071,
    "price": [
      13.644505494505495,
      14.120299198997667,
      13.644432371274881,
      13.899079444590914
    ],
    "volume": 119543703288
  },
  {
    "ts": 1602115200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 500,
      "authorized": 500,
      "funded": 113
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 757,
    "traded_amount": 24907822874,
    "price": [
      13.899079444590914,
      14.151054955181767,
      13.649739583333334,
      13.658814360319798
    ],
    "volume": 56008864718
  },
  {
    "ts": 1602201600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 496,
      "authorized": 496,
      "funded": 112
    },
    "payments": 4,
    "payments_amount": 99380631,
    "trades": 1029,
    "traded_amount": 24487259073,
    "price": [
      13.658814360319798,
      13.740878466700073,
      13.212639060290972,
      13.34787066534842
    ],
    "volume": 67110080200
  },
  {
    "ts": 1602288000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 498,
      "authorized": 498,
      "funded": 113
    },
    "payments": 1,
    "payments_amount": 24353260,
    "trades": 1235,
    "traded_amount": 31213092692,
    "price": [
      13.34787066534842,
      13.34787066534842,
      12.780211960369476,
      12.900581020012911
    ],
    "volume": 103172257798
  },
  {
    "ts": 1602374400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 497,
      "authorized": 497,
      "funded": 113
    },
    "payments": 3,
    "payments_amount": 2040000000,
    "trades": 1120,
    "traded_amount": 23176369312,
    "price": [
      12.900581020012911,
      13.104820219079057,
      12.711457262591919,
      12.94080165753984
    ],
    "volume": 98923723629
  },
  {
    "ts": 1602460800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 502,
      "authorized": 502,
      "funded": 113
    },
    "payments": 2,
    "payments_amount": 103161581,
    "trades": 1099,
    "traded_amount": 37061937314,
    "price": [
      12.94080165753984,
      13.148512815509893,
      12.666666666666666,
      12.888954960326938
    ],
    "volume": 101725531498
  },
  {
    "ts": 1602547200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 503,
      "authorized": 503,
      "funded": 113
    },
    "payments": 22,
    "payments_amount": 605851886,
    "trades": 1418,
    "traded_amount": 37894085353,
    "price": [
      12.888954960326938,
      13.290527300450366,
      12.881676907242737,
      13.099582423832263
    ],
    "volume": 97479158712
  },
  {
    "ts": 1602633600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 506,
      "authorized": 506,
      "funded": 113
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1342,
    "traded_amount": 28749175470,
    "price": [
      13.099582423832263,
      13.56847084464978,
      13.04081323246259,
      13.506626005453354
    ],
    "volume": 77742460236
  },
  {
    "ts": 1602720000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 506,
      "authorized": 506,
      "funded": 112
    },
    "payments": 10,
    "payments_amount": 3439042336,
    "trades": 1183,
    "traded_amount": 28321679931,
    "price": [
      13.506626005453354,
      13.933544911641425,
      13.475466723780219,
      13.550411751613622
    ],
    "volume": 119739318283
  },
  {
    "ts": 1602806400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 508,
      "authorized": 508,
      "funded": 114
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1840,
    "traded_amount": 60043629063,
    "price": [
      13.550411751613622,
      13.809759999496967,
      12.78935925310142,
      12.7908289555914
    ],
    "volume": 125878409722
  },
  {
    "ts": 1602892800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 508,
      "authorized": 508,
      "funded": 114
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2058,
    "traded_amount": 34356659408,
    "price": [
      12.7908289555914,
      12.93006760357609,
      12.1,
      12.303501137272416
    ],
    "volume": 167438240574
  },
  {
    "ts": 1602979200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 509,
      "authorized": 509,
      "funded": 115
    },
    "payments": 2,
    "payments_amount": 145915320,
    "trades": 699,
    "traded_amount": 25882127502,
    "price": [
      12.303501137272416,
      12.660566648569427,
      12.279802933461147,
      12.552023121387283
    ],
    "volume": 70858734703
  },
  {
    "ts": 1603065600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 510,
      "authorized": 510,
      "funded": 114
    },
    "payments": 3,
    "payments_amount": 11274966016,
    "trades": 1432,
    "traded_amount": 26576723975,
    "price": [
      12.552023121387283,
      12.55230346816671,
      11.61786882506225,
      11.855737612654256
    ],
    "volume": 113046401282
  },
  {
    "ts": 1603152000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 518,
      "authorized": 518,
      "funded": 120
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3098,
    "traded_amount": 69202031220,
    "price": [
      11.855737612654256,
      12.528097756702534,
      11.70306572336717,
      12.369473737547256
    ],
    "volume": 279434684445
  },
  {
    "ts": 1603238400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 525,
      "authorized": 525,
      "funded": 123
    },
    "payments": 3,
    "payments_amount": 495000325,
    "trades": 1155,
    "traded_amount": 35467979572,
    "price": [
      12.369473737547256,
      12.369474679148846,
      11.797955209347615,
      11.955688071713764
    ],
    "volume": 102010980917
  },
  {
    "ts": 1603324800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 528,
      "authorized": 528,
      "funded": 121
    },
    "payments": 5,
    "payments_amount": 5775722996,
    "trades": 1769,
    "traded_amount": 31414689699,
    "price": [
      11.955688071713764,
      11.955688071713764,
      11.54830053667263,
      11.679770964905435
    ],
    "volume": 123262503170
  },
  {
    "ts": 1603411200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 527,
      "authorized": 527,
      "funded": 122
    },
    "payments": 2,
    "payments_amount": 38450643000,
    "trades": 1461,
    "traded_amount": 41778660901,
    "price": [
      11.679770964905435,
      12.092439388549476,
      11.676698756444688,
      11.973690936663997
    ],
    "volume": 99654243169
  },
  {
    "ts": 1603497600,
    "supply": 1001929953041,
    "trustlines": {
      "total": 530,
      "authorized": 530,
      "funded": 121
    },
    "payments": 4,
    "payments_amount": 472038740,
    "trades": 903,
    "traded_amount": 18723125040,
    "price": [
      11.973690936663997,
      11.97372167516906,
      11.74861205587985,
      11.846387505234853
    ],
    "volume": 53317157943
  },
  {
    "ts": 1603584000,
    "supply": 1001929953041,
    "trustlines": {
      "total": 532,
      "authorized": 532,
      "funded": 120
    },
    "payments": 1,
    "payments_amount": 584357130,
    "trades": 1007,
    "traded_amount": 42117930520,
    "price": [
      11.846387505234853,
      12.238467367310685,
      11.75640724194686,
      12.197298092482715
    ],
    "volume": 103821912173
  },
  {
    "ts": 1603670400,
    "supply": 1001929953041,
    "trustlines": {
      "total": 535,
      "authorized": 535,
      "funded": 122
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1747,
    "traded_amount": 79081541847,
    "price": [
      12.197298092482715,
      12.500563352492126,
      11.937790308305253,
      12.441217664231113
    ],
    "volume": 253420624243
  },
  {
    "ts": 1603756800,
    "supply": 1001929953041,
    "trustlines": {
      "total": 536,
      "authorized": 536,
      "funded": 123
    },
    "payments": 2,
    "payments_amount": 3544418495,
    "trades": 2270,
    "traded_amount": 58109893419,
    "price": [
      12.441217664231113,
      12.565675870509144,
      11.870967741935484,
      12.396160762896104
    ],
    "volume": 143476719393
  },
  {
    "ts": 1603843200,
    "supply": 1001929953041,
    "trustlines": {
      "total": 539,
      "authorized": 539,
      "funded": 125
    },
    "payments": 2,
    "payments_amount": 2902314098,
    "trades": 1903,
    "traded_amount": 65388852283,
    "price": [
      12.396160762896104,
      12.804097328210862,
      12.261148089572249,
      12.735309353388185
    ],
    "volume": 154131266729
  },
  {
    "ts": 1603929600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 539,
      "authorized": 539,
      "funded": 123
    },
    "payments": 6,
    "payments_amount": 1615542784,
    "trades": 1493,
    "traded_amount": 45565787821,
    "price": [
      12.735309353388185,
      13.158657327452195,
      12.603221439633554,
      12.9198008184663
    ],
    "volume": 199365160192
  },
  {
    "ts": 1604016000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 538,
      "authorized": 538,
      "funded": 123
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1286,
    "traded_amount": 43006095380,
    "price": [
      12.9198008184663,
      13.4081358399392,
      12.776287210936502,
      13.09278183730148
    ],
    "volume": 137530579253
  },
  {
    "ts": 1604102400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 541,
      "authorized": 541,
      "funded": 124
    },
    "payments": 3,
    "payments_amount": 7290000000,
    "trades": 738,
    "traded_amount": 23428202468,
    "price": [
      13.09278183730148,
      13.101468274777137,
      12.629093490847792,
      12.872018369879164
    ],
    "volume": 87716079900
  },
  {
    "ts": 1604188800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 543,
      "authorized": 543,
      "funded": 124
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 366,
    "traded_amount": 18201438264,
    "price": [
      12.872018369879164,
      13.23005956225632,
      12.804097217473393,
      12.804097217473393
    ],
    "volume": 54214447232
  },
  {
    "ts": 1604275200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 547,
      "authorized": 547,
      "funded": 126
    },
    "payments": 8,
    "payments_amount": 10712000000,
    "trades": 1081,
    "traded_amount": 57615399259,
    "price": [
      12.804097217473393,
      13.23340365962077,
      12.681607815837063,
      13.23340365962077
    ],
    "volume": 97817191296
  },
  {
    "ts": 1604361600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 549,
      "authorized": 549,
      "funded": 128
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1293,
    "traded_amount": 52338545159,
    "price": [
      13.23340365962077,
      13.570644765379061,
      13.13457181694608,
      13.291063155140302
    ],
    "volume": 149563151797
  },
  {
    "ts": 1604448000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 552,
      "authorized": 552,
      "funded": 129
    },
    "payments": 5,
    "payments_amount": 3596945794,
    "trades": 1304,
    "traded_amount": 75432702890,
    "price": [
      13.291063155140302,
      13.712662297274811,
      13.150801939838788,
      13.39408274579547
    ],
    "volume": 178312927173
  },
  {
    "ts": 1604534400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 551,
      "authorized": 551,
      "funded": 128
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2676,
    "traded_amount": 128540034323,
    "price": [
      13.39408274579547,
      13.598295630615867,
      12.180346855958161,
      12.45348341416466
    ],
    "volume": 283555119220
  },
  {
    "ts": 1604620800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 556,
      "authorized": 556,
      "funded": 128
    },
    "payments": 7,
    "payments_amount": 21467266002,
    "trades": 2204,
    "traded_amount": 136731760498,
    "price": [
      12.45348341416466,
      12.456332717535993,
      11.812645683270288,
      11.935152889872555
    ],
    "volume": 278691664936
  },
  {
    "ts": 1604707200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 559,
      "authorized": 559,
      "funded": 129
    },
    "payments": 3,
    "payments_amount": 290309747,
    "trades": 3579,
    "traded_amount": 245405151211,
    "price": [
      11.935152889872555,
      12.809726640334343,
      11.648452146905482,
      12.584208460104033
    ],
    "volume": 405707324488
  },
  {
    "ts": 1604793600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 558,
      "authorized": 558,
      "funded": 128
    },
    "payments": 4,
    "payments_amount": 2024436556,
    "trades": 1527,
    "traded_amount": 65961866011,
    "price": [
      12.584208460104033,
      12.651370088294158,
      12.090909090909092,
      12.248325842110814
    ],
    "volume": 107635544104
  },
  {
    "ts": 1604880000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 561,
      "authorized": 561,
      "funded": 127
    },
    "payments": 4,
    "payments_amount": 10185626062,
    "trades": 1990,
    "traded_amount": 153335156378,
    "price": [
      12.248325842110814,
      12.80037611077695,
      12.169526668756701,
      12.56149713977847
    ],
    "volume": 323277302541
  },
  {
    "ts": 1604966400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 561,
      "authorized": 561,
      "funded": 127
    },
    "payments": 3,
    "payments_amount": 5003607639,
    "trades": 1361,
    "traded_amount": 58033608689,
    "price": [
      12.56149713977847,
      12.612150389268256,
      12.195121950467211,
      12.537981721042497
    ],
    "volume": 129229895145
  },
  {
    "ts": 1605052800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 562,
      "authorized": 562,
      "funded": 127
    },
    "payments": 2,
    "payments_amount": 34485818,
    "trades": 1232,
    "traded_amount": 73833168828,
    "price": [
      12.537981721042497,
      12.572387803776802,
      12.196080077928185,
      12.40642449569075
    ],
    "volume": 131419278045
  },
  {
    "ts": 1605139200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 567,
      "authorized": 567,
      "funded": 128
    },
    "payments": 3,
    "payments_amount": 5086798439,
    "trades": 1069,
    "traded_amount": 54124685284,
    "price": [
      12.40642449569075,
      12.774774774774775,
      12.391525059257702,
      12.44357022866742
    ],
    "volume": 130678162427
  },
  {
    "ts": 1605225600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 570,
      "authorized": 570,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1108,
    "traded_amount": 39053848105,
    "price": [
      12.44357022866742,
      12.536927682327986,
      12.209327324857405,
      12.282582694708903
    ],
    "volume": 127663305353
  },
  {
    "ts": 1605312000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 571,
      "authorized": 571,
      "funded": 129
    },
    "payments": 1,
    "payments_amount": 1010000000,
    "trades": 895,
    "traded_amount": 36984163077,
    "price": [
      12.282582694708903,
      12.370612820383705,
      12.081833693185978,
      12.320688943617345
    ],
    "volume": 67234269932
  },
  {
    "ts": 1605398400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 573,
      "authorized": 573,
      "funded": 129
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 716,
    "traded_amount": 29629206016,
    "price": [
      12.320688943617345,
      12.644468721797368,
      12.22893440353415,
      12.477018430371325
    ],
    "volume": 43874233027
  },
  {
    "ts": 1605484800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 576,
      "authorized": 576,
      "funded": 131
    },
    "payments": 2,
    "payments_amount": 19410573380,
    "trades": 351,
    "traded_amount": 19027332892,
    "price": [
      12.477018430371325,
      12.577011239326678,
      12.048323000164745,
      12.048323000164745
    ],
    "volume": 57162213266
  },
  {
    "ts": 1605571200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 578,
      "authorized": 578,
      "funded": 131
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 265,
    "traded_amount": 14950146590,
    "price": [
      12.048323000164745,
      12.048323000164745,
      11.363636363008148,
      11.363637760491693
    ],
    "volume": 37037559048
  },
  {
    "ts": 1605657600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 581,
      "authorized": 581,
      "funded": 129
    },
    "payments": 3,
    "payments_amount": 678097816,
    "trades": 98,
    "traded_amount": 3969500881,
    "price": [
      11.363637760491693,
      11.683856845864762,
      11.110245656081485,
      11.110245656081485
    ],
    "volume": 1526316446
  },
  {
    "ts": 1605744000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 584,
      "authorized": 584,
      "funded": 131
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 159,
    "traded_amount": 16209258582,
    "price": [
      11.110245656081485,
      12.752039229925302,
      11.060240963855422,
      11.060240963855422
    ],
    "volume": 76023333314
  },
  {
    "ts": 1605830400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 584,
      "authorized": 584,
      "funded": 131
    },
    "payments": 1,
    "payments_amount": 70000000,
    "trades": 123,
    "traded_amount": 8451909057,
    "price": [
      11.060240963855422,
      12.738853513624047,
      11.060240963855422,
      11.111123480238028
    ],
    "volume": 8690339090
  },
  {
    "ts": 1605916800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 587,
      "authorized": 587,
      "funded": 132
    },
    "payments": 6,
    "payments_amount": 13961498575,
    "trades": 246,
    "traded_amount": 55094739853,
    "price": [
      11.111123480238028,
      12.753,
      9.463244722683172,
      12.752039231239804
    ],
    "volume": 309316685979
  },
  {
    "ts": 1606003200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 589,
      "authorized": 589,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 163,
    "traded_amount": 8930468261,
    "price": [
      12.752039231239804,
      12.752039231239804,
      9.331743667719946,
      9.331778178348001
    ],
    "volume": 99196093
  },
  {
    "ts": 1606089600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 591,
      "authorized": 591,
      "funded": 135
    },
    "payments": 2,
    "payments_amount": 19940000000,
    "trades": 873,
    "traded_amount": 70438617642,
    "price": [
      9.331778178348001,
      12.75203929524935,
      5,
      8.702227009019788
    ],
    "volume": 175822308171
  },
  {
    "ts": 1606176000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 601,
      "authorized": 601,
      "funded": 147
    },
    "payments": 27,
    "payments_amount": 157422414194,
    "trades": 10827,
    "traded_amount": 775707146915,
    "price": [
      8.702227009019788,
      8.702227009019788,
      4.78451289292784,
      5.21176057577239
    ],
    "volume": 2499568727388
  },
  {
    "ts": 1606262400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 619,
      "authorized": 619,
      "funded": 155
    },
    "payments": 28,
    "payments_amount": 57822259377,
    "trades": 5049,
    "traded_amount": 496958463934,
    "price": [
      5.21176057577239,
      6.279000000079692,
      4.424724502999311,
      5.167285517409766
    ],
    "volume": 1130238283374
  },
  {
    "ts": 1606348800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 625,
      "authorized": 625,
      "funded": 157
    },
    "payments": 14,
    "payments_amount": 3892067031,
    "trades": 9226,
    "traded_amount": 1102776947301,
    "price": [
      5.167285517409766,
      7.014705882352941,
      4.802816901408451,
      6.052755649487888
    ],
    "volume": 1688127115106
  },
  {
    "ts": 1606435200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 624,
      "authorized": 624,
      "funded": 157
    },
    "payments": 18,
    "payments_amount": 8343174492,
    "trades": 6780,
    "traded_amount": 568504889581,
    "price": [
      6.052755649487888,
      6.604651162790698,
      4.578357904567198,
      4.875223742867268
    ],
    "volume": 1147731348589
  },
  {
    "ts": 1606521600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 629,
      "authorized": 629,
      "funded": 159
    },
    "payments": 10,
    "payments_amount": 165968903530,
    "trades": 4634,
    "traded_amount": 247414649325,
    "price": [
      4.875223742867268,
      5.170310199249834,
      4.6,
      5.060253938763682
    ],
    "volume": 532378245585
  },
  {
    "ts": 1606608000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 631,
      "authorized": 631,
      "funded": 159
    },
    "payments": 13,
    "payments_amount": 53743812072,
    "trades": 3760,
    "traded_amount": 223804695878,
    "price": [
      5.060253938763682,
      5.314451010283289,
      4.954873956581241,
      5.136225650047198
    ],
    "volume": 456548111511
  },
  {
    "ts": 1606694400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 641,
      "authorized": 641,
      "funded": 160
    },
    "payments": 5,
    "payments_amount": 671086257,
    "trades": 3027,
    "traded_amount": 302876622729,
    "price": [
      5.136225650047198,
      5.28446669984396,
      4.851662920537273,
      4.950031949824187
    ],
    "volume": 418210917142
  },
  {
    "ts": 1606780800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 656,
      "authorized": 656,
      "funded": 167
    },
    "payments": 5,
    "payments_amount": 7091851302,
    "trades": 3958,
    "traded_amount": 475240292872,
    "price": [
      4.950031949824187,
      5.7068081985640795,
      4.903879266483291,
      5.4516723564184115
    ],
    "volume": 510515115706
  },
  {
    "ts": 1606867200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 666,
      "authorized": 666,
      "funded": 167
    },
    "payments": 4,
    "payments_amount": 137050263,
    "trades": 4556,
    "traded_amount": 194308561174,
    "price": [
      5.4516723564184115,
      5.653575571822878,
      5.28,
      5.415190382621881
    ],
    "volume": 310538230994
  },
  {
    "ts": 1606953600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 670,
      "authorized": 670,
      "funded": 169
    },
    "payments": 4,
    "payments_amount": 7281158572,
    "trades": 4071,
    "traded_amount": 190488774638,
    "price": [
      5.415190382621881,
      5.567721652039949,
      5.2,
      5.409646865899517
    ],
    "volume": 315301882851
  },
  {
    "ts": 1607040000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 668,
      "authorized": 668,
      "funded": 167
    },
    "payments": 3,
    "payments_amount": 2702516490,
    "trades": 3905,
    "traded_amount": 186156092789,
    "price": [
      5.409646865899517,
      6.229672140020735,
      5.3180012187690435,
      6.150176256099538
    ],
    "volume": 380795213615
  },
  {
    "ts": 1607126400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 673,
      "authorized": 673,
      "funded": 168
    },
    "payments": 6,
    "payments_amount": 604742009,
    "trades": 2016,
    "traded_amount": 63277076491,
    "price": [
      6.150176256099538,
      6.249611028693418,
      5.747313565593124,
      5.862007194277659
    ],
    "volume": 117937719492
  },
  {
    "ts": 1607212800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 677,
      "authorized": 677,
      "funded": 169
    },
    "payments": 3,
    "payments_amount": 15241677866,
    "trades": 3115,
    "traded_amount": 136158005494,
    "price": [
      5.862007194277659,
      5.965909622406586,
      5.595902802302016,
      5.711378488506189
    ],
    "volume": 274559115899
  },
  {
    "ts": 1607299200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 687,
      "authorized": 687,
      "funded": 172
    },
    "payments": 11,
    "payments_amount": 4866335640,
    "trades": 2644,
    "traded_amount": 110794622509,
    "price": [
      5.711378488506189,
      6.067527559287341,
      5.651864553463948,
      5.977795086176295
    ],
    "volume": 243749090196
  },
  {
    "ts": 1607385600,
    "supply": 1001929950295,
    "trustlines": {
      "total": 693,
      "authorized": 693,
      "funded": 177
    },
    "payments": 7,
    "payments_amount": 2327989306,
    "trades": 3229,
    "traded_amount": 171278250227,
    "price": [
      5.977795086176295,
      6.60215872402936,
      5.9327276098646395,
      6.5246876654552315
    ],
    "volume": 284286590353
  },
  {
    "ts": 1607472000,
    "supply": 1001929950295,
    "trustlines": {
      "total": 693,
      "authorized": 693,
      "funded": 179
    },
    "payments": 4,
    "payments_amount": 2674573131,
    "trades": 5495,
    "traded_amount": 320637273586,
    "price": [
      6.5246876654552315,
      7.163072277105201,
      5.673072120645479,
      5.792377242667668
    ],
    "volume": 557858475458
  },
  {
    "ts": 1607558400,
    "supply": 1001929950295,
    "trustlines": {
      "total": 697,
      "authorized": 697,
      "funded": 177
    },
    "payments": 3,
    "payments_amount": 377705174,
    "trades": 3360,
    "traded_amount": 158965918359,
    "price": [
      5.792377242667668,
      6.1949304562960075,
      5.64941491468767,
      6.184429994737452
    ],
    "volume": 341052961714
  },
  {
    "ts": 1607644800,
    "supply": 1001929950295,
    "trustlines": {
      "total": 697,
      "authorized": 697,
      "funded": 178
    },
    "payments": 8,
    "payments_amount": 231218694,
    "trades": 2206,
    "traded_amount": 138192073093,
    "price": [
      6.184429994737452,
      6.616601142495283,
      6.109872252065183,
      6.6165476389519196
    ],
    "volume": 258975552193
  },
  {
    "ts": 1607731200,
    "supply": 1001929950295,
    "trustlines": {
      "total": 698,
      "authorized": 698,
      "funded": 178
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1547,
    "traded_amount": 137250006917,
    "price": [
      6.6165476389519196,
      6.61660146325166,
      6.042207600439441,
      6.287957123007931
    ],
    "volume": 266725441588
  },
  {
    "ts": 1607817600,
    "supply": 982400465564,
    "trustlines": {
      "total": 702,
      "authorized": 702,
      "funded": 178
    },
    "payments": 1,
    "payments_amount": 19529484731,
    "trades": 1909,
    "traded_amount": 116323448096,
    "price": [
      6.287957123007931,
      6.406058770993117,
      5.586752348414399,
      5.699901730913606
    ],
    "volume": 240991823667
  },
  {
    "ts": 1607904000,
    "supply": 982400465564,
    "trustlines": {
      "total": 705,
      "authorized": 705,
      "funded": 179
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1364,
    "traded_amount": 72158762674,
    "price": [
      5.699901730913606,
      5.980876865671642,
      5.593575575595983,
      5.880950493744349
    ],
    "volume": 159279318988
  },
  {
    "ts": 1607990400,
    "supply": 982400465564,
    "trustlines": {
      "total": 708,
      "authorized": 708,
      "funded": 176
    },
    "payments": 3,
    "payments_amount": 23645266672,
    "trades": 1001,
    "traded_amount": 55796374454,
    "price": [
      5.880950493744349,
      6.028900123813455,
      5.676874488113002,
      6.027096518450169
    ],
    "volume": 85926967702
  },
  {
    "ts": 1608076800,
    "supply": 982400465564,
    "trustlines": {
      "total": 713,
      "authorized": 713,
      "funded": 178
    },
    "payments": 2,
    "payments_amount": 15556468,
    "trades": 3170,
    "traded_amount": 373377963329,
    "price": [
      6.027096518450169,
      6.23970580872406,
      5.0588235294117645,
      5.155507648209094
    ],
    "volume": 608910330086
  },
  {
    "ts": 1608163200,
    "supply": 982400465564,
    "trustlines": {
      "total": 717,
      "authorized": 717,
      "funded": 180
    },
    "payments": 4,
    "payments_amount": 275134457,
    "trades": 3578,
    "traded_amount": 357729625890,
    "price": [
      5.155507648209094,
      5.5361986706712205,
      4.811744926316977,
      5.366454614492456
    ],
    "volume": 628733183221
  },
  {
    "ts": 1608249600,
    "supply": 982400465564,
    "trustlines": {
      "total": 723,
      "authorized": 723,
      "funded": 181
    },
    "payments": 4,
    "payments_amount": 750577693,
    "trades": 1808,
    "traded_amount": 137601903189,
    "price": [
      5.366454614492456,
      5.614279470519008,
      5.2661972738978555,
      5.424756317689531
    ],
    "volume": 257672737611
  },
  {
    "ts": 1608336000,
    "supply": 982400465564,
    "trustlines": {
      "total": 722,
      "authorized": 722,
      "funded": 181
    },
    "payments": 14,
    "payments_amount": 177473953063,
    "trades": 899,
    "traded_amount": 46169793295,
    "price": [
      5.424756317689531,
      5.525042824777588,
      5.3425532739661685,
      5.523162327369871
    ],
    "volume": 53152375111
  },
  {
    "ts": 1608422400,
    "supply": 982400465564,
    "trustlines": {
      "total": 725,
      "authorized": 725,
      "funded": 181
    },
    "payments": 14,
    "payments_amount": 2251140464,
    "trades": 1233,
    "traded_amount": 82926511816,
    "price": [
      5.523162327369871,
      5.725191220156654,
      5.483431608670376,
      5.6798022019755345
    ],
    "volume": 112371639133
  },
  {
    "ts": 1608508800,
    "supply": 982400465564,
    "trustlines": {
      "total": 731,
      "authorized": 731,
      "funded": 184
    },
    "payments": 2,
    "payments_amount": 24021233,
    "trades": 1924,
    "traded_amount": 177775524262,
    "price": [
      5.6798022019755345,
      6.280288214450164,
      5.5542066423432725,
      6.0042394663040435
    ],
    "volume": 211955350405
  },
  {
    "ts": 1608595200,
    "supply": 982400465564,
    "trustlines": {
      "total": 740,
      "authorized": 740,
      "funded": 185
    },
    "payments": 4,
    "payments_amount": 1028697434,
    "trades": 2218,
    "traded_amount": 95810351879,
    "price": [
      6.0042394663040435,
      6.432734763459565,
      5.6496534908584435,
      6.4049775153010655
    ],
    "volume": 185120706949
  },
  {
    "ts": 1608681600,
    "supply": 982400465564,
    "trustlines": {
      "total": 746,
      "authorized": 746,
      "funded": 185
    },
    "payments": 13,
    "payments_amount": 104499799541,
    "trades": 4928,
    "traded_amount": 458588726221,
    "price": [
      6.4049775153010655,
      9.781105900376364,
      6.4049775153010655,
      7.774015170051382
    ],
    "volume": 1361977255247
  },
  {
    "ts": 1608768000,
    "supply": 982400465564,
    "trustlines": {
      "total": 753,
      "authorized": 753,
      "funded": 184
    },
    "payments": 20,
    "payments_amount": 75027457571,
    "trades": 3204,
    "traded_amount": 296884863505,
    "price": [
      7.774015170051382,
      8.095799085024392,
      5.649653204265281,
      6.3001433538705545
    ],
    "volume": 1163200481846
  },
  {
    "ts": 1608854400,
    "supply": 982400465564,
    "trustlines": {
      "total": 758,
      "authorized": 758,
      "funded": 186
    },
    "payments": 4,
    "payments_amount": 18008562708,
    "trades": 1733,
    "traded_amount": 86313813087,
    "price": [
      6.3001433538705545,
      6.802051376934587,
      6.1638277236371755,
      6.621924562622462
    ],
    "volume": 208690401049
  },
  {
    "ts": 1608940800,
    "supply": 982400465564,
    "trustlines": {
      "total": 760,
      "authorized": 760,
      "funded": 190
    },
    "payments": 3,
    "payments_amount": 251778895,
    "trades": 1530,
    "traded_amount": 63769055746,
    "price": [
      6.621924562622462,
      7.030221917263549,
      6.548984375,
      6.831672105682693
    ],
    "volume": 145633037944
  },
  {
    "ts": 1609027200,
    "supply": 982400465564,
    "trustlines": {
      "total": 768,
      "authorized": 768,
      "funded": 192
    },
    "payments": 9,
    "payments_amount": 1433949426,
    "trades": 2270,
    "traded_amount": 178719674507,
    "price": [
      6.831672105682693,
      7.385836154549541,
      6.637902422834384,
      6.851043421957507
    ],
    "volume": 229628027326
  },
  {
    "ts": 1609113600,
    "supply": 982400465564,
    "trustlines": {
      "total": 771,
      "authorized": 771,
      "funded": 196
    },
    "payments": 4,
    "payments_amount": 627312069,
    "trades": 2108,
    "traded_amount": 122592524760,
    "price": [
      6.851043421957507,
      7.246376780587778,
      6.543453901176386,
      7.2463767519005335
    ],
    "volume": 246781457018
  },
  {
    "ts": 1609200000,
    "supply": 982400465564,
    "trustlines": {
      "total": 778,
      "authorized": 778,
      "funded": 195
    },
    "payments": 8,
    "payments_amount": 81295691304,
    "trades": 3055,
    "traded_amount": 251737627142,
    "price": [
      7.2463767519005335,
      7.698231315779475,
      6.945996131951379,
      7.246825370289879
    ],
    "volume": 979942752526
  },
  {
    "ts": 1609286400,
    "supply": 982400465564,
    "trustlines": {
      "total": 780,
      "authorized": 780,
      "funded": 195
    },
    "payments": 8,
    "payments_amount": 1721980570,
    "trades": 1553,
    "traded_amount": 136317135705,
    "price": [
      7.246825370289879,
      7.698229748608629,
      7.217454680482091,
      7.643541449557601
    ],
    "volume": 329367304387
  },
  {
    "ts": 1609372800,
    "supply": 982400465564,
    "trustlines": {
      "total": 783,
      "authorized": 783,
      "funded": 193
    },
    "payments": 4,
    "payments_amount": 53679725956,
    "trades": 2724,
    "traded_amount": 281946070187,
    "price": [
      7.643541449557601,
      9.669581100456972,
      7.171782738305908,
      7.8574090355499395
    ],
    "volume": 908566880720
  },
  {
    "ts": 1609459200,
    "supply": 982400465564,
    "trustlines": {
      "total": 788,
      "authorized": 788,
      "funded": 193
    },
    "payments": 4,
    "payments_amount": 990994164,
    "trades": 1351,
    "traded_amount": 67212721563,
    "price": [
      7.8574090355499395,
      7.879199578589945,
      7.226533066132265,
      7.523928364468643
    ],
    "volume": 151397113446
  },
  {
    "ts": 1609545600,
    "supply": 982400465564,
    "trustlines": {
      "total": 803,
      "authorized": 803,
      "funded": 191
    },
    "payments": 5,
    "payments_amount": 435875449,
    "trades": 2405,
    "traded_amount": 193001111271,
    "price": [
      7.523928364468643,
      7.972656259733423,
      7.50618044835097,
      7.8285360506658
    ],
    "volume": 333961944900
  },
  {
    "ts": 1609632000,
    "supply": 982400465564,
    "trustlines": {
      "total": 805,
      "authorized": 805,
      "funded": 193
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2843,
    "traded_amount": 299859419075,
    "price": [
      7.8285360506658,
      8.040013930666735,
      7.28290713524868,
      7.36923204
    ],
    "volume": 310820196670
  },
  {
    "ts": 1609718400,
    "supply": 982400465564,
    "trustlines": {
      "total": 815,
      "authorized": 815,
      "funded": 195
    },
    "payments": 8,
    "payments_amount": 77184252614,
    "trades": 6495,
    "traded_amount": 1073191898198,
    "price": [
      7.36923204,
      7.936507940578621,
      6.035259514995782,
      6.10076766640406
    ],
    "volume": 1657040953359
  },
  {
    "ts": 1609804800,
    "supply": 982400465564,
    "trustlines": {
      "total": 825,
      "authorized": 825,
      "funded": 202
    },
    "payments": 4,
    "payments_amount": 1141630400,
    "trades": 4301,
    "traded_amount": 589930026851,
    "price": [
      6.10076766640406,
      6.889272966372347,
      4.83493252824629,
      5.14143596885148
    ],
    "volume": 833355874312
  },
  {
    "ts": 1609891200,
    "supply": 1982400465564,
    "trustlines": {
      "total": 839,
      "authorized": 839,
      "funded": 203
    },
    "payments": 22,
    "payments_amount": 1120463186875,
    "trades": 5397,
    "traded_amount": 811459324995,
    "price": [
      5.14143596885148,
      5.50478587377418,
      2.980102958032666,
      3.0569211879364593
    ],
    "volume": 1344556773774
  },
  {
    "ts": 1609977600,
    "supply": 1982400465564,
    "trustlines": {
      "total": 855,
      "authorized": 855,
      "funded": 212
    },
    "payments": 18,
    "payments_amount": 206413540493,
    "trades": 5253,
    "traded_amount": 644343226832,
    "price": [
      3.0569211879364593,
      3.529311487923298,
      2.628348388445115,
      3.0277701616452464
    ],
    "volume": 701596083992
  },
  {
    "ts": 1610064000,
    "supply": 1982400465564,
    "trustlines": {
      "total": 865,
      "authorized": 865,
      "funded": 217
    },
    "payments": 12,
    "payments_amount": 16562108857,
    "trades": 4126,
    "traded_amount": 603478660688,
    "price": [
      3.0277701616452464,
      3.5422399386816674,
      2.99707934542021,
      3.3004389583814646
    ],
    "volume": 461438098050
  },
  {
    "ts": 1610150400,
    "supply": 1982400465564,
    "trustlines": {
      "total": 867,
      "authorized": 867,
      "funded": 219
    },
    "payments": 5,
    "payments_amount": 4458464693,
    "trades": 2213,
    "traded_amount": 189617036197,
    "price": [
      3.3004389583814646,
      3.5301626735793796,
      3.1722023586976853,
      3.2265710673601657
    ],
    "volume": 170367810565
  },
  {
    "ts": 1610236800,
    "supply": 1982400465564,
    "trustlines": {
      "total": 874,
      "authorized": 874,
      "funded": 219
    },
    "payments": 12,
    "payments_amount": 8410718452,
    "trades": 3796,
    "traded_amount": 441828109005,
    "price": [
      3.2265710673601657,
      3.9592780715496207,
      3.1271070600228223,
      3.5
    ],
    "volume": 386756892032
  },
  {
    "ts": 1610323200,
    "supply": 1982400465564,
    "trustlines": {
      "total": 883,
      "authorized": 883,
      "funded": 224
    },
    "payments": 19,
    "payments_amount": 29381456156,
    "trades": 7594,
    "traded_amount": 963135381047,
    "price": [
      3.5,
      4.545307353023282,
      3.499417523832687,
      3.9601935634442316
    ],
    "volume": 656035362692
  },
  {
    "ts": 1610409600,
    "supply": 1982400465564,
    "trustlines": {
      "total": 885,
      "authorized": 885,
      "funded": 227
    },
    "payments": 21,
    "payments_amount": 42295612325,
    "trades": 4277,
    "traded_amount": 513398369029,
    "price": [
      3.9601935634442316,
      4.16588409130339,
      3.328021760463696,
      3.6101525975409086
    ],
    "volume": 562035780745
  },
  {
    "ts": 1610496000,
    "supply": 1982400465564,
    "trustlines": {
      "total": 892,
      "authorized": 892,
      "funded": 231
    },
    "payments": 11,
    "payments_amount": 4425539880,
    "trades": 2719,
    "traded_amount": 302395281699,
    "price": [
      3.6101525975409086,
      3.621028871698524,
      3.2553332204268117,
      3.302568617232222
    ],
    "volume": 218348476303
  },
  {
    "ts": 1610582400,
    "supply": 1982400465564,
    "trustlines": {
      "total": 900,
      "authorized": 900,
      "funded": 229
    },
    "payments": 10,
    "payments_amount": 141709842378,
    "trades": 2270,
    "traded_amount": 251422559267,
    "price": [
      3.302568617232222,
      3.5161991635513434,
      3.1250959324635454,
      3.3170470687526326
    ],
    "volume": 230160033093
  },
  {
    "ts": 1610668800,
    "supply": 1982400465564,
    "trustlines": {
      "total": 906,
      "authorized": 906,
      "funded": 230
    },
    "payments": 8,
    "payments_amount": 7654542874,
    "trades": 2988,
    "traded_amount": 391509326426,
    "price": [
      3.3170470687526326,
      4.385446276293348,
      3.1715867604944274,
      3.5190285899842415
    ],
    "volume": 321696561379
  },
  {
    "ts": 1610755200,
    "supply": 1982400465564,
    "trustlines": {
      "total": 908,
      "authorized": 908,
      "funded": 230
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2522,
    "traded_amount": 304606859032,
    "price": [
      3.5190285899842415,
      3.5720105022328466,
      3.253016564064779,
      3.4199660236600047
    ],
    "volume": 209482053998
  },
  {
    "ts": 1610841600,
    "supply": 1982400465564,
    "trustlines": {
      "total": 916,
      "authorized": 916,
      "funded": 233
    },
    "payments": 6,
    "payments_amount": 28078477761,
    "trades": 2390,
    "traded_amount": 229679230140,
    "price": [
      3.4199660236600047,
      3.4747944933053754,
      3.1685799286661247,
      3.2722168282506883
    ],
    "volume": 219164000926
  },
  {
    "ts": 1610928000,
    "supply": 1982400465564,
    "trustlines": {
      "total": 918,
      "authorized": 918,
      "funded": 231
    },
    "payments": 14,
    "payments_amount": 55515643778,
    "trades": 1586,
    "traded_amount": 202803091171,
    "price": [
      3.2722168282506883,
      3.4265127203055874,
      3.215921020729412,
      3.3139288021597877
    ],
    "volume": 172871806909
  },
  {
    "ts": 1611014400,
    "supply": 1982400465564,
    "trustlines": {
      "total": 920,
      "authorized": 920,
      "funded": 234
    },
    "payments": 11,
    "payments_amount": 78353701561,
    "trades": 2479,
    "traded_amount": 433710638604,
    "price": [
      3.3139288021597877,
      3.883196809488825,
      3.1328717728282456,
      3.3303170184527278
    ],
    "volume": 512254851004
  },
  {
    "ts": 1611100800,
    "supply": 1982400465564,
    "trustlines": {
      "total": 933,
      "authorized": 933,
      "funded": 234
    },
    "payments": 6,
    "payments_amount": 4461406934,
    "trades": 2538,
    "traded_amount": 382796523321,
    "price": [
      3.3303170184527278,
      3.608798993655177,
      3.3148677264912267,
      3.4062106896302615
    ],
    "volume": 283405211788
  },
  {
    "ts": 1611187200,
    "supply": 1982400465564,
    "trustlines": {
      "total": 938,
      "authorized": 938,
      "funded": 236
    },
    "payments": 9,
    "payments_amount": 19532146758,
    "trades": 3508,
    "traded_amount": 690969153897,
    "price": [
      3.4062106896302615,
      4.366831482460779,
      3.4062106896302615,
      3.941119274889538
    ],
    "volume": 621759325783
  },
  {
    "ts": 1611273600,
    "supply": 1982400430597,
    "trustlines": {
      "total": 946,
      "authorized": 946,
      "funded": 242
    },
    "payments": 25,
    "payments_amount": 16365877713,
    "trades": 3149,
    "traded_amount": 380619637114,
    "price": [
      3.941119274889538,
      4.419574360393693,
      3.5778943376244214,
      3.7099138416337385
    ],
    "volume": 395216972979
  },
  {
    "ts": 1611360000,
    "supply": 1982400430597,
    "trustlines": {
      "total": 946,
      "authorized": 946,
      "funded": 240
    },
    "payments": 4,
    "payments_amount": 42244367,
    "trades": 1477,
    "traded_amount": 172102278478,
    "price": [
      3.7099138416337385,
      3.7788451198967703,
      3.567147986283513,
      3.71653840647188
    ],
    "volume": 150164354146
  },
  {
    "ts": 1611446400,
    "supply": 1982400430597,
    "trustlines": {
      "total": 954,
      "authorized": 954,
      "funded": 243
    },
    "payments": 8,
    "payments_amount": 293942770,
    "trades": 1747,
    "traded_amount": 168001452600,
    "price": [
      3.71653840647188,
      3.7876885405710214,
      3.536724076043517,
      3.6855346045596926
    ],
    "volume": 98569154969
  },
  {
    "ts": 1611532800,
    "supply": 1982400430597,
    "trustlines": {
      "total": 959,
      "authorized": 959,
      "funded": 249
    },
    "payments": 9,
    "payments_amount": 59873327015,
    "trades": 2022,
    "traded_amount": 240472147115,
    "price": [
      3.6855346045596926,
      3.8260179070952445,
      3.6286383465636844,
      3.8017796270855815
    ],
    "volume": 67638239927
  },
  {
    "ts": 1611619200,
    "supply": 1982400430597,
    "trustlines": {
      "total": 965,
      "authorized": 965,
      "funded": 248
    },
    "payments": 8,
    "payments_amount": 9331700983,
    "trades": 3041,
    "traded_amount": 422439881511,
    "price": [
      3.8017796270855815,
      4.032563285801755,
      3.18029300597015,
      3.8442321149656395
    ],
    "volume": 230638425767
  },
  {
    "ts": 1611705600,
    "supply": 1982400430597,
    "trustlines": {
      "total": 966,
      "authorized": 966,
      "funded": 248
    },
    "payments": 12,
    "payments_amount": 476813766,
    "trades": 2796,
    "traded_amount": 269019216228,
    "price": [
      3.8442321149656395,
      4.3084859449871775,
      3.8281540791938005,
      4.184854320360939
    ],
    "volume": 160084583471
  },
  {
    "ts": 1611792000,
    "supply": 1982400430597,
    "trustlines": {
      "total": 971,
      "authorized": 971,
      "funded": 252
    },
    "payments": 16,
    "payments_amount": 77983271902,
    "trades": 3128,
    "traded_amount": 364652561167,
    "price": [
      4.184854320360939,
      4.328985596898002,
      3.3504346134604233,
      3.4527167701906243
    ],
    "volume": 473853868587
  },
  {
    "ts": 1611878400,
    "supply": 1982400430597,
    "trustlines": {
      "total": 979,
      "authorized": 979,
      "funded": 255
    },
    "payments": 30,
    "payments_amount": 12603020647,
    "trades": 6976,
    "traded_amount": 861822096803,
    "price": [
      3.4527167701906243,
      3.944938163688711,
      2.7517885893447285,
      3.3999463204334623
    ],
    "volume": 851657937021
  },
  {
    "ts": 1611964800,
    "supply": 1982400430597,
    "trustlines": {
      "total": 988,
      "authorized": 988,
      "funded": 253
    },
    "payments": 14,
    "payments_amount": 7524657084,
    "trades": 4813,
    "traded_amount": 385749491081,
    "price": [
      3.3999463204334623,
      3.5714285772918384,
      2.880915883157566,
      3.1144822864915978
    ],
    "volume": 444436040789
  },
  {
    "ts": 1612051200,
    "supply": 1982400430597,
    "trustlines": {
      "total": 989,
      "authorized": 989,
      "funded": 252
    },
    "payments": 14,
    "payments_amount": 100457456774,
    "trades": 2851,
    "traded_amount": 299448158797,
    "price": [
      3.1144822864915978,
      3.9011470432983892,
      3.036379869076531,
      3.291874347598978
    ],
    "volume": 397903441869
  },
  {
    "ts": 1612137600,
    "supply": 1982400430597,
    "trustlines": {
      "total": 996,
      "authorized": 996,
      "funded": 258
    },
    "payments": 8,
    "payments_amount": 35566447674,
    "trades": 5112,
    "traded_amount": 315286223501,
    "price": [
      3.291874347598978,
      3.3628240830198117,
      2.7777777777777777,
      3.118704353599545
    ],
    "volume": 419933027578
  },
  {
    "ts": 1612224000,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1007,
      "authorized": 1007,
      "funded": 266
    },
    "payments": 17,
    "payments_amount": 75975906590,
    "trades": 3041,
    "traded_amount": 413936775576,
    "price": [
      3.118704353599545,
      3.5701536601523016,
      2.954903628352552,
      2.968422530750187
    ],
    "volume": 412828794020
  },
  {
    "ts": 1612310400,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1009,
      "authorized": 1009,
      "funded": 264
    },
    "payments": 8,
    "payments_amount": 93553706464,
    "trades": 2468,
    "traded_amount": 307511144423,
    "price": [
      2.968422530750187,
      3.122886067192369,
      2.8941356180667577,
      2.940509701673501
    ],
    "volume": 383568653073
  },
  {
    "ts": 1612396800,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1010,
      "authorized": 1010,
      "funded": 264
    },
    "payments": 9,
    "payments_amount": 116199665023,
    "trades": 2144,
    "traded_amount": 232866884811,
    "price": [
      2.940509701673501,
      3.5701539311457364,
      2.7777777751877433,
      3.06142124405655
    ],
    "volume": 164090772964
  },
  {
    "ts": 1612483200,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1014,
      "authorized": 1014,
      "funded": 265
    },
    "payments": 11,
    "payments_amount": 15459211136,
    "trades": 2433,
    "traded_amount": 210528217896,
    "price": [
      3.06142124405655,
      3.0614230641192886,
      2.7831927787430604,
      2.884885937808165
    ],
    "volume": 173754842273
  },
  {
    "ts": 1612569600,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1028,
      "authorized": 1028,
      "funded": 269
    },
    "payments": 17,
    "payments_amount": 12051111060,
    "trades": 3580,
    "traded_amount": 344200725924,
    "price": [
      2.884885937808165,
      3.021575152699301,
      2.579710144927536,
      2.652572014664247
    ],
    "volume": 267200531593
  },
  {
    "ts": 1612656000,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1031,
      "authorized": 1031,
      "funded": 270
    },
    "payments": 9,
    "payments_amount": 86813279205,
    "trades": 3932,
    "traded_amount": 425687400438,
    "price": [
      2.652572014664247,
      2.8169611307420497,
      2.397851429140292,
      2.598813206860046
    ],
    "volume": 400648650312
  },
  {
    "ts": 1612742400,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1031,
      "authorized": 1031,
      "funded": 273
    },
    "payments": 8,
    "payments_amount": 3760446406,
    "trades": 2817,
    "traded_amount": 337859094267,
    "price": [
      2.598813206860046,
      2.6770435092092657,
      2.4999999986576547,
      2.560094127304347
    ],
    "volume": 184581672396
  },
  {
    "ts": 1612828800,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1034,
      "authorized": 1034,
      "funded": 273
    },
    "payments": 11,
    "payments_amount": 8061823184,
    "trades": 1695,
    "traded_amount": 163543167663,
    "price": [
      2.560094127304347,
      2.580022241009737,
      2.476003148289418,
      2.505165075347981
    ],
    "volume": 99685350677
  },
  {
    "ts": 1612915200,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1043,
      "authorized": 1043,
      "funded": 275
    },
    "payments": 16,
    "payments_amount": 62509218700,
    "trades": 3038,
    "traded_amount": 330908217515,
    "price": [
      2.505165075347981,
      2.5921300934231066,
      2.2978995608707566,
      2.4131079853732453
    ],
    "volume": 327516607441
  },
  {
    "ts": 1613001600,
    "supply": 1982400430597,
    "trustlines": {
      "total": 1053,
      "authorized": 1053,
      "funded": 277
    },
    "payments": 24,
    "payments_amount": 44681075981,
    "trades": 3218,
    "traded_amount": 493822710285,
    "price": [
      2.4131079853732453,
      2.4532904510241225,
      2.150537547509454,
      2.2124385210411783
    ],
    "volume": 415426621837
  },
  {
    "ts": 1613088000,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1071,
      "authorized": 1071,
      "funded": 282
    },
    "payments": 13,
    "payments_amount": 87107184545,
    "trades": 2717,
    "traded_amount": 313071459245,
    "price": [
      2.2124385210411783,
      2.313499679520828,
      1.8973232180105464,
      1.9028824627486949
    ],
    "volume": 271404667313
  },
  {
    "ts": 1613174400,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1083,
      "authorized": 1083,
      "funded": 288
    },
    "payments": 30,
    "payments_amount": 122300963915,
    "trades": 4277,
    "traded_amount": 474690853556,
    "price": [
      1.9028824627486949,
      2.1788766225255474,
      1.6914413017763252,
      1.7657647562666747
    ],
    "volume": 345554327290
  },
  {
    "ts": 1613260800,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1095,
      "authorized": 1095,
      "funded": 288
    },
    "payments": 9,
    "payments_amount": 14486930894,
    "trades": 2223,
    "traded_amount": 224527899825,
    "price": [
      1.7657647562666747,
      2.014105562140287,
      1.7584983268559382,
      1.9067058145404856
    ],
    "volume": 173316305228
  },
  {
    "ts": 1613347200,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1102,
      "authorized": 1102,
      "funded": 290
    },
    "payments": 8,
    "payments_amount": 10656935884,
    "trades": 3393,
    "traded_amount": 389678139463,
    "price": [
      1.9067058145404856,
      2.288968062047779,
      1.8739604772287708,
      2.0304044473317417
    ],
    "volume": 240117085946
  },
  {
    "ts": 1613433600,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1112,
      "authorized": 1112,
      "funded": 293
    },
    "payments": 16,
    "payments_amount": 53490438133,
    "trades": 3197,
    "traded_amount": 310711428182,
    "price": [
      2.0304044473317417,
      2.2917451180050663,
      1.950435624394966,
      2.0763516709030414
    ],
    "volume": 195823396887
  },
  {
    "ts": 1613520000,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1114,
      "authorized": 1114,
      "funded": 296
    },
    "payments": 10,
    "payments_amount": 14679840496,
    "trades": 2219,
    "traded_amount": 226775763645,
    "price": [
      2.0763516709030414,
      2.2450980392156863,
      1.8450974285180792,
      2.0090969146827824
    ],
    "volume": 135144850226
  },
  {
    "ts": 1613606400,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1123,
      "authorized": 1123,
      "funded": 300
    },
    "payments": 25,
    "payments_amount": 64878683769,
    "trades": 2105,
    "traded_amount": 182111125531,
    "price": [
      2.0090969146827824,
      2.055821729803461,
      1.954703389193374,
      2.0178314396917156
    ],
    "volume": 95246283199
  },
  {
    "ts": 1613692800,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1125,
      "authorized": 1125,
      "funded": 301
    },
    "payments": 14,
    "payments_amount": 113147970084,
    "trades": 2658,
    "traded_amount": 299184539644,
    "price": [
      2.0178314396917156,
      2.1330049261083746,
      1.880572910819407,
      1.9663495995281737
    ],
    "volume": 211720989068
  },
  {
    "ts": 1613779200,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1137,
      "authorized": 1137,
      "funded": 302
    },
    "payments": 13,
    "payments_amount": 31347487570,
    "trades": 2531,
    "traded_amount": 212495259738,
    "price": [
      1.9663495995281737,
      2.134822443656791,
      1.899803429039024,
      2.0819399628434536
    ],
    "volume": 125899533578
  },
  {
    "ts": 1613865600,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1140,
      "authorized": 1140,
      "funded": 304
    },
    "payments": 14,
    "payments_amount": 123870416328,
    "trades": 1722,
    "traded_amount": 142035965918,
    "price": [
      2.0819399628434536,
      2.0981902655660996,
      1.9848484848484849,
      2.012764072485869
    ],
    "volume": 92064329792
  },
  {
    "ts": 1613952000,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1143,
      "authorized": 1143,
      "funded": 305
    },
    "payments": 31,
    "payments_amount": 19008922738,
    "trades": 6094,
    "traded_amount": 834958328189,
    "price": [
      2.012764072485869,
      2.505596700892106,
      1.843792429073194,
      2.1628536434155414
    ],
    "volume": 591490624066
  },
  {
    "ts": 1614038400,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1156,
      "authorized": 1156,
      "funded": 306
    },
    "payments": 25,
    "payments_amount": 80039767524,
    "trades": 9664,
    "traded_amount": 1132425926801,
    "price": [
      2.1628536434155414,
      2.945945945945946,
      2.1584102072600935,
      2.6230508248938413
    ],
    "volume": 671526573850
  },
  {
    "ts": 1614124800,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1165,
      "authorized": 1165,
      "funded": 309
    },
    "payments": 13,
    "payments_amount": 194213092622,
    "trades": 5228,
    "traded_amount": 468311040137,
    "price": [
      2.6230508248938413,
      2.7969058385724157,
      2.361052084808991,
      2.4948159988071454
    ],
    "volume": 365200463430
  },
  {
    "ts": 1614211200,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1176,
      "authorized": 1176,
      "funded": 306
    },
    "payments": 15,
    "payments_amount": 91355507936,
    "trades": 3941,
    "traded_amount": 426633084806,
    "price": [
      2.4948159988071454,
      2.782051282051282,
      2.3554262589807418,
      2.6246903177560883
    ],
    "volume": 230771168422
  },
  {
    "ts": 1614297600,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1181,
      "authorized": 1181,
      "funded": 304
    },
    "payments": 7,
    "payments_amount": 164384495790,
    "trades": 5835,
    "traded_amount": 526490802225,
    "price": [
      2.6246903177560883,
      2.8052209018394922,
      2.355347503373819,
      2.5240126061074566
    ],
    "volume": 333894890380
  },
  {
    "ts": 1614384000,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1192,
      "authorized": 1192,
      "funded": 309
    },
    "payments": 18,
    "payments_amount": 43320373113,
    "trades": 6034,
    "traded_amount": 762181774971,
    "price": [
      2.5240126061074566,
      2.9386145165271955,
      2.122736658385034,
      2.2754888703047986
    ],
    "volume": 622509577540
  },
  {
    "ts": 1614470400,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1205,
      "authorized": 1205,
      "funded": 312
    },
    "payments": 15,
    "payments_amount": 20255993804,
    "trades": 5146,
    "traded_amount": 494960990656,
    "price": [
      2.2754888703047986,
      3.0149711376870716,
      2.1808641997592435,
      2.5024423134551994
    ],
    "volume": 476285844072
  },
  {
    "ts": 1614556800,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1211,
      "authorized": 1211,
      "funded": 314
    },
    "payments": 12,
    "payments_amount": 3266034611,
    "trades": 2624,
    "traded_amount": 223034746993,
    "price": [
      2.5024423134551994,
      2.5024423134551994,
      2.2864166181398144,
      2.321586107628732
    ],
    "volume": 155846880066
  },
  {
    "ts": 1614643200,
    "supply": 1982373193384,
    "trustlines": {
      "total": 1224,
      "authorized": 1224,
      "funded": 316
    },
    "payments": 31,
    "payments_amount": 155664703148,
    "trades": 3220,
    "traded_amount": 283243382425,
    "price": [
      2.321586107628732,
      2.508321487086824,
      2.3160847435152037,
      2.4022380072395504
    ],
    "volume": 217851200042
  },
  {
    "ts": 1614729600,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1229,
      "authorized": 1229,
      "funded": 317
    },
    "payments": 35,
    "payments_amount": 1831550041838,
    "trades": 3590,
    "traded_amount": 473276219272,
    "price": [
      2.4022380072395504,
      2.4396986449340643,
      2.3012741250470405,
      2.3725379101947563
    ],
    "volume": 364080174649
  },
  {
    "ts": 1614816000,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1237,
      "authorized": 1237,
      "funded": 315
    },
    "payments": 31,
    "payments_amount": 108347043093,
    "trades": 3857,
    "traded_amount": 387561083034,
    "price": [
      2.3725379101947563,
      2.519746771531892,
      2.3161592505854802,
      2.437003460544914
    ],
    "volume": 298530426249
  },
  {
    "ts": 1614902400,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1242,
      "authorized": 1242,
      "funded": 318
    },
    "payments": 16,
    "payments_amount": 48105716820,
    "trades": 2997,
    "traded_amount": 457557157518,
    "price": [
      2.437003460544914,
      2.5998872792521643,
      2.437003460544914,
      2.4647939272105845
    ],
    "volume": 426859730029
  },
  {
    "ts": 1614988800,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1252,
      "authorized": 1252,
      "funded": 317
    },
    "payments": 12,
    "payments_amount": 207118092795,
    "trades": 1773,
    "traded_amount": 181594352454,
    "price": [
      2.4647939272105845,
      2.56178990338378,
      2.4204083629541624,
      2.497083792796503
    ],
    "volume": 132413856901
  },
  {
    "ts": 1615075200,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1258,
      "authorized": 1258,
      "funded": 318
    },
    "payments": 6,
    "payments_amount": 6999403983,
    "trades": 2072,
    "traded_amount": 221233819012,
    "price": [
      2.497083792796503,
      2.508223209571824,
      2.3857868020304567,
      2.46343104927558
    ],
    "volume": 152662620374
  },
  {
    "ts": 1615161600,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1262,
      "authorized": 1262,
      "funded": 319
    },
    "payments": 29,
    "payments_amount": 93395335217,
    "trades": 2614,
    "traded_amount": 470386088653,
    "price": [
      2.46343104927558,
      2.5202527323257944,
      2.3932431786784205,
      2.3963188741802384
    ],
    "volume": 176904687860
  },
  {
    "ts": 1615248000,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1275,
      "authorized": 1275,
      "funded": 323
    },
    "payments": 15,
    "payments_amount": 45450930724,
    "trades": 2966,
    "traded_amount": 292881569423,
    "price": [
      2.3963188741802384,
      2.4717805654866245,
      1.724137930766687,
      2.3462425624455223
    ],
    "volume": 294135790401
  },
  {
    "ts": 1615334400,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1284,
      "authorized": 1284,
      "funded": 328
    },
    "payments": 7,
    "payments_amount": 4633154347,
    "trades": 3577,
    "traded_amount": 386130767751,
    "price": [
      2.3462425624455223,
      2.873720093546185,
      2.170203837396352,
      2.4469788325188473
    ],
    "volume": 326486853383
  },
  {
    "ts": 1615420800,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1284,
      "authorized": 1284,
      "funded": 327
    },
    "payments": 16,
    "payments_amount": 10488730759,
    "trades": 2201,
    "traded_amount": 268550654973,
    "price": [
      2.4469788325188473,
      2.5258154153722407,
      2.3871716165396344,
      2.4805535433267556
    ],
    "volume": 107334088515
  },
  {
    "ts": 1615507200,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1287,
      "authorized": 1287,
      "funded": 329
    },
    "payments": 4,
    "payments_amount": 1977542733,
    "trades": 1226,
    "traded_amount": 102592146857,
    "price": [
      2.4805535433267556,
      2.651801207951715,
      2.47857996675413,
      2.590374530645803
    ],
    "volume": 57137288225
  },
  {
    "ts": 1615593600,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1289,
      "authorized": 1289,
      "funded": 327
    },
    "payments": 8,
    "payments_amount": 8815324119,
    "trades": 1571,
    "traded_amount": 228897024650,
    "price": [
      2.590374530645803,
      2.7156700795380733,
      2.455700113951736,
      2.4753453238834693
    ],
    "volume": 118884781985
  },
  {
    "ts": 1615680000,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1299,
      "authorized": 1299,
      "funded": 329
    },
    "payments": 25,
    "payments_amount": 56728166476,
    "trades": 1329,
    "traded_amount": 196505218225,
    "price": [
      2.4753453238834693,
      2.565166,
      2.472039749761473,
      2.5639885163142764
    ],
    "volume": 50839363486
  },
  {
    "ts": 1615766400,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1301,
      "authorized": 1301,
      "funded": 331
    },
    "payments": 10,
    "payments_amount": 162365009545,
    "trades": 1921,
    "traded_amount": 318100216704,
    "price": [
      2.5639885163142764,
      2.8868427473851086,
      2.5321663106707972,
      2.6068482265571764
    ],
    "volume": 263938234404
  },
  {
    "ts": 1615852800,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1305,
      "authorized": 1305,
      "funded": 334
    },
    "payments": 6,
    "payments_amount": 1662456157,
    "trades": 2603,
    "traded_amount": 444185966458,
    "price": [
      2.6068482265571764,
      2.6901145634438466,
      2.2024976092024526,
      2.5352919783989627
    ],
    "volume": 200677186225
  },
  {
    "ts": 1615939200,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1308,
      "authorized": 1308,
      "funded": 334
    },
    "payments": 8,
    "payments_amount": 87324651760,
    "trades": 1860,
    "traded_amount": 191588664318,
    "price": [
      2.5352919783989627,
      2.5886392391259503,
      2.406407290158099,
      2.4824668909786154
    ],
    "volume": 120180984320
  },
  {
    "ts": 1616025600,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1315,
      "authorized": 1315,
      "funded": 336
    },
    "payments": 13,
    "payments_amount": 27377393466,
    "trades": 1538,
    "traded_amount": 302288311448,
    "price": [
      2.4824668909786154,
      2.5703240630233255,
      2.4186582511962147,
      2.5271947335146194
    ],
    "volume": 143037998408
  },
  {
    "ts": 1616112000,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1323,
      "authorized": 1323,
      "funded": 336
    },
    "payments": 5,
    "payments_amount": 26281310312,
    "trades": 1373,
    "traded_amount": 319703553262,
    "price": [
      2.5271947335146194,
      2.5796551809105686,
      2.466596301793144,
      2.528183506081783
    ],
    "volume": 145341880860
  },
  {
    "ts": 1616198400,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1326,
      "authorized": 1326,
      "funded": 337
    },
    "payments": 14,
    "payments_amount": 27007770277,
    "trades": 2369,
    "traded_amount": 199965155573,
    "price": [
      2.528183506081783,
      2.5456792213365156,
      2.311304752791334,
      2.41111720491348
    ],
    "volume": 181073071281
  },
  {
    "ts": 1616284800,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1329,
      "authorized": 1329,
      "funded": 336
    },
    "payments": 2,
    "payments_amount": 1021966337,
    "trades": 1551,
    "traded_amount": 163037442537,
    "price": [
      2.41111720491348,
      2.5402118365832074,
      2.383848412943173,
      2.47733209353064
    ],
    "volume": 103676470944
  },
  {
    "ts": 1616371200,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1337,
      "authorized": 1337,
      "funded": 338
    },
    "payments": 23,
    "payments_amount": 123512583673,
    "trades": 2327,
    "traded_amount": 282318808431,
    "price": [
      2.47733209353064,
      2.571023576689226,
      2.340954568055654,
      2.52999875177918
    ],
    "volume": 208362725057
  },
  {
    "ts": 1616457600,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1342,
      "authorized": 1342,
      "funded": 337
    },
    "payments": 10,
    "payments_amount": 2460100676,
    "trades": 1340,
    "traded_amount": 280666523410,
    "price": [
      2.52999875177918,
      2.589650848830277,
      2.4427266705563264,
      2.5172549927869983
    ],
    "volume": 111729593602
  },
  {
    "ts": 1616544000,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1352,
      "authorized": 1352,
      "funded": 335
    },
    "payments": 13,
    "payments_amount": 9978706775,
    "trades": 865,
    "traded_amount": 179704340590,
    "price": [
      2.5172549927869983,
      2.8818474502922307,
      2.4903581383720397,
      2.8818474502922307
    ],
    "volume": 37964642488
  },
  {
    "ts": 1616630400,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1353,
      "authorized": 1353,
      "funded": 331
    },
    "payments": 14,
    "payments_amount": 227888697988,
    "trades": 579,
    "traded_amount": 199035017870,
    "price": [
      2.8818474502922307,
      2.971830985915493,
      2.5,
      2.7500010882576995
    ],
    "volume": 13021819780
  },
  {
    "ts": 1616716800,
    "supply": 2982373193384,
    "trustlines": {
      "total": 1357,
      "authorized": 1357,
      "funded": 334
    },
    "payments": 9,
    "payments_amount": 175085160200,
    "trades": 243,
    "traded_amount": 224527450028,
    "price": [
      2.7500010882576995,
      2.7500010882576995,
      2.5799990740125653,
      2.6199999967817615
    ],
    "volume": 2948610092
  },
  {
    "ts": 1616803200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1366,
      "authorized": 1366,
      "funded": 337
    },
    "payments": 11,
    "payments_amount": 180553684410,
    "trades": 338,
    "traded_amount": 44925193798,
    "price": [
      2.6199999967817615,
      2.839569892473118,
      2.4634146341463414,
      2.636693456760327
    ],
    "volume": 5763197873
  },
  {
    "ts": 1616889600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1371,
      "authorized": 1371,
      "funded": 339
    },
    "payments": 3,
    "payments_amount": 2984778666,
    "trades": 209,
    "traded_amount": 102713298192,
    "price": [
      2.636693456760327,
      2.8392071797871163,
      2.34878587196468,
      2.5958983861535723
    ],
    "volume": 3907121977
  },
  {
    "ts": 1616976000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1373,
      "authorized": 1373,
      "funded": 340
    },
    "payments": 7,
    "payments_amount": 62274324753,
    "trades": 146,
    "traded_amount": 89828945075,
    "price": [
      2.5958983861535723,
      2.5958983861535723,
      2.350176038137505,
      2.4691358623602566
    ],
    "volume": 2128391355
  },
  {
    "ts": 1617062400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1373,
      "authorized": 1373,
      "funded": 338
    },
    "payments": 1,
    "payments_amount": 12548212,
    "trades": 170,
    "traded_amount": 61139989971,
    "price": [
      2.4691358623602566,
      2.5000001095314968,
      2.358490599679655,
      2.475246703706775
    ],
    "volume": 20255683614
  },
  {
    "ts": 1617148800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1376,
      "authorized": 1376,
      "funded": 338
    },
    "payments": 6,
    "payments_amount": 5711989936,
    "trades": 190,
    "traded_amount": 56657555072,
    "price": [
      2.475246703706775,
      2.6666666666666665,
      2.475246703706775,
      2.5000051334175213
    ],
    "volume": 33629958835
  },
  {
    "ts": 1617235200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1383,
      "authorized": 1383,
      "funded": 338
    },
    "payments": 6,
    "payments_amount": 15170870502,
    "trades": 154,
    "traded_amount": 168366281945,
    "price": [
      2.5000051334175213,
      2.665956466222593,
      2.302486916128949,
      2.3024869163250097
    ],
    "volume": 129217017386
  },
  {
    "ts": 1617321600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1391,
      "authorized": 1391,
      "funded": 343
    },
    "payments": 2,
    "payments_amount": 2691516000,
    "trades": 385,
    "traded_amount": 163474886039,
    "price": [
      2.3024869163250097,
      2.8383090518270837,
      2.108029197080292,
      2.202643171806167
    ],
    "volume": 50810762679
  },
  {
    "ts": 1617408000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1399,
      "authorized": 1399,
      "funded": 347
    },
    "payments": 15,
    "payments_amount": 610240845533,
    "trades": 264,
    "traded_amount": 55822377165,
    "price": [
      2.202643171806167,
      2.8386923901393355,
      2.202643171806167,
      2.8386923901393355
    ],
    "volume": 7044999794
  },
  {
    "ts": 1617494400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1404,
      "authorized": 1404,
      "funded": 349
    },
    "payments": 5,
    "payments_amount": 307854991408,
    "trades": 622,
    "traded_amount": 106515210223,
    "price": [
      2.8386923901393355,
      2.8386923901393355,
      2.145228215767635,
      2.3551168219885037
    ],
    "volume": 51578528165
  },
  {
    "ts": 1617580800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1421,
      "authorized": 1421,
      "funded": 350
    },
    "payments": 16,
    "payments_amount": 13591789182,
    "trades": 4077,
    "traded_amount": 509840286486,
    "price": [
      2.3551168219885037,
      2.363990008842147,
      1.730102485607421,
      1.8934986807308987
    ],
    "volume": 352169836195
  },
  {
    "ts": 1617667200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1421,
      "authorized": 1421,
      "funded": 347
    },
    "payments": 12,
    "payments_amount": 170485055363,
    "trades": 1383,
    "traded_amount": 310460452022,
    "price": [
      1.8934986807308987,
      2.3119167131850826,
      1.7316017253162188,
      1.9231199897784128
    ],
    "volume": 269720470102
  },
  {
    "ts": 1617753600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1437,
      "authorized": 1437,
      "funded": 352
    },
    "payments": 49,
    "payments_amount": 234833512344,
    "trades": 3517,
    "traded_amount": 624187154334,
    "price": [
      1.9231199897784128,
      2.282191780821918,
      1.7301038042893975,
      2.0643557855132473
    ],
    "volume": 503689473183
  },
  {
    "ts": 1617840000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1444,
      "authorized": 1444,
      "funded": 354
    },
    "payments": 8,
    "payments_amount": 26570316844,
    "trades": 1287,
    "traded_amount": 207573890558,
    "price": [
      2.0643557855132473,
      2.124495400153729,
      1.6966400754865754,
      1.9904971984915745
    ],
    "volume": 206910831574
  },
  {
    "ts": 1617926400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1450,
      "authorized": 1450,
      "funded": 356
    },
    "payments": 6,
    "payments_amount": 27340846926,
    "trades": 709,
    "traded_amount": 110291289367,
    "price": [
      1.9904971984915745,
      2.1341507650471505,
      1.9878476076128717,
      2.0528523473923057
    ],
    "volume": 75279220021
  },
  {
    "ts": 1618012800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1456,
      "authorized": 1456,
      "funded": 358
    },
    "payments": 8,
    "payments_amount": 23968963785,
    "trades": 1962,
    "traded_amount": 332090763777,
    "price": [
      2.0528523473923057,
      2.052852669759495,
      1.7329759319110807,
      1.7974606550549423
    ],
    "volume": 189413768043
  },
  {
    "ts": 1618099200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1461,
      "authorized": 1461,
      "funded": 359
    },
    "payments": 11,
    "payments_amount": 17476067236,
    "trades": 3014,
    "traded_amount": 482909637920,
    "price": [
      1.7974606550549423,
      1.8400460299194477,
      1.5536854605678112,
      1.6999282579527155
    ],
    "volume": 357612250948
  },
  {
    "ts": 1618185600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1471,
      "authorized": 1471,
      "funded": 357
    },
    "payments": 8,
    "payments_amount": 168849989367,
    "trades": 2092,
    "traded_amount": 351676317428,
    "price": [
      1.6999282579527155,
      3.2000000331918232,
      1.6491228070175439,
      1.6949965171716144
    ],
    "volume": 236603627846
  },
  {
    "ts": 1618272000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1483,
      "authorized": 1483,
      "funded": 360
    },
    "payments": 5,
    "payments_amount": 8573472296,
    "trades": 2786,
    "traded_amount": 577192509829,
    "price": [
      1.6949965171716144,
      1.7279890524808936,
      1.4829534481137265,
      1.5257136144002954
    ],
    "volume": 232938182567
  },
  {
    "ts": 1618358400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1497,
      "authorized": 1497,
      "funded": 360
    },
    "payments": 16,
    "payments_amount": 101052042964,
    "trades": 3824,
    "traded_amount": 499373069089,
    "price": [
      1.5257136144002954,
      1.8699991040346973,
      1.4653532554794904,
      1.5809297936356081
    ],
    "volume": 326493195147
  },
  {
    "ts": 1618444800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1503,
      "authorized": 1503,
      "funded": 363
    },
    "payments": 6,
    "payments_amount": 67296689022,
    "trades": 1603,
    "traded_amount": 167080335093,
    "price": [
      1.5809297936356081,
      1.6236963101645345,
      1.535308613008705,
      1.5644985598827328
    ],
    "volume": 96897084210
  },
  {
    "ts": 1618531200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1512,
      "authorized": 1512,
      "funded": 360
    },
    "payments": 8,
    "payments_amount": 17901502454,
    "trades": 3205,
    "traded_amount": 538845521225,
    "price": [
      1.5644985598827328,
      1.7538365128560833,
      1.5366261870843507,
      1.6438572817099224
    ],
    "volume": 187651688993
  },
  {
    "ts": 1618617600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1518,
      "authorized": 1518,
      "funded": 365
    },
    "payments": 12,
    "payments_amount": 32068578483,
    "trades": 1841,
    "traded_amount": 243399411691,
    "price": [
      1.6438572817099224,
      1.8699991802042844,
      1.5410815483976616,
      1.6766474306939796
    ],
    "volume": 151694176337
  },
  {
    "ts": 1618704000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1530,
      "authorized": 1530,
      "funded": 366
    },
    "payments": 6,
    "payments_amount": 3335055948,
    "trades": 5003,
    "traded_amount": 1163731547127,
    "price": [
      1.6766474306939796,
      2.100403210256366,
      1.6666666666666667,
      1.8380105372236022
    ],
    "volume": 433225459003
  },
  {
    "ts": 1618790400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1539,
      "authorized": 1539,
      "funded": 371
    },
    "payments": 10,
    "payments_amount": 45475983993,
    "trades": 3751,
    "traded_amount": 520246830139,
    "price": [
      1.8380105372236022,
      2.0279601445575075,
      1.5144824038317402,
      1.9997062716607485
    ],
    "volume": 317308993117
  },
  {
    "ts": 1618876800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1549,
      "authorized": 1549,
      "funded": 370
    },
    "payments": 6,
    "payments_amount": 20629922364,
    "trades": 4047,
    "traded_amount": 444964551333,
    "price": [
      1.9997062716607485,
      2.123546671565519,
      1.8950278823102928,
      1.8988105470045888
    ],
    "volume": 235134080738
  },
  {
    "ts": 1618963200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1560,
      "authorized": 1560,
      "funded": 373
    },
    "payments": 8,
    "payments_amount": 42387399302,
    "trades": 2904,
    "traded_amount": 441962571317,
    "price": [
      1.8988105470045888,
      2.0811192009908317,
      1.7693811074918566,
      2.035811562990882
    ],
    "volume": 244326165684
  },
  {
    "ts": 1619049600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1570,
      "authorized": 1570,
      "funded": 377
    },
    "payments": 3,
    "payments_amount": 1357587707,
    "trades": 5559,
    "traded_amount": 983200400429,
    "price": [
      2.035811562990882,
      2.242217875299031,
      1.9366727661146523,
      2.2307034489896767
    ],
    "volume": 392841937190
  },
  {
    "ts": 1619136000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1583,
      "authorized": 1583,
      "funded": 378
    },
    "payments": 12,
    "payments_amount": 34283142388,
    "trades": 5418,
    "traded_amount": 1251993303560,
    "price": [
      2.2307034489896767,
      2.6285033895004015,
      2.1693150980356917,
      2.233315863719731
    ],
    "volume": 754094789381
  },
  {
    "ts": 1619222400,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1626,
      "authorized": 1626,
      "funded": 380
    },
    "payments": 22,
    "payments_amount": 164374100571,
    "trades": 2799,
    "traded_amount": 943163282041,
    "price": [
      2.233315863719731,
      2.4150375793432204,
      2.1707437410956563,
      2.35050583162283
    ],
    "volume": 293776792896
  },
  {
    "ts": 1619308800,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1654,
      "authorized": 1654,
      "funded": 385
    },
    "payments": 23,
    "payments_amount": 157216247723,
    "trades": 3811,
    "traded_amount": 817074083719,
    "price": [
      2.35050583162283,
      2.4777822728890033,
      2.204751608252528,
      2.406083251504552
    ],
    "volume": 443542864390
  },
  {
    "ts": 1619395200,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1676,
      "authorized": 1676,
      "funded": 383
    },
    "payments": 21,
    "payments_amount": 358803044607,
    "trades": 4611,
    "traded_amount": 934693016723,
    "price": [
      2.406083251504552,
      2.406083251504552,
      2.0817610062893084,
      2.08509441908931
    ],
    "volume": 364055539711
  },
  {
    "ts": 1619481600,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1695,
      "authorized": 1695,
      "funded": 391
    },
    "payments": 24,
    "payments_amount": 140474543102,
    "trades": 4662,
    "traded_amount": 775762304503,
    "price": [
      2.08509441908931,
      2.122388554230379,
      1.680192999279906,
      1.9745345347641452
    ],
    "volume": 445008849502
  },
  {
    "ts": 1619568000,
    "supply": 2982373193372,
    "trustlines": {
      "total": 1720,
      "authorized": 1720,
      "funded": 397
    },
    "payments": 34,
    "payments_amount": 983170168748,
    "trades": 7196,
    "traded_amount": 2419422135815,
    "price": [
      1.9745345347641452,
      2.2653300948189763,
      1.6943462897526502,
      1.9883297309003376
    ],
    "volume": 575387103902
  },
  {
    "ts": 1619654400,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1765,
      "authorized": 1765,
      "funded": 401
    },
    "payments": 21,
    "payments_amount": 2191108046036,
    "trades": 3284,
    "traded_amount": 1271760802105,
    "price": [
      1.9883297309003376,
      19920.31958798998,
      1.9524687073159142,
      2.0243352945385094
    ],
    "volume": 929432167956
  },
  {
    "ts": 1619740800,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1808,
      "authorized": 1808,
      "funded": 416
    },
    "payments": 32,
    "payments_amount": 78864082322,
    "trades": 3890,
    "traded_amount": 1255647901590,
    "price": [
      2.0243352945385094,
      2.23755680520385,
      1.7267908841747421,
      1.88782700553186
    ],
    "volume": 705714457804
  },
  {
    "ts": 1619827200,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1828,
      "authorized": 1828,
      "funded": 426
    },
    "payments": 32,
    "payments_amount": 173529249423,
    "trades": 2367,
    "traded_amount": 1001532536970,
    "price": [
      1.88782700553186,
      1.9508192002225455,
      1.8571058988075717,
      1.8850525670942755
    ],
    "volume": 528997776939
  },
  {
    "ts": 1619913600,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1850,
      "authorized": 1850,
      "funded": 435
    },
    "payments": 23,
    "payments_amount": 81859283214,
    "trades": 2418,
    "traded_amount": 639820051537,
    "price": [
      1.8850525670942755,
      1.9633695686718198,
      1.7878248230907177,
      1.8099567490310817
    ],
    "volume": 468764845633
  },
  {
    "ts": 1620000000,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1877,
      "authorized": 1877,
      "funded": 446
    },
    "payments": 17,
    "payments_amount": 17371396307,
    "trades": 4139,
    "traded_amount": 1588652829181,
    "price": [
      1.8099567490310817,
      1.9070288457337192,
      1.7457083798284587,
      1.7994584845018098
    ],
    "volume": 790862801701
  },
  {
    "ts": 1620086400,
    "supply": 4982373193372,
    "trustlines": {
      "total": 1910,
      "authorized": 1910,
      "funded": 443
    },
    "payments": 54,
    "payments_amount": 92617580127,
    "trades": 5622,
    "traded_amount": 1167952554038,
    "price": [
      1.7994584845018098,
      2.008038049435791,
      1.7994584845018098,
      1.9759704174106043
    ],
    "volume": 772031930403
  },
  {
    "ts": 1620172800,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2001,
      "authorized": 2001,
      "funded": 455
    },
    "payments": 103,
    "payments_amount": 179746479654,
    "trades": 6287,
    "traded_amount": 2219727353238,
    "price": [
      1.9759704174106043,
      1.9912955066831006,
      1.6649122807017545,
      1.6886954093074025
    ],
    "volume": 1404345530451
  },
  {
    "ts": 1620259200,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2098,
      "authorized": 2098,
      "funded": 482
    },
    "payments": 51,
    "payments_amount": 146551438769,
    "trades": 6779,
    "traded_amount": 2520682804428,
    "price": [
      1.6886954093074025,
      1.8174462003788263,
      1.4674820714291235,
      1.5372709333236778
    ],
    "volume": 1615022794215
  },
  {
    "ts": 1620345600,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2166,
      "authorized": 2166,
      "funded": 487
    },
    "payments": 57,
    "payments_amount": 385921531090,
    "trades": 5330,
    "traded_amount": 2056651871850,
    "price": [
      1.5372709333236778,
      1.6900000001890834,
      1.4624654863791884,
      1.5935696661431347
    ],
    "volume": 1261185285295
  },
  {
    "ts": 1620432000,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2213,
      "authorized": 2213,
      "funded": 490
    },
    "payments": 40,
    "payments_amount": 188112356203,
    "trades": 3825,
    "traded_amount": 1675487054464,
    "price": [
      1.5935696661431347,
      1.7688408080492168,
      1.5384615378481383,
      1.6281238232220776
    ],
    "volume": 802032265823
  },
  {
    "ts": 1620518400,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2264,
      "authorized": 2264,
      "funded": 502
    },
    "payments": 52,
    "payments_amount": 640391695554,
    "trades": 4123,
    "traded_amount": 1251405024400,
    "price": [
      1.6281238232220776,
      1.7688409,
      1.5893956933875406,
      1.6286367429579047
    ],
    "volume": 696074768302
  },
  {
    "ts": 1620604800,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2304,
      "authorized": 2304,
      "funded": 504
    },
    "payments": 48,
    "payments_amount": 145043133773,
    "trades": 8025,
    "traded_amount": 3382205155323,
    "price": [
      1.6286367429579047,
      1.8148491107760976,
      1.323338350333344,
      1.5118205452930662
    ],
    "volume": 1685680343657
  },
  {
    "ts": 1620691200,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2339,
      "authorized": 2339,
      "funded": 505
    },
    "payments": 86,
    "payments_amount": 214602929713,
    "trades": 5478,
    "traded_amount": 1460937729664,
    "price": [
      1.5118205452930662,
      1.6200008099953425,
      1.3666666666666667,
      1.3978313366794117
    ],
    "volume": 800061350750
  },
  {
    "ts": 1620777600,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2366,
      "authorized": 2366,
      "funded": 507
    },
    "payments": 26,
    "payments_amount": 294614313151,
    "trades": 5838,
    "traded_amount": 1211066107648,
    "price": [
      1.3978313366794117,
      1.7147096410844187,
      1.3805210233765202,
      1.6982040587283223
    ],
    "volume": 610654820284
  },
  {
    "ts": 1620864000,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2386,
      "authorized": 2386,
      "funded": 511
    },
    "payments": 27,
    "payments_amount": 178007471516,
    "trades": 8862,
    "traded_amount": 2859000460026,
    "price": [
      1.6982040587283223,
      1.8119224498070328,
      1.5385063993463397,
      1.575906551087355
    ],
    "volume": 1706268146403
  },
  {
    "ts": 1620950400,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2428,
      "authorized": 2428,
      "funded": 522
    },
    "payments": 27,
    "payments_amount": 58071213229,
    "trades": 3270,
    "traded_amount": 840679890639,
    "price": [
      1.575906551087355,
      1.575906551087355,
      1.4439999943867279,
      1.5128693108119793
    ],
    "volume": 564920390262
  },
  {
    "ts": 1621036800,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2479,
      "authorized": 2479,
      "funded": 534
    },
    "payments": 33,
    "payments_amount": 138318021083,
    "trades": 5834,
    "traded_amount": 1210423799540,
    "price": [
      1.5128693108119793,
      1.5851185671575372,
      1.3740656994737943,
      1.4346459991147307
    ],
    "volume": 743059257298
  },
  {
    "ts": 1621123200,
    "supply": 4982373193372,
    "trustlines": {
      "total": 2539,
      "authorized": 2539,
      "funded": 556
    },
    "payments": 19,
    "payments_amount": 85040454632,
    "trades": 5146,
    "traded_amount": 1337654363180,
    "price": [
      1.4346459991147307,
      1.5384615398882948,
      1.283588213702812,
      1.4369624912192236
    ],
    "volume": 806382329447
  },
  {
    "ts": 1621209600,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2580,
      "authorized": 2580,
      "funded": 567
    },
    "payments": 31,
    "payments_amount": 16161160055078,
    "trades": 5621,
    "traded_amount": 1727485843701,
    "price": [
      1.4369624912192236,
      1.619900154972105,
      1.4147840186007392,
      1.5597606310582566
    ],
    "volume": 683075638209
  },
  {
    "ts": 1621296000,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2603,
      "authorized": 2603,
      "funded": 571
    },
    "payments": 23,
    "payments_amount": 123971362042,
    "trades": 4260,
    "traded_amount": 2065222878092,
    "price": [
      1.5597606310582566,
      1.580091749944058,
      1.4556780262211226,
      1.5589234387248498
    ],
    "volume": 426844855027
  },
  {
    "ts": 1621382400,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2641,
      "authorized": 2641,
      "funded": 570
    },
    "payments": 27,
    "payments_amount": 1531283416578,
    "trades": 8502,
    "traded_amount": 5895130482406,
    "price": [
      1.5589234387248498,
      3.3055555555555554,
      1.537037037037037,
      2.2147135525591106
    ],
    "volume": 2456268897892
  },
  {
    "ts": 1621468800,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2674,
      "authorized": 2674,
      "funded": 573
    },
    "payments": 27,
    "payments_amount": 165901392981,
    "trades": 5842,
    "traded_amount": 3385263123666,
    "price": [
      2.2147135525591106,
      2.3727790359424192,
      1.8933752525682062,
      2.025571639244273
    ],
    "volume": 1094749826063
  },
  {
    "ts": 1621555200,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2697,
      "authorized": 2697,
      "funded": 582
    },
    "payments": 23,
    "payments_amount": 145913269975,
    "trades": 5848,
    "traded_amount": 4392558960605,
    "price": [
      2.025571639244273,
      2.656534401709928,
      1.9438480557839877,
      2.431891828291932
    ],
    "volume": 837902103859
  },
  {
    "ts": 1621641600,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2712,
      "authorized": 2712,
      "funded": 584
    },
    "payments": 14,
    "payments_amount": 240102173051,
    "trades": 5868,
    "traded_amount": 4821511318912,
    "price": [
      2.431891828291932,
      2.7268440000702494,
      2.3502376149136057,
      2.564125794800537
    ],
    "volume": 801037135134
  },
  {
    "ts": 1621728000,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2734,
      "authorized": 2734,
      "funded": 585
    },
    "payments": 27,
    "payments_amount": 217418238471,
    "trades": 9323,
    "traded_amount": 7747942080379,
    "price": [
      2.564125794800537,
      3.6426138304638918,
      2.4890608317622185,
      2.7237130782293804
    ],
    "volume": 2105160143138
  },
  {
    "ts": 1621814400,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2762,
      "authorized": 2762,
      "funded": 596
    },
    "payments": 46,
    "payments_amount": 32390779392,
    "trades": 7619,
    "traded_amount": 6241128905090,
    "price": [
      2.7237130782293804,
      2.7643539905484187,
      2.222222215907835,
      2.2960596503160944
    ],
    "volume": 1094180075916
  },
  {
    "ts": 1621900800,
    "supply": 14982373193372,
    "trustlines": {
      "total": 2807,
      "authorized": 2807,
      "funded": 603
    },
    "payments": 133,
    "payments_amount": 117306648186,
    "trades": 7128,
    "traded_amount": 5601427425920,
    "price": [
      2.2960596503160944,
      2.7373737373737375,
      2.1756833383571283,
      2.330291976166866
    ],
    "volume": 724551779596
  },
  {
    "ts": 1621987200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3039,
      "authorized": 3039,
      "funded": 670
    },
    "payments": 77,
    "payments_amount": 113002926309,
    "trades": 5987,
    "traded_amount": 3964334664048,
    "price": [
      2.330291976166866,
      2.5,
      2.111111111111111,
      2.258637638600849
    ],
    "volume": 1077246595094
  },
  {
    "ts": 1622073600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3132,
      "authorized": 3132,
      "funded": 685
    },
    "payments": 96,
    "payments_amount": 102703657622,
    "trades": 2300,
    "traded_amount": 1906766044842,
    "price": [
      2.258637638600849,
      2.442867680803877,
      2.2499970971345236,
      2.399163052502109
    ],
    "volume": 382652089415
  },
  {
    "ts": 1622160000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3195,
      "authorized": 3195,
      "funded": 703
    },
    "payments": 101,
    "payments_amount": 123434001277,
    "trades": 7069,
    "traded_amount": 4634248164371,
    "price": [
      2.399163052502109,
      2.7346150622320984,
      2.333466829284733,
      2.5122563273695606
    ],
    "volume": 1113259991066
  },
  {
    "ts": 1622246400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3258,
      "authorized": 3258,
      "funded": 714
    },
    "payments": 26,
    "payments_amount": 99090261645,
    "trades": 5942,
    "traded_amount": 3939952592652,
    "price": [
      2.5122563273695606,
      2.9166372085809447,
      2.441819964744938,
      2.729026717385635
    ],
    "volume": 696168803809
  },
  {
    "ts": 1622332800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3322,
      "authorized": 3322,
      "funded": 740
    },
    "payments": 39,
    "payments_amount": 25275100528,
    "trades": 4907,
    "traded_amount": 3787839040662,
    "price": [
      2.729026717385635,
      2.899669405640388,
      2.499999999702103,
      2.6728611726160065
    ],
    "volume": 867077936416
  },
  {
    "ts": 1622419200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3375,
      "authorized": 3375,
      "funded": 756
    },
    "payments": 50,
    "payments_amount": 328314868338,
    "trades": 4220,
    "traded_amount": 2662358824619,
    "price": [
      2.6728611726160065,
      3.010033646731261,
      2.4613583439237074,
      2.5
    ],
    "volume": 486706035002
  },
  {
    "ts": 1622505600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3411,
      "authorized": 3411,
      "funded": 770
    },
    "payments": 78,
    "payments_amount": 222236092972,
    "trades": 4544,
    "traded_amount": 2726328936792,
    "price": [
      2.5,
      2.584848581684935,
      2.2434883380299753,
      2.3918751818227264
    ],
    "volume": 641018365084
  },
  {
    "ts": 1622592000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3441,
      "authorized": 3441,
      "funded": 774
    },
    "payments": 25,
    "payments_amount": 10004338074,
    "trades": 3040,
    "traded_amount": 1021001295158,
    "price": [
      2.3918751818227264,
      2.465348014609829,
      2.3041003281440253,
      2.3855123330173647
    ],
    "volume": 292559646017
  },
  {
    "ts": 1622678400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3478,
      "authorized": 3478,
      "funded": 792
    },
    "payments": 44,
    "payments_amount": 227786886071,
    "trades": 3139,
    "traded_amount": 1903817693588,
    "price": [
      2.3855123330173647,
      2.4188010533243602,
      2.332214635863974,
      2.358591428503988
    ],
    "volume": 256986117760
  },
  {
    "ts": 1622764800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3505,
      "authorized": 3505,
      "funded": 797
    },
    "payments": 58,
    "payments_amount": 369038534788,
    "trades": 4979,
    "traded_amount": 3268294425211,
    "price": [
      2.358591428503988,
      2.688810597968612,
      2.3585898683603954,
      2.591632928475034
    ],
    "volume": 379556652366
  },
  {
    "ts": 1622851200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3539,
      "authorized": 3539,
      "funded": 803
    },
    "payments": 18,
    "payments_amount": 102360057470,
    "trades": 3314,
    "traded_amount": 3223725227292,
    "price": [
      2.591632928475034,
      2.737046542757425,
      2.474264972829337,
      2.665026986404318
    ],
    "volume": 207973191666
  },
  {
    "ts": 1622937600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3553,
      "authorized": 3553,
      "funded": 804
    },
    "payments": 20,
    "payments_amount": 24858907510,
    "trades": 1355,
    "traded_amount": 755604108565,
    "price": [
      2.665026986404318,
      2.6887957653286283,
      2.499999999650278,
      2.643928017667536
    ],
    "volume": 131117233658
  },
  {
    "ts": 1623024000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3567,
      "authorized": 3567,
      "funded": 807
    },
    "payments": 6,
    "payments_amount": 10554199946,
    "trades": 2841,
    "traded_amount": 2247981023997,
    "price": [
      2.643928017667536,
      2.897612646506788,
      2.5652395514780837,
      2.897612646506788
    ],
    "volume": 271379212932
  },
  {
    "ts": 1623110400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3588,
      "authorized": 3588,
      "funded": 808
    },
    "payments": 58,
    "payments_amount": 181801163328,
    "trades": 5049,
    "traded_amount": 3881020140710,
    "price": [
      2.897612646506788,
      3.219855956362162,
      2.8006873956244522,
      2.875981606285877
    ],
    "volume": 615265316692
  },
  {
    "ts": 1623196800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3613,
      "authorized": 3613,
      "funded": 814
    },
    "payments": 29,
    "payments_amount": 125305083703,
    "trades": 4017,
    "traded_amount": 2617625473530,
    "price": [
      2.875981606285877,
      3.0590243620450495,
      2.7607363595304766,
      2.76521142806579
    ],
    "volume": 372193437989
  },
  {
    "ts": 1623283200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3632,
      "authorized": 3632,
      "funded": 825
    },
    "payments": 46,
    "payments_amount": 238234135029,
    "trades": 2325,
    "traded_amount": 1541976030708,
    "price": [
      2.76521142806579,
      3.0246427073931677,
      2.743981010512038,
      2.95177919381399
    ],
    "volume": 211687505517
  },
  {
    "ts": 1623369600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3644,
      "authorized": 3644,
      "funded": 823
    },
    "payments": 47,
    "payments_amount": 42599300781,
    "trades": 2650,
    "traded_amount": 1033144220418,
    "price": [
      2.95177919381399,
      3.2013418113081897,
      2.927162823730178,
      3.146227558817773
    ],
    "volume": 288365809947
  },
  {
    "ts": 1623456000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3664,
      "authorized": 3664,
      "funded": 833
    },
    "payments": 35,
    "payments_amount": 174262926546,
    "trades": 3181,
    "traded_amount": 1289048366511,
    "price": [
      3.146227558817773,
      3.333086693078639,
      2.925961952276428,
      3.054639778681833
    ],
    "volume": 410719017085
  },
  {
    "ts": 1623542400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3688,
      "authorized": 3688,
      "funded": 841
    },
    "payments": 37,
    "payments_amount": 54942412041,
    "trades": 2532,
    "traded_amount": 1120263285605,
    "price": [
      3.054639778681833,
      3.2032560100533827,
      2.8800104965936404,
      2.9054002801236534
    ],
    "volume": 269511196757
  },
  {
    "ts": 1623628800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3700,
      "authorized": 3700,
      "funded": 848
    },
    "payments": 42,
    "payments_amount": 70794943063,
    "trades": 2233,
    "traded_amount": 1074172956293,
    "price": [
      2.9054002801236534,
      3.066635897504108,
      2.868379096162263,
      2.9263189229875097
    ],
    "volume": 193221066388
  },
  {
    "ts": 1623715200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3712,
      "authorized": 3712,
      "funded": 851
    },
    "payments": 19,
    "payments_amount": 31523592186,
    "trades": 2384,
    "traded_amount": 1043630468795,
    "price": [
      2.9263189229875097,
      3.036027675229118,
      2.841719749224178,
      3.0040958046673683
    ],
    "volume": 190425609649
  },
  {
    "ts": 1623801600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3723,
      "authorized": 3723,
      "funded": 853
    },
    "payments": 45,
    "payments_amount": 44096399909,
    "trades": 1906,
    "traded_amount": 1380987930839,
    "price": [
      3.0040958046673683,
      3.1643241618770093,
      2.93496252600122,
      3.1494330453563717
    ],
    "volume": 175155003781
  },
  {
    "ts": 1623888000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3732,
      "authorized": 3732,
      "funded": 854
    },
    "payments": 17,
    "payments_amount": 8009738498,
    "trades": 1169,
    "traded_amount": 568997016500,
    "price": [
      3.1494330453563717,
      3.2146049837970723,
      3.0345988128848704,
      3.151870706553923
    ],
    "volume": 58225724066
  },
  {
    "ts": 1623974400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3741,
      "authorized": 3741,
      "funded": 856
    },
    "payments": 24,
    "payments_amount": 114570219040,
    "trades": 2307,
    "traded_amount": 1385805550143,
    "price": [
      3.151870706553923,
      3.4808960612434787,
      3.138083374414582,
      3.365783197765242
    ],
    "volume": 415758623178
  },
  {
    "ts": 1624060800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3748,
      "authorized": 3748,
      "funded": 855
    },
    "payments": 10,
    "payments_amount": 28371844112,
    "trades": 1714,
    "traded_amount": 838885719850,
    "price": [
      3.365783197765242,
      3.4698264402233705,
      3.287654045945789,
      3.4182365170578666
    ],
    "volume": 136460858337
  },
  {
    "ts": 1624147200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3759,
      "authorized": 3759,
      "funded": 858
    },
    "payments": 36,
    "payments_amount": 13990388720,
    "trades": 1964,
    "traded_amount": 1230610497977,
    "price": [
      3.4182365170578666,
      3.6825405221205516,
      3.3476805356288857,
      3.374880896370018
    ],
    "volume": 200362716378
  },
  {
    "ts": 1624233600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3765,
      "authorized": 3765,
      "funded": 861
    },
    "payments": 25,
    "payments_amount": 148051506721,
    "trades": 664,
    "traded_amount": 511548023383,
    "price": [
      3.374880896370018,
      4.207499396912935,
      3.3281535633866226,
      4.171091945206985
    ],
    "volume": 375336290953
  },
  {
    "ts": 1624320000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3773,
      "authorized": 3773,
      "funded": 866
    },
    "payments": 20,
    "payments_amount": 146103386369,
    "trades": 1024,
    "traded_amount": 777042480316,
    "price": [
      4.171091945206985,
      4.956195160621348,
      3.32815336862415,
      4.29841248413234
    ],
    "volume": 220390158276
  },
  {
    "ts": 1624406400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3771,
      "authorized": 3771,
      "funded": 861
    },
    "payments": 10,
    "payments_amount": 47837004481,
    "trades": 314,
    "traded_amount": 272625965533,
    "price": [
      4.29841248413234,
      4.364168372089967,
      3.7737255522494078,
      3.9571582346609255
    ],
    "volume": 250970332451
  },
  {
    "ts": 1624492800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3774,
      "authorized": 3774,
      "funded": 862
    },
    "payments": 6,
    "payments_amount": 47366500000,
    "trades": 195,
    "traded_amount": 132648796450,
    "price": [
      3.9571582346609255,
      3.9571582346609255,
      3.6666666666666665,
      3.8339825466200588
    ],
    "volume": 75016434915
  },
  {
    "ts": 1624579200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3784,
      "authorized": 3784,
      "funded": 864
    },
    "payments": 5,
    "payments_amount": 4022526218,
    "trades": 132,
    "traded_amount": 92089241270,
    "price": [
      3.8339825466200588,
      4.0894736,
      3.7312270296445544,
      3.932553139868717
    ],
    "volume": 14083826711
  },
  {
    "ts": 1624665600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3783,
      "authorized": 3783,
      "funded": 864
    },
    "payments": 11,
    "payments_amount": 337111506,
    "trades": 152,
    "traded_amount": 120066011101,
    "price": [
      3.932553139868717,
      4.779612102129397,
      3.932553139868717,
      4.185314685314685
    ],
    "volume": 30891778830
  },
  {
    "ts": 1624752000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3787,
      "authorized": 3787,
      "funded": 867
    },
    "payments": 8,
    "payments_amount": 25190117047,
    "trades": 122,
    "traded_amount": 61145252283,
    "price": [
      4.185314685314685,
      4.1958421266466726,
      3.8968653907029056,
      3.910106653123413
    ],
    "volume": 15745457580
  },
  {
    "ts": 1624838400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3786,
      "authorized": 3786,
      "funded": 865
    },
    "payments": 10,
    "payments_amount": 286193484843,
    "trades": 223,
    "traded_amount": 184895756261,
    "price": [
      3.910106653123413,
      3.931339933117241,
      3.759999220013629,
      3.7693578992471077
    ],
    "volume": 27171621541
  },
  {
    "ts": 1624924800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3786,
      "authorized": 3786,
      "funded": 870
    },
    "payments": 14,
    "payments_amount": 474487779920,
    "trades": 548,
    "traded_amount": 413301368092,
    "price": [
      3.7693578992471077,
      3.7844241007120725,
      3.41999962936991,
      3.5410000041328495
    ],
    "volume": 300820506570
  },
  {
    "ts": 1625011200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3790,
      "authorized": 3790,
      "funded": 871
    },
    "payments": 10,
    "payments_amount": 84614931598,
    "trades": 195,
    "traded_amount": 281556184205,
    "price": [
      3.5410000041328495,
      3.739303960196422,
      3.4682539682539684,
      3.5750208568484076
    ],
    "volume": 290368620086
  },
  {
    "ts": 1625097600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3794,
      "authorized": 3794,
      "funded": 875
    },
    "payments": 15,
    "payments_amount": 56123771616,
    "trades": 370,
    "traded_amount": 180170376798,
    "price": [
      3.5750208568484076,
      3.841076090344345,
      3.5750208568484076,
      3.6967443
    ],
    "volume": 57318343048
  },
  {
    "ts": 1625184000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3804,
      "authorized": 3804,
      "funded": 874
    },
    "payments": 10,
    "payments_amount": 15626743951,
    "trades": 127,
    "traded_amount": 40668845113,
    "price": [
      3.6967443,
      3.9284994056576763,
      3.6967443,
      3.8423389895361875
    ],
    "volume": 8070334442
  },
  {
    "ts": 1625270400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3814,
      "authorized": 3814,
      "funded": 875
    },
    "payments": 9,
    "payments_amount": 29460118217,
    "trades": 249,
    "traded_amount": 91500707164,
    "price": [
      3.8423389895361875,
      3.867451512191627,
      3.731999942822555,
      3.840000000730217
    ],
    "volume": 59567825998
  },
  {
    "ts": 1625356800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3818,
      "authorized": 3818,
      "funded": 875
    },
    "payments": 4,
    "payments_amount": 90418163152,
    "trades": 184,
    "traded_amount": 143781119345,
    "price": [
      3.840000000730217,
      3.8400000943879853,
      3.648609763060234,
      3.6758490566037736
    ],
    "volume": 8354937922
  },
  {
    "ts": 1625443200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3826,
      "authorized": 3826,
      "funded": 876
    },
    "payments": 12,
    "payments_amount": 62825504348,
    "trades": 166,
    "traded_amount": 144938453597,
    "price": [
      3.6758490566037736,
      3.9428729086655188,
      3.6758490566037736,
      3.909038550145907
    ],
    "volume": 20810576377
  },
  {
    "ts": 1625529600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3844,
      "authorized": 3844,
      "funded": 876
    },
    "payments": 20,
    "payments_amount": 77903193375,
    "trades": 331,
    "traded_amount": 175671670222,
    "price": [
      3.909038550145907,
      3.927917857952284,
      3.75,
      3.9061627357128192
    ],
    "volume": 11005275511
  },
  {
    "ts": 1625616000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3842,
      "authorized": 3842,
      "funded": 875
    },
    "payments": 1,
    "payments_amount": 886000000,
    "trades": 216,
    "traded_amount": 199080097669,
    "price": [
      3.9061627357128192,
      3.920000048585653,
      3.7618088709924766,
      3.920000048585653
    ],
    "volume": 9478951455
  },
  {
    "ts": 1625702400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3847,
      "authorized": 3847,
      "funded": 871
    },
    "payments": 6,
    "payments_amount": 34390929457,
    "trades": 370,
    "traded_amount": 178648612444,
    "price": [
      3.920000048585653,
      4.183729314340016,
      3.8847691952456285,
      4.110999837516248
    ],
    "volume": 26350682024
  },
  {
    "ts": 1625788800,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3851,
      "authorized": 3851,
      "funded": 870
    },
    "payments": 7,
    "payments_amount": 13601060541,
    "trades": 282,
    "traded_amount": 140562067174,
    "price": [
      4.110999837516248,
      4.245379786011141,
      4,
      4
    ],
    "volume": 36868394359
  },
  {
    "ts": 1625875200,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3862,
      "authorized": 3862,
      "funded": 875
    },
    "payments": 13,
    "payments_amount": 20682747518,
    "trades": 308,
    "traded_amount": 154661752909,
    "price": [
      4,
      4.197958421402675,
      3.962973956775998,
      4.087816266408219
    ],
    "volume": 41095069543
  },
  {
    "ts": 1625961600,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3875,
      "authorized": 3875,
      "funded": 877
    },
    "payments": 3,
    "payments_amount": 16390000000,
    "trades": 228,
    "traded_amount": 56338401192,
    "price": [
      4.087816266408219,
      4.118534550395926,
      4.025033614285714,
      4.056911979149488
    ],
    "volume": 6063674745
  },
  {
    "ts": 1626048000,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3882,
      "authorized": 3882,
      "funded": 887
    },
    "payments": 19,
    "payments_amount": 188380136745,
    "trades": 435,
    "traded_amount": 184610948672,
    "price": [
      4.056911979149488,
      4.28000000358754,
      3.9338102089073836,
      4.2659769999387995
    ],
    "volume": 102938749085
  },
  {
    "ts": 1626134400,
    "supply": 14982123193372,
    "trustlines": {
      "total": 3883,
      "authorized": 3883,
      "funded": 888
    },
    "payments": 5,
    "payments_amount": 28756835938,
    "trades": 1277,
    "traded_amount": 498108250220,
    "price": [
      4.2659769999387995,
      4.391976482586454,
      4.12,
      4.310873216315074
    ],
    "volume": 105589218407
  },
  {
    "ts": 1626220800,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3888,
      "authorized": 3888,
      "funded": 889
    },
    "payments": 19,
    "payments_amount": 220910365951,
    "trades": 2393,
    "traded_amount": 1252533493894,
    "price": [
      4.310873216315074,
      4.570564508514648,
      4.114853795621518,
      4.163006856516995
    ],
    "volume": 282655237464
  },
  {
    "ts": 1626307200,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3898,
      "authorized": 3898,
      "funded": 892
    },
    "payments": 20,
    "payments_amount": 35338895533,
    "trades": 2108,
    "traded_amount": 1202178127125,
    "price": [
      4.163006856516995,
      4.4100006961211395,
      4.117817982673709,
      4.170254091418092
    ],
    "volume": 199438146660
  },
  {
    "ts": 1626393600,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3904,
      "authorized": 3904,
      "funded": 897
    },
    "payments": 17,
    "payments_amount": 7934926929,
    "trades": 2918,
    "traded_amount": 996939108933,
    "price": [
      4.170254091418092,
      4.261316872427984,
      3.890402529314042,
      4.225112441817896
    ],
    "volume": 351037331003
  },
  {
    "ts": 1626480000,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3911,
      "authorized": 3911,
      "funded": 898
    },
    "payments": 27,
    "payments_amount": 12458043246,
    "trades": 1839,
    "traded_amount": 1107669783103,
    "price": [
      4.225112441817896,
      4.3719799242347515,
      4.212418963377142,
      4.303444400656839
    ],
    "volume": 151146094613
  },
  {
    "ts": 1626566400,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3918,
      "authorized": 3918,
      "funded": 902
    },
    "payments": 23,
    "payments_amount": 14387524154,
    "trades": 1441,
    "traded_amount": 755000654694,
    "price": [
      4.303444400656839,
      4.430019905542996,
      4.184226386078301,
      4.326138161857273
    ],
    "volume": 118091882458
  },
  {
    "ts": 1626652800,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3928,
      "authorized": 3928,
      "funded": 900
    },
    "payments": 9,
    "payments_amount": 10346660121,
    "trades": 2114,
    "traded_amount": 943612959365,
    "price": [
      4.326138161857273,
      4.725381352929143,
      4.314248093252753,
      4.725381352929143
    ],
    "volume": 394635881795
  },
  {
    "ts": 1626739200,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3935,
      "authorized": 3935,
      "funded": 901
    },
    "payments": 13,
    "payments_amount": 19677472196,
    "trades": 2900,
    "traded_amount": 1340936182746,
    "price": [
      4.725381352929143,
      5.021959775125112,
      4.650825381020011,
      4.711358614483658
    ],
    "volume": 247034998892
  },
  {
    "ts": 1626825600,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3939,
      "authorized": 3939,
      "funded": 910
    },
    "payments": 38,
    "payments_amount": 28585996958,
    "trades": 2527,
    "traded_amount": 1097364161376,
    "price": [
      4.711358614483658,
      4.832745388471833,
      4.325922246778802,
      4.405147544930665
    ],
    "volume": 271396515943
  },
  {
    "ts": 1626912000,
    "supply": 14982123193371,
    "trustlines": {
      "total": 3949,
      "authorized": 3949,
      "funded": 918
    },
    "payments": 63,
    "payments_amount": 31351902347,
    "trades": 2912,
    "traded_amount": 665641483382,
    "price": [
      4.405147544930665,
      4.773032190107729,
      3.7425648346419225,
      3.8098181177955497
    ],
    "volume": 561312952132
  },
  {
    "ts": 1626998400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3956,
      "authorized": 3956,
      "funded": 921
    },
    "payments": 25,
    "payments_amount": 28829737099,
    "trades": 2307,
    "traded_amount": 774941311814,
    "price": [
      3.8098181177955497,
      4.000000133311319,
      3.61502408181357,
      3.7152978627058304
    ],
    "volume": 511707265816
  },
  {
    "ts": 1627084800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3960,
      "authorized": 3960,
      "funded": 919
    },
    "payments": 20,
    "payments_amount": 10903711419,
    "trades": 1269,
    "traded_amount": 414736576292,
    "price": [
      3.7152978627058304,
      3.841746295970004,
      3.6835071140628983,
      3.753120600141321
    ],
    "volume": 169350840606
  },
  {
    "ts": 1627171200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3960,
      "authorized": 3960,
      "funded": 917
    },
    "payments": 25,
    "payments_amount": 16470924189,
    "trades": 1476,
    "traded_amount": 603342567982,
    "price": [
      3.753120600141321,
      3.961572182182926,
      3.7521951452742734,
      3.8304021061825244
    ],
    "volume": 115600621655
  },
  {
    "ts": 1627257600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3964,
      "authorized": 3964,
      "funded": 917
    },
    "payments": 68,
    "payments_amount": 33015377603,
    "trades": 5048,
    "traded_amount": 3633808320959,
    "price": [
      3.8304021061825244,
      3.903788374186504,
      3.512921872815382,
      3.843772231360074
    ],
    "volume": 562350922606
  },
  {
    "ts": 1627344000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3971,
      "authorized": 3971,
      "funded": 918
    },
    "payments": 63,
    "payments_amount": 45398161231,
    "trades": 3379,
    "traded_amount": 2755272318789,
    "price": [
      3.843772231360074,
      3.9942801852827183,
      3.7230197289114004,
      3.8054068936222096
    ],
    "volume": 225431375981
  },
  {
    "ts": 1627430400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3975,
      "authorized": 3975,
      "funded": 918
    },
    "payments": 61,
    "payments_amount": 226827698869,
    "trades": 2823,
    "traded_amount": 1640453875147,
    "price": [
      3.8054068936222096,
      3.8738895449786663,
      3.5696734071213307,
      3.7065134982602492
    ],
    "volume": 364919491446
  },
  {
    "ts": 1627516800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3987,
      "authorized": 3987,
      "funded": 920
    },
    "payments": 31,
    "payments_amount": 35271158395,
    "trades": 1543,
    "traded_amount": 800823460844,
    "price": [
      3.7065134982602492,
      3.8005551149881045,
      3.6327216088240015,
      3.6906360033699475
    ],
    "volume": 130317570182
  },
  {
    "ts": 1627603200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 3998,
      "authorized": 3998,
      "funded": 921
    },
    "payments": 61,
    "payments_amount": 48843662295,
    "trades": 2265,
    "traded_amount": 1252139633517,
    "price": [
      3.6906360033699475,
      3.808332233647863,
      3.5301149010236004,
      3.615500220350249
    ],
    "volume": 310784611391
  },
  {
    "ts": 1627689600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4006,
      "authorized": 4006,
      "funded": 924
    },
    "payments": 16,
    "payments_amount": 18142298553,
    "trades": 1698,
    "traded_amount": 733276638253,
    "price": [
      3.615500220350249,
      3.6512983702475683,
      3.4450528325770775,
      3.5123367680258157
    ],
    "volume": 200422076276
  },
  {
    "ts": 1627776000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4012,
      "authorized": 4012,
      "funded": 926
    },
    "payments": 31,
    "payments_amount": 41706012279,
    "trades": 3004,
    "traded_amount": 1185789067406,
    "price": [
      3.5123367680258157,
      3.673173607144997,
      3.3394243240477444,
      3.6005024230095484
    ],
    "volume": 317696289607
  },
  {
    "ts": 1627862400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4015,
      "authorized": 4015,
      "funded": 931
    },
    "payments": 76,
    "payments_amount": 105437699748,
    "trades": 2021,
    "traded_amount": 1039997157165,
    "price": [
      3.6005024230095484,
      3.706820356604803,
      3.5,
      3.642153999012248
    ],
    "volume": 231435523004
  },
  {
    "ts": 1627948800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4018,
      "authorized": 4018,
      "funded": 930
    },
    "payments": 67,
    "payments_amount": 25323450122,
    "trades": 2215,
    "traded_amount": 1532273394774,
    "price": [
      3.642153999012248,
      3.771291731279262,
      3.5802421780445224,
      3.6596661150936365
    ],
    "volume": 159829324303
  },
  {
    "ts": 1628035200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4022,
      "authorized": 4022,
      "funded": 931
    },
    "payments": 26,
    "payments_amount": 32473478825,
    "trades": 1604,
    "traded_amount": 455818744278,
    "price": [
      3.6596661150936365,
      3.781052072135342,
      3.5239676416409558,
      3.5687284459954136
    ],
    "volume": 191257329152
  },
  {
    "ts": 1628121600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4020,
      "authorized": 4020,
      "funded": 932
    },
    "payments": 33,
    "payments_amount": 6635712654,
    "trades": 1956,
    "traded_amount": 1187249772090,
    "price": [
      3.5687284459954136,
      3.7900000035854577,
      3.5499091258335946,
      3.566162381322481
    ],
    "volume": 176976341021
  },
  {
    "ts": 1628208000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4023,
      "authorized": 4023,
      "funded": 933
    },
    "payments": 59,
    "payments_amount": 12067135340,
    "trades": 1581,
    "traded_amount": 785273675915,
    "price": [
      3.566162381322481,
      3.6476259258637196,
      3.49735404500404,
      3.514943051614955
    ],
    "volume": 96069323226
  },
  {
    "ts": 1628294400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4031,
      "authorized": 4031,
      "funded": 935
    },
    "payments": 46,
    "payments_amount": 11288340399,
    "trades": 3351,
    "traded_amount": 1809686388193,
    "price": [
      3.514943051614955,
      3.559655390889843,
      3.195222802632941,
      3.2679738160370517
    ],
    "volume": 442640791533
  },
  {
    "ts": 1628380800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4040,
      "authorized": 4040,
      "funded": 942
    },
    "payments": 30,
    "payments_amount": 8513052772,
    "trades": 3083,
    "traded_amount": 2266433111643,
    "price": [
      3.2679738160370517,
      3.5044247583897428,
      3.212189122747667,
      3.47564
    ],
    "volume": 267677281678
  },
  {
    "ts": 1628467200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4049,
      "authorized": 4049,
      "funded": 945
    },
    "payments": 93,
    "payments_amount": 270581001696,
    "trades": 3242,
    "traded_amount": 2027895646900,
    "price": [
      3.47564,
      3.5588370686588404,
      3.2754901135393264,
      3.3515704485370192
    ],
    "volume": 313123594685
  },
  {
    "ts": 1628553600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4058,
      "authorized": 4058,
      "funded": 943
    },
    "payments": 36,
    "payments_amount": 25091587227,
    "trades": 2012,
    "traded_amount": 1754757352404,
    "price": [
      3.3515704485370192,
      3.408742330848269,
      3.1896156030027907,
      3.2273196791492413
    ],
    "volume": 153440464940
  },
  {
    "ts": 1628640000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4067,
      "authorized": 4067,
      "funded": 943
    },
    "payments": 629,
    "payments_amount": 1031244792793,
    "trades": 2573,
    "traded_amount": 1109298267385,
    "price": [
      3.2273196791492413,
      3.229319118052881,
      2.850949908421744,
      2.9629908704568257
    ],
    "volume": 384696571261
  },
  {
    "ts": 1628726400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4077,
      "authorized": 4077,
      "funded": 948
    },
    "payments": 88,
    "payments_amount": 41396355807,
    "trades": 2331,
    "traded_amount": 1270783320792,
    "price": [
      2.9629908704568257,
      3.168958304145907,
      2.8219999969154843,
      3.071969355099824
    ],
    "volume": 330222630089
  },
  {
    "ts": 1628812800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4087,
      "authorized": 4087,
      "funded": 953
    },
    "payments": 94,
    "payments_amount": 56689998274,
    "trades": 1889,
    "traded_amount": 810139198461,
    "price": [
      3.071969355099824,
      3.078639838327036,
      2.7689699842848,
      2.790652591152394
    ],
    "volume": 254202318906
  },
  {
    "ts": 1628899200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4093,
      "authorized": 4093,
      "funded": 951
    },
    "payments": 86,
    "payments_amount": 6866583678,
    "trades": 3977,
    "traded_amount": 1216672260512,
    "price": [
      2.790652591152394,
      2.8600508926482946,
      2.5137715649724806,
      2.5450563800366868
    ],
    "volume": 616222918516
  },
  {
    "ts": 1628985600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4107,
      "authorized": 4107,
      "funded": 966
    },
    "payments": 89,
    "payments_amount": 7681342018,
    "trades": 3743,
    "traded_amount": 911464032382,
    "price": [
      2.5450563800366868,
      2.698313567061966,
      2.4999999902874976,
      2.556039451774049
    ],
    "volume": 544273539061
  },
  {
    "ts": 1629072000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4132,
      "authorized": 4132,
      "funded": 970
    },
    "payments": 95,
    "payments_amount": 12816661025,
    "trades": 3691,
    "traded_amount": 1164958538130,
    "price": [
      2.556039451774049,
      2.70160950261698,
      2.450269481547691,
      2.6873376741675425
    ],
    "volume": 810783443784
  },
  {
    "ts": 1629158400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4165,
      "authorized": 4165,
      "funded": 985
    },
    "payments": 118,
    "payments_amount": 555851510605,
    "trades": 4759,
    "traded_amount": 1610995327494,
    "price": [
      2.6873376741675425,
      2.9738215549997222,
      2.6125644294186112,
      2.927331602701019
    ],
    "volume": 779369214205
  },
  {
    "ts": 1629244800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4183,
      "authorized": 4183,
      "funded": 987
    },
    "payments": 99,
    "payments_amount": 40961024029,
    "trades": 4442,
    "traded_amount": 1553688879343,
    "price": [
      2.927331602701019,
      3.0825284468865872,
      2.794625051390847,
      2.9181626363449857
    ],
    "volume": 747689314867
  },
  {
    "ts": 1629331200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4203,
      "authorized": 4203,
      "funded": 994
    },
    "payments": 140,
    "payments_amount": 16093042332,
    "trades": 4850,
    "traded_amount": 1242094609698,
    "price": [
      2.9181626363449857,
      3.0171428571428573,
      2.718457846646179,
      2.7241379310344827
    ],
    "volume": 719094696524
  },
  {
    "ts": 1629417600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4227,
      "authorized": 4227,
      "funded": 1004
    },
    "payments": 125,
    "payments_amount": 100910187827,
    "trades": 3504,
    "traded_amount": 1005291588228,
    "price": [
      2.7241379310344827,
      2.7814652593620623,
      2.583979321677117,
      2.6046742376149163
    ],
    "volume": 580403665398
  },
  {
    "ts": 1629504000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4242,
      "authorized": 4242,
      "funded": 1010
    },
    "payments": 115,
    "payments_amount": 25648488159,
    "trades": 2589,
    "traded_amount": 696851174677,
    "price": [
      2.6046742376149163,
      2.715347415125028,
      2.6046742376149163,
      2.68877437973859
    ],
    "volume": 272387146303
  },
  {
    "ts": 1629590400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4261,
      "authorized": 4261,
      "funded": 1010
    },
    "payments": 113,
    "payments_amount": 116190636596,
    "trades": 2036,
    "traded_amount": 606702200181,
    "price": [
      2.68877437973859,
      2.7738095238095237,
      2.577353894251185,
      2.6952402653955216
    ],
    "volume": 250858543105
  },
  {
    "ts": 1629676800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4279,
      "authorized": 4279,
      "funded": 1017
    },
    "payments": 95,
    "payments_amount": 38539436498,
    "trades": 2323,
    "traded_amount": 1656601607061,
    "price": [
      2.6952402653955216,
      2.7159811193526635,
      2.591521794832634,
      2.6653958656569205
    ],
    "volume": 328791300689
  },
  {
    "ts": 1629763200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4308,
      "authorized": 4308,
      "funded": 1022
    },
    "payments": 97,
    "payments_amount": 45955876724,
    "trades": 5660,
    "traded_amount": 1238323859972,
    "price": [
      2.6653958656569205,
      2.9500000017514227,
      2.6440677966101696,
      2.8524725182952184
    ],
    "volume": 975974681033
  },
  {
    "ts": 1629849600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4338,
      "authorized": 4338,
      "funded": 1022
    },
    "payments": 112,
    "payments_amount": 95205829257,
    "trades": 3630,
    "traded_amount": 592833655655,
    "price": [
      2.8524725182952184,
      2.929830273667949,
      2.7704024484377774,
      2.7941110613524405
    ],
    "volume": 303794504970
  },
  {
    "ts": 1629936000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4357,
      "authorized": 4357,
      "funded": 1024
    },
    "payments": 108,
    "payments_amount": 56147964506,
    "trades": 3293,
    "traded_amount": 555045641029,
    "price": [
      2.7941110613524405,
      3.013350164423226,
      2.768457339844872,
      2.94926869991118
    ],
    "volume": 185379667295
  },
  {
    "ts": 1630022400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4393,
      "authorized": 4393,
      "funded": 1036
    },
    "payments": 114,
    "payments_amount": 1252817664249,
    "trades": 3045,
    "traded_amount": 693933455749,
    "price": [
      2.94926869991118,
      3.0150533396787127,
      2.817837794755536,
      2.817838669452473
    ],
    "volume": 326617092116
  },
  {
    "ts": 1630108800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4411,
      "authorized": 4411,
      "funded": 1040
    },
    "payments": 101,
    "payments_amount": 1175739677661,
    "trades": 3121,
    "traded_amount": 413870826194,
    "price": [
      2.817838669452473,
      2.9712489121741323,
      2.7956043397146773,
      2.907393850658858
    ],
    "volume": 177215107992
  },
  {
    "ts": 1630195200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4423,
      "authorized": 4423,
      "funded": 1044
    },
    "payments": 131,
    "payments_amount": 6293814851,
    "trades": 4425,
    "traded_amount": 841042877363,
    "price": [
      2.907393850658858,
      3.088000004689662,
      2.8274384341195975,
      2.9092053886992097
    ],
    "volume": 278773643127
  },
  {
    "ts": 1630281600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4434,
      "authorized": 4434,
      "funded": 1043
    },
    "payments": 112,
    "payments_amount": 34667630927,
    "trades": 3633,
    "traded_amount": 680840426630,
    "price": [
      2.9092053886992097,
      3.03377949604116,
      2.884082975424465,
      3.03377949604116
    ],
    "volume": 241951816087
  },
  {
    "ts": 1630368000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4445,
      "authorized": 4445,
      "funded": 1047
    },
    "payments": 175,
    "payments_amount": 104559492747,
    "trades": 5072,
    "traded_amount": 1746755813722,
    "price": [
      3.03377949604116,
      3.1100000001930597,
      2.8320727006961564,
      2.9177263591747145
    ],
    "volume": 407923791555
  },
  {
    "ts": 1630454400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4451,
      "authorized": 4451,
      "funded": 1047
    },
    "payments": 239,
    "payments_amount": 39329789404,
    "trades": 3967,
    "traded_amount": 1072488771114,
    "price": [
      2.9177263591747145,
      2.995701949751885,
      2.786964609418951,
      2.852883198057654
    ],
    "volume": 288485768639
  },
  {
    "ts": 1630540800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4474,
      "authorized": 4474,
      "funded": 1051
    },
    "payments": 250,
    "payments_amount": 34186068404,
    "trades": 4751,
    "traded_amount": 1249553910844,
    "price": [
      2.852883198057654,
      2.8800960068762853,
      2.7242517526371652,
      2.773972886080682
    ],
    "volume": 519721368372
  },
  {
    "ts": 1630627200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4487,
      "authorized": 4487,
      "funded": 1052
    },
    "payments": 167,
    "payments_amount": 495232474814,
    "trades": 4491,
    "traded_amount": 1689511800377,
    "price": [
      2.773972886080682,
      2.8388786551400496,
      2.643448367342427,
      2.7220229410700787
    ],
    "volume": 171186979365
  },
  {
    "ts": 1630713600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4498,
      "authorized": 4498,
      "funded": 1060
    },
    "payments": 123,
    "payments_amount": 67096801639,
    "trades": 4092,
    "traded_amount": 639492133584,
    "price": [
      2.7220229410700787,
      2.731707317073171,
      2.6134860136337688,
      2.6892795216597714
    ],
    "volume": 148242046189
  },
  {
    "ts": 1630800000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4507,
      "authorized": 4507,
      "funded": 1059
    },
    "payments": 146,
    "payments_amount": 66033339504,
    "trades": 3760,
    "traded_amount": 556837066236,
    "price": [
      2.6892795216597714,
      2.717429250263394,
      2.5125616444589114,
      2.518128091594568
    ],
    "volume": 157105546003
  },
  {
    "ts": 1630886400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4513,
      "authorized": 4513,
      "funded": 1060
    },
    "payments": 207,
    "payments_amount": 124771944287,
    "trades": 4879,
    "traded_amount": 931669730723,
    "price": [
      2.518128091594568,
      2.576530604076492,
      2.3255813883832466,
      2.36846425489095
    ],
    "volume": 352951143948
  },
  {
    "ts": 1630972800,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4535,
      "authorized": 4535,
      "funded": 1068
    },
    "payments": 322,
    "payments_amount": 70835998327,
    "trades": 9967,
    "traded_amount": 5238775243162,
    "price": [
      2.36846425489095,
      3.4145397301453255,
      2.36846425489095,
      2.9961942679258433
    ],
    "volume": 1328571337711
  },
  {
    "ts": 1631059200,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4540,
      "authorized": 4540,
      "funded": 1069
    },
    "payments": 189,
    "payments_amount": 27918229109,
    "trades": 7615,
    "traded_amount": 3556113851832,
    "price": [
      2.9961942679258433,
      3.2652094345600373,
      2.904752520369264,
      3.049019030802433
    ],
    "volume": 551205320419
  },
  {
    "ts": 1631145600,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4552,
      "authorized": 4552,
      "funded": 1069
    },
    "payments": 194,
    "payments_amount": 23315358016,
    "trades": 5719,
    "traded_amount": 1928593225054,
    "price": [
      3.049019030802433,
      3.098209433208382,
      2.93758414649446,
      3.0259496372172427
    ],
    "volume": 415616090207
  },
  {
    "ts": 1631232000,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4565,
      "authorized": 4565,
      "funded": 1068
    },
    "payments": 199,
    "payments_amount": 266466202120,
    "trades": 6680,
    "traded_amount": 2862180024965,
    "price": [
      3.0259496372172427,
      3.211881167131244,
      2.8009382518648134,
      3.1579976868676014
    ],
    "volume": 628866672542
  },
  {
    "ts": 1631318400,
    "supply": 14982123193148,
    "trustlines": {
      "total": 4589,
      "authorized": 4589,
      "funded": 1071
    },
    "payments": 113,
    "payments_amount": 45312169647,
    "trades": 4511,
    "traded_amount": 1347199524232,
    "price": [
      3.1579976868676014,
      3.180647921889895,
      2.9846301496187,
      3.089801821410161
    ],
    "volume": 212956896984
  },
  {
    "ts": 1631404800,
    "supply": 14982123193038,
    "trustlines": {
      "total": 4596,
      "authorized": 4596,
      "funded": 1072
    },
    "payments": 120,
    "payments_amount": 304993255692,
    "trades": 3372,
    "traded_amount": 1055142143875,
    "price": [
      3.089801821410161,
      3.1669128915811418,
      2.9535824276333185,
      3.0027243589307493
    ],
    "volume": 177095639730
  },
  {
    "ts": 1631491200,
    "supply": 14982123193038,
    "trustlines": {
      "total": 4606,
      "authorized": 4606,
      "funded": 1074
    },
    "payments": 137,
    "payments_amount": 105079230431,
    "trades": 5932,
    "traded_amount": 2345233650195,
    "price": [
      3.0027243589307493,
      3.260980118925084,
      2.952051829365606,
      3.1447422510893692
    ],
    "volume": 374334087152
  },
  {
    "ts": 1631577600,
    "supply": 14982123193038,
    "trustlines": {
      "total": 4620,
      "authorized": 4620,
      "funded": 1083
    },
    "payments": 104,
    "payments_amount": 6949101613,
    "trades": 4945,
    "traded_amount": 1215731154623,
    "price": [
      3.1447422510893692,
      3.173677415884457,
      3.024666081562253,
      3.024666081562253
    ],
    "volume": 307079072290
  },
  {
    "ts": 1631664000,
    "supply": 14982123193038,
    "trustlines": {
      "total": 4636,
      "authorized": 4636,
      "funded": 1079
    },
    "payments": 156,
    "payments_amount": 30770152287,
    "trades": 2804,
    "traded_amount": 800875863706,
    "price": [
      3.024666081562253,
      3.0695545779745395,
      2.9416691259900687,
      2.9493978606537934
    ],
    "volume": 154613584811
  },
  {
    "ts": 1631750400,
    "supply": 14982123193038,
    "trustlines": {
      "total": 4653,
      "authorized": 4653,
      "funded": 1082
    },
    "payments": 154,
    "payments_amount": 35034455256,
    "trades": 4145,
    "traded_amount": 1199588447816,
    "price": [
      2.9493978606537934,
      3.1043857448676566,
      2.933695819103268,
      3.0230560377442472
    ],
    "volume": 308364612664
  },
  {
    "ts": 1631836800,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4660,
      "authorized": 4660,
      "funded": 1082
    },
    "payments": 127,
    "payments_amount": 184542930319,
    "trades": 4403,
    "traded_amount": 1220462098973,
    "price": [
      3.0230560377442472,
      3.200001233763163,
      3.015492073886115,
      3.1457327167056905
    ],
    "volume": 262688880431
  },
  {
    "ts": 1631923200,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4679,
      "authorized": 4679,
      "funded": 1087
    },
    "payments": 105,
    "payments_amount": 36161583757,
    "trades": 3077,
    "traded_amount": 828991430847,
    "price": [
      3.1457327167056905,
      3.179703903206922,
      3.0228599101429245,
      3.106535934571097
    ],
    "volume": 162917290199
  },
  {
    "ts": 1632009600,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4689,
      "authorized": 4689,
      "funded": 1090
    },
    "payments": 116,
    "payments_amount": 36811396706,
    "trades": 1852,
    "traded_amount": 594809973172,
    "price": [
      3.106535934571097,
      3.220021430486891,
      3.0999734345035987,
      3.201259417797627
    ],
    "volume": 87294344807
  },
  {
    "ts": 1632096000,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4713,
      "authorized": 4713,
      "funded": 1094
    },
    "payments": 201,
    "payments_amount": 64845232272,
    "trades": 6769,
    "traded_amount": 4356910583125,
    "price": [
      3.201259417797627,
      3.693792303642042,
      3.200048459500919,
      3.555493280432973
    ],
    "volume": 522990399957
  },
  {
    "ts": 1632182400,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4723,
      "authorized": 4723,
      "funded": 1094
    },
    "payments": 220,
    "payments_amount": 213241298422,
    "trades": 6989,
    "traded_amount": 4681694740733,
    "price": [
      3.555493280432973,
      3.839011828448991,
      3.4680510426692823,
      3.7602138220694923
    ],
    "volume": 553040877235
  },
  {
    "ts": 1632268800,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4735,
      "authorized": 4735,
      "funded": 1100
    },
    "payments": 107,
    "payments_amount": 40726323367,
    "trades": 5253,
    "traded_amount": 2294171137395,
    "price": [
      3.7602138220694923,
      3.8461538461538463,
      3.4220522886626656,
      3.449152622463381
    ],
    "volume": 372664361313
  },
  {
    "ts": 1632355200,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4763,
      "authorized": 4763,
      "funded": 1107
    },
    "payments": 161,
    "payments_amount": 24080797133,
    "trades": 3073,
    "traded_amount": 945567397066,
    "price": [
      3.449152622463381,
      3.4687659758477176,
      3.314524743039521,
      3.329076790336497
    ],
    "volume": 220981608089
  },
  {
    "ts": 1632441600,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4791,
      "authorized": 4791,
      "funded": 1119
    },
    "payments": 66,
    "payments_amount": 94501938389,
    "trades": 5392,
    "traded_amount": 4009988748539,
    "price": [
      3.329076790336497,
      3.709880701279854,
      3.276538598186733,
      3.575380547703338
    ],
    "volume": 289111745369
  },
  {
    "ts": 1632528000,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4798,
      "authorized": 4798,
      "funded": 1117
    },
    "payments": 28,
    "payments_amount": 371786905818,
    "trades": 4033,
    "traded_amount": 2865847773791,
    "price": [
      3.575380547703338,
      3.7310385383579217,
      3.5063044345028858,
      3.6098565250554815
    ],
    "volume": 357438054558
  },
  {
    "ts": 1632614400,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4822,
      "authorized": 4822,
      "funded": 1124
    },
    "payments": 64,
    "payments_amount": 156665859275,
    "trades": 5994,
    "traded_amount": 3604613606771,
    "price": [
      3.6098565250554815,
      3.8385482446561006,
      3.5153923386224326,
      3.631617401516391
    ],
    "volume": 509136348905
  },
  {
    "ts": 1632700800,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4838,
      "authorized": 4838,
      "funded": 1132
    },
    "payments": 34,
    "payments_amount": 27835759146,
    "trades": 4699,
    "traded_amount": 2053105708783,
    "price": [
      3.631617401516391,
      3.7562906164945926,
      3.5335667456744333,
      3.754824646746438
    ],
    "volume": 422056795240
  },
  {
    "ts": 1632787200,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4850,
      "authorized": 4850,
      "funded": 1138
    },
    "payments": 39,
    "payments_amount": 53361798711,
    "trades": 4851,
    "traded_amount": 2166526003699,
    "price": [
      3.754824646746438,
      3.887317881138262,
      3.706735322948745,
      3.884584768863267
    ],
    "volume": 229089979455
  },
  {
    "ts": 1632873600,
    "supply": 14982123193014,
    "trustlines": {
      "total": 4854,
      "authorized": 4854,
      "funded": 1144
    },
    "payments": 16,
    "payments_amount": 39644114502,
    "trades": 1237,
    "traded_amount": 146297662833,
    "price": [
      3.884584768863267,
      3.9038999506335363,
      3.5860900982273907,
      3.713532892151135
    ],
    "volume": 16727767378
  },
  {
    "ts": 1632960000,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4860,
      "authorized": 4860,
      "funded": 1148
    },
    "payments": 27,
    "payments_amount": 271257783553,
    "trades": 1908,
    "traded_amount": 382242340611,
    "price": [
      3.713532892151135,
      3.9038147086036226,
      3.3332470743839675,
      3.5248116024456135
    ],
    "volume": 24381386563
  },
  {
    "ts": 1633046400,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4867,
      "authorized": 4867,
      "funded": 1151
    },
    "payments": 23,
    "payments_amount": 240241237890,
    "trades": 4249,
    "traded_amount": 430834128973,
    "price": [
      3.5248116024456135,
      3.9039437719640766,
      3.205102940480055,
      3.3515277182632235
    ],
    "volume": 151184962272
  },
  {
    "ts": 1633132800,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4884,
      "authorized": 4884,
      "funded": 1156
    },
    "payments": 38,
    "payments_amount": 181149430768,
    "trades": 3810,
    "traded_amount": 782169460172,
    "price": [
      3.3515277182632235,
      3.4474437217551217,
      3.0376286843909837,
      3.1729516454881606
    ],
    "volume": 202542906928
  },
  {
    "ts": 1633219200,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4893,
      "authorized": 4893,
      "funded": 1160
    },
    "payments": 25,
    "payments_amount": 20161322921,
    "trades": 2860,
    "traded_amount": 479049281608,
    "price": [
      3.1729516454881606,
      3.233358300467305,
      3.072656030963436,
      3.1859463021610934
    ],
    "volume": 152010544690
  },
  {
    "ts": 1633305600,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4906,
      "authorized": 4906,
      "funded": 1167
    },
    "payments": 25,
    "payments_amount": 27049331015,
    "trades": 5651,
    "traded_amount": 1973023291532,
    "price": [
      3.1859463021610934,
      3.3988815537398867,
      3.1428569272851252,
      3.264926404772469
    ],
    "volume": 627941417185
  },
  {
    "ts": 1633392000,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4924,
      "authorized": 4924,
      "funded": 1174
    },
    "payments": 62,
    "payments_amount": 154218233626,
    "trades": 3100,
    "traded_amount": 534331454881,
    "price": [
      3.264926404772469,
      3.346495038931746,
      3.137142201705001,
      3.197176319258562
    ],
    "volume": 235003953758
  },
  {
    "ts": 1633478400,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4933,
      "authorized": 4933,
      "funded": 1173
    },
    "payments": 47,
    "payments_amount": 79567836169,
    "trades": 6382,
    "traded_amount": 2040095966087,
    "price": [
      3.197176319258562,
      3.2841716536878955,
      2.791484821051103,
      2.839409672740631
    ],
    "volume": 754421850530
  },
  {
    "ts": 1633564800,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4949,
      "authorized": 4949,
      "funded": 1187
    },
    "payments": 74,
    "payments_amount": 69635177131,
    "trades": 4989,
    "traded_amount": 1398586956077,
    "price": [
      2.839409672740631,
      3.257328990801839,
      2.74193555834124,
      2.905406202323985
    ],
    "volume": 459271163297
  },
  {
    "ts": 1633651200,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4960,
      "authorized": 4960,
      "funded": 1191
    },
    "payments": 79,
    "payments_amount": 52722749993,
    "trades": 3438,
    "traded_amount": 1242755299906,
    "price": [
      2.905406202323985,
      3.0222375621608744,
      2.8805584765563736,
      2.9940785151108735
    ],
    "volume": 257838266490
  },
  {
    "ts": 1633737600,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4982,
      "authorized": 4982,
      "funded": 1191
    },
    "payments": 17,
    "payments_amount": 31779439870,
    "trades": 2406,
    "traded_amount": 616422796353,
    "price": [
      2.9940785151108735,
      3.011574591813912,
      2.747792355110926,
      2.8622870262519076
    ],
    "volume": 315843506119
  },
  {
    "ts": 1633824000,
    "supply": 14982123073475,
    "trustlines": {
      "total": 4987,
      "authorized": 4987,
      "funded": 1189
    },
    "payments": 31,
    "payments_amount": 260780298039,
    "trades": 2765,
    "traded_amount": 976133574298,
    "price": [
      2.8622870262519076,
      3.0384615384615383,
      2.816390260803321,
      3.029724348024451
    ],
    "volume": 189093067349
  },
  {
    "ts": 1633910400,
    "supply": 14982123073475,
    "trustlines": {
      "total": 5002,
      "authorized": 5002,
      "funded": 1192
    },
    "payments": 34,
    "payments_amount": 121670631049,
    "trades": 1508,
    "traded_amount": 472537314820,
    "price": [
      3.029724348024451,
      3.256268318146333,
      2.8264330368935457,
      2.9371069182389937
    ],
    "volume": 68466170812
  },
  {
    "ts": 1633996800,
    "supply": 14982123073475,
    "trustlines": {
      "total": 5005,
      "authorized": 5005,
      "funded": 1190
    },
    "payments": 43,
    "payments_amount": 17017758677,
    "trades": 1011,
    "traded_amount": 214529065442,
    "price": [
      2.9371069182389937,
      3.1172652855103276,
      2.933124537217868,
      2.995805871598994
    ],
    "volume": 66920156716
  },
  {
    "ts": 1634083200,
    "supply": 14982123073475,
    "trustlines": {
      "total": 5011,
      "authorized": 5011,
      "funded": 1188
    },
    "payments": 35,
    "payments_amount": 185305436873,
    "trades": 1654,
    "traded_amount": 562536804352,
    "price": [
      2.995805871598994,
      3.0638115021080545,
      2.6949366261062853,
      2.830360520588192
    ],
    "volume": 150406093786
  },
  {
    "ts": 1634169600,
    "supply": 14982123073475,
    "trustlines": {
      "total": 5023,
      "authorized": 5023,
      "funded": 1194
    },
    "payments": 32,
    "payments_amount": 104508882553,
    "trades": 704,
    "traded_amount": 21206274376,
    "price": [
      2.830360520588192,
      3.001076191395407,
      2.662614693824429,
      2.7519399771861894
    ],
    "volume": 6027311125
  },
  {
    "ts": 1634256000,
    "supply": 14982122573170,
    "trustlines": {
      "total": 5026,
      "authorized": 5026,
      "funded": 1192
    },
    "payments": 41,
    "payments_amount": 128465940619,
    "trades": 1139,
    "traded_amount": 126791515152,
    "price": [
      2.7519399771861894,
      2.8931796837568786,
      2.7238763954967,
      2.767093462992977
    ],
    "volume": 50989805546
  },
  {
    "ts": 1634342400,
    "supply": 14982122573170,
    "trustlines": {
      "total": 5045,
      "authorized": 5045,
      "funded": 1210
    },
    "payments": 235,
    "payments_amount": 2036562699605,
    "trades": 5145,
    "traded_amount": 3935369325587,
    "price": [
      2.767093462992977,
      2.772442264368709,
      1.544378698224852,
      2.5841049018466693
    ],
    "volume": 1241762753864
  },
  {
    "ts": 1634428800,
    "supply": 14982122573170,
    "trustlines": {
      "total": 5051,
      "authorized": 5051,
      "funded": 1213
    },
    "payments": 158,
    "payments_amount": 963631779938,
    "trades": 2416,
    "traded_amount": 1141720531272,
    "price": [
      2.5841049018466693,
      2.72000071071139,
      2.5159989885713063,
      2.5984149668702092
    ],
    "volume": 568092531797
  },
  {
    "ts": 1634515200,
    "supply": 14982122573170,
    "trustlines": {
      "total": 5061,
      "authorized": 5061,
      "funded": 1216
    },
    "payments": 122,
    "payments_amount": 203550423792,
    "trades": 2206,
    "traded_amount": 986410757772,
    "price": [
      2.5984149668702092,
      2.697831000737975,
      2.53471526394373,
      2.6624930800178914
    ],
    "volume": 318089515019
  },
  {
    "ts": 1634601600,
    "supply": 14982111973170,
    "trustlines": {
      "total": 5061,
      "authorized": 5061,
      "funded": 1215
    },
    "payments": 349,
    "payments_amount": 1034686823178,
    "trades": 4174,
    "traded_amount": 917982568881,
    "price": [
      2.6624930800178914,
      2.7264182520168,
      2.604574818398256,
      2.675376770523373
    ],
    "volume": 419968770437
  },
  {
    "ts": 1634688000,
    "supply": 14982111973170,
    "trustlines": {
      "total": 5080,
      "authorized": 5080,
      "funded": 1221
    },
    "payments": 137,
    "payments_amount": 175519220886,
    "trades": 3083,
    "traded_amount": 991880452068,
    "price": [
      2.675376770523373,
      2.7142990858827445,
      2.5535096391497776,
      2.5683038357705192
    ],
    "volume": 119232925000
  },
  {
    "ts": 1634774400,
    "supply": 14982111972991,
    "trustlines": {
      "total": 5085,
      "authorized": 5085,
      "funded": 1214
    },
    "payments": 124,
    "payments_amount": 65180232857,
    "trades": 4562,
    "traded_amount": 3129117220288,
    "price": [
      2.5683038357705192,
      2.7027027027027026,
      2.503250495697666,
      2.666625585303902
    ],
    "volume": 274012230451
  },
  {
    "ts": 1634860800,
    "supply": 14982111972991,
    "trustlines": {
      "total": 5089,
      "authorized": 5089,
      "funded": 1216
    },
    "payments": 119,
    "payments_amount": 24936218494,
    "trades": 3547,
    "traded_amount": 1263341582463,
    "price": [
      2.666625585303902,
      2.711970048607574,
      2.5892634511758197,
      2.6814909093236774
    ],
    "volume": 524603672556
  },
  {
    "ts": 1634947200,
    "supply": 14982111972990,
    "trustlines": {
      "total": 5101,
      "authorized": 5101,
      "funded": 1222
    },
    "payments": 178,
    "payments_amount": 447924310868,
    "trades": 4293,
    "traded_amount": 3133112237039,
    "price": [
      2.6814909093236774,
      3.2874379770833224,
      2.497943295436828,
      2.653251581675178
    ],
    "volume": 2863691638433
  },
  {
    "ts": 1635033600,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5112,
      "authorized": 5112,
      "funded": 1222
    },
    "payments": 286,
    "payments_amount": 335116757835,
    "trades": 4062,
    "traded_amount": 1473453639067,
    "price": [
      2.653251581675178,
      2.732683570649001,
      2.5355953987318256,
      2.6878848349226954
    ],
    "volume": 394462336353
  },
  {
    "ts": 1635120000,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5120,
      "authorized": 5120,
      "funded": 1226
    },
    "payments": 224,
    "payments_amount": 55247121585,
    "trades": 3069,
    "traded_amount": 547636774269,
    "price": [
      2.6878848349226954,
      2.6957637997432604,
      2.5859522734204434,
      2.5859522734204434
    ],
    "volume": 173111252175
  },
  {
    "ts": 1635206400,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5123,
      "authorized": 5123,
      "funded": 1225
    },
    "payments": 307,
    "payments_amount": 354535308243,
    "trades": 3374,
    "traded_amount": 968622669821,
    "price": [
      2.5859522734204434,
      2.696029671876858,
      2.569520690165421,
      2.6759170806282047
    ],
    "volume": 409544582637
  },
  {
    "ts": 1635292800,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5135,
      "authorized": 5135,
      "funded": 1228
    },
    "payments": 238,
    "payments_amount": 273990702563,
    "trades": 5382,
    "traded_amount": 3194900130288,
    "price": [
      2.6759170806282047,
      3.1699935608499676,
      2.5972192944280743,
      3.0044474609011735
    ],
    "volume": 773921761457
  },
  {
    "ts": 1635379200,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5147,
      "authorized": 5147,
      "funded": 1235
    },
    "payments": 592,
    "payments_amount": 241357239804,
    "trades": 4715,
    "traded_amount": 1844681397045,
    "price": [
      3.0044474609011735,
      3.039681496964268,
      2.8649838196503996,
      2.922898915742334
    ],
    "volume": 230812489325
  },
  {
    "ts": 1635465600,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5157,
      "authorized": 5157,
      "funded": 1239
    },
    "payments": 343,
    "payments_amount": 113499101423,
    "trades": 5489,
    "traded_amount": 756019631844,
    "price": [
      2.922898915742334,
      2.9291338582677167,
      2.750619962567727,
      2.7780360171202454
    ],
    "volume": 254587489115
  },
  {
    "ts": 1635552000,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5178,
      "authorized": 5178,
      "funded": 1244
    },
    "payments": 547,
    "payments_amount": 147183620847,
    "trades": 6941,
    "traded_amount": 1075378986777,
    "price": [
      2.7780360171202454,
      2.8422257168873895,
      2.7289884234725488,
      2.7996213747609198
    ],
    "volume": 405652064190
  },
  {
    "ts": 1635638400,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5184,
      "authorized": 5184,
      "funded": 1247
    },
    "payments": 286,
    "payments_amount": 216242089240,
    "trades": 7927,
    "traded_amount": 1373752263919,
    "price": [
      2.7996213747609198,
      2.8204211410200193,
      2.572867790345356,
      2.691109354613423
    ],
    "volume": 1016305535276
  },
  {
    "ts": 1635724800,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5203,
      "authorized": 5203,
      "funded": 1250
    },
    "payments": 236,
    "payments_amount": 153718522541,
    "trades": 6948,
    "traded_amount": 1434558538561,
    "price": [
      2.691109354613423,
      2.7936090181067255,
      2.6666666666666665,
      2.730539097987456
    ],
    "volume": 319496368529
  },
  {
    "ts": 1635811200,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5223,
      "authorized": 5223,
      "funded": 1262
    },
    "payments": 198,
    "payments_amount": 29083407954,
    "trades": 4014,
    "traded_amount": 1018317766929,
    "price": [
      2.730539097987456,
      2.7533494751522425,
      2.6181008267768116,
      2.6181008267768116
    ],
    "volume": 175589296564
  },
  {
    "ts": 1635897600,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5239,
      "authorized": 5239,
      "funded": 1270
    },
    "payments": 279,
    "payments_amount": 236268252754,
    "trades": 5698,
    "traded_amount": 1597826353701,
    "price": [
      2.6181008267768116,
      2.7867350012858916,
      2.524304824315491,
      2.6189469203696945
    ],
    "volume": 299454067278
  },
  {
    "ts": 1635984000,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5251,
      "authorized": 5251,
      "funded": 1268
    },
    "payments": 1044,
    "payments_amount": 196996066447,
    "trades": 9038,
    "traded_amount": 1734888239973,
    "price": [
      2.6189469203696945,
      3.2574137164864463,
      2.4499999950562774,
      2.725027061678814
    ],
    "volume": 886009295335
  },
  {
    "ts": 1636070400,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5251,
      "authorized": 5251,
      "funded": 1267
    },
    "payments": 1337,
    "payments_amount": 176908754231,
    "trades": 8929,
    "traded_amount": 1627253229117,
    "price": [
      2.725027061678814,
      3.9984006398776577,
      2.6782761794541994,
      2.7961155824374617
    ],
    "volume": 698346877742
  },
  {
    "ts": 1636156800,
    "supply": 14982111962990,
    "trustlines": {
      "total": 5259,
      "authorized": 5259,
      "funded": 1273
    },
    "payments": 542,
    "payments_amount": 63522462473,
    "trades": 6655,
    "traded_amount": 755566318020,
    "price": [
      2.7961155824374617,
      2.865299436462996,
      2.7389292987073723,
      2.795306680084139
    ],
    "volume": 177355658057
  },
  {
    "ts": 1636243200,
    "supply": 14982111961582,
    "trustlines": {
      "total": 5267,
      "authorized": 5267,
      "funded": 1271
    },
    "payments": 726,
    "payments_amount": 108271919431,
    "trades": 6160,
    "traded_amount": 533684550299,
    "price": [
      2.795306680084139,
      2.8170459987176875,
      2.7349240970162674,
      2.743919337552018
    ],
    "volume": 201523034752
  },
  {
    "ts": 1636329600,
    "supply": 14982111961582,
    "trustlines": {
      "total": 5276,
      "authorized": 5276,
      "funded": 1275
    },
    "payments": 1576,
    "payments_amount": 285574056859,
    "trades": 9869,
    "traded_amount": 1795533097867,
    "price": [
      2.743919337552018,
      2.753830796106813,
      2.6133099545929794,
      2.6379648518058247
    ],
    "volume": 984106557572
  },
  {
    "ts": 1636416000,
    "supply": 14982111961582,
    "trustlines": {
      "total": 5290,
      "authorized": 5290,
      "funded": 1282
    },
    "payments": 1094,
    "payments_amount": 346387344541,
    "trades": 17436,
    "traded_amount": 1644916156464,
    "price": [
      2.6379648518058247,
      2.8188275047247147,
      2.39702169762289,
      2.423527502955428
    ],
    "volume": 972223565242
  },
  {
    "ts": 1636502400,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5317,
      "authorized": 5317,
      "funded": 1289
    },
    "payments": 2583,
    "payments_amount": 3102299530212,
    "trades": 26481,
    "traded_amount": 3825707901681,
    "price": [
      2.423527502955428,
      2.7603616489952683,
      2.291881994655867,
      2.609293610201051
    ],
    "volume": 1633652221064
  },
  {
    "ts": 1636588800,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5332,
      "authorized": 5332,
      "funded": 1290
    },
    "payments": 1006,
    "payments_amount": 742496068696,
    "trades": 12930,
    "traded_amount": 1427428698310,
    "price": [
      2.609293610201051,
      2.6617507125330087,
      2.4701843346147236,
      2.5583726380161247
    ],
    "volume": 324719216509
  },
  {
    "ts": 1636675200,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5340,
      "authorized": 5340,
      "funded": 1290
    },
    "payments": 1121,
    "payments_amount": 91216068433,
    "trades": 11170,
    "traded_amount": 2288152130874,
    "price": [
      2.5583726380161247,
      2.723473772002806,
      2.5404386593318864,
      2.650660332391662
    ],
    "volume": 336672948414
  },
  {
    "ts": 1636761600,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5364,
      "authorized": 5364,
      "funded": 1292
    },
    "payments": 632,
    "payments_amount": 163409235349,
    "trades": 6824,
    "traded_amount": 910914245818,
    "price": [
      2.650660332391662,
      2.710699126206353,
      2.607142857142857,
      2.638136013260762
    ],
    "volume": 162820461818
  },
  {
    "ts": 1636848000,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5378,
      "authorized": 5378,
      "funded": 1295
    },
    "payments": 498,
    "payments_amount": 80589777881,
    "trades": 10408,
    "traded_amount": 1421435023591,
    "price": [
      2.638136013260762,
      2.718254317340039,
      2.61891169336635,
      2.650926007520705
    ],
    "volume": 279061253676
  },
  {
    "ts": 1636934400,
    "supply": 16982111961582,
    "trustlines": {
      "total": 5399,
      "authorized": 5399,
      "funded": 1303
    },
    "payments": 596,
    "payments_amount": 9509130601,
    "trades": 10583,
    "traded_amount": 1353183675303,
    "price": [
      2.650926007520705,
      2.6756013169047894,
      2.5380306119980176,
      2.647955666666667
    ],
    "volume": 259581176028
  },
  {
    "ts": 1637020800,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5416,
      "authorized": 5416,
      "funded": 1298
    },
    "payments": 632,
    "payments_amount": 39803393428,
    "trades": 15530,
    "traded_amount": 4082604110726,
    "price": [
      2.647955666666667,
      2.9799798940436237,
      2.640264,
      2.8976719188749467
    ],
    "volume": 778953609935
  },
  {
    "ts": 1637107200,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5435,
      "authorized": 5435,
      "funded": 1305
    },
    "payments": 421,
    "payments_amount": 25671686644,
    "trades": 9557,
    "traded_amount": 2158062631210,
    "price": [
      2.8976719188749467,
      3.008276310198774,
      2.866322090645848,
      2.886939731740597
    ],
    "volume": 327843509038
  },
  {
    "ts": 1637193600,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5462,
      "authorized": 5462,
      "funded": 1311
    },
    "payments": 425,
    "payments_amount": 204450890046,
    "trades": 17362,
    "traded_amount": 4213152767648,
    "price": [
      2.886939731740597,
      3.1803349190088346,
      2.825410783651927,
      3.0605686536558494
    ],
    "volume": 614982420961
  },
  {
    "ts": 1637280000,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5489,
      "authorized": 5489,
      "funded": 1322
    },
    "payments": 223,
    "payments_amount": 80766584274,
    "trades": 13856,
    "traded_amount": 2537413598604,
    "price": [
      3.0605686536558494,
      3.0788907026128194,
      2.5001670927728568,
      2.8364816391688654
    ],
    "volume": 1526875985029
  },
  {
    "ts": 1637366400,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5498,
      "authorized": 5498,
      "funded": 1317
    },
    "payments": 88,
    "payments_amount": 12793279482,
    "trades": 11656,
    "traded_amount": 1417467044424,
    "price": [
      2.8364816391688654,
      3.9967852096147904,
      2.776627109626454,
      2.888103274696408
    ],
    "volume": 497357665215
  },
  {
    "ts": 1637452800,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5510,
      "authorized": 5510,
      "funded": 1315
    },
    "payments": 73,
    "payments_amount": 27630810309,
    "trades": 11436,
    "traded_amount": 1008211101493,
    "price": [
      2.888103274696408,
      2.956170340447357,
      2.8709768642329396,
      2.909691691525855
    ],
    "volume": 162888720737
  },
  {
    "ts": 1637539200,
    "supply": 16982111961444,
    "trustlines": {
      "total": 5522,
      "authorized": 5522,
      "funded": 1322
    },
    "payments": 65,
    "payments_amount": 113069821882,
    "trades": 20591,
    "traded_amount": 2336046177781,
    "price": [
      2.909691691525855,
      3.008898277723948,
      2.807306662747864,
      2.9662117682239972
    ],
    "volume": 944227103085
  },
  {
    "ts": 1637625600,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5524,
      "authorized": 5524,
      "funded": 1315
    },
    "payments": 396,
    "payments_amount": 80595232925,
    "trades": 11528,
    "traded_amount": 698998141578,
    "price": [
      2.9662117682239972,
      3.054474708171206,
      2.9271795261167015,
      2.934367011930964
    ],
    "volume": 68362921244
  },
  {
    "ts": 1637712000,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5537,
      "authorized": 5537,
      "funded": 1316
    },
    "payments": 204,
    "payments_amount": 25806891624,
    "trades": 14978,
    "traded_amount": 842724818980,
    "price": [
      2.934367011930964,
      3.1110036275695285,
      2.7778087962973195,
      3.077060215945872
    ],
    "volume": 192817895321
  },
  {
    "ts": 1637798400,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5799,
      "authorized": 5799,
      "funded": 1322
    },
    "payments": 182,
    "payments_amount": 153834891536,
    "trades": 17839,
    "traded_amount": 3783257053118,
    "price": [
      3.077060215945872,
      3.077060215945872,
      2.827208934378318,
      2.9285569740509354
    ],
    "volume": 483978628014
  },
  {
    "ts": 1637884800,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5811,
      "authorized": 5811,
      "funded": 1321
    },
    "payments": 174,
    "payments_amount": 60743292408,
    "trades": 17886,
    "traded_amount": 2507031290760,
    "price": [
      2.9285569740509354,
      3.245827938125861,
      2.848063069151493,
      3.043952604515985
    ],
    "volume": 676559479823
  },
  {
    "ts": 1637971200,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5817,
      "authorized": 5817,
      "funded": 1322
    },
    "payments": 154,
    "payments_amount": 44561306720,
    "trades": 10352,
    "traded_amount": 625922841437,
    "price": [
      3.043952604515985,
      3.1279324351651727,
      3.0016260598267674,
      3.1118543462879007
    ],
    "volume": 144662534005
  },
  {
    "ts": 1638057600,
    "supply": 16982111961443,
    "trustlines": {
      "total": 5824,
      "authorized": 5824,
      "funded": 1318
    },
    "payments": 127,
    "payments_amount": 33561537500,
    "trades": 18042,
    "traded_amount": 1242013161412,
    "price": [
      3.1118543462879007,
      3.317508710801394,
      3.088301564677619,
      3.1041203507958373
    ],
    "volume": 139524946778
  },
  {
    "ts": 1638144000,
    "supply": 16980650061443,
    "trustlines": {
      "total": 5834,
      "authorized": 5834,
      "funded": 1317
    },
    "payments": 132,
    "payments_amount": 83329653815,
    "trades": 9400,
    "traded_amount": 1374888107573,
    "price": [
      3.1041203507958373,
      3.1160594400575645,
      2.995464659476839,
      3.0574018016045854
    ],
    "volume": 248273510123
  },
  {
    "ts": 1638230400,
    "supply": 16980650061443,
    "trustlines": {
      "total": 5831,
      "authorized": 5831,
      "funded": 1321
    },
    "payments": 188,
    "payments_amount": 49411479895,
    "trades": 18834,
    "traded_amount": 1600391330399,
    "price": [
      3.0574018016045854,
      3.0574018016045854,
      2.8518518518518516,
      2.9574701738011573
    ],
    "volume": 344603992694
  },
  {
    "ts": 1638316800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5844,
      "authorized": 5844,
      "funded": 1323
    },
    "payments": 185,
    "payments_amount": 425109813212,
    "trades": 12579,
    "traded_amount": 1077295174091,
    "price": [
      2.9574701738011573,
      3.079211538821299,
      2.857142857142857,
      3.05966749369479
    ],
    "volume": 221226210697
  },
  {
    "ts": 1638403200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5859,
      "authorized": 5859,
      "funded": 1324
    },
    "payments": 397,
    "payments_amount": 59739602570,
    "trades": 10092,
    "traded_amount": 1083689578625,
    "price": [
      3.05966749369479,
      3.144398785164782,
      2.9559229520979535,
      2.9559229520979535
    ],
    "volume": 138324566669
  },
  {
    "ts": 1638489600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5866,
      "authorized": 5866,
      "funded": 1323
    },
    "payments": 187,
    "payments_amount": 218415164627,
    "trades": 18191,
    "traded_amount": 2634346214713,
    "price": [
      2.9559229520979535,
      3.0800327416526403,
      2.76073636345609,
      3.069788383610986
    ],
    "volume": 930972410278
  },
  {
    "ts": 1638576000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5885,
      "authorized": 5885,
      "funded": 1330
    },
    "payments": 209,
    "payments_amount": 31078375747,
    "trades": 21067,
    "traded_amount": 3049967979015,
    "price": [
      3.069788383610986,
      3.996802717750343,
      2.958017019702956,
      3.4561462234236844
    ],
    "volume": 2659269095770
  },
  {
    "ts": 1638662400,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5903,
      "authorized": 5903,
      "funded": 1341
    },
    "payments": 176,
    "payments_amount": 54403110464,
    "trades": 12925,
    "traded_amount": 1279991479703,
    "price": [
      3.4561462234236844,
      3.7216226280159854,
      3.4133547582946213,
      3.524474560941745
    ],
    "volume": 612095593128
  },
  {
    "ts": 1638748800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5935,
      "authorized": 5935,
      "funded": 1347
    },
    "payments": 229,
    "payments_amount": 40680015314,
    "trades": 18504,
    "traded_amount": 1928519136328,
    "price": [
      3.524474560941745,
      3.934020322625067,
      3.3963606101250803,
      3.3963606101250803
    ],
    "volume": 1119860033480
  },
  {
    "ts": 1638835200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5944,
      "authorized": 5944,
      "funded": 1354
    },
    "payments": 239,
    "payments_amount": 64485964738,
    "trades": 8889,
    "traded_amount": 1688491476467,
    "price": [
      3.3963606101250803,
      3.537761460183822,
      3.3648845616818543,
      3.493855778782292
    ],
    "volume": 805083204957
  },
  {
    "ts": 1638921600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5967,
      "authorized": 5967,
      "funded": 1356
    },
    "payments": 403,
    "payments_amount": 118264714495,
    "trades": 9748,
    "traded_amount": 1473397158778,
    "price": [
      3.493855778782292,
      3.5121438563685827,
      3.1034684994129402,
      3.2820458718923393
    ],
    "volume": 752107628036
  },
  {
    "ts": 1639008000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 5991,
      "authorized": 5991,
      "funded": 1366
    },
    "payments": 243,
    "payments_amount": 138254178346,
    "trades": 8711,
    "traded_amount": 930140144630,
    "price": [
      3.2820458718923393,
      3.59128173321379,
      3.2666537702634737,
      3.5906322176010224
    ],
    "volume": 234009208868
  },
  {
    "ts": 1639094400,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6027,
      "authorized": 6027,
      "funded": 1373
    },
    "payments": 231,
    "payments_amount": 1170316804575,
    "trades": 9681,
    "traded_amount": 1828914853509,
    "price": [
      3.5906322176010224,
      3.881252301764205,
      3.4934730585699145,
      3.81438442500023
    ],
    "volume": 540525741295
  },
  {
    "ts": 1639180800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6061,
      "authorized": 6061,
      "funded": 1379
    },
    "payments": 197,
    "payments_amount": 569226388787,
    "trades": 9045,
    "traded_amount": 1911194840703,
    "price": [
      3.81438442500023,
      3.887384490290116,
      3.593961682133705,
      3.6527602108645074
    ],
    "volume": 889708130640
  },
  {
    "ts": 1639267200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6095,
      "authorized": 6095,
      "funded": 1385
    },
    "payments": 240,
    "payments_amount": 2414173563036,
    "trades": 10561,
    "traded_amount": 4294627883626,
    "price": [
      3.6527602108645074,
      3.935895703870673,
      3.5711135478411027,
      3.708490289463058
    ],
    "volume": 1524874831349
  },
  {
    "ts": 1639353600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6117,
      "authorized": 6117,
      "funded": 1392
    },
    "payments": 221,
    "payments_amount": 732011679996,
    "trades": 12785,
    "traded_amount": 3144232854310,
    "price": [
      3.708490289463058,
      3.96109040698669,
      3.6907776908151626,
      3.942246233108595
    ],
    "volume": 562662692482
  },
  {
    "ts": 1639440000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6148,
      "authorized": 6148,
      "funded": 1407
    },
    "payments": 520,
    "payments_amount": 1008875047272,
    "trades": 10422,
    "traded_amount": 2139910417212,
    "price": [
      3.942246233108595,
      4.031611151156507,
      3.7006581984827664,
      3.7338845542637973
    ],
    "volume": 819072851489
  },
  {
    "ts": 1639526400,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6178,
      "authorized": 6178,
      "funded": 1416
    },
    "payments": 443,
    "payments_amount": 616537226297,
    "trades": 12708,
    "traded_amount": 2397841476611,
    "price": [
      3.7338845542637973,
      4.043714155444342,
      3.664144037854625,
      3.691546445839315
    ],
    "volume": 884550911103
  },
  {
    "ts": 1639612800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6236,
      "authorized": 6236,
      "funded": 1435
    },
    "payments": 372,
    "payments_amount": 597180592184,
    "trades": 8875,
    "traded_amount": 1411472678370,
    "price": [
      3.691546445839315,
      3.939698505679132,
      3.6481012658227847,
      3.9116230370684755
    ],
    "volume": 212234891875
  },
  {
    "ts": 1639699200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6261,
      "authorized": 6261,
      "funded": 1423
    },
    "payments": 1605,
    "payments_amount": 1032730536608,
    "trades": 12101,
    "traded_amount": 1838877484852,
    "price": [
      3.9116230370684755,
      4.044936230680323,
      3.819038933202585,
      3.8948241681629283
    ],
    "volume": 1014701169882
  },
  {
    "ts": 1639785600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6274,
      "authorized": 6274,
      "funded": 1419
    },
    "payments": 1511,
    "payments_amount": 479426943997,
    "trades": 7951,
    "traded_amount": 1829822913650,
    "price": [
      3.8948241681629283,
      4.001726661218668,
      3.7504734085512412,
      3.881804647748797
    ],
    "volume": 1711549795792
  },
  {
    "ts": 1639872000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6277,
      "authorized": 6277,
      "funded": 1419
    },
    "payments": 1513,
    "payments_amount": 396945101010,
    "trades": 10097,
    "traded_amount": 1793007645815,
    "price": [
      3.881804647748797,
      4.08159930096839,
      3.773574285886586,
      3.912578826839753
    ],
    "volume": 1541111465002
  },
  {
    "ts": 1639958400,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6291,
      "authorized": 6291,
      "funded": 1421
    },
    "payments": 1894,
    "payments_amount": 367437677388,
    "trades": 13418,
    "traded_amount": 1153128355770,
    "price": [
      3.912578826839753,
      4.111430800323973,
      3.646172693581832,
      3.8684629
    ],
    "volume": 708139401649
  },
  {
    "ts": 1640044800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6308,
      "authorized": 6308,
      "funded": 1427
    },
    "payments": 1150,
    "payments_amount": 156046463543,
    "trades": 7454,
    "traded_amount": 1042557512227,
    "price": [
      3.8684629,
      3.955427390347583,
      3.686902892974207,
      3.733879980788059
    ],
    "volume": 868684146583
  },
  {
    "ts": 1640131200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6319,
      "authorized": 6319,
      "funded": 1428
    },
    "payments": 609,
    "payments_amount": 127721144936,
    "trades": 6951,
    "traded_amount": 1049011838611,
    "price": [
      3.733879980788059,
      3.7651075289726723,
      3.5063113500064516,
      3.7306879228319696
    ],
    "volume": 427714442565
  },
  {
    "ts": 1640217600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6304,
      "authorized": 6304,
      "funded": 1414
    },
    "payments": 749,
    "payments_amount": 4315283123867,
    "trades": 7718,
    "traded_amount": 1168971705072,
    "price": [
      3.7306879228319696,
      3.7667353471088667,
      3.418842457320906,
      3.4887766094547166
    ],
    "volume": 319864951804
  },
  {
    "ts": 1640304000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6308,
      "authorized": 6308,
      "funded": 1422
    },
    "payments": 474,
    "payments_amount": 93067442638,
    "trades": 4547,
    "traded_amount": 396318060585,
    "price": [
      3.4887766094547166,
      3.693921654504932,
      3.477832512315271,
      3.6055261737722613
    ],
    "volume": 110339273864
  },
  {
    "ts": 1640390400,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6339,
      "authorized": 6339,
      "funded": 1423
    },
    "payments": 311,
    "payments_amount": 152203283411,
    "trades": 3983,
    "traded_amount": 673034746071,
    "price": [
      3.6055261737722613,
      3.622959567707463,
      3.4227190188989614,
      3.47577040451016
    ],
    "volume": 85333248636
  },
  {
    "ts": 1640476800,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6331,
      "authorized": 6331,
      "funded": 1421
    },
    "payments": 674,
    "payments_amount": 107061241326,
    "trades": 4930,
    "traded_amount": 772798430217,
    "price": [
      3.47577040451016,
      3.5489711106464856,
      3.4110377685877786,
      3.438623825091949
    ],
    "volume": 84915084638
  },
  {
    "ts": 1640563200,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6342,
      "authorized": 6342,
      "funded": 1425
    },
    "payments": 556,
    "payments_amount": 765312866678,
    "trades": 7450,
    "traded_amount": 1592089478822,
    "price": [
      3.438623825091949,
      3.5137034434293746,
      3.265940488841658,
      3.275041846028427
    ],
    "volume": 809702811483
  },
  {
    "ts": 1640649600,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6357,
      "authorized": 6357,
      "funded": 1432
    },
    "payments": 1199,
    "payments_amount": 214053737204,
    "trades": 20493,
    "traded_amount": 1102072120808,
    "price": [
      3.275041846028427,
      3.598008147928312,
      3.2634759385020478,
      3.588678333532478
    ],
    "volume": 988217730633
  },
  {
    "ts": 1640736000,
    "supply": 16980614109934,
    "trustlines": {
      "total": 6369,
      "authorized": 6369,
      "funded": 1428
    },
    "payments": 422,
    "payments_amount": 164395876707,
    "trades": 13006,
    "traded_amount": 1142233401406,
    "price": [
      3.588678333532478,
      3.720104731043988,
      3.3191745168635793,
      3.6814171122994654
    ],
    "volume": 668683284557
  },
  {
    "ts": 1640822400,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6381,
      "authorized": 6381,
      "funded": 1430
    },
    "payments": 382,
    "payments_amount": 167042598090,
    "trades": 10761,
    "traded_amount": 1102192748742,
    "price": [
      3.6814171122994654,
      4.160315350992297,
      3.611512933011414,
      3.7328478104585376
    ],
    "volume": 480697723631
  },
  {
    "ts": 1640908800,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6402,
      "authorized": 6402,
      "funded": 1436
    },
    "payments": 2703,
    "payments_amount": 394385825929,
    "trades": 14099,
    "traded_amount": 1582094921598,
    "price": [
      3.7328478104585376,
      3.8551858255869824,
      3.6265652028529916,
      3.7525735992914866
    ],
    "volume": 265943221671
  },
  {
    "ts": 1640995200,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6412,
      "authorized": 6412,
      "funded": 1435
    },
    "payments": 603,
    "payments_amount": 106450549426,
    "trades": 8815,
    "traded_amount": 1205885857775,
    "price": [
      3.7525735992914866,
      4.829130809614197,
      3.607199452848617,
      3.637326535897503
    ],
    "volume": 679765745122
  },
  {
    "ts": 1641081600,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6427,
      "authorized": 6427,
      "funded": 1443
    },
    "payments": 682,
    "payments_amount": 75467652678,
    "trades": 9214,
    "traded_amount": 819381051983,
    "price": [
      3.637326535897503,
      3.7048968405172067,
      3.38924539981779,
      3.4365546
    ],
    "volume": 257894211672
  },
  {
    "ts": 1641168000,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6437,
      "authorized": 6437,
      "funded": 1446
    },
    "payments": 821,
    "payments_amount": 325454089494,
    "trades": 12887,
    "traded_amount": 1771404143901,
    "price": [
      3.4365546,
      3.5549235700217467,
      3.3891054186513236,
      3.4490792
    ],
    "volume": 388533510082
  },
  {
    "ts": 1641254400,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6445,
      "authorized": 6445,
      "funded": 1447
    },
    "payments": 885,
    "payments_amount": 77521583539,
    "trades": 10513,
    "traded_amount": 983200825015,
    "price": [
      3.4490792,
      3.5951199794215998,
      3.441252230175083,
      3.569532894494959
    ],
    "volume": 175631108945
  },
  {
    "ts": 1641340800,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6446,
      "authorized": 6446,
      "funded": 1442
    },
    "payments": 1186,
    "payments_amount": 137660944171,
    "trades": 15949,
    "traded_amount": 1735472897028,
    "price": [
      3.569532894494959,
      3.846104097521635,
      3.3891064299285696,
      3.745739906957808
    ],
    "volume": 456596901023
  },
  {
    "ts": 1641427200,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6449,
      "authorized": 6449,
      "funded": 1439
    },
    "payments": 4620,
    "payments_amount": 754608586191,
    "trades": 16422,
    "traded_amount": 1481468258506,
    "price": [
      3.745739906957808,
      3.846156986793014,
      3.6455863093406933,
      3.7275886
    ],
    "volume": 338121014884
  },
  {
    "ts": 1641513600,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6468,
      "authorized": 6468,
      "funded": 1437
    },
    "payments": 2736,
    "payments_amount": 642065065981,
    "trades": 19536,
    "traded_amount": 2213331920899,
    "price": [
      3.7275886,
      4.01739750997746,
      3.65146240453132,
      3.9155569816567732
    ],
    "volume": 488160642943
  },
  {
    "ts": 1641600000,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6476,
      "authorized": 6476,
      "funded": 1438
    },
    "payments": 1141,
    "payments_amount": 180890604159,
    "trades": 15249,
    "traded_amount": 668990928887,
    "price": [
      3.9155569816567732,
      4.0055078211059705,
      3.7907529151175927,
      3.909400499542982
    ],
    "volume": 249369354702
  },
  {
    "ts": 1641686400,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6482,
      "authorized": 6482,
      "funded": 1436
    },
    "payments": 1655,
    "payments_amount": 162386697532,
    "trades": 10347,
    "traded_amount": 1046579570813,
    "price": [
      3.909400499542982,
      4,
      3.493108605728821,
      3.81949964
    ],
    "volume": 375461434183
  },
  {
    "ts": 1641772800,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6501,
      "authorized": 6501,
      "funded": 1440
    },
    "payments": 1750,
    "payments_amount": 351727630059,
    "trades": 13717,
    "traded_amount": 1013844706312,
    "price": [
      3.81949964,
      4.119306428531942,
      3.683816218857525,
      3.9558982
    ],
    "volume": 512996739176
  },
  {
    "ts": 1641859200,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6513,
      "authorized": 6513,
      "funded": 1445
    },
    "payments": 2025,
    "payments_amount": 456717143892,
    "trades": 12717,
    "traded_amount": 963748045313,
    "price": [
      3.9558982,
      4.07968580300646,
      3.8100840733151617,
      3.8100840733151617
    ],
    "volume": 202635617286
  },
  {
    "ts": 1641945600,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6522,
      "authorized": 6522,
      "funded": 1441
    },
    "payments": 2505,
    "payments_amount": 201631809481,
    "trades": 15754,
    "traded_amount": 909004735571,
    "price": [
      3.8100840733151617,
      3.8261220536002583,
      3.403862697544708,
      3.5372435266675604
    ],
    "volume": 263461981742
  },
  {
    "ts": 1642032000,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6532,
      "authorized": 6532,
      "funded": 1444
    },
    "payments": 1208,
    "payments_amount": 127870080915,
    "trades": 10871,
    "traded_amount": 893200688386,
    "price": [
      3.5372435266675604,
      3.744633705859896,
      3.4448818897637796,
      3.6947269026159173
    ],
    "volume": 269715284412
  },
  {
    "ts": 1642118400,
    "supply": 16980614109933,
    "trustlines": {
      "total": 6549,
      "authorized": 6549,
      "funded": 1437
    },
    "payments": 2557,
    "payments_amount": 282428199459,
    "trades": 17803,
    "traded_amount": 1639373672989,
    "price": [
      3.6947269026159173,
      3.88832012538849,
      3.5556186875412017,
      3.6384805705137535
    ],
    "volume": 527840909222
  }
]
[
  {
    "ts": 1492041600,
    "supply": 5123900000,
    "trustlines": {
      "total": 3,
      "authorized": 3,
      "funded": 1
    },
    "payments": 3,
    "payments_amount": 5123900000,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1492128000,
    "supply": 5123900000,
    "trustlines": {
      "total": 6,
      "authorized": 6,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1492214400,
    "supply": 5123900000,
    "trustlines": {
      "total": 9,
      "authorized": 9,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1492300800,
    "supply": 5123900000,
    "trustlines": {
      "total": 12,
      "authorized": 12,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1492646400,
    "supply": 5123900000,
    "trustlines": {
      "total": 14,
      "authorized": 14,
      "funded": 1
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "volume": 0
  },
  {
    "ts": 1492732800,
    "supply": 115153900000,
    "trustlines": {
      "total": 14,
      "authorized": 14,
      "funded": 3
    },
    "payments": 7,
    "payments_amount": 110040000000,
    "trades": 3,
    "traded_amount": 3042570765,
    "price": [
      355.87188612099646,
      355.87188612099646,
      343.347639484,
      343.347639484
    ],
    "volume": 1044788648926
  },
  {
    "ts": 1492819200,
    "supply": 205153900000,
    "trustlines": {
      "total": 14,
      "authorized": 14,
      "funded": 3
    },
    "payments": 1,
    "payments_amount": 90000000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      343.347639484,
      343.347639484,
      343.347639484,
      343.347639484
    ],
    "volume": 0
  },
  {
    "ts": 1492905600,
    "supply": 205153900000,
    "trustlines": {
      "total": 16,
      "authorized": 16,
      "funded": 4
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 1,
    "traded_amount": 455440,
    "price": [
      343.347639484,
      343.347639484,
      219.5678903917091,
      219.5678903917091
    ],
    "volume": 100000000
  },
  {
    "ts": 1492992000,
    "supply": 305153900000,
    "trustlines": {
      "total": 18,
      "authorized": 18,
      "funded": 5
    },
    "payments": 2,
    "payments_amount": 200000000000,
    "trades": 10,
    "traded_amount": 10428667643,
    "price": [
      219.5678903917091,
      294.11764710552325,
      219.5678903917091,
      263.85224274406335
    ],
    "volume": 2989200000000
  },
  {
    "ts": 1493078400,
    "supply": 305153900000,
    "trustlines": {
      "total": 19,
      "authorized": 19,
      "funded": 6
    },
    "payments": 2,
    "payments_amount": 10189500,
    "trades": 9,
    "traded_amount": 1243105500,
    "price": [
      263.85224274406335,
      293.2551319648094,
      263.85224274406335,
      284.4141069397042
    ],
    "volume": 354050815085
  },
  {
    "ts": 1493164800,
    "supply": 305153900000,
    "trustlines": {
      "total": 21,
      "authorized": 21,
      "funded": 6
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      284.4141069397042,
      284.4141069397042,
      284.4141069397042,
      284.4141069397042
    ],
    "volume": 0
  },
  {
    "ts": 1493251200,
    "supply": 305153900000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 17,
    "traded_amount": 1085293623,
    "price": [
      284.4141069397042,
      284.4141069397042,
      277.77777171987026,
      277.77777777777777
    ],
    "volume": 299120993049
  },
  {
    "ts": 1493337600,
    "supply": 305153900000,
    "trustlines": {
      "total": 22,
      "authorized": 22,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 5569930635,
    "price": [
      277.77777777777777,
      277.77777777777777,
      270.27027027,
      270.27027027
    ],
    "volume": 1529307318526
  },
  {
    "ts": 1493424000,
    "supply": 305153900000,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 7
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 1147994791,
    "price": [
      270.27027027,
      270.27027027,
      263.1578944738421,
      263.1578944738421
    ],
    "volume": 302207894737
  },
  {
    "ts": 1493510400,
    "supply": 305153900000,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 8
    },
    "payments": 2,
    "payments_amount": 150000000,
    "trades": 3,
    "traded_amount": 1120440184,
    "price": [
      263.1578944738421,
      263.1578944738421,
      227.1777256783527,
      227.1777256783527
    ],
    "volume": 274002439024
  },
  {
    "ts": 1493596800,
    "supply": 305153900000,
    "trustlines": {
      "total": 24,
      "authorized": 24,
      "funded": 8
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 169412056,
    "price": [
      227.1777256783527,
      227.27273381923175,
      222.22271605070102,
      227.27272728786636
    ],
    "volume": 25919162955
  },
  {
    "ts": 1493683200,
    "supply": 305153900000,
    "trustlines": {
      "total": 25,
      "authorized": 25,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27272728786636,
      227.27272728786636,
      227.27272728786636,
      227.27272728786636
    ],
    "volume": 0
  },
  {
    "ts": 1493769600,
    "supply": 305153900000,
    "trustlines": {
      "total": 25,
      "authorized": 25,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27272728786636,
      227.27272728786636,
      227.27272728786636,
      227.27272728786636
    ],
    "volume": 0
  },
  {
    "ts": 1493856000,
    "supply": 305153900000,
    "trustlines": {
      "total": 26,
      "authorized": 26,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27272728786636,
      227.27272728786636,
      227.27272728786636,
      227.27272728786636
    ],
    "volume": 0
  },
  {
    "ts": 1493942400,
    "supply": 305153900000,
    "trustlines": {
      "total": 26,
      "authorized": 26,
      "funded": 9
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 12,
    "traded_amount": 4770722629,
    "price": [
      227.27272728786636,
      227.27272728786636,
      71.18055559179125,
      71.18055559179125
    ],
    "volume": 1039865634484
  },
  {
    "ts": 1494028800,
    "supply": 305153900000,
    "trustlines": {
      "total": 26,
      "authorized": 26,
      "funded": 9
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055559179125,
      71.18055559179125,
      71.18055559179125,
      71.18055559179125
    ],
    "volume": 0
  },
  {
    "ts": 1494115200,
    "supply": 305153900000,
    "trustlines": {
      "total": 30,
      "authorized": 30,
      "funded": 11
    },
    "payments": 8,
    "payments_amount": 1403666000,
    "trades": 5,
    "traded_amount": 1235841950,
    "price": [
      71.18055559179125,
      71.1805580271027,
      71.18055556,
      71.18055561373376
    ],
    "volume": 80849861112
  },
  {
    "ts": 1494201600,
    "supply": 305153900000,
    "trustlines": {
      "total": 31,
      "authorized": 31,
      "funded": 11
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055561373376,
      71.18055561373376,
      71.18055561373376,
      71.18055561373376
    ],
    "volume": 0
  },
  {
    "ts": 1494288000,
    "supply": 305153900000,
    "trustlines": {
      "total": 32,
      "authorized": 32,
      "funded": 12
    },
    "payments": 3,
    "payments_amount": 60000000,
    "trades": 10,
    "traded_amount": 75602956,
    "price": [
      71.18055561373376,
      71.18055561373376,
      67.70833674101249,
      71.18055556666667
    ],
    "volume": 5160771533
  },
  {
    "ts": 1494374400,
    "supply": 305153900000,
    "trustlines": {
      "total": 32,
      "authorized": 32,
      "funded": 12
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055556666667,
      71.18055556666667,
      71.18055556666667,
      71.18055556666667
    ],
    "volume": 0
  },
  {
    "ts": 1494460800,
    "supply": 305153900000,
    "trustlines": {
      "total": 32,
      "authorized": 32,
      "funded": 12
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055556666667,
      71.18055556666667,
      71.18055556666667,
      71.18055556666667
    ],
    "volume": 0
  },
  {
    "ts": 1494547200,
    "supply": 305153900000,
    "trustlines": {
      "total": 33,
      "authorized": 33,
      "funded": 12
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 3,
    "traded_amount": 12320000,
    "price": [
      71.18055556666667,
      71.18055556666667,
      67.70828376432283,
      67.70828376432283
    ],
    "volume": 417083354
  },
  {
    "ts": 1494633600,
    "supply": 305153900000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 186965853,
    "price": [
      67.70828376432283,
      71.18056715737255,
      28.571428571428573,
      28.571428571428573
    ],
    "volume": 5848031977
  },
  {
    "ts": 1494720000,
    "supply": 305153900000,
    "trustlines": {
      "total": 35,
      "authorized": 35,
      "funded": 13
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10899066,
    "price": [
      28.571428571428573,
      28.571428571428573,
      27.77777774719412,
      27.77777774719412
    ],
    "volume": 302751833
  },
  {
    "ts": 1494806400,
    "supply": 305153900000,
    "trustlines": {
      "total": 39,
      "authorized": 39,
      "funded": 13
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      27.77777774719412,
      27.77777774719412,
      27.77777774719412,
      27.77777774719412
    ],
    "volume": 0
  },
  {
    "ts": 1494892800,
    "supply": 305153900000,
    "trustlines": {
      "total": 41,
      "authorized": 41,
      "funded": 13
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      27.77777774719412,
      27.77777774719412,
      27.77777774719412,
      27.77777774719412
    ],
    "volume": 0
  },
  {
    "ts": 1494979200,
    "supply": 305153900000,
    "trustlines": {
      "total": 43,
      "authorized": 43,
      "funded": 13
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 41999640,
    "price": [
      27.77777774719412,
      27.77777774719412,
      27.77777774719412,
      27.77777774719412
    ],
    "volume": 0
  },
  {
    "ts": 1495065600,
    "supply": 305153900000,
    "trustlines": {
      "total": 49,
      "authorized": 49,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 3184849,
    "price": [
      27.77777774719412,
      28.571433056951836,
      27.77777774719412,
      28.571433056951836
    ],
    "volume": 90995700
  },
  {
    "ts": 1495152000,
    "supply": 305153900000,
    "trustlines": {
      "total": 49,
      "authorized": 49,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 40000000,
    "price": [
      28.571433056951836,
      28.571433056951836,
      28.571433056951836,
      28.571433056951836
    ],
    "volume": 0
  },
  {
    "ts": 1495238400,
    "supply": 305153900000,
    "trustlines": {
      "total": 51,
      "authorized": 51,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571433056951836,
      28.571433056951836,
      28.571433056951836,
      28.571433056951836
    ],
    "volume": 0
  },
  {
    "ts": 1495324800,
    "supply": 305153900000,
    "trustlines": {
      "total": 52,
      "authorized": 52,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571433056951836,
      28.571433056951836,
      28.571433056951836,
      28.571433056951836
    ],
    "volume": 0
  },
  {
    "ts": 1495497600,
    "supply": 305153900000,
    "trustlines": {
      "total": 53,
      "authorized": 53,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571433056951836,
      28.571433056951836,
      28.571433056951836,
      28.571433056951836
    ],
    "volume": 0
  },
  {
    "ts": 1495584000,
    "supply": 305153900000,
    "trustlines": {
      "total": 55,
      "authorized": 55,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571433056951836,
      28.571433056951836,
      28.571433056951836,
      28.571433056951836
    ],
    "volume": 0
  },
  {
    "ts": 1495670400,
    "supply": 305153900000,
    "trustlines": {
      "total": 55,
      "authorized": 55,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 20000000,
    "price": [
      28.571433056951836,
      28.571433056951836,
      27.77777775,
      27.77777775
    ],
    "volume": 555555555
  },
  {
    "ts": 1495756800,
    "supply": 305153900000,
    "trustlines": {
      "total": 56,
      "authorized": 56,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      27.77777775,
      27.77777775,
      27.77777775,
      27.77777775
    ],
    "volume": 0
  },
  {
    "ts": 1495843200,
    "supply": 305153900000,
    "trustlines": {
      "total": 58,
      "authorized": 58,
      "funded": 14
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      27.77777775,
      27.77777775,
      27.77777775,
      27.77777775
    ],
    "volume": 0
  },
  {
    "ts": 1495929600,
    "supply": 305153900000,
    "trustlines": {
      "total": 58,
      "authorized": 58,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 3510000,
    "price": [
      27.77777775,
      28.49002849002849,
      27.77777775,
      28.49002849002849
    ],
    "volume": 100000000
  },
  {
    "ts": 1496016000,
    "supply": 305153900000,
    "trustlines": {
      "total": 64,
      "authorized": 64,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 68464900,
    "price": [
      28.49002849002849,
      28.57142862292263,
      28.49002849002849,
      28.57142862292263
    ],
    "volume": 977541715
  },
  {
    "ts": 1496102400,
    "supply": 305153900000,
    "trustlines": {
      "total": 63,
      "authorized": 63,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.57142862292263,
      28.57142862292263,
      28.57142862292263,
      28.57142862292263
    ],
    "volume": 0
  },
  {
    "ts": 1496188800,
    "supply": 305153900000,
    "trustlines": {
      "total": 65,
      "authorized": 65,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.57142862292263,
      28.57142862292263,
      28.57142862292263,
      28.57142862292263
    ],
    "volume": 0
  },
  {
    "ts": 1496275200,
    "supply": 305153900000,
    "trustlines": {
      "total": 66,
      "authorized": 66,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 14411820,
    "price": [
      28.57142862292263,
      71.18055711416804,
      28.57142862292263,
      71.18055568276596
    ],
    "volume": 384841308
  },
  {
    "ts": 1496361600,
    "supply": 305153900000,
    "trustlines": {
      "total": 68,
      "authorized": 68,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 7205910,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 256460339
  },
  {
    "ts": 1496448000,
    "supply": 305153900000,
    "trustlines": {
      "total": 69,
      "authorized": 69,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1496793600,
    "supply": 305153900000,
    "trustlines": {
      "total": 72,
      "authorized": 72,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 7205910,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 256460339
  },
  {
    "ts": 1496880000,
    "supply": 305153900000,
    "trustlines": {
      "total": 73,
      "authorized": 73,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1496966400,
    "supply": 305153900000,
    "trustlines": {
      "total": 74,
      "authorized": 74,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497052800,
    "supply": 305153900000,
    "trustlines": {
      "total": 76,
      "authorized": 76,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497139200,
    "supply": 305153900000,
    "trustlines": {
      "total": 77,
      "authorized": 77,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497312000,
    "supply": 305153900000,
    "trustlines": {
      "total": 80,
      "authorized": 80,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497484800,
    "supply": 305153900000,
    "trustlines": {
      "total": 79,
      "authorized": 79,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497744000,
    "supply": 305153900000,
    "trustlines": {
      "total": 80,
      "authorized": 80,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497830400,
    "supply": 305153900000,
    "trustlines": {
      "total": 81,
      "authorized": 81,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1497916800,
    "supply": 305153900000,
    "trustlines": {
      "total": 82,
      "authorized": 82,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1498003200,
    "supply": 305153900000,
    "trustlines": {
      "total": 84,
      "authorized": 84,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1498089600,
    "supply": 305153900000,
    "trustlines": {
      "total": 85,
      "authorized": 85,
      "funded": 15
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      71.18055568276596,
      71.18055568276596,
      71.18055568276596,
      71.18055568276596
    ],
    "volume": 0
  },
  {
    "ts": 1498262400,
    "supply": 305153900000,
    "trustlines": {
      "total": 88,
      "authorized": 88,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 176700935,
    "price": [
      71.18055568276596,
      71.18055568276596,
      27.777777540035366,
      27.77777777777778
    ],
    "volume": 5241692612
  },
  {
    "ts": 1498435200,
    "supply": 305153900000,
    "trustlines": {
      "total": 91,
      "authorized": 91,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 7205910,
    "price": [
      27.77777777777778,
      35.36358183768601,
      27.77777777777778,
      35.36358183768601
    ],
    "volume": 127413394
  },
  {
    "ts": 1498521600,
    "supply": 305153900000,
    "trustlines": {
      "total": 93,
      "authorized": 93,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1498608000,
    "supply": 305153900000,
    "trustlines": {
      "total": 95,
      "authorized": 95,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1498694400,
    "supply": 305153900000,
    "trustlines": {
      "total": 96,
      "authorized": 96,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1498780800,
    "supply": 305153900000,
    "trustlines": {
      "total": 98,
      "authorized": 98,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1498867200,
    "supply": 305153900000,
    "trustlines": {
      "total": 100,
      "authorized": 100,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1498953600,
    "supply": 305153900000,
    "trustlines": {
      "total": 101,
      "authorized": 101,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      35.36358183768601,
      35.36358183768601,
      35.36358183768601,
      35.36358183768601
    ],
    "volume": 0
  },
  {
    "ts": 1499040000,
    "supply": 305153900000,
    "trustlines": {
      "total": 103,
      "authorized": 103,
      "funded": 17
    },
    "payments": 2,
    "payments_amount": 645289138,
    "trades": 3,
    "traded_amount": 1292005893,
    "price": [
      35.36358183768601,
      38.440820102148116,
      35.36358183768601,
      38.440820102148116
    ],
    "volume": 24860322436
  },
  {
    "ts": 1499126400,
    "supply": 305153900000,
    "trustlines": {
      "total": 105,
      "authorized": 105,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1427617,
    "price": [
      38.440820102148116,
      38.440820102148116,
      38.05832446657612,
      38.05832446657612
    ],
    "volume": 54332711
  },
  {
    "ts": 1499212800,
    "supply": 305153900000,
    "trustlines": {
      "total": 105,
      "authorized": 105,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      38.05832446657612,
      38.05832446657612,
      38.05832446657612,
      38.05832446657612
    ],
    "volume": 0
  },
  {
    "ts": 1499299200,
    "supply": 305153900000,
    "trustlines": {
      "total": 106,
      "authorized": 106,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      38.05832446657612,
      38.05832446657612,
      38.05832446657612,
      38.05832446657612
    ],
    "volume": 0
  },
  {
    "ts": 1499385600,
    "supply": 305153900000,
    "trustlines": {
      "total": 105,
      "authorized": 105,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      38.05832446657612,
      38.05832446657612,
      38.05832446657612,
      38.05832446657612
    ],
    "volume": 0
  },
  {
    "ts": 1499472000,
    "supply": 305153900000,
    "trustlines": {
      "total": 109,
      "authorized": 109,
      "funded": 17
    },
    "payments": 1,
    "payments_amount": 1424068,
    "trades": 3,
    "traded_amount": 2855234,
    "price": [
      38.05832446657612,
      44.721833657066284,
      38.05832446657612,
      44.276697661313044
    ],
    "volume": 64002788
  },
  {
    "ts": 1499558400,
    "supply": 305153900000,
    "trustlines": {
      "total": 110,
      "authorized": 110,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      44.276697661313044,
      44.276697661313044,
      44.276697661313044,
      44.276697661313044
    ],
    "volume": 0
  },
  {
    "ts": 1499644800,
    "supply": 305153900000,
    "trustlines": {
      "total": 112,
      "authorized": 112,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      44.276697661313044,
      44.276697661313044,
      44.276697661313044,
      44.276697661313044
    ],
    "volume": 0
  },
  {
    "ts": 1499731200,
    "supply": 305153900000,
    "trustlines": {
      "total": 112,
      "authorized": 112,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      44.276697661313044,
      44.276697661313044,
      44.276697661313044,
      44.276697661313044
    ],
    "volume": 0
  },
  {
    "ts": 1499817600,
    "supply": 305153900000,
    "trustlines": {
      "total": 114,
      "authorized": 114,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 7098,
    "price": [
      44.276697661313044,
      83.81290504367428,
      44.276697661313044,
      82.97886728655959
    ],
    "volume": 591944
  },
  {
    "ts": 1499904000,
    "supply": 305153900000,
    "trustlines": {
      "total": 115,
      "authorized": 115,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      82.97886728655959,
      82.97886728655959,
      82.97886728655959,
      82.97886728655959
    ],
    "volume": 0
  },
  {
    "ts": 1499990400,
    "supply": 305153900000,
    "trustlines": {
      "total": 115,
      "authorized": 115,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      82.97886728655959,
      82.97886728655959,
      82.97886728655959,
      82.97886728655959
    ],
    "volume": 0
  },
  {
    "ts": 1500163200,
    "supply": 305153900000,
    "trustlines": {
      "total": 116,
      "authorized": 116,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      82.97886728655959,
      82.97886728655959,
      82.97886728655959,
      82.97886728655959
    ],
    "volume": 0
  },
  {
    "ts": 1500249600,
    "supply": 305153900000,
    "trustlines": {
      "total": 118,
      "authorized": 118,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      82.97886728655959,
      82.97886728655959,
      82.97886728655959,
      82.97886728655959
    ],
    "volume": 0
  },
  {
    "ts": 1500336000,
    "supply": 305153900000,
    "trustlines": {
      "total": 117,
      "authorized": 117,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      82.97886728655959,
      82.97886728655959,
      82.97886728655959,
      82.97886728655959
    ],
    "volume": 0
  },
  {
    "ts": 1500508800,
    "supply": 305153900000,
    "trustlines": {
      "total": 117,
      "authorized": 117,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 3602956,
    "price": [
      82.97886728655959,
      111.11111117291061,
      82.97886728655959,
      111.11111117291061
    ],
    "volume": 200065639
  },
  {
    "ts": 1500595200,
    "supply": 305153900000,
    "trustlines": {
      "total": 118,
      "authorized": 118,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1500768000,
    "supply": 305153900000,
    "trustlines": {
      "total": 119,
      "authorized": 119,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1500854400,
    "supply": 305153900000,
    "trustlines": {
      "total": 120,
      "authorized": 120,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1500940800,
    "supply": 305153900000,
    "trustlines": {
      "total": 120,
      "authorized": 120,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1501027200,
    "supply": 305153900000,
    "trustlines": {
      "total": 125,
      "authorized": 125,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1501113600,
    "supply": 305153900000,
    "trustlines": {
      "total": 126,
      "authorized": 126,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1501200000,
    "supply": 305153900000,
    "trustlines": {
      "total": 127,
      "authorized": 127,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111117291061,
      111.11111117291061,
      111.11111117291061,
      111.11111117291061
    ],
    "volume": 0
  },
  {
    "ts": 1501286400,
    "supply": 305153900000,
    "trustlines": {
      "total": 127,
      "authorized": 127,
      "funded": 16
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 3602956,
    "price": [
      111.11111117291061,
      111.11111154285537,
      111.11111117291061,
      111.11111154285537
    ],
    "volume": 200164223
  },
  {
    "ts": 1501372800,
    "supply": 305153900000,
    "trustlines": {
      "total": 128,
      "authorized": 128,
      "funded": 17
    },
    "payments": 11,
    "payments_amount": 235210,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111154285537,
      111.11111154285537,
      111.11111154285537,
      111.11111154285537
    ],
    "volume": 0
  },
  {
    "ts": 1501459200,
    "supply": 305153900000,
    "trustlines": {
      "total": 129,
      "authorized": 129,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111154285537,
      111.11111154285537,
      111.11111154285537,
      111.11111154285537
    ],
    "volume": 0
  },
  {
    "ts": 1501632000,
    "supply": 405183900000,
    "trustlines": {
      "total": 129,
      "authorized": 129,
      "funded": 17
    },
    "payments": 4,
    "payments_amount": 100030000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.11111154285537,
      111.11111154285537,
      111.11111154285537,
      111.11111154285537
    ],
    "volume": 0
  },
  {
    "ts": 1501718400,
    "supply": 405183900000,
    "trustlines": {
      "total": 129,
      "authorized": 129,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 7206,
    "price": [
      111.11111154285537,
      111.1112961421038,
      111.11111154285537,
      111.1112961421038
    ],
    "volume": 400334
  },
  {
    "ts": 1501804800,
    "supply": 405183900000,
    "trustlines": {
      "total": 128,
      "authorized": 128,
      "funded": 17
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      111.1112961421038,
      111.1112961421038,
      111.1112961421038,
      111.1112961421038
    ],
    "volume": 0
  },
  {
    "ts": 1501891200,
    "supply": 405183900000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 19
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 2632102564,
    "price": [
      111.1112961421038,
      111.1112961421038,
      58.8235294117647,
      58.8235294117647
    ],
    "volume": 164548304527
  },
  {
    "ts": 1501977600,
    "supply": 405183900000,
    "trustlines": {
      "total": 131,
      "authorized": 131,
      "funded": 19
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.8235294117647,
      58.8235294117647,
      58.8235294117647,
      58.8235294117647
    ],
    "volume": 0
  },
  {
    "ts": 1502064000,
    "supply": 405183900000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 19
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.8235294117647,
      58.8235294117647,
      58.8235294117647,
      58.8235294117647
    ],
    "volume": 0
  },
  {
    "ts": 1502150400,
    "supply": 405183900000,
    "trustlines": {
      "total": 130,
      "authorized": 130,
      "funded": 19
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.8235294117647,
      58.8235294117647,
      58.8235294117647,
      58.8235294117647
    ],
    "volume": 0
  },
  {
    "ts": 1502236800,
    "supply": 405183900000,
    "trustlines": {
      "total": 132,
      "authorized": 132,
      "funded": 19
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 72228000,
    "price": [
      58.8235294117647,
      58.8235294117647,
      58.47953217588747,
      58.47953217588747
    ],
    "volume": 2111929825
  },
  {
    "ts": 1502323200,
    "supply": 405183900000,
    "trustlines": {
      "total": 135,
      "authorized": 135,
      "funded": 21
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 2,
    "traded_amount": 2710000,
    "price": [
      58.47953217588747,
      58.479533,
      58.47953216374269,
      58.479533
    ],
    "volume": 158479533
  },
  {
    "ts": 1502409600,
    "supply": 405183900000,
    "trustlines": {
      "total": 137,
      "authorized": 137,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.479533,
      58.479533,
      58.479533,
      58.479533
    ],
    "volume": 0
  },
  {
    "ts": 1502496000,
    "supply": 405183900000,
    "trustlines": {
      "total": 138,
      "authorized": 138,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.479533,
      58.479533,
      58.479533,
      58.479533
    ],
    "volume": 0
  },
  {
    "ts": 1502582400,
    "supply": 405184200000,
    "trustlines": {
      "total": 138,
      "authorized": 138,
      "funded": 21
    },
    "payments": 3,
    "payments_amount": 300000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.479533,
      58.479533,
      58.479533,
      58.479533
    ],
    "volume": 0
  },
  {
    "ts": 1502668800,
    "supply": 405184200000,
    "trustlines": {
      "total": 139,
      "authorized": 139,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 14446318972,
    "price": [
      58.479533,
      58.479533,
      58.479532163765604,
      58.479532163765604
    ],
    "volume": 422406987505
  },
  {
    "ts": 1502928000,
    "supply": 405184200000,
    "trustlines": {
      "total": 142,
      "authorized": 142,
      "funded": 21
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      58.479532163765604,
      58.479532163765604,
      58.479532163765604,
      58.479532163765604
    ],
    "volume": 0
  },
  {
    "ts": 1503014400,
    "supply": 405184200000,
    "trustlines": {
      "total": 141,
      "authorized": 141,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 30264822,
    "price": [
      58.479532163765604,
      227.27273130981195,
      58.479532163765604,
      227.27273130981195
    ],
    "volume": 1766806696
  },
  {
    "ts": 1503100800,
    "supply": 405184200000,
    "trustlines": {
      "total": 140,
      "authorized": 140,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27273130981195,
      227.27273130981195,
      227.27273130981195,
      227.27273130981195
    ],
    "volume": 0
  },
  {
    "ts": 1503187200,
    "supply": 405184200000,
    "trustlines": {
      "total": 143,
      "authorized": 143,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27273130981195,
      227.27273130981195,
      227.27273130981195,
      227.27273130981195
    ],
    "volume": 0
  },
  {
    "ts": 1503273600,
    "supply": 405184200000,
    "trustlines": {
      "total": 144,
      "authorized": 144,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27273130981195,
      227.27273130981195,
      227.27273130981195,
      227.27273130981195
    ],
    "volume": 0
  },
  {
    "ts": 1503360000,
    "supply": 405184200000,
    "trustlines": {
      "total": 145,
      "authorized": 145,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      227.27273130981195,
      227.27273130981195,
      227.27273130981195,
      227.27273130981195
    ],
    "volume": 0
  },
  {
    "ts": 1503446400,
    "supply": 405184200000,
    "trustlines": {
      "total": 144,
      "authorized": 144,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 6666668,
    "price": [
      227.27273130981195,
      227.27273130981195,
      62.5,
      62.5
    ],
    "volume": 208333375
  },
  {
    "ts": 1503532800,
    "supply": 405184200000,
    "trustlines": {
      "total": 144,
      "authorized": 144,
      "funded": 21
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      62.5,
      62.5,
      62.5,
      62.5
    ],
    "volume": 0
  },
  {
    "ts": 1503619200,
    "supply": 405184200000,
    "trustlines": {
      "total": 146,
      "authorized": 146,
      "funded": 22
    },
    "payments": 1,
    "payments_amount": 60500000,
    "trades": 2,
    "traded_amount": 121166660,
    "price": [
      62.5,
      62.5,
      62.5,
      62.5
    ],
    "volume": 3791666250
  },
  {
    "ts": 1503792000,
    "supply": 405184200000,
    "trustlines": {
      "total": 147,
      "authorized": 147,
      "funded": 22
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      62.5,
      62.5,
      62.5,
      62.5
    ],
    "volume": 0
  },
  {
    "ts": 1503878400,
    "supply": 405184200000,
    "trustlines": {
      "total": 150,
      "authorized": 150,
      "funded": 22
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      62.5,
      62.5,
      62.5,
      62.5
    ],
    "volume": 0
  },
  {
    "ts": 1503964800,
    "supply": 405184200000,
    "trustlines": {
      "total": 153,
      "authorized": 153,
      "funded": 23
    },
    "payments": 4,
    "payments_amount": 4564102564,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      62.5,
      62.5,
      62.5,
      62.5
    ],
    "volume": 0
  },
  {
    "ts": 1504051200,
    "supply": 405184200000,
    "trustlines": {
      "total": 154,
      "authorized": 154,
      "funded": 26
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 6,
    "traded_amount": 237408940,
    "price": [
      62.5,
      62.5,
      44.27678288171786,
      44.27678288171786
    ],
    "volume": 11549999100
  },
  {
    "ts": 1504137600,
    "supply": 405184200000,
    "trustlines": {
      "total": 155,
      "authorized": 155,
      "funded": 26
    },
    "payments": 4,
    "payments_amount": 22000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      44.27678288171786,
      44.27678288171786,
      44.27678288171786,
      44.27678288171786
    ],
    "volume": 0
  },
  {
    "ts": 1504224000,
    "supply": 405184200000,
    "trustlines": {
      "total": 155,
      "authorized": 155,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 1,
    "traded_amount": 277516924,
    "price": [
      44.27678288171786,
      54.05075763955931,
      44.27678288171786,
      54.05075763955931
    ],
    "volume": 15000000000
  },
  {
    "ts": 1504310400,
    "supply": 405184200000,
    "trustlines": {
      "total": 154,
      "authorized": 154,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      54.05075763955931,
      54.05075763955931,
      54.05075763955931,
      54.05075763955931
    ],
    "volume": 0
  },
  {
    "ts": 1504396800,
    "supply": 405184200000,
    "trustlines": {
      "total": 155,
      "authorized": 155,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      54.05075763955931,
      54.05075763955931,
      54.05075763955931,
      54.05075763955931
    ],
    "volume": 0
  },
  {
    "ts": 1504483200,
    "supply": 405184200000,
    "trustlines": {
      "total": 158,
      "authorized": 158,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 185011,
    "price": [
      54.05075763955931,
      54.05084022031122,
      54.05075763955931,
      54.05084022031122
    ],
    "volume": 10000000
  },
  {
    "ts": 1504569600,
    "supply": 405184200000,
    "trustlines": {
      "total": 160,
      "authorized": 160,
      "funded": 28
    },
    "payments": 3,
    "payments_amount": 21188749,
    "trades": 5,
    "traded_amount": 4078338787,
    "price": [
      54.05084022031122,
      55.165206160704884,
      51.95169531360286,
      51.95169531360286
    ],
    "volume": 218710500996
  },
  {
    "ts": 1504656000,
    "supply": 405184200000,
    "trustlines": {
      "total": 160,
      "authorized": 160,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 6,
    "price": [
      51.95169531360286,
      62.5,
      51.95169531360286,
      62.5
    ],
    "volume": 375
  },
  {
    "ts": 1504742400,
    "supply": 405184200000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 27
    },
    "payments": 4,
    "payments_amount": 383672,
    "trades": 5,
    "traded_amount": 20383672,
    "price": [
      62.5,
      62.5,
      57.570523891767415,
      57.600368642359314
    ],
    "volume": 1174133252
  },
  {
    "ts": 1504828800,
    "supply": 405184200000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 14182,
    "trades": 2,
    "traded_amount": 2854182,
    "price": [
      57.600368642359314,
      70.51191651389085,
      57.600368642359314,
      70.51191651389085
    ],
    "volume": 201000000
  },
  {
    "ts": 1504915200,
    "supply": 405184200000,
    "trustlines": {
      "total": 163,
      "authorized": 163,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.51191651389085,
      70.51191651389085,
      70.51191651389085,
      70.51191651389085
    ],
    "volume": 0
  },
  {
    "ts": 1505001600,
    "supply": 405184200000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.51191651389085,
      70.51191651389085,
      70.51191651389085,
      70.51191651389085
    ],
    "volume": 0
  },
  {
    "ts": 1505088000,
    "supply": 405184200000,
    "trustlines": {
      "total": 163,
      "authorized": 163,
      "funded": 27
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.51191651389085,
      70.51191651389085,
      70.51191651389085,
      70.51191651389085
    ],
    "volume": 0
  },
  {
    "ts": 1505174400,
    "supply": 405184200000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 27
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.51191651389085,
      70.51191651389085,
      70.51191651389085,
      70.51191651389085
    ],
    "volume": 0
  },
  {
    "ts": 1505260800,
    "supply": 405184200000,
    "trustlines": {
      "total": 162,
      "authorized": 162,
      "funded": 28
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 141200,
    "price": [
      70.51191651389085,
      70.8215297450425,
      70.51191651389085,
      70.8215297450425
    ],
    "volume": 10000000
  },
  {
    "ts": 1505347200,
    "supply": 405184200000,
    "trustlines": {
      "total": 161,
      "authorized": 161,
      "funded": 29
    },
    "payments": 10,
    "payments_amount": 100000,
    "trades": 11,
    "traded_amount": 10099999,
    "price": [
      70.8215297450425,
      70.8216,
      70.8215297450425,
      70.82153678215367
    ],
    "volume": 715297457
  },
  {
    "ts": 1505433600,
    "supply": 405184200000,
    "trustlines": {
      "total": 160,
      "authorized": 160,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.82153678215367,
      70.82153678215367,
      70.82153678215367,
      70.82153678215367
    ],
    "volume": 0
  },
  {
    "ts": 1505520000,
    "supply": 405184200000,
    "trustlines": {
      "total": 159,
      "authorized": 159,
      "funded": 29
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.82153678215367,
      70.82153678215367,
      70.82153678215367,
      70.82153678215367
    ],
    "volume": 0
  },
  {
    "ts": 1505606400,
    "supply": 405184200000,
    "trustlines": {
      "total": 160,
      "authorized": 160,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1000000,
    "price": [
      70.82153678215367,
      70.82153678215367,
      70.82153,
      70.82153
    ],
    "volume": 70821530
  },
  {
    "ts": 1505692800,
    "supply": 405184200000,
    "trustlines": {
      "total": 164,
      "authorized": 164,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.82153,
      70.82153,
      70.82153,
      70.82153
    ],
    "volume": 0
  },
  {
    "ts": 1505779200,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.82153,
      70.82153,
      70.82153,
      70.82153
    ],
    "volume": 0
  },
  {
    "ts": 1505865600,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      70.82153,
      70.82153,
      70.82153,
      70.82153
    ],
    "volume": 0
  },
  {
    "ts": 1506038400,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 6096758801,
    "price": [
      70.82153,
      83.33333365607751,
      70.82152976405379,
      83.33333333347063
    ],
    "volume": 502308609917
  },
  {
    "ts": 1506124800,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      83.33333333347063,
      83.33333333347063,
      83.33333333347063,
      83.33333333347063
    ],
    "volume": 0
  },
  {
    "ts": 1506211200,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      83.33333333347063,
      83.33333333347063,
      83.33333333347063,
      83.33333333347063
    ],
    "volume": 0
  },
  {
    "ts": 1506297600,
    "supply": 405184200000,
    "trustlines": {
      "total": 165,
      "authorized": 165,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 500000000,
    "price": [
      83.33333333347063,
      100,
      83.33333333347063,
      100
    ],
    "volume": 50000000000
  },
  {
    "ts": 1506384000,
    "supply": 405184200000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      100,
      100,
      100,
      100
    ],
    "volume": 0
  },
  {
    "ts": 1506470400,
    "supply": 405184200000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      100,
      100,
      100,
      100
    ],
    "volume": 0
  },
  {
    "ts": 1506556800,
    "supply": 405184200000,
    "trustlines": {
      "total": 166,
      "authorized": 166,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      100,
      100,
      100,
      100
    ],
    "volume": 0
  },
  {
    "ts": 1506643200,
    "supply": 405184200000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 31
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      100,
      100,
      100,
      100
    ],
    "volume": 0
  },
  {
    "ts": 1506729600,
    "supply": 405184200000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 9999999,
    "price": [
      100,
      100,
      85.76123377612338,
      85.76123377612338
    ],
    "volume": 857612252
  },
  {
    "ts": 1506816000,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      85.76123377612338,
      85.76123377612338,
      85.76123377612338,
      85.76123377612338
    ],
    "volume": 0
  },
  {
    "ts": 1506902400,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      85.76123377612338,
      85.76123377612338,
      85.76123377612338,
      85.76123377612338
    ],
    "volume": 0
  },
  {
    "ts": 1506988800,
    "supply": 405184200000,
    "trustlines": {
      "total": 168,
      "authorized": 168,
      "funded": 30
    },
    "payments": 2,
    "payments_amount": 200000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      85.76123377612338,
      85.76123377612338,
      85.76123377612338,
      85.76123377612338
    ],
    "volume": 0
  },
  {
    "ts": 1507075200,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 1,
    "payments_amount": 202412,
    "trades": 1,
    "traded_amount": 202412,
    "price": [
      85.76123377612338,
      98.80837104519495,
      85.76123377612338,
      98.80837104519495
    ],
    "volume": 20000000
  },
  {
    "ts": 1507161600,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      98.80837104519495,
      98.80837104519495,
      98.80837104519495,
      98.80837104519495
    ],
    "volume": 0
  },
  {
    "ts": 1507248000,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      98.80837104519495,
      98.80837104519495,
      98.80837104519495,
      98.80837104519495
    ],
    "volume": 0
  },
  {
    "ts": 1507334400,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 13,
    "traded_amount": 10380000000,
    "price": [
      98.80837104519495,
      98.80837104519495,
      55.84835607368421,
      56.28285511779932
    ],
    "volume": 584050926475
  },
  {
    "ts": 1507420800,
    "supply": 405184200000,
    "trustlines": {
      "total": 169,
      "authorized": 169,
      "funded": 30
    },
    "payments": 12,
    "payments_amount": 101100000,
    "trades": 1,
    "traded_amount": 100000,
    "price": [
      56.28285511779932,
      63.40559,
      56.28285511779932,
      63.40559
    ],
    "volume": 6340559
  },
  {
    "ts": 1507507200,
    "supply": 405084200000,
    "trustlines": {
      "total": 173,
      "authorized": 173,
      "funded": 32
    },
    "payments": 5,
    "payments_amount": 210000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      63.40559,
      63.40559,
      63.40559,
      63.40559
    ],
    "volume": 0
  },
  {
    "ts": 1507593600,
    "supply": 405084200000,
    "trustlines": {
      "total": 178,
      "authorized": 178,
      "funded": 35
    },
    "payments": 8,
    "payments_amount": 42000000,
    "trades": 7,
    "traded_amount": 33453828,
    "price": [
      63.40559,
      66.94211113997059,
      63.40559,
      66.743224
    ],
    "volume": 233486448
  },
  {
    "ts": 1507680000,
    "supply": 405084200000,
    "trustlines": {
      "total": 179,
      "authorized": 179,
      "funded": 35
    },
    "payments": 7,
    "payments_amount": 149080000,
    "trades": 5,
    "traded_amount": 129080000,
    "price": [
      66.743224,
      66.743224,
      27.77777777777778,
      27.77777777777778
    ],
    "volume": 3657445098
  },
  {
    "ts": 1507766400,
    "supply": 405084200000,
    "trustlines": {
      "total": 181,
      "authorized": 181,
      "funded": 36
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 3035933966,
    "price": [
      27.77777777777778,
      66.17654137113111,
      27.77777777777778,
      65.99527473832873
    ],
    "volume": 100009999968
  },
  {
    "ts": 1507852800,
    "supply": 405084189000,
    "trustlines": {
      "total": 181,
      "authorized": 181,
      "funded": 37
    },
    "payments": 2,
    "payments_amount": 11000,
    "trades": 5,
    "traded_amount": 2961166652,
    "price": [
      65.99527473832873,
      68.86949904271292,
      65.99527473832873,
      66.26477343034013
    ],
    "volume": 99010000000
  },
  {
    "ts": 1507939200,
    "supply": 405084189000,
    "trustlines": {
      "total": 182,
      "authorized": 182,
      "funded": 37
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      66.26477343034013,
      66.26477343034013,
      66.26477343034013,
      66.26477343034013
    ],
    "volume": 0
  },
  {
    "ts": 1508025600,
    "supply": 405084189000,
    "trustlines": {
      "total": 183,
      "authorized": 183,
      "funded": 37
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      66.26477343034013,
      66.26477343034013,
      66.26477343034013,
      66.26477343034013
    ],
    "volume": 0
  },
  {
    "ts": 1508112000,
    "supply": 405084189000,
    "trustlines": {
      "total": 190,
      "authorized": 190,
      "funded": 40
    },
    "payments": 3,
    "payments_amount": 20020000000,
    "trades": 12,
    "traded_amount": 15159010767,
    "price": [
      66.26477343034013,
      66.28765155180055,
      3.4482758620689653,
      3.4482758620689653
    ],
    "volume": 590180000000
  },
  {
    "ts": 1508198400,
    "supply": 405084189000,
    "trustlines": {
      "total": 194,
      "authorized": 194,
      "funded": 41
    },
    "payments": 3,
    "payments_amount": 30010200000,
    "trades": 3,
    "traded_amount": 110100000,
    "price": [
      3.4482758620689653,
      39.0625,
      3.4482758620689653,
      28.571428571428573
    ],
    "volume": 3199218750
  },
  {
    "ts": 1508284800,
    "supply": 405084189000,
    "trustlines": {
      "total": 197,
      "authorized": 197,
      "funded": 41
    },
    "payments": 9,
    "payments_amount": 71000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571428571428573,
      28.571428571428573,
      28.571428571428573,
      28.571428571428573
    ],
    "volume": 0
  },
  {
    "ts": 1508371200,
    "supply": 405084189000,
    "trustlines": {
      "total": 201,
      "authorized": 201,
      "funded": 41
    },
    "payments": 2,
    "payments_amount": 100010000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571428571428573,
      28.571428571428573,
      28.571428571428573,
      28.571428571428573
    ],
    "volume": 0
  },
  {
    "ts": 1508457600,
    "supply": 405084189000,
    "trustlines": {
      "total": 205,
      "authorized": 205,
      "funded": 41
    },
    "payments": 2,
    "payments_amount": 20200000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      28.571428571428573,
      28.571428571428573,
      28.571428571428573,
      28.571428571428573
    ],
    "volume": 0
  },
  {
    "ts": 1508544000,
    "supply": 405084189000,
    "trustlines": {
      "total": 206,
      "authorized": 206,
      "funded": 41
    },
    "payments": 1,
    "payments_amount": 10100000,
    "trades": 2,
    "traded_amount": 329322,
    "price": [
      28.571428571428573,
      40,
      28.571428571428573,
      40
    ],
    "volume": 6586440
  },
  {
    "ts": 1508630400,
    "supply": 405084189000,
    "trustlines": {
      "total": 208,
      "authorized": 208,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1508716800,
    "supply": 405084189000,
    "trustlines": {
      "total": 208,
      "authorized": 208,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1508803200,
    "supply": 405084189000,
    "trustlines": {
      "total": 212,
      "authorized": 212,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1508889600,
    "supply": 405084189000,
    "trustlines": {
      "total": 211,
      "authorized": 211,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1508976000,
    "supply": 405084189000,
    "trustlines": {
      "total": 213,
      "authorized": 213,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509062400,
    "supply": 405084189000,
    "trustlines": {
      "total": 214,
      "authorized": 214,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509148800,
    "supply": 405084189000,
    "trustlines": {
      "total": 216,
      "authorized": 216,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509235200,
    "supply": 405084189000,
    "trustlines": {
      "total": 218,
      "authorized": 218,
      "funded": 41
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509321600,
    "supply": 405084189000,
    "trustlines": {
      "total": 222,
      "authorized": 222,
      "funded": 43
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 1,
    "traded_amount": 25000,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 1000000
  },
  {
    "ts": 1509408000,
    "supply": 405084189000,
    "trustlines": {
      "total": 223,
      "authorized": 223,
      "funded": 43
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509494400,
    "supply": 405084189000,
    "trustlines": {
      "total": 224,
      "authorized": 224,
      "funded": 43
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509580800,
    "supply": 405084189000,
    "trustlines": {
      "total": 225,
      "authorized": 225,
      "funded": 43
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509667200,
    "supply": 405084189000,
    "trustlines": {
      "total": 228,
      "authorized": 228,
      "funded": 44
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509753600,
    "supply": 405084189000,
    "trustlines": {
      "total": 231,
      "authorized": 231,
      "funded": 44
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509840000,
    "supply": 405084189000,
    "trustlines": {
      "total": 235,
      "authorized": 235,
      "funded": 44
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      40,
      40,
      40,
      40
    ],
    "volume": 0
  },
  {
    "ts": 1509926400,
    "supply": 405084189000,
    "trustlines": {
      "total": 238,
      "authorized": 238,
      "funded": 44
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 9214629,
    "price": [
      40,
      66.2247385904338,
      40,
      66.2247385904338
    ],
    "volume": 500000000
  },
  {
    "ts": 1510012800,
    "supply": 405084189000,
    "trustlines": {
      "total": 240,
      "authorized": 240,
      "funded": 44
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      66.2247385904338,
      66.2247385904338,
      66.2247385904338,
      66.2247385904338
    ],
    "volume": 0
  },
  {
    "ts": 1510099200,
    "supply": 405084189000,
    "trustlines": {
      "total": 240,
      "authorized": 240,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 7038040171,
    "price": [
      66.2247385904338,
      66.2247385904338,
      33.3333332,
      33.3333332
    ],
    "volume": 129391624579
  },
  {
    "ts": 1510185600,
    "supply": 405084189000,
    "trustlines": {
      "total": 240,
      "authorized": 240,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      33.3333332,
      33.3333332,
      33.3333332,
      33.3333332
    ],
    "volume": 0
  },
  {
    "ts": 1510272000,
    "supply": 405084189000,
    "trustlines": {
      "total": 241,
      "authorized": 241,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 3612560047,
    "price": [
      33.3333332,
      33.3333332,
      29.250579958561485,
      32.40422325543089
    ],
    "volume": 116540677429
  },
  {
    "ts": 1510358400,
    "supply": 405084189000,
    "trustlines": {
      "total": 243,
      "authorized": 243,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      32.40422325543089,
      32.40422325543089,
      32.40422325543089,
      32.40422325543089
    ],
    "volume": 0
  },
  {
    "ts": 1510444800,
    "supply": 405084189000,
    "trustlines": {
      "total": 244,
      "authorized": 244,
      "funded": 46
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      32.40422325543089,
      32.40422325543089,
      32.40422325543089,
      32.40422325543089
    ],
    "volume": 0
  },
  {
    "ts": 1510531200,
    "supply": 405084189000,
    "trustlines": {
      "total": 245,
      "authorized": 245,
      "funded": 45
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10040131,
    "price": [
      32.40422325543089,
      36.66692416662691,
      32.40422325543089,
      36.66692416662691
    ],
    "volume": 368140722
  },
  {
    "ts": 1510617600,
    "supply": 405084189000,
    "trustlines": {
      "total": 246,
      "authorized": 246,
      "funded": 45
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 1757085009,
    "price": [
      36.66692416662691,
      36.66692416662691,
      35.83142525519604,
      35.83142525519604
    ],
    "volume": 63000000000
  },
  {
    "ts": 1510704000,
    "supply": 405084189000,
    "trustlines": {
      "total": 245,
      "authorized": 245,
      "funded": 46
    },
    "payments": 2,
    "payments_amount": 200018000,
    "trades": 5,
    "traded_amount": 200018000,
    "price": [
      35.83142525519604,
      35.83142525519604,
      29.322289266697247,
      29.322289266697247
    ],
    "volume": 6364386516
  },
  {
    "ts": 1510790400,
    "supply": 405084189000,
    "trustlines": {
      "total": 248,
      "authorized": 248,
      "funded": 47
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 1965163857,
    "price": [
      29.322289266697247,
      32.33765896950617,
      29.322289266697247,
      32.3134423555057
    ],
    "volume": 63500526707
  },
  {
    "ts": 1510876800,
    "supply": 405084189000,
    "trustlines": {
      "total": 248,
      "authorized": 248,
      "funded": 47
    },
    "payments": 3,
    "payments_amount": 914367,
    "trades": 6,
    "traded_amount": 4980994855,
    "price": [
      32.3134423555057,
      32.80958302300936,
      32.049825,
      32.52138500756593
    ],
    "volume": 81006150650
  },
  {
    "ts": 1510963200,
    "supply": 405084189000,
    "trustlines": {
      "total": 250,
      "authorized": 250,
      "funded": 48
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      32.52138500756593,
      32.52138500756593,
      32.52138500756593,
      32.52138500756593
    ],
    "volume": 0
  },
  {
    "ts": 1511049600,
    "supply": 405084189000,
    "trustlines": {
      "total": 248,
      "authorized": 248,
      "funded": 48
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 2638873621,
    "price": [
      32.52138500756593,
      32.52138500756593,
      31.2535949,
      31.60120813716035
    ],
    "volume": 73775374243
  },
  {
    "ts": 1511136000,
    "supply": 405084189000,
    "trustlines": {
      "total": 250,
      "authorized": 250,
      "funded": 48
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      31.60120813716035,
      31.60120813716035,
      31.60120813716035,
      31.60120813716035
    ],
    "volume": 0
  },
  {
    "ts": 1511222400,
    "supply": 405084189000,
    "trustlines": {
      "total": 250,
      "authorized": 250,
      "funded": 48
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      31.60120813716035,
      31.60120813716035,
      31.60120813716035,
      31.60120813716035
    ],
    "volume": 0
  },
  {
    "ts": 1511308800,
    "supply": 405084189000,
    "trustlines": {
      "total": 252,
      "authorized": 252,
      "funded": 49
    },
    "payments": 1,
    "payments_amount": 326900,
    "trades": 1,
    "traded_amount": 326900,
    "price": [
      31.60120813716035,
      31.60120813716035,
      30.593793208932397,
      30.593793208932397
    ],
    "volume": 10001111
  },
  {
    "ts": 1511395200,
    "supply": 405084189000,
    "trustlines": {
      "total": 253,
      "authorized": 253,
      "funded": 50
    },
    "payments": 3,
    "payments_amount": 30000000,
    "trades": 25,
    "traded_amount": 5650491452,
    "price": [
      30.593793208932397,
      30.593793208932397,
      26.837458350079736,
      27.686159747378554
    ],
    "volume": 159522400000
  },
  {
    "ts": 1511481600,
    "supply": 405104189000,
    "trustlines": {
      "total": 256,
      "authorized": 256,
      "funded": 51
    },
    "payments": 68,
    "payments_amount": 335055160,
    "trades": 17,
    "traded_amount": 3562802315,
    "price": [
      27.686159747378554,
      29.985285389880858,
      27.686159747378554,
      29.62821797328582
    ],
    "volume": 104131100000
  },
  {
    "ts": 1511568000,
    "supply": 405104189000,
    "trustlines": {
      "total": 257,
      "authorized": 257,
      "funded": 51
    },
    "payments": 9,
    "payments_amount": 37971770,
    "trades": 10,
    "traded_amount": 2238870948,
    "price": [
      29.62821797328582,
      29.62821797328582,
      27.754341685632063,
      27.754341700299186
    ],
    "volume": 63406000000
  },
  {
    "ts": 1511654400,
    "supply": 405104189000,
    "trustlines": {
      "total": 258,
      "authorized": 258,
      "funded": 51
    },
    "payments": 15,
    "payments_amount": 52205813,
    "trades": 1,
    "traded_amount": 416333,
    "price": [
      27.754341700299186,
      27.754341700299186,
      24.01923460307014,
      24.01923460307014
    ],
    "volume": 10000000
  },
  {
    "ts": 1511740800,
    "supply": 405104189000,
    "trustlines": {
      "total": 258,
      "authorized": 258,
      "funded": 51
    },
    "payments": 18,
    "payments_amount": 107659130,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      24.01923460307014,
      24.01923460307014,
      24.01923460307014,
      24.01923460307014
    ],
    "volume": 0
  },
  {
    "ts": 1511827200,
    "supply": 405104189000,
    "trustlines": {
      "total": 261,
      "authorized": 261,
      "funded": 54
    },
    "payments": 7,
    "payments_amount": 13435190,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      24.01923460307014,
      24.01923460307014,
      24.01923460307014,
      24.01923460307014
    ],
    "volume": 0
  },
  {
    "ts": 1511913600,
    "supply": 405104189000,
    "trustlines": {
      "total": 267,
      "authorized": 267,
      "funded": 54
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 110015571,
    "price": [
      24.01923460307014,
      24.01923460307014,
      12.4665423,
      16.31388230538623
    ],
    "volume": 1756307677
  },
  {
    "ts": 1512000000,
    "supply": 405104189000,
    "trustlines": {
      "total": 266,
      "authorized": 266,
      "funded": 54
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 16480096,
    "price": [
      16.31388230538623,
      18.04574730632637,
      16.31388230538623,
      18.04574730632637
    ],
    "volume": 148697824
  },
  {
    "ts": 1512086400,
    "supply": 405104189000,
    "trustlines": {
      "total": 276,
      "authorized": 276,
      "funded": 59
    },
    "payments": 4,
    "payments_amount": 314747617,
    "trades": 6,
    "traded_amount": 1022017124,
    "price": [
      18.04574730632637,
      18.04574730632637,
      14.24861821762825,
      14.248618218669797
    ],
    "volume": 14568825671
  },
  {
    "ts": 1512172800,
    "supply": 405104189000,
    "trustlines": {
      "total": 280,
      "authorized": 280,
      "funded": 59
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.248618218669797,
      14.248618218669797,
      14.248618218669797,
      14.248618218669797
    ],
    "volume": 0
  },
  {
    "ts": 1512259200,
    "supply": 405104189000,
    "trustlines": {
      "total": 280,
      "authorized": 280,
      "funded": 59
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.248618218669797,
      14.248618218669797,
      14.248618218669797,
      14.248618218669797
    ],
    "volume": 0
  },
  {
    "ts": 1512345600,
    "supply": 405104189000,
    "trustlines": {
      "total": 278,
      "authorized": 278,
      "funded": 59
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.248618218669797,
      14.248618218669797,
      14.248618218669797,
      14.248618218669797
    ],
    "volume": 0
  },
  {
    "ts": 1512432000,
    "supply": 405104189000,
    "trustlines": {
      "total": 281,
      "authorized": 281,
      "funded": 61
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 200867123,
    "price": [
      14.248618218669797,
      14.248618218669797,
      11.532389291945895,
      11.532389291945895
    ],
    "volume": 2512000000
  },
  {
    "ts": 1512518400,
    "supply": 405104189000,
    "trustlines": {
      "total": 284,
      "authorized": 284,
      "funded": 61
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      11.532389291945895,
      11.532389291945895,
      11.532389291945895,
      11.532389291945895
    ],
    "volume": 0
  },
  {
    "ts": 1512604800,
    "supply": 405104189000,
    "trustlines": {
      "total": 286,
      "authorized": 286,
      "funded": 62
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 54480340,
    "price": [
      11.532389291945895,
      11.532389291945895,
      8.25618379033611,
      8.25618379033611
    ],
    "volume": 449799700
  },
  {
    "ts": 1512691200,
    "supply": 405104189000,
    "trustlines": {
      "total": 292,
      "authorized": 292,
      "funded": 63
    },
    "payments": 4,
    "payments_amount": 202200000,
    "trades": 3,
    "traded_amount": 3067123,
    "price": [
      8.25618379033611,
      8.795437272727273,
      8.25618379033611,
      8.477191817077854
    ],
    "volume": 26350664
  },
  {
    "ts": 1512777600,
    "supply": 404328689000,
    "trustlines": {
      "total": 296,
      "authorized": 296,
      "funded": 63
    },
    "payments": 1,
    "payments_amount": 775500000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.477191817077854,
      8.477191817077854,
      8.477191817077854,
      8.477191817077854
    ],
    "volume": 0
  },
  {
    "ts": 1512864000,
    "supply": 404328689000,
    "trustlines": {
      "total": 296,
      "authorized": 296,
      "funded": 63
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.477191817077854,
      8.477191817077854,
      8.477191817077854,
      8.477191817077854
    ],
    "volume": 0
  },
  {
    "ts": 1512950400,
    "supply": 404328689000,
    "trustlines": {
      "total": 299,
      "authorized": 299,
      "funded": 63
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.477191817077854,
      8.477191817077854,
      8.477191817077854,
      8.477191817077854
    ],
    "volume": 0
  },
  {
    "ts": 1513036800,
    "supply": 404328689000,
    "trustlines": {
      "total": 301,
      "authorized": 301,
      "funded": 64
    },
    "payments": 1,
    "payments_amount": 816700000,
    "trades": 1,
    "traded_amount": 820000081,
    "price": [
      8.477191817077854,
      8.477191817077854,
      8.29458298309607,
      8.29458298309607
    ],
    "volume": 6801558718
  },
  {
    "ts": 1513123200,
    "supply": 404328689000,
    "trustlines": {
      "total": 302,
      "authorized": 302,
      "funded": 63
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 14,
    "traded_amount": 7018480154,
    "price": [
      8.29458298309607,
      8.29458298309607,
      7.050450470993651,
      7.414453739860067
    ],
    "volume": 51713205721
  },
  {
    "ts": 1513209600,
    "supply": 404328689000,
    "trustlines": {
      "total": 304,
      "authorized": 304,
      "funded": 63
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 824004776,
    "price": [
      7.414453739860067,
      7.762447471542325,
      7.414453739860067,
      7.762447471542325
    ],
    "volume": 3198146895
  },
  {
    "ts": 1513296000,
    "supply": 404328689000,
    "trustlines": {
      "total": 328,
      "authorized": 328,
      "funded": 70
    },
    "payments": 19,
    "payments_amount": 610362946,
    "trades": 1,
    "traded_amount": 141200,
    "price": [
      7.762447471542325,
      7.762447471542325,
      5.898406515580737,
      5.898406515580737
    ],
    "volume": 832855
  },
  {
    "ts": 1513382400,
    "supply": 404328689000,
    "trustlines": {
      "total": 332,
      "authorized": 332,
      "funded": 70
    },
    "payments": 7,
    "payments_amount": 2972313,
    "trades": 7,
    "traded_amount": 8858198313,
    "price": [
      5.898406515580737,
      5.941388117223766,
      5.5679287341642825,
      5.5679287341642825
    ],
    "volume": 51106941401
  },
  {
    "ts": 1513468800,
    "supply": 404328689000,
    "trustlines": {
      "total": 344,
      "authorized": 344,
      "funded": 73
    },
    "payments": 47,
    "payments_amount": 10009170,
    "trades": 3,
    "traded_amount": 11265574779,
    "price": [
      5.5679287341642825,
      5.719366897884084,
      5.2631579,
      5.2631579
    ],
    "volume": 63052631579
  },
  {
    "ts": 1513555200,
    "supply": 404328689000,
    "trustlines": {
      "total": 349,
      "authorized": 349,
      "funded": 73
    },
    "payments": 24,
    "payments_amount": 130866,
    "trades": 6,
    "traded_amount": 16115056988,
    "price": [
      5.2631579,
      5.2631579,
      4.33557777123558,
      4.33557777123558
    ],
    "volume": 68195848342
  },
  {
    "ts": 1513641600,
    "supply": 404328689000,
    "trustlines": {
      "total": 355,
      "authorized": 355,
      "funded": 75
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 1,
    "traded_amount": 2415634,
    "price": [
      4.33557777123558,
      4.33557777123558,
      4.1396999711048945,
      4.1396999711048945
    ],
    "volume": 10000000
  },
  {
    "ts": 1513728000,
    "supply": 404328689000,
    "trustlines": {
      "total": 358,
      "authorized": 358,
      "funded": 74
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 116985776,
    "price": [
      4.1396999711048945,
      4.233996028713781,
      4.1396999711048945,
      4.233996028713781
    ],
    "volume": 495317311
  },
  {
    "ts": 1513814400,
    "supply": 404328689000,
    "trustlines": {
      "total": 363,
      "authorized": 363,
      "funded": 73
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1415634,
    "price": [
      4.233996028713781,
      4.373121866245088,
      4.233996028713781,
      4.373121866245088
    ],
    "volume": 6190740
  },
  {
    "ts": 1513900800,
    "supply": 404328689000,
    "trustlines": {
      "total": 366,
      "authorized": 366,
      "funded": 76
    },
    "payments": 2,
    "payments_amount": 1825800000,
    "trades": 6,
    "traded_amount": 9145904356,
    "price": [
      4.373121866245088,
      6.97350069735007,
      4.373121866245088,
      6.97350069735007
    ],
    "volume": 50388224366
  },
  {
    "ts": 1513987200,
    "supply": 404328689000,
    "trustlines": {
      "total": 368,
      "authorized": 368,
      "funded": 76
    },
    "payments": 1,
    "payments_amount": 1295500000,
    "trades": 1,
    "traded_amount": 1295500000,
    "price": [
      6.97350069735007,
      6.97350069735007,
      5.197538916248552,
      5.197538916248552
    ],
    "volume": 6733411666
  },
  {
    "ts": 1514073600,
    "supply": 404328689000,
    "trustlines": {
      "total": 369,
      "authorized": 369,
      "funded": 76
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 9228856,
    "price": [
      5.197538916248552,
      6.973501374384864,
      5.197538916248552,
      6.973500969856574
    ],
    "volume": 32178718
  },
  {
    "ts": 1514160000,
    "supply": 404328689000,
    "trustlines": {
      "total": 366,
      "authorized": 366,
      "funded": 76
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      6.973500969856574,
      6.973500969856574,
      6.973500969856574,
      6.973500969856574
    ],
    "volume": 0
  },
  {
    "ts": 1514246400,
    "supply": 404328689000,
    "trustlines": {
      "total": 367,
      "authorized": 367,
      "funded": 77
    },
    "payments": 1,
    "payments_amount": 2500000,
    "trades": 1,
    "traded_amount": 17852033,
    "price": [
      6.973500969856574,
      6.973500969856574,
      5.601602909875867,
      5.601602909875867
    ],
    "volume": 100000000
  },
  {
    "ts": 1514332800,
    "supply": 404328689000,
    "trustlines": {
      "total": 368,
      "authorized": 368,
      "funded": 77
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      5.601602909875867,
      5.601602909875867,
      5.601602909875867,
      5.601602909875867
    ],
    "volume": 0
  },
  {
    "ts": 1514419200,
    "supply": 404328689000,
    "trustlines": {
      "total": 375,
      "authorized": 375,
      "funded": 80
    },
    "payments": 3,
    "payments_amount": 1236000000,
    "trades": 8,
    "traded_amount": 5924337137,
    "price": [
      5.601602909875867,
      5.950000001099148,
      5.37674484,
      5.950000001099148
    ],
    "volume": 19044014275
  },
  {
    "ts": 1514505600,
    "supply": 404328689000,
    "trustlines": {
      "total": 376,
      "authorized": 376,
      "funded": 78
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 575640526,
    "price": [
      5.950000001099148,
      5.950000001099148,
      5.023995559540583,
      5.024000795829713
    ],
    "volume": 2892018460
  },
  {
    "ts": 1514592000,
    "supply": 404328689000,
    "trustlines": {
      "total": 386,
      "authorized": 386,
      "funded": 79
    },
    "payments": 13,
    "payments_amount": 10000000,
    "trades": 13,
    "traded_amount": 10000000,
    "price": [
      5.024000795829713,
      5.024000795829713,
      3.3333333333333335,
      3.3333333333333335
    ],
    "volume": 18690586
  },
  {
    "ts": 1514678400,
    "supply": 404328689000,
    "trustlines": {
      "total": 385,
      "authorized": 385,
      "funded": 80
    },
    "payments": 2,
    "payments_amount": 39998960,
    "trades": 2,
    "traded_amount": 39998960,
    "price": [
      3.3333333333333335,
      3.6403007,
      3.3333333333333335,
      3.576685987671359
    ],
    "volume": 144336014
  },
  {
    "ts": 1514764800,
    "supply": 404328689000,
    "trustlines": {
      "total": 390,
      "authorized": 390,
      "funded": 79
    },
    "payments": 1,
    "payments_amount": 300000,
    "trades": 8,
    "traded_amount": 5882911662,
    "price": [
      3.576685987671359,
      3.640300660634517,
      3.3333333188373437,
      3.3333333333333335
    ],
    "volume": 20073052148
  },
  {
    "ts": 1514851200,
    "supply": 404328689000,
    "trustlines": {
      "total": 392,
      "authorized": 392,
      "funded": 81
    },
    "payments": 1,
    "payments_amount": 500000000,
    "trades": 3,
    "traded_amount": 1804804192,
    "price": [
      3.3333333333333335,
      3.3333333333333335,
      2,
      2
    ],
    "volume": 5033107721
  },
  {
    "ts": 1514937600,
    "supply": 404328689000,
    "trustlines": {
      "total": 406,
      "authorized": 406,
      "funded": 81
    },
    "payments": 1,
    "payments_amount": 4950000000,
    "trades": 4,
    "traded_amount": 5915295418,
    "price": [
      2,
      2.0807720936235246,
      2,
      2
    ],
    "volume": 11850000000
  },
  {
    "ts": 1515024000,
    "supply": 404328689000,
    "trustlines": {
      "total": 407,
      "authorized": 407,
      "funded": 83
    },
    "payments": 1,
    "payments_amount": 9900000000,
    "trades": 8,
    "traded_amount": 16520626204,
    "price": [
      2,
      2,
      1.2366732702999401,
      1.4017095657779708
    ],
    "volume": 30883801614
  },
  {
    "ts": 1515110400,
    "supply": 404328689000,
    "trustlines": {
      "total": 413,
      "authorized": 413,
      "funded": 83
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 6,
    "traded_amount": 2102633855,
    "price": [
      1.4017095657779708,
      1.500000150000015,
      1.1111111,
      1.290758
    ],
    "volume": 3034430734
  },
  {
    "ts": 1515196800,
    "supply": 404328689000,
    "trustlines": {
      "total": 418,
      "authorized": 418,
      "funded": 84
    },
    "payments": 2,
    "payments_amount": 87473809,
    "trades": 7,
    "traded_amount": 6383718322,
    "price": [
      1.290758,
      1.6020316760536544,
      1.290758,
      1.6020316760536544
    ],
    "volume": 10175721425
  },
  {
    "ts": 1515283200,
    "supply": 404328689000,
    "trustlines": {
      "total": 420,
      "authorized": 420,
      "funded": 84
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 6242073,
    "price": [
      1.6020316760536544,
      1.602031889085565,
      1.6020316760536544,
      1.602031889085565
    ],
    "volume": 10000000
  },
  {
    "ts": 1515369600,
    "supply": 404328689000,
    "trustlines": {
      "total": 425,
      "authorized": 425,
      "funded": 85
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 6,
    "traded_amount": 31000000000,
    "price": [
      1.602031889085565,
      1.7499999991183788,
      1.602031889085565,
      1.7049121122743913
    ],
    "volume": 53787379184
  },
  {
    "ts": 1515456000,
    "supply": 404328689000,
    "trustlines": {
      "total": 428,
      "authorized": 428,
      "funded": 85
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 18,
    "traded_amount": 21589169542,
    "price": [
      1.7049121122743913,
      2.020456186552266,
      1.7049121122743913,
      1.9535647717965907
    ],
    "volume": 38018394726
  },
  {
    "ts": 1515542400,
    "supply": 404328689000,
    "trustlines": {
      "total": 432,
      "authorized": 432,
      "funded": 84
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 22,
    "traded_amount": 1197959520,
    "price": [
      1.9535647717965907,
      2,
      1.9052118726786997,
      2
    ],
    "volume": 222199892
  },
  {
    "ts": 1515628800,
    "supply": 404328689000,
    "trustlines": {
      "total": 436,
      "authorized": 436,
      "funded": 84
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 7016282223,
    "price": [
      2,
      2.2919182600364154,
      1.9892120345984525,
      1.9892120646959237
    ],
    "volume": 15000000004
  },
  {
    "ts": 1515715200,
    "supply": 404328689000,
    "trustlines": {
      "total": 449,
      "authorized": 449,
      "funded": 89
    },
    "payments": 1,
    "payments_amount": 1000000000,
    "trades": 30,
    "traded_amount": 14603478433,
    "price": [
      1.9892120646959237,
      1.9892120646959237,
      1.7342296525869765,
      1.81205424
    ],
    "volume": 26111439284
  },
  {
    "ts": 1515801600,
    "supply": 404328689000,
    "trustlines": {
      "total": 457,
      "authorized": 457,
      "funded": 89
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 2153000001,
    "price": [
      1.81205424,
      1.872730622,
      1.7346053736865554,
      1.872730622
    ],
    "volume": 3845087956
  },
  {
    "ts": 1515888000,
    "supply": 404328689000,
    "trustlines": {
      "total": 464,
      "authorized": 464,
      "funded": 89
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 10432605573,
    "price": [
      1.872730622,
      2.0680618077262745,
      1.872730622,
      2.055665062
    ],
    "volume": 21263313372
  },
  {
    "ts": 1515974400,
    "supply": 404328689000,
    "trustlines": {
      "total": 471,
      "authorized": 471,
      "funded": 91
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 32,
    "traded_amount": 74392904980,
    "price": [
      2.055665062,
      2.055665062,
      1.8471632720746642,
      1.9691410370698497
    ],
    "volume": 18516030433
  },
  {
    "ts": 1516060800,
    "supply": 404328689000,
    "trustlines": {
      "total": 484,
      "authorized": 484,
      "funded": 92
    },
    "payments": 18,
    "payments_amount": 60000,
    "trades": 9,
    "traded_amount": 3201181076,
    "price": [
      1.9691410370698497,
      2.71217737,
      1.9691410370698497,
      2.71217737
    ],
    "volume": 7993965460
  },
  {
    "ts": 1516147200,
    "supply": 404328689000,
    "trustlines": {
      "total": 492,
      "authorized": 492,
      "funded": 90
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 3143485986,
    "price": [
      2.71217737,
      3.4901499,
      2.71217737,
      2.961102600692116
    ],
    "volume": 9472336313
  },
  {
    "ts": 1516233600,
    "supply": 404328689000,
    "trustlines": {
      "total": 509,
      "authorized": 509,
      "funded": 91
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 6000000270,
    "price": [
      2.961102600692116,
      2.961102600692116,
      2.2933712625,
      2.2933712625
    ],
    "volume": 14830575624
  },
  {
    "ts": 1516320000,
    "supply": 404328689000,
    "trustlines": {
      "total": 513,
      "authorized": 513,
      "funded": 91
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.2933712625,
      2.2933712625,
      2.2933712625,
      2.2933712625
    ],
    "volume": 0
  },
  {
    "ts": 1516406400,
    "supply": 404328689000,
    "trustlines": {
      "total": 519,
      "authorized": 519,
      "funded": 91
    },
    "payments": 1,
    "payments_amount": 200000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.2933712625,
      2.2933712625,
      2.2933712625,
      2.2933712625
    ],
    "volume": 0
  },
  {
    "ts": 1516492800,
    "supply": 404328689000,
    "trustlines": {
      "total": 520,
      "authorized": 520,
      "funded": 92
    },
    "payments": 3,
    "payments_amount": 1015700000,
    "trades": 5,
    "traded_amount": 6013990965,
    "price": [
      2.2933712625,
      2.548208870390819,
      2.2933712625,
      2.5310368074456746
    ],
    "volume": 15324730309
  },
  {
    "ts": 1516579200,
    "supply": 404328689000,
    "trustlines": {
      "total": 528,
      "authorized": 528,
      "funded": 93
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 16,
    "traded_amount": 27322709188,
    "price": [
      2.5310368074456746,
      2.810774316,
      2.3552570210528034,
      2.602262216095824
    ],
    "volume": 69851826302
  },
  {
    "ts": 1516665600,
    "supply": 404328689000,
    "trustlines": {
      "total": 531,
      "authorized": 531,
      "funded": 93
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 5,
    "traded_amount": 3924309199,
    "price": [
      2.602262216095824,
      2.6803381779798836,
      2.3884149807896256,
      2.3884149807896256
    ],
    "volume": 9876055977
  },
  {
    "ts": 1516752000,
    "supply": 504328689000,
    "trustlines": {
      "total": 542,
      "authorized": 542,
      "funded": 101
    },
    "payments": 7,
    "payments_amount": 100420995003,
    "trades": 25,
    "traded_amount": 28610015626,
    "price": [
      2.3884149807896256,
      2.546116,
      2.1116859239858403,
      2.1798830105021008
    ],
    "volume": 63819208675
  },
  {
    "ts": 1516838400,
    "supply": 504328689000,
    "trustlines": {
      "total": 545,
      "authorized": 545,
      "funded": 101
    },
    "payments": 3,
    "payments_amount": 606000000,
    "trades": 37,
    "traded_amount": 55992925687,
    "price": [
      2.1798830105021008,
      2.1798830796867867,
      1.91575443,
      2.0768431983385254
    ],
    "volume": 33071034235
  },
  {
    "ts": 1516924800,
    "supply": 504328689000,
    "trustlines": {
      "total": 560,
      "authorized": 560,
      "funded": 102
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 2977325536,
    "price": [
      2.0768431983385254,
      2.16217424,
      1.96488285,
      1.9801980059551336
    ],
    "volume": 5976452122
  },
  {
    "ts": 1517011200,
    "supply": 504328689000,
    "trustlines": {
      "total": 571,
      "authorized": 571,
      "funded": 102
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 950000000,
    "price": [
      1.9801980059551336,
      1.9801980059551336,
      1.9589675,
      1.97741141
    ],
    "volume": 1868987272
  },
  {
    "ts": 1517097600,
    "supply": 504328689000,
    "trustlines": {
      "total": 581,
      "authorized": 581,
      "funded": 105
    },
    "payments": 2,
    "payments_amount": 3154800000,
    "trades": 11,
    "traded_amount": 18165923508,
    "price": [
      1.97741141,
      2,
      1.9567171506171286,
      1.9811948157996007
    ],
    "volume": 36181395702
  },
  {
    "ts": 1517184000,
    "supply": 504328689000,
    "trustlines": {
      "total": 593,
      "authorized": 593,
      "funded": 108
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 25,
    "traded_amount": 40588047697,
    "price": [
      1.9811948157996007,
      2.1394705718326024,
      1.9811948157996007,
      2.096936245815106
    ],
    "volume": 83908026263
  },
  {
    "ts": 1517270400,
    "supply": 504328689000,
    "trustlines": {
      "total": 617,
      "authorized": 617,
      "funded": 108
    },
    "payments": 2,
    "payments_amount": 20000000000,
    "trades": 16,
    "traded_amount": 12237538999,
    "price": [
      2.096936245815106,
      2.5496324828256682,
      2.096936245815106,
      2.4505260237899518
    ],
    "volume": 28866818395
  },
  {
    "ts": 1517356800,
    "supply": 504328689000,
    "trustlines": {
      "total": 635,
      "authorized": 635,
      "funded": 110
    },
    "payments": 4,
    "payments_amount": 4201000000,
    "trades": 21,
    "traded_amount": 9883074984,
    "price": [
      2.4505260237899518,
      2.64200793,
      2.308957438756636,
      2.357080876017593
    ],
    "volume": 25464705511
  },
  {
    "ts": 1517443200,
    "supply": 504328689000,
    "trustlines": {
      "total": 647,
      "authorized": 647,
      "funded": 110
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 5037449430,
    "price": [
      2.357080876017593,
      2.891291404,
      2.357080876017593,
      2.891291404
    ],
    "volume": 13842159718
  },
  {
    "ts": 1517529600,
    "supply": 504328689000,
    "trustlines": {
      "total": 651,
      "authorized": 651,
      "funded": 112
    },
    "payments": 3,
    "payments_amount": 9920000000,
    "trades": 17,
    "traded_amount": 20381165924,
    "price": [
      2.891291404,
      3.8138825324180017,
      2.7837836356538515,
      2.7837836356538515
    ],
    "volume": 60631834903
  },
  {
    "ts": 1517616000,
    "supply": 504328689000,
    "trustlines": {
      "total": 652,
      "authorized": 652,
      "funded": 110
    },
    "payments": 1,
    "payments_amount": 500000000,
    "trades": 3,
    "traded_amount": 1237756146,
    "price": [
      2.7837836356538515,
      3.241165918497655,
      2.7837836356538515,
      2.8309550658727614
    ],
    "volume": 3780418458
  },
  {
    "ts": 1517702400,
    "supply": 504328689000,
    "trustlines": {
      "total": 656,
      "authorized": 656,
      "funded": 110
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      2.8309550658727614,
      2.8309550658727614,
      2.8309550658727614,
      2.8309550658727614
    ],
    "volume": 0
  },
  {
    "ts": 1517788800,
    "supply": 504328689000,
    "trustlines": {
      "total": 660,
      "authorized": 660,
      "funded": 112
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 1575068760,
    "price": [
      2.8309550658727614,
      3.6567203124540253,
      2.8309550658727614,
      3.6567203124540253
    ],
    "volume": 5549253547
  },
  {
    "ts": 1517875200,
    "supply": 504328689000,
    "trustlines": {
      "total": 669,
      "authorized": 669,
      "funded": 112
    },
    "payments": 3,
    "payments_amount": 100190080,
    "trades": 3,
    "traded_amount": 100190080,
    "price": [
      3.6567203124540253,
      3.6567203124540253,
      3.187880951773737,
      3.187880951773737
    ],
    "volume": 338571429
  },
  {
    "ts": 1517961600,
    "supply": 504328689000,
    "trustlines": {
      "total": 680,
      "authorized": 680,
      "funded": 117
    },
    "payments": 4,
    "payments_amount": 20100000000,
    "trades": 7,
    "traded_amount": 377489800,
    "price": [
      3.187880951773737,
      3.3333333333333335,
      3.1878808917197454,
      3.225806451612903
    ],
    "volume": 716902564
  },
  {
    "ts": 1518048000,
    "supply": 504328689000,
    "trustlines": {
      "total": 691,
      "authorized": 691,
      "funded": 119
    },
    "payments": 8,
    "payments_amount": 162000000000,
    "trades": 14,
    "traded_amount": 20776124566,
    "price": [
      3.225806451612903,
      3.225806451612903,
      1.948558063183977,
      1.948558063183977
    ],
    "volume": 57211102823
  },
  {
    "ts": 1518134400,
    "supply": 504328689000,
    "trustlines": {
      "total": 692,
      "authorized": 692,
      "funded": 119
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 14,
    "traded_amount": 6493392600,
    "price": [
      1.948558063183977,
      3.030303039411154,
      1.948558063183977,
      3.030303032105196
    ],
    "volume": 9883931215
  },
  {
    "ts": 1518220800,
    "supply": 504328689000,
    "trustlines": {
      "total": 697,
      "authorized": 697,
      "funded": 117
    },
    "payments": 2,
    "payments_amount": 1950000000,
    "trades": 13,
    "traded_amount": 16302463095,
    "price": [
      3.030303032105196,
      3.030303032105196,
      2.580267610155799,
      3.0303030303030303
    ],
    "volume": 44327804493
  },
  {
    "ts": 1518307200,
    "supply": 504328689000,
    "trustlines": {
      "total": 701,
      "authorized": 701,
      "funded": 117
    },
    "payments": 3,
    "payments_amount": 289838434,
    "trades": 6,
    "traded_amount": 863684014,
    "price": [
      3.0303030303030303,
      3.334,
      3.0303030303030303,
      3.1367323606788164
    ],
    "volume": 2723820143
  },
  {
    "ts": 1518393600,
    "supply": 504328689000,
    "trustlines": {
      "total": 708,
      "authorized": 708,
      "funded": 118
    },
    "payments": 3,
    "payments_amount": 2145634234,
    "trades": 14,
    "traded_amount": 12960433939,
    "price": [
      3.1367323606788164,
      3.1885290120507177,
      2.9565063905406603,
      2.9565063905406603
    ],
    "volume": 39040038934
  },
  {
    "ts": 1518480000,
    "supply": 504328689000,
    "trustlines": {
      "total": 713,
      "authorized": 713,
      "funded": 116
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 19730458358,
    "price": [
      2.9565063905406603,
      3.333333333424942,
      2.9051969834472735,
      2.9051969834472735
    ],
    "volume": 61874458630
  },
  {
    "ts": 1518566400,
    "supply": 504328689000,
    "trustlines": {
      "total": 714,
      "authorized": 714,
      "funded": 116
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 5570806140,
    "price": [
      2.9051969834472735,
      2.9507045923216633,
      2.727573082,
      2.734550561797753
    ],
    "volume": 15520592270
  },
  {
    "ts": 1518652800,
    "supply": 504328689000,
    "trustlines": {
      "total": 717,
      "authorized": 717,
      "funded": 116
    },
    "payments": 1,
    "payments_amount": 201000000,
    "trades": 11,
    "traded_amount": 12066311936,
    "price": [
      2.734550561797753,
      2.7927244554003354,
      2.6505994394551537,
      2.7927244554003354
    ],
    "volume": 32721596056
  },
  {
    "ts": 1518739200,
    "supply": 504328689000,
    "trustlines": {
      "total": 727,
      "authorized": 727,
      "funded": 117
    },
    "payments": 2,
    "payments_amount": 340101846,
    "trades": 12,
    "traded_amount": 4851128378,
    "price": [
      2.7927244554003354,
      2.8291374125912694,
      2.709963,
      2.709963
    ],
    "volume": 13515729899
  },
  {
    "ts": 1518825600,
    "supply": 504328689000,
    "trustlines": {
      "total": 733,
      "authorized": 733,
      "funded": 117
    },
    "payments": 2,
    "payments_amount": 279999728,
    "trades": 6,
    "traded_amount": 4310784953,
    "price": [
      2.709963,
      2.709963,
      2.5783337,
      2.5783337
    ],
    "volume": 11287296924
  },
  {
    "ts": 1518912000,
    "supply": 504328689000,
    "trustlines": {
      "total": 740,
      "authorized": 740,
      "funded": 119
    },
    "payments": 2,
    "payments_amount": 339620391,
    "trades": 12,
    "traded_amount": 11587348781,
    "price": [
      2.5783337,
      2.851391030805519,
      2.5783337,
      2.7039178
    ],
    "volume": 32290116714
  },
  {
    "ts": 1518998400,
    "supply": 504328689000,
    "trustlines": {
      "total": 748,
      "authorized": 748,
      "funded": 122
    },
    "payments": 6,
    "payments_amount": 5002202022,
    "trades": 6,
    "traded_amount": 3591874831,
    "price": [
      2.7039178,
      2.7493151868266894,
      2.69433353,
      2.708382399536024
    ],
    "volume": 9748402254
  },
  {
    "ts": 1519084800,
    "supply": 504328689000,
    "trustlines": {
      "total": 757,
      "authorized": 757,
      "funded": 125
    },
    "payments": 5,
    "payments_amount": 831960000,
    "trades": 113,
    "traded_amount": 90635296931,
    "price": [
      2.708382399536024,
      3.1235141701617013,
      2.708382399536024,
      3.1235141701617013
    ],
    "volume": 257458977809
  },
  {
    "ts": 1519171200,
    "supply": 504328689000,
    "trustlines": {
      "total": 760,
      "authorized": 760,
      "funded": 123
    },
    "payments": 10,
    "payments_amount": 25832781345,
    "trades": 60,
    "traded_amount": 53557079024,
    "price": [
      3.1235141701617013,
      284.3332449617179,
      2.8512663147087416,
      3.0275820590906664
    ],
    "volume": 284699741513
  },
  {
    "ts": 1519257600,
    "supply": 504328689000,
    "trustlines": {
      "total": 764,
      "authorized": 764,
      "funded": 123
    },
    "payments": 4,
    "payments_amount": 456361907,
    "trades": 27,
    "traded_amount": 31192727968,
    "price": [
      3.0275820590906664,
      3.4482758616859708,
      3.0275820590906664,
      3.3316708
    ],
    "volume": 55987916239
  },
  {
    "ts": 1519344000,
    "supply": 504328689000,
    "trustlines": {
      "total": 768,
      "authorized": 768,
      "funded": 125
    },
    "payments": 2,
    "payments_amount": 1660808954,
    "trades": 28,
    "traded_amount": 26208144692,
    "price": [
      3.3316708,
      3.47330625186196,
      3.224371918,
      3.2484027603627297
    ],
    "volume": 30518086837
  },
  {
    "ts": 1519430400,
    "supply": 504328689000,
    "trustlines": {
      "total": 766,
      "authorized": 766,
      "funded": 125
    },
    "payments": 3,
    "payments_amount": 9066800000,
    "trades": 3,
    "traded_amount": 107360000,
    "price": [
      3.2484027603627297,
      3.4698475,
      3.2484027603627297,
      3.4441987
    ],
    "volume": 369140462
  },
  {
    "ts": 1519516800,
    "supply": 504328689000,
    "trustlines": {
      "total": 769,
      "authorized": 769,
      "funded": 126
    },
    "payments": 8,
    "payments_amount": 10516938994,
    "trades": 15,
    "traded_amount": 3783743980,
    "price": [
      3.4441987,
      3.546538206324116,
      3.3750994,
      3.3750994
    ],
    "volume": 13290197060
  },
  {
    "ts": 1519603200,
    "supply": 504328689000,
    "trustlines": {
      "total": 776,
      "authorized": 776,
      "funded": 127
    },
    "payments": 2,
    "payments_amount": 7539800000,
    "trades": 14,
    "traded_amount": 18085347466,
    "price": [
      3.3750994,
      3.496796579408409,
      3.3569358825246436,
      3.3569358825246436
    ],
    "volume": 63172361453
  },
  {
    "ts": 1519689600,
    "supply": 504328689000,
    "trustlines": {
      "total": 780,
      "authorized": 780,
      "funded": 129
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 3541419891,
    "price": [
      3.3569358825246436,
      3.4368664510673033,
      3.3569358825246436,
      3.405478303450132
    ],
    "volume": 12087866330
  },
  {
    "ts": 1519776000,
    "supply": 504328689000,
    "trustlines": {
      "total": 783,
      "authorized": 783,
      "funded": 128
    },
    "payments": 2,
    "payments_amount": 987863120,
    "trades": 12,
    "traded_amount": 2058118056,
    "price": [
      3.405478303450132,
      3.5231418979343534,
      3.405478303450132,
      3.5231418970751256
    ],
    "volume": 7146158542
  },
  {
    "ts": 1519862400,
    "supply": 504328689000,
    "trustlines": {
      "total": 786,
      "authorized": 786,
      "funded": 127
    },
    "payments": 7,
    "payments_amount": 1005000000,
    "trades": 11,
    "traded_amount": 7417631200,
    "price": [
      3.5231418970751256,
      3.720238098342071,
      3.5231418970751256,
      3.607255
    ],
    "volume": 27506385035
  },
  {
    "ts": 1519948800,
    "supply": 504328689000,
    "trustlines": {
      "total": 791,
      "authorized": 791,
      "funded": 128
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1728379082,
    "price": [
      3.607255,
      3.772320590952396,
      3.607255,
      3.772320590952396
    ],
    "volume": 6520000000
  },
  {
    "ts": 1520035200,
    "supply": 504328689000,
    "trustlines": {
      "total": 799,
      "authorized": 799,
      "funded": 129
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 3,
    "traded_amount": 3319549776,
    "price": [
      3.772320590952396,
      3.865481252415926,
      3.772320590952396,
      3.8388046023889033
    ],
    "volume": 12761087288
  },
  {
    "ts": 1520121600,
    "supply": 504328689000,
    "trustlines": {
      "total": 801,
      "authorized": 801,
      "funded": 132
    },
    "payments": 2,
    "payments_amount": 336224473,
    "trades": 6,
    "traded_amount": 1946320193,
    "price": [
      3.8388046023889033,
      3.8388046023889033,
      3.3151316236176362,
      3.3151316236176362
    ],
    "volume": 6194109558
  },
  {
    "ts": 1520208000,
    "supply": 504328689000,
    "trustlines": {
      "total": 801,
      "authorized": 801,
      "funded": 133
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 20,
    "traded_amount": 36767843424,
    "price": [
      3.3151316236176362,
      3.31513181411004,
      3.1725888324873095,
      3.225806451612903
    ],
    "volume": 120066587507
  },
  {
    "ts": 1520294400,
    "supply": 504328689000,
    "trustlines": {
      "total": 808,
      "authorized": 808,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 21,
    "traded_amount": 19565331476,
    "price": [
      3.225806451612903,
      3.490000375053477,
      3.22372665,
      3.490000000087985
    ],
    "volume": 63615273093
  },
  {
    "ts": 1520380800,
    "supply": 504328689000,
    "trustlines": {
      "total": 811,
      "authorized": 811,
      "funded": 135
    },
    "payments": 5,
    "payments_amount": 3027974492,
    "trades": 15,
    "traded_amount": 12032907419,
    "price": [
      3.490000000087985,
      3.84615385,
      3.490000000087985,
      3.7220938790000804
    ],
    "volume": 44478802584
  },
  {
    "ts": 1520467200,
    "supply": 504328689000,
    "trustlines": {
      "total": 814,
      "authorized": 814,
      "funded": 134
    },
    "payments": 2,
    "payments_amount": 6165000000,
    "trades": 17,
    "traded_amount": 15800783555,
    "price": [
      3.7220938790000804,
      4.112605012334918,
      3.5028991,
      3.9298454111851773
    ],
    "volume": 61994571808
  },
  {
    "ts": 1520553600,
    "supply": 504328689000,
    "trustlines": {
      "total": 817,
      "authorized": 817,
      "funded": 133
    },
    "payments": 2,
    "payments_amount": 3015000000,
    "trades": 8,
    "traded_amount": 6135729868,
    "price": [
      3.9298454111851773,
      4.2845297,
      3.9298454111851773,
      4.138329109090909
    ],
    "volume": 25469981476
  },
  {
    "ts": 1520640000,
    "supply": 504328689000,
    "trustlines": {
      "total": 823,
      "authorized": 823,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 2401120027,
    "price": [
      4.138329109090909,
      4.191636,
      4.1383291,
      4.191636
    ],
    "volume": 9953068508
  },
  {
    "ts": 1520726400,
    "supply": 504328689000,
    "trustlines": {
      "total": 828,
      "authorized": 828,
      "funded": 131
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 373845702,
    "price": [
      4.191636,
      4.329004340307212,
      4.008694200027672,
      4.008694200027672
    ],
    "volume": 1536429483
  },
  {
    "ts": 1520812800,
    "supply": 504328689000,
    "trustlines": {
      "total": 830,
      "authorized": 830,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 79,
    "traded_amount": 36774340823,
    "price": [
      4.008694200027672,
      4.04858300400545,
      3.9215686274509802,
      4.0485829991802165
    ],
    "volume": 127421761713
  },
  {
    "ts": 1520899200,
    "supply": 504328689000,
    "trustlines": {
      "total": 833,
      "authorized": 833,
      "funded": 132
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 5720630511,
    "price": [
      4.0485829991802165,
      4.0485829991802165,
      3.8461538632051284,
      4.048582996435604
    ],
    "volume": 23154054916
  },
  {
    "ts": 1520985600,
    "supply": 504328689000,
    "trustlines": {
      "total": 836,
      "authorized": 836,
      "funded": 132
    },
    "payments": 3,
    "payments_amount": 410000000,
    "trades": 2,
    "traded_amount": 391000000,
    "price": [
      4.048582996435604,
      4.716205182051282,
      4.048582996435604,
      4.716205182051282
    ],
    "volume": 1843816380
  },
  {
    "ts": 1521072000,
    "supply": 504328689000,
    "trustlines": {
      "total": 836,
      "authorized": 836,
      "funded": 131
    },
    "payments": 3,
    "payments_amount": 222800000,
    "trades": 6,
    "traded_amount": 2950699949,
    "price": [
      4.716205182051282,
      5.503267859914359,
      4.716205182051282,
      5.376057940298508
    ],
    "volume": 15768584299
  },
  {
    "ts": 1521158400,
    "supply": 504328689000,
    "trustlines": {
      "total": 841,
      "authorized": 841,
      "funded": 133
    },
    "payments": 46,
    "payments_amount": 2699318477,
    "trades": 7,
    "traded_amount": 2913277773,
    "price": [
      5.376057940298508,
      5.376057940298508,
      5.096543049347517,
      5.258144610161798
    ],
    "volume": 14505896961
  },
  {
    "ts": 1521244800,
    "supply": 504328689000,
    "trustlines": {
      "total": 843,
      "authorized": 843,
      "funded": 134
    },
    "payments": 346,
    "payments_amount": 19770400000,
    "trades": 2,
    "traded_amount": 177480619,
    "price": [
      5.258144610161798,
      5.971243969909421,
      5.258144610161798,
      5.971243969909421
    ],
    "volume": 1050000000
  },
  {
    "ts": 1521331200,
    "supply": 504328689000,
    "trustlines": {
      "total": 844,
      "authorized": 844,
      "funded": 133
    },
    "payments": 268,
    "payments_amount": 15248200000,
    "trades": 25,
    "traded_amount": 21058118168,
    "price": [
      5.971243969909421,
      7.66238313907915,
      5.490592692620674,
      5.490592692620674
    ],
    "volume": 144463868291
  },
  {
    "ts": 1521417600,
    "supply": 504328689000,
    "trustlines": {
      "total": 846,
      "authorized": 846,
      "funded": 133
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 9,
    "traded_amount": 4794029814,
    "price": [
      5.490592692620674,
      5.4906976744186045,
      5.1816451618822725,
      5.28762987850599
    ],
    "volume": 25227357158
  },
  {
    "ts": 1521504000,
    "supply": 504328689000,
    "trustlines": {
      "total": 844,
      "authorized": 844,
      "funded": 134
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 28,
    "traded_amount": 21861541374,
    "price": [
      5.28762987850599,
      5.347832180394486,
      4.404384116404617,
      4.404384116404617
    ],
    "volume": 108113675193
  },
  {
    "ts": 1521590400,
    "supply": 504328689000,
    "trustlines": {
      "total": 848,
      "authorized": 848,
      "funded": 136
    },
    "payments": 2,
    "payments_amount": 127570970,
    "trades": 12,
    "traded_amount": 626391733,
    "price": [
      4.404384116404617,
      4.742837313731882,
      4.404384116404617,
      4.742837313731882
    ],
    "volume": 1530263228
  },
  {
    "ts": 1521676800,
    "supply": 504328689000,
    "trustlines": {
      "total": 851,
      "authorized": 851,
      "funded": 137
    },
    "payments": 1,
    "payments_amount": 69800000,
    "trades": 32,
    "traded_amount": 10330362210,
    "price": [
      4.742837313731882,
      5.2631578947368425,
      4.742837313731882,
      5.110629742822627
    ],
    "volume": 53356452761
  },
  {
    "ts": 1521763200,
    "supply": 504328689000,
    "trustlines": {
      "total": 852,
      "authorized": 852,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 87,
    "traded_amount": 11481062696,
    "price": [
      5.110629742822627,
      5.467323611156292,
      5.110629742822627,
      5.249703132615535
    ],
    "volume": 61142845580
  },
  {
    "ts": 1521849600,
    "supply": 504328689000,
    "trustlines": {
      "total": 854,
      "authorized": 854,
      "funded": 135
    },
    "payments": 3,
    "payments_amount": 523670536,
    "trades": 378,
    "traded_amount": 1816780395,
    "price": [
      5.249703132615535,
      5.250517616809921,
      5.064316823660488,
      5.176922432073923
    ],
    "volume": 9483434164
  },
  {
    "ts": 1521936000,
    "supply": 504328689000,
    "trustlines": {
      "total": 856,
      "authorized": 856,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 52244385,
    "price": [
      5.176922432073923,
      5.230476492571157,
      5.176922432073923,
      5.2273915316257185
    ],
    "volume": 273239102
  },
  {
    "ts": 1522022400,
    "supply": 504328689000,
    "trustlines": {
      "total": 857,
      "authorized": 857,
      "funded": 138
    },
    "payments": 1,
    "payments_amount": 10869559,
    "trades": 192,
    "traded_amount": 13052199796,
    "price": [
      5.2273915316257185,
      5.697288776955629,
      5.089869207115398,
      5.520003157441806
    ],
    "volume": 69809165938
  },
  {
    "ts": 1522108800,
    "supply": 504328689000,
    "trustlines": {
      "total": 856,
      "authorized": 856,
      "funded": 137
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 334,
    "traded_amount": 1390547320,
    "price": [
      5.520003157441806,
      5.918014143064409,
      5.491584144618505,
      5.652332107030726
    ],
    "volume": 7832855730
  },
  {
    "ts": 1522195200,
    "supply": 504328689000,
    "trustlines": {
      "total": 854,
      "authorized": 854,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 53,
    "traded_amount": 147503277,
    "price": [
      5.652332107030726,
      5.652332107030726,
      5.534648274232377,
      5.6154131071265265
    ],
    "volume": 827340276
  },
  {
    "ts": 1522281600,
    "supply": 504328689000,
    "trustlines": {
      "total": 856,
      "authorized": 856,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 499,
    "traded_amount": 1943084614,
    "price": [
      5.6154131071265265,
      6.406535520951818,
      5.6154131071265265,
      6.327660031206543
    ],
    "volume": 11991221107
  },
  {
    "ts": 1522368000,
    "supply": 504328689000,
    "trustlines": {
      "total": 859,
      "authorized": 859,
      "funded": 138
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 461,
    "traded_amount": 6168702157,
    "price": [
      6.327660031206543,
      6.885572758470803,
      6.271697153383843,
      6.550727468222961
    ],
    "volume": 40505539579
  },
  {
    "ts": 1522454400,
    "supply": 504328689000,
    "trustlines": {
      "total": 861,
      "authorized": 861,
      "funded": 138
    },
    "payments": 1,
    "payments_amount": 150000000,
    "trades": 282,
    "traded_amount": 31634783851,
    "price": [
      6.550727468222961,
      6.550727468222961,
      5.621148304145422,
      5.969184205920619
    ],
    "volume": 182913997512
  },
  {
    "ts": 1522540800,
    "supply": 504328689000,
    "trustlines": {
      "total": 862,
      "authorized": 862,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 179,
    "traded_amount": 21525603754,
    "price": [
      5.969184205920619,
      6.4485512974,
      5.794489163274497,
      5.944771295053573
    ],
    "volume": 136160526632
  },
  {
    "ts": 1522627200,
    "supply": 504328689000,
    "trustlines": {
      "total": 865,
      "authorized": 865,
      "funded": 136
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 590,
    "traded_amount": 53036043043,
    "price": [
      5.944771295053573,
      5.944771295053573,
      5.289672544080605,
      5.402735594405255
    ],
    "volume": 290963293468
  },
  {
    "ts": 1522713600,
    "supply": 504328689000,
    "trustlines": {
      "total": 868,
      "authorized": 868,
      "funded": 138
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 211,
    "traded_amount": 17880245197,
    "price": [
      5.402735594405255,
      5.410547588102736,
      5.20707767328457,
      5.285490419574019
    ],
    "volume": 93581594372
  },
  {
    "ts": 1522800000,
    "supply": 504328689000,
    "trustlines": {
      "total": 868,
      "authorized": 868,
      "funded": 138
    },
    "payments": 1,
    "payments_amount": 56645,
    "trades": 100,
    "traded_amount": 14110469373,
    "price": [
      5.285490419574019,
      6.035828800476203,
      5.218019285945232,
      6.035828800476203
    ],
    "volume": 79015605736
  },
  {
    "ts": 1522886400,
    "supply": 504328689000,
    "trustlines": {
      "total": 873,
      "authorized": 873,
      "funded": 138
    },
    "payments": 1,
    "payments_amount": 14162,
    "trades": 305,
    "traded_amount": 7606545500,
    "price": [
      6.035828800476203,
      6.330824047739749,
      5.916407138561212,
      6.29907637671685
    ],
    "volume": 47409654497
  },
  {
    "ts": 1522972800,
    "supply": 504328689000,
    "trustlines": {
      "total": 878,
      "authorized": 878,
      "funded": 140
    },
    "payments": 2,
    "payments_amount": 1005000000,
    "trades": 12,
    "traded_amount": 1454505534,
    "price": [
      6.29907637671685,
      6.449934208013595,
      6.186813280084496,
      6.3401363313175185
    ],
    "volume": 9084557150
  },
  {
    "ts": 1523059200,
    "supply": 504328689000,
    "trustlines": {
      "total": 877,
      "authorized": 877,
      "funded": 140
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 204,
    "traded_amount": 7434211769,
    "price": [
      6.3401363313175185,
      6.354,
      6.008140429333333,
      6.01240423733471
    ],
    "volume": 44834431399
  },
  {
    "ts": 1523145600,
    "supply": 504328689000,
    "trustlines": {
      "total": 882,
      "authorized": 882,
      "funded": 141
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 38,
    "traded_amount": 73788708,
    "price": [
      6.01240423733471,
      6.076375174847696,
      5.9703132145718625,
      5.9703132145718625
    ],
    "volume": 440922478
  },
  {
    "ts": 1523232000,
    "supply": 504328689000,
    "trustlines": {
      "total": 885,
      "authorized": 885,
      "funded": 143
    },
    "payments": 1,
    "payments_amount": 1620800000,
    "trades": 13,
    "traded_amount": 5054014007,
    "price": [
      5.9703132145718625,
      6.301601252632297,
      5.875766172733506,
      6.301601026740843
    ],
    "volume": 12152227932
  },
  {
    "ts": 1523318400,
    "supply": 504328689000,
    "trustlines": {
      "total": 887,
      "authorized": 887,
      "funded": 144
    },
    "payments": 1,
    "payments_amount": 30000000000,
    "trades": 5,
    "traded_amount": 636817369,
    "price": [
      6.301601026740843,
      6.301601026740843,
      6.21334128641018,
      6.21334128641018
    ],
    "volume": 3963832042
  },
  {
    "ts": 1523404800,
    "supply": 504328689000,
    "trustlines": {
      "total": 888,
      "authorized": 888,
      "funded": 146
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 5483712979,
    "price": [
      6.21334128641018,
      6.21334128641018,
      5.849999005500169,
      5.849999005500169
    ],
    "volume": 15260765345
  },
  {
    "ts": 1523491200,
    "supply": 504328689000,
    "trustlines": {
      "total": 890,
      "authorized": 890,
      "funded": 146
    },
    "payments": 1,
    "payments_amount": 156010,
    "trades": 12,
    "traded_amount": 9405256485,
    "price": [
      5.849999005500169,
      5.849999005500169,
      5.2631578947368425,
      5.2631578947368425
    ],
    "volume": 51499614989
  },
  {
    "ts": 1523577600,
    "supply": 504328689000,
    "trustlines": {
      "total": 890,
      "authorized": 890,
      "funded": 147
    },
    "payments": 2,
    "payments_amount": 200000000,
    "trades": 57,
    "traded_amount": 7643351793,
    "price": [
      5.2631578947368425,
      5.2631578947368425,
      4.603759942154736,
      4.943847776949409
    ],
    "volume": 37583823329
  },
  {
    "ts": 1523664000,
    "supply": 504328689000,
    "trustlines": {
      "total": 892,
      "authorized": 892,
      "funded": 147
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 392,
    "traded_amount": 2268794423,
    "price": [
      4.943847776949409,
      5.035218407509948,
      4.813074620946308,
      4.891734684677518
    ],
    "volume": 11122500834
  },
  {
    "ts": 1523750400,
    "supply": 504328689000,
    "trustlines": {
      "total": 895,
      "authorized": 895,
      "funded": 147
    },
    "payments": 3,
    "payments_amount": 4478740189,
    "trades": 326,
    "traded_amount": 9788623259,
    "price": [
      4.891734684677518,
      4.891734684677518,
      4.213659504288241,
      4.213659504288241
    ],
    "volume": 44240602050
  },
  {
    "ts": 1523836800,
    "supply": 504328689000,
    "trustlines": {
      "total": 899,
      "authorized": 899,
      "funded": 149
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 43,
    "traded_amount": 14678767484,
    "price": [
      4.213659504288241,
      4.51395109089982,
      4.127824415313427,
      4.398500585850299
    ],
    "volume": 31818609831
  },
  {
    "ts": 1523923200,
    "supply": 504328689000,
    "trustlines": {
      "total": 901,
      "authorized": 901,
      "funded": 151
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 85,
    "traded_amount": 24673515155,
    "price": [
      4.398500585850299,
      4.422709843132891,
      3.945259310933696,
      4.074997962501019
    ],
    "volume": 101521771268
  },
  {
    "ts": 1524009600,
    "supply": 504328689000,
    "trustlines": {
      "total": 902,
      "authorized": 902,
      "funded": 150
    },
    "payments": 5,
    "payments_amount": 7772012049,
    "trades": 26,
    "traded_amount": 94345739213,
    "price": [
      4.074997962501019,
      4.074997962501019,
      3.5197040300323104,
      3.5240924580449664
    ],
    "volume": 322482450703
  },
  {
    "ts": 1524096000,
    "supply": 504328689000,
    "trustlines": {
      "total": 905,
      "authorized": 905,
      "funded": 152
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 24,
    "traded_amount": 22720395537,
    "price": [
      3.5240924580449664,
      3.5240924580449664,
      3.3126005528530484,
      3.3805128981779373
    ],
    "volume": 76089916377
  },
  {
    "ts": 1524182400,
    "supply": 504328689000,
    "trustlines": {
      "total": 907,
      "authorized": 907,
      "funded": 152
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 8048400094,
    "price": [
      3.3805128981779373,
      3.3805128981779373,
      3.171871337479815,
      3.171871337479815
    ],
    "volume": 26085980439
  },
  {
    "ts": 1524268800,
    "supply": 504328689000,
    "trustlines": {
      "total": 911,
      "authorized": 911,
      "funded": 155
    },
    "payments": 1,
    "payments_amount": 5117634781,
    "trades": 20,
    "traded_amount": 106744784004,
    "price": [
      3.171871337479815,
      3.5130800753862688,
      3.171871337479815,
      3.359865730341064
    ],
    "volume": 347185749441
  },
  {
    "ts": 1524355200,
    "supply": 504328689000,
    "trustlines": {
      "total": 913,
      "authorized": 913,
      "funded": 153
    },
    "payments": 1,
    "payments_amount": 189300000,
    "trades": 7,
    "traded_amount": 32118015509,
    "price": [
      3.359865730341064,
      3.359865730341064,
      3.2394055804557453,
      3.2750194732306204
    ],
    "volume": 104536507349
  },
  {
    "ts": 1524441600,
    "supply": 504328689000,
    "trustlines": {
      "total": 918,
      "authorized": 918,
      "funded": 153
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 11,
    "traded_amount": 61000000000,
    "price": [
      3.2750194732306204,
      3.2967349597672175,
      3.2750194732306204,
      3.2786885255674054
    ],
    "volume": 200299161002
  },
  {
    "ts": 1524528000,
    "supply": 504328689000,
    "trustlines": {
      "total": 917,
      "authorized": 917,
      "funded": 153
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 43632152789,
    "price": [
      3.2786885255674054,
      3.2786885255674054,
      3.0303030303030303,
      3.0303030303030303
    ],
    "volume": 134717872342
  },
  {
    "ts": 1524614400,
    "supply": 504328689000,
    "trustlines": {
      "total": 933,
      "authorized": 933,
      "funded": 154
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 31,
    "traded_amount": 166035045313,
    "price": [
      3.0303030303030303,
      3.4804948000325253,
      3.0303030303030303,
      3.3952421102765142
    ],
    "volume": 561469465313
  },
  {
    "ts": 1524700800,
    "supply": 504328689000,
    "trustlines": {
      "total": 938,
      "authorized": 938,
      "funded": 153
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 19,
    "traded_amount": 72520727642,
    "price": [
      3.3952421102765142,
      3.4176157821612385,
      3.2051220913785956,
      3.2051220913785956
    ],
    "volume": 215967655757
  },
  {
    "ts": 1524787200,
    "supply": 504328689000,
    "trustlines": {
      "total": 941,
      "authorized": 941,
      "funded": 155
    },
    "payments": 1,
    "payments_amount": 300000000,
    "trades": 8,
    "traded_amount": 20988766597,
    "price": [
      3.2051220913785956,
      3.2051220913785956,
      2.857142856686741,
      3.05381188
    ],
    "volume": 44346558403
  },
  {
    "ts": 1524873600,
    "supply": 504328689000,
    "trustlines": {
      "total": 945,
      "authorized": 945,
      "funded": 156
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 4120286917,
    "price": [
      3.05381188,
      3.05381188,
      2.8101334948903296,
      2.8101334948903296
    ],
    "volume": 11760767460
  },
  {
    "ts": 1524960000,
    "supply": 504328689000,
    "trustlines": {
      "total": 953,
      "authorized": 953,
      "funded": 155
    },
    "payments": 2,
    "payments_amount": 3073000000,
    "trades": 18,
    "traded_amount": 52052014893,
    "price": [
      2.8101334948903296,
      3.0303030995634455,
      2.655453571237375,
      3.0303030995634455
    ],
    "volume": 142283233246
  },
  {
    "ts": 1525046400,
    "supply": 504328689000,
    "trustlines": {
      "total": 957,
      "authorized": 957,
      "funded": 156
    },
    "payments": 53,
    "payments_amount": 26500000000,
    "trades": 9,
    "traded_amount": 46523006351,
    "price": [
      3.0303030995634455,
      3.0303030995634455,
      2.7682848674636533,
      2.793296087703145
    ],
    "volume": 130183260634
  },
  {
    "ts": 1525132800,
    "supply": 504328689000,
    "trustlines": {
      "total": 961,
      "authorized": 961,
      "funded": 156
    },
    "payments": 7,
    "payments_amount": 43220000000,
    "trades": 31,
    "traded_amount": 24910012111,
    "price": [
      2.793296087703145,
      3.703703704349342,
      2.7682848,
      2.7782330341196335
    ],
    "volume": 71934467122
  },
  {
    "ts": 1525219200,
    "supply": 504328689000,
    "trustlines": {
      "total": 963,
      "authorized": 963,
      "funded": 158
    },
    "payments": 3,
    "payments_amount": 26422400000,
    "trades": 46,
    "traded_amount": 103807480891,
    "price": [
      2.7782330341196335,
      2.7782330341196335,
      2.698259326470588,
      2.7373033300163687
    ],
    "volume": 280430472025
  },
  {
    "ts": 1525305600,
    "supply": 504328689000,
    "trustlines": {
      "total": 967,
      "authorized": 967,
      "funded": 160
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 10446492198,
    "price": [
      2.7373033300163687,
      3.125,
      2.7373033300163687,
      3.125
    ],
    "volume": 29092253074
  },
  {
    "ts": 1525392000,
    "supply": 504328689000,
    "trustlines": {
      "total": 970,
      "authorized": 970,
      "funded": 161
    },
    "payments": 2,
    "payments_amount": 48210000000,
    "trades": 13,
    "traded_amount": 10600829269,
    "price": [
      3.125,
      3.125,
      2.725843787334967,
      2.7644124584064693
    ],
    "volume": 29166634864
  },
  {
    "ts": 1525478400,
    "supply": 504328689000,
    "trustlines": {
      "total": 970,
      "authorized": 970,
      "funded": 161
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 250994781,
    "price": [
      2.7644124584064693,
      2.774608316596455,
      2.753772768986742,
      2.753772768986742
    ],
    "volume": 356524307
  },
  {
    "ts": 1525564800,
    "supply": 504328689000,
    "trustlines": {
      "total": 975,
      "authorized": 975,
      "funded": 163
    },
    "payments": 1,
    "payments_amount": 250000000,
    "trades": 93,
    "traded_amount": 72820360485,
    "price": [
      2.753772768986742,
      2.930877,
      2.753772768986742,
      2.889978940724512
    ],
    "volume": 171330215127
  },
  {
    "ts": 1525651200,
    "supply": 504328689000,
    "trustlines": {
      "total": 977,
      "authorized": 977,
      "funded": 161
    },
    "payments": 1,
    "payments_amount": 500000000,
    "trades": 65,
    "traded_amount": 9314511995,
    "price": [
      2.889978940724512,
      3.068341633453147,
      2.889978940724512,
      2.9912237499564402
    ],
    "volume": 21815410015
  },
  {
    "ts": 1525737600,
    "supply": 504328689000,
    "trustlines": {
      "total": 980,
      "authorized": 980,
      "funded": 162
    },
    "payments": 2,
    "payments_amount": 569800000,
    "trades": 93,
    "traded_amount": 28498579523,
    "price": [
      2.9912237499564402,
      3.100626016424017,
      2.9165147136713143,
      3.064798551479714
    ],
    "volume": 51236650867
  },
  {
    "ts": 1525824000,
    "supply": 504328689000,
    "trustlines": {
      "total": 981,
      "authorized": 981,
      "funded": 164
    },
    "payments": 3,
    "payments_amount": 3550300000,
    "trades": 15,
    "traded_amount": 10185701944,
    "price": [
      3.064798551479714,
      3.268201292533184,
      3.0581039759803113,
      3.0933925845873507
    ],
    "volume": 31223996726
  },
  {
    "ts": 1525910400,
    "supply": 504328689000,
    "trustlines": {
      "total": 981,
      "authorized": 981,
      "funded": 163
    },
    "payments": 7,
    "payments_amount": 57320100000,
    "trades": 5,
    "traded_amount": 2487262840,
    "price": [
      3.0933925845873507,
      3.180152280207145,
      3.0933925845873507,
      3.1773232228795223
    ],
    "volume": 7853708084
  },
  {
    "ts": 1525996800,
    "supply": 504328689000,
    "trustlines": {
      "total": 982,
      "authorized": 982,
      "funded": 163
    },
    "payments": 6,
    "payments_amount": 31404599675,
    "trades": 428,
    "traded_amount": 147807752330,
    "price": [
      3.1773232228795223,
      3.9601021294074483,
      3.1773232228795223,
      3.846000006063333
    ],
    "volume": 549408138925
  },
  {
    "ts": 1526083200,
    "supply": 504328689000,
    "trustlines": {
      "total": 983,
      "authorized": 983,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 34,
    "traded_amount": 80563177342,
    "price": [
      3.846000006063333,
      3.8873628828966367,
      3.3010425352534836,
      3.3010425352534836
    ],
    "volume": 289201812020
  },
  {
    "ts": 1526169600,
    "supply": 504328689000,
    "trustlines": {
      "total": 987,
      "authorized": 987,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 23,
    "traded_amount": 38860920576,
    "price": [
      3.3010425352534836,
      3.304866646991696,
      3.158392499415205,
      3.158392499415205
    ],
    "volume": 124393521658
  },
  {
    "ts": 1526256000,
    "supply": 504328689000,
    "trustlines": {
      "total": 989,
      "authorized": 989,
      "funded": 165
    },
    "payments": 1,
    "payments_amount": 5020000000,
    "trades": 14,
    "traded_amount": 43036578944,
    "price": [
      3.158392499415205,
      3.402018962082936,
      3.155352519138791,
      3.198689844222844
    ],
    "volume": 141484574585
  },
  {
    "ts": 1526342400,
    "supply": 504328689000,
    "trustlines": {
      "total": 989,
      "authorized": 989,
      "funded": 164
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 13,
    "traded_amount": 59268549626,
    "price": [
      3.198689844222844,
      3.314821162270692,
      3.198689844222844,
      3.314821162270692
    ],
    "volume": 191706108998
  },
  {
    "ts": 1526428800,
    "supply": 504328689000,
    "trustlines": {
      "total": 991,
      "authorized": 991,
      "funded": 162
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 25,
    "traded_amount": 113446190903,
    "price": [
      3.314821162270692,
      3.6475435562385536,
      3.314821162270692,
      3.562670001658527
    ],
    "volume": 407223232012
  },
  {
    "ts": 1526515200,
    "supply": 504328689000,
    "trustlines": {
      "total": 996,
      "authorized": 996,
      "funded": 161
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 21,
    "traded_amount": 107378832353,
    "price": [
      3.562670001658527,
      3.755180693808616,
      3.4677165986522236,
      3.7505041614465613
    ],
    "volume": 392552551063
  },
  {
    "ts": 1526601600,
    "supply": 504328689000,
    "trustlines": {
      "total": 998,
      "authorized": 998,
      "funded": 162
    },
    "payments": 1,
    "payments_amount": 500000000,
    "trades": 13,
    "traded_amount": 69100573902,
    "price": [
      3.7505041614465613,
      3.8252207664474165,
      3.725530047540239,
      3.810965297939157
    ],
    "volume": 262709009015
  },
  {
    "ts": 1526688000,
    "supply": 504328689000,
    "trustlines": {
      "total": 1002,
      "authorized": 1002,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 3032738865,
    "price": [
      3.810965297939157,
      3.810965297939157,
      3.6321578616666668,
      3.6321578616666668
    ],
    "volume": 11015981620
  },
  {
    "ts": 1526774400,
    "supply": 504328689000,
    "trustlines": {
      "total": 1004,
      "authorized": 1004,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 365520893,
    "price": [
      3.6321578616666668,
      3.6543087413925197,
      3.516004,
      3.516004
    ],
    "volume": 1292297229
  },
  {
    "ts": 1526860800,
    "supply": 504328689000,
    "trustlines": {
      "total": 1009,
      "authorized": 1009,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 45977071070,
    "price": [
      3.516004,
      3.717613020703582,
      3.516004,
      3.68687992
    ],
    "volume": 167639631920
  },
  {
    "ts": 1526947200,
    "supply": 504328689000,
    "trustlines": {
      "total": 1017,
      "authorized": 1017,
      "funded": 163
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 18,
    "traded_amount": 94156853966,
    "price": [
      3.68687992,
      3.8461538461538463,
      3.68687992,
      3.8461538461538463
    ],
    "volume": 352943187544
  },
  {
    "ts": 1527033600,
    "supply": 504328689000,
    "trustlines": {
      "total": 1024,
      "authorized": 1024,
      "funded": 161
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 14,
    "traded_amount": 67026079012,
    "price": [
      3.8461538461538463,
      4.166666666556852,
      3.8461538461538463,
      4.063861590238985
    ],
    "volume": 273354617019
  },
  {
    "ts": 1527120000,
    "supply": 504328689000,
    "trustlines": {
      "total": 1025,
      "authorized": 1025,
      "funded": 164
    },
    "payments": 3,
    "payments_amount": 3759800000,
    "trades": 5,
    "traded_amount": 3694985874,
    "price": [
      4.063861590238985,
      4.09557065313729,
      3.966447770710189,
      3.966447770710189
    ],
    "volume": 14997126459
  },
  {
    "ts": 1527206400,
    "supply": 504328689000,
    "trustlines": {
      "total": 1028,
      "authorized": 1028,
      "funded": 165
    },
    "payments": 3,
    "payments_amount": 101700000,
    "trades": 8,
    "traded_amount": 492218806,
    "price": [
      3.966447770710189,
      4.049227264,
      3.9466457142857143,
      4.044677251256424
    ],
    "volume": 1978596373
  },
  {
    "ts": 1527292800,
    "supply": 504328689000,
    "trustlines": {
      "total": 1028,
      "authorized": 1028,
      "funded": 164
    },
    "payments": 1,
    "payments_amount": 252050,
    "trades": 16,
    "traded_amount": 39358718395,
    "price": [
      4.044677251256424,
      4.044677251256424,
      3.8461538462227938,
      4.005128827872837
    ],
    "volume": 154984771167
  },
  {
    "ts": 1527379200,
    "supply": 504328689000,
    "trustlines": {
      "total": 1026,
      "authorized": 1026,
      "funded": 164
    },
    "payments": 1,
    "payments_amount": 10105600000,
    "trades": 8,
    "traded_amount": 463430240,
    "price": [
      4.005128827872837,
      4.115653255601102,
      4.005128827872837,
      4.115653255601102
    ],
    "volume": 953659088
  },
  {
    "ts": 1527465600,
    "supply": 504328689000,
    "trustlines": {
      "total": 1028,
      "authorized": 1028,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 26,
    "traded_amount": 78776642231,
    "price": [
      4.115653255601102,
      4.582289907735593,
      4.115653255601102,
      4.582289907735593
    ],
    "volume": 228510808629
  },
  {
    "ts": 1527552000,
    "supply": 504328689000,
    "trustlines": {
      "total": 1035,
      "authorized": 1035,
      "funded": 164
    },
    "payments": 2,
    "payments_amount": 540100000,
    "trades": 4,
    "traded_amount": 4595940210,
    "price": [
      4.582289907735593,
      4.608294930875576,
      4.173754543588847,
      4.184226978847508
    ],
    "volume": 20413251204
  },
  {
    "ts": 1527638400,
    "supply": 504328689000,
    "trustlines": {
      "total": 1036,
      "authorized": 1036,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 12049755,
    "price": [
      4.184226978847508,
      4.184226978847508,
      4.149461960014954,
      4.149461960014954
    ],
    "volume": 50000000
  },
  {
    "ts": 1527724800,
    "supply": 504328689000,
    "trustlines": {
      "total": 1032,
      "authorized": 1032,
      "funded": 164
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.149461960014954,
      4.149461960014954,
      4.149461960014954,
      4.149461960014954
    ],
    "volume": 0
  },
  {
    "ts": 1527811200,
    "supply": 504328689000,
    "trustlines": {
      "total": 1036,
      "authorized": 1036,
      "funded": 168
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 9,
    "traded_amount": 17435822082,
    "price": [
      4.149461960014954,
      4.149461960014954,
      3.9583278101138317,
      4.0863105
    ],
    "volume": 69095191686
  },
  {
    "ts": 1527897600,
    "supply": 504328689000,
    "trustlines": {
      "total": 1038,
      "authorized": 1038,
      "funded": 169
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 20,
    "traded_amount": 9505279435,
    "price": [
      4.0863105,
      4.0863105,
      3.900967693006993,
      3.900967693006993
    ],
    "volume": 21383768769
  },
  {
    "ts": 1527984000,
    "supply": 504328689000,
    "trustlines": {
      "total": 1040,
      "authorized": 1040,
      "funded": 169
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 19,
    "traded_amount": 3238335913,
    "price": [
      3.900967693006993,
      3.900967693006993,
      3.784313725490196,
      3.817827787519473
    ],
    "volume": 12353494123
  },
  {
    "ts": 1528070400,
    "supply": 504328689000,
    "trustlines": {
      "total": 1035,
      "authorized": 1035,
      "funded": 169
    },
    "payments": 4,
    "payments_amount": 9739799932,
    "trades": 68,
    "traded_amount": 58192301686,
    "price": [
      3.817827787519473,
      4.02613783933621,
      3.817827787519473,
      4.02613783933621
    ],
    "volume": 128673481915
  },
  {
    "ts": 1528156800,
    "supply": 534328689000,
    "trustlines": {
      "total": 1038,
      "authorized": 1038,
      "funded": 170
    },
    "payments": 8,
    "payments_amount": 375175100000,
    "trades": 55,
    "traded_amount": 76142099042,
    "price": [
      4.02613783933621,
      4.072743880635966,
      3.9641266600173455,
      3.9821085386522808
    ],
    "volume": 154303417434
  },
  {
    "ts": 1528243200,
    "supply": 534328689000,
    "trustlines": {
      "total": 1038,
      "authorized": 1038,
      "funded": 169
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 33,
    "traded_amount": 52283322139,
    "price": [
      3.9821085386522808,
      4.029456128479714,
      3.9474771300334233,
      3.969060670303617
    ],
    "volume": 103996191342
  },
  {
    "ts": 1528329600,
    "supply": 534328689000,
    "trustlines": {
      "total": 1038,
      "authorized": 1038,
      "funded": 172
    },
    "payments": 1,
    "payments_amount": 250000000,
    "trades": 8,
    "traded_amount": 25377204066,
    "price": [
      3.969060670303617,
      4.020384411658477,
      3.9323633503735747,
      3.948326042629049
    ],
    "volume": 101442121541
  },
  {
    "ts": 1528416000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1043,
      "authorized": 1043,
      "funded": 172
    },
    "payments": 3,
    "payments_amount": 402771200000,
    "trades": 2,
    "traded_amount": 3778600136,
    "price": [
      3.948326042629049,
      4.083677559200033,
      3.948326042629049,
      4.054982644869266
    ],
    "volume": 15322158675
  },
  {
    "ts": 1528502400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1049,
      "authorized": 1049,
      "funded": 172
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.054982644869266,
      4.054982644869266,
      4.054982644869266,
      4.054982644869266
    ],
    "volume": 0
  },
  {
    "ts": 1528588800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1053,
      "authorized": 1053,
      "funded": 172
    },
    "payments": 3,
    "payments_amount": 40100000,
    "trades": 88,
    "traded_amount": 188968464178,
    "price": [
      4.054982644869266,
      4.812363307130729,
      4.054982644869266,
      4.800392249035719
    ],
    "volume": 572335009831
  },
  {
    "ts": 1528675200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1060,
      "authorized": 1060,
      "funded": 173
    },
    "payments": 9,
    "payments_amount": 178864800000,
    "trades": 31,
    "traded_amount": 108610960552,
    "price": [
      4.800392249035719,
      4.8565057508831995,
      4.698092803896716,
      4.719057961658091
    ],
    "volume": 308516106013
  },
  {
    "ts": 1528761600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1065,
      "authorized": 1065,
      "funded": 171
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 43,
    "traded_amount": 156790861369,
    "price": [
      4.719057961658091,
      5.097404778031201,
      4.623176424043821,
      5.085319827343123
    ],
    "volume": 553201047924
  },
  {
    "ts": 1528848000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1067,
      "authorized": 1067,
      "funded": 173
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 43,
    "traded_amount": 158041334796,
    "price": [
      5.085319827343123,
      5.320677215301519,
      3.8461531224322965,
      3.8461531224322965
    ],
    "volume": 721056142764
  },
  {
    "ts": 1528934400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1066,
      "authorized": 1066,
      "funded": 171
    },
    "payments": 2,
    "payments_amount": 2500100000,
    "trades": 95,
    "traded_amount": 2508609897,
    "price": [
      3.8461531224322965,
      4.545454545454546,
      3.830188679245283,
      4.165454545454545
    ],
    "volume": 11074781788
  },
  {
    "ts": 1529020800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1067,
      "authorized": 1067,
      "funded": 172
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 2219999451,
    "price": [
      4.165454545454545,
      5.405405405405405,
      4,
      4
    ],
    "volume": 10598331043
  },
  {
    "ts": 1529107200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1068,
      "authorized": 1068,
      "funded": 171
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 236469850,
    "price": [
      4,
      4.608294930875576,
      4,
      4.608294930875576
    ],
    "volume": 1079564566
  },
  {
    "ts": 1529193600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1072,
      "authorized": 1072,
      "funded": 171
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.608294930875576,
      4.608294930875576,
      4.608294930875576,
      4.608294930875576
    ],
    "volume": 0
  },
  {
    "ts": 1529280000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1073,
      "authorized": 1073,
      "funded": 171
    },
    "payments": 3,
    "payments_amount": 6539700000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      4.608294930875576,
      4.608294930875576,
      4.608294930875576,
      4.608294930875576
    ],
    "volume": 0
  },
  {
    "ts": 1529366400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1073,
      "authorized": 1073,
      "funded": 171
    },
    "payments": 3,
    "payments_amount": 70300000,
    "trades": 15,
    "traded_amount": 71204771802,
    "price": [
      4.608294930875576,
      4.962103669301269,
      4.608294930875576,
      4.90898738
    ],
    "volume": 293791565650
  },
  {
    "ts": 1529452800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1073,
      "authorized": 1073,
      "funded": 171
    },
    "payments": 3,
    "payments_amount": 304932600000,
    "trades": 14,
    "traded_amount": 70906120383,
    "price": [
      4.90898738,
      5.053872767440998,
      4.90898738,
      4.9604143910149086
    ],
    "volume": 202505771752
  },
  {
    "ts": 1529539200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1073,
      "authorized": 1073,
      "funded": 171
    },
    "payments": 4,
    "payments_amount": 25190000000,
    "trades": 32,
    "traded_amount": 112632038073,
    "price": [
      4.9604143910149086,
      5.092196768366666,
      4.915932020106098,
      5.092196768366666
    ],
    "volume": 450962660313
  },
  {
    "ts": 1529625600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1075,
      "authorized": 1075,
      "funded": 167
    },
    "payments": 2,
    "payments_amount": 21780000000,
    "trades": 67,
    "traded_amount": 257807323480,
    "price": [
      5.092196768366666,
      5.82589755017433,
      5.092196768366666,
      5.82589755017433
    ],
    "volume": 1097121600533
  },
  {
    "ts": 1529712000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1075,
      "authorized": 1075,
      "funded": 165
    },
    "payments": 1,
    "payments_amount": 529900000,
    "trades": 66,
    "traded_amount": 255899495715,
    "price": [
      5.82589755017433,
      5.882352942086654,
      5.67258958133938,
      5.801315725603539
    ],
    "volume": 944248509433
  },
  {
    "ts": 1529798400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1078,
      "authorized": 1078,
      "funded": 165
    },
    "payments": 1,
    "payments_amount": 2905100984,
    "trades": 244,
    "traded_amount": 643022854939,
    "price": [
      5.801315725603539,
      6.51010811240593,
      5.736508630078,
      5.857240090124074
    ],
    "volume": 2580375631563
  },
  {
    "ts": 1529884800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1077,
      "authorized": 1077,
      "funded": 169
    },
    "payments": 18,
    "payments_amount": 58882400000,
    "trades": 109,
    "traded_amount": 347875011739,
    "price": [
      5.857240090124074,
      6.156661926691831,
      5.854593190137871,
      5.936036130122744
    ],
    "volume": 1211998010117
  },
  {
    "ts": 1529971200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1082,
      "authorized": 1082,
      "funded": 171
    },
    "payments": 1,
    "payments_amount": 4000400000,
    "trades": 157,
    "traded_amount": 520733218947,
    "price": [
      5.936036130122744,
      6.287750869474167,
      5.936036130122744,
      6.287743753760103
    ],
    "volume": 1768278697734
  },
  {
    "ts": 1530057600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1086,
      "authorized": 1086,
      "funded": 168
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 98,
    "traded_amount": 350628018906,
    "price": [
      6.287743753760103,
      6.35441131737769,
      5.99286710019486,
      6.075982346124277
    ],
    "volume": 1225815385610
  },
  {
    "ts": 1530144000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1090,
      "authorized": 1090,
      "funded": 171
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 281,
    "traded_amount": 836937335665,
    "price": [
      6.075982346124277,
      6.5130770210990425,
      6.052918990029359,
      6.4589694606531
    ],
    "volume": 2828808828404
  },
  {
    "ts": 1530230400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1093,
      "authorized": 1093,
      "funded": 169
    },
    "payments": 1,
    "payments_amount": 2876649,
    "trades": 453,
    "traded_amount": 1236010427690,
    "price": [
      6.4589694606531,
      6.666666666666667,
      6.22396866009384,
      6.237857830227024
    ],
    "volume": 4604408212433
  },
  {
    "ts": 1530316800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1094,
      "authorized": 1094,
      "funded": 171
    },
    "payments": 1,
    "payments_amount": 30000000,
    "trades": 38,
    "traded_amount": 118415446031,
    "price": [
      6.237857830227024,
      6.237857830227024,
      5.892507670176729,
      6.054849395657693
    ],
    "volume": 439835329720
  },
  {
    "ts": 1530403200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1098,
      "authorized": 1098,
      "funded": 172
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 562,
    "traded_amount": 1632144845735,
    "price": [
      6.054849395657693,
      6.12414499275654,
      5.813377220334336,
      5.90584500151679
    ],
    "volume": 6235523990357
  },
  {
    "ts": 1530489600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1100,
      "authorized": 1100,
      "funded": 174
    },
    "payments": 1,
    "payments_amount": 50000000,
    "trades": 155,
    "traded_amount": 403827473500,
    "price": [
      5.90584500151679,
      6.024336703920978,
      5.4712007600682435,
      5.478707217850213
    ],
    "volume": 1199370252139
  },
  {
    "ts": 1530576000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1100,
      "authorized": 1100,
      "funded": 176
    },
    "payments": 2,
    "payments_amount": 50816712,
    "trades": 238,
    "traded_amount": 682182731949,
    "price": [
      5.478707217850213,
      5.675927020366183,
      5.3384973001650655,
      5.666414227912814
    ],
    "volume": 2148283963767
  },
  {
    "ts": 1530662400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1103,
      "authorized": 1103,
      "funded": 175
    },
    "payments": 3,
    "payments_amount": 21000000,
    "trades": 84,
    "traded_amount": 341892288334,
    "price": [
      5.666414227912814,
      5.704114787685635,
      5.423198760793561,
      5.518230246126468
    ],
    "volume": 1298920174930
  },
  {
    "ts": 1530748800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1107,
      "authorized": 1107,
      "funded": 176
    },
    "payments": 1,
    "payments_amount": 12000000000,
    "trades": 80,
    "traded_amount": 401802315948,
    "price": [
      5.518230246126468,
      5.845111329973794,
      5.4915394596022695,
      5.7859086508365625
    ],
    "volume": 1900157043609
  },
  {
    "ts": 1530835200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1114,
      "authorized": 1114,
      "funded": 176
    },
    "payments": 4,
    "payments_amount": 47376583,
    "trades": 70,
    "traded_amount": 317579649447,
    "price": [
      5.7859086508365625,
      5.862698072535639,
      5.686350280670763,
      5.711770902385806
    ],
    "volume": 1229222343702
  },
  {
    "ts": 1530921600,
    "supply": 734328689000,
    "trustlines": {
      "total": 1115,
      "authorized": 1115,
      "funded": 176
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 52,
    "traded_amount": 225133084436,
    "price": [
      5.711770902385806,
      5.815658690141105,
      5.711770902385806,
      5.775150186065341
    ],
    "volume": 1139783183756
  },
  {
    "ts": 1531008000,
    "supply": 734328689000,
    "trustlines": {
      "total": 1116,
      "authorized": 1116,
      "funded": 177
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 144,
    "traded_amount": 498613037862,
    "price": [
      5.775150186065341,
      5.775150186065341,
      5.411542290166861,
      5.569361692933821
    ],
    "volume": 1671381768494
  },
  {
    "ts": 1531094400,
    "supply": 734328689000,
    "trustlines": {
      "total": 1119,
      "authorized": 1119,
      "funded": 179
    },
    "payments": 1,
    "payments_amount": 5000000000,
    "trades": 28,
    "traded_amount": 90120983009,
    "price": [
      5.569361692933821,
      5.672146205601318,
      5.5156543923017125,
      5.672146205601318
    ],
    "volume": 437653442281
  },
  {
    "ts": 1531180800,
    "supply": 734328689000,
    "trustlines": {
      "total": 1121,
      "authorized": 1121,
      "funded": 179
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 64,
    "traded_amount": 258413298427,
    "price": [
      5.672146205601318,
      6.075012590085808,
      5.672146205601318,
      6.075012590085808
    ],
    "volume": 1228402099526
  },
  {
    "ts": 1531267200,
    "supply": 734328689000,
    "trustlines": {
      "total": 1120,
      "authorized": 1120,
      "funded": 178
    },
    "payments": 2,
    "payments_amount": 4332800000,
    "trades": 126,
    "traded_amount": 388717770505,
    "price": [
      6.075012590085808,
      6.3084772090798555,
      6.072323805821032,
      6.125672650020373
    ],
    "volume": 1452559231633
  },
  {
    "ts": 1531353600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1120,
      "authorized": 1120,
      "funded": 179
    },
    "payments": 2,
    "payments_amount": 18467625,
    "trades": 241,
    "traded_amount": 663222377340,
    "price": [
      6.125672650020373,
      6.471946505705409,
      6.125672650020373,
      6.378829633500432
    ],
    "volume": 2248186844347
  },
  {
    "ts": 1531440000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1127,
      "authorized": 1127,
      "funded": 182
    },
    "payments": 5,
    "payments_amount": 698534755,
    "trades": 233,
    "traded_amount": 598867072773,
    "price": [
      6.378829633500432,
      6.378829633500432,
      5.515580736543909,
      5.817335660267598
    ],
    "volume": 1976699593319
  },
  {
    "ts": 1531526400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1129,
      "authorized": 1129,
      "funded": 183
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 187,
    "traded_amount": 157109336061,
    "price": [
      5.817335660267598,
      5.893164093395137,
      5.563951670161533,
      5.617752560062707
    ],
    "volume": 670396709874
  },
  {
    "ts": 1531612800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1132,
      "authorized": 1132,
      "funded": 184
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 81,
    "traded_amount": 279496918699,
    "price": [
      5.617752560062707,
      5.6353204500427845,
      5.268414170068288,
      5.304526380056918
    ],
    "volume": 762646770900
  },
  {
    "ts": 1531699200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1138,
      "authorized": 1138,
      "funded": 185
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 120,
    "traded_amount": 464482652106,
    "price": [
      5.304526380056918,
      5.363726927321799,
      5.056655180087616,
      5.057980897491143
    ],
    "volume": 1299945098507
  },
  {
    "ts": 1531785600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1141,
      "authorized": 1141,
      "funded": 190
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 149,
    "traded_amount": 574133587969,
    "price": [
      5.057980897491143,
      5.094006061251579,
      4.612448760143362,
      4.674259973794914
    ],
    "volume": 1567776442911
  },
  {
    "ts": 1531872000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1143,
      "authorized": 1143,
      "funded": 192
    },
    "payments": 2,
    "payments_amount": 17786851,
    "trades": 426,
    "traded_amount": 1619572180285,
    "price": [
      4.674259973794914,
      4.972658317163521,
      3.635125510074745,
      3.9085870597318513
    ],
    "volume": 3915897968418
  },
  {
    "ts": 1531958400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1146,
      "authorized": 1146,
      "funded": 190
    },
    "payments": 3,
    "payments_amount": 12405100000,
    "trades": 377,
    "traded_amount": 1289347566763,
    "price": [
      3.9085870597318513,
      4.135951026330866,
      3.658073815267143,
      3.8241539000264853
    ],
    "volume": 2851672080158
  },
  {
    "ts": 1532044800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1151,
      "authorized": 1151,
      "funded": 189
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 199,
    "traded_amount": 819003565433,
    "price": [
      3.8241539000264853,
      4.381614814283046,
      3.8241539000264853,
      4.381614814283046
    ],
    "volume": 2671406111812
  },
  {
    "ts": 1532131200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1158,
      "authorized": 1158,
      "funded": 191
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 151,
    "traded_amount": 523851912154,
    "price": [
      4.381614814283046,
      4.761905095744129,
      4.0217444300749685,
      4.025570890532491
    ],
    "volume": 1390108236029
  },
  {
    "ts": 1532217600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1159,
      "authorized": 1159,
      "funded": 191
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 145,
    "traded_amount": 666994402417,
    "price": [
      4.025570890532491,
      4.165117340045145,
      3.877945430094894,
      4.119968500142508
    ],
    "volume": 2233969137124
  },
  {
    "ts": 1532304000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1161,
      "authorized": 1161,
      "funded": 192
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 62,
    "traded_amount": 277144190787,
    "price": [
      4.119968500142508,
      4.157048468926514,
      3.932039870066441,
      4.111111111111111
    ],
    "volume": 923431693314
  },
  {
    "ts": 1532390400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1169,
      "authorized": 1169,
      "funded": 194
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 89,
    "traded_amount": 348895464113,
    "price": [
      4.111111111111111,
      4.761902616754454,
      3.867858640095737,
      3.886777440621385
    ],
    "volume": 1124515741450
  },
  {
    "ts": 1532476800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1181,
      "authorized": 1181,
      "funded": 198
    },
    "payments": 1,
    "payments_amount": 3519800000,
    "trades": 153,
    "traded_amount": 702843528821,
    "price": [
      3.886777440621385,
      3.986785450103662,
      3.3698274412795923,
      3.534025080889621
    ],
    "volume": 2387896958677
  },
  {
    "ts": 1532563200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1187,
      "authorized": 1187,
      "funded": 200
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 69,
    "traded_amount": 276095849973,
    "price": [
      3.534025080889621,
      3.8873608704900073,
      3.38330931006565,
      3.8839140050026395
    ],
    "volume": 958857855136
  },
  {
    "ts": 1532649600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1192,
      "authorized": 1192,
      "funded": 200
    },
    "payments": 1,
    "payments_amount": 104000000,
    "trades": 86,
    "traded_amount": 332295732531,
    "price": [
      3.8839140050026395,
      3.9011229182927982,
      3.6228563000397807,
      3.6715404652932606
    ],
    "volume": 1227631707665
  },
  {
    "ts": 1532736000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1193,
      "authorized": 1193,
      "funded": 200
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 27,
    "traded_amount": 124758072451,
    "price": [
      3.6715404652932606,
      3.7418601445344457,
      3.6448547222030774,
      3.7095602745870613
    ],
    "volume": 460823357133
  },
  {
    "ts": 1532822400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1196,
      "authorized": 1196,
      "funded": 199
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 36427828373,
    "price": [
      3.7095602745870613,
      3.7842082490939046,
      3.6923076923076925,
      3.7755102040816326
    ],
    "volume": 135806467715
  },
  {
    "ts": 1532908800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1199,
      "authorized": 1199,
      "funded": 202
    },
    "payments": 176,
    "payments_amount": 1754128200,
    "trades": 205,
    "traded_amount": 145704264910,
    "price": [
      3.7755102040816326,
      4.162164071934726,
      3.7755102040816326,
      3.9659942500117364
    ],
    "volume": 574841655476
  },
  {
    "ts": 1532995200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1200,
      "authorized": 1200,
      "funded": 202
    },
    "payments": 211,
    "payments_amount": 17151499441,
    "trades": 271,
    "traded_amount": 309032056264,
    "price": [
      3.9659942500117364,
      4.357937828538916,
      3.9618231951519483,
      4.241187871899162
    ],
    "volume": 1317396383319
  },
  {
    "ts": 1533081600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1204,
      "authorized": 1204,
      "funded": 201
    },
    "payments": 15,
    "payments_amount": 49308720000,
    "trades": 57,
    "traded_amount": 130913706457,
    "price": [
      4.241187871899162,
      5.139538469445444,
      4.178527020608235,
      5.1020408163265305
    ],
    "volume": 574021039942
  },
  {
    "ts": 1533168000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1210,
      "authorized": 1210,
      "funded": 205
    },
    "payments": 7,
    "payments_amount": 65800100000,
    "trades": 9,
    "traded_amount": 1080322668,
    "price": [
      5.1020408163265305,
      5.1020408163265305,
      4.341376,
      4.341376
    ],
    "volume": 5236471427
  },
  {
    "ts": 1533254400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1209,
      "authorized": 1209,
      "funded": 204
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 122,
    "traded_amount": 313668505873,
    "price": [
      4.341376,
      4.970574200854067,
      4.023453883994045,
      4.970445729950291
    ],
    "volume": 1274786854194
  },
  {
    "ts": 1533340800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1208,
      "authorized": 1208,
      "funded": 206
    },
    "payments": 1,
    "payments_amount": 200000000,
    "trades": 7,
    "traded_amount": 435860748,
    "price": [
      4.970445729950291,
      4.970450670762318,
      4.4594925,
      4.79
    ],
    "volume": 2158155421
  },
  {
    "ts": 1533427200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1212,
      "authorized": 1212,
      "funded": 206
    },
    "payments": 1,
    "payments_amount": 20000000,
    "trades": 26,
    "traded_amount": 2264640820,
    "price": [
      4.79,
      4.892,
      4.699807373708378,
      4.717387700754774
    ],
    "volume": 5867687798
  },
  {
    "ts": 1533513600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1215,
      "authorized": 1215,
      "funded": 207
    },
    "payments": 264,
    "payments_amount": 2657428372,
    "trades": 377,
    "traded_amount": 72590371012,
    "price": [
      4.717387700754774,
      4.9704458,
      4.67423,
      4.9364767700111765
    ],
    "volume": 284478527459
  },
  {
    "ts": 1533600000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1221,
      "authorized": 1221,
      "funded": 210
    },
    "payments": 483,
    "payments_amount": 5979838332,
    "trades": 555,
    "traded_amount": 186941906949,
    "price": [
      4.9364767700111765,
      5.0478881,
      4.734909700353088,
      5.022742743005691
    ],
    "volume": 917132153331
  },
  {
    "ts": 1533686400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1225,
      "authorized": 1225,
      "funded": 207
    },
    "payments": 491,
    "payments_amount": 7731632048,
    "trades": 701,
    "traded_amount": 663345900018,
    "price": [
      5.022742743005691,
      5.91135229681742,
      5.022742743005691,
      5.786693750062402
    ],
    "volume": 2943494737005
  },
  {
    "ts": 1533772800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1242,
      "authorized": 1242,
      "funded": 206
    },
    "payments": 481,
    "payments_amount": 4816035028,
    "trades": 569,
    "traded_amount": 246682926201,
    "price": [
      5.786693750062402,
      5.831269306576836,
      5.116168824016327,
      5.195233929798487
    ],
    "volume": 1382397511070
  },
  {
    "ts": 1533859200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1263,
      "authorized": 1263,
      "funded": 208
    },
    "payments": 480,
    "payments_amount": 9134013121,
    "trades": 588,
    "traded_amount": 435770359200,
    "price": [
      5.195233929798487,
      5.3132215,
      4.83761405276763,
      5.081180840128364
    ],
    "volume": 2234824778124
  },
  {
    "ts": 1533945600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1265,
      "authorized": 1265,
      "funded": 214
    },
    "payments": 13,
    "payments_amount": 3070000000,
    "trades": 80,
    "traded_amount": 301824136650,
    "price": [
      5.081180840128364,
      6,
      5.0558698899816275,
      5.21642624176196
    ],
    "volume": 1608987361513
  },
  {
    "ts": 1534032000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1269,
      "authorized": 1269,
      "funded": 216
    },
    "payments": 5,
    "payments_amount": 12001000000,
    "trades": 104,
    "traded_amount": 331738752598,
    "price": [
      5.21642624176196,
      6,
      5,
      5.076519134314369
    ],
    "volume": 1687064709805
  },
  {
    "ts": 1534118400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1273,
      "authorized": 1273,
      "funded": 222
    },
    "payments": 14,
    "payments_amount": 42761024637,
    "trades": 176,
    "traded_amount": 582689197799,
    "price": [
      5.076519134314369,
      5.177932933824977,
      4.6858957401565435,
      5.1732693432743755
    ],
    "volume": 2787102120786
  },
  {
    "ts": 1534204800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1287,
      "authorized": 1287,
      "funded": 222
    },
    "payments": 28,
    "payments_amount": 72700319310,
    "trades": 205,
    "traded_amount": 509599703208,
    "price": [
      5.1732693432743755,
      5.7020029476863785,
      5.112576340001598,
      5.30813893994696
    ],
    "volume": 2630358996641
  },
  {
    "ts": 1534291200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1299,
      "authorized": 1299,
      "funded": 225
    },
    "payments": 80,
    "payments_amount": 802377287,
    "trades": 201,
    "traded_amount": 402750441566,
    "price": [
      5.30813893994696,
      5.30813893994696,
      4.921024841853108,
      5.208458933046187
    ],
    "volume": 1988368301263
  },
  {
    "ts": 1534377600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1309,
      "authorized": 1309,
      "funded": 225
    },
    "payments": 150,
    "payments_amount": 5985059473,
    "trades": 244,
    "traded_amount": 430163448750,
    "price": [
      5.208458933046187,
      5.422156057019007,
      5.110561506849315,
      5.303048530304853
    ],
    "volume": 2273129643949
  },
  {
    "ts": 1534464000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1322,
      "authorized": 1322,
      "funded": 231
    },
    "payments": 157,
    "payments_amount": 4127811174,
    "trades": 238,
    "traded_amount": 311289413214,
    "price": [
      5.303048530304853,
      5.303048530304853,
      4.785804130101576,
      4.811764705882353
    ],
    "volume": 1498690741357
  },
  {
    "ts": 1534550400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1325,
      "authorized": 1325,
      "funded": 233
    },
    "payments": 95,
    "payments_amount": 1578410557,
    "trades": 246,
    "traded_amount": 590449063479,
    "price": [
      4.811764705882353,
      5.1978495,
      4.671525410209952,
      5.044224070158198
    ],
    "volume": 2939570407819
  },
  {
    "ts": 1534636800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1334,
      "authorized": 1334,
      "funded": 230
    },
    "payments": 106,
    "payments_amount": 1062814420,
    "trades": 452,
    "traded_amount": 1453881761255,
    "price": [
      5.044224070158198,
      5.2273259507954615,
      4.953873440518104,
      5.008100070289026
    ],
    "volume": 7283882258263
  },
  {
    "ts": 1534723200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1348,
      "authorized": 1348,
      "funded": 229
    },
    "payments": 3,
    "payments_amount": 46140000000,
    "trades": 355,
    "traded_amount": 1376366215324,
    "price": [
      5.008100070289026,
      5.382146676033101,
      4.862885900116871,
      5.282612000075773
    ],
    "volume": 6801343349824
  },
  {
    "ts": 1534809600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1372,
      "authorized": 1372,
      "funded": 231
    },
    "payments": 4,
    "payments_amount": 40130000000,
    "trades": 734,
    "traded_amount": 3745007026236,
    "price": [
      5.282612000075773,
      5.5008871276633196,
      5.092295780041988,
      5.422810280747419
    ],
    "volume": 19501708807540
  },
  {
    "ts": 1534896000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1394,
      "authorized": 1394,
      "funded": 234
    },
    "payments": 2,
    "payments_amount": 26070816025,
    "trades": 446,
    "traded_amount": 2189274560857,
    "price": [
      5.422810280747419,
      5.5778645344794775,
      4.871087840060486,
      5.479839810107243
    ],
    "volume": 11212311145623
  },
  {
    "ts": 1534982400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1412,
      "authorized": 1412,
      "funded": 237
    },
    "payments": 5,
    "payments_amount": 50032983144,
    "trades": 102,
    "traded_amount": 462857736143,
    "price": [
      5.479839810107243,
      5.585930916256759,
      5.207232390156479,
      5.254946240154708
    ],
    "volume": 2457832727576
  },
  {
    "ts": 1535068800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1445,
      "authorized": 1445,
      "funded": 239
    },
    "payments": 2,
    "payments_amount": 131727786198,
    "trades": 22,
    "traded_amount": 258997182524,
    "price": [
      5.254946240154708,
      5.4212295573398865,
      5.217561170019527,
      5.359542157747963
    ],
    "volume": 1365092906180
  },
  {
    "ts": 1535155200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1461,
      "authorized": 1461,
      "funded": 238
    },
    "payments": 2,
    "payments_amount": 12630000000,
    "trades": 11,
    "traded_amount": 80025165269,
    "price": [
      5.359542157747963,
      5.378032924515012,
      5.347593582887701,
      5.347593582887701
    ],
    "volume": 419595492204
  },
  {
    "ts": 1535241600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1474,
      "authorized": 1474,
      "funded": 240
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 23,
    "traded_amount": 272456659955,
    "price": [
      5.347593582887701,
      5.487389475973511,
      5.202793110011885,
      5.46901328167902
    ],
    "volume": 1445626129742
  },
  {
    "ts": 1535328000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1496,
      "authorized": 1496,
      "funded": 241
    },
    "payments": 47,
    "payments_amount": 472325986,
    "trades": 60,
    "traded_amount": 20584886094,
    "price": [
      5.46901328167902,
      5.474043474043474,
      5.465601251348294,
      5.465601391974673
    ],
    "volume": 58884205753
  },
  {
    "ts": 1535414400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1507,
      "authorized": 1507,
      "funded": 242
    },
    "payments": 258,
    "payments_amount": 19028284159,
    "trades": 258,
    "traded_amount": 19020723355,
    "price": [
      5.465601391974673,
      5.4740385,
      5.465550497646786,
      5.4740385
    ],
    "volume": 103980579648
  },
  {
    "ts": 1535500800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1520,
      "authorized": 1520,
      "funded": 243
    },
    "payments": 99,
    "payments_amount": 15524108766,
    "trades": 104,
    "traded_amount": 1066973366,
    "price": [
      5.4740385,
      5.474039061817306,
      5.465333333333334,
      5.474039061817306
    ],
    "volume": 5838950740
  },
  {
    "ts": 1535587200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1528,
      "authorized": 1528,
      "funded": 243
    },
    "payments": 214,
    "payments_amount": 2135701487,
    "trades": 224,
    "traded_amount": 252339174630,
    "price": [
      5.474039061817306,
      5.4807501,
      5.28004614012672,
      5.379039967770953
    ],
    "volume": 1344041324154
  },
  {
    "ts": 1535673600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1538,
      "authorized": 1538,
      "funded": 244
    },
    "payments": 224,
    "payments_amount": 7729949497,
    "trades": 257,
    "traded_amount": 745349222313,
    "price": [
      5.379039967770953,
      5.3994332,
      5.2353729909258995,
      5.395562880107663
    ],
    "volume": 3981149852081
  },
  {
    "ts": 1535760000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1549,
      "authorized": 1549,
      "funded": 246
    },
    "payments": 3,
    "payments_amount": 124400000,
    "trades": 1,
    "traded_amount": 9555447,
    "price": [
      5.395562880107663,
      5.39943343309842,
      5.395562880107663,
      5.39943343309842
    ],
    "volume": 51594000
  },
  {
    "ts": 1535846400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1560,
      "authorized": 1560,
      "funded": 249
    },
    "payments": 3,
    "payments_amount": 3000000000,
    "trades": 25,
    "traded_amount": 529909362725,
    "price": [
      5.39943343309842,
      5.39943343309842,
      4.9914650000116865,
      5.169641210906385
    ],
    "volume": 2704573075083
  },
  {
    "ts": 1535932800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1572,
      "authorized": 1572,
      "funded": 248
    },
    "payments": 3,
    "payments_amount": 31000000000,
    "trades": 14,
    "traded_amount": 190813001568,
    "price": [
      5.169641210906385,
      5.250310925031092,
      5.095372400075036,
      5.230153415145919
    ],
    "volume": 982529821471
  },
  {
    "ts": 1536019200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1581,
      "authorized": 1581,
      "funded": 249
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 312867007,
    "price": [
      5.230153415145919,
      5.25094136,
      5.089689802058212,
      5.089692781682
    ],
    "volume": 1637140301
  },
  {
    "ts": 1536105600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1587,
      "authorized": 1587,
      "funded": 249
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 32,
    "traded_amount": 246508946,
    "price": [
      5.089692781682,
      5.470000945310171,
      5.049551363057152,
      5.470000945310171
    ],
    "volume": 669890347
  },
  {
    "ts": 1536192000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1593,
      "authorized": 1593,
      "funded": 249
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 50,
    "traded_amount": 8180415810,
    "price": [
      5.470000945310171,
      5.742491774898584,
      5.470000945310171,
      5.742491774898584
    ],
    "volume": 23433782519
  },
  {
    "ts": 1536278400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1607,
      "authorized": 1607,
      "funded": 249
    },
    "payments": 44,
    "payments_amount": 446497298,
    "trades": 49,
    "traded_amount": 1500952823,
    "price": [
      5.742491774898584,
      5.745317453174532,
      5.049546094135015,
      5.589706552687652
    ],
    "volume": 7834058026
  },
  {
    "ts": 1536364800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1609,
      "authorized": 1609,
      "funded": 248
    },
    "payments": 219,
    "payments_amount": 2225573988,
    "trades": 228,
    "traded_amount": 11233779923,
    "price": [
      5.589706552687652,
      5.9700996,
      5.525614035087719,
      5.525672701511408
    ],
    "volume": 37735965024
  },
  {
    "ts": 1536451200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1611,
      "authorized": 1611,
      "funded": 250
    },
    "payments": 152,
    "payments_amount": 1573038222,
    "trades": 156,
    "traded_amount": 2023204888,
    "price": [
      5.525672701511408,
      6.000024000096,
      5.5256722174949084,
      5.52567248544744
    ],
    "volume": 11500348072
  },
  {
    "ts": 1536537600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1615,
      "authorized": 1615,
      "funded": 252
    },
    "payments": 102,
    "payments_amount": 14972468131,
    "trades": 105,
    "traded_amount": 2530355817,
    "price": [
      5.52567248544744,
      6,
      5.525672227705026,
      6
    ],
    "volume": 10411557701
  },
  {
    "ts": 1536624000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1622,
      "authorized": 1622,
      "funded": 252
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 500000100,
    "price": [
      6,
      6,
      5.99999880000024,
      5.99999880000024
    ],
    "volume": 3000000000
  },
  {
    "ts": 1536710400,
    "supply": 734310236967,
    "trustlines": {
      "total": 1629,
      "authorized": 1629,
      "funded": 250
    },
    "payments": 1,
    "payments_amount": 741096603,
    "trades": 6,
    "traded_amount": 9014254105,
    "price": [
      5.99999880000024,
      5.99999880000024,
      5.5227272727272725,
      5.915508639436724
    ],
    "volume": 49935174982
  },
  {
    "ts": 1536796800,
    "supply": 734310236967,
    "trustlines": {
      "total": 1629,
      "authorized": 1629,
      "funded": 253
    },
    "payments": 6,
    "payments_amount": 84738495833,
    "trades": 10,
    "traded_amount": 2672864282,
    "price": [
      5.915508639436724,
      5.915508639436724,
      5.00399771559109,
      5.7381326985839936
    ],
    "volume": 14714491364
  },
  {
    "ts": 1536883200,
    "supply": 734310236967,
    "trustlines": {
      "total": 1639,
      "authorized": 1639,
      "funded": 255
    },
    "payments": 4,
    "payments_amount": 14107811000,
    "trades": 203,
    "traded_amount": 100899020038,
    "price": [
      5.7381326985839936,
      5.9,
      5.596005605815783,
      5.765372847540983
    ],
    "volume": 574693697630
  },
  {
    "ts": 1536969600,
    "supply": 734310236967,
    "trustlines": {
      "total": 1646,
      "authorized": 1646,
      "funded": 258
    },
    "payments": 4,
    "payments_amount": 1017398568,
    "trades": 53,
    "traded_amount": 10298959960,
    "price": [
      5.765372847540983,
      5.796614503222429,
      5.679178649423839,
      5.756369674893352
    ],
    "volume": 54146285936
  },
  {
    "ts": 1537056000,
    "supply": 734310236967,
    "trustlines": {
      "total": 1656,
      "authorized": 1656,
      "funded": 258
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 44,
    "traded_amount": 7167714776,
    "price": [
      5.756369674893352,
      5.8368165,
      5.628190161946346,
      5.6649748584349275
    ],
    "volume": 41276079082
  },
  {
    "ts": 1537142400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1661,
      "authorized": 1661,
      "funded": 257
    },
    "payments": 10,
    "payments_amount": 2953785301001,
    "trades": 83,
    "traded_amount": 10827620995,
    "price": [
      5.6649748584349275,
      5.970719454423591,
      5.585671309698731,
      5.970719454423591
    ],
    "volume": 62692605405
  },
  {
    "ts": 1537228800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1673,
      "authorized": 1673,
      "funded": 257
    },
    "payments": 7,
    "payments_amount": 50310734,
    "trades": 122,
    "traded_amount": 123665524253,
    "price": [
      5.970719454423591,
      6.000183620019507,
      5.473266100889496,
      5.616338980816949
    ],
    "volume": 689149295643
  },
  {
    "ts": 1537315200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1681,
      "authorized": 1681,
      "funded": 257
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 118,
    "traded_amount": 32504549236,
    "price": [
      5.616338980816949,
      5.859657172458005,
      5.594542004570262,
      5.647640482817555
    ],
    "volume": 183379870641
  },
  {
    "ts": 1537401600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1682,
      "authorized": 1682,
      "funded": 258
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 324,
    "traded_amount": 168299393774,
    "price": [
      5.647640482817555,
      5.650625763193765,
      4.881469680051147,
      5.01762163182
    ],
    "volume": 755126522301
  },
  {
    "ts": 1537488000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1694,
      "authorized": 1694,
      "funded": 263
    },
    "payments": 2,
    "payments_amount": 191052444,
    "trades": 717,
    "traded_amount": 291856588706,
    "price": [
      5.01762163182,
      5.081345581137457,
      4.279045800411679,
      4.706237704139778
    ],
    "volume": 801989210784
  },
  {
    "ts": 1537574400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1704,
      "authorized": 1704,
      "funded": 263
    },
    "payments": 6,
    "payments_amount": 6952544,
    "trades": 155,
    "traded_amount": 59005643339,
    "price": [
      4.706237704139778,
      5.075939609399078,
      4.706237704139778,
      4.942646410012753
    ],
    "volume": 244741226083
  },
  {
    "ts": 1537660800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1714,
      "authorized": 1714,
      "funded": 267
    },
    "payments": 51,
    "payments_amount": 480004339,
    "trades": 627,
    "traded_amount": 848747219830,
    "price": [
      4.942646410012753,
      4.942646410012753,
      3.945203900245161,
      4.199391100720593
    ],
    "volume": 3158004026472
  },
  {
    "ts": 1537747200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1719,
      "authorized": 1719,
      "funded": 267
    },
    "payments": 46,
    "payments_amount": 2451007227,
    "trades": 309,
    "traded_amount": 10366094559,
    "price": [
      4.199391100720593,
      4.619448901062737,
      4.144241905735754,
      4.2838170624450305
    ],
    "volume": 28457856361
  },
  {
    "ts": 1537833600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1726,
      "authorized": 1726,
      "funded": 274
    },
    "payments": 5,
    "payments_amount": 27749800000,
    "trades": 355,
    "traded_amount": 41311415621,
    "price": [
      4.2838170624450305,
      5.09310200258782,
      4.2838170624450305,
      4.678099931487814
    ],
    "volume": 87521210694
  },
  {
    "ts": 1537920000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1732,
      "authorized": 1732,
      "funded": 276
    },
    "payments": 2,
    "payments_amount": 31555,
    "trades": 514,
    "traded_amount": 17909662220,
    "price": [
      4.678099931487814,
      4.9445847,
      3.3499999943182677,
      4.675058415617698
    ],
    "volume": 59760454722
  },
  {
    "ts": 1538006400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1732,
      "authorized": 1732,
      "funded": 277
    },
    "payments": 1,
    "payments_amount": 10019800000,
    "trades": 138,
    "traded_amount": 11242184355,
    "price": [
      4.675058415617698,
      4.792111947093497,
      4.452561699230333,
      4.537344652507112
    ],
    "volume": 25140789645
  },
  {
    "ts": 1538092800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1744,
      "authorized": 1744,
      "funded": 279
    },
    "payments": 3,
    "payments_amount": 2750011000000,
    "trades": 269,
    "traded_amount": 9455993131,
    "price": [
      4.537344652507112,
      4.686616660456898,
      4.428571428571429,
      4.649272754114264
    ],
    "volume": 25664202409
  },
  {
    "ts": 1538179200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1751,
      "authorized": 1751,
      "funded": 277
    },
    "payments": 7,
    "payments_amount": 1063456971,
    "trades": 384,
    "traded_amount": 16736956359,
    "price": [
      4.649272754114264,
      4.782862230135767,
      4.481247707591621,
      4.5613045423180205
    ],
    "volume": 53150611149
  },
  {
    "ts": 1538265600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1754,
      "authorized": 1754,
      "funded": 277
    },
    "payments": 4,
    "payments_amount": 69155,
    "trades": 243,
    "traded_amount": 5336860031,
    "price": [
      4.5613045423180205,
      4.617075533885972,
      4.434782608695652,
      4.45
    ],
    "volume": 14364101581
  },
  {
    "ts": 1538352000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1765,
      "authorized": 1765,
      "funded": 278
    },
    "payments": 1,
    "payments_amount": 50000032,
    "trades": 16,
    "traded_amount": 294409249,
    "price": [
      4.45,
      4.607093641219375,
      4.45,
      4.518227795554576
    ],
    "volume": 1098452468
  },
  {
    "ts": 1538438400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1769,
      "authorized": 1769,
      "funded": 281
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 164,
    "traded_amount": 405384616,
    "price": [
      4.518227795554576,
      4.66,
      4.4774757463330035,
      4.66
    ],
    "volume": 1864633952
  },
  {
    "ts": 1538524800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1772,
      "authorized": 1772,
      "funded": 283
    },
    "payments": 10,
    "payments_amount": 189108769,
    "trades": 56,
    "traded_amount": 8426101868,
    "price": [
      4.66,
      4.875532520361324,
      4.633991467407481,
      4.810000173642686
    ],
    "volume": 35641716243
  },
  {
    "ts": 1538611200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1784,
      "authorized": 1784,
      "funded": 284
    },
    "payments": 6,
    "payments_amount": 51182500000,
    "trades": 28,
    "traded_amount": 4217723472,
    "price": [
      4.810000173642686,
      4.810000173642686,
      4.656665713833407,
      4.74546460308191
    ],
    "volume": 15857835909
  },
  {
    "ts": 1538697600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1787,
      "authorized": 1787,
      "funded": 284
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 137,
    "traded_amount": 10653689166,
    "price": [
      4.74546460308191,
      4.7868018,
      4.666342096721843,
      4.6882169305279
    ],
    "volume": 25293128372
  },
  {
    "ts": 1538784000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1789,
      "authorized": 1789,
      "funded": 286
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 177,
    "traded_amount": 1082800269,
    "price": [
      4.6882169305279,
      4.7949308755760365,
      4.6823321663698065,
      4.778490669104648
    ],
    "volume": 4562089392
  },
  {
    "ts": 1538870400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1797,
      "authorized": 1797,
      "funded": 287
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 87390749,
    "price": [
      4.778490669104648,
      4.8483320646108155,
      4.7632337322250935,
      4.780089588439067
    ],
    "volume": 421522788
  },
  {
    "ts": 1538956800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1801,
      "authorized": 1801,
      "funded": 286
    },
    "payments": 6,
    "payments_amount": 2100085382,
    "trades": 308,
    "traded_amount": 2717097275,
    "price": [
      4.780089588439067,
      4.786690606502432,
      4.640490797546012,
      4.659476117103236
    ],
    "volume": 6573982632
  },
  {
    "ts": 1539043200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1810,
      "authorized": 1810,
      "funded": 285
    },
    "payments": 5,
    "payments_amount": 1047800000,
    "trades": 70,
    "traded_amount": 80006697,
    "price": [
      4.659476117103236,
      4.791250276759721,
      4.643699002719855,
      4.643699002719855
    ],
    "volume": 188766702
  },
  {
    "ts": 1539129600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1819,
      "authorized": 1819,
      "funded": 287
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 38,
    "traded_amount": 2346757008,
    "price": [
      4.643699002719855,
      4.78,
      4.643699002719855,
      4.78
    ],
    "volume": 11100008697
  },
  {
    "ts": 1539216000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1816,
      "authorized": 1816,
      "funded": 294
    },
    "payments": 1,
    "payments_amount": 2800000000,
    "trades": 241,
    "traded_amount": 326204496599,
    "price": [
      4.78,
      5.6225166,
      1.249999999990939,
      5.6225166
    ],
    "volume": 1225833950711
  },
  {
    "ts": 1539302400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1825,
      "authorized": 1825,
      "funded": 293
    },
    "payments": 1,
    "payments_amount": 2545000000,
    "trades": 16,
    "traded_amount": 21960987732,
    "price": [
      5.6225166,
      5.715732002589244,
      5.315204941081587,
      5.43
    ],
    "volume": 117743796733
  },
  {
    "ts": 1539388800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1830,
      "authorized": 1830,
      "funded": 295
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 343881069,
    "price": [
      5.43,
      5.44460002866847,
      5.370000574473382,
      5.44460002866847
    ],
    "volume": 1854739358
  },
  {
    "ts": 1539475200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1831,
      "authorized": 1831,
      "funded": 293
    },
    "payments": 1,
    "payments_amount": 34707617,
    "trades": 10,
    "traded_amount": 5723326059,
    "price": [
      5.44460002866847,
      5.514766700110401,
      5.4239582724812925,
      5.514766700110401
    ],
    "volume": 31341057300
  },
  {
    "ts": 1539561600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1839,
      "authorized": 1839,
      "funded": 293
    },
    "payments": 2,
    "payments_amount": 1219800000,
    "trades": 218,
    "traded_amount": 92321594258,
    "price": [
      5.514766700110401,
      5.5925633,
      5.1570828001608255,
      5.2909605
    ],
    "volume": 343358477583
  },
  {
    "ts": 1539648000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1842,
      "authorized": 1842,
      "funded": 291
    },
    "payments": 5,
    "payments_amount": 24737732760,
    "trades": 157,
    "traded_amount": 91007564200,
    "price": [
      5.2909605,
      5.2909605,
      5.069903501391508,
      5.0699035812672175
    ],
    "volume": 245122120536
  },
  {
    "ts": 1539734400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1848,
      "authorized": 1848,
      "funded": 292
    },
    "payments": 2,
    "payments_amount": 120000000,
    "trades": 48,
    "traded_amount": 68567778409,
    "price": [
      5.0699035812672175,
      5.0881374,
      4.7778761,
      4.813751000622389
    ],
    "volume": 303223634054
  },
  {
    "ts": 1539820800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1855,
      "authorized": 1855,
      "funded": 289
    },
    "payments": 3,
    "payments_amount": 2890000000,
    "trades": 46,
    "traded_amount": 24877152861,
    "price": [
      4.813751000622389,
      5.005869786023289,
      4.7361877,
      4.890337511387962
    ],
    "volume": 83221133128
  },
  {
    "ts": 1539907200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1858,
      "authorized": 1858,
      "funded": 290
    },
    "payments": 2,
    "payments_amount": 359184,
    "trades": 9,
    "traded_amount": 4385010155,
    "price": [
      4.890337511387962,
      4.911691169116912,
      4.747533700158212,
      4.747533700158212
    ],
    "volume": 12824185712
  },
  {
    "ts": 1539993600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1861,
      "authorized": 1861,
      "funded": 291
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 20,
    "traded_amount": 79888243165,
    "price": [
      4.747533700158212,
      4.756180400464822,
      4.590341958716859,
      4.652842099867781
    ],
    "volume": 363687795392
  },
  {
    "ts": 1540080000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1862,
      "authorized": 1862,
      "funded": 291
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 52103540,
    "price": [
      4.652842099867781,
      4.753889158276049,
      4.652842099867781,
      4.753889158276049
    ],
    "volume": 244040556
  },
  {
    "ts": 1540166400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1864,
      "authorized": 1864,
      "funded": 291
    },
    "payments": 5,
    "payments_amount": 1003561554,
    "trades": 64,
    "traded_amount": 4843779712,
    "price": [
      4.753889158276049,
      4.78106,
      4.7530095475300955,
      4.766747171730011
    ],
    "volume": 11538908281
  },
  {
    "ts": 1540252800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1875,
      "authorized": 1875,
      "funded": 293
    },
    "payments": 3,
    "payments_amount": 17047900000,
    "trades": 297,
    "traded_amount": 31981429378,
    "price": [
      4.766747171730011,
      4.7907000303480105,
      4.645544905626475,
      4.75
    ],
    "volume": 132179127892
  },
  {
    "ts": 1540339200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1888,
      "authorized": 1888,
      "funded": 293
    },
    "payments": 4,
    "payments_amount": 216327,
    "trades": 355,
    "traded_amount": 25444654887,
    "price": [
      4.75,
      4.818181818181818,
      4.706312010365259,
      4.773792913499918
    ],
    "volume": 102887221048
  },
  {
    "ts": 1540425600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1892,
      "authorized": 1892,
      "funded": 294
    },
    "payments": 6,
    "payments_amount": 400089599330,
    "trades": 56,
    "traded_amount": 6316760125,
    "price": [
      4.773792913499918,
      4.773877387738774,
      4.000115,
      4.77381900030194
    ],
    "volume": 15080187339
  },
  {
    "ts": 1540512000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1897,
      "authorized": 1897,
      "funded": 298
    },
    "payments": 2,
    "payments_amount": 9892400000,
    "trades": 66,
    "traded_amount": 380781226,
    "price": [
      4.77381900030194,
      4.774116523400191,
      4.690132921119629,
      4.774116523400191
    ],
    "volume": 1382367032
  },
  {
    "ts": 1540598400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1900,
      "authorized": 1900,
      "funded": 298
    },
    "payments": 2,
    "payments_amount": 180429,
    "trades": 109,
    "traded_amount": 6097406569,
    "price": [
      4.774116523400191,
      4.774116523400191,
      4.74999999224108,
      4.773810092137299
    ],
    "volume": 14557571825
  },
  {
    "ts": 1540684800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1903,
      "authorized": 1903,
      "funded": 299
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 30,
    "traded_amount": 7195815384,
    "price": [
      4.773810092137299,
      4.773810092137299,
      4.770001116267203,
      4.770007064284994
    ],
    "volume": 17199534785
  },
  {
    "ts": 1540771200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1905,
      "authorized": 1905,
      "funded": 299
    },
    "payments": 1,
    "payments_amount": 4600000000,
    "trades": 25,
    "traded_amount": 676568764,
    "price": [
      4.770007064284994,
      4.79061494980996,
      4.770007064284994,
      4.7906061865148475
    ],
    "volume": 1919191160
  },
  {
    "ts": 1540857600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1908,
      "authorized": 1908,
      "funded": 298
    },
    "payments": 13,
    "payments_amount": 326321100000,
    "trades": 108,
    "traded_amount": 4025777875,
    "price": [
      4.7906061865148475,
      4.806321476180453,
      4.29999974005516,
      4.69999699997
    ],
    "volume": 9697822775
  },
  {
    "ts": 1540944000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1914,
      "authorized": 1914,
      "funded": 299
    },
    "payments": 8,
    "payments_amount": 935131800000,
    "trades": 6,
    "traded_amount": 6173554124,
    "price": [
      4.69999699997,
      4.920463800098767,
      4.69999699997,
      4.920463800098767
    ],
    "volume": 30151255969
  },
  {
    "ts": 1541030400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1920,
      "authorized": 1920,
      "funded": 299
    },
    "payments": 7,
    "payments_amount": 111783199994,
    "trades": 1,
    "traded_amount": 40000000,
    "price": [
      4.920463800098767,
      4.920463800098767,
      4.81,
      4.81
    ],
    "volume": 192400000
  },
  {
    "ts": 1541116800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1929,
      "authorized": 1929,
      "funded": 299
    },
    "payments": 3,
    "payments_amount": 302545100000,
    "trades": 2,
    "traded_amount": 81102055,
    "price": [
      4.81,
      4.81,
      4.800000786957511,
      4.800000786957511
    ],
    "volume": 389289928
  },
  {
    "ts": 1541203200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1935,
      "authorized": 1935,
      "funded": 301
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 22,
    "traded_amount": 485619451,
    "price": [
      4.800000786957511,
      4.821570398060804,
      4.739183715028125,
      4.8148632888917975
    ],
    "volume": 1700551565
  },
  {
    "ts": 1541289600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1946,
      "authorized": 1946,
      "funded": 302
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 26,
    "traded_amount": 406309458,
    "price": [
      4.8148632888917975,
      4.817475427711131,
      4.601905451037144,
      4.669554348271721
    ],
    "volume": 1508725656
  },
  {
    "ts": 1541376000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1955,
      "authorized": 1955,
      "funded": 308
    },
    "payments": 8,
    "payments_amount": 114615685536,
    "trades": 55,
    "traded_amount": 2790454870,
    "price": [
      4.669554348271721,
      4.724571801326231,
      4.633928725034326,
      4.679454900847376
    ],
    "volume": 12700277094
  },
  {
    "ts": 1541462400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1964,
      "authorized": 1964,
      "funded": 312
    },
    "payments": 5,
    "payments_amount": 34694845472,
    "trades": 104,
    "traded_amount": 10584926331,
    "price": [
      4.679454900847376,
      4.679454900847376,
      4.253918386675752,
      4.2860278149979205
    ],
    "volume": 46243897866
  },
  {
    "ts": 1541548800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 1986,
      "authorized": 1986,
      "funded": 313
    },
    "payments": 64,
    "payments_amount": 1038192902202,
    "trades": 91,
    "traded_amount": 2956830977,
    "price": [
      4.2860278149979205,
      4.48801622863735,
      4.2860278149979205,
      4.405084462134189
    ],
    "volume": 7464659333
  },
  {
    "ts": 1541635200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2000,
      "authorized": 2000,
      "funded": 311
    },
    "payments": 23,
    "payments_amount": 226508900000,
    "trades": 346,
    "traded_amount": 6836263733,
    "price": [
      4.405084462134189,
      5.3333333,
      4.285714285714286,
      5.3125
    ],
    "volume": 21944011657
  },
  {
    "ts": 1541721600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2014,
      "authorized": 2014,
      "funded": 311
    },
    "payments": 37,
    "payments_amount": 332328876069,
    "trades": 535,
    "traded_amount": 9396082011,
    "price": [
      5.3125,
      5.3125,
      4.274486024486024,
      4.416549476666649
    ],
    "volume": 22690430363
  },
  {
    "ts": 1541808000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2022,
      "authorized": 2022,
      "funded": 310
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 81,
    "traded_amount": 7442847697,
    "price": [
      4.416549476666649,
      4.416549476666649,
      4.349999980179946,
      4.350000047392176
    ],
    "volume": 27372633168
  },
  {
    "ts": 1541894400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2021,
      "authorized": 2021,
      "funded": 311
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 198,
    "traded_amount": 49799373496,
    "price": [
      4.350000047392176,
      4.350000047392176,
      4.136363636363637,
      4.1399999998035035
    ],
    "volume": 103862636606
  },
  {
    "ts": 1541980800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2039,
      "authorized": 2039,
      "funded": 314
    },
    "payments": 9,
    "payments_amount": 1453201428,
    "trades": 150,
    "traded_amount": 31907666581,
    "price": [
      4.1399999998035035,
      4.8,
      4.036887716851647,
      4.1964518899332965
    ],
    "volume": 69392279980
  },
  {
    "ts": 1542067200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2038,
      "authorized": 2038,
      "funded": 312
    },
    "payments": 31,
    "payments_amount": 208180642385,
    "trades": 1330,
    "traded_amount": 172504367947,
    "price": [
      4.1964518899332965,
      4.98000000129549,
      4.0959803292709,
      4.2839762135051265
    ],
    "volume": 356311617524
  },
  {
    "ts": 1542153600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2048,
      "authorized": 2048,
      "funded": 311
    },
    "payments": 20,
    "payments_amount": 98302562800,
    "trades": 332,
    "traded_amount": 48230595660,
    "price": [
      4.2839762135051265,
      4.960001386358664,
      4.2839762135051265,
      4.889999955184337
    ],
    "volume": 117823509119
  },
  {
    "ts": 1542240000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2052,
      "authorized": 2052,
      "funded": 312
    },
    "payments": 23,
    "payments_amount": 217550841930,
    "trades": 250,
    "traded_amount": 16074489837,
    "price": [
      4.889999955184337,
      5,
      4.643614190931288,
      4.643614190931288
    ],
    "volume": 3844251204
  },
  {
    "ts": 1542326400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2075,
      "authorized": 2075,
      "funded": 313
    },
    "payments": 35,
    "payments_amount": 398038221016,
    "trades": 820,
    "traded_amount": 24591697591,
    "price": [
      4.643614190931288,
      4.694780577150197,
      4.447136563876652,
      4.447136563876652
    ],
    "volume": 16580237677
  },
  {
    "ts": 1542412800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2084,
      "authorized": 2084,
      "funded": 313
    },
    "payments": 4,
    "payments_amount": 10241459,
    "trades": 183,
    "traded_amount": 15538510858,
    "price": [
      4.447136563876652,
      4.8903377496842975,
      4.447136563876652,
      4.718280566193668
    ],
    "volume": 408009088
  },
  {
    "ts": 1542499200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2085,
      "authorized": 2085,
      "funded": 312
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 33,
    "traded_amount": 11053629291,
    "price": [
      4.718280566193668,
      4.718280566193668,
      4.409999999,
      4.499999755461044
    ],
    "volume": 25087365857
  },
  {
    "ts": 1542585600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2099,
      "authorized": 2099,
      "funded": 313
    },
    "payments": 6,
    "payments_amount": 47584154426,
    "trades": 303,
    "traded_amount": 43272608222,
    "price": [
      4.499999755461044,
      5.02460024600246,
      4.499999755461044,
      5.024599821530064
    ],
    "volume": 95238177459
  },
  {
    "ts": 1542672000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2144,
      "authorized": 2144,
      "funded": 313
    },
    "payments": 3,
    "payments_amount": 5849100051,
    "trades": 251,
    "traded_amount": 60064337551,
    "price": [
      5.024599821530064,
      7.2202166064981945,
      4.762154195400227,
      6.1536468013937835
    ],
    "volume": 352322304773
  },
  {
    "ts": 1542758400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2174,
      "authorized": 2174,
      "funded": 314
    },
    "payments": 9,
    "payments_amount": 350596400000,
    "trades": 123,
    "traded_amount": 1567763903,
    "price": [
      6.1536468013937835,
      6.1536468013937835,
      5.593704545614788,
      5.733333333333333
    ],
    "volume": 9008642188
  },
  {
    "ts": 1542844800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2179,
      "authorized": 2179,
      "funded": 314
    },
    "payments": 6,
    "payments_amount": 1682012300000,
    "trades": 11,
    "traded_amount": 1172233882,
    "price": [
      5.733333333333333,
      6.14878110633117,
      5.733333333333333,
      6.14878110633117
    ],
    "volume": 6932309345
  },
  {
    "ts": 1542931200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2188,
      "authorized": 2188,
      "funded": 316
    },
    "payments": 1,
    "payments_amount": 1000000,
    "trades": 131,
    "traded_amount": 26856344018,
    "price": [
      6.14878110633117,
      6.480110809543732,
      6.14878110633117,
      6.311995616666667
    ],
    "volume": 168886816523
  },
  {
    "ts": 1543017600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2206,
      "authorized": 2206,
      "funded": 317
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 70,
    "traded_amount": 17212729999,
    "price": [
      6.311995616666667,
      7.220216602185973,
      6.311995616666667,
      7.220216602185973
    ],
    "volume": 123107400021
  },
  {
    "ts": 1543104000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2211,
      "authorized": 2211,
      "funded": 319
    },
    "payments": 1,
    "payments_amount": 20476488,
    "trades": 97,
    "traded_amount": 20230453981,
    "price": [
      7.220216602185973,
      9.69000000089195,
      7.169,
      7.298002198929238
    ],
    "volume": 159640830445
  },
  {
    "ts": 1543190400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2220,
      "authorized": 2220,
      "funded": 317
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 220,
    "traded_amount": 26933438047,
    "price": [
      7.298002198929238,
      8.08748714721728,
      7.113531185798315,
      8.08748714721728
    ],
    "volume": 211114191510
  },
  {
    "ts": 1543276800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2225,
      "authorized": 2225,
      "funded": 318
    },
    "payments": 3,
    "payments_amount": 20052200564,
    "trades": 5,
    "traded_amount": 1581387629,
    "price": [
      8.08748714721728,
      8.144963507223906,
      7.7,
      7.7
    ],
    "volume": 12231374470
  },
  {
    "ts": 1543363200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2242,
      "authorized": 2242,
      "funded": 321
    },
    "payments": 31,
    "payments_amount": 340244000258,
    "trades": 275,
    "traded_amount": 45314784551,
    "price": [
      7.7,
      7.7,
      6.790000226806587,
      7.148148148148148
    ],
    "volume": 201761910111
  },
  {
    "ts": 1543449600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2285,
      "authorized": 2285,
      "funded": 319
    },
    "payments": 54,
    "payments_amount": 858380800871,
    "trades": 651,
    "traded_amount": 62354816592,
    "price": [
      7.148148148148148,
      7.148148148148148,
      6.622369998531476,
      6.700795884333434
    ],
    "volume": 190234029123
  },
  {
    "ts": 1543536000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2298,
      "authorized": 2298,
      "funded": 320
    },
    "payments": 13,
    "payments_amount": 63516519021,
    "trades": 253,
    "traded_amount": 45407979797,
    "price": [
      6.700795884333434,
      7.434782608695652,
      6.107390300230947,
      7.16
    ],
    "volume": 92753095082
  },
  {
    "ts": 1543622400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2304,
      "authorized": 2304,
      "funded": 320
    },
    "payments": 8,
    "payments_amount": 730186976,
    "trades": 149,
    "traded_amount": 22830274978,
    "price": [
      7.16,
      7.16,
      6.699063698617462,
      6.860862220469643
    ],
    "volume": 80320543025
  },
  {
    "ts": 1543708800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2319,
      "authorized": 2319,
      "funded": 318
    },
    "payments": 1,
    "payments_amount": 14562,
    "trades": 144,
    "traded_amount": 17970065,
    "price": [
      6.860862220469643,
      7.000114168284051,
      6.788912798442463,
      6.904761904761905
    ],
    "volume": 61339905
  },
  {
    "ts": 1543795200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2331,
      "authorized": 2331,
      "funded": 320
    },
    "payments": 31,
    "payments_amount": 546575916453,
    "trades": 515,
    "traded_amount": 41846866638,
    "price": [
      6.904761904761905,
      7.600001520010944,
      6.904761904761905,
      7.600001520010944
    ],
    "volume": 144817984773
  },
  {
    "ts": 1543881600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2356,
      "authorized": 2356,
      "funded": 319
    },
    "payments": 30,
    "payments_amount": 292030985514,
    "trades": 121,
    "traded_amount": 11668936208,
    "price": [
      7.600001520010944,
      7.625,
      7.250052562169216,
      7.380054462261963
    ],
    "volume": 43253388463
  },
  {
    "ts": 1543968000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2366,
      "authorized": 2366,
      "funded": 317
    },
    "payments": 15,
    "payments_amount": 129289230079,
    "trades": 74,
    "traded_amount": 30752599010,
    "price": [
      7.380054462261963,
      8.010000000866825,
      7.380054462261963,
      8.009999999735228
    ],
    "volume": 69232018859
  },
  {
    "ts": 1544054400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2383,
      "authorized": 2383,
      "funded": 317
    },
    "payments": 5,
    "payments_amount": 20823200000,
    "trades": 95,
    "traded_amount": 14457514141,
    "price": [
      8.009999999735228,
      8.95,
      7.39103762762675,
      8.95
    ],
    "volume": 84022860522
  },
  {
    "ts": 1544140800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2393,
      "authorized": 2393,
      "funded": 319
    },
    "payments": 7,
    "payments_amount": 9483165595,
    "trades": 255,
    "traded_amount": 4665791245,
    "price": [
      8.95,
      10.74334970188811,
      8.95,
      9.850467289719626
    ],
    "volume": 28433594381
  },
  {
    "ts": 1544227200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2399,
      "authorized": 2399,
      "funded": 319
    },
    "payments": 9,
    "payments_amount": 423170721,
    "trades": 60,
    "traded_amount": 3010760157,
    "price": [
      9.850467289719626,
      9.891943284692655,
      7.39103762687248,
      7.519999995339223
    ],
    "volume": 17982700901
  },
  {
    "ts": 1544313600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2413,
      "authorized": 2413,
      "funded": 319
    },
    "payments": 3,
    "payments_amount": 5374452,
    "trades": 63,
    "traded_amount": 3814010639,
    "price": [
      7.519999995339223,
      9.83495080015887,
      7.519739315703722,
      9.009893733333334
    ],
    "volume": 21753572832
  },
  {
    "ts": 1544400000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2434,
      "authorized": 2434,
      "funded": 320
    },
    "payments": 12,
    "payments_amount": 647918560520,
    "trades": 294,
    "traded_amount": 4187936342,
    "price": [
      9.009893733333334,
      9.962253063965527,
      7.520056578457193,
      9.809053703013516
    ],
    "volume": 21766801498
  },
  {
    "ts": 1544486400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2456,
      "authorized": 2456,
      "funded": 318
    },
    "payments": 14,
    "payments_amount": 446299679976,
    "trades": 154,
    "traded_amount": 4019916649,
    "price": [
      9.809053703013516,
      9.999799998687616,
      8.522495192297077,
      8.522495192297077
    ],
    "volume": 29779138662
  },
  {
    "ts": 1544572800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2482,
      "authorized": 2482,
      "funded": 318
    },
    "payments": 19,
    "payments_amount": 263394700000,
    "trades": 6,
    "traded_amount": 980570266,
    "price": [
      8.522495192297077,
      10.138,
      8.522495192297077,
      8.522509890139585
    ],
    "volume": 7616836044
  },
  {
    "ts": 1544659200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2501,
      "authorized": 2501,
      "funded": 315
    },
    "payments": 12,
    "payments_amount": 118911945272,
    "trades": 176,
    "traded_amount": 26366398501,
    "price": [
      8.522509890139585,
      10.614485100588999,
      8.522509890139585,
      10.24
    ],
    "volume": 202023966904
  },
  {
    "ts": 1544745600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2529,
      "authorized": 2529,
      "funded": 316
    },
    "payments": 11,
    "payments_amount": 1014730898506,
    "trades": 122,
    "traded_amount": 10923420019,
    "price": [
      10.24,
      11.75,
      10.24,
      11.75
    ],
    "volume": 18548674003
  },
  {
    "ts": 1544832000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2535,
      "authorized": 2535,
      "funded": 315
    },
    "payments": 2,
    "payments_amount": 729266,
    "trades": 100,
    "traded_amount": 18957072094,
    "price": [
      11.75,
      12.021817164068715,
      11.45,
      11.818192812261746
    ],
    "volume": 181522875509
  },
  {
    "ts": 1544918400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2537,
      "authorized": 2537,
      "funded": 316
    },
    "payments": 18,
    "payments_amount": 1342998705,
    "trades": 815,
    "traded_amount": 1890166421,
    "price": [
      11.818192812261746,
      12,
      11.347273018112016,
      11.796205630354956
    ],
    "volume": 11916638374
  },
  {
    "ts": 1545004800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2545,
      "authorized": 2545,
      "funded": 317
    },
    "payments": 55,
    "payments_amount": 24851959601,
    "trades": 1209,
    "traded_amount": 42762555483,
    "price": [
      11.796205630354956,
      11.875,
      10.083333333333334,
      10.237017669340549
    ],
    "volume": 251497048113
  },
  {
    "ts": 1545091200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2551,
      "authorized": 2551,
      "funded": 318
    },
    "payments": 21,
    "payments_amount": 197530026194,
    "trades": 412,
    "traded_amount": 6189157903,
    "price": [
      10.237017669340549,
      10.780237907493241,
      10.136645962732919,
      10.178633298059443
    ],
    "volume": 43832607975
  },
  {
    "ts": 1545177600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2560,
      "authorized": 2560,
      "funded": 321
    },
    "payments": 7,
    "payments_amount": 602095049460,
    "trades": 201,
    "traded_amount": 14548407969,
    "price": [
      10.178633298059443,
      10.342398412348428,
      9.189454870420018,
      9.89899999576054
    ],
    "volume": 118002139044
  },
  {
    "ts": 1545264000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2563,
      "authorized": 2563,
      "funded": 321
    },
    "payments": 2,
    "payments_amount": 10478513607,
    "trades": 75,
    "traded_amount": 8004334323,
    "price": [
      9.89899999576054,
      9.956569449723318,
      9.01126582944084,
      9.167095993761956
    ],
    "volume": 49134889658
  },
  {
    "ts": 1545350400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2568,
      "authorized": 2568,
      "funded": 322
    },
    "payments": 9,
    "payments_amount": 352209238273,
    "trades": 243,
    "traded_amount": 29253792398,
    "price": [
      9.167095993761956,
      9.599,
      8.533333333333333,
      9.599
    ],
    "volume": 166886175705
  },
  {
    "ts": 1545436800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2571,
      "authorized": 2571,
      "funded": 323
    },
    "payments": 5,
    "payments_amount": 164634191,
    "trades": 24,
    "traded_amount": 3224777882,
    "price": [
      9.599,
      9.668326907580699,
      9.314091346062803,
      9.435413605131425
    ],
    "volume": 17333260157
  },
  {
    "ts": 1545523200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2574,
      "authorized": 2574,
      "funded": 321
    },
    "payments": 2,
    "payments_amount": 120943,
    "trades": 58,
    "traded_amount": 17456680527,
    "price": [
      9.435413605131425,
      9.435413605131425,
      8.788504629374312,
      9.017723782182099
    ],
    "volume": 87370706646
  },
  {
    "ts": 1545609600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2577,
      "authorized": 2577,
      "funded": 320
    },
    "payments": 5,
    "payments_amount": 29169950933,
    "trades": 34,
    "traded_amount": 1791153265,
    "price": [
      9.017723782182099,
      9.017723782182099,
      8.24007579664987,
      8.781526666666666
    ],
    "volume": 14793907386
  },
  {
    "ts": 1545696000,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2581,
      "authorized": 2581,
      "funded": 319
    },
    "payments": 6,
    "payments_amount": 777854591,
    "trades": 342,
    "traded_amount": 50195798304,
    "price": [
      8.781526666666666,
      9.706793908735309,
      8.781526666666666,
      9.353101161291038
    ],
    "volume": 243429383078
  },
  {
    "ts": 1545782400,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2582,
      "authorized": 2582,
      "funded": 317
    },
    "payments": 12,
    "payments_amount": 1468783025,
    "trades": 279,
    "traded_amount": 22966335764,
    "price": [
      9.353101161291038,
      9.772143634385202,
      9.115820141027179,
      9.474727106353809
    ],
    "volume": 111026110844
  },
  {
    "ts": 1545868800,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2586,
      "authorized": 2586,
      "funded": 319
    },
    "payments": 12,
    "payments_amount": 240324519,
    "trades": 368,
    "traded_amount": 14028192209,
    "price": [
      9.474727106353809,
      10.384162025929166,
      8.999999062687463,
      10.384162025929166
    ],
    "volume": 84365888740
  },
  {
    "ts": 1545955200,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2590,
      "authorized": 2590,
      "funded": 319
    },
    "payments": 21,
    "payments_amount": 113373055831,
    "trades": 528,
    "traded_amount": 41608107582,
    "price": [
      10.384162025929166,
      10.4,
      9.363636363636363,
      9.411764705882353
    ],
    "volume": 207635162278
  },
  {
    "ts": 1546041600,
    "supply": 3634320236967,
    "trustlines": {
      "total": 2592,
      "authorized": 2592,
      "funded": 320
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 575798862,
    "price": [
      9.411764705882353,
      9.875486381322958,
      9.411764705882353,
      9.871892386620306
    ],
    "volume": 3190784487
  },
  {
    "ts": 1546128000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2620,
      "authorized": 2620,
      "funded": 320
    },
    "payments": 1,
    "payments_amount": 1,
    "trades": 246,
    "traded_amount": 10146059114,
    "price": [
      9.871892386620306,
      9.8866541,
      9.587832615656398,
      9.710271172305536
    ],
    "volume": 49357700280
  },
  {
    "ts": 1546214400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2628,
      "authorized": 2628,
      "funded": 318
    },
    "payments": 22,
    "payments_amount": 554705200000,
    "trades": 148,
    "traded_amount": 17267085287,
    "price": [
      9.710271172305536,
      10.363636363636363,
      9.710271172305536,
      10.31422269126142
    ],
    "volume": 92512992308
  },
  {
    "ts": 1546300800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2636,
      "authorized": 2636,
      "funded": 318
    },
    "payments": 8,
    "payments_amount": 850920086,
    "trades": 207,
    "traded_amount": 13161495792,
    "price": [
      10.31422269126142,
      10.385504827750626,
      9.155986707086697,
      9.8110128026247
    ],
    "volume": 65481198641
  },
  {
    "ts": 1546387200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2642,
      "authorized": 2642,
      "funded": 317
    },
    "payments": 40,
    "payments_amount": 108960312713,
    "trades": 389,
    "traded_amount": 10873894114,
    "price": [
      9.8110128026247,
      10.046471911432265,
      9.6,
      9.636363636363637
    ],
    "volume": 56425911654
  },
  {
    "ts": 1546473600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2645,
      "authorized": 2645,
      "funded": 318
    },
    "payments": 36,
    "payments_amount": 720659756857,
    "trades": 220,
    "traded_amount": 13503772194,
    "price": [
      9.636363636363637,
      9.922314984501911,
      9.6,
      9.92156862745098
    ],
    "volume": 65178327359
  },
  {
    "ts": 1546560000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2651,
      "authorized": 2651,
      "funded": 319
    },
    "payments": 11,
    "payments_amount": 69478499031,
    "trades": 94,
    "traded_amount": 79025052,
    "price": [
      9.92156862745098,
      10.05481886,
      9.92156862745098,
      10.05481886
    ],
    "volume": 794582537
  },
  {
    "ts": 1546646400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2652,
      "authorized": 2652,
      "funded": 319
    },
    "payments": 2,
    "payments_amount": 84347,
    "trades": 12,
    "traded_amount": 52076428,
    "price": [
      10.05481886,
      10.054818907820195,
      9.801561391057488,
      9.801561391057488
    ],
    "volume": 311086209
  },
  {
    "ts": 1546732800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2656,
      "authorized": 2656,
      "funded": 318
    },
    "payments": 28,
    "payments_amount": 30021588227,
    "trades": 291,
    "traded_amount": 31044248418,
    "price": [
      9.801561391057488,
      10.059987708173837,
      9.3223799099786,
      9.407514205750148
    ],
    "volume": 151868568193
  },
  {
    "ts": 1546819200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2676,
      "authorized": 2676,
      "funded": 316
    },
    "payments": 14,
    "payments_amount": 3500116450,
    "trades": 39,
    "traded_amount": 54713553,
    "price": [
      9.407514205750148,
      9.641688514947266,
      9.305705059203445,
      9.641688514947266
    ],
    "volume": 193778314
  },
  {
    "ts": 1546905600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2698,
      "authorized": 2698,
      "funded": 318
    },
    "payments": 8,
    "payments_amount": 117553495974,
    "trades": 47,
    "traded_amount": 4501387709,
    "price": [
      9.641688514947266,
      9.641688514947266,
      9.14669376470334,
      9.16387015
    ],
    "volume": 10909951634
  },
  {
    "ts": 1546992000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2704,
      "authorized": 2704,
      "funded": 318
    },
    "payments": 14,
    "payments_amount": 267041722903,
    "trades": 84,
    "traded_amount": 1719886595,
    "price": [
      9.16387015,
      9.622823722216967,
      9.132595160701795,
      9.132595160701795
    ],
    "volume": 662662737
  },
  {
    "ts": 1547078400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2724,
      "authorized": 2724,
      "funded": 323
    },
    "payments": 26,
    "payments_amount": 21965683387,
    "trades": 375,
    "traded_amount": 42954168400,
    "price": [
      9.132595160701795,
      10.714285714285714,
      9.0000819,
      10.714285714285714
    ],
    "volume": 176328378471
  },
  {
    "ts": 1547164800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2737,
      "authorized": 2737,
      "funded": 323
    },
    "payments": 34,
    "payments_amount": 55223287201,
    "trades": 436,
    "traded_amount": 11975906406,
    "price": [
      10.714285714285714,
      11,
      10.468770064220063,
      10.945673624592873
    ],
    "volume": 10124556826
  },
  {
    "ts": 1547251200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2749,
      "authorized": 2749,
      "funded": 323
    },
    "payments": 2,
    "payments_amount": 919490919,
    "trades": 109,
    "traded_amount": 3050354238,
    "price": [
      10.945673624592873,
      10.945673624592873,
      10.6,
      10.725574371009612
    ],
    "volume": 15503982841
  },
  {
    "ts": 1547337600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2752,
      "authorized": 2752,
      "funded": 323
    },
    "payments": 22,
    "payments_amount": 73476771657,
    "trades": 350,
    "traded_amount": 46775926938,
    "price": [
      10.725574371009612,
      11.142857142857142,
      10.179952580041451,
      10.405660756
    ],
    "volume": 191837641117
  },
  {
    "ts": 1547424000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2765,
      "authorized": 2765,
      "funded": 324
    },
    "payments": 13,
    "payments_amount": 18721157470,
    "trades": 58,
    "traded_amount": 19512382652,
    "price": [
      10.405660756,
      10.850007791803023,
      10.17448218289273,
      10.469865777036544
    ],
    "volume": 187488385984
  },
  {
    "ts": 1547510400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2766,
      "authorized": 2766,
      "funded": 325
    },
    "payments": 7,
    "payments_amount": 36017699860,
    "trades": 101,
    "traded_amount": 12992881913,
    "price": [
      10.469865777036544,
      10.913833132629371,
      10.401227341957888,
      10.80955059898074
    ],
    "volume": 72009993846
  },
  {
    "ts": 1547596800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2778,
      "authorized": 2778,
      "funded": 328
    },
    "payments": 20,
    "payments_amount": 612394400000,
    "trades": 163,
    "traded_amount": 12213749465,
    "price": [
      10.80955059898074,
      10.80955059898074,
      10.552975695417471,
      10.65000673695557
    ],
    "volume": 101653409791
  },
  {
    "ts": 1547683200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2790,
      "authorized": 2790,
      "funded": 327
    },
    "payments": 7,
    "payments_amount": 29104917735,
    "trades": 208,
    "traded_amount": 3485317680,
    "price": [
      10.65000673695557,
      10.68,
      10.47162853895358,
      10.47162853895358
    ],
    "volume": 33836583489
  },
  {
    "ts": 1547769600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2794,
      "authorized": 2794,
      "funded": 327
    },
    "payments": 11,
    "payments_amount": 96681816630,
    "trades": 112,
    "traded_amount": 57299782,
    "price": [
      10.47162853895358,
      10.56,
      10.151515151515152,
      10.517722209238919
    ],
    "volume": 300381224
  },
  {
    "ts": 1547856000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2794,
      "authorized": 2794,
      "funded": 328
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 1109141001,
    "price": [
      10.517722209238919,
      10.517722209238919,
      10.272567300758322,
      10.45140484982245
    ],
    "volume": 11110099655
  },
  {
    "ts": 1547942400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2808,
      "authorized": 2808,
      "funded": 327
    },
    "payments": 1,
    "payments_amount": 2652300000,
    "trades": 81,
    "traded_amount": 4134155630,
    "price": [
      10.45140484982245,
      10.826086956521738,
      10.45140484982245,
      10.790000000094283
    ],
    "volume": 44591187016
  },
  {
    "ts": 1548028800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2821,
      "authorized": 2821,
      "funded": 326
    },
    "payments": 6,
    "payments_amount": 37857504437,
    "trades": 10,
    "traded_amount": 214264675,
    "price": [
      10.790000000094283,
      10.9365469749886,
      9.1002468,
      10.930000103890167
    ],
    "volume": 2206087908
  },
  {
    "ts": 1548115200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2827,
      "authorized": 2827,
      "funded": 326
    },
    "payments": 5,
    "payments_amount": 15822522987,
    "trades": 19,
    "traded_amount": 30056283853,
    "price": [
      10.930000103890167,
      11.016851345959054,
      10.929999999967817,
      11.016851345959054
    ],
    "volume": 153794019816
  },
  {
    "ts": 1548201600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2848,
      "authorized": 2848,
      "funded": 325
    },
    "payments": 8,
    "payments_amount": 28777995816,
    "trades": 22,
    "traded_amount": 1687723654,
    "price": [
      11.016851345959054,
      11.32728579460495,
      11.000204603337396,
      11.32728579460495
    ],
    "volume": 11796433453
  },
  {
    "ts": 1548288000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2861,
      "authorized": 2861,
      "funded": 325
    },
    "payments": 13,
    "payments_amount": 171146975436,
    "trades": 209,
    "traded_amount": 10267565264,
    "price": [
      11.32728579460495,
      11.363636363636363,
      10.615384615384615,
      10.793832256392692
    ],
    "volume": 30588664769
  },
  {
    "ts": 1548374400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2877,
      "authorized": 2877,
      "funded": 325
    },
    "payments": 9,
    "payments_amount": 166521000000,
    "trades": 5,
    "traded_amount": 20974196,
    "price": [
      10.793832256392692,
      11.357680398183287,
      10.793832256392692,
      11.357680398183287
    ],
    "volume": 207007069
  },
  {
    "ts": 1548460800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2880,
      "authorized": 2880,
      "funded": 326
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 7,
    "traded_amount": 239484148,
    "price": [
      11.357680398183287,
      11.357680398183287,
      10.850804968452028,
      11.357680299888687
    ],
    "volume": 2661502311
  },
  {
    "ts": 1548547200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2888,
      "authorized": 2888,
      "funded": 327
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 6,
    "traded_amount": 238709889,
    "price": [
      11.357680299888687,
      11.656950117119841,
      11.30893477188595,
      11.656950117119841
    ],
    "volume": 2651836152
  },
  {
    "ts": 1548633600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2893,
      "authorized": 2893,
      "funded": 330
    },
    "payments": 14,
    "payments_amount": 734667551665,
    "trades": 73,
    "traded_amount": 6711200323,
    "price": [
      11.656950117119841,
      13.33333340337949,
      10.12299965244836,
      13.33333340337949
    ],
    "volume": 70665804653
  },
  {
    "ts": 1548720000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2900,
      "authorized": 2900,
      "funded": 330
    },
    "payments": 14,
    "payments_amount": 130245500000,
    "trades": 101,
    "traded_amount": 277692953,
    "price": [
      13.33333340337949,
      13.6986302,
      10.29999971621737,
      13.422853671706264
    ],
    "volume": 2953120940
  },
  {
    "ts": 1548806400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2914,
      "authorized": 2914,
      "funded": 331
    },
    "payments": 24,
    "payments_amount": 170477877872,
    "trades": 9,
    "traded_amount": 448495895,
    "price": [
      13.422853671706264,
      13.467359210571214,
      11,
      13.461043100132793
    ],
    "volume": 6028768196
  },
  {
    "ts": 1548892800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2920,
      "authorized": 2920,
      "funded": 331
    },
    "payments": 29,
    "payments_amount": 755968336865,
    "trades": 30,
    "traded_amount": 1892391061,
    "price": [
      13.461043100132793,
      14,
      10.999999962461182,
      13.756816504166666
    ],
    "volume": 16781115465
  },
  {
    "ts": 1548979200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2923,
      "authorized": 2923,
      "funded": 330
    },
    "payments": 20,
    "payments_amount": 155206512361,
    "trades": 18,
    "traded_amount": 516027807,
    "price": [
      13.756816504166666,
      14.109555402114632,
      11.198319327731092,
      13.927580270374516
    ],
    "volume": 3486192405
  },
  {
    "ts": 1549065600,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2932,
      "authorized": 2932,
      "funded": 329
    },
    "payments": 6,
    "payments_amount": 326381708,
    "trades": 12,
    "traded_amount": 517131726,
    "price": [
      13.927580270374516,
      13.927580270374516,
      12.999999878242953,
      13.649255617345426
    ],
    "volume": 3480570911
  },
  {
    "ts": 1549152000,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2939,
      "authorized": 2939,
      "funded": 330
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 14537697,
    "price": [
      13.649255617345426,
      13.649255617345426,
      12.200000436240854,
      12.200000436240854
    ],
    "volume": 176502276
  },
  {
    "ts": 1549238400,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2943,
      "authorized": 2943,
      "funded": 331
    },
    "payments": 17,
    "payments_amount": 112019108899,
    "trades": 22,
    "traded_amount": 31718273185,
    "price": [
      12.200000436240854,
      12.200000436240854,
      11,
      12
    ],
    "volume": 366871479915
  },
  {
    "ts": 1549324800,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2955,
      "authorized": 2955,
      "funded": 333
    },
    "payments": 19,
    "payments_amount": 211896200000,
    "trades": 10,
    "traded_amount": 2569275460,
    "price": [
      12,
      13.649256114872243,
      11.999923978942167,
      11.999923978942167
    ],
    "volume": 19142553210
  },
  {
    "ts": 1549411200,
    "supply": 3634320236966,
    "trustlines": {
      "total": 2966,
      "authorized": 2966,
      "funded": 333
    },
    "payments": 7,
    "payments_amount": 53186698449,
    "trades": 17,
    "traded_amount": 2554574519,
    "price": [
      11.999923978942167,
      14.5,
      11.999923978942167,
      14.49
    ],
    "volume": 33862406303
  },
  {
    "ts": 1549497600,
    "supply": 5134320336966,
    "trustlines": {
      "total": 2969,
      "authorized": 2969,
      "funded": 336
    },
    "payments": 9,
    "payments_amount": 1542808900000,
    "trades": 12,
    "traded_amount": 1443588888,
    "price": [
      14.49,
      14.500045599635204,
      12,
      14.49
    ],
    "volume": 16637681981
  },
  {
    "ts": 1549584000,
    "supply": 5134320336966,
    "trustlines": {
      "total": 2973,
      "authorized": 2973,
      "funded": 335
    },
    "payments": 24,
    "payments_amount": 69340823817,
    "trades": 85,
    "traded_amount": 1875043761,
    "price": [
      14.49,
      14.684593018378568,
      11.159849342033883,
      11.159849342033883
    ],
    "volume": 13647603282
  },
  {
    "ts": 1549670400,
    "supply": 5134320336966,
    "trustlines": {
      "total": 2980,
      "authorized": 2980,
      "funded": 335
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 33356173,
    "price": [
      11.159849342033883,
      14,
      11.159849342033883,
      13.573496875
    ],
    "volume": 422137855
  },
  {
    "ts": 1549756800,
    "supply": 5134320336966,
    "trustlines": {
      "total": 2987,
      "authorized": 2987,
      "funded": 335
    },
    "payments": 2,
    "payments_amount": 10801125,
    "trades": 9,
    "traded_amount": 1902843997,
    "price": [
      13.573496875,
      14.261809138819073,
      11.159999996770349,
      14.213731906679538
    ],
    "volume": 21896305610
  },
  {
    "ts": 1549843200,
    "supply": 5134320336966,
    "trustlines": {
      "total": 2994,
      "authorized": 2994,
      "funded": 336
    },
    "payments": 7,
    "payments_amount": 134794918135,
    "trades": 106,
    "traded_amount": 67505881,
    "price": [
      14.213731906679538,
      14.428571428571429,
      11.169998893695501,
      14.299000714235158
    ],
    "volume": 906871982
  },
  {
    "ts": 1549929600,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3008,
      "authorized": 3008,
      "funded": 338
    },
    "payments": 4,
    "payments_amount": 92717000000,
    "trades": 106,
    "traded_amount": 1027893102,
    "price": [
      14.299000714235158,
      14.299000714235158,
      13.1,
      14.100000002006219
    ],
    "volume": 9876007278
  },
  {
    "ts": 1550016000,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3008,
      "authorized": 3008,
      "funded": 338
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 8,
    "traded_amount": 654292361,
    "price": [
      14.100000002006219,
      14.189000003613197,
      14.099798372883267,
      14.189000003613197
    ],
    "volume": 9229577867
  },
  {
    "ts": 1550102400,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3018,
      "authorized": 3018,
      "funded": 2782
    },
    "payments": 2784,
    "payments_amount": 226768791893,
    "trades": 17,
    "traded_amount": 639677411,
    "price": [
      14.189000003613197,
      14.50524161851177,
      14.189000003613197,
      14.50524161851177
    ],
    "volume": 9181092221
  },
  {
    "ts": 1550188800,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3024,
      "authorized": 3024,
      "funded": 2782
    },
    "payments": 12,
    "payments_amount": 105017025613,
    "trades": 150,
    "traded_amount": 418834108,
    "price": [
      14.50524161851177,
      14.50524161851177,
      12.30993528687651,
      12.309968709671717
    ],
    "volume": 5481424140
  },
  {
    "ts": 1550275200,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3032,
      "authorized": 3032,
      "funded": 2781
    },
    "payments": 14,
    "payments_amount": 620726782,
    "trades": 98,
    "traded_amount": 3249002861,
    "price": [
      12.309968709671717,
      14.301506456241032,
      12.309968709671717,
      14.301500045552695
    ],
    "volume": 34222528380
  },
  {
    "ts": 1550361600,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3042,
      "authorized": 3042,
      "funded": 2783
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 19,
    "traded_amount": 137411798,
    "price": [
      14.301500045552695,
      14.301536854020636,
      14.048780487804878,
      14.301500068457504
    ],
    "volume": 1751384212
  },
  {
    "ts": 1550448000,
    "supply": 5134320336966,
    "trustlines": {
      "total": 3048,
      "authorized": 3048,
      "funded": 2782
    },
    "payments": 5,
    "payments_amount": 48067300000,
    "trades": 23,
    "traded_amount": 52563254,
    "price": [
      14.301500068457504,
      14.301500068457504,
      12.29999995575416,
      12.29999995575416
    ],
    "volume": 507833399
  },
  {
    "ts": 1550534400,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3053,
      "authorized": 3053,
      "funded": 2780
    },
    "payments": 14,
    "payments_amount": 32061969674,
    "trades": 154,
    "traded_amount": 5023475053,
    "price": [
      12.29999995575416,
      13.554543370833848,
      11.159999896909216,
      12.5
    ],
    "volume": 58988142380
  },
  {
    "ts": 1550620800,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3057,
      "authorized": 3057,
      "funded": 2781
    },
    "payments": 5,
    "payments_amount": 13146143851,
    "trades": 53,
    "traded_amount": 282957048,
    "price": [
      12.5,
      12.555555555555555,
      11.499996793798168,
      11.500000013614676
    ],
    "volume": 3406533395
  },
  {
    "ts": 1550707200,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3059,
      "authorized": 3059,
      "funded": 2777
    },
    "payments": 10,
    "payments_amount": 1892372712206,
    "trades": 16,
    "traded_amount": 1163230735,
    "price": [
      11.500000013614676,
      12.445683117310558,
      11.099999969066689,
      11.099999969066689
    ],
    "volume": 11400052640
  },
  {
    "ts": 1550793600,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3063,
      "authorized": 3063,
      "funded": 2778
    },
    "payments": 7,
    "payments_amount": 118462570542,
    "trades": 4,
    "traded_amount": 38000000,
    "price": [
      11.099999969066689,
      12.5,
      11.099999969066689,
      12.384454
    ],
    "volume": 474768908
  },
  {
    "ts": 1550880000,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3068,
      "authorized": 3068,
      "funded": 2780
    },
    "payments": 2,
    "payments_amount": 2500200000,
    "trades": 96,
    "traded_amount": 1940060722,
    "price": [
      12.384454,
      12.384454029529687,
      11.671875,
      12.046824936296895
    ],
    "volume": 22185098060
  },
  {
    "ts": 1550966400,
    "supply": 5134320336961,
    "trustlines": {
      "total": 3088,
      "authorized": 3088,
      "funded": 2780
    },
    "payments": 8,
    "payments_amount": 14997526,
    "trades": 130,
    "traded_amount": 762498763,
    "price": [
      12.046824936296895,
      12.09458125695341,
      11.638919911098334,
      11.863717558906743
    ],
    "volume": 7062691114
  },
  {
    "ts": 1551052800,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3095,
      "authorized": 3095,
      "funded": 2781
    },
    "payments": 10,
    "payments_amount": 1677573604656,
    "trades": 4,
    "traded_amount": 140030560,
    "price": [
      11.863717558906743,
      12.384466666666667,
      11.110123428571429,
      11.110123428571429
    ],
    "volume": 778080174
  },
  {
    "ts": 1551139200,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3098,
      "authorized": 3098,
      "funded": 2782
    },
    "payments": 21,
    "payments_amount": 356264410628,
    "trades": 16,
    "traded_amount": 57537189,
    "price": [
      11.110123428571429,
      12.384463844638447,
      11.110121101211012,
      12.384454
    ],
    "volume": 708473645
  },
  {
    "ts": 1551225600,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3120,
      "authorized": 3120,
      "funded": 2784
    },
    "payments": 16,
    "payments_amount": 74594455260,
    "trades": 5,
    "traded_amount": 323698967,
    "price": [
      12.384454,
      12.500000002308894,
      12.384453053015367,
      12.385385385385385
    ],
    "volume": 4033856907
  },
  {
    "ts": 1551312000,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3122,
      "authorized": 3122,
      "funded": 2781
    },
    "payments": 31,
    "payments_amount": 2733644092522,
    "trades": 13,
    "traded_amount": 353523583,
    "price": [
      12.385385385385385,
      12.5,
      11.11011011011011,
      12.5
    ],
    "volume": 4289632031
  },
  {
    "ts": 1551398400,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3155,
      "authorized": 3155,
      "funded": 2783
    },
    "payments": 32,
    "payments_amount": 390643700000,
    "trades": 3,
    "traded_amount": 31952536,
    "price": [
      12.5,
      12.5,
      12.5,
      12.5
    ],
    "volume": 399406700
  },
  {
    "ts": 1551484800,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3152,
      "authorized": 3152,
      "funded": 2782
    },
    "payments": 1,
    "payments_amount": 5,
    "trades": 1,
    "traded_amount": 8890045,
    "price": [
      12.5,
      12.500000056242685,
      12.5,
      12.500000056242685
    ],
    "volume": 111125563
  },
  {
    "ts": 1551571200,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3161,
      "authorized": 3161,
      "funded": 2783
    },
    "payments": 1,
    "payments_amount": 52597402,
    "trades": 4,
    "traded_amount": 20108407,
    "price": [
      12.500000056242685,
      12.500000056242685,
      11.120007,
      12.5
    ],
    "volume": 233446551
  },
  {
    "ts": 1551657600,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3168,
      "authorized": 3168,
      "funded": 2782
    },
    "payments": 21,
    "payments_amount": 258264685260,
    "trades": 10,
    "traded_amount": 10607168381,
    "price": [
      12.5,
      13.718507998877826,
      12.5,
      13.718507994088064
    ],
    "volume": 137985142346
  },
  {
    "ts": 1551744000,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3177,
      "authorized": 3177,
      "funded": 2781
    },
    "payments": 12,
    "payments_amount": 84776600000,
    "trades": 6,
    "traded_amount": 612347434,
    "price": [
      13.718507994088064,
      13.718507994088064,
      11.12013032102287,
      13
    ],
    "volume": 8053438288
  },
  {
    "ts": 1551830400,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3213,
      "authorized": 3213,
      "funded": 2782
    },
    "payments": 9,
    "payments_amount": 66664200000,
    "trades": 9,
    "traded_amount": 71666636,
    "price": [
      13,
      13,
      11.51,
      12
    ],
    "volume": 731100316
  },
  {
    "ts": 1551916800,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3225,
      "authorized": 3225,
      "funded": 2783
    },
    "payments": 6,
    "payments_amount": 48128050666,
    "trades": 5,
    "traded_amount": 813369961,
    "price": [
      12,
      12,
      12,
      12
    ],
    "volume": 9760439532
  },
  {
    "ts": 1552003200,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3227,
      "authorized": 3227,
      "funded": 2782
    },
    "payments": 10,
    "payments_amount": 48313103720,
    "trades": 9,
    "traded_amount": 359398946,
    "price": [
      12,
      12.853470437017995,
      11.509999955999973,
      12.653533257250876
    ],
    "volume": 4480319399
  },
  {
    "ts": 1552089600,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3235,
      "authorized": 3235,
      "funded": 2785
    },
    "payments": 1,
    "payments_amount": 10000000000,
    "trades": 40,
    "traded_amount": 14505896223,
    "price": [
      12.653533257250876,
      12.65353327558888,
      10.5,
      12.517675605725373
    ],
    "volume": 56792931973
  },
  {
    "ts": 1552176000,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3239,
      "authorized": 3239,
      "funded": 2788
    },
    "payments": 7,
    "payments_amount": 25785943257,
    "trades": 137,
    "traded_amount": 5443715507,
    "price": [
      12.517675605725373,
      12.517675605725373,
      8.099999968456636,
      8.099999968456636
    ],
    "volume": 51994269888
  },
  {
    "ts": 1552262400,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3247,
      "authorized": 3247,
      "funded": 2788
    },
    "payments": 6,
    "payments_amount": 37498071981,
    "trades": 121,
    "traded_amount": 11517385726,
    "price": [
      8.099999968456636,
      10.516129032258064,
      8.099999968456636,
      9.174311927477103
    ],
    "volume": 103667804013
  },
  {
    "ts": 1552348800,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3250,
      "authorized": 3250,
      "funded": 2784
    },
    "payments": 8,
    "payments_amount": 51574661065,
    "trades": 14,
    "traded_amount": 8647765363,
    "price": [
      9.174311927477103,
      11,
      8.258194994522626,
      10.517723881397782
    ],
    "volume": 83114659637
  },
  {
    "ts": 1552435200,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3259,
      "authorized": 3259,
      "funded": 2785
    },
    "payments": 5,
    "payments_amount": 36595500135,
    "trades": 10,
    "traded_amount": 137971828,
    "price": [
      10.517723881397782,
      10.7526882,
      10.1995897,
      10.1995897
    ],
    "volume": 1463472373
  },
  {
    "ts": 1552521600,
    "supply": 6134330336961,
    "trustlines": {
      "total": 3267,
      "authorized": 3267,
      "funded": 2785
    },
    "payments": 8,
    "payments_amount": 3704972151,
    "trades": 55,
    "traded_amount": 957182085,
    "price": [
      10.1995897,
      10.310001058953452,
      9.799999896313796,
      10.310000042412888
    ],
    "volume": 6951023065
  },
  {
    "ts": 1552608000,
    "supply": 6134330336956,
    "trustlines": {
      "total": 3270,
      "authorized": 3270,
      "funded": 2782
    },
    "payments": 2,
    "payments_amount": 48500000005,
    "trades": 6,
    "traded_amount": 1093469695,
    "price": [
      10.310000042412888,
      10.310000042412888,
      9.5,
      9.5
    ],
    "volume": 10801551774
  },
  {
    "ts": 1552694400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3271,
      "authorized": 3271,
      "funded": 2781
    },
    "payments": 6,
    "payments_amount": 1019321500005,
    "trades": 1,
    "traded_amount": 114892862,
    "price": [
      9.5,
      9.5,
      9.5,
      9.5
    ],
    "volume": 1091482189
  },
  {
    "ts": 1552780800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3271,
      "authorized": 3271,
      "funded": 2781
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 885642605,
    "price": [
      9.5,
      9.500000950001425,
      9.1155,
      9.174217002237137
    ],
    "volume": 8413334244
  },
  {
    "ts": 1552867200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3275,
      "authorized": 3275,
      "funded": 2781
    },
    "payments": 5,
    "payments_amount": 300003467397,
    "trades": 24,
    "traded_amount": 16395827993,
    "price": [
      9.174217002237137,
      10.101010125832323,
      9.115770282169294,
      9.691190776719123
    ],
    "volume": 149706804766
  },
  {
    "ts": 1552953600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3284,
      "authorized": 3284,
      "funded": 2779
    },
    "payments": 13,
    "payments_amount": 9599612530,
    "trades": 59,
    "traded_amount": 1462626823,
    "price": [
      9.691190776719123,
      9.900990099943003,
      9.691190776719123,
      9.702806908710983
    ],
    "volume": 7468895377
  },
  {
    "ts": 1553040000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3294,
      "authorized": 3294,
      "funded": 2777
    },
    "payments": 4,
    "payments_amount": 31828064438,
    "trades": 6,
    "traded_amount": 743849135,
    "price": [
      9.702806908710983,
      9.800000005842637,
      9.109999710083303,
      9.110000186572265
    ],
    "volume": 3854693255
  },
  {
    "ts": 1553126400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3308,
      "authorized": 3308,
      "funded": 2776
    },
    "payments": 9,
    "payments_amount": 371942237487,
    "trades": 151,
    "traded_amount": 1889731563,
    "price": [
      9.110000186572265,
      9.900994650844561,
      9.090909090909092,
      9.900991348574818
    ],
    "volume": 18235821040
  },
  {
    "ts": 1553212800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3315,
      "authorized": 3315,
      "funded": 2776
    },
    "payments": 2,
    "payments_amount": 8918,
    "trades": 472,
    "traded_amount": 14416463834,
    "price": [
      9.900991348574818,
      10.1001001001001,
      9.1,
      10.1001001001001
    ],
    "volume": 142737052585
  },
  {
    "ts": 1553299200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3322,
      "authorized": 3322,
      "funded": 2774
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 256,
    "traded_amount": 4277886539,
    "price": [
      10.1001001001001,
      10.384615384615385,
      10.1001001001001,
      10.384615384615385
    ],
    "volume": 43247422567
  },
  {
    "ts": 1553385600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3339,
      "authorized": 3339,
      "funded": 2774
    },
    "payments": 1,
    "payments_amount": 253828265,
    "trades": 3,
    "traded_amount": 701788370,
    "price": [
      10.384615384615385,
      10.590000000883974,
      10.30999999440429,
      10.590000000883974
    ],
    "volume": 7396358341
  },
  {
    "ts": 1553472000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3364,
      "authorized": 3364,
      "funded": 2775
    },
    "payments": 8,
    "payments_amount": 500391037475,
    "trades": 16,
    "traded_amount": 5178778161,
    "price": [
      10.590000000883974,
      10.80000000144,
      9.110010000286525,
      9.700007867964137
    ],
    "volume": 49817663065
  },
  {
    "ts": 1553558400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3395,
      "authorized": 3395,
      "funded": 2775
    },
    "payments": 6,
    "payments_amount": 249344532780,
    "trades": 578,
    "traded_amount": 10543237661,
    "price": [
      9.700007867964137,
      9.75,
      9.110007994496812,
      9.75
    ],
    "volume": 102269350152
  },
  {
    "ts": 1553644800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3407,
      "authorized": 3407,
      "funded": 2776
    },
    "payments": 3,
    "payments_amount": 22325051324,
    "trades": 107,
    "traded_amount": 683187892,
    "price": [
      9.75,
      9.75,
      9.12,
      9.7000078
    ],
    "volume": 6621127856
  },
  {
    "ts": 1553731200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3425,
      "authorized": 3425,
      "funded": 2775
    },
    "payments": 17,
    "payments_amount": 177650283965,
    "trades": 59,
    "traded_amount": 7876783742,
    "price": [
      9.7000078,
      10.575184280948392,
      9.12,
      10.29
    ],
    "volume": 65591145659
  },
  {
    "ts": 1553817600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3450,
      "authorized": 3450,
      "funded": 2778
    },
    "payments": 13,
    "payments_amount": 84367781669,
    "trades": 144,
    "traded_amount": 8443234388,
    "price": [
      10.29,
      10.6,
      8.799999990251438,
      8.799999992239995
    ],
    "volume": 65447985943
  },
  {
    "ts": 1553904000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3470,
      "authorized": 3470,
      "funded": 2777
    },
    "payments": 1,
    "payments_amount": 2000000,
    "trades": 12,
    "traded_amount": 800878401,
    "price": [
      8.799999992239995,
      10.451110047420178,
      8.799999805543752,
      8.799999805543752
    ],
    "volume": 7986485895
  },
  {
    "ts": 1553990400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3500,
      "authorized": 3500,
      "funded": 2777
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 1000000,
    "price": [
      8.799999805543752,
      10,
      8.799999805543752,
      10
    ],
    "volume": 10000000
  },
  {
    "ts": 1554076800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3587,
      "authorized": 3587,
      "funded": 2776
    },
    "payments": 24,
    "payments_amount": 716099511547,
    "trades": 73,
    "traded_amount": 478044646,
    "price": [
      10,
      10.2,
      9.708333333333334,
      9.98860398860399
    ],
    "volume": 3058870667
  },
  {
    "ts": 1554163200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3604,
      "authorized": 3604,
      "funded": 2780
    },
    "payments": 37,
    "payments_amount": 302673908943,
    "trades": 106,
    "traded_amount": 2681537554,
    "price": [
      9.98860398860399,
      9.98860398860399,
      9.085774467240979,
      9.085774623677038
    ],
    "volume": 14951671707
  },
  {
    "ts": 1554249600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3622,
      "authorized": 3622,
      "funded": 2785
    },
    "payments": 95,
    "payments_amount": 685642427556,
    "trades": 777,
    "traded_amount": 17772897185,
    "price": [
      9.085774623677038,
      9.085782664965022,
      8.350001,
      8.69462365591398
    ],
    "volume": 50059828365
  },
  {
    "ts": 1554336000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3670,
      "authorized": 3670,
      "funded": 2784
    },
    "payments": 10,
    "payments_amount": 3070566401,
    "trades": 18,
    "traded_amount": 2837180808,
    "price": [
      8.69462365591398,
      9.450000519750029,
      8.100065605764897,
      9.450000519750029
    ],
    "volume": 25402826626
  },
  {
    "ts": 1554422400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3672,
      "authorized": 3672,
      "funded": 2782
    },
    "payments": 5,
    "payments_amount": 15266430867,
    "trades": 105,
    "traded_amount": 693016539,
    "price": [
      9.450000519750029,
      9.450000519750029,
      8.10003666788893,
      8.58958837772397
    ],
    "volume": 2818483761
  },
  {
    "ts": 1554508800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3686,
      "authorized": 3686,
      "funded": 2783
    },
    "payments": 9,
    "payments_amount": 3160407421,
    "trades": 78,
    "traded_amount": 5792131,
    "price": [
      8.58958837772397,
      8.827875205359343,
      8.511627906976743,
      8.735419630156473
    ],
    "volume": 34289254
  },
  {
    "ts": 1554595200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3714,
      "authorized": 3714,
      "funded": 2784
    },
    "payments": 1,
    "payments_amount": 115950919,
    "trades": 13,
    "traded_amount": 349878530,
    "price": [
      8.735419630156473,
      8.735419630156473,
      8.413238035143847,
      8.41553996234811
    ],
    "volume": 1999216336
  },
  {
    "ts": 1554681600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3719,
      "authorized": 3719,
      "funded": 2777
    },
    "payments": 40,
    "payments_amount": 264904260621,
    "trades": 103,
    "traded_amount": 15774255643,
    "price": [
      8.41553996234811,
      8.500000004439178,
      7.520112835229337,
      8.500000004439178
    ],
    "volume": 66445189944
  },
  {
    "ts": 1554768000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3722,
      "authorized": 3722,
      "funded": 2777
    },
    "payments": 19,
    "payments_amount": 307118337889,
    "trades": 21,
    "traded_amount": 524688433,
    "price": [
      8.500000004439178,
      8.8,
      7.5320112985242895,
      8.680000039953972
    ],
    "volume": 3591026728
  },
  {
    "ts": 1554854400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3726,
      "authorized": 3726,
      "funded": 2779
    },
    "payments": 6,
    "payments_amount": 46672968606,
    "trades": 18,
    "traded_amount": 4092954814,
    "price": [
      8.680000039953972,
      8.680000039953972,
      7.53206802289575,
      8.589999928156365
    ],
    "volume": 17164555304
  },
  {
    "ts": 1554940800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3735,
      "authorized": 3735,
      "funded": 2779
    },
    "payments": 16,
    "payments_amount": 33101153228,
    "trades": 113,
    "traded_amount": 32729910648,
    "price": [
      8.589999928156365,
      9.840000003200517,
      7.531999971400596,
      9.599654377880185
    ],
    "volume": 168626931410
  },
  {
    "ts": 1555027200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3739,
      "authorized": 3739,
      "funded": 2782
    },
    "payments": 4,
    "payments_amount": 201520985093,
    "trades": 8,
    "traded_amount": 1695697060,
    "price": [
      9.599654377880185,
      9.64000001599967,
      9.31999999966448,
      9.639
    ],
    "volume": 16002657974
  },
  {
    "ts": 1555113600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3743,
      "authorized": 3743,
      "funded": 2783
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 50004164,
    "price": [
      9.639,
      9.639,
      9.598463016330452,
      9.598463016330452
    ],
    "volume": 481989971
  },
  {
    "ts": 1555200000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3745,
      "authorized": 3745,
      "funded": 2782
    },
    "payments": 1,
    "payments_amount": 6,
    "trades": 5,
    "traded_amount": 512504010,
    "price": [
      9.598463016330452,
      9.598463016330452,
      8.8,
      8.8
    ],
    "volume": 4709667322
  },
  {
    "ts": 1555286400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3753,
      "authorized": 3753,
      "funded": 2782
    },
    "payments": 2,
    "payments_amount": 14107610000,
    "trades": 5,
    "traded_amount": 62368130,
    "price": [
      8.8,
      9.49999620000152,
      8.8,
      9.400821666943786
    ],
    "volume": 293260256
  },
  {
    "ts": 1555372800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3756,
      "authorized": 3756,
      "funded": 2782
    },
    "payments": 2,
    "payments_amount": 62359612903,
    "trades": 11,
    "traded_amount": 1930100146,
    "price": [
      9.400821666943786,
      9.699998448000247,
      9.400821666943786,
      9.699998448000247
    ],
    "volume": 16655100303
  },
  {
    "ts": 1555459200,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3768,
      "authorized": 3768,
      "funded": 2785
    },
    "payments": 10,
    "payments_amount": 50225770952,
    "trades": 14,
    "traded_amount": 1847790376,
    "price": [
      9.699998448000247,
      9.700000015369914,
      8.800077394181288,
      8.800154811268904
    ],
    "volume": 7025320380
  },
  {
    "ts": 1555545600,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3793,
      "authorized": 3793,
      "funded": 2785
    },
    "payments": 18,
    "payments_amount": 43003312242,
    "trades": 131,
    "traded_amount": 2325782269,
    "price": [
      8.800154811268904,
      9.561582,
      8.800154811268904,
      9.433961893244154
    ],
    "volume": 13309291535
  },
  {
    "ts": 1555632000,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3915,
      "authorized": 3915,
      "funded": 2785
    },
    "payments": 6,
    "payments_amount": 10517630366,
    "trades": 224,
    "traded_amount": 19652712653,
    "price": [
      9.433961893244154,
      9.771468303107756,
      8.606060606060606,
      9.758620689655173
    ],
    "volume": 157482897491
  },
  {
    "ts": 1555718400,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3919,
      "authorized": 3919,
      "funded": 2784
    },
    "payments": 2,
    "payments_amount": 11747634,
    "trades": 140,
    "traded_amount": 471216283,
    "price": [
      9.758620689655173,
      9.758620689655173,
      9.09091008264553,
      9.090991741550772
    ],
    "volume": 3733251885
  },
  {
    "ts": 1555804800,
    "supply": 7134330336956,
    "trustlines": {
      "total": 3927,
      "authorized": 3927,
      "funded": 2781
    },
    "payments": 3,
    "payments_amount": 172622591,
    "trades": 33,
    "traded_amount": 6907661949,
    "price": [
      9.090991741550772,
      10.078065907258095,
      8.7999999992,
      8.7999999992
    ],
    "volume": 59703689442
  },
  {
    "ts": 1555891200,
    "supply": 7134330336951,
    "trustlines": {
      "total": 3930,
      "authorized": 3930,
      "funded": 2779
    },
    "payments": 11,
    "payments_amount": 5281387414,
    "trades": 297,
    "traded_amount": 8324450751,
    "price": [
      8.7999999992,
      10.060362228476396,
      8.7999999992,
      9.000087145969498
    ],
    "volume": 57665671061
  },
  {
    "ts": 1555977600,
    "supply": 7134330336951,
    "trustlines": {
      "total": 3952,
      "authorized": 3952,
      "funded": 2780
    },
    "payments": 7,
    "payments_amount": 2318231767,
    "trades": 130,
    "traded_amount": 4486434167,
    "price": [
      9.000087145969498,
      9.890005818855467,
      8.099999941104397,
      9.890000041538006
    ],
    "volume": 39020406457
  },
  {
    "ts": 1556064000,
    "supply": 7134330336951,
    "trustlines": {
      "total": 3961,
      "authorized": 3961,
      "funded": 2782
    },
    "payments": 10,
    "payments_amount": 57147098208,
    "trades": 95,
    "traded_amount": 9850932147,
    "price": [
      9.890000041538006,
      11.016856,
      9.091074253932907,
      10.677248677248677
    ],
    "volume": 96037841272
  },
  {
    "ts": 1556150400,
    "supply": 7134330336951,
    "trustlines": {
      "total": 3976,
      "authorized": 3976,
      "funded": 2785
    },
    "payments": 21,
    "payments_amount": 96434176563,
    "trades": 364,
    "traded_amount": 5385695441,
    "price": [
      10.677248677248677,
      10.879133,
      9.100999862810854,
      9.101
    ],
    "volume": 50222008752
  },
  {
    "ts": 1556236800,
    "supply": 7134330336951,
    "trustlines": {
      "total": 3999,
      "authorized": 3999,
      "funded": 2784
    },
    "payments": 10,
    "payments_amount": 67293535324,
    "trades": 13,
    "traded_amount": 2039872949,
    "price": [
      9.101,
      11.016856001553636,
      9.101,
      9.102
    ],
    "volume": 18787784109
  },
  {
    "ts": 1556323200,
    "supply": 7134330336951,
    "trustlines": {
      "total": 4026,
      "authorized": 4026,
      "funded": 2785
    },
    "payments": 1,
    "payments_amount": 10251,
    "trades": 11,
    "traded_amount": 962678379,
    "price": [
      9.102,
      11.016856002872595,
      9.102,
      11.016856002872595
    ],
    "volume": 6482202008
  },
  {
    "ts": 1556409600,
    "supply": 7134330336951,
    "trustlines": {
      "total": 4071,
      "authorized": 4071,
      "funded": 2785
    },
    "payments": 2,
    "payments_amount": 10549380,
    "trades": 5,
    "traded_amount": 186157206,
    "price": [
      11.016856002872595,
      11.016856002872595,
      9.10298882376497,
      9.10298882376497
    ],
    "volume": 1919884750
  },
  {
    "ts": 1556496000,
    "supply": 7134330336951,
    "trustlines": {
      "total": 4092,
      "authorized": 4092,
      "funded": 2786
    },
    "payments": 14,
    "payments_amount": 89956300010,
    "trades": 29,
    "traded_amount": 3345751038,
    "price": [
      9.10298882376497,
      11.111111137037039,
      9.10298882376497,
      11.111111137037039
    ],
    "volume": 37122614309
  },
  {
    "ts": 1556582400,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4118,
      "authorized": 4118,
      "funded": 2785
    },
    "payments": 13,
    "payments_amount": 529609650620,
    "trades": 8,
    "traded_amount": 265096607,
    "price": [
      11.111111137037039,
      11.1111112,
      10.65,
      10.65
    ],
    "volume": 1019744690
  },
  {
    "ts": 1556668800,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4167,
      "authorized": 4167,
      "funded": 2787
    },
    "payments": 17,
    "payments_amount": 224419985713,
    "trades": 8,
    "traded_amount": 773855809,
    "price": [
      10.65,
      11.111111112864071,
      9.21,
      11.111111112864071
    ],
    "volume": 8399467115
  },
  {
    "ts": 1556755200,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4216,
      "authorized": 4216,
      "funded": 2786
    },
    "payments": 37,
    "payments_amount": 296816904709,
    "trades": 108,
    "traded_amount": 32144174541,
    "price": [
      11.111111112864071,
      11.156701030927834,
      9.20999977994452,
      10.19732441471572
    ],
    "volume": 213448389558
  },
  {
    "ts": 1556841600,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4271,
      "authorized": 4271,
      "funded": 2786
    },
    "payments": 11,
    "payments_amount": 137331500000,
    "trades": 7,
    "traded_amount": 120517246,
    "price": [
      10.19732441471572,
      10.99999828888885,
      10.19732441471572,
      10.199999991441011
    ],
    "volume": 1044996932
  },
  {
    "ts": 1556928000,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4312,
      "authorized": 4312,
      "funded": 2783
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 7,
    "traded_amount": 140315865,
    "price": [
      10.199999991441011,
      11.110120904169671,
      9.69999998750279,
      11.110120904169671
    ],
    "volume": 1468502354
  },
  {
    "ts": 1557014400,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4344,
      "authorized": 4344,
      "funded": 2785
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 11,
    "traded_amount": 3068396659,
    "price": [
      11.110120904169671,
      11.110120904169671,
      9.249999981881636,
      11.101208004301531
    ],
    "volume": 16970242130
  },
  {
    "ts": 1557100800,
    "supply": 7134160336951,
    "trustlines": {
      "total": 4465,
      "authorized": 4465,
      "funded": 2784
    },
    "payments": 10,
    "payments_amount": 9571995329,
    "trades": 135,
    "traded_amount": 14642744426,
    "price": [
      11.101208004301531,
      11.51436981365391,
      9.245454545454546,
      11.334057708559701
    ],
    "volume": 114809732509
  },
  {
    "ts": 1557187200,
    "supply": 7500000336951,
    "trustlines": {
      "total": 4492,
      "authorized": 4492,
      "funded": 2785
    },
    "payments": 32,
    "payments_amount": 694981576182,
    "trades": 92,
    "traded_amount": 11310855971,
    "price": [
      11.334057708559701,
      11.973684210526315,
      9.67816091954023,
      11.97
    ],
    "volume": 58789255700
  },
  {
    "ts": 1557273600,
    "supply": 7500000336951,
    "trustlines": {
      "total": 4493,
      "authorized": 4493,
      "funded": 2784
    },
    "payments": 7,
    "payments_amount": 194697299329,
    "trades": 30,
    "traded_amount": 1856196481,
    "price": [
      11.97,
      11.97,
      9.71,
      11.962280574495866
    ],
    "volume": 11330621525
  },
  {
    "ts": 1557360000,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4529,
      "authorized": 4529,
      "funded": 2791
    },
    "payments": 34,
    "payments_amount": 592252246716,
    "trades": 199,
    "traded_amount": 18533157285,
    "price": [
      11.962280574495866,
      12,
      9.705882352941176,
      9.709998264843174
    ],
    "volume": 115213012765
  },
  {
    "ts": 1557446400,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4617,
      "authorized": 4617,
      "funded": 2790
    },
    "payments": 29,
    "payments_amount": 38519497876,
    "trades": 217,
    "traded_amount": 11681800566,
    "price": [
      9.709998264843174,
      11.90065600712962,
      9.707388441843452,
      11.90065600377515
    ],
    "volume": 29653042584
  },
  {
    "ts": 1557532800,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4691,
      "authorized": 4691,
      "funded": 2789
    },
    "payments": 55,
    "payments_amount": 1864490191,
    "trades": 236,
    "traded_amount": 16763749514,
    "price": [
      11.90065600377515,
      11.90065600377515,
      9.802749989793751,
      9.802749989793751
    ],
    "volume": 1549646708
  },
  {
    "ts": 1557619200,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4818,
      "authorized": 4818,
      "funded": 2788
    },
    "payments": 17,
    "payments_amount": 9812774,
    "trades": 30,
    "traded_amount": 567718834,
    "price": [
      9.802749989793751,
      10.975000206722619,
      9.802749989793751,
      10.975000206722619
    ],
    "volume": 3034415471
  },
  {
    "ts": 1557705600,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4827,
      "authorized": 4827,
      "funded": 2789
    },
    "payments": 9,
    "payments_amount": 38809639838,
    "trades": 148,
    "traded_amount": 912275966,
    "price": [
      10.975000206722619,
      11.15629290617849,
      10.498796073346917,
      10.878542510121457
    ],
    "volume": 3416395982
  },
  {
    "ts": 1557792000,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4835,
      "authorized": 4835,
      "funded": 2793
    },
    "payments": 16,
    "payments_amount": 105176761487,
    "trades": 70,
    "traded_amount": 15645262018,
    "price": [
      10.878542510121457,
      10.99576164682226,
      8.771929825092158,
      8.999594155844155
    ],
    "volume": 77390520113
  },
  {
    "ts": 1557878400,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4849,
      "authorized": 4849,
      "funded": 2791
    },
    "payments": 26,
    "payments_amount": 9868907158,
    "trades": 186,
    "traded_amount": 12058582582,
    "price": [
      8.999594155844155,
      9.111557812199186,
      7.833333333333333,
      7.935896870080933
    ],
    "volume": 56645999709
  },
  {
    "ts": 1557964800,
    "supply": 8000000336951,
    "trustlines": {
      "total": 4860,
      "authorized": 4860,
      "funded": 2793
    },
    "payments": 70,
    "payments_amount": 44050293098,
    "trades": 848,
    "traded_amount": 88178917108,
    "price": [
      7.935896870080933,
      8.36884175327449,
      6.664434261543884,
      8.08165548098434
    ],
    "volume": 331317186486
  },
  {
    "ts": 1558051200,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4860,
      "authorized": 4860,
      "funded": 2791
    },
    "payments": 44,
    "payments_amount": 77624861732,
    "trades": 172,
    "traded_amount": 27694436718,
    "price": [
      8.08165548098434,
      9.227513227513228,
      7.1429999999907885,
      8.846543115916903
    ],
    "volume": 122260110133
  },
  {
    "ts": 1558137600,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4867,
      "authorized": 4867,
      "funded": 2792
    },
    "payments": 25,
    "payments_amount": 473386151,
    "trades": 190,
    "traded_amount": 4776163735,
    "price": [
      8.846543115916903,
      8.846543115916903,
      8.068965517241379,
      8.437768240343347
    ],
    "volume": 8116322029
  },
  {
    "ts": 1558224000,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4873,
      "authorized": 4873,
      "funded": 2793
    },
    "payments": 25,
    "payments_amount": 102758133,
    "trades": 294,
    "traded_amount": 12305404407,
    "price": [
      8.437768240343347,
      8.437768240343347,
      7.143051012924707,
      7.846153846153846
    ],
    "volume": 42675455571
  },
  {
    "ts": 1558310400,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4891,
      "authorized": 4891,
      "funded": 2794
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 24,
    "traded_amount": 927701043,
    "price": [
      7.846153846153846,
      8,
      7.6701,
      7.670147826086956
    ],
    "volume": 4016496973
  },
  {
    "ts": 1558396800,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4894,
      "authorized": 4894,
      "funded": 2794
    },
    "payments": 2,
    "payments_amount": 39973000000,
    "trades": 4,
    "traded_amount": 19999967,
    "price": [
      7.670147826086956,
      8.3,
      7.670147826086956,
      8.3
    ],
    "volume": 82558211
  },
  {
    "ts": 1558483200,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4896,
      "authorized": 4896,
      "funded": 2794
    },
    "payments": 7,
    "payments_amount": 4526130847,
    "trades": 81,
    "traded_amount": 39167295,
    "price": [
      8.3,
      8.579278122235863,
      8.297156669841106,
      8.518430439952438
    ],
    "volume": 235359468
  },
  {
    "ts": 1558569600,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4902,
      "authorized": 4902,
      "funded": 2797
    },
    "payments": 2,
    "payments_amount": 25020036235,
    "trades": 151,
    "traded_amount": 1971144689,
    "price": [
      8.518430439952438,
      9.099868324905339,
      8.0963,
      8.0963
    ],
    "volume": 11331420824
  },
  {
    "ts": 1558656000,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4908,
      "authorized": 4908,
      "funded": 2797
    },
    "payments": 33,
    "payments_amount": 39710788554,
    "trades": 203,
    "traded_amount": 287706751,
    "price": [
      8.0963,
      8.882716049382717,
      8.0963,
      8.880733944954128
    ],
    "volume": 1660735851
  },
  {
    "ts": 1558742400,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4917,
      "authorized": 4917,
      "funded": 2798
    },
    "payments": 1,
    "payments_amount": 1257444411,
    "trades": 3,
    "traded_amount": 22156980,
    "price": [
      8.880733944954128,
      8.880733944954128,
      8.643230505247013,
      8.869945306139972
    ],
    "volume": 147031934
  },
  {
    "ts": 1558828800,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4920,
      "authorized": 4920,
      "funded": 2797
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 3,
    "traded_amount": 56131732,
    "price": [
      8.869945306139972,
      8.873838720299595,
      8.869945196857906,
      8.873838720299595
    ],
    "volume": 249023239
  },
  {
    "ts": 1558915200,
    "supply": 8000000336946,
    "trustlines": {
      "total": 4948,
      "authorized": 4948,
      "funded": 2798
    },
    "payments": 4,
    "payments_amount": 31154417262,
    "trades": 8,
    "traded_amount": 339434382,
    "price": [
      8.873838720299595,
      8.873838720299595,
      7.760680604812979,
      7.760740788194518
    ],
    "volume": 2132005574
  },
  {
    "ts": 1559001600,
    "supply": 8100000336946,
    "trustlines": {
      "total": 4965,
      "authorized": 4965,
      "funded": 2797
    },
    "payments": 19,
    "payments_amount": 232345409390,
    "trades": 82,
    "traded_amount": 376866842,
    "price": [
      7.760740788194518,
      8.384953736855326,
      7.760740788194518,
      8.0941272683127
    ],
    "volume": 1915918820
  },
  {
    "ts": 1559088000,
    "supply": 8100000336946,
    "trustlines": {
      "total": 4984,
      "authorized": 4984,
      "funded": 2799
    },
    "payments": 10,
    "payments_amount": 1087546781330,
    "trades": 8,
    "traded_amount": 1365689527,
    "price": [
      8.0941272683127,
      8.333082063051785,
      7.7607165621420195,
      7.7607165621420195
    ],
    "volume": 5421859663
  },
  {
    "ts": 1559174400,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5025,
      "authorized": 5025,
      "funded": 2798
    },
    "payments": 2,
    "payments_amount": 128679,
    "trades": 14,
    "traded_amount": 697839032,
    "price": [
      7.7607165621420195,
      8.206518181393102,
      7.7607165621420195,
      8.206518181393102
    ],
    "volume": 3096319537
  },
  {
    "ts": 1559260800,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5047,
      "authorized": 5047,
      "funded": 2800
    },
    "payments": 9,
    "payments_amount": 262165539,
    "trades": 28,
    "traded_amount": 980643230,
    "price": [
      8.206518181393102,
      8.624181780549016,
      6.666666661657005,
      6.666666661657005
    ],
    "volume": 5019390428
  },
  {
    "ts": 1559347200,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5053,
      "authorized": 5053,
      "funded": 2800
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      6.666666661657005,
      6.666666661657005,
      6.666666661657005,
      6.666666661657005
    ],
    "volume": 0
  },
  {
    "ts": 1559433600,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5061,
      "authorized": 5061,
      "funded": 2800
    },
    "payments": 2,
    "payments_amount": 20243767153,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      6.666666661657005,
      6.666666661657005,
      6.666666661657005,
      6.666666661657005
    ],
    "volume": 0
  },
  {
    "ts": 1559520000,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5071,
      "authorized": 5071,
      "funded": 2800
    },
    "payments": 57,
    "payments_amount": 1605008647828,
    "trades": 70,
    "traded_amount": 38676280,
    "price": [
      6.666666661657005,
      8.46684855255342,
      6.666666661657005,
      8.270886075949367
    ],
    "volume": 233382877
  },
  {
    "ts": 1559606400,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5075,
      "authorized": 5075,
      "funded": 2799
    },
    "payments": 17,
    "payments_amount": 1189406764202,
    "trades": 1,
    "traded_amount": 5,
    "price": [
      8.270886075949367,
      8.270886075949367,
      8.270886075949367,
      8.270886075949367
    ],
    "volume": 40
  },
  {
    "ts": 1559692800,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5087,
      "authorized": 5087,
      "funded": 2800
    },
    "payments": 16,
    "payments_amount": 307368388785,
    "trades": 16,
    "traded_amount": 1612105855,
    "price": [
      8.270886075949367,
      9,
      8.270886075949367,
      9
    ],
    "volume": 8282719408
  },
  {
    "ts": 1559779200,
    "supply": 8100000336946,
    "trustlines": {
      "total": 5095,
      "authorized": 5095,
      "funded": 2800
    },
    "payments": 4,
    "payments_amount": 80286800000,
    "trades": 2,
    "traded_amount": 1268232938,
    "price": [
      9,
      9,
      8.72752920094873,
      8.72752920094873
    ],
    "volume": 5534270000
  },
  {
    "ts": 1559865600,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5095,
      "authorized": 5095,
      "funded": 2799
    },
    "payments": 6,
    "payments_amount": 36196821258,
    "trades": 4,
    "traded_amount": 225169653,
    "price": [
      8.72752920094873,
      8.72752920094873,
      7.114516006073932,
      7.114577777777778
    ],
    "volume": 800991001
  },
  {
    "ts": 1559952000,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5101,
      "authorized": 5101,
      "funded": 2799
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.114577777777778,
      7.114577777777778,
      7.114577777777778,
      7.114577777777778
    ],
    "volume": 0
  },
  {
    "ts": 1560038400,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5106,
      "authorized": 5106,
      "funded": 2799
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.114577777777778,
      7.114577777777778,
      7.114577777777778,
      7.114577777777778
    ],
    "volume": 0
  },
  {
    "ts": 1560124800,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5108,
      "authorized": 5108,
      "funded": 2799
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.114577777777778,
      7.114577777777778,
      7.114577777777778,
      7.114577777777778
    ],
    "volume": 0
  },
  {
    "ts": 1560211200,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5117,
      "authorized": 5117,
      "funded": 2797
    },
    "payments": 5,
    "payments_amount": 25639200005,
    "trades": 14,
    "traded_amount": 1059891306,
    "price": [
      7.114577777777778,
      9.194992368925206,
      7.114516,
      7.7
    ],
    "volume": 7491802002
  },
  {
    "ts": 1560297600,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5126,
      "authorized": 5126,
      "funded": 2798
    },
    "payments": 19,
    "payments_amount": 1087846254409,
    "trades": 451,
    "traded_amount": 2097246155,
    "price": [
      7.7,
      9.11614423982629,
      7.7,
      8.962264150943396
    ],
    "volume": 7452061919
  },
  {
    "ts": 1560384000,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5128,
      "authorized": 5128,
      "funded": 2798
    },
    "payments": 3,
    "payments_amount": 35189896,
    "trades": 5,
    "traded_amount": 103270801,
    "price": [
      8.962264150943396,
      8.962264150943396,
      8.31853983581921,
      8.900000021282471
    ],
    "volume": 585458479
  },
  {
    "ts": 1560470400,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5139,
      "authorized": 5139,
      "funded": 2798
    },
    "payments": 8,
    "payments_amount": 46084683194,
    "trades": 30,
    "traded_amount": 3111140304,
    "price": [
      8.900000021282471,
      9.119918568583534,
      8.899898887765419,
      9.10002609376359
    ],
    "volume": 5102860733
  },
  {
    "ts": 1560556800,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5159,
      "authorized": 5159,
      "funded": 2797
    },
    "payments": 5,
    "payments_amount": 612965882,
    "trades": 8,
    "traded_amount": 1456450175,
    "price": [
      9.10002609376359,
      9.10002609376359,
      8.620689655172415,
      8.7672155078396
    ],
    "volume": 7296300927
  },
  {
    "ts": 1560643200,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5169,
      "authorized": 5169,
      "funded": 2797
    },
    "payments": 3,
    "payments_amount": 9275762251,
    "trades": 11,
    "traded_amount": 991466128,
    "price": [
      8.7672155078396,
      8.7672155078396,
      8.502186701486455,
      8.502186701486455
    ],
    "volume": 3714708271
  },
  {
    "ts": 1560729600,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5178,
      "authorized": 5178,
      "funded": 2795
    },
    "payments": 7,
    "payments_amount": 32548257822,
    "trades": 7,
    "traded_amount": 68857827,
    "price": [
      8.502186701486455,
      8.725302014479894,
      8.331689262124131,
      8.331689262124131
    ],
    "volume": 288599901
  },
  {
    "ts": 1560816000,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5196,
      "authorized": 5196,
      "funded": 2796
    },
    "payments": 2,
    "payments_amount": 23530000000,
    "trades": 11,
    "traded_amount": 781041509,
    "price": [
      8.331689262124131,
      8.338461538461539,
      8.3146919,
      8.3146919
    ],
    "volume": 3501537400
  },
  {
    "ts": 1560902400,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5203,
      "authorized": 5203,
      "funded": 2796
    },
    "payments": 4,
    "payments_amount": 38296300000,
    "trades": 3,
    "traded_amount": 29558656,
    "price": [
      8.3146919,
      8.330635470417757,
      8.314691837873873,
      8.330635470417757
    ],
    "volume": 122886009
  },
  {
    "ts": 1560988800,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5212,
      "authorized": 5212,
      "funded": 2794
    },
    "payments": 8,
    "payments_amount": 280568869,
    "trades": 12,
    "traded_amount": 336336639,
    "price": [
      8.330635470417757,
      9.036525651497731,
      8.199090665908889,
      8.199090740154354
    ],
    "volume": 1742653729
  },
  {
    "ts": 1561075200,
    "supply": 8100000336937,
    "trustlines": {
      "total": 5227,
      "authorized": 5227,
      "funded": 2795
    },
    "payments": 3,
    "payments_amount": 1555615,
    "trades": 14,
    "traded_amount": 22667202,
    "price": [
      8.199090740154354,
      9.039000073617638,
      8.199090740154354,
      9.039000073617638
    ],
    "volume": 144159033
  },
  {
    "ts": 1561161600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5233,
      "authorized": 5233,
      "funded": 2793
    },
    "payments": 9,
    "payments_amount": 20662415975,
    "trades": 23,
    "traded_amount": 1193134272,
    "price": [
      9.039000073617638,
      9.039000073617638,
      8.684535486402213,
      8.684535486402213
    ],
    "volume": 2467964713
  },
  {
    "ts": 1561248000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5260,
      "authorized": 5260,
      "funded": 2794
    },
    "payments": 2,
    "payments_amount": 3483382,
    "trades": 76,
    "traded_amount": 763157646,
    "price": [
      8.684535486402213,
      8.800799467021985,
      8.294947584320875,
      8.561643835616438
    ],
    "volume": 896541675
  },
  {
    "ts": 1561334400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5282,
      "authorized": 5282,
      "funded": 2794
    },
    "payments": 4,
    "payments_amount": 13182051380,
    "trades": 6,
    "traded_amount": 1314563,
    "price": [
      8.561643835616438,
      8.561643835616438,
      7.11451,
      7.11451
    ],
    "volume": 711451
  },
  {
    "ts": 1561420800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5344,
      "authorized": 5344,
      "funded": 2795
    },
    "payments": 9,
    "payments_amount": 95024477000,
    "trades": 11,
    "traded_amount": 744341442,
    "price": [
      7.11451,
      8.895655524605385,
      7.11451,
      8.895655524605385
    ],
    "volume": 9580621
  },
  {
    "ts": 1561507200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5404,
      "authorized": 5404,
      "funded": 2795
    },
    "payments": 12,
    "payments_amount": 443123189,
    "trades": 137,
    "traded_amount": 2918994928,
    "price": [
      8.895655524605385,
      8.895655524605385,
      8.426997245179063,
      8.694581280788178
    ],
    "volume": 10538676294
  },
  {
    "ts": 1561593600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5428,
      "authorized": 5428,
      "funded": 2791
    },
    "payments": 5,
    "payments_amount": 1120241,
    "trades": 52,
    "traded_amount": 2981375747,
    "price": [
      8.694581280788178,
      9.450000003715745,
      7.3,
      7.30005858522656
    ],
    "volume": 15713406296
  },
  {
    "ts": 1561680000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5461,
      "authorized": 5461,
      "funded": 2791
    },
    "payments": 25,
    "payments_amount": 683454877110,
    "trades": 3,
    "traded_amount": 2152378,
    "price": [
      7.30005858522656,
      9.300000558000423,
      7.30005858522656,
      8.300027855153203
    ],
    "volume": 18940032
  },
  {
    "ts": 1561766400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5489,
      "authorized": 5489,
      "funded": 2793
    },
    "payments": 10,
    "payments_amount": 215706917,
    "trades": 53,
    "traded_amount": 10742131953,
    "price": [
      8.300027855153203,
      10.169014084507042,
      7.260869565217392,
      7.400051208354362
    ],
    "volume": 87024905694
  },
  {
    "ts": 1561852800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 5529,
      "authorized": 5529,
      "funded": 2794
    },
    "payments": 8,
    "payments_amount": 4830557,
    "trades": 110,
    "traded_amount": 298737514,
    "price": [
      7.400051208354362,
      10.145136909170226,
      7.400042409150033,
      10.145136909170226
    ],
    "volume": 2007952675
  },
  {
    "ts": 1561939200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 6744,
      "authorized": 6744,
      "funded": 2793
    },
    "payments": 20,
    "payments_amount": 1560085075440,
    "trades": 16,
    "traded_amount": 304678912,
    "price": [
      10.145136909170226,
      10.388903560643465,
      10.145136909170226,
      10.388903326403327
    ],
    "volume": 1548631918
  },
  {
    "ts": 1562025600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 7919,
      "authorized": 7919,
      "funded": 2793
    },
    "payments": 15,
    "payments_amount": 401015400000,
    "trades": 1,
    "traded_amount": 169952978,
    "price": [
      10.388903326403327,
      10.388903326403327,
      10.388903326403327,
      10.388903326403327
    ],
    "volume": 0
  },
  {
    "ts": 1562112000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 8589,
      "authorized": 8589,
      "funded": 2793
    },
    "payments": 16,
    "payments_amount": 151055917101,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.388903326403327,
      10.388903326403327,
      10.388903326403327,
      10.388903326403327
    ],
    "volume": 0
  },
  {
    "ts": 1562198400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9040,
      "authorized": 9040,
      "funded": 2793
    },
    "payments": 12,
    "payments_amount": 100079700000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.388903326403327,
      10.388903326403327,
      10.388903326403327,
      10.388903326403327
    ],
    "volume": 0
  },
  {
    "ts": 1562284800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9317,
      "authorized": 9317,
      "funded": 2794
    },
    "payments": 10,
    "payments_amount": 1147913129577,
    "trades": 7,
    "traded_amount": 9857130,
    "price": [
      10.388903326403327,
      10.499999475000026,
      10.388903326403327,
      10.499969998799951
    ],
    "volume": 103499850
  },
  {
    "ts": 1562371200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9420,
      "authorized": 9420,
      "funded": 2794
    },
    "payments": 6,
    "payments_amount": 9371806,
    "trades": 66,
    "traded_amount": 192972148,
    "price": [
      10.499969998799951,
      10.706994319892207,
      10.49711188433757,
      10.552420327304048
    ],
    "volume": 1620784718
  },
  {
    "ts": 1562457600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9499,
      "authorized": 9499,
      "funded": 2794
    },
    "payments": 1,
    "payments_amount": 778648686,
    "trades": 10,
    "traded_amount": 74944,
    "price": [
      10.552420327304048,
      10.735912129894938,
      10.552420327304048,
      10.608953957139827
    ],
    "volume": 400000
  },
  {
    "ts": 1562544000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9610,
      "authorized": 9610,
      "funded": 2794
    },
    "payments": 6,
    "payments_amount": 67751552151,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      10.608953957139827,
      10.608953957139827,
      10.608953957139827,
      10.608953957139827
    ],
    "volume": 0
  },
  {
    "ts": 1562630400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9704,
      "authorized": 9704,
      "funded": 2794
    },
    "payments": 6,
    "payments_amount": 70836500000,
    "trades": 5,
    "traded_amount": 76608026,
    "price": [
      10.608953957139827,
      10.795548297988173,
      10.608953957139827,
      10.795548297988173
    ],
    "volume": 413470188
  },
  {
    "ts": 1562716800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9771,
      "authorized": 9771,
      "funded": 2795
    },
    "payments": 7,
    "payments_amount": 1547372629853,
    "trades": 3,
    "traded_amount": 17012667,
    "price": [
      10.795548297988173,
      11,
      10.795548297988173,
      11
    ],
    "volume": 154270875
  },
  {
    "ts": 1562803200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9806,
      "authorized": 9806,
      "funded": 2792
    },
    "payments": 6,
    "payments_amount": 82423604987,
    "trades": 1,
    "traded_amount": 98569,
    "price": [
      11,
      11,
      7.4799987825787015,
      7.4799987825787015
    ],
    "volume": 737296
  },
  {
    "ts": 1562889600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9827,
      "authorized": 9827,
      "funded": 2792
    },
    "payments": 7,
    "payments_amount": 35944877271,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.4799987825787015,
      7.4799987825787015,
      7.4799987825787015,
      7.4799987825787015
    ],
    "volume": 0
  },
  {
    "ts": 1562976000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9918,
      "authorized": 9918,
      "funded": 2792
    },
    "payments": 1,
    "payments_amount": 34760,
    "trades": 1,
    "traded_amount": 34760,
    "price": [
      7.4799987825787015,
      7.4799987825787015,
      7.479861910241657,
      7.479861910241657
    ],
    "volume": 260000
  },
  {
    "ts": 1563062400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9969,
      "authorized": 9969,
      "funded": 2792
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.479861910241657,
      7.479861910241657,
      7.479861910241657,
      7.479861910241657
    ],
    "volume": 0
  },
  {
    "ts": 1563148800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9983,
      "authorized": 9983,
      "funded": 2792
    },
    "payments": 1,
    "payments_amount": 4500000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.479861910241657,
      7.479861910241657,
      7.479861910241657,
      7.479861910241657
    ],
    "volume": 0
  },
  {
    "ts": 1563235200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 9997,
      "authorized": 9997,
      "funded": 2792
    },
    "payments": 7,
    "payments_amount": 202084500000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      7.479861910241657,
      7.479861910241657,
      7.479861910241657,
      7.479861910241657
    ],
    "volume": 0
  },
  {
    "ts": 1563321600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10005,
      "authorized": 10005,
      "funded": 2793
    },
    "payments": 10,
    "payments_amount": 1044297417823,
    "trades": 11,
    "traded_amount": 58824730,
    "price": [
      7.479861910241657,
      12.4975,
      7.479861910241657,
      12.497154667867472
    ],
    "volume": 638193669
  },
  {
    "ts": 1563408000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10018,
      "authorized": 10018,
      "funded": 2795
    },
    "payments": 5,
    "payments_amount": 38083786,
    "trades": 16,
    "traded_amount": 74335606,
    "price": [
      12.497154667867472,
      12.600007693885924,
      7.480073859098088,
      7.48013
    ],
    "volume": 438629087
  },
  {
    "ts": 1563494400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10026,
      "authorized": 10026,
      "funded": 2797
    },
    "payments": 4,
    "payments_amount": 7459182058,
    "trades": 9,
    "traded_amount": 78721108,
    "price": [
      7.48013,
      12.615384615384615,
      7.48013,
      12.594322614758783
    ],
    "volume": 505336694
  },
  {
    "ts": 1563580800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10033,
      "authorized": 10033,
      "funded": 2797
    },
    "payments": 3,
    "payments_amount": 9567089,
    "trades": 73,
    "traded_amount": 58045348,
    "price": [
      12.594322614758783,
      12.594322614758783,
      8.3,
      12.066666666666666
    ],
    "volume": 463317775
  },
  {
    "ts": 1563667200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10043,
      "authorized": 10043,
      "funded": 2796
    },
    "payments": 2,
    "payments_amount": 76370582,
    "trades": 3,
    "traded_amount": 86369541,
    "price": [
      12.066666666666666,
      12.066666666666666,
      8.299999929992712,
      8.299999929992712
    ],
    "volume": 542316657
  },
  {
    "ts": 1563753600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10055,
      "authorized": 10055,
      "funded": 2796
    },
    "payments": 4,
    "payments_amount": 5329310027,
    "trades": 1,
    "traded_amount": 101691304,
    "price": [
      8.299999929992712,
      12.025040902219132,
      8.299999929992712,
      12.025040902219132
    ],
    "volume": 1222842090
  },
  {
    "ts": 1563840000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10067,
      "authorized": 10067,
      "funded": 2796
    },
    "payments": 6,
    "payments_amount": 71000600000,
    "trades": 2,
    "traded_amount": 100002,
    "price": [
      12.025040902219132,
      12.28067,
      12.025040902219132,
      12.28067
    ],
    "volume": 1228091
  },
  {
    "ts": 1563926400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10077,
      "authorized": 10077,
      "funded": 2797
    },
    "payments": 6,
    "payments_amount": 27868981682,
    "trades": 6,
    "traded_amount": 1232419791,
    "price": [
      12.28067,
      13,
      12.28067,
      13
    ],
    "volume": 14399999986
  },
  {
    "ts": 1564012800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10092,
      "authorized": 10092,
      "funded": 2795
    },
    "payments": 6,
    "payments_amount": 1063866428571,
    "trades": 68,
    "traded_amount": 445761506,
    "price": [
      13,
      13,
      8.350069834392725,
      12.907656098574
    ],
    "volume": 2833326308
  },
  {
    "ts": 1564099200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10097,
      "authorized": 10097,
      "funded": 2795
    },
    "payments": 3,
    "payments_amount": 2560046922,
    "trades": 44,
    "traded_amount": 121551310,
    "price": [
      12.907656098574,
      13,
      12.907656098574,
      13
    ],
    "volume": 793567567
  },
  {
    "ts": 1564185600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10101,
      "authorized": 10101,
      "funded": 2794
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 800,
    "price": [
      13,
      13,
      8.35,
      8.35
    ],
    "volume": 6680
  },
  {
    "ts": 1564272000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10107,
      "authorized": 10107,
      "funded": 2794
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.35,
      8.35,
      8.35,
      8.35
    ],
    "volume": 0
  },
  {
    "ts": 1564358400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10112,
      "authorized": 10112,
      "funded": 2796
    },
    "payments": 8,
    "payments_amount": 83904600000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.35,
      8.35,
      8.35,
      8.35
    ],
    "volume": 0
  },
  {
    "ts": 1564444800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10113,
      "authorized": 10113,
      "funded": 2796
    },
    "payments": 13,
    "payments_amount": 171146000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.35,
      8.35,
      8.35,
      8.35
    ],
    "volume": 0
  },
  {
    "ts": 1564531200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10120,
      "authorized": 10120,
      "funded": 2796
    },
    "payments": 16,
    "payments_amount": 173117461071,
    "trades": 1,
    "traded_amount": 61071,
    "price": [
      8.35,
      8.350935796040673,
      8.35,
      8.350935796040673
    ],
    "volume": 510000
  },
  {
    "ts": 1564617600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10119,
      "authorized": 10119,
      "funded": 2796
    },
    "payments": 12,
    "payments_amount": 139817600000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      8.350935796040673,
      8.350935796040673,
      8.350935796040673,
      8.350935796040673
    ],
    "volume": 0
  },
  {
    "ts": 1564704000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10117,
      "authorized": 10117,
      "funded": 2793
    },
    "payments": 18,
    "payments_amount": 179951800015,
    "trades": 5,
    "traded_amount": 39132057,
    "price": [
      8.350935796040673,
      13.0174123,
      8.333333333333334,
      13.01738680800181
    ],
    "volume": 503093599
  },
  {
    "ts": 1564790400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10118,
      "authorized": 10118,
      "funded": 2793
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.01738680800181,
      13.01738680800181,
      13.01738680800181,
      13.01738680800181
    ],
    "volume": 0
  },
  {
    "ts": 1564876800,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10126,
      "authorized": 10126,
      "funded": 2794
    },
    "payments": 1,
    "payments_amount": 19400000000,
    "trades": 1,
    "traded_amount": 15364036,
    "price": [
      13.01738680800181,
      13.017412351806518,
      13.01738680800181,
      13.017412351806518
    ],
    "volume": 199999992
  },
  {
    "ts": 1564963200,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10133,
      "authorized": 10133,
      "funded": 2794
    },
    "payments": 12,
    "payments_amount": 1057554503124,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      13.017412351806518,
      13.017412351806518,
      13.017412351806518,
      13.017412351806518
    ],
    "volume": 0
  },
  {
    "ts": 1565049600,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10137,
      "authorized": 10137,
      "funded": 2796
    },
    "payments": 7,
    "payments_amount": 78327800000,
    "trades": 2,
    "traded_amount": 12000450,
    "price": [
      13.017412351806518,
      13.017412508706254,
      13.017412314704607,
      13.017412508706254
    ],
    "volume": 156214806
  },
  {
    "ts": 1565136000,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10148,
      "authorized": 10148,
      "funded": 2798
    },
    "payments": 6,
    "payments_amount": 67695287844,
    "trades": 406,
    "traded_amount": 21084213780,
    "price": [
      13.017412508706254,
      14.500320507426233,
      6.299999999884526,
      14.412698412698413
    ],
    "volume": 155569883805
  },
  {
    "ts": 1565222400,
    "supply": 8100000336922,
    "trustlines": {
      "total": 10149,
      "authorized": 10149,
      "funded": 2801
    },
    "payments": 13,
    "payments_amount": 658167400655,
    "trades": 8,
    "traded_amount": 2550777270,
    "price": [
      14.412698412698413,
      14.412698412698413,
      12,
      12
    ],
    "volume": 16050093596
  },
  {
    "ts": 1565308800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10162,
      "authorized": 10162,
      "funded": 2799
    },
    "payments": 6,
    "payments_amount": 98389876748,
    "trades": 16,
    "traded_amount": 3856675176,
    "price": [
      12,
      15.620689655172415,
      12,
      15.620689655172415
    ],
    "volume": 46733598527
  },
  {
    "ts": 1565395200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10186,
      "authorized": 10186,
      "funded": 2801
    },
    "payments": 1,
    "payments_amount": 886217659,
    "trades": 2,
    "traded_amount": 66776,
    "price": [
      15.620689655172415,
      15.620689655172415,
      9,
      9
    ],
    "volume": 1000980
  },
  {
    "ts": 1565481600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10191,
      "authorized": 10191,
      "funded": 2801
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9,
      9,
      9,
      9
    ],
    "volume": 0
  },
  {
    "ts": 1565568000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10201,
      "authorized": 10201,
      "funded": 2801
    },
    "payments": 2,
    "payments_amount": 2621900000,
    "trades": 9,
    "traded_amount": 24877958,
    "price": [
      9,
      14.57256520935002,
      9,
      9
    ],
    "volume": 309191057
  },
  {
    "ts": 1565654400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10204,
      "authorized": 10204,
      "funded": 2801
    },
    "payments": 4,
    "payments_amount": 36181339133,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      9,
      9,
      9,
      9
    ],
    "volume": 0
  },
  {
    "ts": 1565740800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10208,
      "authorized": 10208,
      "funded": 2801
    },
    "payments": 3,
    "payments_amount": 163589800000,
    "trades": 1,
    "traded_amount": 2534808,
    "price": [
      9,
      14,
      9,
      14
    ],
    "volume": 35487312
  },
  {
    "ts": 1565827200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10213,
      "authorized": 10213,
      "funded": 2801
    },
    "payments": 4,
    "payments_amount": 40064500000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14,
      14,
      14,
      14
    ],
    "volume": 0
  },
  {
    "ts": 1565913600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10227,
      "authorized": 10227,
      "funded": 2801
    },
    "payments": 3,
    "payments_amount": 12304800000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14,
      14,
      14,
      14
    ],
    "volume": 0
  },
  {
    "ts": 1566000000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10184,
      "authorized": 10184,
      "funded": 2771
    },
    "payments": 31,
    "payments_amount": 155,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14,
      14,
      14,
      14
    ],
    "volume": 0
  },
  {
    "ts": 1566086400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10190,
      "authorized": 10190,
      "funded": 2771
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14,
      14,
      14,
      14
    ],
    "volume": 0
  },
  {
    "ts": 1566172800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10196,
      "authorized": 10196,
      "funded": 2772
    },
    "payments": 3,
    "payments_amount": 9921900000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14,
      14,
      14,
      14
    ],
    "volume": 0
  },
  {
    "ts": 1566259200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10198,
      "authorized": 10198,
      "funded": 2773
    },
    "payments": 2,
    "payments_amount": 32000000000,
    "trades": 2,
    "traded_amount": 1066556,
    "price": [
      14,
      14.9999,
      10,
      10
    ],
    "volume": 15665460
  },
  {
    "ts": 1566345600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10230,
      "authorized": 10230,
      "funded": 2774
    },
    "payments": 1,
    "payments_amount": 29973000000,
    "trades": 1,
    "traded_amount": 3333355,
    "price": [
      10,
      14.999900100649345,
      10,
      14.999900100649345
    ],
    "volume": 49999992
  },
  {
    "ts": 1566432000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10232,
      "authorized": 10232,
      "funded": 2774
    },
    "payments": 6,
    "payments_amount": 35393100000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999900100649345,
      14.999900100649345,
      14.999900100649345,
      14.999900100649345
    ],
    "volume": 0
  },
  {
    "ts": 1566518400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10235,
      "authorized": 10235,
      "funded": 2774
    },
    "payments": 1,
    "payments_amount": 251000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999900100649345,
      14.999900100649345,
      14.999900100649345,
      14.999900100649345
    ],
    "volume": 0
  },
  {
    "ts": 1566604800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10237,
      "authorized": 10237,
      "funded": 2774
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999900100649345,
      14.999900100649345,
      14.999900100649345,
      14.999900100649345
    ],
    "volume": 0
  },
  {
    "ts": 1566691200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10243,
      "authorized": 10243,
      "funded": 2774
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999900100649345,
      14.999900100649345,
      14.999900100649345,
      14.999900100649345
    ],
    "volume": 0
  },
  {
    "ts": 1566777600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10246,
      "authorized": 10246,
      "funded": 2774
    },
    "payments": 2,
    "payments_amount": 109282500000,
    "trades": 1,
    "traded_amount": 5666645,
    "price": [
      14.999900100649345,
      14.999900100649345,
      14.999899940793892,
      14.999899940793892
    ],
    "volume": 84999108
  },
  {
    "ts": 1566864000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10256,
      "authorized": 10256,
      "funded": 2774
    },
    "payments": 4,
    "payments_amount": 532446400000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999899940793892,
      14.999899940793892,
      14.999899940793892,
      14.999899940793892
    ],
    "volume": 0
  },
  {
    "ts": 1566950400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10271,
      "authorized": 10271,
      "funded": 2774
    },
    "payments": 8,
    "payments_amount": 62075600000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      14.999899940793892,
      14.999899940793892,
      14.999899940793892,
      14.999899940793892
    ],
    "volume": 0
  },
  {
    "ts": 1567036800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10276,
      "authorized": 10276,
      "funded": 2775
    },
    "payments": 15,
    "payments_amount": 164597704351,
    "trades": 5,
    "traded_amount": 3020254277,
    "price": [
      14.999899940793892,
      16,
      14.999899940793892,
      16
    ],
    "volume": 48219890000
  },
  {
    "ts": 1567123200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10275,
      "authorized": 10275,
      "funded": 2775
    },
    "payments": 1,
    "payments_amount": 424760457,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      16,
      16,
      16,
      16
    ],
    "volume": 0
  },
  {
    "ts": 1567209600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10274,
      "authorized": 10274,
      "funded": 2772
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 10000640,
    "price": [
      16,
      16.999,
      16,
      16.999
    ],
    "volume": 170000236
  },
  {
    "ts": 1567296000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10275,
      "authorized": 10275,
      "funded": 2772
    },
    "payments": 1,
    "payments_amount": 7854392030,
    "trades": 8,
    "traded_amount": 507190210,
    "price": [
      16.999,
      17.3007387447146,
      16.886842811715816,
      16.886842811715816
    ],
    "volume": 4332425406
  },
  {
    "ts": 1567382400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10280,
      "authorized": 10280,
      "funded": 2773
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 1,
    "traded_amount": 259750100,
    "price": [
      16.886842811715816,
      16.886842811715816,
      16.886842811715816,
      16.886842811715816
    ],
    "volume": 0
  },
  {
    "ts": 1567468800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10289,
      "authorized": 10289,
      "funded": 2772
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 12,
    "traded_amount": 13444703,
    "price": [
      16.886842811715816,
      16.886842811715816,
      12.999996975916197,
      13
    ],
    "volume": 106765217
  },
  {
    "ts": 1567555200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10295,
      "authorized": 10295,
      "funded": 2773
    },
    "payments": 46,
    "payments_amount": 991098000000,
    "trades": 23,
    "traded_amount": 950179920,
    "price": [
      13,
      16,
      13,
      16
    ],
    "volume": 12284011596
  },
  {
    "ts": 1567641600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10313,
      "authorized": 10313,
      "funded": 2774
    },
    "payments": 3,
    "payments_amount": 2515000000,
    "trades": 5,
    "traded_amount": 24124043,
    "price": [
      16,
      16,
      13,
      16
    ],
    "volume": 355984688
  },
  {
    "ts": 1567728000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10364,
      "authorized": 10364,
      "funded": 2774
    },
    "payments": 1,
    "payments_amount": 4800000000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      16,
      16,
      16,
      16
    ],
    "volume": 0
  },
  {
    "ts": 1567814400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10407,
      "authorized": 10407,
      "funded": 2774
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      16,
      16,
      16,
      16
    ],
    "volume": 0
  },
  {
    "ts": 1567900800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10412,
      "authorized": 10412,
      "funded": 2773
    },
    "payments": 4,
    "payments_amount": 12682747773,
    "trades": 3,
    "traded_amount": 59966668,
    "price": [
      16,
      16,
      13,
      13
    ],
    "volume": 909433352
  },
  {
    "ts": 1567987200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10437,
      "authorized": 10437,
      "funded": 2776
    },
    "payments": 8,
    "payments_amount": 11046100000,
    "trades": 7,
    "traded_amount": 3711100000,
    "price": [
      13,
      17.900000006887222,
      13,
      17.9
    ],
    "volume": 66193123946
  },
  {
    "ts": 1568073600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10497,
      "authorized": 10497,
      "funded": 2781
    },
    "payments": 6,
    "payments_amount": 24164140395,
    "trades": 20,
    "traded_amount": 471421025,
    "price": [
      17.9,
      17.90143369175627,
      12.998266897746968,
      17.900000716000278
    ],
    "volume": 7329553532
  },
  {
    "ts": 1568160000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10538,
      "authorized": 10538,
      "funded": 2782
    },
    "payments": 8,
    "payments_amount": 95995000000,
    "trades": 8,
    "traded_amount": 473576926,
    "price": [
      17.900000716000278,
      17.900000716000278,
      13,
      13
    ],
    "volume": 7222584003
  },
  {
    "ts": 1568246400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10579,
      "authorized": 10579,
      "funded": 2786
    },
    "payments": 3,
    "payments_amount": 40354400000,
    "trades": 7,
    "traded_amount": 682810774,
    "price": [
      13,
      17.90000006712501,
      13,
      17.9
    ],
    "volume": 11067826448
  },
  {
    "ts": 1568332800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10602,
      "authorized": 10602,
      "funded": 2789
    },
    "payments": 6,
    "payments_amount": 27669790552,
    "trades": 6,
    "traded_amount": 103784450,
    "price": [
      17.9,
      17.900000020498755,
      13,
      17.9
    ],
    "volume": 1542222203
  },
  {
    "ts": 1568419200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10627,
      "authorized": 10627,
      "funded": 2791
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 5,
    "traded_amount": 159944132,
    "price": [
      17.9,
      17.9,
      13,
      17.9
    ],
    "volume": 2858072591
  },
  {
    "ts": 1568505600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10642,
      "authorized": 10642,
      "funded": 2798
    },
    "payments": 4,
    "payments_amount": 40100000,
    "trades": 14,
    "traded_amount": 417480488,
    "price": [
      17.9,
      18,
      13,
      13
    ],
    "volume": 7156149045
  },
  {
    "ts": 1568592000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10664,
      "authorized": 10664,
      "funded": 2804
    },
    "payments": 7,
    "payments_amount": 641931398,
    "trades": 12,
    "traded_amount": 380025517,
    "price": [
      13,
      18,
      13,
      17.9568123
    ],
    "volume": 6408356726
  },
  {
    "ts": 1568678400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10673,
      "authorized": 10673,
      "funded": 2804
    },
    "payments": 4,
    "payments_amount": 2285652852,
    "trades": 8,
    "traded_amount": 408316785,
    "price": [
      17.9568123,
      17.956835311316663,
      15.000013979509767,
      15.000013979509767
    ],
    "volume": 3422441356
  },
  {
    "ts": 1568764800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10688,
      "authorized": 10688,
      "funded": 2808
    },
    "payments": 10,
    "payments_amount": 3482187,
    "trades": 241,
    "traded_amount": 72825903613,
    "price": [
      15.000013979509767,
      15.000240155707512,
      11.327777777777778,
      14.117647623529525
    ],
    "volume": 365573967644
  },
  {
    "ts": 1568851200,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10700,
      "authorized": 10700,
      "funded": 2808
    },
    "payments": 1,
    "payments_amount": 7491200000,
    "trades": 6,
    "traded_amount": 498907156,
    "price": [
      14.117647623529525,
      14.117647623529525,
      11.3286822,
      11.3286822
    ],
    "volume": 113286822
  },
  {
    "ts": 1568937600,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10708,
      "authorized": 10708,
      "funded": 2796
    },
    "payments": 19,
    "payments_amount": 19982175275,
    "trades": 293,
    "traded_amount": 1206312565,
    "price": [
      11.3286822,
      15.23076923076923,
      11.3286822,
      15.185185185185185
    ],
    "volume": 10067554261
  },
  {
    "ts": 1569024000,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10746,
      "authorized": 10746,
      "funded": 2795
    },
    "payments": 3,
    "payments_amount": 384280006,
    "trades": 18,
    "traded_amount": 1599533934,
    "price": [
      15.185185185185185,
      15.185185185185185,
      10.292593732141848,
      10.3093846281003
    ],
    "volume": 11330010598
  },
  {
    "ts": 1569110400,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10754,
      "authorized": 10754,
      "funded": 2796
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 10362194,
    "price": [
      10.3093846281003,
      15.71960812546069,
      10.3093846281003,
      15.71960812546069
    ],
    "volume": 162889629
  },
  {
    "ts": 1569196800,
    "supply": 8100000336917,
    "trustlines": {
      "total": 10774,
      "authorized": 10774,
      "funded": 2800
    },
    "payments": 9,
    "payments_amount": 314304387,
    "trades": 1008,
    "traded_amount": 1011374276,
    "price": [
      15.71960812546069,
      17.28570035989643,
      10.29269972,
      17.163265306122447
    ],
    "volume": 6133400190
  },
  {
    "ts": 1569283200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10796,
      "authorized": 10796,
      "funded": 2797
    },
    "payments": 4,
    "payments_amount": 10185244526,
    "trades": 90,
    "traded_amount": 440574879,
    "price": [
      17.163265306122447,
      17.243429483210555,
      12,
      15.000224999839935
    ],
    "volume": 3519284066
  },
  {
    "ts": 1569369600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10800,
      "authorized": 10800,
      "funded": 2796
    },
    "payments": 7,
    "payments_amount": 1259376372172,
    "trades": 26,
    "traded_amount": 270497600,
    "price": [
      15.000224999839935,
      18.543802099413398,
      14.999999156721604,
      14.999999156721604
    ],
    "volume": 2452203102
  },
  {
    "ts": 1569456000,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10805,
      "authorized": 10805,
      "funded": 2796
    },
    "payments": 2,
    "payments_amount": 46327000000,
    "trades": 78,
    "traded_amount": 568304178,
    "price": [
      14.999999156721604,
      18.54377843507726,
      14.999999156721604,
      18.54377843507726
    ],
    "volume": 6893245918
  },
  {
    "ts": 1569542400,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10811,
      "authorized": 10811,
      "funded": 2796
    },
    "payments": 6,
    "payments_amount": 65358861935,
    "trades": 94,
    "traded_amount": 494536845,
    "price": [
      18.54377843507726,
      18.54377843507726,
      10.292592592592593,
      10.292592592592593
    ],
    "volume": 5328345672
  },
  {
    "ts": 1569628800,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10813,
      "authorized": 10813,
      "funded": 2796
    },
    "payments": 1,
    "payments_amount": 556,
    "trades": 3,
    "traded_amount": 10254671,
    "price": [
      10.292592592592593,
      18.54377711303477,
      10.292592592592593,
      18.54377711303477
    ],
    "volume": 190009987
  },
  {
    "ts": 1569715200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10825,
      "authorized": 10825,
      "funded": 2796
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 2,
    "traded_amount": 80000000,
    "price": [
      18.54377711303477,
      18.54377711303477,
      18,
      18
    ],
    "volume": 1467188850
  },
  {
    "ts": 1569801600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10829,
      "authorized": 10829,
      "funded": 2796
    },
    "payments": 6,
    "payments_amount": 42439200000,
    "trades": 191,
    "traded_amount": 609734566,
    "price": [
      18,
      18.2390027,
      17.49999993588193,
      17.5
    ],
    "volume": 8153821125
  },
  {
    "ts": 1569888000,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10843,
      "authorized": 10843,
      "funded": 2795
    },
    "payments": 17,
    "payments_amount": 187419502968,
    "trades": 227,
    "traded_amount": 413341891,
    "price": [
      17.5,
      18.17105263157895,
      17.45123423046829,
      18.065217391304348
    ],
    "volume": 3556834439
  },
  {
    "ts": 1569974400,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10855,
      "authorized": 10855,
      "funded": 2795
    },
    "payments": 12,
    "payments_amount": 141530900000,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      18.065217391304348,
      18.065217391304348,
      18.065217391304348,
      18.065217391304348
    ],
    "volume": 0
  },
  {
    "ts": 1570060800,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10866,
      "authorized": 10866,
      "funded": 2798
    },
    "payments": 9,
    "payments_amount": 90572600000,
    "trades": 2,
    "traded_amount": 189976907,
    "price": [
      18.065217391304348,
      18.543777406948838,
      18.065217391304348,
      18.543777406948838
    ],
    "volume": 3522889431
  },
  {
    "ts": 1570147200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10884,
      "authorized": 10884,
      "funded": 2798
    },
    "payments": 5,
    "payments_amount": 28998000000,
    "trades": 52,
    "traded_amount": 215528193,
    "price": [
      18.543777406948838,
      18.543777406948838,
      18.193361743488826,
      18.243478714171662
    ],
    "volume": 1970081360
  },
  {
    "ts": 1570233600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10889,
      "authorized": 10889,
      "funded": 2799
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 25,
    "traded_amount": 714722074,
    "price": [
      18.243478714171662,
      18.543777432569758,
      18.243478714171662,
      18.330292713635544
    ],
    "volume": 8841260194
  },
  {
    "ts": 1570320000,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10891,
      "authorized": 10891,
      "funded": 2799
    },
    "payments": 1,
    "payments_amount": 189804607,
    "trades": 8,
    "traded_amount": 722006269,
    "price": [
      18.330292713635544,
      18.54379295661886,
      16.70027889271577,
      16.70027889271577
    ],
    "volume": 6689489964
  },
  {
    "ts": 1570406400,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10897,
      "authorized": 10897,
      "funded": 2801
    },
    "payments": 5,
    "payments_amount": 33937300000,
    "trades": 359,
    "traded_amount": 15263342,
    "price": [
      16.70027889271577,
      17.711221,
      16.70027889271577,
      17.58823529411765
    ],
    "volume": 169800310
  },
  {
    "ts": 1570492800,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10908,
      "authorized": 10908,
      "funded": 2804
    },
    "payments": 8,
    "payments_amount": 1013876851656,
    "trades": 126,
    "traded_amount": 869565136,
    "price": [
      17.58823529411765,
      17.666666666666668,
      16.000256,
      17.297174737360603
    ],
    "volume": 8258142055
  },
  {
    "ts": 1570579200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10916,
      "authorized": 10916,
      "funded": 2805
    },
    "payments": 5,
    "payments_amount": 51359977263,
    "trades": 158,
    "traded_amount": 2726684907,
    "price": [
      17.297174737360603,
      17.321749213769266,
      16.623943108561086,
      17.188308009517083
    ],
    "volume": 32094715548
  },
  {
    "ts": 1570665600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10924,
      "authorized": 10924,
      "funded": 2805
    },
    "payments": 1,
    "payments_amount": 8482200000,
    "trades": 112,
    "traded_amount": 2358300925,
    "price": [
      17.188308009517083,
      17.720881805425208,
      17.118685557962472,
      17.35418147346284
    ],
    "volume": 35815460178
  },
  {
    "ts": 1570752000,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10930,
      "authorized": 10930,
      "funded": 2806
    },
    "payments": 6,
    "payments_amount": 46875000000,
    "trades": 350,
    "traded_amount": 7917816447,
    "price": [
      17.35418147346284,
      18.01172176089681,
      17.304407713498623,
      17.95873697364312
    ],
    "volume": 64649903462
  },
  {
    "ts": 1570838400,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10949,
      "authorized": 10949,
      "funded": 2806
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 145,
    "traded_amount": 3157041258,
    "price": [
      17.95873697364312,
      17.95873697364312,
      17.550387070029245,
      17.639220828662676
    ],
    "volume": 29080000700
  },
  {
    "ts": 1570924800,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10958,
      "authorized": 10958,
      "funded": 2805
    },
    "payments": 2,
    "payments_amount": 102500000,
    "trades": 76,
    "traded_amount": 1607701231,
    "price": [
      17.639220828662676,
      17.819926969222745,
      17.324405266429338,
      17.457307887706037
    ],
    "volume": 14993223709
  },
  {
    "ts": 1571011200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10965,
      "authorized": 10965,
      "funded": 2804
    },
    "payments": 3,
    "payments_amount": 11294612540,
    "trades": 27,
    "traded_amount": 456441370,
    "price": [
      17.457307887706037,
      17.457307887706037,
      16.788516618486952,
      16.788516618486952
    ],
    "volume": 3845175115
  },
  {
    "ts": 1571097600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10966,
      "authorized": 10966,
      "funded": 2804
    },
    "payments": 10,
    "payments_amount": 5076879538,
    "trades": 673,
    "traded_amount": 8917081090,
    "price": [
      16.788516618486952,
      17.20980814891367,
      15.2,
      17.19568494241571
    ],
    "volume": 76013895854
  },
  {
    "ts": 1571184000,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10973,
      "authorized": 10973,
      "funded": 2807
    },
    "payments": 9,
    "payments_amount": 8560546041,
    "trades": 1333,
    "traded_amount": 13470146477,
    "price": [
      17.19568494241571,
      18.015723451805925,
      14.666666666666666,
      17.6251413
    ],
    "volume": 118010519775
  },
  {
    "ts": 1571270400,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10983,
      "authorized": 10983,
      "funded": 2809
    },
    "payments": 8,
    "payments_amount": 19794130128,
    "trades": 16,
    "traded_amount": 1460183197,
    "price": [
      17.6251413,
      17.6251413,
      16.69212213095997,
      16.96941423765737
    ],
    "volume": 12990505929
  },
  {
    "ts": 1571356800,
    "supply": 8100000336912,
    "trustlines": {
      "total": 10997,
      "authorized": 10997,
      "funded": 2814
    },
    "payments": 11,
    "payments_amount": 21857605313,
    "trades": 61,
    "traded_amount": 8809547873,
    "price": [
      16.96941423765737,
      17.4598786,
      16.96941423765737,
      17.221512617649083
    ],
    "volume": 24338847193
  },
  {
    "ts": 1571443200,
    "supply": 8100000336912,
    "trustlines": {
      "total": 11119,
      "authorized": 11119,
      "funded": 2813
    },
    "payments": 1,
    "payments_amount": 578871,
    "trades": 24,
    "traded_amount": 7357834546,
    "price": [
      17.221512617649083,
      17.34078982180783,
      17.164603058994903,
      17.275006003064586
    ],
    "volume": 6303260979
  },
  {
    "ts": 1571529600,
    "supply": 8100000336912,
    "trustlines": {
      "total": 11121,
      "authorized": 11121,
      "funded": 2813
    },
    "payments": 1,
    "payments_amount": 123727869,
    "trades": 55,
    "traded_amount": 25208668880,
    "price": [
      17.275006003064586,
      17.499999624989293,
      17.275006003064586,
      17.499999624989293
    ],
    "volume": 699237981
  },
  {
    "ts": 1571616000,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11126,
      "authorized": 11126,
      "funded": 2813
    },
    "payments": 7,
    "payments_amount": 4001004001058,
    "trades": 3,
    "traded_amount": 2203070648,
    "price": [
      17.499999624989293,
      17.499999624989293,
      17.499999624989293,
      17.499999624989293
    ],
    "volume": 0
  },
  {
    "ts": 1571702400,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11130,
      "authorized": 11130,
      "funded": 2814
    },
    "payments": 3,
    "payments_amount": 7096653717,
    "trades": 15,
    "traded_amount": 389506539,
    "price": [
      17.499999624989293,
      17.499999624989293,
      17.018814196516594,
      17.018814196516594
    ],
    "volume": 1649990000
  },
  {
    "ts": 1571788800,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11136,
      "authorized": 11136,
      "funded": 2822
    },
    "payments": 21,
    "payments_amount": 37322583202,
    "trades": 257,
    "traded_amount": 35271756420,
    "price": [
      17.018814196516594,
      19,
      17.018814196516594,
      18.40566410587008
    ],
    "volume": 142981276104
  },
  {
    "ts": 1571875200,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11142,
      "authorized": 11142,
      "funded": 2822
    },
    "payments": 1,
    "payments_amount": 6000000000,
    "trades": 1,
    "traded_amount": 543,
    "price": [
      18.40566410587008,
      18.406998158379373,
      18.40566410587008,
      18.406998158379373
    ],
    "volume": 9995
  },
  {
    "ts": 1571961600,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11145,
      "authorized": 11145,
      "funded": 2825
    },
    "payments": 11,
    "payments_amount": 27792815010,
    "trades": 407,
    "traded_amount": 87597204284,
    "price": [
      18.406998158379373,
      18.406998158379373,
      16.066666666666666,
      17.094222728254255
    ],
    "volume": 627249604541
  },
  {
    "ts": 1572048000,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11148,
      "authorized": 11148,
      "funded": 2824
    },
    "payments": 9,
    "payments_amount": 5866272428,
    "trades": 475,
    "traded_amount": 86854666370,
    "price": [
      17.094222728254255,
      17.655184682365057,
      15.333333333333334,
      17.300201375588028
    ],
    "volume": 510252944971
  },
  {
    "ts": 1572134400,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11150,
      "authorized": 11150,
      "funded": 2823
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 72,
    "traded_amount": 845981518,
    "price": [
      17.300201375588028,
      17.66982408660352,
      16.656339676795035,
      16.656339676795035
    ],
    "volume": 7269723278
  },
  {
    "ts": 1572220800,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11153,
      "authorized": 11153,
      "funded": 2826
    },
    "payments": 4,
    "payments_amount": 2662996297,
    "trades": 58,
    "traded_amount": 8419231007,
    "price": [
      16.656339676795035,
      16.92554284447288,
      15.865662248887368,
      16.92554284447288
    ],
    "volume": 33744767463
  },
  {
    "ts": 1572307200,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11160,
      "authorized": 11160,
      "funded": 2828
    },
    "payments": 19,
    "payments_amount": 115376531239,
    "trades": 16,
    "traded_amount": 7091318905,
    "price": [
      16.92554284447288,
      16.92554284447288,
      15.85368945057274,
      15.85368945057274
    ],
    "volume": 29200481477
  },
  {
    "ts": 1572393600,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11169,
      "authorized": 11169,
      "funded": 2832
    },
    "payments": 20,
    "payments_amount": 93099945645,
    "trades": 206,
    "traded_amount": 81152152829,
    "price": [
      15.85368945057274,
      17.3421865,
      10.999999986578235,
      17.028261721713623
    ],
    "volume": 208457479760
  },
  {
    "ts": 1572480000,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11177,
      "authorized": 11177,
      "funded": 2831
    },
    "payments": 14,
    "payments_amount": 234955300000,
    "trades": 16,
    "traded_amount": 327552039,
    "price": [
      17.028261721713623,
      17.028261721713623,
      17,
      17
    ],
    "volume": 257
  },
  {
    "ts": 1572566400,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11183,
      "authorized": 11183,
      "funded": 2839
    },
    "payments": 25,
    "payments_amount": 79749540298,
    "trades": 64,
    "traded_amount": 21292092023,
    "price": [
      17,
      17,
      15.465703168613471,
      15.849794129235619
    ],
    "volume": 2651800711
  },
  {
    "ts": 1572652800,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11185,
      "authorized": 11185,
      "funded": 2841
    },
    "payments": 8,
    "payments_amount": 3288795632,
    "trades": 17,
    "traded_amount": 5973957619,
    "price": [
      15.849794129235619,
      15.849794129235619,
      15.375,
      15.409887365721893
    ],
    "volume": 2268958088
  },
  {
    "ts": 1572739200,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11193,
      "authorized": 11193,
      "funded": 2841
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 902,
    "price": [
      15.409887365721893,
      15.409887365721893,
      15.38470066518847,
      15.38470066518847
    ],
    "volume": 13877
  },
  {
    "ts": 1572825600,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11199,
      "authorized": 11199,
      "funded": 2842
    },
    "payments": 4,
    "payments_amount": 8538101000,
    "trades": 48,
    "traded_amount": 42012439303,
    "price": [
      15.38470066518847,
      16.0205481065118,
      14.081333779433967,
      14.081333779433967
    ],
    "volume": 86504298374
  },
  {
    "ts": 1572912000,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11206,
      "authorized": 11206,
      "funded": 2849
    },
    "payments": 24,
    "payments_amount": 68337476958,
    "trades": 149,
    "traded_amount": 99198372052,
    "price": [
      14.081333779433967,
      14.081333779433967,
      12.49787219979971,
      13.106155700675876
    ],
    "volume": 175340818115
  },
  {
    "ts": 1572998400,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11212,
      "authorized": 11212,
      "funded": 2857
    },
    "payments": 39,
    "payments_amount": 63316744788,
    "trades": 570,
    "traded_amount": 86997112543,
    "price": [
      13.106155700675876,
      14.274070724415905,
      13.106155700675876,
      14.181560506282056
    ],
    "volume": 352579603954
  },
  {
    "ts": 1573084800,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11233,
      "authorized": 11233,
      "funded": 2859
    },
    "payments": 31,
    "payments_amount": 582388326299,
    "trades": 373,
    "traded_amount": 70299771225,
    "price": [
      14.181560506282056,
      15.003749200393967,
      14.181560506282056,
      14.428744310620615
    ],
    "volume": 336967419654
  },
  {
    "ts": 1573171200,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11235,
      "authorized": 11235,
      "funded": 2862
    },
    "payments": 41,
    "payments_amount": 57393112229,
    "trades": 655,
    "traded_amount": 158928179790,
    "price": [
      14.428744310620615,
      15.918959506541013,
      14.428744310620615,
      15.419256404994828
    ],
    "volume": 949958283436
  },
  {
    "ts": 1573257600,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11243,
      "authorized": 11243,
      "funded": 2864
    },
    "payments": 5,
    "payments_amount": 3216380016,
    "trades": 293,
    "traded_amount": 121854565824,
    "price": [
      15.419256404994828,
      15.46935610091662,
      15.003076401118177,
      15.122290351959101
    ],
    "volume": 619155178530
  },
  {
    "ts": 1573344000,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11250,
      "authorized": 11250,
      "funded": 2867
    },
    "payments": 22,
    "payments_amount": 8370817851,
    "trades": 623,
    "traded_amount": 180021654831,
    "price": [
      15.122290351959101,
      15.122290351959101,
      13.547028601148233,
      13.849704700116307
    ],
    "volume": 744784378400
  },
  {
    "ts": 1573430400,
    "supply": 5098996338054,
    "trustlines": {
      "total": 11254,
      "authorized": 11254,
      "funded": 2869
    },
    "payments": 21,
    "payments_amount": 34074021248,
    "trades": 665,
    "traded_amount": 276607684152,
    "price": [
      13.849704700116307,
      14.370690505273371,
      13.556015594509736,
      13.623795622461627
    ],
    "volume": 1303287818163
  },
  {
    "ts": 1573516800,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11255,
      "authorized": 11255,
      "funded": 2868
    },
    "payments": 40,
    "payments_amount": 76532022882,
    "trades": 1252,
    "traded_amount": 319448563050,
    "price": [
      13.623795622461627,
      14.694542100484577,
      13.33023103300322,
      14.193443400239934
    ],
    "volume": 1773668410294
  },
  {
    "ts": 1573603200,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11261,
      "authorized": 11261,
      "funded": 2869
    },
    "payments": 7,
    "payments_amount": 21309706184,
    "trades": 671,
    "traded_amount": 229257676562,
    "price": [
      14.193443400239934,
      14.457000743929795,
      14.15860920034695,
      14.45292180119585
    ],
    "volume": 1461365263246
  },
  {
    "ts": 1573689600,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11273,
      "authorized": 11273,
      "funded": 2870
    },
    "payments": 9,
    "payments_amount": 508765398267,
    "trades": 92,
    "traded_amount": 32194911134,
    "price": [
      14.45292180119585,
      15.136765400116602,
      14.45292180119585,
      14.863498904203869
    ],
    "volume": 196826965047
  },
  {
    "ts": 1573776000,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11277,
      "authorized": 11277,
      "funded": 2873
    },
    "payments": 6,
    "payments_amount": 24486345468,
    "trades": 448,
    "traded_amount": 112115231897,
    "price": [
      14.863498904203869,
      15.497891854790657,
      14.745824100709665,
      15.192788911023504
    ],
    "volume": 430215851611
  },
  {
    "ts": 1573862400,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11307,
      "authorized": 11307,
      "funded": 2875
    },
    "payments": 31,
    "payments_amount": 2130111155,
    "trades": 281,
    "traded_amount": 99400284043,
    "price": [
      15.192788911023504,
      15.494877702202334,
      15.1083416497171,
      15.400974774946082
    ],
    "volume": 426151422732
  },
  {
    "ts": 1573948800,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11338,
      "authorized": 11338,
      "funded": 2887
    },
    "payments": 124,
    "payments_amount": 57875915463,
    "trades": 914,
    "traded_amount": 296822244032,
    "price": [
      15.400974774946082,
      15.437432601147956,
      14.777777777777779,
      15.306197857251751
    ],
    "volume": 1056179851407
  },
  {
    "ts": 1574035200,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11374,
      "authorized": 11374,
      "funded": 2886
    },
    "payments": 81,
    "payments_amount": 37159020242,
    "trades": 665,
    "traded_amount": 139074536327,
    "price": [
      15.306197857251751,
      16.937784133176088,
      15.306197857251751,
      16.290396241788972
    ],
    "volume": 333460680029
  },
  {
    "ts": 1574121600,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11416,
      "authorized": 11416,
      "funded": 2895
    },
    "payments": 15,
    "payments_amount": 7682385663,
    "trades": 380,
    "traded_amount": 41665587051,
    "price": [
      16.290396241788972,
      17.364140401600622,
      15.857142857142858,
      16.897151
    ],
    "volume": 197590251737
  },
  {
    "ts": 1574208000,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11431,
      "authorized": 11431,
      "funded": 2896
    },
    "payments": 11,
    "payments_amount": 36486301945,
    "trades": 28,
    "traded_amount": 13979634861,
    "price": [
      16.897151,
      17.11230011230011,
      15.7943201,
      16.942657175125774
    ],
    "volume": 21230909701
  },
  {
    "ts": 1574294400,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11441,
      "authorized": 11441,
      "funded": 2895
    },
    "payments": 31,
    "payments_amount": 9780452861,
    "trades": 412,
    "traded_amount": 118081199975,
    "price": [
      16.942657175125774,
      18.47189409297813,
      16.942553191489363,
      18.043962306042253
    ],
    "volume": 606846545451
  },
  {
    "ts": 1574380800,
    "supply": 5098996338049,
    "trustlines": {
      "total": 11452,
      "authorized": 11452,
      "funded": 2897
    },
    "payments": 7,
    "payments_amount": 4206330034,
    "trades": 863,
    "traded_amount": 105933919394,
    "price": [
      18.043962306042253,
      20.371603901443052,
      18.043962306042253,
      18.37654175124179
    ],
    "volume": 520033890255
  },
  {
    "ts": 1574467200,
    "supply": 5098996338044,
    "trustlines": {
      "total": 11497,
      "authorized": 11497,
      "funded": 2897
    },
    "payments": 3,
    "payments_amount": 150281141,
    "trades": 163,
    "traded_amount": 25232174069,
    "price": [
      18.37654175124179,
      18.830000006000002,
      17.663662382450713,
      18.11796310119748
    ],
    "volume": 112057714823
  },
  {
    "ts": 1574553600,
    "supply": 5098996338044,
    "trustlines": {
      "total": 11508,
      "authorized": 11508,
      "funded": 2898
    },
    "payments": 3,
    "payments_amount": 7576419,
    "trades": 347,
    "traded_amount": 26434013816,
    "price": [
      18.11796310119748,
      19.689611800094145,
      17.7299512,
      19.689611800094145
    ],
    "volume": 277574801179
  },
  {
    "ts": 1574640000,
    "supply": 5098996338044,
    "trustlines": {
      "total": 11529,
      "authorized": 11529,
      "funded": 2902
    },
    "payments": 4,
    "payments_amount": 22350000000,
    "trades": 258,
    "traded_amount": 22282402458,
    "price": [
      19.689611800094145,
      20.550637271787807,
      18.5,
      18.897596601773706
    ],
    "volume": 201613048639
  },
  {
    "ts": 1574726400,
    "supply": 5098996338044,
    "trustlines": {
      "total": 11626,
      "authorized": 11626,
      "funded": 2904
    },
    "payments": 5,
    "payments_amount": 39982600310,
    "trades": 141,
    "traded_amount": 4563562561,
    "price": [
      18.897596601773706,
      19.4000445056421,
      18.897596601773706,
      19.093443302656436
    ],
    "volume": 51596802379
  },
  {
    "ts": 1574812800,
    "supply": 5098996338044,
    "trustlines": {
      "total": 11994,
      "authorized": 11994,
      "funded": 2906
    },
    "payments": 5,
    "payments_amount": 49323120386,
    "trades": 143,
    "traded_amount": 7641844943,
    "price": [
      19.093443302656436,
      19.86226880099135,
      18.525311750194316,
      18.73354424752534
    ],
    "volume": 100368677520
  },
  {
    "ts": 1574899200,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12035,
      "authorized": 12035,
      "funded": 2905
    },
    "payments": 6,
    "payments_amount": 22557900000,
    "trades": 133,
    "traded_amount": 9118065299,
    "price": [
      18.73354424752534,
      18.97385592236458,
      18.498538402947897,
      18.883420140500746
    ],
    "volume": 157324263271
  },
  {
    "ts": 1574985600,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12062,
      "authorized": 12062,
      "funded": 2905
    },
    "payments": 7,
    "payments_amount": 60333700000,
    "trades": 128,
    "traded_amount": 5419374269,
    "price": [
      18.883420140500746,
      18.883420140500746,
      18.365435262684368,
      18.45549527828009
    ],
    "volume": 65178396895
  },
  {
    "ts": 1575072000,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12089,
      "authorized": 12089,
      "funded": 2906
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 753918388,
    "price": [
      18.45549527828009,
      19.301037060498906,
      18.45549527828009,
      19.301037060498906
    ],
    "volume": 12322721518
  },
  {
    "ts": 1575158400,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12113,
      "authorized": 12113,
      "funded": 2905
    },
    "payments": 1,
    "payments_amount": 100000,
    "trades": 169,
    "traded_amount": 7317870570,
    "price": [
      19.301037060498906,
      19.827146862763776,
      19.22067837333333,
      19.324902864980572
    ],
    "volume": 101996296815
  },
  {
    "ts": 1575244800,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12148,
      "authorized": 12148,
      "funded": 2903
    },
    "payments": 7,
    "payments_amount": 85249600000,
    "trades": 20,
    "traded_amount": 15153186211,
    "price": [
      19.324902864980572,
      19.79897320137349,
      19.186566226415096,
      19.5695482
    ],
    "volume": 29670402875
  },
  {
    "ts": 1575331200,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12404,
      "authorized": 12404,
      "funded": 2903
    },
    "payments": 10,
    "payments_amount": 620683400000,
    "trades": 235,
    "traded_amount": 2501323358,
    "price": [
      19.5695482,
      19.957284408809215,
      19.5512197743849,
      19.620846358538337
    ],
    "volume": 25411223959
  },
  {
    "ts": 1575417600,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12521,
      "authorized": 12521,
      "funded": 2904
    },
    "payments": 11,
    "payments_amount": 79027926192,
    "trades": 244,
    "traded_amount": 15207165373,
    "price": [
      19.620846358538337,
      20.37527173319203,
      19.620846358538337,
      20.148528405201915
    ],
    "volume": 152221769292
  },
  {
    "ts": 1575504000,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12658,
      "authorized": 12658,
      "funded": 2909
    },
    "payments": 10,
    "payments_amount": 9598120561,
    "trades": 74,
    "traded_amount": 6685110646,
    "price": [
      20.148528405201915,
      20.291221551323734,
      19.804843130262803,
      19.943200005447412
    ],
    "volume": 16275664587
  },
  {
    "ts": 1575590400,
    "supply": 5098996338044,
    "trustlines": {
      "total": 12751,
      "authorized": 12751,
      "funded": 2893
    },
    "payments": 27,
    "payments_amount": 35087823304,
    "trades": 35,
    "traded_amount": 7216072220,
    "price": [
      19.943200005447412,
      20.123947936459448,
      19.698924731182796,
      19.898504912430585
    ],
    "volume": 33061693413
  },
  {
    "ts": 1575676800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 12814,
      "authorized": 12814,
      "funded": 2892
    },
    "payments": 1,
    "payments_amount": 5,
    "trades": 0,
    "traded_amount": 0,
    "price": [
      19.898504912430585,
      19.898504912430585,
      19.898504912430585,
      19.898504912430585
    ],
    "volume": 0
  },
  {
    "ts": 1575763200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 12868,
      "authorized": 12868,
      "funded": 2892
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1,
    "traded_amount": 13837167,
    "price": [
      19.898504912430585,
      19.898504912430585,
      19.512663683252505,
      19.512663683252505
    ],
    "volume": 269999986
  },
  {
    "ts": 1575849600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 12894,
      "authorized": 12894,
      "funded": 2892
    },
    "payments": 6,
    "payments_amount": 58973845553,
    "trades": 16,
    "traded_amount": 3364826450,
    "price": [
      19.512663683252505,
      19.761714285714287,
      19.512663683252505,
      19.727897193187076
    ],
    "volume": 1218068323
  },
  {
    "ts": 1575936000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13010,
      "authorized": 13010,
      "funded": 2891
    },
    "payments": 8,
    "payments_amount": 12334913858,
    "trades": 37,
    "traded_amount": 9613966960,
    "price": [
      19.727897193187076,
      20.75382039053989,
      19.727897193187076,
      20.753820199424894
    ],
    "volume": 67729111077
  },
  {
    "ts": 1576022400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13073,
      "authorized": 13073,
      "funded": 2891
    },
    "payments": 9,
    "payments_amount": 61850783795,
    "trades": 20,
    "traded_amount": 4753361106,
    "price": [
      20.753820199424894,
      20.777627788078348,
      20.29741379310345,
      20.66880105669282
    ],
    "volume": 23341632849
  },
  {
    "ts": 1576108800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13134,
      "authorized": 13134,
      "funded": 2890
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 186,
    "traded_amount": 7932828838,
    "price": [
      20.66880105669282,
      21.44482283113264,
      20.66880105669282,
      21.379241317185468
    ],
    "volume": 28330610971
  },
  {
    "ts": 1576195200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13186,
      "authorized": 13186,
      "funded": 2892
    },
    "payments": 7,
    "payments_amount": 566783497347,
    "trades": 23,
    "traded_amount": 3865479343,
    "price": [
      21.379241317185468,
      21.393367237653084,
      21.1333430517087,
      21.293255629612883
    ],
    "volume": 560184458
  },
  {
    "ts": 1576281600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13220,
      "authorized": 13220,
      "funded": 2894
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 10,
    "traded_amount": 4103483769,
    "price": [
      21.293255629612883,
      21.293255629612883,
      21.081462102201982,
      21.22441150183699
    ],
    "volume": 10908198574
  },
  {
    "ts": 1576368000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13342,
      "authorized": 13342,
      "funded": 2897
    },
    "payments": 3,
    "payments_amount": 998936704,
    "trades": 27,
    "traded_amount": 6414324572,
    "price": [
      21.22441150183699,
      21.62039312039312,
      21.22441150183699,
      21.271178340778302
    ],
    "volume": 12527144977
  },
  {
    "ts": 1576454400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13417,
      "authorized": 13417,
      "funded": 2908
    },
    "payments": 52,
    "payments_amount": 16000883507,
    "trades": 404,
    "traded_amount": 33817986957,
    "price": [
      21.271178340778302,
      23.580571704859377,
      20.428571428571427,
      23.43716731201543
    ],
    "volume": 297792471859
  },
  {
    "ts": 1576540800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13468,
      "authorized": 13468,
      "funded": 2917
    },
    "payments": 69,
    "payments_amount": 88636592628,
    "trades": 902,
    "traded_amount": 269331565116,
    "price": [
      23.43716731201543,
      26,
      23.43716731201543,
      25.46301764697571
    ],
    "volume": 2451837331084
  },
  {
    "ts": 1576627200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13526,
      "authorized": 13526,
      "funded": 2913
    },
    "payments": 17,
    "payments_amount": 8629083484,
    "trades": 593,
    "traded_amount": 713948149910,
    "price": [
      25.46301764697571,
      25.599187015516105,
      23.204462398109904,
      23.695703703703703
    ],
    "volume": 7658281799570
  },
  {
    "ts": 1576713600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13560,
      "authorized": 13560,
      "funded": 2916
    },
    "payments": 4,
    "payments_amount": 38971500000,
    "trades": 454,
    "traded_amount": 172841717866,
    "price": [
      23.695703703703703,
      24.824563,
      23.495479204339965,
      24.411467599776127
    ],
    "volume": 1394651357913
  },
  {
    "ts": 1576800000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13586,
      "authorized": 13586,
      "funded": 2916
    },
    "payments": 11,
    "payments_amount": 38205703895,
    "trades": 49,
    "traded_amount": 48503025665,
    "price": [
      24.411467599776127,
      24.477619806313637,
      23.625358514815463,
      23.634962700990755
    ],
    "volume": 320482370599
  },
  {
    "ts": 1576886400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13604,
      "authorized": 13604,
      "funded": 2914
    },
    "payments": 1,
    "payments_amount": 450,
    "trades": 3,
    "traded_amount": 240141948,
    "price": [
      23.634962700990755,
      23.875807595817545,
      23.634962700990755,
      23.81400310294911
    ],
    "volume": 5727673212
  },
  {
    "ts": 1576972800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13647,
      "authorized": 13647,
      "funded": 2914
    },
    "payments": 10,
    "payments_amount": 3098605878,
    "trades": 107,
    "traded_amount": 189404543974,
    "price": [
      23.81400310294911,
      24.116984100400103,
      23.262305721830696,
      23.331124653422442
    ],
    "volume": 2040085865871
  },
  {
    "ts": 1577059200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13670,
      "authorized": 13670,
      "funded": 2917
    },
    "payments": 19,
    "payments_amount": 12813684258,
    "trades": 230,
    "traded_amount": 264776498879,
    "price": [
      23.331124653422442,
      24.645372202313933,
      23.220994038065147,
      24.4568141131126
    ],
    "volume": 2420549264020
  },
  {
    "ts": 1577145600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13698,
      "authorized": 13698,
      "funded": 2917
    },
    "payments": 4,
    "payments_amount": 5203018864,
    "trades": 230,
    "traded_amount": 89069537437,
    "price": [
      24.4568141131126,
      24.7212946020263,
      24.227702900072565,
      24.451013615157454
    ],
    "volume": 1036743449925
  },
  {
    "ts": 1577232000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13710,
      "authorized": 13710,
      "funded": 2916
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 28,
    "traded_amount": 4226779355,
    "price": [
      24.451013615157454,
      25.155523903133822,
      24.451013615157454,
      24.912908001751216
    ],
    "volume": 64215407411
  },
  {
    "ts": 1577318400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13719,
      "authorized": 13719,
      "funded": 2918
    },
    "payments": 1,
    "payments_amount": 129800000,
    "trades": 173,
    "traded_amount": 32789133172,
    "price": [
      24.912908001751216,
      25.013383324988293,
      24.29689510036833,
      24.665281760196617
    ],
    "volume": 354849988904
  },
  {
    "ts": 1577404800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13723,
      "authorized": 13723,
      "funded": 2916
    },
    "payments": 4,
    "payments_amount": 11463954995,
    "trades": 100,
    "traded_amount": 10101311914,
    "price": [
      24.665281760196617,
      25.045163302674407,
      24.233005546849547,
      24.33880540056147
    ],
    "volume": 94743078615
  },
  {
    "ts": 1577491200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13725,
      "authorized": 13725,
      "funded": 2918
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 179,
    "traded_amount": 55513404727,
    "price": [
      24.33880540056147,
      24.33880540056147,
      24.04900635841992,
      24.049213302967317
    ],
    "volume": 656660541715
  },
  {
    "ts": 1577577600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13728,
      "authorized": 13728,
      "funded": 2917
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 978895582,
    "price": [
      24.049213302967317,
      24.100045002299694,
      23.948252610236587,
      23.948252610236587
    ],
    "volume": 13474360220
  },
  {
    "ts": 1577664000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13730,
      "authorized": 13730,
      "funded": 2916
    },
    "payments": 6,
    "payments_amount": 37973800000,
    "trades": 319,
    "traded_amount": 8744423522,
    "price": [
      23.948252610236587,
      24.460159403387514,
      23.762656505171474,
      24.25
    ],
    "volume": 109790266376
  },
  {
    "ts": 1577750400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13732,
      "authorized": 13732,
      "funded": 2916
    },
    "payments": 9,
    "payments_amount": 408148940178,
    "trades": 35,
    "traded_amount": 6827132867,
    "price": [
      24.25,
      24.89572292665442,
      24.25,
      24.781796273452475
    ],
    "volume": 84643520346
  },
  {
    "ts": 1577836800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13732,
      "authorized": 13732,
      "funded": 2916
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 4,
    "traded_amount": 3129544,
    "price": [
      24.781796273452475,
      24.781796273452475,
      24.628276975994456,
      24.628276975994456
    ],
    "volume": 38561400
  },
  {
    "ts": 1577923200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13739,
      "authorized": 13739,
      "funded": 2918
    },
    "payments": 5,
    "payments_amount": 7115498263,
    "trades": 160,
    "traded_amount": 5939970280,
    "price": [
      24.628276975994456,
      25.367630057608256,
      24.620949338201733,
      25.367630057608256
    ],
    "volume": 116572218416
  },
  {
    "ts": 1578009600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13743,
      "authorized": 13743,
      "funded": 2919
    },
    "payments": 9,
    "payments_amount": 78172600000,
    "trades": 334,
    "traded_amount": 101970703153,
    "price": [
      25.367630057608256,
      25.367630057608256,
      24.30416271800167,
      24.386779812416258
    ],
    "volume": 1360136931318
  },
  {
    "ts": 1578096000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13745,
      "authorized": 13745,
      "funded": 2922
    },
    "payments": 2,
    "payments_amount": 300000000,
    "trades": 38,
    "traded_amount": 4628903090,
    "price": [
      24.386779812416258,
      24.47257990982605,
      24.240543625175523,
      24.24983136841828
    ],
    "volume": 88275290101
  },
  {
    "ts": 1578182400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13753,
      "authorized": 13753,
      "funded": 2927
    },
    "payments": 3,
    "payments_amount": 192238163,
    "trades": 497,
    "traded_amount": 10181448075,
    "price": [
      24.24983136841828,
      24.428571428571427,
      24.056365608871936,
      24.428571428571427
    ],
    "volume": 185934602062
  },
  {
    "ts": 1578268800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13757,
      "authorized": 13757,
      "funded": 2925
    },
    "payments": 7,
    "payments_amount": 43352485665,
    "trades": 336,
    "traded_amount": 56158853411,
    "price": [
      24.428571428571427,
      24.428571428571427,
      22.146186946290694,
      22.181457630545708
    ],
    "volume": 355418970154
  },
  {
    "ts": 1578355200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13766,
      "authorized": 13766,
      "funded": 2910
    },
    "payments": 26,
    "payments_amount": 520010295332,
    "trades": 107,
    "traded_amount": 100861019542,
    "price": [
      22.181457630545708,
      23.22114424508616,
      21.910316690209488,
      22.765848704581146
    ],
    "volume": 875514263360
  },
  {
    "ts": 1578441600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13769,
      "authorized": 13769,
      "funded": 2911
    },
    "payments": 4,
    "payments_amount": 26151300000,
    "trades": 508,
    "traded_amount": 59989698480,
    "price": [
      22.765848704581146,
      23.488831307672445,
      22.414985590778098,
      23.38520730076008
    ],
    "volume": 726975854459
  },
  {
    "ts": 1578528000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13770,
      "authorized": 13770,
      "funded": 2912
    },
    "payments": 3,
    "payments_amount": 601500000000,
    "trades": 37,
    "traded_amount": 5352118464,
    "price": [
      23.38520730076008,
      23.62643704275912,
      22.907145882851836,
      23.62643704275912
    ],
    "volume": 111604511519
  },
  {
    "ts": 1578614400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13770,
      "authorized": 13770,
      "funded": 2909
    },
    "payments": 1,
    "payments_amount": 30000000000,
    "trades": 249,
    "traded_amount": 178394095456,
    "price": [
      23.62643704275912,
      24.32557737804649,
      23.157475459537626,
      23.157475461360548
    ],
    "volume": 2131331685049
  },
  {
    "ts": 1578700800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13771,
      "authorized": 13771,
      "funded": 2882
    },
    "payments": 32,
    "payments_amount": 389987049,
    "trades": 341,
    "traded_amount": 20504774735,
    "price": [
      23.157475461360548,
      23.33107020503411,
      22.58768300091025,
      23.05402243685884
    ],
    "volume": 290021949960
  },
  {
    "ts": 1578787200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13776,
      "authorized": 13776,
      "funded": 2885
    },
    "payments": 3,
    "payments_amount": 25102640178,
    "trades": 64,
    "traded_amount": 331340874470,
    "price": [
      23.05402243685884,
      23.05402243685884,
      22.57946556216671,
      22.826865302797277
    ],
    "volume": 1952045398094
  },
  {
    "ts": 1578873600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13779,
      "authorized": 13779,
      "funded": 2885
    },
    "payments": 4,
    "payments_amount": 54039836964,
    "trades": 371,
    "traded_amount": 274426428699,
    "price": [
      22.826865302797277,
      23.287549416415022,
      22.781257912594317,
      23.090688676575237
    ],
    "volume": 1453062045872
  },
  {
    "ts": 1578960000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13783,
      "authorized": 13783,
      "funded": 2891
    },
    "payments": 31,
    "payments_amount": 16366481862,
    "trades": 1362,
    "traded_amount": 1428758543581,
    "price": [
      23.090688676575237,
      23.194703674134974,
      20.14711106974787,
      21.015997375664558
    ],
    "volume": 9980101418491
  },
  {
    "ts": 1579046400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13782,
      "authorized": 13782,
      "funded": 2905
    },
    "payments": 37,
    "payments_amount": 48467876555,
    "trades": 896,
    "traded_amount": 1815575228576,
    "price": [
      21.015997375664558,
      21.53897562403713,
      19.71374580610965,
      20.338762425224438
    ],
    "volume": 10576464367151
  },
  {
    "ts": 1579132800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13787,
      "authorized": 13787,
      "funded": 2906
    },
    "payments": 14,
    "payments_amount": 13851583352,
    "trades": 1286,
    "traded_amount": 1633936828863,
    "price": [
      20.338762425224438,
      21.30901264481757,
      20.320741900703975,
      20.797197243001868
    ],
    "volume": 9992008924260
  },
  {
    "ts": 1579219200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13794,
      "authorized": 13794,
      "funded": 2906
    },
    "payments": 7,
    "payments_amount": 14057689882,
    "trades": 1049,
    "traded_amount": 2469337064090,
    "price": [
      20.797197243001868,
      20.97307230647128,
      17.208771654945412,
      18.173126870737605
    ],
    "volume": 23094590167247
  },
  {
    "ts": 1579305600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13794,
      "authorized": 13794,
      "funded": 2905
    },
    "payments": 6,
    "payments_amount": 1302612116,
    "trades": 995,
    "traded_amount": 1965237647849,
    "price": [
      18.173126870737605,
      19.120202979514676,
      16.498327498315966,
      17.94797169372544
    ],
    "volume": 17691696107022
  },
  {
    "ts": 1579392000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13795,
      "authorized": 13795,
      "funded": 2903
    },
    "payments": 3,
    "payments_amount": 2120044176,
    "trades": 1089,
    "traded_amount": 1041535651587,
    "price": [
      17.94797169372544,
      18.89710510024122,
      16.78229873819593,
      18.451415521646442
    ],
    "volume": 9533677514119
  },
  {
    "ts": 1579478400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13800,
      "authorized": 13800,
      "funded": 2903
    },
    "payments": 6,
    "payments_amount": 522022820147,
    "trades": 662,
    "traded_amount": 1135334612572,
    "price": [
      18.451415521646442,
      18.685467100719382,
      17.11862689299411,
      17.721823029812548
    ],
    "volume": 10334203452466
  },
  {
    "ts": 1579564800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13803,
      "authorized": 13803,
      "funded": 2904
    },
    "payments": 1,
    "payments_amount": 2000000000,
    "trades": 163,
    "traded_amount": 305731479963,
    "price": [
      17.721823029812548,
      17.96996858694858,
      17.211938447652308,
      17.671736546027233
    ],
    "volume": 2883835088020
  },
  {
    "ts": 1579651200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13810,
      "authorized": 13810,
      "funded": 2906
    },
    "payments": 9,
    "payments_amount": 5087029171,
    "trades": 297,
    "traded_amount": 154887086340,
    "price": [
      17.671736546027233,
      18.21994756289315,
      17.480989422605028,
      18.209496182991156
    ],
    "volume": 1393081937131
  },
  {
    "ts": 1579737600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13809,
      "authorized": 13809,
      "funded": 2907
    },
    "payments": 3,
    "payments_amount": 1014986600000,
    "trades": 321,
    "traded_amount": 813457562431,
    "price": [
      18.209496182991156,
      19.3774368,
      18.209496182991156,
      19.113770898184885
    ],
    "volume": 6531143726226
  },
  {
    "ts": 1579824000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13815,
      "authorized": 13815,
      "funded": 2893
    },
    "payments": 21,
    "payments_amount": 1363544282364,
    "trades": 295,
    "traded_amount": 366314265953,
    "price": [
      19.113770898184885,
      20.074315114222987,
      18.99220823645886,
      19.024789300053904
    ],
    "volume": 3305424652489
  },
  {
    "ts": 1579910400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13814,
      "authorized": 13814,
      "funded": 2892
    },
    "payments": 2,
    "payments_amount": 610311058,
    "trades": 224,
    "traded_amount": 30301947866,
    "price": [
      19.024789300053904,
      20.767008703526436,
      19.024789300053904,
      19.576175624363433
    ],
    "volume": 558677997084
  },
  {
    "ts": 1579996800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13818,
      "authorized": 13818,
      "funded": 2893
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 224,
    "traded_amount": 12106288386,
    "price": [
      19.576175624363433,
      19.719729713420975,
      18.930576070901033,
      19
    ],
    "volume": 234864186523
  },
  {
    "ts": 1580083200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13819,
      "authorized": 13819,
      "funded": 2890
    },
    "payments": 3,
    "payments_amount": 1200103111932,
    "trades": 254,
    "traded_amount": 229808965475,
    "price": [
      19,
      19,
      18.262638659956494,
      18.498026782793346
    ],
    "volume": 1304202668929
  },
  {
    "ts": 1580169600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13824,
      "authorized": 13824,
      "funded": 2892
    },
    "payments": 11,
    "payments_amount": 21764952028,
    "trades": 494,
    "traded_amount": 298917096073,
    "price": [
      18.498026782793346,
      18.744904600428793,
      17.78562070111827,
      18.159597437682248
    ],
    "volume": 1418359448013
  },
  {
    "ts": 1580256000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13832,
      "authorized": 13832,
      "funded": 2897
    },
    "payments": 4,
    "payments_amount": 6618100451,
    "trades": 334,
    "traded_amount": 115183200538,
    "price": [
      18.159597437682248,
      18.303350400099742,
      11.936302400694663,
      18.003881122070236
    ],
    "volume": 864437210927
  },
  {
    "ts": 1580342400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13834,
      "authorized": 13834,
      "funded": 2893
    },
    "payments": 11,
    "payments_amount": 639160727539,
    "trades": 329,
    "traded_amount": 628397642595,
    "price": [
      18.003881122070236,
      26.5,
      18.003881122070236,
      18.07828841098116
    ],
    "volume": 1586206409316
  },
  {
    "ts": 1580428800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13839,
      "authorized": 13839,
      "funded": 2894
    },
    "payments": 63,
    "payments_amount": 590498778119,
    "trades": 302,
    "traded_amount": 11665864999,
    "price": [
      18.07828841098116,
      18.525,
      17.890399829622982,
      18.466947856242296
    ],
    "volume": 84684471368
  },
  {
    "ts": 1580515200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13845,
      "authorized": 13845,
      "funded": 2897
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 68,
    "traded_amount": 61213735625,
    "price": [
      18.466947856242296,
      18.615101802226523,
      18.010779600211805,
      18.010779600211805
    ],
    "volume": 121565265455
  },
  {
    "ts": 1580601600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13851,
      "authorized": 13851,
      "funded": 2895
    },
    "payments": 2,
    "payments_amount": 135369292,
    "trades": 310,
    "traded_amount": 243350453671,
    "price": [
      18.010779600211805,
      18.31389844162372,
      17.05263157894737,
      17.712495702748996
    ],
    "volume": 1100779840931
  },
  {
    "ts": 1580688000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13853,
      "authorized": 13853,
      "funded": 2896
    },
    "payments": 2,
    "payments_amount": 3838761569,
    "trades": 292,
    "traded_amount": 184612114796,
    "price": [
      17.712495702748996,
      17.768916801341728,
      17.08195752344976,
      17.41326950098215
    ],
    "volume": 946902196118
  },
  {
    "ts": 1580774400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13859,
      "authorized": 13859,
      "funded": 2899
    },
    "payments": 6,
    "payments_amount": 2017421403,
    "trades": 289,
    "traded_amount": 30060352545,
    "price": [
      17.41326950098215,
      17.93660844150781,
      16.655170396328796,
      16.725624715403352
    ],
    "volume": 354532777291
  },
  {
    "ts": 1580860800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13868,
      "authorized": 13868,
      "funded": 2900
    },
    "payments": 7,
    "payments_amount": 129557000239,
    "trades": 138,
    "traded_amount": 18724878835,
    "price": [
      16.725624715403352,
      16.767387703400566,
      16.06259234873557,
      16.142323901934553
    ],
    "volume": 250300258776
  },
  {
    "ts": 1580947200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13874,
      "authorized": 13874,
      "funded": 2907
    },
    "payments": 10,
    "payments_amount": 29434016768,
    "trades": 282,
    "traded_amount": 162523458760,
    "price": [
      16.142323901934553,
      16.354914727169852,
      15.5,
      15.812792006480493
    ],
    "volume": 554978330772
  },
  {
    "ts": 1581033600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13886,
      "authorized": 13886,
      "funded": 2914
    },
    "payments": 12,
    "payments_amount": 549559346183,
    "trades": 197,
    "traded_amount": 121541728062,
    "price": [
      15.812792006480493,
      15.812792006480493,
      15.038408024059926,
      15.352669552669553
    ],
    "volume": 501049989503
  },
  {
    "ts": 1581120000,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13888,
      "authorized": 13888,
      "funded": 2915
    },
    "payments": 2,
    "payments_amount": 100121794871,
    "trades": 499,
    "traded_amount": 176279962495,
    "price": [
      15.352669552669553,
      16.412285714285716,
      15.339827650805546,
      15.541343023428793
    ],
    "volume": 1178876725080
  },
  {
    "ts": 1581206400,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13895,
      "authorized": 13895,
      "funded": 2903
    },
    "payments": 13,
    "payments_amount": 15240805,
    "trades": 54,
    "traded_amount": 56725232815,
    "price": [
      15.541343023428793,
      15.541343023428793,
      15.010912870155897,
      15.109369159099138
    ],
    "volume": 354164010718
  },
  {
    "ts": 1581292800,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13897,
      "authorized": 13897,
      "funded": 2901
    },
    "payments": 7,
    "payments_amount": 40241324184,
    "trades": 128,
    "traded_amount": 166031992535,
    "price": [
      15.109369159099138,
      16.14576456270631,
      15.109199927728293,
      15.5827561208778
    ],
    "volume": 1327938147134
  },
  {
    "ts": 1581379200,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13905,
      "authorized": 13905,
      "funded": 2908
    },
    "payments": 6,
    "payments_amount": 80000000,
    "trades": 261,
    "traded_amount": 116396134357,
    "price": [
      15.5827561208778,
      15.952016334205107,
      14.649786212953122,
      14.738111097460783
    ],
    "volume": 936838630087
  },
  {
    "ts": 1581465600,
    "supply": 5098996338039,
    "trustlines": {
      "total": 13908,
      "authorized": 13908,
      "funded": 2909
    },
    "payments": 6,
    "payments_amount": 14198241174,
    "trades": 482,
    "traded_amount": 142755854722,
    "price": [
      14.738111097460783,
      14.74702209561543,
      13.313183787539339,
      13.313183787539339
    ],
    "volume": 1099050253129
  },
  {
    "ts": 1581552000,
    "supply": 5098996338029,
    "trustlines": {
      "total": 13911,
      "authorized": 13911,
      "funded": 2910
    },
    "payments": 4,
    "payments_amount": 16554599550,
    "trades": 408,
    "traded_amount": 238304982707,
    "price": [
      13.313183787539339,
      13.638537304629903,
      12.5107407,
      13.203370208089977
    ],
    "volume": 1746539454187
  },
  {
    "ts": 1581638400,
    "supply": 5098996338029,
    "trustlines": {
      "total": 13915,
      "authorized": 13915,
      "funded": 2910
    },
    "payments": 10,
    "payments_amount": 543023548519,
    "trades": 453,
    "traded_amount": 142860784879,
    "price": [
      13.203370208089977,
      13.526753209515649,
      12.454819421311637,
      12.611285112471698
    ],
    "volume": 1568868436444
  },
  {
    "ts": 1581724800,
    "supply": 5098996338029,
    "trustlines": {
      "total": 13915,
      "authorized": 13915,
      "funded": 2912
    },
    "payments": 6,
    "payments_amount": 402826813,
    "trades": 312,
    "traded_amount": 162710418217,
    "price": [
      12.611285112471698,
      14.122041334522681,
      12.301916300892058,
      13.800107275573723
    ],
    "volume": 1175522690658
  },
  {
    "ts": 1581811200,
    "supply": 5098996338029,
    "trustlines": {
      "total": 13917,
      "authorized": 13917,
      "funded": 2910
    },
    "payments": 43,
    "payments_amount": 121611089,
    "trades": 450,
    "traded_amount": 60669107758,
    "price": [
      13.800107275573723,
      16.128564009228043,
      13.410161724618307,
      14.504645480323422
    ],
    "volume": 573843568798
  },
  {
    "ts": 1581897600,
    "supply": 5098996338029,
    "trustlines": {
      "total": 13923,
      "authorized": 13923,
      "funded": 2912
    },
    "payments": 25,
    "payments_amount": 9463463,
    "trades": 515,
    "traded_amount": 17563948798,
    "price": [
      14.504645480323422,
      15.928208376917192,
      14.433700948546818,
      15.094202859088826
    ],
    "volume": 212040576367
  },
  {
    "ts": 1581984000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13929,
      "authorized": 13929,
      "funded": 2908
    },
    "payments": 20,
    "payments_amount": 333997751,
    "trades": 432,
    "traded_amount": 45675134602,
    "price": [
      15.094202859088826,
      15.327181104800276,
      14.036169421627285,
      14.036169421627285
    ],
    "volume": 287173078155
  },
  {
    "ts": 1582070400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13925,
      "authorized": 13925,
      "funded": 2903
    },
    "payments": 25,
    "payments_amount": 337758540,
    "trades": 368,
    "traded_amount": 84864609249,
    "price": [
      14.036169421627285,
      15.469746590081108,
      13.646641581574595,
      15.336294253871761
    ],
    "volume": 681684235252
  },
  {
    "ts": 1582156800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13927,
      "authorized": 13927,
      "funded": 2905
    },
    "payments": 5,
    "payments_amount": 500204950155,
    "trades": 213,
    "traded_amount": 3620283996,
    "price": [
      15.336294253871761,
      15.684475700898922,
      15.006666229536862,
      15.142857142857142
    ],
    "volume": 41463256773
  },
  {
    "ts": 1582243200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13929,
      "authorized": 13929,
      "funded": 2908
    },
    "payments": 38,
    "payments_amount": 100100131460,
    "trades": 256,
    "traded_amount": 197918816583,
    "price": [
      15.142857142857142,
      15.493264525283712,
      14.96185573,
      15.493264525283712
    ],
    "volume": 1633879366495
  },
  {
    "ts": 1582329600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13932,
      "authorized": 13932,
      "funded": 2909
    },
    "payments": 20,
    "payments_amount": 10587946,
    "trades": 179,
    "traded_amount": 3748050087,
    "price": [
      15.493264525283712,
      15.523731724686858,
      15.21373840072079,
      15.4491685
    ],
    "volume": 48048106916
  },
  {
    "ts": 1582416000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13936,
      "authorized": 13936,
      "funded": 2909
    },
    "payments": 1,
    "payments_amount": 425994831,
    "trades": 37,
    "traded_amount": 44353023825,
    "price": [
      15.4491685,
      15.4491685,
      14.832855962,
      14.860000001830764
    ],
    "volume": 660966753180
  },
  {
    "ts": 1582502400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13937,
      "authorized": 13937,
      "funded": 2909
    },
    "payments": 5,
    "payments_amount": 5002963114,
    "trades": 22,
    "traded_amount": 5088397396,
    "price": [
      14.860000001830764,
      15.60112970114528,
      14.860000001830764,
      15.601121776338614
    ],
    "volume": 27246977067
  },
  {
    "ts": 1582588800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13941,
      "authorized": 13941,
      "funded": 2909
    },
    "payments": 23,
    "payments_amount": 1082823260825,
    "trades": 36,
    "traded_amount": 6140608080,
    "price": [
      15.601121776338614,
      17.07122627063806,
      15.601121776338614,
      17.071217010581673
    ],
    "volume": 75842947238
  },
  {
    "ts": 1582675200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13940,
      "authorized": 13940,
      "funded": 2911
    },
    "payments": 9,
    "payments_amount": 556123072923,
    "trades": 40,
    "traded_amount": 115397955110,
    "price": [
      17.071217010581673,
      19.05211060109802,
      17.071217010581673,
      18.43419909957697
    ],
    "volume": 857663613448
  },
  {
    "ts": 1582761600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13940,
      "authorized": 13940,
      "funded": 2910
    },
    "payments": 6,
    "payments_amount": 519440265298,
    "trades": 180,
    "traded_amount": 447067039144,
    "price": [
      18.43419909957697,
      19.03467556,
      17.458633971190977,
      18.422506443612313
    ],
    "volume": 3845069831365
  },
  {
    "ts": 1582848000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13940,
      "authorized": 13940,
      "funded": 2912
    },
    "payments": 7,
    "payments_amount": 11610363024,
    "trades": 156,
    "traded_amount": 141239344192,
    "price": [
      18.422506443612313,
      19.392322191797824,
      18.008964663025154,
      18.910070044329622
    ],
    "volume": 1048589137918
  },
  {
    "ts": 1582934400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13941,
      "authorized": 13941,
      "funded": 2912
    },
    "payments": 1,
    "payments_amount": 2000000,
    "trades": 62,
    "traded_amount": 112913192473,
    "price": [
      18.910070044329622,
      19.199262439945034,
      18.419068320587115,
      19.199262439945034
    ],
    "volume": 1872634147890
  },
  {
    "ts": 1583020800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13943,
      "authorized": 13943,
      "funded": 2914
    },
    "payments": 1,
    "payments_amount": 1820033,
    "trades": 32,
    "traded_amount": 59208802876,
    "price": [
      19.199262439945034,
      20.179999996848224,
      19.06628587161113,
      19.762277800735323
    ],
    "volume": 1151639539871
  },
  {
    "ts": 1583107200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13945,
      "authorized": 13945,
      "funded": 2918
    },
    "payments": 14,
    "payments_amount": 114873710747,
    "trades": 41,
    "traded_amount": 25381744243,
    "price": [
      19.762277800735323,
      19.762277800735323,
      18.54365547370833,
      18.576829067240492
    ],
    "volume": 198542263619
  },
  {
    "ts": 1583193600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13951,
      "authorized": 13951,
      "funded": 2916
    },
    "payments": 14,
    "payments_amount": 262000000,
    "trades": 63,
    "traded_amount": 183531769310,
    "price": [
      18.576829067240492,
      19.14039790820771,
      18.568136386811034,
      19.1350329177756
    ],
    "volume": 2006477161848
  },
  {
    "ts": 1583280000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13954,
      "authorized": 13954,
      "funded": 2917
    },
    "payments": 2,
    "payments_amount": 10094500000,
    "trades": 66,
    "traded_amount": 38109689124,
    "price": [
      19.1350329177756,
      19.235445109749946,
      18.75310598,
      19.172068729916987
    ],
    "volume": 123418467419
  },
  {
    "ts": 1583366400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13956,
      "authorized": 13956,
      "funded": 2917
    },
    "payments": 6,
    "payments_amount": 21528002979,
    "trades": 25,
    "traded_amount": 43833624200,
    "price": [
      19.172068729916987,
      19.172068729916987,
      18.181851239227193,
      18.570915621209814
    ],
    "volume": 326014070939
  },
  {
    "ts": 1583452800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13956,
      "authorized": 13956,
      "funded": 2920
    },
    "payments": 7,
    "payments_amount": 57893989033,
    "trades": 17,
    "traded_amount": 45821831990,
    "price": [
      18.570915621209814,
      18.782843718405694,
      18.305094684622613,
      18.305094684622613
    ],
    "volume": 377348833819
  },
  {
    "ts": 1583539200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13955,
      "authorized": 13955,
      "funded": 2919
    },
    "payments": 3,
    "payments_amount": 771486690,
    "trades": 26,
    "traded_amount": 3070228472,
    "price": [
      18.305094684622613,
      18.814549603353843,
      18.305094684622613,
      18.814540558734432
    ],
    "volume": 26951695207
  },
  {
    "ts": 1583625600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13957,
      "authorized": 13957,
      "funded": 2919
    },
    "payments": 4,
    "payments_amount": 390926722,
    "trades": 26,
    "traded_amount": 23367881690,
    "price": [
      18.814540558734432,
      22.409616414714083,
      18.814540558734432,
      21.11441889904312
    ],
    "volume": 305820461301
  },
  {
    "ts": 1583712000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13961,
      "authorized": 13961,
      "funded": 2926
    },
    "payments": 25,
    "payments_amount": 140016414226,
    "trades": 158,
    "traded_amount": 247199435873,
    "price": [
      21.11441889904312,
      23.49844909916717,
      21.11441889904312,
      21.78020605410227
    ],
    "volume": 2019605552304
  },
  {
    "ts": 1583798400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13962,
      "authorized": 13962,
      "funded": 2923
    },
    "payments": 4,
    "payments_amount": 502526125618,
    "trades": 81,
    "traded_amount": 131308671668,
    "price": [
      21.78020605410227,
      21.909420404092792,
      21.020768518,
      21.253123196486918
    ],
    "volume": 314051405772
  },
  {
    "ts": 1583884800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13969,
      "authorized": 13969,
      "funded": 2928
    },
    "payments": 10,
    "payments_amount": 24972200000,
    "trades": 312,
    "traded_amount": 539595143504,
    "price": [
      21.253123196486918,
      23.098632569012914,
      21.249770902142895,
      21.869667100154675
    ],
    "volume": 1560992067077
  },
  {
    "ts": 1583971200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13975,
      "authorized": 13975,
      "funded": 2930
    },
    "payments": 20,
    "payments_amount": 190331186487,
    "trades": 333,
    "traded_amount": 338792370831,
    "price": [
      21.869667100154675,
      33.78904630251181,
      21.869667100154675,
      33.78904630251181
    ],
    "volume": 1641576168357
  },
  {
    "ts": 1584057600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13980,
      "authorized": 13980,
      "funded": 2934
    },
    "payments": 10,
    "payments_amount": 215607540,
    "trades": 1194,
    "traded_amount": 414133037655,
    "price": [
      33.78904630251181,
      35.737065101971346,
      26.5,
      26.93091472615494
    ],
    "volume": 9603356211039
  },
  {
    "ts": 1584144000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13984,
      "authorized": 13984,
      "funded": 2929
    },
    "payments": 14,
    "payments_amount": 50320294932,
    "trades": 122,
    "traded_amount": 150479865422,
    "price": [
      26.93091472615494,
      29.59926131117267,
      26.93091472615494,
      29.410207693430312
    ],
    "volume": 2250541651074
  },
  {
    "ts": 1584230400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13985,
      "authorized": 13985,
      "funded": 2930
    },
    "payments": 10,
    "payments_amount": 2146770668,
    "trades": 155,
    "traded_amount": 80216065316,
    "price": [
      29.410207693430312,
      29.77708671858949,
      27.00039082449805,
      28.570066730219256
    ],
    "volume": 988263851129
  },
  {
    "ts": 1584316800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13989,
      "authorized": 13989,
      "funded": 2937
    },
    "payments": 31,
    "payments_amount": 57189412549,
    "trades": 935,
    "traded_amount": 419610773587,
    "price": [
      28.570066730219256,
      35,
      28.422333087542118,
      30.78495473102095
    ],
    "volume": 7446645733579
  },
  {
    "ts": 1584403200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13995,
      "authorized": 13995,
      "funded": 2941
    },
    "payments": 17,
    "payments_amount": 1106791383589,
    "trades": 236,
    "traded_amount": 767362840903,
    "price": [
      30.78495473102095,
      30.78495473102095,
      27.797081248045963,
      27.827093571384843
    ],
    "volume": 1102686728535
  },
  {
    "ts": 1584489600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13997,
      "authorized": 13997,
      "funded": 2939
    },
    "payments": 9,
    "payments_amount": 102627061724,
    "trades": 439,
    "traded_amount": 191173162896,
    "price": [
      27.827093571384843,
      30.15579710114781,
      27.809840334110238,
      28.50960551233202
    ],
    "volume": 1266289994430
  },
  {
    "ts": 1584576000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 13997,
      "authorized": 13997,
      "funded": 2940
    },
    "payments": 178,
    "payments_amount": 50741990009,
    "trades": 53,
    "traded_amount": 49221703858,
    "price": [
      28.50960551233202,
      28.50960551233202,
      24.284628002385062,
      24.284628002385062
    ],
    "volume": 641276811516
  },
  {
    "ts": 1584662400,
    "supply": 5098996338024,
    "trustlines": {
      "total": 14001,
      "authorized": 14001,
      "funded": 2939
    },
    "payments": 610,
    "payments_amount": 50602501706,
    "trades": 206,
    "traded_amount": 163883050448,
    "price": [
      24.284628002385062,
      28.666666666666668,
      23.271400367598268,
      26.2856369013426
    ],
    "volume": 1817978958120
  },
  {
    "ts": 1584748800,
    "supply": 5098996338024,
    "trustlines": {
      "total": 14077,
      "authorized": 14077,
      "funded": 2942
    },
    "payments": 200,
    "payments_amount": 921257725,
    "trades": 152,
    "traded_amount": 217271786803,
    "price": [
      26.2856369013426,
      29,
      25.320877998974478,
      26.23013340072815
    ],
    "volume": 3523402056681
  },
  {
    "ts": 1584835200,
    "supply": 5098996338024,
    "trustlines": {
      "total": 14137,
      "authorized": 14137,
      "funded": 2943
    },
    "payments": 62,
    "payments_amount": 99609147740,
    "trades": 366,
    "traded_amount": 389274997698,
    "price": [
      26.23013340072815,
      28.090020602083243,
      25.7209584642239,
      27.603263808300216
    ],
    "volume": 2985882021505
  },
  {
    "ts": 1584921600,
    "supply": 5098996338024,
    "trustlines": {
      "total": 14176,
      "authorized": 14176,
      "funded": 2945
    },
    "payments": 199,
    "payments_amount": 1033109608,
    "trades": 174,
    "traded_amount": 79703796272,
    "price": [
      27.603263808300216,
      28.074903845172734,
      25.781900588764106,
      26.25665548291884
    ],
    "volume": 399393296969
  },
  {
    "ts": 1585008000,
    "supply": 5098996338024,
    "trustlines": {
      "total": 14199,
      "authorized": 14199,
      "funded": 2945
    },
    "payments": 1156,
    "payments_amount": 406814068754,
    "trades": 270,
    "traded_amount": 600756098066,
    "price": [
      26.25665548291884,
      26.35788620198263,
      25.634793706992973,
      25.858502276082067
    ],
    "volume": 221509622489
  },
  {
    "ts": 1585094400,
    "supply": 3082296338024,
    "trustlines": {
      "total": 14267,
      "authorized": 14267,
      "funded": 2947
    },
    "payments": 17,
    "payments_amount": 2874646918429,
    "trades": 87,
    "traded_amount": 6790002969,
    "price": [
      25.858502276082067,
      26.53489200305478,
      25.62639096472825,
      25.758456350660456
    ],
    "volume": 105769127141
  },
  {
    "ts": 1585180800,
    "supply": 3082296338024,
    "trustlines": {
      "total": 14287,
      "authorized": 14287,
      "funded": 2950
    },
    "payments": 11,
    "payments_amount": 3460193428,
    "trades": 15,
    "traded_amount": 1512184419,
    "price": [
      25.758456350660456,
      27.16710757457533,
      25.758456350660456,
      25.78383096122112
    ],
    "volume": 5230156914
  },
  {
    "ts": 1585267200,
    "supply": 3082296338024,
    "trustlines": {
      "total": 14301,
      "authorized": 14301,
      "funded": 2950
    },
    "payments": 4,
    "payments_amount": 633019188683,
    "trades": 25,
    "traded_amount": 16741630988,
    "price": [
      25.78383096122112,
      26.22474822352379,
      25.720163649113886,
      26.22474822352379
    ],
    "volume": 218841089028
  },
  {
    "ts": 1585353600,
    "supply": 3082296338024,
    "trustlines": {
      "total": 14327,
      "authorized": 14327,
      "funded": 2947
    },
    "payments": 11,
    "payments_amount": 823435801,
    "trades": 11,
    "traded_amount": 2220849073,
    "price": [
      26.22474822352379,
      28.112362864535502,
      26.22474822352379,
      27.837551181443327
    ],
    "volume": 61340929494
  },
  {
    "ts": 1585440000,
    "supply": 3082296338024,
    "trustlines": {
      "total": 14364,
      "authorized": 14364,
      "funded": 2946
    },
    "payments": 2,
    "payments_amount": 550000000,
    "trades": 11,
    "traded_amount": 608611518,
    "price": [
      27.837551181443327,
      28.63570713257237,
      27.837551181443327,
      28.635707102406798
    ],
    "volume": 17330130742
  },
  {
    "ts": 1585526400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 14399,
      "authorized": 14399,
      "funded": 2948
    },
    "payments": 10,
    "payments_amount": 514626000000,
    "trades": 8,
    "traded_amount": 2020182796,
    "price": [
      28.635707102406798,
      28.635707102406798,
      26.802189848685,
      26.802189848685
    ],
    "volume": 36093946768
  },
  {
    "ts": 1585612800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 14454,
      "authorized": 14454,
      "funded": 2952
    },
    "payments": 8,
    "payments_amount": 37968924262,
    "trades": 13,
    "traded_amount": 486541280,
    "price": [
      26.802189848685,
      27.03354670431851,
      26.780000021417084,
      26.780000021417084
    ],
    "volume": 11827818621
  },
  {
    "ts": 1585699200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 14706,
      "authorized": 14706,
      "funded": 2954
    },
    "payments": 5,
    "payments_amount": 10497200000,
    "trades": 17,
    "traded_amount": 19330588673,
    "price": [
      26.780000021417084,
      27.8421485,
      26.68004140017933,
      26.681825100773324
    ],
    "volume": 259250220841
  },
  {
    "ts": 1585785600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 14851,
      "authorized": 14851,
      "funded": 2954
    },
    "payments": 9,
    "payments_amount": 22376773202,
    "trades": 47,
    "traded_amount": 47694879475,
    "price": [
      26.681825100773324,
      26.866986921150765,
      25.499994444382715,
      26.315443215026814
    ],
    "volume": 483519896758
  },
  {
    "ts": 1585872000,
    "supply": 3082296306425,
    "trustlines": {
      "total": 14976,
      "authorized": 14976,
      "funded": 2956
    },
    "payments": 3,
    "payments_amount": 9089882000,
    "trades": 9,
    "traded_amount": 418558969,
    "price": [
      26.315443215026814,
      26.560848290535063,
      25.533848777410356,
      26.14369465904522
    ],
    "volume": 10981738127
  },
  {
    "ts": 1585958400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 15135,
      "authorized": 15135,
      "funded": 2957
    },
    "payments": 9,
    "payments_amount": 520593398,
    "trades": 21,
    "traded_amount": 8918482746,
    "price": [
      26.14369465904522,
      26.284797559618333,
      25.757038500720572,
      25.757038500720572
    ],
    "volume": 117358063912
  },
  {
    "ts": 1586044800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 15312,
      "authorized": 15312,
      "funded": 2957
    },
    "payments": 3,
    "payments_amount": 444100000,
    "trades": 3,
    "traded_amount": 220000000,
    "price": [
      25.757038500720572,
      25.757038500720572,
      25.668602931052465,
      25.668602931052465
    ],
    "volume": 5648801080
  },
  {
    "ts": 1586131200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 15433,
      "authorized": 15433,
      "funded": 2961
    },
    "payments": 6,
    "payments_amount": 4105618640,
    "trades": 268,
    "traded_amount": 185659296654,
    "price": [
      25.668602931052465,
      25.668602931052465,
      21.73326140124225,
      21.770255334368823
    ],
    "volume": 3028825052332
  },
  {
    "ts": 1586217600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 15741,
      "authorized": 15741,
      "funded": 2960
    },
    "payments": 2,
    "payments_amount": 28495400000,
    "trades": 135,
    "traded_amount": 113281800709,
    "price": [
      21.770255334368823,
      22.366711174168227,
      20.795135800057242,
      22.218615020886162
    ],
    "volume": 1268718013380
  },
  {
    "ts": 1586304000,
    "supply": 3082296306425,
    "trustlines": {
      "total": 15893,
      "authorized": 15893,
      "funded": 2961
    },
    "payments": 3,
    "payments_amount": 2145515355,
    "trades": 137,
    "traded_amount": 83862706505,
    "price": [
      22.218615020886162,
      27.799999071292252,
      20.97280246935928,
      20.97280246935928
    ],
    "volume": 826392351663
  },
  {
    "ts": 1586390400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16034,
      "authorized": 16034,
      "funded": 2963
    },
    "payments": 4,
    "payments_amount": 1990400000,
    "trades": 22,
    "traded_amount": 4052870779,
    "price": [
      20.97280246935928,
      21.93033045674595,
      20.97280246935928,
      21.198162541068722
    ],
    "volume": 38007191679
  },
  {
    "ts": 1586476800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16134,
      "authorized": 16134,
      "funded": 2961
    },
    "payments": 6,
    "payments_amount": 21941600000,
    "trades": 89,
    "traded_amount": 48562084443,
    "price": [
      21.198162541068722,
      23.247618156947546,
      20.918827975426044,
      23.247618105802445
    ],
    "volume": 515081784630
  },
  {
    "ts": 1586563200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16211,
      "authorized": 16211,
      "funded": 2963
    },
    "payments": 3,
    "payments_amount": 1471000000,
    "trades": 63,
    "traded_amount": 1817466663,
    "price": [
      23.247618105802445,
      23.247618105802445,
      21.82662673849082,
      21.82662673849082
    ],
    "volume": 37291386404
  },
  {
    "ts": 1586649600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16249,
      "authorized": 16249,
      "funded": 2963
    },
    "payments": 2,
    "payments_amount": 216402041,
    "trades": 78,
    "traded_amount": 66253451579,
    "price": [
      21.82662673849082,
      22.14729030788005,
      21.198207479575526,
      21.817063102659347
    ],
    "volume": 489480290786
  },
  {
    "ts": 1586736000,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16290,
      "authorized": 16290,
      "funded": 2962
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 302,
    "traded_amount": 11091872461,
    "price": [
      21.817063102659347,
      23.12951603800642,
      21.75,
      22.576840280697873
    ],
    "volume": 116246983369
  },
  {
    "ts": 1586822400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16337,
      "authorized": 16337,
      "funded": 2962
    },
    "payments": 2,
    "payments_amount": 514291496,
    "trades": 128,
    "traded_amount": 2835141347,
    "price": [
      22.576840280697873,
      22.73073640081309,
      22.301516503122212,
      22.55788341825599
    ],
    "volume": 48372667861
  },
  {
    "ts": 1586908800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16376,
      "authorized": 16376,
      "funded": 2963
    },
    "payments": 3,
    "payments_amount": 518032100000,
    "trades": 16,
    "traded_amount": 2115457479,
    "price": [
      22.55788341825599,
      23.22539959494225,
      22.55788341825599,
      23.22539959494225
    ],
    "volume": 43979447931
  },
  {
    "ts": 1586995200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16420,
      "authorized": 16420,
      "funded": 2965
    },
    "payments": 6,
    "payments_amount": 503200010000,
    "trades": 71,
    "traded_amount": 34710769461,
    "price": [
      23.22539959494225,
      23.2317717715875,
      22.330068574072538,
      22.330118700762824
    ],
    "volume": 397129149445
  },
  {
    "ts": 1587081600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16446,
      "authorized": 16446,
      "funded": 2965
    },
    "payments": 4,
    "payments_amount": 502039800000,
    "trades": 8,
    "traded_amount": 2268210603,
    "price": [
      22.330118700762824,
      22.330118700762824,
      22,
      22.234799915165617
    ],
    "volume": 1097547479
  },
  {
    "ts": 1587168000,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16483,
      "authorized": 16483,
      "funded": 2967
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 15,
    "traded_amount": 769524512,
    "price": [
      22.234799915165617,
      22.234799915165617,
      21.395207206162503,
      21.40319335644878
    ],
    "volume": 12077110377
  },
  {
    "ts": 1587254400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16503,
      "authorized": 16503,
      "funded": 2967
    },
    "payments": 9,
    "payments_amount": 35510626,
    "trades": 969,
    "traded_amount": 1024304356,
    "price": [
      21.40319335644878,
      22.223900436015747,
      21.40319335644878,
      22.132067628670075
    ],
    "volume": 20740635556
  },
  {
    "ts": 1587340800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16525,
      "authorized": 16525,
      "funded": 2969
    },
    "payments": 3,
    "payments_amount": 126927162,
    "trades": 2830,
    "traded_amount": 42085256748,
    "price": [
      22.132067628670075,
      22.239470134629578,
      20.54983121563691,
      21.793984675951403
    ],
    "volume": 431988993766
  },
  {
    "ts": 1587427200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16541,
      "authorized": 16541,
      "funded": 2968
    },
    "payments": 4,
    "payments_amount": 500318708220,
    "trades": 1153,
    "traded_amount": 503481890,
    "price": [
      21.793984675951403,
      21.846937306861957,
      21.362089541608384,
      21.362089541608384
    ],
    "volume": 10869669613
  },
  {
    "ts": 1587513600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16555,
      "authorized": 16555,
      "funded": 2969
    },
    "payments": 5,
    "payments_amount": 503221100000,
    "trades": 37,
    "traded_amount": 2737057003,
    "price": [
      21.362089541608384,
      21.362089541608384,
      19.769956782874473,
      19.85490438261577
    ],
    "volume": 50868440564
  },
  {
    "ts": 1587600000,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16565,
      "authorized": 16565,
      "funded": 2969
    },
    "payments": 12,
    "payments_amount": 1840727299234,
    "trades": 241,
    "traded_amount": 119159707799,
    "price": [
      19.85490438261577,
      19.85490438261577,
      16.56290009755548,
      17.277178859999914
    ],
    "volume": 1068712711977
  },
  {
    "ts": 1587686400,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16572,
      "authorized": 16572,
      "funded": 2967
    },
    "payments": 3,
    "payments_amount": 250042908496,
    "trades": 69,
    "traded_amount": 32968887595,
    "price": [
      17.277178859999914,
      17.540727345939086,
      16.52892561983471,
      17.348614487204667
    ],
    "volume": 304724859178
  },
  {
    "ts": 1587772800,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16590,
      "authorized": 16590,
      "funded": 2969
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 509,
    "traded_amount": 45240233118,
    "price": [
      17.348614487204667,
      17.524792303885896,
      17.096998049470404,
      17.52443053617376
    ],
    "volume": 414802126459
  },
  {
    "ts": 1587859200,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16607,
      "authorized": 16607,
      "funded": 2968
    },
    "payments": 2,
    "payments_amount": 21469999,
    "trades": 10,
    "traded_amount": 1267162838,
    "price": [
      17.52443053617376,
      17.597380868942057,
      17.30713753270944,
      17.4111682181156
    ],
    "volume": 21770894898
  },
  {
    "ts": 1587945600,
    "supply": 3082296306425,
    "trustlines": {
      "total": 16614,
      "authorized": 16614,
      "funded": 2968
    },
    "payments": 3,
    "payments_amount": 52807500000,
    "trades": 35,
    "traded_amount": 513240678067,
    "price": [
      17.4111682181156,
      17.543731300003618,
      16,
      16.022456952102075
    ],
    "volume": 8901558881982
  },
  {
    "ts": 1588032000,
    "supply": 3082296306420,
    "trustlines": {
      "total": 16623,
      "authorized": 16623,
      "funded": 2968
    },
    "payments": 6,
    "payments_amount": 16717020004,
    "trades": 58,
    "traded_amount": 519975222325,
    "price": [
      16.022456952102075,
      16.198659039456487,
      15.263235002653696,
      15.698513192551491
    ],
    "volume": 7992349235067
  },
  {
    "ts": 1588118400,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16639,
      "authorized": 16639,
      "funded": 2969
    },
    "payments": 8,
    "payments_amount": 4127999800000,
    "trades": 49,
    "traded_amount": 46792261444,
    "price": [
      15.698513192551491,
      15.77095525619204,
      14.836214919011207,
      15.122187075146988
    ],
    "volume": 294771743759
  },
  {
    "ts": 1588204800,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16644,
      "authorized": 16644,
      "funded": 2970
    },
    "payments": 12,
    "payments_amount": 2059255249804,
    "trades": 440,
    "traded_amount": 722753762376,
    "price": [
      15.122187075146988,
      16.477200250756358,
      14.66225870140493,
      16.02617979784932
    ],
    "volume": 9584232094589
  },
  {
    "ts": 1588291200,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16655,
      "authorized": 16655,
      "funded": 2970
    },
    "payments": 7,
    "payments_amount": 16440000000,
    "trades": 232,
    "traded_amount": 522479719283,
    "price": [
      16.02617979784932,
      16.02617979784932,
      14.984816968373915,
      15.394632546182281
    ],
    "volume": 7874955767627
  },
  {
    "ts": 1588377600,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16660,
      "authorized": 16660,
      "funded": 2973
    },
    "payments": 3,
    "payments_amount": 1375743869,
    "trades": 53,
    "traded_amount": 6879206377,
    "price": [
      15.394632546182281,
      15.394632546182281,
      14.488805881587782,
      14.62796641154307
    ],
    "volume": 53578481032
  },
  {
    "ts": 1588464000,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16674,
      "authorized": 16674,
      "funded": 2973
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 48,
    "traded_amount": 14765405391,
    "price": [
      14.62796641154307,
      15.156491554988392,
      14.347346373376636,
      15.156491554988392
    ],
    "volume": 109603717242
  },
  {
    "ts": 1588550400,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16684,
      "authorized": 16684,
      "funded": 2975
    },
    "payments": 7,
    "payments_amount": 2008159753727,
    "trades": 92,
    "traded_amount": 33610660068,
    "price": [
      15.156491554988392,
      15.882447865460323,
      14.820842131458152,
      14.837519102085624
    ],
    "volume": 273619693706
  },
  {
    "ts": 1588636800,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16698,
      "authorized": 16698,
      "funded": 2980
    },
    "payments": 5,
    "payments_amount": 4072000000,
    "trades": 45,
    "traded_amount": 503220010475,
    "price": [
      14.837519102085624,
      15.154036,
      14.760182844231412,
      15.025201907171354
    ],
    "volume": 7591137320926
  },
  {
    "ts": 1588723200,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16705,
      "authorized": 16705,
      "funded": 2981
    },
    "payments": 12,
    "payments_amount": 3516523325038,
    "trades": 113,
    "traded_amount": 1017255767448,
    "price": [
      15.025201907171354,
      15.208417315957309,
      14.805445802601275,
      15.208398714787418
    ],
    "volume": 15059570069337
  },
  {
    "ts": 1588809600,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16716,
      "authorized": 16716,
      "funded": 2984
    },
    "payments": 6,
    "payments_amount": 1500096040867,
    "trades": 71,
    "traded_amount": 1007831255121,
    "price": [
      15.208398714787418,
      15.556153700635031,
      14.795375218150086,
      14.795434576762124
    ],
    "volume": 15325216165486
  },
  {
    "ts": 1588896000,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16727,
      "authorized": 16727,
      "funded": 2986
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 28,
    "traded_amount": 501492665609,
    "price": [
      14.795434576762124,
      15.266833100004165,
      14.69857629,
      14.69857629
    ],
    "volume": 7653724618427
  },
  {
    "ts": 1588982400,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16749,
      "authorized": 16749,
      "funded": 2985
    },
    "payments": 1,
    "payments_amount": 520,
    "trades": 38,
    "traded_amount": 24713261891,
    "price": [
      14.69857629,
      15.150000002500558,
      14.69857629,
      14.950115118956255
    ],
    "volume": 12686309110
  },
  {
    "ts": 1589068800,
    "supply": 4582296306420,
    "trustlines": {
      "total": 16779,
      "authorized": 16779,
      "funded": 2984
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 125,
    "traded_amount": 55171718841,
    "price": [
      14.950115118956255,
      18.395114942528735,
      14.950115118956255,
      18.395114942528735
    ],
    "volume": 230175875241
  },
  {
    "ts": 1589155200,
    "supply": 4982296306420,
    "trustlines": {
      "total": 16812,
      "authorized": 16812,
      "funded": 2986
    },
    "payments": 15,
    "payments_amount": 1784480005826,
    "trades": 89,
    "traded_amount": 578418943140,
    "price": [
      18.395114942528735,
      18.395114942528735,
      16.57471264367816,
      17.751741860848433
    ],
    "volume": 9022576453216
  },
  {
    "ts": 1589241600,
    "supply": 6032296306420,
    "trustlines": {
      "total": 16831,
      "authorized": 16831,
      "funded": 2989
    },
    "payments": 13,
    "payments_amount": 2051401800001,
    "trades": 59,
    "traded_amount": 503046396666,
    "price": [
      17.751741860848433,
      17.751741860848433,
      15.499987200010922,
      15.499987200010922
    ],
    "volume": 8009259901119
  },
  {
    "ts": 1589328000,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16854,
      "authorized": 16854,
      "funded": 2991
    },
    "payments": 2,
    "payments_amount": 1000100000000,
    "trades": 48,
    "traded_amount": 761851121,
    "price": [
      15.499987200010922,
      15.826242499625234,
      15.295290883333333,
      15.295290883333333
    ],
    "volume": 8414292766
  },
  {
    "ts": 1589414400,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16871,
      "authorized": 16871,
      "funded": 2991
    },
    "payments": 7,
    "payments_amount": 5709431910140,
    "trades": 35,
    "traded_amount": 1004952251509,
    "price": [
      15.295290883333333,
      15.893758456379787,
      15.295290883333333,
      15.874542903981139
    ],
    "volume": 15787097052056
  },
  {
    "ts": 1589500800,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16885,
      "authorized": 16885,
      "funded": 2992
    },
    "payments": 6,
    "payments_amount": 2707140126180,
    "trades": 24,
    "traded_amount": 1001067888628,
    "price": [
      15.874542903981139,
      16.059510123257542,
      15.714103879310345,
      16.059481177432495
    ],
    "volume": 15784002753236
  },
  {
    "ts": 1589587200,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16906,
      "authorized": 16906,
      "funded": 2993
    },
    "payments": 8,
    "payments_amount": 1000720200000,
    "trades": 8,
    "traded_amount": 11434871077,
    "price": [
      16.059481177432495,
      16.127189200082825,
      15.848588918833684,
      15.848588918833684
    ],
    "volume": 184318826159
  },
  {
    "ts": 1589673600,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16922,
      "authorized": 16922,
      "funded": 2991
    },
    "payments": 2,
    "payments_amount": 3000000000,
    "trades": 16,
    "traded_amount": 695954579,
    "price": [
      15.848588918833684,
      15.848588918833684,
      15.39565454392978,
      15.4335516
    ],
    "volume": 9345623418
  },
  {
    "ts": 1589760000,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16949,
      "authorized": 16949,
      "funded": 2989
    },
    "payments": 9,
    "payments_amount": 503716200000,
    "trades": 222,
    "traded_amount": 520967875709,
    "price": [
      15.4335516,
      15.770617000000529,
      15.23881509054861,
      15.529999614312318
    ],
    "volume": 7979877062066
  },
  {
    "ts": 1589846400,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16964,
      "authorized": 16964,
      "funded": 2990
    },
    "payments": 4,
    "payments_amount": 397200000,
    "trades": 36,
    "traded_amount": 990873656460,
    "price": [
      15.529999614312318,
      16.113363500000474,
      15.529999614312318,
      15.981931800000163
    ],
    "volume": 15780929507951
  },
  {
    "ts": 1589932800,
    "supply": 7032296306420,
    "trustlines": {
      "total": 16984,
      "authorized": 16984,
      "funded": 2992
    },
    "payments": 16,
    "payments_amount": 3107656595450,
    "trades": 100,
    "traded_amount": 540787713779,
    "price": [
      15.981931800000163,
      16.1860351395564,
      15.530000991768324,
      16.085980000006195
    ],
    "volume": 8223195794741
  },
  {
    "ts": 1590019200,
    "supply": 8532296306420,
    "trustlines": {
      "total": 17008,
      "authorized": 17008,
      "funded": 2992
    },
    "payments": 3,
    "payments_amount": 2514001600000,
    "trades": 144,
    "traded_amount": 556905075767,
    "price": [
      16.085980000006195,
      16.890606075483444,
      16.03131747520146,
      16.323301656340497
    ],
    "volume": 9052472629486
  },
  {
    "ts": 1590105600,
    "supply": 8532296306420,
    "trustlines": {
      "total": 17038,
      "authorized": 17038,
      "funded": 2994
    },
    "payments": 9,
    "payments_amount": 7008354200000,
    "trades": 7,
    "traded_amount": 211635566,
    "price": [
      16.323301656340497,
      16.610106560297954,
      15.84946489947467,
      15.84946489947467
    ],
    "volume": 3269498858
  },
  {
    "ts": 1590192000,
    "supply": 8532296306420,
    "trustlines": {
      "total": 17064,
      "authorized": 17064,
      "funded": 2994
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 16,
    "traded_amount": 504950299122,
    "price": [
      15.84946489947467,
      16.364308559660905,
      15.84946489947467,
      16.364255000003727
    ],
    "volume": 8188429179503
  },
  {
    "ts": 1590278400,
    "supply": 8532296306420,
    "trustlines": {
      "total": 17088,
      "authorized": 17088,
      "funded": 2994
    },
    "payments": 4,
    "payments_amount": 5000147771,
    "trades": 125,
    "traded_amount": 36473794721,
    "price": [
      16.364255000003727,
      16.91527000086189,
      16.114768666153452,
      16.91527000086189
    ],
    "volume": 320766008048
  },
  {
    "ts": 1590364800,
    "supply": 8532296306420,
    "trustlines": {
      "total": 17099,
      "authorized": 17099,
      "funded": 2991
    },
    "payments": 13,
    "payments_amount": 3250512310724,
    "trades": 35,
    "traded_amount": 31080664478,
    "price": [
      16.91527000086189,
      16.9575207,
      16.44812589556626,
      16.6
    ],
    "volume": 159340864144
  },
  {
    "ts": 1590451200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17114,
      "authorized": 17114,
      "funded": 2990
    },
    "payments": 12,
    "payments_amount": 2703728006778,
    "trades": 341,
    "traded_amount": 571632840187,
    "price": [
      16.6,
      17.195528100002253,
      16.6,
      17.195528100002253
    ],
    "volume": 9103193457885
  },
  {
    "ts": 1590537600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17130,
      "authorized": 17130,
      "funded": 2991
    },
    "payments": 10,
    "payments_amount": 515321860515,
    "trades": 110,
    "traded_amount": 538256584261,
    "price": [
      17.195528100002253,
      17.195528100002253,
      16.36120300012911,
      16.909696500000724
    ],
    "volume": 8691995791815
  },
  {
    "ts": 1590624000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17150,
      "authorized": 17150,
      "funded": 2992
    },
    "payments": 17,
    "payments_amount": 2502809811008,
    "trades": 144,
    "traded_amount": 1004936150775,
    "price": [
      16.909696500000724,
      16.909696500000724,
      15.87702239727515,
      16.031574350878373
    ],
    "volume": 16564137899612
  },
  {
    "ts": 1590710400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17178,
      "authorized": 17178,
      "funded": 2992
    },
    "payments": 5,
    "payments_amount": 3800047800000,
    "trades": 101,
    "traded_amount": 434982950629,
    "price": [
      16.031574350878373,
      16.66666666699621,
      15.83157894736842,
      16.55
    ],
    "volume": 7158419057233
  },
  {
    "ts": 1590796800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17201,
      "authorized": 17201,
      "funded": 2994
    },
    "payments": 2,
    "payments_amount": 189000000,
    "trades": 69,
    "traded_amount": 12596476931,
    "price": [
      16.55,
      16.55,
      15.60000002496,
      16.364122510553212
    ],
    "volume": 99240065880
  },
  {
    "ts": 1590883200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17222,
      "authorized": 17222,
      "funded": 2995
    },
    "payments": 4,
    "payments_amount": 114359155,
    "trades": 57,
    "traded_amount": 24044763990,
    "price": [
      16.364122510553212,
      16.364122510553212,
      15.131318664643398,
      15.198330602370312
    ],
    "volume": 186619943366
  },
  {
    "ts": 1590969600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17242,
      "authorized": 17242,
      "funded": 2995
    },
    "payments": 11,
    "payments_amount": 1037079308305,
    "trades": 558,
    "traded_amount": 1119019658653,
    "price": [
      15.198330602370312,
      15.20079810066401,
      14,
      14.800058589340301
    ],
    "volume": 15532955161434
  },
  {
    "ts": 1591056000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17256,
      "authorized": 17256,
      "funded": 2994
    },
    "payments": 7,
    "payments_amount": 4213702795950,
    "trades": 207,
    "traded_amount": 583394948023,
    "price": [
      14.800058589340301,
      14.9,
      13.2489814846962,
      14.037550454723357
    ],
    "volume": 7429583094482
  },
  {
    "ts": 1591142400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17272,
      "authorized": 17272,
      "funded": 2997
    },
    "payments": 12,
    "payments_amount": 1661621000000,
    "trades": 52,
    "traded_amount": 509841711888,
    "price": [
      14.037550454723357,
      14.3732892781133,
      13.591589116719243,
      13.758072652745716
    ],
    "volume": 7091595854186
  },
  {
    "ts": 1591228800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17291,
      "authorized": 17291,
      "funded": 2999
    },
    "payments": 5,
    "payments_amount": 1505531499997,
    "trades": 79,
    "traded_amount": 1035231815488,
    "price": [
      13.758072652745716,
      14.007678000000444,
      13.148616003139987,
      13.9252784
    ],
    "volume": 14111965982212
  },
  {
    "ts": 1591315200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17313,
      "authorized": 17313,
      "funded": 2998
    },
    "payments": 11,
    "payments_amount": 8683991615929,
    "trades": 76,
    "traded_amount": 502753035402,
    "price": [
      13.9252784,
      14.208273201039097,
      13.8222605,
      13.994409849273222
    ],
    "volume": 7128866260658
  },
  {
    "ts": 1591401600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17327,
      "authorized": 17327,
      "funded": 2999
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 86,
    "traded_amount": 3328212562,
    "price": [
      13.994409849273222,
      14.350000538133967,
      13.994409849273222,
      14.051149437571507
    ],
    "volume": 26799508300
  },
  {
    "ts": 1591488000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17351,
      "authorized": 17351,
      "funded": 2999
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 135,
    "traded_amount": 2065957465,
    "price": [
      14.051149437571507,
      14.7,
      14.051149437571507,
      14.43569624057273
    ],
    "volume": 22600384274
  },
  {
    "ts": 1591574400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17368,
      "authorized": 17368,
      "funded": 3004
    },
    "payments": 3,
    "payments_amount": 1500037200000,
    "trades": 340,
    "traded_amount": 12923377215,
    "price": [
      14.43569624057273,
      15.199838279799021,
      12.335019094971637,
      15.199838279799021
    ],
    "volume": 103791063504
  },
  {
    "ts": 1591660800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17381,
      "authorized": 17381,
      "funded": 3001
    },
    "payments": 8,
    "payments_amount": 1005174800000,
    "trades": 462,
    "traded_amount": 515258959429,
    "price": [
      15.199838279799021,
      15.199838279799021,
      14.17287943159754,
      14.540560445185287
    ],
    "volume": 7361260664196
  },
  {
    "ts": 1591747200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17400,
      "authorized": 17400,
      "funded": 3001
    },
    "payments": 7,
    "payments_amount": 2198373400002,
    "trades": 130,
    "traded_amount": 507232677720,
    "price": [
      14.540560445185287,
      14.691386201710918,
      14.484975249533846,
      14.538636995810535
    ],
    "volume": 7382426549375
  },
  {
    "ts": 1591833600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17423,
      "authorized": 17423,
      "funded": 3000
    },
    "payments": 4,
    "payments_amount": 506400000000,
    "trades": 122,
    "traded_amount": 17551401411,
    "price": [
      14.538636995810535,
      16.265505965989973,
      14.538636995810535,
      15.701992499224298
    ],
    "volume": 148648657143
  },
  {
    "ts": 1591920000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17440,
      "authorized": 17440,
      "funded": 2999
    },
    "payments": 4,
    "payments_amount": 1000148446270,
    "trades": 169,
    "traded_amount": 516269570328,
    "price": [
      15.701992499224298,
      15.900779594675631,
      15.186942118179312,
      15.4112676675892
    ],
    "volume": 7152766175370
  },
  {
    "ts": 1592006400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17456,
      "authorized": 17456,
      "funded": 3000
    },
    "payments": 1,
    "payments_amount": 10256162,
    "trades": 8,
    "traded_amount": 1848607879,
    "price": [
      15.4112676675892,
      15.944444444444445,
      15.358131154629229,
      15.358131154629229
    ],
    "volume": 868031831
  },
  {
    "ts": 1592092800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17477,
      "authorized": 17477,
      "funded": 2999
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 22,
    "traded_amount": 6075879017,
    "price": [
      15.358131154629229,
      15.910429806280106,
      15.358131154629229,
      15.560048774503203
    ],
    "volume": 38033285476
  },
  {
    "ts": 1592179200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17497,
      "authorized": 17497,
      "funded": 2999
    },
    "payments": 7,
    "payments_amount": 2000149856180,
    "trades": 91,
    "traded_amount": 19812797888,
    "price": [
      15.560048774503203,
      16.795553952292916,
      15.560048774503203,
      15.702571528312102
    ],
    "volume": 145926539686
  },
  {
    "ts": 1592265600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17505,
      "authorized": 17505,
      "funded": 3001
    },
    "payments": 2,
    "payments_amount": 761015704000,
    "trades": 54,
    "traded_amount": 12837598669,
    "price": [
      15.702571528312102,
      15.8503137,
      15.67031369894268,
      15.696111262348056
    ],
    "volume": 136771249367
  },
  {
    "ts": 1592352000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17528,
      "authorized": 17528,
      "funded": 3000
    },
    "payments": 14,
    "payments_amount": 4557993042253,
    "trades": 44,
    "traded_amount": 8983797418,
    "price": [
      15.696111262348056,
      16.13809063488964,
      15.384615348864092,
      15.460352744210393
    ],
    "volume": 71783032341
  },
  {
    "ts": 1592438400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17549,
      "authorized": 17549,
      "funded": 3000
    },
    "payments": 2,
    "payments_amount": 530000000000,
    "trades": 42,
    "traded_amount": 37387527904,
    "price": [
      15.460352744210393,
      16.046143514965465,
      15.460352744210393,
      15.976424007716613
    ],
    "volume": 426456497211
  },
  {
    "ts": 1592524800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17563,
      "authorized": 17563,
      "funded": 2996
    },
    "payments": 8,
    "payments_amount": 3731229382,
    "trades": 31,
    "traded_amount": 506531271604,
    "price": [
      15.976424007716613,
      16.422786455378496,
      15.6560675,
      16.11637577069214
    ],
    "volume": 8126158323816
  },
  {
    "ts": 1592611200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17588,
      "authorized": 17588,
      "funded": 2996
    },
    "payments": 2,
    "payments_amount": 1433241554495,
    "trades": 11,
    "traded_amount": 1070258219,
    "price": [
      16.11637577069214,
      16.34001866317584,
      15.970099667774086,
      15.970099667774086
    ],
    "volume": 1791952229
  },
  {
    "ts": 1592697600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17604,
      "authorized": 17604,
      "funded": 2999
    },
    "payments": 2,
    "payments_amount": 150000000,
    "trades": 21,
    "traded_amount": 2831458041,
    "price": [
      15.970099667774086,
      16.239196875711254,
      15.843441218260926,
      15.843441218260926
    ],
    "volume": 7190278942
  },
  {
    "ts": 1592784000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17619,
      "authorized": 17619,
      "funded": 3004
    },
    "payments": 4,
    "payments_amount": 226946256,
    "trades": 62,
    "traded_amount": 5160448631,
    "price": [
      15.843441218260926,
      16.23473232902025,
      15.681693495247655,
      15.729527630105688
    ],
    "volume": 39528319254
  },
  {
    "ts": 1592870400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17639,
      "authorized": 17639,
      "funded": 3003
    },
    "payments": 3,
    "payments_amount": 400136710061,
    "trades": 143,
    "traded_amount": 510300979998,
    "price": [
      15.729527630105688,
      16.06302233446757,
      15.656067510729525,
      15.882978723404255
    ],
    "volume": 8067285425487
  },
  {
    "ts": 1592956800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17664,
      "authorized": 17664,
      "funded": 3005
    },
    "payments": 6,
    "payments_amount": 1500000000,
    "trades": 82,
    "traded_amount": 15988093610,
    "price": [
      15.882978723404255,
      16.51264339451707,
      15.512134452393795,
      16.51264339451707
    ],
    "volume": 127409294787
  },
  {
    "ts": 1593043200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17676,
      "authorized": 17676,
      "funded": 3003
    },
    "payments": 1,
    "payments_amount": 10000000,
    "trades": 46,
    "traded_amount": 6208069930,
    "price": [
      16.51264339451707,
      17.10743600168262,
      16.35164835164835,
      16.507703230270618
    ],
    "volume": 67725142000
  },
  {
    "ts": 1593129600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17694,
      "authorized": 17694,
      "funded": 3003
    },
    "payments": 2,
    "payments_amount": 1000080000000,
    "trades": 61,
    "traded_amount": 508519047497,
    "price": [
      16.507703230270618,
      16.94712472010794,
      16.48634670008955,
      16.94712472010794
    ],
    "volume": 8521092472539
  },
  {
    "ts": 1593216000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17709,
      "authorized": 17709,
      "funded": 3002
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 62,
    "traded_amount": 67570480069,
    "price": [
      16.94712472010794,
      18.239999998545372,
      16.94712472010794,
      18.239999998545372
    ],
    "volume": 584683694572
  },
  {
    "ts": 1593302400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17718,
      "authorized": 17718,
      "funded": 3002
    },
    "payments": 1,
    "payments_amount": 42488,
    "trades": 39,
    "traded_amount": 10819189302,
    "price": [
      18.239999998545372,
      18.239999998545372,
      17.246169560776302,
      17.431007891793424
    ],
    "volume": 88992327991
  },
  {
    "ts": 1593388800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17732,
      "authorized": 17732,
      "funded": 3003
    },
    "payments": 7,
    "payments_amount": 204712383791,
    "trades": 22,
    "traded_amount": 11752338830,
    "price": [
      17.431007891793424,
      17.73187016496941,
      17.14475250129179,
      17.34139131236977
    ],
    "volume": 89641348900
  },
  {
    "ts": 1593475200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17743,
      "authorized": 17743,
      "funded": 3006
    },
    "payments": 1,
    "payments_amount": 100000000,
    "trades": 21,
    "traded_amount": 2293269280,
    "price": [
      17.34139131236977,
      17.419661888541366,
      16.68313954850866,
      16.68313954850866
    ],
    "volume": 18833427131
  },
  {
    "ts": 1593561600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17761,
      "authorized": 17761,
      "funded": 3007
    },
    "payments": 2,
    "payments_amount": 20000000,
    "trades": 111,
    "traded_amount": 519224372675,
    "price": [
      16.68313954850866,
      16.96409413512207,
      16.509096967856703,
      16.682702562751757
    ],
    "volume": 8454753014888
  },
  {
    "ts": 1593648000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17777,
      "authorized": 17777,
      "funded": 3007
    },
    "payments": 3,
    "payments_amount": 1500039800000,
    "trades": 78,
    "traded_amount": 515461861408,
    "price": [
      16.682702562751757,
      16.853931400000743,
      15.954306865138244,
      16.486636883512336
    ],
    "volume": 8460654012826
  },
  {
    "ts": 1593734400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17787,
      "authorized": 17787,
      "funded": 3006
    },
    "payments": 6,
    "payments_amount": 2550383457861,
    "trades": 93,
    "traded_amount": 67029011585,
    "price": [
      16.486636883512336,
      16.8545869956834,
      16.386731391585762,
      16.854586850106763
    ],
    "volume": 670248966728
  },
  {
    "ts": 1593820800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17797,
      "authorized": 17797,
      "funded": 3005
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 13,
    "traded_amount": 3953983805,
    "price": [
      16.854586850106763,
      16.854586850106763,
      16.49103712132456,
      16.625729976881438
    ],
    "volume": 31360025993
  },
  {
    "ts": 1593907200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17821,
      "authorized": 17821,
      "funded": 3003
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 32,
    "traded_amount": 6058145937,
    "price": [
      16.625729976881438,
      17.118083821852107,
      16.625729976881438,
      16.769963527385084
    ],
    "volume": 44057552974
  },
  {
    "ts": 1593993600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17832,
      "authorized": 17832,
      "funded": 3003
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 38,
    "traded_amount": 12669853054,
    "price": [
      16.769963527385084,
      16.916542902313996,
      15.888020169357631,
      15.973866378341564
    ],
    "volume": 95341445436
  },
  {
    "ts": 1594080000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17848,
      "authorized": 17848,
      "funded": 3004
    },
    "payments": 3,
    "payments_amount": 1000063181803,
    "trades": 32,
    "traded_amount": 5140964362,
    "price": [
      15.973866378341564,
      16.01982005059076,
      15.236121877795004,
      15.236121877795004
    ],
    "volume": 30934791492
  },
  {
    "ts": 1594166400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17857,
      "authorized": 17857,
      "funded": 3006
    },
    "payments": 3,
    "payments_amount": 1400000000,
    "trades": 108,
    "traded_amount": 520031183928,
    "price": [
      15.236121877795004,
      15.432101369512944,
      13.333333333333334,
      13.333333333333334
    ],
    "volume": 7347173716504
  },
  {
    "ts": 1594252800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17870,
      "authorized": 17870,
      "funded": 3008
    },
    "payments": 8,
    "payments_amount": 2751652400000,
    "trades": 425,
    "traded_amount": 190430241980,
    "price": [
      13.333333333333334,
      13.499898227816022,
      11.733630259868697,
      12.2567322
    ],
    "volume": 1095766271311
  },
  {
    "ts": 1594339200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17881,
      "authorized": 17881,
      "funded": 3006
    },
    "payments": 9,
    "payments_amount": 277008091762,
    "trades": 100,
    "traded_amount": 13681477744,
    "price": [
      12.2567322,
      13.52298582566216,
      12.2567322,
      12.578106754444363
    ],
    "volume": 95299012079
  },
  {
    "ts": 1594425600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17894,
      "authorized": 17894,
      "funded": 3010
    },
    "payments": 3,
    "payments_amount": 317656,
    "trades": 121,
    "traded_amount": 30631359516,
    "price": [
      12.578106754444363,
      12.578106754444363,
      11.299435027489206,
      12.050227632821956
    ],
    "volume": 197453636406
  },
  {
    "ts": 1594512000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17907,
      "authorized": 17907,
      "funded": 3008
    },
    "payments": 1,
    "payments_amount": 105680,
    "trades": 55,
    "traded_amount": 26403716712,
    "price": [
      12.050227632821956,
      12.343894,
      11.752023755022067,
      12.0080846907653
    ],
    "volume": 129810977584
  },
  {
    "ts": 1594598400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17913,
      "authorized": 17913,
      "funded": 3007
    },
    "payments": 10,
    "payments_amount": 50600630526,
    "trades": 587,
    "traded_amount": 192819792180,
    "price": [
      12.0080846907653,
      13.013109736858711,
      11.660717950499235,
      12.743705398354173
    ],
    "volume": 1187091873563
  },
  {
    "ts": 1594684800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17928,
      "authorized": 17928,
      "funded": 3009
    },
    "payments": 9,
    "payments_amount": 105100420823,
    "trades": 172,
    "traded_amount": 51564468941,
    "price": [
      12.743705398354173,
      12.9736225,
      12.254664658786064,
      12.307245671150657
    ],
    "volume": 290324884011
  },
  {
    "ts": 1594771200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17946,
      "authorized": 17946,
      "funded": 3009
    },
    "payments": 10,
    "payments_amount": 1672939056444,
    "trades": 413,
    "traded_amount": 165370562748,
    "price": [
      12.307245671150657,
      13.032709818977438,
      11.852411402239143,
      12.070425799707863
    ],
    "volume": 1267170375449
  },
  {
    "ts": 1594857600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17959,
      "authorized": 17959,
      "funded": 3009
    },
    "payments": 23,
    "payments_amount": 2700546964192,
    "trades": 223,
    "traded_amount": 598020421729,
    "price": [
      12.070425799707863,
      12.760565545838638,
      11.04289550542809,
      11.138995736401977
    ],
    "volume": 6726671371525
  },
  {
    "ts": 1594944000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17976,
      "authorized": 17976,
      "funded": 3009
    },
    "payments": 12,
    "payments_amount": 257710015093,
    "trades": 174,
    "traded_amount": 156674670212,
    "price": [
      11.138995736401977,
      11.4999063,
      10.601065209440648,
      11.00773871
    ],
    "volume": 1388884660634
  },
  {
    "ts": 1595030400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 17991,
      "authorized": 17991,
      "funded": 3007
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 50,
    "traded_amount": 12507482437,
    "price": [
      11.00773871,
      11.489677556929756,
      11.00773871,
      11.270638781059837
    ],
    "volume": 97856178034
  },
  {
    "ts": 1595116800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18006,
      "authorized": 18006,
      "funded": 3008
    },
    "payments": 4,
    "payments_amount": 1001080603,
    "trades": 31,
    "traded_amount": 8465721727,
    "price": [
      11.270638781059837,
      11.712876324475474,
      11.22122836718031,
      11.498218435251287
    ],
    "volume": 51249547171
  },
  {
    "ts": 1595203200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18018,
      "authorized": 18018,
      "funded": 3008
    },
    "payments": 3,
    "payments_amount": 263109,
    "trades": 46,
    "traded_amount": 10260655901,
    "price": [
      11.498218435251287,
      12.193320168012185,
      11.301176524237311,
      11.945361906761827
    ],
    "volume": 49065972478
  },
  {
    "ts": 1595289600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18037,
      "authorized": 18037,
      "funded": 3009
    },
    "payments": 3,
    "payments_amount": 100272388580,
    "trades": 62,
    "traded_amount": 113991646064,
    "price": [
      11.945361906761827,
      12.120112550957277,
      11.679972101308286,
      12.120112550957277
    ],
    "volume": 942640133099
  },
  {
    "ts": 1595376000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18056,
      "authorized": 18056,
      "funded": 3009
    },
    "payments": 2,
    "payments_amount": 1500019800000,
    "trades": 40,
    "traded_amount": 4325737480,
    "price": [
      12.120112550957277,
      12.20030720564501,
      11.873747794593417,
      11.873747794593417
    ],
    "volume": 26224787842
  },
  {
    "ts": 1595462400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18068,
      "authorized": 18068,
      "funded": 3009
    },
    "payments": 7,
    "payments_amount": 199520761,
    "trades": 65,
    "traded_amount": 5429943356,
    "price": [
      11.873747794593417,
      11.893943260420798,
      11.60344827586207,
      11.869
    ],
    "volume": 27398815956
  },
  {
    "ts": 1595548800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18082,
      "authorized": 18082,
      "funded": 3009
    },
    "payments": 6,
    "payments_amount": 1043758540000,
    "trades": 29,
    "traded_amount": 27763474518,
    "price": [
      11.869,
      12.177018688597707,
      11.869,
      12.177018688597707
    ],
    "volume": 310095620841
  },
  {
    "ts": 1595635200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18108,
      "authorized": 18108,
      "funded": 3009
    },
    "payments": 7,
    "payments_amount": 2042800998,
    "trades": 37,
    "traded_amount": 7605239329,
    "price": [
      12.177018688597707,
      12.177018688597707,
      11.530613972028785,
      11.546771060116383
    ],
    "volume": 43417263350
  },
  {
    "ts": 1595721600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18130,
      "authorized": 18130,
      "funded": 3008
    },
    "payments": 3,
    "payments_amount": 3910218237,
    "trades": 58,
    "traded_amount": 21371692956,
    "price": [
      11.546771060116383,
      11.907867922710691,
      11.546771060116383,
      11.798037933888708
    ],
    "volume": 135363394447
  },
  {
    "ts": 1595808000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18143,
      "authorized": 18143,
      "funded": 3007
    },
    "payments": 5,
    "payments_amount": 514756406439,
    "trades": 172,
    "traded_amount": 536498439604,
    "price": [
      11.798037933888708,
      13.1594997086488,
      11.682177077520393,
      12.496784677250202
    ],
    "volume": 6396792748612
  },
  {
    "ts": 1595894400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18161,
      "authorized": 18161,
      "funded": 3008
    },
    "payments": 2,
    "payments_amount": 1000010000000,
    "trades": 64,
    "traded_amount": 12266664460,
    "price": [
      12.496784677250202,
      12.496784677250202,
      11.988927843815434,
      12.0269881
    ],
    "volume": 79880401281
  },
  {
    "ts": 1595980800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18174,
      "authorized": 18174,
      "funded": 3009
    },
    "payments": 10,
    "payments_amount": 2504174931839,
    "trades": 47,
    "traded_amount": 512862014353,
    "price": [
      12.0269881,
      12.388908767622498,
      11.921673761906407,
      12.305905111626865
    ],
    "volume": 6150427650086
  },
  {
    "ts": 1596067200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18187,
      "authorized": 18187,
      "funded": 3011
    },
    "payments": 4,
    "payments_amount": 32540735994,
    "trades": 75,
    "traded_amount": 8502778041,
    "price": [
      12.305905111626865,
      12.437810943174695,
      12.184796423422199,
      12.184796423422199
    ],
    "volume": 67292456843
  },
  {
    "ts": 1596153600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18202,
      "authorized": 18202,
      "funded": 3013
    },
    "payments": 6,
    "payments_amount": 61730400000,
    "trades": 115,
    "traded_amount": 74720250853,
    "price": [
      12.184796423422199,
      12.381229427899395,
      12.0754811325274,
      12.330322338581503
    ],
    "volume": 486149686411
  },
  {
    "ts": 1596240000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18208,
      "authorized": 18208,
      "funded": 3018
    },
    "payments": 3,
    "payments_amount": 490507427,
    "trades": 106,
    "traded_amount": 24450129990,
    "price": [
      12.330322338581503,
      12.330322338581503,
      10.854973567353879,
      11.1111111
    ],
    "volume": 139897032262
  },
  {
    "ts": 1596326400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18220,
      "authorized": 18220,
      "funded": 3015
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 173,
    "traded_amount": 31680637566,
    "price": [
      11.1111111,
      11.820436778067586,
      10.191417804084256,
      11.448904267170176
    ],
    "volume": 191507267633
  },
  {
    "ts": 1596412800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18230,
      "authorized": 18230,
      "funded": 3014
    },
    "payments": 3,
    "payments_amount": 1582382600000,
    "trades": 230,
    "traded_amount": 571056687688,
    "price": [
      11.448904267170176,
      11.574535862065323,
      10.786795649247631,
      11.235955056179776
    ],
    "volume": 5989196490057
  },
  {
    "ts": 1596499200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18242,
      "authorized": 18242,
      "funded": 3015
    },
    "payments": 6,
    "payments_amount": 2103741820870,
    "trades": 192,
    "traded_amount": 696540043946,
    "price": [
      11.235955056179776,
      11.235955056179776,
      10.603281190675187,
      10.75268817204301
    ],
    "volume": 6765084248528
  },
  {
    "ts": 1596585600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18254,
      "authorized": 18254,
      "funded": 3014
    },
    "payments": 5,
    "payments_amount": 3010006900000,
    "trades": 58,
    "traded_amount": 525688720700,
    "price": [
      10.75268817204301,
      11.08434421291638,
      10.75268817204301,
      10.960765938300726
    ],
    "volume": 5788051646288
  },
  {
    "ts": 1596672000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18263,
      "authorized": 18263,
      "funded": 3014
    },
    "payments": 11,
    "payments_amount": 745119644015,
    "trades": 123,
    "traded_amount": 612032756133,
    "price": [
      10.960765938300726,
      11.285183573010844,
      10.960765938300726,
      11.098355152735817
    ],
    "volume": 6304681245163
  },
  {
    "ts": 1596758400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18275,
      "authorized": 18275,
      "funded": 3016
    },
    "payments": 9,
    "payments_amount": 2629952183076,
    "trades": 300,
    "traded_amount": 1036471959237,
    "price": [
      11.098355152735817,
      11.653817557986908,
      10.962968821677713,
      11.583368190341712
    ],
    "volume": 11844873597953
  },
  {
    "ts": 1596844800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18284,
      "authorized": 18284,
      "funded": 3015
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 98,
    "traded_amount": 85578507258,
    "price": [
      11.583368190341712,
      11.583368190341712,
      11.342114099880346,
      11.362098398809215
    ],
    "volume": 831104947760
  },
  {
    "ts": 1596931200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18299,
      "authorized": 18299,
      "funded": 3015
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 30,
    "traded_amount": 2597521666,
    "price": [
      11.362098398809215,
      11.495806306826209,
      11.151910795875638,
      11.151910795875638
    ],
    "volume": 14919407003
  },
  {
    "ts": 1597017600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18310,
      "authorized": 18310,
      "funded": 3016
    },
    "payments": 8,
    "payments_amount": 2755019900000,
    "trades": 143,
    "traded_amount": 1052511509706,
    "price": [
      11.151910795875638,
      11.320278647424518,
      11.020317899966376,
      11.201826463217586
    ],
    "volume": 11528112783156
  },
  {
    "ts": 1597104000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18325,
      "authorized": 18325,
      "funded": 3016
    },
    "payments": 9,
    "payments_amount": 6702119800000,
    "trades": 511,
    "traded_amount": 912857661472,
    "price": [
      11.201826463217586,
      12.05541051462707,
      10.695652173913043,
      11.71730804304213
    ],
    "volume": 8654749676085
  },
  {
    "ts": 1597190400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18338,
      "authorized": 18338,
      "funded": 3020
    },
    "payments": 7,
    "payments_amount": 530229900000,
    "trades": 105,
    "traded_amount": 358601844921,
    "price": [
      11.71730804304213,
      12.126761433101047,
      11.413404573390178,
      11.773015500006212
    ],
    "volume": 4145575585576
  },
  {
    "ts": 1597276800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18359,
      "authorized": 18359,
      "funded": 3024
    },
    "payments": 14,
    "payments_amount": 3108525477236,
    "trades": 336,
    "traded_amount": 208864337498,
    "price": [
      11.773015500006212,
      12.239108111444876,
      11.623035315349533,
      11.774840965300786
    ],
    "volume": 1312148175512
  },
  {
    "ts": 1597363200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18366,
      "authorized": 18366,
      "funded": 3028
    },
    "payments": 11,
    "payments_amount": 3039011400000,
    "trades": 368,
    "traded_amount": 683893950503,
    "price": [
      11.774840965300786,
      11.774840965300786,
      10.993408366441368,
      11.248638403007078
    ],
    "volume": 5549231208610
  },
  {
    "ts": 1597449600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18373,
      "authorized": 18373,
      "funded": 3026
    },
    "payments": 2,
    "payments_amount": 180996860,
    "trades": 134,
    "traded_amount": 7765745858,
    "price": [
      11.248638403007078,
      11.449505442839447,
      10.914428692127364,
      10.999449217889403
    ],
    "volume": 40232629066
  },
  {
    "ts": 1597536000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18383,
      "authorized": 18383,
      "funded": 3027
    },
    "payments": 12,
    "payments_amount": 153570575582,
    "trades": 220,
    "traded_amount": 79296505065,
    "price": [
      10.999449217889403,
      11.302378450992887,
      10.2740614825093,
      10.277242482074776
    ],
    "volume": 641596037457
  },
  {
    "ts": 1597622400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18389,
      "authorized": 18389,
      "funded": 3029
    },
    "payments": 5,
    "payments_amount": 152884400000,
    "trades": 325,
    "traded_amount": 118337035983,
    "price": [
      10.277242482074776,
      10.729505518913735,
      9.909882065987789,
      10.36693740167563
    ],
    "volume": 806723317514
  },
  {
    "ts": 1597708800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18398,
      "authorized": 18398,
      "funded": 3026
    },
    "payments": 2,
    "payments_amount": 1005898000000,
    "trades": 188,
    "traded_amount": 560649151620,
    "price": [
      10.36693740167563,
      16.66666667069112,
      9.523684210526316,
      10.984447726783733
    ],
    "volume": 5849493397786
  },
  {
    "ts": 1597795200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18420,
      "authorized": 18420,
      "funded": 3026
    },
    "payments": 17,
    "payments_amount": 5311077683240,
    "trades": 415,
    "traded_amount": 1379404779316,
    "price": [
      10.984447726783733,
      11.832848600900457,
      10.90693290140136,
      11.686038
    ],
    "volume": 13591418273542
  },
  {
    "ts": 1597881600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18433,
      "authorized": 18433,
      "funded": 3025
    },
    "payments": 9,
    "payments_amount": 3037516396061,
    "trades": 110,
    "traded_amount": 980674138734,
    "price": [
      11.686038,
      11.715667178937203,
      11.163151056639736,
      11.163151056639736
    ],
    "volume": 10772646867617
  },
  {
    "ts": 1597968000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18458,
      "authorized": 18458,
      "funded": 3031
    },
    "payments": 19,
    "payments_amount": 3662371601050,
    "trades": 243,
    "traded_amount": 67454319042,
    "price": [
      11.163151056639736,
      11.818608318269558,
      10.90749231585296,
      11.713310580204778
    ],
    "volume": 282513035959
  },
  {
    "ts": 1598054400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18469,
      "authorized": 18469,
      "funded": 3030
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 79,
    "traded_amount": 31775371264,
    "price": [
      11.713310580204778,
      12.14480244361553,
      11.527482000065671,
      11.527482000931574
    ],
    "volume": 300632796825
  },
  {
    "ts": 1598140800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18481,
      "authorized": 18481,
      "funded": 3030
    },
    "payments": 3,
    "payments_amount": 1353904551,
    "trades": 27,
    "traded_amount": 5268314033,
    "price": [
      11.527482000931574,
      11.853943332624738,
      11.484979321847902,
      11.484979321847902
    ],
    "volume": 29880758821
  },
  {
    "ts": 1598227200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18497,
      "authorized": 18497,
      "funded": 3032
    },
    "payments": 9,
    "payments_amount": 1683732620000,
    "trades": 200,
    "traded_amount": 1210115726054,
    "price": [
      11.484979321847902,
      11.57980749286697,
      11.088785046728972,
      11.57980749286697
    ],
    "volume": 13749045913282
  },
  {
    "ts": 1598313600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18506,
      "authorized": 18506,
      "funded": 3035
    },
    "payments": 16,
    "payments_amount": 1124513227648,
    "trades": 371,
    "traded_amount": 1029809963389,
    "price": [
      11.57980749286697,
      12.340891845399204,
      11.488888888888889,
      12.327623500002396
    ],
    "volume": 12193658858953
  },
  {
    "ts": 1598400000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18518,
      "authorized": 18518,
      "funded": 3037
    },
    "payments": 13,
    "payments_amount": 2649963001312,
    "trades": 307,
    "traded_amount": 588286152199,
    "price": [
      12.327623500002396,
      12.327623500002396,
      11.768192893182112,
      11.995197351460424
    ],
    "volume": 6743184452516
  },
  {
    "ts": 1598486400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18535,
      "authorized": 18535,
      "funded": 3048
    },
    "payments": 9,
    "payments_amount": 3300022415911,
    "trades": 598,
    "traded_amount": 53403152160,
    "price": [
      11.995197351460424,
      12.900992860771373,
      11.995197351460424,
      12.587661831696666
    ],
    "volume": 371633680671
  },
  {
    "ts": 1598572800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18548,
      "authorized": 18548,
      "funded": 3053
    },
    "payments": 20,
    "payments_amount": 3461249668531,
    "trades": 1587,
    "traded_amount": 142494123281,
    "price": [
      12.587661831696666,
      12.690399554114325,
      12.288665574805403,
      12.48967014791119
    ],
    "volume": 188417466269
  },
  {
    "ts": 1598659200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18561,
      "authorized": 18561,
      "funded": 3054
    },
    "payments": 2,
    "payments_amount": 339900000,
    "trades": 2180,
    "traded_amount": 23880536693,
    "price": [
      12.48967014791119,
      12.562574705778113,
      12.124691933279767,
      12.36040969561854
    ],
    "volume": 65728145949
  },
  {
    "ts": 1598745600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18581,
      "authorized": 18581,
      "funded": 3052
    },
    "payments": 10,
    "payments_amount": 2702432533,
    "trades": 2384,
    "traded_amount": 74454542453,
    "price": [
      12.36040969561854,
      12.36040969561854,
      12.03825170813606,
      12.115232563396637
    ],
    "volume": 271539182905
  },
  {
    "ts": 1598832000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18589,
      "authorized": 18589,
      "funded": 3051
    },
    "payments": 5,
    "payments_amount": 2167996307982,
    "trades": 2985,
    "traded_amount": 1173639498109,
    "price": [
      12.115232563396637,
      12.57,
      12.025853423780328,
      12.285833052721246
    ],
    "volume": 12846217423211
  },
  {
    "ts": 1598918400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18605,
      "authorized": 18605,
      "funded": 3054
    },
    "payments": 20,
    "payments_amount": 1104327505108,
    "trades": 2860,
    "traded_amount": 681101583878,
    "price": [
      12.285833052721246,
      12.47030024576199,
      11.8888881104546,
      12.048402051961562
    ],
    "volume": 6320103247994
  },
  {
    "ts": 1599004800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18619,
      "authorized": 18619,
      "funded": 3051
    },
    "payments": 27,
    "payments_amount": 3146461813767,
    "trades": 2723,
    "traded_amount": 869097410478,
    "price": [
      12.048402051961562,
      13.223083723809353,
      11.969458413265004,
      12.72795051552496
    ],
    "volume": 10279859876091
  },
  {
    "ts": 1599091200,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18632,
      "authorized": 18632,
      "funded": 3050
    },
    "payments": 18,
    "payments_amount": 2791652515622,
    "trades": 1969,
    "traded_amount": 348504428326,
    "price": [
      12.72795051552496,
      15.14793961781644,
      12.72795051552496,
      14.958528504512739
    ],
    "volume": 2182184688256
  },
  {
    "ts": 1599177600,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18650,
      "authorized": 18650,
      "funded": 3053
    },
    "payments": 10,
    "payments_amount": 5640237766203,
    "trades": 2076,
    "traded_amount": 704715288664,
    "price": [
      14.958528504512739,
      15.65,
      14.2658940741514,
      14.603165356441473
    ],
    "volume": 10033747286357
  },
  {
    "ts": 1599264000,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18666,
      "authorized": 18666,
      "funded": 3053
    },
    "payments": 2,
    "payments_amount": 3010000000,
    "trades": 2238,
    "traded_amount": 39977058688,
    "price": [
      14.603165356441473,
      16.09579860755859,
      14.249781561863639,
      15.778255646462231
    ],
    "volume": 107047662950
  },
  {
    "ts": 1599350400,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18679,
      "authorized": 18679,
      "funded": 3054
    },
    "payments": 3,
    "payments_amount": 205928412,
    "trades": 1236,
    "traded_amount": 28331664616,
    "price": [
      15.778255646462231,
      16.248992569591735,
      14.977742939508314,
      15.253494716617483
    ],
    "volume": 83883900275
  },
  {
    "ts": 1599436800,
    "supply": 10032296306420,
    "trustlines": {
      "total": 18689,
      "authorized": 18689,
      "funded": 3053
    },
    "payments": 5,
    "payments_amount": 206768687826,
    "trades": 1730,
    "traded_amount": 113411073043,
    "price": [
      15.253494716617483,
      16.011293595911003,
      14.948900108454225,
      15.098436753326508
    ],
    "volume": 138175329544
  },
  {
    "ts": 1599523200,
    "supply": 10032286306420,
    "trustlines": {
      "total": 18702,
      "authorized": 18702,
      "funded": 3053
    },
    "payments": 12,
    "payments_amount": 2910924124733,
    "trades": 1145,
    "traded_amount": 957842517854,
    "price": [
      15.098436753326508,
      15.536665996366402,
      14.899193037749066,
      15.222223549978079
    ],
    "volume": 11621999583329
  },
  {
    "ts": 1599609600,
    "supply": 10032286306420,
    "trustlines": {
      "total": 18717,
      "authorized": 18717,
      "funded": 3055
    },
    "payments": 7,
    "payments_amount": 772801155711,
    "trades": 1080,
    "traded_amount": 382653857156,
    "price": [
      15.222223549978079,
      15.574408966166521,
      14.904293146154338,
      15.1200788576788
    ],
    "volume": 5372304458776
  },
  {
    "ts": 1599696000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18730,
      "authorized": 18730,
      "funded": 3053
    },
    "payments": 7,
    "payments_amount": 5429843927965,
    "trades": 1172,
    "traded_amount": 1240256892008,
    "price": [
      15.1200788576788,
      15.1200788576788,
      14.37904158457615,
      14.600147949262263
    ],
    "volume": 14905146089313
  },
  {
    "ts": 1599782400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18742,
      "authorized": 18742,
      "funded": 3053
    },
    "payments": 14,
    "payments_amount": 2630277782282,
    "trades": 1190,
    "traded_amount": 465517067804,
    "price": [
      14.600147949262263,
      14.838866224326434,
      14.075629402496746,
      14.131069245259726
    ],
    "volume": 5409905756010
  },
  {
    "ts": 1599868800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18752,
      "authorized": 18752,
      "funded": 3053
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 1143,
    "traded_amount": 38050504555,
    "price": [
      14.131069245259726,
      14.34737747205503,
      13.985989113061953,
      14.162353109773575
    ],
    "volume": 89291438505
  },
  {
    "ts": 1599955200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18774,
      "authorized": 18774,
      "funded": 3053
    },
    "payments": 3,
    "payments_amount": 722231144,
    "trades": 1090,
    "traded_amount": 42582898491,
    "price": [
      14.162353109773575,
      14.965815467928497,
      14.031076748822203,
      14.836732342104
    ],
    "volume": 130853222462
  },
  {
    "ts": 1600041600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18782,
      "authorized": 18782,
      "funded": 3053
    },
    "payments": 12,
    "payments_amount": 1208985089252,
    "trades": 1196,
    "traded_amount": 964780980782,
    "price": [
      14.836732342104,
      14.97616763141035,
      14.320415610250429,
      14.86052330056965
    ],
    "volume": 11264387451286
  },
  {
    "ts": 1600128000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18791,
      "authorized": 18791,
      "funded": 3054
    },
    "payments": 4,
    "payments_amount": 3370019800000,
    "trades": 1148,
    "traded_amount": 59354347482,
    "price": [
      14.86052330056965,
      15.273314963967783,
      14.612866692018597,
      15.259149405614165
    ],
    "volume": 408698472602
  },
  {
    "ts": 1600214400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18800,
      "authorized": 18800,
      "funded": 3052
    },
    "payments": 4,
    "payments_amount": 2250904294915,
    "trades": 1246,
    "traded_amount": 1066663631288,
    "price": [
      15.259149405614165,
      15.727155448720056,
      14.465934769524855,
      14.715507973931967
    ],
    "volume": 15111586433463
  },
  {
    "ts": 1600300800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18815,
      "authorized": 18815,
      "funded": 3055
    },
    "payments": 8,
    "payments_amount": 4424700240000,
    "trades": 1256,
    "traded_amount": 1173246137262,
    "price": [
      14.715507973931967,
      15.04616260000429,
      14.373515047301769,
      14.71686386026013
    ],
    "volume": 14578793133423
  },
  {
    "ts": 1600387200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18827,
      "authorized": 18827,
      "funded": 3054
    },
    "payments": 5,
    "payments_amount": 2045507925274,
    "trades": 1196,
    "traded_amount": 1106171129564,
    "price": [
      14.71686386026013,
      15.334880054544733,
      14.702224299171943,
      15.20993300160129
    ],
    "volume": 13485714531931
  },
  {
    "ts": 1600473600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18833,
      "authorized": 18833,
      "funded": 3053
    },
    "payments": 3,
    "payments_amount": 2000060000000,
    "trades": 1201,
    "traded_amount": 186621098150,
    "price": [
      15.20993300160129,
      15.293597469167088,
      14.25,
      15.102132798219118
    ],
    "volume": 1959546262750
  },
  {
    "ts": 1600560000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18846,
      "authorized": 18846,
      "funded": 3055
    },
    "payments": 4,
    "payments_amount": 716753145,
    "trades": 1018,
    "traded_amount": 33814080354,
    "price": [
      15.102132798219118,
      15.6549200544001,
      15.062756135687115,
      15.415256627185562
    ],
    "volume": 68051788581
  },
  {
    "ts": 1600646400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18857,
      "authorized": 18857,
      "funded": 3054
    },
    "payments": 5,
    "payments_amount": 373540000000,
    "trades": 1677,
    "traded_amount": 133974990448,
    "price": [
      15.415256627185562,
      16.918298154552037,
      15.351757036399116,
      16.726045504593447
    ],
    "volume": 836959688337
  },
  {
    "ts": 1600732800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18868,
      "authorized": 18868,
      "funded": 3055
    },
    "payments": 5,
    "payments_amount": 2001921864550,
    "trades": 1199,
    "traded_amount": 40283597678,
    "price": [
      16.726045504593447,
      16.91416055514228,
      16.276575405175745,
      16.47690500146902
    ],
    "volume": 112447044790
  },
  {
    "ts": 1600819200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18880,
      "authorized": 18880,
      "funded": 3056
    },
    "payments": 12,
    "payments_amount": 4064622588804,
    "trades": 1255,
    "traded_amount": 1215313490468,
    "price": [
      16.47690500146902,
      17.33770666135321,
      16.128247587777715,
      17.324436408162583
    ],
    "volume": 16644858818857
  },
  {
    "ts": 1600905600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18883,
      "authorized": 18883,
      "funded": 3055
    },
    "payments": 5,
    "payments_amount": 4865784859835,
    "trades": 1170,
    "traded_amount": 815619102300,
    "price": [
      17.324436408162583,
      17.44186503044968,
      16.542937037361565,
      16.747335315384614
    ],
    "volume": 11994633169915
  },
  {
    "ts": 1600992000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18896,
      "authorized": 18896,
      "funded": 3058
    },
    "payments": 3,
    "payments_amount": 2705396800000,
    "trades": 1241,
    "traded_amount": 1055738549712,
    "price": [
      16.747335315384614,
      16.800303965513066,
      15.486095486095486,
      15.531821761953585
    ],
    "volume": 14741446979462
  },
  {
    "ts": 1601078400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18909,
      "authorized": 18909,
      "funded": 3057
    },
    "payments": 2,
    "payments_amount": 40001967,
    "trades": 1174,
    "traded_amount": 33625144356,
    "price": [
      15.531821761953585,
      15.958352310282232,
      15.271658758055978,
      15.902115583322724
    ],
    "volume": 33463674309
  },
  {
    "ts": 1601164800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18921,
      "authorized": 18921,
      "funded": 3059
    },
    "payments": 4,
    "payments_amount": 100300000,
    "trades": 1100,
    "traded_amount": 35602310194,
    "price": [
      15.902115583322724,
      16.159444533937485,
      15.645722201411969,
      15.909968689661108
    ],
    "volume": 67924608991
  },
  {
    "ts": 1601251200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18938,
      "authorized": 18938,
      "funded": 3060
    },
    "payments": 12,
    "payments_amount": 5103403946547,
    "trades": 1283,
    "traded_amount": 1042796831530,
    "price": [
      15.909968689661108,
      16.01757568269661,
      15.407626397803732,
      16.01757568269661
    ],
    "volume": 15776540644887
  },
  {
    "ts": 1601337600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18950,
      "authorized": 18950,
      "funded": 3061
    },
    "payments": 16,
    "payments_amount": 2849786000243,
    "trades": 1195,
    "traded_amount": 887546634303,
    "price": [
      16.01757568269661,
      16.230465869179326,
      15.630654289353755,
      15.929291734773233
    ],
    "volume": 12047626046683
  },
  {
    "ts": 1601424000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18959,
      "authorized": 18959,
      "funded": 3064
    },
    "payments": 14,
    "payments_amount": 5760355924272,
    "trades": 1443,
    "traded_amount": 1032608741333,
    "price": [
      15.929291734773233,
      16.280199207197363,
      15.731541764286307,
      15.739867296091173
    ],
    "volume": 16377066490134
  },
  {
    "ts": 1601510400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18967,
      "authorized": 18967,
      "funded": 3064
    },
    "payments": 16,
    "payments_amount": 3112055860020,
    "trades": 1433,
    "traded_amount": 1161856149123,
    "price": [
      15.739867296091173,
      16.306657082988277,
      15.441468972693864,
      16.041831450967507
    ],
    "volume": 16801381823172
  },
  {
    "ts": 1601596800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18974,
      "authorized": 18974,
      "funded": 3062
    },
    "payments": 7,
    "payments_amount": 2989252669039,
    "trades": 1176,
    "traded_amount": 918923416194,
    "price": [
      16.041831450967507,
      17.036286700009235,
      15.926160841447322,
      16.653597541840732
    ],
    "volume": 14957289133143
  },
  {
    "ts": 1601683200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18975,
      "authorized": 18975,
      "funded": 3062
    },
    "payments": 5,
    "payments_amount": 6548250100,
    "trades": 978,
    "traded_amount": 24986602020,
    "price": [
      16.653597541840732,
      16.653597541840732,
      16.243955084033782,
      16.472876569814968
    ],
    "volume": 26458957754
  },
  {
    "ts": 1601769600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 18988,
      "authorized": 18988,
      "funded": 3062
    },
    "payments": 3,
    "payments_amount": 900000000,
    "trades": 1056,
    "traded_amount": 26370365311,
    "price": [
      16.472876569814968,
      16.58630892678871,
      16.032502212405007,
      16.032502212405007
    ],
    "volume": 19602994018
  },
  {
    "ts": 1601856000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19004,
      "authorized": 19004,
      "funded": 3064
    },
    "payments": 23,
    "payments_amount": 5183585721465,
    "trades": 1343,
    "traded_amount": 1098520211336,
    "price": [
      16.032502212405007,
      16.2480175,
      15.939943996010038,
      16.1571832014346
    ],
    "volume": 16341777132174
  },
  {
    "ts": 1601942400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19026,
      "authorized": 19026,
      "funded": 3065
    },
    "payments": 8,
    "payments_amount": 3244689033845,
    "trades": 1458,
    "traded_amount": 1152795848312,
    "price": [
      16.1571832014346,
      16.605688888099216,
      15.664241363927585,
      16.24413605770006
    ],
    "volume": 14245468200068
  },
  {
    "ts": 1602028800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19030,
      "authorized": 19030,
      "funded": 3065
    },
    "payments": 7,
    "payments_amount": 780350526007,
    "trades": 1210,
    "traded_amount": 32383141909,
    "price": [
      16.24413605770006,
      16.549756645981006,
      16.111308717684988,
      16.258755163748972
    ],
    "volume": 86238366706
  },
  {
    "ts": 1602115200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19039,
      "authorized": 19039,
      "funded": 3068
    },
    "payments": 9,
    "payments_amount": 3354421389107,
    "trades": 1310,
    "traded_amount": 185554092345,
    "price": [
      16.258755163748972,
      16.6565,
      16.01720070809616,
      16.01720070809616
    ],
    "volume": 939682026431
  },
  {
    "ts": 1602201600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19056,
      "authorized": 19056,
      "funded": 3068
    },
    "payments": 11,
    "payments_amount": 4152046956894,
    "trades": 1289,
    "traded_amount": 1036304332779,
    "price": [
      16.01720070809616,
      16.184528897445947,
      15.581428103133492,
      15.720477336573849
    ],
    "volume": 15923579193071
  },
  {
    "ts": 1602288000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19067,
      "authorized": 19067,
      "funded": 3068
    },
    "payments": 3,
    "payments_amount": 5742340214,
    "trades": 1125,
    "traded_amount": 130827621279,
    "price": [
      15.720477336573849,
      15.738850202236215,
      15.074123383976135,
      15.315247057548046
    ],
    "volume": 91252733826
  },
  {
    "ts": 1602374400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19083,
      "authorized": 19083,
      "funded": 3069
    },
    "payments": 5,
    "payments_amount": 1650848381,
    "trades": 1232,
    "traded_amount": 32106637799,
    "price": [
      15.315247057548046,
      15.356786607357344,
      15.070679779530963,
      15.313290023669236
    ],
    "volume": 56138068444
  },
  {
    "ts": 1602460800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19105,
      "authorized": 19105,
      "funded": 3072
    },
    "payments": 31,
    "payments_amount": 3330281472819,
    "trades": 1409,
    "traded_amount": 1268836122894,
    "price": [
      15.313290023669236,
      15.605915454915053,
      14.955495570128035,
      15.188140772357567
    ],
    "volume": 15801246768152
  },
  {
    "ts": 1602547200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19133,
      "authorized": 19133,
      "funded": 3071
    },
    "payments": 12,
    "payments_amount": 4849496911369,
    "trades": 1314,
    "traded_amount": 1609081476600,
    "price": [
      15.188140772357567,
      15.570859933273193,
      15.188140772357567,
      15.330181249657725
    ],
    "volume": 23323095808963
  },
  {
    "ts": 1602633600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19148,
      "authorized": 19148,
      "funded": 3072
    },
    "payments": 24,
    "payments_amount": 2194210716764,
    "trades": 1486,
    "traded_amount": 851172238541,
    "price": [
      15.330181249657725,
      16.07880485529074,
      15.32039101629271,
      15.879637752730599
    ],
    "volume": 13039135291242
  },
  {
    "ts": 1602720000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19168,
      "authorized": 19168,
      "funded": 3073
    },
    "payments": 11,
    "payments_amount": 3200968329651,
    "trades": 1423,
    "traded_amount": 1342762110153,
    "price": [
      15.879637752730599,
      16.38113540011906,
      15.801216746220106,
      15.87083842485907
    ],
    "volume": 17980278119773
  },
  {
    "ts": 1602806400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19180,
      "authorized": 19180,
      "funded": 3074
    },
    "payments": 11,
    "payments_amount": 2212773867906,
    "trades": 1273,
    "traded_amount": 595907714492,
    "price": [
      15.87083842485907,
      16.185891729772045,
      14.87033242825079,
      14.931957748175048
    ],
    "volume": 9038420260978
  },
  {
    "ts": 1602892800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19190,
      "authorized": 19190,
      "funded": 3075
    },
    "payments": 7,
    "payments_amount": 105118146106,
    "trades": 1308,
    "traded_amount": 32651532329,
    "price": [
      14.931957748175048,
      15.053999748838377,
      14.132894689738656,
      14.393131644754
    ],
    "volume": 59007511181
  },
  {
    "ts": 1602979200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19201,
      "authorized": 19201,
      "funded": 3075
    },
    "payments": 3,
    "payments_amount": 2000000000,
    "trades": 1185,
    "traded_amount": 35703867058,
    "price": [
      14.393131644754,
      14.830085505616916,
      14.271144378753782,
      14.797310576674425
    ],
    "volume": 90060262704
  },
  {
    "ts": 1603065600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19210,
      "authorized": 19210,
      "funded": 3075
    },
    "payments": 6,
    "payments_amount": 3897295363200,
    "trades": 1461,
    "traded_amount": 1526827336939,
    "price": [
      14.797310576674425,
      14.797310576674425,
      13.528473405776786,
      13.801544401544401
    ],
    "volume": 17645981499850
  },
  {
    "ts": 1603152000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19227,
      "authorized": 19227,
      "funded": 3076
    },
    "payments": 16,
    "payments_amount": 8585696839693,
    "trades": 1551,
    "traded_amount": 1142460570279,
    "price": [
      13.801544401544401,
      14.7269902,
      13.609148073815689,
      14.7269902
    ],
    "volume": 14847100016608
  },
  {
    "ts": 1603238400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19250,
      "authorized": 19250,
      "funded": 3078
    },
    "payments": 15,
    "payments_amount": 3022447876717,
    "trades": 448,
    "traded_amount": 885460135645,
    "price": [
      14.7269902,
      14.7269902,
      13.814124,
      14.245014245014245
    ],
    "volume": 12043732311507
  },
  {
    "ts": 1603324800,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19266,
      "authorized": 19266,
      "funded": 3080
    },
    "payments": 15,
    "payments_amount": 3108273742667,
    "trades": 837,
    "traded_amount": 541377698179,
    "price": [
      14.245014245014245,
      14.245014245014245,
      13.676680628482943,
      13.873398097930535
    ],
    "volume": 1416308132047
  },
  {
    "ts": 1603411200,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19272,
      "authorized": 19272,
      "funded": 3080
    },
    "payments": 18,
    "payments_amount": 7092524212958,
    "trades": 206,
    "traded_amount": 998285111331,
    "price": [
      13.873398097930535,
      14.232928305325636,
      13.575583699954636,
      14.203200454502415
    ],
    "volume": 12475622055934
  },
  {
    "ts": 1603497600,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19278,
      "authorized": 19278,
      "funded": 3079
    },
    "payments": 0,
    "payments_amount": 0,
    "trades": 311,
    "traded_amount": 61366914301,
    "price": [
      14.203200454502415,
      14.375,
      13.920424175539152,
      13.930378923498814
    ],
    "volume": 231107178728
  },
  {
    "ts": 1603584000,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19291,
      "authorized": 19291,
      "funded": 3079
    },
    "payments": 4,
    "payments_amount": 507721844,
    "trades": 371,
    "traded_amount": 95044513492,
    "price": [
      13.930378923498814,
      14.426117798589578,
      13.828124325849222,
      14.292717851478425
    ],
    "volume": 570737265803
  },
  {
    "ts": 1603670400,
    "supply": 10032286299706,
    "trustlines": {
      "total": 19301,
      "authorized": 19301,
      "funded": 3081
    },
    "payments": 15,
    "payments_amount": 2180708831975,
    "trades": 225,
    "traded_amount": 1144511204843,
    "price": [
      14.292717851478425,
      14.75106714975718,
      14.084473539131979,
      14.521444807306143
    ],
    "volume": 14594182642364
  },
  {
    "ts": 1603756800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19324,
      "authorized": 19324,
      "funded": 3083
    },
    "payments": 57,
    "payments_amount": 3023621653398,
    "trades": 162,
    "traded_amount": 836034205011,
    "price": [
      14.521444807306143,
      14.564102564102564,
      14.225254547697446,
      14.404142857142856
    ],
    "volume": 10961726263807
  },
  {
    "ts": 1603843200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19334,
      "authorized": 19334,
      "funded": 3083
    },
    "payments": 16,
    "payments_amount": 902344190513,
    "trades": 111,
    "traded_amount": 10555148084,
    "price": [
      14.404142857142856,
      15.030303030303031,
      14.404142857142856,
      14.9390000090876
    ],
    "volume": 78183014459
  },
  {
    "ts": 1603929600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19349,
      "authorized": 19349,
      "funded": 3084
    },
    "payments": 31,
    "payments_amount": 859524689,
    "trades": 166,
    "traded_amount": 574108020743,
    "price": [
      14.9390000090876,
      16.666666666666668,
      14.802282595107451,
      15.030754629744473
    ],
    "volume": 8439763826185
  },
  {
    "ts": 1604016000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19363,
      "authorized": 19363,
      "funded": 3086
    },
    "payments": 13,
    "payments_amount": 2457326832278,
    "trades": 454,
    "traded_amount": 523459588730,
    "price": [
      15.030754629744473,
      15.682132917737414,
      14.9,
      15.242229511395092
    ],
    "volume": 6358650396379
  },
  {
    "ts": 1604102400,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19373,
      "authorized": 19373,
      "funded": 3085
    },
    "payments": 4,
    "payments_amount": 501420000,
    "trades": 322,
    "traded_amount": 63042010674,
    "price": [
      15.242229511395092,
      15.242229511395092,
      14.696599560348973,
      14.981183023523448
    ],
    "volume": 431242464943
  },
  {
    "ts": 1604188800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19377,
      "authorized": 19377,
      "funded": 3086
    },
    "payments": 8,
    "payments_amount": 4748862783,
    "trades": 44,
    "traded_amount": 5407431132,
    "price": [
      14.981183023523448,
      15.216190983012787,
      14.832022790487818,
      14.848052145508742
    ],
    "volume": 42409304117
  },
  {
    "ts": 1604275200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19388,
      "authorized": 19388,
      "funded": 3087
    },
    "payments": 38,
    "payments_amount": 2412347094033,
    "trades": 131,
    "traded_amount": 1015994249897,
    "price": [
      14.848052145508742,
      16.666666666666668,
      14.796248307045888,
      15.336697683273453
    ],
    "volume": 15393892573676
  },
  {
    "ts": 1604361600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19404,
      "authorized": 19404,
      "funded": 3085
    },
    "payments": 29,
    "payments_amount": 7201879751391,
    "trades": 117,
    "traded_amount": 1013483921050,
    "price": [
      15.336697683273453,
      16.666666666666668,
      15.336697683273453,
      15.619941639596982
    ],
    "volume": 13131926110762
  },
  {
    "ts": 1604448000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19431,
      "authorized": 19431,
      "funded": 3087
    },
    "payments": 34,
    "payments_amount": 2833143721457,
    "trades": 353,
    "traded_amount": 1019239006171,
    "price": [
      15.619941639596982,
      16.666666666666668,
      15.454057429081148,
      15.734991379869712
    ],
    "volume": 16110493803870
  },
  {
    "ts": 1604534400,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19445,
      "authorized": 19445,
      "funded": 3086
    },
    "payments": 21,
    "payments_amount": 5047032850183,
    "trades": 420,
    "traded_amount": 1036625538672,
    "price": [
      15.734991379869712,
      16.184148458662865,
      14.368424394256937,
      14.710623499198402
    ],
    "volume": 16490168017142
  },
  {
    "ts": 1604620800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19460,
      "authorized": 19460,
      "funded": 3085
    },
    "payments": 30,
    "payments_amount": 3219716534124,
    "trades": 185,
    "traded_amount": 1007426957049,
    "price": [
      14.710623499198402,
      14.72607838554909,
      13.974864891421834,
      14.11714479
    ],
    "volume": 11435062369149
  },
  {
    "ts": 1604707200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19481,
      "authorized": 19481,
      "funded": 3086
    },
    "payments": 10,
    "payments_amount": 4922470040,
    "trades": 314,
    "traded_amount": 31309092717,
    "price": [
      14.11714479,
      15.031897653774992,
      13.68107360136106,
      14.93193833962351
    ],
    "volume": 288356436726
  },
  {
    "ts": 1604793600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19492,
      "authorized": 19492,
      "funded": 3087
    },
    "payments": 7,
    "payments_amount": 2737750000,
    "trades": 120,
    "traded_amount": 6239407607,
    "price": [
      14.93193833962351,
      14.93193833962351,
      14.379512523649698,
      14.543341695667158
    ],
    "volume": 44655942720
  },
  {
    "ts": 1604880000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19499,
      "authorized": 19499,
      "funded": 3086
    },
    "payments": 23,
    "payments_amount": 2916976439109,
    "trades": 211,
    "traded_amount": 1014219503700,
    "price": [
      14.543341695667158,
      15.007225309553995,
      14.543341695667158,
      14.756750620538789
    ],
    "volume": 14943781774144
  },
  {
    "ts": 1604966400,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19519,
      "authorized": 19519,
      "funded": 3092
    },
    "payments": 32,
    "payments_amount": 7214608146902,
    "trades": 128,
    "traded_amount": 1313046400251,
    "price": [
      14.756750620538789,
      14.878301529663558,
      14.36,
      14.80931610251613
    ],
    "volume": 14686672296892
  },
  {
    "ts": 1605052800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19531,
      "authorized": 19531,
      "funded": 3090
    },
    "payments": 24,
    "payments_amount": 2013298169917,
    "trades": 468,
    "traded_amount": 1706299099981,
    "price": [
      14.80931610251613,
      14.849037895400617,
      14.332543696995184,
      14.625967673721567
    ],
    "volume": 24933705441220
  },
  {
    "ts": 1605139200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19573,
      "authorized": 19573,
      "funded": 3086
    },
    "payments": 25,
    "payments_amount": 4998961676382,
    "trades": 168,
    "traded_amount": 107380209884,
    "price": [
      14.625967673721567,
      14.855706522546505,
      14.55946516912509,
      14.799471347092279
    ],
    "volume": 17307139129
  },
  {
    "ts": 1605225600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19605,
      "authorized": 19605,
      "funded": 3086
    },
    "payments": 56,
    "payments_amount": 10934053957651,
    "trades": 99,
    "traded_amount": 5841922825,
    "price": [
      14.799471347092279,
      14.85,
      14.359563469270535,
      14.359563469270535
    ],
    "volume": 37356872074
  },
  {
    "ts": 1605312000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19625,
      "authorized": 19625,
      "funded": 3085
    },
    "payments": 6,
    "payments_amount": 2498908000,
    "trades": 239,
    "traded_amount": 7693452749,
    "price": [
      14.359563469270535,
      14.646060416971398,
      14.008547008547009,
      14.512995
    ],
    "volume": 45263777470
  },
  {
    "ts": 1605398400,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19634,
      "authorized": 19634,
      "funded": 3085
    },
    "payments": 1,
    "payments_amount": 90000001,
    "trades": 37,
    "traded_amount": 4298576977,
    "price": [
      14.512995,
      14.9,
      14.437908496732026,
      14.9
    ],
    "volume": 41774999989
  },
  {
    "ts": 1605484800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19651,
      "authorized": 19651,
      "funded": 3085
    },
    "payments": 37,
    "payments_amount": 2052386369276,
    "trades": 464,
    "traded_amount": 316708389146,
    "price": [
      14.9,
      14.9,
      14.1,
      14.338641770036242
    ],
    "volume": 2774236504163
  },
  {
    "ts": 1605571200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19664,
      "authorized": 19664,
      "funded": 3087
    },
    "payments": 11,
    "payments_amount": 4102568421952,
    "trades": 146,
    "traded_amount": 20064342704,
    "price": [
      14.338641770036242,
      14.400960290624266,
      13.5,
      13.756680823797998
    ],
    "volume": 197735965079
  },
  {
    "ts": 1605657600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19676,
      "authorized": 19676,
      "funded": 3087
    },
    "payments": 33,
    "payments_amount": 6344192470668,
    "trades": 91,
    "traded_amount": 29145464947,
    "price": [
      13.756680823797998,
      14.381952357618365,
      12.545454545454545,
      14.31099413006691
    ],
    "volume": 171652142311
  },
  {
    "ts": 1605744000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19684,
      "authorized": 19684,
      "funded": 3087
    },
    "payments": 9,
    "payments_amount": 4004523771980,
    "trades": 110,
    "traded_amount": 16022696875,
    "price": [
      14.31099413006691,
      14.475198444267146,
      13.790989493933731,
      13.953119021109613
    ],
    "volume": 78275775169
  },
  {
    "ts": 1605830400,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19688,
      "authorized": 19688,
      "funded": 3086
    },
    "payments": 20,
    "payments_amount": 4400563822015,
    "trades": 214,
    "traded_amount": 552410272431,
    "price": [
      13.953119021109613,
      14.285714285714286,
      13.49997604373108,
      13.5
    ],
    "volume": 4866173548964
  },
  {
    "ts": 1605916800,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19704,
      "authorized": 19704,
      "funded": 3093
    },
    "payments": 9,
    "payments_amount": 53084917000,
    "trades": 836,
    "traded_amount": 113085765726,
    "price": [
      13.5,
      13.500000000846837,
      10.826472237680017,
      10.851501305483028
    ],
    "volume": 1185873156456
  },
  {
    "ts": 1606003200,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19709,
      "authorized": 19709,
      "funded": 3093
    },
    "payments": 11,
    "payments_amount": 1030499630000,
    "trades": 978,
    "traded_amount": 39413458342,
    "price": [
      10.851501305483028,
      12.208587643353678,
      10.441972717733472,
      11.414821460777532
    ],
    "volume": 250550173050
  },
  {
    "ts": 1606089600,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19721,
      "authorized": 19721,
      "funded": 3097
    },
    "payments": 10,
    "payments_amount": 3400171300000,
    "trades": 978,
    "traded_amount": 324947819403,
    "price": [
      11.414821460777532,
      11.65152163046733,
      8.999999961497593,
      9
    ],
    "volume": 1055570401068
  },
  {
    "ts": 1606176000,
    "supply": 10032286298706,
    "trustlines": {
      "total": 19740,
      "authorized": 19740,
      "funded": 3100
    },
    "payments": 36,
    "payments_amount": 5022081370608,
    "trades": 2428,
    "traded_amount": 278000588932,
    "price": [
      9,
      9.016375208552184,
      5.997680396351418,
      6.138474284456756
    ],
    "volume": 1032405645662
  },
  {
    "ts": 1606262400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19774,
      "authorized": 19774,
      "funded": 3103
    },
    "payments": 22,
    "payments_amount": 14564997564023,
    "trades": 4511,
    "traded_amount": 554146983385,
    "price": [
      6.138474284456756,
      6.489557217535516,
      5.171299288946348,
      6.092412144370672
    ],
    "volume": 2392227083892
  },
  {
    "ts": 1606348800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19798,
      "authorized": 19798,
      "funded": 3106
    },
    "payments": 18,
    "payments_amount": 4758972884812,
    "trades": 4887,
    "traded_amount": 655791645559,
    "price": [
      6.092412144370672,
      9.39246141532974,
      5.845526126579022,
      7.095001668047024
    ],
    "volume": 3022667230375
  },
  {
    "ts": 1606435200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19816,
      "authorized": 19816,
      "funded": 3104
    },
    "payments": 12,
    "payments_amount": 68608436173,
    "trades": 1913,
    "traded_amount": 135519811506,
    "price": [
      7.095001668047024,
      7.2862832478129835,
      5.850224955279974,
      5.850224955279974
    ],
    "volume": 434095208573
  },
  {
    "ts": 1606521600,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19842,
      "authorized": 19842,
      "funded": 3107
    },
    "payments": 4,
    "payments_amount": 6300000000,
    "trades": 1917,
    "traded_amount": 124586377457,
    "price": [
      5.850224955279974,
      6.114161835833064,
      5.4510125992037,
      6.011394201453172
    ],
    "volume": 432321533445
  },
  {
    "ts": 1606608000,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19859,
      "authorized": 19859,
      "funded": 3107
    },
    "payments": 4,
    "payments_amount": 5110000596,
    "trades": 420,
    "traded_amount": 33893641829,
    "price": [
      6.011394201453172,
      6.3433383046477525,
      5.920398929898614,
      6.17714445599367
    ],
    "volume": 106247465944
  },
  {
    "ts": 1606694400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19891,
      "authorized": 19891,
      "funded": 3109
    },
    "payments": 30,
    "payments_amount": 3074741142076,
    "trades": 555,
    "traded_amount": 58800560404,
    "price": [
      6.17714445599367,
      6.253133909116083,
      5.829016670633649,
      5.84903330553277
    ],
    "volume": 150580068641
  },
  {
    "ts": 1606780800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19900,
      "authorized": 19900,
      "funded": 3109
    },
    "payments": 15,
    "payments_amount": 206382557198,
    "trades": 687,
    "traded_amount": 291094960106,
    "price": [
      5.84903330553277,
      6.795520939536825,
      5.84903330553277,
      6.535336899486134
    ],
    "volume": 687471188098
  },
  {
    "ts": 1606867200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19921,
      "authorized": 19921,
      "funded": 3109
    },
    "payments": 15,
    "payments_amount": 628538645833,
    "trades": 1770,
    "traded_amount": 151464541648,
    "price": [
      6.535336899486134,
      6.810627100043065,
      6.4316,
      6.574268558706456
    ],
    "volume": 288033888017
  },
  {
    "ts": 1606953600,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19931,
      "authorized": 19931,
      "funded": 3105
    },
    "payments": 33,
    "payments_amount": 7256954233047,
    "trades": 2851,
    "traded_amount": 436010290283,
    "price": [
      6.574268558706456,
      6.74500222187851,
      6.401134899811904,
      6.592382663067351
    ],
    "volume": 1043845095570
  },
  {
    "ts": 1607040000,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19943,
      "authorized": 19943,
      "funded": 3104
    },
    "payments": 110,
    "payments_amount": 2885106030258,
    "trades": 1483,
    "traded_amount": 172142970219,
    "price": [
      6.592382663067351,
      7.558380719562848,
      6.4898662928959885,
      7.442317127061429
    ],
    "volume": 608260590978
  },
  {
    "ts": 1607126400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19963,
      "authorized": 19963,
      "funded": 3106
    },
    "payments": 278,
    "payments_amount": 19589426459,
    "trades": 838,
    "traded_amount": 21429792465,
    "price": [
      7.442317127061429,
      7.549062264198924,
      6.889304675182599,
      7.089358673633454
    ],
    "volume": 113640556660
  },
  {
    "ts": 1607212800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19971,
      "authorized": 19971,
      "funded": 3106
    },
    "payments": 186,
    "payments_amount": 12694308612,
    "trades": 910,
    "traded_amount": 26021907282,
    "price": [
      7.089358673633454,
      7.230308775284788,
      6.770122666756741,
      6.878182197785131
    ],
    "volume": 109257286659
  },
  {
    "ts": 1607299200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19981,
      "authorized": 19981,
      "funded": 3108
    },
    "payments": 521,
    "payments_amount": 8636068257216,
    "trades": 1271,
    "traded_amount": 334959679775,
    "price": [
      6.878182197785131,
      7.6923076923076925,
      6.866170680927941,
      7.2239414
    ],
    "volume": 651206657502
  },
  {
    "ts": 1607385600,
    "supply": 12032296298706,
    "trustlines": {
      "total": 19996,
      "authorized": 19996,
      "funded": 3112
    },
    "payments": 37,
    "payments_amount": 8019703955811,
    "trades": 843,
    "traded_amount": 24115808233,
    "price": [
      7.2239414,
      8.11360348586923,
      7.2239414,
      7.9207996146185495
    ],
    "volume": 87100005845
  },
  {
    "ts": 1607472000,
    "supply": 12032296298706,
    "trustlines": {
      "total": 20024,
      "authorized": 20024,
      "funded": 3112
    },
    "payments": 239,
    "payments_amount": 6233802781911,
    "trades": 2056,
    "traded_amount": 207931977675,
    "price": [
      7.9207996146185495,
      8.608815426997245,
      6.906320634551857,
      7.021727604296631
    ],
    "volume": 319826270578
  },
  {
    "ts": 1607558400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 20045,
      "authorized": 20045,
      "funded": 3117
    },
    "payments": 57,
    "payments_amount": 7238600103487,
    "trades": 782,
    "traded_amount": 64250568396,
    "price": [
      7.021727604296631,
      7.501999107821394,
      6.816915122336016,
      7.48968978339444
    ],
    "volume": 218184476013
  },
  {
    "ts": 1607644800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21235,
      "authorized": 21235,
      "funded": 3114
    },
    "payments": 53,
    "payments_amount": 7204238045377,
    "trades": 1045,
    "traded_amount": 184014268220,
    "price": [
      7.48968978339444,
      8.07428635120661,
      7.375071941019688,
      8.03401040183019
    ],
    "volume": 213435770917
  },
  {
    "ts": 1607731200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21253,
      "authorized": 21253,
      "funded": 3114
    },
    "payments": 2,
    "payments_amount": 507185385,
    "trades": 574,
    "traded_amount": 28458137806,
    "price": [
      8.03401040183019,
      8.074286365978583,
      7.367662547530333,
      7.616307171101113
    ],
    "volume": 154996095270
  },
  {
    "ts": 1607817600,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21280,
      "authorized": 21280,
      "funded": 3115
    },
    "payments": 3,
    "payments_amount": 600274274,
    "trades": 724,
    "traded_amount": 23977555801,
    "price": [
      7.616307171101113,
      7.791696701164448,
      6.790687251503289,
      6.931952512129784
    ],
    "volume": 146986166818
  },
  {
    "ts": 1607904000,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21288,
      "authorized": 21288,
      "funded": 3111
    },
    "payments": 14,
    "payments_amount": 5820170290427,
    "trades": 532,
    "traded_amount": 306192429107,
    "price": [
      6.931952512129784,
      7.336587960421466,
      6.8152016538011875,
      7.187004817542716
    ],
    "volume": 931450019668
  },
  {
    "ts": 1607990400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21309,
      "authorized": 21309,
      "funded": 3114
    },
    "payments": 24,
    "payments_amount": 7331664133262,
    "trades": 487,
    "traded_amount": 267422373259,
    "price": [
      7.187004817542716,
      7.3829851162609055,
      6.901580518167348,
      7.322924139233012
    ],
    "volume": 970281366697
  },
  {
    "ts": 1608076800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21347,
      "authorized": 21347,
      "funded": 3120
    },
    "payments": 52,
    "payments_amount": 6362639496029,
    "trades": 939,
    "traded_amount": 208635927732,
    "price": [
      7.322924139233012,
      7.597642705338436,
      6.264380180566163,
      6.264380180566163
    ],
    "volume": 669916035541
  },
  {
    "ts": 1608163200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21379,
      "authorized": 21379,
      "funded": 3115
    },
    "payments": 37,
    "payments_amount": 9868546191549,
    "trades": 1559,
    "traded_amount": 158425605548,
    "price": [
      6.264380180566163,
      6.781130717872439,
      5.9523809523809526,
      6.60113069843349
    ],
    "volume": 690467843415
  },
  {
    "ts": 1608249600,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21418,
      "authorized": 21418,
      "funded": 3117
    },
    "payments": 33,
    "payments_amount": 13036631160587,
    "trades": 460,
    "traded_amount": 647640818751,
    "price": [
      6.60113069843349,
      6.920555352581832,
      6.5,
      6.711859856366199
    ],
    "volume": 1332938515613
  },
  {
    "ts": 1608336000,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21459,
      "authorized": 21459,
      "funded": 3116
    },
    "payments": 17,
    "payments_amount": 78061404964,
    "trades": 585,
    "traded_amount": 408773966602,
    "price": [
      6.711859856366199,
      6.865300146146434,
      6.569439003021119,
      6.82778006561232
    ],
    "volume": 580661147487
  },
  {
    "ts": 1608422400,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21502,
      "authorized": 21502,
      "funded": 3116
    },
    "payments": 15,
    "payments_amount": 205110894553,
    "trades": 547,
    "traded_amount": 791417166262,
    "price": [
      6.82778006561232,
      7.015354231490572,
      6.76121578867875,
      6.955231861759808
    ],
    "volume": 2019475981665
  },
  {
    "ts": 1608508800,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21536,
      "authorized": 21536,
      "funded": 3117
    },
    "payments": 28,
    "payments_amount": 12325019374464,
    "trades": 1119,
    "traded_amount": 338179594039,
    "price": [
      6.955231861759808,
      7.590396526556951,
      6.79787610619469,
      7.33313130109085
    ],
    "volume": 882784958967
  },
  {
    "ts": 1608595200,
    "supply": 12032296298706,
    "trustlines": {
      "total": 21559,
      "authorized": 21559,
      "funded": 3121
    },
    "payments": 31,
    "payments_amount": 10518982403911,
    "trades": 1657,
    "traded_amount": 633332080941,
    "price": [
      7.33313130109085,
      8.333333333333334,
      7.238095238095238,
      7.577909717186367
    ],
    "volume": 823336963533
  },
  {
    "ts": 1608681600,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21580,
      "authorized": 21580,
      "funded": 3121
    },
    "payments": 22,
    "payments_amount": 5010911542489,
    "trades": 2941,
    "traded_amount": 738788625681,
    "price": [
      7.577909717186367,
      10.956502685420446,
      7.577829225112352,
      9.707581166167893
    ],
    "volume": 2216343205004
  },
  {
    "ts": 1608768000,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21591,
      "authorized": 21591,
      "funded": 3120
    },
    "payments": 30,
    "payments_amount": 8217955551711,
    "trades": 1515,
    "traded_amount": 534694242766,
    "price": [
      9.707581166167893,
      9.897728805330024,
      7.473624877957884,
      7.6912405058257445
    ],
    "volume": 1559385905276
  },
  {
    "ts": 1608854400,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21605,
      "authorized": 21605,
      "funded": 3120
    },
    "payments": 13,
    "payments_amount": 3524362310,
    "trades": 315,
    "traded_amount": 33622453070,
    "price": [
      7.6912405058257445,
      8.242274706163371,
      7.410552994693715,
      8.171400894535394
    ],
    "volume": 87542298081
  },
  {
    "ts": 1608940800,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21622,
      "authorized": 21622,
      "funded": 3123
    },
    "payments": 5,
    "payments_amount": 1713299464,
    "trades": 254,
    "traded_amount": 16299882843,
    "price": [
      8.171400894535394,
      8.528074029584127,
      8.05422515644203,
      8.420581655480984
    ],
    "volume": 72291939284
  },
  {
    "ts": 1609027200,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21638,
      "authorized": 21638,
      "funded": 3125
    },
    "payments": 7,
    "payments_amount": 2879478367,
    "trades": 418,
    "traded_amount": 35950557204,
    "price": [
      8.420581655480984,
      9.096079834560792,
      8.001584314771044,
      8.43646210600675
    ],
    "volume": 87680322328
  },
  {
    "ts": 1609113600,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21655,
      "authorized": 21655,
      "funded": 3122
    },
    "payments": 49,
    "payments_amount": 6563012161101,
    "trades": 613,
    "traded_amount": 296745000895,
    "price": [
      8.43646210600675,
      8.657700567079228,
      8.049964356037648,
      8.588568801879225
    ],
    "volume": 923377260949
  },
  {
    "ts": 1609200000,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21662,
      "authorized": 21662,
      "funded": 3120
    },
    "payments": 20,
    "payments_amount": 6719587706494,
    "trades": 888,
    "traded_amount": 187118812047,
    "price": [
      8.588568801879225,
      9.549567641324499,
      8.36068903064282,
      8.941984405823993
    ],
    "volume": 613256058334
  },
  {
    "ts": 1609286400,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21677,
      "authorized": 21677,
      "funded": 3122
    },
    "payments": 29,
    "payments_amount": 795632289147,
    "trades": 830,
    "traded_amount": 459067411938,
    "price": [
      8.941984405823993,
      9.585535322758917,
      8.94150483692628,
      9.399030074663317
    ],
    "volume": 372818584268
  },
  {
    "ts": 1609372800,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21687,
      "authorized": 21687,
      "funded": 3123
    },
    "payments": 39,
    "payments_amount": 17545755801,
    "trades": 1229,
    "traded_amount": 68325457981,
    "price": [
      9.399030074663317,
      9.95237526930657,
      9.399030074663317,
      9.567881300458215
    ],
    "volume": 139559048642
  },
  {
    "ts": 1609459200,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21704,
      "authorized": 21704,
      "funded": 3121
    },
    "payments": 14,
    "payments_amount": 8023197251,
    "trades": 1265,
    "traded_amount": 43298694636,
    "price": [
      9.567881300458215,
      9.60826435702705,
      8.763743859752331,
      9.253397517765334
    ],
    "volume": 201912556300
  },
  {
    "ts": 1609545600,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21716,
      "authorized": 21716,
      "funded": 3120
    },
    "payments": 8,
    "payments_amount": 4478535679,
    "trades": 992,
    "traded_amount": 18764418288,
    "price": [
      9.253397517765334,
      9.577356610374892,
      9.101827454313643,
      9.544910179640718
    ],
    "volume": 86406062502
  },
  {
    "ts": 1609632000,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21734,
      "authorized": 21734,
      "funded": 3121
    },
    "payments": 7,
    "payments_amount": 3606721609,
    "trades": 601,
    "traded_amount": 22196942988,
    "price": [
      9.544910179640718,
      9.791666666666666,
      8.872436525031004,
      9.0901961
    ],
    "volume": 116747112122
  },
  {
    "ts": 1609718400,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21752,
      "authorized": 21752,
      "funded": 3117
    },
    "payments": 28,
    "payments_amount": 2012747333195,
    "trades": 1051,
    "traded_amount": 337991486678,
    "price": [
      9.0901961,
      9.510110571514803,
      7.440012042751769,
      7.479869452798688
    ],
    "volume": 1809372414856
  },
  {
    "ts": 1609804800,
    "supply": 12032296298705,
    "trustlines": {
      "total": 21764,
      "authorized": 21764,
      "funded": 3120
    },
    "payments": 29,
    "payments_amount": 6029706454036,
    "trades": 712,
    "traded_amount": 356586531842,
    "price": [
      7.479869452798688,
      8.175984523886308,
      5.866248360476479,
      6.3499619838341665
    ],
    "volume": 616899136103
  },
  {
    "ts": 1609891200,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21786,
      "authorized": 21786,
      "funded": 3128
    },
    "payments": 23,
    "payments_amount": 2957243736781,
    "trades": 1647,
    "traded_amount": 951452874580,
    "price": [
      6.3499619838341665,
      6.385411696253154,
      3.3034180439403897,
      3.664601069949329
    ],
    "volume": 1920384784882
  },
  {
    "ts": 1609977600,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21813,
      "authorized": 21813,
      "funded": 3138
    },
    "payments": 33,
    "payments_amount": 2924966342416,
    "trades": 395,
    "traded_amount": 348022198749,
    "price": [
      3.664601069949329,
      4.097256616525149,
      3.2600347894581376,
      3.8610038617713593
    ],
    "volume": 339273151534
  },
  {
    "ts": 1610064000,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21835,
      "authorized": 21835,
      "funded": 3141
    },
    "payments": 16,
    "payments_amount": 3623418535678,
    "trades": 462,
    "traded_amount": 110066309266,
    "price": [
      3.8610038617713593,
      4.54725704461065,
      3.7135614702154625,
      4.1640004353030795
    ],
    "volume": 231249363221
  },
  {
    "ts": 1610150400,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21864,
      "authorized": 21864,
      "funded": 3141
    },
    "payments": 14,
    "payments_amount": 6605128211,
    "trades": 329,
    "traded_amount": 55502091174,
    "price": [
      4.1640004353030795,
      4.277257730376736,
      3.8461275587501564,
      3.850000050010252
    ],
    "volume": 96263110616
  },
  {
    "ts": 1610236800,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21893,
      "authorized": 21893,
      "funded": 3151
    },
    "payments": 15,
    "payments_amount": 16412541062,
    "trades": 193,
    "traded_amount": 85874510026,
    "price": [
      3.850000050010252,
      4.637257488824412,
      3.8039606071907412,
      4.4124786
    ],
    "volume": 246664843958
  },
  {
    "ts": 1610323200,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21922,
      "authorized": 21922,
      "funded": 3148
    },
    "payments": 36,
    "payments_amount": 4027156482721,
    "trades": 864,
    "traded_amount": 512437671092,
    "price": [
      4.4124786,
      5.820134320024445,
      4.4124786,
      4.49939893875443
    ],
    "volume": 346891561382
  },
  {
    "ts": 1610409600,
    "supply": 12032296298700,
    "trustlines": {
      "total": 21956,
      "authorized": 21956,
      "funded": 3147
    },
    "payments": 29,
    "payments_amount": 3625546112791,
    "trades": 574,
    "traded_amount": 104223895257,
    "price": [
      4.49939893875443,
      4.727256606481459,
      3.984310523263172,
      4.347389454841875
    ],
    "volume": 270008785993
  },
  {
    "ts": 1610496000,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22009,
      "authorized": 22009,
      "funded": 3147
    },
    "payments": 89,
    "payments_amount": 1014270507648,
    "trades": 486,
    "traded_amount": 416841942410,
    "price": [
      4.347389454841875,
      4.457256595830425,
      3.9609782850161057,
      4.003084758001685
    ],
    "volume": 43567248621
  },
  {
    "ts": 1610582400,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22058,
      "authorized": 22058,
      "funded": 3148
    },
    "payments": 22,
    "payments_amount": 20900562717,
    "trades": 212,
    "traded_amount": 34390465997,
    "price": [
      4.003084758001685,
      4.217753649846511,
      3.885852952781797,
      4.091650155373925
    ],
    "volume": 83493475832
  },
  {
    "ts": 1610668800,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22087,
      "authorized": 22087,
      "funded": 3148
    },
    "payments": 39,
    "payments_amount": 16374803098,
    "trades": 194,
    "traded_amount": 428750606350,
    "price": [
      4.091650155373925,
      4.547256598510441,
      3.9567665172771944,
      4.295117606826148
    ],
    "volume": 76776333717
  },
  {
    "ts": 1610755200,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22136,
      "authorized": 22136,
      "funded": 3149
    },
    "payments": 23,
    "payments_amount": 12730742497,
    "trades": 209,
    "traded_amount": 26515005686,
    "price": [
      4.295117606826148,
      4.367256599645532,
      3.92953377861561,
      4.169263345032342
    ],
    "volume": 77983549427
  },
  {
    "ts": 1610841600,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22175,
      "authorized": 22175,
      "funded": 3152
    },
    "payments": 14,
    "payments_amount": 6669556660,
    "trades": 340,
    "traded_amount": 65866772379,
    "price": [
      4.169263345032342,
      4.19000000159831,
      3.841885610847799,
      4.004327877570078
    ],
    "volume": 156279316193
  },
  {
    "ts": 1610928000,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22209,
      "authorized": 22209,
      "funded": 3150
    },
    "payments": 41,
    "payments_amount": 6060956964889,
    "trades": 159,
    "traded_amount": 23354131892,
    "price": [
      4.004327877570078,
      4.135567002713194,
      3.950762106617605,
      4
    ],
    "volume": 87368619886
  },
  {
    "ts": 1611014400,
    "supply": 12032296298700,
    "trustlines": {
      "total": 22232,
      "authorized": 22232,
      "funded": 3153
    },
    "payments": 52,
    "payments_amount": 6397341699723,
    "trades": 310,
    "traded_amount": 438600304848,
    "price": [
      4,
      4.097256599895744,
      3.8712432353796915,
      4.06341423940727
    ],
    "volume": 81727346304
  },
  {
    "ts": 1611100800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22268,
      "authorized": 22268,
      "funded": 3153
    },
    "payments": 49,
    "payments_amount": 3786325696890,
    "trades": 287,
    "traded_amount": 24354505053,
    "price": [
      4.06341423940727,
      4.417539222782489,
      4.06341423940727,
      4.215096623205588
    ],
    "volume": 62827648539
  },
  {
    "ts": 1611187200,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22303,
      "authorized": 22303,
      "funded": 3149
    },
    "payments": 40,
    "payments_amount": 3825624903376,
    "trades": 335,
    "traded_amount": 83507490106,
    "price": [
      4.215096623205588,
      4.9072566016167904,
      4.10744257456832,
      4.8368393800790015
    ],
    "volume": 155485577127
  },
  {
    "ts": 1611273600,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22344,
      "authorized": 22344,
      "funded": 3155
    },
    "payments": 58,
    "payments_amount": 6241219952080,
    "trades": 237,
    "traded_amount": 430286231419,
    "price": [
      4.8368393800790015,
      5.32315953034814,
      4.37035038143481,
      4.547256595950205
    ],
    "volume": 77312667266
  },
  {
    "ts": 1611360000,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22380,
      "authorized": 22380,
      "funded": 3155
    },
    "payments": 52,
    "payments_amount": 28974421536,
    "trades": 79,
    "traded_amount": 8869863502,
    "price": [
      4.547256595950205,
      4.614770200639646,
      4.406188326615138,
      4.555829759569492
    ],
    "volume": 26749415081
  },
  {
    "ts": 1611446400,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22432,
      "authorized": 22432,
      "funded": 3155
    },
    "payments": 37,
    "payments_amount": 8920432546,
    "trades": 192,
    "traded_amount": 28251977148,
    "price": [
      4.555829759569492,
      4.6366534698730435,
      4.362637362637362,
      4.491111549604011
    ],
    "volume": 84694757725
  },
  {
    "ts": 1611532800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22482,
      "authorized": 22482,
      "funded": 3152
    },
    "payments": 88,
    "payments_amount": 4655219680490,
    "trades": 250,
    "traded_amount": 24671932378,
    "price": [
      4.491111549604011,
      4.676160239051265,
      4.432148298930478,
      4.676160239051265
    ],
    "volume": 55667416918
  },
  {
    "ts": 1611619200,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22550,
      "authorized": 22550,
      "funded": 3156
    },
    "payments": 96,
    "payments_amount": 873176725265,
    "trades": 775,
    "traded_amount": 503092176147,
    "price": [
      4.676160239051265,
      4.874462286519419,
      4.608663549101649,
      4.668785732700444
    ],
    "volume": 300589611117
  },
  {
    "ts": 1611705600,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22606,
      "authorized": 22606,
      "funded": 3153
    },
    "payments": 98,
    "payments_amount": 3461521524216,
    "trades": 1319,
    "traded_amount": 458251917582,
    "price": [
      4.668785732700444,
      5.23688859678742,
      4.668785732700444,
      5.10300838890665
    ],
    "volume": 150598023092
  },
  {
    "ts": 1611792000,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22663,
      "authorized": 22663,
      "funded": 3159
    },
    "payments": 83,
    "payments_amount": 50970768135,
    "trades": 2118,
    "traded_amount": 45109487332,
    "price": [
      5.10300838890665,
      5.212832410987808,
      4.067850809985834,
      4.148369497431788
    ],
    "volume": 128415977124
  },
  {
    "ts": 1611878400,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22706,
      "authorized": 22706,
      "funded": 3160
    },
    "payments": 75,
    "payments_amount": 670874226546,
    "trades": 2488,
    "traded_amount": 594878998681,
    "price": [
      4.148369497431788,
      4.244463611781361,
      3.3255813953488373,
      4.181898875730147
    ],
    "volume": 354582760470
  },
  {
    "ts": 1611964800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22772,
      "authorized": 22772,
      "funded": 3160
    },
    "payments": 107,
    "payments_amount": 75461526120,
    "trades": 655,
    "traded_amount": 101024016660,
    "price": [
      4.181898875730147,
      4.3344613082200505,
      3.443963784723092,
      3.742341826672669
    ],
    "volume": 200798967729
  },
  {
    "ts": 1612051200,
    "supply": 12788896298698,
    "trustlines": {
      "total": 22862,
      "authorized": 22862,
      "funded": 3162
    },
    "payments": 70,
    "payments_amount": 82235748732,
    "trades": 775,
    "traded_amount": 65676194316,
    "price": [
      3.742341826672669,
      4.113371591275434,
      3.6515384358006835,
      3.9742480023733213
    ],
    "volume": 174875080398
  },
  {
    "ts": 1612137600,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16295,
      "authorized": 16295,
      "funded": 3157
    },
    "payments": 127,
    "payments_amount": 6090445183489,
    "trades": 633,
    "traded_amount": 103017489486,
    "price": [
      3.9742480023733213,
      4.1140710578384425,
      3.353963561178747,
      3.713962495418954
    ],
    "volume": 312177339405
  },
  {
    "ts": 1612224000,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16446,
      "authorized": 16446,
      "funded": 3161
    },
    "payments": 156,
    "payments_amount": 2182941203707,
    "trades": 398,
    "traded_amount": 456085172366,
    "price": [
      3.713962495418954,
      3.805179367037281,
      3.4967013117740726,
      3.496707312660564
    ],
    "volume": 131359331019
  },
  {
    "ts": 1612310400,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16604,
      "authorized": 16604,
      "funded": 3163
    },
    "payments": 166,
    "payments_amount": 8969365247394,
    "trades": 793,
    "traded_amount": 73024591861,
    "price": [
      3.496707312660564,
      3.6604130022219223,
      3.4765549958065844,
      3.5426621485795953
    ],
    "volume": 150603094799
  },
  {
    "ts": 1612396800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16693,
      "authorized": 16693,
      "funded": 3162
    },
    "payments": 175,
    "payments_amount": 4183698355075,
    "trades": 567,
    "traded_amount": 466913860705,
    "price": [
      3.5426621485795953,
      3.8159134242145427,
      3.4897821681181087,
      3.713929565402014
    ],
    "volume": 128197675559
  },
  {
    "ts": 1612483200,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16804,
      "authorized": 16804,
      "funded": 3165
    },
    "payments": 193,
    "payments_amount": 2542561006279,
    "trades": 632,
    "traded_amount": 465058534381,
    "price": [
      3.713929565402014,
      3.713929565402014,
      3.3509706213651786,
      3.483446060425548
    ],
    "volume": 135447128975
  },
  {
    "ts": 1612569600,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16838,
      "authorized": 16838,
      "funded": 3165
    },
    "payments": 24,
    "payments_amount": 26731607838,
    "trades": 791,
    "traded_amount": 145500203512,
    "price": [
      3.483446060425548,
      3.554574299710071,
      3.1548509566284153,
      3.186235734141957
    ],
    "volume": 182341511273
  },
  {
    "ts": 1612656000,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16870,
      "authorized": 16870,
      "funded": 3164
    },
    "payments": 16,
    "payments_amount": 15532650702,
    "trades": 965,
    "traded_amount": 158019049155,
    "price": [
      3.186235734141957,
      3.3207950466590908,
      2.886928493458347,
      3.187072158006958
    ],
    "volume": 190780388867
  },
  {
    "ts": 1612742400,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16893,
      "authorized": 16893,
      "funded": 3166
    },
    "payments": 28,
    "payments_amount": 3425210111348,
    "trades": 539,
    "traded_amount": 49704473791,
    "price": [
      3.187072158006958,
      3.218679231906162,
      3.0088618991122806,
      3.0354531977003205
    ],
    "volume": 100452602614
  },
  {
    "ts": 1612828800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16920,
      "authorized": 16920,
      "funded": 3164
    },
    "payments": 24,
    "payments_amount": 5290082967001,
    "trades": 420,
    "traded_amount": 60824900774,
    "price": [
      3.0354531977003205,
      3.1524966686634595,
      3,
      3.0140360315012233
    ],
    "volume": 116708209088
  },
  {
    "ts": 1612915200,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16937,
      "authorized": 16937,
      "funded": 3169
    },
    "payments": 5,
    "payments_amount": 3000350000001,
    "trades": 758,
    "traded_amount": 77532136013,
    "price": [
      3.0140360315012233,
      3.1682813995121184,
      2.7178423236514524,
      2.894285714285714
    ],
    "volume": 149418776723
  },
  {
    "ts": 1613001600,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16962,
      "authorized": 16962,
      "funded": 3174
    },
    "payments": 23,
    "payments_amount": 8102168712254,
    "trades": 586,
    "traded_amount": 94753828696,
    "price": [
      2.894285714285714,
      2.8946112062630225,
      2.617222634346347,
      2.6919916534770607
    ],
    "volume": 142005078611
  },
  {
    "ts": 1613088000,
    "supply": 12788896298698,
    "trustlines": {
      "total": 16982,
      "authorized": 16982,
      "funded": 3178
    },
    "payments": 25,
    "payments_amount": 5532358981713,
    "trades": 841,
    "traded_amount": 136344446757,
    "price": [
      2.6919916534770607,
      2.7447227836698995,
      2.289560359912927,
      2.3007214023436537
    ],
    "volume": 145478605611
  },
  {
    "ts": 1613174400,
    "supply": 12788896298698,
    "trustlines": {
      "total": 17003,
      "authorized": 17003,
      "funded": 3177
    },
    "payments": 17,
    "payments_amount": 597614933468,
    "trades": 1286,
    "traded_amount": 750219570918,
    "price": [
      2.3007214023436537,
      2.4363731277766254,
      1.2499999999932156,
      2.1678931328443127
    ],
    "volume": 1401835438500
  },
  {
    "ts": 1613260800,
    "supply": 12788896298698,
    "trustlines": {
      "total": 17036,
      "authorized": 17036,
      "funded": 3181
    },
    "payments": 12,
    "payments_amount": 5760550902,
    "trades": 667,
    "traded_amount": 116443007464,
    "price": [
      2.1678931328443127,
      2.4626850336447137,
      2.1143120682997982,
      2.332274712187376
    ],
    "volume": 124726458862
  },
  {
    "ts": 1613347200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17052,
      "authorized": 17052,
      "funded": 3181
    },
    "payments": 21,
    "payments_amount": 8526558598475,
    "trades": 978,
    "traded_amount": 109121675917,
    "price": [
      2.332274712187376,
      2.8075783298969297,
      2.264466112524577,
      2.48777394152548
    ],
    "volume": 147338912019
  },
  {
    "ts": 1613433600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17064,
      "authorized": 17064,
      "funded": 3177
    },
    "payments": 12,
    "payments_amount": 30974951902,
    "trades": 1135,
    "traded_amount": 89701283449,
    "price": [
      2.48777394152548,
      2.5641025751836857,
      2.359310227557895,
      2.4895901569790024
    ],
    "volume": 118396404804
  },
  {
    "ts": 1613520000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17086,
      "authorized": 17086,
      "funded": 3177
    },
    "payments": 4,
    "payments_amount": 3520099000000,
    "trades": 1095,
    "traded_amount": 103813030009,
    "price": [
      2.4895901569790024,
      2.631578961927771,
      2.3509671746776086,
      2.4182175660567427
    ],
    "volume": 154747855005
  },
  {
    "ts": 1613606400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17105,
      "authorized": 17105,
      "funded": 3180
    },
    "payments": 4,
    "payments_amount": 105500929824,
    "trades": 1078,
    "traded_amount": 181508928549,
    "price": [
      2.4182175660567427,
      2.5395502817126103,
      2.3608352552414806,
      2.4430742224190927
    ],
    "volume": 330203744925
  },
  {
    "ts": 1613692800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17128,
      "authorized": 17128,
      "funded": 3179
    },
    "payments": 13,
    "payments_amount": 1237989713159,
    "trades": 683,
    "traded_amount": 81415475511,
    "price": [
      2.4430742224190927,
      2.502337149989031,
      2.344622893717595,
      2.3892620794131716
    ],
    "volume": 98413182563
  },
  {
    "ts": 1613779200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17171,
      "authorized": 17171,
      "funded": 3180
    },
    "payments": 8,
    "payments_amount": 442723788,
    "trades": 768,
    "traded_amount": 195370041729,
    "price": [
      2.3892620794131716,
      2.6,
      2.2488783596143036,
      2.518241932121303
    ],
    "volume": 237106434785
  },
  {
    "ts": 1613865600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17230,
      "authorized": 17230,
      "funded": 3179
    },
    "payments": 6,
    "payments_amount": 6004235005,
    "trades": 793,
    "traded_amount": 38636778233,
    "price": [
      2.518241932121303,
      2.5800827095573884,
      2.402229751885452,
      2.462633567753317
    ],
    "volume": 65099886333
  },
  {
    "ts": 1613952000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17263,
      "authorized": 17263,
      "funded": 3177
    },
    "payments": 21,
    "payments_amount": 12022565967881,
    "trades": 1410,
    "traded_amount": 125432681008,
    "price": [
      2.462633567753317,
      3.1201323164568593,
      2.3255813953488373,
      2.6345342379663714
    ],
    "volume": 139246736489
  },
  {
    "ts": 1614038400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17305,
      "authorized": 17305,
      "funded": 3177
    },
    "payments": 13,
    "payments_amount": 2029596236114,
    "trades": 2036,
    "traded_amount": 134137975236,
    "price": [
      2.6345342379663714,
      3.742109145553925,
      2.6345342379663714,
      3.1662625628537664
    ],
    "volume": 238034256157
  },
  {
    "ts": 1614124800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17371,
      "authorized": 17371,
      "funded": 3175
    },
    "payments": 17,
    "payments_amount": 2004324909149,
    "trades": 1296,
    "traded_amount": 43893929460,
    "price": [
      3.1662625628537664,
      3.2879769275231054,
      2.8273589890584727,
      3.0180128528922694
    ],
    "volume": 81256003092
  },
  {
    "ts": 1614211200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17429,
      "authorized": 17429,
      "funded": 3174
    },
    "payments": 5,
    "payments_amount": 24816594563,
    "trades": 1418,
    "traded_amount": 33660707809,
    "price": [
      3.0180128528922694,
      3.189082585538529,
      2.880871847674735,
      3.189082585538529
    ],
    "volume": 53397324287
  },
  {
    "ts": 1614297600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17474,
      "authorized": 17474,
      "funded": 3176
    },
    "payments": 7,
    "payments_amount": 1004286622284,
    "trades": 1171,
    "traded_amount": 50473725283,
    "price": [
      3.189082585538529,
      3.3952589115681677,
      3.0399414914463825,
      3.0566905111721425
    ],
    "volume": 90214552430
  },
  {
    "ts": 1614384000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17529,
      "authorized": 17529,
      "funded": 3178
    },
    "payments": 8,
    "payments_amount": 12601313726,
    "trades": 1252,
    "traded_amount": 70570293833,
    "price": [
      3.0566905111721425,
      3.0566905111721425,
      2.6108739895144226,
      2.774877471456419
    ],
    "volume": 111779124725
  },
  {
    "ts": 1614470400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17552,
      "authorized": 17552,
      "funded": 3173
    },
    "payments": 6,
    "payments_amount": 4461300725,
    "trades": 548,
    "traded_amount": 42376694809,
    "price": [
      2.774877471456419,
      3.162646088404229,
      2.7023787260245853,
      2.9974131749249713
    ],
    "volume": 63274745297
  },
  {
    "ts": 1614556800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17587,
      "authorized": 17587,
      "funded": 3177
    },
    "payments": 19,
    "payments_amount": 6033283256187,
    "trades": 697,
    "traded_amount": 52269597488,
    "price": [
      2.9974131749249713,
      3.0145926380751993,
      2.786917225852987,
      2.8273371014860915
    ],
    "volume": 73241817675
  },
  {
    "ts": 1614643200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17672,
      "authorized": 17672,
      "funded": 3178
    },
    "payments": 10,
    "payments_amount": 2263898846048,
    "trades": 624,
    "traded_amount": 53050337490,
    "price": [
      2.8273371014860915,
      3.0195441318099845,
      2.782596497281622,
      2.907164160914326
    ],
    "volume": 81127150059
  },
  {
    "ts": 1614729600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17746,
      "authorized": 17746,
      "funded": 3176
    },
    "payments": 8,
    "payments_amount": 8379789783,
    "trades": 794,
    "traded_amount": 51318452806,
    "price": [
      2.907164160914326,
      2.93604753583156,
      2.7715111646259247,
      2.868987191047915
    ],
    "volume": 57740187435
  },
  {
    "ts": 1614816000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17826,
      "authorized": 17826,
      "funded": 3176
    },
    "payments": 17,
    "payments_amount": 6323548528,
    "trades": 977,
    "traded_amount": 48256842573,
    "price": [
      2.868987191047915,
      2.987578100924584,
      2.8097000992546803,
      2.9555658294896716
    ],
    "volume": 69575839622
  },
  {
    "ts": 1614902400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17887,
      "authorized": 17887,
      "funded": 3177
    },
    "payments": 6,
    "payments_amount": 2004355551,
    "trades": 1055,
    "traded_amount": 52996231278,
    "price": [
      2.9555658294896716,
      3.112840569899617,
      2.869565217391304,
      2.9415882901454777
    ],
    "volume": 87940523416
  },
  {
    "ts": 1614988800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 17951,
      "authorized": 17951,
      "funded": 3176
    },
    "payments": 6,
    "payments_amount": 102952487370,
    "trades": 384,
    "traded_amount": 14730001914,
    "price": [
      2.9415882901454777,
      3.0409482026017556,
      2.9261536182505,
      2.9887832810179584
    ],
    "volume": 30070048478
  },
  {
    "ts": 1615075200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18048,
      "authorized": 18048,
      "funded": 3178
    },
    "payments": 8,
    "payments_amount": 108667658894,
    "trades": 558,
    "traded_amount": 23454445242,
    "price": [
      2.9887832810179584,
      2.9887832810179584,
      2.8625021368296184,
      2.926757890131898
    ],
    "volume": 44983898402
  },
  {
    "ts": 1615161600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18122,
      "authorized": 18122,
      "funded": 3179
    },
    "payments": 16,
    "payments_amount": 27482831516,
    "trades": 1442,
    "traded_amount": 58429898821,
    "price": [
      2.926757890131898,
      2.9464517101099896,
      2.834373699542239,
      2.853512585812357
    ],
    "volume": 66510334505
  },
  {
    "ts": 1615248000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18193,
      "authorized": 18193,
      "funded": 3181
    },
    "payments": 19,
    "payments_amount": 1015641199563,
    "trades": 648,
    "traded_amount": 40696625823,
    "price": [
      2.853512585812357,
      2.874147810878349,
      2.75239458328746,
      2.7867279349613487
    ],
    "volume": 70636181567
  },
  {
    "ts": 1615334400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18254,
      "authorized": 18254,
      "funded": 3176
    },
    "payments": 16,
    "payments_amount": 35577289636,
    "trades": 727,
    "traded_amount": 175830848107,
    "price": [
      2.7867279349613487,
      2.9321561649840766,
      2.7593999994815697,
      2.885239175396193
    ],
    "volume": 301519954214
  },
  {
    "ts": 1615420800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18335,
      "authorized": 18335,
      "funded": 3181
    },
    "payments": 30,
    "payments_amount": 371208578972,
    "trades": 662,
    "traded_amount": 88036508796,
    "price": [
      2.885239175396193,
      3.0225911000067867,
      2.885239175396193,
      2.967023674867867
    ],
    "volume": 88877522322
  },
  {
    "ts": 1615507200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18395,
      "authorized": 18395,
      "funded": 3178
    },
    "payments": 19,
    "payments_amount": 44430580292,
    "trades": 555,
    "traded_amount": 77877349426,
    "price": [
      2.967023674867867,
      3.147672274717677,
      2.954971793139642,
      3.094738423230535
    ],
    "volume": 59168309836
  },
  {
    "ts": 1615593600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18464,
      "authorized": 18464,
      "funded": 3178
    },
    "payments": 13,
    "payments_amount": 29172803365,
    "trades": 565,
    "traded_amount": 55742248332,
    "price": [
      3.094738423230535,
      3.21098551646382,
      2.9371705417235376,
      2.9483478051601733
    ],
    "volume": 54338069950
  },
  {
    "ts": 1615680000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18552,
      "authorized": 18552,
      "funded": 3177
    },
    "payments": 11,
    "payments_amount": 23557250658,
    "trades": 519,
    "traded_amount": 49538871467,
    "price": [
      2.9483478051601733,
      3.072746053296108,
      2.9483478051601733,
      3.072410919778904
    ],
    "volume": 62588727174
  },
  {
    "ts": 1615766400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18614,
      "authorized": 18614,
      "funded": 3174
    },
    "payments": 25,
    "payments_amount": 23779768892,
    "trades": 541,
    "traded_amount": 169842351263,
    "price": [
      3.072410919778904,
      3.223336720766382,
      2.988990372964967,
      3.0868059992023897
    ],
    "volume": 395011688454
  },
  {
    "ts": 1615852800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18682,
      "authorized": 18682,
      "funded": 3175
    },
    "payments": 20,
    "payments_amount": 23064975999,
    "trades": 407,
    "traded_amount": 43898283889,
    "price": [
      3.0868059992023897,
      3.1813290000978935,
      2.9318375340599454,
      3.0161360519530382
    ],
    "volume": 52608255810
  },
  {
    "ts": 1615939200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18752,
      "authorized": 18752,
      "funded": 3173
    },
    "payments": 20,
    "payments_amount": 21141122349,
    "trades": 283,
    "traded_amount": 27030042374,
    "price": [
      3.0161360519530382,
      3.0714688380041357,
      2.960947243166554,
      2.973025642218913
    ],
    "volume": 45953872954
  },
  {
    "ts": 1616025600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18793,
      "authorized": 18793,
      "funded": 3173
    },
    "payments": 7,
    "payments_amount": 9207375791,
    "trades": 276,
    "traded_amount": 27586872588,
    "price": [
      2.973025642218913,
      3.0153215855792608,
      2.9314314661852294,
      2.9926318338939297
    ],
    "volume": 35740053969
  },
  {
    "ts": 1616112000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18836,
      "authorized": 18836,
      "funded": 3175
    },
    "payments": 12,
    "payments_amount": 6203411515,
    "trades": 206,
    "traded_amount": 24835611922,
    "price": [
      2.9926318338939297,
      3.0710973591917847,
      2.947280085932405,
      3.0050564980508154
    ],
    "volume": 43497625496
  },
  {
    "ts": 1616198400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18895,
      "authorized": 18895,
      "funded": 3176
    },
    "payments": 13,
    "payments_amount": 45630253314,
    "trades": 433,
    "traded_amount": 45218368230,
    "price": [
      3.0050564980508154,
      3.0276187576126676,
      2.745595628571838,
      2.8823201618187517
    ],
    "volume": 64111847268
  },
  {
    "ts": 1616284800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 18957,
      "authorized": 18957,
      "funded": 3175
    },
    "payments": 4,
    "payments_amount": 11088151202,
    "trades": 375,
    "traded_amount": 34646049247,
    "price": [
      2.8823201618187517,
      3.027600000286764,
      2.829732334526466,
      2.915936467156618
    ],
    "volume": 77274576547
  },
  {
    "ts": 1616371200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19002,
      "authorized": 19002,
      "funded": 3177
    },
    "payments": 24,
    "payments_amount": 28065771473,
    "trades": 461,
    "traded_amount": 72839015240,
    "price": [
      2.915936467156618,
      3.0288066719561932,
      2.829731131244741,
      3.0287046438124663
    ],
    "volume": 95491976150
  },
  {
    "ts": 1616457600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19035,
      "authorized": 19035,
      "funded": 3174
    },
    "payments": 13,
    "payments_amount": 55140310968,
    "trades": 408,
    "traded_amount": 32597098260,
    "price": [
      3.0287046438124663,
      3.0607055964015624,
      2.9249707762255204,
      2.984962519325677
    ],
    "volume": 53855151081
  },
  {
    "ts": 1616544000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19085,
      "authorized": 19085,
      "funded": 3171
    },
    "payments": 7,
    "payments_amount": 26029658452,
    "trades": 600,
    "traded_amount": 99888768096,
    "price": [
      2.984962519325677,
      3.347251374520576,
      2.95007444,
      3.246594149296703
    ],
    "volume": 105110353752
  },
  {
    "ts": 1616630400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19122,
      "authorized": 19122,
      "funded": 3172
    },
    "payments": 10,
    "payments_amount": 75574520836,
    "trades": 877,
    "traded_amount": 105878258356,
    "price": [
      3.246594149296703,
      3.4206318026090012,
      3.1938034599370355,
      3.276321803491412
    ],
    "volume": 138305923169
  },
  {
    "ts": 1616716800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19156,
      "authorized": 19156,
      "funded": 3171
    },
    "payments": 7,
    "payments_amount": 28989452189,
    "trades": 233,
    "traded_amount": 21875171701,
    "price": [
      3.276321803491412,
      3.276321803491412,
      3.0416666666666665,
      3.0513698630136985
    ],
    "volume": 38710946939
  },
  {
    "ts": 1616803200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19185,
      "authorized": 19185,
      "funded": 3171
    },
    "payments": 8,
    "payments_amount": 22503487242,
    "trades": 428,
    "traded_amount": 60651351115,
    "price": [
      3.0513698630136985,
      3.1889931438857166,
      3.0411035556582773,
      3.090083644544687
    ],
    "volume": 89695260809
  },
  {
    "ts": 1616889600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19229,
      "authorized": 19229,
      "funded": 3177
    },
    "payments": 6,
    "payments_amount": 1075418133,
    "trades": 354,
    "traded_amount": 75827326444,
    "price": [
      3.090083644544687,
      3.136302996600438,
      2.9062206199259206,
      2.9779766139966974
    ],
    "volume": 113620821843
  },
  {
    "ts": 1616976000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19271,
      "authorized": 19271,
      "funded": 3179
    },
    "payments": 10,
    "payments_amount": 23662529134,
    "trades": 148,
    "traded_amount": 5720541327,
    "price": [
      2.9779766139966974,
      2.992884249777954,
      2.8663206341396092,
      2.9204572946179446
    ],
    "volume": 15842362529
  },
  {
    "ts": 1617062400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19313,
      "authorized": 19313,
      "funded": 3181
    },
    "payments": 7,
    "payments_amount": 308810267622,
    "trades": 143,
    "traded_amount": 24987598448,
    "price": [
      2.9204572946179446,
      2.9603231171299598,
      2.8642447782234775,
      2.9289413238423316
    ],
    "volume": 44261779721
  },
  {
    "ts": 1617148800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19350,
      "authorized": 19350,
      "funded": 3179
    },
    "payments": 7,
    "payments_amount": 15534509922,
    "trades": 302,
    "traded_amount": 58505814221,
    "price": [
      2.9289413238423316,
      3.0775783904960448,
      2.912216342483702,
      2.9122170428390213
    ],
    "volume": 98912397098
  },
  {
    "ts": 1617235200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19389,
      "authorized": 19389,
      "funded": 3188
    },
    "payments": 21,
    "payments_amount": 316529911031,
    "trades": 269,
    "traded_amount": 47011672761,
    "price": [
      2.9122170428390213,
      2.926176826130148,
      2.7377648153370298,
      2.7740251999834844
    ],
    "volume": 98968014259
  },
  {
    "ts": 1617321600,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19421,
      "authorized": 19421,
      "funded": 3187
    },
    "payments": 43,
    "payments_amount": 144908136263,
    "trades": 563,
    "traded_amount": 41306048623,
    "price": [
      2.7740251999834844,
      2.7740251999834844,
      2.5085470085470085,
      2.660156125691307
    ],
    "volume": 68306906335
  },
  {
    "ts": 1617408000,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19446,
      "authorized": 19446,
      "funded": 19428
    },
    "payments": 58344,
    "payments_amount": 124063072382,
    "trades": 393,
    "traded_amount": 45996565757,
    "price": [
      2.660156125691307,
      2.88564365500824,
      2.570626163004067,
      2.88564365500824
    ],
    "volume": 78823884509
  },
  {
    "ts": 1617494400,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19477,
      "authorized": 19477,
      "funded": 19425
    },
    "payments": 5,
    "payments_amount": 15950500000,
    "trades": 218,
    "traded_amount": 24143478023,
    "price": [
      2.88564365500824,
      2.901662983232282,
      2.6942148760330578,
      2.7610115375764286
    ],
    "volume": 48398476405
  },
  {
    "ts": 1617580800,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19504,
      "authorized": 19504,
      "funded": 19490
    },
    "payments": 98901,
    "payments_amount": 22199212973,
    "trades": 762,
    "traded_amount": 76346033535,
    "price": [
      2.7610115375764286,
      2.7730951002320077,
      2.176689453727352,
      2.2117659888784433
    ],
    "volume": 129390333189
  },
  {
    "ts": 1617667200,
    "supply": 12788896298697,
    "trustlines": {
      "total": 19534,
      "authorized": 19534,
      "funded": 19490
    },
    "payments": 54,
    "payments_amount": 586618044043,
    "trades": 295,
    "traded_amount": 93775190688,
    "price": [
      2.2117659888784433,
      2.3670097024702375,
      2.2027515764454044,
      2.2731472826125936
    ],
    "volume": 111699900709
  },
  {
    "ts": 1617753600,
    "supply": 12788896298561,
    "trustlines": {
      "total": 19564,
      "authorized": 19564,
      "funded": 19483
    },
    "payments": 52,
    "payments_amount": 576704797336,
    "trades": 440,
    "traded_amount": 123409462601,
    "price": [
      2.2731472826125936,
      2.573529411764706,
      2.228451983523087,
      2.444042874622128
    ],
    "volume": 125105573156
  },
  {
    "ts": 1617840000,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19593,
      "authorized": 19593,
      "funded": 19481
    },
    "payments": 62,
    "payments_amount": 839804263549,
    "trades": 464,
    "traded_amount": 84266806468,
    "price": [
      2.444042874622128,
      2.515843365430796,
      2.3692133879395567,
      2.3722244721550063
    ],
    "volume": 125563155910
  },
  {
    "ts": 1617926400,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19623,
      "authorized": 19623,
      "funded": 19481
    },
    "payments": 47,
    "payments_amount": 581962341143,
    "trades": 266,
    "traded_amount": 47837878988,
    "price": [
      2.3722244721550063,
      2.479317659536857,
      2.358129411016238,
      2.4602322156178054
    ],
    "volume": 71510915110
  },
  {
    "ts": 1618012800,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19648,
      "authorized": 19648,
      "funded": 19482
    },
    "payments": 23,
    "payments_amount": 84623724420,
    "trades": 451,
    "traded_amount": 90643832398,
    "price": [
      2.4602322156178054,
      2.4623320911063065,
      2.049424418721734,
      2.124791034352869
    ],
    "volume": 110067922629
  },
  {
    "ts": 1618099200,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19679,
      "authorized": 19679,
      "funded": 19483
    },
    "payments": 6,
    "payments_amount": 17678431170,
    "trades": 1521,
    "traded_amount": 101707906022,
    "price": [
      2.124791034352869,
      2.124791034352869,
      1.8287542976342452,
      2.0308194585390185
    ],
    "volume": 87486217503
  },
  {
    "ts": 1618185600,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19698,
      "authorized": 19698,
      "funded": 19481
    },
    "payments": 88,
    "payments_amount": 1151793024065,
    "trades": 730,
    "traded_amount": 80152400023,
    "price": [
      2.0308194585390185,
      2.1187122261395115,
      1.9648519909655748,
      2.0237522874284584
    ],
    "volume": 104052252656
  },
  {
    "ts": 1618272000,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19718,
      "authorized": 19718,
      "funded": 19481
    },
    "payments": 28,
    "payments_amount": 146458497044,
    "trades": 951,
    "traded_amount": 106287838636,
    "price": [
      2.0237522874284584,
      2.0547486,
      1.762214983713355,
      1.7990040416358692
    ],
    "volume": 110399606016
  },
  {
    "ts": 1618358400,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19742,
      "authorized": 19742,
      "funded": 19475
    },
    "payments": 19,
    "payments_amount": 60812846629,
    "trades": 619,
    "traded_amount": 114070316556,
    "price": [
      1.7990040416358692,
      2.007561801152646,
      1.729918810433992,
      1.8875478419201897
    ],
    "volume": 107085572378
  },
  {
    "ts": 1618444800,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19768,
      "authorized": 19768,
      "funded": 19477
    },
    "payments": 4,
    "payments_amount": 16115999000,
    "trades": 326,
    "traded_amount": 35236427866,
    "price": [
      1.8875478419201897,
      1.9514453507130365,
      1.8487516565151512,
      1.8677007494955795
    ],
    "volume": 48700020127
  },
  {
    "ts": 1618531200,
    "supply": 12788896298552,
    "trustlines": {
      "total": 19803,
      "authorized": 19803,
      "funded": 19473
    },
    "payments": 24,
    "payments_amount": 137772073821,
    "trades": 564,
    "traded_amount": 84312803218,
    "price": [
      1.8677007494955795,
      2.0961422671046934,
      1.849864327730587,
      1.9601990986220719
    ],
    "volume": 107302317043
  },
  {
    "ts": 1618617600,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19838,
      "authorized": 19838,
      "funded": 19472
    },
    "payments": 10,
    "payments_amount": 46955394831,
    "trades": 264,
    "traded_amount": 36412845381,
    "price": [
      1.9601990986220719,
      2.013699602296803,
      1.8808323448926414,
      2.010974331799661
    ],
    "volume": 46472844214
  },
  {
    "ts": 1618704000,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19863,
      "authorized": 19863,
      "funded": 19474
    },
    "payments": 8,
    "payments_amount": 3439077885,
    "trades": 696,
    "traded_amount": 91637244687,
    "price": [
      2.010974331799661,
      2.5714285714285716,
      2.0104515868929074,
      2.191564315701367
    ],
    "volume": 106723679183
  },
  {
    "ts": 1618790400,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19894,
      "authorized": 19894,
      "funded": 19473
    },
    "payments": 19,
    "payments_amount": 337977966388,
    "trades": 427,
    "traded_amount": 17528891702,
    "price": [
      2.191564315701367,
      2.4357316321722426,
      2.1253109222368782,
      2.4021165695435096
    ],
    "volume": 21457475351
  },
  {
    "ts": 1618876800,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19937,
      "authorized": 19937,
      "funded": 19471
    },
    "payments": 22,
    "payments_amount": 307132236989,
    "trades": 521,
    "traded_amount": 71775744804,
    "price": [
      2.4021165695435096,
      2.5475386800665922,
      2.27345903489114,
      2.27345903489114
    ],
    "volume": 94856750961
  },
  {
    "ts": 1618963200,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19967,
      "authorized": 19967,
      "funded": 19476
    },
    "payments": 18,
    "payments_amount": 25084451895,
    "trades": 364,
    "traded_amount": 23936840595,
    "price": [
      2.27345903489114,
      2.472638379922442,
      2.234408247846195,
      2.43699694722919
    ],
    "volume": 33211859984
  },
  {
    "ts": 1619049600,
    "supply": 12788896291231,
    "trustlines": {
      "total": 19987,
      "authorized": 19987,
      "funded": 19473
    },
    "payments": 11,
    "payments_amount": 49672557843,
    "trades": 702,
    "traded_amount": 91841867208,
    "price": [
      2.43699694722919,
      2.685348420951036,
      2.330613984813381,
      2.678840473244001
    ],
    "volume": 46912723680
  },
  {
    "ts": 1619136000,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20001,
      "authorized": 20001,
      "funded": 19471
    },
    "payments": 16,
    "payments_amount": 26468172451,
    "trades": 672,
    "traded_amount": 88569748081,
    "price": [
      2.678840473244001,
      3.108711402564729,
      2.6229918728814945,
      2.6774433533817437
    ],
    "volume": 131211183084
  },
  {
    "ts": 1619222400,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20027,
      "authorized": 20027,
      "funded": 19472
    },
    "payments": 3,
    "payments_amount": 1474175991,
    "trades": 347,
    "traded_amount": 41230425306,
    "price": [
      2.6774433533817437,
      2.920404503388846,
      2.620985010706638,
      2.8198897819889783
    ],
    "volume": 60470888427
  },
  {
    "ts": 1619308800,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20057,
      "authorized": 20057,
      "funded": 19475
    },
    "payments": 5,
    "payments_amount": 5087213395,
    "trades": 385,
    "traded_amount": 43410502918,
    "price": [
      2.8198897819889783,
      2.990313041601072,
      2.4881223934173695,
      2.9130515607147554
    ],
    "volume": 60561031581
  },
  {
    "ts": 1619395200,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20075,
      "authorized": 20075,
      "funded": 19477
    },
    "payments": 6,
    "payments_amount": 13203299451,
    "trades": 374,
    "traded_amount": 49521133906,
    "price": [
      2.9130515607147554,
      2.9130515607147554,
      2.4912323342751854,
      2.4912323342751854
    ],
    "volume": 65680367182
  },
  {
    "ts": 1619481600,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20106,
      "authorized": 20106,
      "funded": 19480
    },
    "payments": 10,
    "payments_amount": 5211714560,
    "trades": 326,
    "traded_amount": 32062063382,
    "price": [
      2.4912323342751854,
      2.542508232638837,
      2.3245215936836097,
      2.3774530476545324
    ],
    "volume": 43938363219
  },
  {
    "ts": 1619568000,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20137,
      "authorized": 20137,
      "funded": 19478
    },
    "payments": 12,
    "payments_amount": 30661102896,
    "trades": 506,
    "traded_amount": 96255643772,
    "price": [
      2.3774530476545324,
      2.5262123896921374,
      2.321258020530196,
      2.4341307719441034
    ],
    "volume": 114943174214
  },
  {
    "ts": 1619654400,
    "supply": 12788896291231,
    "trustlines": {
      "total": 20166,
      "authorized": 20166,
      "funded": 19476
    },
    "payments": 163,
    "payments_amount": 33588962331,
    "trades": 407,
    "traded_amount": 118280504486,
    "price": [
      2.4341307719441034,
      2.5322365,
      2.3901073060613425,
      2.45219815
    ],
    "volume": 81028969319
  },
  {
    "ts": 1619740800,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20196,
      "authorized": 20196,
      "funded": 19475
    },
    "payments": 59,
    "payments_amount": 11100036718,
    "trades": 298,
    "traded_amount": 66055039050,
    "price": [
      2.45219815,
      2.4919892943918893,
      2.2344087273440336,
      2.2715755603495555
    ],
    "volume": 84640056877
  },
  {
    "ts": 1619827200,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20227,
      "authorized": 20227,
      "funded": 19479
    },
    "payments": 15,
    "payments_amount": 15303479792,
    "trades": 290,
    "traded_amount": 45689545226,
    "price": [
      2.2715755603495555,
      2.336921087059854,
      2.2344086253971427,
      2.237164727623435
    ],
    "volume": 37380418231
  },
  {
    "ts": 1619913600,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20247,
      "authorized": 20247,
      "funded": 19478
    },
    "payments": 9,
    "payments_amount": 29201564620,
    "trades": 379,
    "traded_amount": 61230971920,
    "price": [
      2.237164727623435,
      2.3471395491000875,
      2.1563216179628086,
      2.1597418209108104
    ],
    "volume": 68914979728
  },
  {
    "ts": 1620000000,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20270,
      "authorized": 20270,
      "funded": 19477
    },
    "payments": 20,
    "payments_amount": 71912340025,
    "trades": 398,
    "traded_amount": 70065736832,
    "price": [
      2.1597418209108104,
      2.226701222288376,
      2.1141027561922625,
      2.1872056019816117
    ],
    "volume": 75440346316
  },
  {
    "ts": 1620086400,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20298,
      "authorized": 20298,
      "funded": 19478
    },
    "payments": 15,
    "payments_amount": 31737391781,
    "trades": 355,
    "traded_amount": 37705979398,
    "price": [
      2.1872056019816117,
      2.4306633100294563,
      2.185375806196352,
      2.382836508771319
    ],
    "volume": 64593785562
  },
  {
    "ts": 1620172800,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20323,
      "authorized": 20323,
      "funded": 19481
    },
    "payments": 14,
    "payments_amount": 33020691284,
    "trades": 377,
    "traded_amount": 85424167308,
    "price": [
      2.382836508771319,
      2.3921248873227894,
      2.002607091513545,
      2.002607091513545
    ],
    "volume": 75451732209
  },
  {
    "ts": 1620259200,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20355,
      "authorized": 20355,
      "funded": 19486
    },
    "payments": 19,
    "payments_amount": 94624295971,
    "trades": 800,
    "traded_amount": 183163298470,
    "price": [
      2.002607091513545,
      2.0094196002548146,
      1.7685720897940291,
      1.8304421056914824
    ],
    "volume": 149730351635
  },
  {
    "ts": 1620345600,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20386,
      "authorized": 20386,
      "funded": 19488
    },
    "payments": 17,
    "payments_amount": 19215535240,
    "trades": 527,
    "traded_amount": 71920299980,
    "price": [
      1.8304421056914824,
      2,
      1.768123037037682,
      1.9308201132350469
    ],
    "volume": 69226222580
  },
  {
    "ts": 1620432000,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20417,
      "authorized": 20417,
      "funded": 19489
    },
    "payments": 7,
    "payments_amount": 27108939855,
    "trades": 516,
    "traded_amount": 118145680976,
    "price": [
      1.9308201132350469,
      2.0112094874662754,
      1.8995309734439771,
      1.9657977152429276
    ],
    "volume": 128859913174
  },
  {
    "ts": 1620518400,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20435,
      "authorized": 20435,
      "funded": 19485
    },
    "payments": 12,
    "payments_amount": 51148164771,
    "trades": 554,
    "traded_amount": 90251799445,
    "price": [
      1.9657977152429276,
      2.10389270405763,
      1.927035233457462,
      1.9276901160193647
    ],
    "volume": 112714332185
  },
  {
    "ts": 1620604800,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20452,
      "authorized": 20452,
      "funded": 19487
    },
    "payments": 29,
    "payments_amount": 97183790001,
    "trades": 874,
    "traded_amount": 205833228522,
    "price": [
      1.9276901160193647,
      1.9500000023041952,
      1.5274725274725274,
      1.8376481714095956
    ],
    "volume": 145364998052
  },
  {
    "ts": 1620691200,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20481,
      "authorized": 20481,
      "funded": 19486
    },
    "payments": 25,
    "payments_amount": 122087385733,
    "trades": 557,
    "traded_amount": 288767621770,
    "price": [
      1.8376481714095956,
      1.9452017422362309,
      1.6496634368500893,
      1.6613111312616617
    ],
    "volume": 299534001438
  },
  {
    "ts": 1620777600,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20514,
      "authorized": 20514,
      "funded": 19484
    },
    "payments": 23,
    "payments_amount": 60847734202,
    "trades": 660,
    "traded_amount": 141703659985,
    "price": [
      1.6613111312616617,
      2.013477381838298,
      1.656244403534256,
      2.013340448239061
    ],
    "volume": 165611559882
  },
  {
    "ts": 1620864000,
    "supply": 12788896291223,
    "trustlines": {
      "total": 20541,
      "authorized": 20541,
      "funded": 19483
    },
    "payments": 31,
    "payments_amount": 79093486088,
    "trades": 864,
    "traded_amount": 173149088384,
    "price": [
      2.013340448239061,
      2.123764425791148,
      1.8546178560980078,
      1.8674173728392285
    ],
    "volume": 166360590942
  },
  {
    "ts": 1620950400,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20569,
      "authorized": 20569,
      "funded": 19484
    },
    "payments": 26,
    "payments_amount": 18840071563,
    "trades": 670,
    "traded_amount": 82898854434,
    "price": [
      1.8674173728392285,
      1.9017475424761978,
      1.725812877260759,
      1.816129585524292
    ],
    "volume": 81422345566
  },
  {
    "ts": 1621036800,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20602,
      "authorized": 20602,
      "funded": 19492
    },
    "payments": 26,
    "payments_amount": 98493727752,
    "trades": 737,
    "traded_amount": 191936359009,
    "price": [
      1.816129585524292,
      1.862464052135505,
      1.630098955993521,
      1.7945867458179885
    ],
    "volume": 160003259178
  },
  {
    "ts": 1621123200,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20632,
      "authorized": 20632,
      "funded": 19492
    },
    "payments": 13,
    "payments_amount": 26159972041,
    "trades": 648,
    "traded_amount": 152649623466,
    "price": [
      1.7945867458179885,
      1.828830806103548,
      1.5462157404883972,
      1.7087929876689076
    ],
    "volume": 106779529532
  },
  {
    "ts": 1621209600,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20652,
      "authorized": 20652,
      "funded": 19487
    },
    "payments": 27,
    "payments_amount": 106106694142,
    "trades": 770,
    "traded_amount": 133701077845,
    "price": [
      1.7087929876689076,
      1.939895733893187,
      1.7087929876689076,
      1.8823588389751427
    ],
    "volume": 122182319111
  },
  {
    "ts": 1621296000,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20674,
      "authorized": 20674,
      "funded": 19489
    },
    "payments": 38,
    "payments_amount": 84140116596,
    "trades": 520,
    "traded_amount": 52545137937,
    "price": [
      1.8823588389751427,
      1.9218535900068943,
      1.7645281065170233,
      1.8845635351101806
    ],
    "volume": 58499759797
  },
  {
    "ts": 1621382400,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20692,
      "authorized": 20692,
      "funded": 19482
    },
    "payments": 20,
    "payments_amount": 316792531293,
    "trades": 1735,
    "traded_amount": 690582378679,
    "price": [
      1.8845635351101806,
      4,
      1.8742551262472649,
      2.6623015259170546
    ],
    "volume": 1037407843786
  },
  {
    "ts": 1621468800,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20702,
      "authorized": 20702,
      "funded": 19482
    },
    "payments": 16,
    "payments_amount": 54027051874,
    "trades": 773,
    "traded_amount": 105991313807,
    "price": [
      2.6623015259170546,
      2.8303463031249936,
      2.292400088483243,
      2.4687859297616894
    ],
    "volume": 146090668358
  },
  {
    "ts": 1621555200,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20719,
      "authorized": 20719,
      "funded": 19480
    },
    "payments": 12,
    "payments_amount": 20306535724,
    "trades": 755,
    "traded_amount": 49554011729,
    "price": [
      2.4687859297616894,
      3.192963752665245,
      2.4026223300349057,
      2.9380432080927776
    ],
    "volume": 77938459602
  },
  {
    "ts": 1621641600,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20726,
      "authorized": 20726,
      "funded": 19481
    },
    "payments": 2,
    "payments_amount": 3469748743,
    "trades": 458,
    "traded_amount": 37900948010,
    "price": [
      2.9380432080927776,
      3.254217476548771,
      2.898255484443426,
      3.114288240244226
    ],
    "volume": 66689568236
  },
  {
    "ts": 1621728000,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20737,
      "authorized": 20737,
      "funded": 19476
    },
    "payments": 12,
    "payments_amount": 20087529693,
    "trades": 1166,
    "traded_amount": 214696612537,
    "price": [
      3.114288240244226,
      4.372051300840151,
      3.0419510867654966,
      3.3234147173734527
    ],
    "volume": 389133106830
  },
  {
    "ts": 1621814400,
    "supply": 12788896291215,
    "trustlines": {
      "total": 20748,
      "authorized": 20748,
      "funded": 19479
    },
    "payments": 10,
    "payments_amount": 36484597630,
    "trades": 656,
    "traded_amount": 78279964205,
    "price": [
      3.3234147173734527,
      3.3478665811273185,
      2.7388803686260346,
      2.7804343194420444
    ],
    "volume": 121089120828
  },
  {
    "ts": 1621900800,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20751,
      "authorized": 20751,
      "funded": 19477
    },
    "payments": 16,
    "payments_amount": 62309452505,
    "trades": 717,
    "traded_amount": 86303015721,
    "price": [
      2.7804343194420444,
      3.032849678745682,
      2.6652547360772285,
      2.860466709437491
    ],
    "volume": 134286953570
  },
  {
    "ts": 1621987200,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20765,
      "authorized": 20765,
      "funded": 19484
    },
    "payments": 17,
    "payments_amount": 12461307061,
    "trades": 733,
    "traded_amount": 57125164335,
    "price": [
      2.860466709437491,
      2.883273322127236,
      2.6064904581063693,
      2.7421916386894565
    ],
    "volume": 72989610005
  },
  {
    "ts": 1622073600,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20779,
      "authorized": 20779,
      "funded": 19483
    },
    "payments": 19,
    "payments_amount": 28073062635,
    "trades": 425,
    "traded_amount": 37754045003,
    "price": [
      2.7421916386894565,
      2.960884795236859,
      2.7371638884157674,
      2.8909522440919333
    ],
    "volume": 46145278650
  },
  {
    "ts": 1622160000,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20791,
      "authorized": 20791,
      "funded": 19482
    },
    "payments": 19,
    "payments_amount": 66114575801,
    "trades": 853,
    "traded_amount": 110697633513,
    "price": [
      2.8909522440919333,
      3.3223329610157646,
      2.8687585128178164,
      3.0512703258866725
    ],
    "volume": 171541001297
  },
  {
    "ts": 1622246400,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20798,
      "authorized": 20798,
      "funded": 19482
    },
    "payments": 13,
    "payments_amount": 31820645717,
    "trades": 811,
    "traded_amount": 82342712429,
    "price": [
      3.0512703258866725,
      3.559795984332726,
      3.006225071099645,
      3.3176507018274304
    ],
    "volume": 235758830579
  },
  {
    "ts": 1622332800,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20806,
      "authorized": 20806,
      "funded": 19484
    },
    "payments": 9,
    "payments_amount": 8238692848,
    "trades": 792,
    "traded_amount": 58155340938,
    "price": [
      3.3176507018274304,
      3.4806531186097236,
      3.068050211742364,
      3.2600613064848023
    ],
    "volume": 92795879528
  },
  {
    "ts": 1622419200,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20822,
      "authorized": 20822,
      "funded": 19485
    },
    "payments": 14,
    "payments_amount": 12673193089,
    "trades": 641,
    "traded_amount": 33796071426,
    "price": [
      3.2600613064848023,
      3.3271448552503102,
      2.9874215346070674,
      3.0482787779078264
    ],
    "volume": 46464661938
  },
  {
    "ts": 1622505600,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20829,
      "authorized": 20829,
      "funded": 19491
    },
    "payments": 20,
    "payments_amount": 21042421268,
    "trades": 580,
    "traded_amount": 35415171818,
    "price": [
      3.0482787779078264,
      3.145685998861968,
      2.735294117647059,
      2.9082439085973992
    ],
    "volume": 58260502178
  },
  {
    "ts": 1622592000,
    "supply": 12788896291214,
    "trustlines": {
      "total": 20841,
      "authorized": 20841,
      "funded": 19493
    },
    "payments": 23,
    "payments_amount": 78586238610,
    "trades": 306,
    "traded_amount": 13287398793,
    "price": [
      2.9082439085973992,
      3.0013684589849605,
      2.8252266015116483,
      2.9193504603293374
    ],
    "volume": 13726791417
  },
  {
    "ts": 1622678400,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20847,
      "authorized": 20847,
      "funded": 19492
    },
    "payments": 24,
    "payments_amount": 55340103865,
    "trades": 267,
    "traded_amount": 9422168761,
    "price": [
      2.9193504603293374,
      2.9411865794739533,
      2.814253707457591,
      2.8424781118115487
    ],
    "volume": 16103905701
  },
  {
    "ts": 1622764800,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20858,
      "authorized": 20858,
      "funded": 19493
    },
    "payments": 18,
    "payments_amount": 154689931748,
    "trades": 655,
    "traded_amount": 169513583594,
    "price": [
      2.8424781118115487,
      3.261398769202814,
      2.8424781118115487,
      3.1517156734736513
    ],
    "volume": 440719782470
  },
  {
    "ts": 1622851200,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20867,
      "authorized": 20867,
      "funded": 19491
    },
    "payments": 9,
    "payments_amount": 226983928115,
    "trades": 377,
    "traded_amount": 269376161023,
    "price": [
      3.1517156734736513,
      3.334458747366636,
      2.9751378415267213,
      3.2697863388429753
    ],
    "volume": 759847423879
  },
  {
    "ts": 1622937600,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20873,
      "authorized": 20873,
      "funded": 19491
    },
    "payments": 9,
    "payments_amount": 27130289295,
    "trades": 142,
    "traded_amount": 31932859840,
    "price": [
      3.2697863388429753,
      3.2697863388429753,
      3.1651281073890494,
      3.2265954491000746
    ],
    "volume": 53748947106
  },
  {
    "ts": 1623024000,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20890,
      "authorized": 20890,
      "funded": 19490
    },
    "payments": 19,
    "payments_amount": 25741965977,
    "trades": 207,
    "traded_amount": 42812505134,
    "price": [
      3.2265954491000746,
      3.5365937444543887,
      3.1231846489228134,
      3.5365937444543887
    ],
    "volume": 63153820146
  },
  {
    "ts": 1623110400,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20903,
      "authorized": 20903,
      "funded": 19492
    },
    "payments": 33,
    "payments_amount": 98737305001,
    "trades": 311,
    "traded_amount": 43965448461,
    "price": [
      3.5365937444543887,
      3.84065315474638,
      3.4283921962235744,
      3.452473631910316
    ],
    "volume": 69593161953
  },
  {
    "ts": 1623196800,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20908,
      "authorized": 20908,
      "funded": 19498
    },
    "payments": 17,
    "payments_amount": 22051243256,
    "trades": 148,
    "traded_amount": 15386868977,
    "price": [
      3.452473631910316,
      3.7372695247756145,
      3.3856747385314847,
      3.3856747385314847
    ],
    "volume": 28683663313
  },
  {
    "ts": 1623283200,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20910,
      "authorized": 20910,
      "funded": 19491
    },
    "payments": 12,
    "payments_amount": 6034868519,
    "trades": 95,
    "traded_amount": 7121468994,
    "price": [
      3.3856747385314847,
      3.66065310060013,
      3.3566010584287644,
      3.6071735755807985
    ],
    "volume": 15328264757
  },
  {
    "ts": 1623369600,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20915,
      "authorized": 20915,
      "funded": 19490
    },
    "payments": 12,
    "payments_amount": 17241829486,
    "trades": 125,
    "traded_amount": 14919103751,
    "price": [
      3.6071735755807985,
      3.846939510941435,
      3.5530243519245874,
      3.809732817624737
    ],
    "volume": 30442170349
  },
  {
    "ts": 1623456000,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20922,
      "authorized": 20922,
      "funded": 19493
    },
    "payments": 7,
    "payments_amount": 12403485281,
    "trades": 245,
    "traded_amount": 147399460383,
    "price": [
      3.809732817624737,
      4.020653098221678,
      3.5526009613502927,
      3.7140936450233832
    ],
    "volume": 488510885979
  },
  {
    "ts": 1623542400,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20931,
      "authorized": 20931,
      "funded": 19495
    },
    "payments": 9,
    "payments_amount": 14226705107,
    "trades": 976,
    "traded_amount": 42345328360,
    "price": [
      3.7140936450233832,
      3.8549814109467615,
      3.4915713467689,
      3.517820999564701
    ],
    "volume": 82396957297
  },
  {
    "ts": 1623628800,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20941,
      "authorized": 20941,
      "funded": 19496
    },
    "payments": 10,
    "payments_amount": 14198898561,
    "trades": 604,
    "traded_amount": 29100866110,
    "price": [
      3.517820999564701,
      3.6868125611828275,
      3.495974466415348,
      3.5394461944143605
    ],
    "volume": 35139541823
  },
  {
    "ts": 1623715200,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20951,
      "authorized": 20951,
      "funded": 19496
    },
    "payments": 7,
    "payments_amount": 2385213632,
    "trades": 564,
    "traded_amount": 28213732402,
    "price": [
      3.5394461944143605,
      3.6666666666666665,
      3.455568488832973,
      3.628943973878999
    ],
    "volume": 49834336336
  },
  {
    "ts": 1623801600,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20953,
      "authorized": 20953,
      "funded": 19494
    },
    "payments": 14,
    "payments_amount": 32578982367,
    "trades": 646,
    "traded_amount": 38150129877,
    "price": [
      3.628943973878999,
      3.8384520979725494,
      3.549999514006862,
      3.8014547534218184
    ],
    "volume": 71421942727
  },
  {
    "ts": 1623888000,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20959,
      "authorized": 20959,
      "funded": 19494
    },
    "payments": 13,
    "payments_amount": 16657043638,
    "trades": 772,
    "traded_amount": 25367524592,
    "price": [
      3.8014547534218184,
      3.8357361331947617,
      3.6200480606485868,
      3.743292336567655
    ],
    "volume": 42480094415
  },
  {
    "ts": 1623974400,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20973,
      "authorized": 20973,
      "funded": 19494
    },
    "payments": 20,
    "payments_amount": 25548264245,
    "trades": 623,
    "traded_amount": 22854414979,
    "price": [
      3.743292336567655,
      4.138680056429034,
      3.743292336567655,
      3.9800471975176217
    ],
    "volume": 37346413272
  },
  {
    "ts": 1624060800,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20981,
      "authorized": 20981,
      "funded": 19495
    },
    "payments": 9,
    "payments_amount": 29846733700,
    "trades": 1418,
    "traded_amount": 131326427694,
    "price": [
      3.9800471975176217,
      4.110653378035301,
      3.8876029408173687,
      4.0869421814703655
    ],
    "volume": 260837203991
  },
  {
    "ts": 1624147200,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20982,
      "authorized": 20982,
      "funded": 19496
    },
    "payments": 5,
    "payments_amount": 4684931820,
    "trades": 782,
    "traded_amount": 18918251790,
    "price": [
      4.0869421814703655,
      4.380653561970741,
      3.965539749399724,
      3.998261148911714
    ],
    "volume": 44334118504
  },
  {
    "ts": 1624233600,
    "supply": 12788896291213,
    "trustlines": {
      "total": 20990,
      "authorized": 20990,
      "funded": 19495
    },
    "payments": 20,
    "payments_amount": 53717683601,
    "trades": 1017,
    "traded_amount": 59291852499,
    "price": [
      3.998261148911714,
      4.981208417913118,
      3.9566017026569145,
      4.8920663320741085
    ],
    "volume": 131195256912
  },
  {
    "ts": 1624320000,
    "supply": 12788896291212,
    "trustlines": {
      "total": 20995,
      "authorized": 20995,
      "funded": 19495
    },
    "payments": 17,
    "payments_amount": 48000501749,
    "trades": 1962,
    "traded_amount": 242982322675,
    "price": [
      4.8920663320741085,
      5.872339486001943,
      4.608278353189126,
      5.127224040592778
    ],
    "volume": 277087362666
  },
  {
    "ts": 1624406400,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21001,
      "authorized": 21001,
      "funded": 19496
    },
    "payments": 28,
    "payments_amount": 206508085486,
    "trades": 1006,
    "traded_amount": 178832277989,
    "price": [
      5.127224040592778,
      5.2310786113928485,
      4.462016734600046,
      4.655813612035982
    ],
    "volume": 602231274776
  },
  {
    "ts": 1624492800,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21002,
      "authorized": 21002,
      "funded": 19497
    },
    "payments": 17,
    "payments_amount": 22389516503,
    "trades": 884,
    "traded_amount": 47193421011,
    "price": [
      4.655813612035982,
      4.887422823581431,
      4.379139157353927,
      4.458025025575109
    ],
    "volume": 63127032906
  },
  {
    "ts": 1624579200,
    "supply": 14788896291212,
    "trustlines": {
      "total": 21016,
      "authorized": 21016,
      "funded": 19497
    },
    "payments": 27,
    "payments_amount": 6040313193859,
    "trades": 1042,
    "traded_amount": 22301402237,
    "price": [
      4.458025025575109,
      4.949173969182317,
      4.372016492868292,
      4.90404043579309
    ],
    "volume": 58435810937
  },
  {
    "ts": 1624665600,
    "supply": 14788896291212,
    "trustlines": {
      "total": 21021,
      "authorized": 21021,
      "funded": 19499
    },
    "payments": 5,
    "payments_amount": 93114164148,
    "trades": 641,
    "traded_amount": 110899286459,
    "price": [
      4.90404043579309,
      5.198775552798488,
      4.809201602310416,
      4.817340213087694
    ],
    "volume": 521610030859
  },
  {
    "ts": 1624752000,
    "supply": 14788896291212,
    "trustlines": {
      "total": 21024,
      "authorized": 21024,
      "funded": 19500
    },
    "payments": 5,
    "payments_amount": 1915523998,
    "trades": 794,
    "traded_amount": 112473864507,
    "price": [
      4.817340213087694,
      4.972303805923079,
      4.628659155074466,
      4.665343699602625
    ],
    "volume": 270644949578
  },
  {
    "ts": 1624838400,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21027,
      "authorized": 21027,
      "funded": 19500
    },
    "payments": 26,
    "payments_amount": 2387607422424,
    "trades": 722,
    "traded_amount": 31613957342,
    "price": [
      4.665343699602625,
      4.665343699602625,
      4.460829837037414,
      4.514482291412063
    ],
    "volume": 60825852612
  },
  {
    "ts": 1624924800,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21032,
      "authorized": 21032,
      "funded": 19500
    },
    "payments": 23,
    "payments_amount": 43336743005,
    "trades": 879,
    "traded_amount": 35723962566,
    "price": [
      4.514482291412063,
      4.53172181594954,
      4.052641741962678,
      4.235860551870151
    ],
    "volume": 64415857705
  },
  {
    "ts": 1625011200,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21037,
      "authorized": 21037,
      "funded": 19502
    },
    "payments": 57,
    "payments_amount": 12155365907401,
    "trades": 691,
    "traded_amount": 51467395504,
    "price": [
      4.235860551870151,
      4.43909437155474,
      4.18774219078857,
      4.192017896270931
    ],
    "volume": 54771296039
  },
  {
    "ts": 1625097600,
    "supply": 12788896291212,
    "trustlines": {
      "total": 21043,
      "authorized": 21043,
      "funded": 19502
    },
    "payments": 76,
    "payments_amount": 309987237547,
    "trades": 612,
    "traded_amount": 19100132742,
    "price": [
      4.192017896270931,
      4.546468283047122,
      4.1814724622704835,
      4.401681909276387
    ],
    "volume": 46170313349
  },
  {
    "ts": 1625184000,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21040,
      "authorized": 21040,
      "funded": 19500
    },
    "payments": 71,
    "payments_amount": 126122128533,
    "trades": 479,
    "traded_amount": 20893318264,
    "price": [
      4.401681909276387,
      4.680599780458883,
      4.352320893977992,
      4.51104809004236
    ],
    "volume": 54031843540
  },
  {
    "ts": 1625270400,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21041,
      "authorized": 21041,
      "funded": 19501
    },
    "payments": 29,
    "payments_amount": 65565921902,
    "trades": 206,
    "traded_amount": 9305692249,
    "price": [
      4.51104809004236,
      4.562450773029372,
      4.407261814325099,
      4.502516906950985
    ],
    "volume": 16850465896
  },
  {
    "ts": 1625356800,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21045,
      "authorized": 21045,
      "funded": 19504
    },
    "payments": 17,
    "payments_amount": 17140556406,
    "trades": 53,
    "traded_amount": 7572863271,
    "price": [
      4.502516906950985,
      4.569541457802613,
      4.37201664547656,
      4.453025665175418
    ],
    "volume": 13570653621
  },
  {
    "ts": 1625443200,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21044,
      "authorized": 21044,
      "funded": 19502
    },
    "payments": 91,
    "payments_amount": 301992169108,
    "trades": 202,
    "traded_amount": 48751513377,
    "price": [
      4.453025665175418,
      4.75,
      4.453025665175418,
      4.66798630465925
    ],
    "volume": 84811120696
  },
  {
    "ts": 1625529600,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21048,
      "authorized": 21048,
      "funded": 19504
    },
    "payments": 54,
    "payments_amount": 143556694562,
    "trades": 275,
    "traded_amount": 43441715491,
    "price": [
      4.66798630465925,
      4.66798630465925,
      4.439368124108115,
      4.5578512396694215
    ],
    "volume": 48559629470
  },
  {
    "ts": 1625616000,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21053,
      "authorized": 21053,
      "funded": 19507
    },
    "payments": 71,
    "payments_amount": 154489619749,
    "trades": 286,
    "traded_amount": 41873948507,
    "price": [
      4.5578512396694215,
      4.668082447247733,
      4.4913577742843,
      4.5452209493736415
    ],
    "volume": 58391617051
  },
  {
    "ts": 1625702400,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21056,
      "authorized": 21056,
      "funded": 19506
    },
    "payments": 49,
    "payments_amount": 111782965303,
    "trades": 1463,
    "traded_amount": 38488366204,
    "price": [
      4.5452209493736415,
      4.934843043049738,
      4.5452209493736415,
      4.934843043049738
    ],
    "volume": 63726300863
  },
  {
    "ts": 1625788800,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21058,
      "authorized": 21058,
      "funded": 19507
    },
    "payments": 43,
    "payments_amount": 12035885748750,
    "trades": 2660,
    "traded_amount": 38707718115,
    "price": [
      4.934843043049738,
      5.0838658554681,
      4.769326579152269,
      4.861702127659575
    ],
    "volume": 74416561070
  },
  {
    "ts": 1625875200,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21059,
      "authorized": 21059,
      "funded": 19507
    },
    "payments": 21,
    "payments_amount": 25741628822,
    "trades": 1919,
    "traded_amount": 14799259367,
    "price": [
      4.861702127659575,
      4.940338498835971,
      4.717989749723891,
      4.85449546041605
    ],
    "volume": 28649792570
  },
  {
    "ts": 1625961600,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21061,
      "authorized": 21061,
      "funded": 19508
    },
    "payments": 25,
    "payments_amount": 49850765537,
    "trades": 120,
    "traded_amount": 16094380286,
    "price": [
      4.85449546041605,
      4.937340468205229,
      4.814198807492228,
      4.882352941176471
    ],
    "volume": 28114792763
  },
  {
    "ts": 1626048000,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21063,
      "authorized": 21063,
      "funded": 19507
    },
    "payments": 89,
    "payments_amount": 1448449121680,
    "trades": 620,
    "traded_amount": 51996084893,
    "price": [
      4.882352941176471,
      4.928775740408085,
      4.642016711277498,
      4.928274720263462
    ],
    "volume": 89039335945
  },
  {
    "ts": 1626134400,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21069,
      "authorized": 21069,
      "funded": 19509
    },
    "payments": 59,
    "payments_amount": 109634929343,
    "trades": 142,
    "traded_amount": 13809344810,
    "price": [
      4.928274720263462,
      5.159954039671021,
      4.8524276019441235,
      5.071920579419672
    ],
    "volume": 30182076792
  },
  {
    "ts": 1626220800,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21072,
      "authorized": 21072,
      "funded": 19509
    },
    "payments": 55,
    "payments_amount": 80860975140,
    "trades": 275,
    "traded_amount": 14993292841,
    "price": [
      5.071920579419672,
      5.3269310454874415,
      4.8592200659263005,
      4.931267922875186
    ],
    "volume": 32495830181
  },
  {
    "ts": 1626307200,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21078,
      "authorized": 21078,
      "funded": 19509
    },
    "payments": 56,
    "payments_amount": 168101013167,
    "trades": 110,
    "traded_amount": 13854546142,
    "price": [
      4.931267922875186,
      5.198775399112691,
      4.901527084681608,
      4.91201597375811
    ],
    "volume": 30260709164
  },
  {
    "ts": 1626393600,
    "supply": 12788896291204,
    "trustlines": {
      "total": 21080,
      "authorized": 21080,
      "funded": 19508
    },
    "payments": 78,
    "payments_amount": 499173549240,
    "trades": 222,
    "traded_amount": 28136634629,
    "price": [
      4.91201597375811,
      5.018775396264424,
      4.635407096434437,
      4.966467597925869
    ],
    "volume": 67707534017
  },
  {
    "ts": 1626480000,
    "supply": 12788896291196,
    "trustlines": {
      "total": 21082,
      "authorized": 21082,
      "funded": 19507
    },
    "payments": 58,
    "payments_amount": 37084045416,
    "trades": 44,
    "traded_amount": 8361469314,
    "price": [
      4.966467597925869,
      5.156668186075016,
      4.966467597925869,
      5.075482357756636
    ],
    "volume": 15892299130
  },
  {
    "ts": 1626566400,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21086,
      "authorized": 21086,
      "funded": 19505
    },
    "payments": 67,
    "payments_amount": 59601659631,
    "trades": 114,
    "traded_amount": 11146500306,
    "price": [
      5.075482357756636,
      5.191729466060324,
      4.932474554020357,
      5.124403539835588
    ],
    "volume": 22738070456
  },
  {
    "ts": 1626652800,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21090,
      "authorized": 21090,
      "funded": 19504
    },
    "payments": 167,
    "payments_amount": 135914074966,
    "trades": 86,
    "traded_amount": 11369481035,
    "price": [
      5.124403539835588,
      5.558775397414907,
      5.117553034740272,
      5.558775397414907
    ],
    "volume": 34234822591
  },
  {
    "ts": 1626739200,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21092,
      "authorized": 21092,
      "funded": 19504
    },
    "payments": 68,
    "payments_amount": 63689632084,
    "trades": 143,
    "traded_amount": 12444169109,
    "price": [
      5.558775397414907,
      5.91770332390846,
      5.507033620873739,
      5.507033620873739
    ],
    "volume": 40813052743
  },
  {
    "ts": 1626825600,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21092,
      "authorized": 21092,
      "funded": 19505
    },
    "payments": 30,
    "payments_amount": 21333475741,
    "trades": 115,
    "traded_amount": 18934688708,
    "price": [
      5.507033620873739,
      5.648775399851763,
      5.182015710881309,
      5.239909302040979
    ],
    "volume": 65655285623
  },
  {
    "ts": 1626912000,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21099,
      "authorized": 21099,
      "funded": 19507
    },
    "payments": 44,
    "payments_amount": 4034983871007,
    "trades": 266,
    "traded_amount": 21680818695,
    "price": [
      5.239909302040979,
      5.239909302040979,
      4.407373213320371,
      4.4681266626013025
    ],
    "volume": 53231954989
  },
  {
    "ts": 1626998400,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21099,
      "authorized": 21099,
      "funded": 19506
    },
    "payments": 66,
    "payments_amount": 130840968720,
    "trades": 237,
    "traded_amount": 137918917208,
    "price": [
      4.4681266626013025,
      4.740667683086697,
      4.279036640052899,
      4.370666883032465
    ],
    "volume": 318119385536
  },
  {
    "ts": 1627084800,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21102,
      "authorized": 21102,
      "funded": 19506
    },
    "payments": 10,
    "payments_amount": 8088951188,
    "trades": 93,
    "traded_amount": 4930267042,
    "price": [
      4.370666883032465,
      4.507872723780056,
      4.343302348721096,
      4.471012192450249
    ],
    "volume": 9496083475
  },
  {
    "ts": 1627171200,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21103,
      "authorized": 21103,
      "funded": 19507
    },
    "payments": 10,
    "payments_amount": 9717889505,
    "trades": 69,
    "traded_amount": 7342043761,
    "price": [
      4.471012192450249,
      4.643002410709571,
      4.471012192450249,
      4.510042255021127
    ],
    "volume": 17921537270
  },
  {
    "ts": 1627257600,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21112,
      "authorized": 21112,
      "funded": 19507
    },
    "payments": 59,
    "payments_amount": 73810268269,
    "trades": 113,
    "traded_amount": 15630968325,
    "price": [
      4.510042255021127,
      4.578963074355425,
      4.182516806525972,
      4.5199356300969695
    ],
    "volume": 51300229984
  },
  {
    "ts": 1627344000,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21116,
      "authorized": 21116,
      "funded": 19505
    },
    "payments": 108,
    "payments_amount": 108716642326,
    "trades": 61,
    "traded_amount": 8440043033,
    "price": [
      4.5199356300969695,
      4.6587753958674245,
      4.399565621990272,
      4.538052204857846
    ],
    "volume": 29378980136
  },
  {
    "ts": 1627430400,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21123,
      "authorized": 21123,
      "funded": 19506
    },
    "payments": 114,
    "payments_amount": 8132686624011,
    "trades": 82,
    "traded_amount": 51388505917,
    "price": [
      4.538052204857846,
      4.53943441966526,
      4.2210586020957095,
      4.3182584599156115
    ],
    "volume": 166645670521
  },
  {
    "ts": 1627516800,
    "supply": 12788896291188,
    "trustlines": {
      "total": 21126,
      "authorized": 21126,
      "funded": 19507
    },
    "payments": 99,
    "payments_amount": 118355136825,
    "trades": 65,
    "traded_amount": 8906388666,
    "price": [
      4.3182584599156115,
      4.50603594443931,
      4.3182584599156115,
      4.373367503844182
    ],
    "volume": 6315134412
  },
  {
    "ts": 1627603200,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21127,
      "authorized": 21127,
      "funded": 19507
    },
    "payments": 105,
    "payments_amount": 415530560255,
    "trades": 160,
    "traded_amount": 31506303515,
    "price": [
      4.373367503844182,
      4.484226195446588,
      4.23733500625,
      4.267419604088554
    ],
    "volume": 62283306734
  },
  {
    "ts": 1627689600,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21129,
      "authorized": 21129,
      "funded": 19508
    },
    "payments": 73,
    "payments_amount": 99843447947,
    "trades": 139,
    "traded_amount": 14411343644,
    "price": [
      4.267419604088554,
      4.322183090220988,
      4.139411884,
      4.139411884
    ],
    "volume": 28148346317
  },
  {
    "ts": 1627776000,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21130,
      "authorized": 21130,
      "funded": 19508
    },
    "payments": 37,
    "payments_amount": 38040617387,
    "trades": 117,
    "traded_amount": 14428510233,
    "price": [
      4.139411884,
      4.298775396246178,
      3.956780027570457,
      4.298775396246178
    ],
    "volume": 34712200146
  },
  {
    "ts": 1627862400,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21131,
      "authorized": 21131,
      "funded": 19506
    },
    "payments": 134,
    "payments_amount": 144148257913,
    "trades": 340,
    "traded_amount": 35700835274,
    "price": [
      4.298775396246178,
      4.388775406861857,
      4.085714285714285,
      4.301589691774747
    ],
    "volume": 36160191038
  },
  {
    "ts": 1627948800,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21135,
      "authorized": 21135,
      "funded": 19505
    },
    "payments": 61,
    "payments_amount": 63270752443,
    "trades": 176,
    "traded_amount": 23935813426,
    "price": [
      4.301589691774747,
      4.475156422692063,
      4.290129089748303,
      4.33665202017671
    ],
    "volume": 48106529141
  },
  {
    "ts": 1628035200,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21137,
      "authorized": 21137,
      "funded": 19508
    },
    "payments": 90,
    "payments_amount": 198649260388,
    "trades": 95,
    "traded_amount": 7992485039,
    "price": [
      4.33665202017671,
      4.476156851923681,
      4.191340394309726,
      4.2226982675268
    ],
    "volume": 26022553299
  },
  {
    "ts": 1628121600,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21140,
      "authorized": 21140,
      "funded": 19507
    },
    "payments": 72,
    "payments_amount": 83068004848,
    "trades": 46,
    "traded_amount": 14587057745,
    "price": [
      4.2226982675268,
      4.3916781023389,
      4.2226982675268,
      4.239479023527528
    ],
    "volume": 23660314247
  },
  {
    "ts": 1628208000,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21144,
      "authorized": 21144,
      "funded": 19509
    },
    "payments": 113,
    "payments_amount": 78325299011,
    "trades": 129,
    "traded_amount": 12231363841,
    "price": [
      4.239479023527528,
      4.28771694239627,
      4.1214794528587655,
      4.141689144611619
    ],
    "volume": 23994596092
  },
  {
    "ts": 1628294400,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21149,
      "authorized": 21149,
      "funded": 19509
    },
    "payments": 69,
    "payments_amount": 42196192076,
    "trades": 323,
    "traded_amount": 29522679747,
    "price": [
      4.141689144611619,
      4.174947818293346,
      3.8121393373533037,
      3.846950805727394
    ],
    "volume": 55962720710
  },
  {
    "ts": 1628380800,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21148,
      "authorized": 21148,
      "funded": 19508
    },
    "payments": 24,
    "payments_amount": 28046371783,
    "trades": 245,
    "traded_amount": 17242866709,
    "price": [
      3.846950805727394,
      4.091692796851141,
      3.777736669947103,
      4.076704545454546
    ],
    "volume": 22134985250
  },
  {
    "ts": 1628467200,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21150,
      "authorized": 21150,
      "funded": 19508
    },
    "payments": 85,
    "payments_amount": 94952414833,
    "trades": 302,
    "traded_amount": 26959755175,
    "price": [
      4.076704545454546,
      4.1866666666666665,
      3.8592483500555015,
      3.935819409913092
    ],
    "volume": 46566015591
  },
  {
    "ts": 1628553600,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21157,
      "authorized": 21157,
      "funded": 19510
    },
    "payments": 86,
    "payments_amount": 99924359589,
    "trades": 155,
    "traded_amount": 27921972280,
    "price": [
      3.935819409913092,
      3.9572147929312314,
      3.7374295936193582,
      3.745802794
    ],
    "volume": 49146740790
  },
  {
    "ts": 1628640000,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21162,
      "authorized": 21162,
      "funded": 19508
    },
    "payments": 117,
    "payments_amount": 119780132967,
    "trades": 135,
    "traded_amount": 24116144679,
    "price": [
      3.745802794,
      3.765097693644182,
      3.342457715758055,
      3.4529444243474288
    ],
    "volume": 31822136363
  },
  {
    "ts": 1628726400,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21168,
      "authorized": 21168,
      "funded": 19508
    },
    "payments": 97,
    "payments_amount": 99925165171,
    "trades": 95,
    "traded_amount": 27914345459,
    "price": [
      3.4529444243474288,
      3.698997698884351,
      3.336389738458494,
      3.5616438356164384
    ],
    "volume": 35410724690
  },
  {
    "ts": 1628812800,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21174,
      "authorized": 21174,
      "funded": 19505
    },
    "payments": 100,
    "payments_amount": 66887233402,
    "trades": 135,
    "traded_amount": 15323483634,
    "price": [
      3.5616438356164384,
      3.563997637085918,
      3.2323733816053726,
      3.4070406
    ],
    "volume": 24392921520
  },
  {
    "ts": 1628899200,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21176,
      "authorized": 21176,
      "funded": 19505
    },
    "payments": 61,
    "payments_amount": 51830465253,
    "trades": 99,
    "traded_amount": 30375165848,
    "price": [
      3.4070406,
      3.4070406,
      3.003967633061726,
      3.0039706
    ],
    "volume": 29654615263
  },
  {
    "ts": 1628985600,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21176,
      "authorized": 21176,
      "funded": 19506
    },
    "payments": 24,
    "payments_amount": 13016915043,
    "trades": 142,
    "traded_amount": 14516912151,
    "price": [
      3.0039706,
      3.1471634339043257,
      2.9339976702694703,
      2.9990782825036
    ],
    "volume": 24090820252
  },
  {
    "ts": 1629072000,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21176,
      "authorized": 21176,
      "funded": 19496
    },
    "payments": 65,
    "payments_amount": 52998742645,
    "trades": 137,
    "traded_amount": 17191372265,
    "price": [
      2.9990782825036,
      3.1675840077333572,
      2.888237327706955,
      3.158078471163736
    ],
    "volume": 28582304232
  },
  {
    "ts": 1629158400,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21189,
      "authorized": 21189,
      "funded": 19490
    },
    "payments": 142,
    "payments_amount": 38607034774569,
    "trades": 172,
    "traded_amount": 37579227155,
    "price": [
      3.158078471163736,
      3.4102661596958175,
      3.1004016064257027,
      3.4054740788652027
    ],
    "volume": 53139804772
  },
  {
    "ts": 1629244800,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21190,
      "authorized": 21190,
      "funded": 19488
    },
    "payments": 69,
    "payments_amount": 67186817151,
    "trades": 86,
    "traded_amount": 19380576759,
    "price": [
      3.4054740788652027,
      3.4971463989113776,
      3.3036576784036984,
      3.4076194507114987
    ],
    "volume": 32460924902
  },
  {
    "ts": 1629331200,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21191,
      "authorized": 21191,
      "funded": 19486
    },
    "payments": 96,
    "payments_amount": 10233206796472,
    "trades": 142,
    "traded_amount": 62857161584,
    "price": [
      3.4076194507114987,
      3.504934306540211,
      3.2035302053665116,
      3.2035302053665116
    ],
    "volume": 97193102712
  },
  {
    "ts": 1629417600,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21191,
      "authorized": 21191,
      "funded": 19486
    },
    "payments": 100,
    "payments_amount": 134765819565,
    "trades": 182,
    "traded_amount": 56766760347,
    "price": [
      3.2035302053665116,
      3.231049785307512,
      3.0223330239291477,
      3.039342211404433
    ],
    "volume": 38787658716
  },
  {
    "ts": 1629504000,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21190,
      "authorized": 21190,
      "funded": 19483
    },
    "payments": 51,
    "payments_amount": 46681495928,
    "trades": 193,
    "traded_amount": 25894607321,
    "price": [
      3.039342211404433,
      3.1508288212025617,
      3.039342211404433,
      3.1221709018485684
    ],
    "volume": 23931269921
  },
  {
    "ts": 1629590400,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21194,
      "authorized": 21194,
      "funded": 19482
    },
    "payments": 46,
    "payments_amount": 60333290232,
    "trades": 146,
    "traded_amount": 41333469854,
    "price": [
      3.1221709018485684,
      3.2271466954098877,
      3.042407125523533,
      3.140491017926148
    ],
    "volume": 12751212081
  },
  {
    "ts": 1629676800,
    "supply": 12788896290188,
    "trustlines": {
      "total": 21202,
      "authorized": 21202,
      "funded": 19482
    },
    "payments": 103,
    "payments_amount": 89435281262,
    "trades": 204,
    "traded_amount": 50541245067,
    "price": [
      3.140491017926148,
      3.1746031746031744,
      3.0429836038356943,
      3.1261728494636816
    ],
    "volume": 42749403855
  },
  {
    "ts": 1629763200,
    "supply": 12788896290180,
    "trustlines": {
      "total": 21202,
      "authorized": 21202,
      "funded": 19479
    },
    "payments": 118,
    "payments_amount": 365707402749,
    "trades": 363,
    "traded_amount": 110807037807,
    "price": [
      3.1261728494636816,
      3.352051835853132,
      3.047383979531117,
      3.324501790583993
    ],
    "volume": 147257467957
  },
  {
    "ts": 1629849600,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21215,
      "authorized": 21215,
      "funded": 19484
    },
    "payments": 89,
    "payments_amount": 86665767370,
    "trades": 578,
    "traded_amount": 65664499550,
    "price": [
      3.324501790583993,
      3.4243818165486704,
      3.2699999982331613,
      3.29
    ],
    "volume": 36496602232
  },
  {
    "ts": 1629936000,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21219,
      "authorized": 21219,
      "funded": 19480
    },
    "payments": 62,
    "payments_amount": 10088277046811,
    "trades": 269,
    "traded_amount": 39571982492,
    "price": [
      3.29,
      3.5501790440604077,
      3.278867751236644,
      3.492562197746355
    ],
    "volume": 39628919869
  },
  {
    "ts": 1630022400,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21217,
      "authorized": 21217,
      "funded": 19476
    },
    "payments": 137,
    "payments_amount": 220237888604,
    "trades": 74,
    "traded_amount": 37129405548,
    "price": [
      3.492562197746355,
      3.5875,
      3.34634086457219,
      3.358085545
    ],
    "volume": 13884062160
  },
  {
    "ts": 1630108800,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21218,
      "authorized": 21218,
      "funded": 19478
    },
    "payments": 67,
    "payments_amount": 90897003960,
    "trades": 99,
    "traded_amount": 27947976522,
    "price": [
      3.358085545,
      3.441275773185444,
      3.3036576800315136,
      3.4129798706980776
    ],
    "volume": 19119259439
  },
  {
    "ts": 1630195200,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21223,
      "authorized": 21223,
      "funded": 19478
    },
    "payments": 84,
    "payments_amount": 123153088478,
    "trades": 138,
    "traded_amount": 45162963168,
    "price": [
      3.4129798706980776,
      3.465311994017725,
      3.337803960892454,
      3.4436189763976968
    ],
    "volume": 44520505534
  },
  {
    "ts": 1630281600,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21226,
      "authorized": 21226,
      "funded": 19474
    },
    "payments": 116,
    "payments_amount": 177257368269,
    "trades": 193,
    "traded_amount": 60080981779,
    "price": [
      3.4436189763976968,
      3.538675272226247,
      3.4102575866815235,
      3.5182648401826486
    ],
    "volume": 53090754631
  },
  {
    "ts": 1630368000,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21227,
      "authorized": 21227,
      "funded": 19472
    },
    "payments": 123,
    "payments_amount": 144355703879,
    "trades": 276,
    "traded_amount": 83743614604,
    "price": [
      3.5182648401826486,
      3.618664443359607,
      3.348424533523214,
      3.4437428243398394
    ],
    "volume": 70829428494
  },
  {
    "ts": 1630454400,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21235,
      "authorized": 21235,
      "funded": 19468
    },
    "payments": 208,
    "payments_amount": 479401893269,
    "trades": 121,
    "traded_amount": 19933693170,
    "price": [
      3.4437428243398394,
      3.4971463964195904,
      3.3477416532242263,
      3.3866502552515554
    ],
    "volume": 31804462764
  },
  {
    "ts": 1630540800,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21240,
      "authorized": 21240,
      "funded": 19469
    },
    "payments": 175,
    "payments_amount": 188854095214,
    "trades": 114,
    "traded_amount": 42024835286,
    "price": [
      3.3866502552515554,
      3.3866502552515554,
      3.2276256736233107,
      3.32958739438287
    ],
    "volume": 116363846929
  },
  {
    "ts": 1630627200,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21249,
      "authorized": 21249,
      "funded": 19467
    },
    "payments": 95,
    "payments_amount": 115484866035,
    "trades": 185,
    "traded_amount": 41923732020,
    "price": [
      3.32958739438287,
      3.4071464217091973,
      3.157188984775327,
      3.2226669502613583
    ],
    "volume": 35750372199
  },
  {
    "ts": 1630713600,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21248,
      "authorized": 21248,
      "funded": 19466
    },
    "payments": 38,
    "payments_amount": 38216802702,
    "trades": 116,
    "traded_amount": 12144284177,
    "price": [
      3.2226669502613583,
      3.242072521753496,
      3.102392193014312,
      3.171569734613213
    ],
    "volume": 9065543561
  },
  {
    "ts": 1630800000,
    "supply": 12788896277512,
    "trustlines": {
      "total": 21251,
      "authorized": 21251,
      "funded": 19464
    },
    "payments": 42,
    "payments_amount": 55928581307,
    "trades": 186,
    "traded_amount": 18565596605,
    "price": [
      3.171569734613213,
      3.212480004636792,
      3.010397270779711,
      3.0314732314562405
    ],
    "volume": 22071104405
  },
  {
    "ts": 1630886400,
    "supply": 12788896277504,
    "trustlines": {
      "total": 21250,
      "authorized": 21250,
      "funded": 19465
    },
    "payments": 84,
    "payments_amount": 176025802987,
    "trades": 202,
    "traded_amount": 43214325186,
    "price": [
      3.0314732314562405,
      3.0435701587352955,
      2.7751330007631116,
      2.7974140704332915
    ],
    "volume": 37823745431
  },
  {
    "ts": 1630972800,
    "supply": 12788896277494,
    "trustlines": {
      "total": 21253,
      "authorized": 21253,
      "funded": 19462
    },
    "payments": 98,
    "payments_amount": 110346827829,
    "trades": 785,
    "traded_amount": 259054898266,
    "price": [
      2.7974140704332915,
      4.10886145006931,
      2.7974140704332915,
      3.5334524955794238
    ],
    "volume": 421830108680
  },
  {
    "ts": 1631059200,
    "supply": 12788896277494,
    "trustlines": {
      "total": 21256,
      "authorized": 21256,
      "funded": 19462
    },
    "payments": 118,
    "payments_amount": 189563742501,
    "trades": 288,
    "traded_amount": 40521596843,
    "price": [
      3.5334524955794238,
      3.85714640302448,
      3.481553195194149,
      3.554522528675987
    ],
    "volume": 64796315126
  },
  {
    "ts": 1631145600,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21265,
      "authorized": 21265,
      "funded": 19460
    },
    "payments": 83,
    "payments_amount": 624341357907,
    "trades": 249,
    "traded_amount": 17722052252,
    "price": [
      3.554522528675987,
      3.6331928498764716,
      3.4937414791812245,
      3.5677177177177177
    ],
    "volume": 37922306754
  },
  {
    "ts": 1631232000,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21267,
      "authorized": 21267,
      "funded": 19460
    },
    "payments": 73,
    "payments_amount": 240648886640,
    "trades": 300,
    "traded_amount": 67168729220,
    "price": [
      3.5677177177177177,
      3.767146402737248,
      3.3333332102278224,
      3.767146402737248
    ],
    "volume": 143276585068
  },
  {
    "ts": 1631318400,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21268,
      "authorized": 21268,
      "funded": 19459
    },
    "payments": 30,
    "payments_amount": 32803286141,
    "trades": 145,
    "traded_amount": 12216248648,
    "price": [
      3.767146402737248,
      3.767146402737248,
      3.5639965430593126,
      3.65094569851077
    ],
    "volume": 31141597547
  },
  {
    "ts": 1631404800,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21269,
      "authorized": 21269,
      "funded": 19459
    },
    "payments": 24,
    "payments_amount": 13927450337,
    "trades": 77,
    "traded_amount": 16509667186,
    "price": [
      3.65094569851077,
      3.684585050434364,
      3.5104770283144218,
      3.550298069410881
    ],
    "volume": 35058446362
  },
  {
    "ts": 1631491200,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21272,
      "authorized": 21272,
      "funded": 19458
    },
    "payments": 61,
    "payments_amount": 132308598408,
    "trades": 148,
    "traded_amount": 23381406287,
    "price": [
      3.550298069410881,
      3.7714735,
      3.550298069410881,
      3.703790520423094
    ],
    "volume": 46513737130
  },
  {
    "ts": 1631577600,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21282,
      "authorized": 21282,
      "funded": 19459
    },
    "payments": 64,
    "payments_amount": 271474924129,
    "trades": 184,
    "traded_amount": 19330888754,
    "price": [
      3.703790520423094,
      3.7262419224555736,
      3.574734423520123,
      3.574734423520123
    ],
    "volume": 48323594039
  },
  {
    "ts": 1631664000,
    "supply": 12788896277481,
    "trustlines": {
      "total": 21289,
      "authorized": 21289,
      "funded": 19458
    },
    "payments": 44,
    "payments_amount": 68397919247,
    "trades": 115,
    "traded_amount": 36302542845,
    "price": [
      3.574734423520123,
      3.605241511833665,
      3.4769579083185236,
      3.4769579083185236
    ],
    "volume": 50941326700
  },
  {
    "ts": 1631750400,
    "supply": 12788896277472,
    "trustlines": {
      "total": 21297,
      "authorized": 21297,
      "funded": 19457
    },
    "payments": 88,
    "payments_amount": 120319031131,
    "trades": 150,
    "traded_amount": 25780740998,
    "price": [
      3.4769579083185236,
      3.620557607235358,
      3.46786689729628,
      3.570105970580257
    ],
    "volume": 57986710674
  },
  {
    "ts": 1631836800,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21304,
      "authorized": 21304,
      "funded": 19456
    },
    "payments": 43,
    "payments_amount": 32505874771,
    "trades": 212,
    "traded_amount": 36999395519,
    "price": [
      3.570105970580257,
      3.7918664944682905,
      3.525925925925926,
      3.6768313991263906
    ],
    "volume": 56107664118
  },
  {
    "ts": 1631923200,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21309,
      "authorized": 21309,
      "funded": 19456
    },
    "payments": 60,
    "payments_amount": 117120917580,
    "trades": 89,
    "traded_amount": 6349032412,
    "price": [
      3.6768313991263906,
      3.6818181818181817,
      3.5898321920703355,
      3.6506601043818137
    ],
    "volume": 11647458380
  },
  {
    "ts": 1632009600,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21312,
      "authorized": 21312,
      "funded": 19455
    },
    "payments": 17,
    "payments_amount": 15678732454,
    "trades": 42,
    "traded_amount": 22529827940,
    "price": [
      3.6506601043818137,
      3.7476092906174245,
      3.6326927720871494,
      3.735501346867908
    ],
    "volume": 31704987808
  },
  {
    "ts": 1632096000,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21315,
      "authorized": 21315,
      "funded": 19453
    },
    "payments": 67,
    "payments_amount": 131402936373,
    "trades": 150,
    "traded_amount": 25072956481,
    "price": [
      3.735501346867908,
      4.307146398919503,
      3.735501346867908,
      4.137986319815186
    ],
    "volume": 38746223062
  },
  {
    "ts": 1632182400,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21319,
      "authorized": 21319,
      "funded": 19452
    },
    "payments": 64,
    "payments_amount": 57041847883,
    "trades": 70,
    "traded_amount": 11624161102,
    "price": [
      4.137986319815186,
      4.4871463998742716,
      4.096703470390699,
      4.471876088665163
    ],
    "volume": 29993829159
  },
  {
    "ts": 1632268800,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21339,
      "authorized": 21339,
      "funded": 19460
    },
    "payments": 88,
    "payments_amount": 308760425574,
    "trades": 119,
    "traded_amount": 60981881942,
    "price": [
      4.471876088665163,
      4.471876088665163,
      3.998547308585974,
      4.005296110943643
    ],
    "volume": 188566554401
  },
  {
    "ts": 1632355200,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21343,
      "authorized": 21343,
      "funded": 19464
    },
    "payments": 73,
    "payments_amount": 39833296574,
    "trades": 178,
    "traded_amount": 28956270812,
    "price": [
      4.005296110943643,
      4.049802585848881,
      3.888618498552303,
      3.961831613509149
    ],
    "volume": 56480871600
  },
  {
    "ts": 1632441600,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21344,
      "authorized": 21344,
      "funded": 19463
    },
    "payments": 49,
    "payments_amount": 37888734609,
    "trades": 168,
    "traded_amount": 23273941065,
    "price": [
      3.961831613509149,
      4.3152919148071724,
      3.8579969574689645,
      4.127439364856748
    ],
    "volume": 50710145775
  },
  {
    "ts": 1632528000,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21345,
      "authorized": 21345,
      "funded": 19460
    },
    "payments": 30,
    "payments_amount": 20793756782,
    "trades": 170,
    "traded_amount": 27846588464,
    "price": [
      4.127439364856748,
      4.332208974588044,
      4.1114097388679145,
      4.217292263072814
    ],
    "volume": 42696291347
  },
  {
    "ts": 1632614400,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21348,
      "authorized": 21348,
      "funded": 19461
    },
    "payments": 17,
    "payments_amount": 16595784106,
    "trades": 152,
    "traded_amount": 10464375574,
    "price": [
      4.217292263072814,
      4.480000109403799,
      4.176213141743499,
      4.280329863602727
    ],
    "volume": 23608860214
  },
  {
    "ts": 1632700800,
    "supply": 12788896277442,
    "trustlines": {
      "total": 21353,
      "authorized": 21353,
      "funded": 19460
    },
    "payments": 49,
    "payments_amount": 67237727380,
    "trades": 108,
    "traded_amount": 27123325455,
    "price": [
      4.280329863602727,
      4.398350121033178,
      4.184668989547038,
      4.3963638884981355
    ],
    "volume": 21754071460
  },
  {
    "ts": 1632787200,
    "supply": 12787541787274,
    "trustlines": {
      "total": 21362,
      "authorized": 21362,
      "funded": 19460
    },
    "payments": 75,
    "payments_amount": 84883726452,
    "trades": 153,
    "traded_amount": 30890490888,
    "price": [
      4.3963638884981355,
      4.557737231050945,
      4.341889809442785,
      4.557737231050945
    ],
    "volume": 67388882054
  },
  {
    "ts": 1632873600,
    "supply": 12787541787274,
    "trustlines": {
      "total": 21368,
      "authorized": 21368,
      "funded": 19461
    },
    "payments": 95,
    "payments_amount": 80138436246,
    "trades": 256,
    "traded_amount": 33572054362,
    "price": [
      4.557737231050945,
      4.557737231050945,
      4.115502281574279,
      4.32001948304004
    ],
    "volume": 84589525852
  },
  {
    "ts": 1632960000,
    "supply": 12787541787274,
    "trustlines": {
      "total": 21373,
      "authorized": 21373,
      "funded": 19463
    },
    "payments": 75,
    "payments_amount": 88641655644,
    "trades": 389,
    "traded_amount": 63002420090,
    "price": [
      4.32001948304004,
      4.320670638635793,
      4.1415472546680485,
      4.180463410893102
    ],
    "volume": 81194239840
  },
  {
    "ts": 1633046400,
    "supply": 12787541787274,
    "trustlines": {
      "total": 21379,
      "authorized": 21379,
      "funded": 19466
    },
    "payments": 104,
    "payments_amount": 172327398384,
    "trades": 247,
    "traded_amount": 29465020907,
    "price": [
      4.180463410893102,
      4.222184400849605,
      3.870000014952273,
      3.870000014952273
    ],
    "volume": 76930133121
  },
  {
    "ts": 1633132800,
    "supply": 12787541787274,
    "trustlines": {
      "total": 21380,
      "authorized": 21380,
      "funded": 19467
    },
    "payments": 30,
    "payments_amount": 19798896037,
    "trades": 480,
    "traded_amount": 95446287398,
    "price": [
      3.870000014952273,
      3.9606767574060795,
      3.5672326183107814,
      3.665014456836018
    ],
    "volume": 203398312987
  },
  {
    "ts": 1633219200,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21379,
      "authorized": 21379,
      "funded": 19466
    },
    "payments": 46,
    "payments_amount": 113461349515,
    "trades": 254,
    "traded_amount": 49101200935,
    "price": [
      3.665014456836018,
      3.73476543,
      3.609372346567945,
      3.7050759554444905
    ],
    "volume": 144513750539
  },
  {
    "ts": 1633305600,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21381,
      "authorized": 21381,
      "funded": 19465
    },
    "payments": 68,
    "payments_amount": 592854892354,
    "trades": 131,
    "traded_amount": 59666901331,
    "price": [
      3.7050759554444905,
      3.9037025782807087,
      3.7050759554444905,
      3.7213865845734424
    ],
    "volume": 178167265288
  },
  {
    "ts": 1633392000,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21389,
      "authorized": 21389,
      "funded": 19467
    },
    "payments": 68,
    "payments_amount": 93923469418,
    "trades": 74,
    "traded_amount": 31920058528,
    "price": [
      3.7213865845734424,
      3.752621772343518,
      3.6226375082862066,
      3.6399778
    ],
    "volume": 102000140459
  },
  {
    "ts": 1633478400,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21394,
      "authorized": 21394,
      "funded": 19467
    },
    "payments": 79,
    "payments_amount": 188952295106,
    "trades": 339,
    "traded_amount": 177198515554,
    "price": [
      3.6399778,
      3.7671464037068505,
      3.269823756215237,
      3.269823923150471
    ],
    "volume": 498565911651
  },
  {
    "ts": 1633564800,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21396,
      "authorized": 21396,
      "funded": 19467
    },
    "payments": 94,
    "payments_amount": 144503186266,
    "trades": 244,
    "traded_amount": 53709091654,
    "price": [
      3.269823923150471,
      3.374823112288832,
      3.1862354411087774,
      3.3538436162189362
    ],
    "volume": 93872442132
  },
  {
    "ts": 1633651200,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21400,
      "authorized": 21400,
      "funded": 19466
    },
    "payments": 83,
    "payments_amount": 58425583400,
    "trades": 190,
    "traded_amount": 47097843843,
    "price": [
      3.3538436162189362,
      3.4682113779661066,
      3.3130693436852554,
      3.4595349782246183
    ],
    "volume": 109311172036
  },
  {
    "ts": 1633737600,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21402,
      "authorized": 21402,
      "funded": 19465
    },
    "payments": 56,
    "payments_amount": 85508548520,
    "trades": 231,
    "traded_amount": 48200559998,
    "price": [
      3.4595349782246183,
      3.4595349782246183,
      3.186235461117947,
      3.2868668960242386
    ],
    "volume": 89696802477
  },
  {
    "ts": 1633824000,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21407,
      "authorized": 21407,
      "funded": 19465
    },
    "payments": 29,
    "payments_amount": 48804751268,
    "trades": 230,
    "traded_amount": 54112077364,
    "price": [
      3.2868668960242386,
      3.497146398484768,
      3.255066734339509,
      3.497146398484768
    ],
    "volume": 80594870384
  },
  {
    "ts": 1633910400,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21409,
      "authorized": 21409,
      "funded": 19465
    },
    "payments": 95,
    "payments_amount": 276050608175,
    "trades": 293,
    "traded_amount": 111768226073,
    "price": [
      3.497146398484768,
      3.5406968047220344,
      3.2487628985036356,
      3.3629248725348333
    ],
    "volume": 82302483440
  },
  {
    "ts": 1633996800,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21418,
      "authorized": 21418,
      "funded": 19465
    },
    "payments": 55,
    "payments_amount": 43175348285,
    "trades": 203,
    "traded_amount": 34862937900,
    "price": [
      3.3629248725348333,
      3.5871504531823564,
      3.3629248725348333,
      3.4681014604150655
    ],
    "volume": 60986204327
  },
  {
    "ts": 1634083200,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21429,
      "authorized": 21429,
      "funded": 19466
    },
    "payments": 62,
    "payments_amount": 49032708429,
    "trades": 233,
    "traded_amount": 41680586245,
    "price": [
      3.4681014604150655,
      3.4855898318729275,
      3.1236578054093385,
      3.1618001014997468
    ],
    "volume": 52240781895
  },
  {
    "ts": 1634169600,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21430,
      "authorized": 21430,
      "funded": 19465
    },
    "payments": 56,
    "payments_amount": 307497970287,
    "trades": 182,
    "traded_amount": 99849883766,
    "price": [
      3.1618001014997468,
      3.176605113155384,
      3.0662460904602367,
      3.1678126103077604
    ],
    "volume": 165841644563
  },
  {
    "ts": 1634256000,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21428,
      "authorized": 21428,
      "funded": 19462
    },
    "payments": 47,
    "payments_amount": 62609766391,
    "trades": 134,
    "traded_amount": 61286138838,
    "price": [
      3.1678126103077604,
      3.2844146234775087,
      3.1390078948229503,
      3.2053969475779778
    ],
    "volume": 176772087981
  },
  {
    "ts": 1634342400,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21426,
      "authorized": 21426,
      "funded": 19460
    },
    "payments": 4,
    "payments_amount": 3573905936,
    "trades": 578,
    "traded_amount": 158246324231,
    "price": [
      3.2053969475779778,
      3.2053969475779778,
      2.7756712816679947,
      2.9577558853166224
    ],
    "volume": 222819799333
  },
  {
    "ts": 1634428800,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21426,
      "authorized": 21426,
      "funded": 19457
    },
    "payments": 6,
    "payments_amount": 12374082233,
    "trades": 161,
    "traded_amount": 65090780374,
    "price": [
      2.9577558853166224,
      3.158798253201705,
      2.8971000616274045,
      3.0354971979381515
    ],
    "volume": 97804147956
  },
  {
    "ts": 1634515200,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21432,
      "authorized": 21432,
      "funded": 19456
    },
    "payments": 73,
    "payments_amount": 205646420540,
    "trades": 215,
    "traded_amount": 93276068912,
    "price": [
      3.0354971979381515,
      3.1008824644637665,
      2.92572501837052,
      3.0563479265415863
    ],
    "volume": 252960409130
  },
  {
    "ts": 1634601600,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21431,
      "authorized": 21431,
      "funded": 19452
    },
    "payments": 39,
    "payments_amount": 47211668910,
    "trades": 168,
    "traded_amount": 68966023100,
    "price": [
      3.0563479265415863,
      3.220777876349809,
      3.048302574618364,
      3.220777876349809
    ],
    "volume": 121206682187
  },
  {
    "ts": 1634688000,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21438,
      "authorized": 21438,
      "funded": 19450
    },
    "payments": 31,
    "payments_amount": 272793404859,
    "trades": 201,
    "traded_amount": 59398293529,
    "price": [
      3.220777876349809,
      3.220777876349809,
      2.9791216414989927,
      3.0030030034788897
    ],
    "volume": 102361231429
  },
  {
    "ts": 1634774400,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21436,
      "authorized": 21436,
      "funded": 19446
    },
    "payments": 41,
    "payments_amount": 52338389720,
    "trades": 230,
    "traded_amount": 97227378166,
    "price": [
      3.0030030034788897,
      3.1254775474415983,
      2.9436577829461794,
      3.0878947733815836
    ],
    "volume": 147438457921
  },
  {
    "ts": 1634860800,
    "supply": 12787541787270,
    "trustlines": {
      "total": 21439,
      "authorized": 21439,
      "funded": 19439
    },
    "payments": 39,
    "payments_amount": 53912648775,
    "trades": 129,
    "traded_amount": 13317971788,
    "price": [
      3.0878947733815836,
      3.161246656586505,
      3.0588634634739456,
      3.1612346860764173
    ],
    "volume": 19834916107
  },
  {
    "ts": 1634947200,
    "supply": 12787541787262,
    "trustlines": {
      "total": 21439,
      "authorized": 21439,
      "funded": 19436
    },
    "payments": 31,
    "payments_amount": 74978203030,
    "trades": 130,
    "traded_amount": 55786310385,
    "price": [
      3.1612346860764173,
      3.170865165884035,
      3.08836560112329,
      3.101250398365296
    ],
    "volume": 48268278490
  },
  {
    "ts": 1635033600,
    "supply": 12787541787262,
    "trustlines": {
      "total": 21443,
      "authorized": 21443,
      "funded": 19432
    },
    "payments": 25,
    "payments_amount": 180799959639,
    "trades": 138,
    "traded_amount": 27560460283,
    "price": [
      3.101250398365296,
      3.1565524087364083,
      3.0328001561961098,
      3.1549616790138004
    ],
    "volume": 58157546859
  },
  {
    "ts": 1635120000,
    "supply": 12787541787262,
    "trustlines": {
      "total": 21470,
      "authorized": 21470,
      "funded": 19440
    },
    "payments": 54,
    "payments_amount": 31810795934,
    "trades": 164,
    "traded_amount": 25377106592,
    "price": [
      3.1549616790138004,
      3.1549616790138004,
      3.007415004460232,
      3.007415004460232
    ],
    "volume": 43660858823
  },
  {
    "ts": 1635206400,
    "supply": 12787541787249,
    "trustlines": {
      "total": 21482,
      "authorized": 21482,
      "funded": 19443
    },
    "payments": 46,
    "payments_amount": 32550669138,
    "trades": 167,
    "traded_amount": 31717322727,
    "price": [
      3.007415004460232,
      3.127797941670458,
      3.007415004460232,
      3.112917367648949
    ],
    "volume": 82926874312
  },
  {
    "ts": 1635292800,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21485,
      "authorized": 21485,
      "funded": 19439
    },
    "payments": 63,
    "payments_amount": 467842984567,
    "trades": 412,
    "traded_amount": 418820249917,
    "price": [
      3.112917367648949,
      3.5481087399991664,
      3.074657063700781,
      3.4963894805144276
    ],
    "volume": 625546738574
  },
  {
    "ts": 1635379200,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21493,
      "authorized": 21493,
      "funded": 19440
    },
    "payments": 49,
    "payments_amount": 48967814976,
    "trades": 141,
    "traded_amount": 15094230862,
    "price": [
      3.4963894805144276,
      3.547548907693489,
      3.348254200627837,
      3.4375
    ],
    "volume": 21584671037
  },
  {
    "ts": 1635465600,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21500,
      "authorized": 21500,
      "funded": 19441
    },
    "payments": 61,
    "payments_amount": 27328087411,
    "trades": 303,
    "traded_amount": 31128067551,
    "price": [
      3.4375,
      3.4375,
      3.1915255552354016,
      3.236545993671306
    ],
    "volume": 47820376057
  },
  {
    "ts": 1635552000,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21502,
      "authorized": 21502,
      "funded": 19438
    },
    "payments": 51,
    "payments_amount": 40133856175,
    "trades": 363,
    "traded_amount": 24703400345,
    "price": [
      3.236545993671306,
      3.303030303030303,
      3.1674247133146327,
      3.250533516893817
    ],
    "volume": 37114607793
  },
  {
    "ts": 1635638400,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21505,
      "authorized": 21505,
      "funded": 19437
    },
    "payments": 45,
    "payments_amount": 30814680269,
    "trades": 264,
    "traded_amount": 61952577638,
    "price": [
      3.250533516893817,
      3.250533516893817,
      3.0173990033554414,
      3.10415763297306
    ],
    "volume": 103179611746
  },
  {
    "ts": 1635724800,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21508,
      "authorized": 21508,
      "funded": 19438
    },
    "payments": 61,
    "payments_amount": 84976074885,
    "trades": 117,
    "traded_amount": 15925631320,
    "price": [
      3.10415763297306,
      3.2062386949343153,
      3.0970905581336234,
      3.1796200345423142
    ],
    "volume": 16219271149
  },
  {
    "ts": 1635811200,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21516,
      "authorized": 21516,
      "funded": 19438
    },
    "payments": 45,
    "payments_amount": 109960498737,
    "trades": 126,
    "traded_amount": 54560951999,
    "price": [
      3.1796200345423142,
      3.1947267808202686,
      3.041436235273662,
      3.041436235273662
    ],
    "volume": 136486330469
  },
  {
    "ts": 1635897600,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21523,
      "authorized": 21523,
      "funded": 19440
    },
    "payments": 73,
    "payments_amount": 117870998090,
    "trades": 471,
    "traded_amount": 64188012199,
    "price": [
      3.041436235273662,
      3.1463414634146343,
      2.9351135285634986,
      3.0403961368047043
    ],
    "volume": 100398857982
  },
  {
    "ts": 1635984000,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21530,
      "authorized": 21530,
      "funded": 19442
    },
    "payments": 73,
    "payments_amount": 138844931250,
    "trades": 698,
    "traded_amount": 74649432755,
    "price": [
      3.0403961368047043,
      3.1666666666666665,
      2.985502947853858,
      3.157905495360665
    ],
    "volume": 39621169637
  },
  {
    "ts": 1636070400,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21536,
      "authorized": 21536,
      "funded": 19444
    },
    "payments": 83,
    "payments_amount": 54827506863,
    "trades": 309,
    "traded_amount": 42343785443,
    "price": [
      3.157905495360665,
      3.235294117647059,
      3.099506206015675,
      3.2138860740503277
    ],
    "volume": 41164561204
  },
  {
    "ts": 1636156800,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21536,
      "authorized": 21536,
      "funded": 19444
    },
    "payments": 28,
    "payments_amount": 16426788398,
    "trades": 393,
    "traded_amount": 17412583790,
    "price": [
      3.2138860740503277,
      3.2861959086099923,
      3.167531007497255,
      3.209255411530929
    ],
    "volume": 24257164770
  },
  {
    "ts": 1636243200,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21545,
      "authorized": 21545,
      "funded": 19447
    },
    "payments": 18,
    "payments_amount": 4524794418,
    "trades": 237,
    "traded_amount": 8910889532,
    "price": [
      3.209255411530929,
      3.2270383145919403,
      3.1640651295688538,
      3.1941746551156136
    ],
    "volume": 15169998180
  },
  {
    "ts": 1636329600,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21550,
      "authorized": 21550,
      "funded": 19451
    },
    "payments": 33,
    "payments_amount": 19131924952,
    "trades": 360,
    "traded_amount": 17550306552,
    "price": [
      3.1941746551156136,
      3.1941746551156136,
      3.068721498359391,
      3.0687215644588033
    ],
    "volume": 26285218450
  },
  {
    "ts": 1636416000,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21554,
      "authorized": 21554,
      "funded": 19449
    },
    "payments": 61,
    "payments_amount": 227470948435,
    "trades": 654,
    "traded_amount": 36308931490,
    "price": [
      3.0687215644588033,
      3.096973682185244,
      2.7818781423158305,
      2.8137719116953424
    ],
    "volume": 71484115842
  },
  {
    "ts": 1636502400,
    "supply": 12787541787240,
    "trustlines": {
      "total": 21565,
      "authorized": 21565,
      "funded": 19451
    },
    "payments": 48,
    "payments_amount": 121669153297,
    "trades": 1017,
    "traded_amount": 90961590809,
    "price": [
      2.8137719116953424,
      3.095223835903578,
      2.664767930315727,
      3.030820908152434
    ],
    "volume": 141034286941
  },
  {
    "ts": 1636588800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21567,
      "authorized": 21567,
      "funded": 19450
    },
    "payments": 94,
    "payments_amount": 99172589950781,
    "trades": 800,
    "traded_amount": 65506773459,
    "price": [
      3.030820908152434,
      3.0430637354963603,
      2.8461538461538463,
      2.9303029885880263
    ],
    "volume": 120722959372
  },
  {
    "ts": 1636675200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21568,
      "authorized": 21568,
      "funded": 19449
    },
    "payments": 43,
    "payments_amount": 76880256285,
    "trades": 662,
    "traded_amount": 48044459656,
    "price": [
      2.9303029885880263,
      3.137146434438496,
      2.9075727067472785,
      3.0066800597741645
    ],
    "volume": 84816586468
  },
  {
    "ts": 1636761600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21568,
      "authorized": 21568,
      "funded": 19448
    },
    "payments": 17,
    "payments_amount": 8250776588,
    "trades": 346,
    "traded_amount": 13437976758,
    "price": [
      3.0066800597741645,
      3.100706272018651,
      2.993594964086739,
      3.023103592142998
    ],
    "volume": 21364850441
  },
  {
    "ts": 1636848000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21569,
      "authorized": 21569,
      "funded": 19445
    },
    "payments": 41,
    "payments_amount": 8198802295,
    "trades": 264,
    "traded_amount": 14939610740,
    "price": [
      3.023103592142998,
      3.0995127521680965,
      3.005127468888084,
      3.0409460204730103
    ],
    "volume": 16923246839
  },
  {
    "ts": 1636934400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21573,
      "authorized": 21573,
      "funded": 19442
    },
    "payments": 110,
    "payments_amount": 131365990891,
    "trades": 521,
    "traded_amount": 67591196692,
    "price": [
      3.0409460204730103,
      3.0409463517718454,
      2.9099656729260226,
      3.005059383205704
    ],
    "volume": 167313309331
  },
  {
    "ts": 1637020800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21584,
      "authorized": 21584,
      "funded": 19444
    },
    "payments": 108,
    "payments_amount": 240271323450,
    "trades": 960,
    "traded_amount": 263462500822,
    "price": [
      3.005059383205704,
      3.3397896589994707,
      3.005059383205704,
      3.2853689028987834
    ],
    "volume": 759233111134
  },
  {
    "ts": 1637107200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21595,
      "authorized": 21595,
      "funded": 19444
    },
    "payments": 37,
    "payments_amount": 28864631280,
    "trades": 1332,
    "traded_amount": 66993509740,
    "price": [
      3.2853689028987834,
      3.407354708008792,
      3.237161788619939,
      3.2783124373436996
    ],
    "volume": 91972679385
  },
  {
    "ts": 1637193600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21604,
      "authorized": 21604,
      "funded": 19446
    },
    "payments": 40,
    "payments_amount": 73402762635,
    "trades": 3030,
    "traded_amount": 111860234577,
    "price": [
      3.2783124373436996,
      3.577581738586042,
      3.2242686372379206,
      3.4800847178149255
    ],
    "volume": 271852375559
  },
  {
    "ts": 1637280000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21615,
      "authorized": 21615,
      "funded": 19445
    },
    "payments": 24,
    "payments_amount": 9147591092,
    "trades": 3889,
    "traded_amount": 229494690532,
    "price": [
      3.4800847178149255,
      3.5145620167513716,
      3.173462742912706,
      3.179458688791237
    ],
    "volume": 352529643754
  },
  {
    "ts": 1637366400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21620,
      "authorized": 21620,
      "funded": 19445
    },
    "payments": 43,
    "payments_amount": 41179895082,
    "trades": 2706,
    "traded_amount": 22404665439,
    "price": [
      3.179458688791237,
      3.3278747506869486,
      3.157609536039155,
      3.2331050427358705
    ],
    "volume": 41240607002
  },
  {
    "ts": 1637452800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21635,
      "authorized": 21635,
      "funded": 19451
    },
    "payments": 58,
    "payments_amount": 17754475507,
    "trades": 2785,
    "traded_amount": 28160580293,
    "price": [
      3.2331050427358705,
      3.347676803380318,
      3.2331050427358705,
      3.274180621757287
    ],
    "volume": 58700714587
  },
  {
    "ts": 1637539200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21644,
      "authorized": 21644,
      "funded": 19449
    },
    "payments": 41,
    "payments_amount": 147943272988,
    "trades": 3939,
    "traded_amount": 89046661605,
    "price": [
      3.274180621757287,
      3.3703416647186466,
      3.164020591483074,
      3.333680101098787
    ],
    "volume": 198547485802
  },
  {
    "ts": 1637625600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21649,
      "authorized": 21649,
      "funded": 19453
    },
    "payments": 18,
    "payments_amount": 21241971201,
    "trades": 3509,
    "traded_amount": 111795342613,
    "price": [
      3.333680101098787,
      3.407146406148756,
      3.2855752274625,
      3.2915365932249783
    ],
    "volume": 209280237350
  },
  {
    "ts": 1637712000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21654,
      "authorized": 21654,
      "funded": 19449
    },
    "payments": 52,
    "payments_amount": 126894554332,
    "trades": 4351,
    "traded_amount": 55588971933,
    "price": [
      3.2915365932249783,
      3.480516435364978,
      3.2915365932249783,
      3.431121967508239
    ],
    "volume": 80372764631
  },
  {
    "ts": 1637798400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21661,
      "authorized": 21661,
      "funded": 19451
    },
    "payments": 35,
    "payments_amount": 60035294328,
    "trades": 2754,
    "traded_amount": 51856519655,
    "price": [
      3.431121967508239,
      3.431121967508239,
      3.2331735657225855,
      3.268315640851331
    ],
    "volume": 60158783801
  },
  {
    "ts": 1637884800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21665,
      "authorized": 21665,
      "funded": 19449
    },
    "payments": 37,
    "payments_amount": 77864160517,
    "trades": 3454,
    "traded_amount": 114783275825,
    "price": [
      3.268315640851331,
      3.6905541977741567,
      3.212291872294582,
      3.4429520958517865
    ],
    "volume": 285592705423
  },
  {
    "ts": 1637971200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21663,
      "authorized": 21663,
      "funded": 19445
    },
    "payments": 39,
    "payments_amount": 60954819961,
    "trades": 2509,
    "traded_amount": 69738635463,
    "price": [
      3.4429520958517865,
      3.5319033641275652,
      3.3873904390904856,
      3.4683544303797467
    ],
    "volume": 171745460853
  },
  {
    "ts": 1638057600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21666,
      "authorized": 21666,
      "funded": 19446
    },
    "payments": 14,
    "payments_amount": 9765957146,
    "trades": 2564,
    "traded_amount": 47496948141,
    "price": [
      3.4683544303797467,
      3.7347041288067078,
      3.4683544303797467,
      3.5058113109538342
    ],
    "volume": 92040462871
  },
  {
    "ts": 1638144000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21665,
      "authorized": 21665,
      "funded": 19445
    },
    "payments": 42,
    "payments_amount": 50130130098,
    "trades": 1575,
    "traded_amount": 243783560063,
    "price": [
      3.5058113109538342,
      3.5084934682776763,
      3.2801143619688116,
      3.4300172143537626
    ],
    "volume": 729084748824
  },
  {
    "ts": 1638230400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21677,
      "authorized": 21677,
      "funded": 19445
    },
    "payments": 55,
    "payments_amount": 214049321307,
    "trades": 1510,
    "traded_amount": 58383200220,
    "price": [
      3.4300172143537626,
      3.4300172143537626,
      3.248847504112895,
      3.3593097350528978
    ],
    "volume": 94504616938
  },
  {
    "ts": 1638316800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21691,
      "authorized": 21691,
      "funded": 19444
    },
    "payments": 56,
    "payments_amount": 165500546338,
    "trades": 2703,
    "traded_amount": 95997514560,
    "price": [
      3.3593097350528978,
      3.4646377753004276,
      3.260887098188143,
      3.4430629460423487
    ],
    "volume": 254829314852
  },
  {
    "ts": 1638403200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21694,
      "authorized": 21694,
      "funded": 19444
    },
    "payments": 84,
    "payments_amount": 377646815542,
    "trades": 1647,
    "traded_amount": 62483762381,
    "price": [
      3.4430629460423487,
      3.5440005386880817,
      3.306254751633194,
      3.333558000947608
    ],
    "volume": 97245963692
  },
  {
    "ts": 1638489600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21701,
      "authorized": 21701,
      "funded": 19442
    },
    "payments": 115,
    "payments_amount": 273553103530,
    "trades": 1914,
    "traded_amount": 91548439310,
    "price": [
      3.333558000947608,
      3.4830468130803167,
      3.1236577962671905,
      3.4201461038615104
    ],
    "volume": 176732143662
  },
  {
    "ts": 1638576000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21702,
      "authorized": 21702,
      "funded": 19442
    },
    "payments": 54,
    "payments_amount": 67929745483,
    "trades": 3013,
    "traded_amount": 154592606044,
    "price": [
      3.4201461038615104,
      4.217146429632607,
      3.4201461038615104,
      3.881061946902655
    ],
    "volume": 276446392955
  },
  {
    "ts": 1638662400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21706,
      "authorized": 21706,
      "funded": 19443
    },
    "payments": 33,
    "payments_amount": 23894744531,
    "trades": 2218,
    "traded_amount": 96786575404,
    "price": [
      3.881061946902655,
      4.2056254296922395,
      3.8354017046961015,
      3.971805479895761
    ],
    "volume": 226805847101
  },
  {
    "ts": 1638748800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21712,
      "authorized": 21712,
      "funded": 19440
    },
    "payments": 102,
    "payments_amount": 105640656412,
    "trades": 3503,
    "traded_amount": 73909592773,
    "price": [
      3.971805479895761,
      4.481304967511752,
      3.836989097003269,
      3.836989097003269
    ],
    "volume": 185249730053
  },
  {
    "ts": 1638835200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21716,
      "authorized": 21716,
      "funded": 19441
    },
    "payments": 88,
    "payments_amount": 56042896313,
    "trades": 2549,
    "traded_amount": 96651744714,
    "price": [
      3.836989097003269,
      3.9862144345501855,
      3.751986373521859,
      3.928126299851242
    ],
    "volume": 187237150656
  },
  {
    "ts": 1638921600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21725,
      "authorized": 21725,
      "funded": 19441
    },
    "payments": 77,
    "payments_amount": 80914935772,
    "trades": 1653,
    "traded_amount": 96163927015,
    "price": [
      3.928126299851242,
      3.9526288156357086,
      3.6980472244233398,
      3.7151337101131214
    ],
    "volume": 242614648243
  },
  {
    "ts": 1639008000,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21750,
      "authorized": 21750,
      "funded": 19450
    },
    "payments": 809,
    "payments_amount": 648929715552,
    "trades": 3993,
    "traded_amount": 582904905068,
    "price": [
      3.7151337101131214,
      4.120036526942783,
      3.598103297502199,
      4.120036526942783
    ],
    "volume": 1508439344807
  },
  {
    "ts": 1639094400,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21774,
      "authorized": 21774,
      "funded": 19459
    },
    "payments": 729,
    "payments_amount": 227961129351,
    "trades": 3003,
    "traded_amount": 347413144612,
    "price": [
      4.120036526942783,
      4.3317611899797965,
      3.8708484849795513,
      4.305734911368644
    ],
    "volume": 770871758763
  },
  {
    "ts": 1639180800,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21785,
      "authorized": 21785,
      "funded": 19465
    },
    "payments": 1190,
    "payments_amount": 537220332374,
    "trades": 3525,
    "traded_amount": 637527065983,
    "price": [
      4.305734911368644,
      4.381811346189102,
      4.027165982109435,
      4.153366174055829
    ],
    "volume": 695872198570
  },
  {
    "ts": 1639267200,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21795,
      "authorized": 21795,
      "funded": 19464
    },
    "payments": 763,
    "payments_amount": 339359137823,
    "trades": 7411,
    "traded_amount": 1293342304133,
    "price": [
      4.153366174055829,
      4.3022628987109695,
      3.9184543012426514,
      4.171855971198067
    ],
    "volume": 1950803938556
  },
  {
    "ts": 1639353600,
    "supply": 12787525763033,
    "trustlines": {
      "total": 21797,
      "authorized": 21797,
      "funded": 19458
    },
    "payments": 21,
    "payments_amount": 304860723632,
    "trades": 3014,
    "traded_amount": 229411212015,
    "price": [
      4.171855971198067,
      4.48720729885644,
      4.146425443503277,
      4.437224317586141
    ],
    "volume": 625334744190
  },
  {
    "ts": 1639440000,
    "supply": 12787525762804,
    "trustlines": {
      "total": 21801,
      "authorized": 21801,
      "funded": 19456
    },
    "payments": 67,
    "payments_amount": 72749662597,
    "trades": 2438,
    "traded_amount": 65925202420,
    "price": [
      4.437224317586141,
      4.526036083088228,
      4.162327948587236,
      4.203530523162559
    ],
    "volume": 168904348132
  },
  {
    "ts": 1639526400,
    "supply": 12787525762804,
    "trustlines": {
      "total": 21806,
      "authorized": 21806,
      "funded": 19453
    },
    "payments": 63,
    "payments_amount": 57685814481,
    "trades": 2320,
    "traded_amount": 81147815167,
    "price": [
      4.203530523162559,
      4.581994409959189,
      4.103119005818504,
      4.118134362996697
    ],
    "volume": 172705999565
  },
  {
    "ts": 1639612800,
    "supply": 12787525762804,
    "trustlines": {
      "total": 21814,
      "authorized": 21814,
      "funded": 19454
    },
    "payments": 36,
    "payments_amount": 45216473241,
    "trades": 1565,
    "traded_amount": 59605636899,
    "price": [
      4.118134362996697,
      4.408596211518966,
      4.051165701410902,
      4.408596211518966
    ],
    "volume": 104631287900
  },
  {
    "ts": 1639699200,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21813,
      "authorized": 21813,
      "funded": 19453
    },
    "payments": 26,
    "payments_amount": 256868466134,
    "trades": 2082,
    "traded_amount": 122335179509,
    "price": [
      4.408596211518966,
      4.552398702090512,
      4.301814875780325,
      4.418099302850203
    ],
    "volume": 382608476862
  },
  {
    "ts": 1639785600,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21819,
      "authorized": 21819,
      "funded": 19452
    },
    "payments": 97,
    "payments_amount": 13512934840,
    "trades": 1598,
    "traded_amount": 35905801354,
    "price": [
      4.418099302850203,
      4.463080284119691,
      4.216347538348735,
      4.384832163971676
    ],
    "volume": 62539693830
  },
  {
    "ts": 1639872000,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21818,
      "authorized": 21818,
      "funded": 19452
    },
    "payments": 14,
    "payments_amount": 11114906774,
    "trades": 1393,
    "traded_amount": 32230748001,
    "price": [
      4.384832163971676,
      4.428910778042257,
      4.221647531195398,
      4.428910778042257
    ],
    "volume": 62179643820
  },
  {
    "ts": 1639958400,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21818,
      "authorized": 21818,
      "funded": 19450
    },
    "payments": 54,
    "payments_amount": 36060683606,
    "trades": 2705,
    "traded_amount": 53702873210,
    "price": [
      4.428910778042257,
      4.471262080791234,
      4.134853280952039,
      4.376588240313747
    ],
    "volume": 121722991691
  },
  {
    "ts": 1640044800,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21822,
      "authorized": 21822,
      "funded": 19450
    },
    "payments": 76,
    "payments_amount": 216222408731,
    "trades": 1361,
    "traded_amount": 18230287551,
    "price": [
      4.376588240313747,
      4.439915730399437,
      4.196174509430367,
      4.258523311826334
    ],
    "volume": 43718070709
  },
  {
    "ts": 1640131200,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21820,
      "authorized": 21820,
      "funded": 19451
    },
    "payments": 58,
    "payments_amount": 102532487453,
    "trades": 1901,
    "traded_amount": 74351655880,
    "price": [
      4.258523311826334,
      4.258523311826334,
      4.082916989853318,
      4.216699214020815
    ],
    "volume": 184606853902
  },
  {
    "ts": 1640217600,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21819,
      "authorized": 21819,
      "funded": 19448
    },
    "payments": 47,
    "payments_amount": 57483843736,
    "trades": 1449,
    "traded_amount": 32801244506,
    "price": [
      4.216699214020815,
      4.263968654713626,
      3.9251723974968704,
      3.926342418641741
    ],
    "volume": 94372067502
  },
  {
    "ts": 1640304000,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21821,
      "authorized": 21821,
      "funded": 19446
    },
    "payments": 39,
    "payments_amount": 54672417629,
    "trades": 1175,
    "traded_amount": 61304787591,
    "price": [
      3.926342418641741,
      4.118083617514657,
      3.926342418641741,
      4.07436753684529
    ],
    "volume": 192571159259
  },
  {
    "ts": 1640390400,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21824,
      "authorized": 21824,
      "funded": 19446
    },
    "payments": 17,
    "payments_amount": 6125577742,
    "trades": 1052,
    "traded_amount": 33710956617,
    "price": [
      4.07436753684529,
      4.0965684835437255,
      3.857243836656134,
      3.9027683997299123
    ],
    "volume": 66479639040
  },
  {
    "ts": 1640476800,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21810,
      "authorized": 21810,
      "funded": 19446
    },
    "payments": 24,
    "payments_amount": 13737043620,
    "trades": 1201,
    "traded_amount": 45211366781,
    "price": [
      3.9027683997299123,
      3.962603842401971,
      3.823239923368854,
      3.888808544555041
    ],
    "volume": 84707321519
  },
  {
    "ts": 1640563200,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21814,
      "authorized": 21814,
      "funded": 19446
    },
    "payments": 60,
    "payments_amount": 66111823665,
    "trades": 1552,
    "traded_amount": 72018796208,
    "price": [
      3.888808544555041,
      3.888808544555041,
      3.7057265904180587,
      3.733400050557343
    ],
    "volume": 113324778695
  },
  {
    "ts": 1640649600,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21816,
      "authorized": 21816,
      "funded": 19442
    },
    "payments": 85,
    "payments_amount": 22230812083,
    "trades": 2966,
    "traded_amount": 114975324589,
    "price": [
      3.733400050557343,
      4.102464801877616,
      3.733400050557343,
      4.080414381474711
    ],
    "volume": 160115627919
  },
  {
    "ts": 1640736000,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21823,
      "authorized": 21823,
      "funded": 19441
    },
    "payments": 134,
    "payments_amount": 273370822798,
    "trades": 1855,
    "traded_amount": 136942572018,
    "price": [
      4.080414381474711,
      4.2429765014672425,
      3.9858114637691147,
      4.233457394802288
    ],
    "volume": 183845393924
  },
  {
    "ts": 1640822400,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21827,
      "authorized": 21827,
      "funded": 19439
    },
    "payments": 45,
    "payments_amount": 122505943729,
    "trades": 2283,
    "traded_amount": 136043732246,
    "price": [
      4.233457394802288,
      4.300000006192,
      4.1136569551640845,
      4.222338065068035
    ],
    "volume": 394127054563
  },
  {
    "ts": 1640908800,
    "supply": 12787525762803,
    "trustlines": {
      "total": 21827,
      "authorized": 21827,
      "funded": 19437
    },
    "payments": 44,
    "payments_amount": 15695303704,
    "trades": 1740,
    "traded_amount": 53253418430,
    "price": [
      4.222338065068035,
      4.377051022701731,
      4.105753392696799,
      4.258453672227101
    ],
    "volume": 97590663260
  },
  {
    "ts": 1640995200,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21830,
      "authorized": 21830,
      "funded": 19437
    },
    "payments": 21,
    "payments_amount": 27771150368,
    "trades": 1131,
    "traded_amount": 36641949522,
    "price": [
      4.258453672227101,
      4.258453672227101,
      4.1167487772261415,
      4.128267244866272
    ],
    "volume": 52129334473
  },
  {
    "ts": 1641081600,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21832,
      "authorized": 21832,
      "funded": 19434
    },
    "payments": 53,
    "payments_amount": 23062525461,
    "trades": 1367,
    "traded_amount": 52870590352,
    "price": [
      4.128267244866272,
      4.133696168852506,
      3.89198015927575,
      3.8965409978847827
    ],
    "volume": 89180982593
  },
  {
    "ts": 1641168000,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21830,
      "authorized": 21830,
      "funded": 19433
    },
    "payments": 58,
    "payments_amount": 39717852945,
    "trades": 1646,
    "traded_amount": 56106297451,
    "price": [
      3.8965409978847827,
      3.9465984964136163,
      3.8322319607459483,
      3.8864141067504683
    ],
    "volume": 98274529275
  },
  {
    "ts": 1641254400,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21837,
      "authorized": 21837,
      "funded": 19433
    },
    "payments": 68,
    "payments_amount": 63686213870,
    "trades": 1286,
    "traded_amount": 42932474253,
    "price": [
      3.8864141067504683,
      4.037146409580733,
      3.881758675614439,
      4.034349193254472
    ],
    "volume": 60007894540
  },
  {
    "ts": 1641340800,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21840,
      "authorized": 21840,
      "funded": 19430
    },
    "payments": 83,
    "payments_amount": 173357918332,
    "trades": 2321,
    "traded_amount": 87261380906,
    "price": [
      4.034349193254472,
      4.397196261682243,
      3.84304393675272,
      4.229116622119972
    ],
    "volume": 95725813976
  },
  {
    "ts": 1641427200,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21846,
      "authorized": 21846,
      "funded": 19430
    },
    "payments": 76,
    "payments_amount": 48056573399,
    "trades": 2110,
    "traded_amount": 40827999217,
    "price": [
      4.229116622119972,
      4.359399985798481,
      4.164447407007943,
      4.214867456381869
    ],
    "volume": 78268388729
  },
  {
    "ts": 1641513600,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21853,
      "authorized": 21853,
      "funded": 19430
    },
    "payments": 82,
    "payments_amount": 70734270430,
    "trades": 3454,
    "traded_amount": 98951582261,
    "price": [
      4.214867456381869,
      4.473465939424181,
      4.214867456381869,
      4.436007911066964
    ],
    "volume": 193989935498
  },
  {
    "ts": 1641600000,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21852,
      "authorized": 21852,
      "funded": 19431
    },
    "payments": 228,
    "payments_amount": 9701294795,
    "trades": 2554,
    "traded_amount": 34455260015,
    "price": [
      4.436007911066964,
      4.577144307998471,
      4.308227775995798,
      4.44299778972897
    ],
    "volume": 62023866958
  },
  {
    "ts": 1641686400,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21853,
      "authorized": 21853,
      "funded": 19427
    },
    "payments": 1792,
    "payments_amount": 37079399103,
    "trades": 3125,
    "traded_amount": 46399839877,
    "price": [
      4.44299778972897,
      4.482263287588895,
      4.319459432202108,
      4.347841042290117
    ],
    "volume": 140070729016
  },
  {
    "ts": 1641772800,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21859,
      "authorized": 21859,
      "funded": 19425
    },
    "payments": 138,
    "payments_amount": 74694055649,
    "trades": 2539,
    "traded_amount": 82016985567,
    "price": [
      4.347841042290117,
      4.677958770418494,
      4.315731345165282,
      4.473658430457876
    ],
    "volume": 154054402443
  },
  {
    "ts": 1641859200,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21864,
      "authorized": 21864,
      "funded": 19423
    },
    "payments": 238,
    "payments_amount": 82748377263,
    "trades": 2497,
    "traded_amount": 93648658450,
    "price": [
      4.473658430457876,
      4.558158786635328,
      4.322479358743599,
      4.322479358743599
    ],
    "volume": 268985370585
  },
  {
    "ts": 1641945600,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21869,
      "authorized": 21869,
      "funded": 19419
    },
    "payments": 121,
    "payments_amount": 61551884646,
    "trades": 2616,
    "traded_amount": 89499189577,
    "price": [
      4.322479358743599,
      4.325386732827782,
      3.94128749578699,
      4.037581811501456
    ],
    "volume": 85457473109
  },
  {
    "ts": 1642032000,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21870,
      "authorized": 21870,
      "funded": 19413
    },
    "payments": 167,
    "payments_amount": 16434140653,
    "trades": 2167,
    "traded_amount": 45829006021,
    "price": [
      4.037581811501456,
      4.238806523056406,
      3.958652318394635,
      4.2334998993896304
    ],
    "volume": 107541505588
  },
  {
    "ts": 1642118400,
    "supply": 12787525762790,
    "trustlines": {
      "total": 21879,
      "authorized": 21879,
      "funded": 19416
    },
    "payments": 72,
    "payments_amount": 189778745988,
    "trades": 2840,
    "traded_amount": 90423823803,
    "price": [
      4.2334998993896304,
      4.377619318657972,
      4.104604042187415,
      4.110145413927559
    ],
    "volume": 142948904053
  }
]
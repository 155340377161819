import * as dataTables from './datatables.js';
import * as map from './map.js';
import * as utils from './utils.js';
import * as modifications from './modifications.js';
import * as rates from './rates.js';

import StellarSdk from 'stellar-sdk';
import JSZip from 'jszip';
import Papa from 'papaparse';
import saveAs from 'file-saver';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)

const server = new StellarSdk.Server("https://horizon.stellar.org");

window.download = download;

async function download(target) {
    const version = `${process.env.VERSION || "dev"}`;
    console.log(`Version: ${version}`);

    target.setAttribute('disabled', 'disabled');
    target.innerText = 'Downloading...'

    const date = dayjs();

    const accountsDataTable = dataTables.getAccounts()
    const accounts = map.mapAccounts(accountsDataTable);

    const contactsDataTable = dataTables.getContacts()
    const contacts = map.mapContacts(contactsDataTable);

    const modificationList = dataTables.getModifications();

    const balances = await getBalances(accounts);

    const rawEvents = await getEvents(accounts, contacts);
    const events = modifyEvents(accounts, contacts, modificationList, rawEvents);

    log(`Building asset reports...`);
    const symbols = {};
    for (const e of events) {
        symbols[e.Symbol] = true;
    }
    const symbolReport = [];
    for (const s in symbols) {
        symbolReport.push([s, utils.getShortAssetIDFromLong(s)]);
    }

    log(`Adding transactions for lost accounts...`);
    for (const a in accounts) {
        const acc = accounts[a];
        if (!acc.dateLost) {
            continue;
        }
        const statementEvents = events.filter(e => ["CREDIT", "DEBIT"].includes(e._Type));
        const accountEvents = filterStatementForAccount(statementEvents, a);
        const accountStatement = addBalancesToStatement(accountEvents);
        const lastRow = accountStatement[accountStatement.length-1];
        for (const field in lastRow) {
            const prefix = "Balance ";
            if (!field.startsWith(prefix)) {
                continue;
            }
            const symbol = field.substring(prefix.length);
            const balance = lastRow[field];
            if (balance == 0) {
                continue;
            }
            const i = events.findIndex(e => e.Date >= acc.dateLost);
            events.splice(i, 0, ...map.mapLost(a, acc.dateLost, symbol, balance));
        }
    }

    log(`Building trade, income, and spending statements...`);
    const tradeReport = events.filter(e => e._Type == "EXCHANGE");
    const tradeViaUsdReport = events.filter(e => e._Type == "EXCHANGE_VIA_USD");
    const incomeReport = events.filter(e => e._Type == "CREDIT" && ["INCOME", "TRANSFER", "GIFTIN"].includes(e.Action));
    const spendReport = events.filter(e => e._Type == "DEBIT" && ["SPEND", "TRANSFER", "GIFT", "LOST"].includes(e.Action));

    log(`Building balance statements...`);
    const statementEvents = events.filter(e => ["CREDIT", "DEBIT"].includes(e._Type));
    const statement = addBalancesToStatement(statementEvents);
    const accountStatements = {};
    const accountSymbolStatements = {};
    for (const a in accounts) {
        const accountStatementEvents = filterStatementForAccount(statementEvents, a);
        accountSymbolStatements[a] = {};
        for (const s in symbols) {
            const accountSymbolStatementEvents = filterStatementForSymbol(accountStatementEvents, s);
            accountSymbolStatements[a][s] = addBalancesToStatement(accountSymbolStatementEvents);
        }
        accountStatements[a] = addBalancesToStatement(accountStatementEvents);
    }
    const symbolStatements = {};
    for (const s in symbols) {
        const symbolStatementEvents = filterStatementForSymbol(statementEvents, s);
        symbolStatements[s] = addBalancesToStatement(symbolStatementEvents);
    }

    log(`Assigning friendly names to accounts and assets in reports...`);
    for (const r of [
        tradeReport,
        tradeViaUsdReport,
        incomeReport,
        spendReport,
        statement,
        ...Object.values(accountStatements),
        ...Object.values(accountSymbolStatements).map(symbolStatements => Object.values(symbolStatements)).flat(),
        ...Object.values(symbolStatements),
    ]) {
        for (const e of r) {
            if (e.Account) { e.Account = `${utils.getNetworkAccountName(accounts, e.Account)}`; }
            if (e.Symbol) { e.Symbol = `${utils.getShortAssetIDFromLong(e.Symbol)}`; }
            if (e.Currency) { e.Currency = `${utils.getShortAssetIDFromLong(e.Currency)}`; }
        }
    }

    log(`Building reports...`);
    const zip = new JSZip();
    zip.file("meta.csv", Papa.unparse([
        ["Meta", "Value"],
        ["Version", version],
        ["Date UTC", date.toISOString()],
        ["Date Local", `${date.format('YYYY-MM-DDTHH:mm:ss.SSS')} ${dayjs.tz.guess()}`],
    ]));
    zip.file("assets.csv", Papa.unparse([["Asset", "Short Name"]].concat(symbolReport)));
    zip.file("balances.csv", Papa.unparse(balances));
    zip.file("accounts.csv", Papa.unparse([["Account", "Description"]].concat(accountsDataTable)));
    zip.file("contacts.csv", Papa.unparse([["Account", "Description"]].concat(contactsDataTable)));
    zip.file("modifications.csv", Papa.unparse([["Transaction ID", "Action", "USD Value"]].concat(modificationList)));

    const folderAll = zip.folder("AllTime");
    folderAll.file("statement.csv", Papa.unparse(statement));
    folderAll.file("trades.csv", Papa.unparse(tradeReport));
    folderAll.file("trades_via_usd.csv", Papa.unparse(tradeViaUsdReport));
    folderAll.file("income.csv", Papa.unparse(incomeReport));
    folderAll.file("spending.csv", Papa.unparse(spendReport));
    for (const a in accountStatements) {
        folderAll.file(`statement_account_${utils.getAccountName(accounts, a)}.csv`, Papa.unparse(accountStatements[a]));
    }
    for (const a in accountSymbolStatements) {
        for (const s in accountSymbolStatements[a]) {
            folderAll.file(`statement_account_${utils.getAccountName(accounts, a)}_asset_${s}.csv`, Papa.unparse(accountSymbolStatements[a][s]));
        }
    }
    for (const s in symbolStatements) {
        folderAll.file(`statement_asset_${s}.csv`, Papa.unparse(symbolStatements[s]));
    }

    for (let y = 2015; y <= date.get('year'); y++) {
        const yearFilter = r => dayjs(r.Date).get('year') == y;
        const upToPriorYearFilter = r => dayjs(r.Date).get('year') < y;
        addToZipForYear(zip, y, `statement.csv`, statement.filter(yearFilter));
        addToZipForYear(zip, y, `trades.csv`, tradeReport.filter(yearFilter));
        addToZipForYear(zip, y, `trades_via_usd.csv`, tradeViaUsdReport.filter(yearFilter));
        addToZipForYear(zip, y, `income.csv`, incomeReport.filter(yearFilter));
        addToZipForYear(zip, y, `spending.csv`, spendReport.filter(yearFilter));
        for (const a in accountStatements) {
            const an = utils.getAccountName(accounts, a);
            addToZipForYear(zip, y, `statement_account_${an}.csv`, accountStatements[a].filter(yearFilter));
        }
        const balanceMax = [];
        for (const a in accountSymbolStatements) {
            for (const s in accountSymbolStatements[a]) {
                const records = accountSymbolStatements[a][s].filter(yearFilter);
                const recordsForMax = [
                    ...accountSymbolStatements[a][s].filter(upToPriorYearFilter).slice(-1).map(r => ({ ...r, Date: `${y}-01-01T00:00:00Z` })),
                    ...records,
                ];
                const max = recordsForMax.reduce(
                    (max, r) => {
                        const date = r["Date"];
                        const amount = new Decimal(r[`Balance ${s}`]);
                        if (max.amount.gte(amount)) {
                            return max;
                        } else {
                            return { date: date, amount: amount };
                        }
                    },
                    { date: undefined, amount: new Decimal(0) },
                );
                if (!max.amount.isZero()) {
                    balanceMax.push({
                        "Account": utils.getAccountName(accounts, a),
                        "Symbol": s,
                        "Date": max.date,
                        "Max Amount": max.amount.toFixed(7),
                        "USD": rates.getUsdAmount(s, max.date, max.amount),
                    });
                }
                addToZipForYear(zip, y, `statement_account_${utils.getAccountName(accounts, a)}_asset_${s}.csv`, records);
            }
        }
        addToZipForYear(zip, y, "balance_max.csv", balanceMax);
        for (const s in symbolStatements) {
            addToZipForYear(zip, y, `statement_asset_${s}.csv`, symbolStatements[s].filter(yearFilter));
        }
    }

    zip.generateAsync({ type: "blob" })
        .then(function (content) {
            const filename = `${date.format('YYYY-MM-DD HHmm')} - Stellar Reports.zip`
            saveAs(content, filename);
        });

    target.removeAttribute('disabled');
    target.innerText = "Download"
}

function addToZipForYear(zip, year, file, data) {
    if (data.length == 0) {
        return;
    }

    const balanceSums = {};
    for (const r of data) {
        for (const f in r) {
            if (f.startsWith("Balance ")) {
                const v = new Decimal(r[f]);
                let s = balanceSums[f];
                if (typeof (s) === "undefined") {
                    s = new Decimal(0);
                }
                s = s.add(v);
                balanceSums[f] = s;
            }
        }
    }

    for (const b in balanceSums) {
        const s = balanceSums[b];
        if (s.isZero()) {
            data = data.map(r => {
                const nr = { ...r };
                delete (nr[b]);
                return nr;
            })
        }
    }

    zip.file(`${year}/${file}`, Papa.unparse(data));
}

function modifyEvents(accounts, contacts, modificationList, events) {
    const allAccounts = { ...contacts, ...accounts };
    // Modifications map incomes and spends to other transaction types.
    const map = new Map(modificationList.map(m => [m[0], { Action: m[1], TotalUSD: m[2] }]));
    const newEvents = events.map(e => {
        if (e.Action != "INCOME" && e.Action != "SPEND") {
            return [e];
        }
        const modification = map.get(e.TxHash);
        if (!modification) {
            return [e];
        }
        return modifications.modifyEvent(allAccounts, modification, e)
    });
    return newEvents.flat();
}

async function getEvents(accounts, contacts) {
    const allAccounts = { ...contacts, ...accounts };
    const events = [];
    log(`Finding transactions...`);
    const transactions = await getTransactions(accounts);
    log(`Found ${transactions.length} transactions...`);
    let tNumber = 0;
    for (const t of transactions) {
        tNumber++;

        const feeEvents = map.mapTransactionFee(allAccounts, t);
        if (feeEvents) {
            console.log(...feeEvents);
            events.push(...feeEvents);
        }

        log(`Downloading effects for transaction ${t.id.substring(0, 6)}..${t.id.substring(t.id.length - 6, t.id.length)} (${tNumber}/${transactions.length})`);
        const effects = await getEffects(allAccounts, t);
        for (const e of effects) {
            const o = await e.operation();
            const effectEvents = map.mapEffect(allAccounts, t, o, e);
            if (effectEvents) {
                console.log(...effectEvents);
                events.push(...effectEvents);
            }
        }
    }
    return events;
}

async function getTransactions(accounts) {
    const transactions = [];
    const transactionsSeen = {};
    const accountAddresses = Object.keys(accounts);
    let aNumber = 0;
    for (const a of accountAddresses) {
        aNumber++;
        log(`Finding transactions for account ${a.substring(0, 4)}..${a.substring(a.length - 4, a.length)} (${aNumber}/${accountAddresses.length})...`);
        const account = accounts[a];
        let res = await server.transactions().forAccount(a).includeFailed(true).limit(200).call();
        while (res.records.length > 0) {
            for (let i = 0; i < res.records.length; i++) {
                const t = res.records[i];
                if (transactionsSeen[t.id]) {
                    continue;
                }
                if (account.dateLost && t.created_at >= account.dateLost) {
                    continue;
                }
                    transactions.push(t);
                    transactionsSeen[t.id] = true;
            }
            res = await res.next();
        }
    }
    transactions.sort((t0, t1) => {
        return t0.ledger_attr - t1.ledger_attr;
    });
    return transactions;
}

async function getEffects(accounts, transaction) {
    let res = await server.effects().forTransaction(transaction.id).limit(200).call();
    let effects = [];
    while (res.records.length > 0) {
        for (let i = 0; i < res.records.length; i++) {
            let e = res.records[i];
            if ((accounts[e.account] || {}).rel == "me") {
                effects.push(e);
            }
        }
        res = await res.next();
    }
    // Keep trades sorted above account balance changes because in path payments they proceed the debit.
    effects.sort((e0, e1) => {
        if ((e0.type == "account_debited" || e0.type == "account_credited") && e1.type == "trade") {
            return 1;
        }
        if ((e1.type == "account_debited" || e1.type == "account_credited") && e0.type == "trade") {
            return -1;
        }
        return 0;
    });
    return effects;
}

function filterStatementForAccount(statement, account) {
    return statement.filter(e =>
        (e._Type == "CREDIT" && e.Recipient == account) ||
        (e._Type == "DEBIT" && e.Sender == account)
    );
}

function filterStatementForSymbol(statement, symbol) {
    return statement.filter(e => e.Symbol == symbol);
}

function addBalancesToStatement(statement) {
    const statementWithBalances = [];

    const balances = {};
    for (const event of statement) {
        // prepare balances at zero
        balances[event.Symbol] = new Decimal(0);
    }
    for (const event of statement) {
        // update balances
        let balance = balances[event.Symbol];
        let volume = new Decimal(event.Volume);
        switch (event._Type) {
            case "CREDIT":
                balance = balance.plus(volume);
                break;
            case "DEBIT":
                balance = balance.plus(volume.mul("-1"));
                break;
        }
        balances[event.Symbol] = balance;

        // insert balances into event before txhash
        const eventWithBalances = {};
        for (const k in event) {
            if (k == "TxHash") {
                for (const symbol in balances) {
                    eventWithBalances[`Balance ${symbol}`] = balances[symbol].toFixed(7);
                }
            }
            eventWithBalances[k] = event[k];
        }
        statementWithBalances.push(eventWithBalances);
    }

    return statementWithBalances;
}

async function getBalances(accounts) {
    const symbols = {};
    const balances = [];
    log(`Getting balances for accounts...`);
    for (const a in accounts) {
        let res;
        const aBalances = { Account: a, Status: "" };
        try {
            res = await server.accounts().accountId(a).call();
        } catch (err) {
            if (err instanceof StellarSdk.NotFoundError) {
                console.log(`Account ${a} does not exist and has no balance.`);
                aBalances.Status = "Closed";
                balances.push(aBalances)
                continue;
            }
            throw err;
        }
        aBalances.Status = "Open";
        for (const b of res.balances) {
            const symbol = utils.getShortAssetID(b.asset_type, b.asset_code, b.asset_issuer);
            symbols[symbol] = true;
            aBalances[symbol] = b.balance;
        }
        balances.push(aBalances);
    }
    for (const b of balances) {
        for (const s in symbols) {
            if (b[s] === undefined) {
                b[s] = "0.0000000";
            }
        }
    }
    return balances;
}

function log(text) {
    const div = document.getElementById('button');
    div.innerText = text;
}
